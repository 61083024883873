import { ModelBase } from "@shoothill/core";
import { Validator } from "Application/Validation";
import { makeObservable, observable } from "mobx";

export class TermsAndConditionItemModel extends ModelBase<TermsAndConditionItemModel> {
    public id: string = "";
    public name: string = "";
    public subject: string = "";
    public body: string = "";
    public createdByUserId: string = "";
    public lastUpdatedDate: string = "";
    public lastUpdatedByUserId: string = "";
    public lastUpdatedByUser: string = "";

    constructor() {
        super();
        makeObservable(this, {
            id: observable,
        });
    }
}

export class TermsAndConditionItemModelValidator extends Validator<TermsAndConditionItemModel> {
    constructor() {
        super();
    }
}
