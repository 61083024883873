import { runInAction } from "mobx";

import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";

import { CustomerItemModel } from "Views/Shared/Customer/CustomerItemModel";
import { ProjectItemViewModel } from "Views/Shared/Project/ProjectItemViewModel";
import { MyProjectsViewModel } from "../MyProjects/MyProjectsViewModel";
import { ProjectItemModel } from "Views/Shared/Project/ProjectItemModel";

class ProjectLiteResponse {
    public id: string = "";

    public projectStatusId = "";
    public projectStatusName = "";
    public projectStatusForegroundColor = "";

    public customerId = "";

    public projectLeadId: string | null = null;
    public projectLeadFirstName: string | null = null;
    public projectLeadLastName: string | null = null;

    public seniorAssociateId: string | null = null;
    public seniorAssociateFirstName: string | null = null;
    public seniorAssociateLastName: string | null = null;

    public reference = "";
    public title = "";
    public value = 0;
    public noOfTasks: number | null = null;
    public anticipatedEndDate: string | null = null;
    public complete: number | null = null;
    public noOfHours: number | null = null;
}

class CustomerLiteResponse {
    public id: string = "";
    public contactTypeId: string = "";
    public contactTitleId: string | null = null;

    public businessName: string | null = null;
    public prefixName: string = "";
    public firstName: string | null = null;
    public lastName: string | null = null;

    public contactNumber1: string | null = null;
    public contactNumber2: string | null = null;
    public emailAddress: string | null = null;
    public emailAddress2: string | null = null;

    public address1: string = "";
    public address2: string | null = null;
    public address3: string | null = null;
    public city: string = "";
    public postcode: string = "";
}

class ProjectResponse {
    public projectLite: ProjectLiteResponse | null = null;
    public customerLite: CustomerLiteResponse | null = null;
}

class Response {
    public projectLiteResponses: ProjectResponse[] = [];
}

export class GETAllProjectsLiteByProjectLeadIdEndpoint extends EndpointWithoutRequest<Response> {
    private viewModel: MyProjectsViewModel;

    constructor(viewModel: MyProjectsViewModel) {
        super();
        this.viewModel = viewModel;
        this.verb(Http.Get);
        this.path(AppUrls.Server.Projects.AllProjectsLiteByProjectLeadId);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            const projects: ProjectItemViewModel[] = [];

            response.projectLiteResponses.forEach((response: ProjectResponse) => {
                const model = new ProjectItemModel();
                const customerItemModel = new CustomerItemModel();
                customerItemModel.fromResponse(response.customerLite);

                model.fromResponse(response.projectLite);
                model.customerItemModel = customerItemModel;

                projects.push(new ProjectItemViewModel(model));
            });

            this.viewModel.projects.replace(projects);
        });
    }
}
