import { IColumn, Link, PersonaSize } from "@fluentui/react";
import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import { Box, formatDate, setPageTitle, theme, ThemedComboBox, ThemedEditDate, ThemedEditText, ThemedText } from "Application";
import { ThemedDataTable } from "../../../../Styles/ThemedPrimitives/Style1/ThemedDataTable";
import { EnquiriesViewModel } from "./EnquiriesViewModel";
import { SearchIcon } from "../../../../Assets/SearchIcon";
import { EmailIcon } from "../../../../Assets/Icons/EmailIcon";
import { QuoteItemViewModel } from "../../Quotes/Table/QuoteItemViewModel";
import { EnquiryItemViewModel } from "./EnquiryItemViewModel";
import { ContactCallout, ICalloutState } from "../../../../Styles/ThemedPrimitives/Style1/ContactCallout";
import { SmallPhoneIcon } from "../../../../Assets/Icons/SmallPhoneIcon";
import { ThemedPersona } from "Styles/ThemedPrimitives/Style1/ThemedPersona";

const controlStyles = {
    root: {
        width: "505px",
        height: "39px",
        fontSize: "17px",
        borderColor: "#E1E1E1",
    },
};

export const EnquiriesView: React.FC = observer(() => {
    // #region Code Behind

    const [viewModel] = useState(() => new EnquiriesViewModel());

    useEffect(() => {
        setPageTitle("Enquiries");
    }, []);

    /**
     * Handles updating the tables column sort settings in response to changes in the viewmodel.
     */
    useEffect(() => {
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter((currCol) => viewModel.settings.sortKey === currCol.key)[0];

        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSorted = true;
                currColumn.isSortedDescending = viewModel.settings.sortDescending;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = false;
            }
        });

        setColumns(newColumns);
    }, [viewModel.settings.sortKey, viewModel.settings.sortDescending]);

    /**
     * Gets a key for the row. Must be provided if sorting or filtering is enabled.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The row index.
     *
     * @returns A string key to identify the row.
     */
    const getRowKey = (item: EnquiryItemViewModel, index?: number): string => {
        return item.KEY;
    };

    /**
     * Handles an onClick event for the table column header.
     *
     * @param ev Mouse event
     * @param column The column that has been clicked.
     */
    const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const currColumn: IColumn = columns.filter((currCol) => column.key === currCol.key)[0];
        const newSortKey = currColumn.key;
        const newSortDescending = currColumn.key === viewModel.settings.sortKey ? !currColumn.isSortedDescending : false;

        viewModel.updateSortCommand.execute(newSortKey, newSortDescending);
    };

    /**
     * Handler an onClick event for a table row.
     *
     * @param item The viewmodel data associated with the table row.
     */
    const onRowClick = (item: QuoteItemViewModel, index?: number, ev?: React.FocusEvent<HTMLElement>) => {
        viewModel.navigateToEditEnquiry.execute(item);
    };

    /**
     * Renders the phone icon and provides callout support.
     *
     * The icon is wrapped in a Link with a stop propagation call so
     * clicking on the icon prevents the table row click.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The index of the table row.
     * @param column The table column.
     *
     * @returns React rating element.
     */
    const onRenderPhone = (item: EnquiryItemViewModel, index?: number, column?: IColumn) => {
        if (!isEmptyOrWhitespace(item.model.contactNumber1)) {
            return (
                <Link onFocus={(ev) => ev.stopPropagation()}>
                    <SmallPhoneIcon
                        id={`phoneicon-${index}`}
                        onClick={(event: any) => {
                            setIsCalloutVisible({
                                isCalloutVisble: true,
                                isEmail: false,
                                targetId: `phoneicon-${index}`,
                                data: item.model.contactNumber1,
                            });
                        }}
                    />
                </Link>
            );
        }
        return <React.Fragment />;
    };

    const onRenderContactName = (item: EnquiryItemViewModel, index?: number, column?: IColumn) => {
        if (item.model.customerItemModel != null) {
            return (
                <Link onFocus={(ev) => ev.stopPropagation()}>
                    <div onClick={() => viewModel.navigateToCustomerDetailCommand.execute(item.model.customerItemModel!.id)}>
                        <div style={{ fontWeight: 600, fontSize: "10px", color: "#171716" }}>{item.contactFullName}</div>
                        <div style={{ fontSize: "10px", color: "#171716" }}>{item.contactFullAddress}</div>
                    </div>
                </Link>
            );
        } else {
            return (
                <div>
                    <div style={{ fontWeight: 600, fontSize: "10px", color: "#171716" }}>{item.contactFullName}</div>
                    <div style={{ fontSize: "10px", color: "#171716" }}>{item.contactFullAddress}</div>
                </div>
            );
        }
    };

    /**
     * Renders the email icon and provides callout support.
     *
     * The icon is wrapped in a Link with a stop propagation call so
     * clicking on the icon prevents the table row click.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The index of the table row.
     * @param column The table column.
     *
     * @returns React rating element.
     */
    const onRenderEmail = (item: EnquiryItemViewModel, index?: number, column?: IColumn) => {
        if (!isEmptyOrWhitespace(item.model.emailAddress)) {
            return (
                <Link onFocus={(ev) => ev.stopPropagation()}>
                    <EmailIcon
                        id={`emailicon-${index}`}
                        onClick={(event: any) => {
                            setIsCalloutVisible({
                                isCalloutVisble: true,
                                isEmail: true,
                                targetId: `emailicon-${index}`,
                                data: item.model.emailAddress,
                            });
                        }}
                    />
                </Link>
            );
        }

        return <React.Fragment />;
    };

    /**
     * Renders the source of enquiry details.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The index of the table row.
     * @param column The table column.
     *
     * @returns React element.
     */
    const onRenderSourceOfEnquiry = (item: EnquiryItemViewModel, index?: number, column?: IColumn) => {
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                }}
            >
                <div>{item.sourceOfEnquiryTypeName}</div>
                {item.sourceOfEnquiryDetail && (
                    <>
                        <div>({item.sourceOfEnquiryDetail})</div>
                    </>
                )}
            </div>
        );
    };

    /**
     * Renders the personVisit persona.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The index of the table row.
     * @param column The table column.
     *
     * @returns React element.
     */
    const onRenderPersonVisitingName = (item: EnquiryItemViewModel, index?: number, column?: IColumn) => {
        if (!isEmptyOrWhitespace(item.personVisitingName)) {
            return (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <ThemedPersona
                        styles={{ primaryText: { fontSize: "10px" } }}
                        displayName={item.personVisitingName}
                        size={PersonaSize.size32}
                        imageUrl={item.personVisitingDocumentUrl}
                    />
                </div>
            );
        }

        return <React.Fragment />;
    };

    const onRenderLastAction = (item: EnquiryItemViewModel, index?: number, column?: IColumn) => {
        return (
            <div>
                <div>{!isNullOrUndefined(item.model.lastActionDate) ? formatDate(item.lastActionDate!) : ""}</div>
                <div>{item.fullName ? item.fullName : ""}</div>
            </div>
        );
    };

    const defaultTableColumns: IColumn[] = [
        {
            key: "enquiryStatusName",
            name: "STATUS",
            fieldName: "enquiryStatusName",
            minWidth: 75,
            maxWidth: 100,
            isResizable: true,
            onColumnClick: onColumnClick,
            onRender: (item: EnquiryItemViewModel, index?: number, column?: IColumn) => (
                <div
                    style={{
                        color: item.model.enquiryStatusName === "Booked" ? theme.palette.common.secondary : theme.palette.common.black,
                        fontSize: "10px",
                        fontWeight: "500",
                        textTransform: "uppercase",
                    }}
                >
                    {item.model.enquiryStatusName.toUpperCase()}
                </div>
            ),
        },
        {
            key: "enquiryTypeName",
            name: "ENQUIRY TYPE",
            fieldName: "enquiryTypeName",
            isResizable: true,
            minWidth: 100,
            maxWidth: 150,
            isSorted: true,
            onColumnClick: onColumnClick,
        },
        {
            key: "reference",
            name: "REFERENCE",
            fieldName: "reference",
            isResizable: true,
            minWidth: 100,
            maxWidth: 100,
            styles: {
                cellTitle: {
                    textAlign: "center",
                    justifyContent: "center",
                },
            },
            className: "textCenter",
            isSorted: true,
            onColumnClick: onColumnClick,
        },
        {
            key: "title",
            name: "JOB NAME",
            fieldName: "title",
            isResizable: true,
            minWidth: 350,
            maxWidth: 535,
            isSorted: true,
            onColumnClick: onColumnClick,
        },
        {
            key: "fullName",
            name: "CLIENT",
            fieldName: "fullName",
            minWidth: 250,
            isSorted: true,
            isSortedDescending: false,
            sortAscendingAriaLabel: "Sorted A to Z",
            sortDescendingAriaLabel: "Sorted Z to A",
            onColumnClick: onColumnClick,
            isPadded: true,
            onRender: onRenderContactName,
            styles: {
                cellTitle: {
                    border: "none !important",
                },
            },
        },
        {
            className: "contactCell",
            fieldName: "",
            styles: {
                cellTitle: {
                    border: "none !important",
                },
            },
            key: "phoneicon",
            name: "",
            minWidth: 25,
            onRender: onRenderPhone,
        },
        {
            className: "contactCell",
            fieldName: "",
            key: "emailicon",
            name: "",
            minWidth: 25,
            onRender: onRenderEmail,
        },
        {
            fieldName: "personVisitingName",
            key: "personVisitingName",
            name: "PERSON VISITING",
            minWidth: 190,
            maxWidth: 215,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: onRenderPersonVisitingName,
        },
        {
            fieldName: "sourceOfEnquiry",
            key: "sourceOfEnquiry",
            name: "SOURCE OF ENQUIRY",
            minWidth: 150,
            maxWidth: 175,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: onRenderSourceOfEnquiry,
        },
        {
            fieldName: "lastActionDate",
            key: "lastActionDate",
            name: "LAST ACTION",
            minWidth: 150,
            maxWidth: 175,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: onRenderLastAction,
            styles: {
                cellTitle: {
                    border: "none !important",
                },
            },
        },
    ];

    /**
     * Defines the default state for displaying a callout.
     */
    const defaultCalloutState: ICalloutState = { isCalloutVisble: false, targetId: "", isEmail: false, data: "" };

    const [columns, setColumns] = useState<IColumn[]>(defaultTableColumns);
    const [callout, setIsCalloutVisible] = useState<ICalloutState>(defaultCalloutState);

    // #endregion Code Behind

    return (
        <Box>
            {/* region Filter Bar */}
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} dc={"5fr 1fr 1fr 1fr 1fr"}>
                <Box display={"flex"}>
                    <ThemedEditText
                        command={viewModel.updateFilterKeywordCommand}
                        placeholder={"Keyword search"}
                        prefix={<SearchIcon />}
                        styles={controlStyles}
                        value={() => viewModel.getValue("filterKeyword")}
                    />
                    <Box pl={3}>
                        <ThemedEditDate
                            command={viewModel.updateFilterFromDateComand}
                            placeholder="Action from"
                            size="larger"
                            value={() => viewModel.getValue("filterFromDate")}
                            showLabel={false}
                        />
                    </Box>
                    <Box pl={3}>
                        <ThemedEditDate
                            command={viewModel.updateFilterToDateCommand}
                            placeholder="Action to"
                            size="larger"
                            value={() => viewModel.getValue("filterToDate")}
                            showLabel={false}
                        />
                    </Box>
                    <Box pl={3}>
                        <ThemedComboBox
                            command={viewModel.updateFilterEnquiryStatusCommand}
                            options={viewModel.enquiryStatuses}
                            placeholder="All statuses"
                            size="larger"
                            value={() => viewModel.getValue("filterEnquiryStatusId")}
                        />
                    </Box>
                    <Box pl={3}>
                        <ThemedComboBox
                            command={viewModel.updateFilterVisitingPersonCommand}
                            options={viewModel.visitingPersons}
                            placeholder="All persons"
                            size="larger"
                            value={() => viewModel.getValue("filterVisitingPersonId")}
                        />
                    </Box>
                    <Box pl={3}>
                        <ThemedComboBox
                            command={viewModel.updateFilterSourceOfEnquiryTypeCommand}
                            options={viewModel.sourceOfEnquiriesTypes}
                            placeholder="All sources of enquiry"
                            size="larger"
                            value={() => viewModel.getValue("filterSourceOfEnquiryTypeId")}
                        />
                    </Box>
                </Box>
            </Box>
            {/* endregion Filter Bar */}

            {/* region table */}
            <ThemedDataTable getKey={getRowKey} items={viewModel.filteredAndSortedEnquiries} columns={columns} onActiveItemChanged={(item) => onRowClick(item)} />
            {/* region table */}

            {/* Callout */}
            {callout.isCalloutVisble && (
                <ContactCallout data={callout.data} onDismiss={() => setIsCalloutVisible(defaultCalloutState)} isEmail={callout.isEmail} targetId={callout.targetId} />
            )}
        </Box>
    );
});
