import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

import { LeaveDayType } from "Views/Leave/Shared/LeaveDayType";
import { LeaveType } from "Views/Leave/Shared/LeaveType";

export class LeaveCalendarModel extends ModelBase<LeaveCalendarModel> {
    // Supporting
    public leaveTypes = observable<LeaveType>([]);
    public leaveDayTypes = observable<LeaveDayType>([]);

    constructor() {
        super();

        makeObservable(this, {});
    }
}
