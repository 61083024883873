import { ViewModelBase } from "@shoothill/core";

import { ICommand } from "Application";
import { SendUpdatesConfirmationModel } from "./SendUpdatesConfirmationModel";

export class SendUpdatesConfirmationViewModel extends ViewModelBase<SendUpdatesConfirmationModel> {
    public parentCancelCommand: ICommand;
    public parentSubmitCommand: ICommand;

    constructor(parentCancelCommand: ICommand, parentSubmitCommand: ICommand) {
        super(new SendUpdatesConfirmationModel());

        this.parentCancelCommand = parentCancelCommand;
        this.parentSubmitCommand = parentSubmitCommand;
    }

    // #region Boilerplate
    // #endregion Boilerplate
}
