import { isEmptyOrWhitespace } from "@shoothill/core";
import { FilesModel } from "Views/Shared/Files/FilesModel";
import { EnquiryModel } from "../EnquiryModel";
import { SourceOfEnquiryModel } from "Views/Shared/SourceOfEnquiry/SourceOfEnquiryModel";

class EnquiryDocumentRequest {
    public id: string | null = null;
}
class SourceOfEnquiryRequest {
    public id: string | null = null;
    public sourceOfEnquiryTypeId: string = "";
    public detail: string | null = null;
}
export class EnquiryRequest {
    // #region Request Data

    id: string | null = null;
    enquiryStatusId: string | null = null;
    enquiryTypeId: string | null = null;
    enquiryPersonVisitingId: string | null = null;
    enquiryPersonVisitingVisitDate: Date | null = null;
    enquiryPersonVisitingVisitTime: string | null = null;
    projectTypeId: string = "";
    projectId: string | null = null;
    contactTypeId: string = "";
    customerId: string | null = null;
    customerContactId: string | null = null;
    title: string = "";
    description: string = "";
    estimatedCost: number = 0;

    siteAddress1: string | null = null;
    siteAddress2: string | null = null;
    siteAddress3: string | null = null;
    siteCity: string | null = null;
    sitePostcode: string | null = null;
    sameAsBusinessAddress: boolean = false;
    sourceOfEnquiry: SourceOfEnquiryRequest = new SourceOfEnquiryRequest();
    documents: EnquiryDocumentRequest[] = [];

    // #endregion Request Data

    // #region ViewModel Part Mappings to Request

    public static enquiryPart(request: EnquiryRequest, model: EnquiryModel, filesModel: FilesModel) {
        request.id = model.id;
        request.enquiryStatusId = model.enquirySubModel.enquiryStatusId;
        request.enquiryTypeId = model.enquirySubModel.enquiryTypeId;
        request.enquiryPersonVisitingId = model.enquirySubModel.enquiryPersonVisitingId;
        request.enquiryPersonVisitingVisitDate = model.enquirySubModel.enquiryPersonVisitingVisitDate;
        request.enquiryPersonVisitingVisitTime = model.enquirySubModel.enquiryPersonVisitingVisitTime;
        request.projectTypeId = model.enquirySubModel.projectTypeId;
        request.projectId = isEmptyOrWhitespace(model.enquirySubModel.projectId) ? null : model.enquirySubModel.projectId;
        request.title = model.enquirySubModel.title;
        request.description = model.enquirySubModel.description;
        request.estimatedCost = model.enquirySubModel.estimatedCost;

        request.documents =
            filesModel.files
                .filter((f) => !isEmptyOrWhitespace(f.id))
                .map((f) => {
                    const d = new EnquiryDocumentRequest();

                    d.id = f.id;

                    return d;
                }) ?? [];
    }

    public static sourceOfEnquiryPart(request: EnquiryRequest, model: SourceOfEnquiryModel) {
        request.sourceOfEnquiry.id = model.id;
        request.sourceOfEnquiry.detail = model.detail;
        request.sourceOfEnquiry.sourceOfEnquiryTypeId = model.sourceOfEnquiryTypeId;
    }

    public static customerRequestPart(request: EnquiryRequest, model: EnquiryModel) {
        request.contactTypeId = model.customer.contactTypeId;
        request.customerId = model.customer.customerId;
        request.customerContactId = model.customer.customerContactId;
        request.siteAddress1 = model.customer.siteAddress1;
        request.siteAddress2 = model.customer.siteAddress2;
        request.siteAddress3 = model.customer.siteAddress3;
        request.siteCity = model.customer.siteCity;
        request.sitePostcode = model.customer.sitePostcode;
        request.sameAsBusinessAddress = model.customer.sameAsBusinessAddress;
    }

    // #endregion ViewModel Part Mappings to Request
}
