import { FieldType, ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

import { ICommand, RelayCommand } from "Application";
import { CustomerContactItemModel } from "../CustomerContactItemModel";
import { ConfigureCustomerModel, ConfigureCustomerValidator } from "./ConfigureCustomerModel";

export class ConfigureCustomerViewModel extends ViewModelBase<ConfigureCustomerModel> {
    public cancelDisplayConfigureCustomerCommand: ICommand;
    public parentUpdateFromConfigureCustomerCommand: ICommand;

    public customerContacts: CustomerContactItemModel[] = [];

    constructor(
        configureCustomerModel: ConfigureCustomerModel = new ConfigureCustomerModel(),
        customerContacts: CustomerContactItemModel[],
        cancelDisplayConfigureCustomerCommand: ICommand,
        updateFromConfigureCustomerCommand: ICommand,
    ) {
        super(configureCustomerModel);

        this.customerContacts = customerContacts;
        this.cancelDisplayConfigureCustomerCommand = cancelDisplayConfigureCustomerCommand;
        this.parentUpdateFromConfigureCustomerCommand = updateFromConfigureCustomerCommand;

        this.setValidator(new ConfigureCustomerValidator());

        makeObservable(this, {
            // Observables
        });
    }

    // #region Properties

    public get KEY() {
        return this.model.KEY;
    }

    /**
     * Returns a collection of customer contacts for the customer.
     * However, don't return any deleted customers contacts as they should not be selectable.
     */
    public get customerContactOptions() {
        return this.customerContacts
            .filter((item: CustomerContactItemModel) => {
                return !item.isDeleted;
            })
            .map((item: CustomerContactItemModel) => {
                return {
                    key: item.id!,
                    text: `${item.firstName} ${item.lastName}`,
                };
            });
    }

    // #endregion Properties

    // #region Commands

    public updateCustomerContactCommand = new RelayCommand((value: string | null) => {
        this.updateField("id", value);
    });

    public updateFromConfigureCustomerCommand = new RelayCommand(() => {
        if (this.isModelValid()) {
            this.parentUpdateFromConfigureCustomerCommand.execute();
        }
    });

    // #endregion Commands

    // #region Supporting

    private updateField(fieldName: keyof FieldType<ConfigureCustomerModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    // #endregion Supporting
}
