import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

import { Validator } from "Application/Validation";
import { CustomerItemModel } from "Views/Shared/Customer/CustomerItemModel";

export class ProjectItemModel extends ModelBase<ProjectItemModel> {
    public id: string = "";

    public projectStatusId = "";
    public projectStatusName = "";
    public projectStatusForegroundColor = "";

    public projectLeadId: string | null = null;
    public projectLeadFirstName: string | null = null;
    public projectLeadLastName: string | null = null;

    public seniorAssociateId: string | null = null;
    public seniorAssociateFirstName: string | null = null;
    public seniorAssociateLastName: string | null = null;

    public reference = "";
    public title = "";
    public value = 0;
    public noOfTasks: number | null = null;
    public anticipatedEndDate: Date | null = null;
    public complete: number | null = null;
    public noOfHours: number | null = null;

    public customerItemModel: CustomerItemModel | null = null;

    constructor() {
        super();
        makeObservable(this, {
            id: observable,
            customerItemModel: observable,
        });
    }
}
export class ProjectsListModelValidator extends Validator<ProjectItemModel> {
    constructor() {
        super();
    }
}
