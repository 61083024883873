import { isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { computed, makeObservable, observable } from "mobx";

import { RelayCommand } from "Application";
import { PersonaFileModel } from "./PersonaUploaderModel";

export class PersonaFileViewModel extends ViewModelBase<PersonaFileModel> {
    constructor(fileModel: PersonaFileModel = new PersonaFileModel()) {
        super(fileModel);

        makeObservable(this, {
            // Observables
            // Computeds
            displayName: computed,
            documentUrl: computed,
            hasDocumentUrl: computed,
        });
    }

    public allowMultipleFiles = false;
    public fileTypes = "image/jpeg, image/png";

    // #region Properties

    public get KEY() {
        return this.model.KEY;
    }

    public get displayName() {
        return this.model.fileName;
    }

    public get documentUrl() {
        return this.model.documentUrl;
    }

    public get fileSizeBytes() {
        const fileSizeKiloBytes = new Intl.NumberFormat("en-GB", { maximumSignificantDigits: 3 }).format(this.model.fileSizeBytes / 1024);

        return `${fileSizeKiloBytes} KB`;
    }

    public get limitExceededErrorMessage() {
        return this.model.isWithinUploadLimit ? "" : `The file size should be less than ${PersonaFileModel.MAX_FILESIZE_MB}`;
    }

    public get hasDocumentUrl() {
        return !isEmptyOrWhitespace(this.model.documentUrl);
    }

    // #endregion Properties

    // #region Commands

    /**
     * Command to add an image/mughot for the user.
     */
    public addCommand = new RelayCommand((files: FileList) => {
        if (files) {
            for (let fileIndex = 0; fileIndex < files.length; fileIndex++) {
                this.model.file = files.item(fileIndex);
                this.model.fileName = files.item(fileIndex)!.name;
                this.model.fileSizeBytes = files.item(fileIndex)!.size;
                this.model.mimeType = files.item(fileIndex)!.type;
                this.model.documentUrl = "";

                // If the local file is an image file, create an image from it
                // to display in the control.
                if (/\.(jpe?g|png|gif)$/i.test(this.model.fileName)) {
                    let reader = new FileReader();

                    reader.addEventListener(
                        "load",
                        () => {
                            const image = new Image();

                            image.height = 300;
                            image.title = this.model.fileName;
                            image.src = reader.result as string;

                            this.model.documentUrl = image.src;
                        },
                        false,
                    );

                    reader.readAsDataURL(files.item(fileIndex)!);
                }

                break;
            }
        }
    });

    // #endregion Commands
}
