import { observer } from "mobx-react-lite";
import { ServiceViewModel } from "../ServiceViewModel";
import { ThemedButton, ThemedCheckBox, ThemedEditText } from "Application";
import { ITextFieldStyles } from "@fluentui/react";
import { ButtonGroup, Container } from "./AddServiceView.styles";
import { alignItems } from "styled-system";

interface IAddNewServiceProps {
    viewModel: ServiceViewModel;
}

const controlStyles: Partial<ITextFieldStyles> = {
    root: {
        fontSize: "17px",
        borderColor: "#E1E1E1",
        paddingTop: 0,
    },
    subComponentStyles: {
        label: {
            paddingTop: 0,
        },
    },
    fieldGroup: {
        height: "39px",
    },
};

const ThemedButtonStyles = {
    root: {
        height: 30,
        width: 147,
        fontSize: 17,
    },
};

export const AddServiceView: React.FC<IAddNewServiceProps> = observer(({ viewModel }) => {
    return (
        <Container>
            <ThemedEditText
                command={viewModel.updateNameCommand}
                displayName={"Service Title*"}
                placeholder={"Please enter a service title"}
                styles={controlStyles}
                validationMessage={() => viewModel.getError("name")}
                value={() => viewModel.getValue("name")}
            />
            <ThemedEditText
                command={viewModel.updateManHoursCommand}
                displayName={"Default Hours*"}
                placeholder="99.99"
                size="large"
                styles={controlStyles}
                validationMessage={() => viewModel.getError("manHours")}
                value={() => viewModel.getValue("manHours") ?? ""}
            />
            <ThemedEditText
                command={viewModel.updateHourlyRateCommand}
                displayName={"Hourly Rate*"}
                prefix={"£"}
                placeholder="99.99"
                size="large"
                styles={controlStyles}
                validationMessage={() => viewModel.getError("hourlyRate")}
                value={() => viewModel.getValue("hourlyRate") ?? ""}
            />
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <ThemedCheckBox
                    displayName="Link to planning application"
                    labelColor="secondary"
                    validationMessage={() => viewModel.getError("isLinkedToPlanningApplication")}
                    value={() => viewModel.getValue("isLinkedToPlanningApplication")}
                    command={viewModel.updateIsLinkedToPlanningApplicationCommand}
                />
            </div>

            <ButtonGroup>
                <ThemedButton paletteColor="primary" command={viewModel.submitAddNewServiceCommand} displayName={"Add"} styles={ThemedButtonStyles} />
                <ThemedButton paletteColor="default" command={viewModel.cancelAddNewServiceCommand} displayName={"Cancel"} styles={ThemedButtonStyles} />
            </ButtonGroup>
        </Container>
    );
});
