import { ModelBase } from "@shoothill/core";

export class CalloutInformationModel extends ModelBase<CalloutInformationModel> {
    public callOutKey: string = "";
    public fieldName: string = "";
    public projectTransactionId: string = "";

    constructor(projectTransactionId: string, fieldName: string, callOutKey: string) {
        super();

        this.callOutKey = callOutKey;
        this.fieldName = fieldName;
        this.projectTransactionId = projectTransactionId;
    }
}
