import { isEmptyOrWhitespace, isNullOrUndefined, ViewModelBase } from "@shoothill/core";
import { computed, makeObservable, observable } from "mobx";

import { APIClient, RelayCommand } from "Application";
import { AssignTaskViewModel } from "./AssignTaskSubViews/AssignTaskViewModel";
import { AssignTaskModel } from "./AssignTaskSubViews/AssignTaskModel";
import { UnassignedTasksModel } from "./UnassignedTasksModel";
import { UnassignedTaskItemViewModel } from "./UnassignedTaskItemViewModel";
import { GETUnassignedProjectTasksEndpoint } from "./Endpoints/GETAllUnassignedProjectTasksEndpoint";
import {
    GET_GetFilteredUnassignedProjectTaskAssignmentsXLSXEndpoint,
    GetFilteredUnassignedProjectTaskAssignmentsXLSXRequest,
} from "./Endpoints/GET_GetFilteredUnassignedProjectTaskAssignmentsXLSXEndpoint";
import { ViewModelBaseExtendedViewModel } from "Application/ViewModels/ViewModelBaseUI";

export class UnassignedTasksViewModel extends ViewModelBaseExtendedViewModel<UnassignedTasksModel> {
    public apiClient = new APIClient();
    public assignTaskViewModel: AssignTaskViewModel | null = null;
    public tasks = observable<UnassignedTaskItemViewModel>([]);

    constructor() {
        super(new UnassignedTasksModel());

        makeObservable(this, {
            tasks: observable,
            assignTaskViewModel: observable,

            // Computeds
            canFilterTasks: computed,
            filteredTasks: computed,
            canSortTasks: computed,
            filteredAndSortedTasks: computed,
            projects: computed,
            taskGroups: computed,
            projectLeads: computed,
            canDisplayAssignTask: computed,
        });
    }

    //region endpoint calls
    public loadAsync = async () => {
        const _ = await this.apiClient.sendAsync(new GETUnassignedProjectTasksEndpoint(this));
    };

    // #region Properties

    public get filteredRemainingProjectTaskBillableEffort(): number {
        return this.filteredAndSortedTasks.filter((x) => x.remainingProjectTaskBillableEffort !== "N/A").reduce((acc, x) => acc + Number(x.remainingProjectTaskBillableEffort), 0);
    }

    // #endregion Properties

    // #region Filtering

    public updateFilterKeywordCommand = new RelayCommand((keyword: string) => {
        this.model.filterKeyword = keyword;
    });

    public updateFilterProjectCommand = new RelayCommand((id: string | null) => {
        this.model.filterProjectId = id;
    });

    public updateFilterProjectTaskGroupCommand = new RelayCommand((id: string | null) => {
        this.model.filterProjectTaskGroupName = id;
    });

    public updateFilterProjectLeadCommand = new RelayCommand((id: string | null) => {
        this.model.filterProjectLeadId = id;
    });

    public clearFiltersCommand = new RelayCommand(() => {
        this.model.filterKeyword = "";
        this.model.filterProjectId = null;
        this.model.filterProjectLeadId = null;
        this.model.filterProjectTaskGroupName = null;
    });

    public onDownloadProjectTaskAssignmentsXLSXCommandAsync = new RelayCommand(
        async () => {
            this.startedProcessingData();
            //guard clause to prevent user from spamming the endpoint
            if (this.apiClient.IsBusy) return;

            //create request
            const request = new GetFilteredUnassignedProjectTaskAssignmentsXLSXRequest();
            request.keyword = this.model.filterKeyword;
            request.projectId = this.model.filterProjectId;
            request.projectTaskGroupName = this.model.filterProjectTaskGroupName;
            request.projectLeadId = this.model.filterProjectLeadId;
            //call endpoint
            const endpoint = new GET_GetFilteredUnassignedProjectTaskAssignmentsXLSXEndpoint();

            await this.apiClient.sendAsync(endpoint, request);

            if (!this.apiClient.IsRequestSuccessful) return;

            const response = this.apiClient.Response();

            const contentDisposition = response.headers["content-disposition"];
            const filename = contentDisposition.split("filename=")[1];

            // Create a link element for the file and use the filename provided.
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(new Blob([response]));
            link.setAttribute("download", filename);

            document.body.appendChild(link);

            this.finishedProcessingData();
            // Download.
            link.click();

            // Having clicked the link, delete the element otherwise it will
            // remain attached to the document.
            document.body.removeChild(link);
        },
        () => !this.apiClient.IsBusy,
    );

    public get projects() {
        const options = this.tasks.map((t) => {
            return {
                key: t.model.id,
                text: `${t.model.reference} ${t.model.title}`,
            };
        });

        const distinctOptions = options.filter((option, index, arr) => arr.findIndex((t) => t.key === option.key) === index);

        return [{ key: "", text: "All projects" }, ...distinctOptions];
    }

    public get taskGroups() {
        const options = this.tasks.map((t) => {
            return {
                key: t.model.projectTaskGroupName,
                text: t.model.projectTaskGroupName,
            };
        });

        const distinctOptions = options.filter((option, index, arr) => arr.findIndex((t) => t.key === option.key) === index);

        return [{ key: "", text: "All stages" }, ...distinctOptions];
    }

    public get projectLeads() {
        const options = this.tasks
            .map((t) => {
                return {
                    key: t.model.projectLeadId,
                    text: `${t.model.projectLeadFirstName} ${t.model.projectLeadLastName}`,
                };
            })
            .sort((a, b) => (a.text > b.text ? 1 : b.text > a.text ? -1 : 0));

        const distinctOptions = options.filter((option, index, arr) => arr.findIndex((t) => t.key === option.key) === index);

        return [{ key: "", text: "All leads" }, ...distinctOptions];
    }

    public get canFilterTasks(): boolean {
        return (
            !isEmptyOrWhitespace(this.model.filterKeyword) ||
            !isEmptyOrWhitespace(this.model.filterProjectId) ||
            !isEmptyOrWhitespace(this.model.filterProjectTaskGroupName) ||
            !isEmptyOrWhitespace(this.model.filterProjectLeadId)
        );
    }

    public get filteredTasks(): UnassignedTaskItemViewModel[] {
        return this.canFilterTasks
            ? this.tasks.filter((vm) =>
                  vm.filterPredicate(this.model.filterKeyword, this.model.filterProjectId, this.model.filterProjectTaskGroupName, this.model.filterProjectLeadId),
              )
            : this.tasks;
    }

    // #endregion Filtering

    // #region Sorting

    public updateSortCommand = new RelayCommand((key: string, sortDescending: boolean) => {
        this.model.sortKey = key;
        this.model.sortDescending = sortDescending;
    });

    public get canSortTasks(): boolean {
        return !isEmptyOrWhitespace(this.model.sortKey);
    }

    public get filteredAndSortedTasks(): UnassignedTaskItemViewModel[] {
        return this.canSortTasks
            ? this.filteredTasks.slice().sort((lhs: any, rhs: any) => {
                  if (this.model.sortKey === "remainingProjectTaskBillableEffort") {
                      const lhsValue = lhs[this.model.sortKey] === "N/A" ? 0 : lhs[this.model.sortKey];
                      const rhsValue = rhs[this.model.sortKey] === "N/A" ? 0 : rhs[this.model.sortKey];
                      return (this.model.sortDescending ? lhsValue < rhsValue : lhsValue > rhsValue) ? 1 : -1;
                  } else {
                      return (this.model.sortDescending ? lhs[this.model.sortKey] < rhs[this.model.sortKey] : lhs[this.model.sortKey] > rhs[this.model.sortKey]) ? 1 : -1;
                  }
              })
            : this.filteredTasks;
    }

    // #endregion Sorting

    // #region Create New Task Assignment

    public get canDisplayAssignTask(): boolean {
        return this.assignTaskViewModel !== null;
    }

    /**
     * Displays a new task assignment.
     */
    public displayAssignTaskCommand = new RelayCommand((projectTaskId: string) => {
        this.assignTaskViewModel = new AssignTaskViewModel(projectTaskId, new AssignTaskModel(), this.submitAssignTaskCommand, this.cancelAssignTaskCommand);
    });

    /**
     * Handles the result of saving a new task assignment.
     *
     * Having assigned a task, refesh the table.
     */
    public submitAssignTaskCommand = new RelayCommand((projectTaskId: string) => {
        const _ = this.apiClient.sendAsync(new GETUnassignedProjectTasksEndpoint(this));
        this.assignTaskViewModel = null;
    });

    /**
     * Handles cancelling a new task assignment.
     */
    public cancelAssignTaskCommand = new RelayCommand(() => {
        this.assignTaskViewModel = null;
    });

    // #endregion Create New Task Assignment
}
