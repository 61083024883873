import { Callout, IStyle, Link, mergeStyleSets } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React from "react";

import { theme } from "Application";
import { pxToRem } from "Styles/BaseStyleSheet";
import { CopyIcon } from "../../../Assets/Icons/CopyIcon";
import { SmallEmailIcon } from "../../../Assets/Icons/SmallEmailIcon";
import { SmallPhoneIcon } from "../../../Assets/Icons/SmallPhoneIcon";

export interface ICalloutState {
    isCalloutVisble: boolean;
    isEmail: boolean;
    targetId: string;
    data: string;
}

export interface IContactCalloutStyles {
    root: IStyle;
    divider: IStyle;
    row: IStyle;
}

export interface IContactCalloutProps {
    onDismiss: () => void;
    targetId: string;
    isEmail: boolean;
    data: string;
}

const callOutStyles = {
    root: {},
    divider: {
        borderBottom: "1px solid #E1E1E1",
    },
    row: {
        alignItems: "center",
        display: "flex",
        height: pxToRem(30),
        margin: `${pxToRem(5)} ${pxToRem(10)}`,
        minWidth: pxToRem(80),
    },
    data: {
        marginLeft: pxToRem(10),
        fontFamily: theme.fontStyles.tableHeader.fontFamily ?? theme.defaultFontStyle.fontFamily,
        fontSize: pxToRem(10), //theme.fontStyles.tableHeader.fontSize ?? theme.defaultFontStyle.fontSize,
    },
    copyData: {
        cursor: "pointer",
        marginLeft: pxToRem(10),
        fontFamily: theme.fontStyles.tableHeader.fontFamily ?? theme.defaultFontStyle.fontFamily,
        fontSize: pxToRem(10), //theme.fontStyles.tableHeader.fontSize ?? theme.defaultFontStyle.fontSize,
    },
};

export const ContactCallout: React.FC<IContactCalloutProps> = observer((props) => {
    const styles = mergeStyleSets(callOutStyles);

    const onCopyToClipboardClick = () => {
        navigator.clipboard.writeText(props.data);
        props.onDismiss();
    };

    const getLabelIcon = () => {
        return props.isEmail ? <SmallEmailIcon /> : <SmallPhoneIcon />;
    };

    const getCopyLabel = () => {
        return props.isEmail ? "Copy email to clipboard" : "Copy number to clipboard";
    };

    const getLink = () => {
        return <Link href={props.isEmail ? `mailto:${props.data}` : `tel:${props.data}`}>{getLabelIcon()}</Link>;
    };

    return (
        <Callout className={styles.root} onDismiss={props.onDismiss} target={`#${props.targetId}`}>
            <div className={styles.row}>
                {getLink()}
                <div className={styles.data}>{props.data}</div>
            </div>
            <div className={styles.divider} />
            <div className={styles.row}>
                <CopyIcon />
                <div className={styles.copyData} onClick={onCopyToClipboardClick}>
                    {getCopyLabel()}
                </div>
            </div>
        </Callout>
    );
});
