import { ITextStyles, Text } from "@fluentui/react";
import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { PropsWithChildren } from "react";
import { themeColourOptions, themeFontStyleOptions, CommonColours, themePaletteOptions } from "../../../../../Styles/IShoothillTheme";

/**
 * Text interface.
 */
export interface ITextBaseProps {
    /**
     * An optional class name for use with the text.
     */
    className?: string;
    /**
     * Styling of the text.
     */
    styles?: ITextStyles;
    /**
     * The text variant.
     */
    fontStyle?: themeFontStyleOptions;
    /**
     * The color of the text - use this if using the generic text.
     */
    color?: themeColourOptions;
}

export const TextBase: React.FC<PropsWithChildren<ITextBaseProps>> = observer((props: PropsWithChildren<ITextBaseProps>) => {
    // #region Code Behind

    const getClasseNames = () => {
        return clsx({
            [props.className!]: !isEmptyOrWhitespace(props.className),
        });
    };

    const getStyles = (): ITextStyles | undefined => {
        return !isNullOrUndefined(props.styles) ? props.styles : undefined;
    };

    // #endregion Code Behind

    return (
        <Text styles={getStyles()} className={getClasseNames()}>
            {props.children}
        </Text>
    );
});

TextBase.defaultProps = {
    color: "black",
    //fontStyle: "h3",
};
