import { IStyle, mergeStyleSets } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React from "react";

import { Box, ThemedCheckBox, ThemedText, theme } from "Application";
import { ServiceTaskViewModel } from "./ServiceTaskViewModel";
import styled from "@emotion/styled";

interface IServiceTaskViewStyles {
    root: IStyle;
}

interface IServiceTaskViewProps {
    viewModel: ServiceTaskViewModel;
}

const styleDefinitions: IServiceTaskViewStyles = {
    root: {
        alignItems: "center",
        backgroundColor: "#FCFCFC",
        borderLeft: "5px solid #ff4b22",
        display: "flex",
        paddingLeft: "10px",
        marginBottom: "10px",
        justifyContent: "space-between",
    },
};

const InfoIconContainer = styled.div`
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: ${theme.palette.info.main};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
`;

export const ServiceTaskView: React.FC<IServiceTaskViewProps> = observer((props) => {
    const viewModel = props.viewModel;
    const styles = mergeStyleSets(styleDefinitions);

    if (viewModel) {
        return (
            <div className={styles.root}>
                <Box display={"flex"} alignItems={"center"}>
                    <ThemedText fontStyle="h5">{viewModel.displayName}</ThemedText>
                    {viewModel.showPlanningApplicationInfoIcon && (
                        <InfoIconContainer>
                            <ThemedText color="white" fontStyle="label">
                                PA
                            </ThemedText>
                        </InfoIconContainer>
                    )}
                </Box>
                <ThemedCheckBox command={viewModel.toggleEnableServiceTask} displayName="" value={() => viewModel.getValue("enabled")} paletteColor="secondary" />
            </div>
        );
    }

    return <React.Fragment />;
});
