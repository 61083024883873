import { Modal } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React from "react";

import { Else, Show, ThemedText, theme } from "Application";
import { QuoteView } from "Views/Shared/Quote/QuoteView";
import { QuoteReadOnlyView } from "Views/Shared/Quote/QuoteReadOnlyView";
import { CustomerView } from "Views/Shared/Customer/CustomerView";
import { CustomerReadOnlyView } from "Views/Shared/Customer/CustomerReadOnlyView";
import { LetterView } from "Views/Shared/Letter/LetterView";
import { LetterReadOnlyView } from "Views/Shared/Letter/LetterReadOnlyView";
import { ServicesView } from "Views/Shared/Services/ServicesView";
import { ServicesReadOnlyView } from "Views/Shared/Services/ServiceSubViews/ServicesReadOnlyView";
import { TermsAndConditionView } from "Views/Shared/TermsAndConditions/TermsAndConditionView";
import { TermsAndConditionsViewReadOnlyView } from "Views/Shared/TermsAndConditions/TermsAndConditionsViewReadOnlyView";
import { NotesView } from "Views/Shared/Note/NotesView";
import { NotesReadOnlyView } from "Views/Shared/Note/NotesReadOnlyView";
import { ConvertToProjectView } from "./ModalViews/ConvertToProjectView";
import { DeleteConfirmationView } from "./ModalViews/DeleteConfirmationView";
import { SubmitWithNoteView } from "./ModalViews/SubmitWithNoteView";
import { QuoteFormViewModel } from "./QuoteFormViewModel";
import { ButtonClusterSubView } from "./SubViews/ButtonClusterSubView";
import { FormContainer, FormSubContainer } from "../../../../Shared/SharedComponents";
import { LostWithNoteView } from "./ModalViews/LostNoteView/LostWithNoteView";
import { ToastNotification } from "Components/ToastNotification";

export interface IQuoteFormViewProps {
    viewModel: QuoteFormViewModel;
}

export const QuoteFormView: React.FC<IQuoteFormViewProps> = observer((props) => {
    // #region Code behind

    const viewModel = props.viewModel;

    // #endregion Code behind

    const renderSubmitWithNoteModalContent = () => {
        return (
            <Modal isOpen={viewModel.canDisplaySubmitWithNote} onDismiss={() => viewModel.cancelSubmitWithNoteCommand.execute()}>
                <SubmitWithNoteView viewModel={viewModel.submitWithNoteViewModel!} />
            </Modal>
        );
    };

    const renderDeleteConfirmationModalContent = () => {
        return (
            <Modal isOpen={viewModel.canDisplayDeleteConfirmation} onDismiss={() => viewModel.cancelDeleteConfirmationCommand.execute()}>
                <DeleteConfirmationView viewModel={viewModel.deleteConfirmationViewModel!} />
            </Modal>
        );
    };

    const renderConvertToProjectModalContent = () => {
        return (
            <Modal isOpen={viewModel.canDisplayConvertToProjectConfirmation} onDismiss={() => viewModel.cancelConvertToProjectConfirmationCommand.execute()}>
                <ConvertToProjectView viewModel={viewModel.convertToProjectViewModel!} />
            </Modal>
        );
    };

    const renderLostWithNoteModalContent = () => {
        return (
            <Modal isOpen={viewModel.canDisplayLostWithNote} onDismiss={() => viewModel.cancelLostWithNoteCommand.execute()}>
                <LostWithNoteView viewModel={viewModel.lostWithNoteViewModel!} />
            </Modal>
        );
    };

    return (
        <FormContainer>
            <FormSubContainer>
                <ToastNotification show={viewModel.isAutoSaving} text={"Autosaving..."} />
                <Show if={props.viewModel.hasEditPermission}>
                    <QuoteView viewModel={viewModel.quoteViewModel} />
                    <CustomerView viewModel={viewModel.customerViewModel} />
                    <LetterView viewModel={viewModel.letterViewModel} parentPreviewLetterCommand={viewModel.previewQuoteLetterCommand} />
                    <ServicesView previewTasksAndCostsDocumentCommand={viewModel.previewTasksAndCostsDocumentCommand} viewModel={viewModel.servicesViewModel} />
                    <TermsAndConditionView viewModel={viewModel.termsAndConditionsViewModel} parentPreviewTermsAndConditionsCommand={viewModel.previewTermsAndConditionsCommand} />
                    <Show if={props.viewModel.canDisplayNotes}>
                        <NotesView viewModel={viewModel.notesViewModel} />
                    </Show>
                    <Else>
                        <QuoteReadOnlyView viewModel={viewModel.quoteViewModel} />
                        <CustomerReadOnlyView viewModel={viewModel.customerViewModel} />
                        <LetterReadOnlyView viewModel={viewModel.letterViewModel} />
                        <ServicesReadOnlyView viewModel={viewModel.servicesViewModel} />
                        <TermsAndConditionsViewReadOnlyView viewModel={viewModel.termsAndConditionsViewModel} />
                        <Show if={props.viewModel.canDisplayNotes}>
                            <Show if={props.viewModel.canAddNewNote}>
                                <NotesView viewModel={viewModel.notesViewModel} />
                                <Else>
                                    <NotesReadOnlyView viewModel={viewModel.notesViewModel} />
                                </Else>
                            </Show>
                        </Show>
                    </Else>
                </Show>
            </FormSubContainer>

            <ButtonClusterSubView viewModel={viewModel} />

            {/* Submit modal */}
            {renderSubmitWithNoteModalContent()}

            {/* Lost modal */}
            {renderLostWithNoteModalContent()}

            {/* Delete modal */}
            {renderDeleteConfirmationModalContent()}

            {/* ConvertToProject modal */}
            {renderConvertToProjectModalContent()}
        </FormContainer>
    );
});
