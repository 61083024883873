import { ILabelStyles, Label, mergeStyleSets, Stack } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import { PropsWithChildren } from "react";

import { getThemeColorOption, getThemeShapeOption, pxToRem } from "Application/Helpers";
import { ValidationLabelError } from "./ValidationLabelError";
import { theme } from "../../AppTheme";
import { ComboBoxBase, IComboBoxBaseProps } from "../../../Components";
import React from "react";
import { isEmptyOrWhitespace } from "@shoothill/core";

export const ThemedComboBox = observer((props: PropsWithChildren<IComboBoxBaseProps>) => {
    const size = theme.sizes[props.size as string] || theme.sizes.larger;
    const shape = getThemeShapeOption(props.shape);
    const labelColor = getThemeColorOption(props.labelColor);
    const showLabel = !isEmptyOrWhitespace(props.displayName) || props.showLabel;
    const labelStyle: Partial<ILabelStyles> = {
        root: {
            color: labelColor,
            fontFamily: theme.fontStyles.label.fontFamily ?? theme.defaultFontStyle.fontFamily,
            fontSize: theme.fontStyles.label.fontSize ?? theme.defaultFontStyle.fontSize,
            fontWeight: theme.fontStyles.label.fontWeight ?? theme.defaultFontStyle.fontWeight,
            letterSpacing: theme.fontStyles.label.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
            lineHeight: theme.fontStyles.label.lineHeight ?? theme.defaultFontStyle.lineHeight,
            textTransform: theme.fontStyles.label.textTransform ?? theme.defaultFontStyle.textTransform,
        },
    };

    return (
        <ComboBoxBase
            {...props}
            onRenderLabel={(isInError) => {
                if (isInError) {
                    return <ValidationLabelError labelColor="secondary" displayName={props.displayName} validationMessage={props.validationMessage} />;
                }

                return <Stack horizontal>{showLabel && <Label styles={labelStyle}>{props.displayName}</Label>}</Stack>;
            }}
            styles={mergeStyleSets(
                {
                    root: {
                        "::after": {
                            borderRadius: shape,
                            borderColor: theme.palette.field.light,
                        },
                        ":hover:hover::after:not(:focus)": {
                            borderColor: theme.palette.field.main,
                        },
                        button: {
                            borderRadius: shape,
                            right: 0,
                            width: props.shape === "round" ? size : "auto",
                        },
                    },
                    rootFocused: {
                        "::after": {
                            borderColor: theme.palette.common.focus,
                        },
                    },
                    rootHovered: {
                        "::after": {
                            borderColor: theme.palette.field.main,
                        },
                    },
                    rootPressed: {
                        "::after": {
                            borderColor: theme.palette.common.focus,
                        },
                    },
                    rootError: {
                        "::after": {
                            borderColor: theme.palette.error.light,
                        },
                        ":hover::after": {
                            borderColor: theme.palette.field.main,
                        },
                    },
                },
                {
                    root: {
                        fontFamily: theme.fontStyles.field.fontFamily ?? theme.defaultFontStyle.fontFamily,
                        fontSize: theme.fontStyles.field.fontSize ?? theme.defaultFontStyle.fontSize,
                        fontWeight: theme.fontStyles.field.fontWeight ?? theme.defaultFontStyle.fontWeight,
                        height: size,
                        letterSpacing: theme.fontStyles.field.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                        lineHeight: theme.fontStyles.field.lineHeight ?? theme.defaultFontStyle.lineHeight,
                        textTransform: theme.fontStyles.field.textTransform ?? theme.defaultFontStyle.textTransform,
                        paddingLeft: pxToRem(10),
                    },
                    errorMessage: {
                        display: "none",
                        color: theme.palette.common.error,
                        fontFamily: theme.fontStyles.error.fontFamily ?? theme.defaultFontStyle.fontFamily,
                        fontSize: theme.fontStyles.error.fontSize ?? theme.defaultFontStyle.fontSize,
                        fontWeight: theme.fontStyles.error.fontWeight ?? theme.defaultFontStyle.fontWeight,
                        letterSpacing: theme.fontStyles.error.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                        lineHeight: theme.fontStyles.error.lineHeight ?? theme.defaultFontStyle.lineHeight,
                        textTransform: theme.fontStyles.error.textTransform ?? theme.defaultFontStyle.textTransform,
                    },
                    input: {
                        color: theme.palette.field.text,
                    },
                    label: {
                        color: theme.palette.common.default,
                        fontFamily: theme.fontStyles.label.fontFamily ?? theme.defaultFontStyle.fontFamily,
                        fontSize: theme.fontStyles.label.fontSize ?? theme.defaultFontStyle.fontSize,
                        fontWeight: theme.fontStyles.label.fontWeight ?? theme.defaultFontStyle.fontWeight,
                        letterSpacing: theme.fontStyles.label.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                        lineHeight: theme.fontStyles.label.lineHeight ?? theme.defaultFontStyle.lineHeight,
                        textTransform: theme.fontStyles.label.textTransform ?? theme.defaultFontStyle.textTransform,
                    },
                    optionsContainer: {
                        fontFamily: theme.fontStyles.field.fontFamily ?? theme.defaultFontStyle.fontFamily,
                        fontSize: theme.fontStyles.field.fontSize ?? theme.defaultFontStyle.fontSize,
                        fontWeight: theme.fontStyles.field.fontWeight ?? theme.defaultFontStyle.fontWeight,
                        letterSpacing: theme.fontStyles.field.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                        lineHeight: theme.fontStyles.field.lineHeight ?? theme.defaultFontStyle.lineHeight,
                        textTransform: theme.fontStyles.field.textTransform ?? theme.defaultFontStyle.textTransform,
                        maxHeight: "336px",
                    },
                    subComponentStyles: {
                        label: labelStyle,
                    },
                },
                props.styles,
            )}
            optionStyles={mergeStyleSets(
                {
                    optionText: {
                        fontSize: theme.fontStyles.field.fontSize ?? theme.defaultFontStyle.fontSize,
                    },
                    optionTextWrapper: {
                        height: size,
                    },
                },
                props.optionStyles,
            )}
        />
    );
});

export const ThemedDefaultComboBox = observer((props: PropsWithChildren<IComboBoxBaseProps>) => {
    return (
        <ComboBoxBase
            {...props}
            styles={mergeStyleSets(
                {
                    root: {
                        "::after": {
                            borderColor: theme.palette.field.light,
                        },
                        ":hover:hover::after:not(:focus)": {
                            borderColor: theme.palette.field.main,
                        },
                        button: {
                            right: 0,
                        },
                    },
                    rootFocused: {
                        "::after": {
                            borderColor: theme.palette.common.focus,
                        },
                    },
                    rootHovered: {
                        "::after": {
                            borderColor: theme.palette.field.main,
                        },
                    },
                    rootPressed: {
                        "::after": {
                            borderColor: theme.palette.common.focus,
                        },
                    },
                    rootError: {
                        "::after": {
                            borderColor: theme.palette.error.light,
                        },
                        ":hover::after": {
                            borderColor: theme.palette.field.main,
                        },
                    },
                },
                {
                    root: {
                        fontFamily: theme.fontStyles.field.fontFamily ?? theme.defaultFontStyle.fontFamily,
                        fontSize: theme.fontStyles.field.fontSize ?? theme.defaultFontStyle.fontSize,
                        fontWeight: theme.fontStyles.field.fontWeight ?? theme.defaultFontStyle.fontWeight,
                        height: theme.sizes.larger,
                        letterSpacing: theme.fontStyles.field.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                        lineHeight: theme.fontStyles.field.lineHeight ?? theme.defaultFontStyle.lineHeight,
                        textTransform: theme.fontStyles.field.textTransform ?? theme.defaultFontStyle.textTransform,
                        paddingLeft: pxToRem(10),
                    },
                    errorMessage: {
                        color: theme.palette.common.error,
                        fontFamily: theme.fontStyles.error.fontFamily ?? theme.defaultFontStyle.fontFamily,
                        fontSize: theme.fontStyles.error.fontSize ?? theme.defaultFontStyle.fontSize,
                        fontWeight: theme.fontStyles.error.fontWeight ?? theme.defaultFontStyle.fontWeight,
                        letterSpacing: theme.fontStyles.error.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                        lineHeight: theme.fontStyles.error.lineHeight ?? theme.defaultFontStyle.lineHeight,
                        textTransform: theme.fontStyles.error.textTransform ?? theme.defaultFontStyle.textTransform,
                    },
                    input: {
                        color: theme.palette.field.text,
                    },
                    subComponentStyles: {
                        label: {
                            root: {
                                color: theme.palette.common.default,
                                fontFamily: theme.fontStyles.label.fontFamily ?? theme.defaultFontStyle.fontFamily,
                                fontSize: theme.fontStyles.label.fontSize ?? theme.defaultFontStyle.fontSize,
                                fontWeight: theme.fontStyles.label.fontWeight ?? theme.defaultFontStyle.fontWeight,
                                letterSpacing: theme.fontStyles.label.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                                lineHeight: theme.fontStyles.label.lineHeight ?? theme.defaultFontStyle.lineHeight,
                                textTransform: theme.fontStyles.label.textTransform ?? theme.defaultFontStyle.textTransform,
                            },
                        },
                    },
                    optionsContainer: {
                        fontFamily: theme.fontStyles.field.fontFamily ?? theme.defaultFontStyle.fontFamily,
                        fontSize: theme.fontStyles.field.fontSize ?? theme.defaultFontStyle.fontSize,
                        fontWeight: theme.fontStyles.field.fontWeight ?? theme.defaultFontStyle.fontWeight,
                        letterSpacing: theme.fontStyles.field.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                        lineHeight: theme.fontStyles.field.lineHeight ?? theme.defaultFontStyle.lineHeight,
                        textTransform: theme.fontStyles.field.textTransform ?? theme.defaultFontStyle.textTransform,
                    },
                },
                props.styles,
            )}
            optionStyles={mergeStyleSets(
                {
                    root: {
                        height: theme.sizes.larger,
                    },
                    optionText: {
                        fontFamily: theme.fontStyles.field.fontFamily ?? theme.defaultFontStyle.fontFamily,
                        fontSize: theme.fontStyles.field.fontSize ?? theme.defaultFontStyle.fontSize,
                    },
                },
                props.optionStyles,
            )}
        />
    );
});

// #endregion
