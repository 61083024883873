import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import React from "react";

import { AutoGrid, Box } from "Application";
import { DateViewModel } from "../Date/DateViewModel";
import { BankHolidayEventView } from "../Date/Components/BankHolidayEvent/BankHolidayEventView";
import { BankHolidayEventViewModel } from "../Date/Components/BankHolidayEvent/BankHolidayEventViewModel";
import { LeaveRequestEventViewModel } from "../Date/Components/LeaveRequestEvent/LeaveRequestEventViewModel";
import { LeaveRequestEventView } from "../Date/Components/LeaveRequestEvent/LeaveRequestEventView";
import { NoEventViewModel } from "../Date/Components/NoEvent/NoEventViewModel";
import { NoEventView } from "../Date/Components/NoEvent/NoEventView";
import { ProjectTaskAssignmentGroupEventViewModel } from "../Date/Components/ProjectTaskAssignmentGroupEvent/ProjectTaskAssignmentGroupViewModel";
import { ProjectTaskAssignmentGroupEventView } from "../Date/Components/ProjectTaskAssignmentGroupEvent/ProjectTaskAssignmentGroupEventView";
import { ProjectTaskAssignmentEventViewModel } from "../Date/Components/ProjectTaskAssignmentEvent/ProjectTaskAssignmentEventViewModel";
import { ProjectTaskAssignmentEventView } from "../Date/Components/ProjectTaskAssignmentEvent/ProjectTaskAssignmentEventView";
import { LeaveRowView } from "../Rows/LeaveRow/LeaveRowView";
import { ProjectRowView } from "../Rows/ProjectRow/ProjectRowView";
import { ProjectRowViewModel } from "../Rows/ProjectRow/ProjectRowViewModel";
import { LeaveRowViewModel } from "../Rows/LeaveRow/LeaveRowViewModel";
import { WorkCalendarViewModel } from "../../WorkCalendarViewModel";

interface IProps {
    viewModel: WorkCalendarViewModel;
}

const BodyGrid = styled(AutoGrid)`
    margin: 1px 2px 0px 1px;
    position: relative;
`;

const BodyGridPseudoBorder = styled(Box)`
    content: "";
    width: 100%;
    height: 1px;
    background-color: #8d8d8d;

    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 9999;
`;

const BodyCell = styled(Box)`
    display: flex;
    font-size: 12px;
    position: relative;
    outline: 1px solid #e1e1e1;
    margin-left: 1px;
    min-height: 47px;
`;

export const BodyView: React.FC<IProps> = observer((props) => {
    const viewModel = props.viewModel;
    const projectDatesGridSize = "1fr 5fr";

    const getCellGridArea = (index: number, totalNoOfCells: number, noOfCells: number = 1) => {
        const rowStart = Math.floor((index + totalNoOfCells) / totalNoOfCells);
        const rowEnd = "auto";
        const colStart = (index % totalNoOfCells) + 1;
        const colEnd = colStart + noOfCells;

        return `${rowStart}/${colStart}/${rowEnd}/${colEnd}`;
    };

    const getCellZindex = (noOfCells: number) => {
        return noOfCells > 1 ? "1000" : "500";
    };

    const renderEventCell = (dateViewModel: DateViewModel, index: number) => {
        const eventViewModel = dateViewModel.eventViewModel;

        switch (true) {
            case eventViewModel instanceof BankHolidayEventViewModel:
                return <BankHolidayEventView viewModel={eventViewModel as BankHolidayEventViewModel} />;

            case eventViewModel instanceof LeaveRequestEventViewModel:
                return <LeaveRequestEventView viewModel={eventViewModel as LeaveRequestEventViewModel} />;

            case eventViewModel instanceof NoEventViewModel:
                return <NoEventView viewModel={eventViewModel as NoEventViewModel} />;

            case eventViewModel instanceof ProjectTaskAssignmentEventViewModel:
                return <ProjectTaskAssignmentEventView viewModel={eventViewModel as ProjectTaskAssignmentEventViewModel} />;

            case eventViewModel instanceof ProjectTaskAssignmentGroupEventViewModel:
                return <ProjectTaskAssignmentGroupEventView viewModel={eventViewModel as ProjectTaskAssignmentGroupEventViewModel} />;

            default:
                return dateViewModel.dayOfWeek;
        }
    };

    const renderLeaveRow = (rowViewModel: LeaveRowViewModel) => {
        const dateGridSize = "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr";

        const getNumberOfCells = (dateViewModel: DateViewModel) => {
            const eventViewModel = dateViewModel.eventViewModel;

            switch (true) {
                case eventViewModel instanceof BankHolidayEventViewModel:
                case eventViewModel instanceof LeaveRequestEventViewModel:
                    return dateViewModel.numberOfDays * 2;

                default:
                    return 1;
            }
        };

        return (
            <BodyGrid dc={projectDatesGridSize} columnGap={0} rowGap={0}>
                <BodyCell>
                    <LeaveRowView />
                </BodyCell>
                <AutoGrid mc={dateGridSize} tc={dateGridSize} dc={dateGridSize} columnGap={0} rowGap={0} m={0}>
                    {rowViewModel.dateViewModels.map((dateViewModel, index) => {
                        const gridArea = getCellGridArea(index, 10, getNumberOfCells(dateViewModel));
                        const zindex = getCellZindex(getNumberOfCells(dateViewModel));

                        return <BodyCell style={{ gridArea: `${gridArea}`, zIndex: `${zindex}` }}>{renderEventCell(dateViewModel, index)}</BodyCell>;
                    })}
                </AutoGrid>
                <BodyGridPseudoBorder />
            </BodyGrid>
        );
    };

    const renderProjectRow = (rowViewModel: ProjectRowViewModel, i: number) => {
        const dateGridSize = "1fr 1fr 1fr 1fr 1fr";

        const getNumberOfCells = (dateViewModel: DateViewModel) => {
            const eventViewModel = dateViewModel.eventViewModel;

            switch (true) {
                case eventViewModel instanceof ProjectTaskAssignmentEventViewModel:
                case eventViewModel instanceof ProjectTaskAssignmentGroupEventViewModel:
                    return dateViewModel.numberOfDays;

                default:
                    return 1;
            }
        };

        const autoGridBackgroundColor = i % 2 > 0 ? "white" : "#8D8D8D10";

        return (
            <BodyGrid dc={projectDatesGridSize} columnGap={0} rowGap={0}>
                <BodyCell>
                    <ProjectRowView viewModel={rowViewModel} index={i} />
                </BodyCell>
                <AutoGrid mc={dateGridSize} tc={dateGridSize} dc={dateGridSize} columnGap={0} rowGap={0} m={0} backgroundColor={autoGridBackgroundColor}>
                    {rowViewModel.dateViewModels.map((dateViewModel, index) => {
                        const gridArea = getCellGridArea(index, 5, getNumberOfCells(dateViewModel));
                        const zindex = getCellZindex(getNumberOfCells(dateViewModel));

                        return <BodyCell style={{ gridArea: `${gridArea}`, zIndex: `${parseInt(zindex) - i}` }}>{renderEventCell(dateViewModel, index)}</BodyCell>;
                    })}
                </AutoGrid>
                <BodyGridPseudoBorder />
            </BodyGrid>
        );
    };

    return (
        <React.Fragment>
            {/* If any leave information is available, render it in one row. */}
            {viewModel.canDisplayLeaveRow && renderLeaveRow(viewModel.leaveRowViewModel!)}

            {/* If one or more projects are available, render each as a row. */}
            {viewModel.projectRowViewModels.map((vm, i) => renderProjectRow(vm, i))}
        </React.Fragment>
    );
});
