import { ModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

export class LeaveRowModel extends ModelBase<LeaveRowModel> {
    public title: string = "Leave and Bank Holidays";

    constructor() {
        super();

        makeObservable(this, {});
    }
}
