import { runInAction } from "mobx";
import { Endpoint, EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { EnquiryModel } from "Views/NewBusiness/Enquiries/Form/EnquiryModel";
import { OverviewViewModel } from "../Overview/OverviewViewModel";
import { EnquiryLiteModel } from "Views/Shared/Enquiry/EnquiryLiteModel";
import { QuoteTaskLiteModel } from "Views/Shared/Quote/QuoteTaskLiteModel";
import { ProjectTaskLiteModel } from "Views/Shared/Project/ProjectTaskLiteModel";
import { ProjectLiteModel } from "Views/Shared/Project/ProjectLiteModel";
import { QuoteLiteModel } from "Views/Shared/Quote/QuoteLiteModel";

class Request {
    filterStartDate: Date | null = null;
    filterEndDate: Date | null = null;
}

class Response {
    public enquiryLites: EnquiryLiteResponse[] = [];
    public quoteLites: QuoteLiteResponse[] = [];
    public workWon: QuoteLiteResponse[] = [];
}

class EnquiryLiteResponse {
    public id: string | null = null;
    public title: string | null = null;
    public enquiryTypeId: string | null = null;
    public enquiryStatusId: string | null = null;
    public projectId: string | null = null;
    public projectTypeId: string | null = null;
    public estimatedCost: number | null = null;
    public isQuote: boolean | null = null;
    public isLost: boolean | null = null;
    public createdDate: Date | null = null;
}
class QuoteLiteResponse {
    public id: string | null = null;
    public title: string | null = null;
    public isProject: boolean | null = null;
    public projectId: string | null = null;
    public projectTypeId: string | null = null;
    public quoteValue: number | null = null;
    public quoteConvertedToProjectDate: Date | null = null;
}

export class GETAdminRunningTotalsByGivenDatesEndpoint extends Endpoint<Request, Response> {
    private viewModel: OverviewViewModel;

    constructor(viewModel: OverviewViewModel) {
        super();
        this.viewModel = viewModel;
        this.verb(Http.Post);
        this.path(AppUrls.Server.Dashboard.Admin.GetRunningTotalsByGivenDates);
    }

    public async HandleRequestAsync(): Promise<any> {
        const request = new Request();
        request.filterStartDate = this.viewModel.model.startDateFilter;
        request.filterEndDate = this.viewModel.model.endDateFilter;
        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);
        //to do extend endpoint mapping
        runInAction(() => {
            const enquiryLiteModels: EnquiryLiteModel[] = [];

            response.enquiryLites.forEach((response) => {
                const model = new EnquiryLiteModel();
                model.toModel(response);
                enquiryLiteModels.push(model);
            });

            this.viewModel.model.enquiryLites.replace(enquiryLiteModels);

            const quoteLiteModels: QuoteLiteModel[] = [];

            response.quoteLites.forEach((response) => {
                const model = new QuoteLiteModel();
                model.toModel(response);
                quoteLiteModels.push(model);
            });

            this.viewModel.model.quoteLites.replace(quoteLiteModels);

            const workWonItems: QuoteLiteModel[] = [];

            response.workWon.forEach((response) => {
                const model = new QuoteLiteModel();
                model.toModel(response);
                workWonItems.push(model);
            });

            this.viewModel.model.workWonItems.replace(workWonItems);
        });
    }
}
