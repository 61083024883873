import { FieldType, isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { computed, makeObservable, observable, observe } from "mobx";
import { container } from "tsyringe";

import { APIClient } from "Application";
import { ICommand, ICommandAsync, RelayCommand, RelayCommandAsync } from "Application/Commands";
import { AppUrls } from "AppUrls";
import { LookupStore } from "Stores/Domain";
import { FindAddressViewModel } from "Views/Shared/FindAddress/FindAddressViewModel";
import { FilesViewModel } from "Views/Shared/Files/FilesViewModel";
import { SupplierContactViewModel } from "./SupplierContacts/SupplierContactViewModel";
import { SupplierModel, SupplierModelValidator } from "./SupplierModel";
import { SupplierContactModel } from "./SupplierContacts/SupplierContactModel";
import { POSTSaveSupplierAndContactsEndpoint } from "./POSTSaveSupplierAndContactsEndpoint";
import { GETSupplierByIdEndpoint } from "./GETSupplierByIdEndpoint";

export class SupplierViewModel extends ViewModelBase<SupplierModel> {
    public apiClient = new APIClient();
    private lookupStore = container.resolve(LookupStore);

    public findAddressViewModel: FindAddressViewModel;
    public supplierContactViewModels = observable<SupplierContactViewModel>([]);
    public newSupplierContactViewModel: SupplierContactViewModel | null = null;
    public filesViewModel = new FilesViewModel();

    // #region Constructors and Disposers

    constructor(supplierId: string | undefined) {
        super(new SupplierModel());

        this.findAddressViewModel = new FindAddressViewModel(this.updateAddressCommand);

        this.setValidator(new SupplierModelValidator());

        makeObservable(this, {
            // Observables
            newSupplierContactViewModel: observable,
            supplierContactViewModels: observable,

            // Computed
            canDisplayNewSupplierContact: computed,
            canDisplaySupplierContacts: computed,
            canDisplaySupplierSubTypes: computed,
            displayName: computed,
            supplierSubTypeOptions: computed,
        });

        // Server Actions
        switch (true) {
            case !supplierId:
            case supplierId === "new":
                // A new supplier has no contacts, so display the new contact view.
                this.newSupplierContactViewModel = new SupplierContactViewModel(null, this.addSupplierContactCommand, this.cancelSupplierContactCommand, null);
                break;

            default:
                this.apiClient.sendAsync(new GETSupplierByIdEndpoint(supplierId!, this));
        }
    }

    public dispose = (): void => {
        this.supplierContactsObserverDispose?.();
    };

    // #endregion Constructors and Disposers

    // #region Properties

    public get KEY(): string {
        return this.model.KEY;
    }

    public get displayName() {
        return isEmptyOrWhitespace(this.model.id) ? "New supplier" : "Edit supplier";
    }

    /**
     * Returns a collection of contact types.
     */
    public get contactTypeOptions() {
        return this.lookupStore.getContactTypes;
    }

    public get supplierTypeOptions() {
        const options = this.lookupStore.getSupplierTypes;

        return [{ key: "", text: "Please select" }, ...options];
    }

    public get supplierSubTypeOptions() {
        const options = this.lookupStore.getSupplierSubTypes.filter((item) => item.supplierTypeId === this.model.supplierTypeId);

        return [{ key: "", text: "Please select" }, ...options];
    }

    public get canDisplayNewSupplierContact(): boolean {
        return this.newSupplierContactViewModel !== null;
    }

    public get canDisplaySupplierContacts(): boolean {
        return this.newSupplierContactViewModel !== null || this.supplierContactViewModels.length > 0;
    }

    public get canDisplaySupplierSubTypes(): boolean {
        return this.lookupStore.getSupplierSubTypes.some((item) => item.supplierTypeId === this.model.supplierTypeId);
    }

    // #endregion Properties

    // #region Commands

    public navigateToSupplierCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.Suppliers.Table);
    });

    public saveSupplierCommand: ICommand = new RelayCommand(
        () => {
            switch (true) {
                case this.canSubmitForm:
                    this.apiClient.sendAsync(new POSTSaveSupplierAndContactsEndpoint(this), this.model);
                    break;

                // If there is a validation error on supplier contacts, request a new supplier contact be added.
                case !isEmptyOrWhitespace(this.getError("supplierContacts")) && this.createSupplierContactCommand.canExecute():
                    this.createSupplierContactCommand.execute();
                    break;
            }
        },
        () => {
            return !this.apiClient.IsBusy;
        },
    );

    public resetServerErrorCommand = new RelayCommand(() => {
        this.apiClient.reset();
    });

    public updateContactNumberCommand: ICommandAsync = new RelayCommandAsync(async (value: number) => {
        await this.updateField("contactNumber1", value);
    });

    public updateContactNumber2Command: ICommandAsync = new RelayCommandAsync(async (value: number) => {
        await this.updateField("contactNumber2", value);
    });

    public updateContactPrimaryEmailCommand: ICommandAsync = new RelayCommandAsync(async (value: number) => {
        await this.updateField("emailAddress1", value);
    });

    public updateContactSecondaryEmailCommand: ICommandAsync = new RelayCommandAsync(async (value: number) => {
        await this.updateField("emailAddress2", value);
    });

    public updateClientNoteCommand: ICommandAsync = new RelayCommandAsync(async (value: string) => {
        await this.updateField("clientNote", value);
    });

    public updateBusinessNameCommand: ICommandAsync = new RelayCommandAsync(async (value: number) => {
        await this.updateField("businessName", value);
    });

    public updateSupplierTypeCommand: ICommand = new RelayCommand((value: string | null) => {
        this.updateField("supplierTypeId", value);

        // SIDE-EFFECT. When changing the selection, we need to reset the sub-type.
        this.updateField("supplierSubTypeId", null);
    });

    public updateSupplierSubTypeCommand: ICommand = new RelayCommand((value: string | null) => {
        this.updateField("supplierSubTypeId", value);
    });

    public updateAddress1Command: ICommandAsync = new RelayCommandAsync(async (value: string) => {
        await this.updateField("address1", value);
    });

    public updateAddress2Command: ICommandAsync = new RelayCommandAsync(async (value: string) => {
        await this.updateField("address2", value);
    });

    public updateAddress3Command: ICommandAsync = new RelayCommandAsync(async (value: string) => {
        await this.updateField("address3", value);
    });

    public updateCityCommand: ICommandAsync = new RelayCommandAsync(async (value: string) => {
        await this.updateField("city", value);
    });

    public updatePostalCodeCommand: ICommandAsync = new RelayCommandAsync(async (value: string) => {
        await this.updateField("postcode", value.toUpperCase());
    });

    public updateAddressCommand = new RelayCommand((value: any, postcode: string) => {
        // Address information returned depends on the api used.
        // So for the moment, not using an interface on this.
        const siteAddress1 = value?.formatted_address?.[0];
        const siteAddress2 = value?.formatted_address?.[1];
        const siteAddress3 = value?.formatted_address?.[2];
        const siteCity = value?.formatted_address?.[3];
        const postCode = postcode;

        this.updateField("address1", !isEmptyOrWhitespace(siteAddress1) ? siteAddress1 : null);
        this.updateField("address2", !isEmptyOrWhitespace(siteAddress2) ? siteAddress2 : null);
        this.updateField("address3", !isEmptyOrWhitespace(siteAddress3) ? siteAddress3 : null);
        this.updateField("city", !isEmptyOrWhitespace(siteCity) ? siteCity : null);
        this.updateField("postcode", !isEmptyOrWhitespace(postCode) ? postCode : null);
    });

    public createSupplierContactCommand: ICommand = new RelayCommand(
        () => {
            this.newSupplierContactViewModel = new SupplierContactViewModel(null, this.addSupplierContactCommand, this.cancelSupplierContactCommand, null);
        },
        () => {
            return this.newSupplierContactViewModel === null;
        },
    );

    public addSupplierContactCommand: ICommand = new RelayCommand((customerContact: SupplierContactModel) => {
        this.model.supplierContacts.push(customerContact);
        this.newSupplierContactViewModel = null;
    });

    public cancelSupplierContactCommand: ICommand = new RelayCommand(() => {
        this.newSupplierContactViewModel = null;
    });

    public removeSupplierContactCommand: ICommand = new RelayCommand((supplierContact: SupplierContactModel) => {
        const supplierContactToRemove = this.model.supplierContacts.find((m) => m.KEY === supplierContact.KEY);

        if (supplierContactToRemove) {
            this.model.supplierContacts.remove(supplierContactToRemove);

            // SIDE-EFFECT. Only record contacts with a identifier, as those without do not exist
            // in the database and therefore cannot be deleted.
            if (!isEmptyOrWhitespace(supplierContactToRemove.id)) {
                this.model.deletedSupplierContacts.push(supplierContactToRemove);
            }
        }
    });

    // #endregion Commands

    // #region Supporting

    private supplierContactsObserverDispose = observe(this.model.supplierContacts, (supplierContactModelChanges: any) => {
        for (const addedSupplierContact of supplierContactModelChanges.added) {
            this.supplierContactViewModels.push(new SupplierContactViewModel(addedSupplierContact, null, null, this.removeSupplierContactCommand));
        }

        for (const removedSupplierContact of supplierContactModelChanges.removed) {
            const supplierContactViewModelToRemove = this.supplierContactViewModels.find((vm) => vm.model.KEY === removedSupplierContact.KEY);

            if (supplierContactViewModelToRemove) {
                this.supplierContactViewModels.remove(supplierContactViewModelToRemove);
            }
        }
    });

    private async updateField(fieldName: keyof FieldType<SupplierModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    public get canSubmitForm(): boolean {
        const isFormValid = this.isModelValid();
        const isContactsValid = !this.supplierContactViewModels.some((vm) => !vm.isModelValid());

        return isFormValid && isContactsValid;
    }

    // #endregion Supporting
}
