import _ from "lodash";

import { ProjectLite, Service, ServiceGroup, SignatoryUser } from "Application/Models/Domain";
import { MODELID_CURRENTQUOTE } from "Views/Shared/Constants";
import { CustomerContactItemModel } from "Views/Shared/Customer/CustomerContactItemModel";
import { CustomerItemModel } from "Views/Shared/Customer/CustomerItemModel";
import { FileModel } from "Views/Shared/Files/FileModel";
import { LetterTemplateItemModel } from "Views/Shared/Letter/LetterTemplateItemModel";
import { NoteModel } from "Views/Shared/Note/NoteModel";
import { RevisionModel } from "Views/Shared/Revisions/RevisionModel";
import { TaskGroupModel } from "Views/Shared/Services/ServiceSubViews/TaskGroupModel";
import { TaskModel } from "Views/Shared/Services/ServiceSubViews/TaskModel";
import { TermsAndConditionItemModel } from "Views/Shared/TermsAndConditions/TermsAndConditionItemModel";
import { QuoteContainerViewModel } from "../../QuoteContainerViewModel";
import { User } from "Application/Models/Domain/User";
import { runInAction } from "mobx";

export class QuoteResponse extends Response {
    // #region Response Data

    public id: string = "";
    public reference: string = "";
    public revision: string | null = null;

    public quoteStatusId: string = "";
    public enquiryId: string | null = null;
    public projectTypeId: string = "";
    public contactTypeId: string = "";
    public customerId: string = "";
    public customerContactId: string | null = null;
    public projectId: string = "";

    public sameAsBusinessAddress: boolean = false;
    public siteAddress1: string | null = null;
    public siteAddress2: string | null = null;
    public siteAddress3: string | null = null;
    public siteCity: string | null = null;
    public sitePostcode: string | null = null;

    public title: string = "";
    public leadInWeeks: number | null = null;
    public durationWeeks: number | null = null;
    public confidence: number | null = null;
    public desirability: number | null = null;

    public letterTemplateId: string | null = null;
    public letterDetails: string | null = null;

    public termAndConditionId: string | null = null;
    public termsAndConditionsDetails: string | null = null;
    public signatoryUserId: string | null = null;

    public isDraft: boolean = false;
    public isLost: boolean = false;
    public isProject: boolean = false;
    public isQuickQuote: boolean = false;

    public lostNoteSubTypeId: string | null = null;
    public lostNoteDetail: string | null = null;

    public quoteDate: Date | null = null;
    public approvedDate: Date | null = null;

    customers: CustomerResponse[] = [];
    customerContacts: CustomerContactResponse[] = [];
    quoteTasks: QuoteTaskResponse[] = [];
    notes: NoteResponse[] = [];
    noteDocuments: NoteDocumentResponse[] = [];
    revisions: RevisionResponse[] = [];
    letterTemplates: LetterTemplateResponse[] = [];
    termsAndConditions: TermsAndConditionResponse[] = [];
    projects: ProjectResponse[] = [];
    serviceGroups: ServiceGroupResponse[] = [];
    services: ServiceResponse[] = [];
    projectTypes: ProjectLite[] = [];
    signatoryUsers: SignatoryUserResponse[] = [];
    quoteLeads: User[] = [];
    approvedByUser: User | null = null;
    // #endregion Response Data

    // #region Response Part Mappings to ViewModel

    public static revisionResponsePart(response: QuoteResponse, viewModel: QuoteContainerViewModel) {
        // Historical revisions.
        const revisionModels: RevisionModel[] = [];

        for (const revision of response.revisions) {
            const revisionModel = new RevisionModel();

            revisionModel.id = revision.id;
            revisionModel.title = revision.title;
            revisionModel.revision = revision.revision;
            revisionModel.modifiedDate = revision.revisionDate;

            revisionModels.push(revisionModel);
        }

        // Current revision.
        const currentRevisionModel = new RevisionModel();

        currentRevisionModel.id = MODELID_CURRENTQUOTE;
        currentRevisionModel.title = response.title;
        currentRevisionModel.revision = response.revision ?? "";
        currentRevisionModel.modifiedDate = new Date();

        revisionModels.push(currentRevisionModel);

        viewModel.model.revisions.replace(revisionModels);
    }

    public static quoteResponsePart(response: QuoteResponse, viewModel: QuoteContainerViewModel) {
        viewModel.quoteFormViewModel.quoteViewModel.model.fromResponse(response);
        viewModel.quoteFormViewModel.quoteViewModel.model.isQuickQuote = response.isQuickQuote;

        // Process the response Projects.
        viewModel.quoteFormViewModel.quoteViewModel.model.projectsList.replace(
            response.projects.map((project) => {
                const model: ProjectLite = {
                    id: project.id,
                    title: project.title,
                    projectTypeId: project.projectTypeId,
                };

                return model;
            }),
        );
    }

    public static customerResponsePart(response: QuoteResponse, viewModel: QuoteContainerViewModel) {
        viewModel.quoteFormViewModel.customerViewModel.customers.replace(
            response.customers.map((c) => {
                const model = new CustomerItemModel();

                model.fromResponse(c);

                return model;
            }),
        );

        viewModel.quoteFormViewModel.customerViewModel.customerContacts.replace(
            response.customerContacts.map((cc) => {
                const model = new CustomerContactItemModel();

                model.fromResponse(cc);

                return model;
            }),
        );

        viewModel.quoteFormViewModel.customerViewModel.model.fromResponse(response);
        viewModel.quoteFormViewModel.customerViewModel.findAddressViewModel.model.reset();
    }

    public static letterTemplateResponsePart(response: QuoteResponse, viewModel: QuoteContainerViewModel) {
        viewModel.quoteFormViewModel.letterViewModel.model.fromResponse(response);
        viewModel.quoteFormViewModel.letterViewModel.model.isQuickQuote = response.isQuickQuote;

        viewModel.quoteFormViewModel.letterViewModel.letterTemplates.replace(
            response.letterTemplates.map((lt) => {
                const model = new LetterTemplateItemModel();

                model.fromResponse(lt);

                return model;
            }),
        );
    }

    public static tasksResponsePart(response: QuoteResponse, viewModel: QuoteContainerViewModel) {
        const groupedQuoteTasks = _.groupBy(response.quoteTasks, (quoteTask) => quoteTask.serviceGroupId);

        viewModel.quoteFormViewModel.servicesViewModel.model.serviceGroups.replace(
            response.serviceGroups.map((s) => {
                const serviceGroup = new ServiceGroup(s.id);

                serviceGroup.name = s.name;
                serviceGroup.isDeleted = s.isDeleted;
                serviceGroup.ordinal = s.ordinal;
                serviceGroup.type = s.type;

                return serviceGroup;
            }),
        );

        viewModel.quoteFormViewModel.servicesViewModel.model.services.replace(
            response.services.map((s) => {
                const service = new Service(s.id);

                service.name = s.name;
                service.isDeleted = s.isDeleted;
                service.ordinal = s.ordinal;
                service.serviceGroupId = s.serviceGroupId;
                service.isLinkedToPlanningApplication = s.isLinkedToPlanningApplication;

                return service;
            }),
        );

        viewModel.quoteFormViewModel.servicesViewModel.model.taskGroups.replace(
            Object.entries(groupedQuoteTasks).map(([serviceGroupId, quoteTasks]) => {
                const serviceGroup = viewModel.quoteFormViewModel.servicesViewModel.model.serviceGroups.find((sg) => sg.id === serviceGroupId);
                const taskGroup = new TaskGroupModel();

                taskGroup.id = serviceGroupId;
                taskGroup.taskGroupName = serviceGroup!.name;

                return taskGroup;
            }),
        );

        // Process the quote tasks into tasks and assign to the appropriate task group.
        for (const taskGroup of viewModel.quoteFormViewModel.servicesViewModel.model.taskGroups) {
            const tasks: TaskModel[] =
                Object.entries(groupedQuoteTasks)
                    .find(([serviceGroupId, quoteTasks]) => serviceGroupId === taskGroup.id)?.[1]
                    .map((qt) => {
                        const model = new TaskModel();

                        model.fromResponse(qt);

                        return model;
                    }) ?? [];

            // Create a clean set of ordinals for the tasks in the group.
            TaskGroupModel.patchOrdinals(tasks);

            // Assign to the group.
            taskGroup.tasks.replace(tasks);
        }
    }

    public static termsAndConditionsResponsePart(response: QuoteResponse, viewModel: QuoteContainerViewModel) {
        viewModel.quoteFormViewModel.termsAndConditionsViewModel.model.fromResponse(response);
        viewModel.quoteFormViewModel.termsAndConditionsViewModel.model.isQuickQuote = response.isQuickQuote;

        viewModel.quoteFormViewModel.termsAndConditionsViewModel.termsAndConditionsTemplates.replace(
            response.termsAndConditions.map((tc) => {
                const model = new TermsAndConditionItemModel();
                model.fromResponse(tc);
                return model;
            }),
        );

        // Process the response signatory users.
        viewModel.quoteFormViewModel.termsAndConditionsViewModel.model.signatoryUsers.replace(
            response.signatoryUsers.map((u) => {
                const user = new SignatoryUser();

                user.id = u.id;
                user.firstName = u.firstName;
                user.lastName = u.lastName;

                return user;
            }),
        );
    }

    public static notesResponsePart(response: QuoteResponse, viewModel: QuoteContainerViewModel) {
        const groupedNoteDocuments = _.groupBy(response.noteDocuments, (noteDocument) => noteDocument.noteId);

        viewModel.quoteFormViewModel.notesViewModel.model.notes.replace(
            response.notes.map((n) => {
                const model = new NoteModel();

                model.fromResponse(n);

                return model;
            }),
        );

        // Process the note documents assign to the appropriate note.
        for (const noteViewModel of viewModel.quoteFormViewModel.notesViewModel.noteViewModels) {
            noteViewModel.filesViewModel.model.files.replace(
                Object.entries(groupedNoteDocuments)
                    .find(([noteId, noteDocuments]) => noteId === noteViewModel.model.id)?.[1]
                    .map((nd) => {
                        const model = new FileModel();

                        model.fromResponse(nd);

                        return model;
                    }) ?? [],
            );
        }
    }

    public static remainingResponsePart(response: QuoteResponse, viewModel: QuoteContainerViewModel) {
        viewModel.quoteFormViewModel.model.fromResponse(response);
        viewModel.quoteFormViewModel.model.quote.quoteLeads.replace(
            response.quoteLeads.map((item) => {
                const domainModel = new User();

                domainModel.id = item.id;
                domainModel.firstName = item.firstName;
                domainModel.lastName = item.lastName;
                domainModel.thumbnailDocumentUrl = item.thumbnailDocumentUrl;

                return domainModel;
            }),
        );
        if (response.approvedByUser) {
            const domainModel = new User();

            domainModel.id = response.approvedByUser.id;
            domainModel.firstName = response.approvedByUser.firstName;
            domainModel.lastName = response.approvedByUser.lastName;
            domainModel.thumbnailDocumentUrl = response.approvedByUser.thumbnailDocumentUrl;

            viewModel.quoteFormViewModel.model.quote.approvedByUser = domainModel;
        }
    }

    // #endregion Response Part Mappings to ViewModel
}

class RevisionResponse {
    id: string = "";
    title: string = "";
    revision: string = "";
    revisionDate: Date | null = null;
}

class CustomerResponse {
    id: string = "";
    contactTypeId: string = "";
    contactTitleId: string | null = null;

    businessName: string | null = null;
    firstName: string | null = null;
    lastName: string | null = null;

    public contactNumber1: string | null = null;
    public contactNumber2: string | null = null;
    public emailAddress: string | null = null;
    public emailAddress2: string | null = null;

    public address1: string = "";
    public address2: string | null = null;
    public address3: string | null = null;
    public city: string = "";
    public postcode: string = "";

    public isDeleted: boolean = false;
}

class CustomerContactResponse {
    id: string = "";
    customerId: string = "";
    contactTitleId: string | null = null;

    firstName: string = "";
    lastName: string = "";

    public contactNumber1: string | null = null;
    public contactNumber2: string | null = null;
    public emailAddress: string | null = null;

    public address1: string | null = null;
    public address2: string | null = null;
    public address3: string | null = null;
    public city: string | null = null;
    public postcode: string | null = null;

    public isDeleted: boolean = false;
}

class QuoteTaskResponse {
    id: string = "";
    serviceGroupId: string = "";
    serviceId: string | null = null;
    taskName: string = "";
    manHours: number = 0;
    hourlyRate: number = 0;
    ordinal: number = 0;
}

class NoteResponse {
    id: string = "";
    noteTypeId: string = "";
    noteDate: Date | null = null;
    noteTime: string = "";
    noteDetail: string = "";
    createdDate: Date | null = null;
    createdByUserId: string = "";
    createdByUserFirstName: string = "";
    createdByUserLastName: string = "";
    lastUpdatedDate: Date | null = null;
    lastUpdatedByUserId: string = "";
    lastUpdatedByUserFirstName: string | null = null;
    lastUpdatedByUserLastName: string | null = null;
}

class NoteDocumentResponse {
    id: string = "";
    noteId: string = "";
    fileName: string = "";
    fileSizeBytes: number = 0;
    mimeType: string = "";
    documentUrl: string = "";
}

class LetterTemplateResponse {
    id: string = "";
    name: string = "";
    body: string = "";
    isDeleted: boolean = false;
}

class TermsAndConditionResponse {
    id: string = "";
    name: string = "";
    body: string = "";
    isDeleted: boolean = false;
}

class ProjectResponse {
    id: string = "";
    title: string = "";
    projectTypeId: string = "";
}

class ServiceGroupResponse {
    id: string = "";
    name: string = "";
    ordinal: number = 0;
    type: string = "";
    isDeleted: boolean = false;
}

class ServiceResponse {
    id: string = "";
    name: string = "";
    ordinal: number = 0;
    isDeleted: boolean = false;
    serviceGroupId: string = "";
    isLinkedToPlanningApplication: boolean = false;
}

class SignatoryUserResponse {
    id: string = "";
    firstName: string = "";
    lastName: string = "";
}
