import { isEmptyOrWhitespace } from "@shoothill/core";
import { runInAction } from "mobx";

import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { TaskViewModel } from "./TaskViewModel";
import { TaskModel } from "./TaskModel";
import { NotesViewModel } from "./NotesSubView/NotesViewModel";

class ProjectTaskAssignmentDocumentRequest {
    public id: string | null = null;
}

class Request {
    public id: string | null = null;
    public userId: string | null = null;
    public note: string | null = null;
    public plannedStartDate: Date | null = null;
    public plannedEndDate: Date | null = null;
    public effort: number = 0;
    public billableEffort: number | null = null;
    public isNonBillableEffort = false;
    public isReviewed = false;
    public projectTaskAssignmentStatusId: string = "";
    public reviewedNote: string = "";

    documents: ProjectTaskAssignmentDocumentRequest[] = [];
}

class Response {
    public id: string = "";
}

export class POSTSaveProjectTaskAssignmentEndpoint extends Endpoint<Request, Response> {
    private readonly viewModel: TaskViewModel;

    constructor(viewModel: TaskViewModel) {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Resources.SaveProjectTaskAssignment);
        this.AllowFileUploads();
        this.viewModel = viewModel;
    }

    public async HandleRequestAsync(model: TaskModel): Promise<any> {
        const request = new Request();
        const notesViewModel = this.viewModel.workspaceViewModels.find((vm) => vm instanceof NotesViewModel) as NotesViewModel;

        model.toRequest(request);
        request.userId = model.user!.id;
        const doucmentRequests =
            notesViewModel.filesViewModel?.model.files
                .filter((f) => !isEmptyOrWhitespace(f.id))
                .map((f) => {
                    const d = new ProjectTaskAssignmentDocumentRequest();

                    d.id = f.id;

                    return d;
                }) ?? [];

        request.documents = doucmentRequests;

        // We need to send the data to the server as form data here as we have files.
        const formData = new FormData();

        formData.append("data", JSON.stringify(request));

        for (const file of notesViewModel.filesViewModel.model?.files!) {
            formData.append(file.KEY, file.file!);
        }

        return Promise.resolve(formData);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            this.viewModel.parentSubmitCommand.execute(response.id);
        });
    }
}
