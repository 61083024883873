import { runInAction } from "mobx";

import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { CustomerItemModel } from "Views/Shared/Customer/CustomerItemModel";
import { QuoteItemModel } from "../../../../Shared/Quote/QuoteItemModel";
import { QuoteItemViewModel } from "../../../../Shared/Quote/QuoteItemViewModel";
import { QuotesAwaitingActionViewModel } from "../QuotesAwaitingActionViewModel";

class QuoteLiteResponse {
    public id: string = "";

    public quoteStatusId = "";
    public quoteStatusName = "";
    public quoteStatusForegroundColor = "";
    public contactTypeId = "";
    public contactTypeName = "";
    public customerId = "";
    public customerContactId = "";

    public reference = "";
    public title = "";
    public hours = 0;
    public value = 0;
    public confidence = 0;
    public desirability = 0;

    public contactNumber1 = "";
    public contactNumber2 = "";
    public emailAddress = "";
    public emailAddress2 = "";
    public customerName = "";
    public customerContactFirstName = "";
    public customerContactLastName = "";

    public issueDate: string | null = null;
    public revision = "";

    public letterTemplateId: string | null = null;
    public termsAndConditionsId: string | null = null;

    public createdDate: string = "";
    public lastActionDate: string | null = null;
    public lastActionByUserId: string | null = null;
    public lastActionFirstName = "";
    public lastActionLastName = "";

    public quoteLeadId: string | null = null;
    public quoteLeadFirstName: string | null = null;
    public quoteLeadLastName: string | null = null;
}

class CustomerLiteResponse {
    public id: string = "";
    public contactTypeId: string = "";
    public contactTitleId: string | null = null;

    public businessName: string | null = null;
    public prefixName: string = "";
    public firstName: string | null = null;
    public lastName: string | null = null;

    public contactNumber1: string | null = null;
    public contactNumber2: string | null = null;
    public emailAddress: string | null = null;
    public emailAddress2: string | null = null;

    public address1: string = "";
    public address2: string | null = null;
    public address3: string | null = null;
    public city: string = "";
    public postcode: string = "";
}

class QuoteWithRelatedResponse {
    public quoteLite: QuoteLiteResponse | null = null;
    public customerLite: CustomerLiteResponse | null = null;
}

class Response {
    public totalSentQuoteHours: number = 0;
    public totalSentQuoteValue: number = 0;

    public quotesWithRelatedResponses: QuoteWithRelatedResponse[] = [];
}

export class GETAllQuotesLiteAwaitingActionEndpoint extends EndpointWithoutRequest<Response> {
    private viewModel: QuotesAwaitingActionViewModel;

    constructor(viewModel: QuotesAwaitingActionViewModel) {
        super();
        this.viewModel = viewModel;
        this.verb(Http.Get);
        this.path(AppUrls.Server.Quotes.AllQuotesLiteAwaitingAction);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            const quotes: QuoteItemViewModel[] = [];

            this.viewModel.model.totalSentQuoteHours = response.totalSentQuoteHours;
            this.viewModel.model.totalSentQuoteHours = response.totalSentQuoteHours;
            this.viewModel.model.totalSentQuoteHours = response.totalSentQuoteHours;
            this.viewModel.model.totalSentQuoteValue = response.totalSentQuoteValue;

            response.quotesWithRelatedResponses.forEach((response: QuoteWithRelatedResponse) => {
                const model = new QuoteItemModel();
                const customerItemModel = new CustomerItemModel();
                customerItemModel.fromResponse(response.customerLite);

                model.fromResponse(response.quoteLite);
                model.customerItemModel = customerItemModel;

                quotes.push(
                    new QuoteItemViewModel(
                        model,
                        this.viewModel.downloadLetterDocumentCommand,
                        this.viewModel.downloadTasksAndCostsDocumentCommand,
                        this.viewModel.downloadTermsAndConditionsDocumentCommand,
                    ),
                );
            });

            this.viewModel.quotes.replace(quotes);
        });

        return Promise.resolve(response);
    }
}
