import { runInAction } from "mobx";
import { Endpoint, EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { OverviewViewModel } from "../Overview/OverviewViewModel";

import { AbsenteesViewModel } from "../Overview/Absentees/AbsenteesViewModel";
import { AbsenteeModel } from "../Overview/Absentees/AbsenteesModel";

class Request {
    filterStartDate: Date | null = null;
    filterEndDate: Date | null = null;
}
class Response {
    public absentees: AbsenteeResponse[] = [];
}

class AbsenteeResponse {
    public userId: string | null = null;
    public userFirstName: string | null = null;
    public userLastName: string | null = null;
    public userDetailsPersonaThumbnailPath: string | null = null;
    public leaveTypeId: string | null = null;
    public leaveTypename: string | null = null;
    public leaveRequestToDate: Date | null = null;
    public leaveRequestFromDate: Date | null = null;
}

export class GETAbsenteesByGivenDatesEndpoint extends Endpoint<Request, Response> {
    private viewModel: AbsenteesViewModel;

    constructor(viewModel: AbsenteesViewModel) {
        super();
        this.viewModel = viewModel;
        this.verb(Http.Post);
        this.path(AppUrls.Server.Dashboard.Admin.GetAbsenteesByGivenDates);
    }

    public async HandleRequestAsync(): Promise<any> {
        const request = new Request();
        request.filterStartDate = this.viewModel.model.startDateFilter;
        request.filterEndDate = this.viewModel.model.endDateFilter;
        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            const absenteesModels: AbsenteeModel[] = [];

            response.absentees.forEach((absentee) => {
                const model = new AbsenteeModel();
                model.fromResponse(absentee);
                absenteesModels.push(model);
            });

            this.viewModel.model.absenteeModels.replace(absenteesModels);
        });
    }
}
