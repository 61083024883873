import {
    DetailsList,
    DetailsListLayoutMode,
    IColumn,
    IDetailsColumnRenderTooltipProps,
    IDetailsHeaderProps,
    IDetailsRowProps,
    IListProps,
    IRenderFunction,
    SelectionMode,
    TooltipHost,
} from "@fluentui/react";
import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React from "react";
import { IDetailsListStyles } from "@fluentui/react/lib/components/DetailsList/DetailsList.types";
import { Box } from "../../../../General/Box";

/**
 * Button interface.
 */
export interface IDetailsListProps {
    /**
     * An optional id for use with the button.
     */
    id?: string;
    /**
     * Pass in the data items to display in the list.
     */
    items: any[];
    /**
     * Pass in the columns to display.
     */
    columns: IColumn[];
    /**
     * An optional class name for use with the button.
     */
    className?: string;
    /**
     * An optional boolean to determinbe if the header should be displayed
     */
    isHeaderVisible?: boolean;

    /**
     * An optional boolean to select a single row.
     */
    selectSingle?: boolean;
    /**
     * An optional boolean to select multiple rows.
     */
    selectMultiple?: string;
    /**
     * A callback to get the item key, to be used in the selection and on render.
     * Must be provided if sorting or filtering is enabled.
     */
    getKey?: (item: any, index?: number) => string;

    onActiveItemChanged?: (item?: any, index?: number, ev?: React.FocusEvent<HTMLElement>) => void;

    onItemInvoked?: (item?: any, index?: number, ev?: Event) => void;

    onRenderItemColumn?: (item?: any, index?: number, column?: IColumn) => React.ReactNode;

    onRenderRow?: IRenderFunction<IDetailsRowProps>;

    /**
     * Text content to display in the error message.
     */
    validationMessage?: () => string;
    /**
     * Styling of the control.
     */
    styles?: Partial<IDetailsListStyles>;
    /**
     * Callback to determine whether the list should be rendered in full, or virtualized.
     *
     * Virtualization will add and remove pages of items as the user scrolls them into the visible range.
     * This benefits larger list scenarios by reducing the DOM on the screen, but can negatively affect performance
     * for smaller lists.
     *
     * The default implementation will virtualize when this callback is not provided.
     */
    onShouldVirtualize?: (props: IListProps) => boolean;
}

export const DataTableBase: React.FC<IDetailsListProps> = observer((props) => {
    const getClassNames = () => {
        return clsx({
            [props.className!]: !isEmptyOrWhitespace(props.className),
        });
    };

    const getValidationMessage = (): string => {
        return isEmptyOrWhitespace(props.validationMessage?.() as string) ? "" : (props.validationMessage?.() as string);
    };

    /*const isDisabled = (): boolean => {
        return isNullOrUndefined(props.command.canExecute) ? false : !props.command.canExecute();
    };

    const onChange = (vent: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        props.command.execute(newValue);
    };*/

    const getStyles = (): Partial<IDetailsListStyles> | undefined => {
        return !isNullOrUndefined(props.styles) ? props.styles : undefined;
    };

    const getSelection = (): SelectionMode => {
        if (props.selectSingle) {
            return SelectionMode.single;
        } else if (props.selectMultiple) {
            return SelectionMode.multiple;
        } else {
            return SelectionMode.none;
        }
    };

    function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }

    // const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    //     const { columns, items } = this.state;
    //     const newColumns: IColumn[] = columns.slice();
    //     const currColumn: IColumn = newColumns.filter((currCol) => column.key === currCol.key)[0];
    //     newColumns.forEach((newCol: IColumn) => {
    //         if (newCol === currColumn) {
    //             currColumn.isSortedDescending = !currColumn.isSortedDescending;
    //             currColumn.isSorted = true;
    //             this.setState({
    //                 announcedMessage: `${currColumn.name} is sorted ${currColumn.isSortedDescending ? "descending" : "ascending"}`,
    //             });
    //         } else {
    //             newCol.isSorted = false;
    //             newCol.isSortedDescending = true;
    //         }
    //     });
    //     const newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
    //     this.setState({
    //         columns: newColumns,
    //         items: newItems,
    //     });
    // };

    const renderFixedDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
        if (!props) {
            return null;
        }
        const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = (tooltipHostProps) => <TooltipHost {...tooltipHostProps} />;
        return (
            <Box style={{ fontSize: "100px" }}>
                {defaultRender!({
                    ...props,
                    onRenderColumnHeaderTooltip,
                })}
            </Box>
        );
    };

    return (
        <div id={props.id}>
            <DetailsList
                className={getClassNames()}
                items={props.items}
                columns={props.columns}
                onRenderItemColumn={props.onRenderItemColumn}
                onRenderRow={props.onRenderRow}
                setKey="set"
                selectionMode={getSelection()}
                layoutMode={DetailsListLayoutMode.justified}
                //selection={this._selection}
                selectionPreservedOnEmptyClick={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="select row"
                isHeaderVisible={props.isHeaderVisible}
                getKey={props.getKey}
                onActiveItemChanged={props.onActiveItemChanged}
                onItemInvoked={props.onItemInvoked}
                styles={getStyles()}
            />
        </div>
    );
});
