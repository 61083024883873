import _ from "lodash";

import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";

export class GETQuoteTasksAndCostsDocumentByIdEndpoint extends EndpointWithoutRequest<Response> {
    private downloadAsBlob: boolean;

    constructor(quoteId: string, downloadAsBlob: boolean = false) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.Quotes.GetQuoteTasksAndCostsDocumentById.replace(":id", quoteId).replace(":downloadasblob", String(downloadAsBlob)));
        this.responseIsBlob(true);
        this.downloadAsBlob = downloadAsBlob;
    }

    public async HandleResponseAsync(response: any): Promise<any> {
        if (this.downloadAsBlob) {
            window.open(window.URL.createObjectURL(response), "_blank");
        } else {
            const contentDisposition = response.headers["content-disposition"];
            const filename = contentDisposition.split("filename=")[1];
            // Decode the filename if it's URL-encoded.
            const decodedFilename = decodeURIComponent(filename);
            // Create a link element for the file and use the filename provided.
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(new Blob([response]));
            link.setAttribute("download", decodedFilename);

            document.body.appendChild(link);

            // Download.
            link.click();

            // Having clicked the link, delete the element otherwise it will
            // remain attached to the document.
            document.body.removeChild(link);
        }

        return Promise.resolve();
    }
}
