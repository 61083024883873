import { ProjectQuoteHistoryViewModel } from "../../ProjectQuoteHistoryViewModel";
import { QuoteListItemViewModel } from "./QuoteListItemViewModel";

export class ContainerResponse extends Response {
    // #region Response Data

    public id: string = "";
    public reference: string = "";
    public revision: string | null = null;

    public quoteStatusId: string = "";
    public enquiryId: string | null = null;
    public projectTypeId: string = "";
    public contactTypeId: string = "";
    public customerId: string = "";
    public customerContactId: string | null = null;

    public sameAsBusinessAddress: boolean = false;
    public siteAddress1: string | null = null;
    public siteAddress2: string | null = null;
    public siteAddress3: string | null = null;
    public siteCity: string | null = null;
    public sitePostcode: string | null = null;

    public title: string = "";
    public leadInWeeks: number | null = null;
    public durationWeeks: number | null = null;
    public confidence: number | null = null;
    public desirability: number | null = null;

    public letterTemplateId: string | null = null;
    public letterDetails: string | null = null;

    public termAndConditionId: string | null = null;
    public termsAndConditionsDetails: string | null = null;

    public isLost: boolean = false;
    public isProject: boolean = false;
    public projectId: string = "";

    quotesList: QuotesList[] = [];

    // #endregion Response Data

    // #region Response Part Mappings to ViewModel
    public static quoteListResponsePart(response: ContainerResponse, viewModel: ProjectQuoteHistoryViewModel) {
        viewModel.quoteListViewModels.replace(
            response.quotesList.map((ql) => {
                const vm = new QuoteListItemViewModel(ql.id);
                vm.model.id = ql.id;
                vm.model.title = ql.title;
                vm.model.isMaster = ql.isMaster;
                return vm;
            }),
        );
    }

    public static remainingResponsePart(response: ContainerResponse, viewModel: ProjectQuoteHistoryViewModel) {
        viewModel.model.fromResponse(response);
    }

    // #endregion Response Part Mappings to ViewModel
}

class QuotesList {
    id: string = "";
    title: string = "";
    isMaster: boolean = false;
}
