import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { InvoiceForecastingModel } from "./InvoiceForecasting/InvoiceForecastingModel";
import { InvoiceHistoryModel } from "./InvoiceHistory/InvoiceHistoryModel";

export class InvoiceModel extends ModelBase<InvoiceModel> {
    //filtering
    public financialYearFilter: Date = new Date();

    public invoiceForecastingModel = new InvoiceForecastingModel();
    public invoiceHistoryModel = new InvoiceHistoryModel();

    constructor() {
        super();

        makeObservable(this, {
            // Observables
            // filtering
            financialYearFilter: observable,

            // model specific
            invoiceForecastingModel: observable,
            invoiceHistoryModel: observable,
        });
    }
}
