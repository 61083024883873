import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { ServiceGroupModel } from "../ServiceGroup/ServiceGroupModel";
import { ServiceGroupsViewModel } from "../ServiceGroupsViewModel";
import { ServiceGroupViewModel } from "../ServiceGroup/ServiceGroupViewModel";
import { runInAction } from "mobx";
import { SyncServiceGroupsResponse } from "./SharedResponseModels";

class Request {
    public idOne: string = "";
    public idTwo: string = "";
}

export class PostSwapServiceGroupOrdinalsEndpoint extends Endpoint<Request, SyncServiceGroupsResponse> {
    private viewModel: ServiceGroupsViewModel;

    constructor(viewModel: ServiceGroupsViewModel) {
        super();
        this.viewModel = viewModel;
        this.verb(Http.Post);
        this.path(AppUrls.Server.Admin.Services.PostSwapServiceGroupOrdinals);
    }

    HandleRequestAsync(requestModel: any): Promise<Request> {
        let request: Request = new Request();
        request.idOne = requestModel.idOne;
        request.idTwo = requestModel.idTwo;

        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: SyncServiceGroupsResponse): Promise<any> {
        runInAction(() => {
            this.viewModel.syncModels(response);
        });
    }
}
