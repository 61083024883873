import { debounce } from "@mui/material";
import { isEmptyOrWhitespace, isNullOrUndefined, ViewModelBase } from "@shoothill/core";
import { action, computed, makeObservable, observable, observe } from "mobx";

import { APIClient, formatDate, ICommand, RelayCommand } from "Application";
import { MODELID_CURRENTPROJECTUPDATE, SERVER_DEBOUNCE_PERIOD_MS } from "Views/Shared/Constants";

import { WeeklyUpdateViewModel } from "../WeeklyUpdateViewModel";
import { WeeklyUpdateHistoryViewModel } from "./History/WeeklyUpdateHistoryViewModel";
import { ProjectWeeklyUpdateContainerModel } from "./ProjectWeeklyUpdateContainerModel";
import { GETProjectWeeklyUpdateByUpdateIdEndpoint } from "../Endpoints/GETProjectWeeklyUpdateByUpdateIdEndpoint";
import { GETProjectWeeklyUpdateWithRelatedByIdEndpoint } from "../Endpoints/GETProjectWeeklyUpdateWithRelatedByIdEndpoint";
import moment from "moment";

export class ProjectWeeklyUpdateContainerViewModel extends ViewModelBase<ProjectWeeklyUpdateContainerModel> {
    public apiClient = new APIClient();

    // Hosted in its own viewmodel.
    public parentViewModel: WeeklyUpdateViewModel;
    public weeklyUpdateHistoryViewModels = observable<WeeklyUpdateHistoryViewModel>([]);
    public isWeeklyUpdateHistoryLoading: boolean = false;
    constructor(parentViewModel: WeeklyUpdateViewModel) {
        super(new ProjectWeeklyUpdateContainerModel());

        this.parentViewModel = parentViewModel;

        makeObservable(this, {
            //Observables
            isWeeklyUpdateHistoryLoading: observable,

            //Actions
            setIsWeeklyUpdateHistoryLoading: action,

            // Computed values
            weeklyUpdates: computed,
            canDisplayWeeklyUpdateHistoryLoadingComponent: computed,
        });

        this.getProjectWeeklyUpdateWithRelatedById();
    }

    //region actions
    public setIsWeeklyUpdateHistoryLoading = (value: boolean) => {
        this.isWeeklyUpdateHistoryLoading = value;
    };
    //endregion actions

    //region computed
    public get canDisplayWeeklyUpdateHistoryLoadingComponent() {
        return this.isWeeklyUpdateHistoryLoading;
    }
    //endregion computed

    public getProjectWeeklyUpdateWithRelatedById = async () => {
        await this.apiClient.sendAsync(new GETProjectWeeklyUpdateWithRelatedByIdEndpoint(this.parentViewModel.model.projectId, this)).finally(() => this.apiClient.setIsBusy(true));
        this.getLatestWeeklyUpdate();
    };

    public getLatestWeeklyUpdate = () => {
        const latestWeeklyUpdate = this.weeklyUpdates[0];

        this.navigateToWeeklyUpdateCommand.execute(latestWeeklyUpdate.key);
    };

    /**
     * Disposes of the update histories collection observer.
     */
    public dispose = (): void => {
        this.updateHistoriesObserverDispose?.();
    };

    // #region Properties

    public get isBusy() {
        return this.apiClient.IsBusy;
    }

    public get hasError() {
        return this.apiClient.IsSubmitted && !this.apiClient.IsBusy && this.apiClient.HaveValidationMessage;
    }

    //only return previous weekly updates
    public get weeklyUpdates() {
        return this.model.weeklyUpdates
            .filter((weeklyUpdate) => weeklyUpdate.id !== MODELID_CURRENTPROJECTUPDATE)
            .slice()
            .sort((a, b) => b.weekCommencingDate.getTime() - a.weekCommencingDate.getTime())
            .map((item) => {
                return {
                    key: item.id,
                    text: formatDate(item.weekCommencingDate),
                };
            });
    }

    public get weeklyUpdateHistoryViewModel() {
        const retVal = this.weeklyUpdateHistoryViewModels.find((vm) => vm.model.id === this.model.weeklyUpdateId);
        return retVal ?? null;
    }

    //copies the previous weekly update customer detail to current weekly update

    // #endregion Properties

    // #region Commands

    public cancelCommand = new RelayCommand(() => {
        this.parentViewModel.closeProjectWeeklyUpdateSidePanel();
    });

    public navigateToWeeklyUpdateCommand = new RelayCommand((value: string) => {
        this.setValue("weeklyUpdateId", value);

        if (this.model.weeklyUpdateId !== MODELID_CURRENTPROJECTUPDATE && isNullOrUndefined(this.model.updateHistories.find((m) => m.id === this.model.weeklyUpdateId))) {
            this.getWeeklyUpdate(this.model.weeklyUpdateId!);
        } else {
            this.apiClient.setIsBusy(false);
        }
    });

    public resetServerErrorCommand = new RelayCommand(() => {
        this.apiClient.reset();
    });

    // #endregion Commands

    // #region Supporting

    private getWeeklyUpdate = debounce(
        action(async (weeklyUpdateId: string) => {
            if (!isEmptyOrWhitespace(weeklyUpdateId) && weeklyUpdateId !== MODELID_CURRENTPROJECTUPDATE) {
                await this.apiClient.sendAsync(new GETProjectWeeklyUpdateByUpdateIdEndpoint(weeklyUpdateId, this), this.model);
            }
        }),
        SERVER_DEBOUNCE_PERIOD_MS,
    );

    private updateHistoriesObserverDispose = observe(this.model.updateHistories, (updateHistoryModelChanges: any) => {
        for (const addedUpdateHistory of updateHistoryModelChanges.added) {
            this.weeklyUpdateHistoryViewModels.push(new WeeklyUpdateHistoryViewModel(addedUpdateHistory));
        }

        for (const removedUpdateHistory of updateHistoryModelChanges.removed) {
            const updateHistoryViewModelToRemove = this.weeklyUpdateHistoryViewModels.find((vm) => vm.model.KEY === removedUpdateHistory.KEY);

            if (updateHistoryViewModelToRemove) {
                this.weeklyUpdateHistoryViewModels.remove(updateHistoryViewModelToRemove);
            }
        }
    });

    // #endregion Supporting
}
