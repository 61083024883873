import { isEmptyOrWhitespace, isNullOrUndefined, ViewModelBase } from "@shoothill/core";
import { computed, makeObservable, observable } from "mobx";
import { container } from "tsyringe";

import { APIClient, ICommand, RelayCommand } from "Application";
import { LookupStore } from "Stores/Domain";
import { ProjectItemViewModel } from "Views/Shared/Project/ProjectItemViewModel";
import { AppUrls } from "AppUrls";
import { MyProjectsModel } from "./MyProjectsModel";
import { GETAllProjectsLiteByProjectLeadIdEndpoint } from "../Endpoints/GetAllProjectsLiteByProjectLeadIdEndpoint";

export class MyProjectsViewModel extends ViewModelBase<MyProjectsModel> {
    public apiClient = new APIClient();
    private lookupStore = container.resolve(LookupStore);

    public projects = observable<ProjectItemViewModel>([]);

    constructor() {
        super(new MyProjectsModel());

        makeObservable(this, {
            // Observables
            projects: observable,

            // Computed values
            canFilterProjects: computed,
            filteredProjects: computed,
            canSortProjects: computed,
            filteredAndSortedProjects: computed,
        });

        this.apiClient.sendAsync(new GETAllProjectsLiteByProjectLeadIdEndpoint(this));
    }

    // #region user roles
    public get isAdmin() {
        return this.lookupStore.AccountStore.isInRole("admin");
    }

    public get isSeniorAssociate() {
        return this.lookupStore.AccountStore.isInRole("seniorassociate");
    }

    public get isArchitect() {
        return this.lookupStore.AccountStore.isInRole("architect");
    }

    public get showNewProjectButton() {
        return this.isAdmin || this.isSeniorAssociate;
    }

    public get canNavigateToProjectDetail() {
        return this.isAdmin || this.isSeniorAssociate;
    }
    // #endregion user roles

    // #region Filtering

    public updateFilterKeywordCommand = new RelayCommand((keyword: string) => {
        this.model.filterKeyword = keyword;
    });

    public updateFilterProjectStatusCommand = new RelayCommand((statusId: string | null) => {
        this.model.filterProjectStatusId = statusId;
    });

    public updateFilterFromDateComand = new RelayCommand((date: Date | undefined) => {
        this.model.filterFromDate = date;
    });

    public updateFilterToDateCommand = new RelayCommand((date: Date | undefined) => {
        this.model.filterToDate = date;
    });

    public updateFilterProjectLeadCommand = new RelayCommand((id: string | null) => {
        this.model.filterProjectLeadId = id;
    });

    public updateFilterSeniorAssociateCommand = new RelayCommand((id: string | null) => {
        this.model.filterSeniorAssociateId = id;
    });

    public get projectStatuses() {
        return [{ key: "", text: "All statuses" }, ...this.lookupStore.getProjectStatuses()];
    }

    public get projectLeads() {
        const options = this.projects
            .filter((p) => p.model.projectLeadId && p.model.projectLeadFirstName && p.model.projectLeadLastName)
            .map((p) => {
                return {
                    key: p.model.projectLeadId!,
                    text: `${p.model.projectLeadFirstName} ${p.model.projectLeadLastName}`,
                };
            })
            .sort((a, b) => (a.text > b.text ? 1 : b.text > a.text ? -1 : 0));

        const distinctOptions = options.filter((option, index, arr) => arr.findIndex((t) => t.key === option.key) === index);

        return [{ key: "", text: "All leads" }, ...distinctOptions];
    }

    public get seniorAssociates() {
        const options = this.projects
            .filter((p) => p.model.seniorAssociateId && p.model.seniorAssociateFirstName && p.model.seniorAssociateLastName)
            .map((t) => {
                return {
                    key: t.model.seniorAssociateId!,
                    text: `${t.model.seniorAssociateFirstName} ${t.model.seniorAssociateLastName}`,
                };
            })
            .sort((a, b) => (a.text > b.text ? 1 : b.text > a.text ? -1 : 0));

        const distinctOptions = options.filter((option, index, arr) => arr.findIndex((t) => t.key === option.key) === index);

        return [{ key: "", text: "All project overviews" }, ...distinctOptions];
    }

    // public get

    public get canFilterProjects(): boolean {
        return (
            !isEmptyOrWhitespace(this.model.filterKeyword) ||
            !isNullOrUndefined(this.model.filterFromDate) ||
            !isNullOrUndefined(this.model.filterToDate) ||
            !isEmptyOrWhitespace(this.model.filterProjectStatusId) ||
            !isEmptyOrWhitespace(this.model.filterProjectLeadId) ||
            !isEmptyOrWhitespace(this.model.filterSeniorAssociateId)
        );
    }

    public get filteredProjects(): ProjectItemViewModel[] {
        return this.canFilterProjects
            ? this.projects.filter((vm) =>
                  vm.filterPredicate(
                      this.model.filterKeyword,
                      this.model.filterFromDate,
                      this.model.filterToDate,
                      this.model.filterProjectStatusId,
                      this.model.filterProjectLeadId,
                      this.model.filterSeniorAssociateId,
                  ),
              )
            : this.projects;
    }

    // #endregion Filtering

    // #region Sorting

    public updateSortCommand = new RelayCommand((key: string, sortDescending: boolean) => {
        this.model.sortKey = key;
        this.model.sortDescending = sortDescending;
    });

    public get canSortProjects(): boolean {
        return !isEmptyOrWhitespace(this.model.sortKey);
    }

    public get filteredAndSortedProjects(): ProjectItemViewModel[] {
        return this.canSortProjects
            ? this.filteredProjects
                  .slice()
                  .sort((lhs, rhs) =>
                      (this.model.sortDescending ? lhs[this.model.sortKey] < rhs[this.model.sortKey] : lhs[this.model.sortKey] > rhs[this.model.sortKey]) ? 1 : -1,
                  )
            : this.filteredProjects;
    }

    // #endregion Sorting

    // #region Navigation

    public navigateToProjectCommand = new RelayCommand((vm: ProjectItemViewModel, index?: number, ev?: React.FocusEvent<HTMLElement>) => {
        this.history.push(AppUrls.Client.Projects.Details.replace(":id", vm.model.id));
    });

    public navigateToProjectByIdCommand = new RelayCommand((id: string) => {
        this.history.push(AppUrls.Client.Projects.Details.replace(":id", id));
    });

    public navigateToCustomerDetailCommand = new RelayCommand((id: string) => {
        this.history.push(AppUrls.Client.Customers.Details.replace(":id", id));
    });
    // #endregion Navigation
}
