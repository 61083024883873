import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { nanoid } from "nanoid";

import { ServiceTaskModel } from "./ConfigureServiceSubViews/ServiceTaskModel";

export class ConfigureServicesModel extends ModelBase<ConfigureServicesModel> {
    public KEY: string = nanoid();

    public serviceGroupId: string = "";
    public taskGroupName: string = "";
    public serviceTasks = observable<ServiceTaskModel>([]);
    public customServiceTasks = observable<ServiceTaskModel>([]);
    public customServiceTaskName: string = "";

    constructor() {
        super();
        makeObservable(this, {
            // Observables
            customServiceTaskName: observable,
        });
    }
}
