import { observer } from "mobx-react-lite";
import React from "react";

import { AutoGrid, ThemedButton } from "Application";
import { UserViewModel } from "../UserViewModel";

export interface IButtonClusterSubViewProps {
    viewModel: UserViewModel;
}

/**
 * Displays a group of buttons for the enquiry form.
 */
export const ButtonClusterSubView: React.FC<IButtonClusterSubViewProps> = observer((props) => {
    const viewModel = props.viewModel;

    return (
        <AutoGrid
            backgroundColor="#F3F3F3"
            columnGap={"30px"}
            dc={"140px 140px 140px 140px 140px 140px"}
            m={"0"}
            padding={"15px 30px"}
            position="fixed"
            left="0"
            right="0"
            bottom="0"
            zIndex="10000"
        >
            <ThemedButton
                styles={{ root: { padding: "0 5px" } }}
                paletteColor={"primary"}
                displayName={!viewModel.apiClient.IsBusy ? "Save" : "Saving"}
                command={viewModel.saveUserCommand}
            />
            <ThemedButton styles={{ root: { padding: "0 5px" } }} displayName="Cancel" command={viewModel.cancelSaveUserCommand} />
            {viewModel.canDisplayDeleteUserCommand && (
                <ThemedButton styles={{ root: { padding: "0 5px", color: "white" } }} paletteColor={"error"} displayName="Delete" command={viewModel.deleteModalOpenCommand} />
            )}
        </AutoGrid>
    );
});
