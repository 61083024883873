import { runInAction } from "mobx";

import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { LetterTemplatesViewModel } from "./LetterTemplatesViewModel";

class Response {}

export class DeleteLetterTemplatesEndpoint extends EndpointWithoutRequest<Response> {
    private readonly viewModel: LetterTemplatesViewModel;

    constructor(id: string, viewModel: LetterTemplatesViewModel) {
        super();
        this.verb(Http.Delete);
        this.path(AppUrls.Server.Admin.LetterTemplates.DeleteLetterTemplate.replace(":id", id));
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: Response[]): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            // Navigate to the letter templates page. As this is a deleted template,
            // remove it from the browser history, so it cannot be navigated to.
            this.viewModel.history.replace(AppUrls.Client.LetterTemplates.Table);
        });
    }
}
