import { ModelBase } from "@shoothill/core";
import { Validator } from "Application/Validation";
import { makeObservable, observable } from "mobx";

export class ConfigureCustomerModel extends ModelBase<ConfigureCustomerModel> {
    public id: string | null = null;

    constructor() {
        super();
        makeObservable(this, {
            // Observables
            id: observable,
        });
    }
}

export class ConfigureCustomerValidator extends Validator<ConfigureCustomerModel> {
    constructor() {
        super();

        this.ruleFor("id").notNull().withMessage("Please select a value").notEmpty().withMessage("Please select a value");
    }
}
