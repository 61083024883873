import { FieldType, ViewModelBase, KeyValuePair, isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import { action, computed, makeObservable, observable } from "mobx";
import { container } from "tsyringe";

import { APIClient, ICommand, RelayCommand } from "Application";
import { LookupStore } from "Stores/Domain";
import { QuoteModel, QuoteModelValidator } from "./QuoteModel";
import { PROJECTTYPE_COMMERCIALTYPE } from "../Constants";
import { nanoid } from "nanoid";
import moment from "moment";

export class QuoteViewModel extends ViewModelBase<QuoteModel> {
    private lookupStore = container.resolve(LookupStore);
    public readonly calloutButtonId = `callout-${nanoid()}`;
    public apiClient = new APIClient();

    public isCalloutVisible: boolean = false;
    constructor(quoteModel: QuoteModel = new QuoteModel()) {
        super(quoteModel);

        this.setValidator(new QuoteModelValidator());

        makeObservable(this, {
            //Observables
            isCalloutVisible: observable,
            //Actions
            setIsCalloutVisible: action,
            //Computed values
            canDisplayForQuickQuote: computed,
            canUpdateQuoteLead: computed,
            canDisplayLostReason: computed,
            lostReasonNoteDetailButtonText: computed,
            canShowApprovedByDetails: computed,
        });

        // Default values
        this.setValue("projectTypeId", this.lookupStore.getProjectTypeIdByType(PROJECTTYPE_COMMERCIALTYPE));
    }

    // #region Properties

    public get canDisplayForQuickQuote() {
        return this.model.isQuickQuote;
    }

    public get formattedQuoteDate() {
        return this.model.quoteDate ? moment(this.model.quoteDate).format("DD/MM/YYYY") : "Not specified";
    }

    public get formattedApprovedDate() {
        return this.model.approvedDate ? moment(this.model.approvedDate).format("DD/MM/YYYY") : "";
    }

    /**
     * Returns a collection of project types.
     */
    public get projectTypeOptions() {
        return this.lookupStore.getProjectTypes;
    }

    /**
     * Returns a collection of potential ratings.
     */
    public get ratings() {
        return [1, 2, 3, 4, 5];
    }

    /**
     * Returns a collection of Projects
     */

    public get hasSelectedProject() {
        return this.model.projectId !== null;
    }

    public get getProjectsListFormatted() {
        const formattedList: KeyValuePair[] = [];

        this.model.projectsList.forEach((project) => {
            let textVal = project.title.split(":");
            let model: KeyValuePair = {
                key: project.id,
                text: textVal[0],
            };
            formattedList.push(model);
        });

        return [{ key: "", text: "New project" }, ...formattedList];
    }

    public get getProjectsList() {
        return this.model.projectsList;
    }

    public get projectDisplayName() {
        const project = this.model.projectsList.find((project) => project.id === this.model.projectId);

        return project ? project.title : "New project";
    }

    public get canDisplayLostReason() {
        return this.model.isLost;
    }

    public get lostReason() {
        const reason = this.lookupStore.noteSubTypes.find((noteSubType) => noteSubType.id == this.model.lostNoteSubTypeId);

        return reason ? reason.name : "";
    }

    public get quoteLeads() {
        return this.model.quoteLeads.map((item) => {
            return {
                key: item.id,
                text: `${item.firstName} ${item.lastName}`,
                data: item.thumbnailDocumentUrl,
            };
        });
    }

    public get quoteLead() {
        return this.model.quoteLeads.find((lead) => lead.id === this.model.quoteLeadId);
    }

    public get quoteLeadFullName() {
        return this.quoteLead ? `${this.quoteLead.firstName} ${this.quoteLead.lastName}` : "Not selected";
    }

    public get quoteLeadImageURL() {
        return this.quoteLead?.thumbnailDocumentUrl ? this.quoteLead.thumbnailDocumentUrl : undefined;
    }

    public get approvedByFullName() {
        return this.model.approvedByUser ? `${this.model.approvedByUser.firstName} ${this.model.approvedByUser.lastName}` : "";
    }

    public get approvedByImageURL() {
        return this.model.approvedByUser?.thumbnailDocumentUrl ? this.model.approvedByUser.thumbnailDocumentUrl : undefined;
    }

    public get canShowApprovedByDetails() {
        return this.model.approvedByUser && this.model.approvedDate;
    }

    public get canDisplayCallout() {
        //display callout only after the note is more than 60 characters long
        //done to prevent the ui from breaking for longer notes
        return this.model.lostNoteDetail && this.model.lostNoteDetail.length > 60;
    }

    public get canUpdateQuoteLead() {
        return this.model.isLost === false;
    }

    public get lostReasonNoteDetailButtonText() {
        return this.isCalloutVisible ? "Hide additional note" : "See additional note";
    }

    public get lostReasonNoteDetail() {
        return this.model.lostNoteDetail ?? "no additional note provided";
    }

    /**
     * Only allow if the quote is not generated from a enquiry
     */
    public get isSourceOfEnquiryReadOnly() {
        return !isEmptyOrWhitespace(this.model.enquiryId) && !isNullOrUndefined(this.model.enquiryId);
    }

    // #endregion

    // #region Commands

    public calloutVisibilityCommand: ICommand = new RelayCommand(
        () => {
            this.setIsCalloutVisible(!this.isCalloutVisible);
        },
        () => this.canDisplayLostReason,
    );

    public updateProjectTypeCommand = new RelayCommand(
        (value: string) => {
            this.updateField("projectTypeId", value);
        },
        () => {
            return isEmptyOrWhitespace(this.model.projectId);
        },
    );

    public updateProjectIdCommand = new RelayCommand((value: string | null) => {
        this.updateField("projectId", value);

        // SIDE-EFFECT - Inherit ProjectType from linked Project
        const inheritedProjectType = value ? this.model.projectsList.find((p) => p.id === value) : null;

        if (inheritedProjectType) {
            this.updateField("projectTypeId", inheritedProjectType.projectTypeId);
        }
    });

    public updateTitleCommand = new RelayCommand((value: string) => {
        this.updateField("title", value);
    });

    public updateLeadInWeeksCommand = new RelayCommand((value: string) => {
        const parsedValue = parseInt(value);
        const checkedValue = Number.isNaN(parsedValue) ? null : parsedValue;

        this.updateField("leadInWeeks", checkedValue);
    });

    public updateDurationWeeksCommand = new RelayCommand((value: string) => {
        const parsedValue = parseInt(value);
        const checkedValue = Number.isNaN(parsedValue) ? null : parsedValue;

        this.updateField("durationWeeks", checkedValue);
    });

    public updateConfidenceCommand = new RelayCommand((value: number) => {
        this.updateField("confidence", value);
    });

    public updateDesirabilityCommand = new RelayCommand((value: number) => {
        this.updateField("desirability", value);
    });

    public setSaveQuoteStatusType = (value: string | null): void => {
        this.setValue("saveQuoteStatusType", value);
    };

    public updateQuoteLeadCommand = new RelayCommand(
        (value: string) => {
            this.setValue("quoteLeadId", value);
            //send api c
        },
        () => this.canUpdateQuoteLead,
    );

    public updateQuoteQuoteDateCommand = new RelayCommand((value: string) => {
        this.updateField("quoteDate", value);
    });

    // #endregion Commands

    // region actions

    public setIsCalloutVisible = (value: boolean) => (this.isCalloutVisible = value);

    // endregion actions

    // #region Supporting

    private updateField(fieldName: keyof FieldType<QuoteModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    // #endregion Supporting
}
