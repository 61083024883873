import styled from "@emotion/styled";
import { darken } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

import { Box } from "Application";
import { LeaveRowViewModel } from "./LeaveRowViewModel";

interface IProps {}

const LabelCell = styled(Box)`
    /* background-color: ${darken("#46943f", 0.25)}; */
    background-color: #5ca156;
    padding-left: 5px;
    flex: 1;
    line-height: 1.25;
`;

const LabelDisplayName = styled(Box)`
    color: white;
    font-size: 10px;
    font-weight: bold;
    padding: 7px 12px 7px 7px;
`;

export const LeaveRowView: React.FC<IProps> = observer((props) => {
    const [viewModel] = useState(() => new LeaveRowViewModel());

    return (
        <LabelCell>
            <LabelDisplayName>{viewModel.displayName}</LabelDisplayName>
        </LabelCell>
    );
});
