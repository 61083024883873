import { ModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

export class InProgressGroupModel extends ModelBase<InProgressGroupModel> {
    public id: string = "";
    public name: string = "";
    public note: string = "";
    public userId: string = "";
    public projectId: string = "";

    public groupStatusName: string = "";
    public groupStatusType: string = "";
    public groupStatusForegroundColor: string = "";
    public groupStatusBackgroundColor: string = "";

    constructor() {
        super();

        makeObservable(this, {});
    }
}
