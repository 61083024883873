import { ModelBase } from "@shoothill/core";
import { Validator } from "Application/Validation";
import { IObservableArray, makeObservable, observable } from "mobx";

/**
 * Represents a model for an invoice forecast.
 */
export class InvoiceForecastModel extends ModelBase<InvoiceForecastModel> {
    constructor() {
        super();
        makeObservable(this, {});
    }
}

/**
 * Provides validation for the `InvoiceForecastModel`.
 */
export class InvoiceForecastModelValidator extends Validator<InvoiceForecastModel> {
    constructor() {
        super();
    }
}
