import { IStyle, mergeStyleSets } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React from "react";

import { AutoGrid, formatCurrency, Box, theme, ThemedEditText, ThemedText, ThemedTransparentIconButton } from "Application";
import { CrossSVG } from "Assets/CrossSVG";
import { TaskViewModel } from "./TaskViewModel";
import { Reorder, useDragControls } from "framer-motion";
import { ReorderIcon } from "Assets/Icons/ReorderIcon";
import { TickIcon } from "Assets/Icons/TickIconSVG";
import { EditSVG } from "Assets/EditSVG";
import { Else, Show, ThemedButton } from "Application";

interface ITaskViewStyles {
    root: IStyle;
    displayName: IStyle;
    manHours: IStyle;
    hourlyRate: IStyle;
    cost: IStyle;
    iconButton: IStyle;
}

interface ITaskViewProps {
    viewModel: TaskViewModel;
}

const styleDefinitions: ITaskViewStyles = {
    root: {
        display: "flex",
        minHeight: "50px",
    },
    displayName: {
        flex: 1,
        display: "flex",
        "> div": {
            alignItems: "center",
            backgroundColor: "#FCFCFC",
            borderLeft: "15px solid #ff4b22",
            display: "flex",
            flex: "1",
            marginBottom: "15px",
            padding: "4px 15px",
        },
    },
    manHours: {
        borderLeft: "1px solid #E1E1E1",
        display: "flex",
        minWidth: "110px",
        width: "110px",
        "> div": {
            alignItems: "center",
            backgroundColor: "#FCFCFC",
            display: "flex",
            flex: "1",
            marginBottom: "15px",
            padding: "4px 15px",
            ".ms-Label": {
                display: "none",
            },
        },
    },
    hourlyRate: {
        borderLeft: "1px solid #E1E1E1",
        display: "flex",
        minWidth: "110px",
        width: "110px",
        "> div": {
            alignItems: "center",
            backgroundColor: "#FCFCFC",
            display: "flex",
            flex: "1",
            marginBottom: "15px",
            padding: "4px 15px",
            ".ms-Label": {
                display: "none",
            },
        },
    },
    cost: {
        borderLeft: "1px solid #E1E1E1",
        display: "flex",
        minWidth: "175px",
        "> div": {
            alignItems: "center",
            backgroundColor: "#FCFCFC",
            display: "flex",
            flex: "1",
            marginBottom: "15px",
            padding: "4px 3px 0 15px",
            "> span": {
                flex: 1,
                paddingRight: "30px",
                textAlign: "end",
            },
            "> button": {
                minWidth: "30px",
            },
        },
    },
    iconButton: {
        g: {
            stroke: theme.palette.defaultTr.text,
        },
    },
};

export const TaskView: React.FC<ITaskViewProps> = observer((props) => {
    const viewModel = props.viewModel;
    const styles = mergeStyleSets(styleDefinitions);

    if (viewModel) {
        return (
            <Reorder.Item
                style={{ listStyleType: "none" }}
                key={viewModel.KEY}
                value={viewModel}
                whileHover={{ cursor: "grab" }}
                whileTap={{ cursor: "grabbing" }}
                onDragEnd={() => viewModel.parentViewModel.onDragEndCommand.execute()}
            >
                <div className={styles.root}>
                    <div className={styles.displayName}>
                        <Show if={viewModel.labelEditView == false}>
                            <div>
                                <ThemedText fontStyle="h5">{viewModel.displayName}</ThemedText>
                            </div>
                            <Else>
                                <AutoGrid mb={""} dc={"70% 15% 15%"}>
                                    <ThemedEditText
                                        command={viewModel.updateLabelCommand}
                                        placeholder=""
                                        size="large"
                                        value={() => viewModel.displayName}
                                        validationMessage={() => viewModel.getError("taskName")}
                                        styles={{ root: { marginBottom: "10px" } }}
                                    />
                                </AutoGrid>
                                <ThemedButton
                                    styles={{ root: { minWidth: "20px", width: "20px", height: "20px", padding: "0", margin: "30px 5px" } }}
                                    paletteColor="success"
                                    command={viewModel.saveUpdateLabelCommand}
                                    icon={<TickIcon color="#ffffff" />}
                                />
                                <ThemedButton
                                    styles={{ root: { minWidth: "20px", width: "20px", height: "20px", padding: "0", margin: "30px 10px 15px 5px" } }}
                                    paletteColor="error"
                                    command={viewModel.cancelUpdateLabelCommand}
                                    icon={<CrossSVG />}
                                />
                            </Else>
                        </Show>

                        <Show if={viewModel.labelEditView == false}>
                            <ThemedButton
                                styles={{ root: { minWidth: "20px", width: "20px", height: "20px", padding: "0", margin: "10px" } }}
                                paletteColor="primary"
                                command={viewModel.editLabelCommand}
                                icon={<EditSVG />}
                            />
                        </Show>
                    </div>
                    <div className={styles.manHours}>
                        <div>
                            <ThemedEditText
                                command={viewModel.updateManHoursCommand}
                                placeholder=""
                                size="large"
                                value={() => viewModel.getValue("manHours") ?? ""}
                                validationMessage={() => viewModel.getError("manHours")}
                            />
                        </div>
                    </div>
                    <div className={styles.hourlyRate}>
                        <ThemedEditText
                            prefix={"£"}
                            command={viewModel.updateHourlyRateCommand}
                            placeholder=""
                            size="large"
                            value={() => viewModel.getValue("hourlyRate") ?? ""}
                            validationMessage={() => viewModel.getError("hourlyRate")}
                        />
                    </div>
                    <div className={styles.cost}>
                        <div>
                            <ThemedText fontStyle="p">{viewModel.formattedPredictedCostExclVAT}</ThemedText>
                            <ThemedTransparentIconButton command={viewModel.removeCommand} icon={<CrossSVG className={styles.iconButton} />} shape="round" />
                        </div>
                    </div>
                </div>
            </Reorder.Item>
        );
    }

    return <React.Fragment />;
});
