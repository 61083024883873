import { isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { computed, makeObservable } from "mobx";

import { APIClient, ICommand, RelayCommand } from "Application";
import { NewProjectModel } from "./NewProjectModel";

export class NewProjectViewModel extends ViewModelBase<NewProjectModel> {
    public apiClient = new APIClient();

    public parentCancelCommand: ICommand;
    public parentUpdateCommand: ICommand;

    constructor(newProjectModel: NewProjectModel = new NewProjectModel(), cancelCommand: ICommand, updateCommand: ICommand) {
        super(newProjectModel);

        this.parentCancelCommand = cancelCommand;
        this.parentUpdateCommand = updateCommand;

        makeObservable(this, {
            // Observables

            // Computeds
            canFilterQuotes: computed,
            filteredQuotes: computed,
        });
    }

    // #region Properties

    public get quotes() {
        return this.model.quotes.map((item) => {
            return {
                key: item.id,
                text: `${item.reference}: ${item.title}`,
            };
        });
    }

    public get canFilterQuotes(): boolean {
        return !isEmptyOrWhitespace(this.model.filterKeyword);
    }

    public get filteredQuotes() {
        return this.canFilterQuotes
            ? this.quotes.filter((m) => {
                  return m.text.toLowerCase().includes(this.model.filterKeyword.toLowerCase());
              })
            : this.quotes;
    }

    // endregion Properties

    // #region Commands

    public updateFilterKeywordCommand: ICommand = new RelayCommand((filterKeyword: string) => {
        this.model.filterKeyword = filterKeyword;
    });

    public updateSelectQuoteCommand: ICommand = new RelayCommand((quoteId: string) => {
        this.model.quoteId = quoteId;
    });

    public updateCommand: ICommand = new RelayCommand(
        () => this.parentUpdateCommand.execute(this.model.quoteId),
        () => this.parentUpdateCommand.canExecute() && !isEmptyOrWhitespace(this.model.quoteId),
    );

    // #endregion Commands
}
