import { mergeStyleSets } from "@fluentui/react";
import { PropsWithChildren } from "react";

import { theme } from "Styles/AppTheme";
import { HeaderBase, IHeaderProps } from "./HeaderBase";
import { pxToRem } from "../../Application";
import logo from "Assets/logo.svg";
import styled from "@emotion/styled";

const HeaderBaseWrapper = styled.div`
    font-family: ${theme.fontStyles.headerOptionLabel.fontFamily};
`;
export const BASEHeader = (props: PropsWithChildren<IHeaderProps>) => {
    return (
        <HeaderBaseWrapper>
            <HeaderBase
                {...props}
                styles={mergeStyleSets(
                    {
                        root: {
                            backgroundColor: theme.palette.common.white,
                            boxShadow: `3px 0px 6px #00000029`,
                            display: "flex",
                            justifyContent: "center",
                            minHeight: pxToRem(70),
                            zIndex: 1000,
                        },
                        content: {
                            display: "flex",
                            flex: 1,
                            justifyContent: "space-between",
                            margin: `0 ${pxToRem(30)}`,
                        },
                        options: {
                            display: "flex",
                        },
                        option: {
                            color: theme.palette.common.default,
                            display: "flex",
                            flexDirection: "column",
                            "& > div": {
                                alignItems: "center",
                                display: "flex",
                                flex: 1,
                                padding: `0 ${pxToRem(30)}`,
                                fontSize: theme.fontStyles.headerOptionLabel.fontSize,
                                letterSpacing: theme.fontStyles.headerOptionLabel.letterSpacing,
                                textTransform: theme.fontStyles.headerOptionLabel.textTransform,
                            },
                            "&:hover": {
                                cursor: "pointer",
                            },
                        },
                        optionActive: {
                            "&&": {
                                color: theme.palette.common.white,
                                backgroundColor: theme.palette.secondary.main,
                            },
                        },
                        logoOption: {
                            backgroundImage: `url(${logo})`,
                            backgroundPosition: "left center",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            flex: 1,
                            margin: `${pxToRem(17.5)} ${pxToRem(30)} ${pxToRem(17.5)} 0`,
                            maxWidth: pxToRem(110),
                            "&:hover": {
                                cursor: "pointer",
                            },
                        },
                    },
                    props.styles,
                )}
            />
        </HeaderBaseWrapper>
    );
};
