import { IStyle, mergeStyleSets, Modal } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React from "react";

import { AutoGrid, Box, ThemedButton } from "Application";
import warning from "Assets/warning.png";
import { ServiceViewModel } from "../ServiceViewModel";

interface IDeleteImageSettingsViewStyles {
    root: IStyle;
    header: IStyle;
    body: IStyle;
    footer: IStyle;
}

export interface IServiceGroupViewSubViewProps {
    viewModel: ServiceViewModel;
}

export const styleDefinitions: IDeleteImageSettingsViewStyles = {
    root: {
        fontFamily: `"Poppins", sans-serif`,
        textAlign: "center",
        maxWidth: "365px",
        width: "100%",
    },
    header: {
        fontSize: "18px",
        textAlign: "center",
        display: "block",
        color: "#DC3545",
        fontWeight: 600,
        padding: "10px 0",
    },
    body: {
        fontSize: "12px",
        lineHeight: "18px",
        color: "#171716",
        margin: "15px 20px 22px",
    },
    footer: {
        display: "flex",
        padding: "30px 30px",
    },
};

export const DeleteServiceModalView: React.FC<IServiceGroupViewSubViewProps> = observer((props) => {
    const viewModel = props.viewModel;
    const styles = mergeStyleSets(styleDefinitions);

    return (
        <Modal isOpen={viewModel.isDeleteModalOpen} onDismiss={() => viewModel.cancelDeleteServiceCommand.execute()}>
            <div className={styles.root}>
                <div style={{ marginTop: "20px" }}>
                    <img src={warning} height={"50"} width={"50"} alt={"warning"} />
                </div>
                <div className={styles.header}>Warning</div>
                <div className={styles.body}>You are about to remove a service. Are you sure you wish to proceed?</div>
                <AutoGrid m={0} className={styles.footer} columnGap={"30px"} dc={"140px 140px"} borderTop="1px solid #DCDCDC">
                    <ThemedButton paletteColor="primary" command={viewModel.confirmDeleteServiceCommand} displayName="Continue" styles={{ root: { padding: "0 20px" } }} />
                    <ThemedButton command={viewModel.cancelDeleteServiceCommand} displayName="Cancel" styles={{ root: { padding: "0 20px" } }} />
                </AutoGrid>
            </div>
        </Modal>
    );
});
