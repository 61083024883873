import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { FileModel } from "./FileModel";
import { FileViewModel } from "./FileViewModel";
import { ImageSettingsViewModel } from "./ImageSettingsViewModel";

export class Response {
    public fileName: string = "";
    public fileSizeBytes: number = 0;
    public mimeType: string = "";
    public documentUrl: string = "";
    public createdDate: string = "";
    public createdByUserId: string = "";
    public isDeleted: boolean = false;
    public id: string = "";
}

export class POSTSaveImageSettingsEndpoint extends Endpoint<FormData, Response> {
    private readonly viewModel: ImageSettingsViewModel;

    constructor(viewModel: ImageSettingsViewModel) {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Admin.ImageSettings.PostSaveImageSettings);
        this.AllowFileUploads();
        this.viewModel = viewModel;
    }

    public async HandleRequestAsync(): Promise<any> {
        const formData = new FormData();
        for (const file of this?.viewModel?.model?.files!) {
            formData.append("MultipleImages", file.file!, file.fileName);
        }
        return Promise.resolve(formData);
    }

    public async HandleResponseAsync(response: Response[]): Promise<any> {
        this.viewModel.model.files.replace([]);
        response.forEach((img, index) => {
            let imageModel = new FileModel();
            imageModel.fromResponse(img);
            this.viewModel.fileViewModels.push(new FileViewModel(imageModel, this.viewModel.deleteCommand));
        });
    }
}
