import { IButtonStyles, IIconProps, IRenderFunction, PrimaryButton } from "@fluentui/react";
import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React from "react";

import { ICommand } from "../../../../../Application/Commands";
import { themePaletteOptions, themeShapeOptions, themeSizeOptions } from "../../../../../Styles/IShoothillTheme";
import { AddSVG } from "../../../../../Assets/AddSVG";

/**
 * Button interface.
 */
export interface IButtonBaseProps {
    /**
     * An optional class name for use with the button.
     */
    className?: string;
    /**
     * A command to execute.
     */
    command: ICommand;
    /**
     * A value to use with the button. Will be passed back by the command.
     */
    value?: string | number | boolean;
    /**
     * Text content to display on the button.
     */
    displayName?: string;
    /**
     * An icon to display on the button.
     */
    icon?: JSX.Element;
    /**
     * Styling of the button.
     */
    styles?: IButtonStyles;
    /**
     * The size of the button - use this if using generic button.
     */
    size?: themeSizeOptions;
    /**
     * The color of the button - use this if using the generic button.
     */
    paletteColor?: themePaletteOptions;
    /**
     * The shape of the button - use this if using the generic button.
     */
    shape?: themeShapeOptions;
    /**
     * The button type.
     */
    type?: "button" | "submit";
}

export const ButtonBase: React.FC<IButtonBaseProps> = observer((props) => {
    // #region Code Behind

    const getClasseNames = () => {
        return clsx({
            [props.className!]: !isEmptyOrWhitespace(props.className),
        });
    };

    const isDisabled = (): boolean => {
        return isNullOrUndefined(props.command.canExecute) ? false : !props.command.canExecute();
    };

    const getType = (): string => {
        return !isEmptyOrWhitespace(props.type) ? props.type! : "button";
    };

    const onClick = (): void => {
        props.command.execute(props.value);
    };

    const getIcon = (): any | undefined => {
        return props.icon ? props.icon : undefined;
    };

    // #endregion Code Behind
    return (
        <PrimaryButton
            className={getClasseNames()}
            disabled={isDisabled()}
            onRenderIcon={() => getIcon()}
            onClick={onClick}
            styles={props.styles}
            text={props.displayName}
            type={getType()}
        />
    );
});
