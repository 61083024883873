export class LeaveStatusType {
    public static readonly DECLINED = "DECLINED";
    public static readonly PENDING = "PENDING";
    public static readonly APPROVED = "APPROVED";

    public id: string = "";
    public name: string = "";
    public type: string = "";
    public ordinal: number = 0;
    public isDeleted: boolean = false;
    public foregroundColor: string = "";
    public backgroundColor: string = "";
}
