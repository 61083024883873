import { FieldType, isEmptyOrWhitespace, isNullOrEmpty, ViewModelBase } from "@shoothill/core";
import { computed, makeObservable, observable } from "mobx";
import { container } from "tsyringe";

import { ICommand, RelayCommand } from "Application";
import { LookupStore } from "Stores/Domain";
import { CustomerModel, CustomerValidator } from "./CustomerModel";
import { CustomerContactItemModel } from "./CustomerContactItemModel";
import { CustomerItemModel } from "./CustomerItemModel";
import { CONTACTTYPE_COMMERCIALTYPE } from "Views/Shared/Constants";
import { ConfigureCustomerViewModel } from "./ConfigureCustomerSubViews/ConfigureCustomerViewModel";
import { ConfigureCustomerModel } from "./ConfigureCustomerSubViews/ConfigureCustomerModel";

export class CustomerViewModel extends ViewModelBase<CustomerModel> {
    public configureCustomerViewModel: ConfigureCustomerViewModel | null = null;
    private lookupStore = container.resolve(LookupStore);
    private parentSaveCustomerContactCommand: ICommand;

    public customers = observable<CustomerItemModel>([]);
    public customerContacts = observable<CustomerContactItemModel>([]);

    constructor(customerModel: CustomerModel = new CustomerModel(), saveCustomerContactCommand: ICommand) {
        super(customerModel);

        this.parentSaveCustomerContactCommand = saveCustomerContactCommand;

        this.setValidator(new CustomerValidator());

        makeObservable(this, {
            // Observables
            customers: observable,
            customerContacts: observable,
            configureCustomerViewModel: observable,

            // Computeds
            customersCustomerContacts: computed,
        });
    }

    // #region Properties

    public get canDisplayConfigureCustomer(): boolean {
        return this.configureCustomerViewModel !== null;
    }

    /**
     * Determines if the site address can be displayed. This is if the site
     * address will differ from the business/correspondence address.
     */
    public get canDisplaySiteAddress(): boolean {
        return !this.model.sameAsBusinessAddress;
    }

    public get fullSiteAddress(): string {
        return [this.model.siteAddress1, this.model.siteAddress2, this.model.siteAddress3, this.model.siteCity, this.model.sitePostcode?.toUpperCase()]
            .filter((item) => !isEmptyOrWhitespace(item))
            .join(", ");
    }

    public get customersCustomerContacts() {
        if (this.model.contactTypeId === this.lookupStore.getContactTypeIdByType(CONTACTTYPE_COMMERCIALTYPE)) {
            // For a commercial customer, only return the associated contact.
            return this.customerContacts.filter((item: CustomerContactItemModel) => item.id === this.model.customerContactId);
        } else {
            // For a dometics customer, return all contacts.
            return this.customerContacts;
        }
    }

    // #endregion Properties

    // #region Commands

    public editCustomerContactCommand = new RelayCommand(
        () => {
            // Create the configure customer model via the viewmodel.
            const configureCustomerViewModel = new ConfigureCustomerViewModel(
                new ConfigureCustomerModel(),
                this.customerContacts,
                this.cancelEditCustomerContactCommand,
                this.updateCustomerContactCommand,
            );

            configureCustomerViewModel.model.id = null;

            // Assign the viewmodel to display the view.
            this.configureCustomerViewModel = configureCustomerViewModel;
        },
        () => {
            return this.model.contactTypeId === this.lookupStore.getContactTypeIdByType(CONTACTTYPE_COMMERCIALTYPE);
        },
    );

    public cancelEditCustomerContactCommand = new RelayCommand(() => {
        this.configureCustomerViewModel = null;
    });

    public updateCustomerContactCommand = new RelayCommand(
        () => {
            this.updateField("customerContactId", this.configureCustomerViewModel?.model.id);
            this.configureCustomerViewModel = null;
            this.parentSaveCustomerContactCommand.execute();
        },
        () => {
            return this.model.contactTypeId === this.lookupStore.getContactTypeIdByType(CONTACTTYPE_COMMERCIALTYPE) && !isNullOrEmpty(this.getValue("customerId"));
        },
    );

    // #endregion Commands

    // #region Supporting

    // Customers below...
    public getCustomerName = (item: CustomerItemModel) => {
        return item.contactTypeId === this.lookupStore.getContactTypeIdByType(CONTACTTYPE_COMMERCIALTYPE)
            ? item.businessName
            : [item.prefixName, item.firstName, item.lastName].filter((item) => !isEmptyOrWhitespace(item)).join(" ");
    };

    public getCustomerContactNumber1 = (item: CustomerItemModel) => {
        return !isEmptyOrWhitespace(item.contactNumber1) ? item.contactNumber1 : "";
    };

    public getCustomerContactNumber2 = (item: CustomerItemModel) => {
        return !isEmptyOrWhitespace(item.contactNumber2) ? item.contactNumber2 : "";
    };

    public getCustomerEmailAddress = (item: CustomerItemModel) => {
        return !isEmptyOrWhitespace(item.emailAddress) ? item.emailAddress : "";
    };

    public getCustomerEmailAddress2 = (item: CustomerItemModel) => {
        return !isEmptyOrWhitespace(item.emailAddress2) ? item.emailAddress2 : "";
    };

    public getCustomerAddress = (item: CustomerItemModel) => {
        return !isEmptyOrWhitespace(item.fullAddress) ? item.fullAddress : "";
    };

    // Contacts below...
    public getCustomerContactName = (item: CustomerContactItemModel) => {
        return [item.prefixName, item.firstName, item.lastName].filter((item) => !isEmptyOrWhitespace(item)).join(" ");
    };

    public getCustomerContactContactNumber1 = (item: CustomerContactItemModel) => {
        return !isEmptyOrWhitespace(item.contactNumber1) ? item.contactNumber1 : "";
    };

    public getCustomerContactContactNumber2 = (item: CustomerContactItemModel) => {
        return !isEmptyOrWhitespace(item.contactNumber2) ? item.contactNumber2 : "";
    };

    public getCustomerContactEmailAddress = (item: CustomerContactItemModel) => {
        return !isEmptyOrWhitespace(item.emailAddress) ? item.emailAddress : "";
    };

    public getCustomerContactAddress = (item: CustomerContactItemModel) => {
        return !isEmptyOrWhitespace(item.fullAddress) ? item.fullAddress : "";
    };

    // Other...
    private updateField(fieldName: keyof FieldType<CustomerModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    // #endregion Supporting
}
