import { runInAction } from "mobx";

import { EndpointWithoutRequest, Http } from "Application";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { FileModel } from "Views/Shared/Files/FileModel";
import { ProgrammingWeekTaskGroupViewModel } from "../ProgrammingWeekTaskGroupViewModel";
import { ProjectTaskAssignmentGroupReviewLite } from "Views/Shared/Project/ProjectTaskAssignmentGroupReviewLite";

class Response {
    public id: string = "";
    public name: string = "";
    public note: string = "";

    public groupStatusName: string = "";
    public groupStatusType: string = "";
    public groupStatusForegroundColor: string = "";
    public groupStatusBackgroundColor: string = "";

    public reference: string = "";
    public projectTitle: string = "";
    public documents: DocumentResponse[] = [];
    public reviews: ProjectTaskAssignmentReview[] = [];
}

class ProjectTaskAssignmentReview {
    public projectTaskAssignmentId: string | null = null;
    public projectTaskAssignmentReviewNote: string | null = null;
    public projectTaskAssignmentReviewDate: string | null = null;
}

class DocumentResponse {
    id: string = "";
    fileName: string = "";
    fileSizeBytes: number = 0;
    mimeType: string = "";
    documentUrl: string = "";
}

export class GETProjectTaskAssignmentGroupLiteWithRelatedByIdEndpoint extends EndpointWithoutRequest<Response> {
    private viewModel: ProgrammingWeekTaskGroupViewModel;

    constructor(projectTaskAssignmentGroupId: string, viewModel: ProgrammingWeekTaskGroupViewModel) {
        super();

        this.verb(Http.Get);
        this.path(AppUrls.Server.Dashboard.Staff.GetProjectTaskAssignmentGroupWithRelatedLiteById.replace(":projectTaskAssignmentGroupId", projectTaskAssignmentGroupId));
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        this.viewModel.model.id = response.id;
        this.viewModel.model.projectTaskAssignmentGroupName = response.name;
        this.viewModel.model.projectTaskAssignmentGroupNote = response.note;

        this.viewModel.model.groupStatusName = response.groupStatusName;
        this.viewModel.model.groupStatusType = response.groupStatusType;
        this.viewModel.model.groupStatusForegroundColor = response.groupStatusForegroundColor;
        this.viewModel.model.groupStatusBackgroundColor = response.groupStatusBackgroundColor;

        this.viewModel.model.reference = response.reference;
        this.viewModel.model.projectName = response.projectTitle;

        this.viewModel.filesViewModel.model.files.replace(
            response.documents.map((ptad) => {
                const model = new FileModel();

                model.fromResponse(ptad);

                return model;
            }) ?? [],
        );

        this.viewModel.model.reviews.replace(
            response.reviews.map((ptar) => {
                const model = new ProjectTaskAssignmentGroupReviewLite();
                model.fromResponse(ptar);
                return model;
            }) ?? [],
        );

        runInAction(() => {});
    }
}
