//libraries
import { observer } from "mobx-react-lite";
import styled from "@emotion/styled";
import React from "react";
//app
import { WeeklyUpdatesViewModel } from "../WeeklyUpdatesViewModel";
import { WeeklyUpdateItemViewModel } from "./WeeklyUpdateItemViewModel";
import { Box, theme, ThemedText } from "Application";
import { WeeklyUpdateView } from "../WeeklyUpdate/WeeklyUpdatelView";
import { PrimaryTableDottedSeparator } from "Views/Shared/SharedComponents";

interface IProps {
    viewModel: WeeklyUpdateItemViewModel;
    parentViewModel: WeeklyUpdatesViewModel;
}

const PseudoRow = styled(Box)`
    position: relative;
    cursor: pointer;
    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transition: 0.1s ease;
        opacity: 0%;
        background-color: ${theme.palette.common.black};
    }
    &:hover {
        &::after {
            opacity: 5%;
            background-color: ${theme.palette.common.black};
        }
    }
`;

export const WeeklyUpdateItemView = observer((props: IProps) => {
    const { viewModel, parentViewModel } = props;

    return (
        <>
            <PseudoRow onClick={() => viewModel.toggleAdditionalInformationCommand.execute()} backgroundColor={viewModel.projectStatusColour}>
                <Box padding={"10px 0px"} display={"flex"} justifyContent={"space-between"} alignItems="center">
                    <Box display={"flex"} alignItems="center">
                        <Box width={8} marginRight={"5px"}>
                            <ThemedText fontStyle="radioButtonLabel">{viewModel.expandedDisplayName}</ThemedText>
                        </Box>
                        <ThemedText marginRight={"5px"} fontStyle="expandable">
                            {viewModel.reference}
                        </ThemedText>
                        <ThemedText marginRight={"5px"} fontStyle="radioButtonLabel">
                            -
                        </ThemedText>
                        <ThemedText fontStyle="radioButtonLabel">{viewModel.title}</ThemedText>
                    </Box>
                    <ThemedText marginRight={"5px"} fontStyle="expandable">
                        {viewModel.updateSentDateFormatted}
                    </ThemedText>
                </Box>
                <PrimaryTableDottedSeparator />
            </PseudoRow>
            {viewModel.showAdditionalInformation && <WeeklyUpdateView parentViewModel={parentViewModel} projectId={viewModel.getValue("projectId")} />}
        </>
    );
});
