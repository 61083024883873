import { ViewModelBase } from "@shoothill/core";
import { computed, makeObservable } from "mobx";
import { container } from "tsyringe";

import { LookupStore } from "Stores/Domain";
import { ProgrammingWeekTaskSummaryModel } from "./ProgrammingWeekTaskSummaryModel";
import { theme } from "Styles/AppTheme";

export class ProgrammingWeekTaskSummaryViewModel extends ViewModelBase<ProgrammingWeekTaskSummaryModel> {
    private lookupStore = container.resolve(LookupStore);

    constructor() {
        super(new ProgrammingWeekTaskSummaryModel());

        makeObservable(this, {
            reference: computed,
            title: computed,
            taskGroupName: computed,
            taskName: computed,
        });
    }

    // #region Properties

    public get reference() {
        return this.model.referenceNumber;
    }

    public get title() {
        return this.model.projectTitle;
    }

    public get taskGroupName() {
        return this.model.projectTaskGroupName;
    }

    public get taskName() {
        return this.model.projectTaskName;
    }

    public get isTaskReviewed() {
        const status = this.lookupStore.projectTaskAssignmentStatuses.find((pts) => pts.id === this.model.projectTaskAssignmentStatusId);
        return status?.type == "Assigned" && this.model.projectTaskAssignmentIsReviewed;
    }

    public get backgroundColor() {
        switch (true) {
            // if a complete task is being marked as reviewed
            case this.isTaskReviewed:
                return theme.palette.quintenary.main;

            case this.model.projectTaskAssignmentIsNonBillableEffort:
                return "#AC0011";

            // Otherwise fallback onto the project task assignment status for the color.
            default:
                return this.lookupStore.projectTaskAssignmentStatuses.find((pts) => pts.id === this.model.projectTaskAssignmentStatusId)?.foregroundColor ?? "#ff4b22";
        }
    }

    public get isProjectTaskAssignmentStatusComplete(): boolean {
        let retVal = false;
        const status = this.lookupStore.projectTaskAssignmentStatuses.find((pts) => pts.id === this.model.projectTaskAssignmentStatusId);

        if (status?.name == "Complete") {
            retVal = true;
        }

        return retVal;
    }
    // #endregion Properties
}
