import "gantt-task-react/dist/index.css";

import { Gantt, Task, ViewMode } from "gantt-task-react";
import { observer } from "mobx-react-lite";
import React from "react";

import { Box, theme } from "Application";
import {
    GanttChartContainer,
    ganttChartGetColumnWidth,
    GanttTable,
    GanttTableHeader,
    GanttTableHeaderItem,
    getBarCornerRadius,
    TaskList,
    TaskListCell,
    TaskListEmptyExpander,
    TaskListExpander,
    TaskListNameWrapper,
    TaskListTableRow,
} from "./GanttChartStyles";
import { ViewModeBar } from "./ViewModeBar";
import { ProjectChartViewModel } from "./Projects/ProjectChartViewModel";

interface Props {
    projectViewModel: ProjectChartViewModel | null;
}

const BAR_FILL = 60;
const ROW_HEIGHT = 75;

export const ProjectGanttChart: React.FC<Props> = observer((props: Props) => {
    // #region Code Behind

    const [viewMode, setViewMode] = React.useState<ViewMode>(ViewMode.Week);
    const onViewModelChange = (viewMode: ViewMode) => setViewMode(viewMode);
    const columnWidth = ganttChartGetColumnWidth(viewMode);

    const onHandleExpanderClick = (task: Task) => {};

    const onHandleDateChange = (task: Task, children: Task[]) => {};

    const onHandleProgressChange = (task: Task, children: Task[]) => {};

    /**
     * Converts the provided viewmodel into a form the chart can use.
     */
    const convertToGanttTasks = (): Task[] => {
        if (props.projectViewModel !== null) {
            const tasks: Task[] = [];

            tasks.push({
                id: "projectId",
                name: "",
                type: "task",
                isDisabled: true,
                start: props.projectViewModel.startDate!,
                end: props.projectViewModel.endDate!,
                progress: 100,
                styles: { progressColor: "#C15DB0", progressSelectedColor: "#C15DB0" },
            });

            return tasks;
        }

        return [];
    };

    // #endregion Code Behind

    return (
        <Box>
            <ViewModeBar viewMode={viewMode} onViewModelChange={onViewModelChange} />
            <GanttChartContainer>
                <Gantt
                    barCornerRadius={getBarCornerRadius(ROW_HEIGHT, BAR_FILL)}
                    barFill={BAR_FILL}
                    rowHeight={ROW_HEIGHT}
                    fontFamily={theme.defaultFontStyle.fontFamily}
                    fontSize="10px"
                    columnWidth={columnWidth}
                    listCellWidth=""
                    viewMode={viewMode}
                    tasks={convertToGanttTasks()}
                    TooltipContent={() => <React.Fragment />}
                    onExpanderClick={onHandleExpanderClick}
                    onDateChange={onHandleDateChange}
                    onProgressChange={onHandleProgressChange}
                />
            </GanttChartContainer>
        </Box>
    );
});
