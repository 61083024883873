import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import React from "react";

import { AutoGrid, Box, Else, Show, theme, ThemedButton, ThemedComboBox, ThemedEditDate, ThemedEditText, ThemedText } from "Application";
import { EditNoteViewModel } from "./EditNoteViewModel";
import { Spacer } from "Components/Spacer";
import { Files } from "../Files/Files";
import { isEmptyOrWhitespace, isNullOrEmpty } from "@shoothill/core";

export interface IEditNoteViewProps {
    viewModel: EditNoteViewModel;
}

const EditNoteContainer = styled(Box)`
    background-color: white;
    border: 1px dashed ${theme.palette.common.secondary};
    padding: 10px 15px;
`;

export const EditNoteView: React.FC<IEditNoteViewProps> = observer((props) => {
    const viewModel = props.viewModel;

    return (
        <EditNoteContainer>
            <Box display={"flex"} justifyContent={"flex-end"} p={1}>
                <Show if={!isEmptyOrWhitespace(viewModel.getValue("id"))}>
                    <ThemedText>{viewModel.userDetails}</ThemedText>
                    <Else>
                        <ThemedText> </ThemedText>
                    </Else>
                </Show>
            </Box>
            <AutoGrid mt={-6} dc={"300px 200px 200px"}>
                <ThemedComboBox
                    displayName="Note Type*"
                    labelColor="default"
                    options={viewModel.noteTypes}
                    value={() => viewModel.getValue("noteTypeId")}
                    command={viewModel.updateNoteTypeCommand}
                    placeholder="Please select"
                    validationMessage={() => viewModel.getError("noteTypeId")}
                />
                <ThemedEditDate
                    displayName={"Note Date"}
                    size={"larger"}
                    maxDate={new Date()}
                    command={viewModel.updateNoteDateCommand}
                    value={() => viewModel.getValue("noteDate")}
                />
                <ThemedEditText
                    displayName={"Note Time"}
                    placeholder={"HH:MM"}
                    command={viewModel.updateNoteTimeCommand}
                    value={() => viewModel.getValue("noteTime")}
                    validationMessage={() => viewModel.getError("noteTime")}
                />
            </AutoGrid>
            <ThemedEditText
                displayName="Note"
                isMultiline={true}
                command={viewModel.updateNoteDetailCommand}
                placeholder="Please add (if applicable)"
                value={() => viewModel.getValue("noteDetail")}
                validationMessage={() => viewModel.getError("noteDetail")}
            />
            <Spacer mt={3} />
            <Files viewModel={props.viewModel.filesViewModel} />
            <Box display={"flex"} mt={3} mb={0}>
                <Show if={viewModel.getValue<string>("id") === ""}>
                    <ThemedButton mr={2} paletteColor={"primary"} displayName={"Add"} command={viewModel.updateNoteCommand} />
                </Show>
                <Show if={viewModel.getValue<string>("id") !== ""}>
                    <ThemedButton mr={2} paletteColor={"primary"} displayName={"Update"} command={viewModel.updateNoteCommand} />
                </Show>
                <ThemedButton displayName="Cancel" command={viewModel.cancelNoteCommand} />
            </Box>
        </EditNoteContainer>
    );
});
