import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { ApproveRequestModel } from "../ApproveRequestModel";

class Request {
    public leaveRequestId: string = "";
    public responseReason: string | null = null;
}

class Response {}

export class POSTApproveRequestEndpoint extends Endpoint<Request, Response> {
    constructor() {
        super();

        this.verb(Http.Post);
        this.path(AppUrls.Server.Admin.Leave.ApproveLeaveRequest);
    }

    public async HandleRequestAsync(model: ApproveRequestModel): Promise<any> {
        const request = new Request();

        request.leaveRequestId = model.leaveRequest.id;
        request.responseReason = model.responseReason;

        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);
    }
}
