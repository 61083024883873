import { ViewModelBase } from "@shoothill/core";
import { computed, makeObservable, observable } from "mobx";

import { APIClient, RelayCommand } from "Application";
import { BilledAssignmentsViewModel } from "./BilledAssignmentsSubView/BilledAssignmentsViewModel";
import { GETProjectBilledAssignmentsByIdEndpoint } from "./GETProjectBilledAssignmentsByIdEndpoint";

export class BilledAssignmentsContainerViewModel extends ViewModelBase<any> {
    public apiClient = new APIClient();
    public showAdditionalInformation = false;

    public billedTasksViewModel: BilledAssignmentsViewModel;

    constructor(projectId: string) {
        super({});

        this.billedTasksViewModel = new BilledAssignmentsViewModel();

        makeObservable(this, {
            showAdditionalInformation: observable,
            displayName: computed,
        });

        const _ = this.apiClient.sendAsync(new GETProjectBilledAssignmentsByIdEndpoint(projectId, this.billedTasksViewModel));
    }

    public get KEY() {
        return this.model.KEY;
    }

    public get displayName() {
        return this.showAdditionalInformation ? "- Billed Tasks" : "+ Billed Tasks";
    }

    // #region Commands

    public toggleAdditionalInformationCommand = new RelayCommand(() => {
        this.showAdditionalInformation = !this.showAdditionalInformation;
    });

    // #endregion Commands
}
