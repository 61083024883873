import { ViewModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

import { APIClient, ICommand, RelayCommand } from "Application";
import { TaskModel } from "./TaskModel";

import { NotesViewModel } from "../NotesSubView/NotesViewModel";
import { GETAllProjectTaskAssignmentsWithRelatedByProjectTaskIdEndpoint } from "../../Endpoints/GETAllProjectTaskAssignmentsWithRelatedByProjectTaskIdEndpoint";
import { BillingViewModel } from "./BillingSubView/BillingViewModel";
import { SummaryViewModel } from "../SummaryView/SummaryViewModel";

export class TaskViewModel extends ViewModelBase<TaskModel> {
    //displayed content is read-only for this vie

    public apiClient = new APIClient();

    // Callback commands to type parent viewmodel.
    public readonly parentSubmitCommand: ICommand;
    public readonly parentCancelCommand: ICommand;

    // Sub-viewmodels.
    public summaryViewModel = new SummaryViewModel();
    public readonly workspaceViewModels = observable<NotesViewModel | BillingViewModel>([]);
    public activeWorkspaceViewModel: NotesViewModel | BillingViewModel;
    constructor(projectTaskId: string, model: TaskModel, submitCommand: ICommand, cancelCommand: ICommand) {
        super(model, false);

        // Commands to be called on the parent viewmodel.
        this.parentSubmitCommand = submitCommand;
        this.parentCancelCommand = cancelCommand;

        // A collection of workspace viewmodels.
        // In this case, the workspace is a different view of the same model.
        // this.workspaceViewModels.push(new NotesViewModel(model));
        this.workspaceViewModels.push(new BillingViewModel(model));
        // The default active viewmodel.
        this.activeWorkspaceViewModel = this.workspaceViewModels[0];

        // Properties on which to observe changes.
        makeObservable(this, {
            // Observables
            workspaceViewModels: observable,
            activeWorkspaceViewModel: observable,
        });

        //api call to initialize viewModel
        this.apiClient.sendAsync(new GETAllProjectTaskAssignmentsWithRelatedByProjectTaskIdEndpoint(projectTaskId, this));
    }

    // #region Properties

    public get reference() {
        return this.summaryViewModel.model.reference;
    }

    public get title() {
        return this.summaryViewModel.model.title;
    }

    public get taskGroupName() {
        return this.summaryViewModel.model.projectTaskGroupName;
    }

    public get taskName() {
        return this.summaryViewModel.model.projectTaskName;
    }

    // #endregion Properties

    // #region Commands

    public cancelCommand = new RelayCommand(() => {
        this.parentCancelCommand.execute();
    });

    public resetServerErrorCommand = new RelayCommand(() => {
        this.apiClient.reset();
    });

    public navigateToWorkspaceCommand = new RelayCommand((vm: NotesViewModel | BillingViewModel) => {
        this.activeWorkspaceViewModel = vm;
    });

    public isActiveWorkspace = (vm: NotesViewModel | BillingViewModel) => {
        return vm.KEY === this.activeWorkspaceViewModel.KEY;
    };

    // #endregion Commands
}
