import { isEmptyOrWhitespace, isNullOrUndefined, ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";
import moment from "moment";

import { formatDate, ICommand, RelayCommand } from "Application";

import { PlanningApplicationTableItemModel } from "./PlanningApplicationTableItemModel";

export class PlanningApplicationTableItemViewModel extends ViewModelBase<PlanningApplicationTableItemModel> {
    constructor(model = new PlanningApplicationTableItemModel()) {
        super(model);
        makeObservable(this, {});
    }

    //region getters

    public get KEY() {
        return this.model.KEY;
    }

    public get typeName() {
        return this.model.planningApplicationTypeName ? this.model.planningApplicationTypeName : "-";
    }

    public get projectRef() {
        return this.model.projectRef;
    }

    public get projectTitle() {
        return this.model.projectTitle;
    }

    public get projectLeadFullName() {
        return this.model.projectLeadFirstName + " " + this.model.projectLeadLastName;
    }

    public get projectLeadDocumentUrl() {
        return this.model.projectLeadDocumentUrl;
    }

    public get customerFullName() {
        return this.model.customerFirstName + " " + this.model.customerLastName;
    }

    public get localAuthorityDistrictName() {
        return this.model.localAuthorityDistrictName ? this.model.localAuthorityDistrictName : "-";
    }

    public get planningRef() {
        return this.model.planningRef ? this.model.planningRef : "-";
    }

    public get note() {
        return this.model.note ? this.model.note : "-";
    }

    public get formattedSubmittedDate() {
        return this.model.submittedDate ? formatDate(this.model.submittedDate) : "-";
    }

    public get formattedDeterminationDate() {
        return this.model.determinationDate ? formatDate(this.model.determinationDate) : "-";
    }

    public get formattedApprovedDate() {
        return this.model.approvedDate ? formatDate(this.model.approvedDate) : "-";
    }

    public get formattedConsultationDate() {
        return this.model.consultationDate ? formatDate(this.model.consultationDate) : "-";
    }

    /**
     * Determines if the planning application can be displayed, given a set of filters.
     *
     * @param filterKeyword A general filter to cover a number of properties of the planning application.
     * @param filterDateFrom A date from filter.
     * @param filterDateTo  A date to filter.
     * @param filterLocalAuthorityDistrictId A local authority district filter.
     * @param filterPlanningApplicationTypeId A planning application type filter.
     * @param filterProjectLeadId A project Lead to filter.
  
     *
     * @returns true if the planning application can be displayed otherwise false.
     */
    public filterPredicate = (
        filterKeyword: string,
        filterLocalAuthorityDistrictId: string | null,
        filterPlanningApplicationTypeId: string | null,
        filterProjectLeadId: string | null,
        filterFromDate: Date | null,
        filterToDate: Date | null,
    ): boolean => {
        let result = true;

        // Attempt filtering by keyword.
        if (result) {
            const lowerCaseFilterKeyword = filterKeyword.toLowerCase();

            switch (true) {
                case isEmptyOrWhitespace(filterKeyword):
                case this.projectRef.toLowerCase().includes(lowerCaseFilterKeyword):
                case this.projectTitle.toLowerCase().includes(lowerCaseFilterKeyword):
                case this.typeName.toLowerCase().includes(lowerCaseFilterKeyword):
                case this.localAuthorityDistrictName.toLowerCase().includes(lowerCaseFilterKeyword):
                case this.projectLeadFullName.toLowerCase().includes(lowerCaseFilterKeyword):
                case this.planningRef.toLowerCase().includes(lowerCaseFilterKeyword):
                    result = true;
                    break;

                default:
                    result = false;
                    break;
            }
        }

        // If the item has passed the keyword filter, attempt filtering by from date.

        if (result) {
            switch (true) {
                case isNullOrUndefined(filterFromDate):
                    result = true;
                    break;
                //submitted date
                case isNullOrUndefined(this.model.submittedDate):
                    result = false;
                    break;
                case moment(this.model.submittedDate).isSameOrAfter(filterFromDate):
                    result = true;
                    break;
                //determination date
                case isNullOrUndefined(this.model.determinationDate):
                    result = false;
                    break;
                case moment(this.model.determinationDate).isSameOrAfter(filterFromDate):
                    result = true;
                    break;
                //approved Date
                case isNullOrUndefined(this.model.approvedDate):
                    result = false;
                    break;
                case moment(this.model.approvedDate).isSameOrAfter(filterFromDate):
                    result = true;
                    break;
                //consultation date
                case isNullOrUndefined(this.model.consultationDate):
                    result = false;
                    break;
                case moment(this.model.consultationDate).isSameOrAfter(filterFromDate):
                    result = true;
                    break;
                default:
                    result = false;
                    break;
            }
        }

        //if the item has passed the from date filter, attempt filtering by to date.

        if (result) {
            switch (true) {
                case isNullOrUndefined(filterToDate):
                    result = true;
                    break;
                //submitted date
                case isNullOrUndefined(this.model.submittedDate):
                    result = false;
                    break;
                case moment(this.model.submittedDate).isSameOrBefore(filterToDate):
                    result = true;
                    break;
                //determination date
                case isNullOrUndefined(this.model.determinationDate):
                    result = false;
                    break;
                case moment(this.model.determinationDate).isSameOrBefore(filterToDate):
                    result = true;
                    break;
                //approved Date
                case isNullOrUndefined(this.model.approvedDate):
                    result = false;
                    break;
                case moment(this.model.approvedDate).isSameOrBefore(filterToDate):
                    result = true;
                    break;
                //approved Date
                case isNullOrUndefined(this.model.consultationDate):
                    result = false;
                    break;
                case moment(this.model.consultationDate).isSameOrBefore(filterToDate):
                    result = true;
                    break;
                default:
                    result = false;
                    break;
            }
        }

        if (result) {
            switch (true) {
                case isEmptyOrWhitespace(filterLocalAuthorityDistrictId):
                case this.model.localAuthorityDistrictId === filterLocalAuthorityDistrictId:
                    result = true;
                    break;

                default:
                    result = false;
                    break;
            }
        }

        if (result) {
            switch (true) {
                case isEmptyOrWhitespace(filterPlanningApplicationTypeId):
                case this.model.planningApplicationTypeId === filterPlanningApplicationTypeId:
                    result = true;
                    break;

                default:
                    result = false;
                    break;
            }
        }

        if (result) {
            switch (true) {
                case isEmptyOrWhitespace(filterProjectLeadId):
                case this.model.projectLeadId === filterProjectLeadId:
                    result = true;
                    break;

                default:
                    result = false;
                    break;
            }
        }

        return result;
    };
}
