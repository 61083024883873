import { Editor } from "@tinymce/tinymce-react";
import { observer } from "mobx-react-lite";
import React from "react";

import { ExpandableContainer, ICommand, ThemedButton, ThemedComboBox } from "Application";
import { setupQuotePlaceholderPlugin } from "Plugins/TinyMCE/QuotePlaceholders";
import { FormContainerRow, FormSeparator } from "Views/Shared/SharedComponents";
import { TermsAndConditionsViewModel } from "./TermsAndConditionsViewModel";
import useResizeObserver from "Application/Hooks/UseResizeObserver";

export interface ITermsAndConditionsSubViewProps {
    viewModel: TermsAndConditionsViewModel;
    parentPreviewTermsAndConditionsCommand: ICommand;
}

export const TermsAndConditionView: React.FC<ITermsAndConditionsSubViewProps> = observer((props) => {
    const { ref, observedHeight } = useResizeObserver(90);
    const viewModel = props.viewModel;

    const renderTermsAndConditions = () => {
        return (
            <>
                <div ref={ref}>
                    <FormContainerRow mb="30px !important" mt={"13px !important"} dc={"25% 25%"}>
                        <ThemedComboBox
                            displayName="Terms And Conditions*"
                            labelColor="default"
                            options={viewModel.termsAndConditionsOptions}
                            value={() => viewModel.getValue("termAndConditionId") ?? ""}
                            command={viewModel.updateTermsAndConditionsTemplateIdCommand}
                            placeholder="Please select"
                            validationMessage={() => viewModel.getError("termAndConditionId")}
                        />
                        <ThemedComboBox
                            displayName="Choose signature"
                            labelColor="default"
                            options={viewModel.signatoryUserOptions}
                            value={() => viewModel.getValue("signatoryUserId") ?? ""}
                            command={viewModel.updateSignatoryUserCommand}
                            placeholder="Please select"
                            validationMessage={() => viewModel.getError("signatoryUserId")}
                        />
                    </FormContainerRow>
                    <FormContainerRow dc={"1fr"}>
                        <Editor
                            apiKey="g828m3lar5y087wnket2v38pbvjugg2m1091afqssqvjj8ze"
                            value={viewModel.getValue("termsAndConditionsDetails") ?? ""}
                            onEditorChange={viewModel.updateTermsAndConditionsText}
                            init={{
                                height: 446,
                                menubar: false,
                                browser_spellcheck: true,
                                plugins:
                                    "preview paste searchreplace autolink directionality visualblocks visualchars fullscreen link table charmap hr nonbreaking insertdatetime advlist lists wordcount imagetools textpattern noneditable charmap contextmenu code",
                                toolbar:
                                    "undo redo | bold italic underline strikethrough | subscript superscript | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | fullscreen preview code | quoteplaceholders",
                                font_formats:
                                    "Arapey=arapey,serif; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino;HeadingNow 55 Medium=headingnow55medium, sans-serif; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
                                content_style:
                                    "body { font-family: Arapey, serif; } @import url('https://basearchitects.blob.core.windows.net/fonts/ArapeyRegularFontFace.css');@import url('https://basearchitects.blob.core.windows.net/fonts/HeadingNow55MediumFontFace.css');",
                                contextmenu: "undo redo | cut copy paste | bold italic underline | fontformats | fontsizes | quoteplaceholders",
                                setup: (editor) => {
                                    setupQuotePlaceholderPlugin(editor);
                                },
                            }}
                        />
                    </FormContainerRow>
                    <ThemedButton
                        styles={{ root: { padding: "0 5px" } }}
                        paletteColor={"quarternary"}
                        displayName="Preview terms and conditions"
                        command={props.parentPreviewTermsAndConditionsCommand}
                    />
                </div>
            </>
        );
    };

    return (
        <React.Fragment>
            {viewModel.canDisplayForQuickQuote && <></>}
            {!viewModel.canDisplayForQuickQuote && (
                <ExpandableContainer displayName="Terms & Conditions" childrenHeight={observedHeight} openByDefault>
                    {renderTermsAndConditions()}
                </ExpandableContainer>
            )}
        </React.Fragment>
    );
});
