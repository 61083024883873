import { runInAction } from "mobx";
import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { PlanningApplicationTableViewModel } from "../PlanningApplicationTableViewModel";
import { PlanningApplicationTableItemModel } from "../TableItem/PlanningApplicationTableItemModel";
import { PlanningApplicationTableItemViewModel } from "../TableItem/PlanningApplicationTableItemViewModel";

class Response {
    public items: PlanningApplicationTableItemResponse[] = [];
}

class PlanningApplicationTableItemResponse {
    public id: string = "";
    public projectId: string = "";
    public projectRef: string = "";
    public localAuthorityDistrictId: string | null = null;
    public localAuthorityDistrictName: string | null = null;
    public planningRef: string | null = null;
    public planningApplicationStatusId: string = "";
    public note: string | null = null;
    public submittedDate: Date | null = null;
    public determinationDate: Date | null = null;
    public approvedDate: Date | null = null;
    public consultationDate: Date | null = null;
    public projectTitle: string = "";
    public customerId: string = "";
    public customerFirstName: string = "";
    public customerLastName: string = "";
    public projectLeadId: string = "";
    public projectLeadFirstName: string = "";
    public projectLeadLastName: string = "";
    public projectLeadDocumentUrl: string = "";
}

export class GETPlanningApplicationTableItemsByStatusTypeEndpoint extends EndpointWithoutRequest<Response> {
    private viewModel: PlanningApplicationTableViewModel;

    constructor(viewModel: PlanningApplicationTableViewModel) {
        super();
        this.verb(Http.Get);
        this.viewModel = viewModel;
        this.path(AppUrls.Server.PlanningApplication.GetPlanningApplicationTableItemsByStatusType.replace(":statustype", viewModel.model.filterStatusType as unknown as string));
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        runInAction(() => {
            const vms: PlanningApplicationTableItemViewModel[] = [];

            response.items.forEach((item) => {
                const model = new PlanningApplicationTableItemModel();
                model.fromResponse(item);
                const vm = new PlanningApplicationTableItemViewModel(model);
                vms.push(vm);
            });

            this.viewModel.items.replace(vms);
        });
    }
}
