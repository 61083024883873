import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

export class ProgrammingWeekTaskSummaryModel extends ModelBase<ProgrammingWeekTaskSummaryModel> {
    public projectTaskAssignmentId: string = "";
    public projectTaskAssignmentNote: string = "";
    public projectTaskAssignmentStatusId = "";
    public projectTaskAssignmentIsNonBillableEffort = false;
    public projectTaskAssignmentIsReviewed = false;
    public projectTitle: string = "";
    public projectTaskGroupName: string = "";
    public projectTaskName: string = "";
    public referenceNumber: string = "";

    constructor() {
        super();
        makeObservable(this, {
            projectTaskAssignmentId: observable,
            projectTaskAssignmentNote: observable,
            projectTaskAssignmentStatusId: observable,
            projectTaskAssignmentIsNonBillableEffort: observable,
            projectTaskAssignmentIsReviewed: observable,
            projectTitle: observable,
            projectTaskGroupName: observable,
            projectTaskName: observable,
            referenceNumber: observable,
        });
    }
}
