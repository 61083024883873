import { ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

import { ICommand, RelayCommand } from "Application";
import { TaskAssignmentModel } from "./TaskAssignmentModel";

export class TaskAssignmentViewModel extends ViewModelBase<TaskAssignmentModel> {
    constructor(model: TaskAssignmentModel) {
        super(model);

        makeObservable(this, {});
    }

    //#region Properties

    public get KEY() {
        return this.model.KEY;
    }

    public get displayName() {
        return this.model.inProgressItemModel.projectTaskName;
    }

    //#endregion Properties

    //#region Commands

    /**
     * Command to toggle selection of this assignment.
     */
    public toggleSelectTaskAssignment: ICommand = new RelayCommand(() => {
        this.model.enabled = !this.model.enabled;
    });

    //#endregion Commands
}
