export class LeaveDayType {
    public static readonly FROM_MORNING = "FROM_MORNING";
    public static readonly FROM_AFTERNOON = "FROM_AFTERNOON";
    public static readonly ENDOF_MORNING = "ENDOF_MORNING";
    public static readonly ENDOF_AFTERNOON = "ENDOF_AFTERNOON";

    public id: string = "";
    public name: string = "";
    public type: string = "";
    public ordinal: number = 0;
    public isStartDay: boolean = false;
}
