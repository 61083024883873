import { runInAction } from "mobx";

import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { BilledAssignmentsViewModel } from "./BilledAssignmentsSubView/BilledAssignmentsViewModel";
import { BilledAssignmentsItemModel } from "./BilledAssignmentsSubView/BilledAssignmentsItemModel";
import { BilledAssignmentsItemViewModel } from "./BilledAssignmentsSubView/BilledAssignmentsItemViewModel";

class Response {
    billedAssignments: any[] = [];
}

export class GETProjectBilledAssignmentsByIdEndpoint extends EndpointWithoutRequest<Response> {
    private readonly viewModel: BilledAssignmentsViewModel;

    constructor(projectId: string, viewModel: BilledAssignmentsViewModel) {
        super();

        this.verb(Http.Get);
        this.path(AppUrls.Server.Projects.GetProjectBilledAssignmentsByProjectId.replace(":id", projectId));
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        runInAction(() => {
            const billedAssignments: BilledAssignmentsItemViewModel[] = [];

            response.billedAssignments.forEach((element: any) => {
                const model = new BilledAssignmentsItemModel();

                model.fromResponse(element);

                billedAssignments.push(new BilledAssignmentsItemViewModel(model));
            });

            this.viewModel.assignments.replace(billedAssignments);
        });
    }
}
