import { MODELID_CURRENTPROJECTUPDATE } from "Views/Shared/Constants";
import { ProjectWeeklyUpdateContainerViewModel } from "../ProjectWeeklyUpdateContainerViewModel";
import { WeeklyUpdateModel } from "../../../Shared/WeeklyUpdate/WeeklyUpdateModel";

export class ProjectWeeklyUpdateResponse extends Response {
    // #region Response Data

    public id: string = "";

    activeUpdate: ActiveWeeklyUpdateResponse | null = null;
    updates: WeeklyUpdateResponse[] = [];

    // #endregion Response Data

    // #region Response Part Mappings to ViewModel

    public static remainingResponsePart(response: ProjectWeeklyUpdateResponse, viewModel: ProjectWeeklyUpdateContainerViewModel) {
        viewModel.model.weeklyUpdates.replace(
            response.updates.map((item) => {
                const domainModel = new WeeklyUpdateModel();

                domainModel.id = item.id;
                domainModel.updateDate = new Date(item.updateDate);

                return domainModel;
            }),
        );

        // If weekly updates are available, set the current option to the last one available.
        // We override the Id as it may not have a Guid.
        if (viewModel.model.weeklyUpdates.length > 0) {
            viewModel.model.weeklyUpdates[viewModel.model.weeklyUpdates.length - 1].id = MODELID_CURRENTPROJECTUPDATE;
            viewModel.model.weeklyUpdateId = MODELID_CURRENTPROJECTUPDATE;
        }

        viewModel.weeklyUpdateFormViewModel.model.fromResponse(response.activeUpdate);
    }

    // #endregion Response Part Mappings to ViewModel
}

class ActiveWeeklyUpdateResponse {
    id: string = "";
    projectId: string = "";

    updateDetail: string = "";
    updateDate: string = "";
    sendUpdate: boolean = true;

    createdDate: string = "";
    createdByUserId: string = "";
    updatedDate: string = "";
    updatedByUserId: string = "";
}

class WeeklyUpdateResponse {
    id: string = "";
    updateDate: string = "";
}
