import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

import { WeeklyUpdateFormModel } from "./Form/WeeklyUpdateFormModel";
import { WeeklyUpdateHistoryModel } from "./History/WeeklyUpdateHistoryModel";
import { WeeklyUpdateModel } from "../../Shared/WeeklyUpdate/WeeklyUpdateModel";
import { MODELID_CURRENTPROJECTUPDATE } from "Views/Shared/Constants";

export class ProjectWeeklyUpdateContainerModel extends ModelBase<ProjectWeeklyUpdateContainerModel> {
    public weeklyUpdates = observable<WeeklyUpdateModel>([]);
    public weeklyUpdateId: string | null = null;
    public updateForm = new WeeklyUpdateFormModel();
    public updateHistories = observable<WeeklyUpdateHistoryModel>([]);

    constructor() {
        super();

        makeObservable(this, {
            // Observables
            weeklyUpdates: observable,
            weeklyUpdateId: observable,
            updateHistories: observable,
        });
    }

    //simple business logic
    public get previousWeeklyUpdate() {
        const previousWeeklyUpdates = this.weeklyUpdates.filter((item) => item.id != MODELID_CURRENTPROJECTUPDATE);
        return previousWeeklyUpdates.find((item) => item.updateDate?.getTime() == Math.max(...previousWeeklyUpdates.map((i) => i.updateDate!.getTime()), 0));
    }

    public get latestUpdateHistory() {
        return this.updateHistories.find((update) => update.id == this.previousWeeklyUpdate!.id);
    }
}
