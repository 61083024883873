import { observer } from "mobx-react-lite";
import { useState } from "react";

import { Box, ThemedText } from "Application";
import { DefaultTableDottedSeparator, SecondaryTableDottedSeparator, SecondaryTableSeparator } from "Views/Shared/SharedComponents";
import { ProgrammingViewModel } from "./ProgrammingViewModel";
import { InProgressView } from "./InProgressSuvbView/InProgressView";
import { UnassignedTasksView } from "./UnassignedTasksSubView/UnassignedTasksView";

interface IProps {
    projectId: string;
}

export const ProgrammingView = observer((props: IProps) => {
    const [viewModel] = useState(() => new ProgrammingViewModel(props.projectId));

    const onTitleClick = () => {
        viewModel.toggleAdditionalInformationCommand.execute();
    };

    return (
        <Box style={{ marginBottom: "10px" }}>
            <Box onClick={onTitleClick} display="flex" flexDirection="column" minHeight="40px" style={{ cursor: "pointer" }}>
                <Box display="flex" flex="1" alignItems="center">
                    <ThemedText fontStyle="expandable">{viewModel.displayName}</ThemedText>
                </Box>
                <SecondaryTableDottedSeparator />
            </Box>
            {viewModel.showAdditionalInformation && (
                <Box>
                    <Box display="flex">
                        <Box flex={4} padding="25px 0 15px 0">
                            <UnassignedTasksView viewModel={viewModel.unassignedTasksViewModel} />
                        </Box>
                        <DefaultTableDottedSeparator styles={{ root: { minWidth: "30px", margin: "15px 0" } }} vertical={true} />
                        <Box flex={6} padding="25px 0 15px 0">
                            <InProgressView viewModel={viewModel.inProgressViewModel} />
                        </Box>
                    </Box>
                    <SecondaryTableSeparator />
                </Box>
            )}
        </Box>
    );
});
