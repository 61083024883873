import { IStyle, mergeStyleSets } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React from "react";

import { AutoGrid, Box, Nullcommand, ThemedButton } from "Application";
import warning from "Assets/warning.png";
import { UnassignedTasksViewModel } from "../UnassignedTasksViewModel";

interface IDeleteImageSettingsViewStyles {
    root: IStyle;
    header: IStyle;
    body: IStyle;
    footer: IStyle;
}

export interface IServiceGroupViewSubViewProps {
    parentViewModel: UnassignedTasksViewModel;
}

const styleDefinitions: IDeleteImageSettingsViewStyles = {
    root: {
        fontFamily: `"Poppins", sans-serif`,
        textAlign: "center",
        maxWidth: "365px",
        width: "100%",
    },
    header: {
        fontSize: "18px",
        textAlign: "center",
        display: "block",
        color: "#DC3545",
        fontWeight: 600,
        padding: "10px 0",
    },
    body: {
        fontSize: "12px",
        lineHeight: "18px",
        color: "#171716",
        margin: "15px 20px 22px",
    },
    footer: {
        display: "flex",
        padding: "30px 30px",
    },
};

export const DeleteUnassignedTaskModalView: React.FC<IServiceGroupViewSubViewProps> = observer((props) => {
    const { parentViewModel } = props;
    const styles = mergeStyleSets(styleDefinitions);

    return (
        <Box className={styles.root}>
            <Box marginTop={20}>
                <img src={warning} height={"50"} width={"50"} alt={"warning"} />
            </Box>
            <Box className={styles.header}>Warning</Box>
            <Box className={styles.body}>You are about to remove a task. Are you sure you wish to proceed?</Box>
            <AutoGrid m={0} className={styles.footer} columnGap={"30px"} dc={"140px 140px"} borderTop="1px solid #DCDCDC">
                <ThemedButton paletteColor="primary" command={parentViewModel.confirmItemRemovalRequestCommand} displayName="Continue" styles={{ root: { padding: "0 20px" } }} />
                <ThemedButton command={parentViewModel.cancelItemRemovalRequestCommand} displayName="Cancel" styles={{ root: { padding: "0 20px" } }} />
            </AutoGrid>
        </Box>
    );
});
