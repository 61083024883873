import _ from "lodash";
import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { TermsAndConditionsResponse } from "./Endpoints/Shared/TermsAndConditionsResponse";
import { Logger } from "index";
import { runInAction } from "mobx";
import { TermsAndConditionViewModel } from "./TermsAndConditionViewModel";

export class GETTermsAndConditionByIdEndpoint extends EndpointWithoutRequest<TermsAndConditionsResponse> {
    private viewModel: TermsAndConditionViewModel;

    constructor(id: string, viewModel: TermsAndConditionViewModel) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.Admin.TermConditionTemplates.GetTermsAndConditionById.replace(":id", id));
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: TermsAndConditionsResponse): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            this.viewModel.model.fromResponse(response);
        });
    }
}
