import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import React, { CSSProperties, useState } from "react";

interface IOverlayProps {
    overlayColor: string;
}

const Overlay = styled.div<IOverlayProps>`
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    right: -100vw;
    background-color: ${(props) => props.overlayColor};
    z-index: 100000;

    &.expanded {
        right: 0;
    }
`;

const Wrapper = styled.div`
    position: fixed;

    height: 100vh;
    width: 475px;

    z-index: 100001;
    top: 0;
    right: -475px;
    /* background-color: lightgrey; */
    overflow-x: hidden;
    transition: right 0.567s cubic-bezier(0.1, 0.9, 0.2, 1);

    box-shadow: 0px 3px 6px #00000029;

    &.expanded {
        right: 0;
    }

    & > * {
        height: 100%;
    }
`;

interface IProps {
    isOpen: boolean;
    onDismiss?: () => void;
    children: React.ReactNode;
    //defines override styles for the overlay
    overlayStyles?: CSSProperties;
    overlayColor?: string;
}

export const CustomSidePanel: React.FC<IProps> = observer((props) => {
    const { children, isOpen, onDismiss, overlayColor, overlayStyles } = props;

    const [targetOuterHTML, setTargetOuterHTML] = useState(null);

    const handleOverlayClick = (e: any) => {
        e.stopPropagation();
        // target only when the overlay is clicked
        if (e.target.outerHTML === targetOuterHTML && onDismiss) {
            onDismiss();
        }
    };

    const handleOverlayMouseDown = (e: any) => {
        e.stopPropagation();
        if (onDismiss) {
            setTargetOuterHTML(e.target.outerHTML);
        }
    };

    const handleWrapperMouseDown = (e: any) => {
        e.stopPropagation();
        if (onDismiss) {
            setTargetOuterHTML(e.target.outerHTML);
        }
    };

    return (
        <Overlay
            className={`${isOpen ? "expanded" : ""}`}
            overlayColor={overlayColor ? overlayColor : "transparent"}
            onClick={handleOverlayClick}
            onMouseDown={handleOverlayMouseDown}
            style={{ ...overlayStyles }}
        >
            <Wrapper className={`${isOpen ? "expanded" : ""}`} onMouseDown={handleWrapperMouseDown}>
                {children}
            </Wrapper>
        </Overlay>
    );
});
