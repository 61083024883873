import styled from "@emotion/styled";
import { Callout, DirectionalHint } from "@fluentui/react";
import { darken, lighten } from "@mui/material";
import { observer } from "mobx-react-lite";
import { nanoid } from "nanoid";
import React, { useState } from "react";

import { Box } from "Application";
import InfoIcon from "Assets/InfoIcon.svg";
import { LeaveRequestEventViewModel } from "./LeaveRequestEventViewModel";

interface IProps {
    viewModel: LeaveRequestEventViewModel;
}

const EventDisplayName = styled(Box)`
    font-size: 10px;
    font-weight: bold;
    padding: 7px 12px 3px 7px;
`;

const EventDisplayStatusName = styled(Box)`
    font-size: 10px;
    padding: 0px 12px 4px 7px;
`;

const LeaveRequestInfoIcon = styled(Box)`
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url(${InfoIcon});
    height: 18px;
    margin: 4px 4px auto auto;
    opacity: 0.33;
    width: 18px;
`;

export const LeaveRequestEventView: React.FC<IProps> = observer((props) => {
    const [callout, setIsCalloutVisible] = useState(false);
    const [buttonId] = useState(() => `callout-${nanoid()}`);
    const viewModel = props.viewModel;

    const EventCell = styled(Box)`
        background: ${viewModel.isPendingRequest
            ? `repeating-linear-gradient(
                45deg,
                ${viewModel.backgroundColor},
                ${viewModel.backgroundColor} 10px,
                ${lighten(viewModel.backgroundColor, 0.5)} 10px,
                ${lighten(viewModel.backgroundColor, 0.5)} 20px
            )`
            : viewModel.backgroundColor};
        border-left: 5px solid ${darken(viewModel.backgroundColor, 0.25)};
        display: flex;
        flex: 1;
        cursor: pointer;
        justify-content: end;
        line-height: 1.25;
    `;

    const EventCallout = styled(Box)`
        background: ${viewModel.isPendingRequest
            ? `repeating-linear-gradient(
                45deg,
                ${viewModel.backgroundColor},
                ${viewModel.backgroundColor} 10px,
                ${lighten(viewModel.backgroundColor, 0.5)} 10px,
                ${lighten(viewModel.backgroundColor, 0.5)} 20px
            )`
            : viewModel.backgroundColor};
        border-left: 5px solid ${darken(viewModel.backgroundColor, 0.25)};
        display: flex;
        flex: 1;
        min-width: 260px;
        min-height: 60px;
    `;

    return (
        <React.Fragment>
            <EventCell id={buttonId} onClick={() => setIsCalloutVisible(true)}>
                <LeaveRequestInfoIcon />
            </EventCell>
            {callout && (
                <Callout directionalHint={DirectionalHint.topAutoEdge} onDismiss={() => setIsCalloutVisible(false)} target={`#${buttonId}`}>
                    <EventCallout>
                        <Box flex={1}>
                            <EventDisplayName>{viewModel.displayName}</EventDisplayName>
                            <EventDisplayStatusName>
                                {viewModel.displayStatusName} {viewModel.displayNumberOFDays}
                            </EventDisplayStatusName>
                        </Box>
                    </EventCallout>
                </Callout>
            )}
        </React.Fragment>
    );
});
