import { ViewModelBase } from "@shoothill/core";
import { computed, makeObservable } from "mobx";

import { ProjectChartModel } from "./ProjectChartModel";

export class ProjectChartViewModel extends ViewModelBase<ProjectChartModel> {
    constructor(projectModel: ProjectChartModel) {
        super(projectModel);

        makeObservable(this, {
            id: computed,
            name: computed,
            startDate: computed,
            endDate: computed,
        });
    }

    // #region Properties

    public get id() {
        return this.model.id;
    }

    public get name() {
        return this.model.name;
    }

    public get startDate() {
        return this.model.startDate;
    }

    public get endDate() {
        return this.model.endDate;
    }

    // #endregion Properties
}
