import { FieldType, isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { computed, makeObservable, observable, action } from "mobx";
import { AddEditPlanningApplicationModel, AddEditPlanningApplicationModelValidator } from "./AddEditPlanningApplicationModel";
import { container } from "tsyringe";
import { LookupStore } from "Stores/Domain";
import { APIClient, ICommand, RelayCommand, startOfDay } from "Application";
import { GETAllProjectOptionsEndpoint } from "./Endpoints/GETAllProjectOptionsEndpoint";
import {
    POST_UpsertPlanningApplicationEndpoint,
    UpsertPlanningApplicationRequest,
    UpsertPlanningApplicationDocumentRequest,
} from "./Endpoints/POST_UpsertPlanningApplicationEndpoint";
import { GET_GetPlanningApplicationAndRelatedByIdEndpoint } from "./Endpoints/GET_GetPlanningApplicationAndRelatedByIdEndpoint";
import { FilesViewModel } from "Views/Shared/Files/FilesViewModel";

export class AddEditPlanningApplicationViewModel extends ViewModelBase<AddEditPlanningApplicationModel> {
    lookupStore = container.resolve(LookupStore);
    public apiClient = new APIClient();
    public onDismissCommand: ICommand;
    public onSubmitSuccessCommand: ICommand;
    public render: boolean = false;
    public filesViewModel = new FilesViewModel();

    constructor(id: string, onDismissCommand: ICommand, onSubmitSuccessCommand: ICommand, model = new AddEditPlanningApplicationModel()) {
        super(model);
        makeObservable(this, {
            render: observable,
            setRender: action,
            projectOptions: computed,
            canRender: computed,
        });
        this.setValue("id", id);
        this.onDismissCommand = onDismissCommand;
        this.onSubmitSuccessCommand = onSubmitSuccessCommand;
        this.setValidator(new AddEditPlanningApplicationModelValidator());
    }

    //region endpoint calls

    public setRender = (render: boolean) => {
        this.render = render;
    };

    public get canRender(): boolean {
        return this.render;
    }

    public loadData = async () => {
        if (this.model.id === null) return;

        if (this.model.id === "new-id") {
            await this.loadProjectOptions();
        } else {
            await this.loadPlanningApplicationAndRelatedById();
        }

        this.setRender(true);
    };

    public loadProjectOptions = async () => {
        await this.apiClient.sendAsync(new GETAllProjectOptionsEndpoint(this));
    };

    public loadPlanningApplicationAndRelatedById = async () => {
        await this.apiClient.sendAsync(new GET_GetPlanningApplicationAndRelatedByIdEndpoint(this));
    };

    public submitCommandAsync = new RelayCommand(async () => {
        if (this.isModelValid()) {
            if (this.model.id === "new-id") {
                this.setValue("id", null);
            }
            //create request
            const request = new UpsertPlanningApplicationRequest();
            this.model.toRequest(request);

            request.documents = this.filesViewModel.model.files.filter((f) => !isEmptyOrWhitespace(f.id)).map((f) => ({ id: f.id } as UpsertPlanningApplicationDocumentRequest));

            const formData = new FormData();

            formData.append("data", JSON.stringify(request));

            for (const file of this.filesViewModel.model?.files!) {
                formData.append(file.KEY, file.file!);
            }

            await this.apiClient.sendAsync(new POST_UpsertPlanningApplicationEndpoint(), formData);

            if (this.apiClient.IsRequestSuccessful) {
                this.onDismissCommand.execute();
                this.onSubmitSuccessCommand.execute();
            }
        }
    });

    //region getters

    public get displayName(): string {
        let retVal = "New planning application";

        if (this.model.id !== "new-id") {
            if (this.model.planningRef) {
                retVal = this.model.planningRef + " - Update planning application details";
            } else {
                retVal = "Update planning application details";
            }
        }

        return retVal;
    }

    public get submitDisplayName(): string {
        return this.model.id === "new-id" ? "Add" : "Update";
    }

    public get statusOptions() {
        const options = this.lookupStore.planningApplicationStatusKeyValuePairs;
        return options;
    }

    public get typeOptions() {
        const options = this.lookupStore.planningApplicationTypeKeyValuePairs;
        return options;
    }

    public get localAuthorityDistrictOptions() {
        const options = this.lookupStore.localAuthorityDistrictKeyValuePairs.slice().sort((a, b) => a.text.localeCompare(b.text));
        return options;
    }

    public get projectOptions() {
        const options = this.model.projectKeyValuePairs.slice().sort((a, b) => a.text.localeCompare(b.text));
        return options;
    }

    // region commands

    public updateProjectIdCommand = new RelayCommand((value: string) => {
        this.updateField("projectId", value);
    });

    public updateStatusCommand = new RelayCommand((value: string) => {
        this.updateField("planningApplicationStatusId", value);
    });

    public updateTypeCommand = new RelayCommand((value: string) => {
        this.updateField("planningApplicationTypeId", value);
    });

    public updateLocalAuthorityCommand = new RelayCommand((value: string) => {
        this.updateField("localAuthorityDistrictId", value);
    });

    public updatePlanningRefCommand = new RelayCommand((value: string) => {
        this.updateField("planningRef", value);
    });

    public updateDeterminationDateCommand = new RelayCommand((date: Date | undefined) => {
        const fromDate = startOfDay(date);

        this.updateField("determinationDate", fromDate);
    });

    public updateConsultationDateCommand = new RelayCommand((date: Date | undefined) => {
        const fromDate = startOfDay(date);

        this.updateField("consultationDate", fromDate);
    });

    public updateApprovedDateCommand = new RelayCommand((date: Date | undefined) => {
        const fromDate = startOfDay(date);

        this.updateField("approvedDate", fromDate);
    });

    public updateSubmittedDateCommand = new RelayCommand((date: Date | undefined) => {
        const fromDate = startOfDay(date);

        this.updateField("submittedDate", fromDate);
    });

    public updateNoteCommand = new RelayCommand((value: string) => {
        this.updateField("note", value);
    });

    // region helpers

    private updateField(fieldName: keyof FieldType<AddEditPlanningApplicationModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }
}
