import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

export class InProgressModel extends ModelBase<InProgressModel> {
    // Filtering
    public filterIncludeBilledTasks = false;

    // Sorting
    public sortKey = "projectTaskName"; // A property defined in the table column definitions.
    public sortDescending = false;

    constructor() {
        super();

        makeObservable(this, {
            filterIncludeBilledTasks: observable,

            sortKey: observable,
            sortDescending: observable,
        });
    }
}
