import _ from "lodash";

import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { LetterTemplatesModel } from "./LetterTemplatesModel";

class Response {}

export class GETLetterTemplatesByIdEndpoint extends EndpointWithoutRequest<Response> {
    private readonly viewModel: LetterTemplatesModel;

    constructor(id: string, viewModel: LetterTemplatesModel) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.Admin.LetterTemplates.GetLetterTemplateById.replace(":id", id));
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        this.viewModel.fromResponse(response);
    }
}
