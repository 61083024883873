import { action, makeObservable, observable } from "mobx";
import { singleton } from "tsyringe";

@singleton()
export class SettingsStore {
    private enquiriesTable = new EnquiriesTable();
    private lostTable = new LostTable();
    private quotesTable = new QuotesTable();
    private projectTable = new ProjectsTable();
    private planningApplicationTable = new PlanningApplicationTable();

    public get EnquiriesTable() {
        return this.enquiriesTable;
    }

    public get LostTable() {
        return this.lostTable;
    }

    public get QuotesTable() {
        return this.quotesTable;
    }

    public get ProjectTable() {
        return this.projectTable;
    }

    public get PlanningApplicationTable() {
        return this.planningApplicationTable;
    }
    public reset() {
        this.enquiriesTable.reset();
        this.lostTable.reset();
        this.quotesTable.reset();
        this.projectTable.reset();
        this.planningApplicationTable.reset();
    }
}

class EnquiriesTable {
    private readonly DEFAULT_SORTKEY = "lastActionDate"; // A property defined in the table column definitions.
    private readonly DEFAULT_SORTDESCENDING = false;

    public sortKey = this.DEFAULT_SORTKEY;
    public sortDescending = this.DEFAULT_SORTDESCENDING;

    constructor() {
        makeObservable(this, {
            sortKey: observable,
            sortDescending: observable,
            reset: action,
        });
    }

    public reset() {
        this.sortKey = this.DEFAULT_SORTKEY;
        this.sortDescending = this.DEFAULT_SORTDESCENDING;
    }
}

class LostTable {
    private readonly DEFAULT_SORTKEY = "lastActionDate"; // A property defined in the table column definitions.
    private readonly DEFAULT_SORTDESCENDING = false;

    public sortKey = this.DEFAULT_SORTKEY;
    public sortDescending = this.DEFAULT_SORTDESCENDING;

    constructor() {
        makeObservable(this, {
            sortKey: observable,
            sortDescending: observable,
            reset: action,
        });
    }

    public reset() {
        this.sortKey = this.DEFAULT_SORTKEY;
        this.sortDescending = this.DEFAULT_SORTDESCENDING;
    }
}

class QuotesTable {
    private readonly DEFAULT_SORTKEY = "lastActionDate"; // A property defined in the table column definitions.
    private readonly DEFAULT_SORTDESCENDING = false;

    public sortKey = this.DEFAULT_SORTKEY;
    public sortDescending = this.DEFAULT_SORTDESCENDING;

    constructor() {
        makeObservable(this, {
            sortKey: observable,
            sortDescending: observable,
            reset: action,
        });
    }

    public reset() {
        this.sortKey = this.DEFAULT_SORTKEY;
        this.sortDescending = this.DEFAULT_SORTDESCENDING;
    }
}

class ProjectsTable {
    private readonly DEFAULT_SORTKEY = "anticipatedDate"; // A property defined in the table column definitions.
    private readonly DEFAULT_SORTDESCENDING = false;

    public sortKey = this.DEFAULT_SORTKEY;
    public sortDescending = this.DEFAULT_SORTDESCENDING;

    constructor() {
        makeObservable(this, {
            sortKey: observable,
            sortDescending: observable,
            reset: action,
        });
    }

    public reset() {
        this.sortKey = this.DEFAULT_SORTKEY;
        this.sortDescending = this.DEFAULT_SORTDESCENDING;
    }
}

class PlanningApplicationTable {
    private readonly DEFAULT_SORTKEY = "projectRef"; // A property defined in the table column definitions.
    private readonly DEFAULT_SORTDESCENDING = false;

    public sortKey = this.DEFAULT_SORTKEY;
    public sortDescending = this.DEFAULT_SORTDESCENDING;

    constructor() {
        makeObservable(this, {
            sortKey: observable,
            sortDescending: observable,
            reset: action,
        });
    }

    public reset() {
        this.sortKey = this.DEFAULT_SORTKEY;
        this.sortDescending = this.DEFAULT_SORTDESCENDING;
    }
}
