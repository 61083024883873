import { ChoiceGroup, IChoiceGroupOption, IChoiceGroupOptionStyles, IChoiceGroupStyles, ILabelStyles, ITextStyles, Label, mergeStyleSets, Stack } from "@fluentui/react";
import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React from "react";
import { getThemeColorOption } from "Application/Helpers/StyleHelpers";
import { ICommand } from "../../../../../Application/Commands";
import { TextBase } from "./TextBase";
import { theme, themeColourOptions } from "../../../../../Styles";
import { ValidationLabelError } from "Styles/ThemedPrimitives/Style1/ValidationLabelError";
import { Show } from "Components";
/**
 * Radio button group interface.
 */
export interface IRadioButtonsBaseProps {
    /**
     * An optional class name for use with the radio button group.
     */
    className?: string;
    /**
     * A command to execute.
     */
    command: ICommand;
    /**
     * A collection of options to display in the radio button group.
     */
    options: IChoiceGroupOption[];
    /**
     * A selection value to use with the radio button group. Will be passed back by the command.
     */
    value: () => string;
    /**
     * Text content to display as the label of the radio button group.
     */
    displayName?: string;
    /**
     * Text content to display as the error message.
     */
    validationMessage?: () => string;
    /**
     * Styling of the radio button group.
     */
    styles?: IChoiceGroupStyles;
    /**
     * Styling of the radio button options.
     */
    optionStyles?: IChoiceGroupOptionStyles;
    /**
     * Styling for the error message.
     */
    errorStyles?: ITextStyles;

    labelColor?: themeColourOptions;
}

export const RadioButtonsBase: React.FC<IRadioButtonsBaseProps> = observer((props) => {
    const labelColor = getThemeColorOption(props.labelColor);
    const labelStyle: Partial<ILabelStyles> = {
        root: {
            color: labelColor,
            fontFamily: theme.fontStyles.label.fontFamily ?? theme.defaultFontStyle.fontFamily,
            fontSize: theme.fontStyles.label.fontSize ?? theme.defaultFontStyle.fontSize,
            fontWeight: theme.fontStyles.label.fontWeight ?? theme.defaultFontStyle.fontWeight,
            letterSpacing: theme.fontStyles.label.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
            lineHeight: theme.fontStyles.label.lineHeight ?? theme.defaultFontStyle.lineHeight,
            textTransform: theme.fontStyles.label.textTransform ?? theme.defaultFontStyle.textTransform,
        },
    };
    const canDisplayErrorMessage = (): boolean => {
        return !isEmptyOrWhitespace(props.validationMessage?.() as string);
    };

    const getClasseNames = (): string => {
        return clsx({
            [props.className!]: !isEmptyOrWhitespace(props.className),
        });
    };

    const getOptions = (): IChoiceGroupOption[] => {
        return isNullOrUndefined(props.optionStyles)
            ? props.options
            : props.options.map((option) => {
                  option.styles = mergeStyleSets(props.optionStyles, option.styles);

                  return option;
              });
    };

    const isDisabled = (): boolean => {
        if (!props.command) return false;
        return isNullOrUndefined(props.command.canExecute) ? false : !props.command.canExecute();
    };

    const getValidationMessage = (): string => {
        return isEmptyOrWhitespace(props.validationMessage?.() as string) ? "" : (props.validationMessage?.() as string);
    };

    const onChange = (event?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption): void => {
        props.command.execute(option?.key);
    };

    // #endregion Code Behind

    return (
        <Stack>
            {canDisplayErrorMessage() ? (
                <ValidationLabelError labelColor="secondary" displayName={props.displayName} validationMessage={props.validationMessage} />
            ) : (
                <Stack horizontal>
                    <Label styles={labelStyle}>{props.displayName}</Label>
                </Stack>
            )}
            <ChoiceGroup className={getClasseNames()} onChange={onChange} options={getOptions()} selectedKey={props.value()} styles={props.styles} disabled={isDisabled()} />
            {/* {canDisplayErrorMessage() && <TextBase styles={props.errorStyles}>{getValidationMessage()}</TextBase>} */}
        </Stack>
    );
});
