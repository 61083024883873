import { isNullOrUndefined, ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import moment from "moment";

import { User } from "Application/Models/Domain/User";
import { Validator } from "Application/Validation";

export class TaskModel extends ModelBase<TaskModel> {
    // This is not part of the form model, but used for validation.
    public saveBilledEffortAsNCTType: boolean | null = null;

    public id: string = "";
    public reference: string = "";
    public title: string = "";
    public projectTaskGroupName = "";
    public projectTaskName = "";
    public projectTaskIsBilledHourly = false;

    public effort: number | null = null;
    public billableEffort: number | null = null;
    public isNonBillableEffort: boolean = false;
    public plannedStartDate: Date | undefined = undefined;
    public plannedEndDate: Date | undefined = undefined;
    public billedEffort: number | null = null;

    public note: string | null = null;
    public billedNote: string | null = null;
    public reviewedNote: string | null = null;

    public isReviewed: boolean = false;

    public projectTaskAssignmentStatusId = "";
    public projectTaskBillableEffort: number = 0;

    public remainingProjectTaskBillableEffort: number | null = null;
    public maximumBillableEffort: number | null = null;

    public user: User | null = null;
    public users = observable<User>([]);

    //id of the initial assignee. Used to determine the isAssigned flag.
    public readonly initialAssigneeId: string | null = null;

    constructor() {
        super();

        makeObservable(this, {
            id: observable,
            reference: observable,
            title: observable,
            projectTaskGroupName: observable,
            projectTaskName: observable,
            projectTaskIsBilledHourly: observable,

            effort: observable,
            billableEffort: observable,
            isNonBillableEffort: observable,
            plannedStartDate: observable,
            plannedEndDate: observable,
            note: observable,
            reviewedNote: observable,
            billedEffort: observable,
            billedNote: observable,

            isReviewed: observable,

            projectTaskAssignmentStatusId: observable,
            projectTaskBillableEffort: observable,

            remainingProjectTaskBillableEffort: observable,
            maximumBillableEffort: observable,

            user: observable,
            users: observable,
            initialAssigneeId: observable,
        });
    }
}

export class TaskModelValidator extends Validator<TaskModel> {
    constructor() {
        super();

        // -----------------------------------------------------------------------------------------------------
        // Changes to the logic here, must also be made to:
        // Resource\AssignedTasks\Table\ConfigureSubViews\TaskModel.ts
        // -----------------------------------------------------------------------------------------------------
        this.ruleFor("effort").notNull().withMessage("This field cannot be left empty.").greaterThanOrEqualTo(0);
        this.ruleFor("billableEffort")
            .must({
                predicate: (billableEffort, model) => {
                    // -----------------------------------------------------------------------------------------------------
                    // Rules (Part 1):
                    // 1. If billed hourly, no rules apply.
                    // 2. If non-billable, the billable effort must greater than or equal to zero.
                    // 3. If billable, the billable effort must be greater than zero.
                    // -----------------------------------------------------------------------------------------------------
                    return (
                        model.projectTaskIsBilledHourly ||
                        (!isNullOrUndefined(billableEffort) && billableEffort! >= 0 && model.isNonBillableEffort) ||
                        (!isNullOrUndefined(billableEffort) && billableEffort! > 0 && !model.isNonBillableEffort)
                    );
                },
                message: () => {
                    return "Unless marked as NCT, The chargeable amount must be greater than 0 hrs.";
                },
            })
            .must({
                predicate: (billableEffort, model) => {
                    // -----------------------------------------------------------------------------------------------------
                    // Rules (Part 2) - having passed Part 1:
                    // -----------------------------------------------------------------------------------------------------
                    // 1. If billed hourly, no rules apply.
                    // 2. If non-billable, no rules apply.
                    // 3. If billable, the billable effort must be less than or equal to the maximum billbale effort.
                    // -----------------------------------------------------------------------------------------------------
                    return model.projectTaskIsBilledHourly || model.isNonBillableEffort || (!isNullOrUndefined(billableEffort) && billableEffort! <= model.maximumBillableEffort!);
                },
                message: (billableEffort, model) => {
                    return `The chargeable amount cannot be more than ${model.maximumBillableEffort} hrs.`;
                },
            });
        this.ruleFor("plannedStartDate").notNull().withMessage("Please provide a start date.");
        this.ruleFor("plannedEndDate")
            .notNull()
            .withMessage("Please provide an end date.")
            .must({
                predicate: (plannedEndDate, model) => moment(plannedEndDate).isAfter(moment(model.plannedStartDate)),
                message: () => "Date must be later than start date.",
            });
        this.ruleFor("user")
            .notNull()
            .must({
                predicate: (user, model) => {
                    if (user!.isAssignable) {
                        return true;
                    } else {
                        if (user!.id == model.initialAssigneeId) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                },
                message: () => "User cannot be assigned a task.",
            });
    }
}

export class BilledTaskModelValidator extends Validator<TaskModel> {
    constructor() {
        super();

        // -----------------------------------------------------------------------------------------------------
        // Changes to the logic here, must also be made to:
        // Resource\AssignedTasks\Table\ConfigureSubViews\TaskModel.ts
        // -----------------------------------------------------------------------------------------------------
        this.ruleFor("billedEffort")
            .notNull()
            .withMessage("This field cannot be left empty.")
            .must({
                predicate: (billedEffort, model) => {
                    // -----------------------------------------------------------------------------------------------------
                    // Rules (Part 1):
                    // 1. The billed effort must be greater than or equal to zero.
                    // -----------------------------------------------------------------------------------------------------
                    return billedEffort! >= 0;
                },
                message: () => {
                    return "The amount charged cannot be less than 0 hrs";
                },
            })
            .must({
                predicate: (billedEffort, model) => {
                    // -----------------------------------------------------------------------------------------------------
                    // Rules (Part 2a) - having passed Part 1:
                    // -----------------------------------------------------------------------------------------------------
                    // 1. If billed hourly, no rules apply.
                    // -----------------------------------------------------------------------------------------------------
                    if (model.projectTaskIsBilledHourly) {
                        return true;
                    }

                    // -----------------------------------------------------------------------------------------------------
                    // Rules (Part 2b) - having passed Part 1:
                    // -----------------------------------------------------------------------------------------------------
                    // 1. If intend to bill as NCT, no rules apply.
                    // 2. If intend to bill (not as NCT), cannot charge zero or more than the maximum billable effort on ths task.
                    // -----------------------------------------------------------------------------------------------------
                    return model.saveBilledEffortAsNCTType || (!isNullOrUndefined(billedEffort) && billedEffort! > 0 && billedEffort! <= model.maximumBillableEffort!);
                },
                message: (billedEffort, model) => {
                    return `If intending to bill, the charged amount must be non-zero and cannot be more than ${model.maximumBillableEffort} hrs.`;
                },
            });
    }
}
