//libraries
import styled from "@emotion/styled";
import { DateRangeType, DayOfWeek, ICalendarProps } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import * as React from "react";

//app
import { Box, theme, ThemedButton, ThemedEditDate, ThemedText } from "Application";
import { WeeklyUpdatesViewModel } from "./WeeklyUpdatesViewModel";
import { WeeklyUpdateItemView } from "./WeeklyUpdateItem/WeeklyUpdateItemView";
import { APICommonComponents } from "Application/Helpers/APICommonComponents";

const calendarProps: ICalendarProps = {
    dateRangeType: DateRangeType.WorkWeek,
    workWeekDays: [DayOfWeek.Monday],
};

const HorizontalSpacer = styled.hr`
    margin-top: 35px;
    margin-bottom: 35px;
    color: ${theme.palette.common.dropZoneText};
`;

export const WeeklyUpdatesView: React.FC = observer(() => {
    const [viewModel] = useState(() => new WeeklyUpdatesViewModel());

    const renderProjectLeadProjects = () => {
        const retVal: JSX.Element[] = [];
        viewModel.weeklyUpdateItemViewModels.forEach((itemVM) => {
            retVal.push(<WeeklyUpdateItemView viewModel={itemVM} parentViewModel={viewModel} />);
        });
        return retVal;
    };

    return (
        <Box padding={"15px 30px"}>
            {/* Programming Week Filter */}
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                <Box display="flex" flex={6} justifyContent="start" alignItems="center">
                    <Box display="flex" alignItems="center" justifyContent="end" mr="10px">
                        <ThemedText fontStyle="h3">Programming week:</ThemedText>
                    </Box>
                    <Box mt="-5px" mb="-2px">
                        <ThemedEditDate
                            calendarProps={calendarProps}
                            command={viewModel.updateProgrammingWeekStartDateCommand}
                            placeholder="Start date"
                            size="larger"
                            value={() => viewModel.getValue("programmingWeekStartDate")}
                        />
                    </Box>
                    <ThemedButton displayName="Previous" paletteColor="primary" command={viewModel.updatePreviousProgrammingWeekDateCommand} ml="15px" />
                    <ThemedButton displayName="Next" paletteColor="primary" command={viewModel.updateNextProgrammingWeekDateCommand} ml="15px" />
                </Box>
            </Box>
            <HorizontalSpacer />
            <Box>
                {renderProjectLeadProjects()}
                {/* Busy Overlay */}
                {APICommonComponents(viewModel.apiClient).renderBusy()}
                {/* Error Overlay */}
                {APICommonComponents(viewModel.apiClient).renderError()}
            </Box>
        </Box>
    );
});
