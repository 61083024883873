import { isEmptyOrWhitespace, isNullOrUndefined, ViewModelBase } from "@shoothill/core";
import { computed, makeObservable, observable } from "mobx";

import { APIClient, RelayCommand } from "Application";
import { TaskViewModel } from "./ConfigureSubViews/TaskViewModel";
import { TaskModel } from "./ConfigureSubViews/TaskModel";
import { BilledTasksModel } from "./BilledTasksModel";
import { BilledTaskItemViewModel } from "./BilledTaskItemViewModel";
import { GETProjectTasksEndpoint } from "./GETAllBilledProjectTasksEndpoint";

export class BilledTasksViewModel extends ViewModelBase<BilledTasksModel> {
    public apiClient = new APIClient();
    public taskViewModel: TaskViewModel | null = null;
    public tasks = observable<BilledTaskItemViewModel>([]);

    constructor() {
        super(new BilledTasksModel());

        makeObservable(this, {
            tasks: observable,
            taskViewModel: observable,

            // Computeds
            canFilterTasks: computed,
            filteredTasks: computed,
            canSortTasks: computed,
            filteredAndSortedTasks: computed,
            projects: computed,
            taskGroups: computed,
            projectLeads: computed,
        });

        const _ = this.apiClient.sendAsync(new GETProjectTasksEndpoint(this));
    }

    // #region Filtering

    public updateFilterKeywordCommand = new RelayCommand((keyword: string) => {
        this.model.filterKeyword = keyword;
    });

    public updateFilterStartDateCommand = new RelayCommand((date: Date | undefined) => {
        this.model.filterStartDate = date;
    });

    public updateFilterEndDateCommand = new RelayCommand((date: Date | undefined) => {
        this.model.filterEndDate = date;
    });

    public updateFilterProjectCommand = new RelayCommand((id: string | null) => {
        this.model.filterProjectId = id;
    });

    public updateFilterProjectTaskGroupCommand = new RelayCommand((id: string | null) => {
        this.model.filterProjectTaskGroupName = id;
    });

    public updateFilterProjectLeadCommand = new RelayCommand((id: string | null) => {
        this.model.filterProjectLeadId = id;
    });

    public clearFiltersCommand = new RelayCommand(() => {
        this.model.filterKeyword = "";
        this.model.filterProjectId = null;
        this.model.filterProjectLeadId = null;
        this.model.filterProjectTaskGroupName = null;
        this.model.filterStartDate = undefined;
        this.model.filterEndDate = undefined;
    });

    public get projects() {
        const options = this.tasks.map((t) => {
            return {
                key: t.model.id,
                text: `${t.model.reference} ${t.model.title}`,
            };
        });

        const distinctOptions = options.filter((option, index, arr) => arr.findIndex((t) => t.key === option.key) === index);

        return [{ key: "", text: "All projects" }, ...distinctOptions];
    }

    public get taskGroups() {
        const options = this.tasks.map((t) => {
            return {
                key: t.model.projectTaskGroupName,
                text: t.model.projectTaskGroupName,
            };
        });

        const distinctOptions = options.filter((option, index, arr) => arr.findIndex((t) => t.key === option.key) === index);

        return [{ key: "", text: "All stages" }, ...distinctOptions];
    }

    public get projectLeads() {
        const options = this.tasks
            .map((t) => {
                return {
                    key: t.model.projectLeadId,
                    text: `${t.model.projectLeadFirstName} ${t.model.projectLeadLastName}`,
                };
            })
            .sort((a, b) => (a.text > b.text ? 1 : b.text > a.text ? -1 : 0));

        const distinctOptions = options.filter((option, index, arr) => arr.findIndex((t) => t.key === option.key) === index);

        return [{ key: "", text: "All leads" }, ...distinctOptions];
    }

    public get canFilterTasks(): boolean {
        return (
            !isEmptyOrWhitespace(this.model.filterKeyword) ||
            !isNullOrUndefined(this.model.filterStartDate) ||
            !isNullOrUndefined(this.model.filterEndDate) ||
            !isEmptyOrWhitespace(this.model.filterProjectId) ||
            !isEmptyOrWhitespace(this.model.filterProjectTaskGroupName) ||
            !isEmptyOrWhitespace(this.model.filterProjectLeadId)
        );
    }

    public get filteredTasks(): BilledTaskItemViewModel[] {
        return this.canFilterTasks
            ? this.tasks.filter((vm) =>
                  vm.filterPredicate(
                      this.model.filterKeyword,
                      this.model.filterStartDate,
                      this.model.filterEndDate,
                      this.model.filterProjectId,
                      this.model.filterProjectTaskGroupName,
                      this.model.filterProjectLeadId,
                  ),
              )
            : this.tasks;
    }

    // #endregion Filtering

    // #region Sorting

    public updateSortCommand = new RelayCommand((key: string, sortDescending: boolean) => {
        this.model.sortKey = key;
        this.model.sortDescending = sortDescending;
    });

    public get canSortTasks(): boolean {
        return !isEmptyOrWhitespace(this.model.sortKey);
    }

    public get filteredAndSortedTasks(): BilledTaskItemViewModel[] {
        return this.canSortTasks
            ? this.filteredTasks.slice().sort((lhs, rhs) => {
                  return (this.model.sortDescending ? lhs[this.model.sortKey] < rhs[this.model.sortKey] : lhs[this.model.sortKey] > rhs[this.model.sortKey]) ? 1 : -1;
              })
            : this.filteredTasks;
    }

    // #endregion Sorting

    // #region Billed Task

    public get canDisplayTask(): boolean {
        return this.taskViewModel !== null;
    }

    /**
     * Displays a task.
     */
    public displayTaskCommand = new RelayCommand((projectTaskId: string) => {
        this.taskViewModel = new TaskViewModel(projectTaskId, new TaskModel(), this.submitTaskCommand, this.cancelTaskCommand);
    });

    /**
     * Handles the result of saving a task.
     */
    public submitTaskCommand = new RelayCommand((projectTaskId: string) => {});

    /**
     * Handles cancelling a task.
     */
    public cancelTaskCommand = new RelayCommand(() => {
        this.taskViewModel = null;
    });

    // #endregion Billed Task
}
