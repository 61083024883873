import { ViewModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { container } from "tsyringe";

//app
import { RelayCommand, formatDate } from "Application";
import { LookupStore } from "Stores/Domain";
import { WeeklyUpdateItemModel } from "./WeeklyUpdateItemModel";
import { WeeklyUpdatesViewModel } from "../WeeklyUpdatesViewModel";

export class WeeklyUpdateItemViewModel extends ViewModelBase<WeeklyUpdateItemModel> {
    private lookupStore = container.resolve(LookupStore);
    public showAdditionalInformation = false;
    public parentViewModel: WeeklyUpdatesViewModel;

    constructor(model: WeeklyUpdateItemModel, parentViewModel: WeeklyUpdatesViewModel) {
        super(model);

        this.parentViewModel = parentViewModel;

        makeObservable(this, {
            // Observables
            showAdditionalInformation: observable,
        });
    }

    public get KEY() {
        return this.model.KEY;
    }

    public get reference() {
        return this.model.reference;
    }

    public get title() {
        return this.model.projectTitle;
    }

    public get expandedDisplayName() {
        return this.showAdditionalInformation ? "-" : "+";
    }

    public get isUpdateSent() {
        return this.getValue("sendUpdate");
    }

    public get updateSentDateFormatted() {
        if (this.model.sendUpdate && this.model.updateDate) {
            return `Update sent: ${formatDate(this.model.updateDate)}`;
        }

        return "";
    }

    public get projectStatus() {
        const projectStatus = this.lookupStore.projectStatuses.find((c: any) => c.id === this.model.projectStatusId);
        return projectStatus;
    }

    public get projectStatusColour() {
        return this.projectStatus?.type === "OnHold" ? `${this.projectStatus.foregroundColor}1A` : "transparent";
    }

    // #region Commands

    public toggleAdditionalInformationCommand = new RelayCommand(() => {
        this.showAdditionalInformation = !this.showAdditionalInformation;
    });

    // #endregion Commands
}
