import { ILabelStyles, Label, mergeStyleSets, Stack } from "@fluentui/react";
import { isEmptyOrWhitespace } from "@shoothill/core";
import { observer } from "mobx-react-lite";
import { PropsWithChildren } from "react";
import { EditDateBase, IEditDateBaseProps } from "../../../Components/StyleFrameworks/FluentUI/Primitives/Active/EditDateBase";
import { theme } from "../../AppTheme";
import { getThemeColorOption, getThemeShapeOption, getThemeSizeOption, pxToRem } from "Application/Helpers/StyleHelpers";
import { Show } from "Components";
import { ValidationLabelError } from "./ValidationLabelError";

export const ThemedEditDate = observer((props: PropsWithChildren<IEditDateBaseProps>) => {
    const size = getThemeSizeOption(props.size);
    const shape = getThemeShapeOption(props.shape);
    const haveError = !isEmptyOrWhitespace(props.validationMessage?.() as string);
    const labelColor = getThemeColorOption(props.labelColor);
    const labelStyle: Partial<ILabelStyles> = {
        root: {
            color: labelColor,
            fontFamily: theme.fontStyles.label.fontFamily ?? theme.defaultFontStyle.fontFamily,
            fontSize: theme.fontStyles.label.fontSize ?? theme.defaultFontStyle.fontSize,
            fontWeight: theme.fontStyles.label.fontWeight ?? theme.defaultFontStyle.fontWeight,
            letterSpacing: theme.fontStyles.label.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
            lineHeight: theme.fontStyles.label.lineHeight ?? theme.defaultFontStyle.lineHeight,
            textTransform: theme.fontStyles.label.textTransform ?? theme.defaultFontStyle.textTransform,
        },
    };
    return (
        <EditDateBase
            {...props}
            onRenderLabel={(isInError) => {
                if (isInError) {
                    return (
                        <ValidationLabelError
                            labelColor="secondary"
                            displayName={props.displayName}
                            validationMessage={props.validationMessage}
                            encloseErrorLabel={props.encloseErrorLabel}
                            showErrorMessage={props.showErrorMessage}
                        />
                    );
                }

                if (props.showLabel == false) {
                    return <div></div>;
                }

                return (
                    <Stack horizontal>
                        <Label styles={labelStyle}>{props.displayName}</Label>
                    </Stack>
                );
            }}
            styles={mergeStyleSets(
                {
                    textField: {
                        ".ms-TextField-fieldGroup": {
                            borderColor: haveError ? theme.palette.error.light : theme.palette.field.light,
                            borderRadius: shape,
                            "::after": {
                                borderColor: theme.palette.common.focus,
                                borderRadius: shape,
                            },
                            ":hover": {
                                borderColor: theme.palette.field.main,
                            },
                            ":hover::after": {
                                borderColor: haveError ? theme.palette.field.main : theme.palette.common.focus,
                            },
                        },
                    },
                },
                {
                    root: {},
                    callout: {
                        color: theme.palette.field.text,
                        fontFamily: theme.fontStyles.field.fontFamily ?? theme.defaultFontStyle.fontFamily,
                        fontSize: theme.fontStyles.field.fontSize ?? theme.defaultFontStyle.fontSize,
                        fontWeight: theme.fontStyles.field.fontWeight ?? theme.defaultFontStyle.fontWeight,
                        letterSpacing: theme.fontStyles.field.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                        lineHeight: theme.fontStyles.field.lineHeight ?? theme.defaultFontStyle.lineHeight,
                        textTransform: theme.fontStyles.field.textTransform ?? theme.defaultFontStyle.textTransform,
                    },
                    icon: {
                        color: theme.palette.common.secondary,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                        borderRadius: shape,
                        right: 0,
                        height: "99%",
                        width: props.shape === "round" ? size : "32px",
                        ":hover": {
                            backgroundColor: "#f3f2f1",
                        },
                    },

                    textField: {
                        root: {
                            label: {
                                color: theme.palette.field.text,
                                fontFamily: theme.fontStyles.label.fontFamily ?? theme.defaultFontStyle.fontFamily,
                                fontSize: theme.fontStyles.label.fontSize ?? theme.defaultFontStyle.fontSize,
                                fontWeight: theme.fontStyles.label.fontWeight ?? theme.defaultFontStyle.fontWeight,
                                letterSpacing: theme.fontStyles.label.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                                lineHeight: theme.fontStyles.label.lineHeight ?? theme.defaultFontStyle.lineHeight,
                                textTransform: theme.fontStyles.label.textTransform ?? theme.defaultFontStyle.textTransform,
                            },
                        },
                        ".ms-TextField-field": {
                            color: theme.palette.field.text,
                            fontFamily: theme.fontStyles.field.fontFamily ?? theme.defaultFontStyle.fontFamily,
                            fontSize: theme.fontStyles.field.fontSize ?? theme.defaultFontStyle.fontSize,
                            fontWeight: theme.fontStyles.field.fontWeight ?? theme.defaultFontStyle.fontWeight,
                            letterSpacing: theme.fontStyles.field.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                            lineHeight: theme.fontStyles.field.lineHeight ?? theme.defaultFontStyle.lineHeight,
                            textTransform: theme.fontStyles.field.textTransform ?? theme.defaultFontStyle.textTransform,
                            padding: `0 ${pxToRem(10)}`,
                        },
                        ".ms-TextField-errorMessage": {
                            display: "none",
                            color: theme.palette.common.error,
                            fontFamily: theme.fontStyles.error.fontFamily ?? theme.defaultFontStyle.fontFamily,
                            fontSize: theme.fontStyles.error.fontSize ?? theme.defaultFontStyle.fontSize,
                            fontWeight: theme.fontStyles.error.fontWeight ?? theme.defaultFontStyle.fontWeight,
                            letterSpacing: theme.fontStyles.error.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                            lineHeight: theme.fontStyles.error.lineHeight ?? theme.defaultFontStyle.lineHeight,
                            textTransform: theme.fontStyles.error.textTransform ?? theme.defaultFontStyle.textTransform,
                            paddingTop: 0,
                        },
                        ".ms-TextField-fieldGroup": {
                            height: size,
                        },
                    },
                    errorMessage: {
                        display: "none",
                        color: theme.palette.common.error,
                        fontFamily: theme.fontStyles.error.fontFamily ?? theme.defaultFontStyle.fontFamily,
                        fontSize: theme.fontStyles.error.fontSize ?? theme.defaultFontStyle.fontSize,
                        fontWeight: theme.fontStyles.error.fontWeight ?? theme.defaultFontStyle.fontWeight,
                        letterSpacing: theme.fontStyles.error.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                        lineHeight: theme.fontStyles.error.lineHeight ?? theme.defaultFontStyle.lineHeight,
                        textTransform: theme.fontStyles.error.textTransform ?? theme.defaultFontStyle.textTransform,
                    },
                    subComponentStyles: {
                        textField: {
                            root: {
                                label: labelStyle,
                            },
                        },
                    },
                },
                props.styles,
            )}
        />
    );
});

export const ThemedDefaultEditDate = observer((props: PropsWithChildren<IEditDateBaseProps>) => {
    const haveError = !isEmptyOrWhitespace(props.validationMessage?.() as string);
    return (
        <EditDateBase
            {...props}
            styles={mergeStyleSets(
                {
                    textField: {
                        ".ms-TextField-fieldGroup": {
                            borderColor: haveError ? theme.palette.error.light : theme.palette.field.light,
                            "::after": {
                                borderColor: theme.palette.common.focus,
                            },
                            ":hover": {
                                borderColor: theme.palette.field.main,
                            },
                            ":hover::after": {
                                borderColor: haveError ? theme.palette.field.main : theme.palette.common.focus,
                            },
                        },
                    },
                },
                {
                    root: {},
                    callout: {
                        color: theme.palette.field.text,
                        fontFamily: theme.fontStyles.field.fontFamily ?? theme.defaultFontStyle.fontFamily,
                        fontSize: theme.fontStyles.field.fontSize ?? theme.defaultFontStyle.fontSize,
                        fontWeight: theme.fontStyles.field.fontWeight ?? theme.defaultFontStyle.fontWeight,
                        letterSpacing: theme.fontStyles.field.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                        lineHeight: theme.fontStyles.field.lineHeight ?? theme.defaultFontStyle.lineHeight,
                        textTransform: theme.fontStyles.field.textTransform ?? theme.defaultFontStyle.textTransform,
                    },
                    icon: {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                        right: 0,
                        height: "99%",
                        width: "32px",
                        ":hover": {
                            backgroundColor: "#f3f2f1",
                        },
                    },
                    textField: {
                        subComponentStyles: {
                            root: {
                                label: {
                                    color: theme.palette.field.text,
                                    fontFamily: theme.fontStyles.label.fontFamily ?? theme.defaultFontStyle.fontFamily,
                                    fontSize: theme.fontStyles.label.fontSize ?? theme.defaultFontStyle.fontSize,
                                    fontWeight: theme.fontStyles.label.fontWeight ?? theme.defaultFontStyle.fontWeight,
                                    letterSpacing: theme.fontStyles.label.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                                    lineHeight: theme.fontStyles.label.lineHeight ?? theme.defaultFontStyle.lineHeight,
                                    textTransform: theme.fontStyles.label.textTransform ?? theme.defaultFontStyle.textTransform,
                                },
                            },
                        },
                        ".ms-TextField-errorMessage": {
                            display: "none",
                            color: theme.palette.error.text,
                            fontFamily: theme.fontStyles.error.fontFamily ?? theme.defaultFontStyle.fontFamily,
                            fontSize: theme.fontStyles.error.fontSize ?? theme.defaultFontStyle.fontSize,
                            fontWeight: theme.fontStyles.error.fontWeight ?? theme.defaultFontStyle.fontWeight,
                            letterSpacing: theme.fontStyles.error.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                            lineHeight: theme.fontStyles.error.lineHeight ?? theme.defaultFontStyle.lineHeight,
                            textTransform: theme.fontStyles.error.textTransform ?? theme.defaultFontStyle.textTransform,
                            // paddingTop: 0,
                        },
                        ".ms-TextField-field": {
                            color: theme.palette.field.text,
                            fontFamily: theme.fontStyles.field.fontFamily ?? theme.defaultFontStyle.fontFamily,
                            fontSize: theme.fontStyles.field.fontSize ?? theme.defaultFontStyle.fontSize,
                            fontWeight: theme.fontStyles.field.fontWeight ?? theme.defaultFontStyle.fontWeight,
                            letterSpacing: theme.fontStyles.field.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                            lineHeight: theme.fontStyles.field.lineHeight ?? theme.defaultFontStyle.lineHeight,
                            textTransform: theme.fontStyles.field.textTransform ?? theme.defaultFontStyle.textTransform,
                            padding: `0 ${pxToRem(10)}`,
                        },
                        ".ms-TextField-fieldGroup": {
                            height: theme.sizes.larger,
                        },
                    },
                    errorMessage: {
                        display: "none",
                        color: theme.palette.common.error,
                        fontFamily: theme.fontStyles.error.fontFamily ?? theme.defaultFontStyle.fontFamily,
                        fontSize: theme.fontStyles.error.fontSize ?? theme.defaultFontStyle.fontSize,
                        fontWeight: theme.fontStyles.error.fontWeight ?? theme.defaultFontStyle.fontWeight,
                        letterSpacing: theme.fontStyles.error.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                        lineHeight: theme.fontStyles.error.lineHeight ?? theme.defaultFontStyle.lineHeight,
                        textTransform: theme.fontStyles.error.textTransform ?? theme.defaultFontStyle.textTransform,
                    },
                },
                props.styles,
            )}
        />
    );
});
