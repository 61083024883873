import { runInAction } from "mobx";

import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { BillingNoteModel } from "Views/Resource/AssignedTasks/Table/ConfigureSubViews/BillingSubView/SubViews/BillingNoteModel";
import { BillingNoteViewModel } from "Views/Resource/AssignedTasks/Table/ConfigureSubViews/BillingSubView/SubViews/BillingNoteViewModel";
import { TA_NOTECONTEXT_ASSIGNMENT, TA_NOTECONTEXT_COMPLETED } from "Views/Shared/Constants";
import { FileModel } from "Views/Shared/Files/FileModel";
import { ProgrammingWeekTaskViewModel } from "../ProgrammingWeekTaskViewModel";
import { ProgrammingWeekTaskDetailsViewModel } from "../ProgrammingWeekTaskDetails/ProgrammingWeekTaskDetailsViewModel";
import { ProgrammingWeekTaskUserNotesViewModel } from "../ProgrammingWeekTaskUserNotes/ProgrammingWeekTaskUserNotesViewModel";

class ProjectTaskAssignmentLiteWithRelatedResponse {
    public projectTaskAssignmentId: string = "";

    public projectTaskAssignmentCompletedNote: string = "";
    public projectTaskAssignmentCompletedDate: Date | null = null;
    public projectTaskAssignmentCompletedByUserId: string = "";

    public projectTaskAssignmentReviewedNote: string = "";
    public projectTaskAssignmentIsReviewed: boolean = false;

    public projectTaskAssignmentStatusId: string = "";

    public projectTitle: string = "";
    public projectTaskGroupName: string = "";
    public projectTaskName: string = "";

    public referenceNumber: string = "";
}

class ProjectTaskAssignmentDocumentResponse {
    id: string = "";
    fileName: string = "";
    fileSizeBytes: number = 0;
    mimeType: string = "";
    documentUrl: string = "";
}

class ProjectTaskAssignmentUserNoteResponse {
    public context: string = "";
    public date: Date | null = null;
    public note: string | null = null;
    public firstName: string | null = null;
    public lastName: string | null = null;
    public documentUrl: string | null = null;
}

class Response {
    public projectTaskAssignmentLiteWithRelated: ProjectTaskAssignmentLiteWithRelatedResponse | null = null;
    public projectTaskAssignmentDocuments: ProjectTaskAssignmentDocumentResponse[] = [];
    public projectTaskAssignmentUserNotes: ProjectTaskAssignmentUserNoteResponse[] = [];
    public projectTaskAssignmentCompletedUserNote: ProjectTaskAssignmentUserNoteResponse | null = null;
}

export class GETProjectTaskAssignmentLiteWithRelatedByIdEndpoint extends EndpointWithoutRequest<Response> {
    private viewModel: ProgrammingWeekTaskViewModel;

    constructor(projectTaskAssignmentId: string, viewModel: ProgrammingWeekTaskViewModel) {
        super();

        this.verb(Http.Get);
        this.path(AppUrls.Server.Dashboard.Staff.GetProjectTaskAssignmentWithRelatedLiteById.replace(":projectTaskAssignmentId", projectTaskAssignmentId));
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            // populate ProgramingWeekTaskViewModel
            this.viewModel.model.fromResponse(response.projectTaskAssignmentLiteWithRelated);
            this.viewModel.programmingWeekTaskViewModel.model.fromResponse(response.projectTaskAssignmentLiteWithRelated);
            //populate  ProgrammingWeekDetailsViewModel
            const programmingWeekTaskDetailsViewModel = this.viewModel.workspaceViewModels.find(
                (vm) => vm instanceof ProgrammingWeekTaskDetailsViewModel,
            ) as ProgrammingWeekTaskDetailsViewModel;

            programmingWeekTaskDetailsViewModel.filesViewModel.model.files.replace(
                response.projectTaskAssignmentDocuments.map((ptad) => {
                    const model = new FileModel();

                    model.fromResponse(ptad);

                    return model;
                }) ?? [],
            );

            //populate ProgrammingWeekTaskUserNoteViewModels
            const programmingWeekTaskUserNotesViewModel = this.viewModel.workspaceViewModels.find(
                (vm) => vm instanceof ProgrammingWeekTaskUserNotesViewModel,
            ) as ProgrammingWeekTaskUserNotesViewModel;

            //get update notes

            response.projectTaskAssignmentUserNotes.forEach((noteResponse: ProjectTaskAssignmentUserNoteResponse) => {
                //initialize models and viewModels
                const noteModel: BillingNoteModel = new BillingNoteModel();
                //populate model
                noteModel.context = TA_NOTECONTEXT_ASSIGNMENT;

                noteModel.date = new Date(noteResponse.date!);
                noteModel.firstName = noteResponse.firstName;
                noteModel.lastName = noteResponse.lastName;
                noteModel.note = noteResponse.note;
                noteModel.documentUrl = noteResponse.documentUrl;
                const noteVM: BillingNoteViewModel = new BillingNoteViewModel(noteModel);

                //push to vm array
                programmingWeekTaskUserNotesViewModel.projectTaskAssignmentUserNoteViewModels.push(noteVM);
            });

            //get complete note

            if (response.projectTaskAssignmentCompletedUserNote != null) {
                const noteModel: BillingNoteModel = new BillingNoteModel();
                //populate model
                noteModel.context = TA_NOTECONTEXT_COMPLETED;

                noteModel.date = new Date(response.projectTaskAssignmentCompletedUserNote.date!);
                noteModel.firstName = response.projectTaskAssignmentCompletedUserNote.firstName;
                noteModel.lastName = response.projectTaskAssignmentCompletedUserNote.lastName;
                noteModel.note = response.projectTaskAssignmentCompletedUserNote.note;
                noteModel.documentUrl = response.projectTaskAssignmentCompletedUserNote.documentUrl;
                const noteVM: BillingNoteViewModel = new BillingNoteViewModel(noteModel);

                //push to vm array
                programmingWeekTaskUserNotesViewModel.projectTaskAssignmentUserNoteViewModels.push(noteVM);
            }
        });
    }
}
