import { runInAction } from "mobx";

import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { ContainerResponse } from "./Shared/ContainerResponse";
import { ProjectQuoteHistoryViewModel } from "../ProjectQuoteHistoryViewModel";

export class GETQuoteHistoryContainerByProjectId extends EndpointWithoutRequest<Response> {
    private readonly viewModel: ProjectQuoteHistoryViewModel;

    constructor(projectId: string, viewModel: ProjectQuoteHistoryViewModel) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.ProjectQuoteHistory.GetQuoteHistoryContainerByProjectId.replace(":projectId", projectId));
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: ContainerResponse): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            ContainerResponse.quoteListResponsePart(response, this.viewModel);
            ContainerResponse.remainingResponsePart(response, this.viewModel);
        });
    }
}
