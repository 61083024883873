import { IStyle, mergeStyleSets } from "@fluentui/react";
import React from "react";
import { observer } from "mobx-react-lite";

import { AutoGrid, theme, ThemedButton, ThemedText, ThemedTransparentIconButton } from "Application";
import { CrossSVG } from "Assets/CrossSVG";
import { FormContainerRow, FormSeparator } from "Views/Shared/SharedComponents";
import { AdjustmentInput } from "./Components/AdjustmentInput";
import { ChangeAllowanceViewModel } from "./ChangeAllowanceViewModel";

interface IProps {
    viewModel: ChangeAllowanceViewModel | null;
}

interface IApproveRequestViewStyles {
    root: IStyle;
    header: IStyle;
    body: IStyle;
    separator: IStyle;
    footer: IStyle;
    iconButton: IStyle;
}

const styleDefinitions: IApproveRequestViewStyles = {
    root: {
        width: "700px",
    },
    header: {
        alignItems: "center",
        backgroundColor: "#F3F3F3",
        display: "flex",
        height: "75px",
        justifyContent: "space-between",
        padding: "20px 30px",
    },
    body: {
        padding: "30px 30px 0 30px",
    },
    separator: {
        "&&": {
            lineHeight: "0px",
            margin: "0px 30px",
        },
    },
    footer: {
        display: "flex",
        height: "75px",
        padding: "20px 30px",
    },
    iconButton: {
        g: {
            stroke: theme.palette.defaultTr.text,
        },
    },
};

export const ChangeAllowanceView: React.FC<IProps> = observer((props) => {
    // #region Code Behind

    const viewModel = props.viewModel;
    const styles = mergeStyleSets(styleDefinitions);

    // #endregion Code Behind

    if (viewModel) {
        return (
            <div className={styles.root}>
                <div className={styles.header}>
                    <ThemedText fontStyle="h2">Change Allowance</ThemedText>
                    <ThemedTransparentIconButton command={viewModel.cancelCommand} icon={<CrossSVG className={styles.iconButton} />} shape="round" />
                </div>
                <div className={styles.body}>
                    <FormContainerRow dc={"100%"}>
                        <AutoGrid m={"0"} dc={"1fr 1fr 1fr"}>
                            <AdjustmentInput
                                command={viewModel.updateNumberOfDaysCommand}
                                displayName="Adjust the allowance by:"
                                onBlur={() => viewModel.isFieldValid("numberOfDays")}
                                placeholder="Number of days"
                                suffix=" days"
                                validationMessage={() => viewModel.getError("numberOfDays")}
                                value={() => viewModel.getValue("numberOfDays") ?? ""}
                            />
                        </AutoGrid>
                    </FormContainerRow>
                </div>
                <FormSeparator className={styles.separator} />
                <AutoGrid m={0} className={styles.footer} columnGap={"30px"} dc={"135px 135px"}>
                    <ThemedButton styles={{ root: { padding: "0 5px" } }} paletteColor={"primary"} displayName="Change" command={viewModel.submitCommand} />
                    <ThemedButton styles={{ root: { padding: "0 5px" } }} displayName="Cancel" command={viewModel.cancelCommand} />
                </AutoGrid>
            </div>
        );
    }

    return <React.Fragment />;
});
