import { FieldType, isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { computed, makeObservable, observable } from "mobx";
import { container } from "tsyringe";
import { APIClient } from "Application";
import { ICommand, ICommandAsync, RelayCommand, RelayCommandAsync } from "Application/Commands";
import { LookupStore } from "Stores/Domain";
import { FindAddressViewModel } from "Views/Shared/FindAddress/FindAddressViewModel";
import { SupplierContactModel, SupplierContactModelValidator } from "./SupplierContactModel";

export class SupplierContactViewModel extends ViewModelBase<SupplierContactModel> {
    public apiClient = new APIClient();
    private lookupStore = container.resolve(LookupStore);

    public findAddressViewModel: FindAddressViewModel;

    public addParentSupplierContactCommand: ICommand | null = null;
    public cancelParentSupplierContactCommand: ICommand | null = null;
    public removeParentSupplierContactCommand: ICommand | null = null;

    constructor(
        supplierContactModel: SupplierContactModel | null,
        addSupplierContactCommand: ICommand | null,
        cancelSupplierContactCommand: ICommand | null,
        removeSupplierContactCommand: ICommand | null,
    ) {
        super(supplierContactModel ?? new SupplierContactModel());

        this.findAddressViewModel = new FindAddressViewModel(this.updateAddressCommand);

        this.setValidator(new SupplierContactModelValidator());

        makeObservable(this, {
            addParentSupplierContactCommand: observable,
            cancelParentSupplierContactCommand: observable,
            removeParentSupplierContactCommand: observable,
            canDisplayAddress: computed,
            canDisplayParentAdd: computed,
            canDisplayParentCancel: computed,
            canDisplayParentRemove: computed,
        });

        this.addParentSupplierContactCommand = addSupplierContactCommand;
        this.cancelParentSupplierContactCommand = cancelSupplierContactCommand;
        this.removeParentSupplierContactCommand = removeSupplierContactCommand;
    }

    // #region Properties

    public get KEY(): string {
        return this.model.KEY;
    }

    /**
     * Returns a collection of personal prefixes.
     */
    public get prefixOptions() {
        const options = this.lookupStore.getPrefixes;

        return [{ key: "", text: "No prefix" }, ...options];
    }

    /**
     * Returns a collection of business contact types.
     */
    public get supplierContactTypeOptions() {
        const options = this.lookupStore.getSupplierContactTypes;
        return [{ key: "", text: "Please select" }, ...options];
    }

    public get canDisplayAddress(): boolean {
        return !this.model.sameAsCustomerAddress;
    }

    public get canDisplayParentAdd(): boolean {
        return this.addParentSupplierContactCommand !== null && this.addParentSupplierContactCommand.canExecute();
    }

    public get canDisplayParentCancel(): boolean {
        return this.cancelParentSupplierContactCommand !== null && this.cancelParentSupplierContactCommand.canExecute();
    }

    public get canDisplayParentRemove(): boolean {
        return this.removeParentSupplierContactCommand !== null && this.removeParentSupplierContactCommand.canExecute();
    }

    // #endregion Properties

    // #region Commands

    public updateFirstNameCommand: ICommandAsync = new RelayCommandAsync(async (value: string) => {
        await this.updateField("firstName", value);
    });

    public updateLastNameCommand: ICommandAsync = new RelayCommandAsync(async (value: string) => {
        await this.updateField("lastName", value);
    });

    public updatePrefixCommand: ICommand = new RelayCommand((value: string | null) => {
        this.updateField("contactTitleId", value);
    });

    public updateSupplierContactTypeCommand: ICommand = new RelayCommand((value: string) => {
        this.updateField("supplierContactTypeId", value);
    });

    public updateContactNumberCommand: ICommandAsync = new RelayCommandAsync(async (value: number) => {
        await this.updateField("contactNumber1", value);
    });

    public updateContactNumber2Command: ICommandAsync = new RelayCommandAsync(async (value: number) => {
        await this.updateField("contactNumber2", value);
    });

    public updateContactEmailCommand: ICommandAsync = new RelayCommandAsync(async (value: number) => {
        await this.updateField("emailAddress", value);
    });

    public updateAddress1Command: ICommandAsync = new RelayCommandAsync(async (value: string) => {
        await this.updateField("address1", value);
    });

    public updateAddress2Command: ICommandAsync = new RelayCommandAsync(async (value: string) => {
        await this.updateField("address2", value);
    });

    public updateAddress3Command: ICommandAsync = new RelayCommandAsync(async (value: string) => {
        await this.updateField("address3", value);
    });

    public updateCityCommand: ICommandAsync = new RelayCommandAsync(async (value: string) => {
        await this.updateField("city", value);
    });

    public updatePostalCodeCommand: ICommandAsync = new RelayCommandAsync(async (value: string) => {
        await this.updateField("postcode", value.toUpperCase());
    });

    public updateSameAsSupplierAddressCommand: ICommand = new RelayCommand((value: boolean) => {
        this.updateField("sameAsCustomerAddress", value);
    });

    public updateAddressCommand: ICommand = new RelayCommand((value: any, postcode: string) => {
        // Address information returned depends on the api used.
        // So for the moment, not using an interface on this.
        const siteAddress1 = value?.formatted_address?.[0];
        const siteAddress2 = value?.formatted_address?.[1];
        const siteAddress3 = value?.formatted_address?.[2];
        const siteCity = value?.formatted_address?.[3];
        const postCode = postcode;

        this.updateField("address1", !isEmptyOrWhitespace(siteAddress1) ? siteAddress1 : null);
        this.updateField("address2", !isEmptyOrWhitespace(siteAddress2) ? siteAddress2 : null);
        this.updateField("address3", !isEmptyOrWhitespace(siteAddress3) ? siteAddress3 : null);
        this.updateField("city", !isEmptyOrWhitespace(siteCity) ? siteCity : null);
        this.updateField("postcode", !isEmptyOrWhitespace(postCode) ? postCode : null);
    });

    public addCommand: ICommand = new RelayCommand(() => {
        if (this.isModelValid()) {
            this.addParentSupplierContactCommand?.execute(this.model);
        }
    });

    public cancelCommand: ICommand = new RelayCommand(() => {
        this.cancelParentSupplierContactCommand?.execute();
    });

    public removeCommand: ICommand = new RelayCommand(() => {
        this.removeParentSupplierContactCommand?.execute(this.model);
    });

    // #endregion Commands

    // #region Supporting

    private async updateField(fieldName: keyof FieldType<SupplierContactModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    // #endregion Supporting
}
