import { runInAction } from "mobx";

import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { QuoteListItemViewModel } from "./QuoteListItemViewModel";
import { QuoteResponse } from "./QuoteResponse";

export class GETQuoteWithRelatedByIdEndpoint extends EndpointWithoutRequest<Response> {
    private readonly viewModel: QuoteListItemViewModel;

    constructor(quoteId: string, viewModel: QuoteListItemViewModel) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.Quotes.GetQuoteWithRelatedById.replace(":id", quoteId));
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: QuoteResponse): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            QuoteResponse.revisionResponsePart(response, this.viewModel);
            QuoteResponse.quoteResponsePart(response, this.viewModel);
            QuoteResponse.customerResponsePart(response, this.viewModel);
            QuoteResponse.letterTemplateResponsePart(response, this.viewModel);
            QuoteResponse.tasksResponsePart(response, this.viewModel);
            QuoteResponse.termsAndConditionsResponsePart(response, this.viewModel);
            QuoteResponse.notesResponsePart(response, this.viewModel);
            QuoteResponse.remainingResponsePart(response, this.viewModel);
        });
    }
}
