import styled from "@emotion/styled";
import { Box, theme } from "Application";
import InfoIcon from "Assets/InfoIcon.svg";

export const Container = styled.div`
    background: white;
    width: 100%;
    height: 100%;
    padding: 25px 0px 30px 0px;
    min-width: 1050px;
    grid-area: runningTotals;
    display: flex;
`;

export const WidgetWrapper = styled.div`
    flex: 1;
    min-width: 350px;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border-right: 1px solid ${theme.palette.common.lighterGray};
`;

export const PieChartContainer = styled.div`
    height: 180px;
    width: 180px;

    position: relative;
`;

export const PieChart = styled.div`
    background-color: white;
    border-radius: 50%;
    position: relative;

    height: 100%;
    width: 100%;
    z-index: 2;
`;

export const PseudoPieChart = styled.div`
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
    border-radius: 50%;
    box-sizing: border-box;
    border: 20px solid ${theme.palette.common.lighterGray};
    z-index: -1;
`;

export const PieChartDetailLabelContainer = styled.div`
    position: absolute;
    width: 100%;

    top: 50%;
    right: -82%;
    transform: translateY(-50%);
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 15px;
    z-index: 0;
`;

interface IAssignmentInfoIconProps {
    color: string;
    position: "absolute" | "relative";
}

export const AssignmentInfoIcon = styled(Box)<IAssignmentInfoIconProps>`
    background-color: ${(props) => props.color};
    mask-clip: view-box;
    mask-size: cover;
    mask-image: url(${InfoIcon});

    height: 20px;
    width: 20px;
    opacity: 1;

    position: ${(props) => props.position};
    right: ${(props) => (props.position == "absolute" || undefined ? "10px" : "inherit")};
    top: ${(props) => (props.position == "absolute" || undefined ? "50%" : "inherit")};
    transform: ${(props) => (props.position == "absolute" || undefined ? "translateY(-50%)" : "inherit")};

    &:hover {
        cursor: pointer;
    }
`;

export const EventCallout = styled(Box)`
    display: flex;
    flex: 1;
    min-width: 260px;
    min-height: 60px;
`;

export const EventDisplayList = styled(Box)``;

export const EventDisplayName = styled(Box)`
    font-size: 10px;
    line-height: 1.25;
    font-family: "Poppins";
    flex: 1;
`;

interface IPieChartDetailLabelProps {
    backgroundColor: string;
}

export const PieChartDetailLabel = styled.div<IPieChartDetailLabelProps>`
    background-color: ${(props) => props.backgroundColor};
    position: relative;

    height: 40px;
    padding: 0px 40px;
    border-radius: 100px;
    width: 120%;

    display: flex;

    animation-name: expandToRight;

    animation-duration: 300ms;
    animation-timing-function: cubic-bezier(0.17, 0.67, 0.64, 1.05);

    -webkit-animation-name: expandToRight;
    -webkit-animation-animation-duration: 300ms;
    -webkit-animation-animation-timing-function: cubic-bezier(0.17, 0.67, 0.64, 1.05);

    -moz-animation-name: expandToRight;
    -moz-animation-animation-duration: 300ms;
    -moz-animation-animation-timing-function: cubic-bezier(0.17, 0.67, 0.64, 1.05);

    -o-animation-name: expandToRight;
    -o-animation-animation-duration: 300ms;
    -o-animation-animation-timing-function: cubic-bezier(0.17, 0.67, 0.64, 1.05);

    -ms-animation-name: expandToRight;
    -ms-animation-animation-duration: 300ms;
    -ms-animation-animation-timing-function: cubic-bezier(0.17, 0.67, 0.64, 1.05);

    @keyframes expandToRight {
        0% {
            transform: translateX(-50%);
            visibility: hidden;
        }

        90% {
            transform: translateX(3%);
        }

        100% {
            transform: translateX(0%);
            visibility: visible;
        }
    }
`;

export const PieChartInnerLabel = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

export const PieChartTotalValueLabel = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
