import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import React from "react";

import { Box } from "Application";
import { EffortEventViewModel } from "./EffortEventViewModel";

interface IProps {
    viewModel: EffortEventViewModel;
}

const EventCell = styled(Box)`
    align-items: baseline;
    display: flex;
    flex: 1;
    line-height: 1.25;
    justify-content: space-between;
`;

const DateCell = styled(Box)`
    font-size: 12px;
`;

const TargetCell = styled(Box)`
    font-size: 10px;
`;

const ProgCell = styled(Box)`
    color: #ff4b22;
    font-size: 10px;
`;

export const EffortEventView: React.FC<IProps> = observer((props) => {
    const viewModel = props.viewModel;

    if (viewModel.canDisplayEffort) {
        return (
            <EventCell>
                <DateCell>{viewModel.formattedDate}</DateCell>
                <TargetCell>
                    <b>TARGET:</b> {viewModel.displayAvailableEffort}
                </TargetCell>
                <ProgCell>
                    <b>PROG: </b>
                    {viewModel.displayProgrammedEffort}
                </ProgCell>
            </EventCell>
        );
    }

    return (
        <EventCell>
            <DateCell>{viewModel.formattedDate}</DateCell>
        </EventCell>
    );
});
