import { Logger } from "../../../../../index";
import { Endpoint, EndpointWithoutRequest, Http } from "../../../../../Application/Helpers/BaseEndpoint";
import { AppUrls } from "../../../../../AppUrls";
import { ForgotPasswordModel } from "../ForgotPasswordModel";

class Request {
    public emailAddress: string = "";
}
class Response {}

export class ForgotPasswordEndpoint extends Endpoint<Request, Response> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Account.ForgotPassword);
    }

    public async HandleRequestAsync(model: ForgotPasswordModel): Promise<Request> {
        let request = new Request();
        request.emailAddress = model.emailAddress;
        return request;
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);
        return response;
    }
}
