import { observer } from "mobx-react-lite";
import { useState } from "react";

import { Box, ThemedText } from "Application";
import { BilledTaskDottedSeparator, BilledTaskTableSeparator } from "Views/Shared/SharedComponents";
import { BilledAssignmentsView } from "./BilledAssignmentsSubView/BilledAssignmentsView";
import { BilledAssignmentsContainerViewModel } from "./BilledAssignmentsContainerViewModel";

interface IProps {
    projectId: string;
}

export const BilledAssignmentsContainerView = observer((props: IProps) => {
    const [viewModel] = useState(() => new BilledAssignmentsContainerViewModel(props.projectId));

    const onTitleClick = () => {
        viewModel.toggleAdditionalInformationCommand.execute();
    };

    return (
        <Box style={{ marginBottom: "10px" }}>
            <Box onClick={onTitleClick} display="flex" flexDirection="column" minHeight="40px" style={{ cursor: "pointer" }}>
                <Box display="flex" flex="1" alignItems="center">
                    <ThemedText fontStyle="expandable">{viewModel.displayName}</ThemedText>
                </Box>
                <BilledTaskDottedSeparator />
            </Box>
            {viewModel.showAdditionalInformation && (
                <Box>
                    <Box display="flex">
                        <Box flex={4} padding="25px 0 15px 0">
                            <BilledAssignmentsView viewModel={viewModel.billedTasksViewModel} />
                        </Box>
                    </Box>
                    <BilledTaskTableSeparator />
                </Box>
            )}
        </Box>
    );
});
