import { runInAction } from "mobx";
import { Endpoint, EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { ProgrammingViewModel } from "../Overview/Programming/ProgrammingViewModel";
import { endOfDay, startOfDay } from "Application";

class Request {
    filterStartDate: Date | null = null;
    filterEndDate: Date | null = null;
}

class Response {
    public numberOfLiveProjects: number = 0;
    public numberOfProjectsOnHold: number = 0;
    public totalBillableEffort: number = 0;
    public totalBilledEffort: number = 0;
}

export class GETAdminProgrammingDetailsEndpoint extends Endpoint<Request, Response> {
    private viewModel: ProgrammingViewModel;
    startDateFilter: Date | null = null;
    endDateFilter: Date | null = null;

    constructor(viewModel: ProgrammingViewModel, startDateFilter: Date | null, endDateFilter: Date | null) {
        super();
        this.viewModel = viewModel;
        this.startDateFilter = startDateFilter;
        this.endDateFilter = endDateFilter;
        this.verb(Http.Post);
        this.path(AppUrls.Server.Dashboard.Admin.GetProgrammingDetails);
    }

    public async HandleRequestAsync(): Promise<any> {
        const request = new Request();

        request.filterStartDate = startOfDay(this.startDateFilter) ?? null;
        request.filterEndDate = endOfDay(this.endDateFilter) ?? null;

        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            this.viewModel.model.toModel(response);
        });
    }
}
