import { IStyle, mergeStyleSets } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React from "react";
import { AutoGrid, Box, ThemedButton } from "Application";
import warning from "Assets/warning.png";
import { TermsAndConditionViewModel } from "../TermsAndConditionViewModel";

interface IDeleteTermsAndConditionViewStyles {
    root: IStyle;
    header: IStyle;
    body: IStyle;
    footer: IStyle;
}
export interface TermsAndConditionViewSubViewProps {
    viewModel: TermsAndConditionViewModel;
}

const styleDefinitions: IDeleteTermsAndConditionViewStyles = {
    root: {
        fontFamily: `"Poppins", sans-serif`,
        textAlign: "center",
        maxWidth: "365px",
        width: "100%",
    },
    header: {
        fontSize: "18px",
        textAlign: "center",
        display: "block",
        color: "#DC3545",
        fontWeight: 600,
        padding: "10px 0",
    },
    body: {
        fontSize: "12px",
        lineHeight: "18px",
        color: "#171716",
        margin: "15px 0 22px",
    },
    footer: {
        display: "flex",
        padding: "30px 30px",
    },
};

export const DeleteTermsAndConditionView: React.FC<TermsAndConditionViewSubViewProps> = observer((props) => {
    const viewModel = props.viewModel;
    const styles = mergeStyleSets(styleDefinitions);
    return (
        <Box className={styles.root}>
            <Box>
                <img src={warning} height={"100"} width={"100"} alt={"warning"} />
            </Box>
            <Box className={styles.header}>Warning</Box>
            <Box className={styles.body}>You are about to remove a template from the system. Are you sure you wish to proceed?</Box>
            <AutoGrid m={0} className={styles.footer} columnGap={"30px"} dc={"140px 140px"} borderTop="1px solid #DCDCDC">
                <ThemedButton paletteColor="primary" command={viewModel.deleteTermsAndConditionCommand} displayName="Continue" styles={{ root: { padding: "0 20px" } }} />
                <ThemedButton command={viewModel.closeDeleteModalCommand} displayName="Cancel" styles={{ root: { padding: "0 20px" } }} />
            </AutoGrid>
        </Box>
    );
});
