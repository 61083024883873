import styled from "@emotion/styled";
import { theme } from "Application";

export const Container = styled.div`
    display: grid;
    min-width: 850px;
    grid-template-columns: minmax(225px, 625px) 1fr;
    padding-left: 70px;
    padding-right: 15px;
    padding-top: 0px;
    align-items: center;
    column-gap: 5px;
    margin-bottom: 10px;
    width: 100%;
    border-bottom: 1px dashed #ff4b22;
    border-top: 1px dashed #ff4b22;
    border-left: 1px dashed #ff4b22;
    border-right: 1px dashed #ff4b22;
    padding-bottom: 10px;
    /* background-color: ${theme.palette.common.white}; */
`;
