import styled from "@emotion/styled";
import React from "react";
import { observer } from "mobx-react-lite";

import { theme, ThemedButton, ThemedCheckBox, ThemedComboBox, ThemedEditText, ThemedText, ThemedTransparentIconButton } from "Application";
import { CrossSVG } from "Assets/CrossSVG";
import { AutoGrid, Box, Show } from "Components";
import { FormContainerRow } from "Views/Shared/SharedComponents";
import { FindAddressView } from "Views/Shared/FindAddress/FindAddressView";
import { SupplierContactViewModel } from "./SupplierContactViewModel";

const CustomerCrossSVG = styled(CrossSVG)`
    g {
        stroke: ${theme.palette.defaultTr.text};
    }
`;

const CheckBoxGroupLabelStyles = {
    root: {
        fontFamily: theme.fontStyles.label.fontFamily ?? theme.defaultFontStyle.fontFamily,
        fontSize: theme.fontStyles.label.fontSize ?? theme.defaultFontStyle.fontSize,
        fontWeight: theme.fontStyles.label.fontWeight ?? theme.defaultFontStyle.fontWeight,
        letterSpacing: theme.fontStyles.label.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
        lineHeight: theme.fontStyles.label.lineHeight ?? theme.defaultFontStyle.lineHeight,
        textTransform: theme.fontStyles.label.textTransform ?? theme.defaultFontStyle.textTransform,
        padding: "5px 0",
    },
};

export const CustomerThemeCheckBoxStyle = {
    root: {
        marginTop: "5px",
    },
};

export interface ICustomerContactProps {
    viewModel: SupplierContactViewModel;
    isNewContact?: boolean;
}

export const SupplierContactView: React.FC<ICustomerContactProps> = observer((props) => {
    // #region Code Behind

    const getBorderColor = () => {
        return props.isNewContact ? `1px dashed ${theme.palette.common.secondary}` : `1px solid #dcdcdc`;
    };

    // #endregion Code Behind

    return (
        <Box mt={3} id={"container"} mb={"10px"} padding="20px 30px 10px 30px" backgroundColor={theme.palette.common.boxBackground} border={getBorderColor()} position={"relative"}>
            <FormContainerRow alignContent={"flex-start"} dc={"22% 22% 22% 22% auto"} alignItems="end" rowGap={"20px"}>
                <ThemedComboBox
                    command={props.viewModel.updatePrefixCommand}
                    displayName="Prefix"
                    labelColor="default"
                    options={props.viewModel.prefixOptions}
                    placeholder="Please select"
                    validationMessage={() => props.viewModel.getError("contactTitleId")}
                    value={() => props.viewModel.getValue("contactTitleId")}
                />
                <ThemedEditText
                    displayName="First name"
                    command={props.viewModel.updateFirstNameCommand}
                    placeholder="First name"
                    value={() => props.viewModel.getValue("firstName") ?? ""}
                    validationMessage={() => props.viewModel.getError("firstName")}
                />
                <ThemedEditText
                    displayName="Last name"
                    command={props.viewModel.updateLastNameCommand}
                    placeholder="Last name"
                    value={() => props.viewModel.getValue("lastName") ?? ""}
                    validationMessage={() => props.viewModel.getError("lastName")}
                />
                <ThemedComboBox
                    command={props.viewModel.updateSupplierContactTypeCommand}
                    displayName="Contact Type"
                    labelColor="default"
                    options={props.viewModel.supplierContactTypeOptions}
                    placeholder="Please select"
                    validationMessage={() => props.viewModel.getError("supplierContactTypeId")}
                    value={() => props.viewModel.getValue("supplierContactTypeId")}
                />
                <Show if={props.viewModel.canDisplayParentRemove}>
                    <ThemedTransparentIconButton
                        command={props.viewModel.removeCommand}
                        icon={<CustomerCrossSVG />}
                        styles={{ root: { marginLeft: "auto ", margin: "5px 0" } }}
                        shape="round"
                    />
                </Show>
            </FormContainerRow>
            <FormContainerRow dc={"100%"}>
                <AutoGrid m={0} dc={"22% 22% 22%"}>
                    <ThemedEditText
                        displayName="Alternative contact number (If applicable)"
                        command={props.viewModel.updateContactNumberCommand}
                        isMultiline={false}
                        type={"text"}
                        placeholder="Contact telephone number"
                        value={() => props.viewModel.getValue("contactNumber1") ?? ""}
                        validationMessage={() => props.viewModel.getError("contactNumber1")}
                    />
                    <ThemedEditText
                        displayName="Alternative contact number 2 (If applicable)"
                        command={props.viewModel.updateContactNumber2Command}
                        isMultiline={false}
                        type={"text"}
                        placeholder="Alternative contact telephone number"
                        value={() => props.viewModel.getValue("contactNumber2") ?? ""}
                        validationMessage={() => props.viewModel.getError("contactNumber2")}
                    />
                    <ThemedEditText
                        displayName="Alternative contact email (If applicable)"
                        command={props.viewModel.updateContactEmailCommand}
                        isMultiline={false}
                        placeholder="Contact email address"
                        value={() => props.viewModel.getValue("emailAddress") ?? ""}
                        validationMessage={() => props.viewModel.getError("emailAddress")}
                    />
                </AutoGrid>
            </FormContainerRow>
            <FormContainerRow dc={"100%"}>
                <AutoGrid m={"0"} columnGap={"30px"} dc={"auto 1fr"}>
                    <Box>
                        <ThemedText styles={CheckBoxGroupLabelStyles}>ALTERNATIVE CORRESPONDENCE ADDRESS</ThemedText>
                        <ThemedCheckBox
                            paletteColor="secondary"
                            command={props.viewModel.updateSameAsSupplierAddressCommand}
                            displayName="Same as correspondence address"
                            styles={CustomerThemeCheckBoxStyle}
                            value={() => props.viewModel.getValue("sameAsCustomerAddress")}
                        />
                    </Box>
                    <Show if={props.viewModel.canDisplayAddress}>
                        <FindAddressView viewModel={props.viewModel.findAddressViewModel} />
                    </Show>
                </AutoGrid>
            </FormContainerRow>
            <Show if={props.viewModel.canDisplayAddress}>
                <FormContainerRow dc={"100%"}>
                    <AutoGrid m={"0"} columnGap={"30px"} dc={"1fr 1fr 1fr 1fr"}>
                        <ThemedEditText
                            displayName="Address 1"
                            command={props.viewModel.updateAddress1Command}
                            placeholder="Please enter the first line"
                            value={() => props.viewModel.getValue("address1") ?? ""}
                            validationMessage={() => props.viewModel.getError("address1")}
                        />
                        <ThemedEditText
                            displayName="Address 2"
                            command={props.viewModel.updateAddress2Command}
                            isMultiline={false}
                            placeholder="Please enter the second line"
                            value={() => props.viewModel.getValue("address2") ?? ""}
                            validationMessage={() => props.viewModel.getError("address2")}
                        />
                        <ThemedEditText
                            displayName="Address 3"
                            command={props.viewModel.updateAddress3Command}
                            isMultiline={false}
                            placeholder="Please enter the third line"
                            value={() => props.viewModel.getValue("address3") ?? ""}
                            validationMessage={() => props.viewModel.getError("address3")}
                        />
                    </AutoGrid>
                </FormContainerRow>
                <FormContainerRow dc={"100%"}>
                    <AutoGrid m={"0"} columnGap={"30px"} dc={"1fr 1fr 1fr 1fr"}>
                        <ThemedEditText
                            displayName="Town/city"
                            command={props.viewModel.updateCityCommand}
                            isMultiline={false}
                            placeholder="Please enter a town or city"
                            value={() => props.viewModel.getValue("city") ?? ""}
                            validationMessage={() => props.viewModel.getError("city")}
                        />
                        <ThemedEditText
                            displayName="Postcode"
                            command={props.viewModel.updatePostalCodeCommand}
                            isMultiline={false}
                            placeholder="Postcode"
                            value={() => props.viewModel.getValue("postcode") ?? ""}
                            validationMessage={() => props.viewModel.getError("postcode")}
                        />
                    </AutoGrid>
                </FormContainerRow>
            </Show>

            {/* Form Row */}
            <Show if={props.viewModel.canDisplayParentAdd}>
                <ThemedButton mr={3} paletteColor={"primary"} displayName="Add" command={props.viewModel.addCommand!} />
            </Show>
            <Show if={props.viewModel.canDisplayParentCancel}>
                <ThemedButton mr={3} displayName="Cancel" command={props.viewModel.cancelCommand!} />
            </Show>
        </Box>
    );
});
