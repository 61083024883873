import React from "react";
import { observer } from "mobx-react-lite";
import { OverviewViewModel } from "../OverviewViewModel";
import { Container } from "./PieChartShared.styles";
import { QuotesPieChartView } from "./QuotesPieChartView";
import { EnquiriesPieChartView } from "./EnquiriesPieChartView";
import { WorkWonPieChartView } from "./WorkWonPieChartView";

interface IProps {
    viewModel: OverviewViewModel;
}

export const RunningTotals: React.FC<IProps> = observer((props: IProps) => {
    const { viewModel } = props;

    return (
        <Container>
            <EnquiriesPieChartView viewModel={viewModel} />
            <QuotesPieChartView viewModel={viewModel} />
            <WorkWonPieChartView viewModel={viewModel} />
        </Container>
    );
});
