import { Logger } from "index";
import { Endpoint, EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { PlanningApplicationsViewModel } from "../PlanningApplicationsViewModel";
import { PlanningApplicationLiteModel } from "../PlanningApplicationLiteModel";

class Response {
    public live: number = 0;
    public refused: number = 0;
    public approved: number = 0;
    public livePlanningApplicationLites: PlanningApplicationLiteModel[] = [];
}

export class GETPlanningApplicationTotalsEndpoint extends EndpointWithoutRequest<Response> {
    private viewModel: PlanningApplicationsViewModel;
    constructor(viewModel: PlanningApplicationsViewModel) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.Dashboard.Admin.GetPlanningApplicationTotals);
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        this.viewModel.model.fromResponse(response);
        response.livePlanningApplicationLites.forEach((item) => {
            const model = new PlanningApplicationLiteModel();
            model.fromResponse(item);
            this.viewModel.model.livePlanningApplicationLites.push(item);
        });
    }
}
