import { runInAction } from "mobx";

import { EndpointWithoutRequest, Http } from "Application";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { UnassignedTaskItemModel } from "../UnassignedTaskItemModel";
import { UnassignedTasksViewModel } from "../UnassignedTasksViewModel";
import { UnassignedTaskItemViewModel } from "../UnassignedTaskItemViewModel";

class Response {
    public id: string = "";
    public projectTaskGroupId = "";
    public projectTaskId = "";
    public projectLeadId = "";
    public anticipatedEndDate: string | null = null;
    public reference = "";
    public originReference = "";
    public title = "";
    public projectTaskGroupName = "";
    public projectTaskName = "";
    public projectLeadFirstName = "";
    public projectLeadLastName = "";
    public projectLeadDocumentUrl = "";
    public remainingProjectTaskBillableEffort: number | null = null;
}

export class GETUnassignedProjectTasksEndpoint extends EndpointWithoutRequest<Response> {
    private readonly viewModel: UnassignedTasksViewModel;

    constructor(viewModel: UnassignedTasksViewModel) {
        super();
        this.viewModel = viewModel;
        this.verb(Http.Get);
        this.path(AppUrls.Server.Resources.AllUnassignedProjectTasks);
    }

    public async HandleResponseAsync(response: Response[]): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            const tasks: UnassignedTaskItemViewModel[] = [];

            response.forEach((element: any) => {
                const model = new UnassignedTaskItemModel();

                model.fromResponse(element);

                tasks.push(new UnassignedTaskItemViewModel(model));
            });

            this.viewModel.tasks.replace(tasks);
        });
    }
}
