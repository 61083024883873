import { DetailsRow, IColumn, Modal } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { Box, setPageTitle, ThemedButton, ThemedText } from "Application";
import { ThemedDataTable } from "Styles/ThemedPrimitives/Style1/ThemedDataTable";
import { ThemedError } from "Styles/ThemedPrimitives/Style1/ThemedError";
import { ThemedLoader } from "Styles/ThemedPrimitives/Style1/ThemedLoader";
import { CancelLeaveRequestView } from "Views/Leave/Shared/ModalViews/CancelLeaveRequest/CancelLeaveRequestView";
import { DeleteLeaveRequestView } from "Views/Leave/Shared/ModalViews/DeleteLeaveRequest/DeleteLeaveRequestView";
import { NewRequestView } from "Views/Leave/Shared/ModalViews/NewRequest/NewRequestView";
import { LeaveRequestItemViewModel } from "./LeaveRequestItemViewModel";
import { LeaveRequestsViewModel } from "./LeaveRequestsViewModel";
import { DashboardViewModel } from "Views/Dashboard/DashboardViewModel";
import { LeaveRequestsModel } from "./LeaveRequestsModel";

const ThemedButtonStyles = {
    root: {
        height: 30,
        width: 147,
        fontSize: 17,
    },
};

interface IProps {
    parentViewModel: DashboardViewModel;
}

export const LeaveRequestsView: React.FC<IProps> = observer((props: IProps) => {
    // #region Code Behind

    const [viewModel] = useState(() => new LeaveRequestsViewModel(new LeaveRequestsModel(), props.parentViewModel));

    useEffect(() => {
        setPageTitle("Leave requests");
    }, []);

    /**
     * Handles updating the tables column sort settings in response to changes in the viewmodel.
     */
    useEffect(() => {
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter((currCol) => viewModel.getValue("sortKey") === currCol.key)[0];

        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSorted = true;
                currColumn.isSortedDescending = viewModel.getValue("sortDescending");
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = false;
            }
        });

        setColumns(newColumns);
    }, [viewModel.getValue("sortKey"), viewModel.getValue("sortDescending")]);

    /**
     * Gets a key for the row. Must be provided if sorting or filtering is enabled.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The row index.
     *
     * @returns A string key to identify the row.
     */
    const getRowKey = (item: LeaveRequestItemViewModel, index?: number): string => {
        return item.KEY;
    };

    const onRenderRow = (props: any) => {
        return <DetailsRow {...props} styles={{ cell: { color: `${props.item.leaveStatusTypeColor} !important` } }} />;
    };

    const onRenderLeaveType = (item: LeaveRequestItemViewModel, index?: number, column?: IColumn) => {
        return <Box fontSize={"10px"}>{item.renderLeaveTypeContentString}</Box>;
    };

    const onRenderLeaveStatusType = (item: LeaveRequestItemViewModel, index?: number, column?: IColumn) => {
        return (
            <Box fontSize={"10px"}>
                <b style={{ textTransform: "uppercase" }}>{item.renderLeaveStatusTypeContentString}</b> {item.renderResponseReasonContentString}
            </Box>
        );
    };

    const onRenderRequestFromDate = (item: LeaveRequestItemViewModel, index?: number, column?: IColumn) => {
        return <Box fontSize={"10px"}>{item.requestFromContentString}</Box>;
    };

    const onRenderRequestToDate = (item: LeaveRequestItemViewModel, index?: number, column?: IColumn) => {
        return <Box fontSize={"10px"}>{item.requestToContentString}</Box>;
    };

    const onRenderRequestActions = (item: LeaveRequestItemViewModel, index?: number, column?: IColumn) => {
        return (
            <Box display="flex" marginLeft="auto">
                {item.editLeaveCommand.canExecute() && (
                    <ThemedButton
                        styles={{ root: { margin: "0 15px", minWidth: "80px", padding: "0 5px" } }}
                        paletteColor={"primary"}
                        displayName="Edit"
                        command={item.editLeaveCommand}
                        value={item.model.leaveRequest as any}
                    />
                )}
                {item.cancelLeaveCommand.canExecute() && (
                    <ThemedButton
                        styles={{ root: { margin: "0 15px", minWidth: "80px", padding: "0 5px" } }}
                        paletteColor={"primary"}
                        displayName="Cancel"
                        command={item.cancelLeaveCommand}
                        value={item.model.leaveRequest as any}
                    />
                )}
                {item.deleteLeaveCommand.canExecute() && (
                    <ThemedButton
                        styles={{ root: { margin: "0 15px", minWidth: "80px", padding: "0 5px" } }}
                        paletteColor={"primary"}
                        displayName="Delete"
                        command={item.deleteLeaveCommand}
                        value={item.model.leaveRequest as any}
                    />
                )}
            </Box>
        );
    };

    /**
     * Handles an onClick event for the table column header.
     *
     * @param ev Mouse event
     * @param column The column that has been clicked.
     */
    const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const currColumn: IColumn = columns.filter((currCol) => column.key === currCol.key)[0];
        const newSortKey = currColumn.key;
        const newSortDescending = currColumn.key === viewModel.getValue("sortKey") ? !currColumn.isSortedDescending : false;

        viewModel.updateSortCommand.execute(newSortKey, newSortDescending);
    };

    const defaultTableColumns: IColumn[] = [
        {
            fieldName: "requestFrom",
            key: "requestFrom",
            name: "REQUEST FROM",
            maxWidth: 200,
            minWidth: 200,
            isResizable: true,
            isSorted: true,
            isSortedDescending: false,
            onColumnClick: onColumnClick,
            onRender: onRenderRequestFromDate,
        },
        {
            fieldName: "requestTo",
            key: "requestTo",
            name: "REQUEST TO",
            maxWidth: 200,
            minWidth: 200,
            isResizable: true,
            isSorted: true,
            isSortedDescending: false,
            onColumnClick: onColumnClick,
            onRender: onRenderRequestToDate,
        },
        {
            fieldName: "numberOfDays",
            key: "numberOfDays",
            name: "NO. OF DAYS",
            maxWidth: 200,
            minWidth: 200,
            isResizable: true,
            isSorted: true,
            isSortedDescending: false,
            onColumnClick: onColumnClick,
        },
        {
            fieldName: "remainingAllowance",
            key: "remainingAllowance",
            name: "REMAINING ALLOWANCE",
            maxWidth: 200,
            minWidth: 200,
            isResizable: true,
            isSorted: true,
            isSortedDescending: false,
            onColumnClick: onColumnClick,
        },
        {
            fieldName: "leaveType",
            key: "leaveType",
            name: "LEAVE TYPE",
            maxWidth: 200,
            minWidth: 200,
            isResizable: true,
            isSorted: true,
            isSortedDescending: false,
            onColumnClick: onColumnClick,
            onRender: onRenderLeaveType,
        },
        {
            fieldName: "leaveStatusType",
            key: "leaveStatusType",
            name: "LEAVE STATUS",
            minWidth: 200,
            isResizable: true,
            isSorted: true,
            isSortedDescending: false,
            onColumnClick: onColumnClick,
            onRender: onRenderLeaveStatusType,
        },
        {
            fieldName: "",
            key: "actions",
            name: "",
            flexGrow: 1,
            minWidth: 220,
            isResizable: true,
            isSorted: false,
            onRender: onRenderRequestActions,
        },
    ];

    const [columns, setColumns] = useState<IColumn[]>(defaultTableColumns);

    // #endregion Code Behind

    const renderBusy = () => <ThemedLoader isOpen={viewModel.apiClient.IsBusy} />;

    const renderError = () => {
        const isOpen = viewModel.apiClient.IsSubmitted && !viewModel.apiClient.IsBusy && viewModel.apiClient.HaveValidationMessage;
        return <ThemedError command={viewModel.resetServerErrorCommand} isOpen={isOpen} errorMessage={viewModel.apiClient.ValidationMessage} />;
    };

    const renderNewRequestModal = () => {
        return (
            <Modal isOpen={viewModel.canDisplayNewRequest} onDismiss={() => viewModel.cancelNewRequestCommand.execute()}>
                <NewRequestView viewModel={viewModel.newRequestViewModel} />
            </Modal>
        );
    };

    const renderCancelLeaveRequestModal = () => {
        return (
            <Modal isOpen={viewModel.canDisplayCancelRequest} onDismiss={() => viewModel.cancelCancelRequestCommand.execute()}>
                <CancelLeaveRequestView viewModel={viewModel.cancelLeaveRequestViewModel} />
            </Modal>
        );
    };

    const renderDeleteLeaveRequestModal = () => {
        return (
            <Modal isOpen={viewModel.canDisplayDeleteRequest} onDismiss={() => viewModel.cancelDeleteRequestCommand.execute()}>
                <DeleteLeaveRequestView viewModel={viewModel.deleteLeaveRequestViewModel} />
            </Modal>
        );
    };

    const renderWorkspace = () => {
        return (
            <React.Fragment>
                <Box alignItems={"center"} display={"flex"} justifyContent={"space-between"}>
                    <Box alignItems="baseline" display="flex">
                        <ThemedText fontStyle="h3" mr={3}>
                            My Requests
                        </ThemedText>
                        <Box
                            alignItems={"center"}
                            display={"flex"}
                            justifyContent={"space-between"}
                            onClick={() => viewModel.navigateToCalendarCommand.execute()}
                            style={{ cursor: "pointer" }}
                        >
                            <ThemedText>VIEW CALENDAR {">"}</ThemedText>
                        </Box>
                    </Box>

                    <ThemedButton marginTop={3} command={viewModel.displayNewRequestCommand} displayName="New request" paletteColor={"primary"} styles={ThemedButtonStyles} />
                </Box>
                <ThemedText fontStyle="h8">Remaining allowance: {viewModel.numbersOfDaysLeft}</ThemedText>
                <ThemedDataTable getKey={getRowKey} items={viewModel.sortedLeaveRequests} columns={columns} onRenderRow={onRenderRow} />
            </React.Fragment>
        );
    };

    return (
        <Box p={30}>
            {/* Workspace */}
            {renderWorkspace()}

            {/* Busy Overlay */}
            {renderBusy()}

            {/* Error Overlay */}
            {renderError()}

            {/* New Request Modal*/}
            {renderNewRequestModal()}

            {/* Cancel Leave Request Modal*/}
            {renderCancelLeaveRequestModal()}

            {/* Delete Leave Request Modal*/}
            {renderDeleteLeaveRequestModal()}
        </Box>
    );
});
