import styled from "@emotion/styled";
import { Callout, DirectionalHint, IColumn, Link, Modal } from "@fluentui/react";
import { isNullOrUndefined } from "@shoothill/core";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import { Box, formatDate, setPageTitle, ThemedButton, ThemedText } from "Application";
import InfoIcon from "Assets/InfoIcon.svg";
import { ThemedDataTable } from "Styles/ThemedPrimitives/Style1/ThemedDataTable";
import { ThemedError } from "Styles/ThemedPrimitives/Style1/ThemedError";
import { ThemedLoader } from "Styles/ThemedPrimitives/Style1/ThemedLoader";
import { CalloutInformationView } from "./Components/CalloutInformation/CalloutInformationView";
import { SendUpdatesConfirmationView } from "./Components/SendUpdatesConfirmation/SendUpdatesConfirmationView";
import { WeeklyUpdatesItemViewModel } from "./WeeklyUpdatesItemViewModel";
import { WeeklyUpdatesListViewModel } from "./WeeklyUpdatesListViewModel";

const ThemedButtonStyles = {
    root: {
        height: 30,
        width: 147,
        fontSize: 17,
    },
};

const UpdatesStatsBox = styled(Box)`
    align-items: right;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    justify-content: center;
    padding-right: 15px;
`;

const UpdatesCountInfoIcon = styled(Box)`
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url(${InfoIcon});
    height: 18px;
    opacity: 0.33;
    width: 18px;
`;

export const WeeklyUpdatesListView: React.FC = observer(() => {
    // #region Code Behind

    const [viewModel] = useState(() => new WeeklyUpdatesListViewModel());

    useEffect(() => {
        setPageTitle("Weekly updates");
    }, []);

    /**
     * Handles updating the tables column sort settings in response to changes in the viewmodel.
     */
    useEffect(() => {
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter((currCol) => viewModel.getValue("sortKey") === currCol.key)[0];

        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSorted = true;
                currColumn.isSortedDescending = viewModel.getValue("sortDescending");
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = false;
            }
        });

        setColumns(newColumns);
    }, [viewModel.getValue("sortKey"), viewModel.getValue("sortDescending")]);

    /**
     * Gets a key for the row. Must be provided if sorting or filtering is enabled.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The row index.
     *
     * @returns A string key to identify the row.
     */
    const getRowKey = (item: any, index?: number): string => {
        return item.KEY;
    };

    const onRenderDate = (item: WeeklyUpdatesItemViewModel, index?: number, column?: IColumn) => {
        return !isNullOrUndefined(item.projectUpdateCommencementDate) ? formatDate(item.projectUpdateCommencementDate!) : "";
    };

    const onRenderCreatedDate = (item: WeeklyUpdatesItemViewModel, index?: number, column?: IColumn) => {
        return (
            <Box>
                <Box fontSize={"10px"}>{!isNullOrUndefined(item.createdDate) ? formatDate(item.createdDate!) : ""}</Box>
                <Box fontSize={"10px"}>{item.createdByUserName}</Box>
            </Box>
        );
    };

    const onRenderUpdatesCount = (item: WeeklyUpdatesItemViewModel, index?: number, column?: IColumn) => {
        const projectUpdateTransactionId = item.model.projectUpdateTransactionId;
        const fieldName = column?.fieldName!;

        return (
            <Box display="flex" flex={1} alignItems="center" justifyContent="space-between">
                {(() => {
                    switch (fieldName) {
                        case "numberOfUpdatesNotProcessed": {
                            const callOutKey = viewModel.createCalloutKey(item, fieldName);

                            return (
                                <React.Fragment>
                                    <Box>{item.numberOfUpdatesNotProcessed}</Box>
                                    {item.canDisplayNotProcessedIcon && (
                                        <UpdatesCountInfoIcon
                                            id={callOutKey}
                                            onClick={(event: any) => {
                                                event.stopPropagation();
                                                viewModel.displayCalloutInformationCommand.execute(projectUpdateTransactionId, fieldName, callOutKey);
                                            }}
                                        />
                                    )}
                                </React.Fragment>
                            );
                        }

                        case "numberOfUpdatesFailed": {
                            const callOutKey = viewModel.createCalloutKey(item, fieldName);

                            return (
                                <React.Fragment>
                                    <Box>{item.numberOfUpdatesFailed}</Box>
                                    {item.canDisplayFailedIcon && (
                                        <UpdatesCountInfoIcon
                                            id={callOutKey}
                                            onClick={(event: any) => {
                                                event.stopPropagation();
                                                viewModel.displayCalloutInformationCommand.execute(projectUpdateTransactionId, fieldName, callOutKey);
                                            }}
                                        />
                                    )}
                                </React.Fragment>
                            );
                        }

                        default:
                            return <React.Fragment />;
                    }
                })()}
            </Box>
        );
    };

    /**
     * Handles an onClick event for the table column header.
     *
     * @param ev Mouse event
     * @param column The column that has been clicked.
     */
    const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const currColumn: IColumn = columns.filter((currCol) => column.key === currCol.key)[0];
        const newSortKey = currColumn.key;
        const newSortDescending = currColumn.key === viewModel.getValue("sortKey") ? !currColumn.isSortedDescending : false;

        viewModel.updateSortCommand.execute(newSortKey, newSortDescending);
    };

    const defaultTableColumns: IColumn[] = [
        {
            fieldName: "projectUpdateCommencementDate",
            key: "projectUpdateCommencementDate",
            name: "WEEK COMMENCING",
            minWidth: 250,
            isResizable: true,
            isSorted: true,
            isSortedDescending: false,
            onColumnClick: onColumnClick,
            onRender: onRenderDate,
        },
        {
            fieldName: "numberOfUpdatesAvailable",
            key: "numberOfUpdatesAvailable",
            name: "UPDATES AVAILABLE",
            minWidth: 200,
            isResizable: true,
            isSorted: true,
            isSortedDescending: false,
            onColumnClick: onColumnClick,
        },
        {
            fieldName: "numberOfUpdatesProcessed",
            key: "numberOfUpdatesProcessed",
            name: "UPDATES PROCESSED",
            minWidth: 200,
            isResizable: true,
            isSorted: true,
            isSortedDescending: false,
            onColumnClick: onColumnClick,
        },
        {
            fieldName: "numberOfUpdatesNotProcessed",
            key: "numberOfUpdatesNotProcessed",
            name: "UPDATES NOT PROCESSED",
            minWidth: 200,
            isResizable: true,
            isSorted: true,
            isSortedDescending: false,
            onRender: onRenderUpdatesCount,
            onColumnClick: onColumnClick,
        },
        {
            fieldName: "numberOfUpdatesFailed",
            key: "numberOfUpdatesFailed",
            name: "UPDATES FAILED",
            minWidth: 200,
            isResizable: true,
            isSorted: true,
            isSortedDescending: false,
            onRender: onRenderUpdatesCount,
            onColumnClick: onColumnClick,
        },
        {
            fieldName: "projectUpdateTransactionStatus",
            key: "projectUpdateTransactionStatus",
            name: "STATUS",
            minWidth: 250,
            isResizable: true,
            isSorted: true,
            isSortedDescending: false,
        },
        {
            fieldName: "createdDate",
            key: "createdDate",
            name: "CREATED",
            minWidth: 250,
            isResizable: true,
            isSorted: true,
            isSortedDescending: false,
            onRender: onRenderCreatedDate,
            onColumnClick: onColumnClick,
        },
    ];

    const [columns, setColumns] = useState<IColumn[]>(defaultTableColumns);

    // #endregion Code Behind

    const renderBusy = () => <ThemedLoader isOpen={viewModel.apiClient.IsBusy} />;

    const renderError = () => {
        const isOpen = viewModel.apiClient.IsSubmitted && !viewModel.apiClient.IsBusy && viewModel.apiClient.HaveValidationMessage;

        return <ThemedError command={viewModel.resetServerErrorCommand} isOpen={isOpen} errorMessage={viewModel.apiClient.ValidationMessage} />;
    };

    const renderSendUpdatesConfirmationModal = () => {
        return (
            <Modal isOpen={viewModel.canDisplaySendUpdatesConfirmation} onDismiss={() => viewModel.cancelSendUpdatesCommand.execute()}>
                <SendUpdatesConfirmationView viewModel={viewModel.sendUpdatesConfirmationViewModel!} />
            </Modal>
        );
    };

    const renderCallout = () => {
        if (viewModel.canDisplayCalloutInformation) {
            return (
                <Callout
                    directionalHint={DirectionalHint.topAutoEdge}
                    onDismiss={() => viewModel.cancelCalloutInformationCommand.execute()}
                    gapSpace={0}
                    setInitialFocus
                    target={`#${viewModel.calloutInformationViewModel!.model.callOutKey}`}
                >
                    <CalloutInformationView viewModel={viewModel.calloutInformationViewModel!} />
                </Callout>
            );
        }

        return null;
    };

    return (
        <Box>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                <Box alignItems="baseline" display="flex">
                    <ThemedText fontStyle="h3" mr={3}>
                        History
                    </ThemedText>
                </Box>
                <Box display={"flex"} alignItems="center" marginTop={3}>
                    <UpdatesStatsBox>
                        <Box>
                            <b>WEEK COMMENCING: </b> {!isNullOrUndefined(viewModel.projectUpdateCommencementDate) ? formatDate(viewModel.projectUpdateCommencementDate!) : ""}
                        </Box>
                        <Box>
                            <b>UPDATES AVAILABLE: </b> {viewModel.numberOfUpdatesAvailable}
                        </Box>
                    </UpdatesStatsBox>
                    <ThemedButton command={viewModel.displaySendUpdatesConfirmationCommand} displayName="Send updates" paletteColor={"primary"} styles={ThemedButtonStyles} />
                </Box>
            </Box>
            <ThemedDataTable getKey={getRowKey} items={viewModel.sortedWeeklyUpdates} columns={columns} />

            {/* Busy Overlay */}
            {renderBusy()}

            {/* Error Overlay */}
            {renderError()}

            {/* Send Updates Modal */}
            {renderSendUpdatesConfirmationModal()}

            {/* Callout */}
            {renderCallout()}
        </Box>
    );
});
