import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

export class InvoiceForecastingModel extends ModelBase<InvoiceForecastingModel> {
    public totalInstructedHours: number = 0;
    public totalInstructedValue: number = 0;

    public totalCapacityHours: number = 0;
    public totalCapacityValue: number = 0;

    public totalSentQuoteHours: number = 0;
    public totalSentQuoteValue: number = 0;

    public totalRemainingHolidayHours: number = 0;
    public totalRemainingHolidayValue: number = 0;

    public totalForecastedCapacityHours: number = 0;
    public totalForecastedCapacityValue: number = 0;

    public capacities = observable<InvoiceForecastingCapacityModel>([]);

    constructor() {
        super();

        makeObservable(this, {
            // Obvservables
            totalInstructedHours: observable,
            totalInstructedValue: observable,
            totalCapacityHours: observable,
            totalCapacityValue: observable,
            totalSentQuoteHours: observable,
            totalSentQuoteValue: observable,
            totalRemainingHolidayHours: observable,
            totalRemainingHolidayValue: observable,
            totalForecastedCapacityHours: observable,
            totalForecastedCapacityValue: observable,
            capacities: observable,
        });
    }
}

export class InvoiceForecastingCapacityModel extends ModelBase<InvoiceForecastingCapacityModel> {
    public id: string = "";
    public year: number = 0;
    public month: string = "";
    public hours: number = 0;
    public value: number = 0;
    public isActive: boolean = false;
    constructor() {
        super();

        makeObservable(this, {
            // Obvservables
            id: observable,
            year: observable,
            month: observable,
            hours: observable,
            value: observable,
            isActive: observable,
        });
    }
}
