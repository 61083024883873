import React, { useEffect, useState, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { AnimatePresence, Reorder } from "framer-motion";
import styled from "@emotion/styled";

import { Box, setPageTitle, theme, ThemedButton, ThemedEditText } from "Application";
import { ServiceGroupView } from "./ServiceGroup/ServiceGroupView";
import { ServiceGroupModel } from "./ServiceGroup/ServiceGroupModel";
import { ServiceGroupsViewModel } from "./ServiceGroupsViewModel";

const ThemedButtonStyles = {
    root: {
        height: 30,
        width: 147,
        fontSize: 17,
    },
};

const controlStyles = {
    root: {
        width: "610px",
        height: "39px",
        fontSize: "17px",
        borderColor: theme.palette.common.border,
    },
};

const NewServiceGroupContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 50px;
    padding-top: 10px;
    align-items: center;
    column-gap: 5px;
    margin-top: 50px;
    margin-bottom: 0px;
    width: 100%;
    border: 1px dashed #ff4b22;
    position: relative;
`;

const NewServiceButtonGroup = styled.div`
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
`;

export const ServiceGroupsView: React.FC = observer(() => {
    const [viewModel] = useState(() => new ServiceGroupsViewModel());

    useEffect(() => {
        setPageTitle("Services");
    }, []);

    const handleReorder = useCallback(
        (models: ServiceGroupModel[]) => {
            viewModel.model.serviceGroupModels.replace(models);
        },
        [viewModel],
    );

    const renderAddNewServiceGroup = () => {
        if (viewModel.canDisplayNewServiceGroup) {
            const newServiceGroupViewModel = viewModel.newServiceGroupViewModel!;

            return (
                <NewServiceGroupContainer>
                    <ThemedEditText
                        command={newServiceGroupViewModel.updateNameCommand}
                        displayName={"Stage Title*"}
                        placeholder={"Please enter a stage title"}
                        styles={controlStyles}
                        validationMessage={() => newServiceGroupViewModel.getError("name")}
                        value={() => newServiceGroupViewModel.getValue("name")}
                    />
                    <NewServiceButtonGroup>
                        <ThemedButton paletteColor="primary" command={viewModel.submitNewServiceGroupCommand} displayName={"Add"} styles={ThemedButtonStyles} />
                        <ThemedButton paletteColor="default" command={viewModel.cancelAddNewServiceGroupCommand} displayName={"Cancel"} styles={ThemedButtonStyles} />
                    </NewServiceButtonGroup>
                </NewServiceGroupContainer>
            );
        }
    };

    const renderServiceGroupList = () => {
        return (
            <AnimatePresence>
                <Reorder.Group values={viewModel.model.serviceGroupModels} onReorder={handleReorder}>
                    {viewModel.model.serviceGroupModels.map((model) => (
                        <Reorder.Item
                            style={{ listStyleType: "none" }}
                            key={model.KEY}
                            value={model}
                            whileDrag={{ scale: 0.9, backgroundColor: "lightgray" }}
                            whileHover={{ cursor: "grab" }}
                            whileTap={{ cursor: "grabbing" }}
                            onDragEnd={() => viewModel.onDragEndCommand.execute()}
                        >
                            <ServiceGroupView parentViewModel={viewModel} model={model} />
                        </Reorder.Item>
                    ))}
                </Reorder.Group>
            </AnimatePresence>
        );
    };

    return (
        <Box>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                <ThemedButton marginTop={3} paletteColor="primary" command={viewModel.displayNewServiceGroupCommand} displayName={"New Stage"} styles={ThemedButtonStyles} />
            </Box>

            {renderAddNewServiceGroup()}
            <Box marginTop={50}>{renderServiceGroupList()}</Box>
        </Box>
    );
});
