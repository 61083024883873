import { ViewModelBase } from "@shoothill/core";
import { computed, makeObservable, observable } from "mobx";

import { APIClient } from "Application";
import { GETProjectTaskGroupOverviewByIdEndpoint } from "./Endpoints/GETProjectTaskGroupOverviewByProjectId";
import { ProjectOverviewModel } from "./ProjectOverviewModel";
import { ProjectViewModel } from "../ProjectViewModel";
import { ProjectChartViewModel } from "./SubViews/Projects/ProjectChartViewModel";
import { ProjectChartModel } from "./SubViews/Projects/ProjectChartModel";
import { ProjectTaskGroupChartViewModel } from "./SubViews/ProjectTaskGroups/ProjectTaskGroupChartViewModel";

export class ProjectOverviewViewModel extends ViewModelBase<ProjectOverviewModel> {
    public apiClient = new APIClient();

    public parentViewModel: ProjectViewModel;
    public projectTaskGroupChartViewModels = observable<ProjectTaskGroupChartViewModel>([]);

    constructor(parentViewModel: ProjectViewModel) {
        super(new ProjectOverviewModel());

        this.parentViewModel = parentViewModel;

        makeObservable(this, {
            canDisplayProjectTaskGroupsChart: computed,
            canDisplayProjectChart: computed,
            projectChartViewModel: computed,
        });

        this.apiClient.sendAsync(new GETProjectTaskGroupOverviewByIdEndpoint(this.parentViewModel.model.id, this));
    }

    public get canDisplayProjectTaskGroupsChart(): boolean {
        return this.projectTaskGroupChartViewModels.length > 0;
    }

    public get canDisplayProjectChart(): boolean {
        return this.projectChartViewModel !== null;
    }

    public get projectChartViewModel() {
        if (this.projectTaskGroupChartViewModels.length > 0) {
            const model = new ProjectChartModel();

            model.id = "id-project";
            model.name = "";
            model.startDate = this.projectTaskGroupChartViewModels.map((vm) => vm.model.startDate).reduce((lhs, rhs) => (lhs!.getTime() < rhs!.getTime() ? lhs! : rhs!));
            model.endDate = this.projectTaskGroupChartViewModels.map((vm) => vm.model.endDate).reduce((lhs, rhs) => (lhs!.getTime() > rhs!.getTime() ? lhs! : rhs!));

            return new ProjectChartViewModel(model);
        }

        return null;
    }
}
