import _ from "lodash";
import { runInAction } from "mobx";

import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { EnquiryViewModel } from "./EnquiryViewModel";
import { EnquiryResponse } from "./Shared/EnquiryResponse";

export class GETEnquiryWithRelatedByIdEndpoint extends EndpointWithoutRequest<Response> {
    private readonly viewModel: EnquiryViewModel;

    constructor(quoteId: string, viewModel: EnquiryViewModel) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.Enquiries.GetEnquiryWithRelatedById.replace(":id", quoteId));
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: EnquiryResponse): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            EnquiryResponse.subEnquiryResponsePart(response, this.viewModel);

            EnquiryResponse.customerResponsePart(response, this.viewModel);
            EnquiryResponse.notesResponsePart(response, this.viewModel);

            EnquiryResponse.remainingResponsePart(response, this.viewModel);
        });
    }
}
