import { IStyle, mergeStyleSets } from "@fluentui/react";
import React from "react";
import { observer } from "mobx-react-lite";

import { AutoGrid, theme, ThemedButton, ThemedText, ThemedTransparentIconButton } from "Application";
import { CrossSVG } from "Assets/CrossSVG";
import { FormSeparator } from "Views/Shared/SharedComponents";
import { ResetAllowancesViewModel } from "./ResetAllowancesViewModel";

interface IProps {
    viewModel: ResetAllowancesViewModel | null;
}

interface IApproveRequestViewStyles {
    root: IStyle;
    header: IStyle;
    body: IStyle;
    separator: IStyle;
    footer: IStyle;
    iconButton: IStyle;
}

const styleDefinitions: IApproveRequestViewStyles = {
    root: {
        width: "700px",
    },
    header: {
        alignItems: "center",
        backgroundColor: "#F3F3F3",
        display: "flex",
        height: "75px",
        justifyContent: "space-between",
        padding: "20px 30px",
    },
    body: {
        padding: "30px",
    },
    separator: {
        "&&": {
            lineHeight: "0px",
            margin: "0px 30px",
        },
    },
    footer: {
        display: "flex",
        height: "75px",
        padding: "20px 30px",
    },
    iconButton: {
        g: {
            stroke: theme.palette.defaultTr.text,
        },
    },
};

export const ResetAllowancesView: React.FC<IProps> = observer((props) => {
    // #region Code Behind

    const viewModel = props.viewModel;
    const styles = mergeStyleSets(styleDefinitions);

    // #endregion Code Behind

    if (viewModel) {
        return (
            <div className={styles.root}>
                <div className={styles.header}>
                    <ThemedText fontStyle="h2">Reset Allowances</ThemedText>
                    <ThemedTransparentIconButton command={viewModel.cancelCommand} icon={<CrossSVG className={styles.iconButton} />} shape="round" />
                </div>
                <div className={styles.body}>
                    <ThemedText>
                        If not already done for the new leave year, this action will set the allowance to the employees default annual leave allowance plus any days currently
                        remaining.
                    </ThemedText>
                    <br />
                    <ThemedText>You can also do this action for any new employees added to the system.</ThemedText>
                    <br />
                    <ThemedText>
                        <span style={{ color: "red", fontWeight: "bold" }}>
                            If an employee has days remaining that you do not want carried into the new leave year, adjust their current allowance so the days remaining is zero.{" "}
                            <span style={{ textDecoration: "underline" }}>Please do this using the adjustment button against each employee before proceeding with the reset.</span>
                        </span>
                    </ThemedText>
                </div>
                <FormSeparator className={styles.separator} />
                <AutoGrid m={0} className={styles.footer} columnGap={"30px"} dc={"135px 135px"}>
                    <ThemedButton styles={{ root: { padding: "0 5px" } }} paletteColor={"primary"} displayName="Reset" command={viewModel.submitCommand} />
                    <ThemedButton styles={{ root: { padding: "0 5px" } }} displayName="Cancel" command={viewModel.cancelCommand} />
                </AutoGrid>
            </div>
        );
    }

    return <React.Fragment />;
});
