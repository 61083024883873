// Supporting Constants
export const SERVER_DEBOUNCE_PERIOD_MS = 200;

// Quote Id Constants
// This is used to mark the currently active version of the
// quote. Historical revisions will use the actual id.
export const MODELID_CURRENTQUOTE = "ID_CURRENTQUOTE";

// Project Weekly Update Constants
// This is used to mark the currently active weekly update.
// Historical updates will use the actual id.
export const MODELID_CURRENTPROJECTUPDATE = "ID_CURRENTPROJECTUPDATE";

// Project Type Constants
// These constants are the types and not the display names!
// Never use display names for matching.
export const PROJECTTYPE_DOMESTICTYPE = "Domestic";
export const PROJECTTYPE_COMMERCIALTYPE = "Commercial";

// Project Task Assignment Constants
// These constants are the types and not the display names!
// Never use display names for matching.
export const PROJECTTASKASSIGNMENTSTATUSTYPE_ASSIGNEDTYPE = "Assigned";
export const PROJECTTASKASSIGNMENTSTATUSTYPE_UPDATEDTYPE = "Updated";
export const PROJECTTASKASSIGNMENTSTATUSTYPE_COMPLETEDTYPE = "Complete";
export const PROJECTTASKASSIGNMENTSTATUSTYPE_BILLEDTYPE = "Billed";

// Contact Type Constants
// These constants are the types and not the display names!
// Never use display names for matching.
export const CONTACTTYPE_DOMESTICTYPE = "Domestic";
export const CONTACTTYPE_COMMERCIALTYPE = "Commercial";

// Enquiry Status Constants
export const ENQUIRYSTATUS_NOTYPE = "";

// Quote Status Constants
// These constants are the types and not the display names!
// Never use display names for matching.
export const QUOTESTATUS_NOTYPE = "";
export const QUOTESTATUS_DRAFTTYPE = "Draft";
export const QUOTESTATUS_PENDINGAPPROVALTYPE = "Pending";
export const QUOTESTATUS_APPROVEDTYPE = "Approved";
export const QUOTESTATUS_SENTTYPE = "Sent";

// Project Status Constants
// These constants are the types and not the display names!
// Never use display names for matching.
export const PROJECTSTATUS_NOTYPE = "";
export const PROJECTSTATUS_NEWPROJECT = "NewProject";
export const PROJECTSTATUS_INSTRUCTED = "Instructed";
export const PROJECTSTATUS_INPROGRESS = "InProgress";
export const PROJECTSTATUS_ONHOLD = "OnHold";
export const PROJECTSTATUS_COMPLETED = "Completed";

// Note Context
// Used to help determine how we should display notes from
// various parts of the system. For example NOTECONTEXT_QUOTE
// will tell the Notes view how to display enquiry notes when
// view from a Quote.
export const NOTECONTEXT_ENQUIRY = "Enquiry";
export const NOTECONTEXT_QUOTE = "Quote";
export const NOTECONTEXT_PROJECT = "Project";

// User Role Constants
export const USERROLE_ADMIN = "admin";
export const USERROLE_SENIORASSOCIATE = "seniorassociate";
export const USERROLE_ARCHITECT = "architect";
export const USERROLE_USER = "user";
export const USERROLE_PROJECTSUPPORT = "projectsupport";

// Note type Constants
export const USER_EMAIL = "USER_EMAIL";
export const USER_PHONECALL = "USER_PHONECALL";
export const GENERAL_NOTE = "GENERAL_NOTE";

// Task Assignment Note Context
export const TA_NOTECONTEXT_ASSIGNMENT = "Assignment";
export const TA_NOTECONTEXT_BILLED = "Billed";
export const TA_NOTECONTEXT_COMPLETED = "Completed";

//Financial Period Type

export const CURR_CALENDAR_WEEK = "CURR_CALENDAR_WEEK";
export const CURR_CALENDAR_MONTH = "CURR_CALENDAR_MONTH";
export const CURR_CALENDAR_QUARTER = "CURR_CALENDAR_QUARTER";
export const CURR_CALENDAR_YEAR = "CURR_CALENDAR_YEAR";
export const PREV_CALENDAR_WEEK = "PREV_CALENDAR_WEEK";
export const PREV_CALENDAR_MONTH = "PREV_CALENDAR_MONTH";
export const PREV_CALENDAR_QUARTER = "PREV_CALENDAR_QUARTER";
export const PREV_CALENDAR_YEAR = "PREV_CALENDAR_YEAR";
export const CURR_FINANCIAL_YEAR = "CURR_FINANCIAL_YEAR";
export const PREV_FINANCIAL_YEAR = "PREV_FINANCIAL_YEAR";

//Note Type
export const QUOTE_LOST = "QUOTE_LOST";
export const ENQUIRY_LOST = "ENQUIRY_LOST";

//Source of Enquiry Type
export const SOURCE_OF_ENQUIRY_GOOGLE = "GOOGLE";
export const SOURCE_OF_ENQUIRY_EXISTING_CLIENT = "EXISTING_CLIENT";
export const SOURCE_OF_ENQUIRY_INSTAGRAM = "INSTAGRAM";
export const SOURCE_OF_ENQUIRY_FACEBOOK = "FACEBOOK";
export const SOURCE_OF_ENQUIRY_LINKEDIN = "LINKEDIN";
export const SOURCE_OF_ENQUIRY_ADVERTISEMENT = "ADVERTISEMENT";
export const SOURCE_OF_ENQUIRY_REFERRAL = "REFERRAL";
export const SOURCE_OF_ENQUIRY_WALK_IN = "WALK_IN";
export const SOURCE_OF_ENQUIRY_UNKNOWN = "UNKNOWN";
export const SOURCE_OF_ENQUIRY_OTHER = "OTHER";

//region PlaNning Application
export const PLANNING_APPLICATION_STATUS_LIVE = "LIVE";
export const PLANNING_APPLICATION_STATUS_APPROVED = "APPROVED";
export const PLANNING_APPLICATION_STATUS_REJECTED = "REJECTED";
export const PLANNING_APPLICATION_STATUS_WITHDRAWN = "WITHDRAWN";
