import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { nanoid } from "nanoid";

import { Validator } from "Application/Validation";
import { CustomerItemModel } from "Views/Shared/Customer/CustomerItemModel";

export class QuoteItemModel extends ModelBase<QuoteItemModel> {
    // Note: some observables are predefined in the ModelBase class.
    // If any errors arise, check there to see if the property exists
    // and if so, delete it from here.
    // public createdDate: Date | null = null;
    // public updatedDate: Date | null = null;

    public KEY: string = nanoid();

    public id: string = "";
    public quoteStatusId = "";
    public contactTypeId = "";
    public customerId = "";
    public reference = "";
    public title = "";
    public hours = "";
    public value = 0;
    public confidence = 0;
    public desirability = 0;
    public contactNumber1 = "";
    public contactNumber2 = "";
    public emailAddress = "";
    public emailAddress2 = "";
    public contactTypeName = "";
    public customerName = "";
    public customerFirstName = "";
    public customerLastName = "";
    public quoteStatusName = "";
    public quoteStatusForegroundColor = "";
    public issueDate: Date | null = null;
    public revision = "";
    public letterTemplateId: string | null = null;
    public termsAndConditionsId: string | null = null;
    public lastActionDate: Date | null = null;
    public lastActionByUserId = null;
    public lastActionFirstName = null;
    public lastActionLastName = null;
    //quote lead
    public quoteLeadId: string | null = null;
    public quoteLeadFirstName: string | null = null;
    public quoteLeadLastName: string | null = null;
    //person visiting
    public enquiryPersonVisitingId: string | null = null;
    public enquiryPersonVisitingFirstName: string | null = null;
    public enquiryPersonVisitingLastName: string | null = null;
    public customerItemModel: CustomerItemModel | null = null;

    constructor() {
        super();
        makeObservable(this, {
            id: observable,
            customerItemModel: observable,
        });
    }
}
export class QuotesListModelValidator extends Validator<QuoteItemModel> {
    constructor() {
        super();
    }
}
