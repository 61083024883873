import { runInAction } from "mobx";

import { Endpoint, Http } from "Application";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { ProgrammingViewModel } from "../ProgrammingViewModel";
import { UnassignedTasksItemViewModel } from "../UnassignedTasksSubView/UnassignedTasksItemViewModel";
import { UnassignedTasksItemModel } from "../UnassignedTasksSubView/UnassignedTasksItemModel";
import { InProgressItemViewModel } from "../InProgressSuvbView/InProgressItemViewModel";
import { InProgressItemModel } from "../InProgressSuvbView/InProgressItemModel";
import { InProgressGroupViewModel } from "../InProgressSuvbView/InProgressGroupViewModel";

class Request {
    userId: string | null = null;
    filterStartDate: Date | null = null;
    projectId: string | null = null;
}

class Response {
    public projectStatusId: string = "";
    public noOfProjectTasks: number | null = null;
    public noOfUnassignedOrPartialTasks: number | null = null;
    public noOfAssignmentsInProgress: number | null = null;

    unassignedTasks: UnassignedTaskResponse[] = [];
    assignedTasks: any[] = [];
    assignedTaskGroups: any[] = [];
    assignedTaskGroupItems: any[] = [];
}

class UnassignedTaskResponse {
    public id: string = "";
    public projectTaskName: string = "";
    public projectTaskIsBilledHourly: boolean = false;
    public projectTaskOrdinal: number = 0;
    public anticipatedEndDate: Date | null = null;
    public manHours: number | null = null;
    public remainingProjectTaskBillableEffort: number = 0;
}

export class GETProgrammingByIdEndpoint extends Endpoint<Request, Response> {
    private readonly viewModel: ProgrammingViewModel;

    constructor(viewModel: ProgrammingViewModel) {
        super();

        this.verb(Http.Post);
        this.path(AppUrls.Server.Resources.GetStaffProjectDetailsProgrammingByUserId);
        this.viewModel = viewModel;
    }

    public async HandleRequestAsync(): Promise<any> {
        const request = new Request();

        request.userId = this.viewModel.parentViewModel.model.userId;
        request.filterStartDate = this.viewModel.parentViewModel.model.filterStartDate;
        request.projectId = this.viewModel.projectId;

        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            // Process statistics for parent viewmodel.
            this.viewModel.parentStaffDetailsItemViewModel.model.projectStatusId = response.projectStatusId;
            this.viewModel.parentStaffDetailsItemViewModel.model.noOfProjectTasks = response.noOfProjectTasks;
            this.viewModel.parentStaffDetailsItemViewModel.model.noOfUnassignedOrPartialTasks = response.noOfUnassignedOrPartialTasks;
            this.viewModel.parentStaffDetailsItemViewModel.model.noOfAssignmentsInProgress = response.noOfAssignmentsInProgress;

            // Process unassigned tasks.
            const unassignedTasks: UnassignedTasksItemViewModel[] = [];

            response.unassignedTasks.forEach((unassignedTask: any) => {
                const model = new UnassignedTasksItemModel();

                model.fromResponse(unassignedTask);

                unassignedTasks.push(new UnassignedTasksItemViewModel(model));
            });

            this.viewModel.unassignedTasksViewModel.tasks.replace(unassignedTasks);

            // Process assigned tasks:
            // Those that do not belong to a project task assignment group.
            const assignedTasks: InProgressItemViewModel[] = [];

            response.assignedTasks
                .filter((assignedTask) => !response.assignedTaskGroupItems.some((atgi: any) => atgi.projectTaskAssignmentId === assignedTask.id))
                .forEach((assignedTask) => {
                    const model = new InProgressItemModel();

                    model.fromResponse(assignedTask);

                    assignedTasks.push(new InProgressItemViewModel(model));
                });

            this.viewModel.inProgressViewModel.tasks.replace(assignedTasks);

            // Those that do belong to a project task assignment group.
            const inProgressGroupViewModels: InProgressGroupViewModel[] = [];

            response.assignedTaskGroups.forEach((assignedTaskGroup: any) => {
                // First create group view model.
                const groupViewModel = new InProgressGroupViewModel();

                groupViewModel.model.fromResponse(assignedTaskGroup);

                groupViewModel.model.name = assignedTaskGroup.name ? assignedTaskGroup.name : "";
                groupViewModel.model.note = assignedTaskGroup.note ? assignedTaskGroup.note : "";

                groupViewModel.model.groupStatusName = assignedTaskGroup.groupStatusName ?? "";
                groupViewModel.model.groupStatusType = assignedTaskGroup.groupStatusType ?? "";
                groupViewModel.model.groupStatusForegroundColor = assignedTaskGroup.groupStatusForegroundColor ?? "";
                groupViewModel.model.groupStatusBackgroundColor = assignedTaskGroup.groupStatusBackgroundColor ?? "";

                inProgressGroupViewModels.push(groupViewModel);

                // Second create items under that group viewmodel.
                const items = response.assignedTaskGroupItems.filter((atgi: any) => atgi.projectTaskAssignmentGroupId === assignedTaskGroup.id);

                response.assignedTasks
                    .filter((assignedTask) => items.some((item: any) => item.projectTaskAssignmentId == assignedTask.id))
                    .forEach((assignedTask) => {
                        const model = new InProgressItemModel();

                        model.fromResponse(assignedTask);

                        groupViewModel.tasks.push(new InProgressItemViewModel(model));
                    });
            });

            this.viewModel.inProgressViewModel.taskAssignmentGroups.replace(inProgressGroupViewModels);
        });

        return Promise.resolve(response);
    }
}
