import { ModelBase } from "@shoothill/core";
import { Validator } from "Application/Validation";
import { IObservableArray, makeObservable, observable } from "mobx";
import { InvoiceHistoryCapacityModel } from "./InvoiceHistoryCapacity/InvoiceHistoryCapacityModel";

export class InvoiceHistoryModel extends ModelBase<InvoiceHistoryModel> {
    //filtering
    public financialYearFilter: string | null = null;
    //model specific
    public capacities: IObservableArray<InvoiceHistoryCapacityModel> = observable.array([]);

    constructor() {
        super();
        makeObservable(this, {
            financialYearFilter: observable,
            capacities: observable,
        });
    }
}
