import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

import { Validator } from "Application/Validation";
import { nanoid } from "nanoid";

export class UserModel extends ModelBase<UserModel> {
    public KEY: string = nanoid();
    public id: string = "";
    public firstName: string = "";
    public lastName: string = "";
    public emailAddress: string = "";
    public lastLoginDate: Date | null = null;
    public currentRoleId: string | null = null;
    public password: string = "";
    public confirmPassword: string = "";
    public documentUrl: string = "";
    public signature: string | null = "";
    public annualLeaveAllowance: number | null = null;
    public dailyChargeableHours: number | null = null;
    public isAssignable: boolean = false;
    public companyTitle: string | null = null;
    public isInvoicingForecastVisible: boolean = false;
    public isProjectOverview: boolean = false;
    public isQuoteLead: boolean = false;

    constructor() {
        super();

        makeObservable(this, {
            // Observables
            id: observable,
            firstName: observable,
            lastName: observable,
            emailAddress: observable,
            lastLoginDate: observable,
            password: observable,
            confirmPassword: observable,
            currentRoleId: observable,
            documentUrl: observable,
            signature: observable,
            annualLeaveAllowance: observable,
            dailyChargeableHours: observable,
            isAssignable: observable,
            companyTitle: observable,
            isInvoicingForecastVisible: observable,
            isProjectOverview: observable,
            isQuoteLead: observable,
        });
    }
}

export class UserModelValidator extends Validator<UserModel> {
    constructor() {
        super();

        this.ruleFor("firstName").notNull().withMessage("Please provide a first name").notEmpty().withMessage("Please provide a first name");
        this.ruleFor("lastName").notNull().withMessage("Please provide a last name").notEmpty().withMessage("Please provide a last name");
        this.ruleFor("emailAddress").notNull().notEmpty().emailAddress().withMessage("Please provide a valid email address");
        this.ruleFor("currentRoleId").notNull().withMessage("Please select a role").notEmpty().withMessage("Please select a role");
        this.ruleFor("confirmPassword").must({ predicate: (confirmPassword, model) => confirmPassword === model.password, message: (value, model) => "Passwords must match" });
        this.ruleFor("annualLeaveAllowance")
            .notNull()
            .withMessage("Please provide an annual leave allowance")
            .greaterThan(0)
            .withMessage("Please provide an annual leave allowance");
        this.ruleFor("dailyChargeableHours").notNull().withMessage("Please provide a daily target").greaterThanOrEqualTo(0).withMessage("Please provide a daily target");
    }
}
