import { Callout, DirectionalHint, PersonaSize, Stack, mergeStyleSets } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React from "react";

import { AutoGrid, Box, ThemedPersonaComboBox, ThemedText, theme } from "Application";
import { QuoteViewModel } from "./QuoteViewModel";
import { FormContainerRow, FormSeparator, RatingButton, RatingsContainer, ThemedTextRatingLabel } from "Views/Shared/SharedComponents";
import styled from "@emotion/styled";
import { ThemedPersona } from "Styles/ThemedPrimitives/Style1/ThemedPersona";
import { LinkButton } from "Components/General/LinkButton";
import { Form } from "react-router-dom";
import { SourceOfEnquiryView } from "../SourceOfEnquiry/SourceOfEnquiryView";

const EventCell = styled(Box)`
    display: flex;
    align-items: flex-start;
    flex: 1;
    cursor: pointer;

    line-height: 1.25;
    max-height: 48px;
    overflow: hidden;
    flex-direction: column;
`;

const EventCallout = styled(Box)`
    display: flex;
    flex: 1;
    min-width: 260px;
    max-height: 400px;
    overflow-y: scroll;
`;

const EventDisplayName = styled(Box)`
    font-size: 10px;
    line-height: 1.25;
    font-family: "Poppins";
    flex: 1;
`;

const EventHeader = styled(Box)`
    display: flex;
    flex: row;
    flex: 1;
    width: 100%;
`;

const labelStyle = mergeStyleSets({
    root: {
        fontSize: theme.fontStyles.h3.fontSize ?? theme.defaultFontStyle.fontSize,
        fontWeight: theme.fontStyles.h3.fontWeight ?? theme.defaultFontStyle.fontWeight,
        letterSpacing: theme.fontStyles.h3.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
        lineHeight: theme.fontStyles.h3.lineHeight ?? theme.defaultFontStyle.lineHeight,
        textTransform: theme.fontStyles.h3.textTransform ?? theme.defaultFontStyle.textTransform,
    },
});

export interface IQuoteViewProps {
    viewModel: QuoteViewModel;
}

export const QuoteReadOnlyView: React.FC<IQuoteViewProps> = observer((props) => {
    const viewModel = props.viewModel;

    const renderQuoteLead = () => {
        if (viewModel.canUpdateQuoteLead) {
            return (
                <ThemedPersonaComboBox
                    command={viewModel.updateQuoteLeadCommand}
                    displayName="Quote Lead"
                    labelStyle={labelStyle}
                    options={viewModel.quoteLeads}
                    placeholder="Please select"
                    value={() => viewModel.getValue("quoteLeadId")}
                />
            );
        } else {
            return (
                <Stack>
                    <ThemedText fontStyle="h3" marginBottom={"20px"}>
                        QUOTE LEAD
                    </ThemedText>
                    <ThemedPersona displayName={viewModel.quoteLeadFullName} size={PersonaSize.size32} imageUrl={viewModel.quoteLeadImageURL} />
                </Stack>
            );
        }
    };

    const renderLostReason = () => {
        return (
            <>
                {viewModel.canDisplayLostReason && (
                    <Box>
                        <Box display={"flex"} alignItems={"center"}>
                            <ThemedText fontStyle={"h3"} marginRight={"10px"}>
                                LOST REASON:
                            </ThemedText>
                            <ThemedText fontStyle="p">{viewModel.lostReason}</ThemedText>
                        </Box>
                        {!viewModel.canDisplayCallout && (
                            <Box>
                                <ThemedText fontStyle="p">{viewModel.lostReasonNoteDetail}</ThemedText>
                            </Box>
                        )}
                        {viewModel.canDisplayCallout && (
                            <Box>
                                <EventCell id={viewModel.calloutButtonId} style={{ width: "fit-content", marginLeft: "-5px" }}>
                                    <EventHeader>
                                        <LinkButton paletteColor={"default"} displayName={viewModel.lostReasonNoteDetailButtonText} command={viewModel.calloutVisibilityCommand} />
                                    </EventHeader>
                                </EventCell>
                                {viewModel.isCalloutVisible && (
                                    <Callout
                                        directionalHint={DirectionalHint.bottomAutoEdge}
                                        onDismiss={() => viewModel.setIsCalloutVisible(false)}
                                        target={`#${viewModel.calloutButtonId}`}
                                    >
                                        <EventCallout maxWidth={"600px"} padding={"7px 7px 12px 7px"} minHeight={"0px"}>
                                            <Box flex={1}>
                                                <EventDisplayName>
                                                    <ThemedText fontStyle="p">{viewModel.lostReasonNoteDetail}</ThemedText>
                                                </EventDisplayName>
                                            </Box>
                                        </EventCallout>
                                    </Callout>
                                )}
                            </Box>
                        )}
                    </Box>
                )}
            </>
        );
    };

    if (viewModel.canDisplayForQuickQuote) {
        return (
            <>
                <FormContainerRow dc="1fr" key={viewModel.canDisplayForQuickQuote.toString()}>
                    {/* Link to an existing project */}
                    <Stack>
                        <ThemedText fontStyle={"h3"} marginBottom={"20px"}>
                            LINK TO EXISTING PROJECT
                        </ThemedText>
                        <ThemedText fontStyle={"p"} marginBottom={"5px"}>
                            {viewModel.projectDisplayName}
                        </ThemedText>
                    </Stack>
                </FormContainerRow>
                <FormContainerRow dc="10% 1fr">
                    <></>
                    <Box>
                        <ThemedText fontStyle="h3" marginBottom={"12px"} padding={"5px 0px"}>
                            Quote Date
                        </ThemedText>
                        <ThemedText fontStyle="p">{viewModel.formattedQuoteDate}</ThemedText>
                    </Box>
                </FormContainerRow>
            </>
        );
    } else {
        return (
            <React.Fragment>
                <FormContainerRow dc="10% 10% 290px auto 1fr" key={viewModel.canDisplayForQuickQuote.toString()}>
                    <Stack>
                        <ThemedText fontStyle={"h3"} marginBottom={"20px"}>
                            LEAD IN
                        </ThemedText>
                        <ThemedText fontStyle={"p"} marginBottom={"5px"}>
                            {`${viewModel.getValue("leadInWeeks")} weeks`}
                        </ThemedText>
                    </Stack>
                    <Stack>
                        <ThemedText fontStyle={"h3"} marginBottom={"20px"}>
                            DURATION
                        </ThemedText>
                        <ThemedText fontStyle={"p"} marginBottom={"5px"}>
                            {`${viewModel.getValue("durationWeeks")} weeks`}
                        </ThemedText>
                    </Stack>

                    {/* Confidence and Desirability Controls in 2 parts */}
                    <AutoGrid m={"0"} columnGap={"30px"} dc={"1fr 1fr"}>
                        <Stack>
                            <ThemedTextRatingLabel>CONFIDENCE</ThemedTextRatingLabel>
                            <RatingsContainer>
                                {viewModel.ratings.map((item, index) => {
                                    return index < parseInt(viewModel.getValue("confidence")) ? (
                                        <span key={index} className="active big-rating-round" />
                                    ) : (
                                        <span key={index} className="big-rating-round" />
                                    );
                                })}
                            </RatingsContainer>
                        </Stack>
                        <Stack>
                            <ThemedTextRatingLabel>DESIRABILITY</ThemedTextRatingLabel>
                            <RatingsContainer>
                                {viewModel.ratings.map((item, index) => {
                                    return index < parseInt(viewModel.getValue("desirability")) ? (
                                        <span key={index} className="active purple big-rating-round" />
                                    ) : (
                                        <span key={index} className="big-rating-round" />
                                    );
                                })}
                            </RatingsContainer>
                        </Stack>
                    </AutoGrid>

                    {/* Link to an existing project */}
                    <Stack>
                        <ThemedText fontStyle={"h3"} marginBottom={"20px"}>
                            LINK TO EXISTING PROJECT
                        </ThemedText>
                        <ThemedText fontStyle={"p"} marginBottom={"5px"}>
                            {viewModel.projectDisplayName}
                        </ThemedText>
                    </Stack>
                </FormContainerRow>
                <FormContainerRow dc="10% 10% 10% 1fr" key={viewModel.canDisplayForQuickQuote.toString()}>
                    <Box>
                        <Stack>
                            <ThemedText fontStyle="h3" marginBottom={"20px"}>
                                QUOTE LEAD
                            </ThemedText>
                            <ThemedPersona displayName={viewModel.quoteLeadFullName} size={PersonaSize.size32} imageUrl={viewModel.quoteLeadImageURL} />
                        </Stack>
                    </Box>
                    <Box>
                        <ThemedText fontStyle="h3" marginBottom={"12px"} padding={"5px 0px"}>
                            Quote Date
                        </ThemedText>
                        <ThemedText fontStyle="p">{viewModel.formattedQuoteDate}</ThemedText>
                    </Box>
                    {viewModel.canShowApprovedByDetails && (
                        <>
                            <Stack>
                                <ThemedText fontStyle="h3" marginBottom={"20px"}>
                                    Approved by
                                </ThemedText>
                                <ThemedPersona displayName={viewModel.approvedByFullName} size={PersonaSize.size32} imageUrl={viewModel.approvedByImageURL} />
                            </Stack>
                            <Box>
                                <ThemedText fontStyle="h3" marginBottom={"12px"} padding={"5px 0px"}>
                                    Approved Date
                                </ThemedText>
                                <ThemedText fontStyle="p">{viewModel.formattedApprovedDate}</ThemedText>
                            </Box>
                        </>
                    )}
                </FormContainerRow>
                <FormContainerRow>{renderLostReason()}</FormContainerRow>

                <FormSeparator />
            </React.Fragment>
        );
    }
});
