import styled from "@emotion/styled";
import { Modal } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import { AutoGrid, Box, ThemedButton, ThemedText, setPageTitle } from "Application";
import { PhoneIcon } from "Assets/Icons/PhoneIcon";
import { AddressIcon } from "Assets/Icons/AddressIcon";
import { EmailIcon } from "Assets/Icons/EmailIcon";
import { EditIcon } from "Assets/Icons/EditIcon";
import { CrossSVG } from "Assets/CrossSVG";
import { ContactCallout, ICalloutState } from "Styles/ThemedPrimitives/Style1/ContactCallout";
import { ThemedError } from "Styles/ThemedPrimitives/Style1/ThemedError";
import { ThemedLoader } from "Styles/ThemedPrimitives/Style1/ThemedLoader";
import { FilesReadOnlyView } from "Views/Shared/Files/FilesReadOnlyView";
import { FormContainer, FormContainerRow, ItemInformationCell, ItemInformationContainer, NavigationContainer, PageContainer, TitleContainer } from "Views/Shared/SharedComponents";
import { DeleteSupplierView } from "./ModalViews/DeleteSupplierView";
import SupplierDetailViewModel from "./SupplierDetailViewModel";
import { isEmptyOrWhitespace, isNullOrEmpty } from "@shoothill/core";

export const SupplierDetail: React.FC = observer(() => {
    // #region Code Behind

    const { id } = useParams();
    const [viewModel] = useState(() => new SupplierDetailViewModel(id!));
    const defaultCalloutState: ICalloutState = { isCalloutVisble: false, targetId: "", isEmail: false, data: "" };
    const [callout, setIsCalloutVisible] = useState<ICalloutState>(defaultCalloutState);

    useEffect(() => {
        setPageTitle("Supplier Detail");
    }, []);

    const titlePaddingBottom = () => {
        return viewModel.canDisplaySupplierContactNumber1 || viewModel.canDisplaySupplierContactNumber2 || viewModel.canDisplaySupplierAddress ? "15px" : "0";
    };

    const renderDeleteModalContent = () => {
        return (
            <Modal isOpen={viewModel.canDisplayDelete} onDismiss={() => viewModel.closeDeleteModalCommand.execute()}>
                <DeleteSupplierView viewModel={viewModel} />
            </Modal>
        );
    };

    const ButtonGroup = styled.div`
        display: flex;
        align-items: center;
        gap: 30px;
        .redBtn {
            svg {
                margin-right: 8px;
            }
            &:hover {
                color: #fff;
            }
        }
    `;

    const CustomerCrossSVG = styled(CrossSVG)`
        g {
            stroke: #fff;
        }
    `;

    // #endregion Code Behind

    const renderTitle = () => {
        return (
            <TitleContainer>
                <Box display={"flex"} justifyContent={"space-between"} mb={titlePaddingBottom()}>
                    <Box style={{ marginBottom: "8px" }}>
                        <ThemedText fontStyle={"h2"} styles={{ root: { font: "normal normal normal 18px/25px Poppins", letterSpacing: "0.54px", color: "#171716" } }}>
                            {viewModel.displayName}
                        </ThemedText>
                        <ThemedText fontStyle={"h2"} styles={{ root: { font: "normal normal normal 14px/20px Poppins", letterSpacing: "0.42px", color: "#171716" } }}>
                            {viewModel.displaySupplierTypeName}
                        </ThemedText>
                    </Box>
                    <ButtonGroup>
                        <ThemedButton
                            className="editicon"
                            paletteColor={"primary"}
                            displayName="EDIT SUPPLIER"
                            icon={<EditIcon />}
                            command={viewModel.navigateToEditSupplierCommand}
                        />
                        {viewModel.deleteModalOpenCommand.canExecute() && (
                            <ThemedButton
                                styles={{ root: { color: "#fff" } }}
                                paletteColor={"error"}
                                className="redBtn"
                                displayName="DELETE SUPPLIER"
                                icon={<CustomerCrossSVG />}
                                command={viewModel.deleteModalOpenCommand}
                            />
                        )}
                    </ButtonGroup>
                </Box>
                <Box display="flex" alignItems="center" flexDirection="row">
                    {(viewModel.canDisplaySupplierContactNumber1 || viewModel.canDisplaySupplierContactNumber2) && (
                        <Box display="flex" mr="30px">
                            <Box display="flex" alignItems="center" width="20px" marginBottom="1px" paddingLeft="1px">
                                <PhoneIcon
                                    width="8px"
                                    id="phone"
                                    style={{ cursor: "pointer" }}
                                    onClick={(event: any) => {
                                        setIsCalloutVisible({
                                            isCalloutVisble: true,
                                            isEmail: false,
                                            targetId: `phone`,
                                            data: `${isNullOrEmpty(viewModel.model.supplier.contactNumber1) ? " " : viewModel.model.supplier.contactNumber1} ${
                                                isNullOrEmpty(viewModel.model.supplier.contactNumber2) ? "" : viewModel.model.supplier.contactNumber2
                                            }`,
                                        });
                                    }}
                                />
                            </Box>
                            {viewModel.canDisplaySupplierContactNumber1 && (
                                <Box
                                    style={{ cursor: "pointer" }}
                                    id="customercontactnumber1"
                                    onClick={(event: any) => {
                                        setIsCalloutVisible({
                                            isCalloutVisble: true,
                                            isEmail: false,
                                            targetId: "customercontactnumber1",
                                            data: viewModel.model.supplier.contactNumber1,
                                        });
                                    }}
                                >
                                    <ThemedText fontStyle={"h4"}>{viewModel.model.supplier.contactNumber1}</ThemedText>
                                </Box>
                            )}
                            {viewModel.canDisplaySupplierContactNumber1 && viewModel.canDisplaySupplierContactNumber2 && (
                                <Box m="0 5px">
                                    <ThemedText color="secondary" fontStyle={"h4"}>
                                        |
                                    </ThemedText>
                                </Box>
                            )}
                            {viewModel.canDisplaySupplierContactNumber2 && (
                                <Box
                                    style={{ cursor: "pointer" }}
                                    id="customercontactnumber2"
                                    onClick={(event: any) => {
                                        setIsCalloutVisible({
                                            isCalloutVisble: true,
                                            isEmail: false,
                                            targetId: "customercontactnumber2",
                                            data: viewModel.model.supplier.contactNumber2,
                                        });
                                    }}
                                >
                                    <ThemedText fontStyle={"h4"}>{viewModel.model.supplier.contactNumber2}</ThemedText>
                                </Box>
                            )}
                        </Box>
                    )}
                    {(viewModel.canDisplaySupplierEmailAddress || viewModel.canDisplaySupplierEmailAddress2) && (
                        <Box display="flex">
                            <Box display="flex" alignItems="center" width="20px" marginBottom="1px">
                                <EmailIcon
                                    width="15px"
                                    id="email"
                                    style={{ cursor: "pointer" }}
                                    onClick={(event: any) => {
                                        setIsCalloutVisible({
                                            isCalloutVisble: true,
                                            isEmail: true,
                                            targetId: `email`,
                                            data: `${!isEmptyOrWhitespace(viewModel.model.supplier.emailAddress) ? viewModel.model.supplier.emailAddress : ""} 
                                            `,
                                        });
                                    }}
                                />
                            </Box>
                            {viewModel.canDisplaySupplierEmailAddress && (
                                <Box
                                    style={{ cursor: "pointer" }}
                                    id="customeremailaddress"
                                    onClick={(event: any) => {
                                        setIsCalloutVisible({
                                            isCalloutVisble: true,
                                            isEmail: true,
                                            targetId: "customeremailaddress",
                                            data: viewModel.model.supplier.emailAddress,
                                        });
                                    }}
                                >
                                    <ThemedText fontStyle={"h4"}>{viewModel.model.supplier.emailAddress}</ThemedText>
                                </Box>
                            )}
                            {viewModel.canDisplaySupplierEmailAddress && viewModel.canDisplaySupplierEmailAddress2 && (
                                <Box m="0 5px">
                                    <ThemedText color="secondary" fontStyle={"h4"}>
                                        |
                                    </ThemedText>
                                </Box>
                            )}

                            {viewModel.canDisplaySupplierEmailAddress2 && (
                                <Box
                                    style={{ cursor: "pointer" }}
                                    id="customeremailaddress2"
                                    onClick={(event: any) => {
                                        setIsCalloutVisible({
                                            isCalloutVisble: true,
                                            isEmail: true,
                                            targetId: "customeremailaddress2",
                                            data: viewModel.model.supplier.emailAddress2,
                                        });
                                    }}
                                >
                                    <ThemedText fontStyle={"h4"}>{viewModel.model.supplier.emailAddress2}</ThemedText>
                                </Box>
                            )}
                        </Box>
                    )}
                </Box>
                {viewModel.canDisplaySupplierAddress && (
                    <Box display="flex">
                        <Box display="flex" alignItems="center" width="20px" marginBottom="2px">
                            <AddressIcon width="10px" />
                        </Box>
                        <ThemedText fontStyle={"h4"}>{viewModel.supplierAddress}</ThemedText>
                    </Box>
                )}
            </TitleContainer>
        );
    };

    const renderClientNotes = () => {
        if (viewModel.canDisplayClientNotes) {
            return (
                <React.Fragment>
                    <ThemedText
                        fontStyle={"h4"}
                        mt={"35px"}
                        mb={"22px"}
                        styles={{ root: { font: "normal normal 600 14px/21px Poppins", letterSpacing: "0.42px", color: "#171716", textTransform: "uppercase" } }}
                    >
                        NOTES
                    </ThemedText>
                    <FormContainerRow dc={"2fr 1fr"} mt="5px !important" mb="25px !important" rowGap={"8px"} paddingLeft="15px">
                        <Box>
                            <ThemedText
                                fontStyle={"h4"}
                                styles={{
                                    root: {
                                        textAlign: "left",
                                        font: "normal normal normal 10px/16px Poppins",
                                        letterSpacing: "0.3px",
                                        color: "#171716",
                                        textTransform: "uppercase",
                                        marginBottom: "8px",
                                    },
                                }}
                            >
                                CLIENT NOTE
                            </ThemedText>
                            <ThemedText fontStyle="h7">{viewModel.clientNote}</ThemedText>
                        </Box>
                        <ThemedText
                            fontStyle="h7"
                            styles={{ root: { textAlign: "right", font: "normal normal normal 10px/16px Poppins", letterSpacing: "0.3px", color: "#171716" } }}
                        >
                            {viewModel.userDetails}
                        </ThemedText>
                    </FormContainerRow>
                    <FilesReadOnlyView viewModel={viewModel.filesViewModel} />
                </React.Fragment>
            );
        }

        return <Box marginBottom="-15px" />;
    };

    const renderSupplierContacts = () => {
        if (viewModel.canDisplaySupplierContacts) {
            return (
                <React.Fragment>
                    <ThemedText
                        styles={{ root: { font: "normal normal 600 14px/21px Poppins", letterSpacing: "0.42px", color: "#171716", textTransform: "uppercase" } }}
                        fontStyle={"h3"}
                        marginBottom={"10px"}
                        marginTop={"20px"}
                    >
                        CONTACTS
                    </ThemedText>
                    <AutoGrid dc={"1fr"} mt={0} rowGap={0}>
                        {viewModel.model.supplierContacts.map((supplierContact, index) => {
                            return (
                                <ItemInformationContainer key={supplierContact.KEY} className="clientDetailsList">
                                    <ItemInformationCell flex="20" flexDirection={"column"} style={{ alignItems: "flex-start" }}>
                                        {viewModel.getSupplierContactName(supplierContact)}
                                        <ThemedText fontStyle="h7">{viewModel.getSupplierContactTypeName(supplierContact)}</ThemedText>
                                    </ItemInformationCell>
                                    {viewModel.canDisplaySupplierContactContactNumber1(supplierContact) || viewModel.canDisplaySupplierContactContactNumber2(supplierContact) ? (
                                        <ItemInformationCell flex="10" padding="2.5px 15px !important">
                                            <Box display="flex" alignItems="center" width="20px" marginBottom="1px" paddingLeft="1px">
                                                <PhoneIcon width="8px" />
                                            </Box>
                                            <Box flexDirection="column">
                                                <Box
                                                    id={`customercontactnumber1-${index}`}
                                                    onClick={(event: any) => {
                                                        setIsCalloutVisible({
                                                            isCalloutVisble: true,
                                                            isEmail: false,
                                                            targetId: `customercontactnumber1-${index}`,
                                                            data: viewModel.getSupplierContactNumber1(supplierContact),
                                                        });
                                                    }}
                                                >
                                                    {viewModel.getSupplierContactNumber1(supplierContact)}
                                                </Box>
                                                <Box
                                                    id={`customercontactnumber2-${index}`}
                                                    onClick={(event: any) => {
                                                        setIsCalloutVisible({
                                                            isCalloutVisble: true,
                                                            isEmail: false,
                                                            targetId: `customercontactnumber2-${index}`,
                                                            data: viewModel.getSupplierContactNumber2(supplierContact),
                                                        });
                                                    }}
                                                >
                                                    {viewModel.getSupplierContactNumber2(supplierContact)}
                                                </Box>
                                            </Box>
                                        </ItemInformationCell>
                                    ) : (
                                        <Box></Box>
                                    )}
                                    {viewModel.canDisplaySupplierContactEmailAddress(supplierContact) ? (
                                        <ItemInformationCell flex="30">
                                            <Box display="flex" alignItems="center" width="20px" marginBottom="1px" paddingLeft="1px">
                                                <EmailIcon width="15px" />
                                            </Box>
                                            <Box
                                                id={`customeremailaddress-${index}`}
                                                onClick={(event: any) => {
                                                    setIsCalloutVisible({
                                                        isCalloutVisble: true,
                                                        isEmail: true,
                                                        targetId: `customeremailaddress-${index}`,
                                                        data: viewModel.getSupplierContactEmailAddress(supplierContact),
                                                    });
                                                }}
                                            >
                                                {viewModel.getSupplierContactEmailAddress(supplierContact)}
                                            </Box>
                                        </ItemInformationCell>
                                    ) : (
                                        <Box></Box>
                                    )}
                                    {viewModel.canDisplaySupplierContactAddress(supplierContact) ? (
                                        <ItemInformationCell flex="40">
                                            <Box display="flex" alignItems="center" width="20px" marginBottom="2px">
                                                <AddressIcon width="10px" />
                                            </Box>
                                            <Box>{viewModel.getSupplierContactAddress(supplierContact)}</Box>
                                        </ItemInformationCell>
                                    ) : (
                                        <Box></Box>
                                    )}
                                </ItemInformationContainer>
                            );
                        })}
                    </AutoGrid>
                </React.Fragment>
            );
        }

        return <React.Fragment />;
    };

    const renderWorkspace = () => {
        return (
            <FormContainer>
                {renderSupplierContacts()}
                {renderClientNotes()}
                {/* Delete Modal */}
                {renderDeleteModalContent()}
            </FormContainer>
        );
    };

    const renderBusy = () => <ThemedLoader isOpen={viewModel.apiClient.IsBusy} />;

    const renderError = () => {
        const isOpen = viewModel.apiClient.IsSubmitted && !viewModel.apiClient.IsBusy && viewModel.apiClient.HaveValidationMessage;

        return <ThemedError command={viewModel.resetServerErrorCommand} isOpen={isOpen} errorMessage={viewModel.apiClient.ValidationMessage} />;
    };

    return (
        <PageContainer>
            {/* Navigation */}
            <NavigationContainer onClick={() => viewModel.navigateToSupplierCommand.execute()}>
                <ThemedText>{"<"} BACK TO SUPPLIERS</ThemedText>
            </NavigationContainer>

            {/* Title */}
            {renderTitle()}

            {/* Workspace */}
            {renderWorkspace()}

            {/* Busy Overlay */}
            {renderBusy()}

            {/* Error Overlay */}
            {renderError()}

            {/* Callout */}
            {callout.isCalloutVisble && (
                <ContactCallout data={callout.data} onDismiss={() => setIsCalloutVisible(defaultCalloutState)} isEmail={callout.isEmail} targetId={callout.targetId} />
            )}
        </PageContainer>
    );
});
