import { isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

import { theme } from "Application";
import { InProgressItemModel } from "./InProgressItemModel";

export class InProgressItemViewModel extends ViewModelBase<InProgressItemModel> {
    constructor(model = new InProgressItemModel()) {
        super(model);

        makeObservable(this, {});
    }

    public get KEY() {
        return this.model.KEY;
    }

    public get projectTaskAssignmentStatusName() {
        if (this.model.isNonBillableEffort) {
            return this.model.projectTaskAssignmentStatusName + " as NCT";
        }

        return this.model.projectTaskAssignmentStatusName;
    }

    public get projectTaskAssignmentStatusForegroundColor() {
        if (this.model.isNonBillableEffort) {
            return theme.palette.common.deepRed;
        }

        return this.model.projectTaskAssignmentStatusForegroundColor;
    }

    public get projectTaskName() {
        return this.model.projectTaskName;
    }

    public get assignedUserFullName() {
        return !isEmptyOrWhitespace(this.model.assignedUserFirstName) && !isEmptyOrWhitespace(this.model.assignedUserLastName)
            ? `${this.model.assignedUserFirstName} ${this.model.assignedUserLastName}`
            : "";
    }

    public get plannedStartDate() {
        return this.model.plannedStartDate;
    }

    public get plannedEndDate() {
        return this.model.plannedEndDate;
    }

    public get billableEffort() {
        return this.model.billableEffort;
    }

    public get effort() {
        return this.model.effort;
    }

    // #region Filtering

    public filterPredicate = (filterKeyword: string): boolean => {
        let result = true;

        if (result) {
            const lowerCaseFilterKeyword = filterKeyword.toLowerCase();

            switch (true) {
                case isEmptyOrWhitespace(filterKeyword):
                    result = true;
                    break;

                default:
                    result = false;
                    break;
            }
        }

        return result;
    };

    // #endregion Filtering
}
