import { SVGProps } from "react";

export const FilesIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={18.682} height={24.242} {...props}>
        <g data-name="Group 7165">
            <g data-name="Group 7166">
                <g data-name="Path 5673" fill="none">
                    <path d="M18.682 7.775v14.614a1.854 1.854 0 0 1-1.854 1.854H3.854A1.854 1.854 0 0 1 2 22.389h.953a.816.816 0 0 0 .9.795h12.975a.844.844 0 0 0 .881-.795V7.775l-4.07-4.961.605-.488Z" />
                    <path
                        className="canFill"
                        d="M18.682 7.775V22.39c0 1.023-.83 1.853-1.854 1.853H3.854A1.854 1.854 0 0 1 2 22.39h.953s0 .795.9.795h12.975c.84 0 .881-.795.881-.795V7.775l-4.07-4.96.606-.489 4.437 5.45Z"
                        fill="#8d8d8d"
                    />
                </g>
            </g>
            <g data-name="Group 7165">
                <g data-name="Path 5673" fill="none">
                    <path d="M16.682 5.529v14.86a1.854 1.854 0 0 1-1.854 1.854H1.854A1.854 1.854 0 0 1 0 20.389V1.854A1.854 1.854 0 0 1 1.854 0H12.51Z" />
                    <path
                        className="canFill"
                        d="M15.682 5.864 12.012 1H1.854A.854.854 0 0 0 1 1.854v18.535c0 .47.383.853.854.853h12.974c.471 0 .854-.383.854-.853V5.864m1-.335v14.86c0 1.023-.83 1.853-1.854 1.853H1.854A1.854 1.854 0 0 1 0 20.39V1.854C0 .83.83 0 1.854 0H12.51l4.172 5.529Z"
                        fill="#8d8d8d"
                    />
                </g>
                <g data-name="Group 7137">
                    <g data-name="Path 5673" fill="none">
                        <path d="M12.591 5.529h4.091v14.86a1.854 1.854 0 0 1-1.854 1.854H1.854A1.854 1.854 0 0 1 0 20.389V1.854A1.854 1.854 0 0 1 1.854 0h10.737Z" />
                        <path
                            className="canFill"
                            d="M15.682 6.529h-4.09V1H1.853A.854.854 0 0 0 1 1.854v18.535c0 .47.383.853.854.853h12.974c.471 0 .854-.383.854-.853V6.529m1-1v14.86c0 1.023-.83 1.853-1.854 1.853H1.854A1.854 1.854 0 0 1 0 20.39V1.854C0 .83.83 0 1.854 0H12.59v5.529h4.091Z"
                            fill="#8d8d8d"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
