import { observer } from "mobx-react-lite";
import { PropsWithChildren } from "react";
import { mergeStyleSets } from "@fluentui/react";
import { theme } from "../../AppTheme";
import { IRadioButtonsBaseProps, RadioButtonsBase } from "../../../Components/StyleFrameworks/FluentUI/Primitives/Active/RadioButtonsBase";

export const ThemedRadioButtons = observer((props: PropsWithChildren<IRadioButtonsBaseProps>) => {
    return (
        <RadioButtonsBase
            {...props}
            styles={mergeStyleSets(
                {
                    label: {
                        color: theme.palette.field.text,
                        fontFamily: theme.fontStyles.label.fontFamily ?? theme.defaultFontStyle.fontFamily,
                        fontSize: theme.fontStyles.label.fontSize ?? theme.defaultFontStyle.fontSize,
                        fontWeight: theme.fontStyles.label.fontWeight ?? theme.defaultFontStyle.fontWeight,
                        letterSpacing: theme.fontStyles.label.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                        lineHeight: theme.fontStyles.label.lineHeight ?? theme.defaultFontStyle.lineHeight,
                        textTransform: theme.fontStyles.label.textTransform ?? theme.defaultFontStyle.textTransform,
                    },
                    root: {},
                    flexContainer: {},
                },
                {},
                props.styles,
            )}
            optionStyles={mergeStyleSets(
                {
                    root: {
                        label: {
                            display: "flex",
                            alignItems: "center",
                            minHeight: "30px",
                            color: theme.palette.field.text,
                            fontFamily: theme.fontStyles.radioButtonLabel.fontFamily ?? theme.defaultFontStyle.fontFamily,
                            fontSize: theme.fontStyles.radioButtonLabel.fontSize ?? theme.defaultFontStyle.fontSize,
                            fontWeight: theme.fontStyles.radioButtonLabel.fontWeight ?? theme.defaultFontStyle.fontWeight,
                            letterSpacing: theme.fontStyles.radioButtonLabel.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                            lineHeight: theme.fontStyles.radioButtonLabel.lineHeight ?? theme.defaultFontStyle.lineHeight,
                            textTransform: theme.fontStyles.radioButtonLabel.textTransform ?? theme.defaultFontStyle.textTransform,
                            ".ms-ChoiceFieldLabel": {
                                paddingLeft: "38px",
                            },
                            "::before": {
                                borderColor: theme.palette.field.light,
                                height: "30px",
                                width: "30px",
                            },
                            ":hover::before": {
                                borderColor: theme.palette.field.main,
                            },
                            "::after": {
                                height: "20px",
                                width: "20px",
                            },
                            ":hover::after": {
                                background: theme.palette.field.light,
                                borderColor: theme.palette.field.light,
                                height: "20px",
                                width: "20px",
                            },
                        },
                        ".ms-ChoiceField-field.is-checked": {
                            "::after": {
                                background: theme.palette.secondary.light,
                                borderColor: theme.palette.secondary.light,
                            },
                            ":hover::after": {
                                background: theme.palette.secondary.main,
                                borderColor: theme.palette.secondary.main,
                            },
                        },
                    },
                },
                props.optionStyles,
            )}
            errorStyles={{
                root: {
                    display: "none",
                    color: theme.palette.common.error,
                    fontFamily: theme.fontStyles.error.fontFamily ?? theme.defaultFontStyle.fontFamily,
                    fontSize: theme.fontStyles.error.fontSize ?? theme.defaultFontStyle.fontSize,
                    fontWeight: theme.fontStyles.error.fontWeight ?? theme.defaultFontStyle.fontWeight,
                    letterSpacing: theme.fontStyles.error.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                    lineHeight: theme.fontStyles.error.lineHeight ?? theme.defaultFontStyle.lineHeight,
                    textTransform: theme.fontStyles.error.textTransform ?? theme.defaultFontStyle.textTransform,
                    marginTop: "5px !important",
                    paddingTop: 0,
                },
            }}
        />
    );
});

export const ThemedDefaultRadioButtons = observer((props: PropsWithChildren<IRadioButtonsBaseProps>) => {
    return (
        <RadioButtonsBase
            {...props}
            styles={mergeStyleSets(
                {
                    label: {
                        color: theme.palette.field.text,
                        fontFamily: theme.fontStyles.label.fontFamily ?? theme.defaultFontStyle.fontFamily,
                        fontSize: theme.fontStyles.label.fontSize ?? theme.defaultFontStyle.fontSize,
                        fontWeight: theme.fontStyles.label.fontWeight ?? theme.defaultFontStyle.fontWeight,
                        letterSpacing: theme.fontStyles.label.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                        lineHeight: theme.fontStyles.label.lineHeight ?? theme.defaultFontStyle.lineHeight,
                        textTransform: theme.fontStyles.label.textTransform ?? theme.defaultFontStyle.textTransform,
                    },
                    root: {},
                    flexContainer: {},
                },
                {},
                props.styles,
            )}
            optionStyles={mergeStyleSets(
                {
                    root: {
                        label: {
                            display: "flex",
                            alignItems: "center",
                            minHeight: "30px",
                            color: theme.palette.field.text,
                            fontFamily: theme.fontStyles.radioButtonLabel.fontFamily ?? theme.defaultFontStyle.fontFamily,
                            fontSize: theme.fontStyles.radioButtonLabel.fontSize ?? theme.defaultFontStyle.fontSize,
                            fontWeight: theme.fontStyles.radioButtonLabel.fontWeight ?? theme.defaultFontStyle.fontWeight,
                            letterSpacing: theme.fontStyles.radioButtonLabel.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                            lineHeight: theme.fontStyles.radioButtonLabel.lineHeight ?? theme.defaultFontStyle.lineHeight,
                            textTransform: theme.fontStyles.radioButtonLabel.textTransform ?? theme.defaultFontStyle.textTransform,
                            ".ms-ChoiceFieldLabel": {
                                paddingLeft: "38px",
                            },
                            "::before": {
                                borderColor: theme.palette.field.light,
                                height: "30px",
                                width: "30px",
                            },
                            ":hover::before": {
                                borderColor: theme.palette.field.main,
                            },
                            "::after": {
                                height: "20px",
                                width: "20px",
                            },
                            ":hover::after": {
                                background: theme.palette.field.light,
                                borderColor: theme.palette.field.light,
                                height: "20px",
                                width: "20px",
                            },
                        },
                        ".ms-ChoiceField-field.is-checked": {
                            "::after": {
                                background: theme.palette.secondary.light,
                                borderColor: theme.palette.secondary.light,
                            },
                            ":hover::after": {
                                background: theme.palette.secondary.main,
                                borderColor: theme.palette.secondary.main,
                            },
                        },
                    },
                },
                props.optionStyles,
            )}
            errorStyles={{
                root: {
                    display: "none",
                    color: theme.palette.common.error,
                    fontFamily: theme.fontStyles.error.fontFamily ?? theme.defaultFontStyle.fontFamily,
                    fontSize: theme.fontStyles.error.fontSize ?? theme.defaultFontStyle.fontSize,
                    fontWeight: theme.fontStyles.error.fontWeight ?? theme.defaultFontStyle.fontWeight,
                    letterSpacing: theme.fontStyles.error.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                    lineHeight: theme.fontStyles.error.lineHeight ?? theme.defaultFontStyle.lineHeight,
                    textTransform: theme.fontStyles.error.textTransform ?? theme.defaultFontStyle.textTransform,
                    marginTop: "5px !important",
                    paddingTop: 0,
                },
            }}
        />
    );
});

// #endregion Directly-styled radio buttons
