import { runInAction } from "mobx";

import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { UsersViewModel } from "./UsersViewModel";
import { UserItemLiteModel } from "./UserItemLiteModel";
import { UserItemLiteViewModel } from "./UserItemLiteViewModel";

export class Response {
    public id: string = "";
    public documentUrl: string = "";
    public emailAddress: string = "";
    public firstName: string = "";
    public lastName: string = "";
    public isDeleted: boolean = false;
    public userRole: string = "";
    public lastLoginDate: Date | null = null;
    public lastActionDate: Date | null = null;
    public lastActionLabel: string = "";
}

export class GETAllUsersLiteEndpoint extends EndpointWithoutRequest<Response> {
    private viewModel: UsersViewModel;

    constructor(viewModel: UsersViewModel) {
        super();
        this.viewModel = viewModel;
        this.verb(Http.Get);
        this.path(AppUrls.Server.Admin.Users.GetAllUsersLite);
    }

    public async HandleResponseAsync(response: Response[]): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            const users: UserItemLiteViewModel[] = [];

            response.forEach((element: any) => {
                const model = new UserItemLiteModel();

                model.fromResponse(element);

                users.push(new UserItemLiteViewModel(model));
            });

            this.viewModel.users.replace(users);
        });
    }
}
