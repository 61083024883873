import { isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { computed, makeObservable, observable } from "mobx";

import { APIClient, ICommandAsync, RelayCommand } from "Application";
import { AppUrls } from "AppUrls";
import { LeaveRequest } from "Views/Leave/Shared/LeaveRequest";
import { CancelLeaveRequestModel } from "Views/Leave/Shared/ModalViews/CancelLeaveRequest/CancelLeaveRequestModel";
import { CancelLeaveRequestViewModel } from "Views/Leave/Shared/ModalViews/CancelLeaveRequest/CancelLeaveRequestViewModel";
import { POSTCancelLeaveEndpoint } from "Views/Leave/Shared/ModalViews/CancelLeaveRequest/Endpoints/POSTCancelLeaveRequestEndpoint";
import { DeleteLeaveRequestModel } from "Views/Leave/Shared/ModalViews/DeleteLeaveRequest/DeleteLeaveRequestModel";
import { DeleteLeaveRequestViewModel } from "Views/Leave/Shared/ModalViews/DeleteLeaveRequest/DeleteLeaveRequestViewModel";
import { POSTDeleteLeaveEndpoint } from "Views/Leave/Shared/ModalViews/DeleteLeaveRequest/Endpoints/POSTDeleteLeaveRequestEndpoint";
import { NewRequestModel } from "Views/Leave/Shared/ModalViews/NewRequest/NewRequestModel";
import { NewRequestViewModel } from "Views/Leave/Shared/ModalViews/NewRequest/NewRequestViewModel";
import { POSTRequestLeaveEndpoint } from "Views/Leave/Shared/ModalViews/NewRequest/Endpoints/POSTRequestLeave";
import { GETAllLeaveRequestsEndpoint } from "./Endpoints/GETAllLeaveRequests";
import { LeaveRequestsModel } from "./LeaveRequestsModel";
import { LeaveRequestItemViewModel } from "./LeaveRequestItemViewModel";
import { DashboardViewModel } from "Views/Dashboard/DashboardViewModel";

export class LeaveRequestsViewModel extends ViewModelBase<LeaveRequestsModel> {
    public apiClient = new APIClient();
    public leaveRequests = observable<LeaveRequestItemViewModel>([]);

    public newRequestViewModel: NewRequestViewModel | null = null;
    public cancelLeaveRequestViewModel: CancelLeaveRequestViewModel | null = null;
    public deleteLeaveRequestViewModel: DeleteLeaveRequestViewModel | null = null;
    public dashboardSyncCommand: ICommandAsync | null = null;

    constructor(model = new LeaveRequestsModel(), dashboardViewModel?: DashboardViewModel) {
        super(model, false);

        this.dashboardSyncCommand = dashboardViewModel ? dashboardViewModel.syncDashboardCommand : null;

        makeObservable(this, {
            // Observables
            newRequestViewModel: observable,
            cancelLeaveRequestViewModel: observable,
            deleteLeaveRequestViewModel: observable,

            // Computeds
            canDisplayNewRequest: computed,
        });

        const _ = this.apiClient.sendAsync(new GETAllLeaveRequestsEndpoint(this));
    }

    // #region Sorting

    public updateSortCommand = new RelayCommand((key: string, sortDescending: boolean) => {
        this.model.sortKey = key;
        this.model.sortDescending = sortDescending;
    });

    public get canSortLeaveRequests(): boolean {
        return !isEmptyOrWhitespace(this.model.sortKey);
    }

    public get sortedLeaveRequests(): LeaveRequestItemViewModel[] {
        return this.canSortLeaveRequests
            ? this.leaveRequests.slice().sort((lhs, rhs) => {
                  return (this.model.sortDescending ? lhs[this.model.sortKey] < rhs[this.model.sortKey] : lhs[this.model.sortKey] > rhs[this.model.sortKey]) ? 1 : -1;
              })
            : this.leaveRequests;
    }

    // #endregion Sorting

    // #region New Request Commands

    public get canDisplayNewRequest(): boolean {
        return this.newRequestViewModel !== null;
    }

    public displayNewRequestCommand = new RelayCommand(() => {
        this.newRequestViewModel = new NewRequestViewModel(
            new NewRequestModel(this.model.leaveTypes, this.model.leaveDayTypes),
            this.submitNewRequestCommand,
            this.cancelNewRequestCommand,
        );
    });

    public submitNewRequestCommand = new RelayCommand(
        async () => {
            await this.apiClient.sendAsync(new POSTRequestLeaveEndpoint(), this.newRequestViewModel?.model);

            if (this.apiClient.IsRequestSuccessful) {
                this.newRequestViewModel = null;
                this.apiClient.reset();
                this.apiClient.sendAsync(new GETAllLeaveRequestsEndpoint(this));
                if (this.dashboardSyncCommand) {
                    this.dashboardSyncCommand.execute();
                }
            }
        },
        () => {
            return !this.apiClient.IsBusy;
        },
    );

    public cancelNewRequestCommand = new RelayCommand(() => {
        this.newRequestViewModel = null;
    });

    // #endregion New Request Commands

    // #region Edit Request Commands

    public get canDisplayEditRequest(): boolean {
        return this.newRequestViewModel !== null;
    }

    public displayEditRequestCommand = new RelayCommand((leaveRequest: LeaveRequest) => {
        this.newRequestViewModel = new NewRequestViewModel(
            new NewRequestModel(this.model.leaveTypes, this.model.leaveDayTypes, leaveRequest),
            this.submitEditRequestCommand,
            this.cancelEditRequestCommand,
        );
    });

    public submitEditRequestCommand = new RelayCommand(
        async () => {
            await this.apiClient.sendAsync(new POSTRequestLeaveEndpoint(), this.newRequestViewModel?.model);

            if (this.apiClient.IsRequestSuccessful) {
                this.newRequestViewModel = null;
                this.apiClient.reset();
                this.apiClient.sendAsync(new GETAllLeaveRequestsEndpoint(this));
            }
        },
        () => {
            return !this.apiClient.IsBusy;
        },
    );

    public cancelEditRequestCommand = new RelayCommand(() => {
        this.newRequestViewModel = null;
    });

    // #endregion Edit Request Commands

    // #region Cancel Request Commands

    public get canDisplayCancelRequest(): boolean {
        return this.cancelLeaveRequestViewModel !== null;
    }

    public displayCancelRequestCommand = new RelayCommand((leaveRequest: LeaveRequest) => {
        this.cancelLeaveRequestViewModel = new CancelLeaveRequestViewModel(
            new CancelLeaveRequestModel(leaveRequest),
            this.submitCancelRequestCommand,
            this.cancelCancelRequestCommand,
        );
    });

    public submitCancelRequestCommand = new RelayCommand(
        async () => {
            await this.apiClient.sendAsync(new POSTCancelLeaveEndpoint(), this.cancelLeaveRequestViewModel?.model);

            if (this.apiClient.IsRequestSuccessful) {
                this.cancelLeaveRequestViewModel = null;
                this.apiClient.reset();
                this.apiClient.sendAsync(new GETAllLeaveRequestsEndpoint(this));
            }
        },
        () => {
            return !this.apiClient.IsBusy;
        },
    );

    public cancelCancelRequestCommand = new RelayCommand(() => {
        this.cancelLeaveRequestViewModel = null;
    });

    // #endregion Cancel Request Commands

    // #region Delete Rquest Commands

    public get canDisplayDeleteRequest(): boolean {
        return this.deleteLeaveRequestViewModel !== null;
    }

    public displayDeleteRequestCommand = new RelayCommand((leaveRequest: LeaveRequest) => {
        this.deleteLeaveRequestViewModel = new DeleteLeaveRequestViewModel(
            new DeleteLeaveRequestModel(leaveRequest),
            this.submitDeleteRequestCommand,
            this.cancelDeleteRequestCommand,
        );
    });

    public submitDeleteRequestCommand = new RelayCommand(
        async () => {
            await this.apiClient.sendAsync(new POSTDeleteLeaveEndpoint(), this.deleteLeaveRequestViewModel?.model);

            if (this.apiClient.IsRequestSuccessful) {
                this.deleteLeaveRequestViewModel = null;
                this.apiClient.reset();
                this.apiClient.sendAsync(new GETAllLeaveRequestsEndpoint(this));
            }
        },
        () => {
            return !this.apiClient.IsBusy;
        },
    );

    public cancelDeleteRequestCommand = new RelayCommand(() => {
        this.deleteLeaveRequestViewModel = null;
    });

    // #endregion Delete Request Commands

    // #region Commands

    public resetServerErrorCommand = new RelayCommand(() => {
        this.apiClient.reset();
    });

    public navigateToCalendarCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.DashboardStaff.LeaveCalendar);
    });

    // #endregion Commands

    //region properties

    public get numbersOfDaysLeft() {
        let retVal = 0;
        retVal = this.model.leaveAllowanceNumberOfDaysLeft ? this.model.leaveAllowanceNumberOfDaysLeft : 0;
        return `${retVal} day${retVal != 1 ? "s" : ""}`;
    }
    //endregion properties
}
