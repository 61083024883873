import { FieldType, ViewModelBase } from "@shoothill/core";
import { action, computed, makeObservable, observable } from "mobx";
import { nanoid } from "nanoid";
import { container } from "tsyringe";

import { LookupStore } from "Stores/Domain";
import { RelayCommand } from "Application";

import { FilesViewModel } from "Views/Shared/Files/FilesViewModel";
import { ProgrammingWeekTaskModel, ProgrammingWeekTaskModelValidator } from "../ProgrammingWeekTaskModel";

export class ProgrammingWeekTaskDetailsViewModel extends ViewModelBase<ProgrammingWeekTaskModel> {
    private lookupStore = container.resolve(LookupStore);
    private key = nanoid();

    public filesViewModel = new FilesViewModel();

    constructor(model: ProgrammingWeekTaskModel) {
        super(model);

        this.setValidator(new ProgrammingWeekTaskModelValidator());

        makeObservable(this, {
            isProgrammingWeekTaskComplete: computed,
        });
    }

    // #region Properties

    public get KEY() {
        return this.key;
    }

    public get displayName() {
        return this.isTaskReviewed ? "Reviewed" : "Task";
    }

    public get isTaskReviewed() {
        const status = this.lookupStore.projectTaskAssignmentStatuses.find((pts) => pts.id === this.model.projectTaskAssignmentStatusId);
        return status?.type == "Assigned" && this.model.projectTaskAssignmentIsReviewed;
    }

    // #endregion Properties

    // #region Commands
    public updateUserNoteCommand = new RelayCommand((value: string) => {
        this.updateField("projectTaskAssignmentUserNote", value);
    });

    // #endregion Commands

    // #region Supporting

    private updateField(fieldName: keyof FieldType<ProgrammingWeekTaskModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    public get isProgrammingWeekTaskComplete() {
        const status = this.lookupStore.projectTaskAssignmentStatuses.find((pts) => pts.id === this.model.projectTaskAssignmentStatusId);
        return status?.type == "Complete" || status?.type == "Billed";
    }
}
