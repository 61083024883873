import { computed, makeObservable, observable } from "mobx";
import { isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { container } from "tsyringe";

import { APIClient, RelayCommand, theme } from "Application";
import { AppUrls } from "../../../../AppUrls";
import { LookupStore } from "../../../../Stores/Domain";

import { EnquiryModel, EnquiryModelValidator } from "./EnquiryModel";
import { ENQUIRYSTATUS_NOTYPE, NOTECONTEXT_ENQUIRY } from "Views/Shared/Constants";
import { GETEnquiryRelatedEndpoint } from "./GETEnquiryRelatedEndpoint";
import { GETEnquiryWithRelatedByIdEndpoint } from "./GETEnquiryWithRelatedByIdEndpont";
import { CustomerViewModel } from "Views/Shared/Customer/CustomerViewModel";
import { NotesViewModel } from "Views/Shared/Note/NotesViewModel";
import { EnquirySubViewModel } from "./SubViews/EnquirySubView/EnquirySubViewModel";
import { POSTSaveEnquiryStatusEndpoint } from "./POSTSaveEnquiryStatusEndpoint";
import { POSTSavePersonVisitingEndpoint } from "./POSTSavePersonVisitingEndpoint";
import { POSTSaveEnquiryAsLostEndpoint } from "./POSTSaveEnquiryAsLostEndpoint";

import { POSTSaveEnquiryEndpoint } from "./POSTSaveEnquiryEndpoint";
import { NoteViewModel } from "Views/Shared/Note/NoteViewModel";
import { POSTSaveEnquiryNoteEndpoint } from "./POSTSaveEnquiryNoteEndpoint";
import { GETEnquiryRelatedByCustomerIdEndpoint } from "./GETEnquiryRelatedByCustomerIdEndpoint";
import { SubmitWithNoteViewModel } from "./SubViews/SubmitNoteView/SubmitWithNoteViewModel";
import { ConvertToQuoteViewModel } from "./ModalViews/ConvertToQuote/ConvertToQuoteViewModel";
import { ConvertToEnquiryViewModel } from "./ModalViews/ConvertToEnquiry/ConvertToEnquiryViewModel";
import { POSTSaveEnquiryAsQuoteEndpoint } from "./POSTSaveEnquiryAsQuoteEndpoint";
import { POSTSaveLostEnquiryAsEnquiryEndpoint } from "./POSTSaveLostEnquiryAsEnquiryEndpoint";
import { LostWithNoteViewModel } from "./SubViews/LostNoteView/LostWithNoteViewModel";
import { LostWithNoteModel } from "./SubViews/LostNoteView/LostWithNoteModel";

export class EnquiryViewModel extends ViewModelBase<EnquiryModel> {
    public apiClient = new APIClient();
    private lookupStore = container.resolve(LookupStore);

    public isEditMode = false;

    // Hosted in its own viewmodel.
    public enquirySubViewModel: EnquirySubViewModel;
    public customerViewModel: CustomerViewModel;
    public notesViewModel: NotesViewModel;

    public convertToQuoteViewModel: ConvertToQuoteViewModel | null = null;
    public convertToEnquiryViewModel: ConvertToEnquiryViewModel | null = null;
    public submitWithNoteViewModel: SubmitWithNoteViewModel | null = null;
    public lostWithNoteViewModel: LostWithNoteViewModel | null = null;

    constructor(enquiryId: string | undefined, customerId: string | undefined) {
        super(new EnquiryModel());

        this.setValidator(new EnquiryModelValidator());

        this.enquirySubViewModel = new EnquirySubViewModel(this.model.enquirySubModel, this.updateStatusTaskCommand, this.updatePersonVisitingCommand);
        this.customerViewModel = new CustomerViewModel(this.model.customer);
        this.notesViewModel = new NotesViewModel(this.model.notes, this.saveEnquiryNoteCommand, NOTECONTEXT_ENQUIRY);

        makeObservable(this, {
            // Observable
            isEditMode: observable,
            convertToQuoteViewModel: observable,
            convertToEnquiryViewModel: observable,
            submitWithNoteViewModel: observable,
            lostWithNoteViewModel: observable,

            //actions

            // Computed
            hasEditPermission: computed,
            canDisplayEnquiryStatus: computed,
            canDisplayEnquiryType: computed,
            enquiryStatusDisplayName: computed,
            enquiryStatusType: computed,
            enquiryStatusBackgroundColor: computed,
            displayName: computed,
            canDisplayCancelEditModeCommand: computed,
            canDisplayCreateEnquiryCommand: computed,
            canDisplayUpdateEnquiryCommand: computed,
            canDisplayDisplayConvertEnquiryToQuoteCommand: computed,
            canDisplayConvertEnquiryToQuoteCommand: computed,
            canDisplayDisplayConvertToEnquiryCommand: computed,
            canDisplayConvertToEnquiryCommand: computed,
            canDisplayDisplayMarkAsLostEnquiryCommand: computed,
            canDisplayMarkAsLostEnquiryCommand: computed,
        });

        // Server Actions
        switch (true) {
            case customerId !== undefined:
                this.apiClient.sendAsync(new GETEnquiryRelatedByCustomerIdEndpoint(customerId!, this));
                break;
            case !enquiryId:
            case enquiryId === "new":
                this.apiClient.sendAsync(new GETEnquiryRelatedEndpoint(this));
                break;
            default:
                this.apiClient.sendAsync(new GETEnquiryWithRelatedByIdEndpoint(enquiryId!, this));
                break;
        }
    }

    // #region Properties

    public get hasEditPermission(): boolean {
        return this.model.hasEditPermission();
    }

    public get canDisplayConvertToQuote(): boolean {
        return this.convertToQuoteViewModel !== null;
    }

    public get canDisplayConvertToEnquiry(): boolean {
        return this.convertToEnquiryViewModel !== null;
    }

    public get canDisplaySubmitWithNote(): boolean {
        return this.submitWithNoteViewModel !== null;
    }

    public get canDisplayLostWithNote(): boolean {
        return this.lostWithNoteViewModel !== null;
    }

    /**
     * Returns if the enquiry is in a mode for editing.
     */
    public get canDisplayInEditMode() {
        return this.isEditMode || isEmptyOrWhitespace(this.model.id);
    }

    /**
     * Returns if the notes can be displayed.
     */
    public get canDisplayNotes() {
        return !(this.isEditMode || isEmptyOrWhitespace(this.model.id));
    }

    /**
     * Returns if the enquiry status can be displayed.
     */
    public get canDisplayEnquiryStatus() {
        return this.model.isLost || !isEmptyOrWhitespace(this.model.enquiryStatusId);
    }

    /**
     * Returns if the enquiry type can be displayed.
     */
    public get canDisplayEnquiryType() {
        return !isEmptyOrWhitespace(this.model.enquiryTypeId);
    }

    /**
     * Returns the enquiry status display name.
     */
    public get enquiryStatusDisplayName() {
        return this.model.isLost
            ? "LOST"
            : this.model.isQuote
            ? "QUOTE"
            : this.lookupStore.enquiryStatuses.find((es) => es.id === this.model.enquiryStatusId)?.name?.toLocaleUpperCase() ?? "";
    }

    /**
     * Returns the enquiry status type.
     */
    public get enquiryStatusType() {
        return this.lookupStore.enquiryStatuses.find((es) => es.id === this.model.enquiryStatusId)?.type ?? ENQUIRYSTATUS_NOTYPE;
    }

    /**
     * Returns the enquiry status color.
     */
    public get enquiryStatusBackgroundColor() {
        return this.model.isLost
            ? theme.palette.common.black
            : this.model.isQuote
            ? theme.palette.common.quarternary
            : this.lookupStore.enquiryStatuses.find((es) => es.id === this.model.enquiryStatusId)?.foregroundColor ?? "";
    }

    /**
     * Returns the display name of the enquiry.
     */
    public get displayName() {
        return isEmptyOrWhitespace(this.model.id) ? "New enquiry" : `${this.model.reference}: ${this.model.title}`;
    }

    /**
     * Returns the display name of the enquiry type.
     */
    public get enquiryTypeDisplayName() {
        return this.lookupStore.enquiryTypes.find((et) => et.id === this.model.enquiryTypeId)?.name ?? "";
    }

    // #endregion Properties

    // #region Actions

    public cancelSubmitWithNoteCommand = new RelayCommand(() => {
        this.submitWithNoteViewModel = null;
    });

    // #endregion Actions

    // #region Commands

    public navigateToEnquiriesCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.Enquiries.Table);
    });

    public enterEditModeCommand = new RelayCommand(
        () => {
            this.isEditMode = true;
            this.enquirySubViewModel.model.backup();
            this.enquirySubViewModel.sourceOfEnquiryViewModel.model.backup();
            this.customerViewModel.model.backup();
        },
        () => !this.isEditMode && !isEmptyOrWhitespace(this.model.id) && this.model.hasEditPermission(),
    );

    public get canDisplayCancelEditModeCommand() {
        return this.isEditMode && !isEmptyOrWhitespace(this.model.id);
    }

    public cancelEditModeCommand = new RelayCommand(
        () => {
            this.isEditMode = false;
            this.enquirySubViewModel.model.restore();
            this.enquirySubViewModel.sourceOfEnquiryViewModel.model.restore();
            this.customerViewModel.model.restore();
        },
        () => this.canDisplayCancelEditModeCommand,
    );

    // Create enquiry.
    public get canDisplayCreateEnquiryCommand() {
        return !this.model.isQuote && !this.model.isLost && isEmptyOrWhitespace(this.model.id);
    }

    public createEnquiryCommand = new RelayCommand(
        () => {
            if (this.canSubmitForm) {
                this.isEditMode = false;
                this.apiClient.sendAsync(new POSTSaveEnquiryEndpoint(this), this.model);
            }
        },
        () => !this.apiClient.IsBusy && this.canDisplayCreateEnquiryCommand,
    );

    // Update enquiry.
    public get canDisplayUpdateEnquiryCommand() {
        return this.isEditMode && !this.model.isQuote && !this.model.isLost && !isEmptyOrWhitespace(this.model.id);
    }

    public updateEnquiryCommand = new RelayCommand(
        () => {
            if (this.canSubmitForm) {
                this.isEditMode = false;
                this.apiClient.sendAsync(new POSTSaveEnquiryEndpoint(this), this.model);
            }
        },
        () => !this.apiClient.IsBusy && this.canDisplayUpdateEnquiryCommand,
    );

    // Display convert enquiry to quote.
    public get canDisplayDisplayConvertEnquiryToQuoteCommand() {
        return this.canDisplayConvertEnquiryToQuoteCommand;
    }

    public displayConvertEnquiryToQuoteCommand = new RelayCommand(
        () => {
            this.convertToQuoteViewModel = new ConvertToQuoteViewModel(this.cancelConvertToQuoteCommand, this.convertEnquiryToQuoteCommand);
        },
        () => !this.apiClient.IsBusy && this.canDisplayDisplayConvertEnquiryToQuoteCommand,
    );

    // Convert enquiry to quote.
    public get canDisplayConvertEnquiryToQuoteCommand() {
        return !this.isEditMode && !this.model.isQuote && !isEmptyOrWhitespace(this.model.id);
    }

    public convertEnquiryToQuoteCommand = new RelayCommand(
        () => {
            if (this.canSubmitForm) {
                this.apiClient.sendAsync(new POSTSaveEnquiryAsQuoteEndpoint(this), this.model);
                this.convertToQuoteViewModel = null;
            }
        },
        () => !this.apiClient.IsBusy && this.canDisplayConvertEnquiryToQuoteCommand,
    );

    public cancelConvertToQuoteCommand = new RelayCommand(() => {
        this.convertToQuoteViewModel = null;
    });

    // Display convert lost enquiry to active enquiry.
    public get canDisplayDisplayConvertToEnquiryCommand() {
        return this.canDisplayConvertToEnquiryCommand;
    }

    public displayConvertToEnquiryCommand = new RelayCommand(
        () => {
            this.convertToEnquiryViewModel = new ConvertToEnquiryViewModel(this.cancelConvertToEnquiryCommand, this.convertLostEnquiryToActiveEnquiryCommand);
        },
        () => !this.apiClient.IsBusy && this.canDisplayDisplayConvertToEnquiryCommand,
    );

    // Convert lost enquiry to active enquiry.
    public get canDisplayConvertToEnquiryCommand() {
        return !this.isEditMode && !this.model.isQuote && this.model.isLost && !isEmptyOrWhitespace(this.model.id);
    }

    public convertLostEnquiryToActiveEnquiryCommand = new RelayCommand(
        () => {
            if (this.canSubmitForm) {
                this.apiClient.sendAsync(new POSTSaveLostEnquiryAsEnquiryEndpoint(this), this.model);
                this.convertToEnquiryViewModel = null;
            }
        },
        () => !this.apiClient.IsBusy && this.canDisplayConvertToEnquiryCommand,
    );

    public cancelConvertToEnquiryCommand = new RelayCommand(() => {
        this.convertToEnquiryViewModel = null;
    });

    // Display mark enquiry as lost.
    public get canDisplayDisplayMarkAsLostEnquiryCommand() {
        return this.canDisplayMarkAsLostEnquiryCommand;
    }

    public displayMarkAsLostEnquiryCommand = new RelayCommand(
        () => {
            const lostWithNoteViewModel = new LostWithNoteViewModel(
                "Lost enquiry",
                "Please select a reason why this enquiry has been lost.",
                this.cancelMarkEnquiryAsLostCommand,
                this.markEnquiryAsLostCommand,
                new LostWithNoteModel(),
            );

            this.lostWithNoteViewModel = lostWithNoteViewModel;
        },
        () => !this.apiClient.IsBusy && this.canDisplayDisplayMarkAsLostEnquiryCommand,
    );

    // Mark enquiry as lost.
    public get canDisplayMarkAsLostEnquiryCommand() {
        return !this.isEditMode && !this.model.isQuote && !this.model.isLost && !isEmptyOrWhitespace(this.model.id);
    }

    public markEnquiryAsLostCommand = new RelayCommand(
        () => {
            if (this.canSubmitForm) {
                this.apiClient.sendAsync(new POSTSaveEnquiryAsLostEndpoint(this), this.model);
                this.lostWithNoteViewModel = null;
            }
        },
        () => !this.apiClient.IsBusy && this.canDisplayMarkAsLostEnquiryCommand,
    );

    public cancelMarkEnquiryAsLostCommand = new RelayCommand(() => {
        this.lostWithNoteViewModel = null;
    });

    // Others
    public updateStatusTaskCommand = new RelayCommand((value: string) => {
        this.apiClient.sendAsync(new POSTSaveEnquiryStatusEndpoint(this), this.model);
    });

    public updatePersonVisitingCommand = new RelayCommand((value: string) => {
        this.apiClient.sendAsync(new POSTSavePersonVisitingEndpoint(this), this.model);
    });

    public saveEnquiryNoteCommand = new RelayCommand((noteViewModel: NoteViewModel) => {
        this.apiClient.sendAsync(new POSTSaveEnquiryNoteEndpoint(this, noteViewModel), noteViewModel.editNoteViewModel?.model);
    });

    public backToEnquiriesCommand = new RelayCommand(
        () => {
            this.history.push(AppUrls.Client.Enquiries.Table);
        },
        () => !this.isEditMode,
    );

    public resetServerErrorCommand = new RelayCommand(() => {
        this.apiClient.reset();
    });

    // #endregion Commands

    // #region Supporting

    public get canSubmitForm(): boolean {
        const isFormValid = this.isModelValid();

        // Note that enquiry note files validation is called here, even though it
        // is a sub-viewmodel of the enquiry sub-viewmodel.
        const isEnquiryValid = this.enquirySubViewModel.isModelValid();
        const isSourceOfEnquiryValid = this.enquirySubViewModel.sourceOfEnquiryViewModel.isModelValid();
        const isEnquiryNoteFilesValid = this.enquirySubViewModel.filesViewModel.isModelValid();
        const isCustomerValid = this.customerViewModel.isModelValid();
        return isFormValid && isEnquiryValid && isSourceOfEnquiryValid && isEnquiryNoteFilesValid && isCustomerValid;
    }

    //#endregion Supporting
}
