import { isNullOrEmpty } from "@shoothill/core";
import moment from "moment";
import { config } from "../config";

export const setPageTitle = (title: string): void => {
    const currentPageTitle = document.getElementsByTagName("title")[0].innerHTML as string;
    if (!isNullOrEmpty(title)) {
        const newPageTitle = config.rootPageTitle + " | " + title;
        if (currentPageTitle !== newPageTitle) {
            document.title = newPageTitle;
        }
    }
};

export const scrollIntoView = (element: HTMLElement | null) => {
    setTimeout(() => {
        element!.scrollIntoView({ block: "start", behavior: "smooth" });
    }, 100);
};

export const copyAndSort = <T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] => {
    const key = columnKey as keyof T;
    return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
};

// #region Formatters

export const formatCurrency = (value: number, isoCode = "en-GB", currency = "GBP"): string => {
    // Create our number formatter.
    const formatter = new Intl.NumberFormat(isoCode, {
        style: "currency",
        currency: currency,

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return formatter.format(value); /* $2,500.00 */
};

export const formatDate = (value: Date, isoCode = "en-GB"): string => {
    const formatter = new Intl.DateTimeFormat(isoCode);

    return formatter.format(value); /* 15/06/2022 */
};

export const formatTime = (value: Date, isoCode = "en-GB"): string => {
    const formatter = new Intl.DateTimeFormat(isoCode, {
        hour: "2-digit",
        minute: "2-digit",
    });

    return formatter.format(value);
};

export const formatDecimalNumber = (value: number, isoCode = "en-GB"): string => {
    const formatter = new Intl.NumberFormat(isoCode, {
        maximumFractionDigits: 2,
    });

    return formatter.format(value);
};

export const formatFixedDecimalNumber = (value: number, fractionDigits: number = 2, isoCode = "en-GB"): string => {
    const formatter = new Intl.NumberFormat(isoCode, {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
    });

    return formatter.format(value);
};

// #endregion Formatters

// #region DateTime Utils

export const startOfDay = (date: Date | null | undefined) => {
    return date ? moment(date).clone().startOf("day").toDate() : date;
};

export const endOfDay = (date: Date | null | undefined) => {
    return date ? moment(date).clone().startOf("day").add(1, "day").subtract(1, "second").toDate() : date;
};

// #endregion DateTime Utils

export const isTypeOfNumber = (value: any): boolean => {
    return typeof value === "number" && !isNaN(value);
};
