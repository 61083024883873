import { isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { computed, makeObservable, observable } from "mobx";
import { container } from "tsyringe";

import { APIClient, RelayCommand } from "Application";
import { AppUrls } from "AppUrls";
import { NotesViewModel } from "Views/Shared/Note/NotesViewModel";
import { NoteViewModel } from "Views/Shared/Note/NoteViewModel";
import {
    NOTECONTEXT_PROJECT,
    PROJECTSTATUS_INPROGRESS,
    PROJECTSTATUS_INSTRUCTED,
    PROJECTSTATUS_NEWPROJECT,
    PROJECTSTATUS_ONHOLD,
    PROJECTSTATUS_COMPLETED,
} from "Views/Shared/Constants";
import { ProjectDetailsModel } from "./ProjectDetailsModel";
import { DELETEProjectEndpoint } from "./Endpoints/DELETEProjectEndpoint";
import { GETProjectDetailsWithRelatedByIdEndpoint } from "./Endpoints/GETProjectDetailsWithRelatedByIdEndpoint";
import { POSTSaveCustomerContactEndpoint } from "./Endpoints/POSTSaveCustomerContactEndpoint";
import { POSTSaveProjectNoteEndpoint } from "./Endpoints/POSTSaveProjectNoteEndpoint";
import { POSTSaveProjectAsInstructedEndpoint } from "./Endpoints/POSTSaveProjectAsInstructedEndpoint";
import { POSTSaveProjectAsPutOnHoldEndpoint } from "./Endpoints/POSTSaveProjectAsPutOnHoldEndpoint";
import { POSTSaveProjectAsTakenOffHoldEndpoint } from "./Endpoints/POSTSaveProjectAsTakenOffHoldEndpoint";
import { POSTSaveProjectAsCompleteEndpoint } from "./Endpoints/POSTSaveProjectAsCompletedEndpoint";
import { POSTSaveProjectAsReopenedEndpoint } from "./Endpoints/POSTSaveProjectAsReopenedEndPoint";
import { POSTSetProjectLeadEndpoint } from "./Endpoints/POSTSetProjectLeadEndpoint";
import { POSTSetSeniorAssociateEndpoint } from "./Endpoints/POSTSetSeniorAssociateEndpoint";
import { POSTSetProjectSupportEndpoint } from "./Endpoints/POSTSetProjectSupportEndpoint";
import { ProjectAsCompleteViewModel } from "./ModalViews/CompleteProject/ProjectAsCompleteViewModel";
import { DeleteProjectViewModel } from "./ModalViews/DeleteProject/DeleteProjectViewModel";
import { CustomerViewModel } from "./SubViews/Customer/CustomerViewModel";
import { ProjectViewModel } from "../ProjectViewModel";
import { LookupStore } from "../../../Stores/Domain";

export class ProjectDetailsViewModel extends ViewModelBase<ProjectDetailsModel> {
    public apiClient = new APIClient();
    private lookupStore = container.resolve(LookupStore);

    // Hosted in its own viewmodel.
    public parentViewModel: ProjectViewModel;
    public customerViewModel: CustomerViewModel;
    public notesViewModel: NotesViewModel;
    public deleteProjectViewModel: DeleteProjectViewModel | null = null;
    public projectAsCompleteViewModel: ProjectAsCompleteViewModel | null = null;

    constructor(parentViewModel: ProjectViewModel) {
        super(new ProjectDetailsModel());

        this.parentViewModel = parentViewModel;
        this.customerViewModel = new CustomerViewModel(this.model.customer, this.saveCustomerContactCommand);
        this.notesViewModel = new NotesViewModel(this.model.notes, this.saveQuoteNoteCommand, NOTECONTEXT_PROJECT);

        makeObservable(this, {
            // Observables
            deleteProjectViewModel: observable,
            projectAsCompleteViewModel: observable,

            // Computeds
            projectLeads: computed,
            projectSupports: computed,
            seniorAssociates: computed,
            canDisplayDeleteProjectConfirmation: computed,
            canDisplayProjectAsCompleteConfirmation: computed,
        });

        this.apiClient.sendAsync(new GETProjectDetailsWithRelatedByIdEndpoint(this.parentViewModel.model.id, this));
    }

    /**
     * Disposes any resources.
     */
    public dispose = (): void => {
        this.notesViewModel.dispose();
    };

    // #region Properties

    public get projectLeads() {
        return this.model.projectLeads.map((item) => {
            return {
                key: item.id,
                text: `${item.firstName} ${item.lastName}`,
                data: item.thumbnailDocumentUrl,
            };
        });
    }

    public get projectSupports() {
        return this.model.projectSupports.map((item) => {
            return {
                key: item.id,
                text: `${item.firstName} ${item.lastName}`,
                data: item.thumbnailDocumentUrl,
            };
        });
    }

    public get seniorAssociates() {
        return this.model.seniorAssociates.map((item) => {
            return {
                key: item.id,
                text: `${item.firstName} ${item.lastName}`,
                data: item.thumbnailDocumentUrl,
            };
        });
    }

    // #endregion Properties

    // #region Complete Project Commands

    public get canDisplayProjectAsCompleteConfirmation(): boolean {
        return this.projectAsCompleteViewModel !== null;
    }

    public displayProjectAsCompleteConfirmationCommand = new RelayCommand(
        () => {
            this.projectAsCompleteViewModel = new ProjectAsCompleteViewModel(this.cancelProjectAsCompleteConfirmationCommand, this.submitProjectAsCompletedCommand);
        },
        () => {
            const status = this.lookupStore.projectStatuses.find((ps) => ps.id === this.model.projectStatusId);
            const canExecute = status?.type === PROJECTSTATUS_INPROGRESS || status?.type === PROJECTSTATUS_INSTRUCTED;

            return canExecute;
        },
    );

    public submitProjectAsCompletedCommand = new RelayCommand(async () => {
        await this.apiClient.sendAsync(new POSTSaveProjectAsCompleteEndpoint(this), this.model);

        if (this.apiClient.IsRequestSuccessful) {
            this.projectAsCompleteViewModel = null;
            this.apiClient.reset();
        }
    });

    public cancelProjectAsCompleteConfirmationCommand = new RelayCommand(() => {
        this.projectAsCompleteViewModel = null;
    });

    // #endregion Complete Project Commands

    // #region Delete Project Commands

    public get canDisplayDeleteProjectConfirmation(): boolean {
        return this.deleteProjectViewModel !== null;
    }

    public displayDeleteProjectConfirmationCommand = new RelayCommand(
        () => {
            this.deleteProjectViewModel = new DeleteProjectViewModel(this.cancelDeleteProjectConfirmationCommand, this.submitDeleteProjectCommand);
        },
        () => {
            const status = this.lookupStore.projectStatuses.find((ps) => ps.id === this.model.projectStatusId);

            return status?.type === PROJECTSTATUS_NEWPROJECT;
        },
    );

    public submitDeleteProjectCommand = new RelayCommand(async () => {
        await this.apiClient.sendAsync(new DELETEProjectEndpoint(this.model.id!));

        if (this.apiClient.IsRequestSuccessful) {
            this.deleteProjectViewModel = null;
            this.apiClient.reset();
            this.navigateToProjectsCommand.execute();
        }
    });

    public cancelDeleteProjectConfirmationCommand = new RelayCommand(() => {
        this.deleteProjectViewModel = null;
    });

    // #endregion Delete Project Commands

    // #region Commands

    public updateProjectLeadCommand = new RelayCommand((value: string) => {
        this.setValue("projectLeadId", value);
        this.apiClient.sendAsync(new POSTSetProjectLeadEndpoint(this), this.model);
    });

    public updateProjectSupportCommand = new RelayCommand((value: string) => {
        this.setValue("projectSupportId", value);
        this.apiClient.sendAsync(new POSTSetProjectSupportEndpoint(this), this.model);
    });

    public updateSeniorAssociateCommand = new RelayCommand((value: string) => {
        this.setValue("seniorAssociateId", value);
        this.apiClient.sendAsync(new POSTSetSeniorAssociateEndpoint(this), this.model);
    });

    public saveCustomerContactCommand = new RelayCommand(() => {
        this.apiClient.sendAsync(new POSTSaveCustomerContactEndpoint(this), this.model);
    });

    public saveQuoteNoteCommand = new RelayCommand((noteViewModel: NoteViewModel) => {
        this.apiClient.sendAsync(new POSTSaveProjectNoteEndpoint(this, noteViewModel), noteViewModel.editNoteViewModel?.model);
    });

    public markProjectAsInstructedCommand = new RelayCommand(
        () => {
            this.apiClient.sendAsync(new POSTSaveProjectAsInstructedEndpoint(this), this.model);
        },
        () => {
            // Rule stating if project can be set to instructed.
            // 1. The projects status is a New Project.
            // 2. Lead and senior associate are defined
            const status = this.lookupStore.projectStatuses.find((ps) => ps.id === this.model.projectStatusId);
            const isNewProject = status?.type === PROJECTSTATUS_NEWPROJECT;
            const hasMandatoryProjectStakeholders = !isEmptyOrWhitespace(this.model.projectLeadId) && !isEmptyOrWhitespace(this.model.seniorAssociateId);

            return isNewProject && hasMandatoryProjectStakeholders;
        },
    );

    public setProjectAsReopenedCommand = new RelayCommand(
        () => {
            this.apiClient.sendAsync(new POSTSaveProjectAsReopenedEndpoint(this), this.model);
        },
        () => {
            const status = this.lookupStore.projectStatuses.find((ps) => ps.id === this.model.projectStatusId);
            const canReopenProject = status?.type === PROJECTSTATUS_COMPLETED;

            return canReopenProject;
        },
    );

    public putProjectOnHoldCommand = new RelayCommand(
        () => {
            this.apiClient.sendAsync(new POSTSaveProjectAsPutOnHoldEndpoint(this), this.model);
        },
        () => {
            const status = this.lookupStore.projectStatuses.find((ps) => ps.id === this.model.projectStatusId);
            const isOnHoldOrInstructedProject = status?.type === PROJECTSTATUS_INPROGRESS || status?.type === PROJECTSTATUS_INSTRUCTED;

            return isOnHoldOrInstructedProject;
        },
    );

    public takeProjectOffHoldCommand = new RelayCommand(
        () => {
            this.apiClient.sendAsync(new POSTSaveProjectAsTakenOffHoldEndpoint(this), this.model);
        },
        () => {
            const status = this.lookupStore.projectStatuses.find((ps) => ps.id === this.model.projectStatusId);
            const isOnHoldProject = status?.type === PROJECTSTATUS_ONHOLD;

            return isOnHoldProject;
        },
    );

    public resetServerErrorCommand = new RelayCommand(() => {
        this.apiClient.reset();
    });

    public navigateToProjectsCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.Projects.Table);
    });

    // #endregion Commands
}
