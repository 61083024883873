import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { nanoid } from "nanoid";

export class WeeklyUpdatesItemModel extends ModelBase<WeeklyUpdatesItemModel> {
    // Note: some observables are predefined in the ModelBase class.
    // If any errors arise, check there to see if the property exists
    // and if so, delete it from here.
    // public createdDate: Date | null = null;
    // public updatedDate: Date | null = null;

    public KEY: string = nanoid();
    public projectUpdateTransactionId: string = "";
    public projectUpdateDate: Date | null = null;
    public projectUpdateCommencementDate: Date | null = null;
    public numberOfUpdatesAvailable: number = 0;
    public numberOfUpdatesProcessed: number = 0;
    public numberOfUpdatesNotProcessed: number = 0;
    public numberOfUpdatesFailed: number = 0;
    public projectUpdateTransactionStatus: string = "";
    public createdByUserName: string = "";
    public createdDate: Date | null = null;

    constructor() {
        super();

        makeObservable(this, {
            KEY: observable,
        });
    }
}
