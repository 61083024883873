import { action, makeObservable, computed, observable } from "mobx";
import { IModel, ViewModelBase } from "@shoothill/core";
import { IKeyState } from "Application";

//This is a base class for all view models that have a UI component associated with it
//Holds useful methods and properties for all view models that have a UI component associated with it
//To be extended as needed.

export class ViewModelBaseExtendedViewModel<T extends IModel<T>> extends ViewModelBase<T> {
    //**The ViewModels should have the ability to set at a more granular level which parts of the ui are busy
    //**We need to have the ability to set determine which parts are busy without directly using the apiClient.
    //**The apiClient is a global object so using the isBusy flag on the apiClient will cause issues if the entire ui is listening to the isBusy flag.

    public busyProcessingData: boolean = false;

    constructor(initialState: T) {
        super(initialState);
        makeObservable(this, {
            busyProcessingData: observable,
            setIsBusyProcessingData: action,
            isBusyProcessingData: computed,
        });
    }

    public setIsBusyProcessingData = (v: boolean) => (this.busyProcessingData = v);

    public get isBusyProcessingData() {
        return this.busyProcessingData;
    }

    public startedProcessingData = () => this.setIsBusyProcessingData(true);

    public finishedProcessingData = () => this.setIsBusyProcessingData(false);
}
