import { FieldType, ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";
import { nanoid } from "nanoid";

import { endOfDay, RelayCommand, startOfDay } from "Application";
import { AssignTaskModel, AssignTaskModelValidator } from "../AssignTaskModel";
import { FilesViewModel } from "Views/Shared/Files/FilesViewModel";

export class AssignViewModel extends ViewModelBase<AssignTaskModel> {
    private key = nanoid();

    public filesViewModel = new FilesViewModel();

    constructor(model: AssignTaskModel) {
        super(model);

        this.setValidator(new AssignTaskModelValidator());

        makeObservable(this, {
            // Observables
        });
    }

    // #region Properties

    public get displayName() {
        return "Assign";
    }

    public get KEY() {
        return this.key;
    }

    public get users() {
        return this.model.users
            .filter((u) => u.isAssignable)
            .map((item) => {
                return {
                    key: item.id,
                    text: `${item.firstName} ${item.lastName}`,
                    data: item.thumbnailDocumentUrl,
                };
            })
            .sort((a, b) => a.text.localeCompare(b.text));
    }

    public get templateNotes() {
        const options = this.model.projectTaskAssignmentTemplateNotes
            .map((t) => {
                return {
                    key: t.id,
                    text: t.name,
                };
            })
            .sort((a, b) => (a.text > b.text ? 1 : b.text > a.text ? -1 : 0));

        const distinctOptions = options.filter((option, index, arr) => arr.findIndex((t) => t.key === option.key) === index);

        return [...distinctOptions];
    }

    // #endregion Properties

    // #region Commands

    public updateUserCommand = new RelayCommand((value: string) => {
        this.updateField("userId", value);
    });

    public updateEffortCommand = new RelayCommand((value: string) => {
        const parsedValue = parseFloat(value);
        const checkedValue = Number.isNaN(parsedValue) ? null : parsedValue;

        this.updateField("effort", checkedValue);
    });

    public updateBillableEffortCommand = new RelayCommand(
        (value: string) => {
            const parsedValue = parseFloat(value);
            const checkedValue = Number.isNaN(parsedValue) ? null : parsedValue;

            this.updateField("billableEffort", checkedValue);
        },
        () => {
            // Billable effort cannot be set if a task is billed hourly.
            return !this.model.projectTaskIsBilledHourly;
        },
    );

    public updateIsNonBillableEffortCommand = new RelayCommand(() => {
        this.updateField("isNonBillableEffort", !this.model.isNonBillableEffort);
    });

    public updatePlannedStartDateCommand = new RelayCommand((date: Date | undefined) => {
        // Start date should align with midnight utc (if that is not provided by parameter).
        const startDate = startOfDay(date);
        this.updateField("plannedStartDate", startDate);
        // Update planned end date if the planned end date is before the start date
        // Planned end should align with midnight utc (if that is not provided by parameter).
        const endDate = endOfDay(date);
        this.updateField("plannedEndDate", endDate);
    });

    public updatePlannedEndDateCommand = new RelayCommand((date: Date | undefined) => {
        // Dates should align with midnight utc (if that is not provided by parameter).
        const endDate = endOfDay(date);

        this.updateField("plannedEndDate", endDate);
    });

    public updateDescriptionCommand = new RelayCommand((value: string) => {
        this.updateField("note", value);
    });

    public updateDescriptionViaTemplateCommand = new RelayCommand((id: string) => {
        const templateNote = this.model.projectTaskAssignmentTemplateNotes.find((t) => t.id == id)?.note;
        this.updateField("note", templateNote ? templateNote : null);
    });

    // #endregion Commands

    // #region Supporting

    private updateField(fieldName: keyof FieldType<AssignTaskModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    // #endregion Supporting
}
