import styled from "@emotion/styled";

import { theme } from "Application";
import { Task, ViewMode } from "gantt-task-react";

// #region ViewMode Bar Styles

export const ViewModeTabbed = styled.div`
    display: flex;
    align-items: flex-end;
    background-color: transparent;
    justify-content: flex-end;
    margin-top: -5px;

    div {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 1px;
        min-width: 80px;
        height: 30px;
        background-color: #2d91ff;
        cursor: pointer;
        span {
            color: white;
            font-size: 10px;
            font-family: ${theme.defaultFontStyle.fontFamily};
            text-transform: uppercase;
        }
    }

    .activeTab {
        background-color: #dcdcdc;

        span {
            color: #171716;
        }
    }
`;

// #endregion ViewMode Bar Styles

// #region Table Header Styles

export const GanttTable = styled.div`
    display: table;
    border-bottom: #e6e4e4 1px solid;
    border-top: #e6e4e4 1px solid;
    border-left: #e6e4e4 1px solid;
`;

export const GanttTableHeader = styled.div`
    display: table-row;
    list-style: none;
`;

export const GanttTableHeaderSeparator = styled.div`
    border-right: 1px solid #e1e1e1;
    opacity: 1;
    margin-left: -2px;
`;

export const GanttTableHeaderItem = styled.div`
    display: table-cell;
    text-align: center;
    text-transform: uppercase;
    vertical-align: -webkit-baseline-middle;
    vertical-align: middle;
    padding: 0 15px;
    white-space: pre-wrap;
`;

// #endregion Table Header Styles

// #region Table Body Styles

export const TaskList = styled.div`
    display: table;
    border-bottom: #e6e4e4 1px solid;
    border-left: #e6e4e4 1px solid;
`;

export const TaskListTableRow = styled.div`
    display: table-row;
    text-overflow: ellipsis;
`;

export const TaskListCell = styled.div`
    display: table-cell;
    vertical-align: middle;
    white-space: pre-wrap;
    overflow: hidden;
    padding-left: 15px;
    padding-right: 15px;
`;

export const TaskListNameWrapper = styled.div`
    display: flex;
`;

export const TaskListExpander = styled.div`
    color: rgb(86 86 86);
    font-size: 0.6rem;
    padding: 0.15rem 0.2rem 0rem 0.2rem;
    user-select: none;
    cursor: pointer;
`;

export const TaskListEmptyExpander = styled.div`
    font-size: 0.6rem;
    user-select: none;
`;

// #endregion Table Body Styles

// #region Chart Styles

export const GanttChartContainer = styled.div`
    margin-bottom: 25px;

    // The gantt chart component.
    div {
        .calendar {
            rect {
                fill: #dcdcdc;
            }
        }
        ._2k9Ys {
            height: auto;
        }
        .handleGroup {
            g {
                rect {
                    fill: #ffffff66;
                }
            }
        }
    }
`;

// #endregion Chart Styles

// #region Style Helpers

export const ganttChartGetColumnWidth = (viewMode: ViewMode): number => {
    switch (viewMode) {
        case ViewMode.Year:
            return 620;

        case ViewMode.Month:
            return 465;

        case ViewMode.Week:
            return 310;

        case ViewMode.Day:
        default:
            return 155;
    }
};

export const getBarCornerRadius = (rowHeight: number, barFill: number): number => {
    return (rowHeight * barFill) / 200;
};

// #endregion Style Helpers
