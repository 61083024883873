import { isEmptyOrWhitespace, isNullOrUndefined, ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";
import moment from "moment";

import { UnassignedTaskItemModel } from "./UnassignedTaskItemModel";

export class UnassignedTaskItemViewModel extends ViewModelBase<UnassignedTaskItemModel> {
    constructor(model = new UnassignedTaskItemModel()) {
        super(model);
        makeObservable(this, {});
    }

    public get KEY() {
        return this.model.KEY;
    }

    public get reference() {
        return `${this.model.reference} ${this.model.title}`;
    }

    public get projectTaskGroupName() {
        return this.model.projectTaskGroupName;
    }

    public get projectTaskName() {
        return this.model.originReference ? `${this.model.originReference} ${this.model.projectTaskName}` : `${this.model.reference} ${this.model.projectTaskName}`;
    }

    public get projectLeadName() {
        return `${this.model.projectLeadFirstName} ${this.model.projectLeadLastName}`;
    }

    public get projectLeadDocumentUrl() {
        return this.model.projectLeadDocumentUrl;
    }

    public get remainingProjectTaskBillableEffort() {
        return this.model.remainingProjectTaskBillableEffort ? this.model.remainingProjectTaskBillableEffort : "N/A";
    }

    /**
     * Determines if the task can be displayed, given a set of filters.
     *
     * @param filterKeyword A general filter to cover a number of propoerties of the task.
     * @param filterProjectId A project filter.
     * @param filterProjectTaskGroupName A project group filter.
     * @param filterProjectLeadId A project lead filter.
     * @returns true if the quote can be displayed otherwise false.
     */
    public filterPredicate = (
        filterKeyword: string,

        filterProjectId: string | null,
        filterProjectTaskGroupName: string | null,
        filterProjectLeadId: string | null,
    ): boolean => {
        let result = true;

        // Attempt filtering by keyword.
        if (result) {
            const lowerCaseFilterKeyword = filterKeyword.toLowerCase();
            switch (true) {
                case isEmptyOrWhitespace(filterKeyword):
                case this.model.reference.toLowerCase().includes(lowerCaseFilterKeyword):
                case this.model.title.toLowerCase().includes(lowerCaseFilterKeyword):
                case this.model.projectTaskGroupName.toLowerCase().includes(lowerCaseFilterKeyword):
                case this.model.projectTaskName.toLowerCase().includes(lowerCaseFilterKeyword):
                    result = true;
                    break;

                default:
                    result = false;
                    break;
            }
        }

        // If the task has passed the project date filter, attempt filtering by project.
        if (result) {
            switch (true) {
                case isEmptyOrWhitespace(filterProjectId):
                case this.model.id === filterProjectId:
                    result = true;
                    break;

                default:
                    result = false;
                    break;
            }
        }

        // If the task has passed the project filter, attempt filtering by project group.
        if (result) {
            switch (true) {
                case isEmptyOrWhitespace(filterProjectTaskGroupName):
                case this.model.projectTaskGroupName === filterProjectTaskGroupName:
                    result = true;
                    break;

                default:
                    result = false;
                    break;
            }
        }

        // If the task has passed the project group filter, attempt filtering by project lead.
        if (result) {
            switch (true) {
                case isEmptyOrWhitespace(filterProjectLeadId):
                case this.model.projectLeadId === filterProjectLeadId:
                    result = true;
                    break;

                default:
                    result = false;
                    break;
            }
        }

        return result;
    };
}
