import { observer } from "mobx-react-lite";
import React from "react";

import { Box, ThemedButton, ThemedText } from "Application";
import { NoteView } from "Views/Shared/Note/NoteView";
import { NoteReadOnlyView } from "Views/Shared/Note/NoteReadOnlyView";
import { FormDottedSeparator } from "Views/Shared/SharedComponents";
import { NotesViewModel } from "./NotesViewModel";
import { NOTECONTEXT_ENQUIRY, NOTECONTEXT_PROJECT, NOTECONTEXT_QUOTE } from "../Constants";

export interface INotesViewProps {
    viewModel: NotesViewModel;
}

export const NotesView: React.FC<INotesViewProps> = observer((props) => {
    const viewModel = props.viewModel;

    return (
        <React.Fragment>
            <Box display={"flex"} mt={3} justifyContent={"space-between"}>
                <ThemedText fontStyle={"h3"}>NOTES</ThemedText>
                <ThemedButton paletteColor={"primary"} displayName="Add Note" command={viewModel.addNoteCommand} />
            </Box>
            <FormDottedSeparator />
            <Box mt={3} mb={"55px"}>
                {viewModel.notes.map((vm) => {
                    switch (viewModel.getNoteContext) {
                        case NOTECONTEXT_ENQUIRY:
                            return <NoteView key={vm.KEY} viewModel={vm} />;
                            break;

                        case NOTECONTEXT_QUOTE:
                            return vm.isQuoteNote || vm.model.editMode ? <NoteView key={vm.KEY} viewModel={vm} /> : <NoteReadOnlyView key={vm.KEY} viewModel={vm} />;
                            break;

                        case NOTECONTEXT_PROJECT:
                            return <NoteView key={vm.KEY} viewModel={vm} />;
                            break;

                        default:
                            return <NoteView key={vm.KEY} viewModel={vm} />;
                            break;
                    }
                })}
            </Box>
        </React.Fragment>
    );
});
