import { ViewModelBase, isEmptyOrWhitespace } from "@shoothill/core";

import { RelayCommand } from "Application";
import { AppUrls } from "AppUrls";
import { CalloutInformationItemModel } from "./CalloutInformationItemModel";

export class CalloutInformationItemViewModel extends ViewModelBase<CalloutInformationItemModel> {
    constructor(model: CalloutInformationItemModel) {
        super(model);
    }

    public get KEY() {
        return this.model.KEY;
    }

    public get customerName() {
        return !isEmptyOrWhitespace(this.model.customerName) ? this.model.customerName : "Customer not available";
    }

    public get projectReference() {
        return !isEmptyOrWhitespace(this.model.projectReference) ? this.model.projectReference : "Project not available";
    }

    public navigateToCustomerCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.Customers.Details.replace(":id", this.model.customerId));
    });

    public navigateToProjectCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.Projects.Details.replace(":id", this.model.projectId));
    });

    // #region Boilerplate
    // #endregion Boilerplate
}
