import { Callout, DirectionalHint, mergeStyleSets } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React from "react";

import { pxToRem, theme } from "Application";
import { DownloadCalloutViewModel } from "./DownloadCalloutViewModel";
import { DownloadIcon } from "Assets/Icons/DownloadIcon";
import { DownloadCalloutItemViewModel } from "./DownloadCalloutItemViewModel";

export interface IDownloadCalloutState {
    isCalloutVisble: boolean;
    targetId: string;
    viewModel: DownloadCalloutViewModel | null;
}

interface IProps {
    onDismiss: () => void;
    targetId: string;
    viewModel: DownloadCalloutViewModel | null;
}

const callOutStyles = {
    root: {},
    divider: {
        borderBottom: "1px solid #E1E1E1",
    },
    header: {
        fontSize: pxToRem(10),
        fontWeight: "bold",
        margin: `${pxToRem(8)} ${pxToRem(10)} ${pxToRem(0)} ${pxToRem(10)}`,
    },
    row: {
        alignItems: "center",
        display: "flex",
        height: pxToRem(30),
        margin: `${pxToRem(5)} ${pxToRem(10)}`,
    },
    data: {
        cursor: "pointer",
        display: "flex",
        fontFamily: theme.fontStyles.tableHeader.fontFamily ?? theme.defaultFontStyle.fontFamily,
        fontSize: pxToRem(10),
        width: pxToRem(120),
        svg: {
            marginRight: pxToRem(10),
        },
    },
};

export const DownloadCalloutView: React.FC<IProps> = observer((props) => {
    const viewModel = props.viewModel!;
    const styles = mergeStyleSets(callOutStyles);

    const downloadLetterDocumentLink = (itemViewModel: DownloadCalloutItemViewModel) => (
        <div
            className={styles.data}
            onClick={() => {
                itemViewModel.downloadQuoteLetterDocumentCommand.execute();
                props?.onDismiss();
            }}
        >
            <DownloadIcon />
            <div>Preview feebid</div>
        </div>
    );

    const downloadTasksAndCostsDocumentLink = (itemViewModel: DownloadCalloutItemViewModel) => (
        <div
            className={styles.data}
            onClick={() => {
                itemViewModel.downloadQuoteTasksAndCostsDocumentCommand.execute();
                props?.onDismiss();
            }}
        >
            <DownloadIcon />
            <div>Preview services</div>
        </div>
    );

    const downloadTermsAndConditionsDocumentLink = (itemViewModel: DownloadCalloutItemViewModel) => (
        <div
            className={styles.data}
            onClick={() => {
                itemViewModel.downloadQuoteTermsAndConditionsDocumentCommand.execute();
                props?.onDismiss();
            }}
        >
            <DownloadIcon />
            <div>Preview t&c's</div>
        </div>
    );

    return (
        <Callout className={styles.root} directionalHint={DirectionalHint.leftBottomEdge} onDismiss={props.onDismiss} target={`#${props.targetId}`}>
            {viewModel.items.map((itemViewModel) => {
                return (
                    <div>
                        {itemViewModel.canDisplayTitle && <div className={styles.header}>{itemViewModel.model.quoteTitle}</div>}
                        {itemViewModel.downloadQuoteLetterDocumentCommand.canExecute() && <div className={styles.row}>{downloadLetterDocumentLink(itemViewModel)}</div>}
                        {itemViewModel.downloadQuoteTasksAndCostsDocumentCommand.canExecute() && (
                            <div className={styles.row}>{downloadTasksAndCostsDocumentLink(itemViewModel)}</div>
                        )}
                        {itemViewModel.downloadQuoteTermsAndConditionsDocumentCommand.canExecute() && (
                            <div className={styles.row}>{downloadTermsAndConditionsDocumentLink(itemViewModel)}</div>
                        )}
                        <div className={styles.divider} />
                    </div>
                );
            })}
        </Callout>
    );
});
