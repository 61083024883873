import { FieldType, isEmptyOrWhitespace, isNullOrUndefined, ViewModelBase } from "@shoothill/core";
import { action, computed, makeObservable, observable } from "mobx";

import { APIClient, formatDate, formatTime, RelayCommand } from "Application";
import { WeeklyUpdateModel, WeeklyUpdateModelValidator } from "./WeeklyUpdateModel";

import { WeeklyUpdatesViewModel } from "../WeeklyUpdatesViewModel";
import { GETWeeklyUpdateByIdEndpoint } from "../Endpoints/GETWeeklyUpdateByIdEndpoint";
import { POSTSaveWeeklyUpdateEndpoint } from "../Endpoints/POSTSaveWeeklyUpdateEndpoint";

export class WeeklyUpdateViewModel extends ViewModelBase<WeeklyUpdateModel> {
    public apiClient = new APIClient();
    public responseReceived: boolean = false;
    //local variables
    public showAdditionalInformation = false;
    public parentViewModel: WeeklyUpdatesViewModel | null = null;
    public filterStartDate: Date | null = null;
    public projectId: string | null = null;

    constructor(projectId: string, parentViewModel: WeeklyUpdatesViewModel) {
        super(new WeeklyUpdateModel());
        makeObservable(this, {
            // Observables
            showAdditionalInformation: observable,
            filterStartDate: observable,
            projectId: observable,
            parentViewModel: observable,
            responseReceived: observable,
            // Computed values
            displayName: computed,
            canDisplayUpdateDetails: computed,
            canDisplayNoUpdateAvailable: computed,
            canDisplayReadonly: computed,
            //Actions
            setInitialValues: action,
            setResponseReceivedValue: action,
        });

        this.setValidator(new WeeklyUpdateModelValidator());
        this.setInitialValues(projectId, parentViewModel);

        const _ = this.apiClient.sendAsync(new GETWeeklyUpdateByIdEndpoint(this));
    }

    public setResponseReceivedValue(value: boolean) {
        this.responseReceived = value;
    }

    public setInitialValues(projectId: string, parentViewModel: WeeklyUpdatesViewModel) {
        this.filterStartDate = parentViewModel.model.programmingWeekStartDate;
        this.projectId = projectId;
        this.parentViewModel = parentViewModel;
    }

    public get KEY() {
        return this.model.KEY;
    }

    public get displayName() {
        return this.showAdditionalInformation ? "- Weekly update" : "+ Weekly update";
    }

    public get updateDetails() {
        const date = !isNullOrUndefined(this.model.updatedDate) ? this.model.updatedDate : this.model.createdDate;

        switch (true) {
            case !isNullOrUndefined(date):
                return `Last updated: ${formatDate(date!)} @ ${formatTime(date!)}`;

            default:
                return "";
        }
    }

    public get canDisplayUpdateDetails() {
        return !isEmptyOrWhitespace(this.updateDetails);
    }

    public get canDisplayNoUpdateAvailable() {
        return !this.model.hasUpdate;
    }

    public get canDisplayReadonly() {
        return this.model.hasUpdate && !this.model.hasActiveUpdate;
    }

    // #region Commands

    public toggleAdditionalInformationCommand = new RelayCommand(() => {
        this.showAdditionalInformation = !this.showAdditionalInformation;
    });

    public updateDetailCommand = new RelayCommand((value: string) => {
        this.updateField("updateDetail", value);
    });

    public updateInternalDetailCommand = new RelayCommand((value: string) => {
        this.updateField("internalDetail", value);
    });

    public onSubmitCommand = new RelayCommand(
        async () => {
            if (this.isModelValid()) {
                await this.apiClient.sendAsync(new POSTSaveWeeklyUpdateEndpoint(this), this.model);
                await this.apiClient.sendAsync(new GETWeeklyUpdateByIdEndpoint(this));
            }
        },
        () => {
            return !this.apiClient.IsBusy;
        },
    );

    public copyPreviousProjectUpdateUpdateDetailCommand = new RelayCommand(
        () => {
            if (!isNullOrUndefined(this.model.previousProjectUpdateUpdateDetail)) {
                this.model.setValue("updateDetail", this.model.previousProjectUpdateUpdateDetail);
            }
        },
        () => !isNullOrUndefined(this.model.previousProjectUpdateUpdateDetail),
    );

    public copyPreviousProjectUpdateInternalNoteCommand = new RelayCommand(
        () => {
            if (!isNullOrUndefined(this.model.previousProjectUpdateInternalDetail)) {
                this.model.setValue("internalDetail", this.model.previousProjectUpdateInternalDetail);
            }
        },
        () => !isNullOrUndefined(this.model.previousProjectUpdateInternalDetail),
    );

    // #endregion Commands

    // #region Supporting

    private updateField(fieldName: keyof FieldType<WeeklyUpdateModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    // #endregion Supporting
}
