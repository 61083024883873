import { FieldType, isEmptyOrWhitespace, isNullOrUndefined, ViewModelBase } from "@shoothill/core";
import { action, computed, makeObservable, observable } from "mobx";

import { APIClient, formatDate, formatTime, RelayCommand } from "Application";
import { WeeklyUpdateModel, WeeklyUpdateModelValidator } from "./WeeklyUpdateModel";
import { GETWeeklyUpdateByIdEndpoint } from "./GETWeeklyUpdateByIdEndpoint";
import { POSTSaveWeeklyUpdateEndpoint } from "./POSTSaveWeeklyUpdateEndpoint";
import { StaffDetailsItemViewModel } from "../../StaffDetailsItemViewModel";
import { StaffDetailsViewModel } from "../../StaffDetailsViewModel";
import { ProjectWeeklyUpdateContainerViewModel } from "./ProjectWeeklyUpdate/ProjectWeeklyUpdateContainerViewModel";

export class WeeklyUpdateViewModel extends ViewModelBase<WeeklyUpdateModel> {
    getProgrammingWeek() {
        throw new Error("Method not implemented.");
    }
    public apiClient = new APIClient();
    public showAdditionalInformation = false;
    public parentStaffDetailsItemViewModel: StaffDetailsItemViewModel;
    public parentViewModel: StaffDetailsViewModel;
    public projectId: string;
    public projectWeeklyUpdateContainerViewModel: ProjectWeeklyUpdateContainerViewModel | null = null;

    constructor(projectId: string, staffDetailsItemViewModel: StaffDetailsItemViewModel, parentViewModel: StaffDetailsViewModel) {
        super(new WeeklyUpdateModel());

        this.parentStaffDetailsItemViewModel = staffDetailsItemViewModel;
        this.parentViewModel = parentViewModel;

        this.projectId = projectId;

        this.setValidator(new WeeklyUpdateModelValidator());

        makeObservable(this, {
            // Observables
            showAdditionalInformation: observable,

            projectWeeklyUpdateContainerViewModel: observable,

            // Actions
            setProjectWeeklyUpdateViewModel: action,

            // Computed values
            displayName: computed,
            canDisplayUpdateDetails: computed,
            canDisplayNoUpdateAvailable: computed,
            canDisplayReadonly: computed,
            canDisplayProjectWeeklyUpdatesSidePanel: computed,
        });
    }

    //region actions

    public setProjectWeeklyUpdateViewModel = (value: ProjectWeeklyUpdateContainerViewModel | null) => {
        this.projectWeeklyUpdateContainerViewModel = value;
    };

    public openProjectWeeklyUpdateSidePanel = () => {
        this.setProjectWeeklyUpdateViewModel(new ProjectWeeklyUpdateContainerViewModel(this));
    };

    public closeProjectWeeklyUpdateSidePanel = () => {
        this.setProjectWeeklyUpdateViewModel(null);
    };

    //endregion actions

    public getWeeklyUpdate() {
        this.apiClient.sendAsync(new GETWeeklyUpdateByIdEndpoint(this));
    }

    public get KEY() {
        return this.model.KEY;
    }

    public get displayName() {
        return this.showAdditionalInformation ? "- Weekly update" : "+ Weekly update";
    }

    public get updateDetails() {
        const date = !isNullOrUndefined(this.model.updatedDate) ? this.model.updatedDate : this.model.createdDate;

        switch (true) {
            case !isNullOrUndefined(date):
                return `Last updated: ${formatDate(date!)} @ ${formatTime(date!)}`;

            default:
                return "";
        }
    }

    public get canDisplayProjectWeeklyUpdatesSidePanel() {
        return this.projectWeeklyUpdateContainerViewModel != null;
    }

    public get canDisplayUpdateDetails() {
        return !isEmptyOrWhitespace(this.updateDetails);
    }

    public get canDisplayNoUpdateAvailable() {
        return !this.model.hasUpdate;
    }

    public get canDisplayReadonly() {
        return this.model.hasUpdate && !this.model.hasActiveUpdate;
    }

    // #region Commands

    public toggleAdditionalInformationCommand = new RelayCommand(() => {
        this.showAdditionalInformation = !this.showAdditionalInformation;
    });

    public updateDetailCommand = new RelayCommand((value: string) => {
        this.updateField("updateDetail", value);
    });

    public updateInternalDetailCommand = new RelayCommand((value: string) => {
        this.updateField("internalDetail", value);
    });

    public updateKeyInformationCommand = new RelayCommand((value: string) => {
        this.updateField("keyInformation", value);
    });

    public updateSendUpdateCommand = new RelayCommand(() => {
        this.updateField("sendUpdate", !this.model.sendUpdate);
    });

    public onSubmitCommand = new RelayCommand(
        async () => {
            if (this.isModelValid()) {
                this.closeProjectWeeklyUpdateSidePanel();
                await this.apiClient.sendAsync(new POSTSaveWeeklyUpdateEndpoint(this), this.model);
                this.getWeeklyUpdate();
            }
        },
        () => {
            return !this.apiClient.IsBusy;
        },
    );

    public copyPreviousProjectUpdateUpdateDetailCommand = new RelayCommand(
        () => {
            //filter out the current weekly update
            if (!isNullOrUndefined(this.model.previousProjectUpdateUpdateDetail)) {
                this.model.setValue("updateDetail", this.model.previousProjectUpdateUpdateDetail);
            }
        },
        () => {
            return !this.apiClient.IsBusy && !isNullOrUndefined(this.model.previousProjectUpdateUpdateDetail);
        },
    );

    public copyPreviousProjectUpdateInternalDetailCommand = new RelayCommand(
        () => {
            //filter out the current weekly update
            if (!isNullOrUndefined(this.model.previousProjectUpdateInternalDetail)) {
                this.model.setValue("internalDetail", this.model.previousProjectUpdateInternalDetail);
            }
        },
        () => {
            return !this.apiClient.IsBusy && !isNullOrUndefined(this.model.previousProjectUpdateInternalDetail);
        },
    );

    public openPreviousWeeklyUpdatesSidePanelDetailCommand = new RelayCommand(
        () => {
            this.openProjectWeeklyUpdateSidePanel();
        },
        () => !isNullOrUndefined(this.model.previousProjectUpdateUpdateDetail) && isNullOrUndefined(this.projectWeeklyUpdateContainerViewModel),
    );
    // #endregion Commands

    // #region Supporting

    private updateField(fieldName: keyof FieldType<WeeklyUpdateModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    // #endregion Supporting
}
