import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import LetterTemplatesListViewModel from "./TermsAndConditionListViewModel";
import { TermsAndConditionItemModel } from "./TermsAndConditionItemModel";
import { TermsAndConditionItemViewModel } from "./TermsAndConditionItemViewModel";

export class Response {
    public id: string = "";
    public name: string = "";
    public subject: string = "";
    public body: string = "";
    public createdDate: string = "";
    public createdByUserId: string = "";
    public lastUpdatedDate: string = "";
}

export class GetAllTermsAndConditionEndpoint extends EndpointWithoutRequest<Response> {
    private viewModel: LetterTemplatesListViewModel;

    constructor(viewModel: LetterTemplatesListViewModel) {
        super();

        this.verb(Http.Get);
        this.path(AppUrls.Server.Admin.TermConditionTemplates.GetAllTermsAndConditionLite);
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: Response[]): Promise<any> {
        const termsAndConditionItemViewModel: TermsAndConditionItemViewModel[] = [];

        response.forEach((letterTemplate, index) => {
            let termsAndConditionItemModel = new TermsAndConditionItemModel();
            termsAndConditionItemModel.fromResponse(letterTemplate);
            termsAndConditionItemViewModel.push(new TermsAndConditionItemViewModel(termsAndConditionItemModel));
        });

        this.viewModel.termsAndConditionItemViewModel.replace(termsAndConditionItemViewModel);
    }
}
