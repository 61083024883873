//libraries
import { FieldType, ViewModelBase, formatCurrency } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { APIClient, IKeyState, RelayCommand, formatFixedDecimalNumber } from "Application";

//app
import { InvoiceForecastModelValidator, InvoiceForecastMonthCapacityModel } from "./InvoiceForecastMonthCapacityModel";
import moment from "moment";
import { InvoiceForecastMonthCapacityOverrideViewModel } from "../../../Shared/InvoiceForecastMonthCapacityOverride/InvoiceForecastMonthCapacityOverrideViewModel";

export class InvoiceForecastMonthCapacityViewModel extends ViewModelBase<InvoiceForecastMonthCapacityModel> {
    public apiClient = new APIClient();

    public invoiceForecastMonthCapacityOverride: InvoiceForecastMonthCapacityOverrideViewModel;

    constructor(invoiceForecastMonthCapacityOverride: InvoiceForecastMonthCapacityOverrideViewModel, model = new InvoiceForecastMonthCapacityModel()) {
        super(model, false);
        this.setValidator(new InvoiceForecastModelValidator());
        makeObservable(this, {
            invoiceForecastMonthCapacityOverride: observable,
        });
        this.invoiceForecastMonthCapacityOverride = invoiceForecastMonthCapacityOverride;
    }

    //region endpoint calls

    //endregion endpoint calls

    //region properties
    public get KEY() {
        return this.getValue("KEY");
    }

    public get month() {
        return this.model.month ? moment(this.model.month).format("MMMM") : "";
    }

    public get hours() {
        return this.model.hours ? formatFixedDecimalNumber(this.model.hours) : "";
    }

    public get value() {
        return this.model.value ? formatCurrency(this.model.value) : "";
    }

    public get overrideHours() {
        return this.invoiceForecastMonthCapacityOverride.hours;
    }

    public get overrideValue() {
        return this.invoiceForecastMonthCapacityOverride.value;
    }

    public get useOverrideValues() {
        return this.invoiceForecastMonthCapacityOverride.isActive;
    }

    public get monthNumber() {
        return this.model.month ? moment(this.model.month).month() : 0;
    }

    public get currentMonthNumber() {
        return moment(new Date()).month();
    }

    public get canOverride() {
        return this.model.month ? new Date().getTime() > new Date(this.model.month).getTime() && this.currentMonthNumber != this.monthNumber : false;
    }
    //endregion properties

    //region commands
    public updateOverrideHoursCommand = new RelayCommand(
        (value: string) => {
            if (this.invoiceForecastMonthCapacityOverride) {
                this.invoiceForecastMonthCapacityOverride.updateOverrideHoursCommand.execute(parseFloat(value));
            }
        },
        () => this.canOverride,
    );

    public updateOverrideValueCommand = new RelayCommand(
        (value: string) => {
            if (this.invoiceForecastMonthCapacityOverride) {
                this.invoiceForecastMonthCapacityOverride.updateOverrideValueCommand.execute(parseFloat(value));
            }
        },
        () => this.canOverride,
    );

    public updateUseOverrideValuesCommand = new RelayCommand(
        (value: boolean) => {
            if (this.invoiceForecastMonthCapacityOverride) {
                this.invoiceForecastMonthCapacityOverride.updateUseOverrideValuesCommand.execute(value);
            }
        },
        () => this.canOverride,
    );

    //endregion commands

    // #region Supporting

    private updateField(fieldName: keyof FieldType<InvoiceForecastMonthCapacityModel>, value: any, keyState?: IKeyState) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    // #endregion Supporting
}
