import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { nanoid } from "nanoid";

import { NoteModel } from "./NoteModel";

export class NotesModel extends ModelBase<NotesModel> {
    public KEY: string = nanoid();

    public notes = observable<NoteModel>([]);

    constructor() {
        super();
        makeObservable(this, {
            // Observables
            notes: observable,
        });
    }
}
