import { runInAction } from "mobx";

import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { LeaveDayType } from "Views/Leave/Shared/LeaveDayType";
import { LeaveRequestUser } from "Views/Leave/Shared/LeaveRequestUser";
import { LeaveType } from "Views/Leave/Shared/LeaveType";
import { LeaveCalendarViewModel } from "../LeaveCalendarViewModel";
import { LeaveCalendarModel } from "../LeaveCalendarModel";
import { BankHolidayEventModel } from "../Components/Body/Date/Components/BankHolidayEvent/BankHolidayEventModel";
import { BankHolidayEventViewModel } from "../Components/Body/Date/Components/BankHolidayEvent/BankHolidayEventViewModel";
import { HalfDaysEventViewModel } from "../Components/Body/Date/Components/HalfDaysEvent/HalfDaysEventViewModel";
import { LeaveRequestEventModel } from "../Components/Body/Date/Components/LeaveRequestEvent/LeaveRequestEventModel";
import { LeaveRequestEventViewModel } from "../Components/Body/Date/Components/LeaveRequestEvent/LeaveRequestEventViewModel";
import { UserCalendarViewModel } from "../Components/Body/UserCalendar/UserCalendarViewModel";
import { UserCalendarModel } from "../Components/Body/UserCalendar/UserCalendarModel";

class Request {
    public fromDate: Date | null = null;
    public toDate: Date | null = null;
}

class Response {
    public userCalendars: UserAndLeaveAllowanceResponse[] = [];
    public leaveTypes: LeaveTypeResponse[] = [];
    public leaveDayTypes: LeaveDayTypeResponse[] = [];
}

class BankHolidayResponse {
    public date: string | null = null;
    public title: string | null = null;
}

class LeaveRequestAndHalfDayResponse {
    public leaveRequest: LeaveRequestResponse | null = null;
    public leaveRequestHalfDays: HalfDayResponse[] = [];
}

class LeaveRequestResponse {
    public id: string | null = null;

    // Value Objects
    public leaveType: LeaveTypeResponse | null = null;
    public leaveStatusType: LeaveStatusTypeResponse | null = null;
    public fromDate: RequestDateResponse | null = null;
    public toDate: RequestDateResponse | null = null;

    // User Request
    public requestUserId: string | null = null;
    public requestDate: string | null = null;
    public requestReason: string | null = null;

    // User Response
    public responseUserId: string | null = null;
    public responseUserFullName: string | null = null;
    public responseDate: string | null = null;
    public responseReason: string | null = null;

    // Additonal
    public numberOfDays: number | null = null;
    public originalLeaveRequestId: string | null = null;
}

class LeaveTypeResponse {
    public id: string | null = null;
    public name: string | null = null;
    public type: string | null = null;
    public ordinal: number | null = null;
    public isDeleted: boolean | null = null;
    public foregroundColor: string | null = null;
    public backgroundColor: string | null = null;
    public isTransactionRequest: boolean | null = null;
    public isTransactionCancelRequest: boolean | null = null;
}

class LeaveStatusTypeResponse {
    public id: string | null = null;
    public name: string | null = null;
    public type: string | null = null;
    public ordinal: number | null = null;
    public isDeleted: boolean | null = null;
    public foregroundColor: string | null = null;
    public backgroundColor: string | null = null;
}

class RequestDateResponse {
    public date: string | null = null;
    public leaveDayType: LeaveDayTypeResponse | null = null;
}

class HalfDayResponse {
    public date: string | null = null;
    public isAfternoon: boolean = false;
}

class UserAndLeaveAllowanceResponse {
    public bankHolidays: BankHolidayResponse[] = [];
    public leaveRequests: LeaveRequestAndHalfDayResponse[] = [];
    public leaveAllowance: LeaveAllowanceResponse | null = null;
    public requestUser: AllowanceUserResponse | null = null;
}

class LeaveAllowanceResponse {
    public id: string | null = null;

    // Additonal
    public numberOfDays: number | null = null;
    public numberOfDaysAccrued: number | null = null;
    public numberOfDaysTaken: number | null = null;
    public numberOfDaysLeft: number | null = null;
}

class AllowanceUserResponse {
    public id: string | null = null;
    public firstName: string | null = null;
    public lastName: string | null = null;
    public documentUrl: string | null = null;
}

class LeaveDayTypeResponse {
    public id: string | null = null;
    public name: string | null = null;
    public type: string | null = null;
    public ordinal: number | null = null;
    public isStartDay: boolean | null = null;
}

export class GETAdminCalendarEventsEndpoint extends Endpoint<Request, Response> {
    private viewModel: LeaveCalendarViewModel;

    constructor(viewModel: LeaveCalendarViewModel) {
        super();

        this.viewModel = viewModel;
        this.verb(Http.Post);
        this.path(AppUrls.Server.Admin.Leave.GetAdminCalenedarEvents);
    }

    public async HandleRequestAsync(model: LeaveCalendarModel): Promise<any> {
        const request = new Request();

        request.fromDate = this.viewModel.startOfMonth.toDate();
        request.toDate = this.viewModel.endOfMonth.toDate();

        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            // Leave Types.
            const leaveTypes: LeaveType[] = [];

            response.leaveTypes.forEach((item: LeaveTypeResponse) => {
                const valueObject = new LeaveType();

                valueObject.id = item.id!;
                valueObject.name = item.name!;
                valueObject.type = item.type!;
                valueObject.ordinal = item.ordinal!;
                valueObject.isDeleted = item.isDeleted!;
                valueObject.foregroundColor = item.foregroundColor!;
                valueObject.backgroundColor = item.backgroundColor!;
                valueObject.isTransactionRequest = item.isTransactionRequest!;
                valueObject.isTransactionCancelRequest = item.isTransactionCancelRequest!;

                leaveTypes.push(valueObject);
            });

            this.viewModel.model.leaveTypes.replace(leaveTypes);

            // Leave Day Types.
            const leaveDayTypes: LeaveDayType[] = [];

            response.leaveDayTypes.forEach((item: LeaveDayTypeResponse) => {
                const valueObject = new LeaveDayType();

                valueObject.id = item.id!;
                valueObject.name = item.name!;
                valueObject.type = item.type!;
                valueObject.ordinal = item.ordinal!;
                valueObject.isStartDay = item.isStartDay!;

                leaveDayTypes.push(valueObject);
            });

            this.viewModel.model.leaveDayTypes.replace(leaveDayTypes);

            // User Calendars.
            const userCalendarViewModels: UserCalendarViewModel[] = [];
            const leaveRequestUsers: LeaveRequestUser[] = [];

            response.userCalendars.forEach((item: UserAndLeaveAllowanceResponse) => {
                // Users.
                const valueObject = new LeaveRequestUser();

                valueObject.id = item.requestUser!.id!;
                valueObject.firstName = item.requestUser!.firstName!;
                valueObject.lastName = item.requestUser!.lastName!;
                valueObject.documentUrl = item.requestUser!.documentUrl;

                leaveRequestUsers.push(valueObject);

                // Calendar.
                const userCalendarmodel = new UserCalendarModel();

                userCalendarmodel.fromResponse(item);

                const userCalendarViewModel = new UserCalendarViewModel(
                    userCalendarmodel,
                    this.viewModel.startOfMonth,
                    this.viewModel.endOfMonth,
                    this.viewModel.displayChangeAllowanceCommand,
                );

                // Calenadar - Bank Holidays.
                item.bankHolidays.forEach((item: BankHolidayResponse) => {
                    const dateViewModel = userCalendarViewModel.dateViewModels.find((dateViewModel) => {
                        const bankHolidayDate = new Date(item.date!);
                        const dateViewModelDate = dateViewModel.date;

                        return bankHolidayDate.getTime() === dateViewModelDate.getTime();
                    });

                    if (dateViewModel) {
                        const model = new BankHolidayEventModel();

                        model.fromResponse(item);

                        dateViewModel.eventViewModel = new BankHolidayEventViewModel(model);
                    }
                });

                // Calendar - Leave Requests.
                const dateGroups = new Map<string, LeaveRequestAndHalfDayResponse[]>();

                item.leaveRequests.forEach((item: LeaveRequestAndHalfDayResponse) => {
                    item.leaveRequestHalfDays.forEach((halfDay) => {
                        if (!dateGroups.has(halfDay.date!)) {
                            dateGroups.set(halfDay.date!, new Array<LeaveRequestAndHalfDayResponse>());
                        }

                        dateGroups.get(halfDay.date!)!.push(item);
                    });
                });

                dateGroups.forEach((group, key) => {
                    const dateViewModel = userCalendarViewModel.dateViewModels.find((dateViewModel) => {
                        const bankHolidayDate = new Date(key!);
                        const dateViewModelDate = dateViewModel.date;

                        return bankHolidayDate.getTime() === dateViewModelDate.getTime();
                    });

                    if (dateViewModel) {
                        const halfDayEventViewModel = new HalfDaysEventViewModel();

                        group.forEach((item: LeaveRequestAndHalfDayResponse) => {
                            const halfDays = item.leaveRequestHalfDays.filter((hf) => hf.date === key);

                            if (halfDays.some((hf) => !hf.isAfternoon)) {
                                const model = new LeaveRequestEventModel();

                                model.fromResponse(item);

                                halfDayEventViewModel.morningEventViewModel = new LeaveRequestEventViewModel(model, this.viewModel);
                            }

                            if (halfDays.some((hf) => hf.isAfternoon)) {
                                const model = new LeaveRequestEventModel();

                                model.fromResponse({ leaveRequest: item.leaveRequest });

                                halfDayEventViewModel.afternoonEventViewModel = new LeaveRequestEventViewModel(model, this.viewModel);
                            }
                        });

                        dateViewModel.eventViewModel = halfDayEventViewModel;
                    }
                });

                userCalendarViewModels.push(userCalendarViewModel);
            });

            this.viewModel.userCalendarViewModels.replace(userCalendarViewModels);
            this.viewModel.model.users.replace(leaveRequestUsers);
        });
    }
}
