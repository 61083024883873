import { runInAction } from "mobx";
import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { FileModel } from "Views/Shared/Files/FileModel";
import { Supplier, SupplierContact } from "./SupplierDetailModel";
import SupplierDetailViewModel from "./SupplierDetailViewModel";

class SupplierDocumentResponse {
    id: string = "";
    fileName: string = "";
    fileSizeBytes: number = 0;
    mimeType: string = "";
    documentUrl: string = "";
}
class Response {
    public supplier: Supplier = new Supplier();
    public supplierContacts: SupplierContact[] = [];
    public documents: SupplierDocumentResponse[] = [];
}

export class GETSupplierDetailsEndpoint extends EndpointWithoutRequest<Response> {
    private readonly viewModel: SupplierDetailViewModel;

    constructor(id: string, viewModel: SupplierDetailViewModel) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.Suppliers.GetSupplierDetailsById.replace(":id", id));
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        runInAction(() => {
            this.viewModel.getModel.supplier.fromResponse(response.supplier);

            this.viewModel.model.supplierContacts.replace(
                response.supplierContacts.map((e) => {
                    const model = new SupplierContact();
                    model.fromResponse(e);
                    return model;
                }),
            );

            // Process to supplier documents.
            this.viewModel.filesViewModel.model.files.replace(
                response.documents.map((nd) => {
                    const model = new FileModel();
                    model.fromResponse(nd);
                    return model;
                }) ?? [],
            );
        });
    }
}
