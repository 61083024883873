import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

export class QuotesAwaitingActionModel extends ModelBase<QuotesAwaitingActionModel> {
    public totalSentQuoteHours: number = 0;
    public totalSentQuoteValue: number = 0;

    // Filtering
    public filterKeyword: string = "";
    public filterQuoteStatusId: string | null = null;
    public filterFromDate: Date | undefined = undefined;
    public filterToDate: Date | undefined = undefined;
    public filterQuoteLeadId: string | null = null;

    constructor() {
        super();
        makeObservable(this, {
            // Observables
            totalSentQuoteHours: observable,
            totalSentQuoteValue: observable,

            filterKeyword: observable,
            filterQuoteStatusId: observable,
            filterFromDate: observable,
            filterToDate: observable,
            filterQuoteLeadId: observable,
        });
    }
}
