import { ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

import { BankHolidayEventModel } from "./BankHolidayEventModel";

export class BankHolidayEventViewModel extends ViewModelBase<BankHolidayEventModel> {
    constructor(model: BankHolidayEventModel) {
        super(model, false);

        makeObservable(this, {});
    }

    public get displayName() {
        return this.model.title;
    }
}
