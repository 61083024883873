import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

export class DashboardModel extends ModelBase<DashboardModel> {
    public id: string = "";
    public numberOfPendingAndApprovedQuotes: number | null = null;
    public numberOfUpdatedProjectTaskAssignments: number | null = null;
    public numberOfInProgressProjectTaskAssignments: number | null = null;
    public numberOfCompleteProjectTaskAssignments: number | null = null;
    public numberOfPendingLeaveRequests: number | null = null;

    constructor() {
        super();
        makeObservable(this, {
            id: observable,
            numberOfPendingAndApprovedQuotes: observable,
            numberOfUpdatedProjectTaskAssignments: observable,
            numberOfInProgressProjectTaskAssignments: observable,
            numberOfCompleteProjectTaskAssignments: observable,
            numberOfPendingLeaveRequests: observable,
        });
    }
}

export class DashboardModelValidator extends Validator<DashboardModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
    }
}
