import styled from "@emotion/styled";
import { theme } from "Application";

export const Container = styled.div`
    height: 62px;
    min-width: 850px;
    display: grid;

    grid-template-columns: 56px 1fr 250px repeat(2, 150px) 65px;
    padding-left: 15px;
    padding-right: 15px;
    align-items: center;
    width: 100%;

    border-bottom: 1px dashed #cccccc;
`;

export const ColumnLeft = styled.div`
    display: flex;
    align-items: center;
    padding-left: 15px;
    border-right: 1px solid ${theme.palette.common.border};
`;
export const ColumnCenter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 15px;
    border-right: 1px solid ${theme.palette.common.border};
`;
export const ColumnRight = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;
