import _ from "lodash";
import { runInAction } from "mobx";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { TermsAndConditionViewModel } from "./TermsAndConditionViewModel";
import { TermsAndConditionModel } from "./TermsAndConditionModel";

class Request {
    public id: string | null = null;
    public name: string | null = null;
    public body: string | null = null;
    public termsAndConditionsTypeId: string | null = null;
}

class Response {}

export class POSTSaveTermsAndConditionEndpoint extends Endpoint<Request, Response> {
    private readonly viewModel: TermsAndConditionViewModel;

    constructor(viewModel: TermsAndConditionViewModel) {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Admin.TermConditionTemplates.PostSaveTermsAndCondition);
        this.viewModel = viewModel;
    }

    public async HandleRequestAsync(model: TermsAndConditionModel): Promise<any> {
        let request = new Request();

        request.id = model.id;
        request.name = model.name;
        request.body = model.body;
        request.termsAndConditionsTypeId = model.termsAndConditionsTypeId;

        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        runInAction(() => {
            this.viewModel.history.push(AppUrls.Client.TermConditionTemplates.Table);
        });
    }
}
