import { isEmptyOrWhitespace, isNullOrUndefined, ViewModelBase } from "@shoothill/core";
import { theme } from "Application";
import { endOfDay, startOfDay } from "Application/Utils";
import { makeObservable } from "mobx";
import moment from "moment";
import { LookupStore } from "Stores/Domain";
import { container } from "tsyringe";

import { AssignedTaskItemModel } from "./AssignedTaskItemModel";

export class AssignedTaskItemViewModel extends ViewModelBase<AssignedTaskItemModel> {
    private lookupStore = container.resolve(LookupStore);

    constructor(model = new AssignedTaskItemModel()) {
        super(model);
        makeObservable(this, {});
    }

    public get KEY() {
        return this.model.KEY;
    }

    public get projectTaskAssignmentStatusName() {
        if (this.model.isNonBillableEffort) {
            return this.model.projectTaskAssignmentStatusName + " as NCT";
        }

        return this.model.projectTaskAssignmentStatusName;
    }

    public get reference() {
        return `${this.model.reference} ${this.model.title}`;
    }

    public get projectTaskName() {
        return this.model.originReference ? `${this.model.originReference} ${this.model.projectTaskName}` : `${this.model.reference} ${this.model.projectTaskName}`;
    }

    public get projectLeadName() {
        return `${this.model.projectLeadFirstName} ${this.model.projectLeadLastName}`;
    }

    public get projectLeadDocumentUrl() {
        return this.model.projectLeadDocumentUrl;
    }

    public get assignedName() {
        return `${this.model.assignedFirstName} ${this.model.assignedLastName}`;
    }

    public get assignedDocumentUrl() {
        return this.model.assignedDocumentUrl;
    }

    public get billableEffort() {
        return this.model.billableEffort;
    }

    public get plannedStartDate() {
        return this.model.plannedStartDate;
    }

    public get plannedEndDate() {
        return this.model.plannedEndDate;
    }

    public get completedDate() {
        return this.model.completedDate;
    }

    public get projectTaskAssignmentStatusForegroundColor() {
        if (this.model.isNonBillableEffort) {
            return theme.palette.common.deepRed;
        }

        return this.model.projectTaskAssignmentStatusForegroundColor;
    }

    /**
     * Determines if the task can be displayed, given a set of filters.
     *
     * @param filterKeyword A general filter to cover a number of propoerties of the task.
     * @param filterDate A date filter.
     * @param filterProjectTaskAssignmentStatusId A project task assignment status filter.
     * @param filterProjectId A project filter.
     * @param filterProjectLeadId A project lead filter.
     *
     * @returns true if the quote can be displayed otherwise false.
     */
    public filterPredicate = (
        filterKeyword: string,
        filterDate: Date | undefined,
        filterProjectTaskAssignmentStatusId: string | null,
        filterProjectId: string | null,
        filterProjectLeadId: string | null,
    ): boolean => {
        let result = true;

        // Attempt filtering by keyword.
        if (result) {
            const lowerCaseFilterKeyword = filterKeyword.toLowerCase();
            switch (true) {
                case isEmptyOrWhitespace(filterKeyword):
                case this.model.reference.toLowerCase().includes(lowerCaseFilterKeyword):
                case this.model.title.toLowerCase().includes(lowerCaseFilterKeyword):
                case this.model.projectTaskName.toLowerCase().includes(lowerCaseFilterKeyword):
                    result = true;
                    break;

                default:
                    result = false;
                    break;
            }
        }

        // If the task has passed the keyword filter, attempt filtering by date.
        if (result) {
            switch (true) {
                case isNullOrUndefined(filterDate):
                    result = true;
                    break;

                case isNullOrUndefined(this.model.plannedStartDate):
                    result = false;
                    break;

                case moment(this.model.plannedStartDate).isSameOrBefore(moment(startOfDay(filterDate))):
                    result = true;
                    break;

                default:
                    result = false;
                    break;
            }
        }

        // If the task has passed the keyword filter, attempt filtering by date.
        if (result) {
            switch (true) {
                case isNullOrUndefined(filterDate):
                    result = true;
                    break;

                case isNullOrUndefined(this.model.plannedEndDate):
                    result = false;
                    break;

                case moment(this.model.plannedEndDate).isSameOrAfter(moment(endOfDay(filterDate))):
                    result = true;
                    break;

                default:
                    result = false;
                    break;
            }
        }

        // If the task has passed the date filter, attempt filtering by project task status.
        if (result) {
            this.lookupStore.projectTaskAssignmentStatuses;

            const isAssignedStatus = this.model.projectTaskAssignmentStatusName == "Assigned";
            const isAssignedWithNCTStatus = isAssignedStatus && this.model.isNonBillableEffort;

            const isAssignedWithNCTStatusFilter = filterProjectTaskAssignmentStatusId == "NCT";

            switch (true) {
                case isEmptyOrWhitespace(filterProjectTaskAssignmentStatusId):
                case this.model.projectTaskAssignmentStatusId === filterProjectTaskAssignmentStatusId && isAssignedStatus == false && isAssignedWithNCTStatus == false:
                    result = true;
                    break;
                case this.model.projectTaskAssignmentStatusId === filterProjectTaskAssignmentStatusId && isAssignedStatus == true && isAssignedWithNCTStatus == false:
                    result = true;
                    break;
                case isAssignedWithNCTStatus && isAssignedWithNCTStatusFilter:
                    result = true;
                    break;
                default:
                    result = false;
                    break;
            }
        }
        // If the task has passed the project status task filter, attempt filtering by project.
        if (result) {
            switch (true) {
                case isEmptyOrWhitespace(filterProjectId):
                case this.model.projectId === filterProjectId:
                    result = true;
                    break;

                default:
                    result = false;
                    break;
            }
        }

        // If the task has passed the project group filter, attempt filtering by project lead.
        if (result) {
            switch (true) {
                case isEmptyOrWhitespace(filterProjectLeadId):
                case this.model.projectLeadId === filterProjectLeadId:
                    result = true;
                    break;

                default:
                    result = false;
                    break;
            }
        }

        return result;
    };
}
