import { ViewModelBase } from "@shoothill/core";
import { computed, makeObservable, observable } from "mobx";
import moment from "moment";

import { APIClient, RelayCommand } from "Application";
import { AppUrls } from "AppUrls";
import { LeaveRequest } from "Views/Leave/Shared/LeaveRequest";
import { CancelLeaveRequestModel } from "Views/Leave/Shared/ModalViews/CancelLeaveRequest/CancelLeaveRequestModel";
import { CancelLeaveRequestViewModel } from "Views/Leave/Shared/ModalViews/CancelLeaveRequest/CancelLeaveRequestViewModel";
import { POSTCancelLeaveEndpoint } from "Views/Leave/Shared/ModalViews/CancelLeaveRequest/Endpoints/POSTCancelLeaveRequestEndpoint";
import { DeleteLeaveRequestModel } from "Views/Leave/Shared/ModalViews/DeleteLeaveRequest/DeleteLeaveRequestModel";
import { DeleteLeaveRequestViewModel } from "Views/Leave/Shared/ModalViews/DeleteLeaveRequest/DeleteLeaveRequestViewModel";
import { POSTDeleteLeaveEndpoint } from "Views/Leave/Shared/ModalViews/DeleteLeaveRequest/Endpoints/POSTDeleteLeaveRequestEndpoint";
import { NewRequestModel } from "Views/Leave/Shared/ModalViews/NewRequest/NewRequestModel";
import { NewRequestViewModel } from "Views/Leave/Shared/ModalViews/NewRequest/NewRequestViewModel";
import { POSTRequestLeaveEndpoint } from "Views/Leave/Shared/ModalViews/NewRequest/Endpoints/POSTRequestLeave";
import { LeaveCalendarModel } from "./LeaveCalendarModel";
import { DateViewModel } from "./Components/Body/Date/DateViewModel";
import { DateModel } from "./Components/Body/Date/DateModel";
import { WeekendEventViewModel } from "./Components/Body/Date/Components/WeekendEvent/WeekendEventViewModel";
import { NoEventViewModel } from "./Components/Body/Date/Components/NoEvent/NoEventViewModel";
import { GETCalendarEventsEndpoint } from "./Endpoints/GETCalendarEvents";

export class LeaveCalendarViewModel extends ViewModelBase<LeaveCalendarModel> {
    public apiClient = new APIClient();

    public relativeMonthIndex: number = 0;
    public newRequestViewModel: NewRequestViewModel | null = null;
    public cancelLeaveRequestViewModel: CancelLeaveRequestViewModel | null = null;
    public deleteLeaveRequestViewModel: DeleteLeaveRequestViewModel | null = null;

    constructor(model = new LeaveCalendarModel()) {
        super(model, false);

        makeObservable(this, {
            // Observables
            relativeMonthIndex: observable,
            newRequestViewModel: observable,
            cancelLeaveRequestViewModel: observable,
            deleteLeaveRequestViewModel: observable,

            // Computeds
            startOfMonth: computed,
            endOfMonth: computed,
            canDisplayNewRequest: computed,
            dateViewModels: computed,
        });

        const _ = this.apiClient.sendAsync(new GETCalendarEventsEndpoint(this));
    }

    // #region Calendar

    public get currentMonthAndYear() {
        return moment().startOf("month").add(this.relativeMonthIndex, "month").format("MMMM YYYY");
    }

    public get startOfMonth() {
        return moment().startOf("month").add(this.relativeMonthIndex, "month").startOf("isoWeek");
    }

    public get endOfMonth() {
        return moment().endOf("month").add(this.relativeMonthIndex, "month").endOf("isoWeek");
    }

    public get dateViewModels() {
        const dateViewModels: DateViewModel[] = [];

        for (let index = 0; this.startOfMonth.clone().add(index, "day") < this.endOfMonth; index++) {
            const momentDate = this.startOfMonth.clone().add(index, "day");

            const dateViewModel = new DateViewModel(new DateModel(momentDate.toDate()));

            switch (true) {
                case momentDate.isoWeekday() === 6: // Saturday
                case momentDate.isoWeekday() === 7: // Sunday
                    dateViewModel.eventViewModel = new WeekendEventViewModel();
                    break;

                default:
                    dateViewModel.eventViewModel = new NoEventViewModel();
                    break;
            }

            dateViewModels.push(dateViewModel);
        }

        return dateViewModels;
    }

    public previousMonthCommand = new RelayCommand(() => {
        this.relativeMonthIndex--;
        this.apiClient.sendAsync(new GETCalendarEventsEndpoint(this));
    });

    public nextMonthCommand = new RelayCommand(() => {
        this.relativeMonthIndex++;
        this.apiClient.sendAsync(new GETCalendarEventsEndpoint(this));
    });

    // #endregion Calendar

    // #region New Request Commands

    public get canDisplayNewRequest(): boolean {
        return this.newRequestViewModel !== null;
    }

    public displayNewRequestCommand = new RelayCommand(() => {
        this.newRequestViewModel = new NewRequestViewModel(
            new NewRequestModel(this.model.leaveTypes, this.model.leaveDayTypes),
            this.submitNewRequestCommand,
            this.cancelNewRequestCommand,
        );
    });

    public submitNewRequestCommand = new RelayCommand(
        async () => {
            await this.apiClient.sendAsync(new POSTRequestLeaveEndpoint(), this.newRequestViewModel?.model);

            if (this.apiClient.IsRequestSuccessful) {
                this.newRequestViewModel = null;
                this.apiClient.reset();
                this.apiClient.sendAsync(new GETCalendarEventsEndpoint(this));
            }
        },
        () => {
            return !this.apiClient.IsBusy;
        },
    );

    public cancelNewRequestCommand = new RelayCommand(() => {
        this.newRequestViewModel = null;
    });

    // #endregion New Request Commands

    // #region Edit Request Commands

    public get canDisplayEditRequest(): boolean {
        return this.newRequestViewModel !== null;
    }

    public displayEditRequestCommand = new RelayCommand((leaveRequest: LeaveRequest) => {
        this.newRequestViewModel = new NewRequestViewModel(
            new NewRequestModel(this.model.leaveTypes, this.model.leaveDayTypes, leaveRequest),
            this.submitEditRequestCommand,
            this.cancelEditRequestCommand,
        );
    });

    public submitEditRequestCommand = new RelayCommand(
        async () => {
            await this.apiClient.sendAsync(new POSTRequestLeaveEndpoint(), this.newRequestViewModel?.model);

            if (this.apiClient.IsRequestSuccessful) {
                this.newRequestViewModel = null;
                this.apiClient.reset();
                this.apiClient.sendAsync(new GETCalendarEventsEndpoint(this));
            }
        },
        () => {
            return !this.apiClient.IsBusy;
        },
    );

    public cancelEditRequestCommand = new RelayCommand(() => {
        this.newRequestViewModel = null;
    });

    // #endregion Edit Request Commands

    // #region Cancel Request Commands

    public get canDisplayCancelRequest(): boolean {
        return this.cancelLeaveRequestViewModel !== null;
    }

    public displayCancelRequestCommand = new RelayCommand((leaveRequest: LeaveRequest) => {
        this.cancelLeaveRequestViewModel = new CancelLeaveRequestViewModel(
            new CancelLeaveRequestModel(leaveRequest),
            this.submitCancelRequestCommand,
            this.cancelCancelRequestCommand,
        );
    });

    public submitCancelRequestCommand = new RelayCommand(
        async () => {
            await this.apiClient.sendAsync(new POSTCancelLeaveEndpoint(), this.cancelLeaveRequestViewModel?.model);

            if (this.apiClient.IsRequestSuccessful) {
                this.cancelLeaveRequestViewModel = null;
                this.apiClient.reset();
                this.apiClient.sendAsync(new GETCalendarEventsEndpoint(this));
            }
        },
        () => {
            return !this.apiClient.IsBusy;
        },
    );

    public cancelCancelRequestCommand = new RelayCommand(() => {
        this.cancelLeaveRequestViewModel = null;
    });

    // #endregion Cancel Request Commands

    // #region Delete Rquest Commands

    public get canDisplayDeleteRequest(): boolean {
        return this.deleteLeaveRequestViewModel !== null;
    }

    public displayDeleteRequestCommand = new RelayCommand((leaveRequest: LeaveRequest) => {
        this.deleteLeaveRequestViewModel = new DeleteLeaveRequestViewModel(
            new DeleteLeaveRequestModel(leaveRequest),
            this.submitDeleteRequestCommand,
            this.cancelDeleteRequestCommand,
        );
    });

    public submitDeleteRequestCommand = new RelayCommand(
        async () => {
            await this.apiClient.sendAsync(new POSTDeleteLeaveEndpoint(), this.deleteLeaveRequestViewModel?.model);

            if (this.apiClient.IsRequestSuccessful) {
                this.deleteLeaveRequestViewModel = null;
                this.apiClient.reset();
                this.apiClient.sendAsync(new GETCalendarEventsEndpoint(this));
            }
        },
        () => {
            return !this.apiClient.IsBusy;
        },
    );

    public cancelDeleteRequestCommand = new RelayCommand(() => {
        this.deleteLeaveRequestViewModel = null;
    });

    // #endregion Delete Request Commands

    // #region Commands

    public resetServerErrorCommand = new RelayCommand(() => {
        this.apiClient.reset();
    });

    public navigateToRequestsCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.DashboardStaff.LeaveRequests);
    });

    // #endregion Commands
}
