import { observer } from "mobx-react-lite";
import React from "react";
import { PlanningApplicationTableViewModel } from "../PlanningApplicationTableViewModel";
import styled from "@emotion/styled";
import { AutoGrid, Box, Nullcommand, ThemedComboBox, ThemedEditDate, ThemedEditText, ThemedIconButton, ThemedTransparentIconButton, theme } from "Application";
import { SearchIcon } from "Assets/SearchIcon";
import { CSVIcon } from "Assets/Icons/CSVIcon";
import { padding } from "styled-system";
import { ExportCSVButton } from "Components/General/ExportCSVButton";

interface IPlanningApplicationTableFilterProps {
    viewModel: PlanningApplicationTableViewModel;
}

const controlStyles = {
    root: {
        height: "39px",
        fontSize: "17px",
        borderColor: "#E1E1E1",
    },
};

const DashedSeparator = styled.div`
    height: 1px;
    border-bottom: 1px dashed ${theme.palette.field.light};
`;

export const PlanningApplicationTableFilter: React.FC<IPlanningApplicationTableFilterProps> = observer(({ viewModel }) => {
    // #region Code Behind

    return (
        <AutoGrid dc={"1fr"} alignItems={"center"} rowGap={"10px"} mt={0} mb={0}>
            {/* first row */}
            <AutoGrid dc={"1fr 1fr"} columnGap={"30px"} mt={0} mb={0}>
                <ThemedEditText
                    command={viewModel.updateFilterKeywordCommand}
                    placeholder={"Keyword search"}
                    prefix={<SearchIcon />}
                    styles={controlStyles}
                    value={() => viewModel.getValue("filterKeyword")}
                />

                <AutoGrid dc={"1fr 1fr 1fr 30px"} columnGap={"10px"} mt={0} mb={0}>
                    <ThemedComboBox
                        command={viewModel.updateFinancialPeriodTypeCommand}
                        options={viewModel.financialPeriodTypes}
                        size="larger"
                        value={() => viewModel.getValue("financialPeriodTypeId")}
                        showLabel={false}
                    />
                    <ThemedEditDate
                        command={viewModel.updateFilterDateFromCommand}
                        placeholder="Action from"
                        size="larger"
                        value={() => viewModel.getValue("filterDateFrom")}
                        showLabel={false}
                    />

                    <ThemedEditDate
                        command={viewModel.updateFilterDateToCommand}
                        placeholder="Action to"
                        size="larger"
                        value={() => viewModel.getValue("filterDateTo")}
                        showLabel={false}
                    />

                    <ExportCSVButton command={viewModel.downloadFilteredPlanningApplicationCSVCommand} isBusy={viewModel.isBusyProcessingData} />
                </AutoGrid>
            </AutoGrid>
            {/* separator */}
            <DashedSeparator />
            {/* second row */}
            <AutoGrid dc={"1fr 1fr"} alignItems={"center"} columnGap={"30px"} mt={0} mb={0}>
                <AutoGrid dc={"1fr 1fr 1fr"} columnGap={"30px"} mt={0} mb={0}>
                    <ThemedComboBox
                        command={viewModel.updateFilterLocalAuthorityDistrictCommand}
                        options={viewModel.localAuthorityDistrictOptions}
                        size="larger"
                        value={() => viewModel.getValue("filterLocalAuthorityDistrictId")}
                    />
                    <ThemedComboBox
                        command={viewModel.updateFilterPlanningApplicationTypeCommand}
                        options={viewModel.planningApplicationTypeOptions}
                        size="larger"
                        value={() => viewModel.getValue("filterPlanningApplicationTypeId")}
                    />
                    <ThemedComboBox
                        command={viewModel.updateFilterProjectLeadCommand}
                        options={viewModel.projectLeads}
                        size="larger"
                        value={() => viewModel.getValue("filterProjectLeadId")}
                    />
                </AutoGrid>
                <Box></Box>
            </AutoGrid>
        </AutoGrid>
    );
});
