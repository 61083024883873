import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { FileModel } from "./FileModel";
import { FileViewModel } from "./FileViewModel";
import { ImageSettingsViewModel } from "./ImageSettingsViewModel";

export class Response {
    public fileName: string = "";
    public fileSizeBytes: number = 0;
    public mimeType: string = "";
    public documentUrl: string = "";
    public createdDate: string = "";
    public createdByUserId: string = "";
    public isDeleted: boolean = false;
    public id: string = "";
}

export class GetAllImageSettingsLiteEndpoint extends EndpointWithoutRequest<Response> {
    private viewModel: ImageSettingsViewModel;

    constructor(viewModel: ImageSettingsViewModel) {
        super();

        this.verb(Http.Get);
        this.path(AppUrls.Server.Admin.ImageSettings.GetAllImageSettingsLite);
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: Response[]): Promise<any> {
        const fileViewModels: FileViewModel[] = [];

        response.forEach((image) => {
            let imageModel = new FileModel();

            imageModel.fromResponse(image);

            fileViewModels.push(new FileViewModel(imageModel, this.viewModel.deleteCommand));
        });

        this.viewModel.fileViewModels.replace(fileViewModels);
    }
}
