import React, { useState, useRef, useEffect } from "react";
import styled from "@emotion/styled";
import { ThemedText, theme, Box, Nullcommand } from "Application";
import { observer } from "mobx-react-lite";
import { OverviewViewModel } from "../OverviewViewModel";
import { ProgrammingViewModel } from "./ProgrammingViewModel";
import { ResponsivePie } from "@nivo/pie";
import { ThemedLoadingSpinner } from "Styles/ThemedPrimitives/Style1/ThemedLoadingSpinner";
import { LinkButton } from "Components/General/LinkButton";

interface IProps {
    parentViewModel: OverviewViewModel;
}

const Container = styled.div`
    padding: 20px 25px;
    align-self: flex-end;
`;

const PieChartContainer = styled.div`
    height: 180px;
    width: 180px;

    position: relative;
`;

const PieChart = styled.div`
    border-radius: 50%;
    position: relative;

    height: 100%;
    width: 100%;
    z-index: 2;
`;

const PseudoPieChart = styled.div`
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
    border-radius: 50%;
    box-sizing: border-box;
    border: 20px solid ${theme.palette.common.lighterGray};
    z-index: -1;
`;

const PieChartTotalValueLabel = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
`;

export const ProgrammingView: React.FC<IProps> = observer((props: IProps) => {
    const { parentViewModel } = props;
    const [viewModel] = useState(() => new ProgrammingViewModel(parentViewModel));

    useEffect(() => {
        if (parentViewModel.render) {
            viewModel.getProgrammingDetails();
        }
    }, [parentViewModel.render]);

    const renderPieChart = () => (
        <PieChartContainer>
            <PieChart>
                <ResponsivePie
                    motionConfig={"wobbly"}
                    data={viewModel.data}
                    margin={{ top: 1, right: 1, bottom: 1, left: 1 }}
                    innerRadius={0.8}
                    padAngle={0.7}
                    cornerRadius={0}
                    activeOuterRadiusOffset={8}
                    borderWidth={1}
                    borderColor={{
                        from: "color",
                        modifiers: [["darker", 0.2]],
                    }}
                    arcLinkLabelsSkipAngle={10}
                    arcLinkLabelsTextColor="#333333"
                    arcLinkLabelsThickness={2}
                    arcLinkLabelsColor={{ from: "color" }}
                    arcLabelsSkipAngle={10}
                    arcLabelsTextColor={{
                        from: "color",
                        modifiers: [["darker", 2]],
                    }}
                    colors={{ datum: "data.color" }}
                    enableArcLabels={false}
                    enableArcLinkLabels={false}
                    isInteractive={false}
                />
                <PseudoPieChart />
                {viewModel.isLoading && (
                    <Box width={"180px"} height={"180px"} position={"absolute"} top={"0px"}>
                        <ThemedLoadingSpinner color="secondary" showDisplayMessage={false} width={"180px"} height={"180px"} borderWidth="20px" />
                    </Box>
                )}
                <PieChartTotalValueLabel>
                    <span style={{ fontSize: "1.8rem", fontWeight: 600 }}>
                        {viewModel.render && <>{viewModel.programmedHoursPercentageStringRepresentation}</>}
                        {!viewModel.render && <div style={{ fontSize: "12px" }}>Fetching data...</div>}
                    </span>
                    {viewModel.render && <ThemedText fontStyle="workItemLabelBold">of programmed hours billed</ThemedText>}
                </PieChartTotalValueLabel>
            </PieChart>
        </PieChartContainer>
    );

    return (
        <Container>
            <Box display={"flex"} alignItems="center" justifyContent={"center"} flexDirection={"column"} marginBottom={"15px"}>
                <ThemedText fontStyle="pBold">
                    Live projects:{" "}
                    {viewModel.render && (
                        <>
                            {viewModel.numberOfLiveProjects} ({viewModel.numberOfProjectsOnHold} on hold)
                        </>
                    )}
                    {!viewModel.render && <> Fetching data...</>}
                </ThemedText>
                <ThemedText fontStyle="pBold" color="secondary">
                    Target hours : {viewModel.render && <>{viewModel.targetHours}</>}
                    {!viewModel.render && <> Fetching data...</>}
                </ThemedText>
                <ThemedText fontStyle="pBold" color="secondary">
                    Billed hours: {viewModel.render && <>{viewModel.totalBilledEffort}</>}
                    {!viewModel.render && <> Fetching data...</>}
                </ThemedText>
                <ThemedText fontStyle="pBold" color="secondary">
                    Overage:{viewModel.render && <span style={{ color: viewModel.overageHoursStatusColour }}> {viewModel.overage}</span>}
                    {!viewModel.render && <> Fetching data...</>}
                </ThemedText>
            </Box>
            {renderPieChart()}
            {viewModel.requestHasTimedOut && (
                <Box fontSize={"12px"} marginTop={"10px"} display={"flex"} alignItems={"center"} justifyContent={"center"} flexDirection={"column"}>
                    <span>The request has timed out.</span> <LinkButton paletteColor={"default"} displayName="Try again" command={viewModel.retryRequestCommand} />
                </Box>
            )}
        </Container>
    );
});
