import { ModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

export class BankHolidayEventModel extends ModelBase<BankHolidayEventModel> {
    public title: string = "";

    constructor() {
        super();

        makeObservable(this, {});
    }
}
