import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { Box, setPageTitle } from "Application";
import { ProjectQuoteHistoryViewModel } from "./ProjectQuoteHistoryViewModel";
import { FormDottedSeparator, PageContainer, RevisionNavigationContainer } from "Views/Shared/SharedComponents";
import { ThemedLoader } from "Styles/ThemedPrimitives/Style1/ThemedLoader";
import { ProjectViewModel } from "../ProjectViewModel";
import { QuoteListItemViewModel } from "./Container/Shared/QuoteListItemViewModel";
import { CurrentQuoteView } from "./Container/Current/CurrentQuoteView";
import { QuoteHistoryView } from "./Container/History/QuoteHistoryView";
import { RevisionView } from "Views/Shared/Revisions/RevisionView";
import { MODELID_CURRENTQUOTE } from "Views/Shared/Constants";

interface Props {
    viewModel: ProjectViewModel;
}
export const ProjectQuoteHistoryView: React.FC<Props> = observer((props: Props) => {
    const [viewModel] = useState(() => new ProjectQuoteHistoryViewModel(props.viewModel));

    useEffect(() => {
        setPageTitle("Project Quote History");
    }, []);

    const onRowClick = (vm: QuoteListItemViewModel) => {
        viewModel.quoteRowClickCommand.execute(vm);
    };

    const renderBusy = () => <ThemedLoader isOpen={viewModel.apiClient.IsBusy} />;

    const renderActiveWorkspace = (row: QuoteListItemViewModel) => {
        switch (true) {
            // If the page does not have an active revision selected or the active revision is the current
            // quote, display the quote form view.
            case !row.activeRevisionViewModel || row.activeRevisionViewModel!.model.id === MODELID_CURRENTQUOTE:
                return <CurrentQuoteView viewModel={row.currentQuoteViewModel} />;

            // If the page has an active revision selected that is not the current quote, it must be an
            // older revision, so display the quote history view.
            default:
                return <QuoteHistoryView key={row.activeRevisionViewModel!.KEY} quoteHistoryId={row.activeRevisionViewModel!.model.id} />;
        }
    };

    return (
        <PageContainer mt="15px !important">
            <>
                {/* Active Workspace */}
                {viewModel.quoteListViewModels.map((quoteListViewModel) => {
                    return (
                        <>
                            <Box key={quoteListViewModel.model.KEY} mt={3}>
                                <Box onClick={() => onRowClick(quoteListViewModel)} style={{ margin: "0px 30px", fontSize: "12px", fontWeight: "600", cursor: "pointer" }}>
                                    {quoteListViewModel.quoteDisplayTitle}
                                    <FormDottedSeparator />
                                </Box>
                                {quoteListViewModel.isVisible && (
                                    <>
                                        {quoteListViewModel.canDisplayRevisionNavigation && (
                                            <React.Fragment>
                                                <RevisionNavigationContainer style={{ margin: "20px 60px -1px 30px" }}>
                                                    {quoteListViewModel.revisionViewModels.map((vm) => {
                                                        return <RevisionView key={vm.KEY} viewModel={vm} />;
                                                    })}
                                                </RevisionNavigationContainer>
                                                <Box style={{ borderBottom: "solid 1px #d4d4d4", margin: "0 30px" }}></Box>
                                            </React.Fragment>
                                        )}
                                        {renderActiveWorkspace(quoteListViewModel)}
                                    </>
                                )}
                            </Box>
                        </>
                    );
                })}

                {/* Busy Overlay */}
                {renderBusy()}
            </>
        </PageContainer>
    );
});
