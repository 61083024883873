import { ViewModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import moment from "moment";

import { BankHolidayEventViewModel } from "./Components/BankHolidayEvent/BankHolidayEventViewModel";
import { LeaveRequestEventViewModel } from "./Components/LeaveRequestEvent/LeaveRequestEventViewModel";
import { NoEventViewModel } from "./Components/NoEvent/NoEventViewModel";
import { WeekendEventViewModel } from "./Components/WeekendEvent/WeekendEventViewModel";

import { DateModel } from "./DateModel";

export class DateViewModel extends ViewModelBase<DateModel> {
    public eventViewModel: BankHolidayEventViewModel | LeaveRequestEventViewModel | NoEventViewModel | WeekendEventViewModel | null = null;

    constructor(model: DateModel) {
        super(model, false);

        this.eventViewModel = new NoEventViewModel();

        makeObservable(this, {
            // Obseravbles
            eventViewModel: observable,
        });
    }

    public get KEY() {
        return this.model.KEY;
    }

    public get date() {
        return this.model.date;
    }

    public get formattedDate() {
        return moment(this.date).format("D");
    }
}
