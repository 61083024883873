import { Editor } from "tinymce/tinymce";

export const setupQuotePlaceholderPlugin = (editor: Editor) => {
    const PLUGINNAME = "quoteplaceholders";
    const MENUNAME = "Placeholders";

    const placeholders = [
        {
            title: "Standard date",
            value: "BASE_PLACEHOLDER_STDDATE",
        },
        {
            title: "Formatted date",
            value: "BASE_PLACEHOLDER_FORMATTEDDATE",
        },
        {
            title: "|",
            value: "",
        },
        {
            title: "Project reference",
            value: "BASE_PLACEHOLDER_PROJECTREF",
        },
        {
            title: "Project name",
            value: "BASE_PLACEHOLDER_PROJECTNAME",
        },
        {
            title: "|",
            value: "",
        },
        {
            title: "Client business name",
            value: "BASE_PLACEHOLDER_CUSTOMERBUSINESSNAME",
        },
        {
            title: "Client full name",
            value: "BASE_PLACEHOLDER_CUSTOMERFULLNAME",
        },
        {
            title: "Client salutation",
            value: "BASE_PLACEHOLDER_CUSTOMERSALUTATION",
        },
        {
            title: "Client first name",
            value: "BASE_PLACEHOLDER_CUSTOMERFIRSTNAME",
        },
        {
            title: "Client last name",
            value: "BASE_PLACEHOLDER_CUSTOMERLASTNAME",
        },
        {
            title: "|",
            value: "",
        },
        {
            title: "Client correspondence address",
            value: "BASE_PLACEHOLDER_CORRESPONDENCEADDR",
        },
        {
            title: "Client site address",
            value: "BASE_PLACEHOLDER_SITEADDR",
        },
        {
            title: "|",
            value: "",
        },
        {
            title: "Cost excluding VAT",
            value: "BASE_PLACEHOLDER_COSTEXCLVAT",
        },
        {
            title: "Cost including VAT",
            value: "BASE_PLACEHOLDER_COSTINCVAT",
        },
        {
            title: "VAT",
            value: "BASE_PLACEHOLDER_VAT",
        },
        {
            title: "|",
            value: "",
        },
        {
            title: "Signature",
            value: "BASE_PLACEHOLDER_SIGNATURE",
        },
    ];

    // For context menu
    editor.ui.registry.addNestedMenuItem(PLUGINNAME, {
        text: MENUNAME,
        getSubmenuItems: () =>
            placeholders.map((ph) => {
                switch (ph.title) {
                    case "|": {
                        return {
                            type: "separator",
                        };
                    }

                    default:
                        return {
                            type: "menuitem",
                            text: ph.title,
                            onAction: () => {
                                editor.insertContent(ph.value);
                            },
                        };
                }
            }),
    });

    // For toolbar
    editor.ui.registry.addMenuButton(PLUGINNAME, {
        text: MENUNAME,
        fetch: (callback) => {
            callback(
                placeholders.map((ph) => {
                    switch (ph.title) {
                        case "|": {
                            return {
                                type: "separator",
                            };
                        }

                        default:
                            return {
                                type: "menuitem",
                                text: ph.title,
                                onAction: () => {
                                    editor.insertContent(ph.value);
                                },
                            };
                    }
                }),
            );
        },
    });
};
