import { runInAction } from "mobx";

import { EndpointWithoutRequest, Http } from "Application";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { EnquiryItemModel } from "./EnquiryItemModel";
import { EnquiriesViewModel } from "./EnquiriesViewModel";
import { EnquiryItemViewModel } from "./EnquiryItemViewModel";
import { CustomerItemModel } from "Views/Shared/Customer/CustomerItemModel";

class EnquiryLiteResponse {
    public id: string = "";

    public enquiryStatusId = "";
    public enquiryStatusName = "";
    public enquiryStatusForegroundColor = "";
    public contactTypeId = "";
    public contactTypeName = "";
    public customerId = "";
    public customerContactId = "";

    public reference = "";
    public title = "";
    public estimatedCost = 0;

    public sourceOfEnquiryId = "";

    public contactNumber1 = "";
    public contactNumber2 = "";
    public emailAddress = "";
    public emailAddress2 = "";
    public customerName = "";
    public customerFirstName = "";
    public customerLastName = "";

    public createdDate: string = "";
    public lastActionDate: string | null = null;
    public lastActionByUserId: string | null = null;
    public lastActionFirstName = "";
    public lastActionLastName = "";
}

class CustomerLiteResponse {
    public id: string = "";
    public contactTypeId: string = "";
    public contactTitleId: string | null = null;

    public businessName: string | null = null;
    public prefixName: string = "";
    public firstName: string | null = null;
    public lastName: string | null = null;

    public contactNumber1: string | null = null;
    public contactNumber2: string | null = null;
    public emailAddress: string | null = null;
    public emailAddress2: string | null = null;

    public address1: string = "";
    public address2: string | null = null;
    public address3: string | null = null;
    public city: string = "";
    public postcode: string = "";
}
class EnquiryWithRelatedResponse {
    public enquiryLite: EnquiryLiteResponse | null = null;
    public customerLite: CustomerLiteResponse | null = null;
}

class Response {
    public enquiriesWithRelatedResponses: EnquiryWithRelatedResponse[] = [];
}

export class GETEnquiriesEndpoint extends EndpointWithoutRequest<Response> {
    private viewModel: EnquiriesViewModel;

    constructor(viewModel: EnquiriesViewModel) {
        super();
        this.viewModel = viewModel;
        this.verb(Http.Get);
        this.path(AppUrls.Server.Enquiries.AllEnquiriesLite);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            const enquiries: EnquiryItemViewModel[] = [];

            response.enquiriesWithRelatedResponses.forEach((response: EnquiryWithRelatedResponse) => {
                const model = new EnquiryItemModel();
                const customerItemModel = new CustomerItemModel();
                customerItemModel.fromResponse(response.customerLite);

                model.fromResponse(response.enquiryLite);
                model.customerItemModel = customerItemModel;

                enquiries.push(new EnquiryItemViewModel(model));
            });

            this.viewModel.enquiries.replace(enquiries);
        });
    }
}
