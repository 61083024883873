import { IStyle, mergeStyleSets } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React from "react";

import { Box, ThemedButton, ThemedText } from "Application";
import icon from "Assets/approve_confirm.svg";
import { DeleteProjectViewModel } from "./DeleteProjectViewModel";

interface IViewStyles {
    root: IStyle;
    header: IStyle;
    body: IStyle;
    footer: IStyle;
}

const styleDefinitions: IViewStyles = {
    root: {
        fontFamily: `"Poppins", sans-serif`,
        textAlign: "center",
        maxWidth: "320px",
        padding: "35px 40px",
        width: "320px",
    },
    header: {
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "20px",
    },
    body: {
        textAlign: "center",
        span: {
            fontSize: "18px",
            lineHeight: "27px",
            letterSpacing: "0.54px",
            font: "normal normal 600 18px/22px Poppins",
            color: "#171716",
        },
    },
    footer: {
        display: "flex",
        gap: "20px",
        flexDirection: "column",
        marginTop: "60px",
        button: {
            padding: "10px",
            height: "32px",
            width: "168px",
            margin: "0 auto",
        },
    },
};

interface IProps {
    viewModel: DeleteProjectViewModel;
}

export const DeleteProjectView: React.FC<IProps> = observer((props) => {
    const viewModel = props.viewModel;
    const styles = mergeStyleSets(styleDefinitions);

    if (viewModel) {
        return (
            <Box className={styles.root}>
                <Box className={styles.header}>
                    <img src={icon} height={"127px"} width={"178px"} alt={"convert"} />
                </Box>
                <Box className={styles.body}>
                    <ThemedText fontStyle={"h2"}>{viewModel.displayMessage}</ThemedText>
                </Box>
                <Box className={styles.footer}>
                    <ThemedButton paletteColor="primary" command={viewModel.parentSubmitDeleteProjectCommand} displayName="yes" />
                    <ThemedButton command={viewModel.parentCancelDeleteProjectCommand} displayName="no" />
                </Box>
            </Box>
        );
    }

    return <React.Fragment />;
});
