import { isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { computed, makeObservable } from "mobx";

import { Nullcommand } from "Application";
import { CurrentQuoteModel } from "./CurrentQuoteModel";
import { CustomerViewModel } from "Views/Shared/Customer/CustomerViewModel";
import { LetterViewModel } from "Views/Shared/Letter/LetterViewModel";
import { NotesViewModel } from "Views/Shared/Note/NotesViewModel";
import { QuoteViewModel } from "Views/Shared/Quote/QuoteViewModel";
import { ServicesViewModel } from "Views/Shared/Services/ServicesViewModel";
import { TermsAndConditionsViewModel } from "Views/Shared/TermsAndConditions/TermsAndConditionsViewModel";
import { NOTECONTEXT_QUOTE } from "Views/Shared/Constants";

export class CurrentQuoteViewModel extends ViewModelBase<CurrentQuoteModel> {
    // Hosted in its own viewmodel.
    public quoteViewModel: QuoteViewModel;
    public customerViewModel: CustomerViewModel;
    public notesViewModel: NotesViewModel;
    public servicesViewModel: ServicesViewModel;
    public termsAndConditionsViewModel: TermsAndConditionsViewModel;
    public letterViewModel: LetterViewModel;

    constructor(currentQuote: CurrentQuoteModel) {
        super(currentQuote);

        this.quoteViewModel = new QuoteViewModel(this.model.quote);
        this.customerViewModel = new CustomerViewModel(this.model.customer);
        this.notesViewModel = new NotesViewModel(this.model.notesModel, new Nullcommand(), NOTECONTEXT_QUOTE);
        this.servicesViewModel = new ServicesViewModel(this.model.servicesModel);
        this.termsAndConditionsViewModel = new TermsAndConditionsViewModel(this.model.termsAndConditionsModel);
        this.letterViewModel = new LetterViewModel(this.model.letterModel);

        makeObservable(this, {
            // Observables
            // Computeds
            canDisplayNotes: computed,
        });
    }

    /**
     * Disposes any resources.
     */
    public dispose = (): void => {
        this.notesViewModel.dispose();
        this.servicesViewModel.dispose();
    };

    // #region Properties

    public get canDisplayNotes() {
        return !isEmptyOrWhitespace(this.model.id);
    }

    // #endregion Properties
}
