import { observer } from "mobx-react-lite";
import React, { useState } from "react";

import { DeleteServiceModalView } from "./Modals/DeleteServiceModalView";
import { ServiceModel } from "./ServiceModel";
import { ServiceViewModel } from "./ServiceViewModel";
import { ServiceGroupViewModel } from "../ServiceGroup/ServiceGroupViewModel";
import { EditServiceView } from "./Views/EditServiceView";
import { AddServiceView } from "./Views/AddServiceView";
import { ReadOnlyServiceView } from "./Views/ReadOnlyServiceView";

interface IProps {
    model: ServiceModel;
    parentViewModel: ServiceGroupViewModel;
}

export const ServiceView: React.FC<IProps> = observer((props: IProps) => {
    const [viewModel] = useState(() => new ServiceViewModel(props.parentViewModel, props.model));

    const render = () => {
        switch (true) {
            case viewModel.isInAddMode:
                return <AddServiceView viewModel={viewModel} />;
            case viewModel.isInEditMode:
                return <EditServiceView viewModel={viewModel} />;
            case viewModel.isDeleteModalOpen:
                return <DeleteServiceModalView viewModel={viewModel} />;
            default:
                return <ReadOnlyServiceView viewModel={viewModel} />;
        }
    };

    return (
        <>
            {render()}
            <DeleteServiceModalView viewModel={viewModel} />
        </>
    );
});
