import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

export class ProgrammingWeekTaskModel extends ModelBase<ProgrammingWeekTaskModel> {
    public projectTaskAssignmentId: string = "";
    public projectTaskAssignmentStatusId: string = "";
    public projectTaskAssignmentNote: string = "";
    public projectTaskAssignmentReviewedNote: string = "";
    public projectTaskAssignmentIsReviewed: boolean = false;
    public projectTaskAssignmentUserNote: string = "";

    constructor() {
        super();
        makeObservable(this, {
            projectTaskAssignmentId: observable,
            projectTaskAssignmentStatusId: observable,
            projectTaskAssignmentNote: observable,
            projectTaskAssignmentReviewedNote: observable,
            projectTaskAssignmentIsReviewed: observable,
            projectTaskAssignmentUserNote: observable,
        });
    }
}

export class ProgrammingWeekTaskModelValidator extends Validator<ProgrammingWeekTaskModel> {
    constructor() {
        super();

        this.ruleFor("projectTaskAssignmentUserNote").notNull().withMessage("Note cannot be empty.").notEmpty().withMessage("Note cannot be empty.");
    }
}
