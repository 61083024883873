import { isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import moment from "moment";

import { UnassignedTasksItemModel } from "./UnassignedTasksItemModel";

export class UnassignedTasksItemViewModel extends ViewModelBase<UnassignedTasksItemModel> {
    constructor(model = new UnassignedTasksItemModel()) {
        super(model);

        makeObservable(this, {
            // Observables
        });
    }

    public get KEY() {
        return this.model.KEY;
    }

    public get projectTaskName() {
        return this.model.projectTaskName;
    }

    public get projectTaskOrdinal() {
        return this.model.projectTaskOrdinal;
    }

    public get projectTaskForegroundColor() {
        if (this.model.projectTaskIsBilledHourly) {
            return "#FF2D9111";
        }

        return "#2D91FF11";
    }

    public get anticipatedEndDate() {
        return this.model.anticipatedEndDate;
    }

    public get remainingProjectTaskBillableEffort() {
        return this.model.remainingProjectTaskBillableEffort;
    }

    // #region Filtering
    // #endregion Filtering

    // #region Sorting
    // #endregion Sorting
}
