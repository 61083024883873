import { IStyle, mergeStyleSets } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React from "react";
import { Box, ThemedButton } from "Application";
import deletes from "Assets/delete.png";
import CustomerDetailViewModel from "../CustomerDetailViewModel";

interface IDeleteCustomerViewStyles {
    root: IStyle;
    body: IStyle;
    footer: IStyle;
}
export interface CustomerViewSubViewProps {
    viewModel: CustomerDetailViewModel;
}

const styleDefinitions: IDeleteCustomerViewStyles = {
    root: {
        fontFamily: `"Poppins", sans-serif`,
        textAlign: "center",
        width: "100%",
        padding: "25px 30px",
        background: "#FFFFFF",
        boxShadow: "0px 3px 6px #00000029",
        borderRadius: "3px",
        maxWidth: "320px",
    },
    body: {
        textAlign: "center",
        font: "normal normal normal 18px/27px Poppins",
        letterSpacing: "0.54px",
        color: "#191919",
        margin: "30px 0 50px",
    },
    footer: {
        display: "flex",
        flexDirection: "column",
        gridRowGap: "20px",
        maxWidth: "170px",
        margin: "0 auto",
        "button:hover": {
            color: "#fff",
        },
        "button:focus": {
            color: "#fff",
        },
    },
};

export const DeleteCustomerView: React.FC<CustomerViewSubViewProps> = observer((props) => {
    const viewModel = props.viewModel;
    const styles = mergeStyleSets(styleDefinitions);
    return (
        <Box className={styles.root}>
            <Box>
                <img src={deletes} height={"130"} width={"140"} alt={"Delete Client"} />
            </Box>
            <Box className={styles.body}>Are you sure you want to delete this client?</Box>
            <Box className={styles.footer}>
                <ThemedButton paletteColor="error" command={viewModel.deleteCustomerCommand} displayName="Delete Client" styles={{ root: { padding: "0 20px", color: "#fff" } }} />
                <ThemedButton command={viewModel.closeDeleteModalCommand} displayName="Cancel" styles={{ root: { padding: "0 20px" } }} />
            </Box>
        </Box>
    );
});
