import React, { useState, useRef, useEffect } from "react";
import styled from "@emotion/styled";
import { ThemedText, theme, Box } from "Application";
import { observer } from "mobx-react-lite";
import { OverviewViewModel } from "../OverviewViewModel";
import { ThemedButton } from "Application";
import { AbsenteesViewModel } from "./AbsenteesViewModel";

interface IProps {}

const Container = styled.div`
    padding-top: 15px;
    padding: 10px 30px 0px 30px;
`;

const AbsenteesList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

interface IAbsenteesInnerListProps {
    absentees: number;
}

const AbsenteesInnerList = styled.div<IAbsenteesInnerListProps>`
    padding-top: 10px;

    display: flex;
    flex-direction: column;

    & > * {
        margin-bottom: 10px;
    }

    overflow: hidden;
    min-height: 50px;
    height: 165px;
    width: 200px;
    border-bottom: 1px dashed ${theme.palette.tableHeader.main};
    border-top: 1px dashed ${theme.palette.tableHeader.main};
    transition: height 0.2s ease-in-out;

    scrollbar-gutter: stable both-edges;

    &.expanded {
        height: calc(100vh - 70px - 91px - 210px - 80px); // 100vh - header height - inner tabs height - admin details height - list title and button height
        overflow-y: scroll;
    }
`;

const AbsenteeItem = styled.div`
    display: flex;

    align-items: center;

    padding-right: 10px;

    & > :first-child {
        margin-right: 10px;
    }
    & > :last-child {
        flex: 1;
        text-align: end;
    }
`;

const AbsenteeLeavePeriod = styled.div``;

const AbsenteeLeaveDuration = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 24px;
`;

interface IUserPersonaContainerProps {
    backgroundImage: string;
}

const UserPersonaContainer = styled.div<IUserPersonaContainerProps>`
    border-radius: 50%;
    width: 24px;
    height: 24px;
    background-image: url(${(props) => props.backgroundImage});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    filter: grayscale(100%);
`;

interface IArrowButtonIconProps {
    disabled: boolean;
}

const ArrowButtonIcon = styled.div<IArrowButtonIconProps>`
    border: solid ${theme.palette.tableHeader.main};
    opacity: ${(props) => (props.disabled ? 0.6 : 1)};
    border-width: 0 4px 4px 0;
    display: inline-block;
    padding: 4px;

    transition: transform 0.2s ease-in-out 0.2s;

    &.collapsed {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }

    &.expanded {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
    }
`;

interface IArrowButtonProps {
    disabled: boolean;
}

const ArrowButton = styled(ThemedButton)<IArrowButtonProps>`
    background-color: transparent;
    border: none;

    height: 30px;
    padding: 0;
    transition: padding-top 0.1s ease-in-out;
    padding: 0px;

    &:hover {
        background-color: transparent;
        padding-top: ${(props) => (props.disabled ? "0px" : "5px")};
        border: none;
    }

    &:focus {
        background-color: transparent;
        padding-top: 5px;
    }
`;

export const AbsenteesView: React.FC<IProps> = observer((props: IProps) => {
    const [viewModel] = useState(() => new AbsenteesViewModel());

    const listRef: any = useRef(null);

    useEffect(() => {
        if (!viewModel.expandAbsenteesList) {
            setTimeout(() => {
                listRef.current!.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                });
            }, 200);
        }
    }, [viewModel.expandAbsenteesList]);

    const renderAbsenteeItems = () => {
        const retVal: JSX.Element[] = [];

        if (viewModel.apiClient.IsBusy) {
            return (
                <Box display="flex" justifyContent={"center"}>
                    <ThemedText fontStyle="workItemStatus">Loading...</ThemedText>
                </Box>
            );
        }

        if (viewModel.absentees.length > 0) {
            viewModel.absentees.forEach((model) => {
                retVal.push(
                    <AbsenteeItem>
                        <UserPersonaContainer backgroundImage={viewModel.absenteePersona(model)} />
                        <Box>
                            <ThemedText fontStyle="p">{viewModel.absenteeFullName(model)}</ThemedText>
                            <AbsenteeLeavePeriod>
                                <ThemedText fontStyle="workItemStatus">{viewModel.absenteeLeavePeriod(model)}</ThemedText>
                                <ThemedText fontStyle="workItemStatus" color="secondary">
                                    {viewModel.absenteeLeaveType(model)}
                                </ThemedText>
                            </AbsenteeLeavePeriod>
                        </Box>
                        <AbsenteeLeaveDuration>
                            <ThemedText fontStyle="labelXS" color="secondary">
                                {viewModel.absenteeLeaveDuration(model)}
                            </ThemedText>
                        </AbsenteeLeaveDuration>
                    </AbsenteeItem>,
                );
            });
        } else {
            return (
                <Box display="flex" justifyContent={"center"}>
                    <ThemedText fontStyle="workItemStatus">No absentees for this week</ThemedText>
                </Box>
            );
        }

        return retVal;
    };
    return (
        <Container>
            <Box display={"flex"} justifyContent="center">
                <ThemedText fontStyle="titleBold" marginBottom={"10px"}>
                    Absentees this week
                </ThemedText>
            </Box>
            <AbsenteesList>
                <AbsenteesInnerList ref={listRef} className={viewModel.absenteesInnerListClassName} absentees={viewModel.absentees.length}>
                    {renderAbsenteeItems()}
                </AbsenteesInnerList>
                <ArrowButton
                    disabled={viewModel.isExpandButtonDisabled}
                    icon={<ArrowButtonIcon className={viewModel.absenteesInnerListClassName} disabled={viewModel.isExpandButtonDisabled} />}
                    command={viewModel.toggleExpandListCommand}
                />
            </AbsenteesList>
        </Container>
    );
});
