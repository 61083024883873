import { ViewModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

import { LeaveRowModel } from "./LeaveRowModel";
import { DateViewModel } from "../../Date/DateViewModel";

export class LeaveRowViewModel extends ViewModelBase<LeaveRowModel> {
    public dateViewModels = observable<DateViewModel>([]);

    constructor(model: LeaveRowModel = new LeaveRowModel()) {
        super(model, false);

        makeObservable(this, {});
    }

    public get displayName() {
        return this.model.title;
    }
}
