import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, formatDate, setPageTitle, ThemedButton, ThemedEditText } from "Application";
import { IColumn } from "@fluentui/react";
import { ThemedDataTable } from "Styles/ThemedPrimitives/Style1/ThemedDataTable";
import { SearchIcon } from "Assets/SearchIcon";
import LetterTemplatesListViewModel from "./LetterTemplatesListViewModel";
import { LetterTemplateItemViewModel } from "./LetterTemplateItemViewModel";
import { isNullOrUndefined } from "@shoothill/core";

const ThemedButtonStyles = {
    root: {
        height: 30,
        width: 147,
        fontSize: 17,
    },
};
const controlStyles = {
    root: {
        width: "610px",
        height: "39px",
        fontSize: "17px",
        borderColor: "#E1E1E1",
    },
};

export const LetterTemplatesListView: React.FC = observer(() => {
    // #region Code Behind

    const [viewModel] = useState(() => new LetterTemplatesListViewModel());

    useEffect(() => {
        setPageTitle("Letter templates");
    }, []);

    /**
     * Handles updating the tables column sort settings in response to changes in the viewmodel.
     */
    useEffect(() => {
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter((currCol) => viewModel.getValue("sortKey") === currCol.key)[0];

        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSorted = true;
                currColumn.isSortedDescending = viewModel.getValue("sortDescending");
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = false;
            }
        });

        setColumns(newColumns);
    }, [viewModel.getValue("sortKey"), viewModel.getValue("sortDescending")]);

    /**
     * Gets a key for the row. Must be provided if sorting or filtering is enabled.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The row index.
     *
     * @returns A string key to identify the row.
     */
    const getRowKey = (item: any, index?: number): string => {
        return item.KEY;
    };

    const onRenderLetterTemplateName = (item: LetterTemplateItemViewModel, index?: number, column?: IColumn) => {
        return <div>{item.name}</div>;
    };

    const onRenderLetterTemplateLastUpdateDate = (item: LetterTemplateItemViewModel, index?: number, column?: IColumn) => {
        return (
            <Box>
                <Box fontSize={"10px"}>{!isNullOrUndefined(item.lastUpdatedDate) ? formatDate(new Date(item.lastUpdatedDate)) : ""}</Box>
                <Box fontSize={"10px"}>{item.lastUpdateByUser}</Box>
            </Box>
        );
    };

    /**
     * Handles an onClick event for the table column header.
     *
     * @param ev Mouse event
     * @param column The column that has been clicked.
     */
    const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const currColumn: IColumn = columns.filter((currCol) => column.key === currCol.key)[0];
        const newSortKey = currColumn.key;
        const newSortDescending = currColumn.key === viewModel.getValue("sortKey") ? !currColumn.isSortedDescending : false;

        viewModel.updateSortCommand.execute(newSortKey, newSortDescending);
    };

    /**
     * Handler an onClick event for a table row.
     *
     * @param item The viewmodel data associated with the table row.
     */
    const onRowClick = (item: LetterTemplateItemViewModel, index?: number, ev?: React.FocusEvent<HTMLElement>) => {
        viewModel.navigateToEditForm.execute(item);
    };

    const defaultTableColumns: IColumn[] = [
        {
            fieldName: "name",
            key: "name",
            name: "TEMPLATE NAME",
            minWidth: 250,
            isResizable: true,
            isSorted: true,
            isSortedDescending: false,
            onColumnClick: onColumnClick,
            onRender: onRenderLetterTemplateName,
        },
        {
            fieldName: "lastUpdatedDate",
            key: "lastUpdatedDate",
            name: "LAST ACTION",
            isResizable: true,
            minWidth: 200,
            isSorted: true,
            isSortedDescending: false,
            onColumnClick: onColumnClick,
            onRender: onRenderLetterTemplateLastUpdateDate,
        },
    ];

    const [columns, setColumns] = useState<IColumn[]>(defaultTableColumns);

    // #endregion Code Behind

    return (
        <Box>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                <Box display={"flex"}>
                    <ThemedEditText
                        command={viewModel.updateFilterKeywordCommand}
                        placeholder={"Keyword search"}
                        prefix={<SearchIcon />}
                        styles={controlStyles}
                        value={() => viewModel.getValue("filterKeyword")}
                    />
                </Box>
                <ThemedButton marginTop={3} command={viewModel.navigateToNewForm} displayName="New Template" paletteColor={"primary"} styles={ThemedButtonStyles} />
            </Box>
            <ThemedDataTable getKey={getRowKey} items={viewModel.filteredAndSortedLetterTemplates} onActiveItemChanged={(item) => onRowClick(item)} columns={columns} />
        </Box>
    );
});
