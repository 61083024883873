import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { nanoid } from "nanoid";

export class ServiceTaskModel extends ModelBase<ServiceTaskModel> {
    public KEY: string = nanoid();

    public id: string | null = null;
    public serviceId: string | null = null;
    public taskName: string = "";
    public ordinal: number = 0;
    public enabled: boolean = false;
    public isLinkedToPlanningApplication: boolean = false;

    constructor() {
        super();
        makeObservable(this, {
            // Observables
            enabled: observable,
            ordinal: observable,
        });
    }
}
