import React from "react";
import { observer } from "mobx-react-lite";

import { AutoGrid, theme, ThemedButton, ThemedText, ThemedTransparentIconButton } from "Application";
import { DeleteLeaveRequestViewModel } from "./DeleteLeaveRequestViewModel";
import { IStyle, mergeStyleSets } from "@fluentui/react";
import { FormSeparator } from "Views/Shared/SharedComponents";
import { CrossSVG } from "Assets/CrossSVG";

interface IProps {
    viewModel: DeleteLeaveRequestViewModel | null;
}

interface IDeclineRequestViewStyles {
    root: IStyle;
    header: IStyle;
    body: IStyle;
    separator: IStyle;
    footer: IStyle;
    iconButton: IStyle;
}

const styleDefinitions: IDeclineRequestViewStyles = {
    root: {
        width: "700px",
    },
    header: {
        alignItems: "center",
        backgroundColor: "#F3F3F3",
        display: "flex",
        height: "75px",
        justifyContent: "space-between",
        padding: "20px 30px",
    },
    body: {
        padding: "30px",
    },
    separator: {
        "&&": {
            lineHeight: "0px",
            margin: "0px 30px",
        },
    },
    footer: {
        display: "flex",
        height: "75px",
        padding: "20px 30px",
    },
    iconButton: {
        g: {
            stroke: theme.palette.defaultTr.text,
        },
    },
};

export const DeleteLeaveRequestView: React.FC<IProps> = observer((props) => {
    // #region Code Behind

    const viewModel = props.viewModel;
    const styles = mergeStyleSets(styleDefinitions);

    // #endregion Code Behind

    if (viewModel) {
        return (
            <div className={styles.root}>
                <div className={styles.header}>
                    <ThemedText fontStyle="h2">Delete Request</ThemedText>
                    <ThemedTransparentIconButton command={viewModel.cancelCommand} icon={<CrossSVG className={styles.iconButton} />} shape="round" />
                </div>
                <div className={styles.body}>
                    <ThemedText>Are youe sure you want to delete this leave request?</ThemedText>
                </div>
                <FormSeparator className={styles.separator} />
                <AutoGrid m={0} className={styles.footer} columnGap={"30px"} dc={"135px 135px"}>
                    <ThemedButton styles={{ root: { padding: "0 5px" } }} paletteColor={"tertiary"} displayName="Delete Request" command={viewModel.submitCommand} />
                    <ThemedButton styles={{ root: { padding: "0 5px" } }} displayName="Cancel" command={viewModel.cancelCommand} />
                </AutoGrid>
            </div>
        );
    }

    return <React.Fragment />;
});
