import { Logger } from "../../index";
import { EndpointWithoutRequest, Http } from "../Helpers/BaseEndpoint";
import { InitViewModel } from "./InitViewModel";
import { AccountStatus } from "@shoothill/core";
import { AppSettings, EditableCopy, EditableCopyRoute, InitialState } from "../Models";
import {
    ContactTitle,
    ContactType,
    EnquiryStatus,
    EnquiryType,
    ImageUrl,
    NoteType,
    QuoteStatus,
    ProjectType,
    ProjectStatus,
    ProjectTaskAssignmentStatus,
    BusinessContactType,
    SourceOfEnquiryType,
} from "../Models/Domain";
import { SupplierType } from "Application/Models/Domain/SupplierType";
import { SupplierSubType } from "Application/Models/Domain/SupplierSubType";
import { SupplierContactType } from "Application/Models/Domain/SupplierContactType";
import { FinancialPeriodType } from "Application/Models/Domain/FinancialPeriodType";
import { ProjectTaskAssignmentTemplateNote } from "Views/Shared/ProjectTaskAssignmentTemplateNote/ProjectTaskAssignmentTemplateNoteModel";
import { TermsAndConditionsType } from "Application/Models/Domain/TermsAndConditionsType";
import { NoteSubType } from "Application/Models/Domain/NoteSubType";
import { LocalAuthorityDistrict } from "Application/Models/Domain/LocalAuthorityDistrict";
import { PlanningApplicationStatus } from "Application/Models/Domain/PlanningApplicationStatus";
import { PlanningApplicationType } from "Application/Models/Domain/PlanningApplicationType";

class Response {
    public appInsightsInstrumentationKey: string = "";
    public accountStatus: AccountStatusResponse = new AccountStatusResponse();
    public appSettings: AppSettingsResponse = new AppSettingsResponse();
    public editableCopies: EditableCopyResponse[] = [];
    public editableCopyRoutes: EditableCopyRouteResponse[] = [];
    public contactTitles: ContactTitleResponse[] = [];
    public businessContactTypes: BusinessContactTypeResponse[] = [];
    public contactTypes: ContactTypeResponse[] = [];
    public enquiryStatuses: EnquiryStatusResponse[] = [];
    public enquiryTypes: EnquiryTypeResponse[] = [];
    public projectStatuses: ProjectStatusResponse[] = [];
    public noteTypes: NoteTypeResponse[] = [];
    public noteSubTypes: NoteSubTypeResponse[] = [];
    public projectTypes: ProjectTypeResponse[] = [];
    public projectTaskAssignmentStatuses: ProjectTaskAssignmentStatusResponse[] = [];
    public projectTaskAssignmentTemplateNotes: ProjectTaskAssignmentTemplateNoteResponse[] = [];
    public quoteStatuses: QuoteStatusResponse[] = [];
    public errors: string[] = [];
    public imageURLs: ImageUrlResponse[] = [];
    public supplierTypes: SupplierTypeResponse[] = [];
    public supplierSubTypes: SupplierSubTypeResponse[] = [];
    public supplierContactTypes: SupplierContactTypeResponse[] = [];
    public financialPeriodTypes: FinancialPeriodTypeResponse[] = [];
    public termsAndConditionsTypes: TermsAndConditionsTypeResponse[] = [];
    public sourceOfEnquiryTypes: SourceOfEnquiryTypeResponse[] = [];
    public financialYears: number[] = [];
    public localAuthorityDistricts: LocalAuthorityDistrictResponse[] = [];
    public planningApplicationStatuses: PlanningApplicationStatusResponse[] = [];
    public planningApplicationTypes: PlanningApplicationTypeResponse[] = [];
}

class TypeResponseBase {
    public id: string = "";
    public name: string = "";
    public type: string = "";
    public ordinal: number = 0;
    public isDeleted: boolean = false;
}

class AccountStatusResponse {
    public id?: string = "";
    public displayName: string = "";
    public isLoggedIn: boolean = false;
    public permissions?: string[] = [];
    public jwt?: string | undefined = "";
    public signalRAccessToken: string = "";
}

class AppSettingsResponse {
    public allowWebCrawlers: boolean = false;
    public forgotPasswordExpiryMins: number = 0;
    public jwtExpiryMins: number = 0;
    public jwtIssuer: string = "";
    public jwtSecret: string = "";
    public refreshExpiryMinutes: number = 0;
    public setNwebSecHeaders: boolean = false;
    public token: string = "";
    public useBasicAuthentication: boolean = false;
    public useDatabase: boolean = true;
    public useHangFire: boolean = true;
    public useSerilog: boolean = false;
    public version: string = "0.0.0";
}

class EditableCopyResponse {
    public id: string = "";
    public routeId: string = "";
    public placeholderNumber: number = 0;
    public copyValue: string = "";
    public createdDate: string = "";
    public updatedDate: string = "";
    public updatedBy: string = "";
}

class EditableCopyRouteResponse {
    public id: string = "";
    public route: string = "";
}

class ContactTitleResponse extends TypeResponseBase {}

class BusinessContactTypeResponse extends TypeResponseBase {}

class ContactTypeResponse extends TypeResponseBase {}

class EnquiryStatusResponse extends TypeResponseBase {
    public foregroundColor: string = "";
    public backgroundColor: string = "";
}

class EnquiryTypeResponse extends TypeResponseBase {}

class ProjectStatusResponse extends TypeResponseBase {
    public foregroundColor: string = "";
    public backgroundColor: string = "";
}

class NoteTypeResponse extends TypeResponseBase {
    public isInternal: boolean = false;
}

class NoteSubTypeResponse extends TypeResponseBase {
    public noteTypeId: string = "";
}

class ProjectTypeResponse extends TypeResponseBase {}

class ProjectTaskAssignmentStatusResponse extends TypeResponseBase {
    public foregroundColor: string = "";
    public backgroundColor: string = "";
}

class QuoteStatusResponse extends TypeResponseBase {
    public foregroundColor: string = "";
    public backgroundColor: string = "";
}

class ImageUrlResponse {
    public documentUrl: string = "";
}

class SupplierTypeResponse extends TypeResponseBase {}

class SupplierSubTypeResponse extends TypeResponseBase {
    public supplierTypeId: string = "";
}
class SupplierContactTypeResponse extends TypeResponseBase {}
class FinancialPeriodTypeResponse extends TypeResponseBase {}

class ProjectTaskAssignmentTemplateNoteResponse extends TypeResponseBase {}

class TermsAndConditionsTypeResponse extends TypeResponseBase {}

class SourceOfEnquiryTypeResponse extends TypeResponseBase {
    public isInternal: boolean = false;
}

class PlanningApplicationStatusResponse extends TypeResponseBase {}

class PlanningApplicationTypeResponse extends TypeResponseBase {}

class LocalAuthorityDistrictResponse {
    public id: string = "";
    public officialNameLocalAuthorityDistrict: string = "";
}

//end region response

export class InitStateEndpoint extends EndpointWithoutRequest<Response> {
    private viewModel: InitViewModel;
    constructor(initViewModel: InitViewModel) {
        super();
        this.viewModel = initViewModel;
        this.verb(Http.Get);
        this.path("/api/initialstate");
    }

    public async HandleResponseAsync(response: Response): Promise<InitialState> {
        Logger.logInformation("Response", response);
        let initialState = {} as InitialState;
        initialState.appInsightsInstrumentationKey = response.appInsightsInstrumentationKey;
        let accountStatus: AccountStatus = {} as AccountStatus;
        accountStatus.id = response.accountStatus.id;
        accountStatus.displayName = response.accountStatus.displayName;
        accountStatus.isLoggedIn = response.accountStatus.isLoggedIn;
        accountStatus.permissions = response.accountStatus.permissions;
        accountStatus.jwt = response.accountStatus.jwt;
        accountStatus.signalRAccessToken = response.accountStatus.signalRAccessToken;
        initialState.accountStatus = accountStatus;

        let appSettings: AppSettings = {} as AppSettings;
        appSettings.allowWebCrawlers = response.appSettings.allowWebCrawlers;
        appSettings.forgotPasswordExpiryMins = response.appSettings.forgotPasswordExpiryMins;
        appSettings.jwtExpiryMins = response.appSettings.jwtExpiryMins;
        appSettings.jwtIssuer = response.appSettings.jwtIssuer;
        appSettings.jwtSecret = response.appSettings.jwtSecret;
        appSettings.refreshExpiryMinutes = response.appSettings.refreshExpiryMinutes;
        appSettings.setNwebSecHeaders = response.appSettings.setNwebSecHeaders;
        appSettings.token = response.appSettings.token;
        appSettings.useBasicAuthentication = response.appSettings.useBasicAuthentication;
        appSettings.useDatabase = response.appSettings.useDatabase;
        appSettings.useHangFire = response.appSettings.useHangFire;
        appSettings.useSerilog = response.appSettings.useSerilog;
        appSettings.version = response.appSettings.version;

        initialState.appSettings = appSettings;

        initialState.editableCopies = response.editableCopies.map((x) => {
            let editableCopy: EditableCopy = {} as EditableCopy;
            editableCopy.id = x.id;
            editableCopy.routeId = x.routeId;
            editableCopy.placeholderNumber = x.placeholderNumber;
            editableCopy.copyValue = x.copyValue;
            editableCopy.createdDate = x.createdDate;
            editableCopy.updatedDate = x.updatedDate;
            editableCopy.updatedBy = x.updatedBy;
            return editableCopy;
        });

        initialState.editableCopyRoutes = response.editableCopyRoutes.map((x) => {
            let editableCopyRoute: EditableCopyRoute = {} as EditableCopyRoute;
            editableCopyRoute.id = x.id;
            editableCopyRoute.route = x.route;
            return editableCopyRoute;
        });

        initialState.contactTitles = response.contactTitles.map((x) => {
            let contactTitle: ContactTitle = {} as ContactTitle;
            contactTitle.id = x.id;
            contactTitle.name = x.name;
            contactTitle.type = x.type;
            contactTitle.ordinal = x.ordinal;
            contactTitle.isDeleted = x.isDeleted;
            return contactTitle;
        });

        initialState.businessContactTypes = response.businessContactTypes.map((x) => {
            let businessContactType: BusinessContactType = {} as BusinessContactType;
            businessContactType.id = x.id;
            businessContactType.name = x.name;
            businessContactType.type = x.type;
            businessContactType.ordinal = x.ordinal;
            businessContactType.isDeleted = x.isDeleted;
            return businessContactType;
        });

        initialState.contactTypes = response.contactTypes.map((x) => {
            let contactType: ContactType = {} as ContactType;
            contactType.id = x.id;
            contactType.name = x.name;
            contactType.type = x.type;
            contactType.ordinal = x.ordinal;
            contactType.isDeleted = x.isDeleted;
            return contactType;
        });

        initialState.enquiryStatuses = response.enquiryStatuses.map((x) => {
            let enquiryStatus: EnquiryStatus = {} as EnquiryStatus;
            enquiryStatus.id = x.id;
            enquiryStatus.name = x.name;
            enquiryStatus.type = x.type;
            enquiryStatus.ordinal = x.ordinal;
            enquiryStatus.isDeleted = x.isDeleted;
            enquiryStatus.foregroundColor = x.foregroundColor;
            enquiryStatus.backgroundColor = x.backgroundColor;
            return enquiryStatus;
        });

        initialState.enquiryTypes = response.enquiryTypes.map((x) => {
            let enquiryType: EnquiryType = {} as EnquiryType;
            enquiryType.id = x.id;
            enquiryType.name = x.name;
            enquiryType.type = x.type;
            enquiryType.ordinal = x.ordinal;
            enquiryType.isDeleted = x.isDeleted;
            return enquiryType;
        });

        initialState.projectStatuses = response.projectStatuses.map((x) => {
            let projectStatus: ProjectStatus = {} as ProjectStatus;
            projectStatus.id = x.id;
            projectStatus.name = x.name;
            projectStatus.type = x.type;
            projectStatus.ordinal = x.ordinal;
            projectStatus.isDeleted = x.isDeleted;
            projectStatus.foregroundColor = x.foregroundColor;
            projectStatus.backgroundColor = x.backgroundColor;
            return projectStatus;
        });

        initialState.noteTypes = response.noteTypes.map((x) => {
            let noteType: NoteType = {} as NoteType;
            noteType.id = x.id;
            noteType.name = x.name;
            noteType.type = x.type;
            noteType.ordinal = x.ordinal;
            noteType.isDeleted = x.isDeleted;
            noteType.isInternal = x.isInternal;
            return noteType;
        });

        initialState.noteSubTypes = response.noteSubTypes.map((x) => {
            let supplierSubType: NoteSubType = {} as NoteSubType;
            supplierSubType.id = x.id;
            supplierSubType.noteTypeId = x.noteTypeId;
            supplierSubType.name = x.name;
            supplierSubType.type = x.type;
            supplierSubType.ordinal = x.ordinal;
            supplierSubType.isDeleted = x.isDeleted;
            return supplierSubType;
        });

        initialState.projectTypes = response.projectTypes.map((x) => {
            let projectType: ProjectType = {} as ProjectType;
            projectType.id = x.id;
            projectType.name = x.name;
            projectType.type = x.type;
            projectType.ordinal = x.ordinal;
            projectType.isDeleted = x.isDeleted;
            return projectType;
        });

        initialState.projectTaskAssignmentStatuses = response.projectTaskAssignmentStatuses.map((x) => {
            let projectTaskAssignmentStatus: ProjectTaskAssignmentStatus = {} as ProjectTaskAssignmentStatus;
            projectTaskAssignmentStatus.id = x.id;
            projectTaskAssignmentStatus.name = x.name;
            projectTaskAssignmentStatus.type = x.type;
            projectTaskAssignmentStatus.ordinal = x.ordinal;
            projectTaskAssignmentStatus.isDeleted = x.isDeleted;
            projectTaskAssignmentStatus.foregroundColor = x.foregroundColor;
            projectTaskAssignmentStatus.backgroundColor = x.backgroundColor;
            return projectTaskAssignmentStatus;
        });

        initialState.projectTaskAssignmentTemplateNotes = response.projectTaskAssignmentTemplateNotes.map((x) => {
            const model: ProjectTaskAssignmentTemplateNote = new ProjectTaskAssignmentTemplateNote();
            model.fromResponse(x);
            return model;
        });

        initialState.quoteStatuses = response.quoteStatuses.map((x) => {
            let quoteStatus: QuoteStatus = {} as QuoteStatus;
            quoteStatus.id = x.id;
            quoteStatus.name = x.name;
            quoteStatus.type = x.type;
            quoteStatus.ordinal = x.ordinal;
            quoteStatus.isDeleted = x.isDeleted;
            quoteStatus.foregroundColor = x.foregroundColor;
            quoteStatus.backgroundColor = x.backgroundColor;
            return quoteStatus;
        });

        initialState.imageURLs = response.imageURLs.map((x) => {
            let imageUrl: ImageUrl = {} as ImageUrl;
            imageUrl.documentUrl = x.documentUrl;
            return imageUrl;
        });

        initialState.supplierTypes = response.supplierTypes.map((x) => {
            let supplierType: SupplierType = {} as SupplierType;
            supplierType.id = x.id;
            supplierType.name = x.name;
            supplierType.type = x.type;
            supplierType.ordinal = x.ordinal;
            supplierType.isDeleted = x.isDeleted;
            return supplierType;
        });

        initialState.supplierSubTypes = response.supplierSubTypes.map((x) => {
            let supplierSubType: SupplierSubType = {} as SupplierSubType;
            supplierSubType.id = x.id;
            supplierSubType.supplierTypeId = x.supplierTypeId;
            supplierSubType.name = x.name;
            supplierSubType.type = x.type;
            supplierSubType.ordinal = x.ordinal;
            supplierSubType.isDeleted = x.isDeleted;
            return supplierSubType;
        });

        initialState.supplierContactTypes = response.supplierContactTypes.map((x) => {
            let supplierContactType: SupplierContactType = {} as SupplierContactType;
            supplierContactType.id = x.id;
            supplierContactType.name = x.name;
            supplierContactType.type = x.type;
            supplierContactType.ordinal = x.ordinal;
            supplierContactType.isDeleted = x.isDeleted;
            return supplierContactType;
        });

        initialState.financialPeriodTypes = response.financialPeriodTypes.map((x) => {
            let financialPeriodType: FinancialPeriodType = {} as FinancialPeriodType;
            financialPeriodType.id = x.id;
            financialPeriodType.name = x.name;
            financialPeriodType.type = x.type;
            financialPeriodType.ordinal = x.ordinal;
            financialPeriodType.isDeleted = x.isDeleted;
            return financialPeriodType;
        });

        initialState.termsAndConditionsTypes = response.termsAndConditionsTypes.map((x) => {
            let type: TermsAndConditionsType = {} as TermsAndConditionsType;
            type.id = x.id;
            type.name = x.name;
            type.type = x.type;
            type.ordinal = x.ordinal;
            type.isDeleted = x.isDeleted;
            return type;
        });

        initialState.sourceOfEnquiryTypes = response.sourceOfEnquiryTypes.map((x) => {
            let type: SourceOfEnquiryType = {} as SourceOfEnquiryType;
            type.id = x.id;
            type.name = x.name;
            type.type = x.type;
            type.ordinal = x.ordinal;
            type.isDeleted = x.isDeleted;
            type.isInternal = x.isInternal;
            return type;
        });

        initialState.localAuthorityDistricts = response.localAuthorityDistricts.map((x) => {
            const localAuthorityDistrict: LocalAuthorityDistrict = {} as LocalAuthorityDistrict;
            localAuthorityDistrict.id = x.id;
            localAuthorityDistrict.officialNameLocalAuthorityDistrict = x.officialNameLocalAuthorityDistrict;
            return localAuthorityDistrict;
        });

        initialState.planningApplicationStatuses = response.planningApplicationStatuses.map((x) => {
            const planningApplicationStatus: PlanningApplicationStatus = {} as PlanningApplicationStatus;
            planningApplicationStatus.id = x.id;
            planningApplicationStatus.name = x.name;
            planningApplicationStatus.type = x.type;
            planningApplicationStatus.ordinal = x.ordinal;
            planningApplicationStatus.isDeleted = x.isDeleted;
            return planningApplicationStatus;
        });

        initialState.planningApplicationTypes = response.planningApplicationTypes.map((x) => {
            const planningApplicationType: PlanningApplicationType = {} as PlanningApplicationType;
            planningApplicationType.id = x.id;
            planningApplicationType.name = x.name;
            planningApplicationType.type = x.type;
            planningApplicationType.ordinal = x.ordinal;
            planningApplicationType.isDeleted = x.isDeleted;
            return planningApplicationType;
        });

        initialState.financialYears = response.financialYears;

        initialState.errors = response.errors;

        this.viewModel.InitData = initialState;
        this.viewModel.DataReady = true;

        return initialState;
    }
}
