import { ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

import { LeaveStatusType } from "Views/Leave/Shared/LeaveStatusType";
import { LeaveRequestEventModel } from "./LeaveRequestEventModel";

export class LeaveRequestEventViewModel extends ViewModelBase<LeaveRequestEventModel> {
    constructor(model: LeaveRequestEventModel) {
        super(model, false);

        makeObservable(this, {});
    }

    //#region Properties

    public get displayName() {
        return this.model.leaveRequest?.leaveType?.name ?? "";
    }

    public get displayStatusName() {
        return this.model.leaveRequest?.leaveStatusType?.name ?? "";
    }

    public get displayNumberOFDays() {
        return this.model.leaveRequest?.numberOfDays ? `(${Math.abs(this.model.leaveRequest?.numberOfDays)} days leave)` : "";
    }

    public get backgroundColor() {
        return this.model.leaveRequest?.leaveType?.backgroundColor ?? "#dff6dd";
    }

    public get isPendingRequest() {
        return this.model.leaveRequest?.leaveStatusType?.type === LeaveStatusType.PENDING;
    }

    //#endregion Properties
}
