import { observer } from "mobx-react-lite";
import React from "react";

import { AutoGrid, ThemedButton } from "Application";
import { EnquiryViewModel } from "../EnquiryViewModel";

export interface IButtonClusterSubViewProps {
    viewModel: EnquiryViewModel;
}

/**
 * Displays a group of buttons for the enquiry form.
 */
export const ButtonClusterSubView: React.FC<IButtonClusterSubViewProps> = observer((props) => {
    const viewModel = props.viewModel;

    return (
        <AutoGrid
            backgroundColor="#F3F3F3"
            columnGap={"30px"}
            dc={"140px 140px 140px 140px 140px 140px"}
            m={"0"}
            padding={"15px 30px"}
            position="fixed"
            left="0"
            right="0"
            bottom="0"
            zIndex="10000"
        >
            {viewModel.canDisplayCreateEnquiryCommand && (
                <ThemedButton styles={{ root: { padding: "0 5px" } }} paletteColor={"primary"} displayName="Create enquiry" command={viewModel.createEnquiryCommand} />
            )}

            {viewModel.canDisplayUpdateEnquiryCommand && (
                <ThemedButton styles={{ root: { padding: "0 5px" } }} paletteColor={"primary"} displayName="Save enquiry" command={viewModel.updateEnquiryCommand} />
            )}

            {viewModel.canDisplayCancelEditModeCommand && <ThemedButton styles={{ root: { padding: "0 5px" } }} displayName="Cancel" command={viewModel.cancelEditModeCommand} />}

            {viewModel.canDisplayDisplayConvertEnquiryToQuoteCommand && (
                <ThemedButton
                    styles={{ root: { padding: "0 5px" } }}
                    paletteColor={"quarternary"}
                    displayName="Convert to quote"
                    command={viewModel.displayConvertEnquiryToQuoteCommand}
                />
            )}

            {viewModel.canDisplayDisplayConvertToEnquiryCommand && (
                <ThemedButton
                    styles={{ root: { padding: "0 5px" } }}
                    paletteColor={"secondary"}
                    displayName="Convert to enquiry"
                    command={viewModel.displayConvertToEnquiryCommand}
                />
            )}

            {viewModel.canDisplayDisplayMarkAsLostEnquiryCommand && (
                <ThemedButton styles={{ root: { padding: "0 5px" } }} displayName="Mark as lost" command={viewModel.displayMarkAsLostEnquiryCommand} />
            )}

            {viewModel.backToEnquiriesCommand.canExecute() && (
                <ThemedButton styles={{ root: { padding: "0 5px" } }} displayName="Back to enquiries" command={viewModel.backToEnquiriesCommand} />
            )}
        </AutoGrid>
    );
});
