import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

import { Validator } from "Application/Validation";

export class WeeklyUpdateModel extends ModelBase<WeeklyUpdateModel> {
    /**
     * The weekly updates id.
     */
    public id: string | null = null;

    /**
     * The weekly updates parent project id.
     */
    public projectId: string | null = null;

    /**
     * The weekly updates update date. This is the start of a programming week.
     */
    public updateDate: Date | null = null;

    /**
     * The weekly updates send update status.
     */
    public sendUpdate: boolean = false;

    /**
     * The weekly customer updates notes. Can be seen by the customer and the users
     */
    public updateDetail: string = "";

    /**
     * The weekly internal notes used for. Can only be seen by the users
     */
    public internalDetail: string = "";

    /**
     * States if this model has a weekly update. Not part of the form data.
     */
    public hasUpdate: boolean = false;

    /**
     * States if this model has a weekly update for this programming week. Not part of the form data.
     */
    public hasActiveUpdate: boolean = false;

    /**
     * Audit information. Not part of the form data.
     */
    public createdByUserId: string | null = null;
    public updatedByUserId: string | null = null;

    /**
     * The previous update detail.
     */

    public previousProjectUpdateUpdateDetail: string | null = null;

    /**
     * The previous update internal note.
     */

    public previousProjectUpdateInternalDetail: string | null = null;

    constructor() {
        super();

        makeObservable(this, {
            // Observables
            hasUpdate: observable,
            hasActiveUpdate: observable,
            updateDate: observable,
            sendUpdate: observable,
            updateDetail: observable,
            internalDetail: observable,
            createdByUserId: observable,
            updatedByUserId: observable,
            previousProjectUpdateUpdateDetail: observable,
            previousProjectUpdateInternalDetail: observable,
        });
    }
}

export class WeeklyUpdateModelValidator extends Validator<WeeklyUpdateModel> {
    constructor() {
        super();
        this.ruleFor("updateDetail").notNull().withMessage("Please provide details").notEmpty().withMessage("Please provide details");
    }
}
