import { Validator } from "Application/Validation";
import { makeObservable, observable } from "mobx";
import { ModelBase } from "@shoothill/core";

export class RegisterUserModel extends ModelBase<RegisterUserModel> {
    public lastName: string = "";
    public firstName: string = "";
    public phoneNumber: number | string = "";
    public emailAddress: string = "";
    public password: string = "";
    public confirmPassword: string = "";
    public terms: boolean = false;
    public tod: Date = new Date();

    constructor() {
        super();
        makeObservable(this, {
            lastName: observable,
            firstName: observable,
            phoneNumber: observable,
            emailAddress: observable,
            password: observable,
            confirmPassword: observable,
            terms: observable,
            tod: observable,
        });
    }
}

export class RegisterUserValidator extends Validator<RegisterUserModel> {
    constructor() {
        super();

        this.ruleFor("firstName").notEmpty().maxLength(50).withMessage("Please enter your first name");
        this.ruleFor("lastName").notEmpty().maxLength(50).withMessage("Please enter your last name");
        this.ruleFor("emailAddress").notEmpty().emailAddress().maxLength(100).withMessage("Please enter a valid email address");
        this.ruleFor("password").notEmpty().maxLength(100).withMessage("Please enter a password");
        this.ruleFor("confirmPassword")
            .must({ predicate: (confirmPassword, model) => confirmPassword === model.password, message: (value, model) => "Passwords must match" })
            .withMessage("Passwords must match")
            .when((model) => model.password.length > 0);
    }
}
