import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { nanoid } from "nanoid";

export class FileModel extends ModelBase<FileModel> {
    public KEY: string = nanoid();

    public id: string | null = null;

    public file: File | null = null;
    public fileName: string = "";
    public fileSizeBytes: number = 0;
    public mimeType: string = "";
    public documentUrl: string = "";

    constructor() {
        super();
        makeObservable(this, {
            // Observables
            id: observable,
            file: observable,
            fileName: observable,
            fileSizeBytes: observable,
            mimeType: observable,
            documentUrl: observable,
        });
    }

    // #region Helpers

    public copy = (excludeKey: boolean = false): FileModel => {
        const model = new FileModel();

        model.KEY = excludeKey ? model.KEY : this.KEY;
        model.id = this.id;
        model.file = this.file;
        model.fileName = this.fileName;
        model.fileSizeBytes = this.fileSizeBytes;
        model.mimeType = this.mimeType;
        model.documentUrl = this.documentUrl;

        return model;
    };

    // #endregion Helpers
}
