// Libraries
import { isEmptyOrWhitespace, isNullOrUndefined, KeyValuePair } from "@shoothill/core";
import { DatePicker as FluentDatePicker, defaultDatePickerStrings, Dropdown, DropdownMenuItemType, IDropdownStyles, IDropdownOption, DayOfWeek } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React, { SyntheticEvent, useEffect } from "react";
import { ICommandAsync } from "../../../../../Application/Commands";

interface IProps {
    value: () => string;
    className?: string;
    displayName?: string;
    getOptionLabel?: (option: any) => string;
    groupBy?: (option: any) => string;
    gutterBottom?: boolean;
    placeholder?: string;
    validationMessage?: () => string;

    command: ICommandAsync;
}

export const DatePickerBase: React.FC<IProps> = observer((props: IProps) => {
    useEffect(() => {}, []);

    /* const getClasses = () => {
        return clsx({
            [props.className!]: !isEmptyOrWhitespace(props.className),
            ["select"]: true,
            ["selectGutterBottom"]: hasGutterBottom(),
        });
    };*/

    const getId = (): string => {
        return "selectControlId";
    };

    const getPlaceholder = (): string => {
        return !isEmptyOrWhitespace(props.placeholder) ? props.placeholder! : "";
    };

    const getValidationMessage = (): string => {
        return isEmptyOrWhitespace(props.validationMessage?.() as string) ? "" : (props.validationMessage?.() as string);
    };

    const hasGutterBottom = (): boolean => {
        return isNullOrUndefined(props.gutterBottom) ? false : props.gutterBottom!;
    };

    const isDisabled = (): boolean => {
        return isNullOrUndefined(props.command.canExecute) ? false : !props.command.canExecute();
    };

    const hasError = (): boolean => {
        return !isEmptyOrWhitespace(props.validationMessage?.() as string);
    };

    const onSelectDate = (date: Date | null | undefined): void => {
        if (props.command.execute) props.command.execute(date);
    };

    return (
        <FluentDatePicker
            firstDayOfWeek={DayOfWeek.Monday}
            placeholder="Select a date..."
            ariaLabel="Select a date"
            onSelectDate={onSelectDate}
            // DatePicker uses English strings by default. For localized apps, you must override this prop.
            strings={defaultDatePickerStrings}
        />
    );

    // #endregion Public Interface
});
