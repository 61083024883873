import styled from "@emotion/styled";

export const Container = styled.div`
    display: grid;

    @media only screen and (min-width: 1390px) {
        grid-template-columns: 30% repeat(2, 10%) repeat(2, 20%);
    }

    @media only screen and (max-width: 1389px) {
        row-gap: 10px;
    }

    padding: 0px 15px;
    min-height: 100px;
    padding-top: 10px;
    column-gap: 30px;
    margin-top: 50px;
    margin-bottom: 0px;
    width: 100%;
    border: 1px dashed #ff4b22;
    position: relative;

    .ms-TooltipHost {
        padding-top: 0;
    }
`;

export const ButtonGroup = styled.div`
    @media only screen and (min-width: 1389px) {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 15px;
        margin-top: 5px;
    }

    @media only screen and (max-width: 1389px) {
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 15px;
        margin-top: 5px;
        margin-bottom: 15px;
        width: 25%;
    }
`;
