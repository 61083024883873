import { DetailsRow, IColumn, Panel, PanelType } from "@fluentui/react";
import { isNullOrUndefined } from "@shoothill/core";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import { Box, formatDate, formatDecimalNumber, pxToRem, ThemedButton, ThemedText } from "Application";
import { ThemedDataTableLayer3 } from "Styles/ThemedPrimitives/Style1/ThemedDataTable";
import { CustomSidePanel } from "Views/Shared/CustomSidePanel/CustomSidePanel";
import { PanelStyles } from "Views/Shared/SharedComponents";
import { AssignTaskView } from "./AssignTaskSubViews/AssignTaskView";
import { CreateTaskView } from "./CreateTaskSubViews/CreateTaskView";
import { UnassignedTasksViewModel } from "./UnassignedTasksViewModel";
import { UnassignedTasksItemViewModel } from "./UnassignedTasksItemViewModel";
import { StaffDetailsViewModel } from "../../../StaffDetailsViewModel";
import { MyWorkWeekOverview } from "../../MyWorkWeekOverview/MyWorkWeekOverview";

interface IProps {
    viewModel: UnassignedTasksViewModel;
    parentViewModel: StaffDetailsViewModel;
}

export const UnassignedTasksView = observer((props: IProps) => {
    const viewModel = props.viewModel;

    // #region Code Behind

    /**
     * Handles updating the tables column sort settings in response to changes in the viewmodel.
     */
    useEffect(() => {
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter((currCol) => viewModel.getValue("sortKey") === currCol.key)[0];

        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSorted = true;
                currColumn.isSortedDescending = viewModel.getValue("sortDescending");
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = false;
            }
        });

        setColumns(newColumns);
    }, [viewModel.getValue("sortKey"), viewModel.getValue("sortDescending")]);

    /**
     * Gets a key for the row. Must be provided if sorting or filtering is enabled.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The row index.
     *
     * @returns A string key to identify the row.
     */
    const getRowKey = (item: UnassignedTasksItemViewModel, index?: number): string => {
        return item.KEY;
    };

    /**
     * Handles an onClick event for the table column header.
     *
     * @param ev Mouse event
     * @param column The column that has been clicked.
     */
    const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const currColumn: IColumn = columns.filter((currCol) => column.key === currCol.key)[0];
        const newSortKey = currColumn.key;
        const newSortDescending = currColumn.key === viewModel.getValue("sortKey") ? !currColumn.isSortedDescending : false;

        viewModel.updateSortCommand.execute(newSortKey, newSortDescending);
    };

    /**
     * Handles rendering the row. Normally we would not do this. However if
     * trying to display an overlay (alert, panel or modal), on dismissing
     * the overlay, onActiveItemChanged would be called again and redisplay the
     * overlay!
     *
     * @param props The row props of which item is the viewmodel.
     * @returns The table row.
     */
    const onRenderRow = (props: any) => {
        const viewModel = props.item;
        const projectTaskStatusForegroundColor = `${viewModel.projectTaskForegroundColor} !important`;

        return <DetailsRow {...props} onClick={() => onRowClick(props.item)} styles={{ root: { background: projectTaskStatusForegroundColor } }} />;
    };

    /**
     * Handler an onClick event for a table row.
     *
     * @param item The viewmodel data associated with the table row.
     */
    const onRowClick = (item: UnassignedTasksItemViewModel) => {
        viewModel.displayAssignTaskCommand.execute(item.model.id, item.model.originServiceGroupIsNCTPreset);
    };

    /**
     * Renders a date.
     */
    const onRenderDate = (value?: Date) => {
        return <div>{!isNullOrUndefined(value) ? formatDate(value!) : ""}</div>;
    };

    /**
     * Renders a decimal number.
     */
    const onRenderNumber = (value?: number) => {
        return <div>{!isNullOrUndefined(value) ? formatDecimalNumber(value!) : ""}</div>;
    };

    const defaultTableColumns: IColumn[] = [
        {
            key: "projectTaskName",
            fieldName: "projectTaskName",
            name: "TASK",
            minWidth: 281,
            maxWidth: 502,
            isResizable: true,
            isSorted: true,
            onColumnClick: onColumnClick,
        },

        {
            key: "projectTaskOrdinal",
            fieldName: "projectTaskOrdinal",
            name: "Quote order",
            minWidth: 80,
            maxWidth: 80,
            isResizable: false,
            isSorted: true,
            onColumnClick: onColumnClick,
        },
        {
            key: "anticipatedEndDate",
            fieldName: "anticipatedEndDate",
            name: "ANT. END DATE",
            minWidth: 80,
            maxWidth: 80,
            isResizable: true,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: (item) => onRenderDate(item.anticipatedEndDate),
        },
        {
            key: "remainingProjectTaskBillableEffort",
            fieldName: "remainingProjectTaskBillableEffort",
            name: "HRS REM",
            minWidth: 80,
            maxWidth: 80,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: (item) => onRenderNumber(item.remainingProjectTaskBillableEffort),
        },
    ];

    const [columns, setColumns] = useState<IColumn[]>(defaultTableColumns);

    // #endregion Code Behind

    useEffect(() => {
        if (viewModel.assignTaskViewModel != null) {
            props.parentViewModel.toggleMyWorkWeekOverviewCommand.execute("right", "90%", "calc(100% - 475px)");
        }
    }, [viewModel.canDisplayAssignTask]);

    const renderAssignTaskPanel = () => {
        return (
            <CustomSidePanel
                isOpen={viewModel.canDisplayAssignTask}
                onDismiss={() => {
                    props.parentViewModel.closeMyWorkWeekOverviewCommand.execute();
                    viewModel.cancelAssignTaskCommand.execute();
                }}
            >
                <AssignTaskView viewModel={viewModel.assignTaskViewModel!} parentViewModel={props.parentViewModel} />
                {viewModel.canDisplayWorkCalendar && (
                    <MyWorkWeekOverview
                        workCalendarViewModel={props.parentViewModel.workCalendarViewModel!}
                        show={props.parentViewModel.displayHorizontalMyWorkWeekOverview}
                        closeOverviewCommand={props.parentViewModel.closeMyWorkWeekOverviewCommand}
                        origin={"right"}
                        height={"90%"}
                        width={"calc(100% - 470px)"}
                        overlayColor="transparent"
                        showCloseButton
                        zIndex={-1}
                    />
                )}
            </CustomSidePanel>
        );
    };

    const renderCreateTaskPanel = () => {
        return (
            <Panel
                customWidth="475px"
                hasCloseButton={false}
                isLightDismiss
                isOpen={viewModel.canDisplayCreateTask}
                onDismiss={() => viewModel.cancelCreateTaskCommand.execute()}
                styles={PanelStyles}
                type={PanelType.custom}
            >
                <CreateTaskView viewModel={viewModel.createTaskViewModel!} />
            </Panel>
        );
    };

    return (
        <Box>
            {/* Title/Fiter Bar */}
            <ThemedText fontStyle="expandable">Unassigned/Partially assigned tasks</ThemedText>

            {/* Table */}
            <ThemedDataTableLayer3
                styles={{ root: { "&&&&": { marginTop: 0 } } }}
                onRenderRow={onRenderRow}
                getKey={getRowKey}
                items={viewModel.filteredAndSortedTasks}
                columns={columns}
            />

            <ThemedText fontStyle="expandable" mt={"8px"}>
                Total remaining hours: {formatDecimalNumber(viewModel.filteredAndSortedTasksRemainingHours)}
            </ThemedText>

            {/* Display Put On Hold button */}
            {viewModel.putProjectOnHoldCommand.canExecute() && (
                <ThemedButton command={viewModel.putProjectOnHoldCommand} displayName="Put on hold" mt={pxToRem(22)} mr={pxToRem(10)} paletteColor="tertiary" />
            )}

            {/* Display Taske Off Hold button */}
            {viewModel.takeProjectOffHoldCommand.canExecute() && (
                <ThemedButton command={viewModel.takeProjectOffHoldCommand} displayName="Take off hold" mt={pxToRem(22)} mr={pxToRem(10)} paletteColor="tertiary" />
            )}

            {/* Display Add Adhoc Task button */}
            {viewModel.displayCreateTaskCommand.canExecute() && (
                <ThemedButton command={viewModel.displayCreateTaskCommand} displayName="Add adhoc task" mt={pxToRem(22)} mr={pxToRem(10)} paletteColor="primary" />
            )}

            {/* Display Add Adhoc NCT Task button */}
            {viewModel.displayCreateNCTTaskCommand.canExecute() && (
                <ThemedButton command={viewModel.displayCreateNCTTaskCommand} displayName="Add adhoc NCT task" mt={pxToRem(22)} mr={pxToRem(10)} paletteColor="secondary" />
            )}

            {/* Display create task panel */}
            {renderCreateTaskPanel()}

            {/* Display assign task panel */}
            {renderAssignTaskPanel()}
        </Box>
    );
});
