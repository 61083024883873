import { IStyle, mergeStyleSets, Modal } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React from "react";

import { AutoGrid, Box, ExpandableContainer, ICommand, ThemedButton, ThemedCheckBox, ThemedComboBox, ThemedEditText, ThemedText } from "Application";
import { ServicesViewModel } from "./ServicesViewModel";
import { ConfigureServicesView } from "./ConfigureServicesView";
import { TaskGroupView } from "./ServiceSubViews/TaskGroupView";
import { TaskHeaderView } from "./ServiceSubViews/TaskHeaderView";
import { TaskFooterSubTotalView } from "./ServiceSubViews/TaskFooterSubTotalView";
import { TaskFooterTotalView } from "./ServiceSubViews/TaskFooterTotalView";
import { TaskFooterVATTotalView } from "./ServiceSubViews/TaskFooterVATTotalView";
import { TaskFooterTotalManHoursView } from "./ServiceSubViews/TaskFooterTotalHoursView";
import useResizeObserver from "Application/Hooks/UseResizeObserver";

interface IServicesViewStyles {
    root: IStyle;
    comboBox: IStyle;
    editDefaultHourlyRate: IStyle;
}

interface IServicesViewProps {
    viewModel: ServicesViewModel;
    previewTasksAndCostsDocumentCommand: ICommand;
}

const styleDefinitions: IServicesViewStyles = {
    root: {
        marginTop: "20px",
        marginBottom: "20px",
    },
    comboBox: {
        ".ms-Label": {
            display: "none",
        },
    },
    editDefaultHourlyRate: {
        marginLeft: "10px",
        marginRight: "-1px",
        width: "75px",
        ".ms-Label": {
            display: "none",
        },
    },
};

export const ServicesView: React.FC<IServicesViewProps> = observer((props) => {
    // #region Code Behind
    const { ref, observedHeight } = useResizeObserver(90);
    const viewModel = props.viewModel;
    const styles = mergeStyleSets(styleDefinitions);
    // #endregion Code Behind

    const renderModalContent = () => {
        return (
            <Modal isOpen={viewModel.canDisplayConfigureServices} onDismiss={() => viewModel.cancelDisplayConfigureTaskGroupCommand.execute()}>
                <ConfigureServicesView viewModel={viewModel.configureServicesViewModel!} />
            </Modal>
        );
    };

    const renderContent = () => {
        return (
            <div className={styles.root} ref={ref}>
                <AutoGrid mb={"-30px"} dc={"12% 25%"}>
                    <Box display="flex" alignItems="center">
                        <ThemedText fontStyle="h6">Set hourly rate</ThemedText>
                        <ThemedEditText
                            className={styles.editDefaultHourlyRate}
                            prefix={"£"}
                            command={viewModel.updateDefaultHourlyRateCommand}
                            placeholder=""
                            size="large"
                            value={() => viewModel.getValue("defaultHourlyRate") ?? ""}
                        />
                        <ThemedCheckBox
                            command={viewModel.updateApplyDefaultHourlyRateCommand}
                            value={() => viewModel.getValue("applyDefaultHourlyRate")}
                            paletteColor="secondary"
                            displayName=""
                        />
                    </Box>
                    <ThemedComboBox
                        className={styles.comboBox}
                        labelColor="default"
                        options={viewModel.availableServiceGroups}
                        size="large"
                        value={() => "DON'T PERSIST A SERVICE GROUP SELECTION"}
                        command={viewModel.displayConfigureTaskGroupCommand}
                        placeholder="Add services"
                    />
                </AutoGrid>
                <div>
                    <TaskHeaderView />
                    {viewModel.taskGroupViewModels.map((vm) => {
                        return <TaskGroupView key={vm.KEY} viewModel={vm} />;
                    })}

                    <TaskFooterTotalManHoursView viewModel={viewModel} />
                    <TaskFooterSubTotalView viewModel={viewModel} />
                    <TaskFooterVATTotalView viewModel={viewModel} />
                    <TaskFooterTotalView viewModel={viewModel} />
                </div>
                <ThemedButton
                    styles={{ root: { padding: "0 5px" } }}
                    paletteColor={"quarternary"}
                    displayName="Preview services"
                    command={props.previewTasksAndCostsDocumentCommand}
                />
                {renderModalContent()}
            </div>
        );
    };

    return (
        <React.Fragment>
            <ExpandableContainer displayName="Services" childrenHeight={observedHeight} openByDefault>
                {renderContent()}
            </ExpandableContainer>
        </React.Fragment>
    );
});
