import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Routes, Navigate } from "react-router-dom";

import { LeaveRequestsView } from "./Table/LeaveRequestsView";
import { LeaveCalendarView } from "./Calendar/LeaveCalendarView";
import { DashboardViewModel } from "Views/Dashboard/DashboardViewModel";

interface IProps {
    parentViewModel: DashboardViewModel;
}

export const LeaveView: React.FC<IProps> = observer((props: IProps) => {
    return (
        <Routes>
            <Route path={"requests"} element={<LeaveRequestsView parentViewModel={props.parentViewModel} />} />
            <Route path={"calendar"} element={<LeaveCalendarView />} />
            <Route path={"*"} element={<Navigate to="requests" replace />} />
        </Routes>
    );
});
