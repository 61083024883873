import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { nanoid } from "nanoid";
import { CustomerItemModel } from "Views/Shared/Customer/CustomerItemModel";

export class EnquiryItemModel extends ModelBase<EnquiryItemModel> {
    public KEY: string = nanoid();

    public id: string = "";
    public enquiryTypeId = "";
    public enquiryTypeName = "";
    public contactNumber1 = "";
    public contactTypeName = "";
    public customerName = "";
    public emailAddress = "";
    public emailAddress2 = "";
    public enquiryStatusName = "";
    public estimatedCost = 0;
    public lastActionDate: Date | null = null;
    public lastActionFirstName = null;
    public lastActionLastName = null;
    public reference = "";
    public title = "";
    public sourceOfEnquiryTypeId = "";
    public sourceOfEnquiryDetail: string | null = null;
    public enquiryStatusId = "";
    public customerFirstName = "";
    public customerLastName = "";
    public personVisitingId: string = "";
    public enquiryPersonVisitingFirstName = "";
    public enquiryPersonVisitingLastName = "";
    public enquiryPersonVisitingDocumentUrl = "";

    public customerItemModel: CustomerItemModel | null = null;

    constructor() {
        super();
        makeObservable(this, {
            id: observable,
            lastActionDate: observable,
            title: observable,
            customerName: observable,
            reference: observable,
            enquiryStatusId: observable,
            customerItemModel: observable,
        });
    }
}
