import { IStyle, mergeStyleSets } from "@fluentui/react";
import { isEmptyOrWhitespace } from "@shoothill/core";
import { observer } from "mobx-react-lite";
import React from "react";

import { theme, ThemedTransparentIconButton } from "Application";
import { FileIcon } from "Assets/Icons/FileIcon";
import { CrossSVG } from "Assets/CrossSVG";
import { FileViewModel } from "./FileViewModel";

interface IFileStyles {
    root: IStyle;
    fileInformation: IStyle;
    fileIcon: IStyle;
    documentUrl: IStyle;
    fileSizeBytes: IStyle;
    clearButton: IStyle;
}

interface IProps {
    className?: string;
    viewModel: FileViewModel;
}

const fileStyle: IFileStyles = {
    root: {
        alignItems: "center",
        border: "1px solid #DCDCDC",
        display: "flex",
        height: "50px",
        justifyContent: "space-between",
    },
    fileInformation: {
        alignItems: "center",
        display: "flex",
        p: {
            fontFamily: theme.defaultFontStyle.fontFamily,
            fontSize: "10px",
            letterSpacing: "0.3",
        },
        a: {
            fontFamily: theme.defaultFontStyle.fontFamily,
            fontSize: "10px",
            letterSpacing: "0.3",
        },
    },
    fileIcon: {
        margin: "9px",
    },
    documentUrl: {},
    fileSizeBytes: {
        color: "#D4D2D7",
    },
    clearButton: {
        g: {
            stroke: theme.palette.defaultTr.text,
        },
    },
};

export const File: React.FC<IProps> = observer((props) => {
    // #region Code Behind

    const styles = mergeStyleSets(fileStyle);

    const canDisplayLink = (): boolean => {
        return !isEmptyOrWhitespace(props.viewModel.model.documentUrl);
    };

    const isDisabled = (): boolean => {
        return !props.viewModel.removeCommand.canExecute;
    };

    // #endregion Code Behind

    return (
        <div className={styles.root}>
            <div className={styles.fileInformation}>
                <FileIcon className={styles.fileIcon} />
                <div>
                    {canDisplayLink() ? (
                        <a className={styles.documentUrl} href={props.viewModel.model.documentUrl} target="_blank">
                            {props.viewModel.displayName}
                        </a>
                    ) : (
                        <p>{props.viewModel.displayName}</p>
                    )}
                    <p className={styles.fileSizeBytes}>{props.viewModel.fileSizeBytes}</p>
                </div>
            </div>
            {!isDisabled() && <ThemedTransparentIconButton command={props.viewModel.removeCommand} icon={<CrossSVG className={styles.clearButton} />} shape="round" />}
        </div>
    );
});
