import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

export class TestModel extends ModelBase<TestModel> {
    public erik: string = "";

    constructor() {
        super();
        makeObservable(this, {
            erik: observable,
        });
    }
}

export class TestModelValidator extends Validator<TestModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
    }
}
