//libraries
import { ViewModelBase } from "@shoothill/core";
import { computed, makeObservable } from "mobx";
import { APIClient, RelayCommand, formatCurrency, formatFixedDecimalNumber } from "Application";

//app
import { InvoiceHistoryModel } from "./InvoiceHistoryModel";
import { GETAdminInvoicingForecastOverridesByFinancialYearEndpoint } from "./Endpoints/GETAdminInvoicingForecastOverridesByFinancialYearEndpoint";
import { container } from "tsyringe";
import { LookupStore } from "Stores/Domain";
import { POSTUpsertInvoicingForecastOverridesEndpoint } from "./Endpoints/POSTUpsertInvoicingForecastOverridesEndpoints";

export class InvoiceHistoryViewModel extends ViewModelBase<InvoiceHistoryModel> {
    public apiClient = new APIClient();
    private lookupStore = container.resolve(LookupStore);

    constructor(model = new InvoiceHistoryModel()) {
        super(model, false);

        makeObservable(this, {
            financialYears: computed,
            canEditFinancialYearHistory: computed,
            formattedTotalHours: computed,
            formattedTotalValue: computed,
        });
    }

    // only return the financial years from the past
    public get financialYears() {
        //get current year
        const currentYear = new Date().getFullYear();
        const options = this.lookupStore.getFinancialYears.filter((year) => Number(year.key) < currentYear);
        return options;
    }

    public get formattedTotalHours() {
        const retVal = this.model.capacities.reduce((total, capacity) => {
            let x = 0;
            if (capacity.invoiceForecastCapacityOverride) {
                x = Number(capacity.invoiceForecastCapacityOverride.hours);
            }
            return total + x;
        }, 0);
        return formatFixedDecimalNumber(retVal);
    }

    public get formattedTotalValue() {
        const retVal = this.model.capacities.reduce((total, capacity) => {
            let x = 0;
            if (capacity.invoiceForecastCapacityOverride) {
                x = Number(capacity.invoiceForecastCapacityOverride.value);
            }
            return total + x;
        }, 0);
        return formatCurrency(retVal);
    }

    public get canEditFinancialYearHistory() {
        return this.model.isEditable;
    }

    //endregion properties

    //region endpoint calls
    public init() {
        //get the latest available financial year
        this.model.financialYearFilter = this.financialYears[this.financialYears.length - 1].key;
        this.apiClient.sendAsync(new GETAdminInvoicingForecastOverridesByFinancialYearEndpoint(this));
    }

    //endregion endpoint calls

    //region commands

    public updateFinancialYearCommand = new RelayCommand(
        (financialYear: number) => {
            this.setValue("financialYearFilter", financialYear);
            this.apiClient.sendAsync(new GETAdminInvoicingForecastOverridesByFinancialYearEndpoint(this));
        },
        () => true,
    );

    public editFinancialYearHistoryCommand = new RelayCommand(() => {
        if (!this.model.isEditable) {
            this.model.backup();
        }

        this.setValue("isEditable", true);
    });

    public confirmEditFinancialYearHistoryCommand = new RelayCommand(() => {
        this.apiClient.sendAsync(new POSTUpsertInvoicingForecastOverridesEndpoint(this));
        this.setValue("isEditable", false);
    });

    public cancelEditFinancialYearHistoryCommand = new RelayCommand(() => {
        if (this.model.isEditable) {
            this.model.restore();
        }

        this.setValue("isEditable", false);
    });

    //endregion commands
}
