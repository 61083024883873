import { ViewModelBase } from "@shoothill/core";
import { computed, makeObservable } from "mobx";
import { container } from "tsyringe";

import { LookupStore } from "Stores/Domain";
import { SummaryModel } from "./SummaryModel";
import { theme } from "Application";

export class SummaryViewModel extends ViewModelBase<SummaryModel> {
    private lookupStore = container.resolve(LookupStore);

    constructor() {
        super(new SummaryModel());

        makeObservable(this, {
            reference: computed,
            title: computed,
            taskGroupName: computed,
            taskName: computed,
        });
    }

    // #region Properties

    public get reference() {
        return this.model.reference;
    }

    public get title() {
        return this.model.title;
    }

    public get taskGroupName() {
        return this.model.projectTaskGroupName;
    }

    public get taskName() {
        return this.model.projectTaskName;
    }

    public get backgroundColor() {
        // the task is billed by default so the color will always be the same
        return theme.palette.common.complete;
    }

    // #endregion Properties
}
