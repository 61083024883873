import _ from "lodash";
import { runInAction } from "mobx";

import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";

import { WeeklyUpdateHistoryModel } from "../ProjectWeeklyUpdate/History/WeeklyUpdateHistoryModel";
import { ProjectWeeklyUpdateContainerViewModel } from "../ProjectWeeklyUpdate/ProjectWeeklyUpdateContainerViewModel";

class Response {
    id: string = "";
    projectId: string = "";

    updateDetail: string = "";
    updateDate: string = "";

    createdDate: string = "";
    createdByUserId: string = "";
    updatedDate: string = "";
    updatedByUserId: string = "";
}

export class GETProjectWeeklyUpdateByUpdateIdEndpoint extends EndpointWithoutRequest<Response> {
    private readonly viewModel: ProjectWeeklyUpdateContainerViewModel;

    constructor(weeklyUpdateid: string, viewModel: ProjectWeeklyUpdateContainerViewModel) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.Projects.GetProjectWeeklyUpdateByUpdateId.replace(":id", weeklyUpdateid));
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            const model = new WeeklyUpdateHistoryModel();

            model.fromResponse(response);

            this.viewModel.model.updateHistories.push(model);
        });
    }
}
