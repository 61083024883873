import { ModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";
import { nanoid } from "nanoid";

import { LeaveRequest } from "Views/Leave/Shared/LeaveRequest";
import { LeaveStatusType } from "Views/Leave/Shared/LeaveStatusType";
import { LeaveType } from "Views/Leave/Shared/LeaveType";

export class LeaveRequestItemModel extends ModelBase<LeaveRequestItemModel> {
    public KEY: string = nanoid();

    public leaveRequest: LeaveRequest | null = null;

    constructor() {
        super();

        makeObservable(this, {});
    }

    // #region Business Logic

    public get canCancel() {
        return this.leaveRequest?.leaveStatusType?.type === LeaveStatusType.APPROVED && this.leaveRequest?.leaveType?.type !== LeaveType.CANCEL;
    }

    public get canDelete() {
        return this.leaveRequest?.leaveStatusType?.type === LeaveStatusType.PENDING;
    }

    public get canEdit() {
        return this.leaveRequest?.leaveStatusType?.type === LeaveStatusType.PENDING && this.leaveRequest?.leaveType?.type !== LeaveType.CANCEL;
    }

    // #endregion Business Logic
}
