import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

import { Validator } from "Application/Validation";
import { container } from "tsyringe";
import { LookupStore } from "Stores/Domain";

export class EnquiryLiteModel extends ModelBase<EnquiryLiteModel> {
    public lookupStore = container.resolve(LookupStore);

    public id: string | null = null;
    public title: string | null = null;
    public enquiryTypeId: string | null = null;
    public enquiryStatusId: string | null = null;
    public projectId: string | null = null;
    public projectTypeId: string | null = null;
    public estimatedCost: number | null = null;
    public isQuote: boolean | null = null;
    public isLost: boolean | null = null;

    constructor() {
        super();

        makeObservable(this, {});
    }

    public get projectTypeName() {
        const projectType = this.lookupStore.projectTypes.find((type) => type.id == this.projectTypeId);
        return projectType!.name;
    }
}

export class QuoteModelValidator extends Validator<EnquiryLiteModel> {
    constructor() {
        super();
    }
}
