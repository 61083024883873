import { isEmptyOrWhitespace, isNullOrUndefined, ViewModelBase } from "@shoothill/core";
import { computed, makeObservable, observable } from "mobx";
import { container } from "tsyringe";

import { APIClient, RelayCommand } from "Application";
import { AppUrls } from "AppUrls";
import { GETEnquiriesEndpoint } from "./GETAllEnquiriesEndpoint";
import { EnquiriesModel } from "./EnquiriesModel";
import { EnquiryItemViewModel } from "./EnquiryItemViewModel";
import { LookupStore, SettingsStore } from "../../../../Stores/Domain";

export class EnquiriesViewModel extends ViewModelBase<EnquiriesModel> {
    public apiClient = new APIClient();
    public enquiries = observable<EnquiryItemViewModel>([]);
    private lookupStore = container.resolve(LookupStore);

    constructor() {
        super(new EnquiriesModel());

        makeObservable(this, {
            enquiries: observable,

            // Computeds
            canFilterEnquiries: computed,
            filteredEnquiry: computed,
            canSortEnquiries: computed,
            filteredAndSortedEnquiries: computed,
            visitingPersons: computed,
        });

        const _ = this.apiClient.sendAsync(new GETEnquiriesEndpoint(this));
    }

    public get settings() {
        return container.resolve(SettingsStore).EnquiriesTable;
    }

    // #region Filtering

    public updateFilterKeywordCommand = new RelayCommand((keyword: string) => {
        this.model.filterKeyword = keyword;
    });

    public updateFilterEnquiryStatusCommand = new RelayCommand((statusId: string | null) => {
        this.model.filterEnquiryStatusId = statusId;
    });

    public get enquiryStatuses() {
        return [{ key: "", text: "All statuses" }, ...this.lookupStore.getEnquiryStatuses()];
    }

    public updateFilterFromDateComand = new RelayCommand((date: Date | undefined) => {
        this.model.filterFromDate = date;
    });

    public updateFilterToDateCommand = new RelayCommand((date: Date | undefined) => {
        this.model.filterToDate = date;
    });

    public updateFilterVisitingPersonCommand = new RelayCommand((id: string | null) => {
        this.model.filterVisitingPersonId = id;
    });

    public updateFilterSourceOfEnquiryTypeCommand = new RelayCommand((id: string | null) => {
        this.model.filterSourceOfEnquiryTypeId = id;
    });

    public get canFilterEnquiries(): boolean {
        return (
            !isEmptyOrWhitespace(this.model.filterKeyword) ||
            !isNullOrUndefined(this.model.filterFromDate) ||
            !isNullOrUndefined(this.model.filterToDate) ||
            !isEmptyOrWhitespace(this.model.filterEnquiryStatusId) ||
            !isEmptyOrWhitespace(this.model.filterVisitingPersonId) ||
            !isEmptyOrWhitespace(this.model.filterSourceOfEnquiryTypeId)
        );
    }

    public get filteredEnquiry(): EnquiryItemViewModel[] {
        return this.canFilterEnquiries
            ? this.enquiries.filter((vm) =>
                  vm.filterPredicate(
                      this.model.filterKeyword,
                      this.model.filterFromDate,
                      this.model.filterToDate,
                      this.model.filterEnquiryStatusId,
                      this.model.filterVisitingPersonId,
                      this.model.filterSourceOfEnquiryTypeId,
                  ),
              )
            : this.enquiries;
    }

    public get visitingPersons() {
        const options = this.enquiries
            .map((e) => {
                return {
                    key: e.model.personVisitingId,
                    text: `${e.model.enquiryPersonVisitingFirstName} ${e.model.enquiryPersonVisitingLastName}`,
                };
            })
            .sort((a, b) => (a.text > b.text ? 1 : b.text > a.text ? -1 : 0));

        const distinctOptions = options.filter((option, index, arr) => arr.findIndex((t) => t.key === option.key) === index).filter((o) => o.key !== null);

        return [{ key: "", text: "All persons" }, ...distinctOptions];
    }

    public get sourceOfEnquiriesTypes() {
        const options = this.enquiries
            .map((e) => {
                return {
                    key: e.model.sourceOfEnquiryTypeId,
                    text: e.sourceOfEnquiryTypeName,
                };
            })
            .sort((a, b) => (a.text > b.text ? 1 : b.text > a.text ? -1 : 0));

        const distinctOptions = options.filter((option, index, arr) => arr.findIndex((t) => t.key === option.key) === index);

        return [{ key: "", text: "All sources of enquiry" }, ...distinctOptions];
    }

    // #endregion Filtering

    // #region Sorting

    public updateSortCommand = new RelayCommand((key: string, sortDescending: boolean) => {
        this.settings.sortKey = key;
        this.settings.sortDescending = sortDescending;
    });

    public get canSortEnquiries(): boolean {
        return !isEmptyOrWhitespace(this.settings.sortKey);
    }

    public get filteredAndSortedEnquiries(): EnquiryItemViewModel[] {
        return this.canSortEnquiries
            ? this.filteredEnquiry.slice().sort((lhs, rhs) => {
                  return (this.settings.sortDescending ? lhs[this.settings.sortKey] < rhs[this.settings.sortKey] : lhs[this.settings.sortKey] > rhs[this.settings.sortKey])
                      ? 1
                      : -1;
              })
            : this.filteredEnquiry.slice();
    }

    // #endregion Sorting

    public navigateToEditEnquiry = new RelayCommand((item: EnquiryItemViewModel) => {
        this.history.push(AppUrls.Client.Enquiries.Edit.replace(":id", item.model.id));
    });

    public navigateToCustomerDetailCommand = new RelayCommand((id: string) => {
        this.history.push(AppUrls.Client.Customers.Details.replace(":id", id));
    });
}
