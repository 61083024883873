import { ViewModelBase } from "@shoothill/core";
import { computed, makeObservable } from "mobx";

import { APIClient, RelayCommand } from "Application";
import moment from "moment";
import { InvoiceModel } from "./InvoiceModel";
import { GetAdminInvoicingForecastEndpoint } from "../../Endpoints/GETAdminInvoicingForecastEndpoint";
import { GETAdminInvoicingForecastOverridesByFinancialYearEndpoint } from "./Endpoints/GETAdminInvoicingForecastOverridesByFinancialYearEndpoint";
import { container } from "tsyringe";
import { LookupStore } from "Stores/Domain";

export class InvoiceViewModel extends ViewModelBase<InvoiceModel> {
    public apiClient = new APIClient();
    private lookupStore = container.resolve(LookupStore);
    constructor() {
        super(new InvoiceModel());

        makeObservable(this, {
            //computed
            canDisplayResults: computed,
            // actions
        });
        this.loadData();
    }

    // region Properties

    public get earliestAvailableFinancialYear() {
        return this.lookupStore.financialYears;
    }

    public get formattedFinancialYear() {
        return moment(this.model.financialYearFilter).format("YYYY");
    }

    public get isFinancialYearInPast() {
        return moment(this.model.financialYearFilter).year() < moment().year();
    }

    public get isNextFinancialYearInFuture() {
        return moment(this.model.financialYearFilter).add(1, "year").year() > moment().year();
    }

    public get isEarliestFinancialYearAvailable() {
        const earliestFinancialYear = this.lookupStore.financialYears.reduce((min, cur) => Math.min(min, cur));
        const retVal = earliestFinancialYear == moment(this.model.financialYearFilter).year();
        return retVal;
    }

    public get showInvoiceHistory() {
        return this.isFinancialYearInPast;
    }

    public get showInvoiceForecast() {
        return !this.isFinancialYearInPast;
    }

    public get title() {
        return this.showInvoiceHistory ? "Invoicing History" : "Invoicing Forecast";
    }

    public get canDisplayResults(): boolean {
        return !this.apiClient.IsBusy;
    }

    public get isLoading(): boolean {
        return this.apiClient.IsBusy;
    }

    // region Commands

    public previousFinancialYearCommand = new RelayCommand(
        async () => {
            this.setValue("financialYearFilter", moment(this.model.financialYearFilter).subtract(1, "year").toDate());
            this.loadData();
        },
        () => !this.isEarliestFinancialYearAvailable && !this.isLoading,
    );

    public nextFinancialYearCommand = new RelayCommand(
        async () => {
            this.setValue("financialYearFilter", moment(this.model.financialYearFilter).add(1, "year").toDate());
            this.loadData();
        },
        () => !this.isNextFinancialYearInFuture && !this.isLoading,
    );

    public loadData = async () => {
        if (this.isFinancialYearInPast) {
            await this.apiClient.sendAsync(new GETAdminInvoicingForecastOverridesByFinancialYearEndpoint(this));
        } else {
            await this.apiClient.sendAsync(new GetAdminInvoicingForecastEndpoint(this));
        }
    };
}
