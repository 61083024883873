import { runInAction } from "mobx";

import { EndpointWithoutRequest, Http } from "Application";
import { Logger } from "index";
import { LostViewModel } from "./LostViewModel";
import { LostItemModel } from "./LostItemModel";
import { LostItemViewModel } from "./LostItemViewModel";
import { CustomerItemModel } from "Views/Shared/Customer/CustomerItemModel";

class LostLiteResponse {
    public isEnquiry: boolean = false;
    public contactTypeId: string = "";
    public customerId: string = "";
    public customerContactId: string = "";
    public reference: string = "";
    public title: string = "";
    public hours: number = 0;
    public value: number = 0;
    public confidence: number = 0;
    public desirability: number = 0;
    public contactNumber1: string = "";
    public contactNumber2: string = "";
    public emailAddress: string = "";
    public emailAddress2: string = "";
    public customerName: string = "";
    public contactTypeName: string = "";
    public customerContactFirstName: string = "";
    public customerContactLastName: string = "";
    public createdDate: Date = new Date();
    public lastActionDate: Date = new Date();
    public lastActionByUserId: string = "";
    public lastActionFirstName: string = "";
    public lastActionLastName: string = "";
    public dateIssued: Date = new Date();
    public id: string = "";
    public lostNoteSubTypeId: string = "";
    public revision: string = "";
}

class CustomerLiteResponse {
    public id: string = "";
    public contactTypeId: string = "";
    public contactTitleId: string | null = null;

    public businessName: string | null = null;
    public prefixName: string = "";
    public firstName: string | null = null;
    public lastName: string | null = null;

    public contactNumber1: string | null = null;
    public contactNumber2: string | null = null;
    public emailAddress: string | null = null;
    public emailAddress2: string | null = null;

    public address1: string = "";
    public address2: string | null = null;
    public address3: string | null = null;
    public city: string = "";
    public postcode: string = "";
}
class LostWithRelatedResponse {
    public lostLite: LostLiteResponse | null = null;
    public customerLite: CustomerLiteResponse | null = null;
}

class Response {
    public lostWithRelatedResponses: LostWithRelatedResponse[] = [];
}

export class GETLostEndpoint extends EndpointWithoutRequest<Response> {
    private viewModel: LostViewModel;

    constructor(viewModel: LostViewModel) {
        super();
        this.viewModel = viewModel;
        this.verb(Http.Get);
        this.path("/api/lost/getalllostlite");
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            const enquiries: LostItemViewModel[] = [];

            response.lostWithRelatedResponses.forEach((response: LostWithRelatedResponse) => {
                const model = new LostItemModel();
                const customerItemModel = new CustomerItemModel();
                customerItemModel.fromResponse(response.customerLite);

                model.fromResponse(response.lostLite);
                model.customerItemModel = customerItemModel;

                enquiries.push(new LostItemViewModel(model));
            });

            this.viewModel.enquiries.replace(enquiries);
        });
    }
}
