import { runInAction } from "mobx";

import { Endpoint, Http, startOfDay } from "Application";
import { AppUrls } from "AppUrls";
import { StaffDetailsViewModel } from "./StaffDetailsViewModel";
import { StaffDetailsModel } from "./StaffDetailsModel";
import { StaffDetailsItemModel } from "./StaffDetailsItemModel";
import { StaffDetailsItemViewModel } from "./StaffDetailsItemViewModel";

class Request {
    public userId: string = "";
    public filterStartDate: Date | null = null;
}

class Response {
    public userId: string = "";
    public userFirstName: string = "";
    public userLastName: string = "";
    public documentUrl: string = "";

    public businessHours: number = 0;
    public chargeableBusinessHours: number = 0;
    public chargeableEffortHoursAllocation: number = 0;
    public chargeableEffortPercentageAllocation: number = 0;
    public effortHoursAllocation: number = 0;
    public effortPercentageAllocation: number = 0;

    public projects: ProjectDetailsResponse[] = [];
}

class ProjectDetailsResponse {
    public reference = "";
    public title = "";
    public hasWeeklyUpdate: boolean | null = null;
    public noOfProjectTasks: number | null = null;
    public noOfUnassignedOrPartialTasks: number | null = null;
    public noOfAssignmentsInProgress: number | null = null;
    public customerContactFullName: string = "";
    public projectSupportId: string | null = null;
    public projectSupportFirstName: string | null = null;
    public projectSupportLastName: string | null = null;
}

export class GETStaffProjectDetailsByIdEndpoint extends Endpoint<any, any> {
    private userId: string;
    private viewModel: StaffDetailsViewModel;

    constructor(userId: string, viewModel: StaffDetailsViewModel) {
        super();
        this.userId = userId;
        this.viewModel = viewModel;
        this.verb(Http.Post);
        this.path(AppUrls.Server.Resources.GetStaffProjectDetailsByUserId);
    }

    public async HandleRequestAsync(model: StaffDetailsModel): Promise<any> {
        let request = new Request();

        request.userId = this.userId;
        request.filterStartDate = startOfDay(this.viewModel.model.filterStartDate) ?? null;

        return await Promise.resolve(request);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        runInAction(() => {
            this.viewModel.model.fromResponse(response);

            const projects: StaffDetailsItemViewModel[] = [];

            response.projects.forEach((element: any) => {
                const model = new StaffDetailsItemModel();

                model.fromResponse(element);

                projects.push(new StaffDetailsItemViewModel(model, this.viewModel));
            });

            this.viewModel.projects.replace(projects);
        });
    }
}
