import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { UserModel } from "./UserModel";

import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { UserViewModel } from "./UserViewModel";

class Request {
    public id: string = "";
    public firstName: string = "";
    public lastName: string = "";
    public emailAddress: string = "";
    public password: string = "";
    public concurrencyToken: string = "";
    public isDeleted: boolean = false;
    public lastLoginDate: Date | null = null;
    public currentRoleId: string | null = null;
    public signature: string | null = null;
    public annualLeaveAllowance: number | null = null;
    public dailyChargeableHours: number | null = null;
    public isAssignable: boolean = false;
    public companyTitle: string | null = null;
    public isInvoicingForecastVisible: boolean = false;
    public isProjectOverview: boolean = false;
    public isQuoteLead: boolean = false;
}

class Response {
    public id: string = "";
}

export class POSTSaveUserEndpoint extends Endpoint<Request, Response> {
    private readonly viewModel: UserViewModel;

    constructor(viewModel: UserViewModel) {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Admin.Users.PostSaveUser);
        this.AllowFileUploads();
        this.viewModel = viewModel;
    }

    HandleRequestAsync(model: UserModel): Promise<any> {
        let request: Request = new Request();
        request.id = model.id;
        request.firstName = model.firstName;
        request.lastName = model.lastName;
        request.emailAddress = model.emailAddress;
        request.password = model.password;
        request.lastLoginDate = model.lastLoginDate;
        request.currentRoleId = model.currentRoleId;
        request.signature = model.signature;
        request.annualLeaveAllowance = model.annualLeaveAllowance;
        request.dailyChargeableHours = model.dailyChargeableHours;
        request.isAssignable = model.isAssignable;
        request.companyTitle = model.companyTitle;
        request.isInvoicingForecastVisible = model.isInvoicingForecastVisible;
        request.isProjectOverview = model.isProjectOverview;
        request.isQuoteLead = model.isQuoteLead;

        // We need to send the data to the server as form data here as we have files.
        const formData = new FormData();

        formData.append("data", JSON.stringify(request));

        // Append if personaFileViewModel is not null
        if (this.viewModel.personaFileViewModel.model.file != null) {
            let document = this.viewModel.personaFileViewModel.model;
            formData.append(document.KEY, document.file!);
        }

        return Promise.resolve(formData);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        let userModel: UserModel = new UserModel();

        userModel.fromResponse(response);

        this.viewModel.history.push(AppUrls.Client.Users.Table);
    }
}
