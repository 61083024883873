import { mergeStyleSets } from "@fluentui/react";
import { PropsWithChildren } from "react";

import { IErrorBaseProps, ErrorBase } from "../../../Components/StyleFrameworks/FluentUI/Primitives/Active/ErrorBase";
import { theme } from "../../AppTheme";
import { observer } from "mobx-react-lite";
import { pxToRem } from "../../BaseStyleSheet";

/**
 * WARNING - If your styled components needs to change style because of change in the state of the
 * model (via the props), wrap the component in a mobx observer.
 */
export const ThemedError = observer((props: PropsWithChildren<IErrorBaseProps>) => {
    return (
        <ErrorBase
            {...props}
            styles={mergeStyleSets(
                {
                    keyboardMoveIcon: {},
                    keyboardMoveIconContainer: {},
                    layer: {},
                    main: {
                        border: "none",
                        boxShadow: "none",
                        height: "100%",
                        maxHeight: "100%",
                        maxWidth: "100%",
                        width: "100%",

                        backgroundColor: `${theme.palette.common.default}B2`,

                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                    },
                    root: {
                        "&&": {
                            backgroundColor: "transparent",
                            transition: "none",
                            "> div.ms-Overlay": {
                                backgroundColor: "transparent",
                            },
                        },
                    },
                    scrollableContent: {
                        flex: "0",
                        overflowY: "none",
                        textAlign: "center",
                        button: {
                            width: pxToRem(125),
                        },
                    },
                },
                props.styles,
            )}
        />
    );
});
