import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { nanoid } from "nanoid";

export class UnassignedTaskItemModel extends ModelBase<UnassignedTaskItemModel> {
    public KEY: string = nanoid();

    public id: string = "";
    public projectTaskGroupId = "";
    public projectTaskId = "";
    public projectLeadId = "";

    public reference = "";
    public originReference = "";
    public title = "";
    public projectTaskGroupName = "";
    public projectTaskName = "";
    public projectLeadFirstName = "";
    public projectLeadLastName = "";
    public projectLeadDocumentUrl = "";
    public remainingProjectTaskBillableEffort: number | null = null;

    constructor() {
        super();
        makeObservable(this, {
            id: observable,
            projectTaskGroupId: observable,
            projectTaskId: observable,
            projectLeadId: observable,

            reference: observable,
            originReference: observable,
            title: observable,
            projectTaskGroupName: observable,
            projectTaskName: observable,
            projectLeadFirstName: observable,
            projectLeadLastName: observable,
            projectLeadDocumentUrl: observable,
            remainingProjectTaskBillableEffort: observable,
        });
    }
}
