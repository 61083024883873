import { runInAction } from "mobx";

import { Endpoint, Http, startOfDay } from "Application";
import { AppUrls } from "AppUrls";
import { WeeklyUpdatesViewModel } from "../WeeklyUpdatesViewModel";
import { WeeklyUpdateItemModel } from "../WeeklyUpdateItem/WeeklyUpdateItemModel";
import { WeeklyUpdateItemViewModel } from "../WeeklyUpdateItem/WeeklyUpdateItemViewModel";
import { Logger } from "index";

class Request {
    public programmingWeekStartDate: Date | null = null;
}

class ProjectWithRelatedLiteResponse {
    public projectUpdateId: string | null = null;
    public reference: string | null = null;
    public projectTitle: string | null = null;
    public projectId: string | null = null;
    public projectStatusId: string | null = null;
    public sendUpdate: boolean | null = null;
    public updateDate: boolean | null = null;
}
class Response {
    public projectUpdatesWithRelatedLiteResponse: ProjectWithRelatedLiteResponse[] = [];
}

export class GETProjectUpdatesWithRelatedLiteByGivenDatesAndProjectLeadIdEndpoint extends Endpoint<any, any> {
    private viewModel: WeeklyUpdatesViewModel;

    constructor(viewModel: WeeklyUpdatesViewModel) {
        super();
        this.viewModel = viewModel;
        this.verb(Http.Post);
        this.path(AppUrls.Server.Dashboard.Staff.GetProjectUpdatesWithRelatedLiteByGivenDatesAndProjectLeadId);
    }

    public async HandleRequestAsync(): Promise<any> {
        let request = new Request();
        //user id and end date returned from server side
        request.programmingWeekStartDate = this.viewModel.model.programmingWeekStartDate;
        return await Promise.resolve(request);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            const viewModels: WeeklyUpdateItemViewModel[] = [];
            response.projectUpdatesWithRelatedLiteResponse.forEach((element) => {
                const model = new WeeklyUpdateItemModel();

                model.fromResponse(element);

                viewModels.push(new WeeklyUpdateItemViewModel(model, this.viewModel));
            });

            this.viewModel.weeklyUpdateItemViewModels.replace(viewModels);
        });
    }
}
