import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

export class PlanningApplicationTableItemModel extends ModelBase<PlanningApplicationTableItemModel> {
    public id: string = "";
    public projectId: string = "";
    public projectRef: string = "";
    public localAuthorityDistrictId: string | null = null;
    public localAuthorityDistrictName: string | null = null;
    public planningRef: string | null = null;
    public planningApplicationStatusId: string = "";
    public planningApplicationTypeId: string | null = null;
    public note: string | null = null;
    public submittedDate: Date | null = null;
    public determinationDate: Date | null = null;
    public approvedDate: Date | null = null;
    public consultationDate: Date | null = null;
    //region related
    public projectTitle: string = "";

    public customerId: string | null = null;
    public customerFirstName = "";
    public customerLastName = "";
    public projectLeadId: string = "";
    public projectLeadFirstName = "";
    public projectLeadLastName = "";
    public projectLeadDocumentUrl = "";
    public planningApplicationTypeName: string | null = null;

    constructor() {
        super();
        makeObservable(this, {
            id: observable,
            projectId: observable,
            projectRef: observable,
            localAuthorityDistrictId: observable,
            planningRef: observable,
            planningApplicationStatusId: observable,
            planningApplicationTypeId: observable,
            note: observable,
            submittedDate: observable,
            determinationDate: observable,
            approvedDate: observable,
            consultationDate: observable,
            projectTitle: observable,
            customerId: observable,
            customerFirstName: observable,
            customerLastName: observable,
            projectLeadId: observable,
            projectLeadFirstName: observable,
            projectLeadLastName: observable,
            projectLeadDocumentUrl: observable,
        });
    }
}
