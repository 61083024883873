import { DatePicker, defaultDatePickerStrings, ICalendarProps, IDatePickerStrings, IDatePickerStyles } from "@fluentui/react";
import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";

import { ICommand } from "../../../../../Application/Commands";
import { themeColourOptions, themeShapeOptions, themeSizeOptions } from "../../../../../Styles/IShoothillTheme";

/**
 * Edit Date interface.
 */
export interface IEditDateBaseProps {
    /**
     * An optional class name for use with the button.
     */
    className?: string;
    /**
     * A command to execute.
     */
    command: ICommand;
    /**
     * A value to use with the control. Will be passed back by the command.
     */
    value: () => Date | undefined;
    /**
     * Text content to display as the label of the date control.
     */
    displayName?: string;
    /**
     * Text content to display in the placeholder.
     */
    placeholder?: string;
    /**
     * Text content to display in the error message.
     */
    validationMessage?: () => string;
    /**
     * Styling of the control.
     */
    styles?: IDatePickerStyles;
    /**
     * The size of the control - use this if using generic control.
     */
    size?: themeSizeOptions;
    /**
     * The shape of the control - use this if using the generic control.
     */
    shape?: themeShapeOptions;

    labelColor?: themeColourOptions;
    /**
     * The description text to show under the text.
     */
    description?: string;

    minDate?: Date;
    maxDate?: Date;

    onRenderLabel?: (isInError: boolean) => JSX.Element;

    calendarProps?: ICalendarProps;

    showLabel?: boolean;
    //determines if the error message will be shown or hidden
    showErrorMessage?: boolean;
    //determines if the error message shows by default on top of the input or is enclosed inside the input
    encloseErrorLabel?: boolean;
}

export const EditDateBase: React.FC<IEditDateBaseProps> = observer((props) => {
    // #region DatePicker Code Behind

    const [internalDate, setInternalDate] = React.useState<Date | undefined>();

    useEffect(() => {
        setInternalDate(props.value?.());
    }, [props.value?.()]);

    const getDateFormat = (date?: Date): string => {
        const format = new Intl.DateTimeFormat("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        });

        return format.format(date);
    };

    const onParseDateFromString = (newValue: string): Date => {
        const previousValue = internalDate || new Date();
        const newValueParts = (newValue || "").trim().split("/");

        const day = newValueParts.length > 0 ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10))) : previousValue.getDate();
        const month = newValueParts.length > 1 ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1 : previousValue.getMonth();
        let year = newValueParts.length > 2 ? parseInt(newValueParts[2], 10) : previousValue.getFullYear();

        if (year < 100) {
            year += previousValue.getFullYear() - (previousValue.getFullYear() % 100);
        }

        return new Date(year, month, day);
    };

    const onSelectDate = (date: Date | null | undefined): void => {
        setInternalDate(isNullOrUndefined(date) ? undefined : date!);
        props.command.execute(isNullOrUndefined(date) ? undefined : date);
    };

    // #endregion DatePicker Code Behind

    // #region Code Behind

    const getClasseNames = () => {
        return clsx({
            [props.className!]: !isNullOrUndefined(props.className),
        });
    };

    const getValidationMessage = (): string => {
        return isNullOrUndefined(props.validationMessage?.() as string) ? "" : (props.validationMessage?.() as string);
    };

    const isDisabled = (): boolean => {
        return isNullOrUndefined(props.command.canExecute) ? false : !props.command.canExecute();
    };

    const onRenderLabel = (isInError: boolean): JSX.Element => {
        return props.onRenderLabel?.(isInError) ?? <>{props.description}</>;
    };

    // The string (set to empty) are local validation strings. They have been set to empty so
    // they do not conflict with external validation done in the model.
    const strings: IDatePickerStrings = {
        ...defaultDatePickerStrings,
        invalidInputErrorMessage: "",
        isOutOfBoundsErrorMessage: "",
        isRequiredErrorMessage: "",
        isResetStatusMessage: "",
    };

    // #endregion Code Behind

    return (
        <DatePicker
            allowTextInput={true}
            calendarProps={props.calendarProps}
            className={getClasseNames()}
            disabled={isDisabled()}
            disableAutoFocus={true}
            formatDate={getDateFormat}
            label={props.displayName}
            onSelectDate={onSelectDate}
            parseDateFromString={onParseDateFromString}
            placeholder={props.placeholder}
            strings={strings}
            styles={props.styles}
            minDate={props.minDate}
            maxDate={props.maxDate}
            textField={{
                autoComplete: "off",
                errorMessage: getValidationMessage(),
                onRenderLabel: () => onRenderLabel(getValidationMessage() !== ""),
            }}
            value={internalDate}
        />
    );
});
