import { Modal } from "@fluentui/react";
import { Box } from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import { setPageTitle, ThemedEditText, ThemedText } from "Application";
import { setupQuotePlaceholderPlugin } from "Plugins/TinyMCE/QuotePlaceholders";
import { LetterTemplatesViewModel } from "./LetterTemplatesViewModel";
import { ButtonClusterSubView } from "./SubViews/ButtonClusterSubView";
import { ThemedError } from "Styles/ThemedPrimitives/Style1/ThemedError";
import { ThemedLoader } from "Styles/ThemedPrimitives/Style1/ThemedLoader";
import { PageContainer, NavigationContainer, TitleContainer, FormContainer, FormSubContainer } from "Views/Shared/SharedComponents";
import { DeleteLetterTemplatesView } from "./ModalViews/DeleteLetterTemplatesView";

export const LetterView: React.FC = observer(() => {
    // #region Code Behind

    const { id } = useParams();
    const [viewModel] = useState(() => new LetterTemplatesViewModel(id));

    useEffect(() => {
        setPageTitle("Letter template");
    }, []);

    // #endregion Code Behind

    const renderBusy = () => <ThemedLoader isOpen={viewModel.apiClient.IsBusy} />;

    const renderError = () => {
        const isOpen = viewModel.apiClient.IsSubmitted && !viewModel.apiClient.IsBusy && viewModel.apiClient.HaveValidationMessage;
        return <ThemedError command={viewModel.resetServerErrorCommand} isOpen={isOpen} errorMessage={viewModel.apiClient.ValidationMessage} />;
    };

    const renderDeleteModalContent = () => {
        return (
            <Modal isOpen={viewModel.canDisplayDelete} onDismiss={() => viewModel.closeDeleteModalCommand.execute()}>
                <DeleteLetterTemplatesView viewModel={viewModel} />
            </Modal>
        );
    };

    return (
        <PageContainer>
            {/* Navigation */}
            <NavigationContainer onClick={() => viewModel.navigateToListCommand.execute()}>
                <ThemedText>{"<"} BACK TO LETTER TEMPLATES</ThemedText>
            </NavigationContainer>

            {/* Title */}
            <TitleContainer>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <ThemedText fontStyle={"h2"}>{viewModel.displayName}</ThemedText>
                </Box>
            </TitleContainer>

            {/* Workspace */}
            <FormContainer>
                <FormSubContainer>
                    <Box mb="30px" maxWidth={"310px"} className="smallTitleField">
                        <ThemedEditText
                            command={viewModel.updateLetterTitleCommand}
                            displayName="Template Title*"
                            placeholder="Please enter a template title"
                            validationMessage={() => viewModel.getError("name")}
                            value={() => viewModel.getValue("name") ?? ""}
                        />
                    </Box>
                    <Editor
                        apiKey="g828m3lar5y087wnket2v38pbvjugg2m1091afqssqvjj8ze"
                        value={viewModel.getValue("body")}
                        init={{
                            height: 446,
                            menubar: false,
                            browser_spellcheck: true,
                            plugins:
                                "preview paste searchreplace autolink directionality visualblocks visualchars fullscreen link table charmap hr nonbreaking insertdatetime advlist lists wordcount imagetools textpattern noneditable charmap contextmenu code",
                            toolbar:
                                "undo redo | bold italic underline strikethrough | subscript superscript | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | fullscreen  preview code | quoteplaceholders",
                            font_formats:
                                "Arapey=arapey,serif; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino;HeadingNow 55 Medium=headingnow55medium, sans-serif; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
                            content_style:
                                "@import url('https://basearchitects.blob.core.windows.net/fonts/ArapeyRegularFontFace.css');@import url('https://basearchitects.blob.core.windows.net/fonts/HeadingNow55MediumFontFace.css');",
                            contextmenu: "undo redo | cut copy paste | bold italic underline | fontformats | fontsizes | quoteplaceholders",
                            setup: (editor) => {
                                setupQuotePlaceholderPlugin(editor);
                            },
                        }}
                        onEditorChange={viewModel.updateLetterText}
                    />
                </FormSubContainer>
                <ButtonClusterSubView viewModel={viewModel} />
            </FormContainer>

            {/* Busy Overlay */}
            {renderBusy()}

            {/* Error Overlay */}
            {renderError()}

            {/* Delete Modal */}
            {renderDeleteModalContent()}
        </PageContainer>
    );
});
