import { ViewModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

import { HalfDaysEventModel } from "./HalfDaysEventModel";
import { LeaveRequestEventViewModel } from "../LeaveRequestEvent/LeaveRequestEventViewModel";
import { NoEventViewModel } from "../NoEvent/NoEventViewModel";

export class HalfDaysEventViewModel extends ViewModelBase<HalfDaysEventModel> {
    public morningEventViewModel: NoEventViewModel | LeaveRequestEventViewModel | null = null;
    public afternoonEventViewModel: NoEventViewModel | LeaveRequestEventViewModel | null = null;

    constructor(model: HalfDaysEventModel = new HalfDaysEventModel()) {
        super(model, false);

        this.morningEventViewModel = new NoEventViewModel();
        this.afternoonEventViewModel = new NoEventViewModel();

        makeObservable(this, {
            // Obseravbles
            morningEventViewModel: observable,
            afternoonEventViewModel: observable,
        });
    }
}
