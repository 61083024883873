import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";
import moment from "moment";
import { QuoteTaskLiteModel } from "Views/Shared/Quote/QuoteTaskLiteModel";
import { ProjectTaskLiteModel } from "Views/Shared/Project/ProjectTaskLiteModel";
import { EnquiryLiteModel } from "Views/Shared/Enquiry/EnquiryLiteModel";
import { ProjectLiteModel } from "Views/Shared/Project/ProjectLiteModel";
import { QuoteLiteModel } from "Views/Shared/Quote/QuoteLiteModel";
import { container } from "tsyringe";
import { LookupStore } from "Stores/Domain";
import { AbsenteesModel } from "./Absentees/AbsenteesModel";

export class OverviewModel extends ModelBase<OverviewModel> {
    public lookupStore = container.resolve(LookupStore);
    //Programming Week filtering
    public startDateFilter: Date | null = null;
    public endDateFilter: Date | null = null;

    public absenteesModel: AbsenteesModel | null = null;

    public financialPeriodTypeId: string | null = null;

    public enquiryLites = observable<EnquiryLiteModel>([]);
    public quoteLites = observable<QuoteLiteModel>([]);
    public workWonItems = observable<QuoteLiteModel>([]);

    //represents a historical list of projects with the highest value
    //the total number of items is limited to prevent the UI from being given a very large data set
    public topProjectLites = observable<ProjectLiteModel>([]);
    //represents a historical list of quotes with the highest value
    //the total number of items is limited to prevent the UI from being given a very large data set
    public topQuoteLites = observable<QuoteLiteModel>([]);

    constructor() {
        super();
        makeObservable(this, {
            startDateFilter: observable,
            endDateFilter: observable,
            financialPeriodTypeId: observable,
        });
    }

    //region basic business logic

    // public get workWonItems() {
    //     return this.quoteLites.filter((q) => q.isProject && q.projectId);
    // }

    public get enquiriesBooked() {
        return this.enquiryLites.filter((e) => e.enquiryStatusId! == this.lookupStore.enquiryStatuses.find((es) => es.type == "Booked")?.id);
    }

    public get enquiriesAwaitingConfirmation() {
        return this.enquiryLites.filter((e) => e.enquiryStatusId! == this.lookupStore.enquiryStatuses.find((es) => es.type == "Awaiting Confirmation")?.id);
    }

    public enquiriesByMonth(enquiries: EnquiryLiteModel[], targetDate: Date) {
        return enquiries.filter((e) => e.createdDate?.getMonth() == targetDate.getMonth());
    }

    public enquiriesByYear(enquiries: EnquiryLiteModel[], targetDate: Date) {
        return enquiries.filter((e) => e.createdDate?.getFullYear() == targetDate.getFullYear());
    }

    public numberOfQuotesWonPerYear(targetDate: Date) {
        return this.workWonItems.filter((e) => e.quoteConvertedToProjectDate?.getFullYear() == targetDate.getFullYear());
    }

    public numberOfQuotesWonPerMonth(targetDate: Date) {
        return this.numberOfQuotesWonPerYear(targetDate).filter((e) => e.quoteConvertedToProjectDate?.getMonth() == targetDate.getMonth());
    }

    //end region basic business logic
}

export class OverviewValidator extends Validator<OverviewModel> {
    constructor() {
        super();
        this.ruleFor("startDateFilter").must({
            predicate: (startDateFilter, model) => startDateFilter! < model.endDateFilter!,
            message: (_value, _model) => "Start date must be before the end date",
        });
        this.ruleFor("endDateFilter").must({
            predicate: (endDateFilter, model) => endDateFilter! > model.startDateFilter!,
            message: (_value, _model) => "End date must be after the start date",
        });
    }
}
