import { IStyle, mergeStyleSets } from "@fluentui/react";
import React from "react";
import { observer } from "mobx-react-lite";

import { AutoGrid, theme, ThemedButton, ThemedComboBox, ThemedEditDate, ThemedEditText, ThemedPersonaComboBox, ThemedText, ThemedTransparentIconButton } from "Application";
import { CrossSVG } from "Assets/CrossSVG";
import { FormContainerRow, FormSeparator } from "Views/Shared/SharedComponents";
import { NewRequestViewModel } from "./NewRequestViewModel";

interface IProps {
    viewModel: NewRequestViewModel | null;
}

interface INewRequestViewStyles {
    root: IStyle;
    header: IStyle;
    body: IStyle;
    separator: IStyle;
    footer: IStyle;
    iconButton: IStyle;
}

const styleDefinitions: INewRequestViewStyles = {
    root: {
        width: "700px",
    },
    header: {
        alignItems: "center",
        backgroundColor: "#F3F3F3",
        display: "flex",
        height: "75px",
        justifyContent: "space-between",
        padding: "20px 30px",
    },
    body: {
        padding: "30px 30px 0 30px",
    },
    separator: {
        "&&": {
            lineHeight: "0px",
            margin: "0px 30px",
        },
    },
    footer: {
        display: "flex",
        height: "75px",
        padding: "20px 30px",
    },
    iconButton: {
        g: {
            stroke: theme.palette.defaultTr.text,
        },
    },
};

const labelStyle = mergeStyleSets({
    root: {
        fontWeight: 400,
    },
});

export const NewRequestView: React.FC<IProps> = observer((props) => {
    // #region Code Behind

    const viewModel = props.viewModel;
    const styles = mergeStyleSets(styleDefinitions);

    // #endregion Code Behind

    if (viewModel) {
        return (
            <div className={styles.root}>
                <div className={styles.header}>
                    <ThemedText fontStyle="h2">New Request</ThemedText>
                    <ThemedTransparentIconButton command={viewModel.cancelCommand} icon={<CrossSVG className={styles.iconButton} />} shape="round" />
                </div>
                <div className={styles.body}>
                    <FormContainerRow dc={"100%"}>
                        <AutoGrid m={"0"} dc={"1fr 1fr"}>
                            <ThemedPersonaComboBox
                                command={viewModel.updateUserCommand}
                                displayName="User*"
                                labelStyle={labelStyle}
                                options={viewModel.users}
                                placeholder="Please select"
                                validationMessage={() => viewModel.getError("userId")}
                                value={() => viewModel.getValue("userId")}
                            />
                            <ThemedComboBox
                                command={viewModel.updateLeaveTypeCommand}
                                displayName="Leave type*"
                                labelColor="default"
                                options={viewModel.leaveTypes}
                                placeholder="Please select"
                                validationMessage={() => viewModel.getError("leaveTypeId")}
                                value={() => viewModel.getValue("leaveTypeId")}
                            />
                        </AutoGrid>
                    </FormContainerRow>
                    <FormContainerRow dc={"100%"} mb={"0px !important"}>
                        <AutoGrid m={"0"} dc={"1fr 1fr"}>
                            <ThemedEditDate
                                command={viewModel.updateFromDateCommand}
                                displayName="From*"
                                size="larger"
                                validationMessage={() => viewModel.getError("fromDate")}
                                value={() => viewModel.getValue("fromDate")}
                            />
                            <ThemedEditDate
                                command={viewModel.updateToDateCommand}
                                displayName="To*"
                                size="larger"
                                validationMessage={() => viewModel.getError("toDate")}
                                value={() => viewModel.getValue("toDate")}
                            />
                        </AutoGrid>
                    </FormContainerRow>
                    <FormContainerRow dc={"100%"}>
                        <AutoGrid m={"0"} dc={"1fr 1fr"}>
                            <ThemedComboBox
                                command={viewModel.updateFromDayLeaveTypeCommand}
                                displayName="From time of day*"
                                labelColor="default"
                                options={viewModel.fromDateDayTypes}
                                placeholder="Please select"
                                validationMessage={() => viewModel.getError("fromDateLeaveDayTypeId")}
                                value={() => viewModel.getValue("fromDateLeaveDayTypeId")}
                            />
                            <ThemedComboBox
                                command={viewModel.updateToDayLeaveTypeCommand}
                                displayName="To time of day*"
                                labelColor="default"
                                options={viewModel.toDateDayTypes}
                                placeholder="Please select"
                                validationMessage={() => viewModel.getError("toDateLeaveDayTypeId")}
                                value={() => viewModel.getValue("toDateLeaveDayTypeId")}
                            />
                        </AutoGrid>
                    </FormContainerRow>
                    <FormContainerRow dc={"100%"}>
                        <ThemedEditText
                            displayName="Additional information:"
                            command={viewModel.updateRequestReasonCommand}
                            isMultiline
                            placeholder={"Please enter some information"}
                            value={() => viewModel.getValue("requestReason") ?? ""}
                            validationMessage={() => viewModel.getError("requestReason")}
                        />
                    </FormContainerRow>
                </div>
                <FormSeparator className={styles.separator} />
                <AutoGrid m={0} className={styles.footer} columnGap={"30px"} dc={"135px 135px"}>
                    <ThemedButton styles={{ root: { padding: "0 5px" } }} paletteColor={"primary"} displayName="Add" command={viewModel.submitCommand} />
                    <ThemedButton styles={{ root: { padding: "0 5px" } }} displayName="Cancel" command={viewModel.cancelCommand} />
                </AutoGrid>
            </div>
        );
    }

    return <React.Fragment />;
});
