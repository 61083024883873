import { runInAction } from "mobx";
import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import CustomerDetailViewModel from "./CustomerDetailViewModel";

class Response {}

export class DeleteCustomerEndpoint extends EndpointWithoutRequest<Response> {
    private readonly viewModel: any;

    constructor(id: string, viewModel: CustomerDetailViewModel) {
        super();
        this.verb(Http.Delete);
        this.path(AppUrls.Server.Customers.SoftDeleteCustomerById.replace(":id", id));
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        runInAction(() => {
            this.viewModel.history.replace(AppUrls.Client.Customers.Table);
        });
    }
}
