import { ModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

export class EffortEventModel extends ModelBase<EffortEventModel> {
    public date: Date;
    public programmedEffort: number;
    public availableEffort: number;

    constructor(date: Date, programmedEffort: number, availableEffort: number) {
        super();

        this.date = date;
        this.programmedEffort = programmedEffort;
        this.availableEffort = availableEffort;

        makeObservable(this, {});
    }
}
