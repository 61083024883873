import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { nanoid } from "nanoid";
import { Validator } from "Application/Validation";

export class PersonaFileModel extends ModelBase<PersonaFileModel> {
    public KEY: string = nanoid();

    public id: string | null = null;

    public file: File | null = null;
    public fileName: string = "";
    public fileSizeBytes: number = 0;
    public mimeType: string = "";
    public documentUrl: string = "";

    constructor() {
        super();
        makeObservable(this, {
            // Observables
            id: observable,
            file: observable,
            fileName: observable,
            fileSizeBytes: observable,
            mimeType: observable,
            documentUrl: observable,
        });
    }

    // #region Business Logic

    static readonly MAX_FILESIZE_BYTES = 1024 * 1024;
    static readonly MAX_FILESIZE_MB = "1MB";

    static isInsideUploadLimit(file: File) {
        if (file) {
            return file.size < this.MAX_FILESIZE_BYTES ? true : false;
        }
        return false;
    }

    public get isWithinUploadLimit(): boolean {
        if (this.file !== null) {
            return PersonaFileModel.isInsideUploadLimit(this.file);
        }
        return true;
    }

    // #endregion Business Logic

    // #region Helpers

    public copy = (excludeKey: boolean = false): PersonaFileModel => {
        const model = new PersonaFileModel();

        model.KEY = excludeKey ? model.KEY : this.KEY;
        model.id = this.id;
        model.file = this.file;
        model.fileName = this.fileName;
        model.fileSizeBytes = this.fileSizeBytes;
        model.mimeType = this.mimeType;
        model.documentUrl = this.documentUrl;

        return model;
    };

    // #endregion Helpers
}

export class PersonaFileModelValidator extends Validator<PersonaFileModel> {
    constructor() {
        super();

        this.ruleFor("file").must({
            predicate: (file) => PersonaFileModel.isInsideUploadLimit(file!),
            message: () => `The file size should be less than ${PersonaFileModel.MAX_FILESIZE_MB}`,
        });
    }
}
