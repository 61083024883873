import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { DeleteLeaveRequestModel } from "../DeleteLeaveRequestModel";

class Request {
    leaveRequestId: string | null = null;
}

class Response {}

export class POSTDeleteLeaveEndpoint extends Endpoint<Request, Response> {
    constructor() {
        super();

        this.verb(Http.Post);
        this.path(AppUrls.Server.Admin.Leave.DeleteLeaveRequest);
    }

    public async HandleRequestAsync(model: DeleteLeaveRequestModel): Promise<Request> {
        const request = new Request();

        request.leaveRequestId = model.leaveRequest.id;

        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);
    }
}
