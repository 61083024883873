import { IColumn, PersonaSize } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { Box, setPageTitle, ThemedButton, ThemedEditText, formatDate } from "Application";
import { SearchIcon } from "Assets/SearchIcon";
import { ThemedDataTable } from "Styles/ThemedPrimitives/Style1/ThemedDataTable";
import { ThemedPersona } from "Styles/ThemedPrimitives/Style1/ThemedPersona";
import { UserItemLiteViewModel } from "./UserItemLiteViewModel";
import { UsersViewModel } from "./UsersViewModel";

const ThemedButtonStyles = {
    root: {
        height: 30,
        width: 147,
        fontSize: 17,
    },
};

const controlStyles = {
    root: {
        width: "610px",
        height: "39px",
        fontSize: "17px",
        borderColor: "#E1E1E1",
    },
};

export const UsersView: React.FC = observer(() => {
    // #region Code Behind

    const [viewModel] = useState(() => new UsersViewModel());

    useEffect(() => {
        setPageTitle("Users");
    }, []);

    /**
     * Handles updating the tables column sort settings in response to changes in the viewmodel.
     */
    useEffect(() => {
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter((currCol) => viewModel.getValue("sortKey") === currCol.key)[0];

        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSorted = true;
                currColumn.isSortedDescending = viewModel.getValue("sortDescending");
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = false;
            }
        });

        setColumns(newColumns);
    }, [viewModel.getValue("sortKey"), viewModel.getValue("sortDescending")]);

    /**
     * Gets a key for the row. Must be provided if sorting or filtering is enabled.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The row index.
     *
     * @returns A string key to identify the row.
     */
    const getRowKey = (item: UserItemLiteViewModel, index?: number): string => {
        return item.KEY;
    };

    const onRenderUserName = (item: UserItemLiteViewModel, index?: number, column?: IColumn) => {
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <ThemedPersona
                    styles={{ primaryText: { fontSize: "10px" } }}
                    displayName={`${item.firstName} ${item.lastName}`}
                    size={PersonaSize.size32}
                    imageUrl={item.documentUrl}
                />
            </div>
        );
    };

    const onRenderEmail = (item: UserItemLiteViewModel, index?: number, column?: IColumn) => {
        return <div>{item.emailAddress}</div>;
    };

    const onRenderPermissions = (item: UserItemLiteViewModel, index?: number, column?: IColumn) => {
        return <div>{item.userRole}</div>;
    };

    const onRenderLastAction = (item: UserItemLiteViewModel, index?: number, column?: IColumn) => {
        return (
            <Box>
                <Box fontSize={"10px"}>{item.lastActionDate != null ? formatDate(item.lastActionDate) : ""}</Box>
                <Box fontSize={"10px"}>{item.lastActionLabel}</Box>
            </Box>
        );
    };

    /**
     * Handles an onClick event for the table column header.
     *
     * @param ev Mouse event
     * @param column The column that has been clicked.
     */
    const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const currColumn: IColumn = columns.filter((currCol) => column.key === currCol.key)[0];
        const newSortKey = currColumn.key;
        const newSortDescending = currColumn.key === viewModel.getValue("sortKey") ? !currColumn.isSortedDescending : false;

        viewModel.updateSortCommand.execute(newSortKey, newSortDescending);
    };

    /**
     * Handler an onClick event for a table row.
     *
     * @param item The viewmodel data associated with the table row.
     */
    const onRowClick = (item: UserItemLiteViewModel, index?: number, ev?: React.FocusEvent<HTMLElement>) => {
        viewModel.navigateToEditForm.execute(item);
    };

    const defaultTableColumns: IColumn[] = [
        {
            fieldName: "firstName",
            key: "firstName",
            name: "USER",
            minWidth: 300,
            maxWidth: 486,
            isResizable: true,
            isSorted: true,
            isSortedDescending: false,
            onColumnClick: onColumnClick,
            onRender: onRenderUserName,
        },
        {
            fieldName: "emailAddress",
            key: "emailAddress",
            name: "EMAIL ADDRESS",
            minWidth: 300,
            maxWidth: 486,
            isResizable: true,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: onRenderEmail,
        },
        {
            fieldName: "userRole",
            key: "userRole",
            name: "PERMISSION",
            minWidth: 280,
            maxWidth: 280,
            isResizable: true,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: onRenderPermissions,
        },
        {
            fieldName: "lastActionDate",
            key: "lastActionDate",
            name: "LAST ACTION",
            minWidth: 280,
            maxWidth: 280,
            isResizable: true,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: onRenderLastAction,
        },
    ];

    const [columns, setColumns] = useState<IColumn[]>(defaultTableColumns);

    // #endregion Code Behind

    return (
        <Box>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                <Box display={"flex"}>
                    <ThemedEditText
                        command={viewModel.updateFilterKeywordCommand}
                        placeholder={"Keyword search"}
                        prefix={<SearchIcon />}
                        styles={controlStyles}
                        value={() => viewModel.getValue("filterKeyword")}
                    />
                </Box>
                <ThemedButton marginTop={3} paletteColor="primary" command={viewModel.navigateToNewForm} displayName={"New User"} styles={ThemedButtonStyles} />
            </Box>
            <ThemedDataTable getKey={getRowKey} items={viewModel.filteredAndSortedUsers} columns={columns} onActiveItemChanged={(item) => onRowClick(item)} />
        </Box>
    );
});
