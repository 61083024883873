import { SVGProps } from "react";

export const SmallPhoneIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={12} height={18} viewBox="0 0 12 18" {...props}>
        <g id="Group_7060" data-name="Group 7060" transform="translate(575 -831) rotate(90)">
            <g id="Rectangle_1857" data-name="Rectangle 1857" transform="translate(831 563)" fill="none" stroke="#ff4b22" strokeWidth={1}>
                <rect width={18} height={12} rx={1} stroke="none" />
                <rect x={0.5} y={0.5} width={17} height={11} rx={0.5} fill="none" />
            </g>
            <g
                id="Rectangle_1902"
                data-name="Rectangle 1902"
                transform="translate(846 568)"
                fill="#fff"
                stroke="#ff4b22"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1}
            >
                <rect width={1} height={2} stroke="ff4b22" />
                <rect x={0.5} y={0.5} height={1} fill="ff4b22" />
            </g>
        </g>
    </svg>
);
