import { mergeStyleSets, PersonaSize, ProgressIndicator } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React from "react";

import { Box, Nullcommand, theme, ThemedButton, ThemedText } from "Application";
import { ThemedPersona } from "Styles/ThemedPrimitives/Style1/ThemedPersona";
import { StaffItemViewModel } from "./StaffItemViewModel";
import styled from "@emotion/styled";
import { PersonSVG } from "Assets/Icons/PersonSVG";
import { StaffViewModel } from "./StaffViewModel";
import { CalendarToggleButton } from "Views/Shared/SharedComponents";
import { CalendarToggleIcon } from "Assets/Icons/CalendarToggleIcon";

const StyleResetButton = styled(ThemedButton)`
    background-color: transparent;
    border: none;
    padding: 0px;
    &:hover {
        background-color: transparent;
        border: none;
    }

    &:focus {
        background-color: transparent;
    }
`;

const PersonaButton = styled(StyleResetButton)`
    height: 48px;
    display: flex;
    align-items: center;
`;

const StaffItemWorkWeekButton = styled.button`
    padding: 5px;
    height: 30px;
    border-radius: 50px;

    border: none;

    &:hover,
    &:focus {
        cursor: pointer;
        opacity: 0.8;
        color: #f1f1f1;
    }
`;

const StaffBlock = styled.div`
    width: 20%;
    padding: 8px 8px 8px 0px;
    border-right: 1px solid #000;
    height: auto;
`;

const StaffInnerBlock = styled.div`
    display: grid;
    grid-template-columns: 75% 25%;
    align-items: center;
    background: #f1f1f1;
    border-radius: 150px;

    height: 48px;
    overflow: hidden;
    padding-right: 10px;
`;

export interface IRevisionViewProps {
    viewModel: StaffItemViewModel;
    parentViewModel: StaffViewModel;
    maximumPercentage: number;
}

export const StaffItemView: React.FC<IRevisionViewProps> = observer((props) => {
    const viewModel = props.viewModel;

    const styles = mergeStyleSets({
        root: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",

            ".ms-TooltipHost": {
                fontSize: "12px",
                letterSpacing: "0.36px",
                color: "#343A40",
            },
            ".ms-Persona-imageArea": {
                marginRight: "10px",
            },
            "& > div:nth-child(2)": {
                width: "80%",
                height: "auto",
                padding: "8px 0 8px 7px",
                position: "relative",
            },
            "& > div:nth-child(2) > div > div": {
                width: "100%",
                padding: "2px 0",
                height: "auto",
                position: "relative",
            },
            "& > div:nth-child(2) > div > div > span": {
                position: "absolute",
                color: theme.palette.common.default,
                fontSize: "10px",
                lineHeight: "12px",
                padding: "0 16px",
                zIndex: 1,
                top: "7px",
            },
            "& > div:nth-child(2) .maxPercentLine": {
                position: "absolute",
                // borderRight: "1px dotted #00000033",
                backgroundColor: "transparent",
                bottom: "0",
                top: "0",
            },
            ".ms-ProgressIndicator": {
                background: "#f3f3f3",
                borderRadius: "24px",
            },
            ".ms-ProgressIndicator-itemProgress": {
                padding: 0,
                borderRadius: "24px",
            },
            ".ms-ProgressIndicator-progressTrack": {
                background: "#f3f3f3",
            },
            ".ms-ProgressIndicator-progressBar": {
                borderRadius: "24px",
            },
            ".ms-Persona-initials": {
                height: "48px",
                width: "48px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            },
        },
    });

    const getEffortItemProgress = () => {
        return props.viewModel.effortPercentageAllocation / props.maximumPercentage;
    };

    const getChargeableEffortItemProgress = () => {
        return props.viewModel.chargeableEffortPercentageAllocation / props.maximumPercentage;
    };

    const get100PercentProgress = () => {
        return (100 * 100) / props.maximumPercentage;
    };

    const canDisplay100PercentProgress = () => {
        return props.maximumPercentage > 100;
    };

    return (
        <div className={styles.root}>
            <StaffBlock>
                <StaffInnerBlock>
                    <PersonaButton
                        icon={<ThemedPersona displayName={viewModel.userFullName} size={PersonaSize.size48} imageUrl={viewModel.userDocumentUrl} />}
                        command={props.viewModel.navigateToStaffResourceDetailsCommand}
                    />

                    {props.parentViewModel.showStaffWorkWeekCalendar && (
                        <StaffItemWorkWeekButton
                            onClick={() => {
                                props.parentViewModel.openMyWorkWeekOverviewCommand.execute("right", "80%", "calc(100% - 380px)", viewModel.model.userId);
                            }}
                        >
                            <CalendarToggleIcon hideToggle opened={props.parentViewModel.displayHorizontalMyWorkWeekOverview} />
                        </StaffItemWorkWeekButton>
                    )}
                </StaffInnerBlock>
            </StaffBlock>
            <Box>
                <Box>
                    <Box>
                        <span>{`${viewModel.effortHoursAllocation} out of ${viewModel.businessHours} staff hours programmed `}</span>
                        <ProgressIndicator
                            styles={{
                                progressBar: {
                                    backgroundColor:
                                        viewModel.effortPercentageAllocation > 100 || (viewModel.effortPercentageAllocation !== 0 && viewModel.businessHours === 0)
                                            ? "#FEC97A"
                                            : "#96D8A3",
                                },
                            }}
                            percentComplete={getEffortItemProgress()}
                            barHeight={20}
                        />
                    </Box>
                    <Box>
                        <span>{`${viewModel.chargeableEffortHoursAllocation} out of ${viewModel.chargeableBusinessHours} hours of chargeable time programmed `}</span>
                        <ProgressIndicator
                            styles={{
                                progressBar: {
                                    backgroundColor:
                                        viewModel.chargeableEffortPercentageAllocation > 100 ||
                                        (viewModel.chargeableEffortPercentageAllocation !== 0 && viewModel.chargeableBusinessHours === 0)
                                            ? "#96D8A3"
                                            : "#FEC97A",
                                },
                            }}
                            percentComplete={getChargeableEffortItemProgress()}
                            barHeight={20}
                        />
                    </Box>
                </Box>
                {canDisplay100PercentProgress() && <Box className="maxPercentLine" width={`calc(${get100PercentProgress()}% - ${(get100PercentProgress() * 7) / 100}px)`} />}
            </Box>
        </div>
    );
});
