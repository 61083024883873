import { observer } from "mobx-react-lite";
import { useParams } from "react-router";
import React, { useEffect, useState } from "react";

import { Modal } from "@fluentui/react";
import { Box, Else, setPageTitle, Show, ThemedButton, ThemedText } from "Application";
import { EnquiryViewModel } from "./EnquiryViewModel";
import { EnquiryStatus, FormContainer, FormSubContainer, NavigationContainer, PageContainer, TitleContainer } from "Views/Shared/SharedComponents";
import { CustomerView } from "Views/Shared/Customer/CustomerView";
import { NotesView } from "Views/Shared/Note/NotesView";
import { ButtonClusterSubView } from "./SubViews/ButtonClusterSubView";
import { EditIcon } from "Assets/Icons/EditIcon";
import { CustomerReadOnlyView } from "Views/Shared/Customer/CustomerReadOnlyView";
import { EnquirySubView } from "./SubViews/EnquirySubView/EnquirySubView";
import { EnquirySubReadOnlyView } from "./SubViews/EnquirySubView/EnquirySubReadOnlyView";
import { EnquirySubTaskView } from "./SubViews/EnquirySubView/EnquirySubTaskView";
import { ThemedLoader } from "Styles/ThemedPrimitives/Style1/ThemedLoader";
import { ThemedError } from "Styles/ThemedPrimitives/Style1/ThemedError";
import { NotesReadOnlyView } from "Views/Shared/Note/NotesReadOnlyView";
import { ConvertToQuoteView } from "./ModalViews/ConvertToQuote/ConvertToQuoteView";
import { SubmitWithNoteView } from "./SubViews/SubmitNoteView/SubmitWithNoteView";
import { ConvertToEnquiryView } from "./ModalViews/ConvertToEnquiry/ConvertToEnquiryView";
import { LostWithNoteView } from "./SubViews/LostNoteView/LostWithNoteView";

export const EnquiryView: React.FC = observer(() => {
    // #region Code Behind

    const { id, customerId } = useParams();
    const [viewModel] = useState(() => new EnquiryViewModel(id, customerId));

    useEffect(() => {
        setPageTitle("Enquiry");
    }, []);

    // #endregion Code Behind

    const renderSubmitWithNoteModalContent = () => {
        return (
            <Modal isOpen={viewModel.canDisplaySubmitWithNote} onDismiss={() => viewModel.cancelSubmitWithNoteCommand.execute()}>
                <SubmitWithNoteView viewModel={viewModel.submitWithNoteViewModel!} />
            </Modal>
        );
    };

    const renderLostWithNoteModalContent = () => {
        return (
            <Modal isOpen={viewModel.canDisplayLostWithNote} onDismiss={() => viewModel.cancelMarkEnquiryAsLostCommand.execute()}>
                <LostWithNoteView title={"Lost enquiry"} message={"Please select a reason why this enquiry has been lost."} viewModel={viewModel.lostWithNoteViewModel!} />
            </Modal>
        );
    };

    const renderEnquiryStatus = () => {
        return viewModel.canDisplayEnquiryStatus ? (
            <EnquiryStatus style={{ backgroundColor: viewModel.enquiryStatusBackgroundColor }}>{viewModel.enquiryStatusDisplayName}</EnquiryStatus>
        ) : null;
    };

    const renderBusy = () => <ThemedLoader isOpen={viewModel.apiClient.IsBusy} />;

    const renderError = () => {
        const isOpen = viewModel.apiClient.IsSubmitted && !viewModel.apiClient.IsBusy && viewModel.apiClient.HaveValidationMessage;

        return <ThemedError command={viewModel.resetServerErrorCommand} isOpen={isOpen} errorMessage={viewModel.apiClient.ValidationMessage} />;
    };

    const renderConvertToQuoteModalContent = () => {
        return (
            <Modal isOpen={viewModel.canDisplayConvertToQuote} onDismiss={() => viewModel.cancelConvertToQuoteCommand.execute()}>
                <ConvertToQuoteView viewModel={viewModel.convertToQuoteViewModel!} />
            </Modal>
        );
    };

    const renderConvertToEnquiryModalContent = () => {
        return (
            <Modal isOpen={viewModel.canDisplayConvertToEnquiry} onDismiss={() => viewModel.cancelConvertToEnquiryCommand.execute()}>
                <ConvertToEnquiryView viewModel={viewModel.convertToEnquiryViewModel!} />
            </Modal>
        );
    };

    return (
        <PageContainer>
            {/* Navigation and Enquiry Status */}
            <NavigationContainer onClick={() => viewModel.navigateToEnquiriesCommand.execute()}>
                <ThemedText>{"<"} BACK TO ENQUIRIES</ThemedText>
                {renderEnquiryStatus()}
            </NavigationContainer>

            {/* Title */}
            <TitleContainer style={{ display: "flex", minHeight: "74px", padding: "15px 30px" }}>
                <Box display="flex" flex="1" justifyContent="space-between" alignItems="center">
                    <Box>
                        <Box>
                            <ThemedText fontStyle={"h2"}>{viewModel.displayName}</ThemedText>
                            {viewModel.canDisplayEnquiryType && (
                                <ThemedText fontStyle={"h4"} mt="-3px">
                                    {viewModel.enquiryTypeDisplayName}
                                </ThemedText>
                            )}
                        </Box>
                    </Box>
                    {viewModel.enterEditModeCommand.canExecute() && (
                        <Box>
                            <ThemedButton
                                marginBottom="-3px"
                                className="editicon"
                                paletteColor={"primary"}
                                displayName="EDIT ENQUIRY"
                                icon={<EditIcon />}
                                command={viewModel.enterEditModeCommand}
                            />
                        </Box>
                    )}
                </Box>
            </TitleContainer>

            {/* Workspace */}
            <FormContainer>
                <FormSubContainer>
                    <Show if={viewModel.hasEditPermission}>
                        <Show if={viewModel.canDisplayInEditMode}>
                            <EnquirySubView viewModel={viewModel.enquirySubViewModel} />
                            <CustomerView viewModel={viewModel.customerViewModel} />
                            <Else>
                                <EnquirySubReadOnlyView viewModel={viewModel.enquirySubViewModel} />
                                <CustomerReadOnlyView viewModel={viewModel.customerViewModel} />
                            </Else>
                        </Show>
                        <Show if={viewModel.canDisplayNotes}>
                            <NotesView viewModel={viewModel.notesViewModel} />
                        </Show>
                        <Else>
                            <EnquirySubReadOnlyView viewModel={viewModel.enquirySubViewModel} />
                            <CustomerReadOnlyView viewModel={viewModel.customerViewModel} />
                            <Show if={viewModel.canDisplayNotes}>
                                <NotesReadOnlyView viewModel={viewModel.notesViewModel} />
                            </Show>
                        </Else>
                    </Show>
                </FormSubContainer>

                <ButtonClusterSubView viewModel={viewModel} />
            </FormContainer>

            {/* Submit modal */}
            {renderSubmitWithNoteModalContent()}

            {/* Lost modal */}
            {renderLostWithNoteModalContent()}

            {/* convertToQuote modal */}
            {renderConvertToQuoteModalContent()}

            {/* convertToEnquiry modal */}
            {renderConvertToEnquiryModalContent()}

            {/* Busy Overlay */}
            {renderBusy()}

            {/* Error Overlay */}
            {renderError()}
        </PageContainer>
    );
});
