import * as Utils from "@shoothill/core";
import { BaseStore, CoreStoreInstance, isNullOrEmpty } from "@shoothill/core";
import { Logger } from "index";

import { action, makeObservable, observable } from "mobx";
import { Stores } from "../Stores";
import { InitialState } from "Application/Models";
import { singleton } from "tsyringe";
import { InitViewModel } from "../../Application/ViewModels/InitViewModel";
import { GlobalHistory } from "../../index";
import { UserData } from "Application/Models/Domain/UserData";

export interface IAccountStore {
    init(stores: Stores, initialState: InitialState): Promise<void>;
    setIsLoggedIn(state: boolean): void;
    isInRole(role: string): boolean;
    Logout(redirect: boolean): Promise<void>;
    Logout(redirect: boolean): Promise<void>;
    getJwt(): string;
    getLoginState(jwt: string): void;
}

@singleton()
export class AccountStore extends BaseStore implements IAccountStore {
    public IsLoggedIn: boolean = false;
    public UserName: string = "";
    public DisplayName: string = "";
    public LoggedUserFirstName: string = "";
    public LoggedUserImageUrl: string = "";
    public UserRoles: string[] = [];
    public initViewModel = new InitViewModel();
    public userData = new UserData();
    private jwt: string | null = null;
    private refreshToken: string | null = null;
    private refreshTokenExpiryDate: Date | null = null;
    private tokenViewModel = {};

    public constructor() {
        super();
        Logger.logDebug("Account store created");
        makeObservable(this, {
            IsLoggedIn: observable,
            UserName: observable,
            DisplayName: observable,
            LoggedUserFirstName: observable,
            LoggedUserImageUrl: observable,
            UserRoles: observable,
            userData: observable,
            setIsLoggedIn: action,
            Logout: action,
            getLoginState: action,
        });
    }

    //This gets called once from the Stores.ts file. We make sure this is called first as other stores depend on the login state
    public async init() {
        Logger.logDebug("Init account store");
        await this.initViewModel.getInitialState();
        await this.getLoginState(this.initViewModel.InitData.accountStatus.jwt as string);
    }

    public get initialState() {
        return this.initViewModel.InitData;
    }

    public setIsLoggedIn(state: boolean) {
        this.IsLoggedIn = state;
        CoreStoreInstance.SetLoggedIn(state);
    }

    public isInRole = (role: string): boolean => {
        if (this.UserRoles && this.UserRoles.length > 0) {
            return this.UserRoles.includes(role);
        }
        return false;
    };

    public get canViewInvoicingForecast() {
        return this.userData.isInvoicingForecastVisible;
    }

    public Logout = async (redirect: boolean = false): Promise<void> => {
        await Utils.deleteJWT();
        document.cookie = ".refreshtoken= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
        this.setIsLoggedIn(false);
        this.UserName = "";
        this.DisplayName = "";
        this.LoggedUserFirstName = "";
        this.LoggedUserImageUrl = "";
        CoreStoreInstance.SetDisplayName("");
        this.UserRoles = [];
        this.userData = new UserData();
        localStorage.clear();
        if (redirect) {
            window.location.href = "/account/login";
        } else {
            GlobalHistory.push("/account/login");
        }
    };

    public getJwt = (): string => {
        return this.jwt as string;
    };

    public getLoginState = async (jwt: string | null) => {
        jwt = jwt ?? "";
        if (!jwt || jwt.length === 0) {
            this.jwt = await Utils.getJWT();
            //this.refreshToken = localStorage.getItem(".refreshToken");
            //this.refreshTokenExpiryDate = new Date(localStorage.getItem(".refreshTokenExpiryDate") as string);
        } else {
            this.jwt = jwt;
        }

        if (this.jwt && this.jwt !== "undefined" && this.jwt !== "null") {
            const data = Utils.parseJwt(this.jwt);

            await Utils.setJWT(this.jwt);
            if (data === "") {
                return;
            }
            //localStorage.setItem(".refreshToken", apiResult?.refreshToken ?? "");
            //localStorage.setItem(".refreshTokenExpiryDate", apiResult?.refreshTokenExpiryDate ?? new Date());
            this.setIsLoggedIn(true);
            this.UserName = data["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"] as string;
            this.DisplayName = data["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"] as string;
            this.UserRoles = [].concat(data["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] || []);
            this.LoggedUserFirstName = data["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname"] as string;
            this.LoggedUserImageUrl = data["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/uri"] as string;
            const userDataJSON: string = data["http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata"] as string;
            this.userData = JSON.parse(userDataJSON);

            CoreStoreInstance.SetDisplayName(this.DisplayName);
            // EN: For Debugging
            //(window as any).jwt = this.jwt;
            Logger.logDebug("Getloginstate finished", { jwt });
        }
    };
}
