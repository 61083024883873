import { ViewMode } from "gantt-task-react";
import React from "react";
import { observer } from "mobx-react-lite";

import { Box, ThemedText } from "Application";
import { ViewModeTabbed } from "./GanttChartStyles";

interface Props {
    viewMode: ViewMode;
    onViewModelChange: (viewModel: ViewMode) => void;
}

export const ViewModeBar: React.FC<Props> = observer((props: Props) => {
    // #region Code Behind
    // #endregion Code Behind

    return (
        <ViewModeTabbed>
            <Box className={props.viewMode === ViewMode.Day ? "activeTab" : ""} onClick={() => props.onViewModelChange(ViewMode.Day)}>
                <ThemedText fontStyle={"h2"}>Day</ThemedText>
            </Box>
            <Box className={props.viewMode === ViewMode.Week ? "activeTab" : ""} onClick={() => props.onViewModelChange(ViewMode.Week)}>
                <ThemedText fontStyle={"h2"}>Week</ThemedText>
            </Box>
            <Box className={props.viewMode === ViewMode.Month ? "activeTab" : ""} onClick={() => props.onViewModelChange(ViewMode.Month)}>
                <ThemedText fontStyle={"h2"}>Month</ThemedText>
            </Box>
            <Box className={props.viewMode === ViewMode.Year ? "activeTab" : ""} onClick={() => props.onViewModelChange(ViewMode.Year)}>
                <ThemedText fontStyle={"h2"}>Year</ThemedText>
            </Box>
        </ViewModeTabbed>
    );
});
