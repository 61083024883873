import { ViewModelBase } from "@shoothill/core";
import { RelayCommand } from "Application";
import { makeObservable } from "mobx";
import { LeaveCalendarViewModel } from "Views/Leave/Admin/Calendar/LeaveCalendarViewModel";

import { LeaveStatusType } from "Views/Leave/Shared/LeaveStatusType";
import { LeaveRequestEventModel } from "./LeaveRequestEventModel";
import moment from "moment";

export class LeaveRequestEventViewModel extends ViewModelBase<LeaveRequestEventModel> {
    private parentLeaveCalendarViewModel: LeaveCalendarViewModel;

    constructor(model: LeaveRequestEventModel, leaveCalendarViewModel: LeaveCalendarViewModel) {
        super(model, false);

        this.parentLeaveCalendarViewModel = leaveCalendarViewModel;

        makeObservable(this, {});
    }

    //#region Properties

    public get displayName() {
        return this.model.leaveRequest?.leaveType?.name ?? "";
    }

    public get displayStatusName() {
        return this.model.leaveRequest?.leaveStatusType?.name ?? "";
    }

    public get displayNumberOFDays() {
        return this.model.leaveRequest?.numberOfDays ? `(${Math.abs(this.model.leaveRequest?.numberOfDays)} days leave)` : "";
    }

    public get backgroundColor() {
        return this.model.leaveRequest?.leaveType?.backgroundColor ?? "#dff6dd";
    }

    public get requestedDate() {
        return this.model.leaveRequest?.requestDate ? moment(this.model.leaveRequest?.requestDate).format("DD MM YY") : "";
    }

    public get requestReason() {
        return this.model.leaveRequest?.requestReason ?? "Not given";
    }

    public get responseDate() {
        return this.model.leaveRequest?.responseDate ? moment(this.model.leaveRequest?.responseDate).format("DD MM YY") : "";
    }

    public get responseUserFullName() {
        return this.model.leaveRequest?.responseUserFullName;
    }

    public get isPendingRequest() {
        return this.model.leaveRequest?.leaveStatusType?.type === LeaveStatusType.PENDING;
    }

    public get isApprovedRequest() {
        return this.model.leaveRequest?.leaveStatusType?.type === LeaveStatusType.APPROVED;
    }

    // #endregion Properties

    // #region Commands

    public approveLeaveCommand = new RelayCommand(
        () => this.parentLeaveCalendarViewModel.displayApproveRequestCommand.execute(this.model.leaveRequest),
        () => this.parentLeaveCalendarViewModel.displayApproveRequestCommand.canExecute() && this.model.canApprove,
    );

    public declineLeaveCommand = new RelayCommand(
        () => this.parentLeaveCalendarViewModel.displayDeclineRequestCommand.execute(this.model.leaveRequest),
        () => this.parentLeaveCalendarViewModel.displayDeclineRequestCommand.canExecute() && this.model.canDecline,
    );

    public editLeaveCommand = new RelayCommand(
        () => this.parentLeaveCalendarViewModel.displayEditRequestCommand.execute(this.model.leaveRequest),
        () => this.parentLeaveCalendarViewModel.displayEditRequestCommand.canExecute() && this.model.canEdit,
    );

    public deleteLeaveCommand = new RelayCommand(
        () => this.parentLeaveCalendarViewModel.displayDeleteRequestCommand.execute(this.model.leaveRequest),
        () => this.parentLeaveCalendarViewModel.displayDeleteRequestCommand.canExecute() && this.model.canDelete,
    );

    public cancelLeaveCommand = new RelayCommand(
        () => this.parentLeaveCalendarViewModel.displayCancelRequestCommand.execute(this.model.leaveRequest),
        () => this.parentLeaveCalendarViewModel.displayCancelRequestCommand.canExecute() && this.model.canCancel,
    );

    // #endregion Commands
}
