import { debounce } from "@mui/material";
import { isEmptyOrWhitespace, isNullOrUndefined, ViewModelBase } from "@shoothill/core";
import { action, computed, makeObservable, observable, observe } from "mobx";

import { APIClient, formatDate, RelayCommand } from "Application";
import { MODELID_CURRENTPROJECTUPDATE, SERVER_DEBOUNCE_PERIOD_MS } from "Views/Shared/Constants";

import { WeeklyUpdateFormViewModel } from "./Form/WeeklyUpdateFormViewModel";
import { WeeklyUpdateHistoryViewModel } from "./History/WeeklyUpdateHistoryViewModel";
import { ProjectWeeklyUpdateContainerModel } from "./ProjectWeeklyUpdateContainerModel";
import { POSTSaveProjectWeeklyUpdateEndpoint } from "./POSTSaveProjectWeeklyUpdateEndpoint";
import { GETProjectWeeklyUpdateByUpdateIdEndpoint } from "./GETProjectWeeklyUpdateByUpdateIdEndpoint";
import { ProjectViewModel } from "../ProjectViewModel";
import { GETProjectWeeklyUpdateWithRelatedByIdEndpoint } from "./GETProjectWeeklyUpdateWithRelatedByIdEndpoint";

export class ProjectWeeklyUpdateContainerViewModel extends ViewModelBase<ProjectWeeklyUpdateContainerModel> {
    public apiClient = new APIClient();

    // Hosted in its own viewmodel.
    public parentViewModel: ProjectViewModel;
    public weeklyUpdateFormViewModel: WeeklyUpdateFormViewModel;
    // public previousWeeklyUpdateFormViewModel: WeeklyUpdateFormViewModel;
    public weeklyUpdateHistoryViewModels = observable<WeeklyUpdateHistoryViewModel>([]);

    constructor(parentViewModel: ProjectViewModel) {
        super(new ProjectWeeklyUpdateContainerModel());

        this.parentViewModel = parentViewModel;
        this.weeklyUpdateFormViewModel = new WeeklyUpdateFormViewModel(
            this.copyPreviousWeeklyUpdateCustomerDetailCommand,
            this.copyPreviousProjectUpdateInternalDetailCommand,
            this.saveUpdateCommand,
            this.model.updateForm,
        );

        makeObservable(this, {
            // Computeds
            weeklyUpdates: computed,
        });

        this.getData();
    }

    public getData = async () => {
        await this.apiClient.sendAsync(new GETProjectWeeklyUpdateWithRelatedByIdEndpoint(this.parentViewModel.model.id, this));
        if (this.apiClient.IsRequestSuccessful) {
            await this.getLatestWeeklyUpdateHistory();
        }
    };

    /**
     * Disposes of the update histories collection observer.
     */
    public dispose = (): void => {
        this.updateHistoriesObserverDispose?.();
    };

    // #region Properties

    public get weeklyUpdates() {
        return this.model.weeklyUpdates.map((item) => {
            return {
                key: item.id,
                text: formatDate(item.weekCommencingDate),
            };
        });
    }

    public get weeklyUpdateHistoryViewModel() {
        return this.weeklyUpdateHistoryViewModels.find((vm) => vm.model.id === this.model.weeklyUpdateId);
    }

    //copies the previous weekly update customer detail to current weekly update
    public copyPreviousWeeklyUpdateCustomerDetailCommand = new RelayCommand(
        () => {
            if (this.model.previousWeeklyUpdate) {
                //filter out the current weekly update
                //only get previous weekly update if it's not present in the history array
                if (isNullOrUndefined(this.model.updateHistories.find((m) => m.id === this.model.previousWeeklyUpdate!.id))) {
                    this.apiClient.sendAsync(new GETProjectWeeklyUpdateByUpdateIdEndpoint(this.model.previousWeeklyUpdate.id, this), this.model);
                }
                //find the previous update from newly updated history array
                const previousWeeklyUpdateHistory = this.model.updateHistories.find((update) => update.id == this.model.previousWeeklyUpdate!.id);
                // undefined check
                if (previousWeeklyUpdateHistory) {
                    this.model.updateForm.setValue("updateDetail", previousWeeklyUpdateHistory.updateDetail);
                }
            }
        },
        () => {
            return !this.apiClient.IsBusy && !isNullOrUndefined(this.model.latestUpdateHistory?.updateDetail);
        },
    );

    public copyPreviousProjectUpdateInternalDetailCommand = new RelayCommand(
        () => {
            if (this.model.previousWeeklyUpdate) {
                //filter out the current weekly update
                //only get previous weekly update if it's not present in the history array
                if (isNullOrUndefined(this.model.updateHistories.find((m) => m.id === this.model.previousWeeklyUpdate!.id))) {
                    this.apiClient.sendAsync(new GETProjectWeeklyUpdateByUpdateIdEndpoint(this.model.previousWeeklyUpdate.id, this), this.model);
                }
                //find the previous update from newly updated history array
                const previousWeeklyUpdateHistory = this.model.updateHistories.find((update) => update.id == this.model.previousWeeklyUpdate!.id);
                // undefined check
                if (previousWeeklyUpdateHistory) {
                    this.model.updateForm.setValue("internalDetail", previousWeeklyUpdateHistory.internalDetail);
                }
            }
        },
        () => {
            return !this.apiClient.IsBusy && !isNullOrUndefined(this.model.latestUpdateHistory?.internalDetail);
        },
    );

    public getLatestWeeklyUpdateHistory = async () => {
        if (this.model.previousWeeklyUpdate) {
            await this.apiClient.sendAsync(new GETProjectWeeklyUpdateByUpdateIdEndpoint(this.model.previousWeeklyUpdate.id, this), this.model);
        }
    };
    // #endregion Properties

    // #region Commands

    public navigateToWeeklyUpdateCommand = new RelayCommand((value: string) => {
        this.setValue("weeklyUpdateId", value);

        if (this.model.weeklyUpdateId !== MODELID_CURRENTPROJECTUPDATE && isNullOrUndefined(this.model.updateHistories.find((m) => m.id === this.model.weeklyUpdateId))) {
            this.getWeeklyUpdate(this.model.weeklyUpdateId!);
        }
    });

    public saveUpdateCommand = new RelayCommand(
        () => {
            this.apiClient.sendAsync(new POSTSaveProjectWeeklyUpdateEndpoint(this), this.model);
        },
        () => {
            return !this.apiClient.IsBusy;
        },
    );

    public resetServerErrorCommand = new RelayCommand(() => {
        this.apiClient.reset();
    });

    // #endregion Commands

    // #region Supporting

    private getWeeklyUpdate = debounce(
        action((weeklyUpdateId: string) => {
            if (!isEmptyOrWhitespace(weeklyUpdateId) && weeklyUpdateId !== MODELID_CURRENTPROJECTUPDATE) {
                this.apiClient.sendAsync(new GETProjectWeeklyUpdateByUpdateIdEndpoint(weeklyUpdateId, this), this.model);
            }
        }),
        SERVER_DEBOUNCE_PERIOD_MS,
    );

    private updateHistoriesObserverDispose = observe(this.model.updateHistories, (updateHistoryModelChanges: any) => {
        for (const addedUpdateHistory of updateHistoryModelChanges.added) {
            this.weeklyUpdateHistoryViewModels.push(new WeeklyUpdateHistoryViewModel(addedUpdateHistory));
        }

        for (const removedUpdateHistory of updateHistoryModelChanges.removed) {
            const updateHistoryViewModelToRemove = this.weeklyUpdateHistoryViewModels.find((vm) => vm.model.KEY === removedUpdateHistory.KEY);

            if (updateHistoryViewModelToRemove) {
                this.weeklyUpdateHistoryViewModels.remove(updateHistoryViewModelToRemove);
            }
        }
    });

    // #endregion Supporting
}
