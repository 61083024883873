import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { ProgrammingWeekTaskModel } from "../ProgrammingWeekTask/ProgrammingWeekTaskModel";

class Request {
    public projectTaskAssignmentId: string = "";
    public projectTaskAssignmentStatusId = "";
    public projectTaskAssignmentUserNote = "";
}

class Response {}

export class POSTInsertProjectTaskAssignmentUserNoteEndpoint extends Endpoint<Request, Response> {
    constructor() {
        super();

        this.verb(Http.Post);
        this.path(AppUrls.Server.Dashboard.Staff.PostInsertProjectTaskAssignmentUserNote);
    }

    public async HandleRequestAsync(model: ProgrammingWeekTaskModel): Promise<any> {
        const request = new Request();

        request.projectTaskAssignmentId = model.projectTaskAssignmentId;
        request.projectTaskAssignmentUserNote = model.projectTaskAssignmentUserNote;
        request.projectTaskAssignmentStatusId = model.projectTaskAssignmentStatusId;

        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        return Promise.resolve(response);
    }
}
