import { ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

import { WeekendEventModel } from "./WeekendEventModel";

export class WeekendEventViewModel extends ViewModelBase<WeekendEventModel> {
    constructor(model: WeekendEventModel = new WeekendEventModel()) {
        super(model, false);

        makeObservable(this, {});
    }
}
