import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { TaskAssignmentGroupModel } from "../TaskAssignmentGroupModel";

class Request {
    id: string | null = null;
    name: string | null = null;
    note: string | null = null;
    userId: string | null = null;
    projectId: string | null = null;
    items: string[] = [];
}

class Response {}

export class POSTSaveProjectTaskAssignmentGroupEndpoint extends Endpoint<Request, Response> {
    constructor() {
        super();

        this.verb(Http.Post);
        this.path(AppUrls.Server.Resources.SaveTaskAssignmentGroup);
    }

    public async HandleRequestAsync(model: TaskAssignmentGroupModel): Promise<Request> {
        const request = new Request();

        request.id = model.id;
        request.name = model.name;
        request.note = model.note;
        request.userId = model.userId;
        request.projectId = model.projectId;

        request.items = model.taskAssignments.filter((t) => t.enabled).map((t) => t.inProgressItemModel.id);

        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: any): Promise<any> {
        Logger.logInformation("Response", response);
    }
}
