import { isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { computed, makeObservable, observable } from "mobx";
import { container } from "tsyringe";

import { AppUrls } from "AppUrls";
import { AccountStore, LookupStore } from "Stores/Domain";
import { APIClient, formatDate, formatTime, RelayCommand } from "../../../Application";
import { DomainStore } from "../../../Stores/Domain/DomainStore";
import { FilesViewModel } from "Views/Shared/Files/FilesViewModel";
import { SupplierContact, SupplierDetailModel } from "./SupplierDetailModel";
import { DeleteSupplierEndpoint } from "./DeleteSupplierEndpoint";
import { GETSupplierDetailsEndpoint } from "./GETSupplierDetailsEndpoint";

export default class SupplierDetailViewModel extends ViewModelBase<SupplierDetailModel> {
    public apiClient = new APIClient();
    public filesViewModel = new FilesViewModel();
    public lookupStore = container.resolve(LookupStore);
    public domainStore = container.resolve(DomainStore);

    public deletePopup: boolean = false;
    public accountStore = container.resolve(AccountStore);
    constructor(customerId: string) {
        super(new SupplierDetailModel());

        makeObservable(this, {
            // Observables

            deletePopup: observable,
            // Computed
            canDisplaySupplierContacts: computed,
            canDisplaySupplierContactNumber1: computed,
            canDisplaySupplierContactNumber2: computed,
            canDisplaySupplierEmailAddress: computed,
            canDisplayClientNotes: computed,
            canDisplaySupplierAddress: computed,
            displayName: computed,
            supplierAddress: computed,
        });

        this.apiClient.sendAsync(new GETSupplierDetailsEndpoint(customerId, this));
    }

    // #region User Roles

    public get isAdmin() {
        return this.lookupStore.AccountStore.isInRole("admin");
    }

    public get isBusinessSupport() {
        return this.lookupStore.AccountStore.isInRole("businesssupport");
    }

    public get isSeniorAssociate() {
        return this.lookupStore.AccountStore.isInRole("seniorassociate");
    }

    // #endregion User Roles

    // #region Properties

    /**
     * Returns the display name of the customer.
     */
    public get displayName() {
        return this.model.supplier.businessName;
    }

    public get displaySupplierTypeName() {
        const supplierTypeName = this.model.supplier.supplierTypeName;
        const supplierSubTypeName = this.model.supplier.supplierSubTypeName;

        return !isEmptyOrWhitespace(supplierSubTypeName) ? `${supplierTypeName} - ${supplierSubTypeName}` : supplierTypeName;
    }

    public get supplierAddress() {
        return [this.model.supplier.address1, this.model.supplier.address2, this.model.supplier.address3, this.model.supplier.city, this.model.supplier.postcode]
            .filter((item) => !isEmptyOrWhitespace(item))
            .join(", ");
    }

    public get clientNote() {
        return this.model.supplier.clientNote;
    }

    public get userDetails() {
        const userName = `${this.model.supplier.updatedByUserFirstName} ${this.model.supplier.updatedByUserLastName}`;

        const date = isEmptyOrWhitespace(this.model.supplier.lastUpdatedByUserId) ? formatDate(this.model.createdDate!) : formatDate(this.model.supplier.lastUpdatedDate!);
        const time = isEmptyOrWhitespace(this.model.supplier.lastUpdatedByUserId) ? formatTime(this.model.createdDate!) : formatTime(this.model.supplier.lastUpdatedDate!);

        return `${userName} at ${time} on ${date}`;
    }

    public get canDisplaySupplierContactNumber1() {
        return !isEmptyOrWhitespace(this.model.supplier.contactNumber1);
    }
    public get canDisplaySupplierContactNumber2() {
        return !isEmptyOrWhitespace(this.model.supplier.contactNumber2);
    }

    public get canDisplaySupplierEmailAddress() {
        return !isEmptyOrWhitespace(this.model.supplier.emailAddress);
    }
    public get canDisplaySupplierEmailAddress2() {
        return !isEmptyOrWhitespace(this.model.supplier.emailAddress2);
    }
    public get canDisplaySupplierAddress() {
        return !isEmptyOrWhitespace(this.supplierAddress);
    }

    public get canDisplaySupplierContacts() {
        return this.model.supplierContacts.length > 0;
    }

    public canDisplaySupplierContactContactNumber1(supplierContact: SupplierContact) {
        return !isEmptyOrWhitespace(supplierContact.contactNumber1);
    }

    public canDisplaySupplierContactContactNumber2(supplierContact: SupplierContact) {
        return !isEmptyOrWhitespace(supplierContact.contactNumber2);
    }

    public canDisplaySupplierContactEmailAddress(supplierContact: SupplierContact) {
        return !isEmptyOrWhitespace(supplierContact.emailAddress);
    }

    public canDisplaySupplierContactAddress(supplierContact: SupplierContact) {
        return (
            !supplierContact.sameAsCustomerAddress &&
            (!isEmptyOrWhitespace(supplierContact.address1) ||
                !isEmptyOrWhitespace(supplierContact.address2) ||
                !isEmptyOrWhitespace(supplierContact.address3) ||
                !isEmptyOrWhitespace(supplierContact.city) ||
                !isEmptyOrWhitespace(supplierContact.postcode))
        );
    }

    public get canDisplayClientNotes() {
        return !isEmptyOrWhitespace(this.model.supplier.clientNote) || this.filesViewModel.fileViewModels.length > 0;
    }

    public get canDisplayDelete(): boolean {
        return this.deletePopup;
    }

    // #endregion Properties

    // #region Commands

    public navigateToSupplierCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.Suppliers.Table);
    });

    public navigateToEditSupplierCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.Suppliers.Edit.replace(":id", this.model.supplier.id));
    });

    public resetServerErrorCommand = new RelayCommand(() => {
        this.apiClient.reset();
    });

    public deleteModalOpenCommand = new RelayCommand(
        () => (this.deletePopup = true),
        () => this.isAdmin || this.isSeniorAssociate || this.isBusinessSupport,
    );

    public closeDeleteModalCommand = new RelayCommand(() => {
        this.deletePopup = false;
    });

    public deleteSupplierCommand = new RelayCommand(
        () => {
            this.apiClient.sendAsync(new DeleteSupplierEndpoint(this.model.supplier.id, this));
        },
        () => {
            return !this.apiClient.IsBusy;
        },
    );

    // #endregion Commands

    // #region Supporting

    public getSupplierContactName = (supplierContact: SupplierContact) => {
        return [supplierContact.prefixName, supplierContact.firstName, supplierContact.lastName].filter((item) => !isEmptyOrWhitespace(item)).join(" ");
    };

    public getSupplierContactTypeName = (supplierContact: SupplierContact) => {
        return !isEmptyOrWhitespace(supplierContact.supplierContactTypeName) ? supplierContact.supplierContactTypeName : "";
    };

    public getSupplierContactNumber1 = (supplierContact: SupplierContact) => {
        return !isEmptyOrWhitespace(supplierContact.contactNumber1) ? supplierContact.contactNumber1 : "";
    };

    public getSupplierContactNumber2 = (supplierContact: SupplierContact) => {
        return !isEmptyOrWhitespace(supplierContact.contactNumber2) ? supplierContact.contactNumber2 : "";
    };

    public getSupplierContactEmailAddress = (supplierContact: SupplierContact) => {
        return !isEmptyOrWhitespace(supplierContact.emailAddress) ? supplierContact.emailAddress : "";
    };

    public getSupplierContactAddress = (supplierContact: SupplierContact) => {
        return !supplierContact.sameAsCustomerAddress
            ? [supplierContact.address1, supplierContact.address2, supplierContact.address3, supplierContact.city, supplierContact.postcode]
                  .filter((item) => !isEmptyOrWhitespace(item))
                  .join(", ")
            : "-";
    };

    // #endregion Supporting
}
