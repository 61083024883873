import { ViewModelBase } from "@shoothill/core";
import { makeObservable, observable, runInAction } from "mobx";

import { RelayCommand } from "Application";
import { AppUrls } from "AppUrls";
import { GlobalHistory } from "index";
import { ResourceModel } from "./ResourceModel";

export class ResourceViewModel extends ViewModelBase<ResourceModel> {
    public locationPathName: string = "";

    private historyUnlisten?: any;

    constructor(model = new ResourceModel()) {
        super(model, false);

        makeObservable(this, {
            locationPathName: observable,
        });

        this.historyUnlisten = GlobalHistory.listen((locationUpdate) => runInAction(() => this.setLocationPathName(locationUpdate.location.pathname)));
        this.setLocationPathName(location.pathname);
    }

    public dispose = (): void => {
        this.historyUnlisten?.();
    };

    public setStaffTabCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.Staff.Table);
    });

    public setTasksInProgressTabCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.AssignedTasks.Table);
    });

    public setTasksCompleteTabCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.AssignedTasks.TableComplete);
    });

    public setUnassignedTasksTabCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.UnassignedTasks.Table);
    });

    public setBilledTasksTabCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.BilledTasks.Table);
    });

    public setTimesheetTabCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.Timesheet.Table);
    });

    private setLocationPathName = (pathName: string) => {
        this.locationPathName = pathName;
    };
}
