import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";

import { Box, setPageTitle } from "Application";
import { ProjectViewModel } from "../ProjectViewModel";
import { ProgrammingView } from "./Programming/ProgrammingView";
import { BilledAssignmentsContainerView } from "./BilledAssignments/BilledAssignmentsContainerView";

interface Props {
    viewModel: ProjectViewModel;
}

export const ProjectTasksView: React.FC<Props> = observer((props: Props) => {
    useEffect(() => {
        setPageTitle("Project Tasks");
    }, []);

    return (
        <Box m={"25px 30px"}>
            <ProgrammingView projectId={props.viewModel.model.id} />
            <BilledAssignmentsContainerView projectId={props.viewModel.model.id} />
        </Box>
    );
});
