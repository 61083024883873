import { ViewModelBase } from "@shoothill/core";
import { computed, makeObservable, observable, observe } from "mobx";

import { ICommand, RelayCommand } from "Application/Commands";
import { TaskGroupModel } from "./TaskGroupModel";
import { TaskModel } from "./TaskModel";
import { TaskViewModel } from "./TaskViewModel";
import { APIClient } from "Application";

export class TaskGroupViewModel extends ViewModelBase<TaskGroupModel> {
    public apiClient = new APIClient();

    public taskViewModels = observable<TaskViewModel>([]);

    public displayConfigureTaskGroupCommand: ICommand;
    private removeTaskGroupModelCommand: ICommand;

    constructor(taskGroup: TaskGroupModel = new TaskGroupModel(), displayConfigureTaskGroupCommand: ICommand, removeTaskGroupModelCommand: ICommand) {
        super(taskGroup);

        this.displayConfigureTaskGroupCommand = displayConfigureTaskGroupCommand;
        this.removeTaskGroupModelCommand = removeTaskGroupModelCommand;

        makeObservable(this, {
            // Observables
            taskViewModels: observable,
            // Computeds
            displayName: computed,
        });
    }

    /**
     * Disposes of the task collection observer. Call this from your view.
     */
    public dispose = (): void => {
        this.tasksObserverDispose?.();
    };

    // #region Properties

    public get displayName() {
        return this.model.taskGroupName;
    }

    public get KEY() {
        return this.model.KEY;
    }

    public get sortedTaskViewModels() {
        return this.taskViewModels.slice().sort((a, b) => a.model.ordinal - b.model.ordinal);
    }

    // #endregion Properties

    // #region Commands

    /**
     * Command to remove a task model from the task model collection. If the
     * collection is empty, the group can be removed.
     */
    public removeTaskModelCommand: ICommand = new RelayCommand((task: TaskModel) => {
        const taskToRemove = this.model.tasks.find((m) => m.KEY === task.KEY);

        if (taskToRemove) {
            this.model.tasks.remove(taskToRemove);
        }

        if (this.model.tasks.length === 0) {
            this.removeTaskGroupModelCommand.execute(this.model);
        }
    });

    public onDragEndCommand = new RelayCommand(() => {
        const retValVMs: TaskViewModel[] = [];
        const retValModels: TaskModel[] = [];

        const sortedTaskVMs = this.taskViewModels.slice().sort((a, b) => a.model.ordinal - b.model.ordinal);

        this.taskViewModels.forEach((vm, index) => {
            const model = new TaskModel();
            model.toModel(vm.model);
            const taskVM = new TaskViewModel(this, model, this.removeTaskModelCommand);
            taskVM.setValue("ordinal", sortedTaskVMs[index].model.ordinal);
            retValVMs.push(taskVM);
            retValModels.push(model);
        });

        this.model.tasks.replace(retValModels);
        this.taskViewModels.replace(retValVMs);
    });

    // #endregion Commands

    /**
     * An observer to listen to changes in the task model collection. Use this to create or remove
     * task viewmodels in response to changes in the task model collection.
     */
    private tasksObserverDispose = observe(this.model.tasks, (taskChanges: any) => {
        for (const addedTask of taskChanges.added) {
            this.taskViewModels.push(new TaskViewModel(this, addedTask, this.removeTaskModelCommand));
        }

        for (const removedTask of taskChanges.removed) {
            const taskViewModelToRemove = this.taskViewModels.find((vm) => vm.model.KEY === removedTask.KEY);

            if (taskViewModelToRemove) {
                this.taskViewModels.remove(taskViewModelToRemove);
            }
        }
    });

    public get canSubmitForm(): boolean {
        let isFormValid = true;
        for (const taskViewModel of this.taskViewModels) {
            if (!taskViewModel.isModelValid()) {
                isFormValid = false;
            }
        }
        return isFormValid;
    }
}
