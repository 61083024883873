import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React from "react";

import { ICommand } from "../../Application/Commands";

export interface IHeaderLogoOptionProps {
    /**
     * An optional class name for use with the button.
     */
    className?: string;
    /**
     * A command to execute.
     */
    command: ICommand;
}

export const HeaderLogoOptionBase: React.FC<IHeaderLogoOptionProps> = observer((props) => {
    // #region Code Behind

    const getClasseNames = (): string => {
        return clsx({
            [props.className!]: !isEmptyOrWhitespace(props.className),
        });
    };

    const isVisible = (): boolean => {
        return isNullOrUndefined(props.command.canExecute) ? true : props.command.canExecute();
    };

    const onClick = (): void => {
        props.command.execute();
    };

    // #endregion Code Behind

    return isVisible() ? <div className={getClasseNames()} onClick={onClick}></div> : <React.Fragment />;
});
