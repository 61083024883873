import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { nanoid } from "nanoid";

export class RoleLiteModel extends ModelBase<RoleLiteModel> {
    public KEY: string = nanoid();

    public id: string | null = null;
    public name = "";
    public normalizedName: string = "";
    constructor() {
        super();
        makeObservable(this, {
            // Observables
            id: observable,
            name: observable,
            normalizedName: observable,
        });
    }
}
