import { IStyle, mergeStyleSets } from "@fluentui/react";
import { isEmptyOrWhitespace } from "@shoothill/core";
import { theme } from "Application";
import { AutoGrid, Show } from "Components";
import { observer } from "mobx-react-lite";
import React, { useRef } from "react";

import { FilesIcon } from "Assets/Icons/FilesIcon";
import { File } from "./File";
import { FilesViewModel } from "./FilesViewModel";
import styled from "@emotion/styled";

export interface IFilesStyles {
    root: IStyle;
    fileButton: IStyle;
    fileContainer: IStyle;
}

export interface IFilesProps {
    className?: string;
    viewModel: FilesViewModel;
    styles?: IFilesStyles;
}

const filesStyles: IFilesStyles = {
    root: {},
    fileButton: {
        display: "flex",
        button: {
            alignItems: "center",
            backgroundColor: "#F9F9F9",
            border: "1px dashed #DCDCDC",
            color: "#8D8D8D",
            display: "flex",
            flexDirection: "column",
            flex: 1,
            fontFamily: theme.defaultFontStyle.fontFamily,
            fontSize: "10px",
            height: "80px",
            justifyContent: "center",
            letterSpacing: "0.36",
            textTransform: "uppercase",
            ":hover": {
                cursor: "pointer",
            },
            svg: {
                marginBottom: "4px",
            },
        },
    },
    fileContainer: {
        maxHeight: "180px",
        overflowY: "auto",
        "&&": {
            marginBottom: "0",
        },
    },
};

const UploadedFilesContainer = styled.div<{ height?: string }>`
    height: ${(p) => p.height ?? "auto"};
    overflow-y: auto;
`;

export const Files: React.FC<IFilesProps> = observer(({ viewModel, className, styles }) => {
    // #region Code Behind

    const inputReference = useRef<any>(null);

    const displayName = (): string => {
        return haveErrorMessage() ? viewModel.errorMessage : allowMultipleFiles() ? "Drag or click to attach one or more files" : "Drag or click to attach a file";
    };

    const allowMultipleFiles = (): boolean => {
        return viewModel.allowMultipleFiles;
    };

    const fileTypes = (): string => {
        return viewModel.fileTypes;
    };

    const isDisabled = (): boolean => {
        return !viewModel.addCommand.canExecute;
    };

    const haveErrorMessage = (): boolean => {
        return !isEmptyOrWhitespace(viewModel.errorMessage);
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        viewModel.addCommand.execute(event.target.files);

        // Do this to allow the file(s) to be reselected the next time the file explorer is open.
        event.target.value = "";
    };

    const onDropFile = (event: React.DragEvent<HTMLElement>) => {
        event.preventDefault();

        // TODO: APM - Extend this to cover dataTransfer.items.
        viewModel.addCommand.execute(event.dataTransfer.files);
    };

    const onDragFile = (event: React.DragEvent<HTMLElement>) => {
        event.preventDefault();
    };

    const onClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        inputReference?.current?.click();
    };

    const dynamicStyles: Partial<IFilesStyles> = {
        fileButton: {
            button: {
                backgroundColor: haveErrorMessage() ? "#EF9099" : "#F9F9F9",
                color: haveErrorMessage() ? "#DC3545" : "#8D8D8D",
            },
            svg: {
                ".canFill": {
                    fill: haveErrorMessage() ? "#DC3545" : "#8D8D8D",
                },
            },
        },
    };

    const mergedStyles = mergeStyleSets(filesStyles, dynamicStyles, styles);

    // #endregion Code Behind

    return (
        <div className={mergedStyles.root}>
            <div className={mergedStyles.fileButton} onDragEnter={onDragFile} onDragOver={onDragFile} onDrop={onDropFile}>
                <input accept={fileTypes()} multiple={allowMultipleFiles()} ref={inputReference} style={{ display: "none" }} type="file" onChange={onChange} />
                <button disabled={isDisabled()} onClick={onClick} type="button">
                    <FilesIcon />
                    {displayName()}
                </button>
            </div>
            <Show if={viewModel.fileViewModels.length > 0}>
                <UploadedFilesContainer>
                    <AutoGrid className={mergedStyles.fileContainer} dc={"1fr 1fr"} columnGap="30px" rowGap="15px">
                        {viewModel.fileViewModels.map((viewModel) => {
                            return <File className={className} viewModel={viewModel} key={viewModel.KEY} />;
                        })}
                    </AutoGrid>
                </UploadedFilesContainer>
            </Show>
        </div>
    );
});
