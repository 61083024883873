import { FieldType, isNullOrUndefined, ViewModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { nanoid } from "nanoid";

import { formatDecimalNumber, RelayCommand } from "Application";
import { TaskModel, BilledTaskModelValidator } from "../TaskModel";
import { BillingNotesViewModel } from "./SubViews/BillingNotesViewModel";

export class BillingViewModel extends ViewModelBase<TaskModel> {
    private key = nanoid();

    // Sub viewmodels.
    public readonly billingNotesViewModels = observable<BillingNotesViewModel>([]);

    constructor(model: TaskModel) {
        super(model);

        this.setValidator(new BilledTaskModelValidator());

        makeObservable(this, {
            // Observables
        });
    }

    // #region Properties

    public get displayName() {
        return "Billing";
    }

    public get KEY() {
        return this.key;
    }

    /**
     * Remaining billable effort for the assignment.
     *
     * If the parent task is billed hourly, the value should not be displayed.
     */
    public get remainingProjectTaskBillableEffort() {
        let effort = 0;

        if (!this.model.projectTaskIsBilledHourly) {
            effort = this.model.remainingProjectTaskBillableEffort!;
        }

        return formatDecimalNumber(effort);
    }

    /**
     * The billable effort on the assignments parent task. This value is for use in the view.
     *
     * If the parent task is billed hourly, the value should not be displayed.
     */
    public get projectTaskBillableEffort() {
        return this.model.projectTaskBillableEffort ? formatDecimalNumber(this.model.projectTaskBillableEffort) : "";
    }

    /**
     * Determines if the billable effort or any other properties related to it can be displayed
     * in the view (see remainingProjectTaskBillableEffort and projectTaskBillableEffort).
     */
    public get canDisplayBillableEffort() {
        return !this.model.projectTaskIsBilledHourly;
    }

    public get currentAssignmentNotes() {
        return this.billingNotesViewModels.find((vm) => vm.model.id === this.model.id);
    }

    public get otherAssignmentsNotes() {
        return this.billingNotesViewModels.filter((vm) => vm.model.id !== this.model.id);
    }

    public get canDisplayCurrentAssignmentNotes() {
        return !isNullOrUndefined(this.currentAssignmentNotes);
    }

    public get canDisplayOtherAssignmentsNotes() {
        return this.otherAssignmentsNotes.length > 0;
    }

    // #endregion Properties

    // #region Commands

    public updateBilledEffortCommand = new RelayCommand((value: string) => {
        const parsedValue = parseFloat(value);
        const checkedValue = Number.isNaN(parsedValue) ? null : parsedValue;

        this.updateField("billedEffort", checkedValue);
    });

    public updateBilledNoteCommand = new RelayCommand((value: string) => {
        this.updateField("billedNote", value);
    });

    // #endregion Commands

    // #region Supporting

    private updateField(fieldName: keyof FieldType<TaskModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    // #endregion Supporting
}
