import { ModelBase } from "@shoothill/core";
import { action, makeObservable, observable } from "mobx";
import { nanoid } from "nanoid";

import { Validator } from "Application/Validation";
import { ProjectLite } from "Application/Models/Domain";
import { User } from "Application/Models/Domain/User";

export class EnquirySubModel extends ModelBase<EnquirySubModel> {
    public KEY: string = nanoid();
    public backupCopy: EnquirySubModel | null = null;

    public projectTypeId = "";
    public enquiryTypeId: string | null = null;
    public enquiryStatusId: string | null = null;
    public title = "";
    public description = "";
    public estimatedCost = 0;
    public projectId: string | null = null;
    public enquiryPersonVisitingId: string | null = null;
    public enquiryPersonVisitingVisitDate: Date | null = null;
    public enquiryPersonVisitingVisitTime: string | null = null;
    public projectsList = observable<ProjectLite>([]);
    public personVisitingList = observable<User>([]);
    public lostNoteSubTypeId: string | null = null;
    public lostNoteDetail: string | null = null;

    constructor() {
        super();

        makeObservable(this, {
            // Observables
            projectTypeId: observable,
            enquiryTypeId: observable,
            enquiryStatusId: observable,
            title: observable,
            description: observable,
            estimatedCost: observable,
            projectId: observable,
            enquiryPersonVisitingId: observable,
            enquiryPersonVisitingVisitDate: observable,
            enquiryPersonVisitingVisitTime: observable,
            projectsList: observable,
            personVisitingList: observable,
            lostNoteSubTypeId: observable,
            lostNoteDetail: observable,

            // Actions
            restore: action,
        });
    }

    // #region Helpers

    public backup = (): EnquirySubModel => {
        const model = new EnquirySubModel();

        model.KEY = this.KEY;
        model.projectTypeId = this.projectTypeId;
        model.enquiryStatusId = this.enquiryStatusId;
        model.enquiryTypeId = this.enquiryTypeId;
        model.enquiryPersonVisitingId = this.enquiryPersonVisitingId;
        model.enquiryPersonVisitingVisitDate = this.enquiryPersonVisitingVisitDate;
        model.enquiryPersonVisitingVisitTime = this.enquiryPersonVisitingVisitTime;
        model.title = this.title;
        model.description = this.description;
        model.estimatedCost = this.estimatedCost;
        model.projectId = this.projectId;

        this.backupCopy = model;

        return model;
    };

    public restore = (): EnquirySubModel => {
        if (this.backupCopy) {
            this.KEY = this.backupCopy.KEY;
            this.projectTypeId = this.backupCopy.projectTypeId;
            this.enquiryStatusId = this.backupCopy.enquiryStatusId;
            this.enquiryTypeId = this.backupCopy.enquiryTypeId;
            this.enquiryPersonVisitingId = this.backupCopy.enquiryPersonVisitingId;
            this.enquiryPersonVisitingVisitDate = this.backupCopy.enquiryPersonVisitingVisitDate;
            this.enquiryPersonVisitingVisitTime = this.backupCopy.enquiryPersonVisitingVisitTime;
            this.title = this.backupCopy.title;
            this.description = this.backupCopy.description;
            this.estimatedCost = this.backupCopy.estimatedCost;
            this.projectId = this.backupCopy.projectId;
        }

        return this;
    };

    // #endregion Helpers
}

export class EnquirySubModelValidator extends Validator<EnquirySubModel> {
    constructor() {
        super();

        this.ruleFor("projectTypeId").notNull().withMessage("Please select a project type").notEmpty().withMessage("Please select a project type");
        this.ruleFor("enquiryStatusId").notNull().withMessage("Please select an enquiry status").notEmpty().withMessage("Please select an enquiry status");
        this.ruleFor("enquiryTypeId").notNull().withMessage("Please select an enquiry type").notEmpty().withMessage("Please select an enquiry type");

        this.ruleFor("title").notNull().withMessage("Please enter a Job name").notEmpty().withMessage("Please enter a job name");
        this.ruleFor("description").notNull().withMessage("Please enter a description").notEmpty().withMessage("Please enter a description");
        this.ruleFor("estimatedCost").notNull().withMessage("Please enter a value").greaterThanOrEqualTo(0).withMessage("Please provide a value");
    }
}
