import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

export class CustomerDetailModel extends ModelBase<CustomerDetailModel> {
    public customer: Customer = new Customer();
    public customerContacts = observable<CustomerContact>([]);
    public enquiries = observable<Enquiry>([]);
    public lostEnquiries = observable<Enquiry>([]);
    public quotes = observable<Quote>([]);
    public lostQuotes = observable<Quote>([]);
    public projects = observable<Project>([]);
    public completedProjects = observable<Project>([]);

    constructor() {
        super();
        makeObservable(this, {
            customer: observable,
            customerContacts: observable,
            enquiries: observable,
            lostEnquiries: observable,
            quotes: observable,
            lostQuotes: observable,
            projects: observable,
            completedProjects: observable,
        });
    }
}

export class Customer extends ModelBase<Customer> {
    public id: string = "";
    public contactTypeId: string = "";
    public contactTitleId: string = "";
    public businessName: string = "";
    public prefixName: string = "";
    public firstName: string = "";
    public lastName: string = "";
    public address1: string = "";
    public address2: string = "";
    public address3: string = "";
    public city: string = "";
    public postcode: string = "";
    public contactNumber1: string = "";
    public contactNumber2: string = "";
    public emailAddress: string = "";
    public emailAddress2: string = "";
    public clientNote: string = "";

    constructor() {
        super();
        makeObservable(this, {
            id: observable,
            contactTypeId: observable,
            contactTitleId: observable,
            businessName: observable,
            prefixName: observable,
            firstName: observable,
            lastName: observable,
            address1: observable,
            address2: observable,
            address3: observable,
            city: observable,
            postcode: observable,
            contactNumber1: observable,
            contactNumber2: observable,
            emailAddress: observable,
            emailAddress2: observable,
            clientNote: observable,
        });
    }
}

export class CustomerContact extends ModelBase<CustomerContact> {
    public id: string = "";
    public customerId: string = "";
    public contactTitleId: string = "";
    public prefixName: string = "";
    public firstName: string = "";
    public lastName: string = "";
    public address1: string = "";
    public address2: string = "";
    public address3: string = "";
    public city: string = "";
    public postcode: string = "";
    public sameAsCustomerAddress: boolean = false;
    public contactNumber1: string = "";
    public contactNumber2: string = "";
    public emailAddress: string = "";
    public businessContactTypeName: string = "";

    constructor() {
        super();
        makeObservable(this, {
            id: observable,
            customerId: observable,
            contactTitleId: observable,
            prefixName: observable,
            firstName: observable,
            lastName: observable,
            address1: observable,
            address2: observable,
            address3: observable,
            city: observable,
            postcode: observable,
            sameAsCustomerAddress: observable,
            contactNumber1: observable,
            contactNumber2: observable,
            emailAddress: observable,
            businessContactTypeName: observable,
        });
    }
}

export class Enquiry extends ModelBase<Enquiry> {
    public id: string = "";
    public enquiryStatusId: string = "";
    public contactTypeId: string = "";
    public customerId: string = "";
    public customerContactId: string = "";
    public reference: string = "";
    public title: string = "";
    public contactFirstName: string = "";
    public contactLastName: string = "";
    public lastActionDate: Date | null = null;
    public lastActionByUserId = null;
    public lastActionFirstName = null;
    public lastActionLastName = null;

    constructor() {
        super();
        makeObservable(this, {
            id: observable,
            enquiryStatusId: observable,
            contactTypeId: observable,
            customerId: observable,
            customerContactId: observable,
            reference: observable,
            title: observable,
            contactFirstName: observable,
            contactLastName: observable,
            lastActionDate: observable,
            lastActionByUserId: observable,
            lastActionFirstName: observable,
            lastActionLastName: observable,
        });
    }
}

export class Quote extends ModelBase<Quote> {
    public id: string = "";
    public quoteStatusId: string = "";
    public contactTypeId: string = "";
    public customerId: string = "";
    public customerContactId: string = "";
    public reference: string = "";
    public title: string = "";
    public value: number = 0;
    public hours: number = 0;
    public contactFirstName: string = "";
    public contactLastName: string = "";
    public lastActionDate: Date | null = null;
    public lastActionByUserId = null;
    public lastActionFirstName = null;
    public lastActionLastName = null;

    constructor() {
        super();
        makeObservable(this, {
            id: observable,
            quoteStatusId: observable,
            contactTypeId: observable,
            customerId: observable,
            customerContactId: observable,
            reference: observable,
            title: observable,
            value: observable,
            contactFirstName: observable,
            contactLastName: observable,
            lastActionDate: observable,
            lastActionByUserId: observable,
            lastActionFirstName: observable,
            lastActionLastName: observable,
        });
    }
}

export class Project extends ModelBase<Project> {
    public id: string = "";
    public projectStatusId: string = "";
    public contactTypeId: string = "";
    public customerId: string = "";
    public customerContactId: string = "";
    public reference: string = "";
    public title: string = "";
    public value: number = 0;
    public hours: number = 0;
    public contactFirstName: string = "";
    public contactLastName: string = "";
    public lastActionDate: Date | null = null;
    public lastActionByUserId = null;
    public lastActionFirstName = null;
    public lastActionLastName = null;

    constructor() {
        super();
        makeObservable(this, {
            id: observable,
            projectStatusId: observable,
            contactTypeId: observable,
            customerId: observable,
            customerContactId: observable,
            reference: observable,
            title: observable,
            value: observable,
            contactFirstName: observable,
            contactLastName: observable,
            lastActionDate: observable,
            lastActionByUserId: observable,
            lastActionFirstName: observable,
            lastActionLastName: observable,
        });
    }
}
