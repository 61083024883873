import { ModelBase } from "@shoothill/core";
import Address from "getaddress-api";
import { action, makeObservable, observable } from "mobx";

export interface MatchingAddress {
    id: string;
    address: Address;
}

export class FindAddressModel extends ModelBase<FindAddressModel> {
    // Success
    public postcode: string = "";
    public latitude: number = 0;
    public longitude: number = 0;
    public addresses = observable<MatchingAddress>([]);

    // Failed
    public status: number = 0;
    public message: string = "";

    constructor() {
        super();

        makeObservable(this, {
            // Observables
            postcode: observable,
            latitude: observable,
            longitude: observable,
            addresses: observable,
            status: observable,
            message: observable,

            // Actions
            reset: action,
        });
    }

    public reset = () => {
        this.postcode = "";
        this.latitude = 0;
        this.longitude = 0;
        this.addresses.replace([]);
        this.status = 0;
        this.message = "";
    };
}
