import { FieldType, ViewModelBase } from "@shoothill/core";
import { computed, makeObservable, observable } from "mobx";

import { APIClient, IKeyState, RelayCommand } from "Application";
import { GETAllServicesGroupsLiteEndpoint } from "./Endpoints/GETAllServiceGroupsLiteEndpoint";
import { PostBulkSwapServiceGroupOrdinalsEndpoint } from "./Endpoints/POSTBulkSwapServiceGroupOrdinalsEndpoint";
import { PostDeleteServiceGroupByIdEndpoint } from "./Endpoints/POSTDeleteServiceGroupByIdEndpoint";
import { PostSwapServiceGroupOrdinalsEndpoint } from "./Endpoints/POSTSwapServiceGroupOrdinalsEndpoint";
import { PostUpsertServiceGroupEndpoint } from "./Endpoints/POSTUpsertServiceGroupEndpoint";
import { SyncServiceGroupsResponse } from "./Endpoints/SharedResponseModels";
import { ServiceGroupModel } from "./ServiceGroup/ServiceGroupModel";
import { ServiceGroupViewModel } from "./ServiceGroup/ServiceGroupViewModel";
import { ServiceGroupsModel } from "./ServiceGroupsModel";

export class ServiceGroupsViewModel extends ViewModelBase<ServiceGroupsModel> {
    public apiClient = new APIClient();

    public newServiceGroupViewModel: ServiceGroupViewModel | null = null;

    constructor(model = new ServiceGroupsModel()) {
        super(model, false);

        makeObservable(this, {
            // Observables
            newServiceGroupViewModel: observable,

            // Computeds
            canDisplayNewServiceGroup: computed,
        });

        this.apiClient.sendAsync(new GETAllServicesGroupsLiteEndpoint(this), this.model);
    }

    //region commands
    public updateFilterKeywordCommand = new RelayCommand((keyword: string) => {
        this.setValue("filterKeyword", keyword);
    });

    public upsertServiceGroupCommand = new RelayCommand((model: ServiceGroupModel) => {
        this.apiClient.sendAsync(new PostUpsertServiceGroupEndpoint(this), model);
    });

    public swapServiceGroupOrdinalCommand = new RelayCommand((idOne: string, idTwo: string) => {
        this.swapServiceGroupOrdinals(idOne, idTwo);
    });

    public swapServiceGroupOrdinals(idOne: string, idTwo: string) {
        //idOne is the id of the service group that is to be swapped
        //idTwo is the id of the target service group
        const requestModel: any = {
            idOne,
            idTwo,
        };
        this.apiClient.sendAsync(new PostSwapServiceGroupOrdinalsEndpoint(this), requestModel);
    }

    public deleteServiceGroupCommand = new RelayCommand((id: string) => {
        this.apiClient.sendAsync(new PostDeleteServiceGroupByIdEndpoint(this), id).then(() => {
            this.model.serviceGroupModels.remove(this.model.serviceGroupModels.find((model) => model.id == id)!);
        });
    });

    //endregion commands

    //region helpers

    public syncModels(response: SyncServiceGroupsResponse) {
        const serviceGroupModels: ServiceGroupModel[] = [];
        //sync models
        //if models array is empty, replace observable array.
        if (this.model.serviceGroupModels.length == 0) {
            response.serviceGroupsLite.forEach((item) => {
                if (!item.isDeleted) {
                    //create serviceGroupModel
                    const serviceGroupModel = new ServiceGroupModel();
                    //populate model
                    serviceGroupModel.id = item.id;
                    serviceGroupModel.name = item.name;
                    serviceGroupModel.ordinal = item.ordinal;
                    //push model to array
                    serviceGroupModels.push(serviceGroupModel);
                }
            });
            //replace services array
            this.model.serviceGroupModels.replace(serviceGroupModels);
        } else {
            response.serviceGroupsLite.forEach((item) => {
                const serviceGroupModel = this.model.serviceGroupModels.find((model) => model.id == item.id);
                if (serviceGroupModel) {
                    if (serviceGroupModel.isDeleted) {
                        this.model.serviceGroupModels.remove(serviceGroupModel);
                    } else {
                        serviceGroupModel.name = item.name;
                        serviceGroupModel.ordinal = item.ordinal;
                    }
                } else {
                    if (!item.isDeleted) {
                        //create serviceGroupModel
                        const serviceGroupModel = new ServiceGroupModel();
                        //populate model
                        serviceGroupModel.id = item.id;
                        serviceGroupModel.name = item.name;
                        serviceGroupModel.ordinal = item.ordinal;

                        this.model.serviceGroupModels.push(serviceGroupModel);
                    }
                }
                //sort array by ordinal
                this.model.serviceGroupModels.sort((a, b) => a.ordinal - b.ordinal);
            });
        }
    }

    public onDragEndCommand = new RelayCommand(() => {
        this.apiClient.sendAsync(new PostBulkSwapServiceGroupOrdinalsEndpoint(this), this.model.serviceGroupModels);
    });

    // #region Add New Service Group

    public get canDisplayNewServiceGroup(): boolean {
        return this.newServiceGroupViewModel !== null;
    }

    public displayNewServiceGroupCommand = new RelayCommand(() => {
        this.newServiceGroupViewModel = new ServiceGroupViewModel(this, new ServiceGroupModel());
    });

    public submitNewServiceGroupCommand = new RelayCommand(async () => {
        if (this.newServiceGroupViewModel!.isModelValid()) {
            await this.apiClient.sendAsync(new PostUpsertServiceGroupEndpoint(this), this.newServiceGroupViewModel!.model);

            if (this.apiClient.IsRequestSuccessful) {
                this.newServiceGroupViewModel = null;
                this.apiClient.reset();
            }
        }
    });

    public cancelAddNewServiceGroupCommand = new RelayCommand(() => {
        this.newServiceGroupViewModel = null;
    });

    // #endregion Add New Service Group

    // #region Helpers

    private updateField(fieldName: keyof FieldType<ServiceGroupsModel>, value: any, keyState?: IKeyState) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    // #endregion Helpers
}
