import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

import { Validator } from "Application/Validation";
import { WeeklyUpdateHistoryModel } from "Views/Projects/WeeklyUpdate/History/WeeklyUpdateHistoryModel";

export class WeeklyUpdateModel extends ModelBase<WeeklyUpdateModel> {
    /**
     * The weekly updates id.
     */
    public id: string | null = null;

    /**
     * The weekly updates parent project id.
     */
    public projectId: string = "";

    /**
     * The weekly updates update date.
     */
    public updateDate: Date | null = null;

    /**
     * The weekly updates notes.
     */
    public updateDetail: string = "";

    /**
     * The weekly internal notes.
     */
    public internalDetail: string = "";

    /**
     * Key information note, accumulates during the lifecycle of the project.
     */
    public keyInformation: string = "";

    /**
     * Specifies if the weekly update should be sent (to the client).
     * Set to true by default
     */
    public sendUpdate: boolean = true;

    /**
     * States if this model has a weekly update. Not part of the form data.
     */
    public hasUpdate: boolean = false;

    /**
     * States if this model has a weekly update for this programming week. Not part of the form data.
     */
    public hasActiveUpdate: boolean = false;

    /**
     * Audit information. Not part of the form data.
     */
    public createdByUserId: string | null = null;
    public updatedByUserId: string | null = null;

    /**
     * The previous update detail.
     */

    public previousProjectUpdateUpdateDetail: string | null = null;

    /**
     * The previous update internal note.
     */

    public previousProjectUpdateInternalDetail: string | null = null;

    constructor() {
        super();

        makeObservable(this, {
            // Observables
            hasUpdate: observable,
            hasActiveUpdate: observable,

            updateDate: observable,
            updateDetail: observable,
            internalDetail: observable,
            sendUpdate: observable,

            createdByUserId: observable,
            updatedByUserId: observable,

            previousProjectUpdateUpdateDetail: observable,
            previousProjectUpdateInternalDetail: observable,
        });
    }
}

export class WeeklyUpdateModelValidator extends Validator<WeeklyUpdateModel> {
    constructor() {
        super();
        this.ruleFor("updateDetail").notNull().withMessage("Please provide details").notEmpty().withMessage("Please provide details");
    }
}
