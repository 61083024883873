import styled from "@emotion/styled";
import { IColumn, Link, Modal } from "@fluentui/react";
import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import { Box, formatCurrency, formatDate, setPageTitle, ThemedButton, ThemedComboBox, ThemedEditDate, ThemedEditText } from "Application";
import { PhoneIcon } from "Assets/Icons/PhoneIcon";
import { EmailIcon } from "Assets/Icons/EmailIcon";
import { SearchIcon } from "Assets/SearchIcon";
import { ContactCallout, ICalloutState } from "Styles/ThemedPrimitives/Style1/ContactCallout";
import { ThemedDataTable } from "Styles/ThemedPrimitives/Style1/ThemedDataTable";
import { NewProjectView } from "./ModalViews/NewProjectView";
import { ProjectsViewModel } from "./ProjectsViewModel";
import { ProjectItemViewModel } from "./ProjectItemViewModel";
import { DownloadIcon } from "Assets/Icons/DownloadIcon";
import { DownloadCalloutView, IDownloadCalloutState } from "./Components/DownloadCalloutView";

interface IDataTableWrapperProps {
    isArchitect: boolean;
}

const DataTableWrapper = styled.div<IDataTableWrapperProps>`
    div.ms-DetailsRow {
        cursor: ${(props) => (props.isArchitect ? "default" : "pointer")} !important;
    }
`;

const keywordSearchStyles = {
    root: {
        width: "505px",
        height: "39px",
        fontSize: "17px",
        borderColor: "#E1E1E1",
    },
};

const newItemButtonStyles = {
    root: {
        height: 30,
        width: 147,
        fontSize: 17,
    },
};

export const ProjectsView: React.FC = observer(() => {
    // #region Code Behind

    const [viewModel] = useState(() => new ProjectsViewModel());

    useEffect(() => {
        setPageTitle("Projects");
    }, []);

    /**
     * Handles updating the tables column sort settings in response to changes in the viewmodel.
     */
    useEffect(() => {
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter((currCol) => viewModel.settings.sortKey === currCol.key)[0];

        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSorted = true;
                currColumn.isSortedDescending = viewModel.settings.sortDescending;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = false;
            }
        });

        setColumns(newColumns);
    }, [viewModel.settings.sortKey, viewModel.settings.sortDescending]);

    /**
     * Gets a key for the row. Must be provided if sorting or filtering is enabled.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The row index.
     *
     * @returns A string key to identify the row.
     */
    const getRowKey = (item: ProjectItemViewModel, index?: number): string => {
        return item.KEY;
    };

    /**
     * Handles an onClick event for the table column header.
     *
     * @param ev Mouse event
     * @param column The column that has been clicked.
     */
    const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const currColumn: IColumn = columns.filter((currCol) => column.key === currCol.key)[0];
        const newSortKey = currColumn.key;
        const newSortDescending = currColumn.key === viewModel.settings.sortKey ? !currColumn.isSortedDescending : false;

        viewModel.updateSortCommand.execute(newSortKey, newSortDescending);
    };

    /**
     * Handler an onClick event for a table row.
     *
     * @param item The viewmodel data associated with the table row.
     */
    const onRowClick = (item: ProjectItemViewModel, index?: number, ev?: React.FocusEvent<HTMLElement>) => {
        if (viewModel.canNavigateToProjectDetail) {
            viewModel.navigateToProjectCommand.execute(item, index, ev);
        }
    };

    /**
     * Renders the anticipated date row data.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The index of the table row.
     * @param column The table column.
     *
     * @returns React rating element.
     */
    const onRenderAnticipatedDate = (item: ProjectItemViewModel, index?: number, column?: IColumn) => {
        return <div>{!isNullOrUndefined(item.anticipatedDate) ? formatDate(item.anticipatedDate!) : ""}</div>;
    };

    /**
     * Renders the percentage complete row data.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The index of the table row.
     * @param column The table column.
     *
     * @returns React completed element.
     */
    const onRenderComplete = (item: ProjectItemViewModel, index?: number, column?: IColumn) => {
        const CIRCLEDIAMETER = 30;
        const FONTSIZE = 10;

        // Do not touch these values - they are based on a normalised circle of circumference of 100units.
        const NORMCIRCLEDIAMETER = 36;
        const DIAMETER = 100 / Math.PI;
        const RADIUS = DIAMETER / 2;
        const X = NORMCIRCLEDIAMETER / 2;
        const Y = (NORMCIRCLEDIAMETER - DIAMETER) / 2;

        const PATH = `M${X} ${Y} a ${RADIUS} ${RADIUS} 0 0 1 0 ${DIAMETER} a ${RADIUS} ${RADIUS} 0 0 1 0 -${DIAMETER}`;
        const VIEWBOX = `0 0 ${NORMCIRCLEDIAMETER} ${NORMCIRCLEDIAMETER}`;
        const SCALEDFONTSIZE = `${(FONTSIZE * NORMCIRCLEDIAMETER) / CIRCLEDIAMETER}px`;

        return (
            <div style={{ display: "flex", justifyContent: "center", flex: "1" }}>
                <svg className="progressChartContainer" viewBox={VIEWBOX} width={CIRCLEDIAMETER} height={CIRCLEDIAMETER}>
                    <path className="progressChartBackcircle" fill="white" stroke="white" strokeWidth="4" strokeDasharray="100, 100" d={PATH} />
                    <path
                        className="progressChartNoprogresscircle"
                        fill="none"
                        opacity="0.5"
                        stroke={item.projectStatusForegroundColor}
                        strokeWidth="4"
                        strokeDasharray="100, 100"
                        d={PATH}
                    />
                    <path
                        className="progressChartProgresscircle"
                        fill="none"
                        stroke={item.projectStatusForegroundColor}
                        strokeWidth="4"
                        strokeDasharray={`${item.complete}, 100`}
                        d={PATH}
                    />
                    <text className="progressText" x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontSize={SCALEDFONTSIZE} fill={item.projectStatusForegroundColor}>
                        {item.complete}
                    </text>
                </svg>
            </div>
        );
    };

    /**
     * Renders the project status row data.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The index of the table row.
     * @param column The table column.
     *
     * @returns React rating element.
     */
    const onRenderProjectStatus = (item: ProjectItemViewModel, index?: number, column?: IColumn) => {
        return <div style={{ color: `${item.projectStatusForegroundColor}`, textTransform: "uppercase", fontWeight: "500" }}>{item.projectStatusName}</div>;
    };

    /**
     * Renders the value row data.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The index of the table row.
     * @param column The table column.
     *
     * @returns React rating element.
     */
    const onRenderValue = (item: ProjectItemViewModel, index?: number, column?: IColumn) => {
        return <div>{formatCurrency(item.value)}</div>;
    };

    const onRenderContactName = (item: ProjectItemViewModel, index?: number, column?: IColumn) => {
        if (item.model.customerItemModel != null) {
            return (
                <Link onFocus={(ev) => ev.stopPropagation()}>
                    <div onClick={() => viewModel.navigateToCustomerDetailCommand.execute(item.model.customerItemModel!.id)}>
                        <div style={{ fontWeight: 600, fontSize: "10px", color: "#171716" }}>{item.contactFullName}</div>
                        <div style={{ fontSize: "10px", color: "#171716" }}>{item.contactFullAddress}</div>
                    </div>
                </Link>
            );
        } else {
            return (
                <div>
                    <div style={{ fontWeight: 600, fontSize: "10px", color: "#171716" }}>{item.contactFullName}</div>
                    <div style={{ fontSize: "10px", color: "#171716" }}>{item.contactFullAddress}</div>
                </div>
            );
        }
    };

    /**
     * Renders the phone icon and provides callout support.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The index of the table row.
     * @param column The table column.
     *
     * @returns React rating element.
     */
    const onRenderPhone = (item: ProjectItemViewModel, index?: number, column?: IColumn) => {
        if (item.model.customerItemModel && !isEmptyOrWhitespace(item.model.customerItemModel.contactNumber1)) {
            return (
                <Link onFocus={(ev) => ev.stopPropagation()}>
                    <PhoneIcon
                        id={`phoneicon-${index}`}
                        onClick={(event: any) => {
                            setIsCalloutVisible({
                                isCalloutVisble: true,
                                isEmail: false,
                                targetId: `phoneicon-${index}`,
                                data: item.model.customerItemModel!.contactNumber1!,
                            });
                        }}
                    />
                </Link>
            );
        }

        return <React.Fragment />;
    };

    /**
     * Renders the email icon and provides callout support.
     *
     * The icon is wrapped in a Link with a stop propagation call so
     * clicking on the icon prevents the table row click.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The index of the table row.
     * @param column The table column.
     *
     * @returns React rating element.
     */
    const onRenderEmail = (item: ProjectItemViewModel, index?: number, column?: IColumn) => {
        if (item.model.customerItemModel && !isEmptyOrWhitespace(item.model.customerItemModel.emailAddress)) {
            return (
                <Link onFocus={(ev) => ev.stopPropagation()}>
                    <EmailIcon
                        id={`emailicon-${index}`}
                        onClick={(event: any) => {
                            setIsCalloutVisible({
                                isCalloutVisble: true,
                                isEmail: true,
                                targetId: `emailicon-${index}`,
                                data: item.model.customerItemModel!.emailAddress!,
                            });
                        }}
                    />
                </Link>
            );
        }

        return <React.Fragment />;
    };

    /**
     * Renders the download icon and provides callout support.
     *
     * The icon is wrapped in a Link with a stop propagation call so
     * clicking on the icon prevents the table row click.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The index of the table row.
     * @param column The table column.
     *
     * @returns React rating element.
     */
    const onRenderDownload = (item: ProjectItemViewModel, index?: number, column?: IColumn) => {
        if (item.downloadCalloutViewModel.canDisplayDownloadOptions) {
            return (
                <Link onFocus={(ev) => ev.stopPropagation()}>
                    <DownloadIcon
                        id={`downloadicon-${index}`}
                        onClick={(event: any) => {
                            setIsDownloadCalloutVisible({
                                isCalloutVisble: true,
                                targetId: `downloadicon-${index}`,
                                viewModel: item.downloadCalloutViewModel,
                            });
                        }}
                    />
                </Link>
            );
        }

        return <React.Fragment />;
    };

    /**
     * Defines the columns for use with the table.
     */
    const defaultTableColumns = (): IColumn[] => {
        if (viewModel.isAdmin || viewModel.isSeniorAssociate || viewModel.isBusinessSupport) {
            return [
                {
                    fieldName: "projectStatusName",
                    key: "projectStatusName",
                    name: "STATUS",
                    minWidth: 0,
                    maxWidth: 70,
                    isResizable: true,
                    isSorted: true,
                    onColumnClick: onColumnClick,
                    onRender: onRenderProjectStatus,
                },
                {
                    fieldName: "reference",
                    key: "reference",
                    name: "REFERENCE",
                    isResizable: true,
                    minWidth: 0,
                    maxWidth: 100,
                    styles: {
                        cellTitle: {
                            textAlign: "center",
                            justifyContent: "center",
                        },
                    },
                    className: "textCenter",
                    isSorted: true,
                    onColumnClick: onColumnClick,
                },
                {
                    key: "title",
                    name: "JOB NAME",
                    fieldName: "title",
                    isResizable: true,
                    minWidth: 50,
                    isSorted: true,
                    onColumnClick: onColumnClick,
                },
                {
                    key: "fullName",
                    name: "CLIENT",
                    fieldName: "fullName",
                    minWidth: 250,
                    isSorted: true,
                    isSortedDescending: false,
                    sortAscendingAriaLabel: "Sorted A to Z",
                    sortDescendingAriaLabel: "Sorted Z to A",
                    onColumnClick: onColumnClick,
                    isPadded: true,
                    onRender: onRenderContactName,
                    styles: {
                        cellTitle: {
                            border: "none !important",
                        },
                    },
                },
                {
                    className: "contactCell",
                    fieldName: "",
                    styles: {
                        cellTitle: {
                            border: "none !important",
                        },
                    },
                    key: "phoneicon",
                    name: "",
                    minWidth: 25,
                    onRender: onRenderPhone,
                },
                {
                    className: "contactCell",
                    fieldName: "",
                    key: "emailicon",
                    name: "",
                    minWidth: 25,
                    onRender: onRenderEmail,
                },
                {
                    fieldName: "value",
                    key: "value",
                    name: "VALUE (EX VAT)",
                    isResizable: true,
                    minWidth: 100,
                    isSorted: true,
                    onColumnClick: onColumnClick,
                    onRender: onRenderValue,
                },
                {
                    fieldName: "projectLeadName",
                    key: "projectLeadName",
                    name: "PROJECT LEAD",
                    isResizable: true,
                    minWidth: 100,
                    isSorted: true,
                    onColumnClick: onColumnClick,
                },
                {
                    fieldName: "projectSupportName",
                    key: "projectSupportName",
                    name: "PROJECT SUPPORT",
                    isResizable: true,
                    minWidth: 100,
                    isSorted: true,
                    onColumnClick: onColumnClick,
                },
                {
                    key: "seniorAssociateName",
                    name: "PROJECT OVERVIEW",
                    fieldName: "seniorAssociateName",
                    isResizable: true,
                    minWidth: 100,
                    isSorted: true,
                    onColumnClick: onColumnClick,
                },
                {
                    fieldName: "noOfTasks",
                    key: "noOfTasks",
                    name: "NO. OF TASKS",
                    isResizable: true,
                    minWidth: 0,
                    isSorted: true,
                    onColumnClick: onColumnClick,
                },
                {
                    fieldName: "noOfHours",
                    key: "noOfHours",
                    name: "NO. OF HOURS",
                    isResizable: true,
                    minWidth: 0,
                    isSorted: true,
                    onColumnClick: onColumnClick,
                },
                {
                    fieldName: "anticipatedDate",
                    key: "anticipatedDate",
                    name: "ANT. END DATE",
                    isResizable: true,
                    minWidth: 0,
                    isSorted: true,
                    onColumnClick: onColumnClick,
                    onRender: onRenderAnticipatedDate,
                },
                {
                    fieldName: "complete",
                    key: "complete",
                    name: "% COMPLETE",
                    minWidth: 0,
                    isSorted: true,
                    styles: {
                        cellTitle: {
                            textAlign: "center",
                            justifyContent: "center",
                        },
                    },
                    onColumnClick: onColumnClick,
                    onRender: onRenderComplete,
                },
                {
                    className: "downloadCell",
                    fieldName: "",
                    key: "downloadicon",
                    name: "",
                    maxWidth: 25,
                    minWidth: 25,
                    onRender: onRenderDownload,
                    styles: {
                        cellTitle: {
                            border: "none !important",
                        },
                    },
                },
            ];
        } else {
            return [
                {
                    fieldName: "reference",
                    key: "reference",
                    name: "REFERENCE",
                    isResizable: true,
                    minWidth: 0,
                    maxWidth: 100,
                    styles: {
                        cellTitle: {
                            textAlign: "center",
                            justifyContent: "center",
                        },
                    },
                    className: "textCenter",
                    isSorted: true,
                    onColumnClick: onColumnClick,
                },
                {
                    key: "title",
                    name: "JOB NAME",
                    fieldName: "title",
                    isResizable: true,
                    minWidth: 50,
                    isSorted: true,
                    onColumnClick: onColumnClick,
                },
                {
                    key: "fullName",
                    name: "CLIENT",
                    fieldName: "fullName",
                    minWidth: 450,
                    isSorted: true,
                    isSortedDescending: false,
                    sortAscendingAriaLabel: "Sorted A to Z",
                    sortDescendingAriaLabel: "Sorted Z to A",
                    onColumnClick: onColumnClick,
                    isPadded: true,
                    onRender: onRenderContactName,
                    styles: {
                        cellTitle: {
                            border: "none !important",
                        },
                    },
                },
                {
                    className: "contactCell",
                    fieldName: "",
                    styles: {
                        cellTitle: {
                            border: "none !important",
                        },
                    },
                    key: "phoneicon",
                    name: "",
                    minWidth: 25,
                    onRender: onRenderPhone,
                },
                {
                    className: "contactCell",
                    fieldName: "",
                    key: "emailicon",
                    name: "",
                    minWidth: 25,
                    onRender: onRenderEmail,
                },
                {
                    fieldName: "projectLeadName",
                    key: "projectLeadName",
                    name: "PROJECT LEAD",
                    isResizable: true,
                    minWidth: 150,
                    isSorted: true,
                    onColumnClick: onColumnClick,
                },
                {
                    key: "seniorAssociateName",
                    name: "PROJECT OVERVIEW",
                    fieldName: "seniorAssociateName",
                    isResizable: true,
                    minWidth: 150,
                    isSorted: true,
                    onColumnClick: onColumnClick,
                },
                {
                    className: "downloadCell",
                    fieldName: "",
                    key: "downloadicon",
                    name: "",
                    maxWidth: 25,
                    minWidth: 25,
                    onRender: onRenderDownload,
                    styles: {
                        cellTitle: {
                            border: "none !important",
                        },
                    },
                },
            ];
        }
    };

    /**
     * Defines the default state for displaying a callout.
     */
    const defaultCalloutState: ICalloutState = { isCalloutVisble: false, targetId: "", isEmail: false, data: "" };
    const defaultDownloadCalloutState: IDownloadCalloutState = { isCalloutVisble: false, targetId: "", viewModel: null };

    const [callout, setIsCalloutVisible] = useState<ICalloutState>(defaultCalloutState);
    const [downloadCallout, setIsDownloadCalloutVisible] = useState<IDownloadCalloutState>(defaultDownloadCalloutState);

    const [columns, setColumns] = useState<IColumn[]>(defaultTableColumns);

    // #endregion Code Behind

    const renderModalContent = () => {
        return (
            <Modal isOpen={viewModel.canDisplayNewProject} onDismiss={() => viewModel.cancelDisplayNewProjectCommand.execute()}>
                <NewProjectView viewModel={viewModel.newProjectViewModel!} />
            </Modal>
        );
    };

    return (
        <Box p={"30px"} overflowY="auto">
            {/* Filter Bar */}
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                <Box display={"flex"}>
                    <ThemedEditText
                        command={viewModel.updateFilterKeywordCommand}
                        placeholder={"Keyword search"}
                        prefix={<SearchIcon />}
                        styles={keywordSearchStyles}
                        value={() => viewModel.getValue("filterKeyword")}
                    />
                    {(viewModel.isAdmin || viewModel.isSeniorAssociate || viewModel.isBusinessSupport) && (
                        <>
                            <Box pl={3}>
                                <ThemedComboBox
                                    command={viewModel.updateFilterProjectStatusCommand}
                                    options={viewModel.projectStatuses}
                                    size="larger"
                                    value={() => viewModel.getValue("filterProjectStatusId")}
                                />
                            </Box>
                            <Box pl={3}>
                                <ThemedComboBox
                                    command={viewModel.updateFilterProjectLeadCommand}
                                    options={viewModel.projectLeads}
                                    size="larger"
                                    value={() => viewModel.getValue("filterProjectLeadId")}
                                />
                            </Box>

                            <Box pl={3}>
                                <ThemedComboBox
                                    command={viewModel.updateFilterProjectSupportCommand}
                                    options={viewModel.projectSupport}
                                    size="larger"
                                    value={() => viewModel.getValue("filterProjectSupportId")}
                                />
                            </Box>

                            <Box pl={3}>
                                <ThemedComboBox
                                    command={viewModel.updateFilterSeniorAssociateCommand}
                                    options={viewModel.seniorAssociates}
                                    size="larger"
                                    value={() => viewModel.getValue("filterSeniorAssociateId")}
                                />
                            </Box>
                        </>
                    )}
                    {viewModel.isArchitect && (
                        <>
                            <Box pl={3}>
                                <ThemedComboBox
                                    command={viewModel.updateFilterProjectLeadCommand}
                                    options={viewModel.projectLeads}
                                    size="larger"
                                    value={() => viewModel.getValue("filterProjectLeadId")}
                                />
                            </Box>
                            <Box pl={3}>
                                <ThemedComboBox
                                    command={viewModel.updateFilterSeniorAssociateCommand}
                                    options={viewModel.seniorAssociates}
                                    size="larger"
                                    value={() => viewModel.getValue("filterSeniorAssociateId")}
                                />
                            </Box>
                        </>
                    )}
                </Box>
                {viewModel.showNewProjectButton && (
                    <ThemedButton command={viewModel.displayNewProjectComand} displayName="New Project" paletteColor={"primary"} styles={newItemButtonStyles} />
                )}
            </Box>

            {/* Table */}
            {(viewModel.isAdmin || viewModel.isSeniorAssociate || viewModel.isBusinessSupport) && (
                <DataTableWrapper isArchitect={viewModel.isArchitect}>
                    <ThemedDataTable
                        className="projectslistviewTable"
                        getKey={getRowKey}
                        items={viewModel.filteredAndSortedProjects}
                        onActiveItemChanged={onRowClick}
                        columns={columns}
                    />
                </DataTableWrapper>
            )}

            {viewModel.isArchitect && (
                <DataTableWrapper isArchitect={viewModel.isArchitect}>
                    <ThemedDataTable className="projectslistviewTable" getKey={getRowKey} items={viewModel.filteredAndSortedProjects} columns={columns} />
                </DataTableWrapper>
            )}

            {/* New project */}
            {renderModalContent()}

            {/* Callout */}
            {callout.isCalloutVisble && (
                <ContactCallout data={callout.data} onDismiss={() => setIsCalloutVisible(defaultCalloutState)} isEmail={callout.isEmail} targetId={callout.targetId} />
            )}

            {/* Download Callout */}
            {downloadCallout.isCalloutVisble && (
                <DownloadCalloutView
                    viewModel={downloadCallout.viewModel}
                    onDismiss={() => setIsDownloadCalloutVisible(defaultDownloadCalloutState)}
                    targetId={downloadCallout.targetId}
                />
            )}
        </Box>
    );
});
