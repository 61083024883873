import styled from "@emotion/styled";
import { theme } from "Application";
export const Container = styled.div`
    height: 0px;
    transition: all 0.3s cubic-bezier(0.94, 0.06, 0.52, 0.49);

    & > ul {
        height: 0px;
        transition: all 0.3s cubic-bezier(0.94, 0.06, 0.52, 0.49) 1s;

        & > li:nth-of-type(even) {
            background-color: #f9f9f9;
        }
    }

    &.expanded {
        height: fit-content;
        & > ul {
            height: fit-content;
        }
    }
    background-color: white;
`;
