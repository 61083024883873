// Libs
import { makeObservable, observable } from "mobx";

export class ServiceGroup {
    public id: string = "";
    public name: string = "";
    public type: string = "";
    public ordinal: number = 0;
    public isDeleted: boolean = false;
    public isNCTPreset: boolean = false;

    constructor(id: string) {
        this.id = id;
        makeObservable(this, {
            id: observable,
            name: observable,
            type: observable,
            ordinal: observable,
            isDeleted: observable,
            isNCTPreset: observable,
        });
    }
}
