import { ModelBase } from "@shoothill/core";
import { Validator } from "Application/Validation";
import { makeObservable, observable } from "mobx";

export class InvoiceForecastMonthCapacityModel extends ModelBase<InvoiceForecastMonthCapacityModel> {
    public month: Date = new Date();
    public hours: number | null = null;
    public value: number | null = null;

    constructor() {
        super();
        makeObservable(this, {
            month: observable,
            hours: observable,
            value: observable,
        });
    }
}

export class InvoiceForecastModelValidator extends Validator<InvoiceForecastMonthCapacityModel> {
    constructor() {
        super();
    }
}
