import styled from "@emotion/styled";
import React from "react";
import { observer } from "mobx-react-lite";

import { Box, Else, Show, ThemedText } from "Application";
import { EditNoteView } from "./EditNoteView";
import { NoteViewModel } from "./NoteViewModel";
import { FilesReadOnlyView } from "../Files/FilesReadOnlyView";
import { NoteEditIcon } from "Components/StyleFrameworks/FluentUI/Primitives/Active/NoteEditIcon";
import { isEmptyOrWhitespace } from "@shoothill/core";

const NoteContainer = styled(Box)`
    background: #fcfcfc 0 0 no-repeat padding-box;
    box-shadow: 0 2px 3px #00000029;
    border-radius: 1px;
    padding: 10px 15px;
`;

interface Props {
    viewModel: NoteViewModel;
}

export const NoteView: React.FC<Props> = observer((props: Props) => {
    const { viewModel } = props;

    const renderInNonEditMode = () => {
        return (
            <NoteContainer>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <ThemedText fontStyle="h6">{viewModel.noteDetails}</ThemedText>
                    <Show if={!isEmptyOrWhitespace(viewModel.getValue("id"))}>
                        <ThemedText fontStyle="h7">{viewModel.userDetails}</ThemedText>
                        <Else>
                            <ThemedText fontStyle="h7"> </ThemedText>
                        </Else>
                    </Show>
                </Box>
                <Box display={"flex"} justifyContent={"space-between"} marginTop={"5px"}>
                    <ThemedText fontStyle="h7" mr={"15px"}>
                        {viewModel.getValue("noteDetail")}
                    </ThemedText>
                    {viewModel.editNoteCommand.canExecute() && (
                        <Box style={{ cursor: "pointer" }} onClick={() => viewModel.editNoteCommand.execute()}>
                            <ThemedText>
                                <NoteEditIcon />
                            </ThemedText>
                        </Box>
                    )}
                </Box>
                <FilesReadOnlyView viewModel={props.viewModel.filesViewModel} />
            </NoteContainer>
        );
    };

    return (
        <Box mt={3}>
            <Show if={viewModel.canDisplayEditMode}>
                <EditNoteView viewModel={viewModel.editNoteViewModel!} />
                <Else>{renderInNonEditMode()}</Else>
            </Show>
        </Box>
    );
});
