import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

import { User } from "Application/Models/Domain/User";
import { CustomerModel } from "Views/Shared/Customer/CustomerModel";
import { NotesModel } from "Views/Shared/Note/NotesModel";

export class ProjectDetailsModel extends ModelBase<ProjectDetailsModel> {
    public id: string = "";
    public projectStatusId: string = "";

    public projectLeadId: string | null = null;
    public projectSupportId: string | null = null;
    public seniorAssociateId: string | null = null;

    public projectLeads = observable<User>([]);
    public projectSupports = observable<User>([]);
    public seniorAssociates = observable<User>([]);

    // Hosted in own models.
    public customer = new CustomerModel();
    public notes = new NotesModel();

    constructor() {
        super();

        makeObservable(this, {
            id: observable,
            projectStatusId: observable,
            projectLeadId: observable,
            projectSupportId: observable,
            seniorAssociateId: observable,
            projectLeads: observable,
            projectSupports: observable,
            seniorAssociates: observable,
        });
    }
}
