import styled from "@emotion/styled";
import { DateRangeType, DayOfWeek, DetailsRow, ICalendarProps, IColumn, IProgressIndicatorStyles, mergeStyles, PersonaSize, ProgressIndicator } from "@fluentui/react";
import { isNullOrUndefined } from "@shoothill/core";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import { Box, setPageTitle, theme, ThemedButton, ThemedComboBox, ThemedEditDate, ThemedEditText, ThemedText } from "Application";
import { ThemedDataTableLayer2 } from "Styles/ThemedPrimitives/Style1/ThemedDataTable";
import { ThemedPersona } from "Styles/ThemedPrimitives/Style1/ThemedPersona";
import { NavigationContainer, PageContainer, TitleContainer } from "Views/Shared/SharedComponents";
import { StaffDetailsItemViewModel } from "./StaffDetailsItemViewModel";
import { StaffDetailsViewModel } from "./StaffDetailsViewModel";
import { WeeklyUpdateView } from "./TableSubViews/WeeklyUpdate/WeeklyUpdateView";
import { ProgrammingView } from "./TableSubViews/Programming/ProgrammingView";
import { MyWorkWeekOverview } from "./TableSubViews/MyWorkWeekOverview/MyWorkWeekOverview";
import { CustomSidePanel } from "Views/Shared/CustomSidePanel/CustomSidePanel";
import { TaskView } from "./ConfigureSubViews/TaskView";
import { SearchIcon } from "Assets/SearchIcon";

const ProgressIndicatorContainer = styled.div`
    flex: 4;
    cursor: pointer;
`;
/**
 * Handles rendering the row component and expansion.
 *
 * @param props The row props.
 *
 * @returns The table row.
 */
const TableRow = observer((props: any, parentProps: any) => {
    /**
     * Handler an onClick event for a table row.
     *
     * @param item The viewmodel data associated with the table row.
     */
    const onRowClick = (item: StaffDetailsItemViewModel) => {
        item.toggleAdditionalInformationCommand.execute();
    };

    /**
     * Defines the props for the DetailsRow component.
     */
    const detailsRowProps = {
        ...props.rowProps,
        // This is the row data we expect to re-render and need
        // to expose so that the mobx observer will work.
        ...props.rowProps.item.hasWeeklyUpdate,
        ...props.rowProps.item.noOfProjectTasks,
        ...props.rowProps.item.noOfUnassignedOrPartialTasks,
        ...props.rowProps.item.noOfAssignmentsInProgress,
    };

    return (
        <div>
            <DetailsRow
                {...detailsRowProps}
                styles={{
                    root: {
                        background: `${props.rowProps.item.projectStatusColour} !important`,
                    },
                }}
                onClick={() => onRowClick(props.rowProps.item)}
            />
            {props.rowProps.item.showAdditionalInformation && (
                <Box mb="30px">
                    <WeeklyUpdateView projectId={props.rowProps.item.model.id} staffDetailsItemViewModel={props.rowProps.item} parentViewModel={props.parentProps} />
                    <ProgrammingView projectId={props.rowProps.item.model.id} staffDetailsItemViewModel={props.rowProps.item} parentViewModel={props.parentProps} />
                </Box>
            )}
        </div>
    );
});

const keywordSearchStyles = {
    root: {
        width: "505px",
        height: "39px",
        fontSize: "17px",
        borderColor: "#E1E1E1",
    },
};

export const StaffDetailsView: React.FC = observer(() => {
    // #region Code Behind

    const { id } = useParams();
    const [viewModel] = useState(() => new StaffDetailsViewModel(id!));
    const [workContainerStylesOverrides, setWorkContainerStylesOverrides] = useState({ justifyContent: "flex-end" } as React.CSSProperties);

    useEffect(() => {
        setPageTitle("Staff resource details");
    }, []);

    useEffect(() => {
        if (viewModel.taskViewModel) {
            setWorkContainerStylesOverrides({ justifyContent: "flex-end", marginRight: "475px", width: "calc(100vw - 475px)" });
        } else {
            setWorkContainerStylesOverrides({ justifyContent: "flex-end" });
        }
    }, [viewModel.taskViewModel]);

    /**
     * Handles updating the tables column sort settings in response to changes in the viewmodel.
     */
    useEffect(() => {
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter((currCol) => viewModel.getValue("sortKey") === currCol.key)[0];

        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSorted = true;
                currColumn.isSortedDescending = viewModel.getValue("sortDescending");
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = false;
            }
        });

        setColumns(newColumns);
    }, [viewModel.getValue("sortKey"), viewModel.getValue("sortDescending")]);

    /**
     * Gets a key for the row. Must be provided if sorting or filtering is enabled.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The row index.
     *
     * @returns A string key to identify the row.
     */
    const getRowKey = (item: StaffDetailsItemViewModel, index?: number): string => {
        return item.KEY;
    };

    /**
     * Handles rendering the row. Normally we would not do this. However if
     * trying to display an overlay (alert, panel or modal), on dismissing
     * the overlay, onActiveItemChanged would be called again and redisplay the
     * overlay!
     *
     * @param props The row props of which item is the viewmodel.
     * @returns The table row.
     */
    const onRenderRow = (props: any) => {
        return <TableRow rowProps={props} parentProps={viewModel} />;
    };

    /**
     * Handles an onClick event for the table column header.
     *
     * @param ev Mouse event
     * @param column The column that has been clicked.
     */
    const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const currColumn: IColumn = columns.filter((currCol) => column.key === currCol.key)[0];
        const newSortKey = currColumn.key;
        const newSortDescending = currColumn.key === viewModel.getValue("sortKey") ? !currColumn.isSortedDescending : false;

        viewModel.updateSortCommand.execute(newSortKey, newSortDescending);
    };

    /**
     * Renders the expanded state of the row.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The index of the table row.
     * @param column The table column.
     *
     * @returns React element.
     */
    const onRenderExpandedState = (item: StaffDetailsItemViewModel, index?: number, column?: IColumn) => {
        return <div style={{ color: "white", fontSize: "24px" }}>{item.expandedDisplayName}</div>;
    };

    /**
     * Renders a boolean as Yes/No.
     */
    const onRenderYesNo = (value?: boolean) => {
        return <div>{!isNullOrUndefined(value) ? (value ? "Yes" : "No") : ""}</div>;
    };

    const additionalInformationColumnStyle = mergeStyles({
        alignItems: "center",
        backgroundColor: theme.palette.common.primary,
        display: "flex",
        height: "100%",
        justifyContent: "center",
        padding: "0 !important",
        width: "100%",
    });

    const effortPercentageAllocationStyles: Partial<IProgressIndicatorStyles> = {
        root: {
            flex: 1,
            position: "relative",
            padding: "2px 0",
        },
        itemName: {
            position: "absolute",
            color: theme.palette.common.default,
            fontSize: "10px",
            lineHeight: "12px",
            padding: "0 16px",
            zIndex: 1,
            top: "5px",
        },
        progressBar: {
            backgroundColor: viewModel.effortPercentageAllocation > 100 || (viewModel.effortPercentageAllocation !== 0 && viewModel.businessHours === 0) ? "#FEC97A" : "#96D8A3",
            borderRadius: "10px",
        },
        progressTrack: {
            backgroundColor: "#F3F3F3",
            borderRadius: "10px",
        },
        itemProgress: {
            padding: 0,
        },
    };

    const chargeableEffortpercentageAllocationStyles: Partial<IProgressIndicatorStyles> = {
        root: {
            flex: 1,
            position: "relative",
            padding: "2px 0",
        },
        itemName: {
            position: "absolute",
            color: theme.palette.common.default,
            fontSize: "10px",
            lineHeight: "12px",
            padding: "0 16px",
            zIndex: 1,
            top: "5px",
        },
        progressBar: {
            backgroundColor:
                viewModel.chargeablePercentageAllocation > 100 || (viewModel.chargeablePercentageAllocation !== 0 && viewModel.chargeableBusinessHours === 0)
                    ? "#96D8A3"
                    : "#FEC97A",
            borderRadius: "10px",
        },
        progressTrack: {
            backgroundColor: "#F3F3F3",
            borderRadius: "10px",
        },
        itemProgress: {
            padding: 0,
        },
    };

    const defaultTableColumns: IColumn[] = [
        {
            key: "showAdditionalInformation",
            fieldName: "showAdditionalInformation",
            name: "",
            minWidth: 10,
            maxWidth: 10,
            isResizable: false,
            isSorted: false,
            onRender: onRenderExpandedState,
            className: additionalInformationColumnStyle,
        },
        {
            key: "reference",
            fieldName: "reference",
            name: "REF",
            minWidth: 120,
            maxWidth: 120,
            isResizable: true,
            isSorted: true,
            onColumnClick: onColumnClick,
        },
        {
            key: "title",
            fieldName: "title",
            name: "JOB NAME",
            minWidth: 400,
            maxWidth: 600,
            isResizable: true,
            isSorted: true,
            onColumnClick: onColumnClick,
        },
        {
            key: "customerContactFullName",
            fieldName: "customerContactFullName",
            name: "Client Contact Name",
            minWidth: 200,
            maxWidth: 200,
            isResizable: true,
            isSorted: true,
            onColumnClick: onColumnClick,
        },
        {
            key: "projectSupportFullName",
            fieldName: "projectSupportFullName",
            name: "Project Support",
            minWidth: 200,
            maxWidth: 200,
            isResizable: true,
            isSorted: true,
            onColumnClick: onColumnClick,
        },

        {
            key: "hasWeeklyUpdate",
            fieldName: "hasWeeklyUpdate",
            name: "WEEKLY UPDATE",
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: (item) => onRenderYesNo(item.hasWeeklyUpdate),
        },
        {
            key: "noOfProjectTasks",
            fieldName: "noOfProjectTasks",
            name: "TASKS",
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            isSorted: true,
            onColumnClick: onColumnClick,
        },
        {
            key: "noOfUnassignedOrPartialTasks",
            fieldName: "noOfUnassignedOrPartialTasks",
            name: "UNASSIGNED TASKS",
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            isSorted: true,
            onColumnClick: onColumnClick,
        },
        {
            key: "noOfAssignmentsInProgress",
            fieldName: "noOfAssignmentsInProgress",
            name: "IN PROGRESS",
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            isSorted: true,
            onColumnClick: onColumnClick,
        },
    ];

    const [columns, setColumns] = useState<IColumn[]>(defaultTableColumns);

    const calendarProps: ICalendarProps = {
        dateRangeType: DateRangeType.WorkWeek,
        workWeekDays: [DayOfWeek.Monday],
    };

    // #endregion Code Behind

    return (
        <PageContainer>
            {/* Navigation */}
            <NavigationContainer onClick={() => viewModel.navigateToStaffCommand.execute()}>
                <ThemedText>{"<"} BACK TO RESOURCES</ThemedText>
            </NavigationContainer>

            {/* Filter Bar */}
            <TitleContainer style={{ display: "flex", minHeight: "74px", padding: "15px 30px" }}>
                <ThemedPersona styles={{ primaryText: { fontSize: "18px" } }} displayName={viewModel.userName} size={PersonaSize.size48} imageUrl={viewModel.userDocumentUrl} />
                <Box display="flex" flex={6} justifyContent="center" alignItems="center" ml="30px" mr="30px">
                    <Box display="flex" alignItems="center" justifyContent="end" mr="10px">
                        <ThemedText fontStyle="h4">Programming week:</ThemedText>
                    </Box>
                    <Box mt="-5px" mb="-2px">
                        <ThemedEditDate
                            calendarProps={calendarProps}
                            command={viewModel.updateFilterDateCommand}
                            placeholder="Start date"
                            size="larger"
                            value={() => viewModel.getValue("filterStartDate")}
                        />
                    </Box>
                    <ThemedButton displayName="Previous" paletteColor="primary" command={viewModel.updatePreviousFilterDateCommand} ml="15px" />
                    <ThemedButton displayName="Next" paletteColor="primary" command={viewModel.updateNextFilterDateCommand} ml="15px" />
                </Box>
                <ProgressIndicatorContainer onClick={() => viewModel.openMyWorkWeekOverviewCommand.execute("right", "80%", "100%")}>
                    <ProgressIndicator
                        label={viewModel.effortPercentageAllocationDisplayName}
                        styles={effortPercentageAllocationStyles}
                        percentComplete={viewModel.effortPercentageAllocation / 100}
                        barHeight={20}
                    />

                    <ProgressIndicator
                        label={viewModel.chargeableEffortPercentageAllocationDisplayName}
                        styles={chargeableEffortpercentageAllocationStyles}
                        percentComplete={viewModel.chargeablePercentageAllocation / 100}
                        barHeight={20}
                    />
                </ProgressIndicatorContainer>
            </TitleContainer>

            {/* Calendar */}
            {viewModel.canDisplayWorkCalendar && (
                <MyWorkWeekOverview
                    workCalendarViewModel={viewModel.workCalendarViewModel!}
                    show={viewModel.displayHorizontalMyWorkWeekOverview}
                    closeOverviewCommand={viewModel.closeMyWorkWeekOverviewCommand}
                    origin={viewModel.myWorkWeekOverviewOrigin}
                    height={viewModel.myWorkWeekOverviewHeight}
                    width={viewModel.myWorkWeekOverviewWidth}
                    overlayColor="rgba(255, 255, 255, 0.5)"
                    showCloseButton
                    styleOverrides={workContainerStylesOverrides}
                />
            )}

            {/* Task View */}
            <CustomSidePanel
                isOpen={viewModel.canDisplayTask}
                onDismiss={() => {
                    viewModel.cancelTaskCommand.execute();
                }}
            >
                <TaskView viewModel={viewModel.taskViewModel!} />
            </CustomSidePanel>

            <Box padding={"0px 30px"} display={"flex"}>
                <ThemedEditText
                    command={viewModel.updateFilterKeywordCommand}
                    placeholder={"Keyword search"}
                    prefix={<SearchIcon />}
                    styles={keywordSearchStyles}
                    value={() => viewModel.getValue("filterKeyword")}
                />
                <Box pl={3}>
                    <ThemedComboBox
                        command={viewModel.updateFilterProjectSupportCommand}
                        options={viewModel.projectSupport}
                        size="larger"
                        value={() => viewModel.getValue("filterProjectSupportId")}
                    />
                </Box>
            </Box>

            {/* Table */}
            <Box m="0 30px 30px 30px">
                <ThemedDataTableLayer2
                    styles={{
                        root: {
                            marginTop: 0,
                        },
                    }}
                    onRenderRow={onRenderRow}
                    getKey={getRowKey}
                    items={viewModel.filteredAndSortedProjects}
                    columns={columns}
                />
            </Box>
        </PageContainer>
    );
});
