import { ViewModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

import { APIClient, RelayCommand } from "Application";
import { FileModel } from "./FileModel";
import { ImageSettingsModel, ImageSettingsModelValidator } from "./ImageSettingsModel";
import { FileViewModel } from "./FileViewModel";
import { GetAllImageSettingsLiteEndpoint } from "./GetAllImageSettingsLiteEndpoint";
import { POSTSaveImageSettingsEndpoint } from "./POSTSaveImageSettingsEndpoint";
import { DeleteImageSettingsEndpoint } from "./DeleteImageSettingsEndpoint";

export class ImageSettingsViewModel extends ViewModelBase<ImageSettingsModel> {
    public fileViewModels = observable<FileViewModel>([]);
    public apiClient = new APIClient();
    public allowMultipleFiles = true;
    public fileTypes = "";
    public images: FileModel[] = [];

    constructor(fileModel: ImageSettingsModel = new ImageSettingsModel(), fileTypes = "image/png,image/jpg,image/jpeg", allowMultipleTYpes = true) {
        super(fileModel);

        this.setValidator(new ImageSettingsModelValidator());

        this.allowMultipleFiles = allowMultipleTYpes;
        this.fileTypes = fileTypes;

        makeObservable(this, {
            images: observable,
            fileViewModels: observable,
            allowMultipleFiles: observable,
            fileTypes: observable,
        });
        const __ = this.apiClient.sendAsync(new GetAllImageSettingsLiteEndpoint(this));
    }

    public get limitExceededErrorMessage() {
        return this.model.insideUploadLimit ? "" : `The total file size should be less than ${ImageSettingsModel.MAX_FILESIZE_MB}`;
    }
    public get imageLength() {
        return this.fileViewModels.length;
    }

    public resetServerErrorCommand = new RelayCommand(() => {
        this.apiClient.reset();
    });

    public saveImageCommand = new RelayCommand((files: Array<File>) => {
        if (files) {
            for (let fileIndex = 0; fileIndex < files.length; fileIndex++) {
                const fileModel = new FileModel();
                fileModel.file = files[fileIndex];
                fileModel.fileName = files[fileIndex].name;
                fileModel.fileSizeBytes = files[fileIndex].size;
                fileModel.mimeType = files[fileIndex].type;
                fileModel.documentUrl = "";
                this.model.files.push(fileModel);
            }
            this.apiClient.sendAsync(new POSTSaveImageSettingsEndpoint(this));
        }
    });

    public deleteCommand = new RelayCommand((fileViewModel: FileViewModel) => {
        const fileToRemove = this.fileViewModels.find((x) => x.model.id == fileViewModel.model.id);
        if (fileToRemove) {
            this.apiClient.sendAsync(new DeleteImageSettingsEndpoint(fileViewModel.model.id!, this));
        }
    });
}
