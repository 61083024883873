import { ModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";
import { nanoid } from "nanoid";
import moment from "moment";

export class WeeklyUpdateModel extends ModelBase<WeeklyUpdateModel> {
    public KEY: string = nanoid();
    public id: string = "";
    // Remark: a weekly update spans between the start of Tuesday end the end of the next Monday
    public updateDate: Date | null = null;

    constructor() {
        super();
        makeObservable(this, {
            // Observables
        });
    }

    // simple business logic

    //a week commences on the Monday but a weekly update is created every Tuesday
    //the weeks commencing date will be any WeeklyUpdate updateDate - 1 day.

    public get weekCommencingDate() {
        return moment(this.updateDate).subtract(1, "days").toDate();
    }
}
