import { FieldType, ViewModelBase } from "@shoothill/core";
import { computed, makeObservable } from "mobx";
import { nanoid } from "nanoid";
import { container } from "tsyringe";

import { LookupStore } from "Stores/Domain";
import { RelayCommand } from "Application";
import { TaskModel, TaskModelValidator } from "../TaskModel";
import { FilesViewModel } from "Views/Shared/Files/FilesViewModel";
import { PROJECTTASKASSIGNMENTSTATUSTYPE_ASSIGNEDTYPE, PROJECTTASKASSIGNMENTSTATUSTYPE_COMPLETEDTYPE, PROJECTTASKASSIGNMENTSTATUSTYPE_UPDATEDTYPE } from "Views/Shared/Constants";

export class NotesViewModel extends ViewModelBase<TaskModel> {
    private lookupStore = container.resolve(LookupStore);
    private key = nanoid();

    public filesViewModel = new FilesViewModel();

    constructor(model: TaskModel) {
        super(model);

        this.setValidator(new TaskModelValidator());

        makeObservable(this, {
            // Observables
            // Computeds
            canEditForm: computed,
        });
    }

    // #region Properties

    public get isTaskReviewed() {
        const status = this.lookupStore.projectTaskAssignmentStatuses.find((pts) => pts.id === this.model.projectTaskAssignmentStatusId);
        return status?.type == "Assigned" && this.model.isReviewed;
    }

    public get displayName() {
        return this.canEditReviewedNoteForm ? "Review" : "Notes";
    }

    public get KEY() {
        return this.key;
    }

    public get canEditForm() {
        const status = this.lookupStore.projectTaskAssignmentStatuses.find((pts) => pts.id === this.model.projectTaskAssignmentStatusId);

        return status?.type === PROJECTTASKASSIGNMENTSTATUSTYPE_ASSIGNEDTYPE || status?.type === PROJECTTASKASSIGNMENTSTATUSTYPE_UPDATEDTYPE;
    }

    public get canEditReviewedNoteForm() {
        const status = this.lookupStore.projectTaskAssignmentStatuses.find((pts) => pts.id === this.model.projectTaskAssignmentStatusId);

        return status?.type === PROJECTTASKASSIGNMENTSTATUSTYPE_COMPLETEDTYPE || this.isTaskReviewed;
    }

    public get note(): string {
        return this.getValue<string | null>("note") ? this.getValue("note") : "";
    }

    public get reviewedNote(): string {
        return this.getValue<string | null>("reviewedNote") ? this.getValue("reviewedNote") : "";
    }

    // #endregion Properties

    // #region Commands

    public updateDescriptionCommand = new RelayCommand(
        (value: string) => {
            this.updateField("note", value);
        },
        () => this.canEditForm,
    );

    public updateReviewNoteCommand = new RelayCommand(
        (value: string) => {
            this.updateField("reviewedNote", value);
        },
        () => this.canEditReviewedNoteForm,
    );

    // #endregion Commands

    // #region Supporting

    private updateField(fieldName: keyof FieldType<TaskModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    // #endregion Supporting
}
