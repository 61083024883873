import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

export class HeaderLinkModel extends ModelBase<HeaderLinkModel> {
    public displayName: string = "";
    public isActive: boolean = false;
    public routePath: string = "";

    constructor(displayName: string, routePath?: string) {
        super();
        this.displayName = displayName;
        makeObservable(this, {
            displayName: observable,
            isActive: observable,
            routePath: observable,
        });
    }
}
