import { DetailsRow, IColumn, Panel, PanelType, PersonaSize } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import { AutoGrid, Box, ThemedButton, ThemedComboBox, ThemedEditText, ThemedText } from "Application";
import { setPageTitle } from "Application/Utils";
import { SearchIcon } from "Assets/SearchIcon";
import { ThemedDataTable } from "Styles/ThemedPrimitives/Style1/ThemedDataTable";
import { ThemedPersona } from "Styles/ThemedPrimitives/Style1/ThemedPersona";
import { FormDottedSeparator, PanelStyles } from "Views/Shared/SharedComponents";
import { UnassignedTaskItemViewModel } from "./UnassignedTaskItemViewModel";
import { UnassignedTasksViewModel } from "./UnassignedTasksViewModel";
import { AssignTaskView } from "./AssignTaskSubViews/AssignTaskView";
import { ExportCSVButton } from "Components/General/ExportCSVButton";

const controlStyles = {
    root: {
        height: "39px",
        fontSize: "17px",
        borderColor: "#E1E1E1",
    },
};

export const UnassignedTasksView: React.FC = observer(() => {
    // #region Code Behind

    const [viewModel] = useState(() => new UnassignedTasksViewModel());

    useEffect(() => {
        setPageTitle("Unassigned tasks");
        viewModel.loadAsync();
    }, []);

    /**
     * Handles updating the tables column sort settings in response to changes in the viewmodel.
     */
    useEffect(() => {
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter((currCol) => viewModel.getValue("sortKey") === currCol.key)[0];

        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSorted = true;
                currColumn.isSortedDescending = viewModel.getValue("sortDescending");
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = false;
            }
        });

        setColumns(newColumns);
    }, [viewModel.getValue("sortKey"), viewModel.getValue("sortDescending")]);

    /**
     * Gets a key for the row. Must be provided if sorting or filtering is enabled.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The row index.
     *
     * @returns A string key to identify the row.
     */
    const getRowKey = (item: UnassignedTaskItemViewModel, index?: number): string => {
        return item.KEY;
    };

    /**
     * Handles an onClick event for the table column header.
     *
     * @param ev Mouse event
     * @param column The column that has been clicked.
     */
    const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const currColumn: IColumn = columns.filter((currCol) => column.key === currCol.key)[0];
        const newSortKey = currColumn.key;
        const newSortDescending = currColumn.key === viewModel.getValue("sortKey") ? !currColumn.isSortedDescending : false;

        viewModel.updateSortCommand.execute(newSortKey, newSortDescending);
    };

    /**
     * Handler an onClick event for a table row.
     *
     * @param item The viewmodel data associated with the table row.
     */
    const onRowClick = (item: UnassignedTaskItemViewModel) => {
        viewModel.displayAssignTaskCommand.execute(item.model.projectTaskId);
    };

    /**
     * Handles rendering the row. Normally we would not do this. However if
     * trying to display an overlay (alert, panel or modal), on dismissing
     * the overlay, onActiveItemChanged would be called again and redisplay the
     * overlay!
     *
     * @param props The row props of which item is the viewmodel.
     * @returns The table row.
     */
    const onRenderRow = (props: any) => {
        return <DetailsRow {...props} onClick={() => onRowClick(props.item)} />;
    };

    /**
     * Renders the project lead persona.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The index of the table row.
     * @param column The table column.
     *
     * @returns React element.
     */
    const onRenderProjectLeadName = (item: UnassignedTaskItemViewModel, index?: number, column?: IColumn) => {
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <ThemedPersona styles={{ primaryText: { fontSize: "10px" } }} displayName={item.projectLeadName} size={PersonaSize.size32} imageUrl={item.projectLeadDocumentUrl} />
            </div>
        );
    };

    const defaultTableColumns: IColumn[] = [
        {
            key: "reference",
            fieldName: "reference",
            name: "PROJECT",
            minWidth: 343,
            maxWidth: 520,
            isResizable: true,
            isSorted: true,
            onColumnClick: onColumnClick,
        },
        {
            key: "projectTaskGroupName",
            fieldName: "projectTaskGroupName",
            name: "STAGE",
            minWidth: 343,
            maxWidth: 520,
            isResizable: true,
            isSorted: true,
            onColumnClick: onColumnClick,
        },
        {
            key: "projectTaskName",
            fieldName: "projectTaskName",
            name: "TASK",
            minWidth: 343,
            maxWidth: 520,
            isResizable: true,
            isSorted: true,
            onColumnClick: onColumnClick,
        },
        {
            key: "projectLeadName",
            fieldName: "projectLeadName",
            name: "PROJECT LEAD",
            minWidth: 175,
            maxWidth: 200,
            isResizable: true,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: onRenderProjectLeadName,
        },
        {
            key: "remainingProjectTaskBillableEffort",
            fieldName: "remainingProjectTaskBillableEffort",
            name: "REMAINING HOURS",
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            isSorted: true,
            onColumnClick: onColumnClick,
        },
    ];

    const [columns, setColumns] = useState<IColumn[]>(defaultTableColumns);

    // #endregion Code Behind

    const renderAssignTaskPanel = () => {
        return (
            <Panel
                hasCloseButton={false}
                isOpen={viewModel.canDisplayAssignTask}
                isLightDismiss
                onDismiss={(ev) => {
                    viewModel.cancelAssignTaskCommand.execute();
                }}
                type={PanelType.custom}
                customWidth="475px"
                styles={PanelStyles}
            >
                <AssignTaskView viewModel={viewModel.assignTaskViewModel!} />
            </Panel>
        );
    };

    return (
        <Box>
            {/* Filter Bar */}
            <Box display={"flex"} justifyContent={"center"} alignItems={"space-between"} flexDirection="column">
                <AutoGrid m={0} columnGap="15px" width="75%" dc={"auto 205px 30px 205px "}>
                    <ThemedEditText
                        command={viewModel.updateFilterKeywordCommand}
                        placeholder={"Keyword search"}
                        prefix={<SearchIcon />}
                        styles={controlStyles}
                        value={() => viewModel.getValue("filterKeyword")}
                    />
                    <ThemedButton
                        styles={{
                            root: {
                                height: "38px",
                            },
                        }}
                        paletteColor="primary"
                        command={viewModel.clearFiltersCommand}
                        size="larger"
                        displayName="Clear Filters"
                    />
                    <ExportCSVButton command={viewModel.onDownloadProjectTaskAssignmentsXLSXCommandAsync} isBusy={viewModel.isBusyProcessingData} />

                    <Box display={"flex"} alignItems={"center"}>
                        <ThemedText fontStyle="h5">Remaining hours: {viewModel.filteredRemainingProjectTaskBillableEffort}</ThemedText>
                    </Box>
                </AutoGrid>

                <FormDottedSeparator />
                <AutoGrid columnGap="15px" width="75%" dc={"1fr 1fr 1fr"}>
                    <ThemedComboBox
                        command={viewModel.updateFilterProjectCommand}
                        options={viewModel.projects}
                        placeholder="All statuses"
                        size="larger"
                        value={() => viewModel.getValue("filterProjectId")}
                    />
                    <ThemedComboBox
                        command={viewModel.updateFilterProjectTaskGroupCommand}
                        options={viewModel.taskGroups}
                        placeholder="All stages"
                        size="larger"
                        value={() => viewModel.getValue("filterProjectTaskGroupName")}
                    />
                    <ThemedComboBox
                        command={viewModel.updateFilterProjectLeadCommand}
                        options={viewModel.projectLeads}
                        placeholder="All leads"
                        size="larger"
                        value={() => viewModel.getValue("filterProjectLeadId")}
                    />
                </AutoGrid>
            </Box>
            <ThemedDataTable onRenderRow={onRenderRow} getKey={getRowKey} items={viewModel.filteredAndSortedTasks} columns={columns} />

            {/* Display assign task panel */}
            {viewModel.canDisplayAssignTask && renderAssignTaskPanel()}
        </Box>
    );
});
