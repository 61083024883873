import { ModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

export class DateModel extends ModelBase<DateModel> {
    public date: Date;
    public numberOfDays: number;

    constructor(date: Date, numberOfDays: number = 1) {
        super();

        this.date = date;
        this.numberOfDays = numberOfDays;

        makeObservable(this, {});
    }
}
