import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

import { AutoGrid, Box, Nullcommand, ThemedButton, ThemedText, theme } from "Application";
import { InvoiceHistoryViewModel } from "./InvoiceHistoryViewModel";
import { InvoiceHistoryModel } from "./InvoiceHistoryModel";
import moment from "moment";

interface IInvoiceHistoryViewProps {
    model: InvoiceHistoryModel;
}

export const InvoiceHistoryView: React.FC<IInvoiceHistoryViewProps> = observer(({ model }) => {
    const [viewModel] = useState(() => new InvoiceHistoryViewModel(model));

    const gridColumns = "1fr 1fr 1.3fr";
    const gridColumnGap = "5px";

    const renderCapacities = () => (
        <Box>
            {/* Collection of capacities by month */}
            {viewModel.model.capacities.map((c) => {
                return (
                    <AutoGrid key={model.KEY} display={"flex"} mc={gridColumns} tc={gridColumns} dc={gridColumns} columnGap={gridColumnGap} mt="0" mb="2px">
                        <ThemedText fontStyle="superSmall" color="secondary">
                            {moment(c.month).format("MMMM")}
                        </ThemedText>

                        <ThemedText fontStyle="superSmall">{viewModel.formattedHours(c)}</ThemedText>
                        <ThemedText fontStyle="superSmall">{viewModel.formattedValue(c)}</ThemedText>
                    </AutoGrid>
                );
            })}
            {/* Total capacity */}
        </Box>
    );

    const renderTotal = () => (
        <Box marginTop={15}>
            <AutoGrid display={"flex"} mc={gridColumns} tc={gridColumns} dc={gridColumns} columnGap={gridColumnGap} mt="0" mb="2px">
                <ThemedText fontStyle="superSmallBold">Total</ThemedText>
                <ThemedText fontStyle="superSmallBold">{viewModel.formattedTotalHours}</ThemedText>
                <ThemedText fontStyle="superSmallBold">{viewModel.formattedTotalValue}</ThemedText>
            </AutoGrid>
        </Box>
    );

    return (
        <>
            {renderCapacities()}
            {renderTotal()}
        </>
    );
});
