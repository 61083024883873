import { FieldType, ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

import { ICommand, RelayCommand } from "Application";
import { LostWithNoteModel, LostWithNoteModelValidator } from "./LostWithNoteModel";
import { container } from "tsyringe";
import { LookupStore } from "Stores/Domain";
import { ENQUIRY_LOST } from "Views/Shared/Constants";

export class LostWithNoteViewModel extends ViewModelBase<LostWithNoteModel> {
    private lookupStore = container.resolve(LookupStore);
    private title: string;
    private message: string;
    public parentCancelDisplayLostWithNotesCommand: ICommand;
    public parentUpdateFromLostWithNotesCommand: ICommand;

    constructor(
        title: string,
        message: string,
        cancelDisplayLostWithNotesCommand: ICommand,
        updateFromLostWithNotesCommand: ICommand,
        configureServicesModel: LostWithNoteModel = new LostWithNoteModel(),
    ) {
        super(configureServicesModel);
        this.setValidator(new LostWithNoteModelValidator());
        this.title = title;
        this.message = message;

        this.parentCancelDisplayLostWithNotesCommand = cancelDisplayLostWithNotesCommand;
        this.parentUpdateFromLostWithNotesCommand = updateFromLostWithNotesCommand;

        makeObservable(this, {
            // Observables
        });
    }

    public dispose = (): void => {};

    // #region Properties

    public get displayName() {
        return this.title;
    }

    public get displayMessage() {
        return this.message;
    }

    public get lostEnquiryNoteType() {
        return this.lookupStore.noteTypes.find((t) => t.type == ENQUIRY_LOST);
    }

    public get lostEnquiryNoteNoteSubTypeOptions() {
        const retVal = this.lookupStore.getNoteSubTypesByNoteTypeId(this.lostEnquiryNoteType!.id).map((item) => {
            return {
                key: item.key,
                text: item.text,
            };
        });
        return retVal;
    }

    // #endregion Properties

    // #region Commands

    public updateNotesCommand = new RelayCommand((notes: string) => this.updateField("noteDetail", notes));

    public updateReasonItemCommand = new RelayCommand((id: string | null) => {
        this.updateField("noteSubTypeId", id);
    });

    public updateFromLostWithNotesCommand = new RelayCommand(() => {
        if (this.isModelValid()) {
            this.parentUpdateFromLostWithNotesCommand.execute();
        }
    });

    // #endregion Commands

    // #region Supporting

    private updateField(fieldName: keyof FieldType<LostWithNoteModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    // #endregion Supporting
}
