import { IStyle, mergeStyleSets } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React from "react";

import { AutoGrid, theme, ThemedButton, ThemedComboBox, ThemedEditText, ThemedText, ThemedTransparentIconButton } from "Application";
import { CrossSVG } from "Assets/CrossSVG";
import { FormContainerRow, FormSeparator } from "Views/Shared/SharedComponents";
import { TaskAssignmentGroupViewModel } from "./TaskAssignmentGroupViewModel";
import { TaskAssignmentView } from "./SubViews/TaskAssignmentView";

interface IProps {
    viewModel: TaskAssignmentGroupViewModel | null;
}

interface ITaskAssignmentGroupViewStyles {
    root: IStyle;
    header: IStyle;
    body: IStyle;
    taskContainer: IStyle;
    footer: IStyle;
    iconButton: IStyle;
}

const styleDefinitions: ITaskAssignmentGroupViewStyles = {
    root: {
        width: "1000px",
    },
    header: {
        alignItems: "center",
        backgroundColor: "#F3F3F3",
        display: "flex",
        height: "75px",
        justifyContent: "space-between",
        padding: "20px 30px",
    },
    body: {
        padding: "30px 30px 0 30px",
    },
    taskContainer: {
        maxHeight: "190px",
        overflowY: "auto",
    },
    footer: {
        display: "flex",
        height: "75px",
        padding: "20px 30px",
    },
    iconButton: {
        g: {
            stroke: theme.palette.defaultTr.text,
        },
    },
};

export const TaskAssignmentGroupView: React.FC<IProps> = observer((props) => {
    // #region Code Behind

    const viewModel = props.viewModel;
    const styles = mergeStyleSets(styleDefinitions);

    // #endregion Code Behind

    if (viewModel) {
        return (
            <div className={styles.root}>
                <div className={styles.header}>
                    <ThemedText fontStyle="h2">Link tasks</ThemedText>
                    <ThemedTransparentIconButton command={viewModel.cancelCommand} icon={<CrossSVG className={styles.iconButton} />} shape="round" />
                </div>
                <div className={styles.body}>
                    <div className={styles.taskContainer}>
                        {viewModel.taskAssignmentViewModels.map((vm) => {
                            return <TaskAssignmentView key={vm.KEY} viewModel={vm} />;
                        })}
                    </div>
                    <FormSeparator />
                    {/* <AutoGrid dc={"1fr"}>
                        <ThemedEditText
                            displayName=""
                            command={viewModel.updateNameCommand}
                            placeholder="Type the name for this group of assignments"
                            value={() => viewModel.getValue("name")}
                            validationMessage={() => viewModel.getError("name")}
                        />
                    </AutoGrid> */}
                    <FormContainerRow dc={"100%"} mb="5px !important">
                        <ThemedComboBox
                            command={viewModel.updateDescriptionViaTemplateNoteCommand}
                            options={viewModel.templateNotes}
                            placeholder="Template task notes"
                            size="larger"
                            value={() => viewModel.getValue("id")}
                        />
                        <ThemedEditText
                            command={viewModel.updateDescriptionCommand}
                            displayName="Task notes"
                            isMultiline
                            placeholder="Please enter any notes for the task"
                            styles={{ fieldGroup: { minHeight: 120 } }}
                            validationMessage={() => viewModel.getError("note")}
                            value={() => viewModel.getValue("note") ?? ""}
                        />
                    </FormContainerRow>
                </div>
                <AutoGrid m={0} className={styles.footer} columnGap={"30px"} dc={"135px 135px"}>
                    <ThemedButton styles={{ root: { padding: "0 5px" } }} paletteColor={"primary"} displayName="Link Task" command={viewModel.submitCommand} />
                    <ThemedButton styles={{ root: { padding: "0 5px" } }} displayName="Cancel" command={viewModel.cancelCommand} />
                </AutoGrid>
            </div>
        );
    }

    return <React.Fragment />;
});
