import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

import { Validator } from "Application/Validation";
import { CustomerModel } from "Views/Shared/Customer/CustomerModel";
import { NotesModel } from "Views/Shared/Note/NotesModel";
import { EnquirySubModel } from "./SubViews/EnquirySubView/EnquirySubModel";

export class EnquiryModel extends ModelBase<EnquiryModel> {
    // Note: some observables are predefined in the ModelBase class.
    // If any errors arise, check there to see if the property exists
    // and if so, delete it from here.
    // public createdDate: Date | null = null;
    // public updatedDate: Date | null = null;

    public id: string | null = null;
    public enquiryTypeId: string | null = null;
    public enquiryStatusId: string | null = null;
    public reference = "";
    public title = "";
    public projectId: string | null = null;

    public createdByUserId = "";
    public updatedByUserId: string | null = null;

    public isLost: boolean = false;
    public isQuote: boolean = false;

    // Hosted in own models.
    public enquirySubModel = new EnquirySubModel();
    public customer = new CustomerModel();
    public notes = new NotesModel();

    constructor() {
        super();
        makeObservable(this, {
            // Observables
            id: observable,
            enquiryTypeId: observable,
            enquiryStatusId: observable,
            reference: observable,
            title: observable,

            createdByUserId: observable,
            updatedByUserId: observable,

            isLost: observable,
            isQuote: observable,
        });
    }

    // #region Business Logic

    /**
     * Determines if the form data can be edited.
     */
    public hasEditPermission() {
        return !(this.isLost || this.isQuote);
    }

    // #endregion Business Logic
}

export class EnquiryModelValidator extends Validator<EnquiryModel> {
    constructor() {
        super();

        // Sub-Enquiry
        // The quote will take care of its own rules.

        // Customer
        // The customer will take care of its own rules.

        // Notes
        // The notes will take care of its own rules.
    }
}
