import { ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

import { ICommand } from "Application";
import { DownloadCalloutModel } from "./DownloadCalloutModel";

export class DownloadCalloutViewModel extends ViewModelBase<DownloadCalloutModel> {
    public parentDownloadLetterDocumentCommand: ICommand;
    public parentDownloadTasksAndCostsDocumentCommand: ICommand;
    public parentDownloadTermsAndConditionsDocumentCommand: ICommand;

    constructor(downloadLetterDocumentCommand: ICommand, downloadTasksAndCostsDocumentCommand: ICommand, downloadTermsAndConditionsDocumentCommand: ICommand) {
        super(new DownloadCalloutModel(), false);

        this.parentDownloadLetterDocumentCommand = downloadLetterDocumentCommand;
        this.parentDownloadTasksAndCostsDocumentCommand = downloadTasksAndCostsDocumentCommand;
        this.parentDownloadTermsAndConditionsDocumentCommand = downloadTermsAndConditionsDocumentCommand;

        makeObservable(this, {});
    }
}
