import { isEmptyOrWhitespace } from "@shoothill/core";

import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { NoteViewModel } from "Views/Shared/Note/NoteViewModel";
import { NoteModel } from "Views/Shared/Note/NoteModel";
import { FileModel } from "Views/Shared/Files/FileModel";
import { QuoteFormViewModel } from "../Form/QuoteFormViewModel";

class NoteDocumentRequest {
    public id: string | null = null;
}

class Request {
    // #region Request Data

    public id: string | null = null;
    public enquiryId: string | null = null;
    public quoteId: string | null = null;
    public projectId: string | null = null;
    public noteTypeId: string | null = null;
    public noteDetail: string = "";
    public noteDate: Date | null = null;
    public noteTime: Date | null = null;
    public cancelled: boolean = false;
    public editMode: boolean = false;
    public deleted: boolean = false;
    public edited: boolean = false;
    public added: boolean = false;

    noteDocuments: NoteDocumentRequest[] = [];

    // #endregion Request Data
}

class NoteDocumentResponse {
    id: string = "";
    noteId: string = "";
    fileName: string = "";
    fileSizeBytes: number = 0;
    mimeType: string = "";
    documentUrl: string = "";
}

class Response {
    id: string = "";
    noteTypeId: string = "";
    noteDate: Date | null = null;
    noteTime: string = "";
    noteDetail: string = "";
    createdDate: Date | null = null;
    createdByUserId: string = "";
    createdByUserFirstName: string = "";
    createdByUserLastName: string = "";
    lastUpdatedDate: Date | null = null;
    lastUpdatedByUserId: string = "";
    lastUpdatedByUserFirstName: string | null = null;
    lastUpdatedByUserLastName: string | null = null;

    noteDocuments: NoteDocumentResponse[] = [];
}

export class POSTSaveQuoteNoteEndpoint extends Endpoint<FormData, Response> {
    private readonly quoteFormViewModel: QuoteFormViewModel;
    private readonly viewModel: NoteViewModel;

    constructor(quoteFormViewModel: QuoteFormViewModel, viewModel: NoteViewModel) {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Note.SaveNote);
        this.quoteFormViewModel = quoteFormViewModel;
        this.AllowFileUploads();
        this.viewModel = viewModel;
    }

    public async HandleRequestAsync(model: NoteModel): Promise<any> {
        const request = new Request();

        model.toRequest(request);

        request.quoteId = this.quoteFormViewModel.model.id;

        const noteDoucmentRequests =
            this?.viewModel?.editNoteViewModel?.filesViewModel?.model.files
                .filter((f) => !isEmptyOrWhitespace(f.id))
                .map((f) => {
                    const nd = new NoteDocumentRequest();

                    nd.id = f.id;

                    return nd;
                }) ?? [];

        request.noteDocuments = noteDoucmentRequests;

        const formData = new FormData();

        formData.append("data", JSON.stringify(request));

        for (const file of this?.viewModel?.editNoteViewModel?.filesViewModel?.model?.files!) {
            formData.append(file.KEY, file.file!);
        }

        return Promise.resolve(formData);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        this.viewModel.model.fromResponse(response);
        this.viewModel.filesViewModel.model.files.replace(
            response.noteDocuments.map((nd) => {
                const model = new FileModel();

                model.fromResponse(nd);

                return model;
            }) ?? [],
        );
    }
}
