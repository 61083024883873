import { FieldType, isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

import { ICommand, IKeyState, RelayCommand } from "Application";
import { DeclineRequestModel } from "./DeclineRequestModel";
import { LeaveType } from "Views/Leave/Shared/LeaveType";

export class DeclineRequestViewModel extends ViewModelBase<DeclineRequestModel> {
    // Callback commands to the parent viewmodel.
    public parentSubmitCommand: ICommand;
    public parentCancelCommand: ICommand;

    constructor(model: DeclineRequestModel, submitCommand: ICommand, cancelCommand: ICommand) {
        super(model, false);

        // Commands to be called on the parent viewmodel.
        this.parentSubmitCommand = submitCommand;
        this.parentCancelCommand = cancelCommand;

        makeObservable(this, {});
    }

    //#region Properties

    public get canDisplayResponseReason() {
        return this.model.leaveRequest.leaveType?.type !== LeaveType.CANCEL;
    }

    //#endregion Properties

    //#region Commands

    public updateResponseReasonCommand: ICommand = new RelayCommand((value: string) => {
        const responseName = !isEmptyOrWhitespace(value) ? value : null;

        this.updateField("responseReason", responseName);
    });

    public submitCommand = new RelayCommand(
        () => {
            if (this.isModelValid()) {
                this.parentSubmitCommand.execute();
            }
        },
        () => this.parentSubmitCommand.canExecute(),
    );

    public cancelCommand = new RelayCommand(
        () => this.parentCancelCommand.execute(),
        () => this.parentCancelCommand.canExecute(),
    );

    //#endregion Commands

    // #region Supporting

    private updateField(fieldName: keyof FieldType<DeclineRequestModel>, value: any, keyState?: IKeyState) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    // #endregion Supporting
}
