import { ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";
import moment from "moment";

import { EffortEventModel } from "./EffortEventModel";

export class EffortEventViewModel extends ViewModelBase<EffortEventModel> {
    constructor(model: EffortEventModel) {
        super(model, false);

        makeObservable(this, {});
    }

    public get date() {
        return this.model.date;
    }

    public get formattedDate() {
        return moment(this.date).format("D MMM");
    }

    public get displayProgrammedEffort() {
        return this.model.programmedEffort;
    }

    public get displayAvailableEffort() {
        return this.model.availableEffort;
    }

    public get canDisplayEffort() {
        return this.model.availableEffort > 0;
    }
}
