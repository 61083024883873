import { Modal } from "@fluentui/react";
import styled from "@emotion/styled";
import * as Mui from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import { Box, ThemedComboBox, ThemedText, ThemedEditText, setPageTitle, ThemedCheckBox } from "Application";
import { AutoGrid } from "Components/Grid";
import { Spacer } from "Components/Spacer";
import { ThemedLoader } from "Styles/ThemedPrimitives/Style1/ThemedLoader";
import { ThemedError } from "Styles/ThemedPrimitives/Style1/ThemedError";
import { FormContainer, FormSubContainer, NavigationContainer, PageContainer, TitleContainer } from "Views/Shared/SharedComponents";
import { DeleteUserView } from "./ModalViews/DeleteUserView";
import { PersonaUploaderView } from "./SubViews/PersonaUploaderView";
import { ButtonClusterSubView } from "./SubViews/ButtonClusterSubView";
import { UserViewModel } from "./UserViewModel";

export const ContainerBox = styled(Mui.Box)``;
export const DetailContainer = styled(Mui.Box)`
    position: relative;
`;

export const UserView: React.FC = observer(() => {
    // #region Code Behind

    const { id } = useParams();
    const [viewModel] = useState(() => new UserViewModel(id));

    useEffect(() => {
        setPageTitle("User");
    }, []);

    // #endregion Code Behind

    const renderBusy = () => <ThemedLoader isOpen={viewModel.apiClient.IsBusy} />;

    const renderError = () => {
        const isOpen = viewModel.apiClient.IsSubmitted && !viewModel.apiClient.IsBusy && viewModel.apiClient.HaveValidationMessage;
        return <ThemedError command={viewModel.resetServerErrorCommand} isOpen={isOpen} errorMessage={viewModel.apiClient.ValidationMessage} />;
    };

    const renderDeleteModalContent = () => {
        return (
            <Modal isOpen={viewModel.canDisplayDelete} onDismiss={() => viewModel.closeDeleteModalCommand.execute()}>
                <DeleteUserView viewModel={viewModel} />
            </Modal>
        );
    };

    return (
        <PageContainer>
            {/* Navigation */}
            <NavigationContainer onClick={() => viewModel.navigateToUsersCommand.execute()}>
                <ThemedText>{"<"} BACK TO USERS</ThemedText>
            </NavigationContainer>

            {/* Title */}
            <TitleContainer>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <ThemedText fontStyle={"h2"}>{viewModel.displayName}</ThemedText>
                </Box>
            </TitleContainer>

            {/* Workspace */}
            <FormContainer>
                <FormSubContainer>
                    <Box p={3}>
                        <AutoGrid desktopColumns={"0.35fr 1.5fr"} rowGap="50px">
                            <DetailContainer>
                                <PersonaUploaderView viewModel={viewModel.personaFileViewModel} />
                            </DetailContainer>
                            <DetailContainer>
                                <AutoGrid desktopColumns={"1fr 1fr 1fr"} rowGap="50px">
                                    <ThemedEditText
                                        displayName="First Name*"
                                        command={viewModel.updateFirstNameCommand}
                                        placeholder={"Please enter a first name"}
                                        value={() => viewModel.getValue("firstName") ?? ""}
                                        validationMessage={() => viewModel.getError("firstName")}
                                    />
                                    <ThemedEditText
                                        displayName="Last Name*"
                                        command={viewModel.updateLastNameCommand}
                                        placeholder={"Please enter a last name"}
                                        value={() => viewModel.getValue("lastName") ?? ""}
                                        validationMessage={() => viewModel.getError("lastName")}
                                    />
                                    <ThemedEditText
                                        displayName="Email Address*"
                                        command={viewModel.updateEmailAddressCommand}
                                        placeholder={"Please enter an email address"}
                                        value={() => viewModel.getValue("emailAddress") ?? ""}
                                        validationMessage={() => viewModel.getError("emailAddress")}
                                    />
                                </AutoGrid>
                                <Spacer mt={2} />
                                <AutoGrid desktopColumns={"1fr 1fr 1fr"} rowGap={"50px"}>
                                    <ThemedComboBox
                                        displayName="Privileges*"
                                        options={viewModel.getRoles()}
                                        command={viewModel.updateSelectRoleCommand}
                                        placeholder={"Please select a role"}
                                        value={() => viewModel.getValue("currentRoleId")}
                                        validationMessage={() => viewModel.getError("currentRoleId")}
                                    />
                                    <ThemedEditText
                                        command={viewModel.updateCompanyTitleCommand}
                                        displayName="Role*"
                                        validationMessage={() => viewModel.getError("companyTitle")}
                                        value={() => viewModel.getValue("companyTitle") ?? ""}
                                    />
                                </AutoGrid>
                                <AutoGrid desktopColumns={"1fr 1fr 1fr"} rowGap={"20px"}>
                                    <ThemedCheckBox
                                        command={viewModel.updateIsAssignableCommand}
                                        displayName="Cannot be assigned to tasks"
                                        value={() => viewModel.cannotBeAssignedToTasks}
                                        paletteColor="secondary"
                                    />
                                    {viewModel.canShowUpdateInvoicingForecastVisibleCommand && (
                                        <ThemedCheckBox
                                            command={viewModel.updateCanViewInvoicingForecastCommand}
                                            displayName="Can view invoicing forecast"
                                            value={() => viewModel.getValue("isInvoicingForecastVisible")}
                                            paletteColor="secondary"
                                        />
                                    )}
                                    {viewModel.canShowUpdateInvoicingForecastVisibleCommand && <Spacer mt={2} />}
                                    {!viewModel.canShowUpdateInvoicingForecastVisibleCommand && (
                                        <>
                                            <Spacer mt={2} />
                                            <Spacer mt={2} />
                                        </>
                                    )}
                                    <ThemedCheckBox
                                        command={viewModel.updateShowInProjectOverviewDropdownCommand}
                                        displayName="Show in project overview dropdown"
                                        value={() => viewModel.getValue("isProjectOverview")}
                                        paletteColor="secondary"
                                    />
                                    <ThemedCheckBox
                                        command={viewModel.updateShowInQuoteLeadDropdownCommand}
                                        displayName="Show in quote lead dropdown"
                                        value={() => viewModel.getValue("isQuoteLead")}
                                        paletteColor="secondary"
                                    />
                                    <Spacer mt={2} />
                                </AutoGrid>
                                <Spacer mt={2} />
                                <AutoGrid desktopColumns={"1fr 1fr 1fr 1fr 1fr 1fr"} rowGap={"50px"}>
                                    <ThemedEditText
                                        command={viewModel.updateAnnualLeaveAllowanceCommand}
                                        displayName="Annual leave allowance*"
                                        validationMessage={() => viewModel.getError("annualLeaveAllowance")}
                                        value={() => viewModel.getValue("annualLeaveAllowance") ?? ""}
                                    />
                                    <ThemedEditText
                                        command={viewModel.updateDailyChargeableHoursCommand}
                                        displayName="Target chargeable hours*"
                                        validationMessage={() => viewModel.getError("dailyChargeableHours")}
                                        value={() => viewModel.getValue("dailyChargeableHours") ?? ""}
                                    />
                                </AutoGrid>
                                <AutoGrid desktopColumns={"1fr 1fr 1fr"} rowGap={"50px"}>
                                    <ThemedEditText
                                        displayName={viewModel.passwordDisplayName}
                                        command={viewModel.updatePasswordCommand}
                                        placeholder={viewModel.passwordPlaceHolderName}
                                        type="password"
                                        value={() => viewModel.getValue("password") ?? ""}
                                    />
                                    <ThemedEditText
                                        displayName={viewModel.confirmPasswordDisplayName}
                                        command={viewModel.updateConfirmPasswordCommand}
                                        placeholder={viewModel.confirmPasswordPlaceHolderName}
                                        type="password"
                                        value={() => viewModel.getValue("confirmPassword") ?? ""}
                                        validationMessage={() => viewModel.getError("confirmPassword")}
                                    />
                                </AutoGrid>
                                <AutoGrid desktopColumns={"1fr 1fr 1fr"} rowGap={"50px"}>
                                    <Box style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
                                        <ThemedText fontStyle="label" mb={"8px"} mt={"12px"}>
                                            Create and edit your signature below (for use on quotations)
                                        </ThemedText>
                                        <Editor
                                            apiKey="g828m3lar5y087wnket2v38pbvjugg2m1091afqssqvjj8ze"
                                            value={viewModel.getValue("signature")}
                                            init={{
                                                width: "100%",
                                                height: 300,
                                                menubar: false,
                                                browser_spellcheck: true,
                                                plugins:
                                                    "preview paste searchreplace autolink directionality visualblocks visualchars fullscreen link table charmap hr nonbreaking insertdatetime advlist lists wordcount image imagetools textpattern noneditable charmap contextmenu code",
                                                toolbar:
                                                    "undo redo | link image | code | bold italic underline strikethrough | subscript superscript | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | fullscreen  preview code",
                                                font_formats:
                                                    "Arapey=arapey,serif; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino;HeadingNow 55 Medium=headingnow55medium, sans-serif; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
                                                content_style:
                                                    "body { font-family: Arapey, serif; } @import url('https://basearchitects.blob.core.windows.net/fonts/ArapeyRegularFontFace.css');@import url('https://basearchitects.blob.core.windows.net/fonts/HeadingNow55MediumFontFace.css');",
                                                contextmenu: "undo redo | cut copy paste | bold italic underline | fontformats | fontsizes",
                                                images_file_types: "jpg,jpeg,png",
                                                file_picker_types: "image",
                                                images_upload_handler(blobInfo: any, success: any, failure: any) {
                                                    success("data:image/png;base64," + blobInfo.base64());
                                                },
                                            }}
                                            onEditorChange={viewModel.updateSignature}
                                        />
                                    </Box>
                                </AutoGrid>
                            </DetailContainer>
                        </AutoGrid>
                    </Box>
                </FormSubContainer>
                <ButtonClusterSubView viewModel={viewModel} />
            </FormContainer>

            {/* Busy Overlay */}
            {renderBusy()}

            {/* Error Overlay */}
            {renderError()}

            {/* Delete Modal */}
            {viewModel.canDisplayDelete && renderDeleteModalContent()}
        </PageContainer>
    );
});
