import { isEmptyOrWhitespace, ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

import { SignatoryUser } from "Application/Models/Domain";
import { Validator } from "Application/Validation";
import { QUOTESTATUS_DRAFTTYPE } from "../Constants";

export class TermsAndConditionsModel extends ModelBase<TermsAndConditionsModel> {
    // This is not part of the form model, but used for validation.
    public saveQuoteStatusType: string | null = null;
    public isQuickQuote: boolean = false;

    public termAndConditionId: string | null = null;
    public termsAndConditionsDetails: string | null = null;
    public signatoryUserId: string | null = null;

    public signatoryUsers = observable<SignatoryUser>([]);

    constructor() {
        super();

        makeObservable(this, {
            isQuickQuote: observable,

            // Observables
            termAndConditionId: observable,
            termsAndConditionsDetails: observable,
            signatoryUserId: observable,
            signatoryUsers: observable,
        });
    }
}

export class TermsAndConditionsModelValidator extends Validator<TermsAndConditionsModel> {
    constructor() {
        super();

        this.ruleFor("termAndConditionId").must({
            predicate: (termAndConditionId, model) => {
                return model.isQuickQuote ? true : model.saveQuoteStatusType === QUOTESTATUS_DRAFTTYPE ? true : !isEmptyOrWhitespace(termAndConditionId);
            },
            message: (value, model) => "Please select a value",
        });
    }
}
