import { FieldType, isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

import { ICommand, IKeyState, RelayCommand } from "Application";
import { CancelLeaveRequestModel } from "./CancelLeaveRequestModel";

export class CancelLeaveRequestViewModel extends ViewModelBase<CancelLeaveRequestModel> {
    // Callback commands to the parent viewmodel.
    private parentCancelCommand: ICommand;
    private parentSubmitCommand: ICommand;

    constructor(model: CancelLeaveRequestModel, submitCommand: ICommand, cancelCommand: ICommand) {
        super(model, false);

        this.parentCancelCommand = cancelCommand;
        this.parentSubmitCommand = submitCommand;

        makeObservable(this, {});
    }

    //#region Properties
    //#endregion Properties

    //#region Commands

    public updateResponseReasonCommand = new RelayCommand((value: string) => {
        const responseName = !isEmptyOrWhitespace(value) ? value : null;

        this.updateField("responseReason", responseName);
    });

    public submitCommand = new RelayCommand(
        () => {
            if (this.isModelValid()) {
                this.parentSubmitCommand.execute();
            }
        },
        () => this.parentSubmitCommand.canExecute(),
    );

    public cancelCommand = new RelayCommand(
        () => this.parentCancelCommand.execute(),
        () => this.parentCancelCommand.canExecute(),
    );

    //#endregion Commands

    // #region Supporting

    private updateField(fieldName: keyof FieldType<CancelLeaveRequestModel>, value: any, keyState?: IKeyState) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    // #endregion Supporting
}
