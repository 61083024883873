import { IStyle, mergeStyleSets } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React from "react";

import { ThemedText } from "Application";

interface ITaskHeaderViewStyles {
    root: IStyle;
    displayName: IStyle;
    manHours: IStyle;
    hourlyRate: IStyle;
    cost: IStyle;
}

const styleDefinitions: ITaskHeaderViewStyles = {
    root: {
        display: "flex",
        minHeight: "50px",
    },
    displayName: {
        flex: 1,
    },
    manHours: {
        alignItems: "center",
        borderLeft: "1px solid #E1E1E1",
        display: "flex",
        justifyContent: "center",
        minWidth: "110px",
    },
    hourlyRate: {
        alignItems: "center",
        borderLeft: "1px solid #E1E1E1",
        display: "flex",
        justifyContent: "center",
        minWidth: "110px",
    },
    cost: {
        alignItems: "center",
        borderLeft: "1px solid #E1E1E1",
        display: "flex",
        justifyContent: "center",
        minWidth: "175px",
    },
};

export const TaskHeaderView: React.FC = observer((props) => {
    const styles = mergeStyleSets(styleDefinitions);

    return (
        <div className={styles.root}>
            <div className={styles.displayName} />
            <div className={styles.manHours}>
                <div>
                    <ThemedText fontStyle="h6">Project hours</ThemedText>
                </div>
            </div>
            <div className={styles.hourlyRate}>
                <div>
                    <ThemedText fontStyle="h6">Hourly rate</ThemedText>
                </div>
            </div>
            <div className={styles.cost}>
                <div>
                    <ThemedText fontStyle="h6">Project cost</ThemedText>
                </div>
            </div>
        </div>
    );
});
