import { observer } from "mobx-react-lite";
import React from "react";

import { ThemedEditText, ThemedText, theme } from "Application";
import { FormContainer, FormContainerRow, FormSubContainer, PanelFiles, PanelFilesReadOnlyView } from "Views/Shared/SharedComponents";
import { NotesViewModel } from "./NotesViewModel";
import styled from "@emotion/styled";

interface INotesModelViewProps {
    viewModel: NotesViewModel;
}

const ReadOnlyTaskDescriptionContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 15px;
    border-bottom: 1px dashed ${theme.palette.field.light};
    padding-bottom: 20px;
`;

export const NotesView: React.FC<INotesModelViewProps> = observer((props) => {
    const viewModel = props.viewModel;

    return (
        <FormContainer>
            {/* task description */}
            <FormSubContainer style={{ marginBottom: "20px" }}>
                {viewModel.canEditForm && (
                    <FormContainerRow dc={"100%"} mb="5px !important">
                        <ThemedEditText
                            command={viewModel.updateDescriptionCommand}
                            displayName="Task description"
                            isMultiline
                            placeholder="Please enter any notes for the task"
                            styles={{ fieldGroup: { minHeight: 120 } }}
                            validationMessage={() => viewModel.getError("note")}
                            value={() => viewModel.note}
                        />
                    </FormContainerRow>
                )}
                {viewModel.canEditForm && <PanelFiles viewModel={props.viewModel.filesViewModel} />}
                {!viewModel.canEditForm && (
                    <FormContainerRow dc={"100%"} mb="5px !important">
                        <ReadOnlyTaskDescriptionContainer>
                            <ThemedText fontStyle="label">Task Description</ThemedText>
                            <ThemedText fontStyle="h7">{viewModel.getValue("note") ?? ""}</ThemedText>
                            <PanelFilesReadOnlyView viewModel={props.viewModel.filesViewModel} />
                        </ReadOnlyTaskDescriptionContainer>
                    </FormContainerRow>
                )}
            </FormSubContainer>
            {/* task review notes*/}
            <FormSubContainer>
                {viewModel.canEditReviewedNoteForm && (
                    <FormContainerRow dc={"100%"} mb="5px !important">
                        <ThemedEditText
                            command={viewModel.updateReviewNoteCommand}
                            displayName="Review notes"
                            isMultiline
                            placeholder="Please enter any review notes for the task"
                            styles={{ fieldGroup: { minHeight: 120 } }}
                            validationMessage={() => viewModel.getError("reviewedNote")}
                            value={() => viewModel.reviewedNote}
                        />
                    </FormContainerRow>
                )}
            </FormSubContainer>
        </FormContainer>
    );
});
