import { KeyValuePair, ModelBase } from "@shoothill/core";
import { Validator } from "Application/Validation";
import { makeObservable, observable } from "mobx";

export class AddEditPlanningApplicationModel extends ModelBase<AddEditPlanningApplicationModel> {
    public id: string | null = null;
    public projectId: string | null = null;
    public projectRef: string | null = null;
    public localAuthorityDistrictId: string | null = null;
    public planningRef: string | null = null;
    public planningApplicationStatusId: string | null = null;
    public planningApplicationTypeId: string | null = null;
    public note: string | null = null;
    public submittedDate: Date | null = null;
    public determinationDate: Date | null = null;
    public consultationDate: Date | null = null;
    public approvedDate: Date | null = null;

    //related
    public projectKeyValuePairs = observable<KeyValuePair>([]);
    constructor() {
        super();
        makeObservable(this, {
            id: observable,
            projectId: observable,
            projectRef: observable,
            localAuthorityDistrictId: observable,
            planningRef: observable,
            planningApplicationStatusId: observable,
            planningApplicationTypeId: observable,
            note: observable,
            submittedDate: observable,
            determinationDate: observable,
            approvedDate: observable,
            consultationDate: observable,
            //related
            projectKeyValuePairs: observable,
        });
    }
}

export class AddEditPlanningApplicationModelValidator extends Validator<AddEditPlanningApplicationModel> {
    constructor() {
        super();
        this.ruleFor("projectId").notNull().withMessage("Planning application project is required");
        this.ruleFor("planningApplicationStatusId").notNull().withMessage("Planning application status is required");
    }
}
