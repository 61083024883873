import styled from "@emotion/styled";
import { Callout, DirectionalHint } from "@fluentui/react";
import { darken, lighten } from "@mui/material";
import { observer } from "mobx-react-lite";
import { nanoid } from "nanoid";
import React, { useState } from "react";

import { Box } from "Application";
import InfoIcon from "Assets/InfoIcon.svg";
import { ProjectTaskAssignmentEventViewModel } from "./ProjectTaskAssignmentEventViewModel";

interface IProps {
    viewModel: ProjectTaskAssignmentEventViewModel;
}

export const ProjectTaskAssignmentEventView: React.FC<IProps> = observer((props) => {
    const viewModel = props.viewModel;
    const [callout, setIsCalloutVisible] = useState(false);
    const [buttonId] = useState(() => `callout-${nanoid()}`);

    const AssignmentInfoIcon = styled(Box)`
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        background-image: url(${InfoIcon});
        height: 18px;
        margin: 4px 4px auto auto;
        opacity: 0.33;
        width: 18px;
    `;

    const EventCell = styled(Box)`
        background: ${lighten(viewModel.backgroundColor, 0.8)};
        border-left: 5px solid ${darken(viewModel.backgroundColor, 0.25)};
        display: flex;
        align-items: flex-start;
        flex: 1;
        cursor: pointer;
        line-height: 1.25;
        max-height: 48px;
        overflow: hidden;
        flex-direction: column;
    `;

    const EventCallout = styled(Box)`
        background: ${lighten(viewModel.backgroundColor, 0.8)};
        border-left: 5px solid ${darken(viewModel.backgroundColor, 0.25)};
        display: flex;
        flex: 1;
        min-width: 260px;
        min-height: 60px;
    `;

    const EventDisplayName = styled(Box)`
        font-size: 10px;
        line-height: 1.25;
        font-family: "Poppins";
        flex: 1;
    `;

    const EventDisplayStatusName = styled(Box)`
        background: ${lighten(viewModel.backgroundColor, 0.5)};
        color: white;
        font-size: 10px;
        margin-top: 5px;
        margin-left: 5px;
        height: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 2px 6px 0px 6px;
    `;

    const EventHeader = styled(Box)`
        display: flex;
        flex: row;
        flex: 1;
        width: 100%;
    `;

    return (
        <>
            <EventCell id={buttonId} onClick={() => viewModel.displayProjectTaskAssignmentCommand.execute()}>
                <EventHeader>
                    <EventDisplayStatusName>{viewModel.displayStatusName.toLocaleUpperCase()}</EventDisplayStatusName>
                    <AssignmentInfoIcon
                        onClick={(e: any) => {
                            e.stopPropagation();
                            setIsCalloutVisible(true);
                        }}
                    />
                </EventHeader>
                <EventDisplayName padding={"0px 12px 7px 7px"}>{viewModel.displayTaskNote}</EventDisplayName>
            </EventCell>
            {callout && (
                <Callout directionalHint={DirectionalHint.bottomAutoEdge} onDismiss={() => setIsCalloutVisible(false)} target={`#${buttonId}`}>
                    <EventCallout maxWidth={"600px"} padding={"7px 12px 7px 7px"}>
                        <Box flex={1}>
                            <EventDisplayName>{viewModel.displayTaskNote}</EventDisplayName>
                        </Box>
                    </EventCallout>
                </Callout>
            )}
        </>
    );
});
