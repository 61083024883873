/* eslint-disable @typescript-eslint/no-use-before-define */
import React from "react";
import { Typography } from "@mui/material";

export const SessionExpiredView: React.FC = () => {
    return (
        <Typography variant={"h2"} color={"textSecondary"}>
            Your session has expired!
        </Typography>
    );
};
