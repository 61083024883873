import { isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { computed, makeObservable, observable } from "mobx";
import { container } from "tsyringe";

import { RelayCommand } from "Application";
import { AppUrls } from "AppUrls";
import { LookupStore } from "Stores/Domain";
import { SubmitWithNoteModel } from "./ModalViews/SubmitWithNoteModel";
import { SubmitWithNoteViewModel } from "./ModalViews/SubmitWithNoteViewModel";
import { QuoteFormModel, QuoteFormValidator } from "./QuoteFormModel";
import { CustomerViewModel } from "Views/Shared/Customer/CustomerViewModel";
import { LetterViewModel } from "Views/Shared/Letter/LetterViewModel";
import { NotesViewModel } from "Views/Shared/Note/NotesViewModel";
import { NoteViewModel } from "Views/Shared/Note/NoteViewModel";
import { QuoteViewModel } from "Views/Shared/Quote/QuoteViewModel";
import { ServicesViewModel } from "Views/Shared/Services/ServicesViewModel";
import { TermsAndConditionsViewModel } from "Views/Shared/TermsAndConditions/TermsAndConditionsViewModel";
import { NOTECONTEXT_QUOTE, QUOTE_LOST } from "Views/Shared/Constants";
import { DeleteConfirmationViewModel } from "./ModalViews/DeleteConfirmationViewModel";
import { ConvertToProjectViewModel } from "./ModalViews/ConvertToProjectViewModel";
import { LostWithNoteViewModel } from "./ModalViews/LostNoteView/LostWithNoteViewModel";
import { LostWithNoteModel } from "./ModalViews/LostNoteView/LostWithNoteModel";
import { IFormQuoteContainerViewModel } from "../IFormQuoteContainerViewModel";
import { AutoSaveStore } from "Stores/Domain/AutoSaveStore";

export class QuoteFormViewModel extends ViewModelBase<QuoteFormModel> {
    private lookupStore = container.resolve(LookupStore);
    private autoSaveStore = container.resolve(AutoSaveStore);
    private containerViewModel: IFormQuoteContainerViewModel;

    // Hosted in its own viewmodel.
    public quoteViewModel: QuoteViewModel;
    public customerViewModel: CustomerViewModel;
    public notesViewModel: NotesViewModel;
    public servicesViewModel: ServicesViewModel;
    public termsAndConditionsViewModel: TermsAndConditionsViewModel;
    public letterViewModel: LetterViewModel;

    public submitWithNoteViewModel: SubmitWithNoteViewModel | null = null;
    public deleteConfirmationViewModel: DeleteConfirmationViewModel | null = null;
    public convertToProjectViewModel: ConvertToProjectViewModel | null = null;

    public lostWithNoteViewModel: LostWithNoteViewModel | null = null;

    constructor(quoteForm: QuoteFormModel, containerViewModel: IFormQuoteContainerViewModel) {
        super(quoteForm);

        this.setValidator(new QuoteFormValidator());

        this.containerViewModel = containerViewModel;

        this.quoteViewModel = new QuoteViewModel(this.model.quote);
        this.customerViewModel = new CustomerViewModel(this.model.customer);
        this.notesViewModel = new NotesViewModel(this.model.notesModel, containerViewModel.saveQuoteNoteCommand, NOTECONTEXT_QUOTE);
        this.servicesViewModel = new ServicesViewModel(this.model.servicesModel);
        this.termsAndConditionsViewModel = new TermsAndConditionsViewModel(this.model.termsAndConditionsModel);
        this.letterViewModel = new LetterViewModel(this.model.letterModel);

        makeObservable(this, {
            // Observables
            submitWithNoteViewModel: observable,
            deleteConfirmationViewModel: observable,
            convertToProjectViewModel: observable,
            lostWithNoteViewModel: observable,

            // Computeds
            canDisplayNotes: computed,
            canDisplayDeleteConfirmation: computed,
            hasEditPermission: computed,
            hasSubmitPermission: computed,
            hasDeletePermission: computed,
            canAddNewNote: computed,

            canDisplaySaveQuoteAsDraftCommand: computed,
            canDisplaySaveQuoteAsPendingApprovalCommand: computed,
            canDisplayDisplayGenerateQuoteCommand: computed,
            canDisplayGenerateQuoteCommand: computed,
            canDisplayDisplayDeleteQuoteCommand: computed,
            canDisplayDeleteQuoteCommand: computed,
            canDisplayDisplayConvertQuoteToProjectCommand: computed,
            canDisplayConvertQuoteToProjectCommand: computed,
            canDisplayApproveQuoteCommand: computed,
            canDisplayDisplayRejectQuoteCommand: computed,
            canDisplayRejectQuoteCommand: computed,
            canDisplayDownloadAndSendQuoteCommand: computed,
            canDisplayDisplayReviseQuoteCommand: computed,
            canDisplayReviseQuoteCommand: computed,
            canDisplayRevertLostQuoteCommand: computed,
            canDisplayMarkQuoteAsLostCommand: computed,
            canDisplayDownloadPDFFileCommand: computed,
            canDisplaySaveQuoteNoteCommand: computed,
            canDisplayPreviewQuoteLetterCommand: computed,
            canDisplayPreviewTermsAndConditionsCommand: computed,
            canDisplayPreviewTasksAndCostsDocumentCommand: computed,
        });
    }

    /**
     * Disposes any resources.
     */
    public dispose = (): void => {
        this.notesViewModel.dispose();
        this.servicesViewModel.dispose();
    };

    // #region Properties

    public get isAutoSaving() {
        return this.autoSaveStore.getAutoSave;
    }

    public get canDisplayNotes() {
        return !isEmptyOrWhitespace(this.model.id);
    }

    public get canDisplaySubmitWithNote(): boolean {
        return this.submitWithNoteViewModel !== null;
    }

    public get canDisplayDeleteConfirmation(): boolean {
        return this.deleteConfirmationViewModel !== null;
    }

    public get canDisplayConvertToProjectConfirmation(): boolean {
        return this.convertToProjectViewModel !== null;
    }

    public get hasEditPermission(): boolean {
        return this.model.hasEditPermission(this.lookupStore.quoteStatuses, this.lookupStore.AccountStore.UserRoles);
    }

    public get hasSubmitPermission(): boolean {
        return this.model.hasSubmitPermission(this.lookupStore.quoteStatuses, this.lookupStore.AccountStore.UserRoles);
    }

    public get hasDeletePermission(): boolean {
        return this.model.hasDeletePermission(this.lookupStore.quoteStatuses, this.lookupStore.AccountStore.UserRoles);
    }

    public get canAddNewNote(): boolean {
        return this.model.canAddNote(this.lookupStore.quoteStatuses);
    }

    public get lostQuoteNoteType() {
        return this.lookupStore.noteTypes.find((t) => t.type == QUOTE_LOST);
    }

    public get lostQuoteNoteNoteSubTypeOptions() {
        const retVal = this.lookupStore.getNoteSubTypesByNoteTypeId(this.lostQuoteNoteType!.id).map((item) => {
            return {
                key: item.key,
                text: item.text,
            };
        });
        return retVal;
    }

    public get canDisplayLostWithNote(): boolean {
        return this.lostWithNoteViewModel !== null;
    }

    // #endregion Properties

    // #region Commands

    public cancelSubmitWithNoteCommand = new RelayCommand(() => {
        this.submitWithNoteViewModel = null;
    });

    // Save Quote as Draft.
    public get canDisplaySaveQuoteAsDraftCommand() {
        return this.hasSubmitPermission && this.containerViewModel.canDisplaySaveQuoteAsDraftCommand;
    }

    public saveQuoteAsDraftCommand = new RelayCommand(
        () => this.containerViewModel.saveQuoteAsDraftCommand.execute(),
        () => this.hasSubmitPermission && this.containerViewModel.saveQuoteAsDraftCommand.canExecute(),
    );

    // Save Quote as Pending Approval.
    public get canDisplaySaveQuoteAsPendingApprovalCommand() {
        return this.hasSubmitPermission && this.containerViewModel.canDisplaySaveQuoteAsPendingApprovalCommand;
    }

    public saveQuoteAsPendingApprovalCommand = new RelayCommand(
        () => this.containerViewModel.saveQuoteAsPendingApprovalCommand.execute(),
        () => this.hasSubmitPermission && this.containerViewModel.saveQuoteAsPendingApprovalCommand.canExecute(),
    );

    // Generate Quote.
    public get canDisplayDisplayGenerateQuoteCommand() {
        return this.canDisplayGenerateQuoteCommand;
    }

    public displayGenerateQuoteCommand = new RelayCommand(
        () => {
            const submitWithNoteViewModel = new SubmitWithNoteViewModel(
                new SubmitWithNoteModel(),
                "Generate quote",
                "Please add any notes about the quote you wish to get approved.",
                this.cancelSubmitWithNoteCommand,
                this.generateQuoteCommand,
            );

            this.submitWithNoteViewModel = submitWithNoteViewModel;
        },
        () => this.generateQuoteCommand.canExecute(),
    );

    public get canDisplayGenerateQuoteCommand() {
        return this.containerViewModel.canDisplayGenerateQuoteCommand;
    }

    public generateQuoteCommand = new RelayCommand(
        () => {
            this.containerViewModel.generateQuoteCommand.execute();
            this.submitWithNoteViewModel = null;
        },
        () => this.containerViewModel.generateQuoteCommand.canExecute(),
    );

    // Delete Quote.
    public get canDisplayDisplayDeleteQuoteCommand() {
        return this.canDisplayDeleteQuoteCommand;
    }

    public displayDeleteQuoteCommand = new RelayCommand(
        () => {
            this.deleteConfirmationViewModel = new DeleteConfirmationViewModel(this.cancelDeleteConfirmationCommand, this.deleteQuoteCommand);
        },
        () => this.deleteQuoteCommand.canExecute(),
    );

    public get canDisplayDeleteQuoteCommand() {
        return this.hasDeletePermission && this.containerViewModel.canDisplayDeleteQuoteCommand;
    }

    public deleteQuoteCommand = new RelayCommand(
        () => {
            this.containerViewModel.deleteQuoteCommand.execute();
            this.deleteConfirmationViewModel = null;
        },
        () => this.hasDeletePermission && this.containerViewModel.deleteQuoteCommand.canExecute(),
    );

    public cancelDeleteConfirmationCommand = new RelayCommand(() => {
        this.deleteConfirmationViewModel = null;
    });

    // Convert Quote to Project.
    public get canDisplayDisplayConvertQuoteToProjectCommand() {
        return this.canDisplayConvertQuoteToProjectCommand;
    }

    public displayConvertQuoteToProjectCommand = new RelayCommand(
        () => {
            this.convertToProjectViewModel = new ConvertToProjectViewModel(this.cancelConvertToProjectConfirmationCommand, this.convertQuoteToProjectCommand);
        },
        () => this.convertQuoteToProjectCommand.canExecute(),
    );

    public get canDisplayConvertQuoteToProjectCommand() {
        return this.hasSubmitPermission && this.containerViewModel.canDisplayConvertQuoteToProjectCommand;
    }

    public convertQuoteToProjectCommand = new RelayCommand(
        () => {
            this.containerViewModel.convertQuoteToProjectCommand.execute();
            this.convertToProjectViewModel = null;
        },
        () => this.hasSubmitPermission && this.containerViewModel.convertQuoteToProjectCommand.canExecute(),
    );

    public cancelConvertToProjectConfirmationCommand = new RelayCommand(() => {
        this.convertToProjectViewModel = null;
    });

    // Approve Quote.
    public get canDisplayApproveQuoteCommand() {
        return this.hasSubmitPermission && this.containerViewModel.canDisplayApproveQuoteCommand;
    }

    public approveQuoteCommand = new RelayCommand(
        () => this.containerViewModel.approveQuoteCommand.execute(),
        () => this.hasSubmitPermission && this.containerViewModel.approveQuoteCommand.canExecute(),
    );

    // Reject Quote.
    public get canDisplayDisplayRejectQuoteCommand() {
        return this.canDisplayRejectQuoteCommand;
    }

    public displayRejectQuoteCommand = new RelayCommand(
        () => {
            const submitWithNoteViewModel = new SubmitWithNoteViewModel(
                new SubmitWithNoteModel(),
                "Reject quote",
                "Please give the reason why the quote has not been accepted.",
                this.cancelSubmitWithNoteCommand,
                this.rejectQuoteCommand,
            );

            this.submitWithNoteViewModel = submitWithNoteViewModel;
        },
        () => this.hasSubmitPermission && this.rejectQuoteCommand.canExecute(),
    );

    public get canDisplayRejectQuoteCommand() {
        return this.hasSubmitPermission && this.containerViewModel.canDisplayRejectQuoteCommand;
    }

    public rejectQuoteCommand = new RelayCommand(
        () => {
            this.containerViewModel.rejectQuoteCommand.execute();
            this.submitWithNoteViewModel = null;
        },
        () => this.hasSubmitPermission && this.containerViewModel.rejectQuoteCommand.canExecute(),
    );

    // Download and Send Quote.
    public get canDisplayDownloadAndSendQuoteCommand() {
        return this.hasSubmitPermission && this.containerViewModel.canDisplayDownloadAndSendQuoteCommand;
    }

    public downloadAndSendQuoteCommand = new RelayCommand(
        () => this.containerViewModel.downloadAndSendQuoteCommand.execute(),
        () => this.hasSubmitPermission && this.containerViewModel.downloadAndSendQuoteCommand.canExecute(),
    );

    // Revise Quote.
    public get canDisplayDisplayReviseQuoteCommand() {
        return this.canDisplayReviseQuoteCommand;
    }

    public displayReviseQuoteCommand = new RelayCommand(
        () => {
            const submitWithNoteViewModel = new SubmitWithNoteViewModel(
                new SubmitWithNoteModel(),
                "Revise quote",
                "Please give the reason why this quote is being revised.",
                this.cancelSubmitWithNoteCommand,
                this.reviseQuoteCommand,
            );

            this.submitWithNoteViewModel = submitWithNoteViewModel;
        },
        () => this.hasSubmitPermission && this.reviseQuoteCommand.canExecute(),
    );

    public get canDisplayReviseQuoteCommand() {
        return this.hasSubmitPermission && this.containerViewModel.canDisplayReviseQuoteCommand;
    }

    public reviseQuoteCommand = new RelayCommand(
        () => {
            this.containerViewModel.reviseQuoteCommand.execute();
            this.submitWithNoteViewModel = null;
        },
        () => this.hasSubmitPermission && this.containerViewModel.reviseQuoteCommand.canExecute(),
    );

    //Revert Lost Quote

    public get canDisplayRevertLostQuoteCommand() {
        return this.hasSubmitPermission && this.containerViewModel.canDisplayRevertLostQuoteCommand;
    }

    // public revertLostQuoteCommand = new RelayCommand(
    //     () => {
    //         const submitWithNoteViewModel = new SubmitWithNoteViewModel(
    //             new SubmitWithNoteModel(),
    //             "Revert lost quote",
    //             "Please give the reason why this quote is being revised.",
    //             this.cancelSubmitWithNoteCommand,
    //             this.reviseQuoteCommand,
    //         );

    //         this.submitWithNoteViewModel = submitWithNoteViewModel;
    //     },
    //     () => this.hasSubmitPermission && this.containerViewModel.revertLostQuoteCommand.canExecute(),
    // );

    public confirmRevertLostQuoteCommand = new RelayCommand(
        () => {
            this.containerViewModel.revertLostQuoteCommand.execute();
            // this.revertLostQuoteViewModel = null;
        },
        () => this.hasSubmitPermission && this.containerViewModel.revertLostQuoteCommand.canExecute(),
    );
    // Mark Quote as Lost.

    public displayMarkAsLostQuoteCommand = new RelayCommand(
        () => {
            const lostWithNoteViewModel = new LostWithNoteViewModel(
                "Lost enquiry",
                "Please select a reason why this quote has been lost.",
                this.cancelLostWithNoteCommand,
                this.markQuoteAsLostCommand,
                false,
                new LostWithNoteModel(),
            );

            this.lostWithNoteViewModel = lostWithNoteViewModel;
        },
        () => this.hasSubmitPermission && this.markQuoteAsLostCommand.canExecute(),
    );

    public get canDisplayMarkQuoteAsLostCommand() {
        return this.hasSubmitPermission && this.containerViewModel.canDisplayMarkQuoteAsLostCommand;
    }

    public markQuoteAsLostCommand = new RelayCommand(
        () => {
            this.containerViewModel.markQuoteAsLostCommand.execute();
            this.lostWithNoteViewModel = null;
        },
        () => this.hasSubmitPermission && this.containerViewModel.markQuoteAsLostCommand.canExecute(),
    );

    public cancelLostWithNoteCommand = new RelayCommand(() => {
        this.lostWithNoteViewModel = null;
    });

    // Download Quote.
    public get canDisplayDownloadPDFFileCommand() {
        return this.containerViewModel.canDisplayDownloadPDFFileCommand;
    }

    public downloadPDFFileCommand = new RelayCommand(
        () => this.containerViewModel.downloadPDFFileCommand.execute(),
        () => this.containerViewModel.downloadPDFFileCommand.canExecute(),
    );

    // Save Quote Note.
    public get canDisplaySaveQuoteNoteCommand() {
        return this.containerViewModel.canDisplaySaveQuoteNoteCommand;
    }

    public saveQuoteNoteCommand = new RelayCommand((noteViewModel: NoteViewModel) => {
        this.containerViewModel.saveQuoteNoteCommand.execute(noteViewModel);
    });

    // Preview Quote Letter.
    public get canDisplayPreviewQuoteLetterCommand() {
        return this.containerViewModel.canDisplayPreviewQuoteLetterDocumentCommand;
    }

    public previewQuoteLetterCommand = new RelayCommand(
        () => this.containerViewModel.previewQuoteLetterDocumentCommand.execute(),
        () => this.containerViewModel.previewQuoteLetterDocumentCommand.canExecute(),
    );

    // Preview Quote Terms and Conditions.
    public get canDisplayPreviewTermsAndConditionsCommand() {
        return this.containerViewModel.canDisplayPreviewQuoteTermsAndConditionsDocumentCommand;
    }

    public previewTermsAndConditionsCommand = new RelayCommand(
        () => this.containerViewModel.previewQuoteTermsAndConditionsDocumentCommand.execute(),
        () => this.containerViewModel.previewQuoteTermsAndConditionsDocumentCommand.canExecute(),
    );

    // Preview Tasks and Costs.
    public get canDisplayPreviewTasksAndCostsDocumentCommand() {
        return this.containerViewModel.canDisplayPreviewQuoteTasksAndCostsDocumentCommand;
    }

    public previewTasksAndCostsDocumentCommand = new RelayCommand(
        () => this.containerViewModel.previewQuoteTasksAndCostsDocumentCommand.execute(),
        () => this.containerViewModel.previewQuoteTasksAndCostsDocumentCommand.canExecute(),
    );

    public navigateToQuotesCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.Quotes.Table);
    });

    // #endregion Commands
}
