import { ViewModelBase } from "@shoothill/core";
import { action, computed, makeObservable, observable } from "mobx";
import { nanoid } from "nanoid";
import { container } from "tsyringe";

import { LookupStore } from "Stores/Domain";

import { ProgrammingWeekTaskModel, ProgrammingWeekTaskModelValidator } from "../ProgrammingWeekTaskModel";
import { BillingNoteViewModel } from "Views/Resource/AssignedTasks/Table/ConfigureSubViews/BillingSubView/SubViews/BillingNoteViewModel";
import { BillingNoteModel } from "Views/Resource/AssignedTasks/Table/ConfigureSubViews/BillingSubView/SubViews/BillingNoteModel";
import { TA_NOTECONTEXT_ASSIGNMENT } from "Views/Shared/Constants";

export class ProgrammingWeekTaskUserNotesViewModel extends ViewModelBase<ProgrammingWeekTaskModel> {
    private lookupStore = container.resolve(LookupStore);
    private key = nanoid();

    public projectTaskAssignmentUserNoteViewModels: BillingNoteViewModel[] = observable<BillingNoteViewModel>([]);
    // default viewModel that will be used when there no user notes
    public defaultProjectTaskAssignmentUserNoteViewModel: BillingNoteViewModel | null = null;

    constructor(model: ProgrammingWeekTaskModel) {
        super(model);

        this.setValidator(new ProgrammingWeekTaskModelValidator());

        makeObservable(this, {
            defaultProjectTaskAssignmentUserNoteViewModel: observable,
            initializeProjectTaskAssignmentUserNoteViewModel: action,
            displayDefaultNote: computed,
        });
        this.initializeProjectTaskAssignmentUserNoteViewModel();
    }

    public initializeProjectTaskAssignmentUserNoteViewModel() {
        const noteModel: BillingNoteModel = new BillingNoteModel();
        //populate model
        noteModel.context = TA_NOTECONTEXT_ASSIGNMENT;

        noteModel.date = new Date();
        noteModel.firstName = "";
        noteModel.lastName = "";
        noteModel.note = "There are no notes to display.";
        noteModel.documentUrl = "";
        this.defaultProjectTaskAssignmentUserNoteViewModel = new BillingNoteViewModel(noteModel);
    }

    public get displayDefaultNote() {
        return this.projectTaskAssignmentUserNoteViewModels.length == 0;
    }

    // #region Properties

    public get KEY() {
        return this.key;
    }

    public get displayName() {
        return "History";
    }

    // #endregion Properties
}
