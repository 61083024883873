import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";

export class GetFilteredUnassignedProjectTaskAssignmentsXLSXRequest {
    public keyword: string = "";
    public projectId: string | null = null;
    public projectTaskGroupName: string | null = null;
    public projectLeadId: string | null = null;
}

export class GET_GetFilteredUnassignedProjectTaskAssignmentsXLSXEndpoint extends Endpoint<any, any> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Resources.GetFilteredUnassignedProjectTaskAssignmentsXLSX);
        this.responseIsBlob(true);
    }

    public async HandleRequestAsync(request: any): Promise<any> {
        return await Promise.resolve(request);
    }

    public async HandleResponseAsync(response: any): Promise<any> {
        return Promise.resolve(response);
    }
}
