import { observer } from "mobx-react-lite";
import React from "react";
import { AutoGrid, Show, ThemedButton } from "Application";
import { TermsAndConditionViewModel } from "../TermsAndConditionViewModel";

export interface IButtonClusterSubViewProps {
    viewModel: TermsAndConditionViewModel;
}

export const ButtonClusterSubView: React.FC<IButtonClusterSubViewProps> = observer((props) => {
    const viewModel = props.viewModel;

    return (
        <AutoGrid
            backgroundColor="#F3F3F3"
            columnGap={"30px"}
            dc={"140px 140px 140px 140px 140px 140px"}
            m={"0"}
            padding={"15px 30px"}
            position="fixed"
            left="0"
            right="0"
            bottom="0"
            zIndex="10000"
        >
            <ThemedButton styles={{ root: { padding: "0 5px" } }} paletteColor={"primary"} displayName={viewModel.updatedText} command={viewModel.createTermsAndConditionCommand} />
            <ThemedButton styles={{ root: { padding: "0 5px" } }} displayName="Cancel" command={viewModel.navigateToListCommand} />
            <Show if={!viewModel.setDelete}>
                <ThemedButton styles={{ root: { padding: "0 5px", color: "#fff" } }} paletteColor={"error"} displayName="Delete" command={viewModel.deleteModalOpenCommand} />
            </Show>
        </AutoGrid>
    );
});
