import _ from "lodash";

import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { ProjectLite, Service, ServiceGroup } from "Application/Models/Domain";
import { AppUrls } from "AppUrls";
import { CustomerContactItemModel } from "Views/Shared/Customer/CustomerContactItemModel";
import { CustomerItemModel } from "Views/Shared/Customer/CustomerItemModel";
import { QuoteContainerViewModel } from "../QuoteContainerViewModel";
import { User } from "Application/Models/Domain/User";

class CustomerResponse {
    id: string = "";
    contactTypeId: string = "";
    contactTitleId: string | null = null;

    businessName: string | null = null;
    public prefixName: string = "";
    firstName: string | null = null;
    lastName: string | null = null;

    public contactNumber1: string | null = null;
    public contactNumber2: string | null = null;
    public emailAddress: string | null = null;
    public emailAddress2: string | null = null;

    public address1: string | null = null;
    public address2: string | null = null;
    public address3: string | null = null;
    public city: string | null = null;
    public postcode: string | null = null;
    public isDeleted: boolean = false;
}

class CustomerContactResponse {
    id: string = "";
    customerId: string = "";
    contactTitleId: string | null = null;
    public prefixName: string = "";
    firstName: string = "";
    lastName: string = "";

    public contactNumber1: string | null = null;
    public contactNumber2: string | null = null;
    public emailAddress: string | null = null;

    public address1: string | null = null;
    public address2: string | null = null;
    public address3: string | null = null;
    public city: string | null = null;
    public postcode: string | null = null;
    public isDeleted: boolean = false;
}

class ProjectResponse {
    id: string = "";
    title: string = "";
    projectTypeId: string = "";
}

class ServiceGroupResponse {
    id: string = "";
    name: string = "";
    ordinal: number = 0;
    type: string = "";
    isDeleted: boolean = false;
}

class ServiceResponse {
    id: string = "";
    name: string = "";
    ordinal: number = 0;
    isDeleted: boolean = false;
    serviceGroupId: string = "";
    isLinkedToPlanningApplication: boolean = false;
}

class Response {
    isQuickQuote: boolean = true;
    customers: CustomerResponse[] = [];
    customerContacts: CustomerContactResponse[] = [];
    projects: ProjectResponse[] = [];
    serviceGroups: ServiceGroupResponse[] = [];
    services: ServiceResponse[] = [];
    quoteLeads: User[] = [];
}

export class GETQuickQuoteRelatedByCustomerIdEndpoint extends EndpointWithoutRequest<Response> {
    private readonly viewModel: QuoteContainerViewModel;

    constructor(customerId: string, viewModel: QuoteContainerViewModel) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.Quotes.GetQuickQuoteRelatedByCustomerId.replace(":id", customerId));
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        this.viewModel.quoteFormViewModel.model.isQuickQuote = response.isQuickQuote;
        this.viewModel.quoteFormViewModel.quoteViewModel.model.isQuickQuote = response.isQuickQuote;

        const customerResponse = response.customers[0];

        this.viewModel.quoteFormViewModel.customerViewModel.model.customerId = customerResponse.id;
        this.viewModel.quoteFormViewModel.customerViewModel.model.contactTypeId = customerResponse.contactTypeId;

        if (response.customerContacts.length > 0) {
            const customerContactResponse = response.customerContacts[0];
            this.viewModel.quoteFormViewModel.customerViewModel.model.customerContactId = customerContactResponse.id;
        }

        // Process the response customers.
        this.viewModel.quoteFormViewModel.customerViewModel.customers.replace(
            response.customers.map((c) => {
                const model = new CustomerItemModel();
                model.fromResponse(c);
                return model;
            }),
        );

        // Process the response customer contacts.
        this.viewModel.quoteFormViewModel.customerViewModel.customerContacts.replace(
            response.customerContacts.map((cc) => {
                const model = new CustomerContactItemModel();
                model.fromResponse(cc);
                return model;
            }),
        );

        // Process the response letter templates.
        this.viewModel.quoteFormViewModel.letterViewModel.model.isQuickQuote = response.isQuickQuote;

        // Process the response terms and conditions templates.
        this.viewModel.quoteFormViewModel.termsAndConditionsViewModel.model.isQuickQuote = response.isQuickQuote;

        // Process the response projects.
        response.projects.forEach((projects) => {
            let model: ProjectLite = {
                id: projects.id,
                title: projects.title,
                projectTypeId: projects.projectTypeId,
            };
            this.viewModel.quoteFormViewModel.quoteViewModel.model.projectsList.push(model);
        });

        // Process the response service groups.
        this.viewModel.quoteFormViewModel.servicesViewModel.model.serviceGroups.replace(
            response.serviceGroups.map((s) => {
                const serviceGroup = new ServiceGroup(s.id);

                serviceGroup.name = s.name;
                serviceGroup.isDeleted = s.isDeleted;
                serviceGroup.ordinal = s.ordinal;
                serviceGroup.type = s.type;

                return serviceGroup;
            }),
        );

        // Process the response services.
        this.viewModel.quoteFormViewModel.servicesViewModel.model.services.replace(
            response.services.map((s) => {
                const service = new Service(s.id);

                service.name = s.name;
                service.isDeleted = s.isDeleted;
                service.ordinal = s.ordinal;
                service.serviceGroupId = s.serviceGroupId;
                service.isLinkedToPlanningApplication = s.isLinkedToPlanningApplication;

                return service;
            }),
        );

        this.viewModel.quoteFormViewModel.model.quote.quoteLeads.replace(
            response.quoteLeads.map((item) => {
                const domainModel = new User();

                domainModel.id = item.id;
                domainModel.firstName = item.firstName;
                domainModel.lastName = item.lastName;
                domainModel.thumbnailDocumentUrl = item.thumbnailDocumentUrl;

                return domainModel;
            }),
        );
    }
}
