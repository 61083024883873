import { ModelBase } from "@shoothill/core";
import { computed, makeObservable, observable } from "mobx";
import { nanoid } from "nanoid";

import { TaskModel } from "./TaskModel";

export class TaskGroupModel extends ModelBase<TaskGroupModel> {
    public KEY: string = nanoid();

    public id: string = "";
    public taskGroupName: string = "";
    public tasks = observable<TaskModel>([]);

    constructor() {
        super();
        makeObservable(this, {
            // Observables
            tasks: observable,

            // Computeds
            predictedCostExclVAT: computed,
        });
    }

    // #region Business Logic

    public get totalManHours() {
        return this.tasks.reduce((totalManHours, task) => totalManHours + (task.manHours ?? 0), 0);
    }

    public get predictedCostExclVAT(): number {
        return this.tasks
            .map((t: TaskModel) => {
                return t.predictedCostExclVAT;
            })
            .reduce((runningTotal, predictedCost) => {
                return runningTotal + predictedCost;
            }, 0);
    }

    // #endregion Business Logic

    // #region Patch Ordinals

    public patchOrdinals = (): void => {
        TaskGroupModel.patchOrdinals(this.tasks);
    };

    public static patchOrdinals(tasks: TaskModel[]) {
        const sortedtasks = tasks.sort((a, b) => a.ordinal - b.ordinal);
        const ordinalIncrement = 10;

        sortedtasks.forEach((task: TaskModel, index: number) => (task.ordinal = index * ordinalIncrement));
    }

    // #endregion Patch Ordinals
}
