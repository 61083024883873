import { Modal } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { Box, setPageTitle, ThemedButton, ThemedText } from "Application";
import { ThemedLoader } from "Styles/ThemedPrimitives/Style1/ThemedLoader";
import { ThemedError } from "Styles/ThemedPrimitives/Style1/ThemedError";
import { CancelLeaveRequestView } from "Views/Leave/Shared/ModalViews/CancelLeaveRequest/CancelLeaveRequestView";
import { DeleteLeaveRequestView } from "Views/Leave/Shared/ModalViews/DeleteLeaveRequest/DeleteLeaveRequestView";
import { NewRequestView } from "Views/Leave/Shared/ModalViews/NewRequest/NewRequestView";
import { LeaveCalendarViewModel } from "./LeaveCalendarViewModel";
import { NavigationView } from "./Components/Navigation/NavigationView";
import { HeaderView } from "./Components/Header/HeaderView";
import { BodyView } from "./Components/Body/BodyView";

const ThemedButtonStyles = {
    root: {
        height: 30,
        width: 147,
        fontSize: 17,
    },
};

export const LeaveCalendarView: React.FC = observer(() => {
    // #region Code Behind

    const [viewModel] = useState(() => new LeaveCalendarViewModel());

    useEffect(() => {
        setPageTitle("Leave calendar");
    }, []);

    // #endregion Code Behind

    const renderWorkspace = () => {
        return (
            <React.Fragment>
                <Box alignItems={"center"} display={"flex"} justifyContent={"space-between"}>
                    <Box alignItems="baseline" display="flex">
                        <ThemedText fontStyle="h3" mr={3}>
                            My Calendar
                        </ThemedText>
                        <Box
                            alignItems={"center"}
                            display={"flex"}
                            justifyContent={"space-between"}
                            onClick={() => viewModel.navigateToRequestsCommand.execute()}
                            style={{ cursor: "pointer" }}
                        >
                            <ThemedText>VIEW REQUESTS {">"}</ThemedText>
                        </Box>
                    </Box>
                    <ThemedButton marginTop={3} command={viewModel.displayNewRequestCommand} displayName="New request" paletteColor={"primary"} styles={ThemedButtonStyles} />
                </Box>
                <NavigationView viewModel={viewModel} />
                <HeaderView />
                <BodyView viewModel={viewModel} />
            </React.Fragment>
        );
    };

    const renderBusy = () => <ThemedLoader isOpen={viewModel.apiClient.IsBusy} />;

    const renderError = () => {
        const isOpen = viewModel.apiClient.IsSubmitted && !viewModel.apiClient.IsBusy && viewModel.apiClient.HaveValidationMessage;
        return <ThemedError command={viewModel.resetServerErrorCommand} isOpen={isOpen} errorMessage={viewModel.apiClient.ValidationMessage} />;
    };

    const renderNewRequestModal = () => {
        return (
            <Modal isOpen={viewModel.canDisplayNewRequest} onDismiss={() => viewModel.cancelNewRequestCommand.execute()}>
                <NewRequestView viewModel={viewModel.newRequestViewModel} />
            </Modal>
        );
    };

    const renderCancelLeaveRequestModal = () => {
        return (
            <Modal isOpen={viewModel.canDisplayCancelRequest} onDismiss={() => viewModel.cancelCancelRequestCommand.execute()}>
                <CancelLeaveRequestView viewModel={viewModel.cancelLeaveRequestViewModel} />
            </Modal>
        );
    };

    const renderDeleteLeaveRequestModal = () => {
        return (
            <Modal isOpen={viewModel.canDisplayDeleteRequest} onDismiss={() => viewModel.cancelDeleteRequestCommand.execute()}>
                <DeleteLeaveRequestView viewModel={viewModel.deleteLeaveRequestViewModel} />
            </Modal>
        );
    };

    return (
        <Box display="flex" flexDirection="column" flex="1" p={30}>
            {/* Workspace */}
            {renderWorkspace()}

            {/* Busy Overlay */}
            {renderBusy()}

            {/* Error Overlay */}
            {renderError()}

            {/* New Request Modal*/}
            {renderNewRequestModal()}

            {/* Cancel Leave Request Modal*/}
            {renderCancelLeaveRequestModal()}

            {/* Delete Leave Request Modal*/}
            {renderDeleteLeaveRequestModal()}
        </Box>
    );
});
