import { ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";
import { UnassignedTasksItemModel } from "./UnassignedTasksItemModel";

export class UnassignedTasksItemViewModel extends ViewModelBase<UnassignedTasksItemModel> {
    constructor(model = new UnassignedTasksItemModel()) {
        super(model);

        makeObservable(this, {});
    }

    public get KEY() {
        return this.model.KEY;
    }

    public get projectTaskOrdinal() {
        return this.model.projectTaskOrdinal;
    }

    public get projectTaskName() {
        return this.model.projectTaskName;
    }

    public get manHours() {
        return this.model.manHours;
    }
    public get projectTaskForegroundColor() {
        if (this.model.projectTaskIsBilledHourly) {
            return "#FF2D9111";
        }

        return "#2D91FF11";
    }

    public get anticipatedEndDate() {
        return this.model.anticipatedEndDate;
    }

    public get remainingProjectTaskBillableEffort() {
        return this.model.remainingProjectTaskBillableEffort;
    }
}
