import _ from "lodash";

import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { QuoteContainerModel } from "../QuoteContainerModel";
import { QuoteContainerViewModel } from "../QuoteContainerViewModel";
import { QuoteResponse } from "./Shared/QuoteResponse";
class Request {
    id: string | null = null;
}

class Response extends QuoteResponse {}

export class POSTRevertLostQuoteEndpoint extends Endpoint<Request, Response> {
    private readonly viewModel: QuoteContainerViewModel;

    constructor(viewModel: QuoteContainerViewModel) {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Quotes.RevertLostQuote);
        this.viewModel = viewModel;
    }

    public async HandleRequestAsync(model: QuoteContainerModel): Promise<any> {
        const request = new Request();

        request.id = model.quoteForm.id;

        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);
        QuoteResponse.revisionResponsePart(response, this.viewModel);
        QuoteResponse.quoteResponsePart(response, this.viewModel);
        QuoteResponse.customerResponsePart(response, this.viewModel);
        QuoteResponse.letterTemplateResponsePart(response, this.viewModel);
        QuoteResponse.tasksResponsePart(response, this.viewModel);
        QuoteResponse.termsAndConditionsResponsePart(response, this.viewModel);
        QuoteResponse.notesResponsePart(response, this.viewModel);
        QuoteResponse.remainingResponsePart(response, this.viewModel);
    }
}
