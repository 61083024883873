import { observer } from "mobx-react-lite";
import React from "react";

import { AutoGrid, ThemedButton } from "Application";
import { ProjectDetailsViewModel } from "../ProjectDetailsViewModel";

interface IProps {
    viewModel: ProjectDetailsViewModel;
}

/**
 * Displays a group of buttons for the project details.
 */
export const ButtonClusterSubView: React.FC<IProps> = observer((props) => {
    const viewModel = props.viewModel;

    return (
        <AutoGrid
            backgroundColor="#F3F3F3"
            bottom="0"
            columnGap={"30px"}
            dc={"140px 140px 140px 140px 140px 140px"}
            left="0"
            m={"0"}
            padding={"15px 30px"}
            position="fixed"
            right="0"
            zIndex="10000"
        >
            {viewModel.markProjectAsInstructedCommand.canExecute() && (
                <ThemedButton
                    displayName="Set to instructed"
                    paletteColor={"tertiary"}
                    command={viewModel.markProjectAsInstructedCommand}
                    styles={{ root: { padding: "0 5px" } }}
                />
            )}
            {viewModel.putProjectOnHoldCommand.canExecute() && (
                <ThemedButton displayName="Put on hold" paletteColor={"tertiary"} command={viewModel.putProjectOnHoldCommand} styles={{ root: { padding: "0 5px" } }} />
            )}
            {viewModel.takeProjectOffHoldCommand.canExecute() && (
                <ThemedButton displayName="Take off hold" paletteColor={"tertiary"} command={viewModel.takeProjectOffHoldCommand} styles={{ root: { padding: "0 5px" } }} />
            )}
            {viewModel.displayDeleteProjectConfirmationCommand.canExecute() && (
                <ThemedButton
                    displayName="Revert to quote"
                    paletteColor={"tertiary"}
                    command={viewModel.displayDeleteProjectConfirmationCommand}
                    styles={{ root: { padding: "0 5px" } }}
                />
            )}
            {viewModel.displayProjectAsCompleteConfirmationCommand.canExecute() && (
                <ThemedButton
                    displayName="Complete"
                    paletteColor={"success"}
                    command={viewModel.displayProjectAsCompleteConfirmationCommand}
                    styles={{ root: { padding: "0 5px" } }}
                />
            )}
            {viewModel.setProjectAsReopenedCommand.canExecute() && (
                <ThemedButton displayName="Reopen Project" paletteColor={"secondary"} command={viewModel.setProjectAsReopenedCommand} styles={{ root: { padding: "0 5px" } }} />
            )}
            {viewModel.navigateToProjectsCommand.canExecute() && (
                <ThemedButton displayName="Back to projects" command={viewModel.navigateToProjectsCommand} styles={{ root: { padding: "0 5px" } }} />
            )}
        </AutoGrid>
    );
});
