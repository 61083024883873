import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import React from "react";

import { Box, ThemedEditText, ThemedText } from "Application";
import { BillingViewModel } from "./BillingViewModel";
import { FormContainer, FormContainerRow, FormSeparator } from "Views/Shared/SharedComponents";
import { BillingNotesView } from "./SubViews/BillingNotesView";
import { BillingNoteView } from "./SubViews/BillingNoteView";

interface IAssignViewProps {
    viewModel: BillingViewModel;
}

const BillingNotesContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const BillingView: React.FC<IAssignViewProps> = observer((props) => {
    const viewModel = props.viewModel;

    const renderAssignmentNotes = () => {
        return (
            <Box>
                <ThemedText fontStyle="label" mb="10px">
                    Assignment notes
                </ThemedText>
                <BillingNotesView viewModel={viewModel.currentAssignmentNotes!} />
            </Box>
        );
    };

    const renderOtherAssignmentsNotes = () => {
        return (
            <Box>
                <ThemedText fontStyle="label" mb="10px">
                    Other assignment notes
                </ThemedText>
                {viewModel.otherAssignmentsNotes.map((vm, index) => {
                    return (
                        <React.Fragment key={vm.KEY}>
                            <BillingNotesView viewModel={vm} />
                            {index < viewModel.otherAssignmentsNotes.length - 1 && <FormSeparator styles={{ root: { paddingBottom: "10px" } }} />}
                        </React.Fragment>
                    );
                })}
            </Box>
        );
    };

    const renderDefaultNote = () => {
        return (
            <>
                <BillingNoteView viewModel={viewModel.defaultProjectTaskAssignmentUserNoteViewModel!} flipView={false} />
            </>
        );
    };

    return (
        <BillingNotesContainer>
            <FormContainer style={{ padding: "25px 30px", overflow: "scroll", height: "calc(100vh - 388px)", maxHeight: "calc(100vh - 388px)" }}>
                {viewModel.displayDefaultNote && <>{renderDefaultNote()}</>}
                {viewModel.canDisplayCurrentAssignmentNotes && <Box mb="10px">{renderAssignmentNotes()}</Box>}
                {viewModel.canDisplayOtherAssignmentsNotes && <Box mb="10px">{renderOtherAssignmentsNotes()}</Box>}
            </FormContainer>
            <FormSeparator styles={{ root: { height: "1px" } }} />
            <FormContainer style={{ flex: "none", backgroundColor: "#FCFCFC", padding: "10px 30px" }}>
                <FormContainerRow m="0 0 8px 0" dc="1fr 2fr">
                    <ThemedEditText
                        command={viewModel.updateBilledEffortCommand}
                        displayName="Hours to bill*"
                        placeholder="Enter hours"
                        size="larger"
                        validationMessage={() => viewModel.getError("billedEffort")}
                        value={() => viewModel.getValue("billedEffort") ?? ""}
                    />
                    <ThemedText styles={{ root: { fontWeight: "600", marginTop: "37px" } }} fontStyle="h5">
                        {viewModel.canDisplayBillableEffort
                            ? `${viewModel.remainingProjectTaskBillableEffort} of ${viewModel.projectTaskBillableEffort} hrs. remain`
                            : "This task is billed hourly"}
                    </ThemedText>
                </FormContainerRow>
                <FormContainerRow dc={"100%"} m="0 0 8px 0">
                    <ThemedEditText
                        command={viewModel.updateBilledNoteCommand}
                        displayName="Invoice note"
                        isMultiline
                        placeholder="Please add an invoice note"
                        size="larger"
                        validationMessage={() => viewModel.getError("billedNote")}
                        value={() => viewModel.getValue("billedNote") ?? ""}
                    />
                </FormContainerRow>
            </FormContainer>
        </BillingNotesContainer>
    );
});
