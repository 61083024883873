import styled from "@emotion/styled";
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, ThemedText } from "Application";
import { Route, Routes, Navigate } from "react-router-dom";
import { Tabbed } from "Styles/ApplicationStyles";

import { AppUrls } from "AppUrls";
import { LeaveView } from "Views/Leave/Admin/LeaveView";
import { AdminViewModel } from "./AdminViewModel";
import { UsersView } from "./Users/Table/UsersView";
import { ImageSettingsView } from "./ImageSettings/Table/ImageSettingsView";
import { ServiceGroupsView } from "./Services/ServiceGroupsView";
import { LetterTemplatesListView } from "./LetterTemplates/Table/LetterTemplatesListView";
import { TermsAndConditionListView } from "./TermsAndCondition/Table/TermsAndConditionListView";
import { InvoiceForecastView } from "./InvoicingForecast/InvoiceForecastView";
import { WeeklyUpdatesListView } from "./WeeklyUpdates/Table/WeeklyUpdatesListView";
import { InvoiceHistoryView } from "./InvoiceHistory/InvoiceHistoryView";

const Container = styled(Box)`
    width: 100%;
    margin-top: -1px;
    border-top: 1px solid #d6d6d6;
    background-color: #fff;
    min-height: 70vh;
    padding: 30px;
`;

export const AdminView: React.FC = observer(() => {
    const [viewModel] = useState(() => new AdminViewModel());

    return (
        <Box overflowY="auto">
            <Tabbed>
                <Box
                    className={viewModel.locationPathName === AppUrls.Client.LetterTemplates.Table ? "activeTab" : ""}
                    onClick={() => viewModel.setLetterTemplatesTabCommand.execute()}
                >
                    <ThemedText fontStyle={"h2"}>LETTER TEMPLATES</ThemedText>
                </Box>
                <Box
                    className={viewModel.locationPathName === AppUrls.Client.TermConditionTemplates.Table ? "activeTab" : ""}
                    onClick={() => viewModel.setTermConditionTemplatesTabCommand.execute()}
                >
                    <ThemedText fontStyle={"h2"}>T&C Templates</ThemedText>
                </Box>
                <Box className={viewModel.locationPathName === AppUrls.Client.Services.Table ? "activeTab" : ""} onClick={() => viewModel.setServicesTabCommand.execute()}>
                    <ThemedText fontStyle={"h2"}>SERVICES</ThemedText>
                </Box>
                <Box
                    className={viewModel.locationPathName === AppUrls.Client.ImageSettings.Table ? "activeTab" : ""}
                    onClick={() => viewModel.setImageSettingsTabCommand.execute()}
                >
                    <ThemedText fontStyle={"h2"}>IMAGE SETTINGS</ThemedText>
                </Box>
                <Box className={viewModel.locationPathName === AppUrls.Client.Users.Table ? "activeTab" : ""} onClick={() => viewModel.setUsersListTabCommand.execute()}>
                    <ThemedText fontStyle={"h2"}>USERS</ThemedText>
                </Box>
                <Box className={viewModel.locationPathName.includes(AppUrls.Client.Leave.LeaveHome) ? "activeTab" : ""} onClick={() => viewModel.setLeaveTabCommand.execute()}>
                    <ThemedText fontStyle={"h2"}>LEAVE</ThemedText>
                </Box>
                <Box
                    className={viewModel.locationPathName.includes(AppUrls.Client.InvoiceForecast.Home) ? "activeTab" : ""}
                    onClick={() => viewModel.setInvoiceForecastCommand.execute()}
                >
                    <ThemedText fontStyle={"h2"}>INVOICE FORECAST</ThemedText>
                </Box>
                <Box
                    className={viewModel.locationPathName.includes(AppUrls.Client.InvoiceHistory.Home) ? "activeTab" : ""}
                    onClick={() => viewModel.setInvoiceHistoryCommand.execute()}
                >
                    <ThemedText fontStyle={"h2"}>INVOICE HISTORY</ThemedText>
                </Box>
                <Box
                    className={viewModel.locationPathName.includes(AppUrls.Client.WeeklyUpdates.Table) ? "activeTab" : ""}
                    onClick={() => viewModel.setWeeklyUpdatesTabCommand.execute()}
                >
                    <ThemedText fontStyle={"h2"}>WEEKLY UPDATES</ThemedText>
                </Box>
            </Tabbed>
            <Container>
                <Routes>
                    <Route path={"lettertemplates"} element={<LetterTemplatesListView />} />
                    <Route path={"termconditiontemplates"} element={<TermsAndConditionListView />} />
                    <Route path={"services"} element={<ServiceGroupsView />} />
                    <Route path={"imagesettings"} element={<ImageSettingsView />} />
                    <Route path={"users"} element={<UsersView />} />
                    <Route path={"leave/*"} element={<LeaveView />} />
                    <Route path={"Invoiceforecast/*"} element={<InvoiceForecastView />} />
                    <Route path={"Invoicehistory/*"} element={<InvoiceHistoryView />} />
                    <Route path={"weeklyupdates/*"} element={<WeeklyUpdatesListView />} />
                    <Route path={"*"} element={<Navigate to="lettertemplates" replace />} />
                </Routes>
            </Container>
        </Box>
    );
});
