import { runInAction } from "mobx";

import { EndpointWithoutRequest, Http } from "Application";
import { AppUrls } from "AppUrls";
import { SuppliersViewModel } from "./SuppliersViewModel";
import { SuppliersItemViewModel } from "./SuppliersItemViewModel";
import { SuppliersItemModel } from "./SuppliersItemModel";
import { SuppliersContactItemModel } from "./SuppliersContactItemModel";
import { Logger } from "../../../index";

class Response {
    public id: string = "";
    public name: string = "";
    public supplierTypeId: string = "";
    public supplierSubTypeId: string | null = null;
    public supplierTypeName: string = "";
    public supplierSubTypeName: string = "";
    public contactNumber1: string = "";
    public contactNumber2: string = "";
    public emailAddress1: string = "";
    public emailAddress2: string = "";
    public address1: string = "";
    public address2: string = "";
    public address3: string = "";
    public city: string = "";
    public postCode: string = "";
    public lastActionDate: string | null = null;
    public lastActionByUserId: string = "";
    public lastActionFirstName: string = "";
    public lastActionLastName: string = "";
    public contacts: ContactResponse[] = [];
}

class ContactResponse {
    public prefix: string = "";
    public firstName: string = "";
    public lastName: string = "";
}

export class GETSuppliersEndpoint extends EndpointWithoutRequest<Response> {
    private viewModel: SuppliersViewModel;

    constructor(viewModel: SuppliersViewModel) {
        super();
        this.viewModel = viewModel;
        this.verb(Http.Get);
        this.path(AppUrls.Server.Suppliers.GetAllSuppliersLite);
    }

    public async HandleResponseAsync(responses: Response[]): Promise<any> {
        Logger.logInformation("Response", responses);

        runInAction(() => {
            const suppliersItemViewModel: SuppliersItemViewModel[] = [];

            // Build the list of customers.
            responses.forEach((response: Response) => {
                const model = new SuppliersItemModel();

                model.fromResponse(response);

                // Build the list of contacts for the customer.
                const suppliersContactItemModel: SuppliersContactItemModel[] = [];

                response.contacts.forEach((contactResponse: ContactResponse) => {
                    const contactModel = new SuppliersContactItemModel();

                    contactModel.fromResponse(contactResponse);

                    suppliersContactItemModel.push(contactModel);
                });

                model.suppliersContacts.replace(suppliersContactItemModel);

                suppliersItemViewModel.push(new SuppliersItemViewModel(model));
            });

            this.viewModel.suppliersViewModels.replace(suppliersItemViewModel);
        });
    }
}
