import { action, makeObservable, observable } from "mobx";
import { AccountStore } from "./index";
import { EditableCopy } from "Application/Models";
import { EditableCopyRoute } from "Application/Models";
import { singleton, container } from "tsyringe";
import { Logger } from "index";

@singleton()
export class EditableCopyStore {
    public editableCopies = observable<EditableCopy>([]);
    public editableCopyRoutes = observable<EditableCopyRoute>([]);
    public AccountStore = container.resolve(AccountStore);

    constructor() {
        Logger.logDebug("Domain store created");
        makeObservable(this, {
            editableCopies: observable,
            editableCopyRoutes: observable,
        });
        this.init();
    }

    public init = () => {
        this.editableCopies.clear();
        this.editableCopyRoutes.clear();
        if (this.AccountStore.initialState.editableCopies.length > 0) {
            this.AccountStore.initialState.editableCopies.forEach((editableCopy) => {
                this.editableCopies.push(editableCopy);
            });
        }
        if (this.AccountStore.initialState.editableCopyRoutes.length > 0) {
            this.AccountStore.initialState.editableCopyRoutes.forEach((editableCopyRoute) => {
                this.editableCopyRoutes.push(editableCopyRoute);
            });
        }
    };
}
