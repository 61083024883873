import { Service } from "Application/Models/Domain";
import { ServiceViewModel } from "../ServiceViewModel";
import { observer } from "mobx-react-lite";
import { addEditButtonStyleOverride, ArrowButtonHeader, ArrowDown, ArrowUp, CloseButton, removeCancelButtonStyleOverride } from "../../ServiceGroup/ServiceGroupView.styles";
import { formatCurrency, ThemedButton, ThemedText } from "Application";
import { EditSVG } from "Assets/EditSVG";
import { ColumnCenter, ColumnLeft, ColumnRight, Container } from "./ReadOnlyServiceView.styles";
import { arrowButtonStyleOverride } from "../../ServiceGroup/ServiceGroup.styles";

export const ReadOnlyServiceView: React.FC<{ viewModel: ServiceViewModel }> = observer(({ viewModel }) => {
    return (
        <Container>
            <ArrowButtonHeader>
                <ThemedButton styles={arrowButtonStyleOverride} command={viewModel.incrementServicePositionCommand} icon={<ArrowUp isDisabled={viewModel.isFirstService} />} />
                <ThemedButton styles={arrowButtonStyleOverride} command={viewModel.decrementServicePositionCommand} icon={<ArrowDown isDisabled={viewModel.isLastService} />} />
            </ArrowButtonHeader>
            <ColumnLeft>
                <ThemedText fontStyle={"h6"}>{viewModel.getValue("name")}</ThemedText>
            </ColumnLeft>
            <ColumnCenter>
                <ThemedText fontStyle={"h6"}>{viewModel.getValue<boolean>("isLinkedToPlanningApplication") ? "Yes" : "No"}</ThemedText>
            </ColumnCenter>
            <ColumnCenter>
                <ThemedText fontStyle={"h6"}>{viewModel.getValue("manHours")}</ThemedText>
            </ColumnCenter>

            <ColumnCenter>
                <ThemedText fontStyle={"h6"}>{formatCurrency(viewModel.getValue("hourlyRate"))}</ThemedText>
            </ColumnCenter>
            <ColumnRight>
                <ThemedButton styles={addEditButtonStyleOverride} command={viewModel.beginUpdateServiceCommand} icon={<EditSVG />} marginRight={15} />
                <ThemedButton styles={removeCancelButtonStyleOverride} command={viewModel.beginDeleteServiceCommand} icon={<CloseButton />} />
            </ColumnRight>
        </Container>
    );
});
