import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { Box, ThemedText } from "Application";
import { AppUrls } from "AppUrls";
import { Tabbed } from "Styles/ApplicationStyles";
import { AssignedTasksView } from "./AssignedTasks/Table/AssignedTasksView";
import { BilledTasksView } from "./BilledTasks/Table/BilledTasksView";
import { ResourceViewModel } from "./ResourceViewModel";
import { StaffView } from "./Staff/Table/StaffView";
import { TimesheetView } from "./Timesheet/Table/TimesheetView";
import { UnassignedTasksView } from "./UnassignedTasks/Table/UnassignedTasksView";

const Container = styled(Box)`
    width: 100%;
    margin-top: -1px;
    border-top: 1px solid #d6d6d6;
    background-color: #fff;
    min-height: 70vh;
    padding: 30px;
`;

export const ResourceView: React.FC = observer(() => {
    const [viewModel] = useState(() => new ResourceViewModel());

    useEffect(() => {
        return () => viewModel.dispose();
    }, []);

    return (
        <Box overflowY="auto">
            <Tabbed>
                <Box className={viewModel.locationPathName === AppUrls.Client.Staff.Table ? "activeTab" : ""} onClick={() => viewModel.setStaffTabCommand.execute()}>
                    <ThemedText fontStyle={"h2"}>Staff</ThemedText>
                </Box>
                <Box
                    className={viewModel.locationPathName === AppUrls.Client.UnassignedTasks.Table ? "activeTab" : ""}
                    onClick={() => viewModel.setUnassignedTasksTabCommand.execute()}
                >
                    <ThemedText fontStyle={"h2"}>Unassigned tasks</ThemedText>
                </Box>
                <Box
                    className={viewModel.locationPathName === AppUrls.Client.AssignedTasks.Table ? "activeTab" : ""}
                    onClick={() => viewModel.setTasksInProgressTabCommand.execute()}
                >
                    <ThemedText fontStyle={"h2"}>Tasks in progress</ThemedText>
                </Box>
                <Box
                    className={viewModel.locationPathName === AppUrls.Client.AssignedTasks.TableComplete ? "activeTab" : ""}
                    onClick={() => viewModel.setTasksCompleteTabCommand.execute()}
                >
                    <ThemedText fontStyle={"h2"}>Completed Tasks</ThemedText>
                </Box>

                <Box className={viewModel.locationPathName === AppUrls.Client.BilledTasks.Table ? "activeTab" : ""} onClick={() => viewModel.setBilledTasksTabCommand.execute()}>
                    <ThemedText fontStyle={"h2"}>Billed tasks</ThemedText>
                </Box>
                <Box className={viewModel.locationPathName === AppUrls.Client.Timesheet.Table ? "activeTab" : ""} onClick={() => viewModel.setTimesheetTabCommand.execute()}>
                    <ThemedText fontStyle={"h2"}>Timesheets</ThemedText>
                </Box>
            </Tabbed>
            <Container>
                <Routes>
                    <Route path={"staff"} element={<StaffView />} />
                    <Route path={"assignedtasks"} element={<AssignedTasksView parentViewModel={viewModel} />} />
                    <Route path={"unassignedtasks"} element={<UnassignedTasksView />} />
                    <Route path={"completeassignedtasks"} element={<AssignedTasksView parentViewModel={viewModel} overrideStatusFilter="Complete" />} />
                    <Route path={"billedtasks"} element={<BilledTasksView />} />
                    <Route path={"timesheet"} element={<TimesheetView />} />

                    <Route path={"*"} element={<Navigate to="staff" replace />} />
                </Routes>
            </Container>
        </Box>
    );
});
