import { isNullOrUndefined, ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

import { formatDate } from "Application";
import { LeaveRequestItemModel } from "./LeaveRequestItemModel";

export class LeaveRequestItemViewModel extends ViewModelBase<LeaveRequestItemModel> {
    constructor(model: LeaveRequestItemModel) {
        super(model);

        makeObservable(this, {});
    }

    public get KEY() {
        return this.model.KEY;
    }

    // #region Column Properties

    public get name() {
        return this.model.requestUser?.firstName;
    }

    public get leaveType() {
        return this.model.leaveRequest?.leaveType?.name;
    }

    public get requestFrom() {
        return this.model.leaveRequest?.fromDate?.date;
    }

    public get requestTo() {
        return this.model.leaveRequest?.toDate?.date;
    }

    public get numberOfDays() {
        return this.model.leaveRequest?.numberOfDays;
    }

    public get numberOfDaysLeft() {
        return this.model.leaveRequest?.leaveAllowanceNumberOfDaysLeft;
    }

    public get canDisplayApprove() {
        return this.model.canApprove;
    }

    public get canDisplayDecline() {
        return this.model.canDecline;
    }

    public get canDisplayEdit() {
        return this.model.canEdit;
    }

    public get canDisplayDelete() {
        return this.model.canDelete;
    }

    // #endregion Column Properties

    // #region Content Properties

    public get thumbnailUrl() {
        return !isNullOrUndefined(this.model?.requestUser) ? this.model.requestUser!.documentUrl ?? "" : "";
    }

    public get renderNameContentString() {
        return !isNullOrUndefined(this.model?.requestUser) ? `${this.model.requestUser!.firstName} ${this.model.requestUser!.lastName}` : "";
    }

    public get renderLeaveTypeContentString() {
        return !isNullOrUndefined(this.model?.leaveRequest?.leaveType?.name) ? this.model!.leaveRequest!.leaveType!.name : "";
    }

    public get requestFromContentString() {
        return !isNullOrUndefined(this.model?.leaveRequest?.fromDate)
            ? `${formatDate(new Date(this.model!.leaveRequest!.fromDate!.date!))} ${this.model!.leaveRequest!.fromDate!.leaveDayType!.name}`
            : "";
    }

    public get requestToContentString() {
        return !isNullOrUndefined(this.model?.leaveRequest?.toDate)
            ? `${formatDate(new Date(this.model!.leaveRequest!.toDate!.date!))} ${this.model!.leaveRequest!.toDate!.leaveDayType!.name}`
            : "";
    }

    // #region Content Properties
}
