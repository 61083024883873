import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

export class UsersModel extends ModelBase<UsersModel> {
    // Filtering
    public filterKeyword: string = "";
    public filterStatusId: string = "";
    public filterFromDate: Date | undefined = undefined;
    public filterToDate: Date | undefined = undefined;

    // Sorting
    public sortKey = "firstName"; // A property defined in the table column definitions.
    public sortDescending = false;

    constructor() {
        super();
        makeObservable(this, {
            // Observables
            filterKeyword: observable,
            filterStatusId: observable,
            filterFromDate: observable,
            filterToDate: observable,
            sortKey: observable,
            sortDescending: observable,
        });
    }
}
