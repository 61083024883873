import { isNullOrUndefined, ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";
import { Moment } from "moment";

import { ICommand, RelayCommand } from "Application";
import { UserCalendarModel } from "./UserCalendarModel";
import { NoEventViewModel } from "../Date/Components/NoEvent/NoEventViewModel";
import { WeekendEventViewModel } from "../Date/Components/WeekendEvent/WeekendEventViewModel";
import { DateModel } from "../Date/DateModel";
import { DateViewModel } from "../Date/DateViewModel";

export class UserCalendarViewModel extends ViewModelBase<UserCalendarModel> {
    // Callback commands to the parent viewmodel.
    public parentDisplayChangeAllowanceCommand: ICommand;

    private startOfMonth: Moment;
    private endOfMonth: Moment;

    public dateViewModels: DateViewModel[] = [];

    public get KEY() {
        return this.model.KEY;
    }

    constructor(model: UserCalendarModel, startOfMonth: Moment, endOfMonth: Moment, displayChangeAllowanceCommand: ICommand) {
        super(model, false);

        this.startOfMonth = startOfMonth;
        this.endOfMonth = endOfMonth;
        this.dateViewModels = this.createDateViewModels;

        // Commands to be called on the parent viewmodel.
        this.parentDisplayChangeAllowanceCommand = displayChangeAllowanceCommand;

        makeObservable(this, {});
    }

    // #region Properties

    public get name() {
        return this.model.requestUser?.firstName;
    }

    public get thumbnailUrl() {
        return !isNullOrUndefined(this.model?.requestUser) ? this.model.requestUser!.documentUrl ?? "" : "";
    }

    public get renderNameContentString() {
        return !isNullOrUndefined(this.model?.requestUser) ? `${this.model.requestUser!.firstName} ${this.model.requestUser!.lastName}` : "";
    }

    public get numberOfDaysAccrued() {
        return this.model.leaveAllowance?.numberOfDaysAccrued;
    }

    public get numberOfDaysTaken() {
        return this.model.leaveAllowance?.numberOfDaysTaken;
    }

    public get numberOfDaysLeft() {
        return this.model.leaveAllowance?.numberOfDaysLeft;
    }

    public get createDateViewModels() {
        const dateViewModels: DateViewModel[] = [];

        for (let index = 0; this.startOfMonth.clone().add(index, "day") < this.endOfMonth; index++) {
            const momentDate = this.startOfMonth.clone().add(index, "day");

            const dateViewModel = new DateViewModel(new DateModel(momentDate.toDate()));

            switch (true) {
                case momentDate.isoWeekday() === 6: // Saturday
                case momentDate.isoWeekday() === 7: // Sunday
                    dateViewModel.eventViewModel = new WeekendEventViewModel();
                    break;

                default:
                    dateViewModel.eventViewModel = new NoEventViewModel();
                    break;
            }

            dateViewModels.push(dateViewModel);
        }

        return dateViewModels;
    }

    // #region Properties

    // #region Commands

    public displayChangeAllowanceCommand = new RelayCommand(
        () => this.parentDisplayChangeAllowanceCommand.execute(this.model.requestUser),
        () => this.parentDisplayChangeAllowanceCommand.canExecute(),
    );

    // #endregion Commands
}
