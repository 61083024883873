import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { setPageTitle, ThemedText } from "Application";
import { FormContainer, FormSubContainer } from "Views/Shared/SharedComponents";
import { ProjectOverviewViewModel } from "./ProjectOverviewViewModel";
import { ProjectGanttChart } from "./SubViews/ProjectGanttChart";
import { ProjectTaskGroupsGanttChart } from "./SubViews/ProjectTaskGroupsGanttChart";
import { ProjectViewModel } from "../ProjectViewModel";

interface Props {
    viewModel: ProjectViewModel;
}

export const ProjectOverviewView: React.FC<Props> = observer((props: Props) => {
    // #region Code Behind

    const [viewModel] = useState(() => new ProjectOverviewViewModel(props.viewModel));

    useEffect(() => {
        setPageTitle("Project overview");
    }, []);

    // #endregion Code Behind

    return (
        <FormContainer>
            <FormSubContainer mt="3px">
                <ThemedText fontStyle="h3">PROJECT OVERVIEW</ThemedText>
                {viewModel.canDisplayProjectChart && <ProjectGanttChart projectViewModel={viewModel.projectChartViewModel} />}
                <ThemedText fontStyle="h3">STAGES & TASKS OVERVIEW</ThemedText>
                {viewModel.canDisplayProjectTaskGroupsChart && <ProjectTaskGroupsGanttChart projectTaskGroupViewModels={viewModel.projectTaskGroupChartViewModels} />}
            </FormSubContainer>
        </FormContainer>
    );
});
