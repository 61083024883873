import { runInAction } from "mobx";

import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { Logger } from "index";
import { AppUrls } from "AppUrls";
import { UserViewModel } from "./UserViewModel";

class Response {}

export class DELETEUserEndpoint extends EndpointWithoutRequest<Response> {
    private readonly viewModel: UserViewModel;

    constructor(id: string, viewModel: UserViewModel) {
        super();
        this.verb(Http.Delete);
        this.path(AppUrls.Server.Account.User.DeleteUser.replace(":id", id));
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: Response[]): Promise<void> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            // Navigate to the users page. As this is a deleted user,
            // remove it from the browser history, so it cannot be navigated to.
            this.viewModel.history.replace(AppUrls.Client.Users.Table);
        });
    }
}
