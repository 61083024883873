import { observer } from "mobx-react-lite";
import { useState } from "react";
import * as React from "react";
import { Box, ThemedButton, ThemedCheckBox, ThemedDefaultEditText, ThemedEditText, ThemedText } from "Application";
import { WeeklyUpdateViewModel } from "./WeeklyUpdateViewModel";
import { FormContainerRow, FormSeparator, PrimaryTableDottedSeparator, PrimaryTableSeparator } from "Views/Shared/SharedComponents";
import { WeeklyUpdatesViewModel } from "../WeeklyUpdatesViewModel";
import { APICommonComponents } from "Application/Helpers/APICommonComponents";
import { LinkButton } from "Components/General/LinkButton";

interface IProps {
    projectId: string;
    parentViewModel: WeeklyUpdatesViewModel;
}

export const WeeklyUpdateView = observer((props: IProps) => {
    const { projectId, parentViewModel } = props;
    // #region Code Behind

    const [viewModel] = useState(() => new WeeklyUpdateViewModel(projectId, parentViewModel));

    // #endregion Code Behind

    const renderUpdate = () => {
        switch (true) {
            case !viewModel.responseReceived:
                return <></>;

            case viewModel.canDisplayNoUpdateAvailable:
                return (
                    <Box mt="10px" mb="10px">
                        <ThemedText>There is no update available for this programming week</ThemedText>
                        <PrimaryTableSeparator />
                    </Box>
                );

            case viewModel.canDisplayReadonly:
                return (
                    <>
                        <Box mt="10px" mb="10px">
                            <FormContainerRow dc={"100"} mb="0px !important">
                                <ThemedText>{viewModel.getValue("updateDetail")}</ThemedText>
                            </FormContainerRow>
                            <FormSeparator />
                            <FormContainerRow dc={"100%"} mb="0px !important">
                                <ThemedText fontStyle="h6">{viewModel.updateDetails}</ThemedText>
                            </FormContainerRow>
                        </Box>
                        <PrimaryTableSeparator />
                    </>
                );

            default:
                return (
                    <>
                        <FormContainerRow dc={"100%"} rowGap={"5px"}>
                            <Box display={"grid"} gridTemplateColumns={"49.5% 49.5%"} marginBottom={"20px"} padding="10px 0px" style={{ gap: "10px 1%" }}>
                                <Box>
                                    <ThemedText fontStyle="label" styles={{ root: { fontWeight: 600, marginBottom: 5 } }}>
                                        Customer Update
                                    </ThemedText>
                                    <ThemedDefaultEditText
                                        command={viewModel.updateDetailCommand}
                                        isMultiline={true}
                                        styles={{ fieldGroup: { minHeight: 120 }, root: { fontWeight: "bold" } }}
                                        placeholder="Enter your weekly update here"
                                        validationMessage={() => viewModel.getError("updateDetail")}
                                        value={() => viewModel.getValue("updateDetail") ?? ""}
                                    />
                                    <LinkButton
                                        paletteColor={"default"}
                                        displayName="Copy last week's customer update"
                                        command={viewModel.copyPreviousProjectUpdateUpdateDetailCommand}
                                    />
                                </Box>
                                <Box>
                                    <ThemedText fontStyle="label" styles={{ root: { fontWeight: 600, marginBottom: 5 } }}>
                                        Internal Note
                                    </ThemedText>
                                    <ThemedDefaultEditText
                                        command={viewModel.updateInternalDetailCommand}
                                        isMultiline={true}
                                        styles={{ fieldGroup: { minHeight: 120 } }}
                                        placeholder="Add a note for internal use"
                                        validationMessage={() => viewModel.getError("internalDetail")}
                                        value={() => viewModel.getValue("internalDetail") ?? ""}
                                    />
                                    <LinkButton
                                        paletteColor={"default"}
                                        displayName="Copy last week's internal note"
                                        command={viewModel.copyPreviousProjectUpdateInternalNoteCommand}
                                    />
                                </Box>
                            </Box>
                            {viewModel.canDisplayUpdateDetails && (
                                <Box>
                                    <ThemedText fontStyle="h6">{viewModel.updateDetails}</ThemedText>
                                </Box>
                            )}
                            <Box>
                                <ThemedButton styles={{ root: { padding: "0 5px" } }} paletteColor={"primary"} displayName="Submit update" command={viewModel.onSubmitCommand} />
                            </Box>
                        </FormContainerRow>
                        <PrimaryTableSeparator />
                    </>
                );
        }
    };

    return (
        <Box>
            {renderUpdate()}
            {APICommonComponents(viewModel.apiClient).renderBusy()}
            {/* Error Overlay */}
            {APICommonComponents(viewModel.apiClient).renderError()}
        </Box>
    );
});
