import { ModelBase } from "@shoothill/core";
import moment from "moment";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

export class AbsenteesModel extends ModelBase<AbsenteesModel> {
    //filtering
    //Currently, absentee filtering is static(Monday => Friday) but might change in the future
    public startDateFilter: Date = moment().day(1).toDate();
    public endDateFilter: Date = moment().day(5).toDate();

    public absenteeModels = observable<AbsenteeModel>([]);

    constructor() {
        super();
        makeObservable(this, {});
    }
}

export class OverviewValidator extends Validator<AbsenteesModel> {
    constructor() {
        super();
    }
}

export class AbsenteeModel extends ModelBase<AbsenteeModel> {
    public userId: string | null = null;
    public userFirstName: string | null = null;
    public userLastName: string | null = null;
    public userDetailsPersonaThumbnailPath: string | null = null;
    public leaveTypeId: string | null = null;
    public leaveTypeName: string | null = null;
    public leaveRequestToDate: Date | null = null;
    public leaveRequestFromDate: Date | null = null;
    public leaveRequestNumberOfDays: number | null = null;
    constructor() {
        super();
        makeObservable(this, {
            userId: observable,
            userFirstName: observable,
            userLastName: observable,
            userDetailsPersonaThumbnailPath: observable,
            leaveTypeId: observable,
            leaveRequestToDate: observable,
            leaveRequestFromDate: observable,
            leaveRequestNumberOfDays: observable,
        });
    }
}
