import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { nanoid } from "nanoid";

export class CalloutInformationItemModel extends ModelBase<CalloutInformationItemModel> {
    public KEY: string = nanoid();

    public projectReference: string = "";
    public projectId: string = "";
    public customerName: string = "";
    public customerId: string = "";

    constructor() {
        super();

        makeObservable(this, {
            KEY: observable,
        });
    }
}
