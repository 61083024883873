import { ModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

export class UnassignedTasksItemModel extends ModelBase<UnassignedTasksItemModel> {
    public id: string = "";
    public projectTaskName: string = "";
    public projectTaskIsBilledHourly: boolean = false;
    public projectTaskOrdinal: number = 0;
    public anticipatedEndDate: Date | null = null;
    public manHours: number | null = null;
    public remainingProjectTaskBillableEffort: number = 0;

    constructor() {
        super();

        makeObservable(this, {});
    }
}
