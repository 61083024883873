import styled from "@emotion/styled";
import { DetailsRow, IButtonStyles, IColumn, Modal } from "@fluentui/react";
import { isNullOrUndefined } from "@shoothill/core";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import { Box, formatDate, formatDecimalNumber, theme, ThemedButton, ThemedCheckBox, ThemedIconButton, ThemedText } from "Application";
import { CrossSVG } from "Assets/CrossSVG";
import { EditSVG } from "Assets/EditSVG";
import { ThemedDataTableLayer4 } from "Styles/ThemedPrimitives/Style1/ThemedDataTable";
import { CustomSidePanel } from "Views/Shared/CustomSidePanel/CustomSidePanel";
import { CloseButton } from "Views/Admin/Services/ServiceGroup/ServiceGroupView.styles";
import { TaskView } from "./ConfigureSubViews/TaskView";
import { InProgressViewModel } from "./InProgressViewModel";
import { InProgressItemViewModel } from "./InProgressItemViewModel";
import { TaskAssignmentGroupView } from "./ModalViews/TaskAssignmentGroup/TaskAssignmentGroupView";
import { MyWorkWeekOverview } from "../../MyWorkWeekOverview/MyWorkWeekOverview";
import { StaffDetailsViewModel } from "../../../StaffDetailsViewModel";
import { DeleteTaskAssignmentGroupView } from "./ModalViews/DeleteTaskAssignmentGroup/DeleteTaskAssignmentGroupView";
import { InProgressGroupViewModel } from "./InProgressGroupViewModel";

interface IProps {
    viewModel: InProgressViewModel;
    parentViewModel: StaffDetailsViewModel;
}

const CheckBoxStyle = {
    root: {
        marginTop: "-6px",
        marginLeft: "30px",
        marginBottom: "-6px",
    },
};

export const removeButtonStyleOverride: IButtonStyles = {
    root: {
        backgroundColor: "transparent",
        padding: "0px 0px",
        minWidth: "0px",
        borderWidth: 0,
        height: 20,
        width: 20,
        borderColor: theme.palette.blueAccent.light,
        borderRadius: 0,
    },
    rootFocused: {
        backgroundColor: "none",
        borderWidth: 0,
        "::after": {
            outlineColor: "transparent",
        },
    },
    rootHovered: {
        backgroundColor: "none",
        opacity: 0.9,
        borderWidth: 0,
    },
    rootPressed: {
        backgroundColor: "none",
        borderWidth: 0,
        opacity: 0.9,
    },
    rootDisabled: {
        borderWidth: 0,
        backgroundColor: "none",
    },
};

interface IRemoveButtonProps {
    isDisabled: boolean;
}

const RemoveButton = styled.button<IRemoveButtonProps>`
    padding: 0px;
    width: 100%;
    height: 100%;
    border-width: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    & > div {
        /* &:hover {
            opacity: ${(props) => (props.disabled ? 0.5 : 1)};
        } */
    }

    &:hover {
        opacity: ${(props) => (props.disabled ? 1 : 0.7)};
        cursor: ${(props) => (props.disabled ? "cursor" : "pointer")};
    }
`;

const GroupCross = styled(CrossSVG)`
    margin-left: -2px;
    margin-right: -2px;
    transform: scale(0.7);
`;

export const InProgressView = observer((props: IProps) => {
    const viewModel = props.viewModel;

    // #region Code Behind

    /**
     * Handles updating the tables column sort settings in response to changes in the viewmodel.
     */
    useEffect(() => {
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter((currCol) => viewModel.getValue("sortKey") === currCol.key)[0];

        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSorted = true;
                currColumn.isSortedDescending = viewModel.getValue("sortDescending");
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = false;
            }
        });

        setColumns(newColumns);
    }, [viewModel.getValue("sortKey"), viewModel.getValue("sortDescending")]);

    /**
     * Gets a key for the row. Must be provided if sorting or filtering is enabled.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The row index.
     *
     * @returns A string key to identify the row.
     */
    const getRowKey = (item: InProgressItemViewModel, index?: number): string => {
        return item.KEY;
    };

    /**
     * Handles an onClick event for the table column header.
     *
     * @param ev Mouse event
     * @param column The column that has been clicked.
     */
    const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const currColumn: IColumn = columns.filter((currCol) => column.key === currCol.key)[0];
        const newSortKey = currColumn.key;
        const newSortDescending = currColumn.key === viewModel.getValue("sortKey") ? !currColumn.isSortedDescending : false;

        viewModel.updateSortCommand.execute(newSortKey, newSortDescending);
    };

    /**
     * Handles rendering the row. Normally we would not do this. However if
     * trying to display an overlay (alert, panel or modal), on dismissing
     * the overlay, onActiveItemChanged would be called again and redisplay the
     * overlay!
     *
     * @param props The row props of which item is the viewmodel.
     * @returns The table row.
     */
    const onRenderRow = (props: any) => {
        const itemVM = props.item;
        const projectTaskAssignmentStatusForegroundColor = `${itemVM.projectTaskAssignmentStatusForegroundColor}11 !important`;

        return (
            <DetailsRow
                {...props}
                onClick={() => onRowClick(itemVM)}
                styles={{ root: { background: projectTaskAssignmentStatusForegroundColor }, cell: { border: "1px solid white" } }}
            />
        );
    };

    /**
     * Handler an onClick event for a table row.
     *
     * @param item The viewmodel data associated with the table row.
     */
    const onRowClick = (item: InProgressItemViewModel) => {
        viewModel.displayTaskCommand.execute(item.model.id);
    };

    const onRenderRemoveButton = (item: InProgressItemViewModel) => {
        return (
            <RemoveButton onClick={(e) => viewModel.removeTask(e, item)} isDisabled={viewModel.isRemoveButtonDisabled(item)} disabled={viewModel.isRemoveButtonDisabled(item)}>
                <CloseButton disabled={viewModel.isRemoveButtonDisabled(item)} />
            </RemoveButton>
        );
    };

    /**
     * Renders the project task assignment status row data.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The index of the table row.
     * @param column The table column.
     *
     * @returns React element.
     */
    const onRenderProjectTaskAssignmentStatus = (item: InProgressItemViewModel, index?: number, column?: IColumn) => {
        return (
            <div style={{ color: `${item.projectTaskAssignmentStatusForegroundColor}`, textTransform: "uppercase", fontWeight: "500" }}>{item.projectTaskAssignmentStatusName}</div>
        );
    };

    /**
     * Renders a date.
     */
    const onRenderDate = (value?: Date) => {
        return <div>{!isNullOrUndefined(value) ? formatDate(value!) : ""}</div>;
    };

    /**
     * Renders a decimal number.
     */
    const onRenderNumber = (value?: number) => {
        return <div>{!isNullOrUndefined(value) ? formatDecimalNumber(value!) : ""}</div>;
    };

    /**
     * Renders a boolean as Yes/No.
     */
    const onRenderYesNo = (value?: boolean) => {
        return <div>{!isNullOrUndefined(value) ? (value ? "Yes" : "No") : ""}</div>;
    };

    const defaultTableColumns: IColumn[] = [
        {
            key: "projectTaskAssignmentStatusName",
            fieldName: "projectTaskAssignmentStatusName",
            name: "STATUS",
            minWidth: 80,
            maxWidth: 80,
            isResizable: true,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: onRenderProjectTaskAssignmentStatus,
        },
        {
            key: "projectTaskName",
            fieldName: "projectTaskName",
            name: "TASK",
            minWidth: 246,
            maxWidth: 578,
            isResizable: true,
            isSorted: true,
            onColumnClick: onColumnClick,
        },
        {
            key: "plannedStartDate",
            fieldName: "plannedStartDate",
            name: "START DATE",
            minWidth: 80,
            maxWidth: 80,
            isResizable: true,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: (item) => onRenderDate(item.plannedStartDate),
        },
        {
            key: "plannedEndDate",
            fieldName: "plannedEndDate",
            name: "END DATE",
            minWidth: 80,
            maxWidth: 80,
            isResizable: true,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: (item) => onRenderDate(item.plannedEndDate),
        },
        {
            key: "hasNote",
            fieldName: "hasNote",
            name: "NOTES",
            minWidth: 80,
            maxWidth: 80,
            isResizable: true,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: (item) => onRenderYesNo(item.hasNote),
        },
        {
            key: "effort",
            fieldName: "effort",
            name: "MAN HRS",
            minWidth: 80,
            maxWidth: 80,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: (item) => onRenderNumber(item.effort),
        },
        {
            key: "billableEffort",
            fieldName: "billableEffort",
            name: "BILLABLE HRS",
            minWidth: 80,
            maxWidth: 80,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: (item) => onRenderNumber(item.billableEffort),
        },
        {
            key: "removeAssignedTask",
            name: "",
            minWidth: 20,
            maxWidth: 20,
            isSorted: false,
            isIconOnly: true,
            onRender: onRenderRemoveButton,
        },
    ];

    const [columns, setColumns] = useState<IColumn[]>(defaultTableColumns);

    // #endregion Code Behind

    const renderNewTaskAssignmentGroupModal = () => {
        return (
            <Modal isOpen={viewModel.canDisplayEditTaskAssignmentGroup} onDismiss={() => viewModel.cancelEditTaskAssignmentGroupCommand.execute()}>
                <TaskAssignmentGroupView viewModel={viewModel.editTaskAssignmentGroupViewModel} />
            </Modal>
        );
    };

    const renderDeleteTaskAssignmentGroupModal = () => {
        return (
            <Modal isOpen={viewModel.canDisplayDeleteTaskAssignmentGroup} onDismiss={() => viewModel.cancelDeleteTaskAssignmentGroupCommand.execute()}>
                <DeleteTaskAssignmentGroupView viewModel={viewModel.deleteTaskAssignmentGroupViewModel} />
            </Modal>
        );
    };

    const renderTaskAssignmentGroup = (taskAssignmentGroupViewModel: InProgressGroupViewModel, index: number) => {
        const TaskAssignmentGroupContainer = styled(Box)`
            border: 1px solid #ff4b22;
            padding: 0 5px;
            margin: ${index < viewModel.taskAssignmentGroups.length - 1 ? "0 0 5px 0" : "0"};
        `;

        const TaskAssignmentGroupTitle = styled(Box)`
            flex: 1;
            font-size: 8px;
            font-weight: bold;
            margin: 5px 15px 5px 2.5px;
        `;

        const TaskAssignmentGroupNote = styled(Box)`
            flex: 1;

            margin: 5px 15px 5px 2.5px;
        `;

        return (
            <TaskAssignmentGroupContainer>
                <Box style={{ display: "flex" }}>
                    <TaskAssignmentGroupNote>
                        <ThemedText fontStyle="labelXS">{taskAssignmentGroupViewModel.displayNote}</ThemedText>
                    </TaskAssignmentGroupNote>
                    {viewModel.displayCurrentTaskAssignmentGroupCommand.canExecute(taskAssignmentGroupViewModel) && (
                        <ThemedIconButton
                            size="small"
                            styles={{ root: { color: "#fff", margin: "5px 0 4px 0" } }}
                            paletteColor={"primary"}
                            icon={<EditSVG />}
                            command={viewModel.displayCurrentTaskAssignmentGroupCommand}
                            value={taskAssignmentGroupViewModel as any}
                        />
                    )}
                    {viewModel.displayDeleteTaskAssignmentGroupCommand.canExecute(taskAssignmentGroupViewModel) && (
                        <ThemedIconButton
                            size="small"
                            styles={{ root: { color: "#fff", margin: "5px 0 4px 5px" } }}
                            paletteColor={"primary"}
                            icon={<GroupCross />}
                            command={viewModel.displayDeleteTaskAssignmentGroupCommand}
                            value={taskAssignmentGroupViewModel as any}
                        />
                    )}
                </Box>
                <ThemedDataTableLayer4
                    key={taskAssignmentGroupViewModel.KEY}
                    columns={columns}
                    getKey={getRowKey}
                    isHeaderVisible={false}
                    items={taskAssignmentGroupViewModel.tasks}
                    onRenderRow={onRenderRow}
                    styles={{ root: { "&&&&": { marginTop: 0 } } }}
                />
            </TaskAssignmentGroupContainer>
        );
    };

    const renderTaskPanel = () => {
        return (
            <CustomSidePanel
                isOpen={viewModel.canDisplayTask}
                onDismiss={() => {
                    props.parentViewModel.closeMyWorkWeekOverviewCommand.execute();
                    viewModel.cancelTaskCommand.execute();
                }}
            >
                <TaskView viewModel={viewModel.taskViewModel!} parentViewModel={props.parentViewModel} />
                {viewModel.canDisplayWorkCalendar && (
                    <MyWorkWeekOverview
                        workCalendarViewModel={props.parentViewModel.workCalendarViewModel!}
                        show={props.parentViewModel.displayHorizontalMyWorkWeekOverview}
                        closeOverviewCommand={props.parentViewModel.closeMyWorkWeekOverviewCommand}
                        origin={"right"}
                        height={"90%"}
                        width={"calc(100% - 470px)"}
                        overlayColor="transparent"
                        showCloseButton
                        zIndex={-1}
                    />
                )}
            </CustomSidePanel>
        );
    };

    return (
        <Box>
            {/* Title/Fiter Bar */}
            <Box display="flex">
                <Box display="flex">
                    <ThemedText fontStyle="expandable">In progress</ThemedText>
                    <ThemedCheckBox
                        command={props.viewModel.toggleFilterIncludeBilledTasksCommand}
                        displayName="Show billed tasks"
                        paletteColor="secondary"
                        styles={CheckBoxStyle}
                        value={() => props.viewModel.getValue("filterIncludeBilledTasks")}
                    />
                </Box>
                <Box ml="auto" mt="-6px" mb="-6px">
                    <ThemedButton command={props.viewModel.displayNewTaskAssignmentGroupCommand} displayName="Link tasks" paletteColor="primary" />
                </Box>
            </Box>

            {/* Table for ungrouped tasks */}
            <ThemedDataTableLayer4
                columns={columns}
                getKey={getRowKey}
                items={viewModel.filteredAndSortedTasks}
                onRenderRow={onRenderRow}
                styles={{ root: { "&&&&": { marginTop: 0 } } }}
            />

            {/* Tables for grouped tasks */}
            {viewModel.sortedTaskAssignmentGroups.map((taskAssignmentGroupViewModel, index) => renderTaskAssignmentGroup(taskAssignmentGroupViewModel, index))}

            {/* Display task panel */}
            {renderTaskPanel()}

            {/* New Task Assignment Group Modal*/}
            {renderNewTaskAssignmentGroupModal()}

            {/* Delete Task Assignment Group Modal*/}
            {renderDeleteTaskAssignmentGroupModal()}
        </Box>
    );
});
