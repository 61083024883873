import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Box, theme, ThemedText } from "Application";
import { AppUrls } from "AppUrls";
import { Tabbed } from "Styles/ApplicationStyles";
import { LeaveRequestsView } from "Views/Leave/Admin/Table/LeaveRequestsView";
import { LeaveView } from "Views/Leave/Staff/LeaveView";
import { AssignedTasksView } from "Views/Resource/AssignedTasks/Table/AssignedTasksView";
import { OverviewView } from "./Admin/Overview/OverviewView";
import { DashboardViewModel } from "./DashboardViewModel";
import { HomeView } from "./Staff/Home/HomeView";
import { MyWorkView } from "./Staff/MyWork/MyWorkView";
import { WeeklyUpdatesView } from "./Staff/WeeklyUpdates/WeeklyUpdatesView";
import { MyProjectsView } from "./Admin/MyProjects/MyProjectsView";
import { QuotesAwaitingActionView } from "./Admin/QuotesAwaitingAction/QuotesAwaitingActionView";

const Container = styled(Box)`
    width: 100%;
    margin-top: -1px;
    border-top: 1px solid #d6d6d6;
    background-color: white;
    min-height: calc(100vh - 160px);
    display: flex;
    flex-direction: column;
    flex: 1;
`;

//styling and CSS overrides for the tabbed div

const CountDot = styled.div`
    margin-left: 10px;
    border-radius: 50%;
    display: block !important;
    width: 20px !important;
    min-width: 20px !important;
    height: 20px !important;
    padding: 0px !important;

    background-color: ${theme.palette.secondary.main} !important;
    color: ${theme.palette.secondary.text} !important;
    border: none !important;
    position: relative;
`;

const CountLabel = styled.span`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, calc(-50% + 1px));
    color: white !important;

    font-size: ${theme.fontStyles.workItemStatus.fontSize} !important;
    font-weight: 600;
    height: fit-content;
`;

const CustomTabbedBox = styled.div`
    padding: 0px 10px 0px 10px !important;
    position: relative;
`;

export const DashboardView: React.FC = observer(() => {
    const [viewModel] = useState(() => new DashboardViewModel());

    useEffect(() => {
        return () => viewModel.dispose();
    }, []);

    return (
        <Box overflowY="auto">
            <Tabbed>
                {viewModel.showOverview && (
                    <Box
                        className={viewModel.locationPathName === AppUrls.Client.DashboardAdmin.Overview ? "activeTab" : ""}
                        onClick={() => viewModel.setAdminDashboardOverviewTabCommand.execute()}
                    >
                        <ThemedText fontStyle={"h2"}>Overview</ThemedText>
                    </Box>
                )}
                {viewModel.showHello && (
                    <Box
                        className={viewModel.locationPathName === AppUrls.Client.DashboardStaff.Home ? "activeTab" : ""}
                        onClick={() => viewModel.setStaffDashboardHomeTabCommand.execute()}
                    >
                        <ThemedText fontStyle={"h2"}>Home</ThemedText>
                    </Box>
                )}
                {viewModel.showMyWork && (
                    <Box
                        className={viewModel.locationPathName === AppUrls.Client.DashboardStaff.MyWork ? "activeTab" : ""}
                        onClick={() => viewModel.setStaffDashboardMyWorkTabCommand.execute()}
                    >
                        <ThemedText fontStyle={"h2"}>My work</ThemedText>
                    </Box>
                )}
                {viewModel.showMyProjects && (
                    <Box
                        className={viewModel.locationPathName === AppUrls.Client.DashboardAdmin.MyProjects ? "activeTab" : ""}
                        onClick={() => viewModel.setAdminMyProjectsTabCommand.execute()}
                    >
                        <ThemedText fontStyle={"h2"}>My Projects</ThemedText>
                    </Box>
                )}

                {viewModel.showWeeklyUpdate && (
                    <Box
                        className={viewModel.locationPathName === AppUrls.Client.DashboardStaff.WeeklyUpdate ? "activeTab" : ""}
                        onClick={() => viewModel.setStaffDashboardWeeklyUpdateTabCommand.execute()}
                    >
                        <ThemedText fontStyle={"h2"}>Weekly Update</ThemedText>
                    </Box>
                )}
                {viewModel.showLeave && (
                    <Box
                        className={viewModel.locationPathName.includes(AppUrls.Client.DashboardStaff.Leave) ? "activeTab" : ""}
                        onClick={() => viewModel.setStaffDashboardLeaveTabCommand.execute()}
                    >
                        <ThemedText fontStyle={"h2"}>My Leave</ThemedText>
                    </Box>
                )}
                {viewModel.showPendingLeaveRequests && (
                    <CustomTabbedBox
                        className={viewModel.locationPathName.includes(AppUrls.Client.DashboardAdmin.PendingLeaveRequests) ? "activeTab" : ""}
                        onClick={() => viewModel.setAdminPendingLeaveRequestsTabCommand.execute()}
                    >
                        <ThemedText fontStyle={"h2"}>Pending Leave Requests</ThemedText>
                        <CountDot>
                            <CountLabel>{viewModel.numberOfPendingLeaveRequests}</CountLabel>
                        </CountDot>
                    </CustomTabbedBox>
                )}
                {viewModel.showQuotesAwaitingApproval && (
                    <CustomTabbedBox
                        className={viewModel.locationPathName === AppUrls.Client.DashboardAdmin.QuotesAwaitingAction ? "activeTab" : ""}
                        onClick={() => viewModel.setAdminQuotesAwaitingActionTabCommand.execute()}
                    >
                        <ThemedText fontStyle={"h2"}>Quotes awaiting action</ThemedText>
                        <CountDot>
                            <CountLabel>{viewModel.numberOfQuotesAwaitingAction}</CountLabel>
                        </CountDot>
                    </CustomTabbedBox>
                )}
                {viewModel.showTasksInProgress && (
                    <CustomTabbedBox
                        className={viewModel.locationPathName === AppUrls.Client.DashboardAdmin.TasksInProgress ? "activeTab" : ""}
                        onClick={() => viewModel.setAdminTasksInProgressTabCommand.execute()}
                    >
                        <ThemedText fontStyle={"h2"}>Tasks in progress</ThemedText>
                        <CountDot>
                            <CountLabel>{viewModel.numberOfInProgressTasks}</CountLabel>
                        </CountDot>
                    </CustomTabbedBox>
                )}
                {viewModel.showUpdatedTasks && (
                    <Box
                        className={viewModel.locationPathName === AppUrls.Client.DashboardAdmin.UpdatedTasks ? "activeTab" : ""}
                        onClick={() => viewModel.setAdminUpdatedTasksTabCommand.execute()}
                    >
                        <ThemedText fontStyle={"h2"}>Tasks with updates</ThemedText>
                        <CountDot>
                            <CountLabel>{viewModel.numberOfUpdatedTasks}</CountLabel>
                        </CountDot>
                    </Box>
                )}
                {viewModel.showCompletedTasks && (
                    <Box
                        className={viewModel.locationPathName === AppUrls.Client.DashboardAdmin.TasksToReview ? "activeTab" : ""}
                        onClick={() => viewModel.setAdminTasksToReviewTabCommand.execute()}
                    >
                        <ThemedText fontStyle={"h2"}>Tasks to review</ThemedText>
                        <CountDot>
                            <CountLabel>{viewModel.numberOfCompleteTasks}</CountLabel>
                        </CountDot>
                    </Box>
                )}
            </Tabbed>
            <Container>
                <Routes>
                    <Route path={"admin/overview"} element={<OverviewView />} />
                    <Route path={"staff/home"} element={<HomeView />} />
                    <Route
                        path={"staff/mywork"}
                        element={
                            <Box padding={30} flex={1} display={"flex"} flexDirection={"column"}>
                                <MyWorkView />
                            </Box>
                        }
                    />

                    {/* <Route path={"staff/contacts"} element={<div>Contacts</div>} /> */}
                    <Route
                        path={"staff/weeklyupdate"}
                        element={
                            <Box padding={30} flex={1} display={"flex"} flexDirection={"column"}>
                                <WeeklyUpdatesView />
                            </Box>
                        }
                    />
                    <Route
                        path={"admin/myprojects"}
                        element={
                            <Box padding={30} flex={1} display={"flex"} flexDirection={"column"}>
                                <MyProjectsView />
                            </Box>
                        }
                    />

                    <Route
                        path={"admin/quotesawaitingaction"}
                        element={
                            <Box padding={30} flex={1} display={"flex"} flexDirection={"column"}>
                                <QuotesAwaitingActionView />
                            </Box>
                        }
                    />
                    <Route
                        path={"admin/updatedtasks"}
                        element={
                            <Box padding={30} flex={1} display={"flex"} flexDirection={"column"}>
                                <AssignedTasksView parentViewModel={viewModel} overrideStatusFilter="Updated" />
                            </Box>
                        }
                    />
                    <Route
                        path={"admin/inprogressassignedtasks"}
                        element={
                            <Box padding={30} flex={1} display={"flex"} flexDirection={"column"}>
                                <AssignedTasksView parentViewModel={viewModel} overrideStatusFilter="Assigned" />
                            </Box>
                        }
                    />
                    <Route
                        path={"admin/completedassignedtasks"}
                        element={
                            <Box padding={30} flex={1} display={"flex"} flexDirection={"column"}>
                                <AssignedTasksView parentViewModel={viewModel} overrideStatusFilter="Complete" />
                            </Box>
                        }
                    />
                    <Route path={"staff/leave/*"} element={<LeaveView parentViewModel={viewModel} />} />
                    <Route
                        path={"admin/leave/requests"}
                        element={
                            <Box padding={30} flex={1} display={"flex"} flexDirection={"column"}>
                                <LeaveRequestsView dashboardViewModel={viewModel} hideCalendar />
                            </Box>
                        }
                    />
                    <Route path={"*"} element={<Navigate to={viewModel.defaultTab} replace />} />
                </Routes>
            </Container>
        </Box>
    );
});
