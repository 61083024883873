import { FieldType, isEmptyOrWhitespace, KeyValuePair, ViewModelBase } from "@shoothill/core";
import { action, computed, makeObservable, observable } from "mobx";
import { container } from "tsyringe";

import { ICommand, RelayCommand } from "Application";
import { LookupStore } from "Stores/Domain";
import { PROJECTTYPE_COMMERCIALTYPE } from "Views/Shared/Constants";
import { EnquirySubModel, EnquirySubModelValidator } from "./EnquirySubModel";
import { FilesViewModel } from "Views/Shared/Files/FilesViewModel";
import { SourceOfEnquiryViewModel } from "Views/Shared/SourceOfEnquiry/SourceOfEnquiryViewModel";
import moment from "moment";

export class EnquirySubViewModel extends ViewModelBase<EnquirySubModel> {
    private lookupStore = container.resolve(LookupStore);
    public parentUpdateStatusTaskCommand: ICommand;
    public parentUpdatePersonVisitingCommand: ICommand;

    public filesViewModel = new FilesViewModel();
    public isCalloutVisible: boolean = false;
    public sourceOfEnquiryViewModel: SourceOfEnquiryViewModel = new SourceOfEnquiryViewModel();
    constructor(enquirySubModel: EnquirySubModel, updateStatusTaskCommand: ICommand, updatePersonVisitingCommand: ICommand) {
        super(enquirySubModel);

        this.parentUpdateStatusTaskCommand = updateStatusTaskCommand;
        this.parentUpdatePersonVisitingCommand = updatePersonVisitingCommand;
        this.setValidator(new EnquirySubModelValidator());

        makeObservable(this, {
            //Observables
            isCalloutVisible: observable,

            //Actions
            setIsCalloutVisible: action,

            // Computed values
            enquiryStatusOptions: computed,
            enquiryTypeOptions: computed,
            personVisitingDisplayName: computed,
            personVisitingImage: computed,
            getProjectsListFormatted: computed,
            lostReasonNoteDetailButtonText: computed,
        });

        // Default values
        this.setValue("projectTypeId", this.lookupStore.getProjectTypeIdByType(PROJECTTYPE_COMMERCIALTYPE));
    }

    // #region Properties

    /**
     * Returns a collection of project types.
     */
    public get projectTypeOptions() {
        return this.lookupStore.getProjectTypes;
    }

    /**
     * Returns the enquiry status display name.
     */
    public get enquiryStatusDisplayName() {
        return this.lookupStore.enquiryStatuses.find((es) => es.id === this.model.enquiryStatusId)?.name?.toLocaleUpperCase() ?? "";
    }

    /**
     * Returns a collection of enquiry statuses.
     */
    public get enquiryStatusOptions() {
        return this.lookupStore.getEnquiryStatuses();
    }

    /**
     * Returns a collection of enquiry types.
     */
    public get enquiryTypeOptions() {
        return this.lookupStore.enquiryTypes
            .filter((et) => !et.isDeleted || et.id === this.model.enquiryTypeId)
            .map((item) => {
                return {
                    key: item.id,
                    text: item.name,
                };
            });
    }

    /**
     * Returns the source of enquiry display name.
     */

    /**
     * Returns the description
     */
    public get description() {
        return this.model.description;
    }

    /**
     * Returns a collection of Projects
     */
    public get getProjectsListFormatted() {
        const formattedList: KeyValuePair[] = [];
        this.model.projectsList.forEach((project) => {
            let textVal = project.title.split(":");
            let model: KeyValuePair = {
                key: project.id,
                text: textVal[0],
            };
            formattedList.push(model);
        });

        return [{ key: "", text: "New project" }, ...formattedList];
    }

    public get getProjectsList() {
        return this.model.projectsList;
    }

    public get projectDisplayName() {
        const project = this.model.projectsList.find((project) => project.id === this.model.projectId);

        return project ? project.title : "New project";
    }

    public get personVisitingOptions() {
        return this.model.personVisitingList.map((item) => {
            return {
                key: item.id,
                text: `${item.firstName} ${item.lastName}`,
                data: item.thumbnailDocumentUrl,
            };
        });
    }

    public get personVisitingDisplayName() {
        const personVisiting = this.model.personVisitingList.find((personVisiting) => personVisiting.id === this.model.enquiryPersonVisitingId);

        return personVisiting ? `${personVisiting.firstName} ${personVisiting.lastName}` : "Not selected";
    }

    public get personVisitingImage() {
        const personVisiting = this.model.personVisitingList.find((personVisiting) => personVisiting.id === this.model.enquiryPersonVisitingId);

        return personVisiting?.thumbnailDocumentUrl ? personVisiting.thumbnailDocumentUrl : undefined;
    }

    public get personVisitingFormattedVisitDate() {
        return this.model.enquiryPersonVisitingVisitDate ? moment(this.model.enquiryPersonVisitingVisitDate).format("DD/MM/YYYY") : "N/A";
    }

    public get personVisitingFormattedVisitTime() {
        return this.model.enquiryPersonVisitingVisitTime ?? "N/A";
    }
    public get canDisplayLostReason() {
        return this.model.lostNoteSubTypeId != null;
    }

    public get canDisplayCallout() {
        //display callout only after the note is more than 60 characters long
        //done to prevent the ui from breaking for longer notes
        return this.model.lostNoteDetail && this.model.lostNoteDetail.length > 60;
    }

    public get lostReason() {
        const reason = this.lookupStore.noteSubTypes.find((noteSubType) => noteSubType.id == this.model.lostNoteSubTypeId);

        return reason ? reason.name : "";
    }

    public get lostReasonNoteDetail() {
        return this.model.lostNoteDetail ?? "no additional note provided";
    }

    // #endregion Properties

    // region actions

    public setIsCalloutVisible = (value: boolean) => (this.isCalloutVisible = value);

    // endregion actions

    // #region Commands

    public get lostReasonNoteDetailButtonText() {
        return this.isCalloutVisible ? "Hide additional note" : "See additional note";
    }

    public calloutVisibilityCommand: ICommand = new RelayCommand(
        () => {
            this.setIsCalloutVisible(!this.isCalloutVisible);
        },
        () => this.canDisplayLostReason,
    );

    public updateProjectTypeCommand: ICommand = new RelayCommand(
        (value: string) => {
            this.updateField("projectTypeId", value);
        },
        () => {
            return isEmptyOrWhitespace(this.model.projectId);
        },
    );

    public updateProjectIdCommand = new RelayCommand((value: string) => {
        this.updateField("projectId", value);

        // SIDE-EFFECT - Inherit ProjectType from linked Project
        const inheritedProjectType = value ? this.model.projectsList.find((p) => p.id === value) : null;

        if (inheritedProjectType) {
            this.updateField("projectTypeId", inheritedProjectType.projectTypeId);
        }
    });

    public updateStatusCommand: ICommand = new RelayCommand((value: string | null) => {
        this.updateField("enquiryStatusId", value);
    });

    public updateEnquiryTypeCommand: ICommand = new RelayCommand((value: string | null) => {
        this.updateField("enquiryTypeId", value);
    });

    public updatePersonVisitingCommand = new RelayCommand((value: string) => {
        this.setValue("enquiryPersonVisitingId", value);
    });

    public updatePersonVisitingVisitDateCommand = new RelayCommand((value: Date | undefined) => {
        this.updateField("enquiryPersonVisitingVisitDate", value);
    });

    public updatePersonVisitingVisitTimeCommand = new RelayCommand((value: string | null) => {
        this.updateField("enquiryPersonVisitingVisitTime", value);
    });

    public clearPersonVisitingVisitTimeCommand = new RelayCommand(() => {
        this.updateField("enquiryPersonVisitingVisitTime", null);
    });

    public updateTitleCommand = new RelayCommand((value: string) => {
        this.updateField("title", value);
    });

    public updateDescriptionCommand = new RelayCommand((value: string) => {
        this.updateField("description", value);
    });

    public updateStatusTaskCommand = new RelayCommand((value: string | null) => {
        this.updateField("enquiryStatusId", value);

        if (this.isModelValid()) {
            this.parentUpdateStatusTaskCommand.execute(value);
        }
    });

    public updatePersonVisitingTaskCommand = new RelayCommand((value: string) => {
        this.updateField("enquiryPersonVisitingId", value);
        this.parentUpdatePersonVisitingCommand.execute(value);
    });

    // #endregion Commands

    // #region Supporting

    private updateField(fieldName: keyof FieldType<EnquirySubModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    // #endregion Supporting
}
