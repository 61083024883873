import React, { useEffect, useState } from "react";
import { ThemedText, theme, formatCurrency, Box } from "Application";
import { observer } from "mobx-react-lite";
import { OverviewViewModel } from "../OverviewViewModel";
import { ResponsivePie } from "@nivo/pie";

import { nanoid } from "nanoid";
import { Callout, DirectionalHint } from "@fluentui/react";
import {
    AssignmentInfoIcon,
    Container,
    EventCallout,
    EventDisplayName,
    PieChart,
    PieChartContainer,
    PieChartDetailLabel,
    PieChartDetailLabelContainer,
    PieChartInnerLabel,
    PieChartTotalValueLabel,
    PseudoPieChart,
    WidgetWrapper,
} from "./PieChartShared.styles";
import { ListItemContainer, ListItemDetails, ListItemLink, ListItemPositionDot, ListItemPositionLabel, ListItemsContainer } from "../Shared/Shared.styles";
import { EnquiryLiteModel } from "Views/Shared/Enquiry/EnquiryLiteModel";
interface IProps {
    viewModel: OverviewViewModel;
}

export const EnquiriesPieChartView: React.FC<IProps> = observer((props: IProps) => {
    const { viewModel } = props;
    const [render, setRender] = useState(false);
    const [callout1, setIsCallout1Visible] = useState(false);
    const [callout2, setIsCallout2Visible] = useState(false);
    const [callout3, setIsCallout3Visible] = useState(false);
    const [button1Id] = useState(() => `callout-${nanoid()}`);
    const [button2Id] = useState(() => `callout-${nanoid()}`);
    const [button3Id] = useState(() => `callout-${nanoid()}`);

    useEffect(() => {
        setTimeout(() => {
            setRender(true);
        }, 500);
    });

    const renderEnquiriesList = (items: EnquiryLiteModel[]) => {
        const retVal: JSX.Element[] = [];

        items.forEach((item: EnquiryLiteModel, index) => {
            retVal.push(
                <ListItemContainer>
                    <ListItemPositionDot>
                        <ListItemPositionLabel>{index + 1}</ListItemPositionLabel>
                    </ListItemPositionDot>
                    <ListItemDetails>
                        <ListItemLink to={viewModel.enquiryDetailURL(item)}>
                            <ThemedText fontStyle={"h7"}>{item.title}</ThemedText>
                        </ListItemLink>
                    </ListItemDetails>
                </ListItemContainer>,
            );
        });

        return retVal;
    };

    const renderCallout = () => {
        return (
            <>
                {callout1 && (
                    <Callout directionalHint={DirectionalHint.bottomAutoEdge} onDismiss={() => setIsCallout1Visible(false)} target={`#${button1Id}`}>
                        <EventCallout maxWidth={"300px"} padding={"15px 20px"}>
                            <ListItemsContainer>{renderEnquiriesList(viewModel.sortedEnquiriesByCreatedDate)}</ListItemsContainer>
                        </EventCallout>
                    </Callout>
                )}
                {callout2 && (
                    <Callout directionalHint={DirectionalHint.bottomAutoEdge} onDismiss={() => setIsCallout2Visible(false)} target={`#${button2Id}`}>
                        <EventCallout maxWidth={"300px"} padding={"15px 20px"}>
                            <ListItemsContainer>{renderEnquiriesList(viewModel.sortedDomesticEnquiriesByCreatedDate)}</ListItemsContainer>
                        </EventCallout>
                    </Callout>
                )}
                {callout3 && (
                    <Callout directionalHint={DirectionalHint.bottomAutoEdge} onDismiss={() => setIsCallout3Visible(false)} target={`#${button3Id}`}>
                        <EventCallout maxWidth={"300px"} padding={"15px 20px"}>
                            <ListItemsContainer>{renderEnquiriesList(viewModel.sortedCommercialEnquiriesByCreatedDate)}</ListItemsContainer>
                        </EventCallout>
                    </Callout>
                )}
            </>
        );
    };

    const renderEnquiriesPieChart = () => (
        <>
            <Box display={"flex"} alignItems={"center"}>
                <ThemedText fontStyle="titleBold" marginRight={"5px"}>
                    Total enquiries
                </ThemedText>
                <AssignmentInfoIcon
                    id={button1Id}
                    color="black"
                    position="relative"
                    onClick={(e: any) => {
                        e.stopPropagation();
                        setIsCallout1Visible(true);
                    }}
                />
            </Box>

            <PieChartContainer>
                <PieChart>
                    <ResponsivePie
                        motionConfig={"wobbly"}
                        data={viewModel.totalEnquiriesPieChartData}
                        margin={{ top: 1, right: 1, bottom: 1, left: 1 }}
                        innerRadius={0.8}
                        padAngle={0.7}
                        cornerRadius={0}
                        activeOuterRadiusOffset={8}
                        borderWidth={1}
                        borderColor={{
                            from: "color",
                            modifiers: [["darker", 0.2]],
                        }}
                        arcLinkLabelsSkipAngle={10}
                        arcLinkLabelsTextColor="#333333"
                        arcLinkLabelsThickness={2}
                        arcLinkLabelsColor={{ from: "color" }}
                        arcLabelsSkipAngle={10}
                        arcLabelsTextColor={{
                            from: "color",
                            modifiers: [["darker", 2]],
                        }}
                        colors={{ datum: "data.color" }}
                        enableArcLabels={false}
                        enableArcLinkLabels={false}
                        isInteractive={false}
                    />
                    <PseudoPieChart />
                    <PieChartTotalValueLabel>
                        <ThemedText fontStyle="titleBoldXL" marginBottom={"5px"}>
                            {viewModel.numberOfEnquiries}
                        </ThemedText>
                        <ThemedText fontStyle="h8">Enquiries</ThemedText>
                    </PieChartTotalValueLabel>
                </PieChart>
                {viewModel.render && (
                    <PieChartDetailLabelContainer>
                        <PieChartDetailLabel
                            className="expand-to-right"
                            backgroundColor={`${viewModel.numberOfEnquiries ? theme.palette.common.teal : theme.palette.common.lighterGray}`}
                        >
                            <PieChartInnerLabel>
                                <ThemedText fontStyle="labelXS" color="white">
                                    Domestic
                                </ThemedText>
                                <ThemedText fontStyle="h8" color="white">
                                    {viewModel.numberOfDomesticEnquiries}({viewModel.percentageOfDomesticEnquiries})
                                </ThemedText>
                                <AssignmentInfoIcon
                                    id={button2Id}
                                    color="white"
                                    position={"absolute"}
                                    onClick={(e: any) => {
                                        e.stopPropagation();
                                        setIsCallout2Visible(true);
                                    }}
                                />
                            </PieChartInnerLabel>
                        </PieChartDetailLabel>
                        <PieChartDetailLabel backgroundColor={`${viewModel.numberOfEnquiries ? theme.palette.common.purple : theme.palette.common.lighterGray}`}>
                            <PieChartInnerLabel>
                                <ThemedText fontStyle="labelXS" color="white">
                                    Commercial
                                </ThemedText>
                                <ThemedText fontStyle="h8" color="white">
                                    {viewModel.numberOfCommercialEnquiries}({viewModel.percentageOfCommercialEnquiries})
                                </ThemedText>
                                <AssignmentInfoIcon
                                    id={button3Id}
                                    color="white"
                                    position={"absolute"}
                                    onClick={(e: any) => {
                                        e.stopPropagation();
                                        setIsCallout3Visible(true);
                                    }}
                                />
                            </PieChartInnerLabel>
                        </PieChartDetailLabel>
                    </PieChartDetailLabelContainer>
                )}
            </PieChartContainer>
        </>
    );

    return (
        <>
            <WidgetWrapper>
                <>{render && renderEnquiriesPieChart()}</>
            </WidgetWrapper>
            <>{renderCallout()}</>
        </>
    );
});
