import React, { useEffect, useState } from "react";
import { ThemedText, theme, formatCurrency, Box } from "Application";
import { observer } from "mobx-react-lite";
import { OverviewViewModel } from "../OverviewViewModel";
import { ResponsivePie } from "@nivo/pie";

import { nanoid } from "nanoid";
import { Callout, DirectionalHint } from "@fluentui/react";
import {
    AssignmentInfoIcon,
    Container,
    EventCallout,
    EventDisplayName,
    PieChart,
    PieChartContainer,
    PieChartDetailLabel,
    PieChartDetailLabelContainer,
    PieChartInnerLabel,
    PieChartTotalValueLabel,
    PseudoPieChart,
    WidgetWrapper,
} from "./PieChartShared.styles";
import { QuoteLiteModel } from "Views/Shared/Quote/QuoteLiteModel";
import styled from "@emotion/styled";
import { ListItemContainer, ListItemDetails, ListItemLink, ListItemPositionDot, ListItemPositionLabel, ListItemsContainer } from "../Shared/Shared.styles";

interface IProps {
    viewModel: OverviewViewModel;
}

export const QuotesPieChartView: React.FC<IProps> = observer((props: IProps) => {
    const { viewModel } = props;
    const [render, setRender] = useState(false);
    const [callout1, setIsCallout1Visible] = useState(false);
    const [callout2, setIsCallout2Visible] = useState(false);
    const [callout3, setIsCallout3Visible] = useState(false);
    const [button1Id] = useState(() => `callout-${nanoid()}`);
    const [button2Id] = useState(() => `callout-${nanoid()}`);
    const [button3Id] = useState(() => `callout-${nanoid()}`);

    useEffect(() => {
        setTimeout(() => {
            setRender(true);
        }, 500);
    });

    const renderQuotesList = (items: QuoteLiteModel[]) => {
        const retVal: JSX.Element[] = [];

        items.forEach((item: QuoteLiteModel, index) => {
            retVal.push(
                <ListItemContainer>
                    <ListItemPositionDot>
                        <ListItemPositionLabel>{index + 1}</ListItemPositionLabel>
                    </ListItemPositionDot>
                    <ListItemDetails>
                        <ListItemLink to={viewModel.quoteDetailURL(item)}>
                            <ThemedText fontStyle={"h7"}>{item.title}</ThemedText>
                        </ListItemLink>
                        <ThemedText fontStyle={"h7"}>{formatCurrency(item.quoteValue!)}</ThemedText>
                    </ListItemDetails>
                </ListItemContainer>,
            );
        });

        return retVal;
    };

    const renderCallout = () => {
        return (
            <>
                {callout1 && (
                    <Callout directionalHint={DirectionalHint.bottomAutoEdge} onDismiss={() => setIsCallout1Visible(false)} target={`#${button1Id}`}>
                        <EventCallout maxWidth={"300px"} padding={"15px 20px"}>
                            <ListItemsContainer>{renderQuotesList(viewModel.sortedQuotesByValue)}</ListItemsContainer>
                        </EventCallout>
                    </Callout>
                )}
                {callout2 && (
                    <Callout directionalHint={DirectionalHint.bottomAutoEdge} onDismiss={() => setIsCallout2Visible(false)} target={`#${button2Id}`}>
                        <EventCallout maxWidth={"300px"} padding={"15px 20px"}>
                            <ListItemsContainer>{renderQuotesList(viewModel.sortedDomesticQuotesByValue)}</ListItemsContainer>
                        </EventCallout>
                    </Callout>
                )}
                {callout3 && (
                    <Callout directionalHint={DirectionalHint.bottomAutoEdge} onDismiss={() => setIsCallout3Visible(false)} target={`#${button3Id}`}>
                        <EventCallout maxWidth={"300px"} padding={"15px 20px"}>
                            <ListItemsContainer>{renderQuotesList(viewModel.sortedCommercialQuotesByValue)}</ListItemsContainer>
                        </EventCallout>
                    </Callout>
                )}
            </>
        );
    };

    const renderQuotesPieChart = () => (
        <>
            <Box>
                <Box display={"flex"} alignItems={"center"}>
                    <ThemedText fontStyle="titleBold" marginRight={"5px"}>
                        Total quotes
                    </ThemedText>
                    <AssignmentInfoIcon
                        id={button1Id}
                        color="black"
                        position="relative"
                        onClick={(e: any) => {
                            e.stopPropagation();
                            setIsCallout1Visible(true);
                        }}
                    />
                </Box>
                <ThemedText fontStyle="h1" color="secondary">
                    {formatCurrency(viewModel.totalQuotesValue)}
                </ThemedText>
            </Box>
            <PieChartContainer>
                <PieChart>
                    <ResponsivePie
                        motionConfig={"wobbly"}
                        data={viewModel.totalQuotesPieChartData}
                        margin={{ top: 1, right: 1, bottom: 1, left: 1 }}
                        innerRadius={0.8}
                        padAngle={0.7}
                        cornerRadius={0}
                        activeOuterRadiusOffset={8}
                        borderWidth={1}
                        borderColor={{
                            from: "color",
                            modifiers: [["darker", 0.2]],
                        }}
                        arcLinkLabelsSkipAngle={10}
                        arcLinkLabelsTextColor="#333333"
                        arcLinkLabelsThickness={2}
                        arcLinkLabelsColor={{ from: "color" }}
                        arcLabelsSkipAngle={10}
                        arcLabelsTextColor={{
                            from: "color",
                            modifiers: [["darker", 2]],
                        }}
                        colors={{ datum: "data.color" }}
                        enableArcLabels={false}
                        enableArcLinkLabels={false}
                        isInteractive={false}
                    />
                    <PseudoPieChart />
                    <PieChartTotalValueLabel>
                        <ThemedText fontStyle="titleBoldXL" marginBottom={"5px"}>
                            {viewModel.totalNumberOfQuotes}
                        </ThemedText>
                        <ThemedText fontStyle="h8">Quotes</ThemedText>
                    </PieChartTotalValueLabel>
                </PieChart>
                {viewModel.render && (
                    <PieChartDetailLabelContainer>
                        <PieChartDetailLabel
                            className="expand-to-right"
                            backgroundColor={`${viewModel.totalNumberOfQuotes ? theme.palette.common.teal : theme.palette.common.lighterGray}`}
                        >
                            <PieChartInnerLabel>
                                <ThemedText fontStyle="labelXS" color="white">
                                    Domestic
                                </ThemedText>
                                <ThemedText fontStyle="h8" color="white">
                                    <> {viewModel.numberOfDomesticQuotes}</>
                                    <>({formatCurrency(viewModel.domesticQuotesValue)})</>
                                    <AssignmentInfoIcon
                                        id={button2Id}
                                        color="white"
                                        position={"absolute"}
                                        onClick={(e: any) => {
                                            e.stopPropagation();
                                            setIsCallout2Visible(true);
                                        }}
                                    />
                                </ThemedText>
                            </PieChartInnerLabel>
                        </PieChartDetailLabel>
                        <PieChartDetailLabel backgroundColor={`${viewModel.totalNumberOfQuotes ? theme.palette.common.purple : theme.palette.common.lighterGray}`}>
                            <PieChartInnerLabel>
                                <ThemedText fontStyle="labelXS" color="white">
                                    Commercial
                                </ThemedText>
                                <ThemedText fontStyle="h8" color="white">
                                    <> {viewModel.numberOfCommercialQuotes}</>
                                    <>({formatCurrency(viewModel.commercialQuotesValue)})</>
                                    <AssignmentInfoIcon
                                        id={button3Id}
                                        color="white"
                                        position={"absolute"}
                                        onClick={(e: any) => {
                                            e.stopPropagation();
                                            setIsCallout3Visible(true);
                                        }}
                                    />
                                </ThemedText>
                            </PieChartInnerLabel>
                        </PieChartDetailLabel>
                    </PieChartDetailLabelContainer>
                )}
            </PieChartContainer>
        </>
    );

    return (
        <>
            <WidgetWrapper>
                <>{render && renderQuotesPieChart()}</>
            </WidgetWrapper>
            <>{renderCallout()}</>
        </>
    );
});
