import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

export class SuppliersContactItemModel extends ModelBase<SuppliersContactItemModel> {
    public prefixName: string = "";
    public firstName: string = "";
    public lastName: string = "";

    public get fullName() {
        return [this.prefixName, this.firstName, this.lastName].filter(Boolean).join(" ");
    }

    constructor() {
        super();

        makeObservable(this, {
            prefixName: observable,
            firstName: observable,
            lastName: observable,
        });
    }
}
