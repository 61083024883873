import { ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";
import { LookupStore } from "Stores/Domain";
import { container } from "tsyringe";

import { ICommand, RelayCommand, theme } from "Application";
import { MyWorkViewModel } from "Views/Dashboard/Staff/MyWork/MyWorkViewModel";
import { ProjectTaskAssignmentEventModel } from "./ProjectTaskAssignmentEventModel";

import { StaffViewModel } from "Views/Resource/Staff/Table/StaffViewModel";
import { StaffDetailsViewModel } from "Views/Resource/Staff/Details/StaffDetailsViewModel";

export class ProjectTaskAssignmentEventViewModel extends ViewModelBase<ProjectTaskAssignmentEventModel> {
    private parentViewModel: MyWorkViewModel | StaffViewModel | StaffDetailsViewModel | null;

    private lookupStore = container.resolve(LookupStore);

    constructor(model: ProjectTaskAssignmentEventModel, parentViewModel: MyWorkViewModel | StaffViewModel | StaffDetailsViewModel | null = null) {
        super(model, false);

        this.parentViewModel = parentViewModel;

        makeObservable(this, {});
    }

    // #region Properties

    public get displayName() {
        return this.model.taskTitle;
    }

    public get displayTaskGroupName() {
        return this.model.taskGroupTitle;
    }

    public get displayTaskNote() {
        return this.model.projectTaskAssignmentNote;
    }

    public get isTaskReviewed() {
        const status = this.lookupStore.projectTaskAssignmentStatuses.find((pts) => pts.id === this.model.projectTaskAssignmentStatusId);
        return status?.type == "Assigned" && this.model.projectTaskAssignmentIsReviewed;
    }

    public get displayStatusName() {
        if (this.isTaskReviewed) {
            return "Reviewed";
        } else {
            const status = this.lookupStore.projectTaskAssignmentStatuses.find((pts) => pts.id === this.model.projectTaskAssignmentStatusId);

            if (status) {
                if (status.name == "Updated" || status.name == "Complete") {
                    return "For Review";
                } else if (status.name == "Assigned") {
                    return "In Progress";
                } else if (status.name == "Billed") {
                    return "Completed";
                } else {
                    return "";
                }
            } else {
                return "";
            }
        }
    }

    public get backgroundColor() {
        let retVal = "";
        if (this.isTaskReviewed) {
            retVal = theme.palette.quintenary.main;
        } else {
            const status = this.lookupStore.projectTaskAssignmentStatuses.find((pts) => pts.id === this.model.projectTaskAssignmentStatusId);

            retVal = status?.foregroundColor ?? "#6595b4";
        }
        return retVal;
    }

    // #endregion Properties

    // #region Commands

    public displayProjectTaskAssignmentCommand = new RelayCommand(
        () => this.parentViewModel?.displayTaskCommand.execute(this.model.id),
        () => this.parentViewModel !== null && this.parentViewModel.displayTaskCommand.canExecute(),
    );

    // #endregion Commands
}
