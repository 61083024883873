import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { ProgrammingWeekTaskGroupModel } from "../ProgrammingWeekTaskGroup/ProgrammingWeekTaskGroupModel";

class Request {
    public projectTaskAssignmentGroupId: string | null = null;
    public projectTaskAssignmentStatusId: string | null = null;
    public projectTaskAssignmentUserNote: string | null = null;
}

class Response {}

export class POSTInsertProjectTaskAssignmentGroupUserNoteEndpoint extends Endpoint<Request, Response> {
    constructor() {
        super();

        this.verb(Http.Post);
        this.path(AppUrls.Server.Dashboard.Staff.PostInsertProjectTaskAssignmentGroupUserNote);
    }

    public async HandleRequestAsync(model: ProgrammingWeekTaskGroupModel): Promise<any> {
        const request = new Request();

        request.projectTaskAssignmentGroupId = model.id;
        request.projectTaskAssignmentUserNote = model.projectTaskAssignmentUserNote;
        request.projectTaskAssignmentStatusId = model.projectTaskAssignmentStatusId;

        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        return Promise.resolve(response);
    }
}
