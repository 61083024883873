import { ViewModelBase, isEmptyOrWhitespace } from "@shoothill/core";
import { computed, makeObservable, observable } from "mobx";

import { APIClient, RelayCommand } from "Application";
import { CalloutInformationModel } from "./Components/CalloutInformation/CalloutInformationModel";
import { CalloutInformationViewModel } from "./Components/CalloutInformation/CalloutInformationViewModel";
import { SendUpdatesConfirmationViewModel } from "./Components/SendUpdatesConfirmation/SendUpdatesConfirmationViewModel";
import { GetProjectUpdateTransactionViewsByProjectUpdateDate } from "./Endpoints/GetProjectUpdateTransactionViewsByProjectUpdateDate";
import { SendProjectWeeklyUpdates } from "./Endpoints/SendProjectWeeklyUpdates";
import { WeeklyUpdatesItemViewModel } from "./WeeklyUpdatesItemViewModel";
import { WeeklyUpdatesListModel } from "./WeeklyUpdatesListModel";

export class WeeklyUpdatesListViewModel extends ViewModelBase<WeeklyUpdatesListModel> {
    public apiClient = new APIClient();
    public weeklyUpdates = observable<WeeklyUpdatesItemViewModel>([]);
    public calloutInformationViewModel: CalloutInformationViewModel | null = null;
    public sendUpdatesConfirmationViewModel: SendUpdatesConfirmationViewModel | null = null;

    constructor() {
        super(new WeeklyUpdatesListModel());

        makeObservable(this, {
            weeklyUpdates: observable,
            calloutInformationViewModel: observable,
            canDisplayCalloutInformation: computed,
            sendUpdatesConfirmationViewModel: observable,
            canDisplaySendUpdatesConfirmation: computed,
            projectUpdateCommencementDate: computed,
            numberOfUpdatesAvailable: computed,
            canSortWeeklyUpdates: computed,
            sortedWeeklyUpdates: computed,
        });

        // Make API call from here to get history.
        this.apiClient.sendAsync(new GetProjectUpdateTransactionViewsByProjectUpdateDate(this));
    }

    // #region Current Transaction Status

    public get projectUpdateCommencementDate() {
        return this.model.projectUpdateCommencementDate;
    }

    public get numberOfUpdatesAvailable() {
        return this.model.numberOfUpdatesAvailable;
    }

    // #region Current Transaction Status

    // #region Sort Data

    public get canSortWeeklyUpdates(): boolean {
        return !isEmptyOrWhitespace(this.model.sortKey);
    }

    public get sortedWeeklyUpdates(): WeeklyUpdatesItemViewModel[] {
        return this.canSortWeeklyUpdates
            ? this.weeklyUpdates
                  .slice()
                  .sort((lhs, rhs) =>
                      (this.model.sortDescending ? lhs[this.model.sortKey] < rhs[this.model.sortKey] : lhs[this.model.sortKey] > rhs[this.model.sortKey]) ? 1 : -1,
                  )
            : this.weeklyUpdates.slice();
    }

    public updateSortCommand = new RelayCommand((key: string, sortDescending: boolean) => {
        this.model.sortKey = key;
        this.model.sortDescending = sortDescending;
    });

    // #endregion Sort Data

    // #region Callout Information

    public get canDisplayCalloutInformation() {
        return this.calloutInformationViewModel !== null;
    }

    public displayCalloutInformationCommand = new RelayCommand((projectTransactionId: string, fieldName: string, callOutKey: string) => {
        this.calloutInformationViewModel = new CalloutInformationViewModel(new CalloutInformationModel(projectTransactionId, fieldName, callOutKey));
    });

    public cancelCalloutInformationCommand = new RelayCommand(() => {
        this.calloutInformationViewModel = null;
    });

    public createCalloutKey = (item: WeeklyUpdatesItemViewModel, fieldName: string) => {
        return `${fieldName}-${item.model.projectUpdateTransactionId}`.toLowerCase();
    };

    // #endregion Callout Information

    // #region Send updates Confirmation

    public get canDisplaySendUpdatesConfirmation() {
        return this.sendUpdatesConfirmationViewModel !== null;
    }

    public displaySendUpdatesConfirmationCommand = new RelayCommand(
        () => {
            this.sendUpdatesConfirmationViewModel = new SendUpdatesConfirmationViewModel(this.cancelSendUpdatesCommand, this.apiSubmitSendUpdatesCommandAsync);
        },
        () => {
            return !this.apiClient.IsBusy && this.model.canCreateTransaction;
        },
    );

    public apiSubmitSendUpdatesCommandAsync = new RelayCommand(async () => {
        this.sendUpdatesConfirmationViewModel = null;

        await this.apiClient.sendAsync(new SendProjectWeeklyUpdates(this));

        if (this.apiClient.IsRequestSuccessful) {
            this.apiClient.sendAsync(new GetProjectUpdateTransactionViewsByProjectUpdateDate(this));
        }
    });

    public cancelSendUpdatesCommand = new RelayCommand(() => {
        this.sendUpdatesConfirmationViewModel = null;
    });

    public resetServerErrorCommand = new RelayCommand(() => {
        this.apiClient.reset();
    });

    // #endregion Send updates Confirmation
}
