import { FieldType, isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { computed, makeObservable, observable } from "mobx";

import { RelayCommand } from "Application";
import { TermsAndConditionsModel, TermsAndConditionsModelValidator } from "./TermsAndConditionsModel";
import { TermsAndConditionItemModel } from "./TermsAndConditionItemModel";

export class TermsAndConditionsViewModel extends ViewModelBase<TermsAndConditionsModel> {
    public termsAndConditionsTemplates = observable<TermsAndConditionItemModel>([]);

    constructor(termsAndConditionModel: TermsAndConditionsModel = new TermsAndConditionsModel()) {
        super(termsAndConditionModel);

        this.setValidator(new TermsAndConditionsModelValidator());

        makeObservable(this, {
            // Observables
            termsAndConditionsTemplates: observable,
            // Computeds
            canDisplayForQuickQuote: computed,
            signatoryUserOptions: computed,
            signatoryUserName: computed,
        });
    }

    // #region Properties

    public get canDisplayForQuickQuote() {
        return this.model.isQuickQuote;
    }

    public get termsAndConditionsOptions() {
        return this.termsAndConditionsTemplates.map((item) => {
            return {
                key: item.id,
                text: item.name,
            };
        });
    }

    public get signatoryUserName(): string {
        const user = this.model.signatoryUsers.find((u) => u.id === this.model.signatoryUserId);

        return user ? `${user.firstName} ${user.lastName}` : "Not available";
    }

    public get canDisplayCompletedSignatoryUser(): boolean {
        return !isEmptyOrWhitespace(this.model.signatoryUserId);
    }

    public get signatoryUserOptions() {
        return this.model.signatoryUsers.map((u) => ({
            key: u.id,
            text: `${u.firstName} ${u.lastName}`,
        }));
    }

    // #endregion Properties

    // #region Commands

    public updateTermsAndConditionsTemplateIdCommand = new RelayCommand((value: string | string) => {
        this.setValue("termAndConditionId", value);

        // SIDE-EFFECT. When changing the template selection, we need to reset the details
        // to the body of the new template.
        const template = value ? this.termsAndConditionsTemplates.find((item) => item.id === value) : null;

        this.updateField("termsAndConditionsDetails", template?.body);
    });

    public updateTermsAndConditionsText = (value: string): void => {
        this.updateField("termsAndConditionsDetails", value);
    };

    public updateSignatoryUserCommand = new RelayCommand((value: string | null) => {
        this.setValue("signatoryUserId", value);
    });

    public setSaveQuoteStatusType = (value: string | null): void => {
        this.setValue("saveQuoteStatusType", value);
    };

    // #endregion Commands

    // #region Supporting

    private updateField(fieldName: keyof FieldType<TermsAndConditionsModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    // #endregion Supporting
}
