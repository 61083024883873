import { makeObservable, observable, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";

import { RelayCommand } from "Application";
import { AdminModel } from "./AdminModel";
import { GlobalHistory } from "index";
import { AppUrls } from "AppUrls";

export class AdminViewModel extends ViewModelBase<AdminModel> {
    public locationPathName: string = "";

    constructor(model = new AdminModel()) {
        super(model, false);

        makeObservable(this, {
            locationPathName: observable,
        });

        GlobalHistory.listen((locationUpdate) => runInAction(() => this.setLocationPathName(locationUpdate.location.pathname)));

        this.setLocationPathName(location.pathname);
    }

    private setLocationPathName = (pathName: string) => {
        this.locationPathName = pathName;
    };

    public setLetterTemplatesTabCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.LetterTemplates.Table);
    });

    public setTermConditionTemplatesTabCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.TermConditionTemplates.Table);
    });

    public setServicesTabCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.Services.Table);
    });

    public setImageSettingsTabCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.ImageSettings.Table);
    });

    public setUsersListTabCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.Users.Table);
    });

    public setLeaveTabCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.Leave.LeaveHome);
    });

    public setInvoiceForecastCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.InvoiceForecast.Home);
    });

    public setInvoiceHistoryCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.InvoiceHistory.Home);
    });

    public setWeeklyUpdatesTabCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.WeeklyUpdates.Table);
    });
}
