import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";

class Response {}

export class POSTResetLeaveAllowancesEndpoint extends EndpointWithoutRequest<Response> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Admin.Leave.ResetAllowances);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);
    }
}
