import { isEmptyOrWhitespace, isNullOrUndefined, KeyValuePair, ViewModelBase } from "@shoothill/core";
import { computed, makeObservable, observable } from "mobx";
import { container } from "tsyringe";

import { APIClient, ICommand, RelayCommand } from "Application";
import { AppUrls } from "AppUrls";
import { LookupStore, SettingsStore } from "Stores/Domain";
import { GETAllProjectsLiteEndpoint } from "./Endpoints/GETAllProjectsLiteEndpoint";
import { GETAllSentQuotesLiteEndpoint } from "./Endpoints/GETAllSentQuotesLiteEndpoint";
import { POSTSaveQuoteAsProjectEndpoint } from "./Endpoints/POSTSaveQuoteAsProjectEndpoint";
import { NewProjectViewModel } from "./ModalViews/NewProjectViewModel";
import { ProjectsModel } from "./ProjectsModel";
import { ProjectItemViewModel } from "./ProjectItemViewModel";

import { GETQuoteLetterDocumentByIdEndpoint } from "Views/NewBusiness/Quotes/Endpoints/GETQuoteLetterDocumentByIdEndpoint";
import { GETQuoteTasksAndCostsDocumentByIdEndpoint } from "Views/NewBusiness/Quotes/Endpoints/GETQuoteTasksAndCostsDocumentByIdEndpoint";
import { GETQuoteTermsAndConditionsDocumentByIdEndpoint } from "Views/NewBusiness/Quotes/Endpoints/GETQuoteTermsAndConditionsDocumentByIdEndpoint";

export class ProjectsViewModel extends ViewModelBase<ProjectsModel> {
    public apiClient = new APIClient();
    private lookupStore = container.resolve(LookupStore);

    public newProjectViewModel: NewProjectViewModel | null = null;
    public projects = observable<ProjectItemViewModel>([]);

    constructor() {
        super(new ProjectsModel());

        makeObservable(this, {
            // Observables
            projects: observable,
            newProjectViewModel: observable,

            // Computeds
            canFilterProjects: computed,
            filteredProjects: computed,
            canSortProjects: computed,
            filteredAndSortedProjects: computed,
            canDisplayNewProject: computed,
        });

        this.apiClient.sendAsync(new GETAllProjectsLiteEndpoint(this));
    }

    public get settings() {
        return container.resolve(SettingsStore).ProjectTable;
    }

    // #region User Roles

    public get isAdmin() {
        return this.lookupStore.AccountStore.isInRole("admin");
    }

    public get isBusinessSupport() {
        return this.lookupStore.AccountStore.isInRole("businesssupport");
    }

    public get isSeniorAssociate() {
        return this.lookupStore.AccountStore.isInRole("seniorassociate");
    }

    public get isArchitect() {
        return this.lookupStore.AccountStore.isInRole("architect");
    }

    // #endregion User Roles

    // #region Display Permissions

    public get showNewProjectButton() {
        return this.isAdmin || this.isSeniorAssociate || this.isBusinessSupport;
    }

    public get canNavigateToProjectDetail() {
        return this.isAdmin || this.isSeniorAssociate || this.isBusinessSupport;
    }

    // #endregion Display Permissions

    // #region Filtering

    public updateFilterKeywordCommand = new RelayCommand((keyword: string) => {
        this.model.filterKeyword = keyword;
    });

    public updateFilterProjectStatusCommand = new RelayCommand((statusId: string | null) => {
        this.model.filterProjectStatusId = statusId;
    });

    public updateFilterProjectLeadCommand = new RelayCommand((id: string | null) => {
        this.model.filterProjectLeadId = id;
    });

    public updateFilterSeniorAssociateCommand = new RelayCommand((id: string | null) => {
        this.model.filterSeniorAssociateId = id;
    });

    public updateFilterProjectSupportCommand = new RelayCommand((id: string | null) => {
        this.model.filterProjectSupportId = id;
    });

    public get projectStatuses() {
        return [{ key: "", text: "All project statuses" }, ...this.lookupStore.getProjectStatuses()];
    }

    public get projectLeads() {
        const options = this.projects
            .filter((p) => p.model.projectLeadId && p.model.projectLeadFirstName && p.model.projectLeadLastName)
            .map((p) => {
                return {
                    key: p.model.projectLeadId!,
                    text: `${p.model.projectLeadFirstName} ${p.model.projectLeadLastName}`,
                };
            });
        return this.formatKeyValuePairs(options, { key: "", text: "All project leads" });
    }

    public get seniorAssociates() {
        const options = this.projects
            .filter((p) => p.model.seniorAssociateId && p.model.seniorAssociateFirstName && p.model.seniorAssociateLastName)
            .map((t) => {
                return {
                    key: t.model.seniorAssociateId!,
                    text: `${t.model.seniorAssociateFirstName} ${t.model.seniorAssociateLastName}`,
                };
            });

        return this.formatKeyValuePairs(options, { key: "", text: "All project overviews" });
    }
    public get projectSupport() {
        const options = this.projects
            .filter((p) => p.model.projectSupportId && p.model.projectSupportFirstName && p.model.projectSupportLastName)
            .map((t) => {
                return {
                    key: t.model.projectSupportId!,
                    text: `${t.model.projectSupportFirstName} ${t.model.projectSupportLastName}`,
                };
            });

        return this.formatKeyValuePairs(options, { key: "", text: "All project supports" });
    }

    public get canFilterProjects(): boolean {
        return (
            !isEmptyOrWhitespace(this.model.filterKeyword) ||
            !isEmptyOrWhitespace(this.model.filterProjectStatusId) ||
            !isEmptyOrWhitespace(this.model.filterProjectLeadId) ||
            !isEmptyOrWhitespace(this.model.filterSeniorAssociateId) ||
            !isEmptyOrWhitespace(this.model.filterProjectSupportId)
        );
    }

    public get filteredProjects(): ProjectItemViewModel[] {
        return this.canFilterProjects
            ? this.projects.filter((vm) =>
                  vm.filterPredicate(
                      this.model.filterKeyword,
                      this.model.filterProjectStatusId,
                      this.model.filterProjectLeadId,
                      this.model.filterSeniorAssociateId,
                      this.model.filterProjectSupportId,
                  ),
              )
            : this.projects;
    }

    // #endregion Filtering

    // #region Sorting

    public updateSortCommand = new RelayCommand((key: string, sortDescending: boolean) => {
        this.settings.sortKey = key;
        this.settings.sortDescending = sortDescending;
    });

    public get canSortProjects(): boolean {
        return !isEmptyOrWhitespace(this.settings.sortKey);
    }

    public get filteredAndSortedProjects(): ProjectItemViewModel[] {
        return this.canSortProjects
            ? this.filteredProjects
                  .slice()
                  .sort((lhs, rhs) =>
                      (this.settings.sortDescending ? lhs[this.settings.sortKey] < rhs[this.settings.sortKey] : lhs[this.settings.sortKey] > rhs[this.settings.sortKey]) ? 1 : -1,
                  )
            : this.filteredProjects;
    }

    // #endregion Sorting

    // #region Navigation

    public navigateToProjectCommand = new RelayCommand((vm: ProjectItemViewModel, index?: number, ev?: React.FocusEvent<HTMLElement>) => {
        this.history.push(AppUrls.Client.Projects.Details.replace(":id", vm.model.id));
    });

    public navigateToProjectByIdCommand = new RelayCommand((id: string) => {
        this.history.push(AppUrls.Client.Projects.Details.replace(":id", id));
    });

    public navigateToCustomerDetailCommand = new RelayCommand((id: string) => {
        this.history.push(AppUrls.Client.Customers.Details.replace(":id", id));
    });

    // #endregion Navigation

    // #region Properties

    public get canDisplayNewProject(): boolean {
        return this.newProjectViewModel !== null;
    }

    // #endregion Properties

    // #region Commands

    public displayNewProjectComand: ICommand = new RelayCommand(() => {
        this.apiClient.sendAsync(new GETAllSentQuotesLiteEndpoint(this));
    });

    public updateFromNewProjectCommand: ICommand = new RelayCommand(
        (quoteId: string) => {
            this.apiClient.sendAsync(new POSTSaveQuoteAsProjectEndpoint(this));
        },
        () => {
            return !this.apiClient.IsBusy;
        },
    );

    public cancelDisplayNewProjectCommand: ICommand = new RelayCommand(() => {
        this.newProjectViewModel = null;
    });

    // #endregion Commands

    // #region Downloads

    public downloadLetterDocumentCommand = new RelayCommand((quoteId: string) => this.apiClient.sendAsync(new GETQuoteLetterDocumentByIdEndpoint(quoteId, true)));

    public downloadTasksAndCostsDocumentCommand = new RelayCommand((quoteId: string) => this.apiClient.sendAsync(new GETQuoteTasksAndCostsDocumentByIdEndpoint(quoteId, true)));

    public downloadTermsAndConditionsDocumentCommand = new RelayCommand((quoteId: string) =>
        this.apiClient.sendAsync(new GETQuoteTermsAndConditionsDocumentByIdEndpoint(quoteId, true)),
    );

    // #endregion Downloads

    // #region helpers
    private formatKeyValuePairs = (keyValuePairs: KeyValuePair[], defaultKeyValuePair?: KeyValuePair) => {
        const distinctOptions = keyValuePairs
            .sort((a, b) => (a.text > b.text ? 1 : b.text > a.text ? -1 : 0))
            .filter((option, index, arr) => arr.findIndex((t) => t.key === option.key) === index);
        const retVal = [...distinctOptions];

        if (defaultKeyValuePair) {
            retVal.unshift(defaultKeyValuePair);
        }
        return retVal;
    };

    // #endregion helpers
}
