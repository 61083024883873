import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import {
    PLANNING_APPLICATION_STATUS_APPROVED,
    PLANNING_APPLICATION_STATUS_LIVE,
    PLANNING_APPLICATION_STATUS_REJECTED,
    PLANNING_APPLICATION_STATUS_WITHDRAWN,
} from "Views/Shared/Constants";

export class PlanningApplicationTableModel extends ModelBase<PlanningApplicationTableModel> {
    // region Filtering
    public filterKeyword: string = "";
    public filterLocalAuthorityDistrictId: string | null = null;
    public filterProjectLeadId: string | null = null;
    public filterDateFrom: Date | null = null;
    public filterDateTo: Date | null = null;
    public filterStatusType:
        | typeof PLANNING_APPLICATION_STATUS_LIVE
        | typeof PLANNING_APPLICATION_STATUS_APPROVED
        | typeof PLANNING_APPLICATION_STATUS_REJECTED
        | typeof PLANNING_APPLICATION_STATUS_WITHDRAWN = PLANNING_APPLICATION_STATUS_LIVE;
    public filterPlanningApplicationTypeId: string | null = null;
    //region Related
    public financialPeriodTypeId: string | null = null;

    constructor() {
        super();
        makeObservable(this, {
            // Observables
            filterKeyword: observable,
            filterLocalAuthorityDistrictId: observable,
            filterPlanningApplicationTypeId: observable,
            filterProjectLeadId: observable,
            filterDateFrom: observable,
            filterDateTo: observable,
            financialPeriodTypeId: observable,
            filterStatusType: observable,
        });
    }
}
