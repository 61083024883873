import { observer } from "mobx-react-lite";
import { Container, Row, ActionsContainer, editServiceInputStyles, addEditButtonStyleOverride, removeCancelButtonStyleOverride } from "./EditServiceView.styles";
import { ThemedButton, ThemedCheckBox, ThemedEditText } from "Application";
import { CheckMark } from "Components/General/CheckMark";
import { ServiceViewModel } from "../ServiceViewModel";
import { CloseButton } from "../../ServiceGroup/ServiceGroup.styles";

export const EditServiceView: React.FC<{ viewModel: ServiceViewModel }> = observer(({ viewModel }) => {
    return (
        <Container>
            <Row>
                <ThemedEditText command={viewModel.updateNameCommand} styles={editServiceInputStyles} value={() => viewModel.getValue("name")} />
            </Row>
            <Row>
                <ThemedCheckBox
                    displayName="Link to planning application"
                    labelColor="secondary"
                    // validationMessage={() => viewModel.getError("isLinkedToPlanningApplication")}
                    value={() => viewModel.getValue("isLinkedToPlanningApplication")}
                    command={viewModel.updateIsLinkedToPlanningApplicationCommand}
                />
            </Row>
            <Row>
                <ThemedEditText command={viewModel.updateManHoursCommand} value={() => viewModel.getValue("manHours") ?? ""} />
            </Row>
            <Row>
                <ThemedEditText command={viewModel.updateHourlyRateCommand} value={() => viewModel.getValue("hourlyRate") ?? ""} />
            </Row>
            <ActionsContainer>
                <ThemedButton styles={addEditButtonStyleOverride} command={viewModel.submitUpdateServiceCommand} icon={<CheckMark />} marginRight={15} />
                <ThemedButton styles={removeCancelButtonStyleOverride} command={viewModel.cancelUpdateServiceCommand} icon={<CloseButton />} />
            </ActionsContainer>
        </Container>
    );
});
