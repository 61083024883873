import { ModelBase } from "@shoothill/core";
import { nanoid } from "nanoid";
import { makeObservable } from "mobx";

export class BilledAssignmentsItemModel extends ModelBase<BilledAssignmentsItemModel> {
    public KEY: string = nanoid();

    public id: string = "";
    public projectTaskName: string = "";
    public projectTaskAssignmentStatusForegroundColor: string = "";
    public billedEffort: number = 0;
    public billedDate: Date | null = null;
    public billedNote: string = "";
    public assignedUserFirstName: string = "";
    public assignedUserLastName: string = "";
    public billedByUserFirstName: string = "";
    public billedByUserLastName: string = "";
    public isNonBillableEffort: boolean = false;

    constructor() {
        super();

        makeObservable(this, {});
    }
}
