// Libraries
import React from "react";
import styled from "@emotion/styled";

// Styling & images
import { Terms } from "./Terms";
import { AutoGrid, Box } from "../../../Application";

export const TermsAndConditions: React.FC = () => {
    window.scrollTo(0, 0);

    const terms = (
        <>
            <Box center>
                <TermsContainer>
                    <Terms />
                </TermsContainer>
            </Box>
        </>
    );

    return (
        <>
            <div>{terms}</div>
        </>
    );
};

const TermsContainer = styled(Box)`
    p {
        font-size: 14px;
        margin-bottom: 15px;
        letter-spacing: -0.35px;
        line-height: 24px;
    }

    h1 {
        text-align: left;
        font-size: 28px;
    }

    h2 {
        text-align: left;
        font-size: 24px;
    }

    ol li {
        margin-bottom: 15px;
    }
`;
