import { makeObservable, observable, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, IKeyState } from "Application";
import { PlanningApplicationsModel } from "./PlanningApplicationsModel";
import { GETPlanningApplicationTotalsEndpoint } from "./Endpoints/GETPlanningApplicationTotalsEndpoint";
import { PlanningApplicationLiteModel } from "./PlanningApplicationLiteModel";

export class PlanningApplicationsViewModel extends ViewModelBase<PlanningApplicationsModel> {
    public apiClient = new APIClient();

    constructor() {
        super(new PlanningApplicationsModel());

        makeObservable(this, {});
        this.loadData();
    }

    public loadData = () => this.apiClient.sendAsync(new GETPlanningApplicationTotalsEndpoint(this));
    //region properties

    public get live(): number {
        return this.model.live;
    }

    public get refused(): number {
        return this.model.refused;
    }

    public get approved(): number {
        return this.model.approved;
    }

    public get sortedLivePlanningApplicationLites(): PlanningApplicationLiteModel[] {
        return this.model.livePlanningApplicationLites.slice().sort((a, b) => a.projectTitle.localeCompare(b.projectTitle));
    }
}
