import styled from "@emotion/styled";
import { Callout, DirectionalHint } from "@fluentui/react";
import { darken, lighten } from "@mui/material";
import { observer } from "mobx-react-lite";
import { nanoid } from "nanoid";
import React, { useState } from "react";

import { Box, ThemedText, ThemedTransparentIconButton } from "Application";
import { ApproveIcon } from "Assets/Icons/ApproveIcon";
import { CrossSVG } from "Assets/CrossSVG";
import { DeclineIcon } from "Assets/Icons/DeclineIcon";
import { EditSVG } from "Assets/EditSVG";
import InfoIcon from "Assets/InfoIcon.svg";
import { LeaveRequestEventViewModel } from "./LeaveRequestEventViewModel";

interface IProps {
    viewModel: LeaveRequestEventViewModel;
}

const EventDisplayName = styled(Box)`
    font-size: 10px;
    font-weight: bold;
    padding: 7px 12px 3px 7px;
`;

const EventDisplayStatusName = styled(Box)`
    font-size: 10px;
    padding: 0px 12px 0px 7px;
`;

const EventDisplayDetailGroup = styled.div`
    display: flex;
    font-size: 10px;
    padding: 0px 12px 0px 7px;
    & > *:first-of-type {
        margin-right: 5px;
        font-weight: bold;
    }
`;

const LeaveRequestInfoIcon = styled(Box)`
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url(${InfoIcon});
    height: 18px;
    margin: 4px 4px auto auto;
    opacity: 0.33;
    width: 18px;
`;

export const LeaveRequestEventView: React.FC<IProps> = observer((props) => {
    const [callout, setIsCalloutVisible] = useState(false);
    const [buttonId] = useState(() => `callout-${nanoid()}`);
    const viewModel = props.viewModel;

    const EventCell = styled(Box)`
        background: ${viewModel.isPendingRequest
            ? `repeating-linear-gradient(
                45deg,
                ${viewModel.backgroundColor},
                ${viewModel.backgroundColor} 10px,
                ${lighten(viewModel.backgroundColor, 0.5)} 10px,
                ${lighten(viewModel.backgroundColor, 0.5)} 20px
            )`
            : viewModel.backgroundColor};
        border-left: 5px solid ${darken(viewModel.backgroundColor, 0.25)};
        display: flex;
        flex: 1;
        cursor: pointer;
        justify-content: end;
    `;

    const EventCallout = styled(Box)`
        background: ${viewModel.isPendingRequest
            ? `repeating-linear-gradient(
                45deg,
                ${viewModel.backgroundColor},
                ${viewModel.backgroundColor} 10px,
                ${lighten(viewModel.backgroundColor, 0.5)} 10px,
                ${lighten(viewModel.backgroundColor, 0.5)} 20px
            )`
            : viewModel.backgroundColor};
        border-left: 5px solid ${darken(viewModel.backgroundColor, 0.25)};
        display: flex;
        flex: 1;
        min-width: 260px;
        min-height: 60px;
        padding-bottom: 10px;
    `;

    const ApproveCommandButton = styled(ThemedTransparentIconButton)`
        height: 24px;
        width: 24px;
        path {
            fill: ${darken(viewModel.backgroundColor, 0.33)};
        }
    `;

    const DeclineCommandButton = styled(ThemedTransparentIconButton)`
        height: 24px;
        width: 24px;
        path {
            fill: ${darken(viewModel.backgroundColor, 0.33)};
        }
    `;

    const EditCommandButton = styled(ThemedTransparentIconButton)`
        height: 24px;
        width: 24px;
        g {
            stroke: ${darken(viewModel.backgroundColor, 0.33)};
        }
    `;

    const CancelCommandButton = styled(ThemedTransparentIconButton)`
        height: 24px;
        width: 24px;
        svg {
            scale: 80%;
        }
        g {
            stroke: ${darken(viewModel.backgroundColor, 0.33)};
        }
    `;

    return (
        <React.Fragment>
            <EventCell id={buttonId} onClick={() => setIsCalloutVisible(true)}>
                <LeaveRequestInfoIcon />
            </EventCell>
            {callout && (
                <Callout directionalHint={DirectionalHint.topAutoEdge} onDismiss={() => setIsCalloutVisible(false)} target={`#${buttonId}`}>
                    <EventCallout>
                        <Box flex={1}>
                            <EventDisplayName>{viewModel.displayName}</EventDisplayName>
                            <EventDisplayDetailGroup>
                                <span>Request date:</span>
                                <span>{viewModel.requestedDate}</span>
                            </EventDisplayDetailGroup>
                            {viewModel.isApprovedRequest && (
                                <>
                                    <EventDisplayDetailGroup>
                                        <span>Approved date:</span>
                                        <span>{viewModel.requestedDate}</span>
                                    </EventDisplayDetailGroup>
                                    <EventDisplayDetailGroup>
                                        <span>Approved by:</span>
                                        <span>{viewModel.responseUserFullName}</span>
                                    </EventDisplayDetailGroup>
                                </>
                            )}
                            <EventDisplayDetailGroup>
                                <span>Reason:</span>
                                <span>{viewModel.requestReason}</span>
                            </EventDisplayDetailGroup>
                            <EventDisplayStatusName>
                                {viewModel.displayStatusName} {viewModel.displayNumberOFDays}
                            </EventDisplayStatusName>
                        </Box>
                        <Box>
                            <Box display="flex" justifyContent="right">
                                {viewModel.approveLeaveCommand.canExecute() && <ApproveCommandButton command={props.viewModel.approveLeaveCommand} icon={<ApproveIcon />} />}
                                {viewModel.declineLeaveCommand.canExecute() && <DeclineCommandButton command={props.viewModel.declineLeaveCommand} icon={<DeclineIcon />} />}
                            </Box>
                            <Box display="flex" justifyContent="right">
                                {viewModel.editLeaveCommand.canExecute() && <EditCommandButton command={props.viewModel.editLeaveCommand} icon={<EditSVG />} />}
                                {viewModel.deleteLeaveCommand.canExecute() && <CancelCommandButton command={props.viewModel.deleteLeaveCommand} icon={<CrossSVG />} />}
                                {viewModel.cancelLeaveCommand.canExecute() && <CancelCommandButton command={props.viewModel.cancelLeaveCommand} icon={<CrossSVG />} />}
                            </Box>
                        </Box>
                    </EventCallout>
                </Callout>
            )}
        </React.Fragment>
    );
});
