import styled from "@emotion/styled";
import { PersonaSize } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React from "react";

import { AutoGrid, Box, ThemedIconButton } from "Application";
import { EditSVG } from "Assets/EditSVG";
import { ThemedPersona } from "Styles/ThemedPrimitives/Style1/ThemedPersona";
import { UserCalendarViewModel } from "./UserCalendarViewModel";
import { DateView } from "../Date/DateView";

interface IProps {
    viewModel: UserCalendarViewModel;
}

const RowContainer = styled(AutoGrid)`
    margin: 0 0 5px 0;
`;

const UserPartContainer = styled(AutoGrid)`
    margin: 0;
`;

const UserAllowanceAndLeaveContainer = styled(AutoGrid)`
    align-items: center;
    border-left: 1px solid #e1e1e1;
    display: flex;
    justify-content: center;
    margin: 0;
`;

const DatePartContainer = styled(Box)`
    display: flex;
    margin-bottom: "5px";
`;

const DaysAccruedBox = styled(Box)`
    font-size: 12px;
    font-weight: bold;
    text-align: right;
`;

const DaysLeftBox = styled(Box)`
    align-items: center;
    border-left: 1px solid #e1e1e1;
    display: flex;
    font-size: 12px;
    font-weight: bold;
    justify-content: center;
`;

export const UserCalendarView: React.FC<IProps> = observer((props) => {
    const viewModel = props.viewModel;

    return (
        <RowContainer mc="300px 1fr" tc="300px 1fr" dc="300px 1fr" columnGap={0} rowGap={0}>
            <UserPartContainer mc="175px 3fr 2fr" tc="175px 3fr 2fr" dc="175px 3fr 2fr" columnGap={0} rowGap={0}>
                <ThemedPersona
                    displayName={viewModel.renderNameContentString}
                    imageUrl={viewModel.thumbnailUrl}
                    size={PersonaSize.size32}
                    styles={{ root: { height: "auto", paddingLeft: "12px" }, primaryText: { fontSize: "10px", whiteSpace: "pre-wrap" } }}
                />
                <UserAllowanceAndLeaveContainer mc="1fr 1fr" tc="1fr 1fr" dc="1fr 1fr" columnGap="10px">
                    <DaysAccruedBox>{viewModel.numberOfDaysAccrued}</DaysAccruedBox>
                    <ThemedIconButton
                        command={viewModel.displayChangeAllowanceCommand}
                        icon={<EditSVG />}
                        paletteColor={"primary"}
                        size="small"
                        styles={{ root: { color: "#fff", margin: "5px 0 4px 0" } }}
                    />
                </UserAllowanceAndLeaveContainer>
                <DaysLeftBox>{viewModel.numberOfDaysLeft}</DaysLeftBox>
            </UserPartContainer>
            <DatePartContainer>
                {viewModel.dateViewModels.map((vm) => {
                    return <DateView viewModel={vm} key={vm.KEY} />;
                })}
            </DatePartContainer>
        </RowContainer>
    );
});
