import { ModelBase, isEmptyOrWhitespace } from "@shoothill/core";
import { Validator } from "Application/Validation";
import { makeObservable, observable } from "mobx";

export class LostWithNoteModel extends ModelBase<LostWithNoteModel> {
    public noteDetail: string = "";
    public noteSubTypeId: string | null = null;

    constructor() {
        super();
        makeObservable(this, {
            // Observables
            noteDetail: observable,
            noteSubTypeId: observable,
        });
    }
}

export class LostWithNoteModelValidator extends Validator<LostWithNoteModel> {
    constructor() {
        super();

        this.ruleFor("noteSubTypeId").must({
            predicate: (noteSubTypeId, model) => !isEmptyOrWhitespace(noteSubTypeId),
            message: (value, model) => "Please select a reason",
        });
    }
}
