import { ModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";
import { nanoid } from "nanoid";

import { LeaveAllowance } from "Views/Leave/Shared/LeaveAllowance";
import { LeaveAllowanceUser } from "Views/Leave/Shared/LeaveAllowanceUser";

export class UserCalendarModel extends ModelBase<UserCalendarModel> {
    public KEY: string = nanoid();

    public requestUser: LeaveAllowanceUser | null = null;
    public leaveAllowance: LeaveAllowance | null = null;

    constructor() {
        super();

        makeObservable(this, {});
    }
}
