import { ModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

export class ProjectQuoteHistoryModel extends ModelBase<ProjectQuoteHistoryModel> {
    constructor() {
        super();

        makeObservable(this, {
            // Observables
        });
    }
}
