import { endOfDay, Endpoint, Http, startOfDay } from "Application";
import { AppUrls } from "AppUrls";
import { TimesheetViewModel } from "../Table/TimesheetViewModel";

class Request {
    public keyword: string | null = null;
    public startDate: Date | null | undefined = null;
    public endDate: Date | null | undefined = null;
    public projectId: string | null = null;
    public projectLeadId: string | null = null;
    public projectTaskGroupName: string | null = null;
}

export class GetFilteredBilledProjectTaskAssignmentsCSVEndpoint extends Endpoint<any, any> {
    private viewModel: TimesheetViewModel;

    constructor(viewModel: TimesheetViewModel) {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Resources.GetFilteredBilledProjectTaskAssignmentsCSV);
        this.responseIsBlob(true);
        this.viewModel = viewModel;
    }

    public async HandleRequestAsync(): Promise<any> {
        const request = new Request();

        request.keyword = this.viewModel.model.filterKeyword.length > 0 ? this.viewModel.model.filterKeyword : null;
        request.startDate = this.viewModel.model.filterStartDate ? startOfDay(this.viewModel.model.filterStartDate) : null;
        request.endDate = this.viewModel.model.filterEndDate ? endOfDay(this.viewModel.model.filterEndDate) : null;
        request.projectId = this.viewModel.model.filterProjectId;
        request.projectLeadId = this.viewModel.model.filterProjectLeadId;
        request.projectTaskGroupName = this.viewModel.model.filterProjectTaskGroupName;

        return await Promise.resolve(request);
    }

    public async HandleResponseAsync(response: any): Promise<any> {
        const contentDisposition = response.headers["content-disposition"];
        const filename = contentDisposition.split("filename=")[1];

        // Create a link element for the file and use the filename provided.
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(new Blob([response]));
        link.setAttribute("download", filename);

        document.body.appendChild(link);

        // Download.
        link.click();

        // Having clicked the link, delete the element otherwise it will
        // remain attached to the document.
        document.body.removeChild(link);

        return Promise.resolve();
    }
}
