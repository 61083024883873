import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { nanoid } from "nanoid";

export class StaffDetailsItemModel extends ModelBase<StaffDetailsItemModel> {
    public KEY: string = nanoid();

    public id = "";
    public reference = "";
    public title = "";
    public projectStatusId = "";
    public customerContactFullName = "";
    public hasWeeklyUpdate: boolean | null = null;
    public noOfProjectTasks: number | null = null;
    public noOfUnassignedOrPartialTasks: number | null = null;
    public noOfAssignmentsInProgress: number | null = null;

    public projectSupportId: string | null = null;
    public projectSupportFirstName: string | null = null;
    public projectSupportLastName: string | null = null;

    constructor() {
        super();

        makeObservable(this, {
            hasWeeklyUpdate: observable,
            projectStatusId: observable,
            noOfProjectTasks: observable,
            customerContactFullName: observable,
            noOfUnassignedOrPartialTasks: observable,
            noOfAssignmentsInProgress: observable,
            projectSupportId: observable,
            projectSupportFirstName: observable,
            projectSupportLastName: observable,
        });
    }
}
