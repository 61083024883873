import { ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

import { WeeklyUpdatesItemModel } from "./WeeklyUpdatesItemModel";

export class WeeklyUpdatesItemViewModel extends ViewModelBase<WeeklyUpdatesItemModel> {
    constructor(item: WeeklyUpdatesItemModel) {
        super(item);

        makeObservable(this, {});
    }

    public get id() {
        return this.model.projectUpdateTransactionId;
    }

    public get projectUpdateCommencementDate() {
        return this.model.projectUpdateCommencementDate;
    }

    public get numberOfUpdatesAvailable() {
        return this.model.numberOfUpdatesAvailable;
    }

    public get numberOfUpdatesProcessed() {
        return this.model.numberOfUpdatesProcessed;
    }

    public get numberOfUpdatesNotProcessed() {
        return this.model.numberOfUpdatesNotProcessed;
    }

    public get numberOfUpdatesFailed() {
        return this.model.numberOfUpdatesFailed;
    }

    public get projectUpdateTransactionStatus() {
        return this.model.projectUpdateTransactionStatus;
    }

    public get createdByUserName() {
        return this.model.createdByUserName;
    }

    public get createdDate() {
        return this.model.createdDate;
    }

    public get canDisplayNotProcessedIcon() {
        return this.model.numberOfUpdatesNotProcessed > 0;
    }

    public get canDisplayFailedIcon() {
        return this.model.numberOfUpdatesFailed > 0;
    }
}
