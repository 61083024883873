import _ from "lodash";
import { runInAction } from "mobx";

import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { ProjectWeeklyUpdateResponse } from "./Shared/ProjectWeeklyUpdateResponse";
import { ProjectWeeklyUpdateContainerViewModel } from "./ProjectWeeklyUpdateContainerViewModel";
import { ProjectWeeklyUpdateContainerModel } from "./ProjectWeeklyUpdateContainerModel";

class Request {
    id: string | null = null;
    projectId: string = "";
    updateDetail: string = "";
    internalDetail: string = "";
    updateDate: Date | null = null;
    sendUpdate: boolean = false;
}

export class POSTSaveProjectWeeklyUpdateEndpoint extends Endpoint<Request, ProjectWeeklyUpdateResponse> {
    private readonly viewModel: ProjectWeeklyUpdateContainerViewModel;

    constructor(viewModel: ProjectWeeklyUpdateContainerViewModel) {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Projects.SaveProjectWeeklyUpdate);
        this.viewModel = viewModel;
    }

    public async HandleRequestAsync(model: ProjectWeeklyUpdateContainerModel): Promise<any> {
        const request = new Request();

        request.id = model.updateForm.id;
        request.projectId = model.updateForm.projectId;
        request.updateDetail = model.updateForm.updateDetail;
        request.internalDetail = model.updateForm.internalDetail;
        request.updateDate = model.updateForm.updateDate;
        request.sendUpdate = model.updateForm.sendUpdate;

        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: ProjectWeeklyUpdateResponse): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            ProjectWeeklyUpdateResponse.remainingResponsePart(response, this.viewModel);
        });
    }
}
