import { ViewModelBase } from "@shoothill/core";
import { action, computed, makeObservable, observable } from "mobx";
import { container } from "tsyringe";

import { APIClient, ICommand, ICommandAsync, RelayCommand } from "Application";
import { LookupStore } from "Stores/Domain";
import { TaskModel } from "./TaskModel";
import { SummaryViewModel } from "./SummaryView/SummaryViewModel";
import { AssignViewModel } from "./AssignSubView/AssignViewModel";
import { BillingViewModel } from "./BillingSubView/BillingViewModel";
import { NotesViewModel } from "./NotesSubView/NotesViewModel";
import { GETProjectTaskAssignmentWithRelatedEndpoint } from "./GETProjectTaskAssignmentWithRelatedEndpoint";
import { POSTSaveProjectTaskAssignmentEndpoint } from "./POSTSaveProjectTaskAssignmentEndpoint";
import { POSTSaveProjectTaskAssignmentAsBilledEndpoint } from "./POSTSaveProjectTaskAssignmentAsBilledEndpoint";
import { POSTSaveProjectTaskAssignmentAsBilledAndNonBillableEndpoint } from "./POSTSaveProjectTaskAssignmentAsBilledAndNonBillableEndpoint";
import { PROJECTTASKASSIGNMENTSTATUSTYPE_ASSIGNEDTYPE, PROJECTTASKASSIGNMENTSTATUSTYPE_UPDATEDTYPE } from "Views/Shared/Constants";

export class TaskViewModel extends ViewModelBase<TaskModel> {
    private lookupStore = container.resolve(LookupStore);
    public apiClient = new APIClient();

    // Callback commands to the parent viewmodel.
    public readonly parentSubmitCommand: ICommand;
    public readonly parentCancelCommand: ICommand;

    //optional dashboard sync command
    public readonly dashboardSyncCommand: ICommandAsync | null;

    // Sub-viewmodels.
    public summaryViewModel = new SummaryViewModel();
    public readonly workspaceViewModels = observable<AssignViewModel | NotesViewModel | BillingViewModel>([]);
    public activeWorkspaceViewModel: AssignViewModel | NotesViewModel | BillingViewModel;

    constructor(projectTaskAssignmentId: string, model: TaskModel, submitCommand: ICommand, cancelCommand: ICommand, dashboardSyncCommand?: ICommandAsync) {
        super(model, false);

        // Commands to be called on the parent viewmodel.
        this.parentSubmitCommand = submitCommand;
        this.parentCancelCommand = cancelCommand;

        //optional dashboard sync command to be called after the task is  billed
        this.dashboardSyncCommand = dashboardSyncCommand ? dashboardSyncCommand : null;

        const assignViewModel = new AssignViewModel(model);
        const notesViewModel = new NotesViewModel(model);
        const billingViewModel = new BillingViewModel(model);

        // A collection of workspace viewmodels.
        // In this case, the workspace is a different view of the same model.

        this.workspaceViewModels.push(assignViewModel);
        this.workspaceViewModels.push(notesViewModel);
        this.workspaceViewModels.push(billingViewModel);

        // The default active viewmodel.
        this.activeWorkspaceViewModel = this.workspaceViewModels[this.workspaceViewModels.indexOf(billingViewModel)];

        // Properties on which to observe changes.
        makeObservable(this, {
            // Observables
            workspaceViewModels: observable,
            activeWorkspaceViewModel: observable,
            // Computeds
            canEditAssignment: computed,
            //Actions
            setActiveWorkspaceViewModel: action,
        });

        this.apiClient.sendAsync(new GETProjectTaskAssignmentWithRelatedEndpoint(projectTaskAssignmentId, this));
    }

    // #region Properties

    public get reference() {
        return this.model.reference;
    }

    public get title() {
        return this.model.title;
    }

    public get taskGroupName() {
        return this.model.projectTaskGroupName;
    }

    public get taskName() {
        return this.model.projectTaskName;
    }

    public get canEditAssignment() {
        const status = this.lookupStore.projectTaskAssignmentStatuses.find((pts) => pts.id === this.model.projectTaskAssignmentStatusId);

        return status?.type === PROJECTTASKASSIGNMENTSTATUSTYPE_ASSIGNEDTYPE || status?.type === PROJECTTASKASSIGNMENTSTATUSTYPE_UPDATEDTYPE;
    }

    public setActiveWorkspaceViewModel = (vm: AssignViewModel | NotesViewModel | BillingViewModel) => (this.activeWorkspaceViewModel = vm);

    // #endregion Properties

    // #region Commands

    public navigateToWorkspaceCommand = new RelayCommand((vm: AssignViewModel | NotesViewModel | BillingViewModel) => {
        this.activeWorkspaceViewModel = vm;
    });

    public submitCommand = new RelayCommand(
        async () => {
            const canSubmitForm = this.workspaceViewModels.filter((vm) => vm instanceof AssignViewModel || vm instanceof NotesViewModel).every((vm) => vm.isModelValid());

            if (canSubmitForm) {
                await this.apiClient.sendAsync(new POSTSaveProjectTaskAssignmentEndpoint(this), this.model);
                if (this.apiClient.IsRequestSuccessful) {
                    this.parentSubmitCommand.execute();
                }
            }
        },
        () => this.canEditAssignment && (this.activeWorkspaceViewModel instanceof AssignViewModel || this.activeWorkspaceViewModel instanceof NotesViewModel),
    );

    public submitBillCommand = new RelayCommand(
        () => {
            // Set this as the validation rules are different if billed.
            this.setValue("saveBilledEffortAsNCTType", false);

            const canSubmitForm = this.workspaceViewModels.filter((vm) => vm instanceof BillingViewModel).every((vm) => vm.isModelValid());

            if (canSubmitForm) {
                this.apiClient.sendAsync(new POSTSaveProjectTaskAssignmentAsBilledEndpoint(this), this.model).then(() => {
                    if (this.apiClient.IsRequestSuccessful) {
                        this.parentSubmitCommand.execute();
                    }
                });
            }

            // Now revert the validation rules having processed the command.
            this.setValue("saveBilledEffortAsNCTType", null);
        },
        () => this.activeWorkspaceViewModel instanceof BillingViewModel,
    );

    public submitBillAndNCTCommand = new RelayCommand(
        () => {
            // Set this as the validation rules are different if billed as NCT.
            this.setValue("saveBilledEffortAsNCTType", true);

            const canSubmitForm = this.workspaceViewModels.filter((vm) => vm instanceof BillingViewModel).every((vm) => vm.isModelValid());

            if (canSubmitForm) {
                this.apiClient.sendAsync(new POSTSaveProjectTaskAssignmentAsBilledAndNonBillableEndpoint(this), this.model).then(() => {
                    if (this.apiClient.IsRequestSuccessful) {
                        this.parentSubmitCommand.execute();
                    }
                });
            }

            // Now revert the validation rules having processed the command.
            this.setValue("saveBilledEffortAsNCTType", null);
        },
        () => this.activeWorkspaceViewModel instanceof BillingViewModel,
    );

    public reviewTaskCommand = new RelayCommand(
        () => {
            //change header color
            this.summaryViewModel.setValue("isReviewed", true);
            this.model.setValue("isReviewed", true);

            //change complete status to assign status
            const assignedStatus = this.lookupStore.projectTaskAssignmentStatuses.find((s) => s.type == "Assigned");
            this.summaryViewModel.setValue("projectTaskAssignmentStatusId", assignedStatus?.id);
            this.model.setValue("projectTaskAssignmentStatusId", assignedStatus?.id);

            //change to assign tab
            const assignVM = this.workspaceViewModels.find((vm) => vm instanceof AssignViewModel);
            this.setActiveWorkspaceViewModel(assignVM!);

            // Now revert the validation rules having processed the command.
            // this.setValue("saveBilledEffortAsNCTType", null);
        },
        () => this.activeWorkspaceViewModel instanceof NotesViewModel && this.model.reviewedNote != null && this.model.reviewedNote.length > 0,
    );

    public cancelCommand = new RelayCommand(() => {
        this.parentCancelCommand.execute();
    });

    public resetServerErrorCommand = new RelayCommand(() => {
        this.apiClient.reset();
    });

    // #endregion Commands

    // #region Supporting

    public isActiveWorkspace = (vm: AssignViewModel | NotesViewModel | BillingViewModel) => {
        return vm.KEY === this.activeWorkspaceViewModel.KEY;
    };

    // #endregion Supporting
}
