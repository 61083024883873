import { isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { computed, makeObservable, observable } from "mobx";
import { container } from "tsyringe";

import { APIClient, RelayCommand } from "Application";
import { AppUrls } from "AppUrls";
import { GETAllUsersLiteEndpoint } from "./GETAllUsersLiteEndpoint";
import { UsersModel } from "./UsersModel";
import { UserItemLiteViewModel } from "./UserItemLiteViewModel";
import { LookupStore } from "../../../../Stores/Domain";

export class UsersViewModel extends ViewModelBase<UsersModel> {
    public apiClient = new APIClient();
    public users = observable<UserItemLiteViewModel>([]);
    private lookupStore = container.resolve(LookupStore);

    constructor() {
        super(new UsersModel());

        makeObservable(this, {
            users: observable,

            // Computeds
            canFilterUsers: computed,
            filteredUser: computed,
            canSortUsers: computed,
            filteredAndSortedUsers: computed,
        });

        const _ = this.apiClient.sendAsync(new GETAllUsersLiteEndpoint(this));
    }

    // #region Filtering

    public updateFilterKeywordCommand = new RelayCommand((keyword: string) => {
        this.model.filterKeyword = keyword;
    });

    public updateFilterEnquiryStatusCommand = new RelayCommand((statusId: string) => {
        this.model.filterStatusId = statusId;
    });

    public get enquiryStatuses() {
        return [{ key: "", text: "All statuses" }, ...this.lookupStore.getEnquiryStatuses()];
    }

    public updateFilterFromDateComand = new RelayCommand((date: Date | undefined) => {
        this.model.filterFromDate = date;
    });

    public updateFilterToDateCommand = new RelayCommand((date: Date | undefined) => {
        this.model.filterToDate = date;
    });

    public get canFilterUsers(): boolean {
        return !isEmptyOrWhitespace(this.model.filterKeyword);
    }

    public get filteredUser(): UserItemLiteViewModel[] {
        return this.canFilterUsers ? this.users.filter((vm) => vm.filterPredicate(this.model.filterKeyword)) : this.users;
    }

    // #endregion Filtering

    // #region Sorting

    public updateSortCommand = new RelayCommand((key: string, sortDescending: boolean) => {
        this.model.sortKey = key;
        this.model.sortDescending = sortDescending;
    });

    public get canSortUsers(): boolean {
        return !isEmptyOrWhitespace(this.model.sortKey);
    }

    public get filteredAndSortedUsers(): UserItemLiteViewModel[] {
        return this.canSortUsers
            ? this.filteredUser.slice().sort((lhs, rhs) => {
                  return (this.model.sortDescending ? lhs[this.model.sortKey] < rhs[this.model.sortKey] : lhs[this.model.sortKey] > rhs[this.model.sortKey]) ? 1 : -1;
              })
            : this.filteredUser;
    }

    // #endregion Sorting

    public navigateToEditForm = new RelayCommand((item: UserItemLiteViewModel) => {
        this.history.push(AppUrls.Client.Users.Edit.replace(":id", item.model.id));
    });

    public navigateToNewForm = new RelayCommand(() => {
        this.history.push(AppUrls.Client.Users.New);
    });
}
