//libraries
import React from "react";
import styled from "@emotion/styled";
import { Box, ThemedCheckBox, ThemedEditText, ThemedText, theme } from "Application";
import { observer } from "mobx-react-lite";

//app
import { InvoiceForecastMonthCapacityViewModel } from "./InvoiceForecastMonthCapacityViewModel";

const TableRow = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 2fr 2fr 1.5fr;

    align-items: center;

    & > * {
        padding: 10px;
    }

    & > *:not(:first-child) {
        border-left: 1px solid ${theme.palette.field.light};
    }

    border-left: 1px solid ${theme.palette.field.light};
    border-right: 1px solid ${theme.palette.field.light};
    border-bottom: 1px solid ${theme.palette.field.light};

    :first-of-type {
        border-top: 1px solid ${theme.palette.field.light};
    }
`;

const ThemeEditTextWithoutLabel = styled(ThemedEditText)`
    .ms-Stack {
        display: none;
    }
`;

interface IProps {
    viewModel: InvoiceForecastMonthCapacityViewModel;
}

export const InvoiceForecastMonthCapacityView: React.FC<IProps> = observer((props: IProps) => {
    const { viewModel } = props;

    return (
        <TableRow>
            <Box height={"100%"} flexBox alignItems={"center"}>
                <ThemedText>{viewModel.month}</ThemedText>
            </Box>
            <Box height={"100%"} flexBox alignItems={"center"}>
                <ThemedText>{viewModel.hours}</ThemedText>
            </Box>
            <Box height={"100%"} flexBox alignItems={"center"}>
                <ThemedText>{viewModel.value}</ThemedText>
            </Box>
            <ThemedEditText
                displayName=""
                command={viewModel.updateOverrideHoursCommand}
                placeholder={""}
                value={() => viewModel.overrideHours}
                validationMessage={() => viewModel.invoiceForecastMonthCapacityOverride.getError("hours")}
            />
            <ThemedEditText
                displayName=""
                command={viewModel.updateOverrideValueCommand}
                placeholder={""}
                value={() => viewModel.overrideValue}
                validationMessage={() => viewModel.invoiceForecastMonthCapacityOverride.getError("value")}
            />
            <Box height={"100%"} flexBox alignItems={"center"} justifyContent={"center"}>
                <ThemedCheckBox command={viewModel.updateUseOverrideValuesCommand} displayName="" value={() => viewModel.useOverrideValues} paletteColor="secondary" />
            </Box>
        </TableRow>
    );
});
