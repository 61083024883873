import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import React from "react";

import { AutoGrid, ThemedText, ThemedTransparentIconButton } from "Application";
import { UpIcon } from "Assets/Icons/UpIcon";
import { DownIcon } from "Assets/Icons/DownIcon";
import { LeaveCalendarViewModel } from "../../LeaveCalendarViewModel";

interface IProps {
    viewModel: LeaveCalendarViewModel;
}

const NavigationContainer = styled(AutoGrid)`
    align-items: center;
    column-gap: 4px !important;
    margin: 0px 0px 2px 0px;
    row-gap: 0px !important;
`;

const NavigationCurrentMonth = styled(ThemedText)`
    font-weight: bold;
`;

const NavigationButton = styled(ThemedTransparentIconButton)``;

export const NavigationView: React.FC<IProps> = observer((props) => {
    const viewModel = props.viewModel;

    return (
        <NavigationContainer mc="110px 30px 30px" tc="110px 30px 30px" dc="110px 30px 30px">
            <NavigationCurrentMonth>{viewModel.currentMonthAndYear}</NavigationCurrentMonth>
            <NavigationButton command={props.viewModel.previousMonthCommand} icon={<UpIcon />} />
            <NavigationButton command={props.viewModel.nextMonthCommand} icon={<DownIcon />} />
        </NavigationContainer>
    );
});
