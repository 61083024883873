import { DetailsRow, IColumn, Link, Modal } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Box, formatDate, formatDecimalNumber, Nullcommand, ThemedText } from "Application";
import { UnassignedTasksViewModel } from "./UnassignedTasksViewModel";
import { UnassignedTasksItemViewModel } from "./UnassignedTasksItemViewModel";
import { ThemedDataTableLayer3 } from "Styles/ThemedPrimitives/Style1/ThemedDataTable";
import { isNullOrUndefined } from "@shoothill/core";
import { DeleteIcon } from "Assets";

import { DeleteUnassignedTaskModalView } from "./ModalViews/DeleteUnnasignedTaskItemModallView";
import { CloseButton } from "Views/Admin/Services/ServiceGroup/ServiceGroupView.styles";
import styled from "@emotion/styled";
import { APICommonComponents } from "Application/Helpers/APICommonComponents";

interface IProps {
    viewModel: UnassignedTasksViewModel;
}

interface IRemoveButtonProps {
    isDisabled: boolean;
}

const RemoveButton = styled.button<IRemoveButtonProps>`
    padding: 0px;
    width: 100%;
    height: 100%;
    border-width: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    & > div {
        /* &:hover {
            opacity: ${(props) => (props.disabled ? 0.5 : 1)};
        } */
    }

    &:hover {
        opacity: ${(props) => (props.disabled ? 1 : 0.7)};
        cursor: ${(props) => (props.disabled ? "cursor" : "pointer")};
    }
`;

export const UnassignedTasksView = observer((props: IProps) => {
    const viewModel = props.viewModel;

    useEffect(() => {
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter((currCol) => viewModel.getValue("sortKey") === currCol.key)[0];

        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSorted = true;
                currColumn.isSortedDescending = viewModel.getValue("sortDescending");
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = false;
            }
        });

        setColumns(newColumns);
    }, [viewModel.getValue("sortKey"), viewModel.getValue("sortDescending")]);

    const getRowKey = (item: UnassignedTasksItemViewModel, index?: number): string => {
        return item.KEY;
    };

    const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const currColumn: IColumn = columns.filter((currCol) => column.key === currCol.key)[0];
        const newSortKey = currColumn.key;
        const newSortDescending = currColumn.key === viewModel.getValue("sortKey") ? !currColumn.isSortedDescending : false;

        viewModel.updateSortCommand.execute(newSortKey, newSortDescending);
    };

    const onRenderRow = (props: any) => {
        const viewModel = props.item;
        const projectTaskStatusForegroundColor = `${viewModel.projectTaskForegroundColor} !important`;

        return <DetailsRow {...props} onClick={() => onRowClick(props.item)} styles={{ root: { background: projectTaskStatusForegroundColor } }} />;
    };

    const onRowClick = (item: UnassignedTasksItemViewModel) => {};

    const onRenderDate = (value?: Date) => {
        return <div>{!isNullOrUndefined(value) ? formatDate(value!) : ""}</div>;
    };

    const onRenderNumber = (value?: number) => {
        return <div>{!isNullOrUndefined(value) ? formatDecimalNumber(value!) : ""}</div>;
    };

    /**
     * Renders the remove icon and provides callout support.
     *
     * The icon is wrapped in a Link with a stop propagation call so
     * clicking on the icon prevents the table row click.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The index of the table row.
     * @param column The table column.
     *
     * @returns React rating element.
     */
    const onRenderRemoveProjectTask = (item: UnassignedTasksItemViewModel, index?: number, column?: IColumn) => {
        return (
            <RemoveButton
                onClick={() => viewModel.requestItemRemoval(item.model.id)}
                isDisabled={viewModel.isRemoveButtonDisabled(item)}
                disabled={viewModel.isRemoveButtonDisabled(item)}
            >
                <CloseButton id={`deleteIcon-${index}`} disabled={viewModel.isRemoveButtonDisabled(item)} />
            </RemoveButton>
        );
    };

    const defaultTableColumns: IColumn[] = [
        {
            key: "projectTaskName",
            fieldName: "projectTaskName",
            name: "TASK",
            minWidth: 281,
            maxWidth: 502,
            isResizable: true,
            isSorted: true,
            onColumnClick: onColumnClick,
        },
        {
            key: "projectTaskOrdinal",
            fieldName: "projectTaskOrdinal",
            name: "Quote order",
            minWidth: 80,
            maxWidth: 80,
            isResizable: false,
            isSorted: true,
            onColumnClick: onColumnClick,
        },
        {
            key: "anticipatedEndDate",
            fieldName: "anticipatedEndDate",
            name: "ANT. END DATE",
            minWidth: 80,
            maxWidth: 80,
            isResizable: true,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: (item: UnassignedTasksItemViewModel) => (item.anticipatedEndDate ? onRenderDate(item.anticipatedEndDate) : <></>),
        },
        {
            key: "manHours",
            fieldName: "manHours",
            name: "TOTAL HRS",
            minWidth: 80,
            maxWidth: 80,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: (item: UnassignedTasksItemViewModel) => (item.manHours ? onRenderNumber(item.manHours) : <></>),
        },
        {
            key: "remainingProjectTaskBillableEffort",
            fieldName: "remainingProjectTaskBillableEffort",
            name: "HRS REM",
            minWidth: 80,
            maxWidth: 80,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: (item: UnassignedTasksItemViewModel) => (item.remainingProjectTaskBillableEffort ? onRenderNumber(item.remainingProjectTaskBillableEffort) : <></>),
        },
        {
            className: "removeProjectTaskCell",
            fieldName: "",
            key: "downloadicon",
            name: "",
            maxWidth: 30,
            minWidth: 30,
            onRender: onRenderRemoveProjectTask,
            styles: {
                cellTitle: {
                    border: "none !important",
                },
            },
        },
    ];

    const [columns, setColumns] = useState<IColumn[]>(defaultTableColumns);

    const renderDeleteModalContent = () => {
        return (
            <Modal isOpen={viewModel.isDeleteModalOpen} onDismiss={() => viewModel.cancelItemRemovalRequestCommand.execute()}>
                <DeleteUnassignedTaskModalView parentViewModel={viewModel} />
            </Modal>
        );
    };

    // #endregion Code Behind

    return (
        <Box>
            {/* Title/Fiter Bar */}
            <ThemedText fontStyle="expandable" mb={"8px"}>
                Unassigned tasks
            </ThemedText>
            {/* Table */}
            <ThemedDataTableLayer3
                styles={{ root: { "&&&&": { marginTop: 0 } } }}
                onRenderRow={onRenderRow}
                getKey={getRowKey}
                items={viewModel.filteredAndSortedTasks}
                columns={columns}
            />
            <ThemedText fontStyle="expandable" mt={"8px"}>
                Total remaining hours: {formatDecimalNumber(viewModel.filteredAndSortedTasksRemainingHours)}
            </ThemedText>
            {/* modal */}
            {renderDeleteModalContent()}
            {/* Busy Overlay */}
            {viewModel && APICommonComponents(viewModel.apiClient).renderBusy()}
            {/* Error Overlay */}
            {viewModel && APICommonComponents(viewModel.apiClient).renderError()}
        </Box>
    );
});
