import { ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

import { ICommand, RelayCommand } from "Application";
import { DeleteLeaveRequestModel } from "./DeleteLeaveRequestModel";

export class DeleteLeaveRequestViewModel extends ViewModelBase<DeleteLeaveRequestModel> {
    // Callback commands to the parent viewmodel.
    private parentCancelCommand: ICommand;
    private parentSubmitCommand: ICommand;

    constructor(model: DeleteLeaveRequestModel, submitCommand: ICommand, cancelCommand: ICommand) {
        super(model, false);

        this.parentCancelCommand = cancelCommand;
        this.parentSubmitCommand = submitCommand;

        makeObservable(this, {});
    }

    //#region Properties
    //#endregion Properties

    //#region Commands

    public submitCommand = new RelayCommand(
        () => {
            if (this.isModelValid()) {
                this.parentSubmitCommand.execute();
            }
        },
        () => {
            return this.parentSubmitCommand.canExecute();
        },
    );

    public cancelCommand = new RelayCommand(
        () => this.parentCancelCommand.execute(),
        () => this.parentCancelCommand.canExecute(),
    );

    //#endregion Commands
}
