import { ModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

export class TermsAndConditionItemModel extends ModelBase<TermsAndConditionItemModel> {
    public id: string = "";
    public name: string = "";
    public body: string = "";

    constructor() {
        super();
        makeObservable(this, {});
    }
}
