import { observer } from "mobx-react-lite";
import { SourceOfEnquiryViewModel } from "./SourceOfEnquiryViewModel";
import { Box, Show, ThemedComboBox, ThemedEditText, ThemedText } from "Application";
import React from "react";

interface IProps {
    viewModel: SourceOfEnquiryViewModel;
    isReadOnly?: boolean;
}

export const SourceOfEnquiryView: React.FC<IProps> = observer((props) => {
    // #region Code Behind

    const viewModel = props.viewModel;
    const isReadOnly = props.isReadOnly ?? false;

    return (
        <>
            <>
                {!isReadOnly && (
                    <>
                        <Box display="flex" alignItems="center" width={viewModel.canDisplayDetail ? "610px" : "305px"}>
                            <Box display="flex" alignItems="center" marginRight={viewModel.canDisplayDetail ? "30px" : "0px"} width={viewModel.canDisplayDetail ? "50%" : "100%"}>
                                <ThemedComboBox
                                    displayName="Source of enquiry*"
                                    options={viewModel.sourceOfEnquiryTypeOptions}
                                    command={viewModel.updateSourceOfEnquiryTypeCommand}
                                    placeholder={"Please select a type"}
                                    validationMessage={() => viewModel.getError("sourceOfEnquiryTypeId")}
                                    value={() => viewModel.getValue("sourceOfEnquiryTypeId")}
                                    styles={{ container: { width: "100%" } }}
                                />
                            </Box>

                            <Show if={viewModel.canDisplayDetail}>
                                <Box width={"50%"}>
                                    <ThemedEditText
                                        command={viewModel.updateDetailCommand}
                                        displayName="Detail*"
                                        placeholder="Add a detail"
                                        validationMessage={() => viewModel.getError("detail")}
                                        value={() => viewModel.getValue("detail") ?? ""}
                                    />
                                </Box>
                            </Show>
                        </Box>
                    </>
                )}
            </>
            <>
                {isReadOnly && (
                    <>
                        <Box display={"flex"} alignItems={"center"}>
                            <Box display={"flex"} alignItems={"center"} marginRight={"10px"}>
                                <ThemedText fontStyle={"h3"} marginRight={"10px"}>
                                    SOURCE OF ENQUIRY:
                                </ThemedText>
                                <ThemedText fontStyle="p">{viewModel.sourceOfEnquiryDisplayName}</ThemedText>
                            </Box>
                            {viewModel.canDisplayDetail && (
                                <Box>
                                    <ThemedText fontStyle={"h3"} marginRight={"10px"}></ThemedText>
                                    <ThemedText fontStyle="p">{`(${viewModel.getValue("detail")})`}</ThemedText>
                                </Box>
                            )}
                        </Box>
                    </>
                )}
            </>
        </>
    );
});
