//libraries
import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { AutoGrid, ThemedButton, ThemedText } from "Application";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { ThemedError } from "Styles/ThemedPrimitives/Style1/ThemedError";
import { ThemedLoader } from "Styles/ThemedPrimitives/Style1/ThemedLoader";
import { FormDottedSeparator, PanelTab, PanelTabContainer } from "Views/Shared/SharedComponents";

//app
import { ProgrammingWeekTaskDetailsView } from "./ProgrammingWeekTaskDetails/ProgrammingWeekTaskDetailsView";
import { ProgrammingWeekTaskDetailsViewModel } from "./ProgrammingWeekTaskDetails/ProgrammingWeekTaskDetailsViewModel";
import { ProgrammingWeekTaskSummaryView } from "./ProgrammingWeekTaskSummaryView/ProgrammingWeekTaskSummaryView";
import { ProgrammingWeekTaskUserNotesView } from "./ProgrammingWeekTaskUserNotes/ProgrammingWeekTaskUserNotesView";
import { ProgrammingWeekTaskUserNotesViewModel } from "./ProgrammingWeekTaskUserNotes/ProgrammingWeekTaskUserNotesViewModel";
import { ProgrammingWeekTaskViewModel } from "./ProgrammingWeekTaskViewModel";

const Header = styled(Box)`
    background-color: #ff4b22;
    color: white;
    min-height: 70px;
    padding: 10px 30px;
`;
const Body = styled(Box)`
    background-color: #fcfcfc;
    display: flex;
    flex: 1;
    flex-direction: column;
`;
const Footer = styled(Box)`
    align-items: center;
    background-color: #fcfcfc;
    display: flex;
    min-height: 70px;
    padding: 0 30px;
`;
export interface IProgrammingWeekTaskProps {
    viewModel: ProgrammingWeekTaskViewModel;
}

export const ProgrammingWeekTaskView: React.FC<IProgrammingWeekTaskProps> = observer((props: IProgrammingWeekTaskProps) => {
    const { viewModel } = props;

    const renderTabs = () => {
        return (
            <PanelTabContainer>
                {viewModel &&
                    viewModel.workspaceViewModels.map((workspace) => {
                        return (
                            <PanelTab isActive={viewModel.isActiveWorkspace(workspace)} key={workspace.KEY} onClick={() => viewModel.navigateToWorkspaceCommand.execute(workspace)}>
                                <ThemedText>{workspace.displayName}</ThemedText>
                            </PanelTab>
                        );
                    })}
            </PanelTabContainer>
        );
    };

    const renderActiveWorkspace = () => {
        switch (true) {
            case viewModel.activeWorkspaceViewModel instanceof ProgrammingWeekTaskDetailsViewModel:
                return <ProgrammingWeekTaskDetailsView viewModel={viewModel.activeWorkspaceViewModel as ProgrammingWeekTaskDetailsViewModel} />;

            case viewModel.activeWorkspaceViewModel instanceof ProgrammingWeekTaskUserNotesViewModel:
                return <ProgrammingWeekTaskUserNotesView viewModel={viewModel.activeWorkspaceViewModel as ProgrammingWeekTaskUserNotesViewModel} />;
        }
    };

    const renderBusy = () => <ThemedLoader isOpen={viewModel.apiClient.IsBusy} />;

    const renderError = () => {
        const isOpen = viewModel.apiClient.IsSubmitted && !viewModel.apiClient.IsBusy && viewModel.apiClient.HaveValidationMessage;

        return <ThemedError command={viewModel.resetServerErrorCommand} isOpen={isOpen} errorMessage={viewModel.apiClient.ValidationMessage} />;
    };

    return (
        <Box display="flex" flexDirection="column" flex="1" height={"100vh"} onClick={(e) => e.stopPropagation()}>
            {viewModel && <ProgrammingWeekTaskSummaryView viewModel={viewModel.programmingWeekTaskViewModel} />}
            <Body style={{ height: "calc(100% - 200px)" }}>
                {/* Navigation Tabs */}
                {viewModel && renderTabs()}

                {/* Active Workspace */}
                {viewModel && renderActiveWorkspace()}

                {/* Busy Overlay */}
                {viewModel && renderBusy()}

                {/* Error Overlay */}
                {viewModel && renderError()}
            </Body>
            <FormDottedSeparator styles={{ root: { height: "1px" } }} />
            <Footer>
                <AutoGrid columnGap={"15px"} dc={"105px 105px 105px"}>
                    {viewModel && !viewModel.isProgrammingWeekTaskComplete && viewModel.isNotesTabActive && (
                        <ThemedButton styles={{ root: { padding: "0 5px" } }} paletteColor={"primary"} displayName="Complete" command={viewModel.submitCompletionCommand} />
                    )}
                    {viewModel && !viewModel.isProgrammingWeekTaskComplete && viewModel.isNotesTabActive && (
                        <ThemedButton styles={{ root: { padding: "0 5px" } }} paletteColor={"quarternary"} displayName="Update" command={viewModel.submitUpdateCommand} />
                    )}

                    {viewModel && !viewModel.isProgrammingWeekTaskComplete && viewModel.isNotesTabActive && (
                        <ThemedButton styles={{ root: { padding: "0 5px" } }} paletteColor={"default"} displayName="Cancel" command={viewModel.cancelCommand} />
                    )}
                    {viewModel && (viewModel.isProgrammingWeekTaskComplete || viewModel.isHistoryTabActive) && (
                        <ThemedButton styles={{ root: { padding: "0 5px" } }} paletteColor={"default"} displayName="OK" command={viewModel.cancelCommand} />
                    )}
                </AutoGrid>
            </Footer>
        </Box>
    );
});
