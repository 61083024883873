import React from "react";
import { Route, Route as ReactRoute, Routes, Navigate } from "react-router-dom";
import { container } from "tsyringe";

import { CommonInjects } from "Application/CommonInjects";
import { AppUrls } from "./AppUrls";
import { DebugRouter } from "Components/DebugRouter";
import { PrivateRoute } from "Components/PrivateRoute";
import { AccountStore } from "Stores/Domain/AccountStores";
import { ThemedLoader } from "Styles/ThemedPrimitives/Style1/ThemedLoader";

import { Error } from "Views/Error/Error";
import LoginHomeView from "Views/Login/LoginHomeView";
import { DefaultLayout, DefaultLayoutNoFooter, LayoutNoHeaderOrFooter } from "Views/Layouts";
import { BASEAccountLayout } from "Views/Layouts/AccountLayoutStyes";
import { ResourceView } from "Views/Resource/ResourceView";
import { NewBusinessView } from "Views/NewBusiness/NewBusinessView";
import { EnquiryView } from "Views/NewBusiness/Enquiries/Form/EnquiryView";
import { CustomerDetail } from "Views/Customers/Detail/CustomerDetail";
import { PreviewView } from "Views/Generated/PreviewPage";
import { SessionExpiredView } from "Views/SessionExpired/SessionExpiredView";
import { PrivacyPolicyView } from "Views/Legal/PrivacyPolicy/PrivacyPolicyView";
import { TermsAndConditions } from "Views/Legal/Terms/TermsAndConditions";
import { QuoteContainerView } from "Views/NewBusiness/Quotes/Container/QuoteContainerView";
import { ProjectsView } from "Views/Projects/Table/ProjectsView";
import { ProjectView } from "Views/Projects/ProjectView";
import { UserView } from "Views/Admin/Users/Form/UserView";
import { LetterView } from "Views/Admin/LetterTemplates/Form/LetterView";
import { AdminView } from "Views/Admin/AdminView";
import { StaffDetailsView } from "Views/Resource/Staff/Details/StaffDetailsView";
import { DashboardView } from "Views/Dashboard/DashboardView";
import { TermsAndConditionView } from "Views/Admin/TermsAndCondition/Form/TermsAndConditionView";
import SuppliersView from "Views/Suppliers/Table/SuppliersView";
import SupplierView from "Views/Suppliers/Form/SupplierView";
import { SupplierDetail } from "Views/Suppliers/Detail/SupplierDetail";
import { PlanningApplicationView } from "Views/PlanningApplication/PlanningApplicationView";

const CustomerView = React.lazy(() => import("Views/Customers/Form/CustomerView"));
const CustomersView = React.lazy(() => import("Views/Customers/Table/CustomersView"));
const PageBuilderView = React.lazy(() => import("Views/PageBuilder/PageBuilderView"));

export const AppRoutes: React.FC = (props) => {
    const accountStore = container.resolve(AccountStore);

    // #region User Roles

    const isAdmin = () => accountStore.isInRole("admin");

    const isBusinessSupport = () => accountStore.isInRole("businesssupport");

    const isSeniorAssociate = () => accountStore.isInRole("seniorassociate");

    const isArchitect = () => accountStore.isInRole("architect");

    // #endregion User Roles

    // #region Display Permissions

    const showDashboard = () => isAdmin() || isSeniorAssociate() || isBusinessSupport() || isArchitect();

    const showSuppliers = () => isAdmin() || isSeniorAssociate() || isBusinessSupport() || isArchitect();

    const showClients = () => isAdmin() || isSeniorAssociate() || isBusinessSupport() || isArchitect();

    const showNewBusiness = () => isAdmin() || isSeniorAssociate() || isBusinessSupport();

    const showProjects = () => isAdmin() || isSeniorAssociate() || isBusinessSupport() || isArchitect();

    const showStaff = () => isAdmin() || isSeniorAssociate() || isBusinessSupport();

    const showResource = () => isAdmin() || isSeniorAssociate() || isBusinessSupport();

    const showAdmin = () => isAdmin() || isSeniorAssociate() || isBusinessSupport();

    const showPlanning = () => isAdmin() || isSeniorAssociate() || isBusinessSupport() || isArchitect();

    // #endregion Display Permissions

    return (
        <>
            <DebugRouter>
                <Routes>
                    <Route path="/" element={<Navigate to={AppUrls.Client.Account.Login} replace />} />

                    <Route path={"/"} element={<DefaultLayout />}>
                        {/* <Route path={AppUrls.Client.DashboardRoot} element={<HomeView />} /> */}
                        {/* Dashboard Tabbed Page */}
                        <Route element={<PrivateRoute isAllowed={showDashboard} />}>
                            <Route path={AppUrls.Client.DashboardRoot + "/*"} element={<DashboardView />} />
                        </Route>

                        {/* Customer Pages */}
                        <Route element={<PrivateRoute isAllowed={showClients} />}>
                            <Route
                                path={AppUrls.Client.Customers.Table}
                                element={
                                    <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                        <CustomersView />
                                    </React.Suspense>
                                }
                            />
                            <Route
                                path={AppUrls.Client.Customers.Details}
                                element={
                                    <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                        <CustomerDetail />
                                    </React.Suspense>
                                }
                            />
                            <Route
                                path={AppUrls.Client.Customers.Edit}
                                element={
                                    <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                        <CustomerView />
                                    </React.Suspense>
                                }
                            />
                            <Route
                                path={AppUrls.Client.Customers.New}
                                element={
                                    <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                        <CustomerView />
                                    </React.Suspense>
                                }
                            />
                        </Route>

                        {/* Suppliers Pages */}
                        <Route element={<PrivateRoute isAllowed={showSuppliers} />}>
                            <Route
                                path={AppUrls.Client.Suppliers.Table}
                                element={
                                    <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                        <SuppliersView />
                                    </React.Suspense>
                                }
                            />
                            <Route
                                path={AppUrls.Client.Suppliers.Details}
                                element={
                                    <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                        <SupplierDetail />
                                    </React.Suspense>
                                }
                            />
                            <Route
                                path={AppUrls.Client.Suppliers.Edit}
                                element={
                                    <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                        <SupplierView />
                                    </React.Suspense>
                                }
                            />
                            <Route
                                path={AppUrls.Client.Suppliers.New}
                                element={
                                    <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                        <SupplierView />
                                    </React.Suspense>
                                }
                            />
                        </Route>

                        {/* New Business Tabbed Page */}
                        <Route element={<PrivateRoute isAllowed={showNewBusiness} />}>
                            <Route path={AppUrls.Client.NewBusinessRoot + "/*"} element={<NewBusinessView />} />

                            {/* Enquiry Pages */}
                            <Route path={AppUrls.Client.Enquiries.Edit} element={<EnquiryView />} />
                            <Route path={AppUrls.Client.Enquiries.New} element={<EnquiryView />} />
                            <Route path={AppUrls.Client.Enquiries.NewCustomer} element={<EnquiryView />} />

                            {/* Quote Pages */}
                            <Route path={AppUrls.Client.Quotes.Edit} element={<QuoteContainerView />} />
                            <Route path={AppUrls.Client.Quotes.New} element={<QuoteContainerView />} />
                            <Route path={AppUrls.Client.Quotes.NewCustomer} element={<QuoteContainerView />} />

                            {/* Project Tabbed Page */}
                            <Route path={AppUrls.Client.ProjectRoot + "/*"} element={<ProjectView />} />
                        </Route>

                        {/* Project Pages */}

                        <Route element={<PrivateRoute isAllowed={showProjects} />}>
                            <Route path={AppUrls.Client.Projects.Table} element={<ProjectsView />} />
                        </Route>

                        {/* Planning Pages */}

                        <Route element={<PrivateRoute isAllowed={showPlanning} />}>
                            <Route path={AppUrls.Client.PlanningApplication.Table.Root} element={<PlanningApplicationView />} />
                        </Route>

                        {/* Resource Tabbed Page */}
                        <Route element={<PrivateRoute isAllowed={showResource} />}>
                            <Route path={AppUrls.Client.ResourcesRoot + "/*"} element={<ResourceView />} />
                        </Route>

                        {/* Staff Pages */}
                        <Route element={<PrivateRoute isAllowed={showStaff} />}>
                            <Route path={AppUrls.Client.Staff.Details} element={<StaffDetailsView />} />
                        </Route>

                        <Route element={<PrivateRoute isAllowed={showAdmin} />}>
                            {/* Admin Tabbed Page */}
                            <Route path={AppUrls.Client.AdminRoot + "/*"} element={<AdminView />} />

                            {/* Letter Template Pages */}
                            <Route path={AppUrls.Client.LetterTemplates.Edit} element={<LetterView />} />
                            <Route path={AppUrls.Client.LetterTemplates.New} element={<LetterView />} />

                            {/* Terms And Conditions Pages */}
                            <Route path={AppUrls.Client.TermConditionTemplates.Edit} element={<TermsAndConditionView />} />
                            <Route path={AppUrls.Client.TermConditionTemplates.New} element={<TermsAndConditionView />} />

                            {/* User Pages */}
                            <Route path={AppUrls.Client.Users.Edit} element={<UserView />} />
                            <Route path={AppUrls.Client.Users.New} element={<UserView />} />

                            <Route
                                path={AppUrls.Client.PrivacyPolicy}
                                element={
                                    <React.Suspense fallback={<>...</>}>
                                        <PrivacyPolicyView />
                                    </React.Suspense>
                                }
                            />
                            <Route
                                path={AppUrls.Client.Terms}
                                element={
                                    <React.Suspense fallback={<>...</>}>
                                        <TermsAndConditions />
                                    </React.Suspense>
                                }
                            />
                        </Route>

                        <Route path={AppUrls.Client.Account.SessionExpired} element={<SessionExpiredView />} />
                        <Route path={AppUrls.Client.Error} element={<Error supportEmail={"support@shoothill.com"} />} />
                    </Route>

                    <Route element={<BASEAccountLayout />}>
                        <Route path={"/account/*"} element={<LoginHomeView />} />
                        {/* <Route
                            path={AppUrls.Client.Account.Logout}
                            element={
                                <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                    <LogoutView />
                                </React.Suspense>
                            }
                        /> */}
                    </Route>
                    <Route element={<DefaultLayoutNoFooter />}>
                        <Route
                            path={"/pagebuilder"}
                            element={
                                <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                    <PageBuilderView />
                                </React.Suspense>
                            }
                        />
                    </Route>
                    <Route element={<LayoutNoHeaderOrFooter />}>
                        <Route path="preview/*" element={<PreviewView />} />
                    </Route>
                    {/*404 Route*/}
                    <ReactRoute
                        path={"*"}
                        element={
                            <main style={{ padding: "1rem" }}>
                                <p>There's nothing here!</p>
                            </main>
                        }
                    />
                </Routes>
                {/* <AdminRoutes /> */}
            </DebugRouter>

            <CommonInjects />
        </>
    );
};
