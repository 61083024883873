import { ModelBase } from "@shoothill/core";
import { ServiceGroup, Service } from "Application/Models/Domain";
import { computed, makeObservable, observable } from "mobx";
import { nanoid } from "nanoid";

import { TaskGroupModel } from "./ServiceSubViews/TaskGroupModel";

export class ServicesModel extends ModelBase<ServicesModel> {
    // INTERNAL - A unique key to identify this model.
    public KEY: string = nanoid();

    // INTERNAL - The default rate to apply to new tasks.
    public defaultHourlyRate: number | null = 90.0;

    // INTERNAL - States if the default rate should be applied to new tasks.
    public applyDefaultHourlyRate = true;

    public taskGroups = observable<TaskGroupModel>([]);

    public serviceGroups = observable<ServiceGroup>([]);

    public services = observable<Service>([]);

    constructor() {
        super();
        makeObservable(this, {
            // Observables
            defaultHourlyRate: observable,
            applyDefaultHourlyRate: observable,
            taskGroups: observable,

            // Computeds
            predictedCostExclVAT: computed,
            predictedVAT: computed,
            predictedCostInclVAT: computed,
        });
    }

    // #region Business Logic

    public get totalManHours() {
        return this.taskGroups.reduce((totalManHours, taskGroup) => totalManHours + taskGroup.totalManHours, 0);
    }

    public get percentageVAT(): number {
        return 20;
    }

    public get predictedCostExclVAT(): number {
        return this.taskGroups
            .map((t: TaskGroupModel) => {
                return t.predictedCostExclVAT;
            })
            .reduce((runningTotal, predictedCost) => {
                return runningTotal + predictedCost;
            }, 0);
    }

    public get predictedVAT(): number {
        return (this.predictedCostExclVAT * this.percentageVAT) / 100;
    }

    public get predictedCostInclVAT(): number {
        return this.predictedCostExclVAT + this.predictedVAT;
    }

    // #endregion Business Logic
}
