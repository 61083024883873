import { runInAction } from "mobx";

import { EndpointWithoutRequest, Http } from "Application";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { AssignedTaskItemModel } from "./AssignedTaskItemModel";
import { AssignedTasksViewModel } from "./AssignedTasksViewModel";
import { AssignedTaskItemViewModel } from "./AssignedTaskItemViewModel";

class Response {
    public id: string = "";
    public projectTaskAssignmentStatusId = "";
    public projectTaskId = "";
    public projectLeadId = "";
    public plannedStartDate: string | null = null;
    public plannedEndDate: string | null = null;
    public completedDate: string | null = null;
    public billableEffort: number | null = null;
    public reference = "";
    public originReference = "";
    public title = "";
    public projectTaskAssignmentStatusName = "";
    public projectTaskAssignmentStatusForegroundColor = "";
    public projectTaskName = "";
    public projectLeadFirstName = "";
    public projectLeadLastName = "";
    public projectLeadDocumentUrl = "";
}

export class GETAssignedProjectTasksEndpoint extends EndpointWithoutRequest<Response> {
    private viewModel: AssignedTasksViewModel;

    constructor(viewModel: AssignedTasksViewModel) {
        super();
        this.viewModel = viewModel;
        this.verb(Http.Get);
        this.path(AppUrls.Server.Resources.AllAssignedProjectTasks);
    }

    public async HandleResponseAsync(response: Response[]): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            const tasks: AssignedTaskItemViewModel[] = [];

            response.forEach((element: any) => {
                const model = new AssignedTaskItemModel();

                model.fromResponse(element);

                tasks.push(new AssignedTaskItemViewModel(model));
            });

            this.viewModel.tasks.replace(tasks);
        });
    }
}
