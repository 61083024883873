import "gantt-task-react/dist/index.css";
import { Gantt, Task, ViewMode } from "gantt-task-react";
import { observer } from "mobx-react-lite";
import React from "react";

import { Box, theme } from "Application";
import {
    GanttChartContainer,
    ganttChartGetColumnWidth,
    GanttTable,
    GanttTableHeader,
    GanttTableHeaderItem,
    getBarCornerRadius,
    TaskList,
    TaskListCell,
    TaskListNameWrapper,
    TaskListTableRow,
} from "./GanttChartStyles";
import { ProjectTaskGroupChartViewModel } from "./ProjectTaskGroups/ProjectTaskGroupChartViewModel";
import { ViewModeBar } from "./ViewModeBar";

interface Props {
    projectTaskGroupViewModels: ProjectTaskGroupChartViewModel[];
}

const TASK_COLUMNWIDTH = "250px";
const BAR_FILL = 77;
const ROW_HEIGHT = 47;

export const ProjectTaskGroupsGanttChart: React.FC<Props> = observer((props: Props) => {
    // #region Code Behind

    const [viewMode, setViewMode] = React.useState<ViewMode>(ViewMode.Week);
    const onViewModelChange = (viewMode: ViewMode) => setViewMode(viewMode);
    const columnWidth = ganttChartGetColumnWidth(viewMode);

    const onHandleExpanderClick = (task: Task) => {};

    const onHandleDateChange = (task: Task, children: Task[]) => {
        const taskGroupViewModel = props.projectTaskGroupViewModels.find((projectTaskViewModel) => projectTaskViewModel.id === task.id);

        taskGroupViewModel?.dateChangeCommand.execute(task.start, task.end);
    };

    const onHandleProgressChange = (task: Task, children: Task[]) => {};

    /**
     * Converts the provided viewmodels into a form the chart can use.
     */
    const convertToGanttTasks = (): Task[] => {
        // We use the quoteId as a means of colouring the row. All task groups belonging to
        // the same quote will be the same colour.
        let originQuoteId = props.projectTaskGroupViewModels[0].originQuoteId;

        return props.projectTaskGroupViewModels.map((projectTaskViewModel) => {
            let partOfSameQuote = projectTaskViewModel.originQuoteId === originQuoteId;
            const color: string = partOfSameQuote ? "#C15DB0" : "#11A696";

            originQuoteId = projectTaskViewModel.originQuoteId;

            return {
                id: projectTaskViewModel.id,
                name: projectTaskViewModel.name,
                type: "task",
                start: projectTaskViewModel.startDate!,
                end: projectTaskViewModel.endDate!,
                progress: 100,
                styles: { progressColor: color, progressSelectedColor: color },
            };
        });
    };

    // #endregion Code Behind

    const TaskListHeader: React.FC<{
        headerHeight: number;
        rowWidth: string;
        fontFamily: string;
        fontSize: string;
    }> = ({ headerHeight, fontFamily, fontSize, rowWidth }) => {
        return (
            <GanttTable
                style={{
                    fontFamily: fontFamily,
                    fontSize: fontSize,
                }}
            >
                <GanttTableHeader
                    style={{
                        height: headerHeight - 2,
                    }}
                >
                    <GanttTableHeaderItem
                        style={{
                            minWidth: TASK_COLUMNWIDTH,
                            maxWidth: TASK_COLUMNWIDTH,
                        }}
                    >
                        {/* If required, place the label here. */}
                    </GanttTableHeaderItem>
                </GanttTableHeader>
            </GanttTable>
        );
    };

    const TaskListTable: React.FC<{
        rowHeight: number;
        rowWidth: string;
        fontFamily: string;
        fontSize: string;
        locale: string;
        tasks: Task[];
        selectedTaskId: string;
        setSelectedTask: (taskId: string) => void;
        onExpanderClick: (task: Task) => void;
    }> = ({ rowHeight, rowWidth, tasks, fontFamily, fontSize, locale, onExpanderClick }) => {
        return (
            <>
                <TaskList
                    style={{
                        fontFamily: fontFamily,
                        fontSize: fontSize,
                    }}
                >
                    {tasks.map((task) => {
                        const backgroundColor = `${task.styles!.progressColor}33`;
                        const fontSize = "12px";

                        return (
                            <TaskListTableRow style={{ height: rowHeight, backgroundColor: backgroundColor, fontSize: fontSize }} key={`${task.id}row`}>
                                <TaskListCell
                                    style={{
                                        minWidth: TASK_COLUMNWIDTH,
                                        maxWidth: TASK_COLUMNWIDTH,
                                    }}
                                    title={task.name}
                                >
                                    <TaskListNameWrapper>
                                        <div>{task.name}</div>
                                    </TaskListNameWrapper>
                                </TaskListCell>
                            </TaskListTableRow>
                        );
                    })}
                </TaskList>
            </>
        );
    };

    return (
        <Box>
            <ViewModeBar viewMode={viewMode} onViewModelChange={onViewModelChange} />
            <GanttChartContainer>
                <Gantt
                    barCornerRadius={getBarCornerRadius(ROW_HEIGHT, BAR_FILL)}
                    barFill={BAR_FILL}
                    listCellWidth="90px"
                    rowHeight={ROW_HEIGHT}
                    fontFamily={theme.defaultFontStyle.fontFamily}
                    fontSize="10px"
                    handleWidth={(getBarCornerRadius(ROW_HEIGHT, BAR_FILL) - 1) * 2}
                    columnWidth={columnWidth}
                    viewMode={viewMode}
                    tasks={convertToGanttTasks()}
                    TaskListHeader={TaskListHeader}
                    TaskListTable={TaskListTable}
                    TooltipContent={() => <React.Fragment />}
                    onExpanderClick={onHandleExpanderClick}
                    onDateChange={onHandleDateChange}
                    onProgressChange={onHandleProgressChange}
                />
            </GanttChartContainer>
        </Box>
    );
});
