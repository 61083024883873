import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { PlanningApplicationTableViewModel } from "../PlanningApplicationTableViewModel";
import { ThemedDataTable } from "Styles/ThemedPrimitives/Style1/ThemedDataTable";
import { PlanningApplicationTableItemViewModel } from "../TableItem/PlanningApplicationTableItemViewModel";
import { uniqueId } from "lodash-es";
import { DetailsRow, IColumn, Link, PersonaSize } from "@fluentui/react";
import { ThemedPersona } from "Styles/ThemedPrimitives/Style1/ThemedPersona";
import { DeleteIcon } from "Assets";
import { theme } from "Application";
import styled from "@emotion/styled";

interface IPlanningApplicationTableProps {
    viewModel: PlanningApplicationTableViewModel;
}

export interface IDeleteCalloutState {
    isCalloutVisible: boolean;
    targetId: string | null;
}

const DeleteButton = styled.div`
    //remove the default button styling
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;
    outline: none;
    min-width: 25px;
    min-height: 25px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.2s ease-in-out;

    svg {
        fill: ${theme.palette.error.main} !important;
    }

    &:hover {
        opacity: 0.6;
    }
`;

export const PlanningApplicationTable: React.FC<IPlanningApplicationTableProps> = observer(({ viewModel }) => {
    const tableId = "planning-application-table-" + uniqueId();

    /**
     * Handles an onClick event for the table column header.
     *
     * @param ev Mouse event
     * @param column The column that has been clicked.
     */
    const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const currColumn: IColumn = columns.filter((currCol) => column.key === currCol.key)[0];
        const newSortKey = currColumn.key;
        const newSortDescending = currColumn.key === viewModel.settings.sortKey ? !currColumn.isSortedDescending : false;

        viewModel.updateSortCommand.execute(newSortKey, newSortDescending);
    };

    /**
     * Gets a key for the row. Must be provided if sorting or filtering is enabled.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The row index.
     *
     * @returns A string key to identify the row.
     */
    const getRowKey = (item: PlanningApplicationTableItemViewModel, index?: number): string => {
        return item.KEY;
    };

    /**
     * Handles rendering the row. Normally we would not do this. However if
     * trying to display an overlay (alert, panel or modal), on dismissing
     * the overlay, onActiveItemChanged would be called again and redisplay the
     * overlay!
     *
     * @param props The row props of which item is the viewmodel.
     * @returns The table row.
     */
    const onRenderRow = (props: any) => {
        return <DetailsRow {...props} onClick={() => onRowClick(props.item)} />;
    };
    /**
     * Handler an onClick event for a table row.
     *
     * @param item The viewmodel data associated with the table row.
     */
    const onRowClick = (item: PlanningApplicationTableItemViewModel, index?: number, ev?: Event) => {
        viewModel.onEditItem(item);
    };

    /**
     * Renders the project lead persona.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The index of the table row.
     * @param column The table column.
     *
     * @returns React element.
     */
    const onRenderProjectLead = (item: PlanningApplicationTableItemViewModel, index?: number, column?: IColumn) => {
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <ThemedPersona
                    styles={{ primaryText: { fontSize: "10px" } }}
                    displayName={item.projectLeadFullName}
                    size={PersonaSize.size32}
                    imageUrl={item.projectLeadDocumentUrl}
                />
            </div>
        );
    };

    /**
     * Renders the project lead persona.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The index of the table row.
     * @param column The table column.
     *
     * @returns React element.
     */

    const onRenderNote = (item: PlanningApplicationTableItemViewModel, index?: number, column?: IColumn) => {
        const note = item.note.length > 100 ? `${item.note.substring(0, 100)}...` : item.note;
        return <div style={{ maxHeight: "50px" }}>{note}</div>;
    };

    const onRenderCustomerFullName = (item: PlanningApplicationTableItemViewModel, index?: number, column?: IColumn) => {
        return (
            <Link onFocus={(ev) => ev.stopPropagation()}>
                <div onClick={() => viewModel.navigateToCustomerDetailCommand.execute(item.model.customerId!)}>
                    <div style={{ fontWeight: 600, fontSize: "10px", color: "#171716" }}>{item.customerFullName}</div>
                </div>
            </Link>
        );
    };
    const onRenderProjectTitle = (item: PlanningApplicationTableItemViewModel, index?: number, column?: IColumn) => {
        return (
            <Link onFocus={(ev) => ev.stopPropagation()}>
                <div onClick={() => viewModel.navigateToProjectDetailCommand.execute(item.model.projectId!)}>
                    <div style={{ fontWeight: 600, fontSize: "10px", color: "#171716" }}>{item.projectTitle}</div>
                </div>
            </Link>
        );
    };

    const onRenderProjectReference = (item: PlanningApplicationTableItemViewModel, index?: number, column?: IColumn) => {
        return (
            <Link onFocus={(ev) => ev.stopPropagation()}>
                <div onClick={() => viewModel.navigateToProjectDetailCommand.execute(item.model.projectId!)}>
                    <div style={{ fontWeight: 600, fontSize: "10px", color: "#171716" }}>{item.projectRef}</div>
                </div>
            </Link>
        );
    };

    /**
     * Handles updating the tables column sort settings in response to changes in the viewmodel.
     */
    useEffect(() => {
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter((currCol) => viewModel.settings.sortKey === currCol.key)[0];

        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSorted = true;
                currColumn.isSortedDescending = viewModel.settings.sortDescending;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = false;
            }
        });

        setColumns(newColumns);
    }, [viewModel.settings.sortKey, viewModel.settings.sortDescending]);

    const onDeleteClick = (ev: any, item: PlanningApplicationTableItemViewModel) => {
        ev.stopPropagation();
        viewModel.onInitiateDeleteItem(item);
    };

    /**
     * Renders the delete icon and provides callout support.
     *
     * The icon is wrapped in a Link with a stop propagation call so
     * clicking on the icon prevents the table row click.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The index of the table row.
     * @param column The table column.
     *
     * @returns React rating element.
     */

    const onRenderDelete = (item: PlanningApplicationTableItemViewModel, index?: number, column?: IColumn) => {
        return (
            <DeleteButton typeof="button" onClick={(ev) => onDeleteClick(ev, item)}>
                <DeleteIcon id={`delete-icon-${index}`} color={theme.palette.error.main} path={theme.palette.error.main} />
            </DeleteButton>
        );
    };

    /**
     * Defines the columns for use with the table.
     */
    const defaultTableColumns: IColumn[] = [
        {
            fieldName: "projectRef",
            key: "projectRef",
            name: "Ref.",
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: onRenderProjectReference,
        },
        {
            fieldName: "projectTitle",
            key: "projectTitle",
            name: "Job name",
            isResizable: true,
            minWidth: 150,
            maxWidth: 225,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: onRenderProjectTitle,
        },
        {
            fieldName: "customerFullName",
            key: "customerFullName",
            name: "Customer Name",
            isResizable: true,
            minWidth: 150,
            maxWidth: 225,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: onRenderCustomerFullName,
        },
        {
            fieldName: "typeName",
            key: "typeName",
            name: "Application Type",
            isResizable: true,
            minWidth: 150,
            maxWidth: 225,
            isSorted: true,
            onColumnClick: onColumnClick,
        },
        {
            fieldName: "planningRef",
            key: "planningRef",
            name: "Planning Ref.",
            isResizable: true,
            minWidth: 150,
            styles: {
                cellTitle: {
                    textAlign: "center",
                    justifyContent: "center",
                },
            },
            className: "textCenter",
            isSorted: true,
            onColumnClick: onColumnClick,
        },
        {
            fieldName: "localAuthorityDistrictName",
            key: "localAuthorityDistrictName",
            name: "Local Authority",
            isResizable: true,
            minWidth: 150,
            isSorted: true,
            onColumnClick: onColumnClick,
        },
        {
            fieldName: "projectLeadFullName",
            key: "projectLeadFullName",
            name: "Project Lead",
            isResizable: true,
            minWidth: 150,
            maxWidth: 250,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: onRenderProjectLead,
        },
        {
            fieldName: "formattedSubmittedDate",
            key: "formattedSubmittedDate",
            name: "Submitted Date",
            isResizable: true,
            minWidth: 0,
            maxWidth: 100,
            isSorted: true,
            onColumnClick: onColumnClick,
        },
        {
            fieldName: "formattedConsultationDate",
            key: "formattedConsultationDate",
            name: "Consultation Date",
            isResizable: true,
            minWidth: 0,
            maxWidth: 100,
            isSorted: true,
            onColumnClick: onColumnClick,
        },
        {
            fieldName: "formattedDeterminationDate",
            key: "formattedDeterminationDate",
            name: "Determination Date",
            isResizable: true,
            minWidth: 0,
            maxWidth: 100,
            isSorted: true,
            onColumnClick: onColumnClick,
        },
        {
            fieldName: "formattedApprovedDate",
            key: "formattedApprovedDate",
            name: "Approved Date",
            isResizable: true,
            minWidth: 0,
            maxWidth: 100,
            isSorted: true,
            onColumnClick: onColumnClick,
        },
        {
            className: "deleteCell",
            fieldName: "",
            key: "deleteCell",
            name: "",
            maxWidth: 25,
            minWidth: 25,
            onRender: onRenderDelete,
            isPadded: false,
            styles: {},
        },
    ];

    const [columns, setColumns] = useState<IColumn[]>(defaultTableColumns);

    return (
        <>
            <ThemedDataTable
                onRenderRow={onRenderRow}
                className="planning-application-table"
                getKey={getRowKey}
                items={viewModel.filteredAndSortedItems}
                onItemInvoked={onRowClick}
                columns={columns}
                id={tableId}
            />
        </>
    );
});
