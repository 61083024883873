import { DetailsRow, IColumn, PersonaSize } from "@fluentui/react";
import { isNullOrUndefined } from "@shoothill/core";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import { AutoGrid, Box, ThemedButton, ThemedComboBox, ThemedEditDate, ThemedEditText } from "Application";
import { formatDate, setPageTitle } from "Application/Utils";
import { SearchIcon } from "Assets/SearchIcon";
import { ThemedDataTable } from "Styles/ThemedPrimitives/Style1/ThemedDataTable";
import { ThemedPersona } from "Styles/ThemedPrimitives/Style1/ThemedPersona";
import { FormDottedSeparator } from "Views/Shared/SharedComponents";
import { AssignedTaskItemViewModel } from "./AssignedTaskItemViewModel";
import { AssignedTasksViewModel } from "./AssignedTasksViewModel";
import { TaskView } from "./ConfigureSubViews/TaskView";
import { CustomSidePanel } from "Views/Shared/CustomSidePanel/CustomSidePanel";
import { ResourceViewModel } from "Views/Resource/ResourceViewModel";
import { DashboardViewModel } from "Views/Dashboard/DashboardViewModel";

const controlStyles = {
    root: {
        height: "39px",
        fontSize: "17px",
        borderColor: "#E1E1E1",
    },
};

interface IProps {
    parentViewModel?: ResourceViewModel | DashboardViewModel;
    overrideStatusFilter?: string;
}

export const AssignedTasksView: React.FC<IProps> = observer((props: IProps) => {
    // #region Code Behind

    const [viewModel] = useState(() => new AssignedTasksViewModel(props.parentViewModel, props.overrideStatusFilter));

    useEffect(() => {
        setPageTitle("Tasks in progress");
    }, []);

    useEffect(() => {
        const filter = !isNullOrUndefined(props.overrideStatusFilter) ? props.overrideStatusFilter! : null;

        viewModel.filterStatusByDefault(filter);
    }, [props.overrideStatusFilter]);

    /**
     * Handles updating the tables column sort settings in response to changes in the viewmodel.
     */
    useEffect(() => {
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter((currCol) => viewModel.getValue("sortKey") === currCol.key)[0];

        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSorted = true;
                currColumn.isSortedDescending = viewModel.getValue("sortDescending");
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = false;
            }
        });

        setColumns(newColumns);
    }, [viewModel.getValue("sortKey"), viewModel.getValue("sortDescending")]);

    /**
     * Gets a key for the row. Must be provided if sorting or filtering is enabled.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The row index.
     *
     * @returns A string key to identify the row.
     */
    const getRowKey = (item: AssignedTaskItemViewModel, index?: number): string => {
        return item.KEY;
    };

    /**
     * Handles an onClick event for the table column header.
     *
     * @param ev Mouse event
     * @param column The column that has been clicked.
     */
    const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const currColumn: IColumn = columns.filter((currCol) => column.key === currCol.key)[0];
        const newSortKey = currColumn.key;
        const newSortDescending = currColumn.key === viewModel.getValue("sortKey") ? !currColumn.isSortedDescending : false;

        viewModel.updateSortCommand.execute(newSortKey, newSortDescending);
    };

    /**
     * Handler an onClick event for a table row.
     *
     * @param item The viewmodel data associated with the table row.
     */
    const onRowClick = (item: AssignedTaskItemViewModel) => {
        viewModel.displayTaskCommand.execute(item.model.id);
    };

    /**
     * Handles rendering the row. Normally we would not do this. However if
     * trying to display an overlay (alert, panel or modal), on dismissing
     * the overlay, onActiveItemChanged would be called again and redisplay the
     * overlay!
     *
     * @param props The row props of which item is the viewmodel.
     * @returns The table row.
     */
    const onRenderRow = (props: any) => {
        return <DetailsRow {...props} onClick={() => onRowClick(props.item)} />;
    };

    /**
     * Renders the project task assignment status row data.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The index of the table row.
     * @param column The table column.
     *
     * @returns React element.
     */
    const onRenderProjectTaskAssignmentStatus = (item: AssignedTaskItemViewModel, index?: number, column?: IColumn) => {
        return (
            <div style={{ color: `${item.projectTaskAssignmentStatusForegroundColor}`, textTransform: "uppercase", fontWeight: "500" }}>{item.projectTaskAssignmentStatusName}</div>
        );
    };

    /**
     * Renders the project lead persona.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The index of the table row.
     * @param column The table column.
     *
     * @returns React element.
     */
    const onRenderProjectLeadName = (item: AssignedTaskItemViewModel, index?: number, column?: IColumn) => {
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <ThemedPersona styles={{ primaryText: { fontSize: "10px" } }} displayName={item.projectLeadName} size={PersonaSize.size32} imageUrl={item.projectLeadDocumentUrl} />
            </div>
        );
    };

    /**
     * Renders the assigned persona.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The index of the table row.
     * @param column The table column.
     *
     * @returns React element.
     */
    const onRenderAssignedName = (item: AssignedTaskItemViewModel, index?: number, column?: IColumn) => {
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <ThemedPersona styles={{ primaryText: { fontSize: "10px" } }} displayName={item.assignedName} size={PersonaSize.size32} imageUrl={item.assignedDocumentUrl} />
            </div>
        );
    };

    /**
     * Renders the planned start date row data.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The index of the table row.
     * @param column The table column.
     *
     * @returns React element.
     */
    const onRenderPlannedStartDate = (item: AssignedTaskItemViewModel, index?: number, column?: IColumn) => {
        return <div>{!isNullOrUndefined(item.plannedStartDate) ? formatDate(item.plannedStartDate!) : ""}</div>;
    };

    /**
     * Renders the planned end date row data.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The index of the table row.
     * @param column The table column.
     *
     * @returns React element.
     */
    const onRenderPlannedEndDate = (item: AssignedTaskItemViewModel, index?: number, column?: IColumn) => {
        return <div>{!isNullOrUndefined(item.plannedEndDate) ? formatDate(item.plannedEndDate!) : ""}</div>;
    };

    /**
     * Renders the completed date row data.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The index of the table row.
     * @param column The table column.
     *
     * @returns React element.
     */
    const onRenderIssueDate = (item: AssignedTaskItemViewModel, index?: number, column?: IColumn) => {
        return <div>{!isNullOrUndefined(item.completedDate) ? formatDate(item.completedDate!) : ""}</div>;
    };

    const defaultTableColumns: IColumn[] = [
        {
            key: "projectTaskAssignmentStatusName",
            fieldName: "projectTaskAssignmentStatusName",
            name: "STATUS",
            minWidth: 75,
            maxWidth: 100,
            isResizable: true,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: onRenderProjectTaskAssignmentStatus,
        },
        {
            key: "reference",
            fieldName: "reference",
            name: "PROJECT",
            minWidth: 220,
            maxWidth: 430,
            isResizable: true,
            isSorted: true,
            onColumnClick: onColumnClick,
        },
        {
            key: "projectTaskName",
            fieldName: "projectTaskName",
            name: "TASK",
            isResizable: true,
            minWidth: 320,
            maxWidth: 530,
            isSorted: true,
            onColumnClick: onColumnClick,
        },
        {
            key: "projectLeadName",
            fieldName: "projectLeadName",
            name: "PROJECT LEAD",
            isResizable: true,
            minWidth: 175,
            maxWidth: 200,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: onRenderProjectLeadName,
        },
        {
            key: "assignedName",
            fieldName: "assignedName",
            name: "ASSIGNED TO",
            isResizable: true,
            minWidth: 175,
            maxWidth: 200,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: onRenderAssignedName,
        },
        {
            fieldName: "plannedStartDate",
            key: "plannedStartDate",
            name: "START DATE",
            minWidth: 100,
            maxWidth: 100,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: onRenderPlannedStartDate,
        },
        {
            fieldName: "plannedEndDate",
            key: "plannedEndDate",
            name: "END DATE",
            minWidth: 100,
            maxWidth: 100,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: onRenderPlannedEndDate,
        },
        {
            fieldName: "billableEffort",
            key: "billableEffort",
            name: "CHARGE. HOURS",
            minWidth: 100,
            maxWidth: 100,
            isSorted: true,
            onColumnClick: onColumnClick,
        },
        {
            fieldName: "completedDate",
            key: "completedDate",
            name: "COMPLETED",
            minWidth: 100,
            maxWidth: 100,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: onRenderIssueDate,
        },
    ];

    const [columns, setColumns] = useState<IColumn[]>(defaultTableColumns);

    // #endregion Code Behind

    const renderTaskPanel = () => {
        return (
            <CustomSidePanel
                isOpen={viewModel.canDisplayTask}
                onDismiss={() => {
                    viewModel.cancelTaskCommand.execute();
                }}
            >
                <TaskView viewModel={viewModel.taskViewModel!} />
            </CustomSidePanel>
        );
    };

    return (
        <Box>
            {/* Filter Bar */}
            <Box display={"flex"} justifyContent={"center"} alignItems={"space-between"} flexDirection="column">
                <AutoGrid m={0} columnGap="15px" width="75%" dc={"auto 205px 205px"}>
                    <ThemedEditText
                        command={viewModel.updateFilterKeywordCommand}
                        placeholder={"Keyword search"}
                        prefix={<SearchIcon />}
                        styles={controlStyles}
                        value={() => viewModel.getValue("filterKeyword")}
                    />
                    <ThemedEditDate
                        command={viewModel.updateFilterDateCommand}
                        placeholder="Start/end date"
                        size="larger"
                        value={() => viewModel.getValue("filterDate")}
                        showLabel={false}
                    />
                    <ThemedButton
                        styles={{
                            root: {
                                height: "38px",
                            },
                        }}
                        paletteColor="primary"
                        command={viewModel.clearFiltersCommand}
                        size="larger"
                        displayName="Clear Filters"
                    />
                </AutoGrid>
                <FormDottedSeparator />
                <AutoGrid columnGap="15px" width="75%" dc={"1fr 1fr 1fr"}>
                    {viewModel.canShowStatusFilter && (
                        <ThemedComboBox
                            command={viewModel.updateFilterProjectTaskAssignmentStatusCommand}
                            options={viewModel.projectTaskAssignmentStatuses}
                            placeholder="All statuses"
                            size="larger"
                            value={() => viewModel.getValue("filterProjectTaskAssignmentStatusId")}
                        />
                    )}
                    <ThemedComboBox
                        command={viewModel.updateFilterProjectCommand}
                        options={viewModel.projects}
                        placeholder="All projects"
                        size="larger"
                        value={() => viewModel.getValue("filterProjectId")}
                    />
                    <ThemedComboBox
                        command={viewModel.updateFilterProjectLeadCommand}
                        options={viewModel.projectLeads}
                        placeholder="All leads"
                        size="larger"
                        value={() => viewModel.getValue("filterProjectLeadId")}
                    />
                </AutoGrid>
            </Box>
            <ThemedDataTable onRenderRow={onRenderRow} getKey={getRowKey} items={viewModel.filteredAndSortedTasks} columns={columns} />

            {/* Display task panel */}
            {renderTaskPanel()}
        </Box>
    );
});
