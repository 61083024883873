import { isNullOrUndefined, ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

import { formatDate, RelayCommand } from "Application";
import { LeaveRequestItemModel } from "./LeaveRequestItemModel";
import { LeaveRequestsViewModel } from "./LeaveRequestsViewModel";

export class LeaveRequestItemViewModel extends ViewModelBase<LeaveRequestItemModel> {
    private parentLeaveRequestsViewModel: LeaveRequestsViewModel;

    constructor(model: LeaveRequestItemModel, leaveRequestsViewModel: LeaveRequestsViewModel) {
        super(model);

        this.parentLeaveRequestsViewModel = leaveRequestsViewModel;

        makeObservable(this, {});
    }

    public get KEY() {
        return this.model.KEY;
    }

    // #region Column Properties

    public get leaveType() {
        return this.model.leaveRequest?.leaveType?.name;
    }

    public get leaveStatusType() {
        return this.model.leaveRequest?.leaveStatusType?.name;
    }

    public get requestFrom() {
        return this.model.leaveRequest?.fromDate?.date;
    }

    public get requestTo() {
        return this.model.leaveRequest?.toDate?.date;
    }

    public get numberOfDays() {
        return Math.abs(this.model.leaveRequest!.numberOfDays!);
    }

    // #endregion Column Properties

    // #region Content Properties

    public get leaveStatusTypeColor() {
        return !isNullOrUndefined(this.model?.leaveRequest?.leaveStatusType?.foregroundColor) ? this.model!.leaveRequest!.leaveStatusType!.foregroundColor : "#000000";
    }

    public get renderLeaveTypeContentString() {
        return !isNullOrUndefined(this.model?.leaveRequest?.leaveType?.name) ? this.model!.leaveRequest!.leaveType!.name : "";
    }

    public get renderLeaveStatusTypeContentString() {
        return !isNullOrUndefined(this.model?.leaveRequest?.leaveStatusType?.name) ? this.model!.leaveRequest!.leaveStatusType!.name : "";
    }

    public get renderResponseReasonContentString() {
        return !isNullOrUndefined(this.model?.leaveRequest?.responseReason) ? this.model!.leaveRequest!.responseReason : "";
    }

    public get requestFromContentString() {
        return !isNullOrUndefined(this.model?.leaveRequest?.fromDate)
            ? `${formatDate(new Date(this.model!.leaveRequest!.fromDate!.date!))} ${this.model!.leaveRequest!.fromDate!.leaveDayType!.name}`
            : "";
    }

    public get requestToContentString() {
        return !isNullOrUndefined(this.model?.leaveRequest?.toDate)
            ? `${formatDate(new Date(this.model!.leaveRequest!.toDate!.date!))} ${this.model!.leaveRequest!.toDate!.leaveDayType!.name}`
            : "";
    }

    // #region Content Properties

    // #region Commands

    public editLeaveCommand = new RelayCommand(
        () => this.parentLeaveRequestsViewModel.displayEditRequestCommand.execute(this.model.leaveRequest),
        () => this.parentLeaveRequestsViewModel.displayEditRequestCommand.canExecute() && this.model.canEdit,
    );

    public deleteLeaveCommand = new RelayCommand(
        () => this.parentLeaveRequestsViewModel.displayDeleteRequestCommand.execute(this.model.leaveRequest),
        () => this.parentLeaveRequestsViewModel.displayDeleteRequestCommand.canExecute() && this.model.canDelete,
    );

    public cancelLeaveCommand = new RelayCommand(
        () => this.parentLeaveRequestsViewModel.displayCancelRequestCommand.execute(this.model.leaveRequest),
        () => this.parentLeaveRequestsViewModel.displayCancelRequestCommand.canExecute() && this.model.canCancel,
    );

    // #endregion Commands
}
