import _ from "lodash";

import { ProjectLite, Service, ServiceGroup, SignatoryUser } from "Application/Models/Domain";
import { CustomerContactItemModel } from "Views/Shared/Customer/CustomerContactItemModel";
import { CustomerItemModel } from "Views/Shared/Customer/CustomerItemModel";
import { LetterTemplateItemModel } from "Views/Shared/Letter/LetterTemplateItemModel";
import { TaskGroupModel } from "Views/Shared/Services/ServiceSubViews/TaskGroupModel";
import { TaskModel } from "Views/Shared/Services/ServiceSubViews/TaskModel";
import { QuoteHistoryViewModel } from "../QuoteHistoryViewModel";
import { TermsAndConditionItemModel } from "Views/Shared/TermsAndConditions/TermsAndConditionItemModel";

export class QuoteHistoryResponse extends Response {
    // #region Response Data

    public id: string = "";
    public revision: string = "";

    public quoteStatusId: string = "";
    public enquiryId: string | null = null;
    public quoteId: string = "";
    public contactTypeId: string = "";
    public customerId: string = "";
    public customerContactId: string | null = null;
    public projectId: string = "";

    public sameAsBusinessAddress: boolean = false;
    public siteAddress1: string | null = null;
    public siteAddress2: string | null = null;
    public siteAddress3: string | null = null;
    public siteCity: string | null = null;
    public sitePostcode: string | null = null;

    public title: string = "";
    public leadInWeeks: number = 0;
    public durationWeeks: number = 0;
    public confidence: number = 0;
    public desirability: number = 0;

    public letterTemplateId: string = "";
    public letterDetails: string = "";

    public termAndConditionId: string = "";
    public termsAndConditionsDetails: string = "";
    public signatoryUserid: string = "";

    customers: CustomerResponse[] = [];
    customerContacts: CustomerContactResponse[] = [];
    quoteTasks: QuoteTaskResponse[] = [];
    letterTemplates: LetterTemplateResponse[] = [];
    termsAndConditions: TermsAndConditionResponse[] = [];
    projects: ProjectResponse[] = [];

    serviceGroups: ServiceGroupResponse[] = [];
    services: ServiceResponse[] = [];
    signatoryUsers: SignatoryUserResponse[] = [];

    // #endregion Response Data

    public static quoteResponsePart(response: QuoteHistoryResponse, viewModel: QuoteHistoryViewModel) {
        viewModel.quoteViewModel.model.fromResponse(response);

        // Process the response Projects.
        response.projects.forEach((projects) => {
            let model: ProjectLite = {
                id: projects.id,
                title: projects.title,
                projectTypeId: projects.projectTypeId,
            };
            viewModel.quoteViewModel.model.projectsList.push(model);
        });
    }

    public static customerResponse(response: QuoteHistoryResponse, viewModel: QuoteHistoryViewModel) {
        viewModel.customerViewModel.customers.replace(
            response.customers.map((c) => {
                const model = new CustomerItemModel();

                model.fromResponse(c);

                viewModel.customerViewModel.model.fromResponse(model);

                return model;
            }),
        );

        viewModel.customerViewModel.customerContacts.replace(
            response.customerContacts.map((cc) => {
                const model = new CustomerContactItemModel();

                model.fromResponse(cc);

                return model;
            }),
        );

        viewModel.customerViewModel.model.fromResponse(response);
    }

    public static letterTemplateResponse(response: QuoteHistoryResponse, viewModel: QuoteHistoryViewModel) {
        viewModel.letterViewModel.model.fromResponse(response);

        viewModel.letterViewModel.letterTemplates.replace(
            response.letterTemplates.map((lt) => {
                const model = new LetterTemplateItemModel();

                model.fromResponse(lt);

                return model;
            }),
        );
    }

    public static tasksResponsePart(response: QuoteHistoryResponse, viewModel: QuoteHistoryViewModel) {
        const groupedQuoteTasks = _.groupBy(response.quoteTasks, (quoteTask) => quoteTask.serviceGroupId);

        viewModel.servicesViewModel.model.serviceGroups.replace(
            response.serviceGroups.map((s) => {
                const serviceGroup = new ServiceGroup(s.id);

                serviceGroup.name = s.name;
                serviceGroup.isDeleted = s.isDeleted;
                serviceGroup.ordinal = s.ordinal;
                serviceGroup.type = s.type;

                return serviceGroup;
            }),
        );

        viewModel.servicesViewModel.model.services.replace(
            response.services.map((s) => {
                const service = new Service(s.id);

                service.name = s.name;
                service.isDeleted = s.isDeleted;
                service.ordinal = s.ordinal;
                service.serviceGroupId = s.serviceGroupId;

                return service;
            }),
        );

        viewModel.servicesViewModel.model.taskGroups.replace(
            Object.entries(groupedQuoteTasks).map(([serviceGroupId, quoteTasks]) => {
                const serviceGroup = viewModel.servicesViewModel.model.serviceGroups.find((sg) => sg.id === serviceGroupId);
                const taskGroup = new TaskGroupModel();
                taskGroup.id = serviceGroupId;
                taskGroup.taskGroupName = serviceGroup!.name;
                return taskGroup;
            }),
        );

        // Process the quote tasks into tasks and assign to the appropriate task group.
        for (const taskGroup of viewModel.servicesViewModel.model.taskGroups) {
            const tasks =
                Object.entries(groupedQuoteTasks)
                    .find(([serviceGroupId, quoteTasks]) => serviceGroupId === taskGroup.id)?.[1]
                    .map((qt) => {
                        const model = new TaskModel();
                        model.fromResponse(qt);
                        return model;
                    }) ?? [];

            taskGroup.tasks.replace(tasks);
        }
    }

    public static termsAndConditionsResponse(response: QuoteHistoryResponse, viewModel: QuoteHistoryViewModel) {
        viewModel.termsAndConditionsViewModel.model.fromResponse(response);

        viewModel.termsAndConditionsViewModel.termsAndConditionsTemplates.replace(
            response.termsAndConditions.map((tc) => {
                const model = new TermsAndConditionItemModel();
                model.fromResponse(tc);
                return model;
            }),
        );

        // Process the response signatory users.
        viewModel.termsAndConditionsViewModel.model.signatoryUsers.replace(
            response.signatoryUsers.map((u) => {
                const user = new SignatoryUser();

                user.id = u.id;
                user.firstName = u.firstName;
                user.lastName = u.lastName;

                return user;
            }),
        );
    }
}

class CustomerResponse {
    id: string = "";
    contactTypeId: string = "";
    contactTitleId: string | null = null;

    businessName: string | null = null;
    firstName: string | null = null;
    lastName: string | null = null;

    public contactNumber1: string | null = null;
    public contactNumber2: string | null = null;
    public emailAddress: string | null = null;

    public address1: string = "";
    public address2: string | null = null;
    public address3: string | null = null;
    public city: string = "";
    public postcode: string = "";
}

class CustomerContactResponse {
    id: string = "";
    customerId: string = "";
    contactTitleId: string | null = null;

    firstName: string = "";
    lastName: string = "";

    public contactNumber1: string | null = null;
    public contactNumber2: string | null = null;
    public emailAddress: string | null = null;

    public address1: string | null = null;
    public address2: string | null = null;
    public address3: string | null = null;
    public city: string | null = null;
    public postcode: string | null = null;
}

class QuoteTaskResponse {
    id: string = "";
    serviceGroupId: string = "";
    serviceId: string | null = null;
    taskName: string = "";
    manHours: number = 0;
    hourlyRate: number = 0;
}

class LetterTemplateResponse {
    id: string = "";
    name: string = "";
    body: string = "";
    isDeleted: boolean = false;
}
class TermsAndConditionResponse {
    id: string = "";
    name: string = "";
    body: string = "";
    isDeleted: boolean = false;
}

class ProjectResponse {
    id: string = "";
    title: string = "";
    projectTypeId: string = "";
}

class ServiceGroupResponse {
    id: string = "";
    name: string = "";
    ordinal: number = 0;
    type: string = "";
    isDeleted: boolean = false;
}

class ServiceResponse {
    id: string = "";
    name: string = "";
    ordinal: number = 0;
    isDeleted: boolean = false;
    serviceGroupId: string = "";
}

class SignatoryUserResponse {
    id: string = "";
    firstName: string = "";
    lastName: string = "";
}
