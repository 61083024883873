import { mergeStyleSets } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import { PropsWithChildren } from "react";

import { theme } from "../../AppTheme";
import { DataTableBase, IDetailsListProps } from "../../../Components";
import { pxToRem } from "Styles/BaseStyleSheet";

/**
 * A styled datalist control. The styling can be adjusted using the size and paletteColor props.
 * WARNING - If your styled components needs to change style because of change in the state of the
 * model (via the props), wrap the component in a mobx observer.
 */
export const ThemedDataTable = observer((props: PropsWithChildren<IDetailsListProps>) => {
    // Note. When merging styles, do this after the props spreader, otherwise props.styles will overwrite
    // base styles instead of being merged.
    return (
        <DataTableBase
            {...props}
            styles={mergeStyleSets(
                {
                    check: {
                        ".ms-Check": {
                            backgroundColor: "green",
                        },
                    },
                    root: {
                        fontFamily: theme.fontStyles.tableHeader.fontFamily ?? theme.defaultFontStyle.fontFamily,
                        marginTop: pxToRem(16),
                        ".ms-DetailsHeader": {
                            background: "#F3F3F3",
                            marginTop: pxToRem(16),
                            marginBottom: pxToRem(16),
                            paddingTop: pxToRem(0),
                        },
                        ".ms-DetailsRow": {
                            borderBottom: "none",
                            cursor: "pointer",
                            fontFamily: theme.fontStyles.tableHeader.fontFamily ?? theme.defaultFontStyle.fontFamily,
                        },
                        ".ms-List-cell:nth-child(odd) .ms-DetailsRow": {
                            background: "white",
                        },
                        ".ms-List-cell:nth-child(even) .ms-DetailsRow": {
                            background: "#F9F9F9",
                        },
                        ".ms-DetailsRow-cell": {
                            borderLeft: "1px solid #E1E1E1",
                            display: "flex",
                            alignItems: "center",
                            minHeight: pxToRem(52),
                            fontSize: pxToRem(10),
                            color: "black",
                            whiteSpace: "break-spaces",
                            padding: `${pxToRem(11)} ${pxToRem(12)} ${pxToRem(11)} ${pxToRem(12)}`,
                        },
                        ".ms-DetailsRow-cell:first-child": {
                            borderLeft: "none",
                        },
                        ".ms-DetailsRow-cell:last-child": {
                            borderRight: "none",
                        },
                        ".ms-DetailsHeader-cellName": {
                            color: theme.palette.tableHeader.text,
                            fontFamily: theme.fontStyles.tableHeader.fontFamily ?? theme.defaultFontStyle.fontFamily,
                            fontSize: pxToRem(10),
                            fontWeight: theme.fontStyles.tableHeader.fontWeight ?? theme.defaultFontStyle.fontWeight,
                            letterSpacing: theme.fontStyles.tableHeader.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                            lineHeight: theme.fontStyles.tableHeader.lineHeight ?? theme.defaultFontStyle.lineHeight,
                            textTransform: theme.fontStyles.tableHeader.textTransform ?? theme.defaultFontStyle.textTransform,
                        },
                    },
                    headerWrapper: {},
                    subComponentStyles: {},
                },
                props.styles,
            )}
        />
    );
});

export const ThemedDataTableLayer2 = observer((props: PropsWithChildren<IDetailsListProps>) => {
    // Note. When merging styles, do this after the props spreader, otherwise props.styles will overwrite
    // base styles instead of being merged.
    return (
        <DataTableBase
            {...props}
            styles={mergeStyleSets(
                {
                    root: {
                        "&&": {
                            fontFamily: theme.fontStyles.tableHeader.fontFamily ?? theme.defaultFontStyle.fontFamily,
                            marginTop: pxToRem(16),
                            ".ms-DetailsHeader": {
                                background: "#F3F3F3",
                                marginTop: pxToRem(16),
                                marginBottom: pxToRem(16),
                                paddingTop: pxToRem(0),
                            },
                            ".ms-DetailsRow": {
                                borderBottom: "none",
                                cursor: "pointer",
                                fontFamily: theme.fontStyles.tableHeader.fontFamily ?? theme.defaultFontStyle.fontFamily,
                                marginBottom: "5px",
                                minHeight: pxToRem(37),
                            },
                            ".ms-List-cell:nth-child(odd) .ms-DetailsRow": {
                                background: "#EAF4FF",
                            },
                            ".ms-List-cell:nth-child(even) .ms-DetailsRow": {
                                background: "#EAF4FF",
                            },
                            ".ms-DetailsRow-cell": {
                                borderLeft: "none",
                                display: "flex",
                                alignItems: "center",
                                minHeight: pxToRem(37),
                                fontSize: pxToRem(10),
                                color: "black",
                                whiteSpace: "break-spaces",
                                padding: `${pxToRem(11)} ${pxToRem(12)} ${pxToRem(11)} ${pxToRem(12)}`,
                            },
                            ".ms-DetailsRow-cell:first-child": {
                                borderLeft: "none",
                            },
                            ".ms-DetailsRow-cell:last-child": {
                                borderRight: "none",
                            },
                            ".ms-DetailsHeader-cellName": {
                                color: theme.palette.tableHeader.text,
                                fontFamily: theme.fontStyles.tableHeader.fontFamily ?? theme.defaultFontStyle.fontFamily,
                                fontSize: pxToRem(10),
                                fontWeight: theme.fontStyles.tableHeader.fontWeight ?? theme.defaultFontStyle.fontWeight,
                                letterSpacing: theme.fontStyles.tableHeader.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                                lineHeight: theme.fontStyles.tableHeader.lineHeight ?? theme.defaultFontStyle.lineHeight,
                                textTransform: theme.fontStyles.tableHeader.textTransform ?? theme.defaultFontStyle.textTransform,
                            },
                        },
                    },
                },
                props.styles,
            )}
        />
    );
});

export const ThemedDataTableLayer3 = observer((props: PropsWithChildren<IDetailsListProps>) => {
    // Note. When merging styles, do this after the props spreader, otherwise props.styles will overwrite
    // base styles instead of being merged.
    return (
        <DataTableBase
            {...props}
            styles={mergeStyleSets(
                {
                    root: {
                        "&&&": {
                            fontFamily: theme.fontStyles.tableHeader.fontFamily ?? theme.defaultFontStyle.fontFamily,
                            marginTop: pxToRem(16),
                            ".ms-DetailsHeader": {
                                background: "transparent",
                                border: "none",
                                marginTop: pxToRem(10),
                                marginBottom: pxToRem(10),
                                paddingTop: pxToRem(0),
                            },
                            ".ms-DetailsRow": {
                                borderBottom: "none",
                                cursor: "pointer",
                                fontFamily: theme.fontStyles.tableHeader.fontFamily ?? theme.defaultFontStyle.fontFamily,
                                marginBottom: "5px",
                                minHeight: pxToRem(30),
                            },
                            ".ms-List-cell .ms-DetailsRow": {
                                background: "#2D91FF11",
                            },
                            ".ms-DetailsRow-cell": {
                                borderLeft: "none",
                                display: "flex",
                                alignItems: "center",
                                minHeight: pxToRem(30),
                                fontSize: pxToRem(10),
                                color: "black",
                                whiteSpace: "break-spaces",
                                padding: `${pxToRem(5)} ${pxToRem(12)} ${pxToRem(5)} ${pxToRem(12)}`,
                            },
                            ".ms-DetailsRow-cell:first-child": {
                                borderLeft: "none",
                            },
                            ".ms-DetailsRow-cell:last-child": {
                                borderRight: "none",
                            },
                            ".ms-DetailsHeader-cellName": {
                                color: theme.palette.tableHeader.text,
                                fontFamily: theme.fontStyles.tableHeader.fontFamily ?? theme.defaultFontStyle.fontFamily,
                                fontSize: pxToRem(10),
                                fontWeight: theme.fontStyles.tableHeader.fontWeight ?? theme.defaultFontStyle.fontWeight,
                                letterSpacing: theme.fontStyles.tableHeader.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                                lineHeight: theme.fontStyles.tableHeader.lineHeight ?? theme.defaultFontStyle.lineHeight,
                                textTransform: theme.fontStyles.tableHeader.textTransform ?? theme.defaultFontStyle.textTransform,
                            },
                        },
                    },
                },
                props.styles,
            )}
        />
    );
});

export const ThemedDataTableLayer4 = observer((props: PropsWithChildren<IDetailsListProps>) => {
    // Note. When merging styles, do this after the props spreader, otherwise props.styles will overwrite
    // base styles instead of being merged.
    return (
        <DataTableBase
            {...props}
            styles={mergeStyleSets(
                {
                    root: {
                        "&&&": {
                            fontFamily: theme.fontStyles.tableHeader.fontFamily ?? theme.defaultFontStyle.fontFamily,
                            marginTop: pxToRem(16),
                            ".ms-DetailsHeader": {
                                background: "transparent",
                                border: "none",
                                marginTop: pxToRem(10),
                                marginBottom: pxToRem(10),
                                paddingTop: pxToRem(0),
                            },
                            ".ms-DetailsRow": {
                                borderBottom: "none",
                                cursor: "pointer",
                                fontFamily: theme.fontStyles.tableHeader.fontFamily ?? theme.defaultFontStyle.fontFamily,
                                marginBottom: "5px",
                                minHeight: pxToRem(30),
                            },
                            ".ms-List-cell .ms-DetailsRow": {
                                background: "#2D91FF11",
                            },
                            ".ms-DetailsRow-cell": {
                                borderLeft: "none",
                                display: "flex",
                                alignItems: "center",
                                minHeight: pxToRem(30),
                                fontSize: pxToRem(10),
                                color: "black",
                                whiteSpace: "break-spaces",
                                padding: `${pxToRem(5)} ${pxToRem(12)} ${pxToRem(5)} ${pxToRem(12)}`,
                            },
                            ".ms-DetailsRow-cell:first-child": {
                                borderLeft: "none",
                            },
                            ".ms-DetailsRow-cell:last-child": {
                                borderRight: "none",
                                padding: 0,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            },
                            ".ms-DetailsHeader-cellName": {
                                color: theme.palette.tableHeader.text,
                                fontFamily: theme.fontStyles.tableHeader.fontFamily ?? theme.defaultFontStyle.fontFamily,
                                fontSize: pxToRem(10),
                                fontWeight: theme.fontStyles.tableHeader.fontWeight ?? theme.defaultFontStyle.fontWeight,
                                letterSpacing: theme.fontStyles.tableHeader.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                                lineHeight: theme.fontStyles.tableHeader.lineHeight ?? theme.defaultFontStyle.lineHeight,
                                textTransform: theme.fontStyles.tableHeader.textTransform ?? theme.defaultFontStyle.textTransform,
                            },
                        },
                    },
                },
                props.styles,
            )}
        />
    );
});
