// Libraries
import React, { useRef, useState } from "react";
import ReactHtmlParser from "react-html-parser";

import { EditableCopyViewModel } from "Application/ViewModels/EditableCopyViewModel";

export const Terms: React.FC = () => {
    const { current: viewModel } = useRef(new EditableCopyViewModel());

    return (
        <>
            {ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/terms", 1))}
            <ol>
                <li>
                    <>
                        {ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/terms", 2))}
                        {ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/terms", 21))}
                    </>
                </li>
                <li>
                    <>
                        {ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/terms", 3))}
                        {ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/terms", 22))}
                    </>
                </li>
                <li>
                    <>
                        {ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/terms", 4))}
                        {ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/terms", 23))}
                    </>
                </li>
                <li>
                    <>
                        {ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/terms", 5))}
                        {ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/terms", 24))}
                    </>
                </li>
                <li>
                    <>
                        {ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/terms", 6))}
                        {ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/terms", 25))}
                    </>
                </li>
                <li>
                    <>
                        {ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/terms", 7))}
                        {ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/terms", 26))}
                    </>
                </li>
                <li>
                    <>
                        {ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/terms", 8))}
                        {ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/terms", 27))}
                    </>
                </li>
            </ol>
        </>
    );
};
