import { ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";
import { container } from "tsyringe";

import { ICommand } from "Application";
import { AccountStore } from "Stores/Domain";
import { DeleteConfirmationModel } from "./DeleteConfirmationModel";

export class DeleteConfirmationViewModel extends ViewModelBase<DeleteConfirmationModel> {
    private accountStore = container.resolve(AccountStore);

    public parentCancelDisplayConfirmationCommand: ICommand;
    public parentDeleteConfirmationCommand: ICommand;

    constructor(parentCancelDisplayConfirmationCommand: ICommand, parentDeleteConfirmationCommand: ICommand) {
        super(new DeleteConfirmationModel());

        this.parentCancelDisplayConfirmationCommand = parentCancelDisplayConfirmationCommand;
        this.parentDeleteConfirmationCommand = parentDeleteConfirmationCommand;

        makeObservable(this, {
            // Observables
        });
    }

    // #region Properties

    public get displayName() {
        return this.accountStore.LoggedUserFirstName;
    }

    public get displayMessage() {
        return "are you sure you want to delete this draft?";
    }

    // #endregion Properties

    // #region Commands
    // #endregion Commands

    // #region Supporting
    // #endregion Supporting
}
