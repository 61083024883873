import { isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

import { theme } from "Application";
import { BilledAssignmentsItemModel } from "./BilledAssignmentsItemModel";

export class BilledAssignmentsItemViewModel extends ViewModelBase<BilledAssignmentsItemModel> {
    constructor(model = new BilledAssignmentsItemModel()) {
        super(model);

        makeObservable(this, {});
    }

    public get KEY() {
        return this.model.KEY;
    }

    public get projectTaskName() {
        return this.model.projectTaskName;
    }

    public get projectTaskAssignmentStatusForegroundColor() {
        if (this.model.isNonBillableEffort) {
            return theme.palette.common.deepRed;
        }

        return this.model.projectTaskAssignmentStatusForegroundColor;
    }

    public get billedEffort() {
        return this.model.billedEffort;
    }

    public get billedDate() {
        return this.model.billedDate;
    }

    public get billedNote() {
        return this.model.billedNote ?? "";
    }

    public get assignedUserName() {
        return `${this.model.assignedUserFirstName} ${this.model.assignedUserLastName}`;
    }

    public get billedByUserName() {
        return `${this.model.billedByUserFirstName} ${this.model.billedByUserLastName}`;
    }

    // #region Filtering

    public filterPredicate = (filterKeyword: string): boolean => {
        let result = true;

        if (result) {
            const lowerCaseFilterKeyword = filterKeyword.toLowerCase();

            switch (true) {
                case isEmptyOrWhitespace(filterKeyword):
                    result = true;
                    break;

                default:
                    result = false;
                    break;
            }
        }

        return result;
    };

    // #endregion Filtering
}
