import { ViewModelBase } from "@shoothill/core";
import { computed, makeObservable, observable } from "mobx";

import { APIClient, RelayCommand } from "Application";
import { UnassignedTasksViewModel } from "./UnassignedTasksSubView/UnassignedTasksViewModel";
import { InProgressViewModel } from "./InProgressSuvbView/InProgressViewModel";
import { GETTaskProgrammingByIdEndpoint } from "./GETTaskProgrammingByIdEndpoint";

export class ProgrammingViewModel extends ViewModelBase<any> {
    public apiClient = new APIClient();
    public showAdditionalInformation = true;

    public unassignedTasksViewModel: UnassignedTasksViewModel;
    public inProgressViewModel: InProgressViewModel;

    constructor(projectId: string) {
        super({});

        this.unassignedTasksViewModel = new UnassignedTasksViewModel(projectId);
        this.inProgressViewModel = new InProgressViewModel();

        makeObservable(this, {
            showAdditionalInformation: observable,
            displayName: computed,
        });

        const _ = this.apiClient.sendAsync(new GETTaskProgrammingByIdEndpoint(projectId, this));
    }

    public get KEY() {
        return this.model.KEY;
    }

    public get displayName() {
        return this.showAdditionalInformation ? "- Programming" : "+ Programming";
    }

    // #region Commands

    public toggleAdditionalInformationCommand = new RelayCommand(() => {
        this.showAdditionalInformation = !this.showAdditionalInformation;
    });

    // #endregion Commands
}
