import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

import { Validator } from "Application/Validation";

export class QuoteLiteModel extends ModelBase<QuoteLiteModel> {
    // This is not part of the form model, but used for validation.
    public id: string | null = null;
    public title: string | null = null;
    public projectId: string | null = null;
    public projectTypeId: string | null = null;
    public quoteValue: number | null = null;
    public isProject: boolean | null = null;
    public customerId: string | null = null;
    public customerBusinessName: string | null = null;
    public quoteConvertedToProjectDate: Date | null = null;
    constructor() {
        super();

        makeObservable(this, {});
    }
}

export class QuoteLiteModelValidator extends Validator<QuoteLiteModel> {
    constructor() {
        super();
    }
}
