import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import React from "react";
import ReactHtmlParser from "react-html-parser";

import { AutoGrid, Box, ExpandableContainer, ThemedText } from "Application";
import { TermsAndConditionsViewModel } from "./TermsAndConditionsViewModel";
import { FormSeparator } from "Views/Shared/SharedComponents";
import useResizeObserver from "Application/Hooks/UseResizeObserver";

export interface ITermsAndConditionsSubViewReadOnlyProps {
    viewModel: TermsAndConditionsViewModel;
}
const TermsAndConditionsViewContainer = styled(Box)`
    border: 1px solid #dcdcdc;
    height: 400px;
    margin-bottom: 20px;
    overflow-y: auto;

    background-color: white;
    font-size: 12px;
    line-height: 1.75;
    padding: 30px;

    p {
        padding-bottom: 15px;
    }

    ul {
        padding-left: 15px;
        padding-bottom: 15px;
    }
`;

const SignatoryContainer = styled(Box)`
    background: #fcfcfc 0 0 no-repeat padding-box;
    box-shadow: 0 2px 3px #00000029;
    border-radius: 1px;
    margin-bottom: 20px;
    padding: 8px 15px;
`;

const SignatoryCell = styled(Box)`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const TermsAndConditionsViewReadOnlyView: React.FC<ITermsAndConditionsSubViewReadOnlyProps> = observer((props) => {
    const { ref, observedHeight } = useResizeObserver(90);
    const viewModel = props.viewModel;

    const renderTermsAndConditions = () => {
        return (
            <div ref={ref}>
                <TermsAndConditionsViewContainer>
                    <div>{ReactHtmlParser(viewModel.model.termsAndConditionsDetails as string)}</div>
                </TermsAndConditionsViewContainer>

                <ThemedText fontStyle={"expandable"} marginBottom={"15px"} marginTop={"15px"}>
                    Signatory
                </ThemedText>

                <SignatoryContainer>
                    <AutoGrid m={"0"} dc={"1fr"}>
                        <SignatoryCell>{<ThemedText fontStyle={"customerBarLabel"}>{viewModel.signatoryUserName}</ThemedText>}</SignatoryCell>
                    </AutoGrid>
                </SignatoryContainer>
            </div>
        );
    };

    return (
        <React.Fragment>
            {viewModel.canDisplayForQuickQuote && <></>}
            {!viewModel.canDisplayForQuickQuote && (
                <ExpandableContainer displayName="Terms & Conditions" childrenHeight={observedHeight} openByDefault={false}>
                    {renderTermsAndConditions()}
                </ExpandableContainer>
            )}
        </React.Fragment>
    );
});
