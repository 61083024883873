import { runInAction } from "mobx";

import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { ProjectDetailsResponse } from "./Shared/ProjectDetailsResponse";
import { ProjectDetailsViewModel } from "../ProjectDetailsViewModel";
import { ProjectDetailsModel } from "../ProjectDetailsModel";

class Request {
    id: string = "";
    customerContactId: string = "";
}

export class POSTSaveCustomerContactEndpoint extends Endpoint<Request, ProjectDetailsResponse> {
    private readonly viewModel: ProjectDetailsViewModel;

    constructor(viewModel: ProjectDetailsViewModel) {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Projects.SaveProjectCustomerContact);
        this.viewModel = viewModel;
    }

    public async HandleRequestAsync(model: ProjectDetailsModel): Promise<any> {
        const request = new Request();

        // The project id comes from the top-level project viewmodel.
        request.id = this.viewModel.parentViewModel.model.id;
        request.customerContactId = model.customer.customerContactId!;

        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: ProjectDetailsResponse): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            ProjectDetailsResponse.customerResponsePart(response, this.viewModel);
            ProjectDetailsResponse.notesResponsePart(response, this.viewModel);
            ProjectDetailsResponse.remainingResponsePart(response, this.viewModel);
        });
    }
}
