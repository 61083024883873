import { observer } from "mobx-react-lite";
import React, { useState } from "react";

import { ThemedError } from "Styles/ThemedPrimitives/Style1/ThemedError";
import { ThemedLoader } from "Styles/ThemedPrimitives/Style1/ThemedLoader";
import { CustomerReadOnlyView } from "Views/Shared/Customer/CustomerReadOnlyView";
import { LetterReadOnlyView } from "Views/Shared/Letter/LetterReadOnlyView";
import { TermsAndConditionsViewReadOnlyView } from "Views/Shared/TermsAndConditions/TermsAndConditionsViewReadOnlyView";
import { ServicesReadOnlyView } from "Views/Shared/Services/ServiceSubViews/ServicesReadOnlyView";
import { QuoteReadOnlyView } from "Views/Shared/Quote/QuoteReadOnlyView";
import { QuoteHistoryViewModel } from "./QuoteHistoryViewModel";
import { FormContainer, FormSubContainer } from "../../../../Shared/SharedComponents";

export interface IQuoteHistoryViewProps {
    quoteHistoryId: string;
}

export const QuoteHistoryView: React.FC<IQuoteHistoryViewProps> = observer((props) => {
    // #region Code behind

    const [viewModel] = useState(() => new QuoteHistoryViewModel(props.quoteHistoryId));

    // #endregion Code behind

    const renderBusy = () => <ThemedLoader isOpen={viewModel.apiClient.IsBusy} />;

    const renderError = () => {
        const isOpen = viewModel.apiClient.IsSubmitted && !viewModel.apiClient.IsBusy && viewModel.apiClient.HaveValidationMessage;

        return <ThemedError command={viewModel.resetServerErrorCommand} isOpen={isOpen} errorMessage={viewModel.apiClient.ValidationMessage} />;
    };

    return (
        <FormContainer>
            <FormSubContainer>
                <QuoteReadOnlyView viewModel={viewModel.quoteViewModel} />
                <CustomerReadOnlyView viewModel={viewModel.customerViewModel} />
                <LetterReadOnlyView viewModel={viewModel.letterViewModel} />
                <ServicesReadOnlyView viewModel={viewModel.servicesViewModel} />
                <TermsAndConditionsViewReadOnlyView viewModel={viewModel.termsAndConditionsViewModel} />
            </FormSubContainer>

            {/* Busy Overlay */}
            {renderBusy()}

            {/* Error Overlay */}
            {renderError()}
        </FormContainer>
    );
});
