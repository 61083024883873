import styled from "@emotion/styled";
import {
    shadow,
    ShadowProps,
    position,
    PositionProps,
    border,
    BorderProps,
    background,
    BackgroundProps,
    grid,
    GridProps,
    color,
    ColorProps,
    flexbox,
    FlexboxProps,
    layout,
    LayoutProps,
    space,
    SpaceProps,
    typography,
    TypographyProps,
    gridArea,
} from "styled-system";
import React, { DOMAttributes, PropsWithChildren, useEffect, useState } from "react";
import { action, autorun, computed, makeObservable, observable } from "mobx";
import { CoreStoreInstance, isNullOrUndefined, ViewModelBase } from "@shoothill/core";
import { Logger } from "../../index";
import { debounce, throttle } from "lodash-es";

interface AutoBoxGridProps {
    mc: string; //Mobile columns
    tc: string; //Tablet columns
    dc: string; //Desktop columns
    mro: string; //Mobile rows
    tr: string; //Tablet rows
    dr: string; //Desktop rows
    gridAutoColumns: string; //Grid auto columns
    ma: string[]; //Mobile areas
    ta: string[]; //Tablet areas
    da: string[]; //Desktop areas
    areaName: string;
    columnGap: string | number;
    rowGap: string | number;
    dcs: string;
    drs: string;
    tcs: string;
    trs: string;
    mcs: string;
    mrs: string;
    gap?: string | number;
}
export type BoxProps =
    | BorderProps
    | ShadowProps
    | PositionProps
    | BackgroundProps
    | GridProps
    | DOMAttributes<any>
    | (ColorProps & LayoutProps & SpaceProps & TypographyProps & FlexboxProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>);
export const InternalBox = styled.div<BoxProps>`
    ${color};
    ${layout};
    ${space};
    ${typography};
    ${flexbox};
    ${grid};
    ${background};
    ${border};
    ${position};
    ${shadow};
`;

type Props = {
    id?: string;
    center?: boolean;
    flexBox?: boolean;
    grid?: boolean;
    showIf?: boolean | undefined | null;
} & BoxProps &
    Partial<AutoBoxGridProps>;

const formatAreas = (areas: string[]) => {
    if (areas && areas.length > 0) {
        return areas.map((area) => `"${area}"`).join(" ");
    }
    return "";
};

export const Box: React.FC<PropsWithChildren<Props>> = (props: PropsWithChildren<Props>) => {
    //let { children,  ...rest } = props;
    let { children, dc, tc, mc, dr, tr, mro, da, ta, ma, columnGap, areaName, gap, rowGap, dcs, drs, tcs, trs, mcs, mrs, ...rest } = props;
    const viewModel = new BoxViewModel(props);

    //EN: Used to renrender the component when the view changes
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth,
    });

    useEffect(() => {
        const debouncedHandleResize = throttle(function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth,
            });
        }, 1000);
        window.addEventListener("resize", debouncedHandleResize);
        return () => {
            window.removeEventListener("resize", debouncedHandleResize);
        };
    }, []);

    /*let styles = {};
    if (props.center) styles = { display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center" };
    if (props.flexBox) styles = { display: "flex" };
    if (props.grid) styles = { display: "grid", gridTemplateColumns: dc, gridTemplateRows: dr, gridRowGap: props.rowGap, gridColumnGao: columnGap };

    tc = tc || dc;*/

    return (
        <>
            {viewModel.showIf && (
                <InternalBox style={viewModel.styles} {...rest}>
                    {children}
                </InternalBox>
            )}
        </>
    );
};
Box.defaultProps = {
    dc: "1fr 1fr",
    mc: "1fr",
};

class BoxViewModel extends ViewModelBase {
    public columns: string = "";
    public rows: string = "";
    public rowGap: string = "";
    public columnGap: string = "";
    public gap: string = "";
    public area: string = "";
    public areaName: string = "";
    public view: string = "";
    public dcs: string = "";
    public drs: string = "";
    public tcs: string = "";
    public trs: string = "";
    public mcs: string = "";
    public mrs: string = "";
    public renderComplete: boolean = false;
    public showIf: boolean = true;
    public props: Props;

    constructor(props: Props) {
        super({}, false, false);

        makeObservable(this, {
            columns: observable,
            rows: observable,
            area: observable,
            rowGap: observable,
            columnGap: observable,
            dcs: observable,
            drs: observable,
            tcs: observable,
            trs: observable,
            mcs: observable,
            mrs: observable,
            gap: observable,
            areaName: observable,
            view: observable,
            renderComplete: observable,
            styles: computed,
            setCompleted: action,
            showIf: observable,
        });
        this.props = props;
        /*this.columns = props.dc as string;
        this.rows = props.dr as string;
        this.areas = formatAreas(props.da as string[]);
        this.rowGap = (props.rowGap as string) || "30px";
        this.columnGap = (props.columnGap as string) || "30px";
        this.span = props.span as number;*/

        if (isNullOrUndefined(props.showIf)) {
            this.showIf = true;
        } else {
            this.showIf = props.showIf as boolean;
        }

        //By default make tablet the same as desktop
        this.areaName = props.areaName as string;

        let desktopColumns = props.dc || "1fr 1fr";
        let tabletColumns = props.tc || desktopColumns;
        let mobileColumns = props.mc || "1fr";

        this.rowGap = (props.rowGap as string) || "15px";
        this.columnGap = (props.columnGap as string) || "30px";
        this.gap = (props.gap as string) || "0";

        this.dcs = (props.dcs as string) || "";
        this.drs = (props.drs as string) || "";
        this.tcs = (props.dcs as string) || "";
        this.trs = (props.drs as string) || "";
        this.mcs = (props.dcs as string) || "";
        this.mrs = (props.drs as string) || "";

        if (this.isMobile) {
            this.columns = mobileColumns;
            this.rows = props.mro!;
            this.area = formatAreas(props.ma as []);
            this.view = "mobile";
        } else if (this.isTablet) {
            this.columns = tabletColumns!;
            this.rows = props.tr!;
            this.area = formatAreas(props.ta as []);
            this.view = "tablet";
        } else if (this.isDesktop) {
            this.columns = desktopColumns!;
            this.rows = props.dr!;
            this.area = formatAreas(props.da as []);
            this.view = "desktop";
        } else {
            this.columns = desktopColumns!;
            this.rows = props.dr!;
            this.area = formatAreas(props.da as []);
            this.view = "desktop";
        }
    }

    public setCompleted() {
        this.renderComplete = true;
    }

    public get styles() {
        let retval = {};
        if (this.props.flexBox) {
            retval = { display: "flex" };
        } else if (this.props.center) {
            retval = { display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center" };
        } else if (this.props.grid) {
            retval = {
                display: "grid",
                gridTemplateAreas: this.area,
                gridTemplateColumns: this.columns,
                gridTemplateRows: this.rows,
                gridRowGap: this.rowGap,
                gridColumnGap: this.columnGap,
            };
        }
        //Put this on anyway as it doesn't matter if it is flex or grid
        if (this.areaName) {
            retval = { gridArea: this.areaName, ...retval };
        }
        if (this.dcs) {
            retval = { gridColumn: `span ${this.dcs}`, ...retval };
        }
        if (this.drs) {
            retval = { gridRow: `span ${this.drs}`, ...retval };
        }
        if (this.tcs) {
            retval = { gridColumn: `span ${this.tcs}`, ...retval };
        }
        if (this.trs) {
            retval = { gridRow: `span ${this.trs}`, ...retval };
        }
        if (this.mcs) {
            retval = { gridColumn: `span ${this.mcs}`, ...retval };
        }
        if (this.mrs) {
            retval = { gridRow: `span ${this.mrs}`, ...retval };
        }
        this.setCompleted();
        return retval;
    }
}
