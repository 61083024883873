import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

export class SentQuoteModel extends ModelBase<SentQuoteModel> {
    public id: string = "";
    public reference: string = "";
    public title: string = "";

    constructor() {
        super();

        makeObservable(this, {
            // Observables
            id: observable,
            reference: observable,
            title: observable,
        });
    }
}
