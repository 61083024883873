import styled from "@emotion/styled";
import { darken, lighten } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";

import { Box, ThemedTransparentIconButton } from "Application";
import { CrossSVG } from "Assets/CrossSVG";
import { EditSVG } from "Assets/EditSVG";
import { LeaveRequestEventViewModel } from "./LeaveRequestEventViewModel";

interface IProps {
    viewModel: LeaveRequestEventViewModel;
}

const EventDisplayName = styled(Box)`
    font-size: 10px;
    font-weight: bold;
    padding: 7px 12px 3px 7px;
`;

const EventDisplayStatusName = styled(Box)`
    font-size: 10px;
    padding: 0px 12px 0px 7px;
`;

export const LeaveRequestEventView: React.FC<IProps> = observer((props) => {
    const viewModel = props.viewModel;

    const EventCell = styled(Box)`
        background: ${viewModel.isPendingRequest
            ? `repeating-linear-gradient(
                45deg,
                ${viewModel.backgroundColor},
                ${viewModel.backgroundColor} 10px,
                ${lighten(viewModel.backgroundColor, 0.5)} 10px,
                ${lighten(viewModel.backgroundColor, 0.5)} 20px
            )`
            : viewModel.backgroundColor};
        border-left: 5px solid ${darken(viewModel.backgroundColor, 0.25)};
        display: flex;
        flex: 1;
    `;

    const EditCommandButton = styled(ThemedTransparentIconButton)`
        height: 24px;
        width: 24px;
        g {
            stroke: ${darken(viewModel.backgroundColor, 0.33)};
        }
    `;

    const CancelCommandButton = styled(ThemedTransparentIconButton)`
        height: 24px;
        width: 24px;
        svg {
            scale: 80%;
        }
        g {
            stroke: ${darken(viewModel.backgroundColor, 0.33)};
        }
    `;

    return (
        <EventCell>
            <Box flex={1}>
                <EventDisplayName>{viewModel.displayName}</EventDisplayName>
                <EventDisplayStatusName>
                    {viewModel.displayStatusName} {viewModel.displayNumberOFDays}
                </EventDisplayStatusName>
            </Box>
            <Box display="flex">
                {viewModel.editLeaveCommand.canExecute() && <EditCommandButton command={props.viewModel.editLeaveCommand} icon={<EditSVG />} />}
                {viewModel.deleteLeaveCommand.canExecute() && <CancelCommandButton command={props.viewModel.deleteLeaveCommand} icon={<CrossSVG />} />}
                {viewModel.cancelLeaveCommand.canExecute() && <CancelCommandButton command={props.viewModel.cancelLeaveCommand} icon={<CrossSVG />} />}
            </Box>
        </EventCell>
    );
});
