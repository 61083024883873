import _ from "lodash";

import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { QuoteContainerModel } from "../QuoteContainerModel";
import { QuoteContainerViewModel } from "../QuoteContainerViewModel";

class Request {
    id: string | null = null;
}

class Response {
    id: string = "";
}
export class POSTSaveQuoteAsProjectEndpoint extends Endpoint<Request, Response> {
    private readonly viewModel: QuoteContainerViewModel;

    constructor(viewModel: QuoteContainerViewModel) {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Quotes.SaveQuoteAsProject);
        this.viewModel = viewModel;
    }

    public async HandleRequestAsync(model: QuoteContainerModel): Promise<any> {
        const request = new Request();

        request.id = model.quoteForm.id;

        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);
        this.viewModel.history.push(AppUrls.Client.Projects.Details.replace(":id", response.id));
    }
}
