import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import { Box, Nullcommand, theme, ThemedButton, ThemedCheckBox, ThemedDefaultEditText, ThemedEditText, ThemedText } from "Application";
import { WeeklyUpdateViewModel as WeeklyUpdateViewModel } from "./WeeklyUpdateViewModel";
import { FormContainerRow, FormSeparator, PrimaryTableDottedSeparator, PrimaryTableSeparator } from "Views/Shared/SharedComponents";
import { StaffDetailsItemViewModel } from "../../StaffDetailsItemViewModel";
import { StaffDetailsViewModel } from "../../StaffDetailsViewModel";
import { Modal } from "@fluentui/react";
import { ProjectWeeklyUpdateContainerView } from "./ProjectWeeklyUpdate/ProjectWeeklyUpdateContainerView";
import { CustomSidePanel } from "Views/Shared/CustomSidePanel/CustomSidePanel";
import { LinkButton } from "Components/General/LinkButton";

interface IProps {
    projectId: string;
    staffDetailsItemViewModel: StaffDetailsItemViewModel;
    parentViewModel: StaffDetailsViewModel;
}

export const WeeklyUpdateView: React.FC<IProps> = observer((props: IProps) => {
    // #region Code Behind
    useEffect(() => {
        viewModel.getWeeklyUpdate();
    }, [props.parentViewModel.model.renderCount]);

    const [viewModel] = useState(() => new WeeklyUpdateViewModel(props.projectId, props.staffDetailsItemViewModel, props.parentViewModel));

    /**
     * Handles an onClick event for a title.
     */
    const onTitleClick = () => {
        viewModel.toggleAdditionalInformationCommand.execute();
    };

    // #endregion Code Behind

    const renderTaskPanel = () => {
        return (
            <CustomSidePanel isOpen={viewModel.canDisplayProjectWeeklyUpdatesSidePanel} overlayStyles={{ width: "475px" }}>
                <ProjectWeeklyUpdateContainerView viewModel={viewModel.projectWeeklyUpdateContainerViewModel!} />
            </CustomSidePanel>
        );
    };

    const renderUpdate = () => {
        switch (true) {
            case viewModel.canDisplayNoUpdateAvailable:
                return (
                    <Box mt="10px" mb="10px">
                        <ThemedText>There is no update available for this programming week</ThemedText>
                    </Box>
                );

            case viewModel.canDisplayReadonly:
                return (
                    <Box mt="10px" mb="10px">
                        <FormContainerRow dc={"100"} mb="0px !important" rowGap={"5px"} marginBottom={"10px"}>
                            <ThemedText fontStyle="headerOptionLabel">Customer Note</ThemedText>
                            <Box backgroundColor={theme.palette.disabled.main} padding={"10px"}>
                                <ThemedText>{viewModel.getValue("updateDetail")}</ThemedText>
                            </Box>
                        </FormContainerRow>
                        <FormContainerRow dc={"100"} mb="0px !important" rowGap={"5px"}>
                            <ThemedText fontStyle="headerOptionLabel">Internal Note</ThemedText>
                            <Box backgroundColor={theme.palette.disabled.main} padding={"10px"}>
                                <ThemedText>{viewModel.getValue("internalDetail")}</ThemedText>
                            </Box>
                        </FormContainerRow>
                        <FormSeparator />
                        <FormContainerRow dc={"100%"} mb="0px !important">
                            <ThemedText fontStyle="h6">{viewModel.updateDetails}</ThemedText>
                        </FormContainerRow>
                    </Box>
                );

            default:
                return (
                    <Box mt="5px" mb="15px">
                        <Box display={"grid"} gridTemplateColumns={"49.5% 49.5%"} marginBottom={"20px"} style={{ gap: "10px 1%" }} padding="10px 0px">
                            <Box>
                                <ThemedText fontStyle="label" styles={{ root: { fontWeight: 600, marginBottom: 5 } }}>
                                    Customer Update
                                </ThemedText>
                                <ThemedDefaultEditText
                                    command={viewModel.updateDetailCommand}
                                    isMultiline={true}
                                    styles={{ fieldGroup: { minHeight: 207 } }}
                                    placeholder="Enter your weekly update here"
                                    validationMessage={() => viewModel.getError("updateDetail")}
                                    value={() => viewModel.getValue("updateDetail") ?? ""}
                                />
                                <LinkButton
                                    paletteColor={"default"}
                                    displayName="Copy last week's customer update"
                                    command={viewModel.copyPreviousProjectUpdateUpdateDetailCommand}
                                />
                                <LinkButton
                                    paletteColor={"default"}
                                    displayName="View previous weekly updates"
                                    command={viewModel.openPreviousWeeklyUpdatesSidePanelDetailCommand}
                                />
                            </Box>
                            <Box>
                                <ThemedText fontStyle="label" styles={{ root: { fontWeight: 600, marginBottom: 5 } }}>
                                    Internal Note
                                </ThemedText>
                                <ThemedDefaultEditText
                                    command={viewModel.updateInternalDetailCommand}
                                    isMultiline={true}
                                    styles={{ fieldGroup: { minHeight: 60 } }}
                                    placeholder="Add a note for internal use"
                                    validationMessage={() => viewModel.getError("internalDetail")}
                                    value={() => viewModel.getValue("internalDetail") ?? ""}
                                />
                                <ThemedText fontStyle="label" styles={{ root: { fontWeight: 600, marginBottom: 5, marginTop: 5 } }}>
                                    Key Information
                                </ThemedText>
                                <ThemedDefaultEditText
                                    command={viewModel.updateKeyInformationCommand}
                                    isMultiline={true}
                                    styles={{ fieldGroup: { minHeight: 120 } }}
                                    placeholder="Add information about the project"
                                    value={() => viewModel.getValue("keyInformation") ?? ""}
                                />
                                <LinkButton
                                    paletteColor={"default"}
                                    displayName="Copy last week's internal note"
                                    command={viewModel.copyPreviousProjectUpdateInternalDetailCommand}
                                />
                            </Box>
                        </Box>
                        {viewModel.canDisplayUpdateDetails && (
                            <FormContainerRow dc={"100%"}>
                                <ThemedText fontStyle="h6">{viewModel.updateDetails}</ThemedText>
                            </FormContainerRow>
                        )}
                        <FormContainerRow dc={"140px 140px"} mb={"7.5px !important"}>
                            <ThemedButton styles={{ root: { padding: "0 5px" } }} paletteColor={"primary"} displayName="Save changes" command={viewModel.onSubmitCommand} />
                            <ThemedCheckBox
                                command={viewModel.updateSendUpdateCommand}
                                displayName="Send update"
                                value={() => viewModel.getValue("sendUpdate")}
                                paletteColor="secondary"
                            />
                        </FormContainerRow>
                    </Box>
                );
        }
    };

    return (
        <Box>
            <Box onClick={onTitleClick} display="flex" flexDirection="column" minHeight="40px" style={{ cursor: "pointer" }}>
                <Box display="flex" flex="1" alignItems="center">
                    <ThemedText fontStyle="expandable">{viewModel.displayName}</ThemedText>
                </Box>
                <PrimaryTableDottedSeparator />
            </Box>
            {viewModel.showAdditionalInformation && (
                <Box>
                    {renderUpdate()}
                    <PrimaryTableSeparator />
                </Box>
            )}
            {renderTaskPanel()}
        </Box>
    );
});
