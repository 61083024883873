import _ from "lodash";
import { runInAction } from "mobx";

import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { EnquiryModel } from "./EnquiryModel";
import { EnquiryViewModel } from "./EnquiryViewModel";
import { EnquiryResponse } from "./Shared/EnquiryResponse";

class Request {
    id: string = "";
    enquiryStatusId: string | null = null;
}

export class POSTSaveEnquiryStatusEndpoint extends Endpoint<Request, Response> {
    private readonly viewModel: EnquiryViewModel;

    constructor(viewModel: EnquiryViewModel) {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Enquiries.SaveEnquiryStatus);
        this.viewModel = viewModel;
    }

    public async HandleRequestAsync(model: EnquiryModel): Promise<any> {
        const request = new Request();

        // APM: TODO.
        //request.id = this.viewModel.model.id!;
        //request.enquiryStatusId = this.viewModel.enquirySubViewModel.model.enquiryStatusId;
        request.id = model.id!;
        request.enquiryStatusId = model.enquirySubModel.enquiryStatusId;

        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: EnquiryResponse): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            EnquiryResponse.subEnquiryResponsePart(response, this.viewModel);
            EnquiryResponse.customerResponsePart(response, this.viewModel);
            EnquiryResponse.notesResponsePart(response, this.viewModel);
            EnquiryResponse.remainingResponsePart(response, this.viewModel);
        });
    }
}
