import { ViewModelBase } from "@shoothill/core";
import { computed, makeObservable, observable } from "mobx";

import { DownloadCalloutModel } from "./DownloadCalloutModel";
import { DownloadCalloutItemViewModel } from "./DownloadCalloutItemViewModel";

export class DownloadCalloutViewModel extends ViewModelBase<DownloadCalloutModel> {
    public items = observable<DownloadCalloutItemViewModel>([]);

    constructor(items: DownloadCalloutItemViewModel[]) {
        super(new DownloadCalloutModel(), false);

        this.items.replace(items);

        makeObservable(this, {
            canDisplayDownloadOptions: computed,
        });
    }

    public get canDisplayDownloadOptions() {
        return this.items.some((itemViewModel) => itemViewModel.canDisplayDownloadOptions);
    }
}
