import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { nanoid } from "nanoid";

import { Validator } from "Application/Validation";
import { TaskAssignmentModel } from "./SubViews/TaskAssignmentModel";

export class TaskAssignmentGroupModel extends ModelBase<TaskAssignmentGroupModel> {
    public KEY: string = nanoid();

    public id: string | null = null;
    public name: string | null = null;
    public note: string | null = null;
    public projectId: string | null = null;
    public userId: string | null = null;
    public taskAssignments = observable<TaskAssignmentModel>([]);

    constructor() {
        super();

        makeObservable(this, {
            // Observables
            name: observable,
            note: observable,
            taskAssignments: observable,
        });
    }
}

export class TaskAssignmentGroupModelValidator extends Validator<TaskAssignmentGroupModel> {
    constructor() {
        super();

        this.ruleFor("note").notNull().withMessage("Please enter a note").notEmpty().withMessage("Please enter a note");
    }
}
