import { FieldType, isEmptyOrWhitespace, isNullOrUndefined, ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

import { formatDate, formatTime, ICommand, RelayCommand } from "Application";
import { WeeklyUpdateFormModel, WeeklyUpdateFormModelValidator } from "./WeeklyUpdateFormModel";
import { isNull } from "lodash-es";

export class WeeklyUpdateFormViewModel extends ViewModelBase<WeeklyUpdateFormModel> {
    private parentCopyPreviousProjectUpdateUpdateDetailCommand: ICommand;
    private parentCopyPreviousProjectUpdateInternalDetailCommand: ICommand;
    private parentSaveUpdateCommand: ICommand;

    constructor(
        parentCopyPreviousProjectUpdateUpdateDetailCommand: ICommand,
        parentCopyPreviousProjectUpdateInternalDetailCommand: ICommand,
        parentSaveUpdateCommand: ICommand,
        weeklyUpdateFormModel: WeeklyUpdateFormModel = new WeeklyUpdateFormModel(),
    ) {
        super(weeklyUpdateFormModel);

        this.setValidator(new WeeklyUpdateFormModelValidator());

        this.parentCopyPreviousProjectUpdateUpdateDetailCommand = parentCopyPreviousProjectUpdateUpdateDetailCommand;
        this.parentCopyPreviousProjectUpdateInternalDetailCommand = parentCopyPreviousProjectUpdateInternalDetailCommand;
        this.parentSaveUpdateCommand = parentSaveUpdateCommand;

        makeObservable(this, {
            // Observables
        });
    }

    // #region Properties

    public get KEY() {
        return this.model.KEY;
    }

    public get updateDetails() {
        const date = !isNullOrUndefined(this.model.updatedDate) ? this.model.updatedDate : this.model.createdDate;

        switch (true) {
            case !isNullOrUndefined(date):
                return `Last updated: ${formatDate(date!)} @ ${formatTime(date!)}`;

            default:
                return "";
        }
    }

    // #endregion Properties

    // #region Commands

    public updateDetailCommand = new RelayCommand((value: string) => {
        this.updateField("updateDetail", value);
    });

    public updateInternalDetailCommand = new RelayCommand((value: string) => {
        this.updateField("internalDetail", value);
    });

    public updateSendUpdateCommand = new RelayCommand(() => {
        this.updateField("sendUpdate", !this.model.sendUpdate);
    });

    public saveUpdateCommand = new RelayCommand(
        () => {
            if (this.isModelValid()) {
                this.parentSaveUpdateCommand.execute();
            }
        },
        () => this.parentSaveUpdateCommand.canExecute(),
    );

    public copyPreviousProjectUpdateUpdateDetailCommand = new RelayCommand(
        () => {
            this.parentCopyPreviousProjectUpdateUpdateDetailCommand.execute();
        },
        () => this.parentCopyPreviousProjectUpdateUpdateDetailCommand.canExecute(),
    );

    public copyPreviousProjectUpdateInternalDetailCommand = new RelayCommand(
        () => {
            this.parentCopyPreviousProjectUpdateInternalDetailCommand.execute();
        },
        () => this.parentCopyPreviousProjectUpdateInternalDetailCommand.canExecute(),
    );

    // #endregion Commands

    // #region Supporting

    private updateField(fieldName: keyof FieldType<WeeklyUpdateFormModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    // #endregion Supporting
}
