import { runInAction } from "mobx";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { InvoiceHistoryViewModel } from "../InvoiceHistory/InvoiceHistoryViewModel";
import moment from "moment";
import { InvoiceHistoryCapacityModel } from "../InvoiceHistory/InvoiceHistoryCapacity/InvoiceHistoryCapacityModel";
import { InvoiceForecastMonthCapacityOverrideModel } from "Views/Admin/Shared/InvoiceForecastMonthCapacityOverride/InvoiceForecastMonthCapacityOverrideModel";
import { InvoiceViewModel } from "../InvoiceViewModel";

class Response {
    public capacities: InvoiceForecastMonthCapacityResponse[] = [];
}

class InvoiceForecastMonthCapacityResponse {
    public month: Date = new Date();
    public invoiceForecastMonthCapacityOverride: InvoiceForecastMonthCapacityOverrideResponse = new InvoiceForecastMonthCapacityOverrideResponse();
}

class InvoiceForecastMonthCapacityOverrideResponse {
    public year: number = 0;
    public month: number = 0;
    public hours: number = 0;
    public value: number = 0;
    public isActive: boolean = false;
}

class Request {
    public financialYearFilter: Date = new Date();
}

export class GETAdminInvoicingForecastOverridesByFinancialYearEndpoint extends Endpoint<Request, Response> {
    private viewModel: InvoiceViewModel;

    constructor(viewModel: InvoiceViewModel) {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Admin.InvoiceForecast.GetInvoiceForecastOverridesByFinancialYear);
        this.viewModel = viewModel;
    }

    public async HandleRequestAsync(): Promise<any> {
        const request = new Request();
        request.financialYearFilter = moment(this.viewModel.model.financialYearFilter).toDate();
        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            const models: InvoiceHistoryCapacityModel[] = [];

            response.capacities.forEach((capacity) => {
                const model = new InvoiceHistoryCapacityModel();
                model.fromResponse(capacity);
                const overrideModel = new InvoiceForecastMonthCapacityOverrideModel();
                overrideModel.fromResponse(capacity.invoiceForecastMonthCapacityOverride);
                model.invoiceForecastCapacityOverride = overrideModel;
                models.push(model);
            });

            this.viewModel.model.invoiceHistoryModel.capacities.replace(models);
        });

        return Promise.resolve(response);
    }
}
