import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

export class UnassignedTasksModel extends ModelBase<UnassignedTasksModel> {
    // Filtering
    public filterKeyword: string = "";
    public filterProjectId: string | null = null;
    public filterProjectTaskGroupName: string | null = null;
    public filterProjectLeadId: string | null = null;

    // Sorting
    public sortKey: string = "reference"; // A property defined in the table column definitions.
    public sortDescending = false;

    constructor() {
        super();
        makeObservable(this, {
            // Observables
            filterKeyword: observable,
            filterProjectId: observable,
            filterProjectTaskGroupName: observable,
            filterProjectLeadId: observable,

            sortKey: observable,
            sortDescending: observable,
        });
    }
}
