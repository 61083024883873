import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import React from "react";

import { Box } from "Application";
import { WeekendEventViewModel } from "./WeekendEventViewModel";

interface IProps {
    viewModel: WeekendEventViewModel;
}

const EventCell = styled(Box)`
    background-color: #f8f8f8;
    border-left: 5px solid #f8f8f8;
    flex: 1;
`;

export const WeekendEventView: React.FC<IProps> = observer((props) => {
    return <EventCell />;
});
