import { ViewModelBase } from "@shoothill/core";
import { observable } from "mobx";

import { APIClient } from "Application";
import { CalloutInformationItemViewModel } from "./CalloutInformationItemViewModel";
import { CalloutInformationModel } from "./CalloutInformationModel";
import { GetUnprocessedTransactionProjectUpdates } from "./Endpoints/GetUnprocessedTransactionProjectUpdates";
import { GetFailedTransactionProjectUpdates } from "./Endpoints/GetFailedTransactionProjectUpdates";

export class CalloutInformationViewModel extends ViewModelBase<CalloutInformationModel> {
    public apiClient = new APIClient();
    public itemViewModels = observable<CalloutInformationItemViewModel>([]);

    constructor(model: CalloutInformationModel) {
        super(model);

        // Make API call to get the updates.
        switch (model.fieldName) {
            case "numberOfUpdatesNotProcessed":
                this.apiClient.sendAsync(new GetUnprocessedTransactionProjectUpdates(this));
                break;

            case "numberOfUpdatesFailed":
                this.apiClient.sendAsync(new GetFailedTransactionProjectUpdates(this));
                break;

            default:
                // Do nothing.
                break;
        }
    }

    // #region Boilerplate
    // #endregion Boilerplate
}
