import { Endpoint, EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";

import { UnassignedTasksItemModel } from "../UnassignedTasksItemModel";
import { Logger } from "index";
import { observable, runInAction } from "mobx";
import { UnassignedTasksItemViewModel } from "../UnassignedTasksItemViewModel";
import { UnassignedTasksViewModel } from "../UnassignedTasksViewModel";

class Request {
    projectTaskId: string = "";
    projectId: string = "";
}

class Response {
    public unassignedTasks: UnassignedTaskItemResponse[] = [];
}

class UnassignedTaskItemResponse {
    public id: string = "";
    public projectTaskName: string = "";
    public projectTaskIsBilledHourly: boolean = false;
    public anticipatedEndDate: Date | null = null;
    public remainingProjectTaskBillableEffort: number = 0;
}

export class POSTDeleteProjectTaskEndpoint extends Endpoint<Request, Response> {
    private viewModel: UnassignedTasksViewModel;
    constructor(viewModel: UnassignedTasksViewModel) {
        super();
        this.viewModel = viewModel;
        this.verb(Http.Post);
        this.path(AppUrls.Server.Projects.DeleteProjectTask);
    }

    HandleRequestAsync(): Promise<Request> {
        let request: Request = new Request();
        request.projectTaskId = this.viewModel.taskIdToDelete!;
        request.projectId = this.viewModel.model.projectId!;
        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        runInAction(() => {
            const unassignedTaskViewModels = observable<UnassignedTasksItemViewModel>([]);
            response.unassignedTasks.forEach((element: any) => {
                const model = new UnassignedTasksItemModel();
                model.fromResponse(element);
                unassignedTaskViewModels.push(new UnassignedTasksItemViewModel(model));
            });
            this.viewModel.tasks = unassignedTaskViewModels;
        });
    }
}
