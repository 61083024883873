import { makeObservable, observable } from "mobx";
import { AccountStore } from "./index";
import {
    ContactTitle,
    ContactType,
    EnquiryStatus,
    EnquiryType,
    ImageUrl,
    NoteType,
    QuoteStatus,
    ProjectType,
    ProjectTaskAssignmentStatus,
    BusinessContactType,
    SourceOfEnquiryType,
} from "Application/Models/Domain";
import { singleton, container } from "tsyringe";
import { Logger } from "index";
import { ProjectStatus } from "Application/Models/Domain/ProjectStatus";
import { SupplierType } from "Application/Models/Domain/SupplierType";
import { SupplierSubType } from "Application/Models/Domain/SupplierSubType";
import { SupplierContactType } from "Application/Models/Domain/SupplierContactType";
import { FinancialPeriodType } from "Application/Models/Domain/FinancialPeriodType";
import { ProjectTaskAssignmentTemplateNote } from "Views/Shared/ProjectTaskAssignmentTemplateNote/ProjectTaskAssignmentTemplateNoteModel";
import { TermsAndConditionsType } from "Application/Models/Domain/TermsAndConditionsType";
import { NoteSubType } from "Application/Models/Domain/NoteSubType";
import { LocalAuthorityDistrict } from "Application/Models/Domain/LocalAuthorityDistrict";
import { KeyValuePair } from "@shoothill/core";
import { PlanningApplicationStatus } from "Application/Models/Domain/PlanningApplicationStatus";
import { PlanningApplicationType } from "Application/Models/Domain/PlanningApplicationType";

@singleton()
export class LookupStore {
    public contactTitles = observable<ContactTitle>([]);
    public businessContactTypes = observable<BusinessContactType>([]);
    public contactTypes = observable<ContactType>([]);
    public enquiryStatuses = observable<EnquiryStatus>([]);
    public enquiryTypes = observable<EnquiryType>([]);
    public noteTypes = observable<NoteType>([]);
    public noteSubTypes = observable<NoteSubType>([]);
    public projectTypes = observable<ProjectType>([]);
    public projectTaskAssignmentStatuses = observable<ProjectTaskAssignmentStatus>([]);
    public projectTaskAssignmentTemplateNotes = observable<ProjectTaskAssignmentTemplateNote>([]);
    public quoteStatuses = observable<QuoteStatus>([]);
    public AccountStore = container.resolve(AccountStore);
    public projectStatuses = observable<ProjectStatus>([]);
    public imageURLs = observable<ImageUrl>([]);
    public supplierTypes = observable<SupplierType>([]);
    public supplierSubTypes = observable<SupplierSubType>([]);
    public supplierContactTypes = observable<SupplierContactType>([]);
    public financialPeriodTypes = observable<FinancialPeriodType>([]);
    public termsAndConditionsTypes = observable<TermsAndConditionsType>([]);
    public sourceOfEnquiryTypes = observable<SourceOfEnquiryType>([]);
    public localAuthorityDistricts = observable<LocalAuthorityDistrict>([]);
    public planningApplicationStatuses = observable<PlanningApplicationStatus>([]);
    public planningApplicationTypes = observable<PlanningApplicationType>([]);
    public financialYears = observable<number>([]);

    constructor() {
        Logger.logDebug("Domain store created");
        makeObservable(this, {
            contactTitles: observable,
            businessContactTypes: observable,
            contactTypes: observable,
            enquiryStatuses: observable,
            enquiryTypes: observable,
            noteTypes: observable,
            noteSubTypes: observable,
            quoteStatuses: observable,
            projectTypes: observable,
            projectTaskAssignmentStatuses: observable,
            projectTaskAssignmentTemplateNotes: observable,
            projectStatuses: observable,
            imageURLs: observable,
            supplierTypes: observable,
            supplierSubTypes: observable,
            supplierContactTypes: observable,
            financialPeriodTypes: observable,
            termsAndConditionsTypes: observable,
            sourceOfEnquiryTypes: observable,
            localAuthorityDistricts: observable,
            planningApplicationStatuses: observable,
            planningApplicationTypes: observable,
            financialYears: observable,
        });
        this.init();
    }

    public init = () => {
        this.contactTitles.clear();
        this.businessContactTypes.clear();
        this.contactTypes.clear();
        this.enquiryStatuses.clear();
        this.enquiryTypes.clear();
        this.noteTypes.clear();
        this.noteSubTypes.clear();
        this.projectTypes.clear();
        this.projectTaskAssignmentStatuses.clear();
        this.projectTaskAssignmentTemplateNotes.clear();
        this.quoteStatuses.clear();
        this.projectStatuses.clear();
        this.imageURLs.clear();
        this.supplierTypes.clear();
        this.supplierSubTypes.clear();
        this.supplierContactTypes.clear();
        this.projectStatuses.clear();
        this.financialPeriodTypes.clear();
        this.termsAndConditionsTypes.clear();
        this.sourceOfEnquiryTypes.clear();
        this.localAuthorityDistricts.clear();
        this.planningApplicationStatuses.clear();
        this.planningApplicationTypes.clear();
        this.financialYears.clear();

        if (this.AccountStore.initialState.contactTitles.length > 0) {
            this.AccountStore.initialState.contactTitles.forEach((contactTitle) => {
                this.contactTitles.push(contactTitle);
            });
        }

        if (this.AccountStore.initialState.businessContactTypes.length > 0) {
            this.AccountStore.initialState.businessContactTypes.forEach((businessContactType) => {
                this.businessContactTypes.push(businessContactType);
            });
        }

        if (this.AccountStore.initialState.contactTypes.length > 0) {
            this.AccountStore.initialState.contactTypes.forEach((contactType) => {
                this.contactTypes.push(contactType);
            });
        }
        if (this.AccountStore.initialState.enquiryStatuses.length > 0) {
            this.AccountStore.initialState.enquiryStatuses.forEach((enquiryStatus) => {
                this.enquiryStatuses.push(enquiryStatus);
            });
        }
        if (this.AccountStore.initialState.enquiryTypes.length > 0) {
            this.AccountStore.initialState.enquiryTypes.forEach((enquiryType) => {
                this.enquiryTypes.push(enquiryType);
            });
        }
        if (this.AccountStore.initialState.noteTypes.length > 0) {
            this.AccountStore.initialState.noteTypes.forEach((noteType) => {
                this.noteTypes.push(noteType);
            });
        }
        if (this.AccountStore.initialState.noteSubTypes.length > 0) {
            this.AccountStore.initialState.noteSubTypes.forEach((noteSubTypes) => {
                this.noteSubTypes.push(noteSubTypes);
            });
        }
        if (this.AccountStore.initialState.quoteStatuses.length > 0) {
            this.AccountStore.initialState.quoteStatuses.forEach((quoteStatus) => {
                this.quoteStatuses.push(quoteStatus);
            });
        }
        if (this.AccountStore.initialState.projectTypes.length > 0) {
            this.AccountStore.initialState.projectTypes.forEach((projectType) => {
                this.projectTypes.push(projectType);
            });
        }

        if (this.AccountStore.initialState.projectTaskAssignmentStatuses.length > 0) {
            this.AccountStore.initialState.projectTaskAssignmentStatuses.forEach((projectTaskAssignmentStatus) => {
                this.projectTaskAssignmentStatuses.push(projectTaskAssignmentStatus);
            });
        }

        if (this.AccountStore.initialState.projectTaskAssignmentTemplateNotes.length > 0) {
            this.AccountStore.initialState.projectTaskAssignmentTemplateNotes.forEach((model) => {
                this.projectTaskAssignmentTemplateNotes.push(model);
            });
        }

        if (this.AccountStore.initialState.projectStatuses.length > 0) {
            this.AccountStore.initialState.projectStatuses.forEach((projectStatus) => {
                this.projectStatuses.push(projectStatus);
            });
        }
        if (this.AccountStore.initialState.imageURLs.length > 0) {
            this.AccountStore.initialState.imageURLs.forEach((imageURLs) => {
                this.imageURLs.push(imageURLs);
            });
        }
        if (this.AccountStore.initialState.supplierTypes.length > 0) {
            this.AccountStore.initialState.supplierTypes.forEach((supplierType) => {
                this.supplierTypes.push(supplierType);
            });
        }
        if (this.AccountStore.initialState.supplierSubTypes.length > 0) {
            this.AccountStore.initialState.supplierSubTypes.forEach((supplierSubType) => {
                this.supplierSubTypes.push(supplierSubType);
            });
        }
        if (this.AccountStore.initialState.supplierContactTypes.length > 0) {
            this.AccountStore.initialState.supplierContactTypes.forEach((supplierContactTypes) => {
                this.supplierContactTypes.push(supplierContactTypes);
            });
        }
        if (this.AccountStore.initialState.financialPeriodTypes.length > 0) {
            this.AccountStore.initialState.financialPeriodTypes.forEach((financialPeriodType) => {
                this.financialPeriodTypes.push(financialPeriodType);
            });
        }
        if (this.AccountStore.initialState.termsAndConditionsTypes.length > 0) {
            this.AccountStore.initialState.termsAndConditionsTypes.forEach((type) => {
                this.termsAndConditionsTypes.push(type);
            });
        }

        if (this.AccountStore.initialState.sourceOfEnquiryTypes.length > 0) {
            this.AccountStore.initialState.sourceOfEnquiryTypes.forEach((type) => {
                this.sourceOfEnquiryTypes.push(type);
            });
        }

        if (this.AccountStore.initialState.financialYears.length > 0) {
            this.AccountStore.initialState.financialYears.forEach((financialYear) => {
                this.financialYears.push(financialYear);
            });
        }

        if (this.AccountStore.initialState.planningApplicationStatuses.length > 0) {
            this.AccountStore.initialState.planningApplicationStatuses.forEach((status) => {
                this.planningApplicationStatuses.push(status);
            });
        }

        if (this.AccountStore.initialState.planningApplicationTypes.length > 0) {
            this.AccountStore.initialState.planningApplicationTypes.forEach((type) => {
                this.planningApplicationTypes.push(type);
            });
        }

        if (this.AccountStore.initialState.localAuthorityDistricts.length > 0) {
            this.AccountStore.initialState.localAuthorityDistricts.forEach((localAuthorityDistrict) => {
                this.localAuthorityDistricts.push(localAuthorityDistrict);
            });
        }
    };

    public get getContactTypes() {
        return this.contactTypes.map((item: ContactType) => {
            return {
                key: item.id,
                text: item.name,
            };
        });
    }

    public getContactTypeIdByType(type: string): string {
        for (const contactType of this.contactTypes) {
            if (contactType.type === type) {
                return contactType.id;
            }
        }

        return "";
    }

    public get getPrefixes() {
        return this.contactTitles.map((item: ContactTitle) => {
            return {
                key: item.id,
                text: item.name,
            };
        });
    }

    public get getSupplierTypes() {
        return this.supplierTypes.map((item: SupplierType) => {
            return {
                key: item.id,
                text: item.name,
            };
        });
    }

    public getSupplierTypeIdByType(type: string): string {
        for (const supplierType of this.supplierTypes) {
            if (supplierType.type === type) {
                return supplierType.id;
            }
        }

        return "";
    }

    public get getSupplierSubTypes() {
        return this.supplierSubTypes.map((item: SupplierSubType) => {
            return {
                key: item.id,
                text: item.name,
                supplierTypeId: item.supplierTypeId,
            };
        });
    }

    public get getBusinessContactTypes() {
        return this.businessContactTypes.map((item: BusinessContactType) => {
            return {
                key: item.id,
                text: item.name,
            };
        });
    }

    public get getSupplierContactTypes() {
        return this.supplierContactTypes.map((item: SupplierContactType) => {
            return {
                key: item.id,
                text: item.name,
            };
        });
    }

    public getEnquiryStatuses() {
        return this.enquiryStatuses.map((item) => {
            return {
                key: item.id,
                text: item.name,
            };
        });
    }

    public getImageUrl() {
        return this.imageURLs.map((item: ImageUrl) => {
            return {
                text: item.documentUrl,
            };
        });
    }

    public getQuoteStatuses() {
        return this.quoteStatuses.map((item) => {
            return {
                key: item.id,
                text: item.name,
            };
        });
    }

    public getProjectStatuses() {
        return this.projectStatuses.map((item) => {
            return {
                key: item.id,
                text: item.name,
            };
        });
    }

    public getNoteTypeById(id: string): string {
        let retval = "";
        this.noteTypes.forEach((item) => {
            if (item.id === id) {
                retval = item.name;
            }
        });
        return retval;
    }

    public getNoteSubTypesByNoteTypeId(id: string) {
        return this.noteSubTypes
            .filter((item: NoteSubType) => item.noteTypeId == id)
            .map((item: NoteSubType) => {
                return {
                    key: item.id,
                    text: item.name,
                    supplierTypeId: item.noteTypeId,
                };
            });
    }

    public get getProjectTypes() {
        return this.projectTypes.map((item: ProjectType) => {
            return {
                key: item.id,
                text: item.name,
            };
        });
    }

    public get getFinancialPeriodTypes() {
        const retVal = this.financialPeriodTypes.slice().sort((a, b) => a.ordinal - b.ordinal);
        return retVal.map((item: FinancialPeriodType) => {
            return {
                key: item.id,
                text: item.name,
            };
        });
    }

    public get getFinancialYears() {
        const retVal = this.financialYears.slice().sort((a, b) => a - b);
        return retVal.map((item: number) => {
            return {
                key: item.toString(),
                text: item.toString(),
            };
        });
    }

    public get getProjectTaskAssignmentTemplateNotes() {
        const retVal = this.projectTaskAssignmentTemplateNotes.slice().sort((a, b) => a.ordinal - b.ordinal);
        return retVal.map((item: ProjectTaskAssignmentTemplateNote) => {
            return {
                key: item.id,
                text: item.name,
            };
        });
    }

    public getProjectTypeIdByType(type: string): string {
        for (const projectType of this.projectTypes) {
            if (projectType.type === type) {
                return projectType.id;
            }
        }

        return "";
    }

    public getNoteTypeIdByType(type: string): string {
        for (const noteType of this.noteTypes) {
            if (noteType.type === type) {
                return noteType.id;
            }
        }

        return "";
    }

    public getNoteTypes() {
        return this.noteTypes.map((item) => {
            return {
                key: item.id,
                text: item.name,
            };
        });
    }

    public getExternalNoteTypes() {
        return this.noteTypes
            .filter((item) => !item.isInternal)
            .map((item) => {
                return {
                    key: item.id,
                    text: item.name,
                };
            });
    }

    public get getTermsAndConditionsTypes() {
        const retVal = this.termsAndConditionsTypes.slice().sort((a, b) => a.ordinal - b.ordinal);
        return retVal.map((item: TermsAndConditionsType) => {
            return {
                key: item.id,
                text: item.name,
            };
        });
    }

    public get getSourceOfEnquiryTypes() {
        const retVal = this.sourceOfEnquiryTypes.slice().sort((a, b) => a.ordinal - b.ordinal);
        return retVal.map((item: SourceOfEnquiryType) => {
            return {
                key: item.id,
                text: item.name,
            };
        });
    }

    public getSourceOfEnquiryByType(type: string) {
        return this.sourceOfEnquiryTypes.find((t) => t.type === type);
    }

    public get localAuthorityDistrictKeyValuePairs(): KeyValuePair[] {
        return this.localAuthorityDistricts.map((item: LocalAuthorityDistrict) => {
            return {
                key: item.id,
                text: item.officialNameLocalAuthorityDistrict,
            };
        });
    }

    public get planningApplicationStatusKeyValuePairs() {
        return this.planningApplicationStatuses.map((item) => {
            return {
                key: item.id,
                text: item.name,
            };
        });
    }

    public get planningApplicationTypeKeyValuePairs() {
        return this.planningApplicationTypes.map((item) => {
            return {
                key: item.id,
                text: item.name,
            };
        });
    }
}
