//libraries
import { action, makeObservable, observable } from "mobx";
import moment from "moment";
//app
import { APIClient, RelayCommand, theme } from "Application";
import { FieldType, isEmptyOrWhitespace, isNullOrEmpty, isNullOrUndefined, ViewModelBase } from "@shoothill/core";
import { OverviewModel, OverviewValidator } from "./OverviewModel";
import { container } from "tsyringe";
import { LookupStore } from "Stores/Domain";
import dashboardBgImage from "Assets/dashboardBgImage@2x.png";
import { GETAdminRunningTotalsByGivenDatesEndpoint } from "../Endpoints/GETAdminRunningTotalsByGivenDatesEndpoint";
import {
    CURR_CALENDAR_MONTH,
    CURR_CALENDAR_QUARTER,
    CURR_CALENDAR_WEEK,
    CURR_CALENDAR_YEAR,
    CURR_FINANCIAL_YEAR,
    PREV_CALENDAR_MONTH,
    PREV_CALENDAR_QUARTER,
    PREV_CALENDAR_WEEK,
    PREV_CALENDAR_YEAR,
    PREV_FINANCIAL_YEAR,
} from "Views/Shared/Constants";
import { BarDatum } from "@nivo/bar";
import { GETTopProjectsAndQuotesEndpoint } from "../Endpoints/GETAdminTopQuotesAndProjectsEndpoints";
import { AbsenteesViewModel } from "./Absentees/AbsenteesViewModel";
import { GETAbsenteesByGivenDatesEndpoint } from "../Endpoints/GETAbsenteesByGivenDatesEndpoint";
import { QuoteLiteModel } from "Views/Shared/Quote/QuoteLiteModel";
import { ProjectLiteModel } from "Views/Shared/Project/ProjectLiteModel";
import { EnquiryLiteModel } from "Views/Shared/Enquiry/EnquiryLiteModel";

export class OverviewViewModel extends ViewModelBase<OverviewModel> {
    public apiClient = new APIClient();
    public locationPathName: string = "";
    public lookupStore = container.resolve(LookupStore);
    public absenteesViewModel: AbsenteesViewModel;
    public render: boolean = false;
    private domesticProjectType = this.lookupStore.projectTypes.find((type) => type.type == "Domestic");
    private commercialProjectType = this.lookupStore.projectTypes.find((type) => type.type == "Commercial");
    public backgroundImageURL: string = dashboardBgImage;
    public barChartData: BarDatum[] = [];

    constructor(model = new OverviewModel()) {
        super(model);
        this.absenteesViewModel = new AbsenteesViewModel();
        this.setValidator(new OverviewValidator());
        makeObservable(this, {
            //observables
            backgroundImageURL: observable,
            render: observable,
            barChartData: observable,
            //computed values
            //actions
            setBackgroundImageURL: action,
            setRender: action,
            setBarChartData: action,
        });

        this.init();
    }

    public init() {
        const startOfWeek = moment().startOf("week").add(1, "days");
        this.setValue("startDateFilter", startOfWeek.toDate());
        const endOfWeek = moment().endOf("week").add(-1, "days");
        this.setValue("endDateFilter", endOfWeek);
        this.setValue("financialPeriodTypeId", this.lookupStore.financialPeriodTypes.find((type) => type.type == CURR_CALENDAR_WEEK)?.id);
        //set background image
        this.setBackgroundImageURL();

        //get top projects and quotes
        this.getTopProjectsAndQuotes();
        //get admin overview data
        this.getAdminOverviewDetails();
    }

    // #region User Roles

    public get isAdmin() {
        return this.lookupStore.AccountStore.isInRole("admin");
    }

    public get isBusinessSupport() {
        return this.lookupStore.AccountStore.isInRole("businesssupport");
    }

    public get isSeniorAssociate() {
        return this.lookupStore.AccountStore.isInRole("seniorassociate");
    }

    public get isArchitect() {
        return this.lookupStore.AccountStore.isInRole("architect");
    }

    // #endregion User Roles

    // #region Display Permissions

    public get showInvoicingForecast() {
        return this.lookupStore.AccountStore.canViewInvoicingForecast;
    }

    public get showProjectsByValue() {
        return this.showInvoicingForecast === false;
    }

    // #endregion Display Permissions

    //region getters

    public get isLoading() {
        return this.apiClient.IsBusy;
    }

    public getTopProjectsAndQuotes = async () => {
        await this.apiClient.sendAsync(new GETTopProjectsAndQuotesEndpoint(this));
    };

    public getAdminOverviewDetails = async () => {
        this.setRender(false);
        await this.apiClient.sendAsync(new GETAdminRunningTotalsByGivenDatesEndpoint(this)).then(() => {
            this.setBarChartData();
            setTimeout(() => this.setRender(true), 500);
        });
    };

    public get userPersona() {
        return this.lookupStore.AccountStore.LoggedUserImageUrl;
    }

    public get showApplyDateFilterButton() {
        const retVal = isNullOrEmpty(this.model.financialPeriodTypeId);
        return retVal;
    }

    public get userFirstName() {
        return this.lookupStore.AccountStore.LoggedUserFirstName;
    }

    //return a maximum of five projects ordered by value
    public get topProjectsByValue() {
        return this.model.topProjectLites.slice().sort((a, b) => b.projectValue! - a.projectValue!);
    }

    //return a maximum of five projects ordered by value
    public get topQuotesByValue() {
        return this.model.topQuoteLites.slice().sort((a, b) => b.quoteValue! - a.quoteValue!);
    }

    public quoteDetailURL(model: QuoteLiteModel) {
        // this was passing the same id into project as quote and was 500'ing (silently) projectId corrected this.
        return model.isProject && model.projectId ? `/projects/quotehistory/${model.projectId}` : `/newbusiness/quote/${model.id}`;
    }

    public projectDetailURL(model: QuoteLiteModel) {
        return `/projects/details/${model.projectId}`;
    }

    public customerDetailURL(model: QuoteLiteModel | ProjectLiteModel) {
        return `/customer/details/${model.customerId}`;
    }

    public enquiryDetailURL(model: EnquiryLiteModel) {
        return `/newbusiness/enquiry/${model.id}`;
    }

    public get numberOfEnquiries() {
        return this.model.enquiryLites.length;
    }

    public get domesticEnquiries() {
        return this.model.enquiryLites.filter((e) => e.projectTypeId == this.domesticProjectType!.id);
    }

    public get numberOfDomesticEnquiries() {
        return this.domesticEnquiries.length;
    }

    get percentageOfDomesticEnquiries() {
        const retVal = this.numberOfDomesticEnquiries ? (this.numberOfDomesticEnquiries / this.numberOfEnquiries) * 100 : 0;
        return retVal.toFixed(1) + "%";
    }

    public get commercialEnquiries() {
        return this.model.enquiryLites.filter((e) => e.projectTypeId == this.commercialProjectType!.id);
    }

    public get numberOfCommercialEnquiries() {
        return this.commercialEnquiries.length;
    }

    public get percentageOfCommercialEnquiries() {
        const retVal = this.numberOfCommercialEnquiries ? (this.numberOfCommercialEnquiries / this.numberOfEnquiries) * 100 : 0;
        return retVal.toFixed(1) + "%";
    }

    public get sortedEnquiriesByCreatedDate() {
        return this.model.enquiryLites.slice().sort((a, b) => b.createdDate!.getTime() - a.createdDate!.getTime());
    }
    public get sortedCommercialEnquiriesByCreatedDate() {
        return this.commercialEnquiries.slice().sort((a, b) => b.createdDate!.getTime() - a.createdDate!.getTime());
    }
    public get sortedDomesticEnquiriesByCreatedDate() {
        return this.domesticEnquiries.slice().sort((a, b) => b.createdDate!.getTime() - a.createdDate!.getTime());
    }

    public get totalEnquiriesPieChartData(): any[] {
        const data = [
            {
                id: "domestic",
                label: "domestic",
                value: `${this.numberOfDomesticEnquiries}`,
                color: `${theme.palette.common.teal}`,
            },
            {
                id: "commercial",
                label: "commercial",
                value: `${this.numberOfCommercialEnquiries}`,
                color: `${theme.palette.common.purple}`,
            },
        ];
        return data;
    }

    public get totalQuotesValue() {
        return this.model.quoteLites.reduce((partial_sum, a) => partial_sum + a.quoteValue!, 0);
    }

    public get totalNumberOfQuotes() {
        return this.model.quoteLites.length;
    }

    public get domesticQuotes() {
        return this.model.quoteLites.filter((q) => q.projectTypeId == this.domesticProjectType!.id);
    }

    public get domesticQuotesValue() {
        return this.domesticQuotes.reduce((partial_sum, a) => partial_sum + a.quoteValue!, 0);
    }

    public get numberOfDomesticQuotes() {
        return this.domesticQuotes.length;
    }

    public get commercialQuotes() {
        return this.model.quoteLites.filter((q) => q.projectTypeId == this.commercialProjectType!.id);
    }

    public get commercialQuotesValue() {
        return this.commercialQuotes.reduce((partial_sum, a) => partial_sum + a.quoteValue!, 0);
    }

    public get numberOfCommercialQuotes() {
        return this.commercialQuotes.length;
    }

    public get sortedQuotesByValue() {
        return this.model.quoteLites.slice().sort((a, b) => b.quoteValue! - a.quoteValue!);
    }

    public get sortedCommercialQuotesByValue() {
        return this.commercialQuotes.slice().sort((a, b) => b.quoteValue! - a.quoteValue!);
    }

    public get sortedDomesticQuotesByValue() {
        return this.domesticQuotes.slice().sort((a, b) => b.quoteValue! - a.quoteValue!);
    }

    public get totalQuotesPieChartData(): any[] {
        const data = [
            {
                id: "domestic",
                label: "domestic",
                value: `${this.numberOfDomesticQuotes}`,
                color: `${theme.palette.common.teal}`,
            },
            {
                id: "commercial",
                label: "commercial",
                value: `${this.numberOfCommercialQuotes}`,
                color: `${theme.palette.common.purple}`,
            },
        ];
        return data;
    }

    //region work won

    public get totalWorkWonValue() {
        return this.model.workWonItems.reduce((partialSum, i) => partialSum + (i.quoteValue ?? 0), 0);
    }

    public get totalNumberOfWorkWonItems() {
        return this.model.workWonItems.length;
    }

    public get domesticWorkWonItems() {
        return this.model.workWonItems.filter((q) => q.projectTypeId == this.domesticProjectType!.id);
    }

    public get domesticWorkWonItemsValue() {
        return this.domesticWorkWonItems.reduce((partialSum, a) => partialSum + a.quoteValue!, 0);
    }

    public get numberOfDomesticWorkWonItems() {
        return this.domesticWorkWonItems.length;
    }

    public get commercialWorkWonItems() {
        return this.model.workWonItems.filter((q) => q.projectTypeId == this.commercialProjectType!.id);
    }

    public get commercialWorkWonItemsValue() {
        return this.commercialWorkWonItems.reduce((partial_sum, a) => partial_sum + a.quoteValue!, 0);
    }

    public get numberOfCommercialWorkWonItems() {
        return this.commercialWorkWonItems.length;
    }

    public get sortedWorkWonByValue() {
        return this.model.workWonItems.slice().sort((a, b) => b.quoteValue! - a.quoteValue!);
    }

    public get sortedCommercialWorkWonByValue() {
        return this.commercialWorkWonItems.slice().sort((a, b) => b.quoteValue! - a.quoteValue!);
    }

    public get sortedDomesticWorkWonByValue() {
        return this.domesticWorkWonItems.slice().sort((a, b) => b.quoteValue! - a.quoteValue!);
    }

    //end region work won

    public get totalWorkWonPieChartData(): any[] {
        const data = [
            {
                id: "domestic",
                label: "domestic",
                value: `${this.numberOfDomesticWorkWonItems}`,
                color: `${theme.palette.common.teal}`,
            },
            {
                id: "commercial",
                label: "commercial",
                value: `${this.numberOfCommercialWorkWonItems}`,
                color: `${theme.palette.common.purple}`,
            },
        ];
        return data;
    }

    public get salesFunnelData(): any[] {
        const data: any[] = [
            {
                id: "quotes",
                value: this.totalNumberOfQuotes,
                label: "Quotes",
                color: `${theme.palette.secondary.main}`,
            },
            {
                id: "workWon",
                value: this.totalNumberOfWorkWonItems,
                label: "Work Won",
                color: `${theme.palette.secondary.light}`,
            },
        ];

        return data;
    }

    public get quotesToWorkWonConversionPercentage() {
        const retVal = this.totalNumberOfWorkWonItems ? (this.totalNumberOfWorkWonItems / this.totalNumberOfQuotes) * 100 : 0;
        return retVal.toFixed(1) + "%";
    }

    // this formula prevents longer first name e.g Alexandru-Marian from breaking the UI.
    // dynamically adapt the font size in order to fit longer names
    // the name length is defined as a multiple of 5. If the name is not a multiple of 5 it's reduced to the closest previous multiple of 5. e.g 17 => 15
    // for every multiple factor larger than 15 the pixel size is reduced by 2 => pxSize = defaultPixelSize - multipleFactor * 2

    public get userNameFontSize() {
        let retVal: number = 18;
        if (this.userFirstName.length > 15) {
            const nameLengthMultipleFactor = (this.userFirstName.length - 15) % 5;
            retVal = retVal - nameLengthMultipleFactor * 2;
        }
        return retVal;
    }

    public get financialPeriodTypes() {
        const options = this.lookupStore.getFinancialPeriodTypes.map((item) => {
            return {
                key: item.key,
                text: item.text,
            };
        });

        return [...options, { key: "", text: "Custom" }];
    }

    //end region getters

    //region actions

    public setBackgroundImageURL() {
        const imagesUrlList = this.lookupStore.getImageUrl();

        // In the event that we have no images available, or the image object has no 'text'
        // property defined, we should fall back onto an application asset.
        const randomIndex = Math.floor(Math.random() * imagesUrlList.length);
        const randomImageUrl = imagesUrlList[randomIndex];
        if (randomImageUrl && !isEmptyOrWhitespace(randomImageUrl.text)) {
            this.backgroundImageURL = randomImageUrl.text;
        }
    }

    public formatTargetDate = (targetDate: string) => {
        const month = moment(new Date(targetDate).getMonth() + 1, "M")
            .format("MMMM")
            .slice(0, 3);
        const year = moment(new Date(targetDate).getFullYear(), "Y").format("YY");

        return month + " " + year;
    };

    public setRender = (value: boolean) => (this.render = value);

    public setBarChartData = () => {
        const retVal: BarDatum[] = [];

        const targetDate = new Date(this.model.startDateFilter!);

        while (targetDate < this.model.endDateFilter!) {
            //get the enquiries created in current month

            const dataByMonth: BarDatum = {
                month: targetDate.toString(),
                Enquiries: this.model.enquiriesByMonth(this.model.enquiriesByYear(this.model.enquiryLites, targetDate), targetDate).length.toString(),
                "Work won": this.model.numberOfQuotesWonPerMonth(targetDate).length,
            };

            retVal.push(dataByMonth);

            targetDate.setMonth(targetDate.getMonth() + 1);
        }

        this.barChartData = retVal;
    };
    //end region actions

    //region commands

    public generateOverviewCommand = new RelayCommand(() => this.getAdminOverviewDetails());

    public updateStartDateCommand = new RelayCommand((date: Date) => {
        this.setValue("financialPeriodTypeId", "");
        this.updateField("startDateFilter", date);
        this.isFieldValid("endDateFilter");
    });

    public updateEndDateCommand = new RelayCommand((date: Date) => {
        this.setValue("financialPeriodTypeId", "");
        this.updateField("endDateFilter", date);
        this.isFieldValid("startDateFilter");
    });

    public updateFinancialPeriodTypeCommand = new RelayCommand((value: string | null) => {
        //update filter id
        this.model.setValue("financialPeriodTypeId", value);

        //update date filters
        const financialPeriodType = this.lookupStore.financialPeriodTypes.find((type) => type.id == this.model.financialPeriodTypeId)?.type;

        switch (financialPeriodType) {
            case CURR_CALENDAR_WEEK:
                const startOfWeek = moment().startOf("week").add(1, "days");
                this.setValue("startDateFilter", startOfWeek.toDate());
                const endOfWeek = moment().endOf("week").add(-1, "days");
                this.setValue("endDateFilter", endOfWeek);
                break;

            case CURR_CALENDAR_MONTH:
                const startOfMonth = moment().startOf("month");
                this.setValue("startDateFilter", startOfMonth.toDate());
                this.setValue("endDateFilter", new Date());
                break;

            case CURR_CALENDAR_QUARTER:
                const startOfQuarter = moment().startOf("quarter");
                this.setValue("startDateFilter", startOfQuarter.toDate());
                this.setValue("endDateFilter", new Date());
                break;

            case CURR_CALENDAR_YEAR:
                const startOfYear = moment().startOf("year");
                this.setValue("startDateFilter", startOfYear.toDate());
                this.setValue("endDateFilter", new Date());
                break;

            case PREV_CALENDAR_WEEK:
                const startOfPrevWeek = moment().subtract(1, "weeks").startOf("week").add(1, "days");
                const endOfPrevWeek = moment().subtract(1, "weeks").endOf("week").subtract(1, "days");
                this.setValue("startDateFilter", startOfPrevWeek.toDate());
                this.setValue("endDateFilter", endOfPrevWeek.toDate());
                break;

            case PREV_CALENDAR_MONTH:
                const startOfPrevMonth = moment().subtract(1, "months").startOf("month");
                const endOfPrevMonth = moment().subtract(1, "months").endOf("month");
                this.setValue("startDateFilter", startOfPrevMonth.toDate());
                this.setValue("endDateFilter", endOfPrevMonth.toDate());
                break;

            case PREV_CALENDAR_QUARTER:
                const startOfPrevQuarter = moment().subtract(1, "quarters").startOf("quarter");
                const endOfPrevQuarter = moment().subtract(1, "quarters").endOf("quarter");
                this.setValue("startDateFilter", startOfPrevQuarter.toDate());
                this.setValue("endDateFilter", endOfPrevQuarter.toDate());
                break;

            case PREV_CALENDAR_YEAR:
                const startOfPrevYear = moment().subtract(1, "years").startOf("year");
                const endOfPrevYear = moment().subtract(1, "years").endOf("year");
                this.setValue("startDateFilter", startOfPrevYear.toDate());
                this.setValue("endDateFilter", endOfPrevYear.toDate());
                break;

            // start of each financial year is the 1st of April
            // end of each financial year is the 31st of March
            case CURR_FINANCIAL_YEAR:
                let startOfFinancialYear = moment().month(3).startOf("month");

                //compare current date to start of financial year.
                // current date is in current financial year
                if (startOfFinancialYear.toDate().getTime() < new Date().getTime()) {
                    this.setValue("startDateFilter", startOfFinancialYear.toDate());
                    this.setValue("endDateFilter", new Date());
                } else {
                    //current date is previous financial year
                    startOfFinancialYear = moment().subtract(1, "years").month(3).startOf("month");

                    this.setValue("startDateFilter", startOfFinancialYear.toDate());
                    this.setValue("endDateFilter", new Date());
                }

                break;

            case PREV_FINANCIAL_YEAR:
                let startOfPrevFinancialYear = moment().subtract(1, "years").month(3).startOf("month");
                //get the calendar date from last year
                const calendarDate = moment().subtract(1, "years");
                //compare current date to start of financial year.
                // current date is in current financial year
                if (startOfPrevFinancialYear.toDate().getTime() < calendarDate.toDate().getTime()) {
                    this.setValue("startDateFilter", startOfPrevFinancialYear.toDate());
                    this.setValue("endDateFilter", calendarDate.toDate());
                } else {
                    //current date is previous financial year
                    startOfPrevFinancialYear = startOfPrevFinancialYear.subtract(1, "years").month(3).startOf("month");
                    const endOfPrevFinancialYear = calendarDate.month(2).endOf("month");
                    this.setValue("startDateFilter", startOfPrevFinancialYear.toDate());
                    this.setValue("endDateFilter", endOfPrevFinancialYear.toDate());
                }

                break;
            default:
                return;
        }

        this.getAdminOverviewDetails();
    });

    //end region commands

    //region helpers
    private async updateField(fieldName: keyof FieldType<OverviewModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    //end region helpers
}
