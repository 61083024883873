import { runInAction } from "mobx";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { AddEditPlanningApplicationViewModel } from "../AddEditPlanningApplicationViewModel";
import { FileModel } from "Views/Shared/Files/FileModel";

class Response {}

export class UpsertPlanningApplicationRequest {
    public id: string | null = null;
    public projectId: string | null = null;
    public localAuthorityDistrictId: string | null = null;
    public planningRef: string | null = null;
    public planningApplicationStatusId: string = "";
    public planningApplicationTypeId: string | null = null;
    public note: string | null = null;
    public submittedDate: Date | null = null;
    public determinationDate: Date | null = null;
    public approvedDate: Date | null = null;
    public consultationDate: Date | null = null;
    public documents: UpsertPlanningApplicationDocumentRequest[] = [];
}

export interface UpsertPlanningApplicationDocumentRequest {
    id: string | null;
}

export class POST_UpsertPlanningApplicationEndpoint extends Endpoint<Request, Response> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.PlanningApplication.UpsertPlanningApplication);
    }

    public async HandleRequestAsync(formData: FormData): Promise<any> {
        return Promise.resolve(formData);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        return response;
    }
}
