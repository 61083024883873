import { FieldType, ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

import { ICommand, IKeyState, RelayCommand } from "Application";
import { ChangeAllowanceModel, ChangeAllowanceModelValidator } from "./ChangeAllowanceModel";

export class ChangeAllowanceViewModel extends ViewModelBase<ChangeAllowanceModel> {
    // Callback commands to the parent viewmodel.
    public parentSubmitCommand: ICommand;
    public parentCancelCommand: ICommand;

    constructor(model: ChangeAllowanceModel, submitCommand: ICommand, cancelCommand: ICommand) {
        super(model, false);

        this.setValidator(new ChangeAllowanceModelValidator());

        // Commands to be called on the parent viewmodel.
        this.parentSubmitCommand = submitCommand;
        this.parentCancelCommand = cancelCommand;

        makeObservable(this, {});
    }

    //#region Commands

    public updateNumberOfDaysCommand: ICommand = new RelayCommand((value: string | number) => {
        this.updateField("numberOfDays", value);
    });

    public submitCommand = new RelayCommand(
        () => {
            if (this.isModelValid()) {
                this.parentSubmitCommand.execute();
            }
        },
        () => this.parentSubmitCommand.canExecute(),
    );

    public cancelCommand = new RelayCommand(
        () => this.parentCancelCommand.execute(),
        () => this.parentCancelCommand.canExecute(),
    );

    //#endregion Commands

    // #region Supporting

    private updateField(fieldName: keyof FieldType<ChangeAllowanceModel>, value: any, keyState?: IKeyState) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    // #endregion Supporting
}
