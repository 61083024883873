import React, { useState } from "react";
import { IStyle, mergeStyleSets } from "@fluentui/react";
import { observer } from "mobx-react-lite";

import { HeaderViewModel } from "./HeaderViewModel";
import { HeaderLink } from "./HeaderLink";
import { HeaderLogoOptionBase } from "./HeaderLogoOptions";
import LogoutView from "Views/Logout/LogoutView";

export interface IHeaderStyles {
    root: IStyle;
    content: IStyle;
    logoOption: IStyle;
    options: IStyle;
    option: IStyle;
    optionActive: IStyle;
}

export interface IHeaderProps {
    /**
     * An optional class name for use with the header.
     */
    className?: string;
    /**
     * Styling of the header.
     */
    styles?: IHeaderStyles;
}

export const HeaderBase: React.FC<IHeaderProps> = observer((props) => {
    // #region Code behind
    const [viewModel] = useState(() => new HeaderViewModel());

    const styles = mergeStyleSets(props.styles);

    // #endregion Code behind

    return (
        <div className={styles.root}>
            <div className={styles.content}>
                <HeaderLogoOptionBase className={styles.logoOption} command={viewModel.navigateToHomeCommand} />
                <div className={styles.options}>
                    <HeaderLink
                        show={viewModel.showDashboard}
                        className={styles.option}
                        selectedClassName={styles.optionActive}
                        command={viewModel.navigateToDashboardCommand}
                        displayName={viewModel.dashBoardViewModel.getValue("displayName")}
                        isSelected={viewModel.dashBoardViewModel.getValue("isActive")}
                    />
                    <HeaderLink
                        show={viewModel.showSuppliers}
                        className={styles.option}
                        selectedClassName={styles.optionActive}
                        command={viewModel.navigateToSuppliersCommand}
                        displayName={viewModel.suppliersViewModel.getValue("displayName")}
                        isSelected={viewModel.suppliersViewModel.getValue("isActive")}
                    />
                    <HeaderLink
                        show={viewModel.showClients}
                        className={styles.option}
                        selectedClassName={styles.optionActive}
                        command={viewModel.navigateToCustomersCommand}
                        displayName={viewModel.customersViewModel.getValue("displayName")}
                        isSelected={viewModel.customersViewModel.getValue("isActive")}
                    />
                    <HeaderLink
                        show={viewModel.showNewBusiness}
                        className={styles.option}
                        selectedClassName={styles.optionActive}
                        command={viewModel.navigateToNewBusinessCommand}
                        displayName={viewModel.newBusinessViewModel.getValue("displayName")}
                        isSelected={viewModel.newBusinessViewModel.getValue("isActive")}
                    />
                    <HeaderLink
                        show={viewModel.showProjects}
                        className={styles.option}
                        selectedClassName={styles.optionActive}
                        command={viewModel.navigateToProjectsCommand}
                        displayName={viewModel.projectsViewModel.getValue("displayName")}
                        isSelected={viewModel.projectsViewModel.getValue("isActive")}
                    />
                    <HeaderLink
                        show={viewModel.showResource}
                        className={styles.option}
                        selectedClassName={styles.optionActive}
                        command={viewModel.navigateToResourceCommand}
                        displayName={viewModel.resourceViewModel.getValue("displayName")}
                        isSelected={viewModel.resourceViewModel.getValue("isActive")}
                    />
                    <HeaderLink
                        show={viewModel.showPlanning}
                        className={styles.option}
                        selectedClassName={styles.optionActive}
                        command={viewModel.navigateToPlanningCommand}
                        displayName={viewModel.planningViewModel.getValue("displayName")}
                        isSelected={viewModel.planningViewModel.getValue("isActive")}
                    />
                    <HeaderLink
                        show={viewModel.showAdmin}
                        className={styles.option}
                        selectedClassName={styles.optionActive}
                        command={viewModel.navigateToAdminCommand}
                        displayName={viewModel.adminViewModel.getValue("displayName")}
                        isSelected={viewModel.adminViewModel.getValue("isActive")}
                    />
                    <HeaderLink
                        show={true}
                        className={styles.option}
                        selectedClassName={styles.optionActive}
                        command={viewModel.navigateToLogoutCommand}
                        displayName={viewModel.logoutViewModel.getValue("displayName")}
                        isSelected={viewModel.logoutViewModel.getValue("isActive")}
                    />
                    <HeaderLink
                        show={true}
                        className={styles.option}
                        selectedClassName={styles.optionActive}
                        command={viewModel.navigateToLoginCommand}
                        displayName={viewModel.loginViewModel.getValue("displayName")}
                        isSelected={viewModel.loginViewModel.getValue("isActive")}
                    />
                </div>
            </div>
            <LogoutView isShow={viewModel.logout} onClose={() => viewModel.logoutClose.execute()} />
        </div>
    );
});
