import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

export class ProjectsModel extends ModelBase<ProjectsModel> {
    // Filtering
    public filterKeyword: string = "";
    public filterProjectStatusId: string | null = null;
    public filterProjectLeadId: string | null = null;
    public filterSeniorAssociateId: string | null = null;
    public filterProjectSupportId: string | null = null;

    constructor() {
        super();
        makeObservable(this, {
            // Observables
            filterKeyword: observable,
            filterProjectStatusId: observable,
            filterSeniorAssociateId: observable,
            filterProjectLeadId: observable,
            filterProjectSupportId: observable,
        });
    }
}
