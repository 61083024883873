import { ViewModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

import { InProgressGroupModel } from "./InProgressGroupModel";
import { InProgressItemViewModel } from "./InProgressItemViewModel";

export class InProgressGroupViewModel extends ViewModelBase<InProgressGroupModel> {
    public tasks = observable<InProgressItemViewModel>([]);

    constructor(model = new InProgressGroupModel()) {
        super(model);

        makeObservable(this, {
            // Observables
            tasks: observable,
        });
    }

    public get KEY() {
        return this.model.KEY;
    }

    public get displayName() {
        return this.model.name;
    }

    public get displayNote() {
        return this.model.note;
    }
}
