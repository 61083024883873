import * as React from "react";
import { SVGProps } from "react";

export const EditIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg style={{ cursor: "pointer" }} data-name="Group 4552" xmlns="http://www.w3.org/2000/svg" width={14.002} height={14} {...props}>
        <path
            d="M13.839 2.556a7.226 7.226 0 0 0-1.063-1.328A7.67 7.67 0 0 0 11.465.166a1.112 1.112 0 0 0-1.355.147l-8.5 8.495a.53.53 0 0 0-.1.147l-1.469 4.19a.618.618 0 0 0 .147.664.638.638 0 0 0 .442.192.868.868 0 0 0 .206-.029l4.182-1.475a.337.337 0 0 0 .147-.1l8.511-8.499a1.077 1.077 0 0 0 .163-1.342ZM2.191 9.531a6.566 6.566 0 0 1 1.285 1.018 6.471 6.471 0 0 1 1 1.283l-2.415.855a1.625 1.625 0 0 0-.295-.413 1.524 1.524 0 0 0-.412-.295Z"
            fill="#575756"
        />
        <g data-name="editIcon" fill="#575756">
            <path d="M6.37 9.948c-.42-.835-1.02-1.63-2.334-2.317L11.044.624a.449.449 0 0 1 .539-.059c.35.223.696.503 1.025.832.333.334.612.683.829 1.036a.415.415 0 0 1-.065.528L6.37 9.948Z" />
            <path d="m11.36 1.016-6.512 6.51c.773.499 1.27 1.046 1.632 1.606l6.5-6.487a5.235 5.235 0 0 0-.726-.895 5.577 5.577 0 0 0-.895-.734M11.354 0c.17 0 .341.047.493.14.376.239.751.54 1.114.904.364.363.664.74.902 1.128a.916.916 0 0 1-.138 1.142l-7.238 7.224c-.038.038-.326.145-.326.145-.492-1.129-1.064-2.059-2.845-2.843 0 0 .115-.312.153-.35L10.695.266A.95.95 0 0 1 11.354 0Z" />
        </g>
    </svg>
);
