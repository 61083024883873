import * as React from "react";
import { SVGProps } from "react";

export const NoteEditIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
        <g data-name="Group 7564" transform="translate(-1301.5 -745)">
            <rect data-name="Rectangle 1923" width={20} height={20} rx={1} transform="translate(1301.5 745)" fill="#2d91ff" />
            <g data-name="Group 7153" fill="none">
                <g data-name="Path 5674">
                    <path d="M1316 759v1h-10v-1Z" />
                    <path d="M1306 759h10v1h-10v-1Z" fill="#fff" />
                </g>
                <g data-name="Rectangle 1922" stroke="#fff">
                    <path d="M1316.191 749.707a1 1 0 0 1 0 1.414l-5.657 5.657-1.414-1.414 5.657-5.657a1 1 0 0 1 1.414 0Z" stroke="none" />
                    <path d="M1315.838 750.06a.5.5 0 0 1 0 .708l-5.304 5.303-.707-.707 5.303-5.303a.5.5 0 0 1 .708 0Z" />
                </g>
                <g data-name="Polygon 33">
                    <path d="m1307.707 758.19.707-2.12 1.414 1.414Z" />
                    <path d="m1307.707 758.19.707-2.12 1.414 1.414-2.121.707Z" fill="#fff" />
                </g>
            </g>
        </g>
    </svg>
);
