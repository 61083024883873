import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { Service, ServiceGroup } from "Application/Models/Domain";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { CreateTaskViewModel } from "./CreateTaskViewModel";

class Response {
    serviceGroups: ServiceGroupResponse[] = [];
    services: ServiceResponse[] = [];
    summary: SummaryResponse | null = null;
}

class ServiceGroupResponse {
    id: string = "";
    name: string = "";
    ordinal: number = 0;
    type: string = "";
    isDeleted: boolean = false;
    isNCTPreset: boolean = false;
}

class ServiceResponse {
    id: string = "";
    name: string = "";
    ordinal: number = 0;
    isDeleted: boolean = false;
    serviceGroupId: string = "";
}

class SummaryResponse {
    public reference = "";
    public title = "";
}

export class GETProjectTaskRelatedEndpoint extends EndpointWithoutRequest<Response> {
    private readonly viewModel: CreateTaskViewModel;

    constructor(projectId: string, viewModel: CreateTaskViewModel) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.Resources.GetProjectTaskRelated.replace(":projectId", projectId));
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: any): Promise<any> {
        Logger.logInformation("Response", response);

        this.viewModel.summaryViewModel.model.fromResponse(response.summary);

        this.viewModel.model.fromResponse(response);

        this.viewModel.model.serviceGroups.replace(
            response.serviceGroups.map((sg: ServiceGroupResponse) => {
                const serviceGroup = new ServiceGroup(sg.id);

                serviceGroup.name = sg.name;
                serviceGroup.isDeleted = sg.isDeleted;
                serviceGroup.ordinal = sg.ordinal;
                serviceGroup.type = sg.type;
                serviceGroup.isNCTPreset = sg.isNCTPreset;

                return serviceGroup;
            }),
        );

        this.viewModel.model.services.replace(
            response.services.map((s: ServiceResponse) => {
                const service = new Service(s.id);

                service.name = s.name;
                service.isDeleted = s.isDeleted;
                service.ordinal = s.ordinal;
                service.serviceGroupId = s.serviceGroupId;

                return service;
            }),
        );
    }
}
