import { IStyle, mergeStyleSets, Modal } from "@fluentui/react";
import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import React from "react";

import { theme } from "Application";
import { FileViewModel } from "./FileViewModel";
import { ImageSettingsViewModel } from "./ImageSettingsViewModel";
import { DeleteImageSettingsView } from "../Form/ModalViews/DeleteImageSettingView";

interface IFileStyles {
    root: IStyle;
    image: IStyle;
    buttonContainer: IStyle;
    button: IStyle;
}

export interface IProps {
    className?: string;
    viewModel: FileViewModel;
    parentViewModel: ImageSettingsViewModel;
}

const fileStyle: IFileStyles = {
    root: {
        border: "1px solid #eee",
        position: "relative",
        overflow: "hidden",
        height: "228px",
    },
    image: {
        width: "100%",
        height: "228px",
        objectFit: "cover",
    },
    buttonContainer: {
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
    },
    button: {
        alignItems: "center",
        backgroundColor: "transparent",
        border: "none",
        color: "white",
        display: "flex",
        fontSize: "10px",
        flexDirection: "column",
        flex: 1,
        fontFamily: theme.defaultFontStyle.fontFamily,
        height: "100%",
        justifyContent: "center",
        opacity: 0,
        svg: {
            height: "72px",
            width: "72px",
            marginBottom: "8px",
            color: "#fff",
        },
        transition: "all 0.2s ease",
        width: "100%",
        ":hover": {
            background: "#2d92ffcc",
            opacity: "100%",
            cursor: "pointer",
        },
    },
};

export const File: React.FC<IProps> = observer((props) => {
    const styles = mergeStyleSets(fileStyle);

    const onClickRemoveButton = () => {
        props.viewModel.deleteModalOpenCommand.execute();
    };

    const renderDeleteModalContent = () => {
        return (
            <Modal isOpen={props.viewModel.deleteModalOpen} onDismiss={() => props.viewModel.deleteModalCloseCommand.execute()}>
                <DeleteImageSettingsView viewModel={props.viewModel} />
            </Modal>
        );
    };

    return (
        <div className={styles.root}>
            <img src={props.viewModel.documentUrl} alt={props.viewModel.displayName} className={styles.image} />
            <Box className={styles.buttonContainer}>
                <button onClick={onClickRemoveButton} type="button" className={styles.button}>
                    <CameraAltOutlinedIcon />
                    CLICK TO REMOVE PHOTO
                </button>
            </Box>

            {props.viewModel.deleteModalOpen && renderDeleteModalContent()}
        </div>
    );
});
