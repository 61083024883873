import { ModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

export class ProjectTaskAssignmentTemplateNote extends ModelBase<ProjectTaskAssignmentTemplateNote> {
    // Note: some observables are predefined in the ModelBase class.
    // If any errors arise, check there to see if the property exists
    // and if so, delete it from here.
    // public createdDate: Date | null = null;
    // public updatedDate: Date | null = null;
    // public isDeleted: boolean = false;

    public id: string = "";
    public name: string = "";
    public note: string = "";
    public ordinal: number = 0;
    public isDeleted: boolean = false;

    constructor() {
        super();
        makeObservable(this, {});
    }
}
