import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

export class BillingNoteModel extends ModelBase<BillingNoteModel> {
    public context: string = "";

    public date: Date | null = null;
    public note: string | null = null;
    public firstName: string | null = null;
    public lastName: string | null = null;
    public documentUrl: string | null = null;

    constructor() {
        super();

        makeObservable(this, {});
    }
}
