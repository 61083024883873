import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { ProjectLite } from "Application/Models/Domain";
import { User } from "Application/Models/Domain/User";
import { AppUrls } from "AppUrls";
import { CustomerContactItemModel } from "Views/Shared/Customer/CustomerContactItemModel";
import { CustomerItemModel } from "Views/Shared/Customer/CustomerItemModel";
import { EnquiryViewModel } from "./EnquiryViewModel";

class CustomerResponse {
    id: string = "";
    contactTypeId: string = "";
    contactTitleId: string | null = null;
    public prefixName: string = "";
    businessName: string | null = null;
    firstName: string | null = null;
    lastName: string | null = null;
    public contactNumber1: string | null = null;
    public contactNumber2: string | null = null;
    public emailAddress: string | null = null;
    public emailAddress2: string | null = null;
    public address1: string | null = null;
    public address2: string | null = null;
    public address3: string | null = null;
    public city: string | null = null;
    public postcode: string | null = null;
    public isDeleted: boolean = false;
}

class CustomerContactResponse {
    id: string = "";
    customerId: string = "";
    contactTitleId: string | null = null;
    public prefixName: string = "";
    firstName: string = "";
    lastName: string = "";
    public contactNumber1: string | null = null;
    public contactNumber2: string | null = null;
    public emailAddress: string | null = null;
    public address1: string | null = null;
    public address2: string | null = null;
    public address3: string | null = null;
    public city: string | null = null;
    public postcode: string | null = null;
    public isDeleted: boolean = false;
}

class ProjectResponse {
    id: string = "";
    title: string = "";
    projectTypeId: string = "";
}

class personVisitingResponse {
    id: string = "";
    firstName: string = "";
    lastName: string = "";
    thumbnailDocumentUrl: string = "";
}

class Response {
    customers: CustomerResponse[] = [];
    customerContacts: CustomerContactResponse[] = [];
    projects: ProjectResponse[] = [];
    personVisiting: personVisitingResponse[] = [];
}

export class GETEnquiryRelatedByCustomerIdEndpoint extends EndpointWithoutRequest<Response> {
    private readonly viewModel: EnquiryViewModel;

    constructor(customerId: string, viewModel: EnquiryViewModel) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.Enquiries.GetEnquiryRelatedByCustomerId.replace(":id", customerId));
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        const customerResponse = response.customers[0];
        this.viewModel.customerViewModel.model.customerId = customerResponse.id;
        this.viewModel.customerViewModel.model.contactTypeId = customerResponse.contactTypeId;

        if (response.customerContacts.length > 0) {
            const customerContactResponse = response.customerContacts[0];
            this.viewModel.customerViewModel.model.customerContactId = customerContactResponse.id;
        }

        // Process the response Projects.
        this.viewModel.enquirySubViewModel.model.projectsList.replace(
            response.projects.map((project) => {
                const model: ProjectLite = {
                    id: project.id,
                    title: project.title,
                    projectTypeId: project.projectTypeId,
                };

                return model;
            }),
        );

        // Process the response customers.
        this.viewModel.customerViewModel.customers.replace(
            response.customers.map((c) => {
                const model = new CustomerItemModel();
                model.fromResponse(c);
                return model;
            }),
        );

        // Process the response customer contacts.
        this.viewModel.customerViewModel.customerContacts.replace(
            response.customerContacts.map((cc) => {
                const model = new CustomerContactItemModel();
                model.fromResponse(cc);
                return model;
            }),
        );

        // Process the response visiting persons.
        this.viewModel.enquirySubViewModel.model.personVisitingList.replace(
            response.personVisiting.map((item) => {
                const domainModel = new User();
                domainModel.id = item.id;
                domainModel.firstName = item.firstName;
                domainModel.lastName = item.lastName;
                domainModel.thumbnailDocumentUrl = item.thumbnailDocumentUrl;
                return domainModel;
            }),
        );
    }
}
