import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import React from "react";

import { AutoGrid, Box, ThemedButton, ThemedEditText, ThemedText, theme } from "Application";
import { FormContainer, FormContainerRow, FormDottedSeparator, FormSubContainer, PanelFilesReadOnlyView } from "Views/Shared/SharedComponents";
import { ProgrammingWeekTaskGroupViewModel } from "./ProgrammingWeekTaskGroupViewModel";
import { ThemedError } from "Styles/ThemedPrimitives/Style1/ThemedError";
import { ThemedLoader } from "Styles/ThemedPrimitives/Style1/ThemedLoader";

interface IProps {
    viewModel: ProgrammingWeekTaskGroupViewModel;
}

export const ProgrammingWeekTaskGroupView: React.FC<IProps> = observer((props) => {
    const viewModel = props.viewModel;

    const Body = styled(Box)`
        background-color: #fcfcfc;
        display: flex;
        flex: 1;
        flex-direction: column;
    `;
    const Footer = styled(Box)`
        align-items: center;
        background-color: #fcfcfc;
        display: flex;
        min-height: 70px;
        padding: 0 30px;
    `;

    const ReviewContainers = styled(Box)`
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 10px;
        padding-bottom: 20px;
        border-bottom: 1px dashed ${theme.palette.field.light};
        max-height: 200px;
        overflow: auto;
    `;

    const ReviewContainer = styled(Box)``;

    if (viewModel) {
        const Header = styled(Box)`
            background-color: ${viewModel.backgroundColour};
            color: white;
            min-height: 70px;
            padding: 10px 30px;
        `;

        const renderBusy = () => <ThemedLoader isOpen={viewModel.apiClient.IsBusy} />;

        const renderError = () => {
            const isOpen = viewModel.apiClient.IsSubmitted && !viewModel.apiClient.IsBusy && viewModel.apiClient.HaveValidationMessage;

            return <ThemedError command={viewModel.resetServerErrorCommand} isOpen={isOpen} errorMessage={viewModel.apiClient.ValidationMessage} />;
        };

        const renderAttachedDocuments = (): JSX.Element => {
            if (props.viewModel.filesViewModel.model.files.length) {
                return <PanelFilesReadOnlyView viewModel={props.viewModel.filesViewModel} />;
            } else {
                return <ThemedText fontStyle="h7">There are no documents attached to this task group.</ThemedText>;
            }
        };

        const renderReviews = (): JSX.Element[] => {
            const retVal: JSX.Element[] = [];

            props.viewModel.model.reviews.forEach((r) => {
                retVal.push(
                    <ReviewContainer>
                        <ThemedText fontStyle="h7">{r.projectTaskAssignmentReviewNote}</ThemedText>
                    </ReviewContainer>,
                );
            });

            return retVal;
        };

        return (
            <Box display="flex" flexDirection="column" flex="1" height={"100vh"} onClick={(e: any) => e.stopPropagation()}>
                <Header>
                    <Box display="flex" alignItems="baseline">
                        <ThemedText color="white" fontStyle="h5">
                            <b>{viewModel.referenceDisplayName}</b> - {viewModel.projectDisplayName}
                        </ThemedText>
                    </Box>
                </Header>
                <Body>
                    <FormContainer>
                        {props.viewModel.isTaskGroupReviewed && (
                            <FormContainerRow dc={"100%"} rowGap={10} mb="15px !important">
                                <ThemedText fontStyle="button">Reviews</ThemedText>
                                <ReviewContainers>{renderReviews()}</ReviewContainers>
                            </FormContainerRow>
                        )}
                        <FormContainerRow dc={"100%"} rowGap={10} mb="15px !important">
                            <ThemedText fontStyle="button">Details</ThemedText>
                            <ThemedText fontStyle="h7">{viewModel.projectTaskAssignmentGroupDisplayNote}</ThemedText>
                        </FormContainerRow>
                        <FormContainerRow dc={"100%"} rowGap={10} mb="15px !important">
                            <ThemedText fontStyle="button">Attached files</ThemedText>
                            {renderAttachedDocuments()}
                        </FormContainerRow>
                        <FormSubContainer>
                            {viewModel.updateUserNoteCommand.canExecute() && (
                                <FormContainerRow dc={"100%"} mb="15px !important">
                                    <ThemedEditText
                                        command={viewModel.updateUserNoteCommand}
                                        displayName="Enter your task updates below*"
                                        isMultiline
                                        placeholder="Please enter any notes for the task group"
                                        styles={{ fieldGroup: { minHeight: 120 } }}
                                        validationMessage={() => viewModel.getError("projectTaskAssignmentUserNote")}
                                        value={() => viewModel.getValue("projectTaskAssignmentUserNote") ?? ""}
                                    />
                                </FormContainerRow>
                            )}
                        </FormSubContainer>
                    </FormContainer>
                </Body>
                <FormDottedSeparator styles={{ root: { height: "1px" } }} />
                <Footer>
                    <AutoGrid columnGap={"15px"} dc={"105px 105px 105px"}>
                        {viewModel.submitCompleteCommand.canExecute() && (
                            <ThemedButton command={viewModel.submitCompleteCommand} displayName="Complete" paletteColor={"primary"} styles={{ root: { padding: "0 5px" } }} />
                        )}
                        {viewModel.submitUpdateCommand.canExecute() && (
                            <ThemedButton command={viewModel.submitUpdateCommand} displayName="Update" paletteColor={"quarternary"} styles={{ root: { padding: "0 5px" } }} />
                        )}
                        <ThemedButton
                            command={viewModel.cancelCommand}
                            displayName={viewModel.cancelCommandDisplayName}
                            paletteColor={"default"}
                            styles={{ root: { padding: "0 5px" } }}
                        />
                    </AutoGrid>
                </Footer>

                {/* Busy Overlay */}
                {renderBusy()}

                {/* Error Overlay */}
                {renderError()}
            </Box>
        );
    }

    return <React.Fragment />;
});
