import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

export class SupplierDetailModel extends ModelBase<SupplierDetailModel> {
    public supplier: Supplier = new Supplier();
    public supplierContacts = observable<SupplierContact>([]);

    constructor() {
        super();
        makeObservable(this, {
            supplier: observable,
            supplierContacts: observable,
        });
    }
}

export class Supplier extends ModelBase<Supplier> {
    public id: string = "";
    public contactTypeId: string = "";
    public contactTitleId: string = "";
    public businessName: string = "";
    public supplierTypeId: string = "";
    public supplierSubTypeId: string = "";
    public supplierTypeName: string = "";
    public supplierSubTypeName: string = "";
    public firstName: string = "";
    public lastName: string = "";
    public address1: string = "";
    public address2: string = "";
    public address3: string = "";
    public city: string = "";
    public postcode: string = "";
    public contactNumber1: string = "";
    public contactNumber2: string = "";
    public emailAddress: string = "";
    public emailAddress2: string = "";
    public clientNote: string = "";
    public createdByUserId: string = "";
    public createdByUserFirstName: string = "";
    public createdByUserLastName: string = "";
    public lastUpdatedDate: Date | null = null;
    public lastUpdatedByUserId: string = "";
    public updatedByUserFirstName: string | null = null;
    public updatedByUserLastName: string | null = null;

    constructor() {
        super();
        makeObservable(this, {
            id: observable,
            contactTypeId: observable,
            contactTitleId: observable,
            businessName: observable,
            supplierTypeId: observable,
            firstName: observable,
            lastName: observable,
            address1: observable,
            address2: observable,
            address3: observable,
            city: observable,
            postcode: observable,
            contactNumber1: observable,
            contactNumber2: observable,
            emailAddress: observable,
            emailAddress2: observable,
            clientNote: observable,
            lastUpdatedDate: observable,
            lastUpdatedByUserId: observable,
            updatedByUserFirstName: observable,
            updatedByUserLastName: observable,
            createdByUserId: observable,
            createdByUserFirstName: observable,
            createdByUserLastName: observable,
        });
    }
}

export class SupplierContact extends ModelBase<SupplierContact> {
    public id: string = "";
    public customerId: string = "";
    public contactTitleId: string = "";
    public prefixName: string = "";
    public firstName: string = "";
    public lastName: string = "";
    public address1: string = "";
    public address2: string = "";
    public address3: string = "";
    public city: string = "";
    public postcode: string = "";
    public sameAsCustomerAddress: boolean = false;
    public contactNumber1: string = "";
    public contactNumber2: string = "";
    public emailAddress: string = "";
    public supplierContactTypeName: string = "";

    constructor() {
        super();
        makeObservable(this, {
            id: observable,
            customerId: observable,
            contactTitleId: observable,
            prefixName: observable,
            firstName: observable,
            lastName: observable,
            address1: observable,
            address2: observable,
            address3: observable,
            city: observable,
            postcode: observable,
            sameAsCustomerAddress: observable,
            contactNumber1: observable,
            contactNumber2: observable,
            emailAddress: observable,
            supplierContactTypeName: observable,
        });
    }
}
