import { IStyle, mergeStyleSets } from "@fluentui/react";
import React, { PropsWithChildren } from "react";
import { UnsupportedBrowserView } from "../UnsupportedBrowser/UnsupportedBrowserView";

export interface IAccountLayoutStyles {
    root: IStyle;
    content: IStyle;
    image: IStyle;
}

export interface IAccountLayoutProps {
    /**
     * Styling of the layout.
     */
    styles?: IAccountLayoutStyles;
}

export const AccountLayoutBase: React.FC<PropsWithChildren<IAccountLayoutProps>> = (props) => {
    // #region Code behind

    const styles = mergeStyleSets(props.styles);

    // #endregion Code behind

    if (!props) {
        return <>Props undefined</>;
    }

    return window.IE11 ? (
        <UnsupportedBrowserView />
    ) : (
        <div className={styles.root}>
            <div className={styles.content}>{props.children}</div>
            <div className={styles.image} />
        </div>
    );
};
