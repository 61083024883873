import styled from "@emotion/styled";
import React from "react";
import { observer } from "mobx-react-lite";

import { AutoGrid } from "Application";
import { LeaveCalendarViewModel } from "../../LeaveCalendarViewModel";
import { DateView } from "./Date/DateView";

interface IProps {
    viewModel: LeaveCalendarViewModel;
}

const BodyGrid = styled(AutoGrid)`
    column-gap: 0px !important;
    flex: 1;
    margin: 3px 1px;
    row-gap: 4px !important;
`;

export const BodyView: React.FC<IProps> = observer((props) => {
    const viewModel = props.viewModel;

    const renderDateCells = () => {
        let cells: JSX.Element[] = [];

        for (const dateViewModel of viewModel.dateViewModels) {
            cells.push(<DateView viewModel={dateViewModel} />);
        }

        return cells;
    };

    return <BodyGrid dc="1fr 1fr 1fr 1fr 1fr 1fr 1fr">{renderDateCells()}</BodyGrid>;
});
