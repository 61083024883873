import { observer } from "mobx-react-lite";
import React from "react";

import { Box, ICommand, Nullcommand, ThemedButton, ThemedCheckBox, ThemedDefaultEditText, ThemedEditText, ThemedText, theme } from "Application";
import { WeeklyUpdateFormViewModel } from "./WeeklyUpdateFormViewModel";
import { FormContainerRow } from "Views/Shared/SharedComponents";
import { LinkButton } from "Components/General/LinkButton";

export interface IProps {
    viewModel: WeeklyUpdateFormViewModel;
}

export const WeeklyUpdateFormView: React.FC<IProps> = observer((props) => {
    const viewModel = props.viewModel;

    return (
        <React.Fragment>
            <Box display={"grid"} gridTemplateColumns={"49.5% 49.5%"} style={{ gap: "10px 1%" }} padding="10px 0px">
                <Box>
                    <ThemedText fontStyle="label" styles={{ root: { fontWeight: 600, marginBottom: 5 } }}>
                        Customer Update
                    </ThemedText>
                    <ThemedDefaultEditText
                        command={viewModel.updateDetailCommand}
                        displayName="Customer Update"
                        isMultiline={true}
                        styles={{ fieldGroup: { minHeight: 120 } }}
                        placeholder="Enter your weekly update"
                        validationMessage={() => viewModel.getError("updateDetail")}
                        value={() => viewModel.getValue("updateDetail") ?? ""}
                    />
                    <LinkButton paletteColor={"default"} displayName="Copy last week's customer update" command={viewModel.copyPreviousProjectUpdateUpdateDetailCommand} />
                </Box>
                <Box>
                    <ThemedText fontStyle="label" styles={{ root: { fontWeight: 600, marginBottom: 5 } }}>
                        Internal Note
                    </ThemedText>
                    <ThemedDefaultEditText
                        command={viewModel.updateInternalDetailCommand}
                        displayName="Internal Note"
                        isMultiline={true}
                        styles={{ fieldGroup: { minHeight: 120 } }}
                        placeholder="Add a note for internal use"
                        validationMessage={() => viewModel.getError("internalDetail")}
                        value={() => viewModel.getValue("internalDetail") ?? ""}
                    />
                    <LinkButton paletteColor={"default"} displayName="Copy last week's internal note" command={viewModel.copyPreviousProjectUpdateInternalDetailCommand} />
                </Box>
            </Box>
            <FormContainerRow dc={"140px 140px"} mb={"7.5px !important"}>
                <ThemedButton styles={{ root: { padding: "0 5px" } }} paletteColor={"primary"} displayName="Save update" command={viewModel.saveUpdateCommand} />
                <ThemedCheckBox command={viewModel.updateSendUpdateCommand} displayName="Send update" value={() => viewModel.getValue("sendUpdate")} paletteColor="secondary" />
            </FormContainerRow>
            <FormContainerRow dc={"100%"}>
                <ThemedText fontStyle="h6">{viewModel.updateDetails}</ThemedText>
            </FormContainerRow>
        </React.Fragment>
    );
});
