import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";

import { NewProjectModel } from "../ModalViews/NewProjectModel";
import { NewProjectViewModel } from "../ModalViews/NewProjectViewModel";
import { SentQuoteModel } from "../ModalViews/SentQuoteModel";
import { ProjectsViewModel } from "../ProjectsViewModel";

class Request {}

class Response {
    public id: string = "";
    public reference = "";
    public title = "";
}

export class GETAllSentQuotesLiteEndpoint extends Endpoint<Request, Response> {
    private viewModel: ProjectsViewModel;

    constructor(viewModel: ProjectsViewModel) {
        super();
        this.viewModel = viewModel;
        this.verb(Http.Get);
        this.path(AppUrls.Server.Quotes.AllSentQuotesLite);
    }

    public async HandleRequestAsync(model: any): Promise<any> {}

    public async HandleResponseAsync(responses: Response[]): Promise<any> {
        Logger.logInformation("Response", responses);

        const newProjectModel = new NewProjectModel();

        responses.forEach((response: Response) => {
            let model = new SentQuoteModel();

            model.fromResponse(response);

            newProjectModel.quotes.push(model);
        });

        this.viewModel.newProjectViewModel = new NewProjectViewModel(newProjectModel, this.viewModel.cancelDisplayNewProjectCommand, this.viewModel.updateFromNewProjectCommand);
    }
}
