import { IIconProps, ILabelStyles, ITextFieldStyles, MaskedTextField, mergeStyleSets, TextField } from "@fluentui/react";
import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";

import { reaction } from "mobx";
import { themeColourOptions, themeShapeOptions, themeSizeOptions } from "../../IShoothillTheme";
import { IKeyState } from "../../../Components";
import { getThemeColorOption, ICommand, theme } from "../../../Application";

/**
 * Button interface.
 */
export interface IEditTextBaseProps {
    /**
     * An optional id for use with the button.
     */
    id?: string;
    /**
     * An optional class name for use with the button.
     */
    className?: string;
    /**
     * A command to execute.
     */
    command: ICommand;
    /**
     * A command to execute.
     */
    clearCommand: ICommand;
    /**
     * A value to use with the control. Will be passed back by the command.
     */
    value: () => string;
    /**
     * Text content to display in the control label.
     */
    displayName?: string;
    /**
     * Text content to display in the placeholder.
     */
    placeholder?: string;
    /**
     * Text content to display in the error message.
     */
    validationMessage?: () => string;
    /**
     * An icon to display on the button.
     */
    iconName?: string;
    /**
     * Treats the component as a textarea.
     */
    isMultiline?: boolean;
    /**
     * Forces browsers to not fill the text
     */
    autoFill?: boolean;
    /**
     * Styling of the control.
     */
    styles?: Partial<ITextFieldStyles>;
    /**
     * The size of the control - use this if using generic control.
     */
    size?: themeSizeOptions;
    /**
     * The shape of the control - use this if using the generic control.
     */
    shape?: themeShapeOptions;
    /**
     * The color of the label.
     */
    labelColor?: themeColourOptions;
    /**
     * The description text to show under the text.
     */
    description?: string;
    /**
     * Called when description is rendered
     */
    onRenderDescription?: (isInError: boolean) => JSX.Element;
    /**
     * Called when label is rendered
     */
    onRenderLabel?: (isInError: boolean) => JSX.Element;
    /**
     * The color of the label.
     */
    prefix?: JSX.Element | string;
    /**
     * The color of the label.
     */
    suffix?: JSX.Element | string;
    /**
     * The control type - password or text.
     */
    type?: "password" | "text" | "email";
}

export const ThemedEditTime: React.FC<IEditTextBaseProps> = observer((props) => {
    // #region Code Behind

    const keyState = useRef<IKeyState>({ enterKeyPressed: false, backspaceKeyPressed: false, deleteKeyPressed: false });

    let [value, setValue] = useState<string | null>(null);
    const labelColor = getThemeColorOption(props.labelColor);

    useEffect(() => {
        reaction(
            () => props.value(),
            (value) => {
                setValue(value);
            },
        );
        setValue(props.value());
    }, [props.value]);

    const getClassNames = () => {
        return clsx({
            [props.className!]: !isEmptyOrWhitespace(props.className),
        });
    };

    const getValidationMessage = (): string => {
        return isEmptyOrWhitespace(props.validationMessage?.() as string) ? "" : (props.validationMessage?.() as string);
    };

    const isDisabled = (): boolean => {
        return isNullOrUndefined(props.command.canExecute) ? false : !props.command.canExecute();
    };

    const onChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        if (isValid(newValue as string)) {
            setValue(newValue as string);
            props.command.execute(newValue);
            return;
        }
        //create a mechanism through which the user can clear the field
        if (newValue === "__:__") {
            props.clearCommand.execute();
        }

        setValue(value);
    };

    const getAutoFill = (): "on" | "off" | "new-password" => {
        return isNullOrUndefined(props.autoFill) ? "on" : props.autoFill ? "on" : "new-password";
    };

    const getIcon = (): IIconProps | undefined => {
        return !isEmptyOrWhitespace(props.iconName) ? { iconName: props.iconName } : undefined;
    };

    const getStyles = (): Partial<ITextFieldStyles> | undefined => {
        return !isNullOrUndefined(props.styles) ? props.styles : undefined;
    };

    const getType = (): string => {
        return isEmptyOrWhitespace(props.type) ? "text" : props.type!;
    };

    const onRenderLabel = (isInError: boolean): JSX.Element => {
        return props.onRenderLabel?.(isInError) ?? <>{props.description}</>;
    };

    const onRenderDescription = (isInError: boolean): JSX.Element => {
        return props.onRenderDescription?.(isInError) ?? <>{props.description}</>;
    };

    const labelStyle: Partial<ILabelStyles> = {
        root: {
            color: labelColor,
            fontFamily: theme.fontStyles.label.fontFamily ?? theme.defaultFontStyle.fontFamily,
            fontSize: "12px",
            fontWeight: theme.fontStyles.label.fontWeight ?? theme.defaultFontStyle.fontWeight,
            letterSpacing: theme.fontStyles.label.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
            lineHeight: theme.fontStyles.label.lineHeight ?? theme.defaultFontStyle.lineHeight,
            textTransform: theme.fontStyles.label.textTransform ?? theme.defaultFontStyle.textTransform,
            ".ms-Label": {
                color: labelColor,
                fontSize: theme.fontStyles.label.fontSize ?? theme.defaultFontStyle.fontSize,
            },
            "ms-TextField-field": {
                borderColor: theme.palette.common.error,
            },
        },
    };
    const textFieldStyle: Partial<ITextFieldStyles> = {
        root: {
            color: labelColor,
            fontFamily: theme.fontStyles.label.fontFamily ?? theme.defaultFontStyle.fontFamily,
            fontSize: "12px",
            fontWeight: theme.fontStyles.label.fontWeight ?? theme.defaultFontStyle.fontWeight,
            letterSpacing: theme.fontStyles.label.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
            lineHeight: theme.fontStyles.label.lineHeight ?? theme.defaultFontStyle.lineHeight,
            textTransform: theme.fontStyles.label.textTransform ?? theme.defaultFontStyle.textTransform,
            ".ms-Label": {
                color: labelColor,
                fontSize: theme.fontStyles.label.fontSize ?? theme.defaultFontStyle.fontSize,
            },
        },
        fieldGroup: {
            border: "1px solid rgb(220, 220, 220)",
            height: "40px",
        },
        field: {
            border: `none`,
            outline: "0",
            boxSizing: "border-box",
        },
    };

    const styles = mergeStyleSets(labelStyle, textFieldStyle);
    // #endregion Code Behind

    return <MaskedTextField styles={styles} label={props.displayName} mask="99:99" value={value ?? undefined} onChange={onChange} />;
});

const isValid = (val: string) => {
    const regexp = /^\d{0,2}?\:?\d{0,2}$/;
    const [hoursStr, minutesStr] = val.split(":");

    if (!regexp.test(val)) {
        return false;
    }

    const hours = Number(hoursStr);
    const minutes = Number(minutesStr);

    const isValidHour = (hour: number) => Number.isInteger(hour) && hour >= 0 && hour < 24;
    const isValidMinutes = (minutes: number) => (Number.isInteger(minutes) && hours >= 0 && hours < 24) || Number.isNaN(minutes);

    if (!isValidHour(hours) || !isValidMinutes(minutes)) {
        return false;
    }

    if (minutes < 10 && Number(minutesStr[0]) > 5) {
        return false;
    }

    const valArr = val.indexOf(":") !== -1 ? val.split(":") : [val];

    // check mm and HH
    if (valArr[0] && valArr[0].length && (parseInt(valArr[0], 10) < 0 || parseInt(valArr[0], 10) > 23)) {
        return false;
    }

    if (valArr[1] && valArr[1].length && (parseInt(valArr[1], 10) < 0 || parseInt(valArr[1], 10) > 59)) {
        return false;
    }

    return true;
};
