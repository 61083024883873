import * as React from "react";
import { SVGProps } from "react";

export const EmailIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg data-name="Component 11 \u2013 14" xmlns="http://www.w3.org/2000/svg" width={24} height={14} viewBox="0 0 24 14" {...props}>
        <g data-name="Rectangle 1857" fill="none" stroke="#ff4b22">
            <rect width={24} height={14} rx={1} stroke="none" />
            <rect x={0.5} y={0.5} width={23} height={13} rx={0.5} />
        </g>
        <g data-name="Path 5666" fill="none">
            <path d="M12.536 7.856a1.069 1.069 0 0 1-1.072 0L.957 1.672A.9.9 0 0 1 1.493 0h21.014a.9.9 0 0 1 .536 1.672Z" />
            <path
                d="M12 7a.062.062 0 0 0 .029-.006L22.213 1H1.787l10.184 5.994A.063.063 0 0 0 12 7m0 1c-.186 0-.373-.048-.536-.144L.957 1.672C.125 1.182.504 0 1.493 0h21.014c.99 0 1.368 1.182.536 1.672L12.536 7.856c-.163.096-.35.144-.536.144Z"
                fill="#ff4b22"
            />
        </g>
    </svg>
);
