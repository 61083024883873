import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { DeleteTaskAssignmentGroupModel } from "../DeleteTaskAssignmentGroupModel";

class Request {
    projectTaskAssignmentGroupId: string | null = null;
}

class Response {}

export class POSTDeleteProjectTaskAssignmentGroupEndpoint extends Endpoint<Request, Response> {
    constructor() {
        super();

        this.verb(Http.Post);
        this.path(AppUrls.Server.Resources.DeleteProjectTaskAssignmentGroupById);
    }

    public async HandleRequestAsync(model: DeleteTaskAssignmentGroupModel): Promise<Request> {
        const request = new Request();

        request.projectTaskAssignmentGroupId = model.inProgressGroupModel.id;

        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        return Promise.resolve(response);
    }
}
