import { IStyle, mergeStyleSets } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React from "react";

import { AutoGrid, theme, ThemedButton, ThemedComboBox, ThemedText, ThemedTransparentIconButton } from "Application";
import { CrossSVG } from "Assets/CrossSVG";
import { FormSeparator } from "Views/Shared/SharedComponents";
import { ConfigureCustomerViewModel } from "./ConfigureCustomerViewModel";

interface IProps {
    viewModel: ConfigureCustomerViewModel;
}

interface IViewStyles {
    root: IStyle;
    header: IStyle;
    body: IStyle;
    taskContainer: IStyle;
    footer: IStyle;
    iconButton: IStyle;
    edit: IStyle;
}

const styleDefinitions: IViewStyles = {
    root: {
        width: "700px",
    },
    header: {
        alignItems: "center",
        backgroundColor: "#F3F3F3",
        display: "flex",
        height: "75px",
        justifyContent: "space-between",
        padding: "20px 30px",
    },
    body: {
        padding: "30px 30px 0 30px",
    },
    taskContainer: {
        maxHeight: "190px",
        overflowY: "auto",
    },
    footer: {
        display: "flex",
        height: "75px",
        padding: "20px 30px",
    },
    iconButton: {
        g: {
            stroke: theme.palette.defaultTr.text,
        },
    },
    edit: {
        ".ms-Stack": {
            display: "none",
        },
    },
};

export const ConfigureCustomerSubView: React.FC<IProps> = observer((props) => {
    const viewModel = props.viewModel;
    const styles = mergeStyleSets(styleDefinitions);

    if (viewModel) {
        return (
            <div className={styles.root}>
                <div className={styles.header}>
                    <ThemedText fontStyle="h2">Edit client</ThemedText>
                    <ThemedTransparentIconButton command={viewModel.cancelDisplayConfigureCustomerCommand} icon={<CrossSVG className={styles.iconButton} />} shape="round" />
                </div>
                <div className={styles.body}>
                    <ThemedText fontStyle="h5">{"Please select the contact you wish to change to"}</ThemedText>
                    <AutoGrid dc={"310px"} columnGap={"30px"}>
                        <ThemedComboBox
                            command={viewModel.updateCustomerContactCommand}
                            displayName="Contact*"
                            labelColor="default"
                            options={viewModel.customerContactOptions}
                            placeholder="Please select"
                            validationMessage={() => viewModel.getError("id")}
                            value={() => viewModel.getValue("id")}
                        />
                    </AutoGrid>
                    <FormSeparator />
                </div>
                <AutoGrid m={0} className={styles.footer} columnGap={"30px"} dc={"140px 140px"}>
                    <ThemedButton paletteColor="primary" command={viewModel.updateFromConfigureCustomerCommand} displayName="OK" styles={{ root: { padding: "0 20px" } }} />
                    <ThemedButton command={viewModel.cancelDisplayConfigureCustomerCommand} displayName="Cancel" styles={{ root: { padding: "0 20px" } }} />
                </AutoGrid>
            </div>
        );
    }

    return <React.Fragment />;
});
