import styled from "@emotion/styled";
import { Modal } from "@fluentui/react";
import { isEmptyOrWhitespace } from "@shoothill/core";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

import { AutoGrid, Box, Show, theme, ThemedText } from "Application";
import { AddressIcon } from "Assets/Icons/AddressIcon";
import { PhoneIcon } from "Assets/Icons/PhoneIcon";
import { EmailIcon } from "Assets/Icons/EmailIcon";
import { NoteEditIcon } from "Components/StyleFrameworks/FluentUI/Primitives/Active/NoteEditIcon";
import { ContactCallout, ICalloutState } from "Styles/ThemedPrimitives/Style1/ContactCallout";
import { FormContainerRow, FormSeparator, GroupItemInformationCell, GroupItemInformationContainer, GroupItemInformationSubContainer } from "Views/Shared/SharedComponents";
import { ConfigureCustomerSubView } from "./ConfigureCustomerSubViews/ConfigureCustomerSubView";
import { CustomerViewModel } from "./CustomerViewModel";

interface IProps {
    viewModel: CustomerViewModel;
}

const CompletedClientContainer = styled(Box)`
    background: #fcfcfc 0 0 no-repeat padding-box;
    box-shadow: 0 2px 3px #00000029;
    border-radius: 1px;
    margin-bottom: 20px;
    padding: 8px 15px;
`;

const CompletedClientCell = styled(Box)`
    display: flex;
    align-items: center;
    gap: 10px;
`;

const CheckBoxGroupLabelStyles = {
    root: {
        fontFamily: theme.fontStyles.label.fontFamily ?? theme.defaultFontStyle.fontFamily,
        fontSize: theme.fontStyles.label.fontSize ?? theme.defaultFontStyle.fontSize,
        fontWeight: theme.fontStyles.label.fontWeight ?? theme.defaultFontStyle.fontWeight,
        letterSpacing: theme.fontStyles.label.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
        lineHeight: theme.fontStyles.label.lineHeight ?? theme.defaultFontStyle.lineHeight,
        textTransform: theme.fontStyles.label.textTransform ?? theme.defaultFontStyle.textTransform,
        padding: "5px 0",
    },
};

export const QuoteThemeCheckBoxStyle = {
    root: {
        marginTop: "5px",
    },
};

export const CustomerView: React.FC<IProps> = observer((props) => {
    // #region Code Behind

    const viewModel = props.viewModel;
    const defaultCalloutState: ICalloutState = { isCalloutVisble: false, targetId: "", isEmail: false, data: "" };
    const [callout, setIsCalloutVisible] = useState<ICalloutState>(defaultCalloutState);

    // #endregion Code Behind

    const renderClient = () => {
        return (
            <GroupItemInformationContainer marginBottom="20px !important">
                <GroupItemInformationSubContainer>
                    {viewModel.customers.map((c, index) => {
                        return (
                            <Box display="flex" key={c.KEY} className="clientDetailsList">
                                <GroupItemInformationCell flex="20">
                                    <b>{viewModel.getCustomerName(c)}</b>
                                </GroupItemInformationCell>
                                {!isEmptyOrWhitespace(c.contactNumber1) || !isEmptyOrWhitespace(c.contactNumber2) ? (
                                    <GroupItemInformationCell flex="10">
                                        <Box display="flex" alignItems="center" width="20px" marginBottom="1px" paddingLeft="1px">
                                            <PhoneIcon width="8px" />
                                        </Box>
                                        <Box flexDirection="column">
                                            <Box
                                                id={`customernumber1-${index}`}
                                                onClick={(event: any) => {
                                                    setIsCalloutVisible({
                                                        isCalloutVisble: true,
                                                        isEmail: false,
                                                        targetId: `customernumber1-${index}`,
                                                        data: viewModel.getCustomerContactNumber1(c)!,
                                                    });
                                                }}
                                            >
                                                {viewModel.getCustomerContactNumber1(c)}
                                            </Box>
                                            <Box
                                                id={`customernumber2-${index}`}
                                                onClick={(event: any) => {
                                                    setIsCalloutVisible({
                                                        isCalloutVisble: true,
                                                        isEmail: false,
                                                        targetId: `customernumber2-${index}`,
                                                        data: viewModel.getCustomerContactNumber2(c)!,
                                                    });
                                                }}
                                            >
                                                {viewModel.getCustomerContactNumber2(c)}
                                            </Box>
                                        </Box>
                                    </GroupItemInformationCell>
                                ) : (
                                    <Box></Box>
                                )}
                                {!isEmptyOrWhitespace(c.emailAddress) || !isEmptyOrWhitespace(c.emailAddress2) ? (
                                    <GroupItemInformationCell flex="30">
                                        <Box display="flex" alignItems="center" width="20px" marginBottom="1px" paddingLeft="1px">
                                            <EmailIcon width="15px" />
                                        </Box>
                                        <Box flexDirection="column">
                                            <Box
                                                id={`customeremailaddress-${index}`}
                                                onClick={(event: any) => {
                                                    setIsCalloutVisible({
                                                        isCalloutVisble: true,
                                                        isEmail: true,
                                                        targetId: `customeremailaddress-${index}`,
                                                        data: viewModel.getCustomerEmailAddress(c)!,
                                                    });
                                                }}
                                            >
                                                {viewModel.getCustomerEmailAddress(c)}
                                            </Box>
                                            <Box
                                                id={`customeremailaddress2-${index}`}
                                                onClick={(event: any) => {
                                                    setIsCalloutVisible({
                                                        isCalloutVisble: true,
                                                        isEmail: true,
                                                        targetId: `customeremailaddress2-${index}`,
                                                        data: viewModel.getCustomerEmailAddress2(c)!,
                                                    });
                                                }}
                                            >
                                                {viewModel.getCustomerEmailAddress2(c)}
                                            </Box>
                                        </Box>
                                    </GroupItemInformationCell>
                                ) : (
                                    <Box></Box>
                                )}
                                {!isEmptyOrWhitespace(c.fullAddress) ? (
                                    <GroupItemInformationCell flex="40">
                                        <Box display="flex" alignItems="center" width="20px" marginBottom="2px">
                                            <AddressIcon width="10px" />
                                        </Box>
                                        <Box>{viewModel.getCustomerAddress(c)}</Box>
                                    </GroupItemInformationCell>
                                ) : (
                                    <Box></Box>
                                )}
                            </Box>
                        );
                    })}
                    {viewModel.customersCustomerContacts.map((c, index) => {
                        return (
                            <Box display="flex" key={c.KEY} className="clientDetailsList">
                                <GroupItemInformationCell flex="20">{viewModel.getCustomerContactName(c)}</GroupItemInformationCell>
                                {!isEmptyOrWhitespace(c.contactNumber1) || !isEmptyOrWhitespace(c.contactNumber2) ? (
                                    <GroupItemInformationCell flex="10">
                                        <Box display="flex" alignItems="center" width="20px" marginBottom="1px" paddingLeft="1px">
                                            <PhoneIcon width="8px" />
                                        </Box>
                                        <Box flexDirection="column">
                                            <Box
                                                id={`customercontactnumber1-${index}`}
                                                onClick={(event: any) => {
                                                    setIsCalloutVisible({
                                                        isCalloutVisble: true,
                                                        isEmail: false,
                                                        targetId: `customercontactnumber1-${index}`,
                                                        data: viewModel.getCustomerContactContactNumber1(c)!,
                                                    });
                                                }}
                                            >
                                                {viewModel.getCustomerContactContactNumber1(c)}
                                            </Box>
                                            <Box
                                                id={`customercontactnumber2-${index}`}
                                                onClick={(event: any) => {
                                                    setIsCalloutVisible({
                                                        isCalloutVisble: true,
                                                        isEmail: false,
                                                        targetId: `customercontactnumber2-${index}`,
                                                        data: viewModel.getCustomerContactContactNumber2(c)!,
                                                    });
                                                }}
                                            >
                                                {viewModel.getCustomerContactContactNumber2(c)}
                                            </Box>
                                        </Box>
                                    </GroupItemInformationCell>
                                ) : (
                                    <Box></Box>
                                )}
                                {!isEmptyOrWhitespace(c.emailAddress) ? (
                                    <GroupItemInformationCell flex="30">
                                        <Box display="flex" alignItems="center" width="20px" marginBottom="1px" paddingLeft="1px">
                                            <EmailIcon width="15px" />
                                        </Box>
                                        <Box
                                            id={`customercontactemailaddress-${index}`}
                                            onClick={(event: any) => {
                                                setIsCalloutVisible({
                                                    isCalloutVisble: true,
                                                    isEmail: true,
                                                    targetId: `customercontactemailaddress-${index}`,
                                                    data: viewModel.getCustomerContactEmailAddress(c)!,
                                                });
                                            }}
                                        >
                                            {viewModel.getCustomerContactEmailAddress(c)}
                                        </Box>
                                    </GroupItemInformationCell>
                                ) : (
                                    <Box></Box>
                                )}
                                {!isEmptyOrWhitespace(c.fullAddress) ? (
                                    <GroupItemInformationCell flex="40">
                                        <Box display="flex" alignItems="center" width="20px" marginBottom="2px">
                                            <AddressIcon width="10px" />
                                        </Box>
                                        <Box>{viewModel.getCustomerContactAddress(c)}</Box>
                                    </GroupItemInformationCell>
                                ) : (
                                    <Box></Box>
                                )}
                            </Box>
                        );
                    })}
                </GroupItemInformationSubContainer>
                {viewModel.editCustomerContactCommand.canExecute() && (
                    <Box display="flex" justifyContent="center" alignItems="top" width="20px" marginTop="10px" marginRight="10px">
                        <Box style={{ cursor: "pointer" }} onClick={() => viewModel.editCustomerContactCommand.execute()}>
                            <ThemedText>
                                <NoteEditIcon />
                            </ThemedText>
                        </Box>
                    </Box>
                )}
            </GroupItemInformationContainer>
        );
    };

    const renderDisplaySiteAddress = () => {
        return (
            <>
                <FormContainerRow dc={"75%"} m={"0"}>
                    <AutoGrid mt={"0"} columnGap={"30px"} dc={"1fr"}>
                        <Box>
                            <ThemedText styles={CheckBoxGroupLabelStyles}>SITE ADDRESS</ThemedText>
                        </Box>
                    </AutoGrid>
                </FormContainerRow>
                <CompletedClientContainer>
                    <AutoGrid m={"0"}>
                        <CompletedClientCell>
                            <AddressIcon />
                            <ThemedText fontStyle={"customerBarLabel"}>{viewModel.fullSiteAddress}</ThemedText>
                        </CompletedClientCell>
                    </AutoGrid>
                </CompletedClientContainer>
            </>
        );
    };

    const renderModalContent = () => {
        return (
            <Modal isOpen={viewModel.canDisplayConfigureCustomer} onDismiss={() => viewModel.cancelEditCustomerContactCommand.execute()}>
                <ConfigureCustomerSubView viewModel={viewModel.configureCustomerViewModel!} />
            </Modal>
        );
    };

    return (
        <React.Fragment>
            <ThemedText fontStyle={"h3"} marginBottom={"15px"} marginTop={"15px"}>
                CLIENT
            </ThemedText>
            {renderClient()}
            <Show if={viewModel.canDisplaySiteAddress}>{renderDisplaySiteAddress()}</Show>

            <FormSeparator />

            {/* Dialog */}
            {viewModel.canDisplayConfigureCustomer && renderModalContent()}

            {/* Callout */}
            {callout.isCalloutVisble && (
                <ContactCallout data={callout.data} onDismiss={() => setIsCalloutVisible(defaultCalloutState)} isEmail={callout.isEmail} targetId={callout.targetId} />
            )}
        </React.Fragment>
    );
});
