import { isEmptyOrWhitespace, ModelBase } from "@shoothill/core";
import { computed, makeObservable, observable, ObservableMap } from "mobx";
import { nanoid } from "nanoid";

import { Validator } from "Application/Validation";
import { FileModel } from "./FileModel";

export class ImageSettingsModel extends ModelBase<ImageSettingsModel> {
    public KEY: string = nanoid();
    public files = observable<FileModel>([]);
    public removedFiles = observable<FileModel>([]);

    constructor() {
        super();
        makeObservable(this, {
            files: observable,
            removedFiles: observable,
            insideUploadLimit: computed,
        });
    }
    static readonly MAX_FILESIZE_BYTES = 31457280;
    static readonly MAX_FILESIZE_MB = "30MB";

    static getInsideUploadLimit(files: FileModel[]) {
        return (
            files
                .filter((f) => isEmptyOrWhitespace(f.id))
                .map((f) => f.fileSizeBytes)
                .reduce((runningFileSize, fileSizeBytes) => runningFileSize + fileSizeBytes, 0) < ImageSettingsModel.MAX_FILESIZE_BYTES
        );
    }

    public get insideUploadLimit(): boolean {
        return ImageSettingsModel.getInsideUploadLimit(this.files);
    }
}

export class ImageSettingsModelValidator extends Validator<ImageSettingsModel> {
    constructor() {
        super();
        this.ruleFor("files").must({
            predicate: (files) => ImageSettingsModel.getInsideUploadLimit(files),
            message: () => `The total file size should be less than ${ImageSettingsModel.MAX_FILESIZE_MB}`,
        });
    }
}
