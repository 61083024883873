import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

import { QuoteStatus } from "Application/Models/Domain";
import { Validator } from "Application/Validation";
import {
    QUOTESTATUS_APPROVEDTYPE,
    QUOTESTATUS_DRAFTTYPE,
    QUOTESTATUS_NOTYPE,
    QUOTESTATUS_PENDINGAPPROVALTYPE,
    QUOTESTATUS_SENTTYPE,
    USERROLE_ADMIN,
    USERROLE_SENIORASSOCIATE,
} from "Views/Shared/Constants";
import { CustomerModel } from "Views/Shared/Customer/CustomerModel";
import { NotesModel } from "Views/Shared/Note/NotesModel";
import { ServicesModel } from "Views/Shared/Services/ServicesModel";
import { TermsAndConditionsModel } from "Views/Shared/TermsAndConditions/TermsAndConditionsModel";
import { LetterModel } from "Views/Shared/Letter/LetterModel";
import { QuoteModel } from "Views/Shared/Quote/QuoteModel";

export class QuoteFormModel extends ModelBase<QuoteFormModel> {
    // Note: some observables are predefined in the ModelBase class.
    // If any errors arise, check there to see if the property exists
    // and if so, delete it from here.
    // public createdDate: Date | null = null;
    // public updatedDate: Date | null = null;

    public id: string | null = null;
    public quoteStatusId: string | null = null;
    public enquiryId: string | null = null;
    public reference: string = "";

    public createdByUserId = "";
    public updatedByUserId: string | null = null;

    public isDraft: boolean | null = null;
    public isLost: boolean = false;
    public lostNoteSubTypeId: string | null = null;
    public isQuickQuote: boolean = false;
    public isProject: boolean | null = null;
    public issueDate: string | null = null;
    public revision: string | null = null;

    // Hosted in own models.
    public quote = new QuoteModel();
    public customer = new CustomerModel();
    public letterModel = new LetterModel();
    public servicesModel = new ServicesModel();
    public termsAndConditionsModel = new TermsAndConditionsModel();
    public notesModel = new NotesModel();

    constructor() {
        super();
        makeObservable(this, {
            // Observables
            id: observable,
            quoteStatusId: observable,
            enquiryId: observable,
            reference: observable,

            createdByUserId: observable,
            updatedByUserId: observable,

            isDraft: observable,
            isLost: observable,
            lostNoteSubTypeId: observable,
            isQuickQuote: observable,
            isProject: observable,
            issueDate: observable,
            revision: observable,
        });
    }

    // #region Business Logic

    /**
     * Determines if the form data can be edited.
     */
    public hasEditPermission(quoteStatuses: QuoteStatus[], userRoles: string[]) {
        const quoteStatusType = quoteStatuses.find((qs) => qs.id === this.quoteStatusId)?.type ?? "";

        switch (quoteStatusType) {
            case QUOTESTATUS_NOTYPE:
            case QUOTESTATUS_DRAFTTYPE:
            case QUOTESTATUS_PENDINGAPPROVALTYPE:
                return true;

            case QUOTESTATUS_APPROVEDTYPE:
            case QUOTESTATUS_SENTTYPE:
            default:
                return false;
        }
    }

    /**
     * Determines if the form data can be submitted.
     */
    public hasSubmitPermission(quoteStatuses: QuoteStatus[], userRoles: string[]) {
        const quoteStatusType = quoteStatuses.find((qs) => qs.id === this.quoteStatusId)?.type ?? "";

        switch (quoteStatusType) {
            case QUOTESTATUS_NOTYPE:
            case QUOTESTATUS_DRAFTTYPE:
            case QUOTESTATUS_PENDINGAPPROVALTYPE:
                return true;

            case QUOTESTATUS_APPROVEDTYPE:
            case QUOTESTATUS_SENTTYPE:
            default:
                return true;
        }
    }

    /**
     * Determines if the form data can be deleted.
     */
    public hasDeletePermission(quoteStatuses: QuoteStatus[], userRoles: string[]) {
        const quoteStatusType = quoteStatuses.find((qs) => qs.id === this.quoteStatusId)?.type ?? "";

        switch (quoteStatusType) {
            case QUOTESTATUS_DRAFTTYPE:
                return true;

            default:
                return false;
        }
    }

    /**
     * Determines if a Note can still be added.
     */
    public canAddNote(quoteStatuses: QuoteStatus[]) {
        const quoteStatusType = quoteStatuses.find((qs) => qs.id === this.quoteStatusId)?.type ?? "";

        switch (quoteStatusType) {
            case QUOTESTATUS_APPROVEDTYPE:
            case QUOTESTATUS_SENTTYPE:
                return true;

            default:
                return false;
        }
    }

    // #endregion Business Logic
}

export class QuoteFormValidator extends Validator<QuoteFormModel> {
    constructor() {
        super();

        // Quote
        // The quote will take care of its own rules.

        // Customer
        // The customer will take care of its own rules.

        // Letter
        // The letter will take care of its own rules.

        // Services
        // The services will take care of its own rules.

        // Terms and Conditions
        // The terms and conditions will take care of its own rules.

        // Notes
        // The notes will take care of its own rules.
    }
}
