import { observer } from "mobx-react-lite";
import React, { PropsWithChildren, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { IButtonBaseProps, ThemedButton, theme } from "Application";
import { SpaceProps } from "styled-system";

export const StyledButton = styled(ThemedButton)`
    padding: 0px;
    background-color: transparent;
    color: ${(props) => (props.command.canExecute() ? theme.palette.common.primary : theme.palette.field.light)};
    padding: 5px;
    border: none;

    & > span > span > span {
        margin: 0px;
    }

    &:hover,
    &:active,
    &:focus {
        background-color: transparent;
        color: ${(props) => (props.command.canExecute() ? theme.palette.common.primary : theme.palette.field.light)};
        border: none;
        text-decoration: ${(props) => (props.command.canExecute() ? "underline" : "none")};
        outline: 0;
        box-shadow: none;
        padding: 5px;
    }
`;

interface IProps extends PropsWithChildren<IButtonBaseProps & SpaceProps> {}

export const LinkButton: React.FC<IProps> = observer((props: IProps) => {
    const [color, setColor] = useState(theme.palette.field.light);
    useEffect(() => {
        if (props.command.canExecute()) {
            setColor(theme.palette.common.primary);
        } else {
            setColor(theme.palette.field.light);
        }
    }, [props.command.canExecute()]);
    return <StyledButton {...props} styles={{ root: { color } }} />;
});
