import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { nanoid } from "nanoid";

export class UserItemLiteModel extends ModelBase<UserItemLiteModel> {
    // Note: some observables are predefined in the ModelBase class.
    // If any errors arise, check there to see if the property exists
    // and if so, delete it from here.
    // public createdDate: Date | null = null;
    // public updatedDate: Date | null = null;
    // public isDeleted: boolean = false;

    public KEY: string = nanoid();

    public id: string = "";
    public documentUrl: string = "";
    public emailAddress: string = "";
    public firstName: string = "";
    public lastName: string = "";
    public userRole: string = "";
    public lastLoginDate: Date | null = null;
    public lastActionDate: Date | null = null;
    public lastActionLabel: string = "";

    constructor() {
        super();

        makeObservable(this, {
            documentUrl: observable,
            emailAddress: observable,
            firstName: observable,
            lastName: observable,
            userRole: observable,
            lastLoginDate: observable,
            lastActionDate: observable,
            lastActionLabel: observable,
        });
    }
}
