import { runInAction } from "mobx";

import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { ServiceModel } from "../Service/ServiceModel";
import { ServiceViewModel } from "../Service/ServiceViewModel";
import { ServiceGroupViewModel } from "../ServiceGroup/ServiceGroupViewModel";

class ServiceResponse {
    public id: string = "";
    public serviceGroupId: string = "";
    public name: string = "";
    public ordinal: number = 0;
    public manHours: number = 0;
    public hourlyRate: number = 0;
    public updatedDate: Date | null = null;
    public updatedById: string | null = null;
}

class Response {
    public services: ServiceResponse[] = [];
}

export class GETAllServicesLiteByServiceGroupIdEndpoint extends EndpointWithoutRequest<Response> {
    private viewModel: ServiceGroupViewModel;

    constructor(viewModel: ServiceGroupViewModel) {
        super();
        this.viewModel = viewModel;
        this.verb(Http.Get);
        this.path(AppUrls.Server.Admin.Services.GetAllServicesLiteByServiceGroupId.replace(":id", viewModel.model.id));
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            //create serviceModel array
            const serviceModels: ServiceModel[] = [];
            response.services.forEach((serviceResponse) => {
                //create ServiceModel
                const serviceModel = new ServiceModel();
                //populate model
                serviceModel.toModel(serviceResponse);
                //push to model array.
                serviceModels.push(serviceModel);
            });
            //replace services array
            this.viewModel.model.serviceModels.replace(serviceModels);
        });
    }
}
