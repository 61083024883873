import { FieldType, ViewModelBase } from "@shoothill/core";
import { computed, makeObservable } from "mobx";
import { container } from "tsyringe";

import { APIClient, ICommand, RelayCommand, theme } from "Application";
import { ProjectTaskAssignmentStatus } from "Application/Models/Domain";
import { LookupStore } from "Stores/Domain";
import { FilesViewModel } from "Views/Shared/Files/FilesViewModel";
import { GETProjectTaskAssignmentGroupLiteWithRelatedByIdEndpoint } from "./Endpoints/GETProjectTaskAssignmentGroupWithRelatedLiteByIdEndpoint";
import { ProgrammingWeekTaskGroupModel, ProgrammingWeekTaskGroupModelValidator } from "./ProgrammingWeekTaskGroupModel";

export class ProgrammingWeekTaskGroupViewModel extends ViewModelBase<ProgrammingWeekTaskGroupModel> {
    private lookupStore = container.resolve(LookupStore);
    public apiClient = new APIClient();

    // Callback commands to the parent viewmodel.
    public readonly parentSubmitCommand: ICommand;
    public readonly parentCancelCommand: ICommand;

    // Viewmodel to handle files.
    public filesViewModel = new FilesViewModel();

    constructor(projectTaskAssignmentGroupId: string, model: ProgrammingWeekTaskGroupModel, submitCommand: ICommand, cancelCommand: ICommand) {
        super(model, false);

        this.setValidator(new ProgrammingWeekTaskGroupModelValidator());

        // Commands to be called on the parent viewmodel.
        this.parentSubmitCommand = submitCommand;
        this.parentCancelCommand = cancelCommand;

        makeObservable(this, {
            isGroupCompleted: computed,
        });

        // Call the API.
        const _ = this.apiClient.sendAsync(new GETProjectTaskAssignmentGroupLiteWithRelatedByIdEndpoint(projectTaskAssignmentGroupId, this));
    }

    // #region Properties

    // #endregion Properties

    public get isGroupCompleted() {
        return this.model.groupStatusType === "Completed";
    }

    public get isTaskGroupReviewed() {
        return this.model.reviews.length > 0;
    }

    public get referenceDisplayName() {
        return this.model.reference;
    }

    public get projectDisplayName() {
        return this.model.projectName;
    }

    public get projectTaskAssignmentGroupDisplayName() {
        return this.model.projectTaskAssignmentGroupName;
    }

    public get projectTaskAssignmentGroupDisplayNote() {
        return this.model.projectTaskAssignmentGroupNote;
    }

    public get cancelCommandDisplayName() {
        return this.isGroupCompleted ? "OK" : "Cancel";
    }

    public get backgroundColour() {
        switch (true) {
            case this.isGroupCompleted:
                return "#4abb0c";

            case this.isTaskGroupReviewed:
                return theme.palette.quintenary.main;

            default:
                return "#4c7db5";
        }
    }

    // #region Commands

    public updateUserNoteCommand = new RelayCommand(
        (value: string | null) => this.updateField("projectTaskAssignmentUserNote", value),
        () => !this.isGroupCompleted,
    );

    public submitUpdateCommand = new RelayCommand(
        () => {
            if (this.isModelValid()) {
                const updatedStatus = this.getProjectTaskAssignmentStatus("Updated");

                this.setValue("projectTaskAssignmentStatusId", updatedStatus.id);
                this.parentSubmitCommand.execute();
            }
        },
        () => this.parentSubmitCommand.canExecute() && !this.isGroupCompleted,
    );

    public submitCompleteCommand = new RelayCommand(
        () => {
            if (this.isModelValid()) {
                const completeStatus = this.getProjectTaskAssignmentStatus("Complete");

                this.setValue("projectTaskAssignmentStatusId", completeStatus.id);
                this.parentSubmitCommand.execute();
            }
        },
        () => this.parentSubmitCommand.canExecute() && !this.isGroupCompleted,
    );

    public cancelCommand = new RelayCommand(
        () => this.parentCancelCommand.execute(),
        () => this.parentCancelCommand.canExecute(),
    );

    public resetServerErrorCommand = new RelayCommand(() => {
        this.apiClient.reset();
    });

    // #endregion Commands

    // #region Supporting

    private getProjectTaskAssignmentStatus = (statusType: string): ProjectTaskAssignmentStatus => {
        return this.lookupStore.projectTaskAssignmentStatuses.find((pts) => pts.type === statusType)!;
    };

    private updateField(fieldName: keyof FieldType<ProgrammingWeekTaskGroupModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    // #endregion Supporting
}
