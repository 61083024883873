import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

import { RevisionModel } from "Views/Shared/Revisions/RevisionModel";
import { QuoteFormModel } from "./Form/QuoteFormModel";

export class QuoteContainerModel extends ModelBase<QuoteContainerModel> {
    public revisions = observable<RevisionModel>([]);
    public quoteForm = new QuoteFormModel();

    constructor() {
        super();

        makeObservable(this, {
            // Observables
            revisions: observable,
        });
    }
}
