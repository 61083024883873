import { runInAction } from "mobx";

import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { CustomerItemModel } from "Views/Shared/Customer/CustomerItemModel";
import { ProjectsViewModel } from "../ProjectsViewModel";
import { ProjectItemModel } from "../ProjectItemModel";
import { ProjectItemViewModel } from "../ProjectItemViewModel";
import { DownloadCalloutItemModel } from "../Components/DownloadCalloutItemModel";
import { DownloadCalloutItemViewModel } from "../Components/DownloadCalloutItemViewModel";
import { DownloadCalloutViewModel } from "../Components/DownloadCalloutViewModel";

class ProjectLiteResponse {
    public id: string = "";

    public projectStatusId = "";
    public projectStatusName = "";
    public projectStatusForegroundColor = "";

    public customerId = "";

    public projectLeadId: string | null = null;
    public projectLeadFirstName: string | null = null;
    public projectLeadLastName: string | null = null;

    public seniorAssociateId: string | null = null;
    public seniorAssociateFirstName: string | null = null;
    public seniorAssociateLastName: string | null = null;

    public reference = "";
    public title = "";
    public value = 0;
    public noOfTasks: number | null = null;
    public anticipatedEndDate: string | null = null;
    public complete: number | null = null;
    public noOfHours: number | null = null;
}

class CustomerLiteResponse {
    public id: string = "";
    public contactTypeId: string = "";
    public contactTitleId: string | null = null;

    public businessName: string | null = null;
    public prefixName: string = "";
    public firstName: string | null = null;
    public lastName: string | null = null;

    public contactNumber1: string | null = null;
    public contactNumber2: string | null = null;
    public emailAddress: string | null = null;
    public emailAddress2: string | null = null;

    public address1: string = "";
    public address2: string | null = null;
    public address3: string | null = null;
    public city: string = "";
    public postcode: string = "";
}

class ProjectResponse {
    public projectLite: ProjectLiteResponse | null = null;
    public customerLite: CustomerLiteResponse | null = null;
    public quoteDocumentDownloadInformation: ProjectQuoteDownloadInformationLiteResponse[] = [];
}

class ProjectQuoteDownloadInformationLiteResponse {
    public projectId: string = "";
    public quoteId: string = "";
    public quoteTitle: string = "";
    public hasLetter: boolean = false;
    public hasTermsAndConditions: boolean = false;
    public hasHasQuoteTasksAndCosts: boolean = false;
}

class Response {
    public projectResponses: ProjectResponse[] = [];
}

export class GETAllProjectsLiteEndpoint extends EndpointWithoutRequest<Response> {
    private viewModel: ProjectsViewModel;

    constructor(viewModel: ProjectsViewModel) {
        super();
        this.viewModel = viewModel;
        this.verb(Http.Get);
        this.path(AppUrls.Server.Projects.AllProjectsLite);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            const projects: ProjectItemViewModel[] = [];

            response.projectResponses.forEach((response: ProjectResponse) => {
                const model = new ProjectItemModel();

                model.fromResponse(response.projectLite);

                const customerItemModel = new CustomerItemModel();

                customerItemModel.fromResponse(response.customerLite);
                model.customerItemModel = customerItemModel;

                const downloadCalloutItemViewModels: DownloadCalloutItemViewModel[] = [];

                response.quoteDocumentDownloadInformation.forEach((item) => {
                    const downloadCalloutItemModel = new DownloadCalloutItemModel();

                    downloadCalloutItemModel.fromResponse(item);

                    downloadCalloutItemViewModels.push(
                        new DownloadCalloutItemViewModel(
                            downloadCalloutItemModel,
                            this.viewModel.downloadLetterDocumentCommand,
                            this.viewModel.downloadTermsAndConditionsDocumentCommand,
                            this.viewModel.downloadTasksAndCostsDocumentCommand,
                        ),
                    );
                });

                const downloadCalloutViewModel = new DownloadCalloutViewModel(downloadCalloutItemViewModels);

                projects.push(new ProjectItemViewModel(model, downloadCalloutViewModel));
            });

            this.viewModel.projects.replace(projects);
        });
    }
}
