import { IStyle, mergeStyleSets } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React from "react";

import { formatCurrency, ThemedText } from "Application";
import { ServicesViewModel } from "../ServicesViewModel";

interface ITaskFooterTotalViewStyles {
    root: IStyle;
    displayName: IStyle;
    manHours: IStyle;
    hourlyRate: IStyle;
    cost: IStyle;
}

interface ITaskFooterTotalViewProps {
    viewModel: ServicesViewModel;
}

const stylesDefinition: ITaskFooterTotalViewStyles = {
    root: {
        display: "flex",
    },
    displayName: {
        display: "flex",
        flex: 1,
        "> div": {
            alignItems: "center",
            display: "flex",
            flex: "1",
            justifyContent: "end",
            padding: "25px 35px 0 0",
            "> span": {
                fontWeight: "bold",
                textTransform: "uppercase",
            },
        },
    },
    manHours: {
        borderLeft: "1px solid #E1E1E1",
        display: "flex",
        minWidth: "110px",
    },
    hourlyRate: {
        borderLeft: "1px solid #E1E1E1",
        display: "flex",
        minWidth: "110px",
    },
    cost: {
        borderLeft: "1px solid #E1E1E1",
        display: "flex",
        minWidth: "175px",
        "> div": {
            alignItems: "center",
            display: "flex",
            flex: "1",
            justifyContent: "end",
            padding: "25px 63px 0 30px",
            "> span": {
                fontWeight: "bold",
            },
        },
    },
};

export const TaskFooterTotalView: React.FC<ITaskFooterTotalViewProps> = observer((props) => {
    const viewModel = props.viewModel;
    const styles = mergeStyleSets(stylesDefinition);

    return (
        <div className={styles.root}>
            <div className={styles.displayName}>
                <div>
                    <ThemedText fontStyle="p">Total</ThemedText>
                </div>
            </div>
            <div className={styles.manHours} />
            <div className={styles.hourlyRate} />
            <div className={styles.cost}>
                <div>
                    <ThemedText fontStyle="p">{formatCurrency(viewModel.model.predictedCostInclVAT)}</ThemedText>
                </div>
            </div>
        </div>
    );
});
