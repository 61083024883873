import React from "react";
import { Box } from "@mui/material";
import { UnsupportedBrowserView } from "../UnsupportedBrowser/UnsupportedBrowserView";
import { InfoBar } from "../../Components/InfoBar";
import { Outlet } from "react-router";

//EN: use empty generic type to get default react props
export const LayoutNoHeaderOrFooter: React.FC<{}> = (props) => {
    return (
        <>
            {window.IE11 ? (
                <UnsupportedBrowserView />
            ) : (
                <>
                    <Box className={"overflowContainer"}>
                        <InfoBar />
                        <Outlet />
                    </Box>
                </>
            )}
        </>
    );
};
