import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

import { Validator } from "Application/Validation";

export class LetterTemplatesModel extends ModelBase<LetterTemplatesModel> {
    public id: string | null = null;

    public name: string = "";
    public body: string | null = "";

    constructor() {
        super();
        makeObservable(this, {
            id: observable,
            name: observable,
            body: observable,
        });
    }
}

export class LetterTemplatesModelValidator extends Validator<LetterTemplatesModel> {
    constructor() {
        super();

        this.ruleFor("name").notNull().withMessage("Please enter a title").notEmpty().withMessage("Please enter a title");
    }
}
