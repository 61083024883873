import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

export class ProjectChartModel extends ModelBase<ProjectChartModel> {
    public id: string = "";
    public name: string = "";
    public startDate: Date | null = null;
    public endDate: Date | null = null;

    constructor() {
        super();

        makeObservable(this, {
            id: observable,
            name: observable,
            startDate: observable,
            endDate: observable,
        });
    }
}
