import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

import { AutoGrid, Box, ThemedText } from "Application";
import { InvoiceForecastingViewModel } from "./InvoiceForecastingViewModel";
import { ThemedLoadingSpinner } from "Styles/ThemedPrimitives/Style1/ThemedLoadingSpinner";
import { InvoiceForecastingModel } from "./InvoiceForecastingModel";

interface IInvoiceForecastingViewProps {
    model: InvoiceForecastingModel;
}

export const InvoiceForecastingView: React.FC<IInvoiceForecastingViewProps> = observer(({ model }) => {
    const [viewModel] = useState(() => new InvoiceForecastingViewModel(model));

    const gridColumns = "1fr 1fr 1.3fr";
    const gridColumnGap = "5px";

    const renderCapacities = () => (
        <Box>
            {/* Collection of capacities by month */}
            {viewModel.model.capacities.map((model) => {
                return (
                    <AutoGrid key={model.KEY} display={"flex"} mc={gridColumns} tc={gridColumns} dc={gridColumns} columnGap={gridColumnGap} mt="0" mb="2px">
                        <ThemedText fontStyle="superSmall" color="secondary">
                            {model.month}
                        </ThemedText>
                        <ThemedText
                            fontStyle="superSmall"
                            styles={{ root: { textAlign: "right", color: viewModel.capacityTextColour(model), fontWeight: viewModel.capacityTextFontWeight(model) } }}
                        >
                            {viewModel.capacityHours(model.hours)}
                        </ThemedText>
                        <ThemedText
                            fontStyle="superSmall"
                            styles={{ root: { textAlign: "right", color: viewModel.capacityTextColour(model), fontWeight: viewModel.capacityTextFontWeight(model) } }}
                        >
                            {viewModel.capacityValue(model.value)}
                        </ThemedText>
                    </AutoGrid>
                );
            })}
            {/* Total capacity */}
            <AutoGrid display={"flex"} mc={gridColumns} tc={gridColumns} dc={gridColumns} columnGap={gridColumnGap} mt="0" mb="2px">
                <ThemedText fontStyle="superSmallBold">Subtotal</ThemedText>
                <ThemedText fontStyle="superSmallBold" styles={{ root: { textAlign: "right" } }}>
                    {viewModel.totalCapacityHours}
                </ThemedText>
                <ThemedText fontStyle="superSmallBold" styles={{ root: { textAlign: "right" } }}>
                    {viewModel.totalCapacityValue}
                </ThemedText>
            </AutoGrid>
            {/* Remaining holiday values */}
            <AutoGrid display={"flex"} mc={gridColumns} tc={gridColumns} dc={gridColumns} columnGap={gridColumnGap} mt="0" mb="2px">
                <ThemedText fontStyle="superSmallBold">Holiday Left</ThemedText>
                <ThemedText fontStyle="superSmallBold" styles={{ root: { textAlign: "right" } }}>
                    {viewModel.totalRemainingHolidayHours}
                </ThemedText>
                <ThemedText fontStyle="superSmallBold" styles={{ root: { textAlign: "right" } }}>
                    {viewModel.totalRemainingHolidayValue}
                </ThemedText>
            </AutoGrid>{" "}
            {/* Total forecasted capacity */}
            <AutoGrid display={"flex"} mc={gridColumns} tc={gridColumns} dc={gridColumns} columnGap={gridColumnGap} mt="0" mb="2px">
                <ThemedText fontStyle="superSmallBold">Total</ThemedText>
                <ThemedText fontStyle="superSmallBold" styles={{ root: { textAlign: "right" } }}>
                    {viewModel.totalForecastedCapacityHours}
                </ThemedText>
                <ThemedText fontStyle="superSmallBold" styles={{ root: { textAlign: "right" } }}>
                    {viewModel.totalForecastedCapacityValue}
                </ThemedText>
            </AutoGrid>
        </Box>
    );

    const renderInstructed = () => (
        <Box>
            <AutoGrid display={"flex"} mc={gridColumns} tc={gridColumns} dc={gridColumns} columnGap={gridColumnGap} mt="0" mb="2px">
                <ThemedText fontStyle="superSmallBold">Total</ThemedText>
                <ThemedText fontStyle="superSmallBold" styles={{ root: { textAlign: "right" } }}>
                    {viewModel.totalInstructedHours}
                </ThemedText>
                <ThemedText fontStyle="superSmallBold" styles={{ root: { textAlign: "right" } }}>
                    {viewModel.totalInstructedValue}
                </ThemedText>
            </AutoGrid>
        </Box>
    );

    const renderPipeline = () => (
        <Box>
            <AutoGrid display={"flex"} mc={gridColumns} tc={gridColumns} dc={gridColumns} columnGap={gridColumnGap} mt="0" mb="2px">
                <ThemedText fontStyle="superSmallBold">Total</ThemedText>
                <ThemedText fontStyle="superSmallBold" styles={{ root: { textAlign: "right" } }}>
                    {viewModel.totalSentQuoteHours}
                </ThemedText>
                <ThemedText fontStyle="superSmallBold" styles={{ root: { textAlign: "right" } }}>
                    {viewModel.totalSentQuoteValue}
                </ThemedText>
            </AutoGrid>
        </Box>
    );

    return (
        <>
            {renderCapacities()}
            <ThemedText fontStyle="superSmall" mt={"5px"} mb={"5px"}>
                Instructed
            </ThemedText>
            {renderInstructed()}
            <ThemedText fontStyle="superSmall" mt={"5px"} mb={"5px"}>
                Pipeline
            </ThemedText>
            {renderPipeline()}
        </>
    );
});
