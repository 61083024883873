import { runInAction } from "mobx";

import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { AssignTaskViewModel } from "./AssignTaskViewModel";
import { AssignTaskModel } from "./AssignTaskModel";
import { AssignViewModel } from "./AssignSubView/AssignViewModel";

class Request {
    public id: string | null = null;
    public projectTaskId: string | null = null;
    public userId: string | null = null;
    public note: string | null = null;
    public plannedStartDate: Date | null = null;
    public plannedEndDate: Date | null = null;
    public effort: number = 0;
    public billableEffort: number | null = null;
    public isNonBillableEffort = false;
}

class Response {
    public id: string = "";
}

export class POSTSaveProjectTaskAssignmentAsAssignedEndpoint extends Endpoint<Request, Response> {
    private readonly viewModel: AssignTaskViewModel;

    constructor(viewModel: AssignTaskViewModel) {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Resources.SaveProjectTaskAssignmentAsAssigned);
        this.AllowFileUploads();
        this.viewModel = viewModel;
    }

    public async HandleRequestAsync(model: AssignTaskModel): Promise<any> {
        const request = new Request();
        const notesViewModel = this.viewModel.workspaceViewModels.find((vm) => vm instanceof AssignViewModel) as AssignViewModel;

        model.toRequest(request);
        request.projectTaskId = model.id;

        // We need to send the data to the server as form data here as we have files.
        // Note we have no prior documents, so prior document ids are of no concern
        // in this request.
        const formData = new FormData();

        formData.append("data", JSON.stringify(request));

        for (const file of notesViewModel.filesViewModel.model?.files!) {
            formData.append(file.KEY, file.file!);
        }

        return Promise.resolve(formData);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            this.viewModel.parentSubmitCommand.execute(response.id);
        });
    }
}
