import styled from "@emotion/styled";
import { IDatePickerStyles, ProgressIndicator } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import { AutoGrid, Box, ThemedButton, ThemedComboBox, ThemedEditDate, ThemedText } from "Application";
import { setPageTitle } from "Application/Utils";
import { ThemedError } from "Styles/ThemedPrimitives/Style1/ThemedError";
import { ThemedLoader } from "Styles/ThemedPrimitives/Style1/ThemedLoader";
import { FormSeparator } from "Views/Shared/SharedComponents";
import { StaffViewModel } from "./StaffViewModel";
import { StaffItemView } from "./StaffItemView";
import { MyWorkWeekOverview } from "../Details/TableSubViews/MyWorkWeekOverview/MyWorkWeekOverview";
import { CustomSidePanel } from "Views/Shared/CustomSidePanel/CustomSidePanel";
import { TaskView } from "./ConfigureSubViews/TaskView";

const TotalStaffResourcePercentagesWrapper = styled.div``;
const TotalStaffResourcePercentagesContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 5px;
`;

const TotalStaffResourcePercentageBarContainer = styled.div`
    position: relative;

    & > span {
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
        z-index: 100;
    }
`;

const datePickerBaseStyles: IDatePickerStyles = {
    root: { height: 40 },
    textField: {},
    callout: {},
    icon: {},
};

const DatePickerContainer = styled(Box)`
    display: grid;
    column-gap: 15px;
    background: white;
    align-items: center;
    height: 55px;
    width: 100%;
`;

const DatePickerItem = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const EffortStatsBox = styled(Box)`
    align-items: right;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    justify-content: center;
`;

export const StaffView: React.FC = observer(() => {
    // #region Code Behind

    const [viewModel] = useState(() => new StaffViewModel());
    const [workContainerStylesOverrides, setWorkContainerStylesOverrides] = useState({ justifyContent: "flex-end" } as React.CSSProperties);

    useEffect(() => {
        setPageTitle("Staff");
    }, []);

    useEffect(() => {
        if (viewModel.taskViewModel) {
            setWorkContainerStylesOverrides({ justifyContent: "flex-end", marginRight: "475px", width: "calc(100vw - 100px)" });
        } else {
            setWorkContainerStylesOverrides({ justifyContent: "flex-end" });
        }
    }, [viewModel.taskViewModel]);

    const renderBusy = () => <ThemedLoader isOpen={viewModel.apiClient.IsBusy} />;

    const renderError = () => {
        const isOpen = viewModel.apiClient.IsSubmitted && !viewModel.apiClient.IsBusy && viewModel.apiClient.HaveValidationMessage;

        return <ThemedError command={viewModel.resetServerErrorCommand} isOpen={isOpen} errorMessage={viewModel.apiClient.ValidationMessage} />;
    };

    const datePickerContainerStyles: React.CSSProperties = {
        gridTemplateColumns: `${viewModel.showApplyDateFilterButton ? "160px repeat(3, 175px) 80px" : "160px repeat(3, 175px)"}`,
    } as React.CSSProperties;

    const renderWorkspace = () => (
        <>
            {/* Filter Bar */}
            <Box display={"flex"} justifyContent={"center"} alignItems={"space-between"} flexDirection="column">
                <AutoGrid m={0} columnGap="30px" width="100%" dc={"3fr 4fr minmax(250px,1fr)"} alignItems="center">
                    <DatePickerContainer style={datePickerContainerStyles}>
                        <ThemedText fontStyle="h4">Resources available:</ThemedText>
                        <ThemedComboBox
                            command={viewModel.updateFinancialPeriodTypeCommand}
                            options={viewModel.financialPeriodTypes}
                            size="larger"
                            value={() => viewModel.getValue("financialPeriodTypeId")}
                            showLabel={false}
                        />
                        <DatePickerItem>
                            <ThemedEditDate
                                command={viewModel.updateFilterStartDateCommand}
                                placeholder="Start date"
                                size="larger"
                                value={() => viewModel.getValue("filterStartDate")}
                                showLabel={false}
                                styles={datePickerBaseStyles}
                                validationMessage={() => viewModel.getError("filterStartDate")}
                                showErrorMessage={false}
                                encloseErrorLabel
                            />
                        </DatePickerItem>
                        <DatePickerItem>
                            <ThemedEditDate
                                command={viewModel.updateFilterEndDateCommand}
                                placeholder="Start date"
                                size="larger"
                                value={() => viewModel.getValue("filterEndDate")}
                                showLabel={false}
                                styles={datePickerBaseStyles}
                                validationMessage={() => viewModel.getError("filterEndDate")}
                                showErrorMessage={false}
                                encloseErrorLabel
                            />
                        </DatePickerItem>
                        {viewModel.showApplyDateFilterButton && <ThemedButton displayName="Apply" paletteColor="primary" command={viewModel.getAllStaffCommand} />}
                    </DatePickerContainer>
                    <TotalStaffResourcePercentagesWrapper>
                        <TotalStaffResourcePercentagesContainer>
                            <TotalStaffResourcePercentageBarContainer>
                                <ThemedText fontStyle="h7">{`${viewModel.totalStaffEffortHoursAllocation} out of ${viewModel.totalBusinessHours} staff hours programmed `}</ThemedText>
                                <ProgressIndicator
                                    styles={{
                                        itemProgress: {
                                            borderRadius: 24,
                                            height: 20,
                                            padding: 0,
                                            width: "100%",
                                        },
                                        progressBar: {
                                            backgroundColor: viewModel.totalStaffEffortPercentageAllocation <= 100 ? "#96D8A3" : "#FEC97A",
                                        },
                                    }}
                                    percentComplete={viewModel.effortItemProgress}
                                    barHeight={20}
                                />
                            </TotalStaffResourcePercentageBarContainer>
                            <TotalStaffResourcePercentageBarContainer>
                                <ThemedText fontStyle="h7">{`${viewModel.totalStaffChargeableEffortHoursAllocation} out of ${viewModel.totalChargeableBusinessHours} hours of chargeable time programmed `}</ThemedText>
                                <ProgressIndicator
                                    styles={{
                                        itemProgress: {
                                            borderRadius: 24,
                                            height: 20,
                                            padding: 0,
                                            width: "100%",
                                        },
                                        progressBar: {
                                            backgroundColor: viewModel.totalStaffChargeableEffortPercentageAllocation <= 100 ? "#FEC97A" : "#96D8A3",
                                        },
                                    }}
                                    percentComplete={viewModel.chargeableEffortItemProgress}
                                    barHeight={20}
                                />
                            </TotalStaffResourcePercentageBarContainer>
                        </TotalStaffResourcePercentagesContainer>
                        {viewModel.canDisplay100PercentProgress() && (
                            <Box className="maxPercentLine" width={`calc(${viewModel.percentProgress}% - ${(viewModel.percentProgress * 7) / 100}px)`} />
                        )}
                    </TotalStaffResourcePercentagesWrapper>
                    <EffortStatsBox>
                        {viewModel.canDisplayTotalBillableEffort && (
                            <Box>
                                <b>TARGET HOURS:</b> {viewModel.totalChargeableBusinessHours}
                            </Box>
                        )}
                        {viewModel.canDisplayTotalBilledEffort && (
                            <Box>
                                <b>BILLED HOURS:</b> {viewModel.totalBilledEffort}
                            </Box>
                        )}
                        {viewModel.canDisplayOverage && (
                            <Box>
                                <b>OVERAGE:</b>
                                <span style={{ color: viewModel.overageHoursStatusColour }}> {viewModel.overage}</span>
                            </Box>
                        )}
                    </EffortStatsBox>
                </AutoGrid>
                <FormSeparator />
            </Box>

            {/* Calendar */}
            {viewModel.canDisplayWorkCalendar && (
                <MyWorkWeekOverview
                    workCalendarViewModel={viewModel.workCalendarViewModel!}
                    show={viewModel.showCalendar}
                    closeOverviewCommand={viewModel.closeMyWorkWeekOverviewCommand}
                    origin={viewModel.myWorkWeekOverviewOrigin}
                    height={viewModel.myWorkWeekOverviewHeight}
                    width={viewModel.myWorkWeekOverviewWidth}
                    overlayColor="transparent"
                    showCloseButton
                    styleOverrides={workContainerStylesOverrides}
                />
            )}

            {/* Task View */}
            <CustomSidePanel
                isOpen={viewModel.canDisplayTask}
                onDismiss={() => {
                    viewModel.cancelTaskCommand.execute();
                }}
            >
                <TaskView viewModel={viewModel.taskViewModel!} />
            </CustomSidePanel>

            {/* Staff Navigation */}
            {viewModel.canDisplayStaffNavigation && (
                <Box>
                    {viewModel.staffItemViewModels.map((vm) => {
                        return <StaffItemView key={vm.KEY} viewModel={vm} parentViewModel={viewModel} maximumPercentage={viewModel.maximumPercentageAllocation} />;
                    })}
                </Box>
            )}
        </>
    );

    return (
        <Box>
            {renderWorkspace()}
            {renderBusy()}
            {renderError()}
        </Box>
    );
});
