import { isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { Client } from "getaddress-api";
import { computed, makeObservable, runInAction } from "mobx";

import { ICommand, RelayCommand } from "Application";
import { FindAddressModel, MatchingAddress } from "./FindAddressModel";
import { nanoid } from "nanoid";

export class FindAddressViewModel extends ViewModelBase<FindAddressModel> {
    private apiClient = new Client("sltYkKHMOkaQ-7Tal-HLRw36070");
    private onAddressSelectedCommand: ICommand;

    constructor(onAddressSelectedCommand: ICommand) {
        super(new FindAddressModel());

        this.onAddressSelectedCommand = onAddressSelectedCommand;

        makeObservable(this, {
            // Computed,
            addresses: computed,
            hasAddresses: computed,
        });
    }

    // #region Properties

    public get addresses() {
        return this.model.addresses.map((item: MatchingAddress, index: number) => {
            return {
                key: item.id,
                text: (item.address as any).formatted_address.filter((part: string) => !isEmptyOrWhitespace(part)).join(", "),
            };
        });
    }

    public get hasAddresses() {
        return this.model.addresses.length > 0;
    }

    // #endregion Properties

    // #region Commands

    public updatedPostcodeCommand = new RelayCommand((postcode: string) => {
        this.setValue("postcode", postcode);
    });

    public findAddressesCommand = new RelayCommand(async () => {
        const response = await this.apiClient.find(this.model.postcode);

        if (response.isSuccess) {
            const success = response.toSuccess();

            this.model.status = 0;
            this.model.message = "";

            this.model.postcode = success.addresses.postcode;
            this.model.latitude = success.addresses.latitude;
            this.model.longitude = success.addresses.longitude;
            this.model.addresses.replace(
                success.addresses.addresses.map<MatchingAddress>((address: any) => {
                    return {
                        id: nanoid(),
                        address: address,
                    };
                }),
            );
        } else {
            const failed = response.toFailed();

            this.model.status = failed.status;
            this.model.message = failed.message;

            this.model.postcode = "";
            this.model.latitude = 0;
            this.model.longitude = 0;
            this.model.addresses.replace([]);
        }
    });

    public selectAddressCommand = new RelayCommand((id: string) => {
        const selectedAddress = this.model.addresses.find((address) => address.id === id);

        this.onAddressSelectedCommand.execute(selectedAddress?.address, this.model.postcode);
    });

    // #endregion Commands
}
