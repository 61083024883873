import { FieldType, formatCurrency, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { computed, makeObservable, observable } from "mobx";
import { TaskGroupViewModel } from "./TaskGroupViewModel";

import { TaskModel, TaskModelValidator } from "./TaskModel";

export class TaskViewModel extends ViewModelBase<TaskModel> {
    public apiClient = new APIClient();
    private removeTaskModelCommand: ICommand;
    public parentViewModel: TaskGroupViewModel;

    public labelEditView = false;
    public labelCurrentValue = "";

    constructor(parentViewModel: TaskGroupViewModel, taskServiceItem: TaskModel = new TaskModel(), removeTaskModelCommand: ICommand) {
        super(taskServiceItem);

        this.removeTaskModelCommand = removeTaskModelCommand;
        this.parentViewModel = parentViewModel;
        this.setValidator(new TaskModelValidator());

        makeObservable(this, {
            // Observables
            labelEditView: observable,
            labelCurrentValue: observable,
            // Computeds
            displayName: computed,
        });
    }

    // #region Properties

    public get displayName() {
        return this.model.taskName;
    }

    public get labelEditing() {
        return this.labelEditView;
    }

    public get KEY() {
        return this.model.KEY;
    }

    public get formattedHourlyRate() {
        if (this.model.hourlyRate) return formatCurrency(this.model.hourlyRate);

        return "";
    }

    public get formattedPredictedCostExclVAT() {
        if (this.model.hourlyRate) return formatCurrency(this.model.predictedCostExclVAT);

        return "";
    }

    // #endregion Properties

    // #region Commands

    public updateHourlyRateCommand = new RelayCommand((value: string) => {
        const parsedValue = parseFloat(value);
        const checkedValue = Number.isNaN(parsedValue) ? null : parsedValue;

        this.updateField("hourlyRate", checkedValue);
    });

    public updateManHoursCommand = new RelayCommand((value: string) => {
        const parsedValue = parseFloat(value);
        const checkedValue = Number.isNaN(parsedValue) ? null : parsedValue;

        this.updateField("manHours", checkedValue);
    });

    public updateTaskNameCommand = new RelayCommand((value: string) => {
        this.updateField("taskName", value);
    });

    public editLabelCommand = new RelayCommand(() => {
        this.labelEditView = true;
    });

    public updateLabelCommand = new RelayCommand((value: string) => {
        // for temporary undo
        this.labelCurrentValue = this.displayName;

        // set current view
        this.updateField("taskName", value);
    });

    public saveUpdateLabelCommand = new RelayCommand(() => {
        this.labelEditView = false;
    });

    public cancelUpdateLabelCommand = new RelayCommand(() => {
        if (this.labelCurrentValue.length > 0) {
            this.updateField("taskName", this.labelCurrentValue);
        }
        this.labelEditView = false;
    });

    /**
     * Command to remove this task.
     */
    public removeCommand = new RelayCommand(() => {
        this.removeTaskModelCommand.execute(this.model);
    });

    // #endregion Commands

    private updateField(fieldName: keyof FieldType<TaskModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }
}
