import { mergeStyleSets } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React from "react";

import { ThemedText } from "Application";
import { RevisionViewModel } from "./RevisionViewModel";

export interface IRevisionViewProps {
    viewModel: RevisionViewModel;
}

export const RevisionView: React.FC<IRevisionViewProps> = observer((props) => {
    const viewModel = props.viewModel;
    const styles = mergeStyleSets({
        root: {
            alignItems: "center",
            borderTop: "1px solid #D4D4D4",
            borderLeft: "1px solid #D4D4D4",
            borderRight: "1px solid #D4D4D4",
            justifyContent: "center",
            display: "flex",
            padding: "0 15px",
            backgroundColor: viewModel.isActive ? "white" : "#FCFCFC",
            cursor: viewModel.isActive ? "none" : "pointer",
            height: viewModel.isActive ? "30px" : "21px",
            marginBottom: viewModel.isActive ? "0px" : "1px",
        },
    });

    const labelColor = viewModel.isActive ? "default" : "dropZoneText";

    return (
        <div className={styles.root} onClick={() => viewModel.navigateToRevision.execute()}>
            <ThemedText color={labelColor} fontStyle="h6">
                {viewModel.displayName}
            </ThemedText>
        </div>
    );
});
