import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { ThemedButton, ThemedText } from "Application";
import { ServiceGroupModel } from "./ServiceGroupModel";
import { ServiceGroupViewModel } from "./ServiceGroupViewModel";
import { ServiceGroupsViewModel } from "../ServiceGroupsViewModel";
import { APICommonComponents } from "Application/Helpers/APICommonComponents";
import { AddServiceView } from "../Service/Views/AddServiceView";
import { AddEditServiceGroupView } from "./Views/AddEditServiceGroupView";
import { ReadOnlyServiceGroupView } from "./Views/ReadOnlyServiceGroupView";
import { ServicesListView } from "./Views/ServicesListView";
import { CollapsibleContainer, Divider, PseudoTableCell, PseudoTableHeader, ThemedButtonStyles } from "./ServiceGroup.styles";
import { DeleteServiceGroupModalView } from "./Modals/DeleteServiceGroupModalView";

interface IProps {
    parentViewModel: ServiceGroupsViewModel;
    model: ServiceGroupModel;
}

export const ServiceGroupView: React.FC<IProps> = observer((props: IProps) => {
    const [viewModel] = useState(new ServiceGroupViewModel(props.parentViewModel, props.model));

    const ref: any = useRef(null);
    const clickListener = (e: Event) => {
        //check if the click has been registered outside the component
        if (ref.current && !ref.current.contains(e.target)) {
            //hide the input field
            viewModel.cancelUpdateServiceGroupCommand.execute();
        }
    };

    useEffect(() => {
        //listen on the document container for a click and pass the callback that handles the input
        document.addEventListener("click", clickListener, true);
    });

    useEffect(() => {
        viewModel.getAllServicesByServiceGroupId();
    }, [viewModel.getValue<boolean>("isServicesListExpanded")]);

    const renderItemHeader = () => {
        if (viewModel.isInAddEditMode) {
            return <AddEditServiceGroupView viewModel={viewModel} />;
        } else {
            return <ReadOnlyServiceGroupView viewModel={viewModel} />;
        }
    };

    const render = () => {
        return (
            <>
                {renderItemHeader()}
                <CollapsibleContainer className={viewModel.getValue<boolean>("isServicesListExpanded") ? "expanded" : ""}>
                    <PseudoTableHeader className={viewModel.getValue<boolean>("isServicesListExpanded") ? "expanded" : ""}>
                        <PseudoTableCell>
                            <ThemedText marginLeft={"5px"} fontStyle="button">
                                Service title
                            </ThemedText>
                        </PseudoTableCell>

                        <PseudoTableCell>
                            <ThemedText marginLeft={"5px"} fontStyle="button">
                                Linked to planning application
                            </ThemedText>
                        </PseudoTableCell>

                        <PseudoTableCell>
                            <ThemedText marginLeft={"5px"} fontStyle="button">
                                Default hours
                            </ThemedText>
                        </PseudoTableCell>

                        <PseudoTableCell>
                            <ThemedText marginLeft={"5px"} fontStyle="button">
                                Hourly rate
                            </ThemedText>
                        </PseudoTableCell>
                        <div />
                    </PseudoTableHeader>

                    <ServicesListView viewModel={viewModel} />

                    {viewModel.getValue<boolean>("isServicesListExpanded") && (
                        <>
                            {viewModel.newServiceViewModel && <AddServiceView viewModel={viewModel.newServiceViewModel} />}
                            <ThemedButton
                                marginTop={25}
                                marginBottom={25}
                                paletteColor="primary"
                                command={viewModel.displayNewServiceCommand}
                                displayName={"New Service"}
                                styles={ThemedButtonStyles}
                            />
                            <Divider />
                        </>
                    )}
                </CollapsibleContainer>
            </>
        );
    };

    return (
        <div ref={ref}>
            {render()}
            <DeleteServiceGroupModalView viewModel={viewModel} />
            {/* Busy Overlay */}
            {APICommonComponents(viewModel.apiClient).renderBusy()}
            {/* Error Overlay */}
            {APICommonComponents(viewModel.apiClient).renderError()}
        </div>
    );
});
