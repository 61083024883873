import "@emotion/react";
import { IShoothillTheme } from "./IShoothillTheme";
import { initializeIcons } from "@fluentui/react";

export const pxToRem = (value: number) => {
    return `${value / 16}rem`;
};
export const adobeXDToRem = (fontSize: number, letterSpacing: number): string => {
    return pxToRem((letterSpacing / 1000) * fontSize) + "rem";
};
//Enable FluentUI Icon support
initializeIcons();

//Define your custom breakpoints here or accept the default values which are the standard common breakpoints
let customBreakpointValues = {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
};

export const BaseStyleSheet: IShoothillTheme = {
    // Spacing in pixels.
    space: [0, 5, 10, 15, 20, 25, 30, 40],

    // Sizes. Keep numbers divisible by 2.
    sizes: {
        larger: pxToRem(40),
        large: pxToRem(30),
        medium: pxToRem(26),
        small: pxToRem(20),
    },
    // Colours and Palette.
    /* colors: {
        primary: "#0078d4",
        positive: "#0078d4",
        negative: "#e81123",
    },*/
    palette: {
        // Use this for your default text.
        common: {
            transparent: "transparent",
            white: "#fff",
            black: "#000",
            // FluentUI focus color. Do not edit!
            focus: "#0078d4",
            // Off-black.
            default: "#171716",
            // Mid blue.
            primary: "#2d91ff",
            // Mid orange.
            secondary: "#FF4B22",
            // Mid red.
            tertiary: "#DC3545",
            dropZoneText: "#8D8D8D",
            // Mid green.
            quarternary: "#23A73D",
            boxBackground: "#FCFCFC",
            // red
            error: "#DC3545",
            lightBlue: "#17A2B8",
            grayishBlue: "#4C7DB5",
            complete: "#4ABB0C",
            lightGray: "#D4D2D7",
            lighterGray: "#E1E1E1",
            deepRed: "#AC0011",
            border: "#D4D4D4",
            purple: "#C15DB0",
            teal: "#11A696",
            lightOrange: "#F5C57C",
            lighterOrange: "#F7DFBA",
        },

        // Use these for your controls - those that have a visual state - focused, hover, pressed etc.
        // Light defines the default state with main and dark being non-default states.

        // Light grey background with white text
        disabled: { light: "#f3f2f1", main: "#f3f2f1", dark: "#f3f2f1", contrastText: "#a19f9d", text: "#a19f9d" },
        // Dark grey background with white text
        default: { light: "#575756", main: "#4e4e4d", dark: "#424241", contrastText: "#171716", text: "#fff" },
        // Mid blue background with white text
        primary: { light: "#2d91ff", main: "#2984e6", dark: "#2370c2", contrastText: "#171716", text: "#fff" },
        // Mid orange background with white text
        secondary: { light: "#ff6821", main: "#ff4b22", dark: "#7e2510", contrastText: "#171716", text: "#fff" },
        // Mid red background with white text
        tertiary: { light: "#DC3545", main: "#c52f3e", dark: "#a72835", contrastText: "#171716", text: "#fff" },
        // Mid green background with white text
        quarternary: { light: "#23A73D", main: "#1f9537", dark: "#1a7e2e", contrastText: "#171716", text: "#fff" },

        quintenary: { light: "#e5ddd1", main: "#7D5519", dark: "#7D5519", contrastText: "#171716", text: "#fff" },
        // Dark transparent background with off-black text
        defaultTr: { light: "transparent", main: "#f3f2f1", dark: "#edebe9", contrastText: "#171716", text: "#171716" },

        // Light grey border with dark grey for hover/focus and off-black text.
        field: { light: "#DCDCDC", main: "#4b4b4b", dark: "#323232", contrastText: "#171716", text: "#171716" },
        tableHeader: { light: "#DCDCDC", main: "#4b4b4b", dark: "#323232", contrastText: "#171716", text: "#191919" },

        // Mid red background with mid red text
        error: { light: "#DC3545", main: "#c52f3e", dark: "#a72835", contrastText: "#fff", text: "#DC3545" },

        warning: { light: "#ffb74d", main: "#ff9800", dark: "#f57c00", contrastText: "rgba(0, 0, 0, 0.87)", text: "#fff" },
        info: { light: "#64b5f6", main: "#2196f3", dark: "#1976d2", contrastText: "#fff", text: "#fff" },
        success: { light: "#81c784", main: "#4caf50", dark: "#388e3c", contrastText: "rgba(0, 0, 0, 0.87)", text: "#fff" },
        hint: { light: "#6d6d6d", dark: "#1b1b1b", main: "#424242", contrastText: "#fff", text: "#000" },

        // Add your Custom Colours below and in IShoothillTheme.ts file
        purpleAccent: { light: "rgb(198, 51, 255)", main: "#b800ff", dark: "rgb(128, 0, 178)", contrastText: "rgba(255, 255, 255, 0.87)", text: "pink" }, //This is a custom colour
        blueAccent: { light: "#C9E3FF", main: "#2D91FF", dark: "#2370c2", contrastText: "#171716", text: "#fff" },
    },

    // Fonts and Text.
    defaultFontStyle: {
        fontFamily: `"Poppins", sans-serif`,
        fontSize: pxToRem(16),
        fontWeight: 400,
        letterSpacing: "normal",
        lineHeight: "1.5",
        textTransform: "none",
    },
    fontStyles: {
        title: {
            fontSize: pxToRem(16),
            letterSpacing: pxToRem(0.48),
            lineHeight: "1.375",
        },
        titleBold: {
            fontSize: pxToRem(16),
            letterSpacing: pxToRem(0.48),
            lineHeight: "1.375",
            fontWeight: 600,
        },
        titleBoldXL: {
            fontSize: pxToRem(34),
            letterSpacing: pxToRem(1.02),
            lineHeight: pxToRem(18),
            fontWeight: 600,
        },
        h1: {
            fontSize: pxToRem(24),
            letterSpacing: pxToRem(0.72),
        },
        h2: {
            fontSize: pxToRem(18),
            letterSpacing: pxToRem(0.54),
        },
        h3: {
            fontSize: pxToRem(14),
            fontWeight: 600,
            letterSpacing: pxToRem(0.42),
            textTransform: "uppercase",
        },
        h4: {
            fontSize: pxToRem(14),
            letterSpacing: pxToRem(0.42),
        },
        h5: {
            fontSize: pxToRem(12),
            letterSpacing: pxToRem(0.36),
        },
        h6: {
            fontSize: pxToRem(10),
            letterSpacing: pxToRem(0.3),
            textTransform: "uppercase",
        },
        h7: {
            fontSize: pxToRem(10),
            letterSpacing: pxToRem(0.3),
        },
        h8: {
            fontSize: pxToRem(14),
            fontWeight: 600,
            letterSpacing: pxToRem(0.42),
        },
        p: {
            fontSize: pxToRem(12),
            letterSpacing: pxToRem(0.36),
        },
        pBold: {
            fontSize: pxToRem(12),
            fontWeight: 600,
            letterSpacing: pxToRem(0.36),
        },
        button: {
            fontSize: pxToRem(10),
            letterSpacing: pxToRem(0.3),
            textTransform: "uppercase",
        },
        error: {
            fontSize: pxToRem(10),
            letterSpacing: pxToRem(-0.15),
        },
        field: {
            fontSize: pxToRem(12),
            letterSpacing: pxToRem(-0.18),
        },
        tableHeader: {
            fontSize: pxToRem(14),
            fontWeight: 700,
            letterSpacing: pxToRem(-0.18),
            lineHeight: "42px",
        },
        label: {
            fontSize: pxToRem(10),
            fontWeight: 400,
            letterSpacing: pxToRem(-0.15),
            textTransform: "uppercase",
        },
        labelXS: {
            fontSize: pxToRem(9),
            fontWeight: 600,
            letterSpacing: pxToRem(0.24),
            lineHeight: pxToRem(10),
        },
        checkboxLabel: {
            fontSize: pxToRem(12),
            letterSpacing: pxToRem(0.36),
        },
        radioButtonLabel: {
            fontSize: pxToRem(12),
            letterSpacing: pxToRem(0.36),
        },
        headerOptionLabel: {
            fontSize: pxToRem(12),
            letterSpacing: pxToRem(0.6),
            textTransform: "uppercase",
            fontFamily: `"HeadingNot55Medium", sans-serif`,
        },
        // description: {

        // }
        customerBarLabel: {
            fontSize: pxToRem(10),
            letterSpacing: pxToRem(0.3),
        },
        expandable: {
            fontSize: pxToRem(12),
            fontWeight: 600,
            letterSpacing: pxToRem(0.36),
            textTransform: "uppercase",
        },
        workItemLabelBold: {
            fontSize: pxToRem(10),
            fontWeight: 600,
            letterSpacing: pxToRem(0.3),
        },
        workItemLabelRegular: {
            fontSize: pxToRem(10),
            fontWeight: 400,
            letterSpacing: pxToRem(0.3),
        },
        workItemStatus: {
            fontSize: pxToRem(8),
            fontWeight: 600,
            letterSpacing: pxToRem(0.24),
            textTransform: "uppercase",
        },
        superSmall: {
            fontSize: pxToRem(8),
            fontWeight: 400,
            letterSpacing: pxToRem(0.24),
        },
        superSmallBold: {
            fontSize: pxToRem(8),
            fontWeight: 600,
            letterSpacing: pxToRem(0.24),
        },
    },
    text: {
        fontSizes: {
            h1: pxToRem(96),
            h2: pxToRem(60),
            h3: pxToRem(48),
            h4: pxToRem(32),
            h5: pxToRem(20),
            h6: pxToRem(18),
            subtitle1: pxToRem(16),
            subtitle2: pxToRem(14),
            body1: pxToRem(16),
            body2: pxToRem(14),
            button: pxToRem(16),
            caption: pxToRem(14),
            tiny: pxToRem(10),
            xSmall: pxToRem(10),
            small: pxToRem(12),
            smallPlus: pxToRem(12),
            medium: pxToRem(14),
            mediumPlus: pxToRem(16),
            large: pxToRem(18),
            xLarge: pxToRem(20),
            xxLarge: pxToRem(28),
            mega: pxToRem(68),
        },
    },
};
