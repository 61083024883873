import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React from "react";

import { ICommand } from "../../Application/Commands";

export interface Props {
    /**
     * An optional class name for use with the button.
     */
    className?: string;
    /**
     * An optional class name for use with the button when it is selected.
     */
    selectedClassName?: string;
    /**
     * A command to execute.
     */
    command: ICommand;
    /**
     * Text content to display as the label of the date control.
     */
    displayName?: string;
    /**
     * States if the option is in a selected state.
     */
    isSelected: boolean;

    show: boolean;
}

export const HeaderLink: React.FC<Props> = observer((props) => {
    // #region Code Behind

    const getClassNames = (): string => {
        return clsx({
            [props.className!]: !isEmptyOrWhitespace(props.className),
            [props.selectedClassName!]: props.isSelected && !isEmptyOrWhitespace(props.selectedClassName),
        });
    };

    const isVisible = (): boolean => {
        const retVal = isNullOrUndefined(props.command.canExecute) ? true : props.command.canExecute();
        return props.show && retVal;
    };

    const onClick = (): void => {
        props.command.execute();
    };

    // #endregion Code Behind

    return isVisible() ? (
        <div className={getClassNames()} onClick={onClick}>
            <div>{props.displayName}</div>
        </div>
    ) : null;
});
