import { isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

import { UserItemLiteModel } from "./UserItemLiteModel";

export class UserItemLiteViewModel extends ViewModelBase<UserItemLiteModel> {
    constructor(userItemModel: UserItemLiteModel = new UserItemLiteModel()) {
        super(userItemModel);
        makeObservable(this, {});
    }

    public get KEY() {
        return this.model.KEY;
    }

    public get id() {
        return this.model.id;
    }

    public get firstName() {
        return this.model.firstName;
    }

    public get lastName() {
        return this.model.lastName;
    }

    public get emailAddress() {
        return this.model.emailAddress;
    }

    public get userRole() {
        return this.model.userRole;
    }

    public get lastLoginDate() {
        return this.model.lastLoginDate;
    }

    public get documentUrl() {
        return this.model.documentUrl;
    }

    public get lastActionDate() {
        return this.model.lastActionDate;
    }

    public get lastActionLabel() {
        return this.model.lastActionLabel;
    }

    public filterPredicate = (filterKeyword: string): boolean => {
        let result = true;
        if (result) {
            const lowerCaseFilterKeyword = filterKeyword.toLowerCase();
            switch (true) {
                case isEmptyOrWhitespace(filterKeyword):
                case this.firstName.toLowerCase().includes(lowerCaseFilterKeyword) || this.lastName.toLowerCase().includes(lowerCaseFilterKeyword):
                    result = true;
                    break;

                default:
                    result = false;
                    break;
            }
        }

        return result;
    };
}
