import styled from "@emotion/styled";
import { isEmptyOrWhitespace } from "@shoothill/core";
import React from "react";
import { observer } from "mobx-react-lite";

import { Box, Else, Show, ThemedText } from "Application";
import { NoteViewModel } from "./NoteViewModel";
import { FilesReadOnlyView } from "../Files/FilesReadOnlyView";

const NoteContainer = styled(Box)`
    background: #fcfcfc 0 0 no-repeat padding-box;
    box-shadow: 0 2px 3px #00000029;
    border-radius: 1px;
    padding: 10px 15px;
`;

interface Props {
    viewModel: NoteViewModel;
}

export const NoteReadOnlyView: React.FC<Props> = observer((props: Props) => {
    const { viewModel } = props;

    return (
        <Box mt={3}>
            <NoteContainer>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Box>
                        <ThemedText fontStyle="h6">{viewModel.noteDetails}</ThemedText>
                        {viewModel.canDisplayNoteReason && <ThemedText fontStyle="h7">{viewModel.noteReason}</ThemedText>}
                    </Box>
                    <Show if={!isEmptyOrWhitespace(viewModel.getValue("id"))}>
                        <ThemedText fontStyle="h7">{viewModel.userDetails}</ThemedText>
                        <Else>
                            <ThemedText fontStyle="h7"> </ThemedText>
                        </Else>
                    </Show>
                </Box>
                <Box display={"flex"} justifyContent={"space-between"} marginTop={"5px"}>
                    <ThemedText fontStyle="h7">{viewModel.getValue("noteDetail")}</ThemedText>
                </Box>
                <FilesReadOnlyView viewModel={props.viewModel.filesViewModel} />
            </NoteContainer>
        </Box>
    );
});
