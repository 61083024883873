import { isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

import { ICommand, RelayCommand } from "Application";
import { DownloadCalloutItemModel } from "./DownloadCalloutItemModel";

export class DownloadCalloutItemViewModel extends ViewModelBase<DownloadCalloutItemModel> {
    public parentDownloadLetterDocumentCommand: ICommand;
    public parentDownloadTermsAndConditionsDocumentCommand: ICommand;
    public parentDownloadQuoteTasksDocumentCommand: ICommand;

    constructor(
        model: DownloadCalloutItemModel,
        downloadLetterDocumentCommand: ICommand,
        downloadTermsAndConditionsDocumentCommand: ICommand,
        downloadTasksAndCostsDocumentCommand: ICommand,
    ) {
        super(model, false);

        this.parentDownloadLetterDocumentCommand = downloadLetterDocumentCommand;
        this.parentDownloadTermsAndConditionsDocumentCommand = downloadTermsAndConditionsDocumentCommand;
        this.parentDownloadQuoteTasksDocumentCommand = downloadTasksAndCostsDocumentCommand;

        makeObservable(this, {});
    }

    public get canDisplayTitle() {
        return !isEmptyOrWhitespace(this.model.quoteTitle);
    }

    public get canDisplayDownloadOptions() {
        return this.canDisplayLetterDownloadOption || this.canDisplayTermsAndConditionsDownloadOption || this.canDisplayQuoteTasksAndCostsDownloadOption;
    }

    public get canDisplayLetterDownloadOption() {
        return this.model.hasLetter;
    }

    public get canDisplayTermsAndConditionsDownloadOption() {
        return this.model.hasTermsAndConditions;
    }

    public get canDisplayQuoteTasksAndCostsDownloadOption() {
        return this.model.hasQuoteTasksAndCosts;
    }

    public downloadQuoteLetterDocumentCommand = new RelayCommand(
        () => this.parentDownloadLetterDocumentCommand.execute(this.model.quoteId),
        () => this.canDisplayLetterDownloadOption && this.parentDownloadLetterDocumentCommand.canExecute(),
    );

    public downloadQuoteTermsAndConditionsDocumentCommand = new RelayCommand(
        () => this.parentDownloadTermsAndConditionsDocumentCommand.execute(this.model.quoteId),
        () => this.canDisplayTermsAndConditionsDownloadOption && this.parentDownloadTermsAndConditionsDocumentCommand.canExecute(),
    );

    public downloadQuoteTasksAndCostsDocumentCommand = new RelayCommand(
        () => this.parentDownloadQuoteTasksDocumentCommand.execute(this.model.quoteId),
        () => this.canDisplayQuoteTasksAndCostsDownloadOption && this.parentDownloadQuoteTasksDocumentCommand.canExecute(),
    );
}
