import { observer } from "mobx-react-lite";
import React, { useState } from "react";

import { AutoGrid, Box, ThemedText } from "Application";
import { FilesReadOnlyView } from "Views/Shared/Files/FilesReadOnlyView";
import { FormContainerRow, FormDottedSeparator } from "Views/Shared/SharedComponents";
import { EnquirySubViewModel } from "./EnquirySubViewModel";
import { ThemedPersona } from "Styles/ThemedPrimitives/Style1/ThemedPersona";
import { Callout, DirectionalHint, PersonaSize, Stack } from "@fluentui/react";
import { nanoid } from "nanoid";
import styled from "@emotion/styled";
import { LinkButton } from "Components/General/LinkButton";
import { SourceOfEnquiryView } from "Views/Shared/SourceOfEnquiry/SourceOfEnquiryView";

export interface IEnquirySubViewProps {
    viewModel: EnquirySubViewModel;
}

const EventCell = styled(Box)`
    display: flex;
    align-items: flex-start;
    flex: 1;
    cursor: pointer;

    line-height: 1.25;
    max-height: 48px;
    overflow: hidden;
    flex-direction: column;
`;

const EventCallout = styled(Box)`
    display: flex;
    flex: 1;
    min-width: 260px;
    max-height: 400px;
    overflow-y: scroll;
`;

const EventDisplayName = styled(Box)`
    font-size: 10px;
    line-height: 1.25;
    font-family: "Poppins";
    flex: 1;
`;

const EventHeader = styled(Box)`
    display: flex;
    flex: row;
    flex: 1;
    width: 100%;
`;

const PersonVisitingInputGroup = styled.div`
    display: flex;
    & > *:not(:last-of-type) {
        margin-right: 30px;
    }
`;

export const EnquirySubReadOnlyView: React.FC<IEnquirySubViewProps> = observer((props) => {
    const viewModel = props.viewModel;
    const [buttonId] = useState(() => `callout-${nanoid()}`);
    return (
        <React.Fragment>
            <FormContainerRow dc={"100%"} mt="5px !important" mb="15px !important">
                <ThemedText fontStyle="h7">This enquiry is linked to project: {viewModel.projectDisplayName}</ThemedText>
            </FormContainerRow>
            <FormContainerRow dc={"100%"} mt="5px !important" mb="15px !important">
                <ThemedText fontStyle="h7">{viewModel.description}</ThemedText>
            </FormContainerRow>
            <FilesReadOnlyView viewModel={props.viewModel.filesViewModel} />
            <FormDottedSeparator />
            <FormContainerRow dc={"75%"}>
                <AutoGrid m={"0"} columnGap={"30px"} dc={"2fr 3fr"}>
                    <Box display={"flex"} alignItems={"center"}>
                        <ThemedText fontStyle="h3" marginRight={"10px"}>
                            STATUS:
                        </ThemedText>
                        <ThemedText fontStyle="p">{viewModel.enquiryStatusDisplayName}</ThemedText>
                    </Box>

                    <SourceOfEnquiryView isReadOnly viewModel={viewModel.sourceOfEnquiryViewModel} />

                    {viewModel.canDisplayLostReason && (
                        <Box>
                            <Box display={"flex"} alignItems={"center"}>
                                <ThemedText fontStyle={"h3"} marginRight={"10px"}>
                                    LOST REASON:
                                </ThemedText>
                                <ThemedText fontStyle="p">{viewModel.lostReason}</ThemedText>
                            </Box>
                            {!viewModel.canDisplayCallout && (
                                <Box>
                                    <ThemedText fontStyle="p">{viewModel.lostReasonNoteDetail}</ThemedText>
                                </Box>
                            )}
                            {viewModel.canDisplayCallout && (
                                <Box>
                                    <EventCell id={buttonId} style={{ width: "fit-content", marginLeft: "-5px" }}>
                                        <EventHeader>
                                            <LinkButton
                                                paletteColor={"default"}
                                                displayName={viewModel.lostReasonNoteDetailButtonText}
                                                command={viewModel.calloutVisibilityCommand}
                                            />
                                        </EventHeader>
                                        {/* <EventDisplayName padding={"0px 12px 7px 7px"}>{viewModel.displayNote}</EventDisplayName> */}
                                    </EventCell>
                                    {viewModel.isCalloutVisible && (
                                        <Callout directionalHint={DirectionalHint.bottomAutoEdge} onDismiss={() => viewModel.setIsCalloutVisible(false)} target={`#${buttonId}`}>
                                            <EventCallout maxWidth={"600px"} padding={"7px 7px 12px 7px"} minHeight={"0px"}>
                                                <Box flex={1}>
                                                    <EventDisplayName>
                                                        <ThemedText fontStyle="p">{viewModel.lostReasonNoteDetail}</ThemedText>
                                                    </EventDisplayName>
                                                </Box>
                                            </EventCallout>
                                        </Callout>
                                    )}
                                </Box>
                            )}
                        </Box>
                    )}
                </AutoGrid>

                <PersonVisitingInputGroup>
                    <Box display="flex" alignItems="center">
                        <ThemedText fontStyle="h3" mr="10px">
                            PERSON VISITING:
                        </ThemedText>
                        <ThemedPersona displayName={viewModel.personVisitingDisplayName} size={PersonaSize.size32} imageUrl={viewModel.personVisitingImage} />
                    </Box>

                    <Box display="flex" alignItems="center">
                        <ThemedText fontStyle="h3" mr="10px">
                            Date of visit:
                        </ThemedText>
                        <ThemedText fontStyle="p">{viewModel.personVisitingFormattedVisitDate}</ThemedText>
                    </Box>

                    <Box display="flex" alignItems="center">
                        <ThemedText fontStyle="h3" mr="10px">
                            Time of visit:
                        </ThemedText>
                        <ThemedText fontStyle="p">{viewModel.personVisitingFormattedVisitTime}</ThemedText>
                    </Box>
                </PersonVisitingInputGroup>
            </FormContainerRow>
        </React.Fragment>
    );
});
