import { IStyle, mergeStyleSets } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React from "react";
import { ThemedText, formatCurrency } from "Application";
import { TaskGroupViewModel } from "./TaskGroupViewModel";
import { TaskReadOnlyView } from "./TaskReadOnlyView";

interface ITaskGroupReadOnlyViewStyles {
    root: IStyle;
    displayName: IStyle;
    manHours: IStyle;
    hourlyRate: IStyle;
    cost: IStyle;
}

interface ITaskGroupReadOnlyViewProps {
    viewModel: TaskGroupViewModel;
}

const styleDefinitions: ITaskGroupReadOnlyViewStyles = {
    root: {
        display: "flex",
        minHeight: "50px",
    },
    displayName: {
        flex: 1,
        display: "flex",
        "> div": {
            alignItems: "center",
            display: "flex",
            flex: "1",
            padding: "0 35px 15px 0",
            ":hover": {
                cursor: "pointer",
            },
        },
    },
    manHours: {
        alignItems: "center",
        borderLeft: "1px solid #E1E1E1",
        display: "flex",
        justifyContent: "center",
        minWidth: "110px",
    },
    hourlyRate: {
        alignItems: "center",
        borderLeft: "1px solid #E1E1E1",
        display: "flex",
        justifyContent: "center",
        minWidth: "110px",
    },
    cost: {
        alignItems: "center",
        borderLeft: "1px solid #E1E1E1",
        display: "flex",
        justifyContent: "center",
        minWidth: "175px",
    },
};

export const TaskReadOnlyGroupView: React.FC<ITaskGroupReadOnlyViewProps> = observer((props) => {
    const viewModel = props.viewModel;
    const styles = mergeStyleSets(styleDefinitions);

    if (viewModel) {
        return (
            <React.Fragment>
                <div className={styles.root}>
                    <div className={styles.displayName}>
                        <div>
                            <ThemedText fontStyle="h4">{viewModel.displayName}</ThemedText>
                        </div>
                    </div>
                    <div className={styles.manHours} />
                    <div className={styles.hourlyRate} />
                    <div className={styles.cost} />
                </div>
                {viewModel.sortedTaskViewModels.map((vm) => {
                    return <TaskReadOnlyView key={vm.KEY} viewModel={vm} />;
                })}
                <div className={styles.root}>
                    <div className={styles.displayName}>
                        <div>
                            <ThemedText fontStyle="p" styles={{ root: { width: "100%", textAlign: "right", marginTop: "10px" } }}>
                                SUB-TOTAL
                            </ThemedText>
                        </div>
                    </div>
                    <div className={styles.manHours}>
                        <div>
                            <ThemedText fontStyle="p">{viewModel.model.totalManHours}</ThemedText>
                        </div>
                    </div>
                    <div className={styles.hourlyRate} />
                    <div className={styles.cost}>
                        <div>
                            <ThemedText fontStyle="p">{formatCurrency(viewModel.model.predictedCostExclVAT)}</ThemedText>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    return <React.Fragment />;
});
