import { observer } from "mobx-react-lite";
import React from "react";

import { Box, ThemedText } from "Application";
import { NoteReadOnlyView } from "Views/Shared/Note/NoteReadOnlyView";
import { FormDottedSeparator } from "Views/Shared/SharedComponents";
import { NotesViewModel } from "./NotesViewModel";

export interface INotesViewProps {
    viewModel: NotesViewModel;
}

export const NotesReadOnlyView: React.FC<INotesViewProps> = observer((props) => {
    const viewModel = props.viewModel;

    return (
        <React.Fragment>
            <Box display={"flex"} mt={3} justifyContent={"space-between"}>
                <ThemedText fontStyle={"h3"}>NOTES</ThemedText>
            </Box>
            <FormDottedSeparator />
            <Box mt={3} mb={"55px"}>
                {viewModel.notes.map((vm) => {
                    return <NoteReadOnlyView key={vm.KEY} viewModel={vm} />;
                })}
            </Box>
        </React.Fragment>
    );
});
