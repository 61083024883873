import { FileModel } from "Views/Shared/Files/FileModel";
import { SupplierContactModel } from "../SupplierContacts/SupplierContactModel";
import { SupplierViewModel } from "../SupplierViewModel";

export class SupplierResponse extends Response {
    // #region Response Data

    public id: string = "";
    public contactTypeId: string = "";

    public contactNumber1: string | null = null;
    public contactNumber2: string | null = null;
    public emailAddress: string | null = null;
    public emailAddress2: string | null = null;
    public businessName: string | null = null;

    public contactTitleId: string | null = null;
    public firstName: string | null = null;
    public lastName: string | null = null;

    public address1: string = "";
    public address2: string | null = null;
    public address3: string | null = null;
    public city: string = "";
    public postCode: string = "";

    public createdDate: string | null = null;
    public updatedDate: string | null = null;

    public supplierContacts: SupplierContactResponse[] = [];

    public clientNote: string | null = null;

    public documents: SupplierDocumentResponse[] = [];

    // #endregion Response Data

    // #region Response Part Mappings to ViewModel

    public static supplierContactsResponsePart(response: SupplierResponse, viewModel: SupplierViewModel) {
        viewModel.model.supplierContacts.replace(
            response.supplierContacts.map((cc) => {
                const model = new SupplierContactModel();

                model.fromResponse(cc);

                return model;
            }),
        );

        // Process to supplier documents.
        viewModel.filesViewModel.model.files.replace(
            response.documents.map((nd) => {
                const model = new FileModel();

                model.fromResponse(nd);

                return model;
            }) ?? [],
        );
    }

    public static remainingResponsePart(response: SupplierResponse, viewModel: SupplierViewModel) {
        viewModel.model.fromResponse(response);
    }

    // #endregion Response Part Mappings to ViewModel
}

export class SupplierContactResponse {
    public id: string = "";
    public customerId: string = "";
    public contactTitleId: string = "";

    public firstName: string = "";
    public lastName: string = "";
    public address1: string | null = null;
    public address2: string | null = null;
    public address3: string | null = null;
    public city: string | null = null;
    public postcode: string | null = null;
    public sameAsCustomerAddress: boolean = false;

    public contactNumber1: string | null = null;
    public contactNumber2: string | null = null;
    public emailAddress: string | null = null;

    public createdDate: Date | null = null;
    public updatedDate: Date | null = null;
    public deletedDate: Date | null = null;
    public isDeleted: boolean = false;
}

class SupplierDocumentResponse {
    id: string = "";
    fileName: string = "";
    fileSizeBytes: number = 0;
    mimeType: string = "";
    documentUrl: string = "";
}
