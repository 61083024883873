import { ModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

import { LeaveRequest } from "Views/Leave/Shared/LeaveRequest";

export class LeaveRequestEventModel extends ModelBase<LeaveRequestEventModel> {
    public leaveRequest: LeaveRequest | null = null;

    constructor() {
        super();

        makeObservable(this, {});
    }
}
