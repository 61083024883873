import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";
import { ProjectTaskAssignmentGroupReviewLite } from "Views/Shared/Project/ProjectTaskAssignmentGroupReviewLite";

export class ProgrammingWeekTaskGroupModel extends ModelBase<ProgrammingWeekTaskGroupModel> {
    public id: string = "";
    public reference: string = "";
    public projectName: string = "";
    public projectTaskAssignmentGroupName: string = "";
    public projectTaskAssignmentGroupNote: string = "";

    public groupStatusName: string = "";
    public groupStatusType: string = "";
    public groupStatusForegroundColor: string = "";
    public groupStatusBackgroundColor: string = "";

    public reviews = observable<ProjectTaskAssignmentGroupReviewLite>([]);

    // These are for configuring an API request.
    public projectTaskAssignmentStatusId: string | null = null;
    public projectTaskAssignmentUserNote: string | null = null;

    constructor() {
        super();

        makeObservable(this, {
            reference: observable,
            projectName: observable,
            projectTaskAssignmentGroupName: observable,
            projectTaskAssignmentGroupNote: observable,

            groupStatusName: observable,
            groupStatusType: observable,
            groupStatusForegroundColor: observable,
            groupStatusBackgroundColor: observable,

            projectTaskAssignmentUserNote: observable,
        });
    }
}

export class ProgrammingWeekTaskGroupModelValidator extends Validator<ProgrammingWeekTaskGroupModel> {
    constructor() {
        super();

        this.ruleFor("projectTaskAssignmentUserNote").notNull().withMessage("Note cannot be empty.").notEmpty().withMessage("Note cannot be empty.");
    }
}
