import { ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

import { ICommand } from "Application";
import { DeleteProjectModel } from "./DeleteProjectModel";

export class DeleteProjectViewModel extends ViewModelBase<DeleteProjectModel> {
    public parentCancelDeleteProjectCommand: ICommand;
    public parentSubmitDeleteProjectCommand: ICommand;

    constructor(parentCancelDeleteProjectCommand: ICommand, parentSubmitDeleteProjectCommand: ICommand) {
        super(new DeleteProjectModel());

        this.parentCancelDeleteProjectCommand = parentCancelDeleteProjectCommand;
        this.parentSubmitDeleteProjectCommand = parentSubmitDeleteProjectCommand;

        makeObservable(this, {});
    }

    public get displayMessage() {
        return "Are you sure you want to revert this project to a quote?";
    }
}
