import { ModelBase } from "@shoothill/core";
import { Logger } from "index";
import { makeObservable, observable, action, IObservableArray } from "mobx";
import { singleton } from "tsyringe";

//What is Journey?
//A journey is a series of milestones a user reaches in a process.
//A milestone is a step in a journey.
//A journey history keeps track of the journeys a user has completed.

@singleton()
export class UserJourneyHistoryStore {
    //DetailsList uses virtualization to render the list of items.
    //We need to keep track of the scroll position so we can restore it when the list is re-rendered.
    public scrollPosition: number | undefined = undefined;

    //keeps track of the history of the quote journey
    public quoteJourneyHistory: IObservableArray<JourneyHistoryItem> = observable([]);

    constructor() {
        Logger.logDebug("Domain store created");
        makeObservable(this, {
            //region observables
            quoteJourneyHistory: observable,
            scrollPosition: observable,
            //endregion observables
            //region actions
            addQuoteJourneyHistoryItem: action,
            setScrollPosition: action,
            //endregion actions
        });
    }

    public addQuoteJourneyHistoryItem = (item: JourneyHistoryItem) => {
        this.quoteJourneyHistory.unshift(item);
    };

    public setScrollPosition = (position: number | undefined) => {
        this.scrollPosition = position;
    };

    public get canScrollToPosition() {
        return this.scrollPosition !== undefined;
    }
}

export class JourneyHistoryItem extends ModelBase {
    public id: string = "";
    public name: string = "";
    public timeStamp: Date = new Date();

    constructor() {
        super();
        makeObservable(this, {
            name: observable,
            timeStamp: observable,
        });
    }
}
