import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

export class WeeklyUpdatesListModel extends ModelBase<WeeklyUpdatesListModel> {
    // Sorting
    public sortKey = "projectUpdateCommencementDate"; // A property defined in the table column definitions.
    public sortDescending = true;

    // Data
    public canCreateTransaction: boolean = false;
    public numberOfUpdatesAvailable: number = 0;
    public projectUpdateDate: Date | null = null;
    public projectUpdateCommencementDate: Date | null = null;

    constructor() {
        super();
        makeObservable(this, {
            sortKey: observable,
            sortDescending: observable,
            canCreateTransaction: observable,
            numberOfUpdatesAvailable: observable,
            projectUpdateDate: observable,
            projectUpdateCommencementDate: observable,
        });
    }
}
