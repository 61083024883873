//libraries
import { ViewModelBase } from "@shoothill/core";
import { IObservableArray, computed, makeObservable, observable } from "mobx";
import { APIClient, RelayCommand } from "Application";

//app
import { InvoiceForecastModel } from "./InvoiceForecastModel";
import { GetAdminInvoicingForecastEndpoint } from "./Endpoints/GETAdminInvoicingForecastEndpoint";
import { InvoiceForecastMonthCapacityViewModel } from "./Subviews/InvoiceForecastMonthCapacity/InvoiceForecastMonthCapacityViewModel";
import { POSTUpsertInvoicingForecastOverridesEndpoint } from "./Endpoints/POSTUpsertInvoicingForecastOverridesEndpoint";

export class InvoiceForecastViewModel extends ViewModelBase<InvoiceForecastModel> {
    public apiClient = new APIClient();

    public capacities: IObservableArray<InvoiceForecastMonthCapacityViewModel> = observable<InvoiceForecastMonthCapacityViewModel>([]);

    constructor(model = new InvoiceForecastModel()) {
        super(model, false);

        makeObservable(this, {
            canSave: computed,
        });
        this.init();
    }

    //region properties
    public get canSave() {
        this.capacities.forEach((c) => {
            if (!c.isModelValid()) {
                return false;
            }
        });
        return true;
    }

    //endregion properties

    //region endpoint calls
    public init() {
        this.apiClient.sendAsync(new GetAdminInvoicingForecastEndpoint(this));
    }

    public saveInvoiceForecastOverrides() {
        this.apiClient.sendAsync(new POSTUpsertInvoicingForecastOverridesEndpoint(this));
    }

    //endregion endpoint calls

    //region commands

    public saveInvoiceForecastOverridesCommand = new RelayCommand(
        () => {
            this.saveInvoiceForecastOverrides();
        },
        () => this.canSave,
    );

    //endregion commands
}
