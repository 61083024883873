import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

import { LeaveRequest } from "Views/Leave/Shared/LeaveRequest";

export class CancelLeaveRequestModel extends ModelBase<CancelLeaveRequestModel> {
    public leaveRequest: LeaveRequest;
    public responseReason: string | null = null;

    constructor(leaveRequest: LeaveRequest) {
        super();

        this.leaveRequest = leaveRequest;

        makeObservable(this, {
            // Observables
            responseReason: observable,
        });
    }
}
