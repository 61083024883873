import { runInAction } from "mobx";

import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { TaskViewModel } from "./TaskViewModel";
import { TaskModel } from "./TaskModel";

class Request {
    public id: string | null = null;
    public billedEffort: number = 0;
    public billedNote: string | null = null;
}

class Response {
    public id: string = "";
}

export class POSTSaveProjectTaskAssignmentAsBilledEndpoint extends Endpoint<Request, Response> {
    private readonly viewModel: TaskViewModel;

    constructor(viewModel: TaskViewModel) {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Resources.SaveProjectTaskAssignmentAsBilled);
        this.viewModel = viewModel;
    }

    public async HandleRequestAsync(model: TaskModel): Promise<any> {
        const request = new Request();

        model.toRequest(request);

        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            this.viewModel.parentSubmitCommand.execute(response.id);
        });
    }
}
