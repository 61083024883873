import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import React from "react";

import { AutoGrid, Box } from "Application";
import { WorkCalendarViewModel } from "../../WorkCalendarViewModel";
import { DateViewModel } from "../Date/DateViewModel";
import { EffortEventViewModel } from "../Date/Components/EffortEvent/EffortEventViewModel";
import { EffortEventView } from "../Date/Components/EffortEvent/EffortEventView";

interface IProps {
    viewModel: WorkCalendarViewModel;
}

const DaysGrid = styled(AutoGrid)`
    align-items: center;
    background-color: #f3f3f3;
    border-bottom: 1px solid #edebe9;
    column-gap: 0px !important;
    flex: 1;
    font-size: 10px;
    font-weight: bold;
    margin: 0px 0px;
    max-height: 44px;
    min-height: 44px;
    row-gap: 0px !important;
`;

const DayCell = styled(Box)`
    padding: 4px 12px;
`;

const DateGrid = styled(AutoGrid)`
    column-gap: 0px !important;
    margin: 3px 2px 3px 1px;
    row-gap: 4px !important;
`;

const DateCell = styled(Box)`
    background-color: #f3f3f3;
    font-size: 12px;
    outline: 1px solid #e1e1e1;
    margin-left: 1px;
    padding: 4px 12px;
`;

const DateCellEmpty = styled(DateCell)`
    @media (max-width: 960px) {
        display: none;
    }
`;

export const HeaderView: React.FC<IProps> = observer((props) => {
    const viewModel = props.viewModel;
    const projectDatesGridSize = "1fr 5fr";
    const dateGridSize = "1fr 1fr 1fr 1fr 1fr";

    const getCellGridArea = (index: number) => {
        const totalNoOfCells = 5;

        const rowStart = Math.floor((index + totalNoOfCells) / totalNoOfCells);
        const rowEnd = "auto";
        const colStart = (index % totalNoOfCells) + 1;
        const colEnd = colStart + 1;

        return `${rowStart}/${colStart}/${rowEnd}/${colEnd}`;
    };

    const renderDateEventCell = (dateViewModel: DateViewModel) => {
        const eventViewModel = dateViewModel.eventViewModel;

        switch (true) {
            case eventViewModel instanceof EffortEventViewModel:
                return <EffortEventView viewModel={eventViewModel as EffortEventViewModel} />;

            default:
                return dateViewModel.formattedDate;
        }
    };

    const renderDaysRow = () => {
        return (
            <DaysGrid dc={projectDatesGridSize}>
                <DayCell>{viewModel.currentMonthAndYear}</DayCell>
                <AutoGrid mc={dateGridSize} tc={dateGridSize} dc={dateGridSize} columnGap={0} rowGap={0} m={0}>
                    {viewModel.headerDateViewModels.map((dateViewModel, index) => {
                        const gridArea = getCellGridArea(index);

                        return <DayCell style={{ gridArea: `${gridArea}` }}>{dateViewModel.formattedDay.toLocaleUpperCase()}</DayCell>;
                    })}
                </AutoGrid>
            </DaysGrid>
        );
    };

    const renderDatesRow = () => {
        return (
            <DateGrid dc={projectDatesGridSize}>
                <DateCellEmpty />
                <AutoGrid mc={dateGridSize} tc={dateGridSize} dc={dateGridSize} columnGap={0} rowGap={0} m={0}>
                    {viewModel.headerDateViewModels.map((dateViewModel, index) => {
                        const gridArea = getCellGridArea(index);

                        return <DateCell style={{ gridArea: `${gridArea}` }}>{renderDateEventCell(dateViewModel)}</DateCell>;
                    })}
                </AutoGrid>
            </DateGrid>
        );
    };

    return (
        <React.Fragment>
            {renderDaysRow()}
            {renderDatesRow()}
        </React.Fragment>
    );
});
