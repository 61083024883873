import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import React from "react";

import { AutoGrid, Box, ThemedButton, ThemedText } from "Application";
import { ThemedLoader } from "Styles/ThemedPrimitives/Style1/ThemedLoader";
import { ThemedError } from "Styles/ThemedPrimitives/Style1/ThemedError";
import { FormDottedSeparator, PanelTab, PanelTabContainer } from "Views/Shared/SharedComponents";
import { TaskViewModel } from "./TaskViewModel";
import { APICommonComponents } from "Application/Helpers/APICommonComponents";
import { NotesViewModel } from "../NotesSubView/NotesViewModel";
import { NotesView } from "../NotesSubView/NotesView";
import { BillingView } from "./BillingSubView/BillingView";
import { BillingViewModel } from "./BillingSubView/BillingViewModel";

interface ITestViewProps {
    viewModel: TaskViewModel;
}

const Header = styled(Box)`
    background-color: #23a73d;
    color: white;
    min-height: 70px;
    padding: 10px 30px;
`;
const Body = styled(Box)`
    background-color: #fcfcfc;
    flex: 1;
`;
const Footer = styled(Box)`
    align-items: center;
    background-color: #fcfcfc;
    display: flex;
    min-height: 70px;
    padding: 0 30px;
`;

export const TaskView: React.FC<ITestViewProps> = observer((props) => {
    const viewModel = props.viewModel;

    const renderTabs = () => {
        return (
            <PanelTabContainer>
                {viewModel.workspaceViewModels.map((workspace) => {
                    return (
                        <PanelTab isActive={viewModel.isActiveWorkspace(workspace)} key={workspace.KEY} onClick={() => viewModel.navigateToWorkspaceCommand.execute(workspace)}>
                            <ThemedText>{workspace.displayName}</ThemedText>
                        </PanelTab>
                    );
                })}
            </PanelTabContainer>
        );
    };

    const renderActiveWorkspace = () => {
        switch (true) {
            // case viewModel.activeWorkspaceViewModel instanceof NotesViewModel:
            //     return <NotesView viewModel={viewModel.activeWorkspaceViewModel as NotesViewModel} />;

            case viewModel.activeWorkspaceViewModel instanceof BillingViewModel:
                return <BillingView viewModel={viewModel.activeWorkspaceViewModel as BillingViewModel} />;
        }
    };

    return (
        <Box display="flex" flexDirection="column" flex="1" onClick={(e: any) => e.stopPropagation()}>
            <Header>
                <Box display="flex" alignItems="baseline">
                    <ThemedText color="white" fontStyle="h5">
                        <b>{viewModel && viewModel.reference}</b>
                    </ThemedText>
                    <>&nbsp;-&nbsp;</>
                    <ThemedText color="white" fontStyle="h5">
                        {viewModel && viewModel.title}
                    </ThemedText>
                </Box>
                <ThemedText mb="-2px" mt="1px" color="white" fontStyle="h7">
                    <b>{viewModel && viewModel.taskGroupName}</b>
                </ThemedText>
                <ThemedText color="white" fontStyle="h7">
                    {viewModel && viewModel.taskName}
                </ThemedText>
            </Header>
            <Body flex="1">
                {/* Navigation Tabs */}
                {viewModel && renderTabs()}
                {/* Active Workspace */}
                {viewModel && renderActiveWorkspace()}
                {/* Busy Overlay */}
                {viewModel && APICommonComponents(viewModel.apiClient).renderBusy()}

                {/* Error Overlay */}
                {viewModel && APICommonComponents(viewModel.apiClient).renderError()}
            </Body>
            <FormDottedSeparator styles={{ root: { height: "1px" } }} />
            <Footer>
                <AutoGrid columnGap={"15px"} dc={"85px 85px"}>
                    {viewModel && <ThemedButton styles={{ root: { padding: "0 5px" } }} paletteColor={"default"} displayName="Close" command={viewModel.cancelCommand} />}
                </AutoGrid>
            </Footer>
        </Box>
    );
});
