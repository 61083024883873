import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { AddEditPlanningApplicationViewModel } from "./AddEditPlanningApplicationViewModel";
import { IStyle, mergeStyleSets } from "@fluentui/react";
import { AutoGrid, Box, RelayCommand, ThemedButton, ThemedComboBox, ThemedEditDate, ThemedEditText, ThemedText, ThemedTransparentIconButton, theme } from "Application";
import { CrossSVG } from "Assets/CrossSVG";
import { FormContainerRow, FormSeparator } from "Views/Shared/SharedComponents";
import { APICommonComponents } from "Application/Helpers/APICommonComponents";
import { AddEditPlanningApplicationViewStyles as styles } from "./AddEditPlanningApplicationView.styles";
import { Files } from "Views/Shared/Files/Files";

interface IAddEditPlanningApplicationProps {
    id: string | "new-id";
    onDismissCommand: RelayCommand;
    onSubmitSuccessCommand: RelayCommand;
}

export const AddEditPlanningApplicationView: React.FC<IAddEditPlanningApplicationProps> = observer(({ id, onDismissCommand, onSubmitSuccessCommand }) => {
    const [viewModel] = useState(() => new AddEditPlanningApplicationViewModel(id, onDismissCommand, onSubmitSuccessCommand));

    useEffect(() => {
        viewModel.loadData();
    }, []);

    return (
        <>
            <div className={styles.root}>
                <div className={styles.header}>
                    <ThemedText fontStyle="h2">{viewModel.displayName}</ThemedText>
                    <ThemedTransparentIconButton command={viewModel.onDismissCommand} icon={<CrossSVG className={styles.iconButton} />} shape="round" />
                </div>
                <div className={styles.body}>
                    <FormContainerRow dc={"100%"}>
                        <AutoGrid dc={"3fr 2fr"} columnGap={"20px"}>
                            <ThemedComboBox
                                options={viewModel.projectOptions}
                                displayName="Associated Project"
                                command={viewModel.updateProjectIdCommand}
                                placeholder={"Start typing to search"}
                                value={() => viewModel.getValue("projectId")}
                                validationMessage={() => viewModel.getError("projectId")}
                            />
                            <div />
                        </AutoGrid>
                    </FormContainerRow>
                    <Box style={{ borderBottom: "1px dashed #E5E5E5" }} />

                    <FormContainerRow dc={"100%"}>
                        <AutoGrid m={"0px"} dc={"1fr 1fr 1fr 1fr"} columnGap={"20px"}>
                            <ThemedEditText
                                displayName="Planning ref."
                                command={viewModel.updatePlanningRefCommand}
                                placeholder={"Add ref"}
                                value={() => viewModel.getValue("planningRef") ?? ""}
                                validationMessage={() => viewModel.getError("planningRef")}
                            />
                            <ThemedComboBox
                                command={viewModel.updateLocalAuthorityCommand}
                                displayName="Local authority"
                                labelColor="default"
                                options={viewModel.localAuthorityDistrictOptions}
                                placeholder="Please select"
                                validationMessage={() => viewModel.getError("localAuthorityDistrictId")}
                                value={() => viewModel.getValue("localAuthorityDistrictId")}
                            />
                            <ThemedComboBox
                                command={viewModel.updateTypeCommand}
                                displayName="Type"
                                labelColor="default"
                                options={viewModel.typeOptions}
                                placeholder="Please select"
                                validationMessage={() => viewModel.getError("planningApplicationTypeId")}
                                value={() => viewModel.getValue("planningApplicationTypeId")}
                            />
                            <ThemedComboBox
                                command={viewModel.updateStatusCommand}
                                displayName="Status"
                                labelColor="default"
                                options={viewModel.statusOptions}
                                placeholder="Please select"
                                validationMessage={() => viewModel.getError("planningApplicationStatusId")}
                                value={() => viewModel.getValue("planningApplicationStatusId")}
                            />
                        </AutoGrid>
                    </FormContainerRow>
                    <FormContainerRow dc={"100%"} mb={"0px !important"}>
                        <AutoGrid m={"0"} dc={"1fr 1fr 1fr 1fr"} columnGap={"20px"}>
                            <ThemedEditDate
                                command={viewModel.updateSubmittedDateCommand}
                                displayName="Submitted date"
                                size="larger"
                                validationMessage={() => viewModel.getError("submittedDate")}
                                value={() => viewModel.getValue("submittedDate")}
                            />
                            <ThemedEditDate
                                command={viewModel.updateConsultationDateCommand}
                                displayName="Consultation date"
                                size="larger"
                                validationMessage={() => viewModel.getError("consultationDate")}
                                value={() => viewModel.getValue("consultationDate")}
                            />
                            <ThemedEditDate
                                command={viewModel.updateDeterminationDateCommand}
                                displayName="Determination date"
                                size="larger"
                                validationMessage={() => viewModel.getError("determinationDate")}
                                value={() => viewModel.getValue("determinationDate")}
                            />
                            <ThemedEditDate
                                command={viewModel.updateApprovedDateCommand}
                                displayName="Approved date"
                                size="larger"
                                validationMessage={() => viewModel.getError("approvedDate")}
                                value={() => viewModel.getValue("approvedDate")}
                            />
                        </AutoGrid>
                    </FormContainerRow>

                    <FormContainerRow dc={"100%"}>
                        <ThemedEditText
                            displayName="Note"
                            command={viewModel.updateNoteCommand}
                            isMultiline
                            placeholder={"Please enter some information"}
                            value={() => viewModel.getValue("note") ?? ""}
                            validationMessage={() => viewModel.getError("note")}
                        />
                    </FormContainerRow>

                    <FormContainerRow dc={"100%"}>
                        <Files viewModel={viewModel.filesViewModel} />
                    </FormContainerRow>
                </div>

                <FormSeparator className={styles.separator} />
                <AutoGrid m={0} className={styles.footer} columnGap={"30px"} dc={"135px 135px"}>
                    <ThemedButton
                        styles={{ root: { padding: "0 5px" } }}
                        paletteColor={"primary"}
                        displayName={viewModel.submitDisplayName}
                        command={viewModel.submitCommandAsync}
                    />
                    <ThemedButton styles={{ root: { padding: "0 5px" } }} displayName="Cancel" command={viewModel.onDismissCommand} />
                </AutoGrid>
            </div>
            {APICommonComponents(viewModel.apiClient).renderBusy()}
            {/* Error Overlay */}
            {APICommonComponents(viewModel.apiClient).renderError()}
        </>
    );
});
