import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import React from "react";

import { Box } from "Application";
import { HalfDaysEventViewModel } from "./HalfDaysEventViewModel";
import { LeaveRequestEventViewModel } from "../LeaveRequestEvent/LeaveRequestEventViewModel";
import { LeaveRequestEventView } from "../LeaveRequestEvent/LeaveRequestEventView";
import { NoEventViewModel } from "../NoEvent/NoEventViewModel";
import { NoEventView } from "../NoEvent/NoEventView";

interface IProps {
    viewModel: HalfDaysEventViewModel;
}

const EventCell = styled(Box)`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

const EventCellSeparator = styled(Box)`
    background-color: #fafafa;
    display: flex;
    height: 1px;
    width: auto;
`;

export const HalfDaysEventView: React.FC<IProps> = observer((props) => {
    const viewModel = props.viewModel;

    const renderEventCell = (viewModel: LeaveRequestEventViewModel | NoEventViewModel | null) => {
        switch (true) {
            case viewModel instanceof LeaveRequestEventViewModel:
                return <LeaveRequestEventView viewModel={viewModel as LeaveRequestEventViewModel} />;

            case viewModel instanceof NoEventViewModel:
                return <NoEventView viewModel={viewModel as NoEventViewModel} />;
        }
    };

    return (
        <EventCell>
            {renderEventCell(viewModel.morningEventViewModel)}
            <EventCellSeparator />
            {renderEventCell(viewModel.afternoonEventViewModel)}
        </EventCell>
    );
});
