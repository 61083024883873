import { ModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

export class WeekendEventModel extends ModelBase<WeekendEventModel> {
    constructor() {
        super();

        makeObservable(this, {});
    }
}
