import { isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";
import { TermsAndConditionItemModel } from "./TermsAndConditionItemModel";

export class TermsAndConditionItemViewModel extends ViewModelBase<TermsAndConditionItemModel> {
    constructor(termsAndConditionItemModel: TermsAndConditionItemModel = new TermsAndConditionItemModel()) {
        super(termsAndConditionItemModel);
        makeObservable(this, {});
    }

    public get id() {
        return this.model.id;
    }
    public get name() {
        return this.model.name;
    }
    public get subject() {
        return this.model.subject;
    }
    public get body() {
        return this.model.body;
    }
    public get createdDate() {
        return this.model.createdDate;
    }
    public get createdByUserId() {
        return this.model.createdByUserId;
    }
    public get lastUpdatedDate() {
        return this.model.lastUpdatedDate;
    }
    public get lastUpdateByUserId() {
        return this.model.lastUpdatedByUserId;
    }
    public get lastUpdateByUser() {
        return this.model.lastUpdatedByUser;
    }

    public filterPredicate = (filterKeyword: string): boolean => {
        let result = true;
        if (result) {
            const lowerCaseFilterKeyword = filterKeyword.toLowerCase();

            switch (true) {
                case isEmptyOrWhitespace(filterKeyword):
                case this.model.name.toLowerCase().includes(lowerCaseFilterKeyword):
                    result = true;
                    break;

                default:
                    result = false;
                    break;
            }
        }

        return result;
    };
}
