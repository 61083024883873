import { IColumn, Link } from "@fluentui/react";
import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import { Box, formatCurrency, ThemedComboBox, ThemedEditDate, ThemedEditText } from "Application";
import { ThemedDataTable } from "../../../Styles/ThemedPrimitives/Style1/ThemedDataTable";
import { LostViewModel } from "./LostViewModel";
import { SearchIcon } from "../../../Assets/SearchIcon";
import { PhoneIcon } from "../../../Assets/Icons/PhoneIcon";
import { EmailIcon } from "../../../Assets/Icons/EmailIcon";
import { formatDate, setPageTitle } from "../../../Application/Utils";
import { ContactCallout, ICalloutState } from "Styles/ThemedPrimitives/Style1/ContactCallout";
import { LostItemViewModel } from "./LostItemViewModel";

const controlStyles = {
    root: {
        width: "505px",
        height: "39px",
        fontSize: "17px",
        borderColor: "#E1E1E1",
    },
};

export const LostView: React.FC = observer(() => {
    // #region Code Behind

    const [viewModel] = useState(() => new LostViewModel());

    useEffect(() => {
        setPageTitle("Lost items");
    }, []);

    /**
     * Handles updating the tables column sort settings in response to changes in the viewmodel.
     */
    useEffect(() => {
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter((currCol) => viewModel.settings.sortKey === currCol.key)[0];

        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSorted = true;
                currColumn.isSortedDescending = viewModel.settings.sortDescending;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = false;
            }
        });

        setColumns(newColumns);
    }, [viewModel.settings.sortKey, viewModel.settings.sortDescending]);

    /**
     * Gets a key for the row. Must be provided if sorting or filtering is enabled.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The row index.
     *
     * @returns A string key to identify the row.
     */
    const getRowKey = (item: LostItemViewModel, index?: number): string => {
        return item.KEY;
    };

    /**
     * Handles an onClick event for the table column header.
     *
     * @param ev Mouse event
     * @param column The column that has been clicked.
     */
    const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const currColumn: IColumn = columns.filter((currCol) => column.key === currCol.key)[0];
        const newSortKey = currColumn.key;
        const newSortDescending = currColumn.key === viewModel.settings.sortKey ? !currColumn.isSortedDescending : false;
        viewModel.updateSortCommand.execute(newSortKey, newSortDescending);
    };

    /**
     * Handler an onClick event for a table row.
     *
     * @param item The viewmodel data associated with the table row.
     */
    const onRowClick = (item: any, index?: number, ev?: React.FocusEvent<HTMLElement>) => {
        item.model.isEnquiry ? viewModel.navigateToEditEnquiry.execute(item) : viewModel.navigateToEditQuote.execute(item);
    };

    /**
     * Renders the confidence row data.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The index of the table row.
     * @param column The table column.
     *
     * @returns React rating element.
     */
    const onRenderConfidence = (item: LostItemViewModel, index?: number, column?: IColumn) => {
        const MAXSTARS = 5;
        const stars = [];

        for (let starIndex = 0; starIndex < MAXSTARS; starIndex++) {
            stars.push(starIndex < item.confidence ? <span className="rating-round active" /> : <span className="rating-round" />);
        }

        return (
            <div id="ratingBox" className="ratingBox">
                {stars}
            </div>
        );
    };

    /**
     * Renders the desirability row data.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The index of the table row.
     * @param column The table column.
     *
     * @returns React rating element.
     */
    const onRenderDesirability = (item: LostItemViewModel, index?: number, column?: IColumn) => {
        const MAXSTARS = 5;
        const stars = [];

        for (let starIndex = 0; starIndex < MAXSTARS; starIndex++) {
            stars.push(starIndex < item.desirability ? <span className="rating-round purple active" /> : <span className="rating-round" />);
        }

        return (
            <div id="ratingBox" className="ratingBox">
                {stars}
            </div>
        );
    };

    /**
     * Renders the issued date row data.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The index of the table row.
     * @param column The table column.
     *
     * @returns React rating element.
     */
    const onRenderIssueDate = (item: LostItemViewModel, index?: number, column?: IColumn) => {
        return <div>{!isNullOrUndefined(item.issueDate) ? formatDate(item.issueDate!) : ""}</div>;
    };

    const onRenderContactName = (item: LostItemViewModel, index?: number, column?: IColumn) => {
        if (item.model.customerItemModel != null) {
            return (
                <Link onFocus={(ev) => ev.stopPropagation()}>
                    <div onClick={() => viewModel.navigateToCustomerDetailCommand.execute(item.model.customerItemModel!.id)}>
                        <div style={{ fontWeight: 600, fontSize: "10px", color: "#171716" }}>{item.contactFullName}</div>
                        <div style={{ fontSize: "10px", color: "#171716" }}>{item.contactFullAddress}</div>
                    </div>
                </Link>
            );
        } else {
            return (
                <div>
                    <div style={{ fontWeight: 600, fontSize: "10px", color: "#171716" }}>{item.contactFullName}</div>
                    <div style={{ fontSize: "10px", color: "#171716" }}>{item.contactFullAddress}</div>
                </div>
            );
        }
    };

    /**
     * Renders the phone icon and provides callout support.
     *
     * The icon is wrapped in a Link with a stop propagation call so
     * clicking on the icon prevents the table row click.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The index of the table row.
     * @param column The table column.
     *
     * @returns React rating element.
     */
    const onRenderPhone = (item: LostItemViewModel, index?: number, column?: IColumn) => {
        if (!isEmptyOrWhitespace(item.contactNumber1)) {
            return (
                <Link onFocus={(ev) => ev.stopPropagation()}>
                    <PhoneIcon
                        id={`phoneicon-${index}`}
                        onClick={(event: any) => {
                            setIsCalloutVisible({
                                isCalloutVisble: true,
                                isEmail: false,
                                targetId: `phoneicon-${index}`,
                                data: item.contactNumber1,
                            });
                        }}
                    />
                </Link>
            );
        }
        return <React.Fragment />;
    };

    /**
     * Renders the email icon and provides callout support.
     *
     * The icon is wrapped in a Link with a stop propagation call so
     * clicking on the icon prevents the table row click.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The index of the table row.
     * @param column The table column.
     *
     * @returns React rating element.
     */
    const onRenderEmail = (item: LostItemViewModel, index?: number, column?: IColumn) => {
        if (!isEmptyOrWhitespace(item.emailAddress)) {
            return (
                <Link onFocus={(ev) => ev.stopPropagation()}>
                    <EmailIcon
                        id={`emailicon-${index}`}
                        onClick={(event: any) => {
                            setIsCalloutVisible({
                                isCalloutVisble: true,
                                isEmail: true,
                                targetId: `emailicon-${index}`,
                                data: item.emailAddress,
                            });
                        }}
                    />
                </Link>
            );
        }
        return <React.Fragment />;
    };

    /**
     * Renders the value row data.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The index of the table row.
     * @param column The table column.
     *
     * @returns React rating element.
     */
    const onRenderValue = (item: LostItemViewModel, index?: number, column?: IColumn) => {
        return <div>{formatCurrency(item.value)}</div>;
    };

    /**
     * Renders the type row data.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The index of the table row.
     * @param column The table column.
     *
     * @returns React rating element.
     */
    const onRenderType = (item: LostItemViewModel, index?: number, column?: IColumn) => {
        return <div style={{ textTransform: "uppercase" }}>{item.type}</div>;
    };

    /**
     * Renders the last action user name and date.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The index of the table row.
     * @param column The table column.
     *
     * @returns React rating element.
     */
    const onRenderLastActionDate = (item: LostItemViewModel, index?: number, column?: IColumn) => {
        return (
            <div>
                <div>{!isNullOrUndefined(item.lastActionDate) ? formatDate(item.lastActionDate!) : ""}</div>
                <div>{item.lastActionUserName}</div>
            </div>
        );
    };

    const onRenderRevision = (item: LostItemViewModel, index?: number, column?: IColumn) => {
        return (
            <div>
                <div>{!isNullOrUndefined(item.revision) ? item.revision : ""}</div>
            </div>
        );
    };

    const defaultTableColumns: IColumn[] = [
        {
            fieldName: "type",
            key: "type",
            name: "TYPE",
            minWidth: 0,
            maxWidth: 70,
            isResizable: true,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: onRenderType,
        },
        {
            fieldName: "reference",
            key: "reference",
            name: "REFERENCE",
            isResizable: true,
            minWidth: 0,
            maxWidth: 100,
            styles: {
                cellTitle: {
                    textAlign: "center",
                    justifyContent: "center",
                },
            },
            className: "textCenter",
            isSorted: true,
            onColumnClick: onColumnClick,
        },
        {
            key: "title",
            name: "JOB NAME",
            fieldName: "title",
            isResizable: true,
            minWidth: 50,
            isSorted: true,
            onColumnClick: onColumnClick,
        },
        {
            key: "fullName",
            name: "CLIENT",
            fieldName: "fullName",
            minWidth: 250,
            isSorted: true,
            isSortedDescending: false,
            sortAscendingAriaLabel: "Sorted A to Z",
            sortDescendingAriaLabel: "Sorted Z to A",
            onColumnClick: onColumnClick,
            isPadded: true,
            onRender: onRenderContactName,
            styles: {
                cellTitle: {
                    border: "none !important",
                },
            },
        },
        {
            className: "contactCell",
            fieldName: "",
            styles: {
                cellTitle: {
                    border: "none !important",
                },
            },
            key: "phoneicon",
            name: "",
            minWidth: 25,
            onRender: onRenderPhone,
        },
        {
            className: "contactCell",
            fieldName: "",
            key: "emailicon",
            name: "",
            minWidth: 25,
            onRender: onRenderEmail,
        },
        {
            key: "hours",
            name: "HOURS",
            fieldName: "hours",
            isResizable: true,
            minWidth: 100,
            isSorted: true,
            onColumnClick: onColumnClick,
        },
        {
            fieldName: "value",
            key: "value",
            name: "VALUE",
            isResizable: true,
            minWidth: 100,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: onRenderValue,
        },
        {
            fieldName: "confidence",
            key: "confidence",
            name: "CONFIDENCE",
            isResizable: true,
            minWidth: 0,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: onRenderConfidence,
        },
        {
            fieldName: "desirability",
            key: "desirability",
            name: "DESIRABILITY",
            isResizable: true,
            minWidth: 0,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: onRenderDesirability,
        },
        {
            fieldName: "issueDate",
            key: "issueDate",
            name: "LAST ISSUED",
            isResizable: true,
            minWidth: 0,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: onRenderIssueDate,
        },
        {
            fieldName: "revision",
            key: "revision",
            name: "REV",
            isResizable: true,
            minWidth: 0,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: onRenderRevision,
        },
        {
            fieldName: "lastActionDate",
            key: "lastActionDate",
            name: "LAST ACTION",
            maxWidth: 125,
            minWidth: 125,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: onRenderLastActionDate,
        },
        {
            fieldName: "lostReason",
            key: "lostReason",
            name: "REASON",
            maxWidth: 250,
            minWidth: 250,
            isSorted: true,
            onColumnClick: onColumnClick,
        },
    ];

    /**
     * Defines the default state for displaying a callout.
     */
    const defaultCalloutState: ICalloutState = { isCalloutVisble: false, targetId: "", isEmail: false, data: "" };

    const [columns, setColumns] = useState<IColumn[]>(defaultTableColumns);
    const [callout, setIsCalloutVisible] = useState<ICalloutState>(defaultCalloutState);

    // #endregion Code Behind

    return (
        <Box>
            <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                <Box display={"flex"}>
                    <ThemedEditText
                        command={viewModel.updateFilterKeywordCommand}
                        placeholder={"Keyword search"}
                        prefix={<SearchIcon />}
                        styles={controlStyles}
                        value={() => viewModel.getValue("filterKeyword")}
                    />
                    <Box pl={3}>
                        <ThemedEditDate
                            placeholder="Action from"
                            size={"larger"}
                            command={viewModel.updateFilterFromDateCommand}
                            value={() => viewModel.getValue("filterFromDate")}
                            showLabel={false}
                        />
                    </Box>
                    <Box pl={3}>
                        <ThemedEditDate
                            placeholder="Action to"
                            size={"larger"}
                            command={viewModel.updateFilterToDateCommand}
                            value={() => viewModel.getValue("filterToDate")}
                            showLabel={false}
                        />
                    </Box>
                    <Box pl={3}>
                        <ThemedComboBox
                            command={viewModel.updateLostTypeCommand}
                            options={viewModel.getLostTypes()}
                            placeholder="All Types"
                            size="larger"
                            value={() => viewModel.getValue("filterTypeId")}
                        />
                    </Box>
                </Box>
            </Box>
            <ThemedDataTable getKey={getRowKey} items={viewModel.filteredAndSortedLost} columns={columns} onActiveItemChanged={(item) => onRowClick(item)} />
            {callout.isCalloutVisble && (
                <ContactCallout data={callout.data} onDismiss={() => setIsCalloutVisible(defaultCalloutState)} isEmail={callout.isEmail} targetId={callout.targetId} />
            )}
        </Box>
    );
});
