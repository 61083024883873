import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

import { Box, theme } from "Application";
import { ProjectRowViewModel } from "./ProjectRowViewModel";
import { LinkButton } from "Components/General/LinkButton";

interface IProps {
    viewModel: ProjectRowViewModel;
    index: number;
}

interface ILabelCellProps {
    color: string;
}

const LabelCell = styled(Box)<ILabelCellProps>`
    background-color: ${(props) => props.color};
    border-right: 1px solid #8d8d8d;
    padding-left: 5px;
    flex: 1;
    line-height: 1.25;
`;

const LabelDisplayReference = styled(Box)`
    color: ${theme.palette.common.default};
    font-size: 10px;
    font-weight: bold;
    padding: 7px 12px 3px 7px;
`;

const LabelDisplayName = styled(Box)`
    color: ${theme.palette.common.default};
    font-size: 10px;
    padding: 0px 12px 4px 7px;
`;

const CustomLinkButton = styled(LinkButton)`
    color: ${theme.palette.common.default};
    font-size: 10px;
    padding: 7px 12px 3px 7px;
    min-width: 0px;
    * > span {
        font-weight: bold;
    }

    &:hover,
    &:active,
    &:focus {
        padding: 7px 12px 3px 7px;
    }
`;

export const ProjectRowView: React.FC<IProps> = observer((props) => {
    const viewModel = props.viewModel;

    return (
        <LabelCell color={props.viewModel.getLabelColor(props.index)}>
            <CustomLinkButton paletteColor={"default"} displayName={viewModel.displayReference} command={viewModel.navigateToProjectDetailsPageCommand} />
            {/* <LabelDisplayReference>{viewModel.displayReference} </LabelDisplayReference> */}
            <LabelDisplayName>{viewModel.displayName}</LabelDisplayName>
        </LabelCell>
    );
});
