import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

import { Validator } from "Application/Validation";

// App
export class ServiceModel extends ModelBase<ServiceModel> {
    public id: string | null = null;
    public serviceGroupId: string = "";
    public name: string = "";
    public ordinal: number = 0;
    public hourlyRate: number = 0;
    public manHours: number = 0;
    public isLinkedToPlanningApplication: boolean = false;

    constructor() {
        super();

        makeObservable(this, {
            id: observable,
            serviceGroupId: observable,
            name: observable,
            ordinal: observable,
            manHours: observable,
            hourlyRate: observable,
            isLinkedToPlanningApplication: observable,
        });
    }
}

export class ServiceModelValidator extends Validator<ServiceModel> {
    constructor() {
        super();

        this.ruleFor("name").notEmpty().withMessage("Please enter a name.");
        this.ruleFor("manHours").notNull().withMessage("Please specify default hours.").greaterThanOrEqualTo(0).withMessage("Please specify default hours.");
        this.ruleFor("hourlyRate").notNull().withMessage("Please specify an hourly rate.").greaterThanOrEqualTo(0).withMessage("Please specify an hourly rate.");
    }
}
