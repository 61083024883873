import React from "react";
import { Navigate, Route, RouteProps } from "react-router-dom";
import { AppUrls } from "../AppUrls";
import { Outlet, renderMatches, useLocation } from "react-router";

type Props = RouteProps & {
    isAllowed: () => boolean;
    redirectPath?: string;
    children?: any;
    // layout?: any;
};

//https://bobbyhadz.com/blog/typescript-interface-can-only-extend-object-type
// https://tylermcginnis.com/react-router-protected-routes-authentication/

// const PrivateRoute = (props: Props) => {
export const PrivateRoute: React.FC<Props> = (props) => {
    let location = useLocation();

    //return props.isAllowed() ? props.children : <Navigate to="/account/login" />;
    if (!props.isAllowed()) {
        return <Navigate to={props.redirectPath as string} state={{ from: location }} replace />;
    }

    return props.children ? props.children : <Outlet />;
};
PrivateRoute.defaultProps = {
    redirectPath: AppUrls.Client.Account.Login,
};
