import { FieldType, isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

import { ICommand, IKeyState, RelayCommand, startOfDay } from "Application";
import { NewRequestModel, NewRequestModelValidator } from "./NewRequestModel";

export class NewRequestViewModel extends ViewModelBase<NewRequestModel> {
    // Callback commands to the parent viewmodel.
    public parentSubmitCommand: ICommand;
    public parentCancelCommand: ICommand;

    constructor(model: NewRequestModel, submitCommand: ICommand, cancelCommand: ICommand) {
        super(model, false);

        this.setValidator(new NewRequestModelValidator());

        // Commands to be called on the parent viewmodel.
        this.parentSubmitCommand = submitCommand;
        this.parentCancelCommand = cancelCommand;

        makeObservable(this, {});
    }

    //#region Propertiies

    public get displayName() {
        return this.model.id ? "Edit request" : "New request";
    }

    public get submitDisplayName() {
        return this.model.id ? "Update" : "Add";
    }

    public get leaveTypes() {
        return this.model.leaveTypes.map((item) => {
            return {
                key: item.id,
                text: item.name,
            };
        });
    }

    public get fromDateDayTypes() {
        return this.model.leaveDayTypes
            .filter((item) => item.isStartDay)
            .map((item) => {
                return {
                    key: item.id,
                    text: item.name,
                };
            });
    }

    public get toDateDayTypes() {
        return this.model.leaveDayTypes
            .filter((item) => !item.isStartDay)
            .map((item) => {
                return {
                    key: item.id,
                    text: item.name,
                };
            });
    }

    //#endregion Properties

    //#region Commands

    public updateLeaveTypeCommand = new RelayCommand((value: string | null) => {
        this.updateField("leaveTypeId", value);
    });

    public updateRequestReasonCommand: ICommand = new RelayCommand((value: string) => {
        const requestReason = !isEmptyOrWhitespace(value) ? value : null;

        this.updateField("requestReason", requestReason);
    });

    public updateFromDateCommand = new RelayCommand((date: Date | undefined) => {
        const fromDate = startOfDay(date);

        this.updateField("fromDate", fromDate);
    });

    public updateFromDayLeaveTypeCommand = new RelayCommand((value: string | null) => {
        this.updateField("fromDateLeaveDayTypeId", value);
    });

    public updateToDateCommand = new RelayCommand((date: Date | undefined) => {
        const fromDate = startOfDay(date);

        this.updateField("toDate", fromDate);
    });

    public updateToDayLeaveTypeCommand = new RelayCommand((value: string | null) => {
        this.updateField("toDateLeaveDayTypeId", value);
    });

    public submitCommand = new RelayCommand(
        () => {
            if (this.isModelValid()) {
                this.parentSubmitCommand.execute();
            }
        },
        () => this.parentSubmitCommand.canExecute(),
    );

    public cancelCommand = new RelayCommand(
        () => this.parentCancelCommand.execute(),
        () => this.parentCancelCommand.canExecute(),
    );

    //#endregion Commands

    // #region Supporting

    private updateField(fieldName: keyof FieldType<NewRequestModel>, value: any, keyState?: IKeyState) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    // #endregion Supporting
}
