import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

import { Validator } from "Application/Validation";
import { TermsAndConditionsTypeModel } from "Views/Shared/TermsAndConditions/TermsAndConditionsTypeModel";

export class TermsAndConditionModel extends ModelBase<TermsAndConditionModel> {
    public id: string | null = null;

    public name: string | null = null;
    public body: string | null = null;

    public termsAndConditionsTypeId: string | null = null;

    constructor() {
        super();
        makeObservable(this, {
            id: observable,
            name: observable,
            body: observable,
            termsAndConditionsTypeId: observable,
        });
    }
}

export class TermsAndConditionModelValidator extends Validator<TermsAndConditionModel> {
    constructor() {
        super();

        this.ruleFor("name").notNull().withMessage("Please enter a title").notEmpty().withMessage("Please enter a title");
        this.ruleFor("termsAndConditionsTypeId").notNull().withMessage("Please select a type").notEmpty().withMessage("Please select a type");
    }
}
