import { runInAction } from "mobx";

import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { LeaveDayType } from "Views/Leave/Shared/LeaveDayType";
import { LeaveType } from "Views/Leave/Shared/LeaveType";
import { LeaveCalendarViewModel } from "../LeaveCalendarViewModel";
import { LeaveCalendarModel } from "../LeaveCalendarModel";
import { BankHolidayEventViewModel } from "../Components/Body/Date/Components/BankHolidayEvent/BankHolidayEventViewModel";
import { BankHolidayEventModel } from "../Components/Body/Date/Components/BankHolidayEvent/BankHolidayEventModel";
import { LeaveRequestEventViewModel } from "../Components/Body/Date/Components/LeaveRequestEvent/LeaveRequestEventViewModel";
import { LeaveRequestEventModel } from "../Components/Body/Date/Components/LeaveRequestEvent/LeaveRequestEventModel";
import { HalfDaysEventViewModel } from "../Components/Body/Date/Components/HalfDaysEvent/HalfDaysEventViewModel";
import { NoEventViewModel } from "../Components/Body/Date/Components/NoEvent/NoEventViewModel";
import { WeekendEventViewModel } from "../Components/Body/Date/Components/WeekendEvent/WeekendEventViewModel";

class Request {
    public fromDate: Date | null = null;
    public toDate: Date | null = null;
}

class Response {
    public bankHolidays: BankHolidayResponse[] = [];
    public leaveRequests: LeaveRequestAndHalfDayResponse[] = [];
    public leaveTypes: LeaveTypeResponse[] = [];
    public leaveDayTypes: LeaveDayTypeResponse[] = [];
}

class BankHolidayResponse {
    public date: string | null = null;
    public title: string | null = null;
}

class LeaveRequestAndHalfDayResponse {
    public leaveRequest: LeaveRequestResponse | null = null;
    public leaveRequestHalfDays: HalfDayResponse[] = [];
}

class LeaveRequestResponse {
    public id: string | null = null;

    // Value Objects
    public leaveType: LeaveTypeResponse | null = null;
    public leaveStatusType: LeaveStatusTypeResponse | null = null;
    public fromDate: RequestDateResponse | null = null;
    public toDate: RequestDateResponse | null = null;

    // User Request
    public requestUserId: string | null = null;
    public requesteDate: string | null = null;
    public requestReason: string | null = null;

    // User Response
    public responseUserId: string | null = null;
    public responseDate: string | null = null;
    public responseReason: string | null = null;

    // Additonal
    public numberOfDays: number | null = null;
    public originalLeaveRequestId: string | null = null;
}

class LeaveTypeResponse {
    public id: string | null = null;
    public name: string | null = null;
    public type: string | null = null;
    public ordinal: number | null = null;
    public isDeleted: boolean | null = null;
    public foregroundColor: string | null = null;
    public backgroundColor: string | null = null;
    public isTransactionRequest: boolean | null = null;
    public isTransactionCancelRequest: boolean | null = null;
}

class LeaveStatusTypeResponse {
    public id: string | null = null;
    public name: string | null = null;
    public type: string | null = null;
    public ordinal: number | null = null;
    public isDeleted: boolean | null = null;
    public foregroundColor: string | null = null;
    public backgroundColor: string | null = null;
}

class RequestDateResponse {
    public date: string | null = null;
    public leaveDayType: LeaveDayTypeResponse | null = null;
}

class LeaveDayTypeResponse {
    public id: string | null = null;
    public name: string | null = null;
    public type: string | null = null;
    public ordinal: number | null = null;
    public isStartDay: boolean | null = null;
}

class HalfDayResponse {
    public date: string | null = null;
    public isAfternoon: boolean = false;
}

export class GETCalendarEventsEndpoint extends Endpoint<Request, Response> {
    private viewModel: LeaveCalendarViewModel;

    constructor(viewModel: LeaveCalendarViewModel) {
        super();

        this.viewModel = viewModel;
        this.verb(Http.Post);
        this.path(AppUrls.Server.Admin.Leave.GetCalendarEvents);
    }

    public async HandleRequestAsync(model: LeaveCalendarModel): Promise<any> {
        const request = new Request();

        request.fromDate = this.viewModel.startOfMonth.toDate();
        request.toDate = this.viewModel.endOfMonth.toDate();

        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            // Leave Types.
            const leaveTypes: LeaveType[] = [];

            response.leaveTypes.forEach((item: LeaveTypeResponse) => {
                const valueObject = new LeaveType();

                valueObject.id = item.id!;
                valueObject.name = item.name!;
                valueObject.type = item.type!;
                valueObject.ordinal = item.ordinal!;
                valueObject.isDeleted = item.isDeleted!;
                valueObject.foregroundColor = item.foregroundColor!;
                valueObject.backgroundColor = item.backgroundColor!;
                valueObject.isTransactionRequest = item.isTransactionRequest!;
                valueObject.isTransactionCancelRequest = item.isTransactionCancelRequest!;

                leaveTypes.push(valueObject);
            });

            this.viewModel.model.leaveTypes.replace(leaveTypes);

            // Leave Day Types.
            const leaveDayTypes: LeaveDayType[] = [];

            response.leaveDayTypes.forEach((item: LeaveDayTypeResponse) => {
                const valueObject = new LeaveDayType();

                valueObject.id = item.id!;
                valueObject.name = item.name!;
                valueObject.type = item.type!;
                valueObject.ordinal = item.ordinal!;
                valueObject.isStartDay = item.isStartDay!;

                leaveDayTypes.push(valueObject);
            });

            this.viewModel.model.leaveDayTypes.replace(leaveDayTypes);

            // Reset the events for each date on the calendar.
            // TODO: APM - This code is not good. Must be a better approach available
            // for the 4 following loops?
            this.viewModel.dateViewModels.forEach((viewModel) => {
                switch (true) {
                    case viewModel.eventViewModel instanceof NoEventViewModel:
                    case viewModel.eventViewModel instanceof WeekendEventViewModel:
                        break;

                    default:
                        viewModel.eventViewModel = new NoEventViewModel();
                        break;
                }
            });

            // Bank Holidays.
            response.bankHolidays.forEach((item: BankHolidayResponse) => {
                const dateViewModel = this.viewModel.dateViewModels.find((dateViewModel) => {
                    const bankHolidayDate = new Date(item.date!);
                    const dateViewModelDate = dateViewModel.date;

                    return bankHolidayDate.getTime() === dateViewModelDate.getTime();
                });

                if (dateViewModel) {
                    const model = new BankHolidayEventModel();

                    model.fromResponse(item);

                    dateViewModel.eventViewModel = new BankHolidayEventViewModel(model);
                }
            });

            // Leave Requests.
            const dateGroups = new Map<string, LeaveRequestAndHalfDayResponse[]>();

            response.leaveRequests.forEach((item: LeaveRequestAndHalfDayResponse) => {
                item.leaveRequestHalfDays.forEach((halfDay) => {
                    if (!dateGroups.has(halfDay.date!)) {
                        dateGroups.set(halfDay.date!, new Array<LeaveRequestAndHalfDayResponse>());
                    }

                    dateGroups.get(halfDay.date!)!.push(item);
                });
            });

            dateGroups.forEach((group, key) => {
                const dateViewModel = this.viewModel.dateViewModels.find((dateViewModel) => {
                    const bankHolidayDate = new Date(key!);
                    const dateViewModelDate = dateViewModel.date;

                    return bankHolidayDate.getTime() === dateViewModelDate.getTime();
                });

                if (dateViewModel) {
                    const halfDayEventViewModel = new HalfDaysEventViewModel();

                    group.forEach((item: LeaveRequestAndHalfDayResponse) => {
                        const halfDays = item.leaveRequestHalfDays.filter((hf) => hf.date === key);

                        if (halfDays.some((hf) => !hf.isAfternoon)) {
                            const model = new LeaveRequestEventModel();

                            model.fromResponse(item);

                            halfDayEventViewModel.morningEventViewModel = new LeaveRequestEventViewModel(model, this.viewModel);
                        }

                        if (halfDays.some((hf) => hf.isAfternoon)) {
                            const model = new LeaveRequestEventModel();

                            model.fromResponse({ leaveRequest: item.leaveRequest });

                            halfDayEventViewModel.afternoonEventViewModel = new LeaveRequestEventViewModel(model, this.viewModel);
                        }
                    });

                    dateViewModel.eventViewModel = halfDayEventViewModel;
                }
            });
        });
    }
}
