import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

export class PlanningApplicationLiteModel extends ModelBase<PlanningApplicationLiteModel> {
    public planningRef: string | null = null;
    public projectRef: string = "";
    public projectTitle: string = "";
    constructor() {
        super();
        makeObservable(this, {
            planningRef: observable,
            projectRef: observable,
            projectTitle: observable,
        });
    }
}
