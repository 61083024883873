import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

export class WorkCalendarModel extends ModelBase<WorkCalendarModel> {
    constructor() {
        super();

        makeObservable(this, {});
    }
}
