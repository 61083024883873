import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

export class SuppliersModel extends ModelBase<SuppliersModel> {
    // Filtering
    public filterKeyword: string = "";
    public filterSupplierTypeId: string = "";
    public filterSupplierSubTypeId: string = "";

    // Sorting
    public sortKey = "fullName"; // A property defined in the table column definitions.
    public sortDescending = false;

    constructor() {
        super();
        makeObservable(this, {
            // Observables
            filterKeyword: observable,
            filterSupplierTypeId: observable,
            filterSupplierSubTypeId: observable,
            sortKey: observable,
            sortDescending: observable,
        });
    }
}
