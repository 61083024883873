import { ViewModelBase } from "@shoothill/core";
import { startOfDay } from "date-fns";
import { makeObservable, observable } from "mobx";
import moment from "moment";

import { DateModel } from "./Components/Date/DateModel";
import { DateViewModel } from "./Components/Date/DateViewModel";
import { LeaveRowViewModel } from "./Components/Rows/LeaveRow/LeaveRowViewModel";
import { ProjectRowViewModel } from "./Components/Rows/ProjectRow/ProjectRowViewModel";
import { WorkCalendarModel } from "./WorkCalendarModel";
import { ICommand } from "Application";

export class WorkCalendarViewModel extends ViewModelBase<WorkCalendarModel> {
    public startOfWeek: Date = startOfDay(new Date());
    public headerDateViewModels = observable<DateViewModel>([]);
    public leaveRowViewModel: LeaveRowViewModel | null = null;
    public projectRowViewModels = observable<ProjectRowViewModel>([]);
    constructor(model = new WorkCalendarModel()) {
        super(model, false);

        makeObservable(this, {
            // Observables
            startOfWeek: observable,
            headerDateViewModels: observable,
            leaveRowViewModel: observable,
            projectRowViewModels: observable,
        });
    }

    // #region Properties

    public get currentMonthAndYear() {
        return moment(this.startOfWeek).format("MMMM YYYY");
    }

    public get canDisplayLeaveRow() {
        return this.leaveRowViewModel !== null && this.leaveRowViewModel.dateViewModels.length > 0;
    }

    // #endregion Properties

    // #region Helpers

    /**
     * Creates a fresh collection of date viewmodels for use with the header.
     */
    public createHeaderDateViewModels(): DateViewModel[] {
        const momentStartOfWeek = moment(this.startOfWeek);
        const momentEndOfWeek = moment(this.startOfWeek).add(5, "day");
        const dateViewModels: DateViewModel[] = [];

        for (let index = 0; momentStartOfWeek.clone().add(index, "day") < momentEndOfWeek; index++) {
            const momentDate = momentStartOfWeek.clone().add(index, "day");

            const dateViewModel = new DateViewModel(new DateModel(momentDate.toDate()));

            dateViewModels.push(dateViewModel);
        }

        return dateViewModels;
    }

    /**
     * Creates a fresh collection of date viewmodels for use with leave and bank holidays.
     */
    public createLeaveDateViewModels(): DateViewModel[] {
        const momentStartOfWeek = moment(this.startOfWeek);
        const momentEndOfWeek = moment(this.startOfWeek).add(5, "day");
        const dateViewModels: DateViewModel[] = [];

        // Simulate a row for leave accounting for half days.
        for (let index = 0; momentStartOfWeek.clone().add(index, "day") < momentEndOfWeek; index++) {
            const momentDate = momentStartOfWeek.clone().add(index, "day");

            for (let halfDayIndex = 0; halfDayIndex < 2; halfDayIndex++) {
                const dateViewModel = new DateViewModel(new DateModel(momentDate.toDate()));

                dateViewModels.push(dateViewModel);
            }
        }

        return dateViewModels;
    }

    /**
     * Creates a fresh collection of date viewmodels for use with projects.
     */
    public createProjectDateViewModels(): DateViewModel[] {
        const momentStartOfWeek = moment(this.startOfWeek);
        const momentEndOfWeek = moment(this.startOfWeek).add(5, "day");
        const dateViewModels: DateViewModel[] = [];

        for (let index = 0; momentStartOfWeek.clone().add(index, "day") < momentEndOfWeek; index++) {
            const momentDate = momentStartOfWeek.clone().add(index, "day");

            const dateViewModel = new DateViewModel(new DateModel(momentDate.toDate()));

            dateViewModels.push(dateViewModel);
        }

        return dateViewModels;
    }

    // #endregion Helpers
}
