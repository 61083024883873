import { ModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

export class ResetAllowancesModel extends ModelBase<ResetAllowancesModel> {
    constructor() {
        super();

        makeObservable(this, {});
    }
}
