import { observer } from "mobx-react-lite";
import React from "react";

import { Box, ThemedCheckBox, ThemedEditDate, ThemedEditText, ThemedPersonaComboBox, ThemedText } from "Application";
import { AssignViewModel } from "./AssignViewModel";
import { FormContainer, FormContainerRow, FormSubContainer } from "Views/Shared/SharedComponents";

interface IAssignViewProps {
    viewModel: AssignViewModel;
}

const CheckBoxStyle = {
    root: {
        marginTop: "30px",
    },
};

export const AssignView: React.FC<IAssignViewProps> = observer((props) => {
    const viewModel = props.viewModel;

    return (
        <FormContainer>
            <FormSubContainer>
                <FormContainerRow dc={"100%"}>
                    <ThemedText styles={{ root: { fontWeight: "600" } }} fontStyle="h7">
                        {viewModel.canDisplayBillableEffort
                            ? `This task has ${viewModel.remainingProjectTaskBillableEffort} of ${viewModel.projectTaskBillableEffort} billable hrs. remaining `
                            : "This task is billed hourly"}
                    </ThemedText>
                </FormContainerRow>
                <Box showIf={viewModel.showAssignees}>
                    <ThemedPersonaComboBox
                        command={viewModel.updateUserCommand}
                        displayName="Assigned to*"
                        options={viewModel.users}
                        placeholder="Please select"
                        validationMessage={() => viewModel.getError("user")}
                        value={() => viewModel.assigneeId}
                    />
                </Box>
                <FormContainerRow dc={"1fr 1fr 1fr"}>
                    <ThemedEditText
                        command={viewModel.updateEffortCommand}
                        displayName="staff hours*"
                        validationMessage={() => viewModel.getError("effort")}
                        value={() => viewModel.getValue("effort") ?? ""}
                    />
                    {viewModel.canDisplayBillableEffort && (
                        <ThemedEditText
                            command={viewModel.updateBillableEffortCommand}
                            displayName="Chargeable hours*"
                            validationMessage={() => viewModel.getError("billableEffort")}
                            value={() => viewModel.getValue("billableEffort") ?? ""}
                        />
                    )}
                    <ThemedCheckBox
                        command={viewModel.updateIsNonBillableEffortCommand}
                        displayName="NCT"
                        styles={CheckBoxStyle}
                        value={() => viewModel.getValue("isNonBillableEffort")}
                        paletteColor="secondary"
                    />
                </FormContainerRow>
                <FormContainerRow dc={"1fr 1fr"}>
                    <ThemedEditDate
                        command={viewModel.updatePlannedStartDateCommand}
                        displayName="Date start*"
                        size="larger"
                        validationMessage={() => viewModel.getError("plannedStartDate")}
                        value={() => viewModel.getValue("plannedStartDate")}
                    />
                    <ThemedEditDate
                        command={viewModel.updatePlannedEndDateCommand}
                        displayName="Due by*"
                        size="larger"
                        validationMessage={() => viewModel.getError("plannedEndDate")}
                        value={() => viewModel.getValue("plannedEndDate")}
                    />
                </FormContainerRow>
            </FormSubContainer>
        </FormContainer>
    );
});
