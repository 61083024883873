import { IStyle, mergeStyleSets } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React from "react";

import { ThemedCheckBox, ThemedText } from "Application";
import { TaskAssignmentViewModel } from "./TaskAssignmentViewModel";

interface INewTaskAssignmentViewStyles {
    root: IStyle;
}

interface IProps {
    viewModel: TaskAssignmentViewModel;
}

const styleDefinitions: INewTaskAssignmentViewStyles = {
    root: {
        alignItems: "center",
        backgroundColor: "#FCFCFC",
        borderLeft: "5px solid #ff4b22",
        display: "flex",
        paddingLeft: "10px",
        marginBottom: "10px",
        justifyContent: "space-between",
    },
};

export const TaskAssignmentView: React.FC<IProps> = observer((props) => {
    // #region Code Behind

    const viewModel = props.viewModel;
    const styles = mergeStyleSets(styleDefinitions);

    // #endregion Code Behind

    if (viewModel) {
        return (
            <div className={styles.root}>
                <ThemedText fontStyle="h5">{viewModel.displayName}</ThemedText>
                <ThemedCheckBox command={viewModel.toggleSelectTaskAssignment} displayName="" value={() => viewModel.getValue("enabled")} paletteColor="secondary" />
            </div>
        );
    }

    return <React.Fragment />;
});
