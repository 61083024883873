//libraries
import { FieldType, ViewModelBase, formatCurrency } from "@shoothill/core";
import { computed, makeObservable } from "mobx";
import { APIClient, IKeyState, RelayCommand, formatFixedDecimalNumber } from "Application";

//app
import { InvoiceForecastMonthCapacityOverrideModelValidator, InvoiceForecastMonthCapacityOverrideModel } from "./InvoiceForecastMonthCapacityOverrideModel";

export class InvoiceForecastMonthCapacityOverrideViewModel extends ViewModelBase<InvoiceForecastMonthCapacityOverrideModel> {
    public apiClient = new APIClient();

    constructor(model?: InvoiceForecastMonthCapacityOverrideModel) {
        super(model ?? new InvoiceForecastMonthCapacityOverrideModel(), false);

        this.setValidator(new InvoiceForecastMonthCapacityOverrideModelValidator());
        makeObservable(this, {
            hours: computed,
            value: computed,
            isActive: computed,
        });
    }

    //region endpoint calls

    //endregion endpoint calls

    //region properties
    public get KEY() {
        return this.getValue("KEY");
    }

    public get hours() {
        return this.model.hours ? this.model.hours : "";
    }

    public get value() {
        return this.model.value ? this.model.value : "";
    }

    public get isActive() {
        return this.model.isActive ?? false;
    }

    public get formattedHours() {
        return this.model.hours ? formatFixedDecimalNumber(Number(this.model.hours)) : "N/A";
    }

    public get formattedValue() {
        return this.model.value ? formatCurrency(Number(this.model.value)) : "N/A";
    }

    //endregion properties

    //region commands
    public updateOverrideHoursCommand = new RelayCommand((value: string) => {
        this.updateField("hours", value);
    });

    public updateOverrideValueCommand = new RelayCommand((value: string) => {
        this.updateField("value", value);
    });

    public updateUseOverrideValuesCommand = new RelayCommand((value: boolean) => {
        this.updateField("isActive", value);
    });

    //endregion commands

    // #region Supporting

    private updateField(fieldName: keyof FieldType<InvoiceForecastMonthCapacityOverrideModel>, value: any, keyState?: IKeyState) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    // #endregion Supporting
}
