import * as React from "react";
import { SVGProps } from "react";

interface IProps {
    opened: boolean;
    hideToggle?: boolean;
}

export const CalendarToggleIcon: React.FunctionComponent<IProps> = (props: IProps) => {
    const renderToggleIcon = () => {
        switch (props.opened) {
            case false:
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="23.914" viewBox="0 0 42 23.914">
                        <path
                            id="Union_50"
                            data-name="Union 50"
                            d="M29.526,23.529l-5.257-5.26a8.509,8.509,0,0,1-4.311,1.168,9.021,9.021,0,0,1-8.825-8.825A8.559,8.559,0,0,1,19.695,2.05a9.019,9.019,0,0,1,8.827,8.825,8.514,8.514,0,0,1-1.243,4.442l5.229,5.23h0c.514.514.7,1.162.187,1.676l-1.306,1.3a1.318,1.318,0,0,1-1.863,0ZM19.958,16.8a5.928,5.928,0,0,0,5.927-5.927,6.385,6.385,0,0,0-6.191-6.191,5.926,5.926,0,0,0-5.926,5.927h0A6.388,6.388,0,0,0,19.958,16.8ZM1,23a1,1,0,0,1-1-1V14.183H-4.174V18l-4.766-4.38a.134.134,0,0,1,0-.223L-4.174,9v3.8H0V3.733a1,1,0,0,1,1-1H3V5.467H6V3H3V1A1,1,0,0,1,4,0H5A1,1,0,0,1,6,1V2.734h8.28A9.581,9.581,0,0,0,10.441,8.2H1V22H20V20.436a9.62,9.62,0,0,0,1-.058V22a1,1,0,0,1-1,1Zm13-3H3V18H13.6c.129.112.264.222.4.329V20h0Zm-4-4H3V14h7v2h0Zm10-.2h0V8.2H15.4a4.937,4.937,0,0,1,4.295-2.517A4.691,4.691,0,0,1,21,5.872v9.819a4.909,4.909,0,0,1-1,.111ZM18,12H14.982a4.7,4.7,0,0,1-.214-1.3V10.61a4.937,4.937,0,0,1,.038-.61H18v2h0Zm-7.76,0H3V10h7.152c-.012.2-.019.4-.019.611A9.15,9.15,0,0,0,10.24,12h0ZM15,2.281V1a1,1,0,0,1,1-1h1a1,1,0,0,1,1,1v.2a9.464,9.464,0,0,0-3,1.082h0Z"
                            transform="translate(9)"
                            fill="#ff4b22"
                        />
                    </svg>
                );
            case true:
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="23.914" viewBox="0 0 42 23.914" {...props}>
                        <path
                            id="Union_50"
                            data-name="Union 50"
                            d="M29.526,23.529l-5.257-5.26a8.509,8.509,0,0,1-4.311,1.168,9.021,9.021,0,0,1-8.825-8.825A8.559,8.559,0,0,1,19.695,2.05a9.019,9.019,0,0,1,8.827,8.825,8.514,8.514,0,0,1-1.243,4.442l5.229,5.23h0c.514.514.7,1.162.187,1.676l-1.306,1.3a1.318,1.318,0,0,1-1.863,0ZM19.958,16.8a5.928,5.928,0,0,0,5.927-5.927,6.385,6.385,0,0,0-6.191-6.191,5.926,5.926,0,0,0-5.926,5.927h0A6.388,6.388,0,0,0,19.958,16.8ZM1,23a1,1,0,0,1-1-1V14.483L-3.826,18V14.183h-5A.161.161,0,0,1-9,14.02V12.965a.162.162,0,0,1,.175-.164h5V9L0,12.528V3.733a1,1,0,0,1,1-1H3V5.467H6V3H3V1A1,1,0,0,1,4,0H5A1,1,0,0,1,6,1V2.734h8.28A9.581,9.581,0,0,0,10.441,8.2H1V22H20V20.436a9.62,9.62,0,0,0,1-.058V22a1,1,0,0,1-1,1Zm13-3H3V18H13.6c.129.112.264.222.4.329V20h0Zm-4-4H3V14h7v2h0Zm10-.2h0V8.2H15.4a4.937,4.937,0,0,1,4.295-2.517A4.691,4.691,0,0,1,21,5.872v9.819a4.909,4.909,0,0,1-1,.111ZM18,12H14.982a4.7,4.7,0,0,1-.214-1.3V10.61a4.937,4.937,0,0,1,.038-.61H18v2h0Zm-7.76,0H3V10h7.152c-.012.2-.019.4-.019.611A9.15,9.15,0,0,0,10.24,12h0ZM15,2.281V1a1,1,0,0,1,1-1h1a1,1,0,0,1,1,1v.2a9.464,9.464,0,0,0-3,1.082h0Z"
                            transform="translate(9)"
                            fill="#ff4b22"
                        />
                    </svg>
                );
        }
    };

    const renderSimpleIcon = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="33" height="23.914" viewBox="0 0 33 23.914">
                <path
                    d="M29.526,23.529l-5.257-5.26a8.509,8.509,0,0,1-4.311,1.168,9.021,9.021,0,0,1-8.825-8.825A8.559,8.559,0,0,1,19.695,2.05a9.019,9.019,0,0,1,8.827,8.825,8.514,8.514,0,0,1-1.243,4.442l5.229,5.23h0c.514.514.7,1.162.187,1.676l-1.306,1.3a1.318,1.318,0,0,1-1.863,0ZM19.958,16.8a5.928,5.928,0,0,0,5.927-5.927,6.385,6.385,0,0,0-6.191-6.191,5.926,5.926,0,0,0-5.926,5.927h0A6.388,6.388,0,0,0,19.958,16.8ZM1,23a1,1,0,0,1-1-1V3.733a1,1,0,0,1,1-1H3V5.467H6V3H3V1A1,1,0,0,1,4,0H5A1,1,0,0,1,6,1V2.734h8.28A9.581,9.581,0,0,0,10.441,8.2H1V22H20V20.436a9.62,9.62,0,0,0,1-.058V22a1,1,0,0,1-1,1Zm13-3H3V18H13.6c.129.112.264.222.4.329V20h0Zm-4-4H3V14h7v2h0Zm10-.2h0V8.2H15.4a4.937,4.937,0,0,1,4.295-2.517A4.691,4.691,0,0,1,21,5.872v9.819a4.909,4.909,0,0,1-1,.111ZM18,12H14.982a4.7,4.7,0,0,1-.214-1.3V10.61a4.937,4.937,0,0,1,.038-.61H18v2h0Zm-7.76,0H3V10h7.152c-.012.2-.019.4-.019.611A9.15,9.15,0,0,0,10.24,12h0ZM15,2.281V1a1,1,0,0,1,1-1h1a1,1,0,0,1,1,1v.2a9.464,9.464,0,0,0-3,1.082h0Z"
                    fill="#ff4b22"
                />
            </svg>
        );
    };

    const render = () => {
        if (props.hideToggle) {
            return renderSimpleIcon();
        } else {
            return renderToggleIcon();
        }
    };

    return <>{render()}</>;
};
