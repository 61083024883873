import { mergeStyleSets } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React from "react";

import { AutoGrid, Box, ThemedComboBox, ThemedDefaultRadioButtons, ThemedEditDate, ThemedEditText, ThemedPersonaComboBox } from "Application";
import { FormContainerRow } from "Views/Shared/SharedComponents";
import { EnquirySubViewModel } from "./EnquirySubViewModel";
import { Files } from "Views/Shared/Files/Files";
import { SourceOfEnquiryView } from "Views/Shared/SourceOfEnquiry/SourceOfEnquiryView";
import { ThemedEditTime } from "Styles/ThemedPrimitives/Style1/ThemedEditTime";
import styled from "@emotion/styled";

export interface IEnquirySubViewProps {
    viewModel: EnquirySubViewModel;
}

const labelStyle = mergeStyleSets({
    root: {
        fontWeight: 400,
    },
});

const PersonVisitingInputGroup = styled.div`
    display: flex;
    & > *:not(:last-of-type) {
        margin-right: 30px;
    }
`;

export const EnquirySubView: React.FC<IEnquirySubViewProps> = observer((props) => {
    // #region Code Behind

    const viewModel = props.viewModel;

    const ChoiceGroupStyles = {
        label: {
            display: "inline",
        },
        flexContainer: {
            columnGap: "1em",
            display: "inline-flex",
            flexDirection: "row",
            flexWrap: "wrap",
        },
    };

    // #endregion Code Behind

    return (
        <React.Fragment>
            <FormContainerRow dc={"75%"}>
                <AutoGrid m={"0"} columnGap={"30px"} dc={"305px"}>
                    <ThemedComboBox
                        displayName="Link to Existing Project"
                        options={viewModel.getProjectsListFormatted}
                        command={viewModel.updateProjectIdCommand}
                        placeholder={"Please select a project (if required)"}
                        value={() => viewModel.getValue("projectId")}
                    />
                </AutoGrid>
                <AutoGrid m={"0"} columnGap={"30px"} dc={"305px"}>
                    <ThemedDefaultRadioButtons
                        displayName="Project type"
                        styles={ChoiceGroupStyles}
                        options={viewModel.projectTypeOptions}
                        value={() => viewModel.getValue("projectTypeId")}
                        command={viewModel.updateProjectTypeCommand}
                        validationMessage={() => viewModel.getError("projectTypeId")}
                    />
                </AutoGrid>
            </FormContainerRow>
            <FormContainerRow dc={"100%"}>
                <AutoGrid m={"0"} columnGap={"30px"} dc={"305px auto"}>
                    <ThemedComboBox
                        displayName="Enquiry type*"
                        options={viewModel.enquiryTypeOptions}
                        command={viewModel.updateEnquiryTypeCommand}
                        placeholder={"Please select a type"}
                        validationMessage={() => viewModel.getError("enquiryTypeId")}
                        value={() => viewModel.getValue("enquiryTypeId")}
                    />
                    <ThemedEditText
                        command={viewModel.updateTitleCommand}
                        displayName="Job name*"
                        placeholder="Job name"
                        validationMessage={() => viewModel.getError("title")}
                        value={() => viewModel.getValue("title") ?? ""}
                    />
                </AutoGrid>
            </FormContainerRow>
            <FormContainerRow dc={"100%"} mb="15px !important">
                <ThemedEditText
                    command={viewModel.updateDescriptionCommand}
                    displayName="Enquiry description*"
                    isMultiline
                    placeholder="Please enter a description of the enquiry"
                    styles={{ fieldGroup: { minHeight: 120 } }}
                    validationMessage={() => viewModel.getError("description")}
                    value={() => viewModel.getValue("description") ?? ""}
                />
            </FormContainerRow>
            <Files viewModel={viewModel.filesViewModel} />
            <FormContainerRow dc={"100%"} mt={"20px !important"}>
                <Box display="flex" alignItems="center">
                    <Box display="flex" alignItems="center" marginRight={"30px"} width={"305px"}>
                        <ThemedComboBox
                            command={viewModel.updateStatusCommand}
                            displayName="Status*"
                            labelColor="default"
                            options={viewModel.enquiryStatusOptions}
                            placeholder="Please select"
                            validationMessage={() => viewModel.getError("enquiryStatusId")}
                            value={() => viewModel.getValue("enquiryStatusId")}
                            styles={{ container: { width: "100%" } }}
                        />
                    </Box>

                    <Box display="flex" alignItems="center" marginRight={"30px"} minWidth={"305px"}>
                        <SourceOfEnquiryView viewModel={viewModel.sourceOfEnquiryViewModel} />
                    </Box>
                </Box>
                <PersonVisitingInputGroup>
                    <ThemedPersonaComboBox
                        command={viewModel.updatePersonVisitingCommand}
                        displayName="Person visiting"
                        labelStyle={labelStyle}
                        options={viewModel.personVisitingOptions}
                        placeholder="Please select"
                        validationMessage={() => viewModel.getError("enquiryPersonVisitingId")}
                        value={() => viewModel.getValue("enquiryPersonVisitingId")}
                    />
                    <ThemedEditDate
                        command={viewModel.updatePersonVisitingVisitDateCommand}
                        displayName="Date of visit"
                        size="larger"
                        validationMessage={() => viewModel.getError("enquiryPersonVisitingVisitDate")}
                        value={() => viewModel.getValue("enquiryPersonVisitingVisitDate")}
                    />
                    <ThemedEditTime
                        command={viewModel.updatePersonVisitingVisitTimeCommand}
                        clearCommand={viewModel.clearPersonVisitingVisitTimeCommand}
                        displayName="Time of visit"
                        size="larger"
                        validationMessage={() => viewModel.getError("enquiryPersonVisitingVisitTime")}
                        value={() => viewModel.getValue("enquiryPersonVisitingVisitTime") ?? ""}
                    />
                </PersonVisitingInputGroup>
            </FormContainerRow>
        </React.Fragment>
    );
});
