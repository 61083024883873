import { ModelBase } from "@shoothill/core";
import { Validator } from "Application/Validation";
import { IObservableArray, action, makeObservable, observable } from "mobx";
import { InvoiceHistoryMonthCapacityModel } from "./InvoiceHistoryCapacity/InvoiceHistoryMonthCapacityModel";
import { InvoiceForecastMonthCapacityOverrideModel } from "../Shared/InvoiceForecastMonthCapacityOverride/InvoiceForecastMonthCapacityOverrideModel";

export class InvoiceHistoryModel extends ModelBase<InvoiceHistoryModel> {
    //filtering
    public financialYearFilter: string | null = null;

    //form related
    public isEditable: boolean = false;
    public backupCopy: InvoiceHistoryModel | null = null;

    //model specific
    public capacities: IObservableArray<InvoiceHistoryMonthCapacityModel> = observable.array([]);

    constructor() {
        super();
        makeObservable(this, {
            financialYearFilter: observable,
            backupCopy: observable,
            capacities: observable,
            isEditable: observable,

            backup: action,
            restore: action,
        });
    }

    // #region Helpers

    public backup = () => {
        const model = new InvoiceHistoryModel();
        model.financialYearFilter = this.financialYearFilter;

        model.capacities.replace(
            this.capacities.map((c) => {
                const capacity = new InvoiceHistoryMonthCapacityModel();
                capacity.month = c.month;
                const override = new InvoiceForecastMonthCapacityOverrideModel();
                override.id = c.invoiceForecastCapacityOverride!.id;
                override.hours = c.invoiceForecastCapacityOverride!.hours;
                override.value = c.invoiceForecastCapacityOverride!.value;
                override.month = c.invoiceForecastCapacityOverride!.month;
                override.isActive = c.invoiceForecastCapacityOverride!.isActive;
                capacity.invoiceForecastCapacityOverride = override;
                return capacity;
            }),
        );
        this.backupCopy = model;
    };

    public restore = () => {
        if (this.backupCopy !== null) {
            this.financialYearFilter = this.backupCopy.financialYearFilter;

            const capacities: InvoiceHistoryMonthCapacityModel[] = [];

            this.backupCopy.capacities.forEach((c) => {
                const capacity = new InvoiceHistoryMonthCapacityModel();
                capacity.month = c.month;
                const override = new InvoiceForecastMonthCapacityOverrideModel();
                override.id = c.invoiceForecastCapacityOverride!.id;
                override.hours = c.invoiceForecastCapacityOverride!.hours;
                override.value = c.invoiceForecastCapacityOverride!.value;
                override.month = c.invoiceForecastCapacityOverride!.month;
                override.isActive = c.invoiceForecastCapacityOverride!.isActive;
                capacity.invoiceForecastCapacityOverride = override;
                capacities.push(capacity);
            });

            this.capacities.replace([]);
            this.capacities.replace(capacities);

            this.backupCopy = null;
        }
    };
}
