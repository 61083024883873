import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

import { LeaveDayType } from "Views/Leave/Shared/LeaveDayType";
import { LeaveRequestUser } from "Views/Leave/Shared/LeaveRequestUser";
import { LeaveType } from "Views/Leave/Shared/LeaveType";

export class LeaveRequestsModel extends ModelBase<LeaveRequestsModel> {
    // Sorting
    public sortKey = "name"; // A property defined in the table column definitions.
    public sortDescending = false;

    // Supporting
    public users = observable<LeaveRequestUser>([]);
    public leaveTypes = observable<LeaveType>([]);
    public leaveDayTypes = observable<LeaveDayType>([]);

    constructor() {
        super();

        makeObservable(this, {
            // Observables
            sortKey: observable,
            sortDescending: observable,
        });
    }
}
