import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { ServiceGroupModel } from "../ServiceGroup/ServiceGroupModel";
import { ServiceGroupsViewModel } from "../ServiceGroupsViewModel";
import { ServiceGroupViewModel } from "../ServiceGroup/ServiceGroupViewModel";
import { runInAction } from "mobx";
import { SyncServiceGroupsResponse } from "./SharedResponseModels";

class Request {
    public BulkSwapServiceGroupLites: BulkSwapServiceGroupLite[] = [];
}

class BulkSwapServiceGroupLite {
    public id: string = "";
    public ordinal: number = 0;
}

export class PostBulkSwapServiceGroupOrdinalsEndpoint extends Endpoint<Request, SyncServiceGroupsResponse> {
    private viewModel: ServiceGroupsViewModel;

    constructor(viewModel: ServiceGroupsViewModel) {
        super();
        this.viewModel = viewModel;
        this.verb(Http.Post);
        this.path(AppUrls.Server.Admin.Services.PostBulkSwapServiceGroupOrdinals);
    }

    HandleRequestAsync(requestModels: ServiceGroupModel[]): Promise<Request> {
        const request: Request = new Request();
        const sortedModels = requestModels.slice().sort((a, b) => a.ordinal - b.ordinal);
        requestModels.forEach((requestModel, index) => {
            const swapRequestModel = new BulkSwapServiceGroupLite();

            swapRequestModel.id = requestModel.id;
            swapRequestModel.ordinal = sortedModels[index].ordinal;

            request.BulkSwapServiceGroupLites.push(swapRequestModel);
        });

        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: SyncServiceGroupsResponse): Promise<any> {
        runInAction(() => {
            this.viewModel.syncModels(response);
        });
    }
}
