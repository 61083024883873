import { observer } from "mobx-react-lite";
import React from "react";

import { Box, ThemedCheckBox, ThemedEditDate, ThemedEditText, ThemedText } from "Application";
import { BillingViewModel } from "./BillingViewModel";
import { FormContainer, FormContainerRow, FormSubContainer, FormSeparator } from "Views/Shared/SharedComponents";
import { BillingNotesView } from "./SubViews/BillingNotesView";
import styled from "@emotion/styled";

interface IAssignViewProps {
    viewModel: BillingViewModel;
}

const BillingNotesContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
`;

export const BillingView: React.FC<IAssignViewProps> = observer((props) => {
    const viewModel = props.viewModel;

    const renderAssignmentNotes = () => {
        return (
            <Box>
                <ThemedText fontStyle="label" mb="10px">
                    Assignment notes
                </ThemedText>
                {viewModel.billingNotesViewModels.map((vm, index) => {
                    return (
                        <React.Fragment key={vm.KEY}>
                            <BillingNotesView viewModel={vm} />
                            {index < viewModel.billingNotesViewModels.length - 1 && <FormSeparator styles={{ root: { paddingBottom: "10px" } }} />}
                        </React.Fragment>
                    );
                })}
            </Box>
        );
    };

    return (
        <BillingNotesContainer>
            <FormContainer style={{ padding: "25px 30px", overflow: "scroll", height: "100%", maxHeight: "calc(100vh - 202px)" }}>
                {viewModel.canDisplayAssignmentNotes && <Box mb="10px">{renderAssignmentNotes()}</Box>}
            </FormContainer>
        </BillingNotesContainer>
    );
});
