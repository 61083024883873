import { runInAction } from "mobx";
import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { TermsAndConditionViewModel } from "./TermsAndConditionViewModel";

class Response {}

export class DeleteTermsAndConditionEndpoint extends EndpointWithoutRequest<Response> {
    private readonly viewModel: TermsAndConditionViewModel;

    constructor(id: string, viewModel: TermsAndConditionViewModel) {
        super();
        this.verb(Http.Delete);
        this.path(AppUrls.Server.Admin.TermConditionTemplates.DeleteTermsAndCondition.replace(":id", id));
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: Response[]): Promise<any> {
        runInAction(() => {
            this.viewModel.history.replace(AppUrls.Client.TermConditionTemplates.Table);
        });
    }
}
