import _ from "lodash";
import { runInAction } from "mobx";

import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { ProjectWeeklyUpdateContainerViewModel } from "./ProjectWeeklyUpdateContainerViewModel";
import { ProjectWeeklyUpdateResponse } from "./Shared/ProjectWeeklyUpdateResponse";

export class GETProjectWeeklyUpdateWithRelatedByIdEndpoint extends EndpointWithoutRequest<ProjectWeeklyUpdateResponse> {
    private readonly viewModel: ProjectWeeklyUpdateContainerViewModel;

    constructor(projectId: string, viewModel: ProjectWeeklyUpdateContainerViewModel) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.Projects.GetProjectWeeklyUpdateWithRelatedById.replace(":id", projectId));
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: ProjectWeeklyUpdateResponse): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            ProjectWeeklyUpdateResponse.remainingResponsePart(response, this.viewModel);
        });
    }
}
