import { observer } from "mobx-react-lite";
import { ServiceGroupViewModel } from "../ServiceGroupViewModel";
import { Container } from "./AddEditServiceGroupView.styles";
import { ThemedButton, ThemedEditText } from "Application";
import { CheckMark } from "Components/General/CheckMark";
import { addEditButtonStyleOverride, CloseButton, removeCancelButtonStyleOverride } from "../ServiceGroup.styles";

export const AddEditServiceGroupView: React.FC<{ viewModel: ServiceGroupViewModel }> = observer(({ viewModel }) => {
    return (
        <Container>
            <ThemedEditText command={viewModel.updateNameCommand} value={() => viewModel.getValue("name")} />
            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginTop: "10px" }}>
                <ThemedButton styles={addEditButtonStyleOverride} command={viewModel.confirmUpdateServiceGroupCommand} icon={<CheckMark />} marginRight={15} />
                <ThemedButton styles={removeCancelButtonStyleOverride} command={viewModel.cancelUpdateServiceGroupCommand} icon={<CloseButton />} />
            </div>
        </Container>
    );
});
