import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { Box, ThemedText } from "Application";
import { AppUrls } from "AppUrls";
import { Tabbed } from "Styles/ApplicationStyles";
import { NewBusinessViewModel } from "./NewBusinessViewModel";
import { EnquiriesView } from "./Enquiries/Table/EnquiriesView";
import { QuotesView } from "./Quotes/Table/QuotesView";
import { LostView } from "./Lost/LostView";

const Container = styled(Box)`
    width: 100%;
    margin-top: -1px;
    border-top: 1px solid #d6d6d6;
    background-color: #fff;
    min-height: 70vh;
    padding: 30px;
`;

export const NewBusinessView: React.FC = observer(() => {
    const [viewModel] = useState(() => new NewBusinessViewModel());

    return (
        <Box overflowY="auto" id="newBusinessView">
            <Tabbed>
                <Box className={viewModel.locationPathName === AppUrls.Client.Enquiries.Table ? "activeTab" : ""} onClick={() => viewModel.setEnquiriesTabCommand.execute()}>
                    <ThemedText fontStyle={"h2"}>Enquiries</ThemedText>
                </Box>
                <Box className={viewModel.locationPathName === AppUrls.Client.Quotes.Table ? "activeTab" : ""} onClick={() => viewModel.setQuotesTabCommand.execute()}>
                    <ThemedText fontStyle={"h2"}>Quotes</ThemedText>
                </Box>
                <Box className={viewModel.locationPathName === AppUrls.Client.Lost.Table ? "activeTab" : ""} onClick={() => viewModel.setLostTabCommand.execute()}>
                    <ThemedText fontStyle={"h2"}>Lost</ThemedText>
                </Box>
            </Tabbed>
            <Container>
                <Routes>
                    <Route path={"enquiries"} element={<EnquiriesView />} />
                    <Route path={"quotes"} element={<QuotesView />} />
                    <Route path={"lost"} element={<LostView />} />
                    <Route path={"*"} element={<Navigate to="enquiries" replace />} />
                </Routes>
            </Container>
        </Box>
    );
});
