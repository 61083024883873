import { ModelBase } from "@shoothill/core";
import { Validator } from "Application/Validation";
import { InvoiceForecastMonthCapacityOverrideModel } from "Views/Admin/Shared/InvoiceForecastMonthCapacityOverride/InvoiceForecastMonthCapacityOverrideModel";
import { makeObservable, observable } from "mobx";

export class InvoiceHistoryMonthCapacityModel extends ModelBase<InvoiceHistoryMonthCapacityModel> {
    public month: Date = new Date();
    public invoiceForecastCapacityOverride: InvoiceForecastMonthCapacityOverrideModel | null = null;

    constructor() {
        super();
        makeObservable(this, {
            month: observable,
            invoiceForecastCapacityOverride: observable,
        });
    }
}
