import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { LoginViewModel } from "./LoginViewModel";
import { AutoGrid, ThemedButton, ThemedEditText, setPageTitle, ThemedText, Box } from "../../../Application";
import { ThemedLoader } from "../../../Styles/ThemedPrimitives/Style1/ThemedLoader";
import { LoginHomeViewModel } from "../LoginHomeViewModel";
import { Link } from "@fluentui/react";
import { MessageBarError } from "Styles/ThemedPrimitives/Style1/MessageBarError";
/**
 * Login page view.
 */
const LoginView = observer(({ loginHomeViewModel }: { loginHomeViewModel: LoginHomeViewModel }) => {
    // #region Code Behind

    const [viewModel] = useState(() => new LoginViewModel());

    useEffect(() => {
        setPageTitle("Sign In");
    }, []);

    // #endregion Code Behind

    const renderForm = () => (
        <>
            <AutoGrid alignContent={"center"} dc="1fr" rowGap={"20px"} mb={0}>
                <ThemedText fontStyle="title">
                    <span style={{ fontSize: 15 }}>Let's create something amazing...</span>
                </ThemedText>
                <ThemedEditText
                    displayName="Login"
                    command={viewModel.updateEmailCommand}
                    labelColor="secondary"
                    placeholder="Your email address"
                    value={() => viewModel.getValue("emailAddress")}
                    validationMessage={() => viewModel.getError("emailAddress")}
                />
                <ThemedEditText
                    displayName="Password"
                    command={viewModel.updatePasswordCommand}
                    labelColor="secondary"
                    placeholder="Your password"
                    type="password"
                    value={() => viewModel.getValue("password")}
                    validationMessage={() => viewModel.getError("password")}
                />

                {/* <div className="error-login">{viewModel.apiClient.HaveValidationMessage && <MessageBarError errorMessage={viewModel.apiClient.ValidationMessage} />}</div> */}
                {viewModel.apiClient.HaveValidationMessage && (
                    <div className="error-login">
                        <MessageBarError errorMessage={viewModel.apiClient.ValidationMessage} />
                    </div>
                )}
            </AutoGrid>
            <AutoGrid mt={0} alignContent={"center"} dc="1fr" rowGap={"0"} mb={0}>
                <Box onClick={() => loginHomeViewModel.navigateToForgotPasswordCommand.execute()}>
                    <Link style={{ fontSize: "12px", color: "#71B3FC" }}>Oops! I've forgotten my password</Link>
                </Box>
            </AutoGrid>
            <AutoGrid mb={0} dc={"50%"} mt={"20px"} rowGap={0}>
                <ThemedButton displayName="Continue" command={viewModel.loginCommand} paletteColor="primary" type="submit" />
            </AutoGrid>
        </>
    );

    const renderBusy = () => <ThemedLoader isOpen={viewModel.apiClient.IsBusy} />;

    return (
        <React.Fragment>
            {renderForm()}
            {renderBusy()}
        </React.Fragment>
    );
});
export default LoginView;
