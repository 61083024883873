import styled from "@emotion/styled";
import { theme } from "Application";

export const Container = styled.div`
    display: grid;
    min-width: 850px;
    grid-template-columns: 56px 20px minmax(225px, 625px) 1fr;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    align-items: center;
    column-gap: 5px;
    margin-bottom: 0px;
    width: 100%;
    border-bottom: 1px dashed #cccccc;
    padding-bottom: 10px;
    /* background-color: ${theme.palette.common.white}; */
`;

export const ColumnLeft = styled.div`
    display: flex;
    align-items: center;
    padding-left: 15px;
    border-right: 1px solid ${theme.palette.common.border};
`;
export const ColumnCenter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 15px;
    border-right: 1px solid ${theme.palette.common.border};
`;
export const ColumnRight = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;
