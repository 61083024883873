import { ModelBase } from "@shoothill/core";
import { ProjectTaskAssignmentGroupReviewLite } from "Views/Shared/Project/ProjectTaskAssignmentGroupReviewLite";
import { makeObservable, observable } from "mobx";

export class ProjectTaskAssignmentGroupEventModel extends ModelBase<ProjectTaskAssignmentGroupEventModel> {
    public id: string = "";
    public groupTitle: string = "";
    public groupNote: string = "";

    public groupStatusName: string = "";
    public groupStatusType: string = "";
    public groupStatusForegroundColor: string = "";
    public groupStatusBackgroundColor: string = "";

    // summary: shows if the group is reviewed or not
    // behavior: if any linked project task assignments are assigned and have the isReviewed prop = true the group IsReviewed prop = true
    public isReviewed: boolean = false;

    constructor() {
        super();

        makeObservable(this, {});
    }
}
