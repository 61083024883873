import { FieldType, ViewModelBase } from "@shoothill/core";
import { computed, makeObservable } from "mobx";

import { APIClient, ICommand, RelayCommand } from "Application";
import { CreateTaskModel, CreateTaskModelValidator } from "./CreateTaskModel";
import { SummaryViewModel } from "./SummaryView/SummaryViewModel";
import { GETProjectTaskRelatedEndpoint } from "./GETProjectTaskRelatedEndpoint";
import { POSTSaveProjectTaskEndpoint } from "./POSTSaveProjectTaskEndpoint";

export class CreateTaskViewModel extends ViewModelBase<CreateTaskModel> {
    public apiClient = new APIClient();

    // This is a variable to store the preferred action following the
    // creation of a task. If set to true, the application will display
    // a view to assign a task to a user.
    public displayAssignView: boolean = false;
    public isNCTPreset: boolean | null = null;
    // Callback commands to the parent viewmodel.
    public readonly parentSubmitCommand: ICommand;
    public readonly parentCancelCommand: ICommand;

    // Sub-viewmodels.
    public summaryViewModel = new SummaryViewModel();

    constructor(model: CreateTaskModel, submitCommand: ICommand, cancelCommand: ICommand, initializeOnCreation?: boolean, isNCTPreset?: boolean) {
        super(model, false);

        // Commands to be called on the parent viewmodel.
        this.parentSubmitCommand = submitCommand;
        this.parentCancelCommand = cancelCommand;

        this.setValidator(new CreateTaskModelValidator());

        makeObservable(this, {
            serviceGroups: computed,
            services: computed,
        });

        if (initializeOnCreation !== false) {
            this.initializeProjectTask();
        }
        if (isNCTPreset) {
            this.isNCTPreset = isNCTPreset;
        }
    }

    public initializeProjectTask = () => {
        return this.apiClient.sendAsync(new GETProjectTaskRelatedEndpoint(this.model.projectId!, this));
    };

    // #region Properties

    public get serviceGroups() {
        return this.model.serviceGroups
            .filter((sg) => !sg.isNCTPreset)
            .map((sg) => {
                return {
                    key: sg.id,
                    text: sg.name,
                };
            });
    }

    public get services() {
        return this.model.services
            .filter((s) => {
                return s.serviceGroupId === this.model.serviceGroupId;
            })
            .map((sg) => {
                return {
                    key: sg.id,
                    text: sg.name,
                };
            });
    }

    // #endregion Properties

    // #region Commands

    public updateServiceGroupCommand = new RelayCommand((value: string | null) => {
        this.updateField("serviceGroupId", value);

        // SIDE-EFFECT: When changing the service group, reset any dependant selected
        // values as the they may no longer be valid.
        this.updateField("serviceId", null);
    });

    public updateServiceCommand = new RelayCommand((value: string | null) => {
        this.updateField("serviceId", value);
    });

    public updateHourlyRateCommand = new RelayCommand((value: string) => {
        const parsedValue = parseFloat(value);
        const checkedValue = Number.isNaN(parsedValue) ? null : parsedValue;

        this.updateField("hourlyRate", checkedValue);
    });

    public updateManHoursCommand = new RelayCommand(
        (value: string) => {
            const parsedValue = parseFloat(value);
            const checkedValue = Number.isNaN(parsedValue) ? null : parsedValue;

            this.updateField("manHours", checkedValue);
        },
        () => {
            return !this.model.isBilledHourly;
        },
    );

    public updateIsBilledHourlyCommand = new RelayCommand(() => {
        const newValue = !this.model.isBilledHourly;

        this.updateField("isBilledHourly", !this.model.isBilledHourly);

        // SIDE-EFFECT: If the task is to be billed hourly, the number of
        // manhours is not relevant and should be set to null.
        if (newValue) {
            this.updateField("manHours", null);
        }
    });

    public saveProjectTask = (displayAssignView: boolean) => {
        if (this.isModelValid()) {
            this.displayAssignView = displayAssignView;
            this.apiClient.sendAsync(new POSTSaveProjectTaskEndpoint(this), this.model);
        }
    };

    public submitCommand = new RelayCommand(
        (displayAssignView: boolean) => {
            this.saveProjectTask(displayAssignView);
        },
        () => {
            return !this.apiClient.IsBusy;
        },
    );

    public cancelCommand = new RelayCommand(() => {
        this.parentCancelCommand.execute();
    });

    public resetServerErrorCommand = new RelayCommand(() => {
        this.apiClient.reset();
    });

    // #endregion Commands

    private updateField(fieldName: keyof FieldType<CreateTaskModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }
}
