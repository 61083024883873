import { isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { computed, makeObservable, observable } from "mobx";

import { RelayCommand } from "Application";
import { BilledAssignmentsItemViewModel } from "./BilledAssignmentsItemViewModel";
import { BilledAssignmentsModel } from "./BilledAssignmentsModel";

export class BilledAssignmentsViewModel extends ViewModelBase<BilledAssignmentsModel> {
    public assignments = observable<BilledAssignmentsItemViewModel>([]);

    constructor() {
        super(new BilledAssignmentsModel());

        makeObservable(this, {
            // Observables
            assignments: observable,
            // Computeds
            canFilterAssignments: computed,
            filteredAssignments: computed,
            canSortAssignments: computed,
            filteredAndSortedAssignments: computed,
        });
    }

    // #region Filtering

    public get canFilterAssignments(): boolean {
        return !isEmptyOrWhitespace(this.model.filterKeyword);
    }

    public get filteredAssignments(): BilledAssignmentsItemViewModel[] {
        return this.canFilterAssignments ? this.assignments.filter((vm) => vm.filterPredicate(this.model.filterKeyword)) : this.assignments;
    }

    // #endregion Filtering

    // #region Sorting

    public updateSortCommand = new RelayCommand((key: string, sortDescending: boolean) => {
        this.model.sortKey = key;
        this.model.sortDescending = sortDescending;
    });

    public get canSortAssignments(): boolean {
        return !isEmptyOrWhitespace(this.model.sortKey);
    }

    public get filteredAndSortedAssignments(): BilledAssignmentsItemViewModel[] {
        return this.canSortAssignments
            ? this.filteredAssignments.slice().sort((lhs, rhs) => {
                  return (this.model.sortDescending ? lhs[this.model.sortKey] < rhs[this.model.sortKey] : lhs[this.model.sortKey] > rhs[this.model.sortKey]) ? 1 : -1;
              })
            : this.filteredAssignments;
    }

    // #endregion Sorting
}
