import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { EnquiryViewModel } from "./EnquiryViewModel";
import { EnquiryModel } from "./EnquiryModel";
import { EnquiryResponse } from "./Shared/EnquiryResponse";
import { runInAction } from "mobx";

class Request {
    id: string | null = null;
}

class Response {
    id: string = "";
}

export class POSTSaveLostEnquiryAsEnquiryEndpoint extends Endpoint<Request, Response> {
    private readonly viewModel: EnquiryViewModel;

    constructor(viewModel: EnquiryViewModel) {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Enquiries.SaveLostEnquiryAsEnquiry);
        this.viewModel = viewModel;
    }

    public async HandleRequestAsync(model: EnquiryModel): Promise<any> {
        const request = new Request();

        request.id = model.id;

        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: EnquiryResponse): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            EnquiryResponse.subEnquiryResponsePart(response, this.viewModel);
            EnquiryResponse.customerResponsePart(response, this.viewModel);
            EnquiryResponse.notesResponsePart(response, this.viewModel);
            EnquiryResponse.remainingResponsePart(response, this.viewModel);
        });
    }
}
