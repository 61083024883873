import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

export class UnassignedTasksItemModel extends ModelBase<UnassignedTasksItemModel> {
    public id: string = "";
    public projectTaskName: string = "";
    public projectTaskIsBilledHourly: boolean = false;
    public projectTaskOrdinal: number = 0;
    public anticipatedEndDate: Date | null = null;
    public remainingProjectTaskBillableEffort: number = 0;
    public originServiceGroupIsNCTPreset: boolean = false;
    constructor() {
        super();

        makeObservable(this, {});
    }
}
