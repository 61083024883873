import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { runInAction } from "mobx";
import { ServiceModel } from "../Service/ServiceModel";
import { ServiceGroupViewModel } from "../ServiceGroup/ServiceGroupViewModel";

class Request {
    public id: string | null = null;
    public name: string = "";
    public serviceGroupId: string = "";
    public ordinal: number = 0;
    public manHours: number | null = null;
    public hourlyRate: number | null = null;
    public isLinkedToPlanningApplication: boolean = false;
}

class ServiceResponse {
    public id: string = "";
    public serviceGroupId: string = "";
    public name: string = "";
    public ordinal: number = 0;
    public manHours: number = 0;
    public hourlyRate: number = 0;
    public updatedDate: Date | null = null;
    public updatedById: string | null = null;
}

class Response {
    public services: ServiceResponse[] = [];
}

export class PostUpsertServiceEndpoint extends Endpoint<Request, Response> {
    private viewModel: ServiceGroupViewModel;

    constructor(viewModel: ServiceGroupViewModel) {
        super();
        this.viewModel = viewModel;
        this.verb(Http.Post);
        this.path(AppUrls.Server.Admin.Services.PostUpsertService);
    }

    HandleRequestAsync(model: ServiceModel): Promise<Request> {
        let request: Request = new Request();

        request.id = model.id;
        request.serviceGroupId = model.serviceGroupId;
        request.name = model.name;
        request.ordinal = model.ordinal;
        request.manHours = model.manHours;
        request.hourlyRate = model.hourlyRate;
        request.isLinkedToPlanningApplication = model.isLinkedToPlanningApplication;

        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        runInAction(() => {
            const serviceModels: ServiceModel[] = [];

            response.services.forEach((item) => {
                const serviceModel = new ServiceModel();

                serviceModel.toModel(item);
                serviceModels.push(serviceModel);
            });

            this.viewModel.model.serviceModels.replace(serviceModels);
        });
    }
}
