import { runInAction } from "mobx";

import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { SupplierResponse } from "./Shared/SupplierResponse";
import { SupplierViewModel } from "./SupplierViewModel";

export class GETSupplierByIdEndpoint extends EndpointWithoutRequest<SupplierResponse> {
    private readonly viewModel: SupplierViewModel;

    constructor(supplierId: string, viewModel: SupplierViewModel) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.Suppliers.GetSupplierAndContactsById.replace(":id", supplierId));
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: SupplierResponse): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            SupplierResponse.supplierContactsResponsePart(response, this.viewModel);
            SupplierResponse.remainingResponsePart(response, this.viewModel);
        });
    }
}
