import { Checkbox, ICheckboxStyles, ILabelStyles, ITextStyles } from "@fluentui/react";
import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React from "react";
import { ICommand } from "../../../../../Application/Commands";
import { themePaletteOptions } from "../../../../../Styles/IShoothillTheme";
import { TextBase } from "./TextBase";
import { theme, themeColourOptions } from "../../../../../Styles";
import { getThemeColorOption } from "Application/Helpers/StyleHelpers";
import { Box } from "Components";
/**
 * Checkbox interface.
 */
export interface ICheckBoxBaseProps {
    /**
     * An optional class name for use with the checkbox.
     */
    className?: string;
    /**
     * A command to execute.
     */
    command: ICommand;
    /**
     * A value to use with the checkbox. Will be passed back by the command.
     */
    value: () => boolean;
    /**
     * Text content to display on the checkbox.
     */
    displayName: string;
    /**
     * Used if we have an array of checkboxes
     */
    index?: number;
    /**
     * Styling of the checkbox.
     */
    styles?: ICheckboxStyles;
    /**
     * The color of the checkbox - use this if using the generic checkbox.
     */
    paletteColor?: themePaletteOptions;
    /**
     * Get validation message
     */
    validationMessage?: () => string;
    /**
     * Styling for the error message.
     */
    errorStyles?: ITextStyles;

    onRenderLabel?: (isInError: boolean) => JSX.Element;

    labelColor?: themeColourOptions;

    description?: string;
}

export const CheckBoxBase: React.FC<ICheckBoxBaseProps> = observer((props) => {
    const getClassNames = () => {
        return clsx({
            [props.className!]: !isEmptyOrWhitespace(props.className),
        });
    };

    const canDisplayErrorMessage = (): boolean => {
        return !isEmptyOrWhitespace(props.validationMessage?.() as string);
    };

    const isDisabled = (): boolean => {
        return isNullOrUndefined(props.command.canExecute) ? false : !props.command.canExecute();
    };

    const onChange = (event?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean): void => {
        if (props.command.execute) props.command.execute(isChecked!, props.index);
    };

    const getValidationMessage = (): string => {
        return isEmptyOrWhitespace(props.validationMessage?.() as string) ? "" : (props.validationMessage?.() as string);
    };

    const getErrorStyles = (): Partial<ICheckboxStyles> | undefined => {
        return !isNullOrUndefined(props.errorStyles) ? props.errorStyles : { root: { color: "red" } };
    };

    const onRenderLabel = (isInError: boolean): JSX.Element => {
        return props.onRenderLabel?.(isInError) ?? <>{props.description}</>;
    };

    return (
        <>
            <Box>
                <Checkbox
                    checked={props.value()}
                    className={getClassNames()}
                    label={props.displayName}
                    disabled={isDisabled()}
                    onChange={onChange}
                    styles={props.styles}
                    onRenderLabel={() => onRenderLabel(getValidationMessage() !== "")}
                />
                {canDisplayErrorMessage() && <TextBase styles={getErrorStyles()}>{getValidationMessage()}</TextBase>}
            </Box>
        </>
    );
});
