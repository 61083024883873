import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import { Box, ThemedText } from "Application";
import { DefaultTableDottedSeparator, SecondaryTableDottedSeparator, SecondaryTableSeparator } from "Views/Shared/SharedComponents";
import { ProgrammingViewModel } from "./ProgrammingViewModel";
import { InProgressView } from "./InProgressSuvbView/InProgressView";
import { UnassignedTasksView } from "./UnassignedTasksSubView/UnassignedTasksView";
import { StaffDetailsItemViewModel } from "../../StaffDetailsItemViewModel";
import { StaffDetailsViewModel } from "../../StaffDetailsViewModel";

interface IProps {
    projectId: string;
    staffDetailsItemViewModel: StaffDetailsItemViewModel;
    parentViewModel: StaffDetailsViewModel;
}

export const ProgrammingView = observer((props: IProps) => {
    useEffect(() => {
        viewModel.getProgrammingWeek();
    }, [props.parentViewModel.model.renderCount]);

    // #region Code Behind

    const [viewModel] = useState(() => new ProgrammingViewModel(props.projectId, props.staffDetailsItemViewModel, props.parentViewModel));

    /**
     * Handler an onClick event for a title.
     */
    const onTitleClick = () => {
        viewModel.toggleAdditionalInformationCommand.execute();
    };

    // #endregion Code Behind

    return (
        <Box>
            <Box onClick={onTitleClick} display="flex" flexDirection="column" minHeight="40px" style={{ cursor: "pointer" }}>
                <Box display="flex" flex="1" alignItems="center">
                    <ThemedText fontStyle="expandable">{viewModel.displayName}</ThemedText>
                </Box>
                <SecondaryTableDottedSeparator />
            </Box>
            {viewModel.showAdditionalInformation && (
                <Box>
                    <Box display="flex">
                        <Box flex={4} padding="25px 0 15px 0">
                            <UnassignedTasksView viewModel={viewModel.unassignedTasksViewModel} parentViewModel={props.parentViewModel} />
                        </Box>
                        <DefaultTableDottedSeparator styles={{ root: { width: "30px", margin: "15px 0" } }} vertical={true} />
                        <Box flex={6} padding="25px 0 15px 0">
                            <InProgressView viewModel={viewModel.inProgressViewModel} parentViewModel={props.parentViewModel} />
                        </Box>
                    </Box>
                    <SecondaryTableSeparator />
                </Box>
            )}
        </Box>
    );
});
