import { ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

import { theme } from "Application";
import { InProgressItemModel } from "./InProgressItemModel";
import { container } from "tsyringe";
import { LookupStore } from "Stores/Domain";

export class InProgressItemViewModel extends ViewModelBase<InProgressItemModel> {
    public lookupStore = container.resolve(LookupStore);
    constructor(model = new InProgressItemModel()) {
        super(model);

        makeObservable(this, {
            // Observables
        });
    }

    public get KEY() {
        return this.model.KEY;
    }

    public get isTaskReviewed() {
        const status = this.lookupStore.projectTaskAssignmentStatuses.find((pts) => pts.id === this.model.projectTaskAssignmentStatusId);
        return status?.type == "Assigned" && this.model.isReviewed;
    }

    public get projectTaskAssignmentStatusName() {
        if (this.isTaskReviewed) {
            return "Reviewed";
        }

        if (this.model.isNonBillableEffort) {
            return this.model.projectTaskAssignmentStatusName + " as NCT";
        }

        return this.model.projectTaskAssignmentStatusName;
    }

    public get projectTaskAssignmentStatusForegroundColor() {
        if (this.isTaskReviewed) {
            return theme.palette.quintenary.main;
        }
        if (this.model.isNonBillableEffort) {
            return theme.palette.common.deepRed;
        }

        return this.model.projectTaskAssignmentStatusForegroundColor;
    }

    public get projectTaskName() {
        return this.model.projectTaskName;
    }

    public get plannedStartDate() {
        return this.model.plannedStartDate;
    }

    public get plannedEndDate() {
        return this.model.plannedEndDate;
    }

    public get hasNote() {
        return this.model.hasNote;
    }

    public get effort() {
        return this.model.effort;
    }

    public get billableEffort() {
        return this.model.isBilled ? this.model.billedEffort : this.model.billableEffort;
    }

    // #region Filtering

    /**
     * Determines if the assignment can be displayed, given a set of filters.
     *
     * @param filterIncludeBilledTasks A filter to show assignments that have been billed.
     *
     * @returns true if the assignment can be displayed otherwise false.
     */
    public filterPredicate = (filterIncludeBilledTasks: boolean): boolean => {
        let result = true;

        // Attempt filtering by billed task.
        if (result) {
            return filterIncludeBilledTasks ? true : !this.model.isBilled;
        }

        return result;
    };

    // #endregion Filtering
}
