import { runInAction } from "mobx";

import { endOfDay, Endpoint, Http, startOfDay } from "Application";
import { AppUrls } from "AppUrls";
import { StaffViewModel } from "./StaffViewModel";
import { StaffItemViewModel } from "./StaffItemViewModel";
import { StaffItemModel } from "./StaffItemModel";
import { Logger } from "index";

class Request {
    public filterStartDate: Date | null = null;
    public filterEndDate: Date | null = null;
}

class Response {
    public users: UserResponse[] = [];
    public totalBillableEffort: number = 0;
    public totalBilledEffort: number = 0;
}

class UserResponse {
    public userId: string = "";
    public userFirstName: string = "";
    public userLastName: string = "";
    public documentUrl: string = "";

    public businessHours: number = 0;
    public chargeableBusinessHours: number = 0;
    public chargeableEffortHoursAllocation: number = 0;
    public chargeableEffortPercentageAllocation: number = 0;
    public effortHoursAllocation: number = 0;
    public effortPercentageAllocation: number = 0;
}

export class GETAllStaffEndpoint extends Endpoint<any, any> {
    private viewModel: StaffViewModel;

    constructor(viewModel: StaffViewModel) {
        super();
        this.viewModel = viewModel;
        this.verb(Http.Post);
        this.path(AppUrls.Server.Resources.AllStaffProjectsLite);
    }

    public async HandleRequestAsync(model: StaffViewModel): Promise<any> {
        let request = new Request();

        request.filterStartDate = startOfDay(this.viewModel.model.filterStartDate) ?? null;
        request.filterEndDate = endOfDay(this.viewModel.model.filterEndDate) ?? null;

        return await Promise.resolve(request);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            const staff: StaffItemViewModel[] = [];

            response.users.forEach((element: any) => {
                const model = new StaffItemModel();

                model.fromResponse(element);

                staff.push(new StaffItemViewModel(model));
            });

            this.viewModel.staffItemViewModels.replace(staff);

            this.viewModel.model.totalBillableEffort = response.totalBillableEffort;
            this.viewModel.model.totalBilledEffort = response.totalBilledEffort;
        });

        return Promise.resolve(response);
    }
}
