import { ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

import { NoEventModel } from "./NoEventModel";

export class NoEventViewModel extends ViewModelBase<NoEventModel> {
    constructor(model: NoEventModel = new NoEventModel()) {
        super(model, false);

        makeObservable(this, {});
    }
}
