//libraries
import { observer } from "mobx-react-lite";
import React from "react";
import { Box, ThemedEditText, ThemedText } from "Application";

//app
import { ProgrammingWeekTaskUserNotesViewModel } from "./ProgrammingWeekTaskUserNotesViewModel";
import { BillingNoteView } from "Views/Resource/AssignedTasks/Table/ConfigureSubViews/BillingSubView/SubViews/BillingNoteView";
import { FormContainerRow } from "Views/Shared/SharedComponents";

interface IProps {
    viewModel: ProgrammingWeekTaskUserNotesViewModel;
}

export const ProgrammingWeekTaskUserNotesView: React.FC<IProps> = observer((props) => {
    const viewModel = props.viewModel;

    return (
        <Box style={{ height: "calc(100% - 60px)", overflow: "scroll" }} padding={30}>
            <FormContainerRow dc={"100%"} rowGap={10} mb="15px !important">
                {/* attached files here */}
                <ThemedText fontStyle="button">Task notes</ThemedText>
            </FormContainerRow>
            {viewModel.projectTaskAssignmentUserNoteViewModels.map((vm) => (
                <BillingNoteView viewModel={vm} flipView={false} />
            ))}
            {viewModel.displayDefaultNote && <BillingNoteView viewModel={viewModel.defaultProjectTaskAssignmentUserNoteViewModel!} flipView={false} />}
        </Box>
    );
});
