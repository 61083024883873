import { isEmptyOrWhitespace, isNullOrUndefined, ViewModelBase } from "@shoothill/core";
import { computed, makeObservable } from "mobx";
import { container } from "tsyringe";

import { APIClient, formatCurrency, RelayCommand } from "Application";
import { AppUrls } from "AppUrls";
import { LookupStore } from "Stores/Domain";
import { ProjectModel } from "./ProjectModel";
import { GETProjectSummaryByIdEndpoint } from "./GETProjectSummaryByIdEndpoints";

export class ProjectViewModel extends ViewModelBase<ProjectModel> {
    public apiClient = new APIClient();
    private lookupStore = container.resolve(LookupStore);

    constructor(projectId: string | undefined) {
        super(new ProjectModel(), false);

        if (projectId) {
            this.model.id = projectId;
        }

        makeObservable(this, {
            displayName: computed,
            projectLead: computed,
            projectLeadImage: computed,
            canDisplayLeadIn: computed,
            leadIn: computed,
            canDisplayDuration: computed,
            duration: computed,
            canDisplayProjectStatus: computed,
            projectStatusDisplayName: computed,
            projectStatusBackgroundColor: computed,
        });

        if (projectId) {
            const _ = this.apiClient.sendAsync(new GETProjectSummaryByIdEndpoint(projectId, this));
        }
    }

    // #region Properties

    public get displayName() {
        return `${this.model.reference}: ${this.model.title}`;
    }

    public get projectLead() {
        return this.model.projectLead ? `${this.model.projectLead.firstName} ${this.model.projectLead.lastName}` : "Not selected";
    }

    public get projectLeadImage() {
        return this.model.projectLead?.thumbnailDocumentUrl ? this.model.projectLead.thumbnailDocumentUrl : undefined;
    }

    public get total() {
        return `hours: ${this.model.hours}   value: ${formatCurrency(this.model.value)}`;
    }

    public get remainingTotal() {
        return `hours: ${this.model.remainingHours}   value: ${formatCurrency(this.model.remainingValue)}`;
    }

    public get canDisplayLeadIn() {
        return !isNullOrUndefined(this.model.leadInWeeks);
    }

    public get leadIn() {
        return `${this.model.leadInWeeks} weeks`;
    }

    public get canDisplayDuration() {
        return !isNullOrUndefined(this.model.durationWeeks);
    }

    public get duration() {
        return `${this.model.durationWeeks} weeks`;
    }

    public get canDisplayProjectStatus() {
        return !isEmptyOrWhitespace(this.model.projectStatusId);
    }

    /**
     * Returns the project status display name.
     */
    public get projectStatusDisplayName() {
        return this.lookupStore.projectStatuses.find((ps) => ps.id === this.model.projectStatusId)?.name?.toLocaleUpperCase() ?? "";
    }

    /**
     * Returns the project status color.
     */
    public get projectStatusBackgroundColor() {
        return this.lookupStore.projectStatuses.find((ps) => ps.id === this.model.projectStatusId)?.foregroundColor ?? "";
    }

    // #endregion Properties

    public navigateToProjectsCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.Projects.Table);
    });

    public setDetailsTabCommand = new RelayCommand((id: string) => {
        this.history.push(AppUrls.Client.Projects.Details.replace(":id", id));
    });

    public setOverviewTabCommand = new RelayCommand((id: string) => {
        this.history.push(AppUrls.Client.Projects.Overview.replace(":id", id));
    });

    public setQuoteHistoryTabCommand = new RelayCommand((id: string) => {
        this.history.push(AppUrls.Client.Projects.QuoteHistory.replace(":id", id));
    });

    public setTasksTabCommand = new RelayCommand((id: string) => {
        this.history.push(AppUrls.Client.Projects.Tasks.replace(":id", id));
    });

    public setWeeklyUpdateTabCommand = new RelayCommand((id: string) => {
        this.history.push(AppUrls.Client.Projects.WeeklyUpdate.replace(":id", id));
    });

    public refeshProjectSummaryCommand = new RelayCommand(() => {
        return this.apiClient.sendAsync(new GETProjectSummaryByIdEndpoint(this.model.id, this));
    });
}
