//libraries
import { action, makeObservable, observable, runInAction } from "mobx";
//app
import { GlobalHistory } from "index";
import { AppUrls } from "AppUrls";
import { RelayCommand } from "Application";
import { isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { LookupStore } from "Stores/Domain";
import { container } from "tsyringe";
import { HomeModel } from "./HomeModel";
import house from "Assets/housesmall.png";

export class HomeViewModel extends ViewModelBase<HomeModel> {
    public locationPathName: string = "";
    public lookupStore = container.resolve(LookupStore);
    public logout: boolean = false;
    public backgroundImgURL = house;

    constructor(model = new HomeModel()) {
        super(model, false);
        makeObservable(this, {
            locationPathName: observable,
            backgroundImgURL: observable,
            logout: observable,
            initializeBackgroundImgURL: action,
        });
        this.initializeBackgroundImgURL();
        GlobalHistory.listen((locationUpdate) => runInAction(() => this.setLocationPathName(locationUpdate.location.pathname)));
        this.setLocationPathName(location.pathname);
    }
    private setLocationPathName = (pathName: string) => {
        this.locationPathName = pathName;
    };
    //region actions

    public initializeBackgroundImgURL() {
        const imagesUrlList = this.lookupStore.getImageUrl();
        const randomIndex = Math.floor(Math.random() * imagesUrlList.length);
        const randomImageUrl = imagesUrlList[randomIndex];
        this.backgroundImgURL = randomImageUrl && !isEmptyOrWhitespace(randomImageUrl.text) ? randomImageUrl.text : house;
    }
    //endregion actions

    //region helpers
    public get userDisplayName() {
        return this.lookupStore.AccountStore.LoggedUserFirstName;
    }

    public get userDocumentUrl() {
        return this.lookupStore.AccountStore.LoggedUserImageUrl;
    }

    //endregion helpers

    //region commands

    public setStaffDashboardMyWorkTabCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.DashboardStaff.MyWork);
    });

    public setStaffDashboardWeeklyUpdateTabCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.DashboardStaff.WeeklyUpdate);
    });

    public navigateToLeaveCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.DashboardStaff.Leave);
    });

    public navigateToLogoutCommand = new RelayCommand(
        () => {
            this.logout = true;
        },
        () => this.isLoggedIn,
    );

    public logoutClose = new RelayCommand(
        () => {
            this.logout = false;
        },
        () => this.isLoggedIn,
    );

    //endregion commands
}
