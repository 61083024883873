import { ViewModelBase } from "@shoothill/core";
import { action, makeObservable, observable } from "mobx";

import { APIClient, ICommand, RelayCommand } from "Application";
import { AssignTaskModel } from "./AssignTaskModel";
import { SummaryViewModel } from "./SummaryView/SummaryViewModel";
import { AssignViewModel } from "./AssignSubView/AssignViewModel";
import { NotesViewModel } from "./NotesSubView/NotesViewModel";
import { GETProjectTaskAssignmentRelatedEndpoint } from "./GETProjectTaskAssignmentRelatedEndpoint";
import { POSTSaveProjectTaskAssignmentAsAssignedEndpoint } from "./POSTSaveProjectTaskAssignmentAsAssignedEndpoint";
import { User } from "Application/Models/Domain/User";

export class AssignTaskViewModel extends ViewModelBase<AssignTaskModel> {
    public apiClient = new APIClient();

    // Callback commands to the parent viewmodel.
    public readonly parentSubmitCommand: ICommand;
    public readonly parentCancelCommand: ICommand;

    // Sub-viewmodels.
    public summaryViewModel = new SummaryViewModel();
    public readonly workspaceViewModels = observable<AssignViewModel>([]);
    public activeWorkspaceViewModel: AssignViewModel;

    constructor(projectTaskId: string, userId: string | null, model: AssignTaskModel, submitCommand: ICommand, cancelCommand: ICommand, originServiceGroupNCTPreset?: boolean) {
        super(model, false);

        // Commands to be called on the parent viewmodel.
        this.parentSubmitCommand = submitCommand;
        this.parentCancelCommand = cancelCommand;

        // A collection of workspace viewmodels.
        // In this case, the workspace is a different view of the same model.
        this.workspaceViewModels.push(new AssignViewModel(model, originServiceGroupNCTPreset));

        // The default active viewmodel.
        this.activeWorkspaceViewModel = this.workspaceViewModels[0];

        // Properties on which to observe changes.
        makeObservable(this, {
            // Observables
            workspaceViewModels: observable,
            activeWorkspaceViewModel: observable,
            initializeUserId: action,
        });

        this.apiClient.sendAsync(new GETProjectTaskAssignmentRelatedEndpoint(projectTaskId, this)).then(() => {
            this.initializeUserId(userId);
            if (originServiceGroupNCTPreset) {
                this.setValue("isNonBillableEffort", originServiceGroupNCTPreset);
            }
        });
    }

    public initializeUserId(userId: string | null) {
        if (userId) {
            const assignee = this.getValue<User[]>("users").find((u) => u.id == userId);
            if (assignee && assignee.isAssignable) {
                this.setValue("userId", userId);
            }
        }
    }

    // #region Properties

    public get reference() {
        return this.model.reference;
    }

    public get title() {
        return this.model.title;
    }

    public get taskGroupName() {
        return this.model.projectTaskGroupName;
    }

    public get taskName() {
        return this.model.projectTaskName;
    }

    // #endregion Properties

    // #region Commands

    public navigateToWorkspaceCommand = new RelayCommand((vm: AssignViewModel) => {
        this.activeWorkspaceViewModel = vm;
    });

    public submitCommand = new RelayCommand(
        () => {
            const canSubmitForm = this.workspaceViewModels.every((vm) => vm.isModelValid());

            if (canSubmitForm) {
                this.apiClient.sendAsync(new POSTSaveProjectTaskAssignmentAsAssignedEndpoint(this), this.model);
            }
        },
        () => {
            return !this.apiClient.IsBusy;
        },
    );

    public cancelCommand = new RelayCommand(() => {
        this.parentCancelCommand.execute();
    });

    public resetServerErrorCommand = new RelayCommand(() => {
        this.apiClient.reset();
    });

    // #endregion Commands

    // #region Supporting

    public isActiveWorkspace = (vm: AssignViewModel | NotesViewModel) => {
        return vm.KEY === this.activeWorkspaceViewModel.KEY;
    };

    // #endregion Supporting
}
