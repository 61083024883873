import { ContextualMenu, Dialog, DialogFooter, DialogType, IButtonStyles, IIconProps, IRenderFunction, PrimaryButton } from "@fluentui/react";
import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React from "react";

import { ICommand } from "../../../../../Application/Commands";
import { themePaletteOptions, themeShapeOptions, themeSizeOptions } from "../../../../../Styles/IShoothillTheme";
import { AddSVG } from "../../../../../Assets/AddSVG";
import { ThemedButton } from "../../../../../Styles";

/**
 * Button interface.
 */
export interface IDialogBaseProps {
    /**
     * An optional class name for use with the button.
     */
    className?: string;
    /**
     * A command to execute.
     */
    command: ICommand;
    /**
     * A command to execute.
     */
    dismissCommand: ICommand;
    /**
     * Text content to display on the button.
     */
    title: string;
    /**
     * Text content to display on the button.
     */
    message: string;
    /**
     * Sets the button message for an affirmative action.
     */
    affirmativeButtonTitle?: string;
    /**
     * Sets the button message for a negative action.
     */
    neagtiveButtonTitle?: string;
    /**
     * Boolean to display dialog
     */
    show: boolean;
    /**
     * Styling of the button.
     */
    styles?: IButtonStyles;
    /**
     * The color of the button - use this if using the generic button.
     */
    paletteColor?: themePaletteOptions;
}

export const DialogBase: React.FC<IDialogBaseProps> = observer((props) => {
    // #region Code Behind
    const getClassNames = () => {
        return clsx({
            [props.className!]: !isEmptyOrWhitespace(props.className),
        });
    };

    const dragOptions = {
        moveMenuItemText: "Move",
        closeMenuItemText: "Close",
        menu: ContextualMenu,
    };
    const dialogContentProps = {
        type: DialogType.normal,
        title: props.title,
        subText: props.message,
    };
    const modalProps = React.useMemo(
        () => ({
            isBlocking: false,
            dragOptions: dragOptions,
            className: getClassNames(),
        }),
        [],
    );

    // #endregion Code Behind
    return (
        //EN: Ignore typescript error because react 18 expects explicit children props.
        /*@ts-ignore*/
        <Dialog hidden={!props.show} dialogContentProps={dialogContentProps} modalProps={modalProps}>
            {/*@ts-ignore*/}
            <DialogFooter>
                <ThemedButton paletteColor={"primary"} command={props.command} displayName={props.affirmativeButtonTitle || "Yes"} />
                <ThemedButton paletteColor={"error"} command={props.dismissCommand} displayName={props.neagtiveButtonTitle || "Cancel"} />
            </DialogFooter>
        </Dialog>
    );
});
