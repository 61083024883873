import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { InProgressItemModel } from "../InProgressSuvbView/InProgressItemModel";

class Request {
    id: string | null = null;
}

class Response {}

export class POSTDeleteProjectTaskAssignmentByIdEndpoint extends Endpoint<Request, Response> {
    constructor() {
        super();

        this.verb(Http.Post);
        this.path(AppUrls.Server.Resources.DeleteProjectTaskAssignmentById);
    }

    public async HandleRequestAsync(model: InProgressItemModel): Promise<any> {
        const request = new Request();

        request.id = model.id;

        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        return Promise.resolve(response);
    }
}
