import { runInAction } from "mobx";
import { KeyValuePair as KeyValuePairSH, ModelBase } from "@shoothill/core";
import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { AddEditPlanningApplicationViewModel } from "../AddEditPlanningApplicationViewModel";

class KeyValuePair {
    public key: string = "";
    public value: string = "";
}

class Response {
    public options: KeyValuePair[] = [];
}

export class GETAllProjectOptionsEndpoint extends EndpointWithoutRequest<Response> {
    private viewModel: AddEditPlanningApplicationViewModel;

    constructor(viewModel: AddEditPlanningApplicationViewModel) {
        super();
        this.viewModel = viewModel;

        this.path(AppUrls.Server.Projects.GetAllProjectOptions);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        runInAction(() => {
            const options: KeyValuePairSH[] = [];

            response.options.forEach((option: KeyValuePair) => {
                const keyValuePair: KeyValuePairSH = {
                    key: option.key,
                    text: option.value,
                };

                options.push(keyValuePair);
            });

            this.viewModel.model.projectKeyValuePairs.replace(options);
        });
    }
}
