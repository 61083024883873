import styled from "@emotion/styled";
import { Box } from "../Components";

export const FixedFooter = styled(Box)`
    bottom: 0;
    left: 0;
    position: fixed;
    z-index: 100000;
    background-color: #fff;
    width: 100%;
    padding: 15px 30px;
`;

export const Tabbed = styled(Box)`
    display: flex;
    margin: 30px 0 0 30px;
    align-items: flex-end;

    height: 61px;
    background-color: transparent;
    div {
        cursor: pointer;
        display: flex;
        padding: 0px 10px;
        border-top: 1px solid #d6d6d6;
        border-left: 1px solid #d6d6d6;
        border-right: 1px solid #d6d6d6;
        border-bottom: 1px solid #d6d6d6;
        justify-content: center;
        align-items: center;
        min-width: 187px;
        height: 41px;
        background-color: white;
        transition: height 0.167s cubic-bezier(0.1, 0.9, 0.2, 1);

        span {
            color: #171716;
            font-size: 12px;
            font-family: normal normal normal 12px/18px Poppins;
            text-transform: uppercase;
        }
    }
    .activeTab {
        min-width: 185px;
        height: 61px;
        border-bottom: 1px solid #fff;

        span {
            color: #171716;
            font-size: 12px;
            font-family: normal normal normal 12px/18px Poppins;
            text-transform: uppercase;
            text-align: center;
        }
    }
    &.smallTabs {
        div {
            min-width: 77px;
            height: 21px;
            border-bottom: 1px solid #d6d6d6;
        }
        .activeTab {
            height: 30px;
            border-bottom: 1px solid #fff;
        }
    }
`;
