import { isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { computed, makeObservable, observable } from "mobx";
import { container } from "tsyringe";

import { APIClient, ICommand, RelayCommand } from "Application";
import { LookupStore } from "Stores/Domain";
import { TaskModel } from "./ConfigureSubViews/TaskModel";
import { TaskViewModel } from "./ConfigureSubViews/TaskViewModel";
import { InProgressGroupViewModel } from "./InProgressGroupViewModel";
import { InProgressModel } from "./InProgressModel";
import { InProgressItemViewModel } from "./InProgressItemViewModel";
import { DeleteTaskAssignmentGroupViewModel } from "./ModalViews/DeleteTaskAssignmentGroup/DeleteTaskAssignmentGroupViewModel";
import { DeleteTaskAssignmentGroupModel } from "./ModalViews/DeleteTaskAssignmentGroup/DeleteTaskAssignmentGroupModel";
import { POSTDeleteProjectTaskAssignmentGroupEndpoint } from "./ModalViews/DeleteTaskAssignmentGroup/Endpoints/POSTDeleteProjectTaskAssignmentEndpoint";
import { TaskAssignmentGroupModel } from "./ModalViews/TaskAssignmentGroup/TaskAssignmentGroupModel";
import { TaskAssignmentGroupViewModel } from "./ModalViews/TaskAssignmentGroup/TaskAssignmentGroupViewModel";
import { TaskAssignmentModel } from "./ModalViews/TaskAssignmentGroup/SubViews/TaskAssignmentModel";
import { POSTSaveProjectTaskAssignmentGroupEndpoint } from "./ModalViews/TaskAssignmentGroup/Endpoints/POSTSaveTaskAssignmentGroupEndpoint";
import { POSTDeleteProjectTaskAssignmentByIdEndpoint } from "../Endpoints/POSTDeleteProjectTaskAssignmentByIdEndpoint";
import { ProgrammingViewModel } from "../ProgrammingViewModel";

export class InProgressViewModel extends ViewModelBase<InProgressModel> {
    public apiClient = new APIClient();
    private lookupStore = container.resolve(LookupStore);

    private parentNewTaskCommand: ICommand;
    public parentViewModel: ProgrammingViewModel;

    public tasks = observable<InProgressItemViewModel>([]);
    public taskAssignmentGroups = observable<InProgressGroupViewModel>([]);
    public deleteTaskAssignmentGroupViewModel: DeleteTaskAssignmentGroupViewModel | null = null;
    public editTaskAssignmentGroupViewModel: TaskAssignmentGroupViewModel | null = null;
    public taskViewModel: TaskViewModel | null = null;

    constructor(parentViewModel: ProgrammingViewModel, parentNewTaskCommand: ICommand) {
        super(new InProgressModel());

        this.parentNewTaskCommand = parentNewTaskCommand;
        this.parentViewModel = parentViewModel;

        makeObservable(this, {
            // Observables
            tasks: observable,
            taskViewModel: observable,
            deleteTaskAssignmentGroupViewModel: observable,
            editTaskAssignmentGroupViewModel: observable,

            // Computeds
            canDisplayWorkCalendar: computed,
            canFilterTasks: computed,
            filteredTasks: computed,
            canSortTasks: computed,
            filteredAndSortedTasks: computed,
            canDisplayTask: computed,
            canDisplayDeleteTaskAssignmentGroup: computed,
            canDisplayEditTaskAssignmentGroup: computed,
        });
    }

    // #region Filtering

    public toggleFilterIncludeBilledTasksCommand = new RelayCommand(() => {
        this.model.filterIncludeBilledTasks = !this.model.filterIncludeBilledTasks;
    });

    public get canFilterTasks(): boolean {
        return !this.model.filterIncludeBilledTasks;
    }

    public get filteredTasks(): InProgressItemViewModel[] {
        return this.canFilterTasks ? this.tasks.filter((vm) => vm.filterPredicate(this.model.filterIncludeBilledTasks)) : this.tasks;
    }

    public get sortedTaskAssignmentGroups(): InProgressGroupViewModel[] {
        return this.taskAssignmentGroups.slice().sort((lhs, rhs) => lhs.model.note.localeCompare(rhs.model.note));
    }

    // #endregion Filtering

    // #region Sorting

    public updateSortCommand = new RelayCommand((key: string, sortDescending: boolean) => {
        this.model.sortKey = key;
        this.model.sortDescending = sortDescending;
    });

    public get canSortTasks(): boolean {
        return !isEmptyOrWhitespace(this.model.sortKey);
    }

    public get filteredAndSortedTasks(): InProgressItemViewModel[] {
        return this.canSortTasks
            ? this.filteredTasks.slice().sort((lhs, rhs) => {
                  return (this.model.sortDescending ? lhs[this.model.sortKey] < rhs[this.model.sortKey] : lhs[this.model.sortKey] > rhs[this.model.sortKey]) ? 1 : -1;
              })
            : this.filteredTasks.slice();
    }

    // #endregion Sorting

    // #region Calendar

    public get canDisplayWorkCalendar(): boolean {
        return this.canDisplayTask && this.parentViewModel.parentViewModel.workCalendarViewModel !== null;
    }

    // #endregion Calendar

    // #region Modify Task

    public get canDisplayTask(): boolean {
        return this.taskViewModel !== null;
    }

    public removeTask = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: InProgressItemViewModel) => {
        e.stopPropagation();

        this.apiClient.sendAsync(new POSTDeleteProjectTaskAssignmentByIdEndpoint(), item.model).then(() => {
            this.parentViewModel.parentViewModel.updatePercentageAllocationCommand.execute();
            this.parentViewModel.parentViewModel.setValue("renderCount", this.parentViewModel.model.renderCount + 1);
            this.parentViewModel.parentViewModel.closeMyWorkWeekOverviewCommand.execute();
            this.parentViewModel.getProgrammingWeek();
        });
    };

    public isRemoveButtonDisabled = (item: InProgressItemViewModel) => {
        const retVal = this.lookupStore.projectTaskAssignmentStatuses.find((status) => status.id == item.model.projectTaskAssignmentStatusId)!.type != "Assigned";
        return retVal;
    };

    /**
     * Displays a task.
     */
    public displayTaskCommand = new RelayCommand((projectTaskAssignmentId: string) => {
        this.taskViewModel = new TaskViewModel(projectTaskAssignmentId, new TaskModel(), this.submitTaskCommand, this.cancelTaskCommand);
    });

    /**
     * Handles the result of saving a task.
     *
     * Having modified the task, call the parent command to refresh the information.
     */
    public submitTaskCommand = new RelayCommand((projectTaskAssignmentId: string) => {
        this.parentNewTaskCommand.execute();
        this.taskViewModel = null;
    });

    /**
     * Handles cancelling a task.
     */
    public cancelTaskCommand = new RelayCommand(() => {
        this.taskViewModel = null;
    });

    /**
     * Handles removing a task.
     */

    // #endregion Modify Task

    // #region Edit Task Assignment Group

    public get canDisplayEditTaskAssignmentGroup(): boolean {
        return this.editTaskAssignmentGroupViewModel !== null;
    }

    /**
     * Display a view so that the user can create a new task goup and assign any project
     * task assignments that are not in a group.
     */
    public displayNewTaskAssignmentGroupCommand = new RelayCommand(() => {
        const newTaskAssignmentGroupViewModel = new TaskAssignmentGroupViewModel(
            new TaskAssignmentGroupModel(),
            this.submitEditTaskAssignmentGroupCommand,
            this.cancelEditTaskAssignmentGroupCommand,
        );

        newTaskAssignmentGroupViewModel.model.userId = this.parentViewModel.parentViewModel.model.userId;
        newTaskAssignmentGroupViewModel.model.projectId = this.parentViewModel.projectId;

        const items: TaskAssignmentModel[] = this.tasks.map((task) => {
            return new TaskAssignmentModel(task.model);
        });

        newTaskAssignmentGroupViewModel.model.taskAssignments.replace(items);

        this.editTaskAssignmentGroupViewModel = newTaskAssignmentGroupViewModel;
    });

    /**
     * Display a view so that the user can edit a task group and change the project task
     * assignments in the group.
     */
    public displayCurrentTaskAssignmentGroupCommand = new RelayCommand(
        (taskAssignmentGroupViewModel: InProgressGroupViewModel) => {
            const editTaskAssignmentGroupViewModel = new TaskAssignmentGroupViewModel(
                new TaskAssignmentGroupModel(),
                this.submitEditTaskAssignmentGroupCommand,
                this.cancelEditTaskAssignmentGroupCommand,
            );

            editTaskAssignmentGroupViewModel.model.id = taskAssignmentGroupViewModel.model.id;
            editTaskAssignmentGroupViewModel.model.name = taskAssignmentGroupViewModel.model.name;
            editTaskAssignmentGroupViewModel.model.userId = taskAssignmentGroupViewModel.model.userId;
            editTaskAssignmentGroupViewModel.model.projectId = taskAssignmentGroupViewModel.model.projectId;

            const items: TaskAssignmentModel[] = taskAssignmentGroupViewModel.tasks
                .map((task) => {
                    return new TaskAssignmentModel(task.model, true);
                })
                .concat(
                    this.tasks.map((task) => {
                        return new TaskAssignmentModel(task.model);
                    }),
                );

            editTaskAssignmentGroupViewModel.model.taskAssignments.replace(items);

            this.editTaskAssignmentGroupViewModel = editTaskAssignmentGroupViewModel;
        },
        (taskAssignmentGroupViewModel: InProgressGroupViewModel) => {
            return taskAssignmentGroupViewModel?.model?.groupStatusType !== "Completed" ?? false;
        },
    );

    public submitEditTaskAssignmentGroupCommand = new RelayCommand(async () => {
        await this.apiClient.sendAsync(new POSTSaveProjectTaskAssignmentGroupEndpoint(), this.editTaskAssignmentGroupViewModel?.model);

        if (this.apiClient.IsRequestSuccessful) {
            this.editTaskAssignmentGroupViewModel?.dispose();
            this.editTaskAssignmentGroupViewModel = null;
            this.apiClient.reset();
            this.parentViewModel.getProgrammingWeek();
        }
    });

    public cancelEditTaskAssignmentGroupCommand = new RelayCommand(() => {
        this.editTaskAssignmentGroupViewModel?.dispose();
        this.editTaskAssignmentGroupViewModel = null;
    });

    // #endregion Edit Task Assignment Group

    // #region Delete Task Assignment Group

    public get canDisplayDeleteTaskAssignmentGroup(): boolean {
        return this.deleteTaskAssignmentGroupViewModel !== null;
    }

    public displayDeleteTaskAssignmentGroupCommand = new RelayCommand(
        (taskAssignmentGroupViewModel: InProgressGroupViewModel) => {
            this.deleteTaskAssignmentGroupViewModel = new DeleteTaskAssignmentGroupViewModel(
                new DeleteTaskAssignmentGroupModel(taskAssignmentGroupViewModel.model),
                this.submitDeleteTaskAssignmentGroupCommand,
                this.cancelDeleteTaskAssignmentGroupCommand,
            );
        },
        (taskAssignmentGroupViewModel: InProgressGroupViewModel) => {
            return taskAssignmentGroupViewModel?.model?.groupStatusType !== "Completed" ?? false;
        },
    );

    public submitDeleteTaskAssignmentGroupCommand = new RelayCommand(async () => {
        await this.apiClient.sendAsync(new POSTDeleteProjectTaskAssignmentGroupEndpoint(), this.deleteTaskAssignmentGroupViewModel?.model);

        if (this.apiClient.IsRequestSuccessful) {
            this.deleteTaskAssignmentGroupViewModel = null;
            this.apiClient.reset();
            this.parentViewModel.getProgrammingWeek();
        }
    });

    public cancelDeleteTaskAssignmentGroupCommand = new RelayCommand(() => {
        this.deleteTaskAssignmentGroupViewModel = null;
    });

    // #endregion Delete Task Assignment Group
}
