import { mergeStyleSets } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React from "react";

import { ICommand, pxToRem, theme } from "Application";
import { CalloutInformationViewModel } from "./CalloutInformationViewModel";

export interface IProps {
    viewModel: CalloutInformationViewModel;
}

const callOutStyles = {
    root: {
        minWidth: pxToRem(100),
    },
    row: {
        display: "flex",
    },
    divider: {
        ":not(:last-child)": {
            borderBottom: "1px solid #E1E1E1",
        },
    },
    cell: {
        alignItems: "center",
        display: "flex",
        height: pxToRem(30),
        margin: `${pxToRem(5)}`,
        minWidth: pxToRem(80),
    },
    data: {
        cursor: "pointer",
        marginLeft: pxToRem(10),
        marginRight: pxToRem(10),
        fontFamily: theme.fontStyles.tableHeader.fontFamily ?? theme.defaultFontStyle.fontFamily,
        fontSize: pxToRem(10),
        ":hover": {
            textDecoration: "underline",
        },
    },
};

export const CalloutInformationView: React.FC<IProps> = observer((props) => {
    const styles = mergeStyleSets(callOutStyles);
    const viewModel = props.viewModel;

    if (viewModel === null) {
        return null;
    }

    const onClick = (event: any, command: ICommand) => {
        event.stopPropagation();
        command.execute();
    };

    return (
        <div className={styles.root}>
            {(() => {
                switch (true) {
                    case viewModel.apiClient.IsBusy:
                        return (
                            <div className={styles.cell}>
                                <div className={styles.data}>Please stand by...</div>
                            </div>
                        );

                    case viewModel.itemViewModels.length === 0:
                        return (
                            <div className={styles.cell}>
                                <div className={styles.data}>No data available</div>
                            </div>
                        );

                    default:
                        return (
                            <React.Fragment>
                                {viewModel.itemViewModels.map((itemViewModel) => {
                                    return (
                                        <React.Fragment key={itemViewModel.KEY}>
                                            <div className={styles.row}>
                                                <div className={styles.cell} onClick={(event: any) => onClick(event, itemViewModel.navigateToProjectCommand)}>
                                                    <div className={styles.data}>{itemViewModel.projectReference}</div>
                                                </div>
                                                <div className={styles.cell} onClick={(event: any) => onClick(event, itemViewModel.navigateToCustomerCommand)}>
                                                    <div className={styles.data}>{itemViewModel.customerName}</div>
                                                </div>
                                            </div>
                                            <div className={styles.divider} />
                                        </React.Fragment>
                                    );
                                })}
                            </React.Fragment>
                        );
                }
            })()}
        </div>
    );
});
