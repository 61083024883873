import _ from "lodash";
import { runInAction } from "mobx";

import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { QuoteRequest, QuoteTaskRequest } from "./Shared/QuoteRequest";
import { QuoteResponse } from "./Shared/QuoteResponse";
import { QuoteContainerModel } from "../QuoteContainerModel";
import { QuoteContainerViewModel } from "../QuoteContainerViewModel";

class Request {
    public id: string | null = "";
    public tasks: QuoteTaskRequest[] = [];
}

export class POSTSaveQuoteTasksAndCostsEndpoint extends Endpoint<QuoteRequest, Response> {
    private readonly viewModel: QuoteContainerViewModel;

    constructor(viewModel: QuoteContainerViewModel) {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Quotes.SaveQuoteTasksAndCosts);
        this.viewModel = viewModel;
    }

    public async HandleRequestAsync(model: QuoteContainerModel): Promise<any> {
        const request = new Request();

        request.id = model.quoteForm.id;

        for (const taskGroup of model.quoteForm.servicesModel.taskGroups) {
            for (const task of taskGroup.tasks) {
                const taskRequest = new QuoteTaskRequest();

                taskRequest.id = task.id;
                taskRequest.serviceGroupId = taskGroup.id;
                taskRequest.serviceId = task.serviceId;
                taskRequest.taskName = task.taskName;
                taskRequest.manHours = task.manHours;
                taskRequest.hourlyRate = task.hourlyRate;
                taskRequest.ordinal = task.ordinal;

                request.tasks.push(taskRequest);
            }
        }

        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: QuoteResponse): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            QuoteResponse.revisionResponsePart(response, this.viewModel);
            QuoteResponse.quoteResponsePart(response, this.viewModel);
            QuoteResponse.customerResponsePart(response, this.viewModel);
            QuoteResponse.letterTemplateResponsePart(response, this.viewModel);
            QuoteResponse.tasksResponsePart(response, this.viewModel);
            QuoteResponse.termsAndConditionsResponsePart(response, this.viewModel);
            QuoteResponse.notesResponsePart(response, this.viewModel);
            QuoteResponse.remainingResponsePart(response, this.viewModel);
        });

        return Promise.resolve(response);
    }
}
