import { ComboBox, IComboBox, IComboBoxOption, IComboBoxOptionStyles, IComboBoxStyles } from "@fluentui/react";
import { isEmptyOrWhitespace, isNullOrEmpty, isNullOrUndefined } from "@shoothill/core";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import { ICommand } from "../../../../../Application/Commands";
import { themeColourOptions, themeShapeOptions, themeSizeOptions } from "../../../../../Styles/IShoothillTheme";

/**
 * Single-selection Combo box interface.
 */
export interface IComboBoxBaseProps {
    /**
     * An optional class name for use with the combobox.
     */
    className?: string;
    /**
     * A command to execute.
     */
    command: ICommand;
    /**
     * A value to use with the combobox. Will be passed back by the command.
     */
    value: () => string | null;
    /**
     * Text content to display in the placeholder.
     */
    placeholder?: string;
    /**
     * Text content to display in the error message.
     */
    validationMessage?: () => string;
    /**
     * A collection of options to display in the combox dropdown.
     */
    options: IComboBoxOption[];
    /**
     * Text content to display on the combox.
     */
    displayName?: string;
    /**
     * Styling of the combobox.
     */
    styles?: Partial<IComboBoxStyles>;
    /**
     * Styling of the combobox options.
     */
    optionStyles?: IComboBoxOptionStyles;
    /**
     * The size of the control - use this if using generic control.
     */
    size?: themeSizeOptions;
    /**
     * The shape of the control - use this if using the generic control.
     */
    shape?: themeShapeOptions;

    labelColor?: themeColourOptions;

    onRenderLabel?: (isInError: boolean) => JSX.Element;

    onKeyDown?: any;

    description?: string;

    /**
     * Boolean that determines if the label is hidden or displayed.
     */
    showLabel?: boolean;
}

export const ComboBoxBase: React.FC<IComboBoxBaseProps> = observer((props) => {
    // #region Code Behind

    /**
     * Stores the list of filtered options.
     */
    const [filteredOptions, setFilteredOptions] = useState<IComboBoxOption[]>(props.options);

    /**
     * Stores the state of the autocomplete input filter - used to filter
     * the contents of the dropdown options.
     */
    const [autoCompleteInputFilter, setAutoCompleteInputFilter] = useState("");

    /**
     * Resets the list of filtered options when the external options change.
     * This might occur if the options are provided via an API.
     */
    useEffect(() => {
        setFilteredOptions(props.options.map((option) => option));
    }, [props.options]);

    /**
     * Updates the list of filtered options when the state of the autocomplete
     * input filter changes.
     */
    useEffect(() => {
        const result = isNullOrEmpty(autoCompleteInputFilter)
            ? props.options.map((option) => option)
            : props.options.filter((option) => option.text.toLocaleLowerCase().includes(autoCompleteInputFilter.toLocaleLowerCase())).map((option) => option);

        setFilteredOptions(result);
    }, [autoCompleteInputFilter]);

    const getClasseNames = () => {
        return clsx({
            [props.className!]: !isEmptyOrWhitespace(props.className),
        });
    };

    const getValidationMessage = (): string => {
        return isEmptyOrWhitespace(props.validationMessage?.() as string) ? "" : (props.validationMessage?.() as string);
    };

    const getStyles = (): Partial<IComboBoxStyles> | undefined => {
        return !isNullOrUndefined(props.styles) ? props.styles : undefined;
    };

    const getComboBoxOptionStyles = (): IComboBoxOptionStyles | undefined => {
        return !isNullOrUndefined(props.optionStyles) ? props.optionStyles : undefined;
    };

    const getValue = () => {
        return convertInbound(props.value());
    };

    const isDisabled = (): boolean => {
        if (!props.command) {
            return false;
        }

        return isNullOrUndefined(props.command.canExecute) ? false : !props.command.canExecute();
    };

    const onBlur = () => {
        // On leaving the control, we should clear the input filter, otherwise
        // the user will be presented with a list of filtered options.
        setAutoCompleteInputFilter("");
    };

    const onChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        props.command.execute(convertOutbound(option?.key));

        // If an option is selected, we should clear the input filter, otherwise
        // the user will be presented with a list of filtered options.
        if (option) {
            setAutoCompleteInputFilter("");
        }
    };

    const onInputChange = (text: string) => {
        setAutoCompleteInputFilter(text);
    };

    const onRenderLabel = (isInError: boolean): JSX.Element => {
        return props.onRenderLabel?.(isInError) ?? <>{props.description}</>;
    };

    // #endregion Code Behind

    // #region Converters

    /**
     * Takes a value provided via the props and converts it to a value
     * that can be consumed by the control. Some controls do not like
     * undefined or null.
     */
    const convertInbound = (value: any) => (isEmptyOrWhitespace(value) ? "" : value);

    /**
     * Takes a value provided by the control and converts it to a value
     * that can be consumed by the caller.
     */
    const convertOutbound = (value: any) => (isEmptyOrWhitespace(value) ? null : value);

    // #endregion Converters

    return (
        <ComboBox
            allowFreeform={true}
            className={getClasseNames()}
            comboBoxOptionStyles={getComboBoxOptionStyles()}
            disabled={isDisabled()}
            errorMessage={getValidationMessage()}
            label={props.displayName}
            onBlur={onBlur}
            onChange={onChange}
            onInputValueChange={onInputChange}
            onRenderLabel={() => onRenderLabel(getValidationMessage() !== "")}
            openOnKeyboardFocus
            onKeyDown={props.onKeyDown}
            options={filteredOptions}
            placeholder={props.placeholder}
            selectedKey={getValue()}
            styles={getStyles()}
            useComboBoxAsMenuWidth={true}
        />
    );
});
