import { isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { computed, makeObservable, observable, observe } from "mobx";

import { ICommand, RelayCommand } from "Application";
import { ConfigureServicesModel } from "./ConfigureServicesModel";
import { ServiceTaskViewModel } from "./ConfigureServiceSubViews/ServiceTaskViewModel";
import { ServiceTaskModel } from "./ConfigureServiceSubViews/ServiceTaskModel";

export class ConfigureServicesViewModel extends ViewModelBase<ConfigureServicesModel> {
    public serviceTaskViewModels = observable<ServiceTaskViewModel>([]);
    public customServiceTaskViewModels = observable<ServiceTaskViewModel>([]);

    public cancelDisplayConfigureTaskGroupCommand: ICommand;
    public updateFromConfigureTaskGroupCommand: ICommand;

    constructor(
        configureServicesModel: ConfigureServicesModel = new ConfigureServicesModel(),
        cancelDisplayConfigureTaskGroupCommand: ICommand,
        updateFromConfigureTaskGroupCommand: ICommand,
    ) {
        super(configureServicesModel);

        this.cancelDisplayConfigureTaskGroupCommand = cancelDisplayConfigureTaskGroupCommand;
        this.updateFromConfigureTaskGroupCommand = updateFromConfigureTaskGroupCommand;

        makeObservable(this, {
            // Observables
            serviceTaskViewModels: observable,
            customServiceTaskViewModels: observable,

            // Computeds
            filteredCustomServiceTaskViewModels: computed,
        });
    }

    /**
     * Disposes of the service task collection observer. Call this from your view.
     */
    public dispose = (): void => {
        this.serviceTasksObserverDispose?.();
        this.customServiceTasksObserverDispose?.();
    };

    // #region Properties

    public get KEY() {
        return this.model.KEY;
    }

    public get displayName() {
        return this.model.taskGroupName;
    }

    public get filteredCustomServiceTaskViewModels() {
        return this.customServiceTaskViewModels.filter((vm) => vm.model.enabled);
    }

    // #endregion Properties

    // #region Commands

    public updateCustomTaskName = new RelayCommand((value: string) => {
        this.setValue("customServiceTaskName", value);
    });

    public addCustomTaskCommand = new RelayCommand(
        () => {
            const customServiceTask = new ServiceTaskModel();

            customServiceTask.enabled = true;
            customServiceTask.taskName = this.model.customServiceTaskName;

            this.model.customServiceTasks.push(customServiceTask);
            this.setValue("customServiceTaskName", "");
        },
        () => {
            return (
                !isEmptyOrWhitespace(this.model.customServiceTaskName) &&
                !this.model.customServiceTasks.some((t) => t.enabled && t.taskName.toLowerCase() === this.model.customServiceTaskName.toLowerCase())
            );
        },
    );

    // #endregion Commands

    /**
     * An observer to listen to changes in the service tasks model collection. Use this to create or remove
     * service task viewmodels in response to changes in the service task model collection.
     */
    private serviceTasksObserverDispose = observe(this.model.serviceTasks, (serviceTaskChanges: any) => {
        for (const addedServiceTask of serviceTaskChanges.added) {
            this.serviceTaskViewModels.push(new ServiceTaskViewModel(addedServiceTask));
        }

        for (const removedServiceTask of serviceTaskChanges.removed) {
            const serviceTaskViewModelToRemove = this.serviceTaskViewModels.find((vm) => vm.model.KEY === removedServiceTask.KEY);

            if (serviceTaskViewModelToRemove) {
                this.serviceTaskViewModels.remove(serviceTaskViewModelToRemove);
            }
        }
    });

    /**
     * An observer to listen to changes in the custom service tasks model collection. Use this to create or remove
     * custom service task viewmodels in response to changes in the custom service task model collection.
     */
    private customServiceTasksObserverDispose = observe(this.model.customServiceTasks, (serviceTaskChanges: any) => {
        for (const addedServiceTask of serviceTaskChanges.added) {
            this.customServiceTaskViewModels.push(new ServiceTaskViewModel(addedServiceTask));
        }

        for (const removedServiceTask of serviceTaskChanges.removed) {
            const serviceTaskViewModelToRemove = this.customServiceTaskViewModels.find((vm) => vm.model.KEY === removedServiceTask.KEY);

            if (serviceTaskViewModelToRemove) {
                this.customServiceTaskViewModels.remove(serviceTaskViewModelToRemove);
            }
        }
    });
}
