import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";
import { startOfDay } from "date-fns";

export class MyWorkModel extends ModelBase<MyWorkModel> {
    //Programming Week filtering
    public programmingWeekStartDate: Date = startOfDay(new Date());

    constructor() {
        super();
        makeObservable(this, {
            programmingWeekStartDate: observable,
        });
    }
}

export class MyWorkValidator extends Validator<MyWorkModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
    }
}
