import { ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";
import { nanoid } from "nanoid";

import { formatDate } from "Application";
import { BillingNoteModel } from "./BillingNoteModel";
import { TA_NOTECONTEXT_ASSIGNMENT, TA_NOTECONTEXT_BILLED, TA_NOTECONTEXT_COMPLETED } from "Views/Shared/Constants";

export class BillingNoteViewModel extends ViewModelBase<BillingNoteModel> {
    private key = nanoid();

    constructor(model: BillingNoteModel) {
        super(model);

        makeObservable(this, {
            // Observables
        });
    }

    // #region Properties

    public get KEY() {
        return this.key;
    }

    public get displayName() {
        return `${this.model.firstName} ${this.model.lastName}`;
    }

    public get documentUrl() {
        return this.model.documentUrl ?? "";
    }

    public get updateDateContext() {
        switch (this.model.context) {
            case TA_NOTECONTEXT_ASSIGNMENT:
                return "Updated";

            case TA_NOTECONTEXT_COMPLETED:
                return "Completed";

            case TA_NOTECONTEXT_BILLED:
                return "Billed";

            default:
                return "Updated";
        }
    }

    public get updateDate() {
        return formatDate(this.model.date!);
    }

    public get note() {
        return this.model.note ?? "No note available.";
    }

    // #endregion Properties
}
