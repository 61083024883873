import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

import { Box, ThemedButton, ThemedText, theme } from "Application";

import { ThemedLoadingSpinner } from "Styles/ThemedPrimitives/Style1/ThemedLoadingSpinner";
import { Chevron } from "Assets/Icons/Chevron";
import { InvoiceViewModel } from "./InvoiceViewModel";
import { InvoiceForecastingView } from "./InvoiceForecasting/InvoiceForecastingView";
import { InvoiceHistoryView } from "./InvoiceHistory/InvoiceHistoryView";

const Container = styled.div`
    background: white;
    height: 100%;
    grid-area: projectsList;
    padding: 10px 25px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
`;

const Header = styled.div`
    display: grid;
    grid-template-columns: 4fr 1fr;
    column-gap: 5px;
`;

const ButtonCluster = styled.div`
    display: grid;
    grid-template-columns: 25px 25px;
    column-gap: 5px;
`;

interface IArrowButtonProps {
    disabled: boolean;
}

const ArrowButton = styled(ThemedButton)<IArrowButtonProps>`
    background-color: transparent;
    border: none;

    height: 20px;
    padding: 0;
    transition: padding-top 0.1s ease-in-out;
    padding: 0px;
    border: 1px solid ${theme.palette.field.main};
    width: 25px;
    min-width: 25px;

    transition: background-color 0.2s ease-in-out;

    &:hover {
        cursor: pointer;
        svg {
            fill: ${theme.palette.common.white};
        }

        &:disabled {
            border: 1px solid ${theme.palette.field.light} !important;
            cursor: default;
            svg {
                fill: ${theme.palette.field.light} !important;
            }
        }
    }

    :not(:hover) {
        background-color: transparent;

        svg {
            fill: ${theme.palette.field.main};
        }
    }

    &:disabled {
        svg {
            fill: ${theme.palette.field.light};
        }
        border: 1px solid ${theme.palette.field.light};
    }

    svg {
        fill: ${theme.palette.field.main};
        transition: fill 0.2s ease-in-out;
    }
`;

export const InvoiceView: React.FC = observer(() => {
    const [viewModel] = useState(() => new InvoiceViewModel());

    return (
        <Container>
            <Header>
                <ThemedText fontStyle="label" mb={"5px"}>
                    {viewModel.title}
                </ThemedText>
                <ButtonCluster>
                    <ArrowButton
                        disabled={viewModel.isNextFinancialYearInFuture || viewModel.isLoading}
                        icon={<Chevron orientation={"left"} />}
                        command={viewModel.previousFinancialYearCommand}
                    />
                    <ArrowButton
                        disabled={viewModel.isEarliestFinancialYearAvailable || viewModel.isLoading}
                        icon={<Chevron orientation={"right"} />}
                        command={viewModel.nextFinancialYearCommand}
                    />
                </ButtonCluster>
            </Header>
            <ThemedText fontStyle="label" mb={"5px"}>
                Year: {viewModel.formattedFinancialYear}
            </ThemedText>
            {viewModel.isLoading && (
                <Box height={"100%"} width={"100%"} position={"absolute"} top={"0px"} left={"0px"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    <ThemedLoadingSpinner color="secondary" fontStyle="h5" />
                </Box>
            )}
            {!viewModel.isLoading && viewModel.showInvoiceForecast && <InvoiceForecastingView model={viewModel.model.invoiceForecastingModel} />}
            {!viewModel.isLoading && viewModel.showInvoiceHistory && <InvoiceHistoryView model={viewModel.model.invoiceHistoryModel} />}
        </Container>
    );
});
