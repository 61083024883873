import { ModelBase } from "@shoothill/core";
import { computed, makeObservable, observable } from "mobx";

import { NoteModel } from "Views/Shared/Note/NoteModel";
import { SuppliersContactItemModel } from "./SuppliersContactItemModel";

export class SuppliersItemModel extends ModelBase<SuppliersItemModel> {
    public id: string = "";
    public contactTitleId: string = "";
    public firstName: string = "";
    public lastName: string = "";
    public address1: string = "";
    public address2: string = "";
    public address3: string = "";
    public name: string = "";
    public contactType: string = "";
    public postCode: string = "";
    public city: string = "";
    public emailAddress1: string = "";
    public emailAddress2: string = "";
    public businessName: string = "";
    public contactNumber1: string = "";
    public contactNumber2: string = "";
    public contactTypeId: string = "";
    public supplierTypeId: string = "";
    public supplierTypeName: string = "";
    public supplierSubTypeId: string | null = null;
    public supplierSubTypeName: string | null = null;
    public lastActionDate: Date | null = null;
    public lastActionByUserId: string = "";
    public lastActionFirstName: string = "";
    public lastActionLastName: string = "";
    public notes: NoteModel[] = [];
    public suppliersContacts = observable<SuppliersContactItemModel>([]);

    public get fullName() {
        return [this.name, this.firstName, this.lastName].filter(Boolean).join(" ");
    }

    public get fullAddress() {
        return [this.address1, this.address2, this.address3, this.city, this.postCode].filter(Boolean).join(", ");
    }

    constructor() {
        super();

        makeObservable(this, {
            name: observable,
            contactType: observable,
            postCode: observable,
            address1: observable,
            address2: observable,
            address3: observable,
            city: observable,
            supplierTypeId: observable,
            supplierSubTypeId: observable,
            supplierTypeName: observable,
            supplierSubTypeName: observable,
            businessName: observable,
            contactNumber1: observable,
            contactNumber2: observable,
            emailAddress1: observable,
            emailAddress2: observable,
            lastActionDate: observable,
            lastActionByUserId: observable,
            lastActionFirstName: observable,
            lastActionLastName: observable,
            fullAddress: computed,
            firstName: observable,
            lastName: observable,
            fullName: computed,
        });
    }
}
