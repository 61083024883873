import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { runInAction } from "mobx";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { UserResponse } from "./Shared/UserResponse";
import { UserViewModel } from "./UserViewModel";
import { RoleLiteViewModel } from "./RoleLiteViewModel";

export class GETUserRelatedEndpoint extends EndpointWithoutRequest<UserResponse> {
    private readonly viewModel: UserViewModel;

    constructor(viewModel: UserViewModel) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.Admin.Users.GetUserRelated);
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: UserResponse): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            this.viewModel.rolesLiteViewModel.replace(
                response.availableRoles.map((role) => {
                    const item = new RoleLiteViewModel();
                    item.model.id = role.id;
                    item.model.name = role.name;
                    return item;
                }),
            );
        });
    }
}
