import { isEmptyOrWhitespace, KeyValuePair, ViewModelBase } from "@shoothill/core";
import { action, computed, makeObservable, observable } from "mobx";
import moment from "moment";

import { APIClient, formatDecimalNumber, RelayCommand, RelayCommandAsync } from "Application";
import { AppUrls } from "AppUrls";
import { WorkCalendarViewModel } from "Views/Shared/WorkCalendar/WorkCalendarViewModel";
import { StaffDetailsModel } from "./StaffDetailsModel";
import { GETStaffProjectDetailsByIdEndpoint } from "./GETStaffProjectDetailsByIdEndpoint";
import { StaffDetailsItemViewModel } from "./StaffDetailsItemViewModel";
import { GETStaffProjectDetailsPercentageAllocationByIdEndpoint } from "./GETStaffProjectDetailsPercentageAllocationByIdEndpoint";
import { GETProjectTaskAssignmentsWithRelatedLiteByGivenDatesAndUserIdEndpoint } from "../Endpoints/GETProjectTaskAssignmentsWithRelatedLiteByGivenDatesAndUserIdEndpoint";
import { TransitionOrigin } from "./TableSubViews/MyWorkWeekOverview/MyWorkWeekOverview";
import { TaskViewModel } from "./ConfigureSubViews/TaskViewModel";
import { TaskModel } from "./ConfigureSubViews/TaskModel";

export interface IMyWorkWeekOverviewProps {
    origin: TransitionOrigin;
    height: string;
    width: string;
}

export class StaffDetailsViewModel extends ViewModelBase<StaffDetailsModel> {
    public apiClient = new APIClient();

    public projects = observable<StaffDetailsItemViewModel>([]);
    //task viewModel properties
    public taskViewModel: TaskViewModel | null = null;
    //work week calendar properties
    public workCalendarViewModel: WorkCalendarViewModel | null = null;
    public displayVerticalMyWorkWeekOverview: boolean = false;
    public displayHorizontalMyWorkWeekOverview: boolean = false;
    public myWorkWeekOverviewTransitionProps: IMyWorkWeekOverviewProps = { origin: "bottom", height: "80%", width: "100%" };

    constructor(userId: string) {
        super(new StaffDetailsModel());

        makeObservable(this, {
            // Observables
            workCalendarViewModel: observable,
            displayVerticalMyWorkWeekOverview: observable,
            displayHorizontalMyWorkWeekOverview: observable,
            myWorkWeekOverviewTransitionProps: observable,
            taskViewModel: observable,

            // Computed
            canDisplayWorkCalendar: computed,
            effortPercentageAllocation: computed,
            chargeablePercentageAllocation: computed,
            myWorkWeekOverviewHeight: computed,
            myWorkWeekOverviewWidth: computed,
            myWorkWeekOverviewOrigin: computed,
            canFilterProjects: computed,
            filteredProjects: computed,
            canSortProjects: computed,
            filteredAndSortedProjects: computed,

            //Actions
            setDisplayVerticalMyWorkWeekOverview: action,
            setDisplayHorizontalMyWorkWeekOverview: action,
            setMyWorkWeekOverviewTransitionProps: action,
            resetTaskViewModel: action,
        });

        this.model.filterStartDate = moment(new Date()).startOf("isoWeek").toDate();

        const _ = this.apiClient.sendAsync(new GETStaffProjectDetailsByIdEndpoint(userId, this));
    }

    // #region Properties

    public get userName() {
        return `${this.model.userFirstName} ${this.model.userLastName}`;
    }

    public get userDocumentUrl() {
        return this.model.documentUrl;
    }

    public get businessHours() {
        return this.model.businessHours;
    }

    public get chargeableBusinessHours() {
        return this.model.chargeableBusinessHours;
    }

    public get effortPercentageAllocation() {
        return this.model.effortPercentageAllocation;
    }

    public get effortPercentageAllocationDisplayName() {
        return `${formatDecimalNumber(this.model.effortHoursAllocation)} out of ${formatDecimalNumber(this.model.businessHours)} staff hours programmed`;
    }

    public get chargeablePercentageAllocation() {
        return this.model.chargeableEffortPercentageAllocation;
    }

    public get chargeableEffortPercentageAllocationDisplayName() {
        return `${formatDecimalNumber(this.model.chargeableEffortHoursAllocation)} out of ${formatDecimalNumber(
            this.model.chargeableBusinessHours,
        )} hours of chargeable time programmed`;
    }

    public get canFilterProjects() {
        return !isEmptyOrWhitespace(this.model.filterKeyword) || !isEmptyOrWhitespace(this.model.filterProjectSupportId);
    }

    public get filteredProjects() {
        return this.canFilterProjects ? this.projects.filter((vm) => vm.filterPredicate(this.model.filterKeyword, this.model.filterProjectSupportId)) : this.projects;
    }

    // #endregion Properties

    // #region Commands

    public updateFilterKeywordCommand = new RelayCommand((keyword: string) => {
        this.model.setValue("filterKeyword", keyword);
    });

    public navigateToStaffCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.Staff.Table);
    });

    public updatePercentageAllocationCommand = new RelayCommand(() => {
        this.apiClient.sendAsync(new GETStaffProjectDetailsPercentageAllocationByIdEndpoint(this));
    });

    // #endregion Commands

    // #region Filtering

    public updateFilterDateCommand = new RelayCommand(
        (date: Date | undefined) => {},
        () => false,
    );

    public updatePreviousFilterDateCommand = new RelayCommand(() => {
        this.model.filterStartDate = moment(this.model.filterStartDate).add(-1, "week").toDate();
        const _ = this.apiClient.sendAsync(new GETStaffProjectDetailsByIdEndpoint(this.model.userId, this));
    });

    public updateNextFilterDateCommand = new RelayCommand(() => {
        this.model.filterStartDate = moment(this.model.filterStartDate).add(1, "week").toDate();

        const _ = this.apiClient.sendAsync(new GETStaffProjectDetailsByIdEndpoint(this.model.userId, this));
    });

    public updateFilterProjectSupportCommand = new RelayCommand((id: string | null) => {
        this.model.filterProjectSupportId = id;
    });

    public get projectSupport() {
        const options = this.projects
            .filter((p) => p.model.projectSupportId && p.model.projectSupportFirstName && p.model.projectSupportLastName)
            .map((t) => {
                return {
                    key: t.model.projectSupportId!,
                    text: `${t.model.projectSupportFirstName} ${t.model.projectSupportLastName}`,
                };
            });

        return this.formatKeyValuePairs(options, { key: "", text: "All project supports" });
    }
    // #endregion Filtering

    // #region Sorting

    public updateSortCommand = new RelayCommand((key: string, sortDescending: boolean) => {
        this.model.sortKey = key;
        this.model.sortDescending = sortDescending;
    });

    public get canSortProjects(): boolean {
        return !isEmptyOrWhitespace(this.model.sortKey);
    }

    public get filteredAndSortedProjects(): StaffDetailsItemViewModel[] {
        return this.canSortProjects
            ? this.filteredProjects.slice().sort((lhs, rhs) => {
                  return (this.model.sortDescending ? lhs[this.model.sortKey] < rhs[this.model.sortKey] : lhs[this.model.sortKey] > rhs[this.model.sortKey]) ? 1 : -1;
              })
            : this.projects;
    }

    // #endregion Sorting

    // #region Calendar Properties

    public get myWorkWeekOverviewHeight() {
        return this.myWorkWeekOverviewTransitionProps.height;
    }

    public get myWorkWeekOverviewWidth() {
        return this.myWorkWeekOverviewTransitionProps.width;
    }

    public get myWorkWeekOverviewOrigin() {
        return this.myWorkWeekOverviewTransitionProps.origin;
    }

    public get canDisplayWorkCalendar() {
        return this.workCalendarViewModel !== null;
    }

    // #endregion Calendar Properties

    // #region Calendar Commands

    public setDisplayVerticalMyWorkWeekOverview(value: boolean) {
        this.displayVerticalMyWorkWeekOverview = value;
    }

    public setDisplayHorizontalMyWorkWeekOverview(value: boolean) {
        this.displayHorizontalMyWorkWeekOverview = value;
    }

    public setMyWorkWeekOverviewTransitionProps(origin: TransitionOrigin, height: string, width: string) {
        this.myWorkWeekOverviewTransitionProps = { origin, height, width };
    }

    openMyWorkWeekOverviewCommand = new RelayCommand(async (origin: TransitionOrigin, height: string, width: string) => {
        this.workCalendarViewModel = new WorkCalendarViewModel();
        this.workCalendarViewModel.startOfWeek = this.model.filterStartDate;

        this.setMyWorkWeekOverviewTransitionProps(origin, height, width);
        await this.apiClient.sendAsync(new GETProjectTaskAssignmentsWithRelatedLiteByGivenDatesAndUserIdEndpoint(this));
        if (origin == "bottom") {
            this.setDisplayVerticalMyWorkWeekOverview(!this.displayVerticalMyWorkWeekOverview);
        } else {
            this.setDisplayHorizontalMyWorkWeekOverview(!this.displayHorizontalMyWorkWeekOverview);
        }
    });

    toggleMyWorkWeekOverviewCommand = new RelayCommand((origin: TransitionOrigin, height: string, width: string) => {
        this.workCalendarViewModel = new WorkCalendarViewModel();
        this.workCalendarViewModel.startOfWeek = this.model.filterStartDate;

        this.setMyWorkWeekOverviewTransitionProps(origin, height, width);
        this.apiClient.sendAsync(new GETProjectTaskAssignmentsWithRelatedLiteByGivenDatesAndUserIdEndpoint(this)).then((response) => {
            if (origin == "bottom") {
                this.setDisplayVerticalMyWorkWeekOverview(!this.displayVerticalMyWorkWeekOverview);
            } else {
                this.setDisplayHorizontalMyWorkWeekOverview(!this.displayHorizontalMyWorkWeekOverview);
            }
        });
    });

    public closeMyWorkWeekOverviewCommand = new RelayCommand(() => {
        setTimeout(() => {
            this.workCalendarViewModel = null;
            this.setDisplayVerticalMyWorkWeekOverview(false);
            this.setDisplayHorizontalMyWorkWeekOverview(false);
        }, 0.767);
    });

    // #endregion Calendar Commands

    //task view model
    public get canDisplayTask(): boolean {
        return this.taskViewModel !== null;
    }

    public resetTaskViewModel() {
        this.taskViewModel = null;
    }

    /**
     * Displays a task.
     */
    public displayTaskCommand = new RelayCommand((projectTaskAssignmentId: string) => {
        this.taskViewModel = new TaskViewModel(projectTaskAssignmentId, new TaskModel(), this.submitTaskCommand, this.cancelTaskCommand);
    });

    /**
     * Handles the result of saving a task.
     */
    public submitTaskCommand = new RelayCommandAsync(async () => {
        await this.apiClient.sendAsync(new GETProjectTaskAssignmentsWithRelatedLiteByGivenDatesAndUserIdEndpoint(this));
        if (this.apiClient.IsRequestSuccessful) {
            this.resetTaskViewModel();
        }
    });
    /**
     * Handles cancelling a task.
     */
    public cancelTaskCommand = new RelayCommand(() => {
        this.resetTaskViewModel();
    });

    // #region helpers
    private formatKeyValuePairs = (keyValuePairs: KeyValuePair[], defaultKeyValuePair?: KeyValuePair) => {
        const distinctOptions = keyValuePairs
            .sort((a, b) => (a.text > b.text ? 1 : b.text > a.text ? -1 : 0))
            .filter((option, index, arr) => arr.findIndex((t) => t.key === option.key) === index);
        const retVal = [...distinctOptions];

        if (defaultKeyValuePair) {
            retVal.unshift(defaultKeyValuePair);
        }
        return retVal;
    };

    // #endregion helpers
}
