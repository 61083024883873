import { makeObservable, observable, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { RelayCommand } from "Application";
import { NewBusinessModel } from "./NewBusinessModel";
import { GlobalHistory } from "index";
import { AppUrls } from "AppUrls";

export class NewBusinessViewModel extends ViewModelBase<NewBusinessModel> {
    public locationPathName: string = "";

    constructor(model = new NewBusinessModel()) {
        super(model, false);
        makeObservable(this, {
            locationPathName: observable,
        });

        GlobalHistory.listen((locationUpdate) => runInAction(() => this.setLocationPathName(locationUpdate.location.pathname)));

        this.setLocationPathName(location.pathname);
    }

    public setEnquiriesTabCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.Enquiries.Table);
    });

    public setQuotesTabCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.Quotes.Table);
    });

    public setLostTabCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.Lost.Table);
    });

    private setLocationPathName = (pathName: string) => {
        this.locationPathName = pathName;
    };
}
