import { mergeStyleSets } from "@fluentui/react";
import { isEmptyOrWhitespace } from "@shoothill/core";
import React, { PropsWithChildren } from "react";
import { Outlet } from "react-router";
import { container } from "tsyringe";

import house from "Assets/housesmall.png";
import { LookupStore } from "Stores/Domain";
import { AccountLayoutBase } from "./AccountLayout";

export const BASEAccountLayout: React.FC<PropsWithChildren<any>> = (props) => {
    const lookupStore = container.resolve(LookupStore);
    const imagesUrlList = lookupStore.getImageUrl();

    // In the event that we have no images available, or the image object has no 'text'
    // property defined, we should fall back onto an application asset.
    const randomIndex = Math.floor(Math.random() * imagesUrlList.length);
    const randomImageUrl = imagesUrlList[randomIndex];
    const imageUrl = randomImageUrl && !isEmptyOrWhitespace(randomImageUrl.text) ? randomImageUrl.text : house;

    return (
        <AccountLayoutBase
            {...props}
            styles={mergeStyleSets({
                root: {
                    display: "flex",
                    height: "100%",
                },
                content: {
                    boxShadow: `3px 0px 6px #00000029`,
                    display: "flex",
                    flex: "0.30",
                    overflowY: "auto",
                },
                image: {
                    backgroundImage: `url(${imageUrl})`,
                    backgroundPosition: "left center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    flex: "1",
                },
            })}
        >
            <Outlet />
        </AccountLayoutBase>
    );
};
