import { FieldType, ViewModelBase } from "@shoothill/core";
import { action, computed, makeObservable, observable } from "mobx";
import { ICommand, RelayCommand } from "Application";
import { SourceOfEnquiryModel, SourceOfEnquiryModelValidator } from "./SourceOfEnquiryModel";
import { container } from "tsyringe";
import { LookupStore } from "Stores/Domain";
import { SOURCE_OF_ENQUIRY_OTHER } from "../Constants";

export class SourceOfEnquiryViewModel extends ViewModelBase<SourceOfEnquiryModel> {
    public lookupStore = container.resolve(LookupStore);
    public isCalloutVisible: boolean = false;
    constructor(model = new SourceOfEnquiryModel()) {
        super(model, false);
        this.setValue("sourceOfEnquiryTypeOtherId", this.lookupStore.getSourceOfEnquiryByType(SOURCE_OF_ENQUIRY_OTHER)!.id);
        this.setValidator(new SourceOfEnquiryModelValidator());
        makeObservable(this, {
            //Observables
            isCalloutVisible: observable,

            //Actions
            setIsCalloutVisible: action,

            //Computed
            canDisplayDetail: computed,
        });
    }

    //region actions
    public setIsCalloutVisible = (value: boolean) => (this.isCalloutVisible = value);
    //endregion actions

    //region commands

    public updateSourceOfEnquiryTypeCommand = new RelayCommand((value: number) => {
        this.updateField("sourceOfEnquiryTypeId", value);
        if (!this.isSourceOfEnquiryOtherType) {
            this.setValue("detail", null);
        }
    });

    public updateDetailCommand = new RelayCommand((value: number) => {
        this.updateField("detail", value);
    });

    public calloutVisibilityCommand: ICommand = new RelayCommand(
        () => {
            this.setIsCalloutVisible(!this.isCalloutVisible);
        },
        () => this.canDisplayDetail,
    );

    //endregion commands

    // #region Properties

    public get detailButtonText() {
        return this.isCalloutVisible ? "Hide additional detail" : "See additional detail";
    }

    public get canDisplayCallout() {
        //display callout only after the note is more than 60 characters long
        //done to prevent the ui from breaking for longer notes
        return this.model.detail && this.model.detail.length > 60;
    }

    public get canDisplayDetail() {
        return this.isSourceOfEnquiryOtherType;
    }

    public get isSourceOfEnquiryOtherType() {
        return this.model.sourceOfEnquiryTypeId == this.lookupStore.getSourceOfEnquiryByType(SOURCE_OF_ENQUIRY_OTHER)!.id;
    }

    /**
     * Returns the enquiry status display name.
     */
    public get sourceOfEnquiryDisplayName() {
        return this.lookupStore.sourceOfEnquiryTypes.find((es) => es.id === this.model.sourceOfEnquiryTypeId)?.name?.toLocaleUpperCase() ?? "";
    }

    /**
     * Returns a collection of source of enquiry types.
     */
    public get sourceOfEnquiryTypeOptions() {
        return this.lookupStore.sourceOfEnquiryTypes
            .filter((et) => et.isInternal === false || et.id === this.model.sourceOfEnquiryTypeId)
            .filter((et) => !et.isDeleted || et.id === this.model.sourceOfEnquiryTypeId)
            .map((item) => {
                return {
                    key: item.id,
                    text: item.name,
                };
            });
    }

    // #endregion Properties

    // #region Helpers

    // #endregion Helpers

    // #region Supporting

    private updateField(fieldName: keyof FieldType<SourceOfEnquiryModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    // #endregion Supporting
}
