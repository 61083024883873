import React from "react";
import { Box } from "@mui/material";
import { UnsupportedBrowserView } from "../UnsupportedBrowser/UnsupportedBrowserView";
import { Footer } from "../Footer/FooterView";
import { InfoBar } from "../../Components/InfoBar";
import { Outlet } from "react-router";
import { BASEHeader } from "../Header/HeaderStyles";
import styled from "@emotion/styled";

const Fixed = styled(Box)`
    top: 0;
    left: 0;
    position: fixed;
    z-index: 100000;
    width: 100%;
`;
const Container = styled(Box)`
    height: 100%; // The container must fill the page, so that its children may fill the page too.
    display: flex;
    flex-direction: column;
    flex: 1;

    //overflow-y: auto; // Interferes with the header.
    max-width: 1920px;
    padding-top: 70px;
    margin: 0 auto;
`;
//EN: use empty generic type to get default react props
export const DefaultLayout: React.FC<{}> = (props) => {
    if (!props) return <>Props undefined</>;
    return (
        <>
            {window.IE11 ? (
                <UnsupportedBrowserView />
            ) : (
                <>
                    <Box style={{ height: "100vh" }}>
                        <Fixed>
                            <BASEHeader />
                        </Fixed>
                        {props && (
                            <Container id={"rootcontainer"}>
                                <Outlet />
                            </Container>
                        )}
                    </Box>
                </>
            )}
        </>
    );
};
