import { makeObservable, observable, runInAction, action } from "mobx";
import { FieldType, ViewModelBase, AccountStatus, ApiResult } from "@shoothill/core";
import { LoginModel, LoginValidator } from "./LoginModel";
import { ICommand, RelayCommand, APIClient, ICommandAsync, RelayCommandAsync, IKeyState } from "Application";
import { AccountStore } from "../../../Stores/Domain";
import { singleton, container } from "tsyringe";
import { LoginEndpointPOST } from "./LoginEndpointPOST";
import { AppUrls } from "../../../AppUrls";

@singleton()
export class LoginViewModel extends ViewModelBase<LoginModel> {
    public apiClient = new APIClient();
    public postLoginEndpoint = new LoginEndpointPOST(this);
    public signInViewActive: boolean = true;
    public signUpViewActive: boolean = false;

    constructor() {
        super(new LoginModel());

        this.setValidator(new LoginValidator());

        makeObservable(this, {
            signInViewActive: observable,
            signUpViewActive: observable,
        });
    }

    public accountStore = container.resolve(AccountStore);

    public updateEmailCommand: ICommand = new RelayCommand((value: string) => this.updateField("emailAddress", value));

    public updatePasswordCommand: ICommand = new RelayCommand((value: string) => this.updateField("password", value));

    public rememberMeCommand: ICommand = new RelayCommand((value: string) => this.updateField("rememberMe", value));

    public loginCommand: ICommand = new RelayCommand(async () => {
        if (this.isModelValid()) {
            await this.apiClient.sendAsync(this.postLoginEndpoint, this.model);
            if (this.apiClient.IsRequestSuccessful) {
            } else {
            }
        }
    });

    private updateField = (fieldName: keyof FieldType<LoginModel>, value: any) => {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    };
}
