import { ViewModelBase } from "@shoothill/core";
import { startOfDay } from "date-fns";
import { computed, makeObservable, observable } from "mobx";
import moment from "moment";

import { APIClient, RelayCommand } from "Application";
import { WorkCalendarViewModel } from "Views/Shared/WorkCalendar/WorkCalendarViewModel";
import { GETProjectTaskAssignmentsWithRelatedLiteByGivenDatesAndUserIdEndpoint } from "./Endpoints/GETProjectTaskAssignmentsWithRelatedLiteByGivenDatesAndUserIdEndpoint";
import { POSTInsertProjectTaskAssignmentGroupUserNoteEndpoint } from "./Endpoints/POSTInsertProjectTaskAssignmentGroupUserNoteEndpoint";
import { POSTInsertProjectTaskAssignmentUserNoteEndpoint } from "./Endpoints/POSTInsertProjectTaskAssignmentUserNoteEndpoint";
import { MyWorkModel } from "./MyWorkModel";
import { ProgrammingWeekTaskModel } from "./ProgrammingWeekTask/ProgrammingWeekTaskModel";
import { ProgrammingWeekTaskViewModel } from "./ProgrammingWeekTask/ProgrammingWeekTaskViewModel";
import { ProgrammingWeekTaskGroupModel } from "./ProgrammingWeekTaskGroup/ProgrammingWeekTaskGroupModel";
import { ProgrammingWeekTaskGroupViewModel } from "./ProgrammingWeekTaskGroup/ProgrammingWeekTaskGroupViewModel";

export class MyWorkViewModel extends ViewModelBase<MyWorkModel> {
    public apiClient = new APIClient();

    public workCalendarViewModel: WorkCalendarViewModel;
    public taskViewModel: ProgrammingWeekTaskViewModel | null = null;
    public taskGroupViewModel: ProgrammingWeekTaskGroupViewModel | null = null;

    constructor(model = new MyWorkModel()) {
        super(model, false);

        this.workCalendarViewModel = new WorkCalendarViewModel();

        makeObservable(this, {
            // Observables
            taskViewModel: observable,
            taskGroupViewModel: observable,

            // Computeds
            canDisplayTask: computed,
            canDisplayTaskGroup: computed,
        });

        const thisWeek = moment(new Date()).startOf("isoWeek").toDate();

        this.workCalendarViewModel.startOfWeek = thisWeek;
        this.model.programmingWeekStartDate = thisWeek;

        const _ = this.apiClient.sendAsync(new GETProjectTaskAssignmentsWithRelatedLiteByGivenDatesAndUserIdEndpoint(this), this.model);
    }

    // #region Properties
    // #endregion Properties

    // #region Commands

    public updateProgrammingWeekStartDateCommand = new RelayCommand(
        (date: Date | undefined) => {},
        () => false,
    );

    public getProgrammingWeekProjectTaskAssignments = new RelayCommand(() => {
        this.apiClient.sendAsync(new GETProjectTaskAssignmentsWithRelatedLiteByGivenDatesAndUserIdEndpoint(this), this.model);
    });

    public updatePreviousProgrammingWeekDateCommand = new RelayCommand(() => {
        const previousWeek = startOfDay(moment(this.model.programmingWeekStartDate).add(-1, "week").toDate());

        this.workCalendarViewModel.startOfWeek = previousWeek;
        this.model.programmingWeekStartDate = previousWeek;

        const _ = this.apiClient.sendAsync(new GETProjectTaskAssignmentsWithRelatedLiteByGivenDatesAndUserIdEndpoint(this), this.model);
    });

    public updateNextProgrammingWeekDateCommand = new RelayCommand(() => {
        const nextWeek = startOfDay(moment(this.model.programmingWeekStartDate).add(1, "week").toDate());

        this.workCalendarViewModel.startOfWeek = nextWeek;
        this.model.programmingWeekStartDate = nextWeek;

        const _ = this.apiClient.sendAsync(new GETProjectTaskAssignmentsWithRelatedLiteByGivenDatesAndUserIdEndpoint(this), this.model);
    });

    public resetServerErrorCommand = new RelayCommand(() => {
        this.apiClient.reset();
    });

    // #endregion Commands

    // #region Task Assignment Panel

    public get canDisplayTask(): boolean {
        return this.taskViewModel !== null;
    }

    public displayTaskCommand = new RelayCommand((projectTaskAssignmentId: string) => {
        this.taskViewModel = new ProgrammingWeekTaskViewModel(projectTaskAssignmentId, new ProgrammingWeekTaskModel(), this.submitTaskCommand, this.cancelTaskCommand);
    });

    public submitTaskCommand = new RelayCommand(async () => {
        await this.apiClient.sendAsync(new POSTInsertProjectTaskAssignmentUserNoteEndpoint(), this.taskViewModel?.model);

        if (this.apiClient.IsRequestSuccessful) {
            this.taskViewModel = null;
            this.apiClient.reset();
            this.apiClient.sendAsync(new GETProjectTaskAssignmentsWithRelatedLiteByGivenDatesAndUserIdEndpoint(this), this.model);
        }
    });

    public cancelTaskCommand = new RelayCommand(() => {
        this.taskViewModel = null;
    });

    // #endregion Task Assignment Panel

    // #region Task Assignment Group Panel

    public get canDisplayTaskGroup(): boolean {
        return this.taskGroupViewModel !== null;
    }

    public displayTaskGroupCommand = new RelayCommand((projectTaskAssignmentGroupId: string) => {
        this.taskGroupViewModel = new ProgrammingWeekTaskGroupViewModel(
            projectTaskAssignmentGroupId,
            new ProgrammingWeekTaskGroupModel(),
            this.submitTaskGroupCommand,
            this.cancelTaskGroupCommand,
        );
    });

    public submitTaskGroupCommand = new RelayCommand(async () => {
        await this.apiClient.sendAsync(new POSTInsertProjectTaskAssignmentGroupUserNoteEndpoint(), this.taskGroupViewModel?.model);

        if (this.apiClient.IsRequestSuccessful) {
            this.taskGroupViewModel = null;
            this.apiClient.reset();
            this.apiClient.sendAsync(new GETProjectTaskAssignmentsWithRelatedLiteByGivenDatesAndUserIdEndpoint(this), this.model);
        }
    });

    public cancelTaskGroupCommand = new RelayCommand(() => {
        this.taskGroupViewModel = null;
    });

    // #endregion Task Assignment Group Panel
}
