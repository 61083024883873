import { isEmptyOrWhitespace, isNullOrUndefined, KeyValuePair, ViewModelBase } from "@shoothill/core";
import { action, computed, makeObservable, observable } from "mobx";
import { container } from "tsyringe";

import { APIClient, RelayCommand } from "Application";
import { LookupStore } from "Stores/Domain";
import { DashboardViewModel } from "Views/Dashboard/DashboardViewModel";
import { ResourceViewModel } from "Views/Resource/ResourceViewModel";
import { AssignedTasksModel } from "./AssignedTasksModel";
import { AssignedTaskItemViewModel } from "./AssignedTaskItemViewModel";
import { TaskViewModel } from "./ConfigureSubViews/TaskViewModel";
import { TaskModel } from "./ConfigureSubViews/TaskModel";
import { GETAssignedProjectTasksEndpoint } from "./GETAllAssignedProjectTasksEndpoint";

export class AssignedTasksViewModel extends ViewModelBase<AssignedTasksModel> {
    public lookupStore = container.resolve(LookupStore);
    public apiClient = new APIClient();
    public taskViewModel: TaskViewModel | null = null;
    public tasks = observable<AssignedTaskItemViewModel>([]);
    public showStatusFilter: boolean = true;
    public parentViewModel: ResourceViewModel | DashboardViewModel | null;

    constructor(parentViewModel?: ResourceViewModel | DashboardViewModel, overrideStatusFilter?: string) {
        super(new AssignedTasksModel());

        makeObservable(this, {
            tasks: observable,
            taskViewModel: observable,
            showStatusFilter: observable,
            //actions
            setShowStatusFilter: action,
            // Computeds
            canFilterTasks: computed,
            filteredTasks: computed,
            canSortTasks: computed,
            filteredAndSortedTasks: computed,
            projectTaskAssignmentStatuses: computed,
            projects: computed,
            projectLeads: computed,
            canShowStatusFilter: computed,
        });

        this.parentViewModel = parentViewModel ? parentViewModel : null;

        this.init(overrideStatusFilter);
    }

    public init(overrideStatusFilter?: string) {
        if (this.parentViewModel !== null) {
            this.setShowStatusFilter(false);
            const _ = this.apiClient.sendAsync(new GETAssignedProjectTasksEndpoint(this)).then(() => this.filterStatusByDefault(overrideStatusFilter ?? null));
        } else {
            this.setShowStatusFilter(false);
            const _ = this.apiClient.sendAsync(new GETAssignedProjectTasksEndpoint(this));
        }
    }

    //region actions

    public setShowStatusFilter(value: boolean) {
        this.showStatusFilter = value;
    }

    //endregion actions

    //region computed

    public get canShowStatusFilter() {
        return this.showStatusFilter;
    }
    //endregion computed

    // #region helpers

    public filterStatusByDefault(value: string | null) {
        const completeStatus = this.lookupStore.projectTaskAssignmentStatuses.find((s) => s.type == value);

        if (completeStatus) {
            this.updateFilterProjectTaskAssignmentStatusCommand.execute(completeStatus.id);
            this.setShowStatusFilter(false);
        } else {
            this.setShowStatusFilter(true);
            this.clearFiltersCommand.execute();
        }
    }

    // #endregion helpers

    // #region Filtering

    public updateFilterKeywordCommand = new RelayCommand((keyword: string) => {
        this.model.filterKeyword = keyword;
    });

    public updateFilterDateCommand = new RelayCommand((date: Date | undefined) => {
        this.model.filterDate = date;
    });

    public updateFilterProjectTaskAssignmentStatusCommand = new RelayCommand((id: string | null) => {
        this.model.filterProjectTaskAssignmentStatusId = id;
    });

    public updateFilterProjectCommand = new RelayCommand((id: string | null) => {
        this.model.filterProjectId = id;
    });

    public updateFilterProjectLeadCommand = new RelayCommand((id: string | null) => {
        this.model.filterProjectLeadId = id;
    });

    public clearFiltersCommand = new RelayCommand(() => {
        //if the status id is predefined don't reset it.
        if (this.showStatusFilter) {
            this.model.filterKeyword = "";
            this.model.filterProjectId = null;
            this.model.filterProjectLeadId = null;
            this.model.filterProjectTaskAssignmentStatusId = null;
            this.model.filterDate = undefined;
        } else {
            this.model.filterKeyword = "";
            this.model.filterProjectId = null;
            this.model.filterProjectLeadId = null;
            this.model.filterDate = undefined;
        }
    });

    public get projectTaskAssignmentStatuses() {
        const retVal: KeyValuePair[] = [{ key: "", text: "All statuses" }];

        const options = this.tasks.map((t) => {
            return {
                key: t.model.projectTaskAssignmentStatusId,
                text: t.model.projectTaskAssignmentStatusName,
            };
        });

        const assignedAsNCTStatus: KeyValuePair = { key: "NCT", text: "Assigned as NCT" };

        retVal.push(assignedAsNCTStatus);

        const distinctOptions = options.filter((option, index, arr) => arr.findIndex((t) => t.key === option.key) === index);

        retVal.push(...distinctOptions);

        return retVal.sort((a, b) => a.text.localeCompare(b.text));
    }

    public get projects() {
        const options = this.tasks.map((t) => {
            return {
                key: t.model.projectId,
                text: `${t.model.reference} ${t.model.title}`,
            };
        });

        const distinctOptions = options.filter((option, index, arr) => arr.findIndex((t) => t.key === option.key) === index);

        return [{ key: "", text: "All projects" }, ...distinctOptions];
    }

    public get projectLeads() {
        const options = this.tasks
            .map((t) => {
                return {
                    key: t.model.projectLeadId,
                    text: `${t.model.projectLeadFirstName} ${t.model.projectLeadLastName}`,
                };
            })
            .sort((a, b) => (a.text > b.text ? 1 : b.text > a.text ? -1 : 0));

        const distinctOptions = options.filter((option, index, arr) => arr.findIndex((t) => t.key === option.key) === index);

        return [{ key: "", text: "All leads" }, ...distinctOptions];
    }

    public get canFilterTasks(): boolean {
        return (
            !isEmptyOrWhitespace(this.model.filterKeyword) ||
            !isNullOrUndefined(this.model.filterDate) ||
            !isEmptyOrWhitespace(this.model.filterProjectTaskAssignmentStatusId) ||
            !isEmptyOrWhitespace(this.model.filterProjectId) ||
            !isEmptyOrWhitespace(this.model.filterProjectLeadId)
        );
    }

    public get filteredTasks(): AssignedTaskItemViewModel[] {
        return this.canFilterTasks
            ? this.tasks.filter((vm) =>
                  vm.filterPredicate(
                      this.model.filterKeyword,
                      this.model.filterDate,
                      this.model.filterProjectTaskAssignmentStatusId,
                      this.model.filterProjectId,
                      this.model.filterProjectLeadId,
                  ),
              )
            : this.tasks;
    }

    // #endregion Filtering

    // #region Sorting

    public updateSortCommand = new RelayCommand((key: string, sortDescending: boolean) => {
        this.model.sortKey = key;
        this.model.sortDescending = sortDescending;
    });

    public get canSortTasks(): boolean {
        return !isEmptyOrWhitespace(this.model.sortKey);
    }

    public get filteredAndSortedTasks(): AssignedTaskItemViewModel[] {
        return this.canSortTasks
            ? this.filteredTasks.slice().sort((lhs, rhs) => {
                  return (this.model.sortDescending ? lhs[this.model.sortKey] < rhs[this.model.sortKey] : lhs[this.model.sortKey] > rhs[this.model.sortKey]) ? 1 : -1;
              })
            : this.filteredTasks;
    }

    // #endregion Sorting

    // #region Modify Task

    public get canDisplayTask(): boolean {
        return this.taskViewModel !== null;
    }

    /**
     * Displays a task.
     */
    public displayTaskCommand = new RelayCommand((projectTaskAssignmentId: string) => {
        if (this.parentViewModel instanceof DashboardViewModel) {
            this.taskViewModel = new TaskViewModel(
                projectTaskAssignmentId,
                new TaskModel(),
                this.submitTaskCommand,
                this.cancelTaskCommand,
                this.parentViewModel.syncDashboardCommand,
            );
        } else {
            this.taskViewModel = new TaskViewModel(projectTaskAssignmentId, new TaskModel(), this.submitTaskCommand, this.cancelTaskCommand);
        }
    });

    /**
     * Handles the result of saving a task.
     */
    public submitTaskCommand = new RelayCommand(() => {
        this.taskViewModel = null;
        this.apiClient.sendAsync(new GETAssignedProjectTasksEndpoint(this));
    });

    /**
     * Handles cancelling a task.
     */
    public cancelTaskCommand = new RelayCommand(() => {
        this.taskViewModel = null;
    });

    // #endregion Modify Task
}
