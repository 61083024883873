import { Stack } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React from "react";

import { ThemedButton, ThemedEditText, ThemedComboBox, Show, AutoGrid } from "Application";
import { FindAddressViewModel } from "./FindAddressViewModel";

export interface IFindAddressViewProps {
    viewModel: FindAddressViewModel;
}

export const FindAddressView: React.FC<IFindAddressViewProps> = observer((props) => {
    const viewModel = props.viewModel;

    return (
        <Stack horizontal verticalAlign="end" gap={"30px"}>
            <div style={{ display: "flex", alignItems: "end" }}>
                <ThemedEditText displayName="Postcode search" command={viewModel.updatedPostcodeCommand} placeholder="Postcode" value={() => viewModel.getValue("postcode")} />
                <ThemedButton displayName="Search" command={viewModel.findAddressesCommand} paletteColor="primary" size="larger" />
            </div>
            <Show if={viewModel.hasAddresses}>
                <div style={{ flex: "1" }}>
                    <ThemedComboBox
                        displayName="Select an address"
                        styles={{ root: { flex: 1 } }}
                        command={viewModel.selectAddressCommand}
                        options={viewModel.addresses}
                        placeholder="Select an address"
                        value={() => "DON'T PERSIST AN ADDRESS SELECTION"}
                    />
                </div>
            </Show>
        </Stack>
    );
});
