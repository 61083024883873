import { User } from "Application/Models/Domain/User";
import { ProjectLite } from "Application/Models/Domain";
import _ from "lodash";

import { CustomerContactItemModel } from "Views/Shared/Customer/CustomerContactItemModel";
import { CustomerItemModel } from "Views/Shared/Customer/CustomerItemModel";
import { FileModel } from "Views/Shared/Files/FileModel";
import { NoteModel } from "Views/Shared/Note/NoteModel";
import { EnquiryViewModel } from "../EnquiryViewModel";
import { SourceOfEnquiryResponse } from "Views/Shared/SourceOfEnquiry/Responses";

export class EnquiryResponse extends Response {
    // #region Response Data

    public id: string = "";
    public reference: string = "";

    public enquiryStatusId: string = "";
    public enquiryTypeId: string = "";

    public enquiryPersonVisitingId: string = "";
    public enquiryPersonVisitingVisitDate: Date | null = null;
    public enquiryPersonVisitingVisitTime: string | null = null;

    public projectTypeId: string = "";
    public contactTypeId: string = "";
    public customerId: string = "";
    public customerContactId: string | null = null;
    public customerVisitDate: Date | null = null;
    public projectId: string = "";

    public sameAsBusinessAddress: boolean = false;
    public siteAddress1: string | null = null;
    public siteAddress2: string | null = null;
    public siteAddress3: string | null = null;
    public siteCity: string | null = null;
    public sitePostcode: string | null = null;

    public title: string = "";
    public estimatedCost: number | null = null;

    public isLost: boolean = false;
    public isQuote: boolean = false;

    public lostNoteSubTypeId: string | null = null;
    public lostNoteDetail: string | null = null;

    customers: CustomerResponse[] = [];
    customerContacts: CustomerContactResponse[] = [];
    projects: ProjectResponse[] = [];
    notes: NoteResponse[] = [];
    noteDocuments: NoteDocumentResponse[] = [];
    documents: EnquiryDocumentResponse[] = [];
    enquiryPersonsVisiting: UserResponse[] = [];
    sourceOfEnquiry: SourceOfEnquiryResponse = new SourceOfEnquiryResponse();

    // #endregion Response Data

    // #region Response Part Mappings to ViewModel

    public static subEnquiryResponsePart(response: EnquiryResponse, viewModel: EnquiryViewModel) {
        viewModel.enquirySubViewModel.model.fromResponse(response);
        viewModel.enquirySubViewModel.sourceOfEnquiryViewModel.model.fromResponse(response.sourceOfEnquiry);
        // Process the response Projects.
        viewModel.enquirySubViewModel.model.projectsList.replace(
            response.projects.map((project) => {
                const model: ProjectLite = {
                    id: project.id,
                    title: project.title,
                    projectTypeId: project.projectTypeId,
                };

                return model;
            }),
        );

        // Process te enquiry documents.
        viewModel.enquirySubViewModel.filesViewModel.model.files.replace(
            response.documents.map((nd) => {
                const model = new FileModel();

                model.fromResponse(nd);

                return model;
            }) ?? [],
        );

        // Process the response person visiting.
        viewModel.enquirySubViewModel.model.personVisitingList.replace(
            response.enquiryPersonsVisiting.map((item) => {
                const domainModel = new User();
                domainModel.id = item.id;
                domainModel.firstName = item.firstName;
                domainModel.lastName = item.lastName;
                domainModel.thumbnailDocumentUrl = item.thumbnailDocumentUrl;
                return domainModel;
            }),
        );
    }

    public static customerResponsePart(response: EnquiryResponse, viewModel: EnquiryViewModel) {
        viewModel.customerViewModel.customers.replace(
            response.customers.map((c) => {
                const model = new CustomerItemModel();

                model.fromResponse(c);

                return model;
            }),
        );

        viewModel.customerViewModel.customerContacts.replace(
            response.customerContacts.map((cc) => {
                const model = new CustomerContactItemModel();

                model.fromResponse(cc);

                return model;
            }),
        );

        viewModel.customerViewModel.model.fromResponse(response);
        viewModel.customerViewModel.findAddressViewModel.model.reset();
    }

    public static notesResponsePart(response: EnquiryResponse, viewModel: EnquiryViewModel) {
        const groupedNoteDocuments = _.groupBy(response.noteDocuments, (noteDocument) => noteDocument.noteId);

        viewModel.notesViewModel.model.notes.replace(
            response.notes.map((n) => {
                const model = new NoteModel();

                model.fromResponse(n);

                return model;
            }),
        );

        // Process the note documents assign to the appropriate note.
        for (const noteViewModel of viewModel.notesViewModel.noteViewModels) {
            noteViewModel.filesViewModel.model.files.replace(
                Object.entries(groupedNoteDocuments)
                    .find(([noteId, noteDocuments]) => noteId === noteViewModel.model.id)?.[1]
                    .map((nd) => {
                        const model = new FileModel();

                        model.fromResponse(nd);

                        return model;
                    }) ?? [],
            );
        }
    }

    public static remainingResponsePart(response: EnquiryResponse, viewModel: EnquiryViewModel) {
        viewModel.model.fromResponse(response);
    }

    // #endregion Response Part Mappings to ViewModel
}

class CustomerResponse {
    id: string = "";
    contactTypeId: string = "";
    contactTitleId: string | null = null;

    businessName: string | null = null;
    firstName: string | null = null;
    lastName: string | null = null;

    public contactNumber1: string | null = null;
    public contactNumber2: string | null = null;
    public emailAddress: string | null = null;

    public address1: string = "";
    public address2: string | null = null;
    public address3: string | null = null;
    public city: string = "";
    public postcode: string = "";

    public isDeleted: boolean = false;
}

class CustomerContactResponse {
    id: string = "";
    customerId: string = "";
    contactTitleId: string | null = null;

    firstName: string = "";
    lastName: string = "";

    public contactNumber1: string | null = null;
    public contactNumber2: string | null = null;
    public emailAddress: string | null = null;

    public address1: string | null = null;
    public address2: string | null = null;
    public address3: string | null = null;
    public city: string | null = null;
    public postcode: string | null = null;

    public isDeleted: boolean = false;
}

class ProjectResponse {
    id: string = "";
    title: string = "";
    projectTypeId: string = "";
}

class NoteResponse {
    id: string = "";
    noteTypeId: string = "";
    noteDate: Date | null = null;
    noteTime: string = "";
    noteDetail: string = "";
    createdDate: Date | null = null;
    createdByUserId: string = "";
    createdByUserFirstName: string = "";
    createdByUserLastName: string = "";
    lastUpdatedDate: Date | null = null;
    lastUpdatedByUserId: string = "";
    lastUpdatedByUserFirstName: string | null = null;
    lastUpdatedByUserLastName: string | null = null;
}

class NoteDocumentResponse {
    id: string = "";
    noteId: string = "";
    fileName: string = "";
    fileSizeBytes: number = 0;
    mimeType: string = "";
    documentUrl: string = "";
}

class EnquiryDocumentResponse {
    id: string = "";
    fileName: string = "";
    fileSizeBytes: number = 0;
    mimeType: string = "";
    documentUrl: string = "";
}

class UserResponse {
    id: string = "";
    firstName: string = "";
    lastName: string = "";
    thumbnailDocumentUrl: string = "";
}
