import { runInAction } from "mobx";
import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { DashboardViewModel } from "../../DashboardViewModel";

class Response {
    public numberOfPendingAndApprovedQuotes: number | null = null;
    public numberOfUpdatedProjectTaskAssignments: number | null = null;
    public numberOfInProgressProjectTaskAssignments: number | null = null;
    public numberOfCompleteProjectTaskAssignments: number | null = null;
    public numberOfPendingLeaveRequests: number | null = null;
}

export class GetDashboardSummaryEndpoint extends EndpointWithoutRequest<Response> {
    private viewModel: DashboardViewModel;

    constructor(viewModel: DashboardViewModel) {
        super();
        this.viewModel = viewModel;
        this.verb(Http.Get);
        this.path(AppUrls.Server.Dashboard.Admin.GetSummary);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            this.viewModel.model.numberOfPendingAndApprovedQuotes = response.numberOfPendingAndApprovedQuotes;
            this.viewModel.model.numberOfUpdatedProjectTaskAssignments = response.numberOfUpdatedProjectTaskAssignments;
            this.viewModel.model.numberOfInProgressProjectTaskAssignments = response.numberOfInProgressProjectTaskAssignments;
            this.viewModel.model.numberOfCompleteProjectTaskAssignments = response.numberOfCompleteProjectTaskAssignments;
            this.viewModel.model.numberOfPendingLeaveRequests = response.numberOfPendingLeaveRequests;
        });
    }
}
