//libraries
import { action, computed, IObservableArray, makeObservable, observable, runInAction } from "mobx";
import moment from "moment";
//app
import { GlobalHistory } from "index";
import { AppUrls } from "AppUrls";
import { APIClient, formatDate, RelayCommand, startOfDay } from "Application";
import { ViewModelBase } from "@shoothill/core";
import { WeeklyUpdatesModel } from "./WeeklyUpdatesModel";
import { WeeklyUpdateItemViewModel } from "./WeeklyUpdateItem/WeeklyUpdateItemViewModel";
import { GETProjectUpdatesWithRelatedLiteByGivenDatesAndProjectLeadIdEndpoint } from "./Endpoints/GETProjectUpdatesWithRelatedLiteByGivenDatesAndProjectLeadId";

export class WeeklyUpdatesViewModel extends ViewModelBase<WeeklyUpdatesModel> {
    public apiClient = new APIClient();
    public locationPathName: string = "";
    public weeklyUpdateItemViewModels: IObservableArray<WeeklyUpdateItemViewModel> = observable<WeeklyUpdateItemViewModel>([]);
    constructor(model = new WeeklyUpdatesModel()) {
        super(model, false);
        makeObservable(this, {});
        this.model.programmingWeekStartDate = moment(new Date()).startOf("isoWeek").toDate();

        this.apiClient.sendAsync(new GETProjectUpdatesWithRelatedLiteByGivenDatesAndProjectLeadIdEndpoint(this));
    }
    public updateProgrammingWeekStartDateCommand = new RelayCommand(
        (date: Date | undefined) => {},
        () => {
            return false;
        },
    );
    public updatePreviousProgrammingWeekDateCommand = new RelayCommand(() => {
        this.model.programmingWeekStartDate = startOfDay(moment(this.model.programmingWeekStartDate).add(-1, "week").toDate()) as Date;
        this.apiClient.sendAsync(new GETProjectUpdatesWithRelatedLiteByGivenDatesAndProjectLeadIdEndpoint(this));
    });

    public updateNextProgrammingWeekDateCommand = new RelayCommand(() => {
        this.model.programmingWeekStartDate = startOfDay(moment(this.model.programmingWeekStartDate).add(1, "week").toDate()) as Date;
        this.apiClient.sendAsync(new GETProjectUpdatesWithRelatedLiteByGivenDatesAndProjectLeadIdEndpoint(this));
    });

    public resetServerErrorCommand = new RelayCommand(() => {
        this.apiClient.reset();
    });
}
