import { observer } from "mobx-react-lite";
import { Container } from "./ReadOnlyServiceGroupView.styles";
import { ServiceGroupViewModel } from "../ServiceGroupViewModel";
import {
    AccordionButton,
    addEditButtonStyleOverride,
    ArrowButtonHeader,
    arrowButtonStyleOverride,
    ArrowDown,
    ArrowUp,
    buttonResetStyles,
    CloseButton,
    removeCancelButtonStyleOverride,
} from "../ServiceGroup.styles";
import { ThemedButton, ThemedText } from "Application";
import { EditSVG } from "Assets/EditSVG";

export const ReadOnlyServiceGroupView: React.FC<{ viewModel: ServiceGroupViewModel }> = observer(({ viewModel }) => {
    return (
        <Container>
            <ArrowButtonHeader>
                <ThemedButton
                    styles={arrowButtonStyleOverride}
                    command={viewModel.incrementServiceGroupPositionCommand}
                    icon={<ArrowUp isDisabled={viewModel.isFirstServiceGroup} />}
                />
                <ThemedButton
                    styles={arrowButtonStyleOverride}
                    command={viewModel.decrementServiceGroupPositionCommand}
                    icon={<ArrowDown isDisabled={viewModel.isLastServiceGroup} />}
                />
            </ArrowButtonHeader>
            <ThemedButton
                styles={buttonResetStyles}
                command={viewModel.toggleIsServicesListExpandedCommand}
                icon={<AccordionButton className={viewModel.getValue<boolean>("isServicesListExpanded") ? "expanded" : ""} />}
            />
            <ThemedText fontStyle={"expandable"}>{viewModel.getValue("name")}</ThemedText>
            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                <ThemedButton styles={addEditButtonStyleOverride} command={viewModel.beginUpdateServiceGroupCommand} icon={<EditSVG />} marginRight={15} />
                <ThemedButton styles={removeCancelButtonStyleOverride} command={viewModel.beginDeleteServiceGroupCommand} icon={<CloseButton />} />
            </div>
        </Container>
    );
});
