import { Link } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { AutoGrid, ThemedButton, ThemedEditText, ThemedText, Show, Else } from "../../../../Application";
import { ThemedLoader } from "../../../../Styles/ThemedPrimitives/Style1/ThemedLoader";
import { LoginHomeViewModel } from "../../LoginHomeViewModel";
import { ForgotPasswordViewModel } from "./ForgotPasswordViewModel";
import { useParams } from "react-router";
import { Stack, IStackTokens } from "@fluentui/react";
import { MessageBarError } from "Styles/ThemedPrimitives/Style1/MessageBarError";
import { Box } from "@mui/system";
/**
 * Login page view.
 */
export const ForgotPasswordView = observer(({ loginHomeViewModel }: { loginHomeViewModel: LoginHomeViewModel }) => {
    // #region Code Behind
    const [viewModel] = useState(() => new ForgotPasswordViewModel());
    let params = useParams();
    let token = params.token || null;

    useEffect(() => {
        if (token) {
            viewModel.showResetPasswordCommand.execute(token);
        }
    }, [token]);
    // #endregion Code Behind
    const themedMediumStackTokens: IStackTokens = {
        childrenGap: "m",
        padding: "30px 0 0",
    };
    const renderForm = () => (
        <>
            <AutoGrid alignContent={"center"} dc="1fr" marginTop={"46px"} rowGap={"0"}>
                <ThemedText fontStyle={"h3"} mb={"15px"}>
                    Forgot your password?
                </ThemedText>
                <Show if={!viewModel.showSuccess}>
                    <ThemedText fontStyle={"h5"} mb={"16px"}>
                        Tell us your email address, and we'll send you an email to reset your details.
                    </ThemedText>
                    <ThemedEditText
                        displayName="Login"
                        labelColor="secondary"
                        placeholder="email address"
                        command={viewModel.updateEmailAddressCommand}
                        value={() => viewModel.getValue("emailAddress")}
                        validationMessage={() => viewModel.getError("emailAddress")}
                    />
                    <Stack horizontal tokens={themedMediumStackTokens}>
                        <ThemedButton displayName="Back To Login" command={loginHomeViewModel.navigateToSignInCommand} styles={{ root: { paddingLeft: 16, paddingRight: 16 } }} />
                        <ThemedButton displayName="Continue" command={viewModel.forgotPasswordCommand} paletteColor="primary" type="submit" />
                    </Stack>
                    <Else>
                        <ThemedText fontStyle="title">We have sent you an email to reset your password.</ThemedText>
                    </Else>
                </Show>
                <div className="error-login">{viewModel.apiClient.HaveValidationMessage && <MessageBarError errorMessage={viewModel.apiClient.ValidationMessage} />}</div>
            </AutoGrid>
        </>
    );

    const renderResetPassword = () => {
        return (
            <AutoGrid alignContent={"center"} dc="1fr" rowGap={"0"} marginTop={"46px"}>
                <Show if={!viewModel.resetPasswordViewModel.showSuccessMessage}>
                    <ThemedText fontStyle={"h3"}>Create a new password</ThemedText>
                    <ThemedText fontStyle={"h5"} mt={"15px"} mb={"22px"}>
                        Please add your new password below, makes sure it's at least eight characters and contains at least one uppercase letter,a number and a symbol.
                    </ThemedText>
                    <ThemedEditText
                        displayName={"New Password"}
                        command={viewModel.resetPasswordViewModel.updateNewPasswordCommand}
                        labelColor="secondary"
                        placeholder="Your new password"
                        value={() => viewModel.resetPasswordViewModel.getValue("newPassword")}
                        validationMessage={() => viewModel.resetPasswordViewModel.getError("newPassword")}
                        type={"password"}
                    />
                    <Box mt={"25px"}>
                        <ThemedEditText
                            displayName={"Confirm New Password"}
                            command={viewModel.resetPasswordViewModel.updateConfirmPasswordCommand}
                            value={() => viewModel.resetPasswordViewModel.getValue("confirmPassword")}
                            labelColor="secondary"
                            placeholder="Confirm your new password"
                            validationMessage={() => viewModel.resetPasswordViewModel.getError("confirmPassword")}
                            type={"password"}
                        />
                    </Box>

                    <Stack horizontal tokens={themedMediumStackTokens} style={{ justifyContent: "space-between" }}>
                        <ThemedButton displayName="Back To Login" command={loginHomeViewModel.navigateToSignInCommand} styles={{ root: { paddingLeft: 16, paddingRight: 16 } }} />
                        <ThemedButton
                            displayName="Continue"
                            command={viewModel.resetPasswordViewModel.resetPasswordCommand}
                            paletteColor="primary"
                            type="submit"
                            styles={{ root: { marginLeft: 25 } }}
                        />
                    </Stack>
                    <div className="error-login">
                        {viewModel.resetPasswordViewModel.apiClient.HaveValidationMessage && (
                            <MessageBarError errorMessage={viewModel.resetPasswordViewModel.apiClient.ValidationMessage} />
                        )}
                    </div>
                    <Else>
                        <ThemedText fontStyle={"h4"}>You have successfully reset your password</ThemedText>
                        <ThemedText fontStyle={"h4"}>You can now go back to the login page</ThemedText>
                        <Link onClick={() => loginHomeViewModel.navigateToSignInCommand.execute()}>
                            <ThemedText fontStyle={"h7"}>Back to sign in</ThemedText>
                        </Link>
                    </Else>
                </Show>
            </AutoGrid>
        );
    };

    const renderBusy = () => <ThemedLoader isOpen={viewModel.apiClient.IsBusy} />;

    return (
        <React.Fragment>
            <Show if={!viewModel.showResetPassword}>
                {renderForm()}
                {renderBusy()}
                <Else>{renderResetPassword()}</Else>
            </Show>
        </React.Fragment>
    );
});
