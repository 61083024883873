import { ViewModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

import { ProjectRowModel } from "./ProjectRowModel";
import { DateViewModel } from "../../Date/DateViewModel";
import { ICommand, RelayCommand } from "Application";
import { AppUrls } from "AppUrls";

export class ProjectRowViewModel extends ViewModelBase<ProjectRowModel> {
    public dateViewModels = observable<DateViewModel>([]);
    constructor(model: ProjectRowModel = new ProjectRowModel()) {
        super(model, false);

        makeObservable(this, {});
    }

    public get labelCellColors() {
        const colors = ["#FFF", "#E1E1E1"];
        return colors;
    }

    public getLabelColor(index: number) {
        const rest = index % 2;
        return rest == 0 ? this.labelCellColors[1] : this.labelCellColors[0];
    }

    public get displayName() {
        return this.model.title;
    }

    public get displayReference() {
        return this.model.reference;
    }

    public navigateToProjectDetailsPageCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.Projects.Details.replace(":id", this.model.id));
    });
}
