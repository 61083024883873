import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { CustomerContactItemModel } from "./CustomerContactItemModel";
import { CustomerViewModel } from "./CustomerViewModel";

class Response {
    id: string = "";
    contactTypeId: string = "";
    customerId: string = "";

    firstName: string | null = null;
    lastName: string | null = null;

    public contactNumber1: string = "";
    public contactNumber2: string = "";
    public emailAddress: string = "";

    public address1: string = "";
    public address2: string | null = null;
    public address3: string | null = null;
    public city: string = "";
    public postCode: string = "";
}

export class GETCustomerContactsByCustomerIdEndpoint extends EndpointWithoutRequest<Response> {
    private readonly viewModel: CustomerViewModel;

    constructor(customerId: string, viewModel: CustomerViewModel) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.Customers.GetCustomerContactsLiteByCustomerId.replace(":id", customerId));
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(responses: Response[]): Promise<any> {
        Logger.logInformation("Response", responses);

        responses.forEach((response: Response) => {
            const model = new CustomerContactItemModel();

            model.fromResponse(response);

            this.viewModel.customerContacts.push(model);
        });
    }
}
