import { runInAction } from "mobx";
import moment from "moment";

import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { LeaveRowViewModel } from "Views/Shared/WorkCalendar/Components/Rows/LeaveRow/LeaveRowViewModel";
import { BankHolidayEventModel } from "Views/Shared/WorkCalendar/Components/Date/Components/BankHolidayEvent/BankHolidayEventModel";
import { BankHolidayEventViewModel } from "Views/Shared/WorkCalendar/Components/Date/Components/BankHolidayEvent/BankHolidayEventViewModel";
import { EffortEventModel } from "Views/Shared/WorkCalendar/Components/Date/Components/EffortEvent/EffortEventModel";
import { EffortEventViewModel } from "Views/Shared/WorkCalendar/Components/Date/Components/EffortEvent/EffortEventViewModel";
import { LeaveRequestEventModel } from "Views/Shared/WorkCalendar/Components/Date/Components/LeaveRequestEvent/LeaveRequestEventModel";
import { LeaveRequestEventViewModel } from "Views/Shared/WorkCalendar/Components/Date/Components/LeaveRequestEvent/LeaveRequestEventViewModel";
import { ProjectRowViewModel } from "Views/Shared/WorkCalendar/Components/Rows/ProjectRow/ProjectRowViewModel";
import { ProjectTaskAssignmentEventModel } from "Views/Shared/WorkCalendar/Components/Date/Components/ProjectTaskAssignmentEvent/ProjectTaskAssignmentEventModel";
import { ProjectTaskAssignmentEventViewModel } from "Views/Shared/WorkCalendar/Components/Date/Components/ProjectTaskAssignmentEvent/ProjectTaskAssignmentEventViewModel";
import { ProjectTaskAssignmentGroupEventModel } from "Views/Shared/WorkCalendar/Components/Date/Components/ProjectTaskAssignmentGroupEvent/ProjectTaskAssignmentGroupEventModel";
import { ProjectTaskAssignmentGroupEventViewModel } from "Views/Shared/WorkCalendar/Components/Date/Components/ProjectTaskAssignmentGroupEvent/ProjectTaskAssignmentGroupViewModel";
import { StaffDetailsModel } from "../Details/StaffDetailsModel";
import { StaffDetailsViewModel } from "../Details/StaffDetailsViewModel";
import { StaffViewModel } from "../Table/StaffViewModel";
import { StaffModel } from "../Table/StaffModel";

class Request {
    public programmingWeekStartDate: Date | null = null;
    public userId: string = "";
}

class Response {
    public projects: ProjectResponse[] = [];
    public projectTaskGroups: ProjectTaskGroupResponse[] = [];
    public projectTasks: ProjectTaskResponse[] = [];
    public projectTaskAssignments: ProjectTaskAssignmentAndHalfDayResponse[] = [];

    public assignedTaskGroups: ProjectTaskAssignmentGroupResponse[] = [];
    public assignedTaskGroupItems: ProjectTaskAssignmentGroupItemResponse[] = [];

    public bankHolidays: BankHolidayResponse[] = [];
    public chargeableEfforts: ChargeableEffortResponse[] = [];
    public leaveRequests: LeaveRequestAndHalfDayResponse[] = [];

    public projectTaskAssignmentsWithRelated: ProjectTaskAssignmentResponse[] = [];
}

class ProjectResponse {
    public id: string = "";
    public reference: string = "";
    public title: string = "";
}

class ProjectTaskGroupResponse {
    public id: string = "";
    public projectId: string = "";
    public name: string = "";
}

class ProjectTaskResponse {
    public id: string = "";
    public projectTaskGroupId: string = "";
    public name: string = "";
}

class ProjectTaskAssignmentAndHalfDayResponse {
    public projectTaskAssignment: ProjectTaskAssignmentResponse | null = null;
    public projectTaskAssignmentHalfDays: HalfDayResponse[] = [];
}

class ProjectTaskAssignmentResponse {
    public id: string = "";
    public projectTaskId: string = "";
    public projectTaskAssignmentStatusId = "";
    public projectTaskAssignmentNote = "";
}

class ProjectTaskAssignmentGroupResponse {
    public id: string = "";
    public name: string = "";
    public note: string = "";
    public projectId: string = "";
    public userId: string = "";

    public groupStatusName: string = "";
    public groupStatusType: string = "";
    public groupStatusForegroundColor: string = "";
    public groupStatusBackgroundColor: string = "";
}

class ProjectTaskAssignmentGroupItemResponse {
    public projectTaskAssignmentGroupId: string = "";
    public projectTaskAssignmentId: string = "";
}

class BankHolidayResponse {
    public date: string | null = null;
    public title: string | null = null;
}

class ChargeableEffortResponse {
    public date: string | null = null;
    public availableEffort: number | null = null;
    public programmedEffort: number | null = null;
}

class LeaveRequestAndHalfDayResponse {
    public leaveRequest: LeaveRequestResponse | null = null;
    public leaveRequestHalfDays: HalfDayResponse[] = [];
}

class LeaveRequestResponse {
    public id: string | null = null;

    // Value Objects
    public leaveType: LeaveTypeResponse | null = null;
    public leaveStatusType: LeaveStatusTypeResponse | null = null;
    public fromDate: RequestDateResponse | null = null;
    public toDate: RequestDateResponse | null = null;

    // User Request
    public requestUserId: string | null = null;
    public requesteDate: string | null = null;
    public requestReason: string | null = null;

    // User Response
    public responseUserId: string | null = null;
    public responseDate: string | null = null;
    public responseReason: string | null = null;

    // Additonal
    public numberOfDays: number | null = null;
    public originalLeaveRequestId: string | null = null;
}

class LeaveTypeResponse {
    public id: string | null = null;
    public name: string | null = null;
    public type: string | null = null;
    public ordinal: number | null = null;
    public isDeleted: boolean | null = null;
    public foregroundColor: string | null = null;
    public backgroundColor: string | null = null;
    public isTransactionRequest: boolean | null = null;
    public isTransactionCancelRequest: boolean | null = null;
}

class LeaveStatusTypeResponse {
    public id: string | null = null;
    public name: string | null = null;
    public type: string | null = null;
    public ordinal: number | null = null;
    public isDeleted: boolean | null = null;
    public foregroundColor: string | null = null;
    public backgroundColor: string | null = null;
}

class RequestDateResponse {
    public date: string | null = null;
    public leaveDayType: LeaveDayTypeResponse | null = null;
}

class LeaveDayTypeResponse {
    public id: string | null = null;
    public name: string | null = null;
    public type: string | null = null;
    public ordinal: number | null = null;
    public isStartDay: boolean | null = null;
}

class HalfDayResponse {
    public date: string | null = null;
    public isAfternoon: boolean = false;
}

export class GETProjectTaskAssignmentsWithRelatedLiteByGivenDatesAndUserIdEndpoint extends Endpoint<any, any> {
    private viewModel: StaffViewModel | StaffDetailsViewModel;

    constructor(viewModel: StaffViewModel | StaffDetailsViewModel) {
        super();
        this.viewModel = viewModel;

        this.verb(Http.Post);
        this.path(AppUrls.Server.Resources.GetAllProjectTaskAssignmentsWithRelatedLiteByGivenDatesAndUserId);
    }

    public async HandleRequestAsync(): Promise<any> {
        const request = new Request();

        if (this.viewModel instanceof StaffViewModel) {
            request.programmingWeekStartDate = this.viewModel.model.workCalendarStartDayFilter;
            request.userId = this.viewModel.model.workCalendarUserId!;
        }

        if (this.viewModel instanceof StaffDetailsViewModel) {
            request.programmingWeekStartDate = this.viewModel.model.filterStartDate;
            request.userId = this.viewModel.model.userId;
        }

        return await Promise.resolve(request);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            if (this.viewModel.workCalendarViewModel) {
                this.processChargeableEffortResponses(response);
                this.processLeaveResponses(response);
                this.processProjectsResponses(response);
            }
        });
    }

    private processChargeableEffortResponses = (response: Response) => {
        const headerViewModelS = this.viewModel.workCalendarViewModel!.createHeaderDateViewModels();

        // Chargeable effort.
        response.chargeableEfforts.forEach((item: ChargeableEffortResponse) => {
            const dateViewModel = headerViewModelS.find((dateViewModel) => {
                const bankHolidayDate = new Date(item.date!);
                const dateViewModelDate = dateViewModel.date;

                return bankHolidayDate.getTime() === dateViewModelDate.getTime();
            });

            if (dateViewModel) {
                const model = new EffortEventModel(new Date(item.date!), item.programmedEffort!, item.availableEffort!);

                dateViewModel.eventViewModel = new EffortEventViewModel(model);
            }
        });

        this.viewModel.workCalendarViewModel?.headerDateViewModels.replace(headerViewModelS);
    };

    private processLeaveResponses = (response: Response) => {
        const leaveRowViewModel = new LeaveRowViewModel();

        leaveRowViewModel.dateViewModels.replace(this.viewModel.workCalendarViewModel!.createLeaveDateViewModels());

        // Bank Holidays.
        response.bankHolidays.forEach((item: BankHolidayResponse) => {
            const dateViewModel = leaveRowViewModel.dateViewModels.find((dateViewModel) => {
                const bankHolidayDate = new Date(item.date!);
                const dateViewModelDate = dateViewModel.date;

                return bankHolidayDate.getTime() === dateViewModelDate.getTime();
            });

            if (dateViewModel) {
                const model = new BankHolidayEventModel();

                model.fromResponse(item);

                dateViewModel.model.numberOfDays = 1;
                dateViewModel.eventViewModel = new BankHolidayEventViewModel(model);
            }
        });

        // Leave Requests.
        response.leaveRequests.forEach((item: LeaveRequestAndHalfDayResponse) => {
            // A leave request should have at least 1 half day item available.
            // We are dependant on the API providing the half days in date
            // AM/PM order.
            const earliestHalfDay = item.leaveRequestHalfDays[0];
            const dateViewModels = leaveRowViewModel.dateViewModels.filter((dateViewModel) => {
                const leaveRequestDate = new Date(earliestHalfDay.date!);
                const dateViewModelDate = dateViewModel.date;

                return leaveRequestDate.getTime() === dateViewModelDate.getTime();
            });

            // We should have 2 matching viewmodels. The one we assign the event
            // to depends on whether the half day is in the morning or afternoon.
            const dateViewModelIndex = earliestHalfDay.isAfternoon ? 1 : 0;
            const dateViewModel = dateViewModels[dateViewModelIndex];

            if (dateViewModel) {
                const model = new LeaveRequestEventModel();

                model.fromResponse(item);

                dateViewModel.model.numberOfDays = item.leaveRequestHalfDays.length / 2;
                dateViewModel.eventViewModel = new LeaveRequestEventViewModel(model);
            }
        });

        this.viewModel.workCalendarViewModel!.leaveRowViewModel = leaveRowViewModel;
    };

    private processProjectsResponses = (response: Response) => {
        const projectRowViewModels: ProjectRowViewModel[] = [];

        const standaloneProjectTaskAssignments = response.projectTaskAssignments.filter(
            (pta) => !response.assignedTaskGroupItems.some((atgi) => pta.projectTaskAssignment?.id === atgi.projectTaskAssignmentId!),
        );
        const groupedProjectTaskAssignments = response.projectTaskAssignments.filter((pta) =>
            response.assignedTaskGroupItems.some((atgi) => pta.projectTaskAssignment?.id === atgi.projectTaskAssignmentId!),
        );

        response.projects
            .sort((lhs, rhs) => (lhs.reference.substring(3) > rhs.reference.substring(3) ? 1 : -1))
            .forEach((pItem: ProjectResponse) => {
                const projectRowViewModel = new ProjectRowViewModel();

                projectRowViewModel.model.fromResponse(pItem);

                // Standalone project task assignments.
                const projectTaskGroups = response.projectTaskGroups.filter((ptgItem) => ptgItem.projectId === pItem.id);

                projectTaskGroups.forEach((projectTaskGroup) => {
                    const projectTasks = response.projectTasks.filter((ptItem) => ptItem.projectTaskGroupId === projectTaskGroup.id);

                    projectTasks.forEach((projectTask) => {
                        const assignedTasks = standaloneProjectTaskAssignments.filter((ptaItem) => ptaItem.projectTaskAssignment!.projectTaskId === projectTask.id);

                        assignedTasks.forEach((assignedTask) => {
                            // Get a new set of dates for this assignment.
                            const dateViewModels = this.viewModel.workCalendarViewModel!.createProjectDateViewModels();

                            // An assignment should have at least 1 half day item available.
                            // We are dependant on the API providing the half days in date
                            // AM/PM order.
                            const earliestHalfDay = assignedTask.projectTaskAssignmentHalfDays[0];
                            const dateViewModel = dateViewModels.find((dateViewModel) => {
                                const projectTaskAssignmentStartDate = new Date(earliestHalfDay.date!);
                                const dateViewModelDate = dateViewModel.date;

                                return projectTaskAssignmentStartDate.getTime() === dateViewModelDate.getTime();
                            });

                            if (dateViewModel) {
                                const model = new ProjectTaskAssignmentEventModel();

                                model.id = assignedTask.projectTaskAssignment!.id;
                                model.taskTitle = projectTask.name;
                                model.taskGroupTitle = projectTaskGroup.name;
                                model.projectTaskAssignmentStatusId = assignedTask.projectTaskAssignment?.projectTaskAssignmentStatusId ?? "";
                                model.projectTaskAssignmentNote = assignedTask.projectTaskAssignment?.projectTaskAssignmentNote ?? "";
                                dateViewModel.model.numberOfDays = assignedTask.projectTaskAssignmentHalfDays.length / 2;
                                const viewModel = this.viewModel;
                                dateViewModel.eventViewModel = new ProjectTaskAssignmentEventViewModel(model, viewModel);
                            }

                            projectRowViewModel.dateViewModels.replace(projectRowViewModel.dateViewModels.concat(dateViewModels));
                        });
                    });
                });

                // Grouped project task assignments.
                const projectTaskAssignmentGroups = response.assignedTaskGroups.filter((atgItem) => atgItem.projectId == pItem.id);

                projectTaskAssignmentGroups.forEach((projectTaskAssignmentGroup) => {
                    const assignmentIds = response.assignedTaskGroupItems
                        .filter((atgi) => atgi.projectTaskAssignmentGroupId === projectTaskAssignmentGroup.id)
                        .map((item) => item.projectTaskAssignmentId);
                    const assignments = groupedProjectTaskAssignments.filter((pta) => assignmentIds.some((id) => id === pta.projectTaskAssignment?.id));

                    // Get a new set of dates for this assignment group.
                    const dateViewModels = this.viewModel.workCalendarViewModel!.createProjectDateViewModels();

                    const earliestHalfDay = assignments.reduce((previousAssignment, currentAssignment) =>
                        Date.parse(previousAssignment.projectTaskAssignmentHalfDays[0].date!) > Date.parse(currentAssignment.projectTaskAssignmentHalfDays[0].date!)
                            ? currentAssignment
                            : previousAssignment,
                    ).projectTaskAssignmentHalfDays[0];

                    const latestHalfDay = assignments
                        .reduce((previousAssignment, currentAssignment) =>
                            Date.parse(previousAssignment.projectTaskAssignmentHalfDays.slice(-1)[0].date!) <
                            Date.parse(currentAssignment.projectTaskAssignmentHalfDays.slice(-1)[0].date!)
                                ? currentAssignment
                                : previousAssignment,
                        )
                        .projectTaskAssignmentHalfDays.slice(-1)[0];

                    const dateViewModel = dateViewModels.find((dateViewModel) => {
                        const projectTaskAssignmentStartDate = new Date(earliestHalfDay.date!);
                        const dateViewModelDate = dateViewModel.date;

                        return projectTaskAssignmentStartDate.getTime() === dateViewModelDate.getTime();
                    });

                    if (dateViewModel) {
                        const model = new ProjectTaskAssignmentGroupEventModel();

                        model.id = projectTaskAssignmentGroup.id;
                        model.groupTitle = projectTaskAssignmentGroup.name ? projectTaskAssignmentGroup.name : "";
                        model.groupNote = projectTaskAssignmentGroup.note ? projectTaskAssignmentGroup.note : "";

                        model.groupStatusName = projectTaskAssignmentGroup.groupStatusName;
                        model.groupStatusType = projectTaskAssignmentGroup.groupStatusType;
                        model.groupStatusBackgroundColor = projectTaskAssignmentGroup.groupStatusBackgroundColor;
                        model.groupStatusForegroundColor = projectTaskAssignmentGroup.groupStatusForegroundColor;

                        dateViewModel.model.numberOfDays = moment(new Date(latestHalfDay.date!)).diff(moment(new Date(earliestHalfDay.date!)), "days") + 1;
                        dateViewModel.eventViewModel = new ProjectTaskAssignmentGroupEventViewModel(model, null);
                    }

                    projectRowViewModel.dateViewModels.replace(projectRowViewModel.dateViewModels.concat(dateViewModels));
                });

                projectRowViewModels.push(projectRowViewModel);
            });

        this.viewModel.workCalendarViewModel!.projectRowViewModels.replace(projectRowViewModels);
    };
}
