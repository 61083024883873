import { DetailsRow, IColumn, Panel, PanelType, PersonaSize } from "@fluentui/react";
import { isNullOrUndefined } from "@shoothill/core";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import { AutoGrid, Box, ThemedButton, ThemedComboBox, ThemedEditDate, ThemedEditText } from "Application";
import { formatDate, setPageTitle } from "Application/Utils";
import { SearchIcon } from "Assets/SearchIcon";
import { ThemedDataTable } from "Styles/ThemedPrimitives/Style1/ThemedDataTable";
import { ThemedPersona } from "Styles/ThemedPrimitives/Style1/ThemedPersona";
import { FormDottedSeparator, PanelStyles } from "Views/Shared/SharedComponents";
import { BilledTaskItemViewModel } from "./BilledTaskItemViewModel";
import { BilledTasksViewModel } from "./BilledTasksViewModel";
import { TaskView } from "./ConfigureSubViews/TaskView";
import { CustomSidePanel } from "Views/Shared/CustomSidePanel/CustomSidePanel";

const controlStyles = {
    root: {
        height: "39px",
        fontSize: "17px",
        borderColor: "#E1E1E1",
    },
};

export const BilledTasksView: React.FC = observer(() => {
    // #region Code Behind

    const [viewModel] = useState(() => new BilledTasksViewModel());

    useEffect(() => {
        setPageTitle("Billed tasks");
    }, []);

    /**
     * Handles updating the tables column sort settings in response to changes in the viewmodel.
     */
    useEffect(() => {
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter((currCol) => viewModel.getValue("sortKey") === currCol.key)[0];

        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSorted = true;
                currColumn.isSortedDescending = viewModel.getValue("sortDescending");
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = false;
            }
        });

        setColumns(newColumns);
    }, [viewModel.getValue("sortKey"), viewModel.getValue("sortDescending")]);

    /**
     * Gets a key for the row. Must be provided if sorting or filtering is enabled.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The row index.
     *
     * @returns A string key to identify the row.
     */
    const getRowKey = (item: BilledTaskItemViewModel, index?: number): string => {
        return item.KEY;
    };

    /**
     * Handles an onClick event for the table column header.
     *
     * @param ev Mouse event
     * @param column The column that has been clicked.
     */
    const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const currColumn: IColumn = columns.filter((currCol) => column.key === currCol.key)[0];
        const newSortKey = currColumn.key;
        const newSortDescending = currColumn.key === viewModel.getValue("sortKey") ? !currColumn.isSortedDescending : false;

        viewModel.updateSortCommand.execute(newSortKey, newSortDescending);
    };

    /**
     * Handler an onClick event for a table row.
     *
     * @param item The viewmodel data associated with the table row.
     */
    const onRowClick = (item: BilledTaskItemViewModel) => {
        viewModel.displayTaskCommand.execute(item.model.projectTaskId);
    };

    /**
     * Handles rendering the row. Normally we would not do this. However if
     * trying to display an overlay (alert, panel or modal), on dismissing
     * the overlay, onActiveItemChanged would be called again and redisplay the
     * overlay!
     *
     * @param props The row props of which item is the viewmodel.
     * @returns The table row.
     */
    const onRenderRow = (props: any) => {
        return <DetailsRow {...props} onClick={() => onRowClick(props.item)} />;
    };

    /**
     * Renders the project lead persona.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The index of the table row.
     * @param column The table column.
     *
     * @returns React element.
     */
    const onRenderProjectLeadName = (item: BilledTaskItemViewModel, index?: number, column?: IColumn) => {
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <ThemedPersona styles={{ primaryText: { fontSize: "10px" } }} displayName={item.projectLeadName} size={PersonaSize.size32} imageUrl={item.projectLeadDocumentUrl} />
            </div>
        );
    };

    /**
     * Renders the anticipated end date row data.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The index of the table row.
     * @param column The table column.
     *
     * @returns React element.
     */
    const onRenderBilledEndDate = (item: BilledTaskItemViewModel, index?: number, column?: IColumn) => {
        return <div>{!isNullOrUndefined(item.billedDate) ? formatDate(item.billedDate!) : ""}</div>;
    };

    const defaultTableColumns: IColumn[] = [
        {
            key: "reference",
            fieldName: "reference",
            name: "PROJECT",
            minWidth: 395,
            maxWidth: 660,
            isResizable: true,
            isSorted: true,
            onColumnClick: onColumnClick,
        },
        {
            key: "projectTaskName",
            fieldName: "projectTaskName",
            name: "TASK",
            minWidth: 395,
            maxWidth: 660,
            isResizable: true,
            isSorted: true,
            onColumnClick: onColumnClick,
        },
        {
            key: "billedEffort",
            fieldName: "billedEffort",
            name: "BILLED HOURS",
            minWidth: 120,
            maxWidth: 120,
            isResizable: true,
            isSorted: true,
            onColumnClick: onColumnClick,
        },
        {
            key: "projectLeadName",
            fieldName: "projectLeadName",
            name: "PROJECT LEAD",
            minWidth: 175,
            maxWidth: 200,
            isResizable: true,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: onRenderProjectLeadName,
        },
        {
            key: "billedDate",
            fieldName: "billedDate",
            name: "BILLED DATE",
            minWidth: 100,
            maxWidth: 100,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: onRenderBilledEndDate,
        },
    ];

    const [columns, setColumns] = useState<IColumn[]>(defaultTableColumns);

    // #endregion Code Behind

    const renderTaskPanel = () => {
        return (
            <CustomSidePanel
                isOpen={viewModel.canDisplayTask}
                onDismiss={() => {
                    viewModel.cancelTaskCommand.execute();
                }}
            >
                <TaskView viewModel={viewModel.taskViewModel!} />
            </CustomSidePanel>
        );
    };

    return (
        <Box>
            {/* Filter Bar */}
            <Box display={"flex"} justifyContent={"center"} alignItems={"space-between"} flexDirection="column">
                <AutoGrid m={0} columnGap="15px" width="75%" dc={"auto 205px 205px 205px"}>
                    <ThemedEditText
                        command={viewModel.updateFilterKeywordCommand}
                        placeholder={"Keyword search"}
                        prefix={<SearchIcon />}
                        styles={controlStyles}
                        value={() => viewModel.getValue("filterKeyword")}
                    />
                    <ThemedEditDate
                        command={viewModel.updateFilterStartDateCommand}
                        placeholder="Start date"
                        size="larger"
                        value={() => viewModel.getValue("filterStartDate")}
                        showLabel={false}
                    />
                    <ThemedEditDate
                        command={viewModel.updateFilterEndDateCommand}
                        placeholder="End date"
                        size="larger"
                        value={() => viewModel.getValue("filterEndDate")}
                        showLabel={false}
                    />
                    <ThemedButton
                        styles={{
                            root: {
                                height: "38px",
                            },
                        }}
                        paletteColor="primary"
                        command={viewModel.clearFiltersCommand}
                        size="larger"
                        displayName="Clear Filters"
                    />
                </AutoGrid>

                <FormDottedSeparator />
                <AutoGrid columnGap="15px" width="75%" dc={"1fr 1fr 1fr"}>
                    <ThemedComboBox
                        command={viewModel.updateFilterProjectCommand}
                        options={viewModel.projects}
                        placeholder="All statuses"
                        size="larger"
                        value={() => viewModel.getValue("filterProjectId")}
                    />
                    <ThemedComboBox
                        command={viewModel.updateFilterProjectTaskGroupCommand}
                        options={viewModel.taskGroups}
                        placeholder="All stages"
                        size="larger"
                        value={() => viewModel.getValue("filterProjectTaskGroupName")}
                    />
                    <ThemedComboBox
                        command={viewModel.updateFilterProjectLeadCommand}
                        options={viewModel.projectLeads}
                        placeholder="All leads"
                        size="larger"
                        value={() => viewModel.getValue("filterProjectLeadId")}
                    />
                </AutoGrid>
            </Box>
            <ThemedDataTable onRenderRow={onRenderRow} getKey={getRowKey} items={viewModel.filteredAndSortedTasks} columns={columns} />

            {/* Display task panel */}
            {renderTaskPanel()}
        </Box>
    );
});
