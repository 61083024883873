import { Modal } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { WorkCalendarViewModel } from "./WorkCalendarViewModel";
import { BodyView } from "./Components/Body/BodyView";
import { HeaderView } from "./Components/Header/HeaderView";

interface IProps {
    viewModel: WorkCalendarViewModel;
}

export const WorkCalendarView: React.FC<IProps> = observer((props) => {
    const viewModel = props.viewModel;

    return (
        <React.Fragment>
            <HeaderView viewModel={viewModel} />
            <BodyView viewModel={viewModel} />
        </React.Fragment>
    );
});
