import { ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

import { ICommand } from "Application";
import { ProjectAsCompleteModel } from "./ProjectAsCompleteModel";

export class ProjectAsCompleteViewModel extends ViewModelBase<ProjectAsCompleteModel> {
    public parentCancelCompleteProjectCommand: ICommand;
    public parentCompleteProjectCommand: ICommand;

    constructor(parentCancelCompleteProjectCommand: ICommand, parentCompleteProjectCommand: ICommand) {
        super(new ProjectAsCompleteModel());

        this.parentCancelCompleteProjectCommand = parentCancelCompleteProjectCommand;
        this.parentCompleteProjectCommand = parentCompleteProjectCommand;

        makeObservable(this, {});
    }

    public get displayMessage() {
        return "Are you sure you want to complete this project?";
    }
}
