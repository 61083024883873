import { RelayCommand } from "Application/Commands";
import { ThemedError } from "Styles/ThemedPrimitives/Style1/ThemedError";
import { ThemedLoader } from "Styles/ThemedPrimitives/Style1/ThemedLoader";
import { APIClient } from "./APIClient";

interface IProps {
    renderBusy: () => JSX.Element;
    renderError: () => JSX.Element;
}

export const APICommonComponents = (apiClient: APIClient) => {
    const resetServerErrorCommand = new RelayCommand(() => {
        apiClient.reset();
    });

    const renderBusy = () => <ThemedLoader isOpen={apiClient.IsBusy} />;

    const renderError = () => {
        const isOpen = apiClient.IsSubmitted && !apiClient.IsBusy && apiClient.HaveValidationMessage;

        return <ThemedError command={resetServerErrorCommand} isOpen={isOpen} errorMessage={apiClient.ValidationMessage} />;
    };

    const retVal: IProps = {} as IProps;

    retVal.renderBusy = renderBusy;
    retVal.renderError = renderError;

    return retVal;
};
