import { ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

import { RelayCommand, theme } from "Application";
import { MyWorkViewModel } from "Views/Dashboard/Staff/MyWork/MyWorkViewModel";
import { ProjectTaskAssignmentGroupEventModel } from "./ProjectTaskAssignmentGroupEventModel";

export class ProjectTaskAssignmentGroupEventViewModel extends ViewModelBase<ProjectTaskAssignmentGroupEventModel> {
    private myWorkViewModel: MyWorkViewModel | null;

    constructor(model: ProjectTaskAssignmentGroupEventModel, myWorkViewModel: MyWorkViewModel | null) {
        super(model, false);

        this.myWorkViewModel = myWorkViewModel;

        makeObservable(this, {});
    }

    // #region Properties

    public get displayName() {
        return this.model.groupTitle;
    }

    public get displayNote() {
        return this.model.groupNote;
    }

    public get displayStatusName() {
        return this.model.isReviewed ? "Reviewed" : this.model.groupStatusName;
    }

    public get backgroundColor() {
        return this.model.isReviewed ? theme.palette.quintenary.main : this.model.groupStatusForegroundColor;
    }

    // #endregion Properties

    // #region Commands

    public displayProjectTaskAssignmentGroupCommand = new RelayCommand(
        () => this.myWorkViewModel?.displayTaskGroupCommand.execute(this.model.id),
        () => this.myWorkViewModel !== null && this.myWorkViewModel.displayTaskGroupCommand.canExecute(),
    );

    // #endregion Commands
}
