import _ from "lodash";
import { isEmptyOrWhitespace } from "@shoothill/core";

import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { GETQuoteWithRelatedByIdEndpoint } from "./GETQuoteWithRelatedByIdEndpoint";
import { QuoteResponse } from "./Shared/QuoteResponse";
import { QuoteContainerModel } from "../QuoteContainerModel";
import { QuoteContainerViewModel } from "../QuoteContainerViewModel";

class Request {
    id: string | null = null;
}

class Response {
    id: string | null = null;
}

export class POSTDeleteQuoteEndpoint extends Endpoint<Request, Response> {
    private readonly viewModel: QuoteContainerViewModel;

    constructor(viewModel: QuoteContainerViewModel) {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Quotes.DeleteQuote);
        this.viewModel = viewModel;
    }

    public async HandleRequestAsync(model: QuoteContainerModel): Promise<any> {
        const request = new Request();

        request.id = model.quoteForm.id;

        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: QuoteResponse): Promise<any> {
        Logger.logInformation("Response", response);

        if (!isEmptyOrWhitespace(response.id)) {
            this.viewModel.apiClient.sendAsync(new GETQuoteWithRelatedByIdEndpoint(response.id!, this.viewModel));
        } else {
            this.viewModel.navigateToQuotesCommand.execute();
        }
    }
}
