import { isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { computed, makeObservable, observable, observe } from "mobx";

import { RelayCommand } from "Application";
import { FileModel } from "./FileModel";
import { FilesModel, FilesModelValidator } from "./FilesModel";
import { FileViewModel } from "./FileViewModel";

export class FilesViewModel extends ViewModelBase<FilesModel> {
    public fileViewModels = observable<FileViewModel>([]);

    constructor(fileModel: FilesModel = new FilesModel(), fileTypes = "", allowMultipleTYpes = true) {
        super(fileModel);

        this.setValidator(new FilesModelValidator());

        this.model.allowMultipleFiles = allowMultipleTYpes;
        this.model.fileTypes = fileTypes;

        makeObservable(this, {
            // Observables
            fileViewModels: observable,
        });
    }

    /**
     * Disposes any resources.
     */
    public dispose = (): void => {
        this.filesObserverDispose?.();
    };

    // #region Properties

    public get allowMultipleFiles() {
        return this.model.allowMultipleFiles;
    }

    public get fileTypes() {
        return this.model.fileTypes;
    }

    public get errorMessage() {
        if (!this.model.validMultipleFiles) {
            return `Only one file is allowed`;
        }

        if (!this.model.validFileTypes) {
            return `The type of one or more files is not allowed`;
        }

        if (!this.model.insideUploadLimit) {
            return `The total file size should be less than ${FilesModel.MAX_FILESIZE_MB}`;
        }

        return "";
    }

    // #endregion Properties

    // #region Commands

    /**
     * Command to add one or more files to the file collection.
     */
    public addCommand = new RelayCommand((files: FileList) => {
        if (files) {
            for (let fileIndex = 0; fileIndex < files.length; fileIndex++) {
                const fileModel = new FileModel();

                fileModel.file = files.item(fileIndex);
                fileModel.fileName = files.item(fileIndex)!.name;
                fileModel.fileSizeBytes = files.item(fileIndex)!.size;
                fileModel.mimeType = files.item(fileIndex)!.type;
                fileModel.documentUrl = "";

                // Guard against models that are folders. We do not have
                // code to support extract files from folders at the moment.
                // TODO: APM - Handle folders and application items, then
                // the guard can be removed.
                if (fileModel.fileSizeBytes > 0 && !isEmptyOrWhitespace(fileModel.mimeType)) {
                    this.model.files.push(fileModel);
                }
            }
        }
    });

    public removeCommand = new RelayCommand((file: FileModel) => {
        const fileToRemove = this.model.files.find((m) => m.KEY === file.KEY);

        if (fileToRemove) {
            this.model.files.remove(fileToRemove);

            // Store any removed files that have an id as knowledge of these
            // may be required by consumers of the component.
            if (!isEmptyOrWhitespace(fileToRemove.id)) {
                this.model.removedFiles.remove(fileToRemove);
            }
        }
    });

    // #endregion Commands

    // #region Supporting

    /**
     * An observer to listen to changes in the file model collection. Use this to create or remove
     * file viewmodels in response to changes in the file model collection.
     */
    private filesObserverDispose = observe(this.model.files, (fileChanges: any) => {
        for (const addedFile of fileChanges.added) {
            this.fileViewModels.push(new FileViewModel(addedFile, this.removeCommand));
        }

        for (const removedFile of fileChanges.removed) {
            const fileViewModelToRemove = this.fileViewModels.find((vm) => vm.model.KEY === removedFile.KEY);

            if (fileViewModelToRemove) {
                this.fileViewModels.remove(fileViewModelToRemove);
            }
        }
    });

    // #endregion Supporting
}
