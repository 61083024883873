import { isEmptyOrWhitespace, isNullOrUndefined, ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";
import moment from "moment";

import { BilledTaskItemModel } from "./BilledTaskItemModel";

export class BilledTaskItemViewModel extends ViewModelBase<BilledTaskItemModel> {
    constructor(model = new BilledTaskItemModel()) {
        super(model);
        makeObservable(this, {});
    }

    public get KEY() {
        return this.model.KEY;
    }

    public get reference() {
        return `${this.model.reference} ${this.model.title}`;
    }

    public get billedEffort() {
        return this.model.billedEffort;
    }

    public get projectTaskName() {
        return this.model.originReference ? `${this.model.originReference} ${this.model.projectTaskName}` : `${this.model.reference} ${this.model.projectTaskName}`;
    }

    public get projectLeadName() {
        return `${this.model.projectLeadFirstName} ${this.model.projectLeadLastName}`;
    }

    public get projectLeadDocumentUrl() {
        return this.model.projectLeadDocumentUrl;
    }

    public get billedDate() {
        return this.model.billedDate;
    }

    /**
     * Determines if the task can be displayed, given a set of filters.
     *
     * @param filterKeyword A general filter to cover a number of propoerties of the task.
     * @param filterStartDate A date filter.
     * @param filterEndDate A date filter.
     * @param filterProjectId A project filter.
     * @param filterProjectTaskGroupName A project group filter.
     * @param filterProjectLeadId A project lead filter.
     *
     * @returns true if the quote can be displayed otherwise false.
     */
    public filterPredicate = (
        filterKeyword: string,
        filterStartDate: Date | undefined,
        filterEndDate: Date | undefined,
        filterProjectId: string | null,
        filterProjectTaskGroupName: string | null,
        filterProjectLeadId: string | null,
    ): boolean => {
        let result = true;

        // Attempt filtering by keyword.
        if (result) {
            const lowerCaseFilterKeyword = filterKeyword.toLowerCase();
            switch (true) {
                case isEmptyOrWhitespace(filterKeyword):
                case this.model.reference.toLowerCase().includes(lowerCaseFilterKeyword):
                case this.model.title.toLowerCase().includes(lowerCaseFilterKeyword):
                case this.model.projectTaskName.toLowerCase().includes(lowerCaseFilterKeyword):
                    result = true;
                    break;

                default:
                    result = false;
                    break;
            }
        }

        // If the task has passed the keyword filter, attempt filtering by start date.
        if (result) {
            switch (true) {
                case isNullOrUndefined(filterStartDate):
                    result = true;
                    break;

                case isNullOrUndefined(this.model.billedDate):
                    result = false;
                    break;

                case moment(this.model.billedDate).startOf("day").isSameOrAfter(moment(filterStartDate)):
                    result = true;
                    break;

                default:
                    result = false;
                    break;
            }
        }

        // If the task has passed the start date filter, attempt filtering by end date.
        if (result) {
            switch (true) {
                case isNullOrUndefined(filterEndDate):
                    result = true;
                    break;

                case isNullOrUndefined(this.model.billedDate):
                    result = false;
                    break;

                case moment(this.model.billedDate).startOf("day").isSameOrBefore(moment(filterEndDate)):
                    result = true;
                    break;

                default:
                    result = false;
                    break;
            }
        }

        // If the task has passed the project end date filter, attempt filtering by project.
        if (result) {
            switch (true) {
                case isEmptyOrWhitespace(filterProjectId):
                case this.model.id === filterProjectId:
                    result = true;
                    break;

                default:
                    result = false;
                    break;
            }
        }

        // If the task has passed the project end date filter, attempt filtering by project group.
        if (result) {
            switch (true) {
                case isEmptyOrWhitespace(filterProjectTaskGroupName):
                case this.model.projectTaskGroupName === filterProjectTaskGroupName:
                    result = true;
                    break;

                default:
                    result = false;
                    break;
            }
        }

        // If the task has passed the project group filter, attempt filtering by project lead.
        if (result) {
            switch (true) {
                case isEmptyOrWhitespace(filterProjectLeadId):
                case this.model.projectLeadId === filterProjectLeadId:
                    result = true;
                    break;

                default:
                    result = false;
                    break;
            }
        }

        return result;
    };
}
