import _ from "lodash";

import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { ProjectsViewModel } from "../ProjectsViewModel";
import { ProjectsModel } from "../ProjectsModel";

class Request {
    id: string = "";
}

class Response {
    id: string = "";
}

export class POSTSaveQuoteAsProjectEndpoint extends Endpoint<Request, Response> {
    private readonly viewModel: ProjectsViewModel;

    constructor(viewModel: ProjectsViewModel) {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Projects.SaveQuoteAsProject);
        this.viewModel = viewModel;
    }

    public async HandleRequestAsync(model: ProjectsModel): Promise<any> {
        const request = new Request();

        request.id = this.viewModel.newProjectViewModel?.model.quoteId!;

        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        this.viewModel.navigateToProjectByIdCommand.execute(response.id);
        this.viewModel.newProjectViewModel = null;
    }
}
