import styled from "@emotion/styled";
import React from "react";
import { observer } from "mobx-react-lite";

import { Box, theme } from "Application";
import { DateViewModel } from "./DateViewModel";
import { BankHolidayEventViewModel } from "./Components/BankHolidayEvent/BankHolidayEventViewModel";
import { BankHolidayEventView } from "./Components/BankHolidayEvent/BankHolidayEventView";
import { HalfDaysEventViewModel } from "./Components/HalfDaysEvent/HalfDaysEventViewModel";
import { HalfDaysEventView } from "./Components/HalfDaysEvent/HalfDaysEventView";
import { LeaveRequestEventViewModel } from "./Components/LeaveRequestEvent/LeaveRequestEventViewModel";
import { LeaveRequestEventView } from "./Components/LeaveRequestEvent/LeaveRequestEventView";
import { NoEventViewModel } from "./Components/NoEvent/NoEventViewModel";
import { NoEventView } from "./Components/NoEvent/NoEventView";
import { WeekendEventViewModel } from "./Components/WeekendEvent/WeekendEventViewModel";
import { WeekendEventView } from "./Components/WeekendEvent/WeekendEventView";

interface IProps {
    viewModel: DateViewModel;
}

const CellContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    flex: 1;
    font-size: 12px;
    outline: 1px solid #e1e1e1;
    min-height: 90px;
`;

const CellDate = styled(Box)`
    background-color: #f3f3f3;
    color: ${theme.palette.defaultTr.text};
    font-weight: bold;
    padding: 4px 12px;
`;

const CellContent = styled(Box)`
    display: flex;
    flex: 1;
`;

export const DateView: React.FC<IProps> = observer((props) => {
    const viewModel = props.viewModel;

    const renderEventCell = () => {
        switch (true) {
            case viewModel.eventViewModel instanceof BankHolidayEventViewModel:
                return <BankHolidayEventView viewModel={viewModel.eventViewModel as BankHolidayEventViewModel} />;

            case viewModel.eventViewModel instanceof HalfDaysEventViewModel:
                return <HalfDaysEventView viewModel={viewModel.eventViewModel as HalfDaysEventViewModel} />;

            case viewModel.eventViewModel instanceof LeaveRequestEventViewModel:
                return <LeaveRequestEventView viewModel={viewModel.eventViewModel as LeaveRequestEventViewModel} />;

            case viewModel.eventViewModel instanceof NoEventViewModel:
                return <NoEventView viewModel={viewModel.eventViewModel as NoEventViewModel} />;

            case viewModel.eventViewModel instanceof WeekendEventViewModel:
                return <WeekendEventView viewModel={viewModel.eventViewModel as WeekendEventViewModel} />;
        }
    };

    return (
        <CellContainer>
            <CellDate>{viewModel.formattedDate}</CellDate>
            <CellContent>{renderEventCell()}</CellContent>
        </CellContainer>
    );
});
