import { action, computed, makeObservable, observable } from "mobx";
import { singleton } from "tsyringe";

@singleton()
export class AutoSaveStore {
    public _autoSave = false;

    constructor() {
        makeObservable(this, {
            _autoSave: observable,
            getAutoSave: computed,
            setAutoSave: action,
        });
    }

    public get getAutoSave(): boolean {
        return this._autoSave;
    }

    public setAutoSave(value: boolean) {
        this._autoSave = value;
    }
}
