import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

export class MyProjectsModel extends ModelBase<MyProjectsModel> {
    // Filtering
    public filterKeyword: string = "";
    public filterProjectStatusId: string | null = null;
    public filterProjectLeadId: string | null = null;
    public filterSeniorAssociateId: string | null = null;
    public filterFromDate: Date | undefined = undefined;
    public filterToDate: Date | undefined = undefined;

    // Sorting
    public sortKey = "anticipatedDate"; // A property defined in the table column definitions.
    public sortDescending = false;

    constructor() {
        super();
        makeObservable(this, {
            // Observables
            filterKeyword: observable,
            filterProjectStatusId: observable,
            filterSeniorAssociateId: observable,
            filterProjectLeadId: observable,
            filterFromDate: observable,
            filterToDate: observable,
            sortKey: observable,
            sortDescending: observable,
        });
    }
}
