import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { nanoid } from "nanoid";

import { Validator } from "Application/Validation";

export class WeeklyUpdateFormModel extends ModelBase<WeeklyUpdateFormModel> {
    // Note: some observables are predefined in the ModelBase class.
    // If any errors arise, check there to see if the property exists
    // and if so, delete it from here.
    // public createdDate: Date | null = null;
    // public updatedDate: Date | null = null;

    public KEY: string = nanoid();

    public id: string = "";
    public projectId: string = "";
    public updateDate: Date | null = null;
    public updateDetail: string = "";
    public internalDetail: string = "";
    public sendUpdate: boolean = true;

    public createdByUserId: string | null = null;
    public updatedByUserId: string | null = null;

    constructor() {
        super();

        makeObservable(this, {
            // Observables
            updateDate: observable,
            updateDetail: observable,
            internalDetail: observable,
            sendUpdate: observable,

            createdByUserId: observable,
            updatedByUserId: observable,
        });
    }
}

export class WeeklyUpdateFormModelValidator extends Validator<WeeklyUpdateFormModel> {
    constructor() {
        super();
        this.ruleFor("updateDetail").notNull().withMessage("Please provide details").notEmpty().withMessage("Please provide details");
    }
}
