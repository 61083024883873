import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

export class EnquiriesModel extends ModelBase<EnquiriesModel> {
    // Filtering
    public filterKeyword: string = "";
    public filterEnquiryStatusId: string | null = null;
    public filterFromDate: Date | undefined = undefined;
    public filterToDate: Date | undefined = undefined;
    public filterVisitingPersonId: string | null = null;
    public filterSourceOfEnquiryTypeId: string | null = null;

    constructor() {
        super();
        makeObservable(this, {
            // Observables
            filterKeyword: observable,
            filterEnquiryStatusId: observable,
            filterFromDate: observable,
            filterToDate: observable,
            filterVisitingPersonId: observable,
            filterSourceOfEnquiryTypeId: observable,
        });
    }
}
