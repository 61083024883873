import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

import { Validator } from "Application/Validation";

export class ProjectLiteModel extends ModelBase<ProjectLiteModel> {
    // This is not part of the form model, but used for validation.
    public id: string | null = null;
    public projectTypeId: string | null = null;
    public title: string | null = null;
    public projectValue: number | null = null;
    public customerId: string | null = null;
    public customerBusinessName: string | null = null;

    constructor() {
        super();

        makeObservable(this, {
            id: observable,
            projectTypeId: observable,
            title: observable,
            projectValue: observable,
            customerId: observable,
            customerBusinessName: observable,
        });
    }
}

export class ProjectLiteModelValidator extends Validator<ProjectLiteModel> {
    constructor() {
        super();
    }
}
