import { IColumn, Link, PersonaSize } from "@fluentui/react";
import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { AutoGrid, Box, formatCurrency, formatDate, setPageTitle, ThemedComboBox, ThemedEditDate, ThemedEditText, ThemedText } from "Application";
import { DownloadIcon } from "Assets/Icons/DownloadIcon";
import { EmailIcon } from "Assets/Icons/EmailIcon";
import { PhoneIcon } from "Assets/Icons/PhoneIcon";
import { SearchIcon } from "Assets/SearchIcon";
import { ContactCallout, ICalloutState } from "Styles/ThemedPrimitives/Style1/ContactCallout";
import { ThemedDataTable } from "Styles/ThemedPrimitives/Style1/ThemedDataTable";
import { DownloadCalloutView, IDownloadCalloutState } from "./Components/DownloadCalloutView";
import { QuoteItemViewModel } from "./QuoteItemViewModel";
import { QuotesViewModel } from "./QuotesViewModel";
import { uniqueId } from "lodash-es";
import { JourneyHistoryItem, UserJourneyHistoryStore } from "Stores/Domain";
import { container } from "tsyringe";
import { ThemedPersona } from "Styles/ThemedPrimitives/Style1/ThemedPersona";

const controlStyles = {
    root: {
        width: "505px",
        height: "39px",
        fontSize: "17px",
        borderColor: "#E1E1E1",
    },
};

export const QuotesView: React.FC = observer(() => {
    // #region Code Behind

    //inject the LayoutStore

    const [viewModel] = useState(() => new QuotesViewModel());

    useEffect(() => {
        setPageTitle("Quotes");
    }, []);

    /**
     * Handles updating the tables column sort settings in response to changes in the viewmodel.
     */
    useEffect(() => {
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter((currCol) => viewModel.settings.sortKey === currCol.key)[0];

        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSorted = true;
                currColumn.isSortedDescending = viewModel.settings.sortDescending;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = false;
            }
        });

        setColumns(newColumns);
    }, [viewModel.settings.sortKey, viewModel.settings.sortDescending]);

    /**
     * Gets a key for the row. Must be provided if sorting or filtering is enabled.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The row index.
     *
     * @returns A string key to identify the row.
     */
    const getRowKey = (item: QuoteItemViewModel, index?: number): string => {
        return item.KEY;
    };

    /**
     * Handles an onClick event for the table column header.
     *
     * @param ev Mouse event
     * @param column The column that has been clicked.
     */
    const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const currColumn: IColumn = columns.filter((currCol) => column.key === currCol.key)[0];
        const newSortKey = currColumn.key;
        const newSortDescending = currColumn.key === viewModel.settings.sortKey ? !currColumn.isSortedDescending : false;

        viewModel.updateSortCommand.execute(newSortKey, newSortDescending);
    };

    /**
     * Handler an onClick event for a table row.
     *
     * @param item The viewmodel data associated with the table row.
     */
    const onRowClick = (item: QuoteItemViewModel, index?: number, ev?: React.FocusEvent<HTMLElement>) => {
        //keep track of the scroll position before navigating to the quote
        //select the container so that the scroll position is maintained
        const newBusinessViewContainer = document.getElementById("newBusinessView");
        const scrollPosition = newBusinessViewContainer?.scrollTop;
        //set the scroll position in the store
        const userJourneyHistoryStore = container.resolve(UserJourneyHistoryStore);
        //add the item to the user journey
        const journeyHistoryItem = new JourneyHistoryItem();
        journeyHistoryItem.id = item.model.id;
        userJourneyHistoryStore.addQuoteJourneyHistoryItem(journeyHistoryItem);
        userJourneyHistoryStore.setScrollPosition(scrollPosition);

        //navigate to quote detail

        viewModel.navigateToQuoteCommand.execute(item);
    };

    /**
     * Renders the confidence row data.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The index of the table row.
     * @param column The table column.
     *
     * @returns React rating element.
     */
    const onRenderConfidence = (item: QuoteItemViewModel, index?: number, column?: IColumn) => {
        const MAXSTARS = 5;
        const stars = [];

        for (let starIndex = 0; starIndex < MAXSTARS; starIndex++) {
            stars.push(starIndex < item.confidence ? <span className="rating-round active" /> : <span className="rating-round" />);
        }

        return (
            <div id="ratingBox" className="ratingBox">
                {stars}
            </div>
        );
    };

    /**
     * Renders the desirability row data.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The index of the table row.
     * @param column The table column.
     *
     * @returns React rating element.
     */
    const onRenderDesirability = (item: QuoteItemViewModel, index?: number, column?: IColumn) => {
        const MAXSTARS = 5;
        const stars = [];

        for (let starIndex = 0; starIndex < MAXSTARS; starIndex++) {
            stars.push(starIndex < item.desirability ? <span className="rating-round purple active" /> : <span className="rating-round" />);
        }

        return (
            <div id="ratingBox" className="ratingBox">
                {stars}
            </div>
        );
    };

    /**
     * Renders the issued date row data.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The index of the table row.
     * @param column The table column.
     *
     * @returns React rating element.
     */
    const onRenderIssueDate = (item: QuoteItemViewModel, index?: number, column?: IColumn) => {
        return <div>{!isNullOrUndefined(item.issueDate) ? formatDate(item.issueDate!) : ""}</div>;
    };

    /**
     * Renders the value row data.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The index of the table row.
     * @param column The table column.
     *
     * @returns React rating element.
     */
    const onRenderValue = (item: QuoteItemViewModel, index?: number, column?: IColumn) => {
        return <div>{formatCurrency(item.value)}</div>;
    };

    /**
     * Renders the quote status row data.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The index of the table row.
     * @param column The table column.
     *
     * @returns React rating element.
     */
    const onRenderQuoteStatus = (item: QuoteItemViewModel, index?: number, column?: IColumn) => {
        return <div style={{ color: `${item.quoteStatusForegroundColor}`, textTransform: "uppercase", fontWeight: "500" }}>{item.quoteStatusName}</div>;
    };

    /**
     * Renders the last action user name and date.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The index of the table row.
     * @param column The table column.
     *
     * @returns React rating element.
     */
    const onRenderLastAction = (item: QuoteItemViewModel, index?: number, column?: IColumn) => {
        return (
            <div>
                <div>{!isNullOrUndefined(item.lastActionDate) ? formatDate(item.lastActionDate!) : ""}</div>
                <div>{item.lastActionUserName}</div>
            </div>
        );
    };

    const onRenderContactName = (item: QuoteItemViewModel, index?: number, column?: IColumn) => {
        if (item.model.customerItemModel != null) {
            return (
                <Link onFocus={(ev) => ev.stopPropagation()}>
                    <div onClick={() => viewModel.navigateToCustomerDetailCommand.execute(item.model.customerItemModel!.id)}>
                        <div style={{ fontWeight: 600, fontSize: "10px", color: "#171716" }}>{item.contactFullName}</div>
                        <div style={{ fontSize: "10px", color: "#171716" }}>{item.contactFullAddress}</div>
                    </div>
                </Link>
            );
        } else {
            return (
                <div>
                    <div style={{ fontWeight: 600, fontSize: "10px", color: "#171716" }}>{item.contactFullName}</div>
                    <div style={{ fontSize: "10px", color: "#171716" }}>{item.contactFullAddress}</div>
                </div>
            );
        }
    };

    /**
     * Renders the phone icon and provides callout support.
     *
     * The icon is wrapped in a Link with a stop propagation call so
     * clicking on the icon prevents the table row click.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The index of the table row.
     * @param column The table column.
     *
     * @returns React rating element.
     */
    const onRenderPhone = (item: QuoteItemViewModel, index?: number, column?: IColumn) => {
        if (!isEmptyOrWhitespace(item.contactNumber)) {
            return (
                <Link onFocus={(ev) => ev.stopPropagation()}>
                    <PhoneIcon
                        id={`phoneicon-${index}`}
                        onClick={(event: any) => {
                            setIsCalloutVisible({
                                isCalloutVisble: true,
                                isEmail: false,
                                targetId: `phoneicon-${index}`,
                                data: item.contactNumber,
                            });
                        }}
                    />
                </Link>
            );
        }

        return <React.Fragment />;
    };

    /**
     * Renders the email icon and provides callout support.
     *
     * The icon is wrapped in a Link with a stop propagation call so
     * clicking on the icon prevents the table row click.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The index of the table row.
     * @param column The table column.
     *
     * @returns React rating element.
     */
    const onRenderEmail = (item: QuoteItemViewModel, index?: number, column?: IColumn) => {
        if (!isEmptyOrWhitespace(item.emailAddress)) {
            return (
                <Link onFocus={(ev) => ev.stopPropagation()}>
                    <EmailIcon
                        id={`emailicon-${index}`}
                        onClick={(event: any) => {
                            setIsCalloutVisible({
                                isCalloutVisble: true,
                                isEmail: true,
                                targetId: `emailicon-${index}`,
                                data: item.emailAddress,
                            });
                        }}
                    />
                </Link>
            );
        }

        return <React.Fragment />;
    };

    /**
     * Renders the download icon and provides callout support.
     *
     * The icon is wrapped in a Link with a stop propagation call so
     * clicking on the icon prevents the table row click.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The index of the table row.
     * @param column The table column.
     *
     * @returns React rating element.
     */
    const onRenderDownload = (item: QuoteItemViewModel, index?: number, column?: IColumn) => {
        if (item.canDisplayDownloadOptions) {
            return (
                <Link onFocus={(ev) => ev.stopPropagation()}>
                    <DownloadIcon
                        id={`downloadicon-${index}`}
                        onClick={(event: any) => {
                            setIsDownloadCalloutVisible({
                                isCalloutVisble: true,
                                targetId: `downloadicon-${index}`,
                                viewModel: item.CreateDownloadViewModel(),
                            });
                        }}
                    />
                </Link>
            );
        }
        return <React.Fragment />;
    };

    /**
     * Defines the columns for use with the table.
     */
    const defaultTableColumns: IColumn[] = [
        {
            fieldName: "quoteStatusName",
            key: "quoteStatusName",
            name: "STATUS",
            minWidth: 0,
            maxWidth: 70,
            isResizable: true,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: onRenderQuoteStatus,
        },
        {
            fieldName: "reference",
            key: "reference",
            name: "REFERENCE",
            isResizable: true,
            minWidth: 0,
            maxWidth: 100,
            styles: {
                cellTitle: {
                    textAlign: "center",
                    justifyContent: "center",
                },
            },
            className: "textCenter",
            isSorted: true,
            onColumnClick: onColumnClick,
        },
        {
            key: "title",
            name: "JOB NAME",
            fieldName: "title",
            isResizable: true,
            minWidth: 50,
            isSorted: true,
            onColumnClick: onColumnClick,
        },
        {
            fieldName: "issueDate",
            key: "issueDate",
            name: "LAST ISSUED",
            isResizable: true,
            minWidth: 0,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: onRenderIssueDate,
        },
        {
            key: "fullName",
            name: "CLIENT",
            fieldName: "fullName",
            minWidth: 250,
            isSorted: true,
            isSortedDescending: false,
            sortAscendingAriaLabel: "Sorted A to Z",
            sortDescendingAriaLabel: "Sorted Z to A",
            onColumnClick: onColumnClick,
            isPadded: true,
            onRender: onRenderContactName,
            styles: {
                cellTitle: {
                    border: "none !important",
                },
            },
        },
        {
            className: "contactCell",
            fieldName: "",
            styles: {
                cellTitle: {
                    border: "none !important",
                },
            },
            key: "phoneicon",
            name: "",
            minWidth: 25,
            onRender: onRenderPhone,
        },
        {
            className: "contactCell",
            fieldName: "",
            key: "emailicon",
            name: "",
            minWidth: 25,
            onRender: onRenderEmail,
        },
        {
            key: "hours",
            name: "HOURS",
            fieldName: "hours",
            isResizable: true,
            minWidth: 100,
            isSorted: true,
            onColumnClick: onColumnClick,
        },
        {
            fieldName: "value",
            key: "value",
            name: "VALUE",
            isResizable: true,
            minWidth: 100,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: onRenderValue,
        },
        {
            fieldName: "confidence",
            key: "confidence",
            name: "CONFIDENCE",
            isResizable: true,
            minWidth: 0,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: onRenderConfidence,
        },

        {
            fieldName: "quoteLeadFullName",
            key: "quoteLeadFullName",
            name: "QUOTE LEAD",
            isResizable: true,
            minWidth: 100,
            maxWidth: 200,
            isSorted: true,
            onColumnClick: onColumnClick,
        },
        {
            fieldName: "enquiryPersonVisitingFullName",
            key: "enquiryPersonVisitingFullName",
            name: "PERSON VISITING",
            isResizable: true,
            minWidth: 100,
            maxWidth: 200,
            isSorted: true,
            onColumnClick: onColumnClick,
        },
        {
            fieldName: "revision",
            key: "revision",
            name: "REV",
            isResizable: true,
            minWidth: 20,
            maxWidth: 20,
            isSorted: true,
            onColumnClick: onColumnClick,
            styles: {
                cellTitle: {
                    display: "flex",
                    textAlign: "center",
                    justifyContent: "center",
                },
            },
        },
        {
            fieldName: "lastActionDate",
            key: "lastActionDate",
            name: "LAST ACTION",
            maxWidth: 125,
            minWidth: 125,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: onRenderLastAction,
        },
        {
            className: "downloadCell",
            fieldName: "",
            key: "downloadicon",
            name: "",
            maxWidth: 25,
            minWidth: 25,
            onRender: onRenderDownload,
            styles: {
                cellTitle: {
                    border: "none !important",
                },
            },
        },
    ];

    /**
     * Defines the default state for displaying a callout.
     */
    const defaultCalloutState: ICalloutState = { isCalloutVisble: false, targetId: "", isEmail: false, data: "" };
    const defaultDownloadCalloutState: IDownloadCalloutState = { isCalloutVisble: false, targetId: "", viewModel: null };

    const [callout, setIsCalloutVisible] = useState<ICalloutState>(defaultCalloutState);
    const [downloadCallout, setIsDownloadCalloutVisible] = useState<IDownloadCalloutState>(defaultDownloadCalloutState);

    const [columns, setColumns] = useState<IColumn[]>(defaultTableColumns);

    const tableId = "quotesTable-" + uniqueId();

    const userJourneyHistoryStore = container.resolve(UserJourneyHistoryStore);

    const highlightLastViewedRow = () => {
        //get last viewed row
        const lastViewedItem = userJourneyHistoryStore.quoteJourneyHistory[0];
        //determine the index of the item relative to the filtered rows
        const itemIndex = viewModel.filteredAndSortedQuotes.map((item) => item.model.id).indexOf(lastViewedItem.id);
        //get the table
        const listElement = document.getElementById(tableId);
        if (!listElement) return;

        const rowElement = listElement.querySelector(`[data-list-index="${1}"]`) as HTMLElement;
        if (!rowElement) return;
        console.log(rowElement);
    };

    const scrollToPreviousPosition = () => {
        // get scroll position from the userJourneyHistoryStore

        if (userJourneyHistoryStore.canScrollToPosition) {
            //select the container that does the scrolling
            const newBusinessContainer = document.getElementById("newBusinessView");
            if (newBusinessContainer) {
                //set the scroll position to the one stored globally

                setTimeout(() => {
                    newBusinessContainer.scrollTop = userJourneyHistoryStore.scrollPosition!;
                    setTimeout(() => highlightLastViewedRow(), 100);
                }, 100);
            }
        }
    };

    useEffect(() => {
        if (viewModel.canRender) {
            scrollToPreviousPosition();
        }
    }, [viewModel.canRender]);

    // #endregion Code Behind

    const renderPipeline = () => (
        <Box pl={3}>
            <ThemedText fontStyle="h7">
                <b>Pipeline</b>
            </ThemedText>
            <AutoGrid display={"flex"} mt="0" mb="0">
                <ThemedText fontStyle="superSmall">
                    <b>Total hours</b>
                </ThemedText>
                <ThemedText fontStyle="superSmall" styles={{ root: { textAlign: "right" } }}>
                    <b>{viewModel.totalSentQuoteHours}</b>
                </ThemedText>
            </AutoGrid>
            <AutoGrid display={"flex"} mt="0" mb="0">
                <ThemedText fontStyle="superSmall">
                    <b>Total value</b>
                </ThemedText>
                <ThemedText fontStyle="superSmall" styles={{ root: { textAlign: "right" } }}>
                    <b>{viewModel.totalSentQuoteValue}</b>
                </ThemedText>
            </AutoGrid>
        </Box>
    );

    return (
        <Box>
            {/* Filter Bar */}
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                <Box display={"flex"}>
                    <ThemedEditText
                        command={viewModel.updateFilterKeywordCommand}
                        placeholder={"Keyword search"}
                        prefix={<SearchIcon />}
                        styles={controlStyles}
                        value={() => viewModel.getValue("filterKeyword")}
                    />
                    {/* <Box pl={3}>
                        <ThemedEditDate
                            command={viewModel.updateFilterFromDateComand}
                            placeholder="Action from"
                            size="larger"
                            value={() => viewModel.getValue("filterFromDate")}
                            showLabel={false}
                        />
                    </Box>
                    <Box pl={3}>
                        <ThemedEditDate
                            command={viewModel.updateFilterToDateCommand}
                            placeholder="Action to"
                            size="larger"
                            value={() => viewModel.getValue("filterToDate")}
                            showLabel={false}
                        />
                    </Box> */}
                    <Box pl={3}>
                        <ThemedComboBox
                            command={viewModel.updateFilterQuoteStatusCommand}
                            options={viewModel.quoteStatuses}
                            placeholder="All statuses"
                            size="larger"
                            value={() => viewModel.getValue("filterQuoteStatusId")}
                        />
                    </Box>
                    <Box pl={3}>
                        <ThemedComboBox
                            command={viewModel.updateQuoteLeadCommand}
                            options={viewModel.quoteLeads}
                            placeholder="All statuses"
                            size="larger"
                            value={() => viewModel.getValue("filterQuoteLeadId")}
                        />
                    </Box>
                    <Box pl={3}>
                        <ThemedComboBox
                            command={viewModel.updateFilterEnquiryPersonVisitingCommand}
                            options={viewModel.enquiryPersonsVisiting}
                            placeholder="All persons"
                            size="larger"
                            value={() => viewModel.getValue("filterEnquiryPersonVisitingId")}
                        />
                    </Box>
                    {renderPipeline()}
                </Box>
            </Box>

            {/* Table */}
            <ThemedDataTable
                className="quoteslistviewTable"
                getKey={getRowKey}
                items={viewModel.filteredAndSortedQuotes}
                onActiveItemChanged={onRowClick}
                columns={columns}
                id={tableId}
            />

            {/* Callout */}
            {callout.isCalloutVisble && (
                <ContactCallout data={callout.data} onDismiss={() => setIsCalloutVisible(defaultCalloutState)} isEmail={callout.isEmail} targetId={callout.targetId} />
            )}

            {/* Download Callout */}
            {downloadCallout.isCalloutVisble && (
                <DownloadCalloutView
                    viewModel={downloadCallout.viewModel}
                    onDismiss={() => setIsDownloadCalloutVisible(defaultDownloadCalloutState)}
                    targetId={downloadCallout.targetId}
                />
            )}
        </Box>
    );
});
