import { ModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

export class DownloadCalloutItemModel extends ModelBase<DownloadCalloutItemModel> {
    public quoteId: string = "";
    public quoteTitle: string = "";
    public hasLetter: boolean = false;
    public hasTermsAndConditions: boolean = false;
    public hasQuoteTasksAndCosts: boolean = false;

    constructor() {
        super();
        makeObservable(this, {});
    }
}
