import { isEmptyOrWhitespace, isNullOrUndefined, ViewModelBase } from "@shoothill/core";
import { computed, makeObservable, observable } from "mobx";
import { container } from "tsyringe";

import { APIClient, RelayCommand } from "Application";
import { AppUrls } from "AppUrls";
import { SettingsStore } from "Stores/Domain";
import { GETLostEndpoint } from "./GETLostEndpoint";
import { LostItemViewModel } from "./LostItemViewModel";
import { LostModel } from "./LostModel";

interface Types {
    key: string;
    text: string;
    type: string;
}

export class LostViewModel extends ViewModelBase<LostModel> {
    public apiClient = new APIClient();
    public enquiries = observable<LostItemViewModel>([]);

    public lostTypes: Types[] = [
        {
            key: "",
            text: "All Types",
            type: "",
        },
        {
            key: "id_quote",
            text: "QUOTE",
            type: "",
        },
        {
            key: "id_enquiry",
            text: "ENQUIRY",
            type: "",
        },
    ];

    constructor() {
        super(new LostModel());

        makeObservable(this, {
            enquiries: observable,

            // Computeds
            canFilterLost: computed,
            canSortLost: computed,
            filteredAndSortedLost: computed,
        });

        const _ = this.apiClient.sendAsync(new GETLostEndpoint(this));
    }

    public get settings() {
        return container.resolve(SettingsStore).EnquiriesTable;
    }

    public navigateToEditEnquiry = new RelayCommand((item: any) => {
        this.history.push(AppUrls.Client.Enquiries.Edit.replace(":id", item.model.id));
    });

    public navigateToEditQuote = new RelayCommand((item: any) => {
        this.history.push(AppUrls.Client.Quotes.Edit.replace(":id", item.model.id));
    });

    public navigateToNewEnquiryFormCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.Quotes.New);
    });

    public navigateToCustomerDetailCommand = new RelayCommand((id: string) => {
        this.history.push(AppUrls.Client.Customers.Details.replace(":id", id));
    });

    public get canFilterLost(): boolean {
        return (
            !isEmptyOrWhitespace(this.model.filterKeyword) ||
            !isNullOrUndefined(this.model.filterFromDate) ||
            !isNullOrUndefined(this.model.filterToDate) ||
            !isEmptyOrWhitespace(this.model.filterTypeId)
        );
    }

    public getLostTypes() {
        return this.lostTypes;
    }

    public get canSortLost(): boolean {
        return !isEmptyOrWhitespace(this.settings.sortKey);
    }

    public get filteredLost(): LostItemViewModel[] {
        return this.canFilterLost
            ? this.enquiries.filter((vm) => vm.filterPredicate(this.model.filterKeyword, this.model.filterFromDate, this.model.filterToDate, this.model.filterTypeId))
            : this.enquiries;
    }

    public get filteredAndSortedLost(): LostItemViewModel[] {
        return this.canSortLost
            ? this.filteredLost
                  .slice()
                  .sort((lhs, rhs) =>
                      (this.settings.sortDescending ? lhs[this.settings.sortKey] < rhs[this.settings.sortKey] : lhs[this.settings.sortKey] > rhs[this.settings.sortKey]) ? 1 : -1,
                  )
            : this.filteredLost.slice();
    }

    public updateFilterKeywordCommand = new RelayCommand((keyword: string) => {
        this.model.filterKeyword = keyword;
    });

    public updateFilterFromDateCommand = new RelayCommand((date: Date | undefined) => {
        this.model.filterFromDate = date;
    });

    public updateFilterToDateCommand = new RelayCommand((date: Date | undefined) => {
        this.model.filterToDate = date;
    });

    public updateSortCommand = new RelayCommand((key: string, sortDescending: boolean) => {
        this.settings.sortKey = key;
        this.settings.sortDescending = sortDescending;
    });

    public updateLostTypeCommand = new RelayCommand((value: string | null) => {
        this.model.filterTypeId = value;
    });
}
