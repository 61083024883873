import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { nanoid } from "nanoid";

export class StaffItemModel extends ModelBase<StaffItemModel> {
    public KEY: string = nanoid();

    public userId: string = "";
    public userFirstName: string = "";
    public userLastName: string = "";
    public documentUrl: string = "";

    public businessHours: number = 0;
    public chargeableBusinessHours: number = 0;
    public chargeableEffortHoursAllocation: number = 0;
    public chargeableEffortPercentageAllocation: number = 0;
    public effortHoursAllocation: number = 0;
    public effortPercentageAllocation: number = 0;

    constructor() {
        super();

        makeObservable(this, {
            userId: observable,
            userFirstName: observable,
            userLastName: observable,
            documentUrl: observable,

            businessHours: observable,
            chargeableBusinessHours: observable,
            chargeableEffortHoursAllocation: observable,
            chargeableEffortPercentageAllocation: observable,
            effortHoursAllocation: observable,
            effortPercentageAllocation: observable,
        });
    }
}
