import styled from "@emotion/styled";
import { AutoGrid, Box, Nullcommand, ThemedButton, ThemedText, setPageTitle, theme } from "Application";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { InvoiceForecastViewModel } from "./InvoiceForecastViewModel";
import { APICommonComponents } from "Application/Helpers/APICommonComponents";
import { InvoiceForecastMonthCapacityView } from "./Subviews/InvoiceForecastMonthCapacity/InvoiceForecastMonthCapacityView";

/**
 * Contains the contents of the page.
 */
const PageContainer = styled(Box)`
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
`;

const FormContainer = styled(Box)`
    background-color: ${theme.palette.common.white};
    flex: 1;
    /* padding: 15px 30px; */
`;

const FormSubContainer = styled(Box)`
    flex: 1;
    margin-bottom: 55px;
`;

const TableHeader = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 2fr 2fr 1.5fr;

    background-color: ${theme.palette.tableHeader.main};
    margin-bottom: 10px;

    & > * {
        padding: 10px;
    }

    & > *:not(:first-child) {
        border-left: 1px solid ${theme.palette.tableHeader.light};
    }
`;

const TableBody = styled.div``;

interface IProps {}

export const InvoiceForecastView: React.FC<IProps> = observer((props: IProps) => {
    const [viewModel] = useState(new InvoiceForecastViewModel());

    useEffect(() => {
        setPageTitle("Invoice Forecast");
    }, []);

    const renderMonthCapacities = () => {
        return viewModel.capacities.map((vm) => <InvoiceForecastMonthCapacityView key={vm.model.KEY} viewModel={vm} />);
    };

    return (
        <>
            <PageContainer>
                <FormContainer>
                    <FormSubContainer>
                        <Box>
                            <TableHeader>
                                <Box>
                                    <ThemedText fontStyle="h5" color="white">
                                        Month
                                    </ThemedText>
                                </Box>
                                <Box>
                                    <ThemedText fontStyle="h5" color="white">
                                        Hours
                                    </ThemedText>
                                </Box>

                                <Box>
                                    <ThemedText fontStyle="h5" color="white">
                                        Value
                                    </ThemedText>
                                </Box>
                                <Box>
                                    <ThemedText fontStyle="h5" color="white">
                                        Override Hours
                                    </ThemedText>
                                </Box>
                                <Box>
                                    <ThemedText fontStyle="h5" color="white">
                                        Override Value
                                    </ThemedText>
                                </Box>
                                <Box>
                                    <ThemedText fontStyle="h5" color="white">
                                        Show on Invoice forecast
                                    </ThemedText>
                                </Box>
                            </TableHeader>
                            {/* map over months */}
                            <TableBody>{renderMonthCapacities()}</TableBody>
                        </Box>
                    </FormSubContainer>
                </FormContainer>
                <AutoGrid
                    backgroundColor="#F3F3F3"
                    columnGap={"30px"}
                    dc={"140px 140px 140px 140px 140px 140px"}
                    m={"0"}
                    padding={"15px 30px"}
                    position="fixed"
                    left="0"
                    right="0"
                    bottom="0"
                    zIndex="10000"
                >
                    <ThemedButton styles={{ root: { padding: "0 5px" } }} paletteColor={"primary"} displayName={"Save"} command={viewModel.saveInvoiceForecastOverridesCommand} />
                </AutoGrid>
            </PageContainer>
            <Box>
                {/* Busy Overlay */}
                {APICommonComponents(viewModel.apiClient).renderBusy()}
                {/* Error Overlay */}
                {APICommonComponents(viewModel.apiClient).renderError()}
            </Box>
        </>
    );
});
