import { ModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

export class RevisionModel extends ModelBase<RevisionModel> {
    public id: string = "";
    public title: string = "";
    public revision: string = "";
    public modifiedDate: Date | null = null;

    constructor() {
        super();
        makeObservable(this, {
            // Observables
        });
    }
}
