import { Editor } from "@tinymce/tinymce-react";
import { observer } from "mobx-react-lite";
import React from "react";

import { ExpandableContainer, ICommand, ThemedButton, ThemedComboBox, ThemedText } from "Application";
import { setupQuotePlaceholderPlugin } from "Plugins/TinyMCE/QuotePlaceholders";
import { FormContainerRow, FormSeparator } from "Views/Shared/SharedComponents";
import { LetterViewModel } from "./LetterViewModel";
import useResizeObserver from "Application/Hooks/UseResizeObserver";

export interface ILetterSubViewProps {
    viewModel: LetterViewModel;
    parentPreviewLetterCommand: ICommand;
}

export const LetterView: React.FC<ILetterSubViewProps> = observer((props) => {
    const { ref, observedHeight } = useResizeObserver(90);
    const viewModel = props.viewModel;

    const renderContent = () => {
        return (
            <div ref={ref}>
                <FormContainerRow mb="30px !important" dc={"25%"}>
                    <ThemedComboBox
                        displayName="Letter Template*"
                        labelColor="default"
                        options={viewModel.letterTemplateOptions}
                        value={() => viewModel.getValue("letterTemplateId")}
                        command={viewModel.updateLetterTemplateIdCommand}
                        placeholder="Please select"
                        validationMessage={() => viewModel.getError("letterTemplateId")}
                    />
                </FormContainerRow>
                <FormContainerRow dc={"1fr"}>
                    <Editor
                        apiKey="g828m3lar5y087wnket2v38pbvjugg2m1091afqssqvjj8ze"
                        value={viewModel.getValue("letterDetails") ?? ""}
                        onEditorChange={viewModel.updateLetterText}
                        init={{
                            height: 446,
                            menubar: false,
                            browser_spellcheck: true,
                            plugins:
                                "preview paste searchreplace autolink directionality visualblocks visualchars fullscreen link table charmap hr nonbreaking insertdatetime advlist lists wordcount imagetools textpattern noneditable charmap contextmenu code",
                            toolbar:
                                "undo redo | bold italic underline strikethrough | subscript superscript | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | fullscreen preview code | quoteplaceholders",
                            contextmenu: "undo redo | cut copy paste | bold italic underline | fontformats | fontsizes | quoteplaceholders",
                            font_formats:
                                "Arapey=arapey,serif; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino;HeadingNow 55 Medium=headingnow55medium, sans-serif; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
                            content_style:
                                "body { font-family: Arapey, serif; } @import url('https://basearchitects.blob.core.windows.net/fonts/ArapeyRegularFontFace.css');@import url('https://basearchitects.blob.core.windows.net/fonts/HeadingNow55MediumFontFace.css');",
                            setup: (editor) => {
                                setupQuotePlaceholderPlugin(editor);
                            },
                        }}
                    />
                </FormContainerRow>
                <ThemedButton styles={{ root: { padding: "0 5px" } }} paletteColor={"quarternary"} displayName="Preview quote letter" command={props.parentPreviewLetterCommand} />
            </div>
        );
    };

    return (
        <React.Fragment>
            {viewModel.canDisplayForQuickQuote && <></>}
            {!viewModel.canDisplayForQuickQuote && (
                <ExpandableContainer displayName="Quote letter" childrenHeight={observedHeight} openByDefault>
                    {renderContent()}
                </ExpandableContainer>
            )}
        </React.Fragment>
    );
});
