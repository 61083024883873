import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { ProjectTaskGroupChartModel } from "../SubViews/ProjectTaskGroups/ProjectTaskGroupChartModel";

class Request {
    public projectTaskGroupId: string = "";
    public startDate: Date | null = null;
    public endDate: Date | null = null;
}

class Response {}

export class POSTSaveProjectTaskGroupStartAndEndDateEndpoint extends Endpoint<Request, Response> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Projects.SaveProjectTaskGroupStartAndEndDate);
    }

    public async HandleRequestAsync(model: ProjectTaskGroupChartModel): Promise<Request> {
        let request: Request = new Request();

        request.projectTaskGroupId = model.id;
        request.startDate = model.startDate;
        request.endDate = model.endDate;

        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        return Promise.resolve(response);
    }
}
