import * as React from "react";
import { SVGProps } from "react";
import { theme } from "../../Styles";

export const CopyIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={9} height={10} {...props}>
        <g fill="none">
            <g data-name="Rectangle 1526" stroke="#ff4b22">
                <path stroke="none" d="M0 3h6v7H0z" />
                <path d="M.5 3.5h5v6h-5z" />
            </g>
            <g data-name="Path 3381">
                <path d="M3 0h6v7H5.286V3.792H3Z" />
                <path d="M4 1v1.792h2.286V6H8V1H4M3 0h6v7H5.286V3.792H3V0Z" fill={theme.palette.common.secondary} />
            </g>
        </g>
    </svg>
);
