import { runInAction } from "mobx";
import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { UnassignedTasksItemViewModel } from "./UnassignedTasksSubView/UnassignedTasksItemViewModel";
import { UnassignedTasksItemModel } from "./UnassignedTasksSubView/UnassignedTasksItemModel";
import { InProgressItemViewModel } from "./InProgressSuvbView/InProgressItemViewModel";
import { InProgressItemModel } from "./InProgressSuvbView/InProgressItemModel";
import { ProgrammingViewModel } from "./ProgrammingViewModel";

class Response {
    unassignedTasks: UnassignedTaskResponse[] = [];
    assignedTasks: AssignedTaskResponse[] = [];
}

class UnassignedTaskResponse {
    public id: string = "";
    public projectTaskName: string = "";
    public projectTaskIsBilledHourly: boolean = false;
    public projectTaskOrdinal: number = 0;
    public anticipatedEndDate: Date | null = null;
    public manHours: number | null = null;
    public remainingProjectTaskBillableEffort: number = 0;
}

class AssignedTaskResponse {
    public id: string = "";
    public projectTaskAssignmentStatusForegroundColor: string = "";
    public projectTaskAssignmentStatusId: string = "";
    public projectTaskAssignmentStatusName: string = "";
    public projectTaskName: string = "";
    public projectTaskIsBilledHourly: boolean = false;
    public assignedUserFirstName: string = "";
    public assignedUserLastName: string = "";
    public plannedStartDate: Date | null = null;
    public plannedEndDate: Date | null = null;
    public billableEffort: number | null = null;
    public effort = 0;
    public isNonBillableEffort = false;
}

export class GETTaskProgrammingByIdEndpoint extends EndpointWithoutRequest<Response> {
    private readonly viewModel: ProgrammingViewModel;

    constructor(projectId: string, viewModel: ProgrammingViewModel) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.Projects.GetProjectDetailsProgrammingByProjectId.replace(":id", projectId));
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        runInAction(() => {
            const unassignedTasks: UnassignedTasksItemViewModel[] = [];

            response.unassignedTasks.forEach((element: any) => {
                const model = new UnassignedTasksItemModel();
                model.fromResponse(element);
                unassignedTasks.push(new UnassignedTasksItemViewModel(model));
            });

            this.viewModel.unassignedTasksViewModel.tasks.replace(unassignedTasks);

            const assignedTasks: InProgressItemViewModel[] = [];

            response.assignedTasks.forEach((element: any) => {
                const model = new InProgressItemModel();
                model.fromResponse(element);
                assignedTasks.push(new InProgressItemViewModel(model));
            });

            this.viewModel.inProgressViewModel.tasks.replace(assignedTasks);
        });
    }
}
