import { ViewModelBase } from "@shoothill/core";
import { computed, makeObservable, observable, observe } from "mobx";

import { QuoteListItemModel } from "./QuoteListItemModel";

import { CurrentQuoteViewModel } from "../Current/CurrentQuoteViewModel";
import { APIClient, RelayCommand } from "Application";
import { RevisionViewModel } from "Views/Shared/Revisions/RevisionViewModel";

import { MODELID_CURRENTQUOTE } from "Views/Shared/Constants";

export class QuoteListItemViewModel extends ViewModelBase<QuoteListItemModel> {
    public isVisible = false;

    public apiClient = new APIClient();

    public currentQuoteViewModel: CurrentQuoteViewModel; // current
    public activeRevisionViewModel: RevisionViewModel | null = null; // current
    public revisionViewModels = observable<RevisionViewModel>([]); // history

    constructor(quoteId: string | undefined) {
        super(new QuoteListItemModel());

        (this.currentQuoteViewModel = new CurrentQuoteViewModel(this.model.currentQuoteModel)),
            makeObservable(this, {
                // Observables
                isVisible: observable,
                revisionViewModels: observable,
                activeRevisionViewModel: observable,
                canDisplayRevisionNavigation: computed,

                // Computeds
            });
    }

    /**
     * Disposes any resources. Call this from your view.
     */
    public dispose = (): void => {
        this.currentQuoteViewModel.dispose();
        this.revisionsObserverDispose?.();
    };

    // This will contain our collection of revision viewmodels!
    // Initialise as null.

    /**
     * Returns if revision navigation can be displayed.
     */
    public get canDisplayRevisionNavigation() {
        return this.revisionViewModels.length > 1;
    }

    public navigateToRevision = new RelayCommand((vm: RevisionViewModel) => {
        this.activeRevisionViewModel = vm;

        // SIDE-EFFECT - Update active states of the revisions.
        for (const revisionViewModel of this.revisionViewModels) {
            revisionViewModel.setActive(revisionViewModel.model.KEY === this.activeRevisionViewModel?.model?.KEY);
        }
    });

    public resetServerErrorCommand = new RelayCommand(() => {
        this.apiClient.reset();
    });

    public get quoteDisplayTitle() {
        return this.isVisible ? "- " + this.model.title : "+ " + this.model.title;
    }

    // #region Supporting

    /**
     * An observer to listen to changes in the revision collection. Use this to create or remove
     * revision viewmodels in response to changes in the revision model collection.
     */
    private revisionsObserverDispose = observe(this.model.revisions, (revisionChanges: any) => {
        for (const addedRevision of revisionChanges.added) {
            const revisionViewModelToAdd = new RevisionViewModel(addedRevision, this.navigateToRevision);

            this.revisionViewModels.push(revisionViewModelToAdd);

            // SIDE-EFFECT - If the new viewmodel is the current quote, make this
            // the selected revision.
            if (revisionViewModelToAdd.model.id === MODELID_CURRENTQUOTE) {
                this.navigateToRevision.execute(revisionViewModelToAdd);
            }
        }

        for (const removedRevision of revisionChanges.removed) {
            const revisionViewModelToRemove = this.revisionViewModels.find((vm) => vm.model.KEY === removedRevision.KEY);

            if (revisionViewModelToRemove) {
                this.revisionViewModels.remove(revisionViewModelToRemove);
            }
        }
    });

    // #endregion Supporting
}
