import { runInAction } from "mobx";

import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { ProjectDetailsResponse } from "./Shared/ProjectDetailsResponse";
import { ProjectDetailsViewModel } from "../ProjectDetailsViewModel";

export class GETProjectDetailsWithRelatedByIdEndpoint extends EndpointWithoutRequest<ProjectDetailsResponse> {
    private readonly viewModel: ProjectDetailsViewModel;

    constructor(quoteId: string, viewModel: ProjectDetailsViewModel) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.Projects.GetProjectDetailsWithRelatedById.replace(":id", quoteId));
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: ProjectDetailsResponse): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            ProjectDetailsResponse.customerResponsePart(response, this.viewModel);
            ProjectDetailsResponse.notesResponsePart(response, this.viewModel);
            ProjectDetailsResponse.remainingResponsePart(response, this.viewModel);
        });
    }
}
