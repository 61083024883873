import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { PlanningApplicationTableViewModel } from "./PlanningApplicationTableViewModel";
import { useLocation, useParams } from "react-router";
import { PlanningApplicationTableFilter } from "./Components/PlanningApplicationTableFilter";
import { PlanningApplicationTable } from "./Components/PlanningApplicationTable";
import { APICommonComponents } from "Application/Helpers/APICommonComponents";
import { Modal } from "@fluentui/react";
import { AddEditPlanningApplicationView } from "../AddEdit/AddEditPlanningApplicationView";
import { DeletePlanningApplicationView } from "../Delete/DeletePlanningApplicationView";

interface IPlanningApplicationTableViewProps {
    viewModel: PlanningApplicationTableViewModel;
}

export const PlanningApplicationTableView: React.FC<IPlanningApplicationTableViewProps> = observer(({ viewModel }) => {
    // #region Code Behind
    const location = useLocation();
    const params = useParams();

    useEffect(() => {
        const type = params["*"];
        viewModel.updateFilterPlanningApplicationStatus(type!);
        viewModel.loadItems();
    }, [location.pathname]);

    return (
        <>
            <PlanningApplicationTableFilter viewModel={viewModel} />
            <PlanningApplicationTable viewModel={viewModel} />
            {viewModel.showEditModal && (
                <Modal isOpen={viewModel.showEditModal} onDismiss={viewModel.dismissEditModal} styles={{ main: { width: "80%", maxWidth: "1000px" } }}>
                    <AddEditPlanningApplicationView
                        onDismissCommand={viewModel.dismissEditPlanningApplicationCommand}
                        onSubmitSuccessCommand={viewModel.confirmEditPlanningApplicationCommand}
                        id={viewModel.planningApplicationIdToEdit!}
                    />
                </Modal>
            )}
            {viewModel.showDeleteModal && (
                <Modal isOpen={viewModel.showDeleteModal} onDismiss={viewModel.dismissDeleteModal} styles={{ main: { width: "400px", maxWidth: "400px" } }}>
                    <DeletePlanningApplicationView
                        onDismissCommand={viewModel.dismissDeletePlanningApplicationCommand}
                        onSubmitCommand={viewModel.confirmDeletePlanningApplicationCommand}
                        message={`You are about to delete the planning application for  ${viewModel.planningApplicationToDelete!.projectTitle}.`}
                    />
                </Modal>
            )}
            {APICommonComponents(viewModel.apiClient).renderBusy()}
            {/* Error Overlay */}
            {APICommonComponents(viewModel.apiClient).renderError()}
        </>
    );
});
