import { runInAction } from "mobx";

import { endOfDay, Endpoint, Http, startOfDay } from "Application";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { TimesheetViewModel } from "../Table/TimesheetViewModel";
import { TimesheetItemModel } from "../Table/TimesheetItemModel";
import { TimesheetItemViewModel } from "../Table/TimesheetItemViewModel";
import { TimesheetModel } from "../Table/TimesheetModel";

class Request {
    public filterStartDate: Date | null = null;
    public filterEndDate: Date | null = null;
}

class Response {
    public billedAssignments: BilledAssignedProjectsLiteResponse[] = [];
    public totalBillableEffort: number = 0;
    public totalBilledEffort: number = 0;
}

class BilledAssignedProjectsLiteResponse {
    public id: string = "";
    public projectTaskAssignmentStatusId = "";
    public projectTaskId = "";
    public projectLeadId = "";
    public billedDate: string | null = null;
    public completedDate: string | null = null;
    public billableEffort: number | null = null;
    public billedEffort = 0;
    public reference = "";
    public originReference = "";
    public title = "";
    public projectTaskAssignmentStatusName = "";
    public projectTaskAssignmentStatusForegroundColor = "";
    public projectTaskName = "";
    public projectLeadFirstName = "";
    public projectLeadLastName = "";
    public projectLeadDocumentUrl = "";
}

export class GETAllBilledProjectTaskAssignmentsEndpoint extends Endpoint<Request, Response> {
    private viewModel: TimesheetViewModel;

    constructor(viewModel: TimesheetViewModel) {
        super();
        this.viewModel = viewModel;
        this.verb(Http.Post);
        this.path(AppUrls.Server.Resources.AllBilledProjectTaskAssignments);
    }

    public async HandleRequestAsync(model: TimesheetModel): Promise<any> {
        let request = new Request();

        request.filterStartDate = startOfDay(this.viewModel.model.filterStartDate) ?? null;
        request.filterEndDate = endOfDay(this.viewModel.model.filterEndDate) ?? null;

        return await Promise.resolve(request);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            const tasks: TimesheetItemViewModel[] = [];

            response.billedAssignments.forEach((element: any) => {
                const model = new TimesheetItemModel();

                model.fromResponse(element);

                tasks.push(new TimesheetItemViewModel(model));
            });

            this.viewModel.tasks.replace(tasks);

            this.viewModel.model.totalBillableEffort = response.totalBillableEffort;
            this.viewModel.model.totalBilledEffort = response.totalBilledEffort;
        });

        return Promise.resolve(response);
    }
}
