import { SVGProps } from "react";

export const FileIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={30} {...props}>
        <g data-name="Group 5364">
            <g data-name="Group 5240">
                <path data-name="Union 42" d="M17.985 0H.02L0 30h23.96L24 6Z" fill="#17a2ff" />
            </g>
            <path data-name="Path 5662" d="m18 0 6 6h-6Z" fill="#b0d4eb" />
        </g>
    </svg>
);
