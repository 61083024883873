import { isNullOrUndefined, ViewModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

import { ICommand, RelayCommand } from "Application";
import { RevisionModel } from "./RevisionModel";

export class RevisionViewModel extends ViewModelBase<RevisionModel> {
    private parentNavigateToRevision: ICommand;
    public isActive = false;

    constructor(revisionModel: RevisionModel = new RevisionModel(), parentNavigateToRevision: ICommand) {
        super(revisionModel);

        makeObservable(this, {
            // Observables
            isActive: observable,
        });

        this.parentNavigateToRevision = parentNavigateToRevision;
    }

    // #region Properties

    public get displayName() {
        return isNullOrUndefined(this.model.revision) ? "Original" : `Revision ${this.model.revision}`;
    }

    public get KEY() {
        return this.model.KEY;
    }

    // #endregion Properties

    // #region Commands

    public navigateToRevision = new RelayCommand(() => this.parentNavigateToRevision.execute(this));

    // #endregion Commands

    // #region Actions

    public setActive = (active: boolean) => {
        this.isActive = active;
    };

    // #endregion Actions
}
