import { ViewModelBase } from "@shoothill/core";
import { RelayCommand } from "Application";
import { makeObservable } from "mobx";

import { LeaveStatusType } from "Views/Leave/Shared/LeaveStatusType";
import { LeaveCalendarViewModel } from "Views/Leave/Staff/Calendar/LeaveCalendarViewModel";
import { LeaveRequestEventModel } from "./LeaveRequestEventModel";

export class LeaveRequestEventViewModel extends ViewModelBase<LeaveRequestEventModel> {
    private parentLeaveCalendarViewModel: LeaveCalendarViewModel;

    constructor(model: LeaveRequestEventModel, leaveCalendarViewModel: LeaveCalendarViewModel) {
        super(model, false);

        this.parentLeaveCalendarViewModel = leaveCalendarViewModel;

        makeObservable(this, {});
    }

    //#region Properties

    public get displayName() {
        return this.model.leaveRequest?.leaveType?.name ?? "";
    }

    public get displayStatusName() {
        return this.model.leaveRequest?.leaveStatusType?.name ?? "";
    }

    public get displayNumberOFDays() {
        return this.model.leaveRequest?.numberOfDays ? `(${Math.abs(this.model.leaveRequest?.numberOfDays)} days leave)` : "";
    }

    public get backgroundColor() {
        return this.model.leaveRequest?.leaveType?.backgroundColor ?? "#dff6dd";
    }

    public get isPendingRequest() {
        return this.model.leaveRequest?.leaveStatusType?.type === LeaveStatusType.PENDING;
    }

    // #endregion Properties

    // #region Commands

    public editLeaveCommand = new RelayCommand(
        () => this.parentLeaveCalendarViewModel.displayEditRequestCommand.execute(this.model.leaveRequest),
        () => this.parentLeaveCalendarViewModel.displayEditRequestCommand.canExecute() && this.model.canEdit,
    );

    public deleteLeaveCommand = new RelayCommand(
        () => this.parentLeaveCalendarViewModel.displayDeleteRequestCommand.execute(this.model.leaveRequest),
        () => this.parentLeaveCalendarViewModel.displayDeleteRequestCommand.canExecute() && this.model.canDelete,
    );

    public cancelLeaveCommand = new RelayCommand(
        () => this.parentLeaveCalendarViewModel.displayCancelRequestCommand.execute(this.model.leaveRequest),
        () => this.parentLeaveCalendarViewModel.displayCancelRequestCommand.canExecute() && this.model.canCancel,
    );

    // #endregion Commands
}
