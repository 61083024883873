import styled from "@emotion/styled";
import { IButtonStyles, ITextFieldStyles } from "@fluentui/react";
import { theme } from "Application";

export const ThemedButtonStyles = {
    root: {
        height: 30,
        width: 147,
        fontSize: 17,
    },
};

export const ArrowButtonHeader = styled.div`
    display: grid;
    grid-template-columns: 27px 27px;
    column-gap: 2px;
`;

interface IButtonContainerProps {
    disabled?: boolean;
}

export const ButtonContainer = styled.div<IButtonContainerProps>`
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    :hover {
        cursor: ${(props) => (props.disabled ? "cursor" : "pointer")};
        opacity: ${(props) => (props.disabled ? 0.3 : 0.7)};
    }
`;

interface IArrowBaseProps {
    isDisabled: boolean;
}

export const ArrowBase = styled.div<IArrowBaseProps>`
    border: 2px solid;
    border-color: ${(props) => (props.isDisabled ? theme.palette.disabled.main : theme.palette.blueAccent.main)};
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
`;

export const ArrowUp = styled(ArrowBase)`
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    margin-top: 2px;
`;

export const ArrowDown = styled(ArrowBase)`
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    margin-bottom: 2px;
`;

//draw an x using css

export const CloseButton = styled(ButtonContainer)`
    width: 20px;
    height: 20px;
    content: "";
    position: relative;

    opacity: ${(props) => (props.disabled ? 0.3 : 1)};

    &:after {
        width: 2px;
        height: 20px;
        background-color: ${() => theme.palette.default.main};
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) rotate(45deg);
        content: "";
    }

    &:before {
        width: 2px;
        height: 20px;
        background-color: ${() => theme.palette.default.main};
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) rotate(135deg);
        content: "";
    }
`;

export const AccordionButton = styled(ButtonContainer)`
    width: 27px;
    height: 27px;
    content: "";
    position: relative;
    padding: 5px;

    &:after {
        width: 2px;
        height: 10px;
        background-color: ${() => theme.palette.default.main};
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) rotate(90deg);
        content: "";
        transition: transform 0.1s ease-in-out;
    }

    &:before {
        width: 2px;
        height: 10px;
        background-color: ${() => theme.palette.default.main};
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%);
        content: "";
        transition: transform 0.1s ease-in-out;
    }

    &.expanded {
        &:after {
            transform: translateY(-50%) rotate(270deg);
        }
        &:before {
            transform: translateY(-50%) rotate(90deg);
        }
    }
`;

export const PseudoTableHeader = styled.div`
    padding: 0px 15px;
    min-width: 850px;
    background-color: #f3f3f3;
    display: grid;
    grid-template-columns: 1fr 250px repeat(2, 150px) 65px;
    cursor: default;

    & > * {
        &:first-child {
            justify-content: flex-start;
        }

        padding: 15px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export const PseudoTableCell = styled.div`
    display: flex;
    align-items: baseline;
`;

export const ServicesListContainer = styled.div`
    height: 0px;
    transition: all 0.3s cubic-bezier(0.94, 0.06, 0.52, 0.49);

    & > ul {
        height: 0px;
        transition: all 0.3s cubic-bezier(0.94, 0.06, 0.52, 0.49) 1s;

        & > li:nth-of-type(even) {
            background-color: #f9f9f9;
        }
    }

    &.expanded {
        height: fit-content;
        & > ul {
            height: fit-content;
        }
    }
`;

interface IHeaderProps {
    isUpdatingName: boolean;
}

export const Header = styled.div<IHeaderProps>`
    display: grid;
    min-width: 850px;
    grid-template-columns: ${(props) => (props.isUpdatingName ? "minmax(225px, 625px) 1fr" : "56px 20px minmax(225px, 625px) 1fr")};
    padding-left: ${(props) => (props.isUpdatingName ? "70px" : "15px")};
    padding-right: 15px;
    padding-top: ${(props) => (props.isUpdatingName ? "0px" : "10px")};
    align-items: center;
    column-gap: 5px;
    margin-bottom: ${(props) => (props.isUpdatingName ? "10px" : "0px")};
    width: 100%;
    border-bottom: ${(props) => (props.isUpdatingName ? "1px dashed #ff4b22" : "1px dashed #cccccc")};
    border-top: ${(props) => (props.isUpdatingName ? "1px dashed #ff4b22" : "none")};
    border-left: ${(props) => (props.isUpdatingName ? "1px dashed #ff4b22" : "none")};
    border-right: ${(props) => (props.isUpdatingName ? "1px dashed #ff4b22" : "none")};
    padding-bottom: 10px;
`;

export const CollapsibleContainer = styled.div`
    padding: 0px;
    height: 0px;
    overflow: hidden;
    transition: all 0.3s cubic-bezier(0.94, 0.06, 0.52, 0.49);

    & > * {
        visibility: none;
        transition: all 0.3s cubic-bezier(0.94, 0.06, 0.52, 0.49) 1s;
    }

    &.expanded {
        padding: 18px 0px;
        height: fit-content;
    }

    &.expanded > * {
        visibility: visible;
    }

    background-color: white;
`;

export const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${theme.palette.common.border};
    content: "";
`;

export const buttonResetStyles: IButtonStyles = {
    root: {
        backgroundColor: "transparent",
        padding: "0px 0px",
        minWidth: "0px",
        height: 27,
        borderWidth: 0,
        borderColor: "none",
        borderRadius: 0,
    },
    rootFocused: {
        backgroundColor: "transparent",
        borderColor: "none",
        "::after": {
            outlineColor: "none",
        },
    },
    rootHovered: {
        backgroundColor: "transparent",
        opacity: 0.9,
        borderColor: "transparent",
    },
    rootPressed: {
        backgroundColor: "transparent",
        borderColor: "transparent",
        opacity: 0.9,
    },
    rootDisabled: {
        borderColor: "transparent",
        backgroundColor: "transparent",
    },
};

export const arrowButtonStyleOverride: IButtonStyles = {
    root: {
        backgroundColor: "transparent",
        padding: "0px 0px",
        minWidth: "0px",

        height: 27,
        borderColor: theme.palette.blueAccent.light,
        borderRadius: 0,
    },
    rootFocused: {
        backgroundColor: "transparent",
        borderColor: theme.palette.blueAccent.light,
        "::after": {
            outlineColor: theme.palette.blueAccent.light,
        },
    },
    rootHovered: {
        backgroundColor: "transparent",
        opacity: 0.7,
        borderColor: theme.palette.blueAccent.light,
    },
    rootPressed: {
        backgroundColor: "transparent",
        borderColor: theme.palette.blueAccent.light,
        opacity: 0.7,
    },
    rootDisabled: {
        borderColor: theme.palette.disabled.main,
        backgroundColor: "transparent",
    },
};

export const addEditButtonStyleOverride: IButtonStyles = {
    root: {
        backgroundColor: theme.palette.blueAccent.main,
        padding: "0px 0px",
        minWidth: "0px",
        borderWidth: 0,
        height: 20,
        width: 20,
        borderColor: theme.palette.blueAccent.light,
        borderRadius: 0,
    },
    rootFocused: {
        backgroundColor: theme.palette.blueAccent.main,
        borderWidth: 0,
        "::after": {
            outlineColor: theme.palette.blueAccent.light,
        },
    },
    rootHovered: {
        backgroundColor: theme.palette.blueAccent.main,
        opacity: 0.7,
        borderWidth: 0,
    },
    rootPressed: {
        backgroundColor: theme.palette.blueAccent.main,
        borderWidth: 0,
        opacity: 0.7,
    },
    rootDisabled: {
        borderWidth: 0,
        backgroundColor: theme.palette.disabled.main,
    },
};

export const removeCancelButtonStyleOverride: IButtonStyles = {
    root: {
        backgroundColor: "transparent",
        padding: "0px 0px",
        minWidth: "0px",
        borderWidth: 0,
        height: 20,
        width: 20,
        borderColor: theme.palette.blueAccent.light,
        borderRadius: 0,
    },
    rootFocused: {
        backgroundColor: "none",
        borderWidth: 0,
        "::after": {
            outlineColor: "transparent",
        },
    },
    rootHovered: {
        backgroundColor: "none",
        opacity: 0.9,
        borderWidth: 0,
    },
    rootPressed: {
        backgroundColor: "none",
        borderWidth: 0,
        opacity: 0.9,
    },
    rootDisabled: {
        borderWidth: 0,
        backgroundColor: "none",
    },
};

export const controlStyles: Partial<ITextFieldStyles> = {
    root: {
        fontSize: "17px",
        borderColor: "#E1E1E1",
        paddingTop: 0,
    },
    subComponentStyles: {
        label: {
            paddingTop: 0,
        },
    },
    fieldGroup: {
        height: "39px",
    },
};

interface INewServiceGroupProps {}

export const NewServiceContainer = styled.div<INewServiceGroupProps>`
    display: grid;

    @media only screen and (min-width: 1390px) {
        grid-template-columns: 30% repeat(2, 10%) repeat(2, 20%);
    }

    @media only screen and (max-width: 1389px) {
        row-gap: 10px;
    }

    padding: 0px 15px;
    min-height: 100px;
    padding-top: 10px;
    column-gap: 30px;
    margin-top: 50px;
    margin-bottom: 0px;
    width: 100%;
    border: 1px dashed #ff4b22;
    position: relative;

    .ms-TooltipHost {
        padding-top: 0;
    }
`;

export const NewServiceButtonGroup = styled.div`
    @media only screen and (min-width: 1389px) {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 15px;
        margin-top: 5px;
    }

    @media only screen and (max-width: 1389px) {
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 15px;
        margin-top: 5px;
        margin-bottom: 15px;
        width: 25%;
    }
`;
