import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { LookupStore } from "Stores/Domain/LookupStore";
import { container } from "tsyringe";
import { AutoGrid, Box, ThemedCheckBox, ThemedComboBox, ThemedButton, ThemedEditText, ThemedEditDate, ThemedRadioButtons, setPageTitle } from "Application";
import { TestViewModel } from "./TestViewModel";

export const TestView: React.FC = observer(() => {
    const [viewModel] = useState(() => new TestViewModel());
    const lookupStore = container.resolve(LookupStore);

    useEffect(() => {
        setPageTitle("Test");
    }, []);
    return (
        <Box p={3}>
            <AutoGrid dc="1fr 1fr 1fr">
                <ThemedEditText
                    displayName={"Erik"}
                    command={viewModel.updateErikCommand}
                    value={() => viewModel.getValue("erik")}
                    validationMessage={() => viewModel.getError("erik")}
                />
            </AutoGrid>
        </Box>
    );
});
