import { ModelBase, isEmptyOrWhitespace } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { nanoid } from "nanoid";
import { Validator } from "Application/Validation";
import { SOURCE_OF_ENQUIRY_OTHER } from "../Constants";

export class SourceOfEnquiryModel extends ModelBase<SourceOfEnquiryModel> {
    public KEY: string = nanoid();
    public backupCopy: SourceOfEnquiryModel | null = null;
    public id: string | null = null;
    public sourceOfEnquiryTypeId: string = "";
    public detail: string | null = null;
    public enquiryId: string = "";

    //region validation
    public sourceOfEnquiryTypeOtherId: string = "";

    //endregion validation

    constructor() {
        super();
        makeObservable(this, {
            // Observables
            sourceOfEnquiryTypeId: observable,
            detail: observable,
        });
    }

    // #region Helpers

    public backup = (): SourceOfEnquiryModel => {
        const model = new SourceOfEnquiryModel();

        model.KEY = model.KEY;
        model.sourceOfEnquiryTypeId = this.sourceOfEnquiryTypeId;
        model.detail = this.detail;
        model.enquiryId = this.enquiryId;

        this.backupCopy = model;

        return model;
    };

    public restore = (): SourceOfEnquiryModel => {
        if (this.backupCopy) {
            this.KEY = this.backupCopy.KEY;
            this.sourceOfEnquiryTypeId = this.backupCopy.sourceOfEnquiryTypeId;
            this.detail = this.backupCopy.detail;
            this.enquiryId = this.backupCopy.enquiryId;
        }

        return this;
    };

    // #endregion Helpers
}

export class SourceOfEnquiryModelValidator extends Validator<SourceOfEnquiryModel> {
    constructor() {
        super();

        this.ruleFor("sourceOfEnquiryTypeId").notNull().withMessage("Please select a source of enquiry").notEmpty().withMessage("Please select a source of enquiry");

        this.ruleFor("detail").must({
            predicate: (detail, model) => {
                if (model.sourceOfEnquiryTypeId === model.sourceOfEnquiryTypeOtherId) {
                    if (isEmptyOrWhitespace(detail)) {
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            },
            message: (value, model) => "Please provide another reason",
        });
    }
}
