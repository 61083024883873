export class GlobalAppUrls {
    public Client = {
        DashboardRoot: "/dashboard",
        DashboardAdmin: {
            Overview: "/dashboard/admin/overview",
            QuotesAwaitingAction: "/dashboard/admin/quotesawaitingaction",
            MyProjects: "/dashboard/admin/myprojects",
            UpdatedTasks: "/dashboard/admin/updatedtasks",
            TasksToReview: "/dashboard/admin/completedassignedtasks",
            TasksInProgress: "/dashboard/admin/inprogressassignedtasks",
            PendingLeaveRequests: "/dashboard/admin/leave/requests",
        },
        DashboardStaff: {
            Home: "/dashboard/staff/home",
            MyWork: "/dashboard/staff/mywork",
            Contacts: "/dashboard/staff/contacts",
            WeeklyUpdate: "/dashboard/staff/weeklyupdate",

            Leave: "/dashboard/staff/leave",
            LeaveCalendar: "/dashboard/staff/leave/calendar",
            LeaveRequests: "/dashboard/staff/leave/requests",
        },
        Theme: "/theme",
        StyleSheet: "/stylesheet",
        ColoursMUI: "/coloursmui",
        Error: "/error",
        NotFound: "/notfound",
        PrivacyPolicy: "/privacy-policy",
        Terms: "/terms",
        Test: "/test",
        Account: {
            Login: "/account/login",
            Logout: "/logout",
            ForgotPassword: "/account/forgotpassword",
            ForgotPasswordSuccess: "/account/forgotpassword-success",
            ResetPassword: "/account/resetpassword/:token",
            Register: "/account/register",
            ConfirmEmail: "/account/confirmemail/:token",
            SessionExpired: "/sessionexpired",
        },
        // Customers below...
        Customers: {
            Table: "/customers",
            Details: "/customer/details/:id",
            New: "/customer/edit/new",
            Edit: "/customer/edit/:id",
        },

        Suppliers: {
            Table: "/suppliers",
            Details: "/suppliers/details/:id",
            New: "/suppliers/edit/new",
            Edit: "/suppliers/edit/:id",
        },

        PlanningApplication: {
            Table: {
                Root: "/planning/*",
                Live: "/planning/live",
                Approved: "/planning/approved",
                Refused: "/planning/refused",
                Withdrawn: "/planning/withdrawn",
            },
            New: "/planning/edit/new",
            Edit: "/planning/edit/:id",
        },

        // New Business below...
        NewBusinessRoot: "/newbusiness",
        Enquiries: {
            Table: "/newbusiness/enquiries",
            New: "/newbusiness/enquiry/new",
            Edit: "/newbusiness/enquiry/:id",
            NewCustomer: "/newbusiness/enquiry/newcustomer/:customerId",
        },
        Quotes: {
            Table: "/newbusiness/quotes",
            New: "/newbusiness/quote/new",
            Edit: "/newbusiness/quote/:id",
            NewCustomer: "/newbusiness/quote/newcustomer/:customerId/:isQuickQuote",
        },
        Lost: {
            Table: "/newbusiness/lost",
        },

        // Projects below...
        ProjectRoot: "/projects",
        Projects: {
            Table: "/projects/projects",
            Details: "/projects/details/:id",
            Overview: "/projects/overview/:id",
            QuoteHistory: "/projects/quotehistory/:id",
            Tasks: "/projects/tasks/:id",
            WeeklyUpdate: "/projects/weeklyupdate/:id",
        },

        // Resources below...
        ResourcesRoot: "/resource",
        Staff: {
            Table: "/resource/staff",
            Details: "/resource/staff/:id",
        },
        AssignedTasks: {
            Table: "/resource/assignedtasks",
            TableComplete: "/resource/completeassignedtasks",
        },
        UnassignedTasks: {
            Table: "/resource/unassignedtasks",
        },
        BilledTasks: {
            Table: "/resource/billedtasks",
        },
        Timesheet: { Table: "/resource/timesheet" },

        // Admin below...
        AdminRoot: "/admin",
        LetterTemplates: {
            Table: "/admin/lettertemplates",
            New: "/admin/lettertemplates/new",
            Edit: "/admin/lettertemplates/:id",
        },
        TermConditionTemplates: {
            Table: "/admin/termconditiontemplates",
            New: "/admin/termconditiontemplates/new",
            Edit: "/admin/termconditiontemplates/:id",
        },
        Services: {
            Table: "/admin/services",
            New: "",
            Edit: "",
        },
        ImageSettings: {
            Table: "/admin/imagesettings",
            New: "",
            Edit: "",
        },
        Users: {
            Table: "/admin/users",
            New: "/admin/user/new",
            Edit: "/admin/user/:id",
        },
        VersionHistory: {
            Table: "/admin/versionhistory",
        },
        Admin: {
            AdminHome: "/admin",
            LogViewer: "/admin/logviewer",
        },
        Leave: {
            LeaveHome: "/admin/leave",
            LeaveCalendar: "/admin/leave/calendar",
            LeaveRequests: "/admin/leave/requests",
        },
        InvoiceForecast: {
            Home: "admin/invoiceforecast",
        },
        InvoiceHistory: {
            Home: "admin/invoicehistory",
        },
        WeeklyUpdates: {
            Table: "/admin/weeklyupdates",
        },
    };

    public Server = {
        Account: {
            Login: "/api/account/login",
            Logout: "/api/account/logout",
            ResetPassword: {
                Reset: "/api/account/resetpassword",
                VerifyToken: "/api/account/resetpasswordtoken",
            },
            SetPassword: "/api/account/setpassword",
            ForgotPassword: "/api/account/forgotpassword",
            Register: "/api/account/register",
            User: {
                DeleteUser: "/api/user/delete/:id",
                DeleteRole: "/api/role/delete",
            },
        },
        Admin: {
            ResetFailedLoginAttemptsCount: "/api/user/ResetLoginAttempts",
            GetRoles: "/api/roles/getall",
            LetterTemplates: {
                GetAllLetterTemplates: "/api/lettertemplate/getalllettertemplateslite",
                PostSaveLetterTemplate: "/api/lettertemplate/savelettertemplate",
                DeleteLetterTemplate: "/api/lettertemplate/delete/:id",
                GetLetterTemplateById: "/api/lettertemplate/getlettertemplatebyid/:id",
            },
            TermConditionTemplates: {
                GetAllTermsAndConditionLite: "/api/termsandcondition/getalltermsandconditionlite",
                PostSaveTermsAndCondition: "/api/termsandcondition/savetermsandcondition",
                DeleteTermsAndCondition: "/api/termsandcondition/delete/:id",
                GetTermsAndConditionById: "/api/termsandcondition/gettermsandconditionbyid/:id",
            },
            ImageSettings: {
                GetAllImageSettingsLite: "/api/imagesettings/getallimagesettingslite",
                PostSaveImageSettings: "/api/imagesettings/saveimagesettings",
                DeleteImageSetting: "/api/imagesettings/delete/:id",
            },
            Users: {
                GetAllUsers: "/api/user/getall",
                GetAllUsersLite: "/api/user/getalluserslite",
                GetUserRelated: "/api/user/getuserrelated",
                GetUserWithRelatedById: "/api/user/getuserwithrelatedbyid/:id",
                PostSaveUser: "/api/user/postsaveuser",
            },
            Services: {
                GetAllServiceGroupsLite: "/api/service/getallservicegroupslite",
                PostUpsertServiceGroup: "/api/service/postupsertservicegroup",
                PostSwapServiceGroupOrdinals: "/api/service/postswapservicegroupordinals",
                PostBulkSwapServiceGroupOrdinals: "/api/service/postbulkswapservicegroupordinals",
                DeleteServiceGroupById: "/api/service/postdeleteservicegroupbyid",
                GetAllServicesLiteByServiceGroupId: "/api/service/getallserviceslitebyservicegroupid/:id",
                PostUpsertService: "/api/service/postupsertservice",
                DeleteServiceById: "/api/service/postdeleteservicebyid",
                PostSwapServiceOrdinals: "/api/service/postswapserviceordinals",
                PostBulkSwapServiceOrdinals: "/api/service/postbulkswapserviceordinals",
            },
            Leave: {
                GetAllPendingLeaveRequests: "/api/leave/getallpendingleaverequests",
                GetAllLeaveRequests: "/api/leave/getleaverequests",
                GetCalendarEvents: "/api/leave/getcalendarevents",
                GetAdminCalenedarEvents: "/api/leave/getadmincalendarevents",

                ApproveLeaveRequest: "/api/leave/approverequest",
                DeleteLeaveRequest: "/api/leave/deleterequest",
                DeclineLeaveRequest: "/api/leave/declinerequest",
                RequestCancelLeave: "/api/leave/requestcancelleave",
                RequestCancelLeaveForUser: "/api/leave/requestcancelleaveforuser",
                RequestLeave: "/api/leave/requestleave",
                RequestLeaveForUser: "/api/leave/requestleaveforuser",

                GetAllLeaveAllowances: "/api/leave/getallleaveallowances",
                ChangeAllowance: "/api/leave/changeallowance",
                ResetAllowances: "/api/leave/resetallowances",
            },
            InvoiceForecast: {
                GetInvoiceForecastAndOverrides: "/api/admin/getadmininvoicingforecastandoverrides",
                GetInvoiceForecastOverridesByFinancialYear: "/api/admin/getadmininvoicingforecastoverridesByfinancialyear",
                UpsertInvoiceForecastOverrides: "/api/admin/upsertinvoicingforecastoverrides",
            },

            ProjectUpdates: {
                GetFailedTransactionProjectUpdates: "/api/project/getfailedtransactionprojectupdates/:id",
                GetUnprocessedTransactionProjectUpdates: "/api/project/getunprocessedtransactionprojectupdates/:id",
                GetProjectUpdateTransactionViewsByProjectUpdateDate: "/api/project/getprojectupdatetransactionviews",
                SendProjectWeeklyUpdates: "/api/project/sendprojectweeklyupdates",
            },
        },
        Customers: {
            GetAllCustomersLite: "/api/customer/getallcustomerslite",
            GetCustomerDetailsById: "/api/customer/getcustomerdetailsbyid/:id",
            GetCustomerContactsLiteByCustomerId: "/api/customer/getcustomercontactslitebycustomerid/:id",
            GetCustomerAndContactsById: "/api/customer/getcustomerandcontactsbyid/:id",

            SoftDeleteCustomerById: "/api/customer/deletecustomer/:id",
            SaveCustomerAndContacts: "/api/customer/saveCustomerAndContacts",
        },

        Suppliers: {
            GetAllSuppliersLite: "/api/supplier/getallsupplierslite",
            GetSupplierDetailsById: "/api/supplier/getsupplierdetailsbyid/:id",
            GetSupplierAndContactsById: "/api/supplier/getsupplierandcontactsbyid/:id",
            SaveSupplierAndContacts: "/api/supplier/saveSupplierAndContacts",
            SoftDeleteSupplierById: "/api/supplier/deletesupplier/:id",
        },
        Note: {
            SaveNote: "/api/note/savenote",
        },
        Enquiries: {
            AllEnquiriesLite: "/api/enquiry/getallenquirieslite",

            GetEnquiryRelated: "/api/enquiry/getenquiryrelated",
            GetEnquiryWithRelatedById: "/api/enquiry/getenquirywithrelatedbyid/:id",
            GetEnquiryRelatedByCustomerId: "/api/enquiry/getenquiryrelatedbycustomerid/:id",

            SaveEnquiry: "/api/enquiry/saveenquiry",
            SaveEnquiryAsLost: "/api/enquiry/saveenquiryaslost",
            SaveEnquiryAsQuote: "/api/enquiry/saveenquiryasquote",
            SaveLostEnquiryAsEnquiry: "/api/enquiry/savelostenquiryasenquiry",
            SaveEnquiryStatus: "/api/enquiry/saveenquirystatus",
            SavePersonVisiting: "/api/enquiry/saveenquirypersonvisiting",
        },
        Dashboard: {
            Admin: {
                GetSummary: "api/dashboard/admin/getsummary",
                GetOverviewSummary: "api/dashboard/admin/getoverviewsummary",
                GetEnquiriesByGivenDates: "api/dashboard/admin/getenquiriesbygivendates",
                GetRunningTotalsByGivenDates: "api/dashboard/admin/getrunningtotalsbygivendates",
                GetTopProjectsAndQuotes: "api/dashboard/admin/gettopquotesandprojects",
                GetAbsenteesByGivenDates: "api/dashboard/admin/getabsenteesbygivendates",
                GetProgrammingDetails: "api/dashboard/admin/getadminprogrammingdetailsbygivendates",
                GetInvoicingForecast: "api/dashboard/admin/getdashboardinvoicingforecast",
                GetInvoicingHistory: "api/dashboard/admin/getdashboardinvoicinghistory",
                GetPlanningApplicationTotals: "api/dashboard/admin/getplanningapplicationtotals",
            },
            Staff: {
                GetProjectTaskAssignmentsWithRelatedLiteByGivenDatesAndUserId: "/api/dashboard/staff/getprojecttaskassignmentswithrelatelitedbygivendatesanduserid",
                GetProjectTaskAssignmentWithRelatedLiteById: "/api/dashboard/staff/getprojecttaskassignmentwithrelatedlitebyid/:projectTaskAssignmentId",
                GetProjectTaskAssignmentGroupWithRelatedLiteById: "/api/dashboard/staff/getprojecttaskassignmentgroupwithrelatedlitebyid/:projectTaskAssignmentGroupId",
                PostInsertProjectTaskAssignmentUserNote: "/api/dashboard/staff/postinsertprojecttaskassignmentusernote",
                PostInsertProjectTaskAssignmentGroupUserNote: "/api/dashboard/staff/postinsertprojecttaskassignmentgroupusernote",
                GetProjectUpdatesWithRelatedLiteByGivenDatesAndProjectLeadId: "/api/dashboard/staff/getprojectupdateswithrelatedlitedbygivendatesandprojectleadid",
            },
        },
        Quotes: {
            AllQuotesLite: "/api/quote/getallquoteslite",
            AllQuotesLiteAwaitingAction: "/api/quote/getallawaitingactionquoteslite",
            AllSentQuotesLite: "/api/quote/getallsentquoteslite",

            GetQuoteRelated: "/api/quote/getquoterelated",
            GetQuoteWithRelatedById: "/api/quote/getquotewithrelatedbyid/:id",
            GetQuoteDocumentById: "/api/quote/getquotedocumentbyid/:id",

            GetQuoteLetterDocumentById: "/api/quote/getquoteletterdocumentbyid/:id/:downloadasblob",
            GetQuoteTasksAndCostsDocumentById: "/api/quote/getquotetasksandcostsdocumentbyid/:id/:downloadasblob",
            GetQuoteTermsAndConditionsDocumentById: "/api/quote/getquotetermsandconditionsdocumentbyid/:id/:downloadasblob",

            GetQuoteHistoryWithRelatedById: "/api/quotehistory/getquotehistorywithrelatedbyid/:quoteHistoryId",
            GetQuoteRelatedByCustomerId: "/api/quote/getquoterelatedbycustomerid/:id",
            GetQuickQuoteRelatedByCustomerId: "/api/quote/getquickquoterelatedbycustomerid/:id",
            GetServicesRelatedByProjectId: "/api/quote/getservicesrelatedbyprojectid/:id",
            BulkSwapTaskOrdinals: "/api/quote/bulkswaptaskordinals",
            DeleteQuote: "/api/quote/deletequote",
            GenerateQuote: "/api/quote/generatequote",
            RevertLostQuote: "/api/quote/revertlostquote",
            SaveQuoteAsApproved: "/api/quote/savequoteasapproved",
            SaveQuote: "/api/quote/savequote",
            SaveQuoteAsDraft: "/api/quote/savequoteasdraft",
            SaveQuoteAsLost: "/api/quote/savequoteaslost",
            SaveQuoteAsPendingApproval: "/api/quote/savequoteaspendingapproval",
            SaveQuoteAsProject: "/api/quote/savequoteasproject",
            SaveQuoteAsRejected: "/api/quote/savequoteasrejected",
            SaveQuoteAsRevised: "/api/quote/savequoteasrevised",
            SaveQuoteAsSent: "/api/quote/savequoteassent",
            SaveQuoteLetter: "/api/quote/savequoteletter",
            SaveQuoteTermsAndConditions: "/api/quote/savequotetermsnandconditions",
            SaveQuoteTasksAndCosts: "/api/quote/savequotetasksandcosts",
            SetQuoteLead: "/api/quote/setquotelead",
        },
        Projects: {
            AllProjectsLite: "/api/project/getallprojectslite",
            AllProjectsLiteByProjectLeadId: "/api/project/getallprojectslitebyprojectleadid",
            GetAllProjectOptions: "/api/project/getallprojectoptions",
            GetProjectSummaryById: "/api/project/getprojectsummarybyid/:id",
            GetProjectDetailsWithRelatedById: "/api/project/getprojectdetailswithrelatedbyid/:id",
            GetProjectTaskGroupOverviewByProjectId: "/api/project/getprojecttaskgroupoverviewbyprojectid/:id",
            GetProjectWeeklyUpdateWithRelatedById: "/api/project/getprojectweeklyupdateswithrelatedbyid/:id",
            GetProjectWeeklyUpdateByUpdateId: "/api/project/getprojectweeklyupdatebyupdateid/:id",

            // Projects/Tasks View
            GetProjectDetailsProgrammingByProjectId: "/api/projecttasks/getprojectprogrammingbyprojectid/:id",
            GetProjectBilledAssignmentsByProjectId: "/api/projecttasks/getprojectbilledassignmentsbyprojectid/:id",
            DeleteProjectTask: "/api/projecttasks/deleteprojecttask",
            DeleteProject: "/api/project/delete/:id",
            SaveProjectAsInstructed: "/api/project/saveprojectasinstructed",
            SaveProjectAsComplete: "/api/project/saveprojectascomplete",
            SaveProjectAsReopened: "/api/project/saveprojectasreopened",
            SaveProjectAsPutOnHold: "/api/project/saveprojectasputonhold",
            SaveProjectAsTakenOffHold: "/api/project/saveprojectastakenoffhold",
            SaveQuoteAsProject: "/api/project/savequoteasproject",
            SaveProjectCustomerContact: "/api/project/saveprojectcustomercontact",
            SetProjectLead: "/api/project/setprojectlead",
            SetProjectSupport: "/api/project/setprojectsupport",
            SetSeniorAssociate: "/api/project/setprojectseniorassociate",
            SaveProjectWeeklyUpdate: "/api/project/saveprojectweeklyupdate",

            SaveProjectTaskGroupStartAndEndDate: "/api/project/saveprojecttaskgroupstartandenddate",
        },
        Resources: {
            AllAssignedProjectTasks: "/api/resources/getallassignedprojectslite",
            AllBilledProjectTaskAssignments: "/api/resources/getallbilledprojecttaskassignmentslite",
            GetFilteredBilledProjectTaskAssignmentsCSV: "/api/resources/getfilteredbilledprojecttaskassignmentscsv",
            AllBilledProjectTasks: "/api/resources/getallbilledprojectslite",
            AllStaffProjectsLite: "/api/resources/getstaffprojectslite",
            AllUnassignedProjectTasks: "/api/resources/getallunassignedprojectslite",

            GetStaffProjectDetailsByUserId: "/api/resources/getstaffprojectdetailsbyuserid",
            GetStaffProjectDetailsPercentageAllocationByUserId: "/api/resources/getstaffprojectdetailspercentageallocationbyuserid",
            GetStaffProjectDetailsProgrammingByUserId: "/api/resources/getstaffprojectdetailsprogrammingbyuserid",
            GetStaffProjectDetailsWeeklyUpdateByProjectId: "/api/resources/getstaffprojectdetailsweeklyupdatebyprojectid",

            GetProjectTaskAssignmentWithRelatedById: "/api/resources/getprojecttaskassignmentwithrelatedbyid/:projectTaskAssignmentId",
            GetAllProjectTaskAssignmentsWithRelatedByProjectTaskId: "/api/resources/getallprojecttaskassignmentswithrelatedbyprojecttaskid/:projectTaskId",
            GetAllProjectTaskAssignmentsWithRelatedLiteByGivenDatesAndUserId: "/api/resources/getprojecttaskassignmentswithrelatelitedbygivendatesanduserid",
            GetProjectTaskAssignmentRelated: "/api/resources/getprojecttaskassignmentrelated/:projectTaskId",

            SaveProjectAsPutOnHold: "/api/resources/saveprojectasputonhold",
            SaveProjectAsTakenOffHold: "/api/resources/saveprojectastakenoffhold",

            SaveProjectTaskAssignment: "/api/resources/saveprojecttaskassignment",
            SaveProjectTaskAssignmentAsAssigned: "/api/resources/saveprojecttaskassignmentasassigned",
            SaveProjectTaskAssignmentAsBilled: "/api/resources/saveprojecttaskassignmentasbilled",
            SaveProjectTaskAssignmentAsBilledAndNonBillable: "/api/resources/saveprojecttaskassignmentasbilledandnonbillable",
            SaveProjectWeeklyUpdate: "/api/resources/saveprojectweeklyupdate",
            SaveProjectWeeklyUpdateArchitect: "/api/resources/saveprojectweeklyupdatearchitect",

            GetProjectTaskRelated: "/api/resources/getprojecttaskrelated/:projectId",
            SaveProjectAdHocTask: "/api/resources/saveprojectadhoctask",
            DeleteProjectTaskAssignmentById: "/api/resources/deleteprojecttaskassignmentbyid",

            SaveTaskAssignmentGroup: "/api/resources/saveprojecttaskassignmentgroup",
            DeleteProjectTaskAssignmentGroupById: "/api/resources/deleteprojecttaskassignmentgroup",
            GetFilteredUnassignedProjectTaskAssignmentsXLSX: "/api/resources/getfilteredunassignedprojecttaskassignmentsxlsx",
        },
        ProjectQuoteHistory: {
            GetQuoteHistoryContainerByProjectId: "/api/projectquotehistory/getquotehistorycontainerbyprojectid/:projectId",
        },
        PlanningApplication: {
            GetPlanningApplicationAndRelatedById: "/api/planningapplication/getplanningapplicationandrelatedbyid/:id",
            GetPlanningApplicationTableItemsByStatusType: "/api/planningapplication/getplanningapplicationtableitemsbystatustype/:statustype",
            GetFilteredPlanningApplicationsCSV: "/api/planningapplication/getfilteredplanningapplicationscsv",
            UpsertPlanningApplication: "/api/planningapplication/upsertplanningapplication",
            Delete: "/api/planningapplication/delete",
        },
    };
}
export const AppUrls = new GlobalAppUrls();
