import { isNullOrUndefined, ModelBase } from "@shoothill/core";
import { action, makeObservable, observable } from "mobx";
import moment from "moment";

import { Validator } from "Application/Validation";
import { ProjectTaskAssignmentTemplateNote } from "Views/Shared/ProjectTaskAssignmentTemplateNote/ProjectTaskAssignmentTemplateNoteModel";
import { User } from "Application/Models/Domain/User";

export class AssignTaskModel extends ModelBase<AssignTaskModel> {
    public id: string = "";
    public reference: string = "";
    public title: string = "";
    public projectTaskGroupName = "";
    public projectTaskName = "";
    public projectTaskIsBilledHourly = false;

    public userId: string | null = null;
    public effort: number | null = null;
    public billableEffort: number | null = null;
    public isNonBillableEffort: boolean = false;
    public plannedStartDate: Date | undefined = undefined;
    public plannedEndDate: Date | undefined = undefined;
    public note: string | null = null;

    public projectTaskAssignmentTemplateNotes = observable<ProjectTaskAssignmentTemplateNote>([]);

    // This is to be used as a constraint on the number of billable effort
    // a user can set for a task assignment.
    public maximumBillableEffort: number = 0;

    public users = observable<User>([]);

    //used to determine if the task is assigned
    public readonly initialAssigneeUserId: string | null = null;

    constructor(initialAssigneeUserId: string | null) {
        super();

        makeObservable(this, {
            id: observable,
            reference: observable,
            title: observable,
            projectTaskGroupName: observable,
            projectTaskName: observable,
            projectTaskIsBilledHourly: observable,
            userId: observable,
            effort: observable,
            billableEffort: observable,
            isNonBillableEffort: observable,
            plannedStartDate: observable,
            plannedEndDate: observable,
            note: observable,
        });
        this.initialAssigneeUserId = initialAssigneeUserId;
    }
}

export class AssignTaskModelValidator extends Validator<AssignTaskModel> {
    constructor() {
        super();

        // -----------------------------------------------------------------------------------------------------
        // Changes to the logic here, must also be made to:
        // Resource\UnassignedTasks\Table\AssignTaskSubViews\AssignTaskModel.ts
        // except for the user selection.
        // -----------------------------------------------------------------------------------------------------
        this.ruleFor("effort").notNull().withMessage("This field cannot be left empty").greaterThanOrEqualTo(0);
        this.ruleFor("billableEffort")
            .must({
                predicate: (billableEffort, model) => {
                    // -----------------------------------------------------------------------------------------------------
                    // Must be a non-zero amount.
                    // -----------------------------------------------------------------------------------------------------
                    return (
                        model.projectTaskIsBilledHourly ||
                        (!isNullOrUndefined(billableEffort) && billableEffort! >= 0 && model.isNonBillableEffort) ||
                        (!isNullOrUndefined(billableEffort) && billableEffort! > 0 && !model.isNonBillableEffort)
                    );
                },
                message: () => {
                    return "Unless marked as NCT, The chargeable amount must be greater than 0 hrs.";
                },
            })
            .must({
                predicate: (billableEffort, model) => {
                    // -----------------------------------------------------------------------------------------------------
                    // IsNCT    | Result
                    // -----------------------------------------------------------------------------------------------------
                    // true     | Can charge anything.
                    // false    | Cannot charge more than the original billable effort on this assignment.
                    // -----------------------------------------------------------------------------------------------------
                    return model.projectTaskIsBilledHourly || model.isNonBillableEffort || (!isNullOrUndefined(billableEffort) && billableEffort! <= model.maximumBillableEffort);
                },
                message: (billableEffort, model) => {
                    return `The chargeable amount cannot be more than ${model.maximumBillableEffort} hrs.`;
                },
            });
        this.ruleFor("plannedStartDate").notNull().withMessage("Please provide a start date");
        this.ruleFor("plannedEndDate")
            .notNull()
            .withMessage("Please provide an end date")
            .must({
                predicate: (plannedEndDate, model) => moment(plannedEndDate).isAfter(moment(model.plannedStartDate)),
                message: () => "Date must be later than start date",
            });
    }
}
