import { runInAction } from "mobx";

import { EndpointWithoutRequest, Http } from "Application";
import { AppUrls } from "AppUrls";
import { ProjectViewModel } from "./ProjectViewModel";

class Response {
    id: string = "";
    reference: string = "";
    projectStatusId: string = "";
    title: string = "";
    leadInWeeks: number = 0;
    durationWeeks: number = 0;
    hours: number = 0;
    value: number = 0;
    remainingHours: number = 0;
    remainingValue: number = 0;
    projectLeadId: string | null = null;
    projectLead: ProjectLeadResponse | null = null;
}

class ProjectLeadResponse {
    id: string = "";
    firstName: string = "";
    lastName: string = "";
    thumbnailDocumentUrl: string = "";
}

export class GETProjectSummaryByIdEndpoint extends EndpointWithoutRequest<Response> {
    private viewModel: ProjectViewModel;

    constructor(projectId: string, viewModel: ProjectViewModel) {
        super();
        this.viewModel = viewModel;
        this.verb(Http.Get);
        this.path(AppUrls.Server.Projects.GetProjectSummaryById.replace(":id", projectId));
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        runInAction(() => {
            this.viewModel.model.fromResponse(response);
        });
    }
}
