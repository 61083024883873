import { ViewModelBase } from "@shoothill/core";
import { makeObservable, observable, runInAction } from "mobx";
import { container } from "tsyringe";

import { APIClient, ICommandAsync, RelayCommand, RelayCommandAsync } from "Application";
import { AppUrls } from "AppUrls";
import { GlobalHistory } from "index";
import { LookupStore } from "Stores/Domain";
import { GetDashboardSummaryEndpoint } from "./Admin/Endpoints/GETDashboardSummaryEndpoint";
import { DashboardModel } from "./DashboardModel";

export class DashboardViewModel extends ViewModelBase<DashboardModel> {
    public apiClient = new APIClient();
    public locationPathName: string = "";
    public lookupStore = container.resolve(LookupStore);

    private historyUnlisten?: any;

    constructor(model = new DashboardModel()) {
        super(model, false);

        makeObservable(this, {
            locationPathName: observable,
        });

        this.historyUnlisten = GlobalHistory.listen((locationUpdate) => runInAction(() => this.setLocationPathName(locationUpdate.location.pathname)));
        this.setLocationPathName(location.pathname);

        const _ = this.apiClient.sendAsync(new GetDashboardSummaryEndpoint(this));
    }

    public dispose = (): void => {
        this.historyUnlisten?.();
    };

    private setLocationPathName = (pathName: string) => {
        this.locationPathName = pathName;
    };

    // #region API Calls

    public syncDashboardCommand: ICommandAsync = new RelayCommandAsync(async () => {
        await this.apiClient.sendAsync(new GetDashboardSummaryEndpoint(this));
    });

    // #endregion API Calls

    // #region Staff Navigation

    public setStaffDashboardHomeTabCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.DashboardStaff.Home);
    });

    public setStaffDashboardMyWorkTabCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.DashboardStaff.MyWork);
    });

    public setStaffDashboardContactsTabCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.DashboardStaff.Contacts);
    });

    public setStaffDashboardWeeklyUpdateTabCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.DashboardStaff.WeeklyUpdate);
    });

    public setStaffDashboardLeaveTabCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.DashboardStaff.Leave);
    });

    // #endregion Staff Navigation

    // #region Admin Navigation

    public setAdminDashboardOverviewTabCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.DashboardAdmin.Overview);
    });

    public setAdminQuotesAwaitingActionTabCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.DashboardAdmin.QuotesAwaitingAction);
    });

    public setAdminMyProjectsTabCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.DashboardAdmin.MyProjects);
    });

    public setAdminUpdatedTasksTabCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.DashboardAdmin.UpdatedTasks);
    });

    public setAdminTasksInProgressTabCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.DashboardAdmin.TasksInProgress);
    });

    public setAdminTasksToReviewTabCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.DashboardAdmin.TasksToReview);
    });

    public setAdminPendingLeaveRequestsTabCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.DashboardAdmin.PendingLeaveRequests);
    });

    // #endregion Admin Navigation

    // #region User Roles

    public get isAdmin() {
        return this.lookupStore.AccountStore.isInRole("admin");
    }

    public get isBusinessSupport() {
        return this.lookupStore.AccountStore.isInRole("businesssupport");
    }

    public get isSeniorAssociate() {
        return this.lookupStore.AccountStore.isInRole("seniorassociate");
    }

    public get isArchitect() {
        return this.lookupStore.AccountStore.isInRole("architect");
    }

    // #endregion User Roles

    // #region Display Permissions

    public get showHello() {
        return this.isArchitect;
    }

    public get showMyProjects() {
        return this.isAdmin || this.isSeniorAssociate;
    }

    public get showMyWork() {
        return this.isAdmin || this.isSeniorAssociate || this.isArchitect;
    }

    public get showWeeklyUpdate() {
        return this.isArchitect;
    }

    public get showLeave() {
        return this.isAdmin || this.isSeniorAssociate || this.isArchitect || this.isBusinessSupport;
    }

    public get showPendingLeaveRequests() {
        return this.isAdmin || this.isSeniorAssociate || this.isBusinessSupport;
    }

    public get showQuotesAwaitingApproval() {
        return this.isAdmin || this.isSeniorAssociate || this.isBusinessSupport;
    }

    public get showTasksInProgress() {
        return this.isAdmin || this.isSeniorAssociate || this.isBusinessSupport;
    }

    public get showUpdatedTasks() {
        return this.isAdmin || this.isSeniorAssociate;
    }

    public get showCompletedTasks() {
        return this.isAdmin || this.isSeniorAssociate;
    }

    public get showOverview() {
        return this.isAdmin || this.isSeniorAssociate || this.isBusinessSupport;
    }

    public get defaultTab() {
        if (this.isAdmin || this.isSeniorAssociate || this.isBusinessSupport) {
            return "admin/overview";
        }

        return "staff/home";
    }

    // #endregion Display Permissions

    public get numberOfPendingLeaveRequests() {
        return this.model.numberOfPendingLeaveRequests;
    }

    public get numberOfQuotesAwaitingAction() {
        return this.model.numberOfPendingAndApprovedQuotes;
    }

    public get numberOfInProgressTasks() {
        return this.model.numberOfInProgressProjectTaskAssignments;
    }

    public get numberOfUpdatedTasks() {
        return this.model.numberOfUpdatedProjectTaskAssignments;
    }

    public get numberOfCompleteTasks() {
        return this.model.numberOfCompleteProjectTaskAssignments;
    }
}
