import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import LetterTemplatesListViewModel from "./LetterTemplatesListViewModel";
import { LetterTemplateItemModel } from "./LetterTemplateItemModel";
import { LetterTemplateItemViewModel } from "./LetterTemplateItemViewModel";

export class Response {
    public id: string = "";
    public name: string = "";
    public subject: string = "";
    public body: string = "";
    public createdDate: string = "";
    public createdByUserId: string = "";
    public lastUpdatedDate: string = "";
}

export class GetAllLetterTemplatesEndpoint extends EndpointWithoutRequest<Response> {
    private viewModel: LetterTemplatesListViewModel;

    constructor(viewModel: LetterTemplatesListViewModel) {
        super();

        this.verb(Http.Get);
        this.path(AppUrls.Server.Admin.LetterTemplates.GetAllLetterTemplates);
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: Response[]): Promise<any> {
        const letterTemplates: LetterTemplateItemViewModel[] = [];

        response.forEach((letterTemplate, index) => {
            let letterTemplateModel = new LetterTemplateItemModel();

            letterTemplateModel.fromResponse(letterTemplate);

            letterTemplates.push(new LetterTemplateItemViewModel(letterTemplateModel));
        });

        this.viewModel.letterTemplates.replace(letterTemplates);
    }
}
