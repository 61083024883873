import { observer } from "mobx-react-lite";
import React from "react";

import { ThemedText } from "Application";
import { WeeklyUpdateHistoryViewModel } from "./WeeklyUpdateHistoryViewModel";
import { FormContainerRow, FormSeparator } from "Views/Shared/SharedComponents";

export interface IRevisionViewProps {
    viewModel: WeeklyUpdateHistoryViewModel;
}

export const WeeklyUpdateHistoryView: React.FC<IRevisionViewProps> = observer((props) => {
    const viewModel = props.viewModel;

    return (
        <React.Fragment>
            <FormContainerRow dc={"75%"} rowGap={12}>
                <ThemedText fontStyle="h6" p="5px 0">
                    Weekly update
                </ThemedText>
                <ThemedText>{viewModel.getValue("updateDetail")}</ThemedText>
            </FormContainerRow>
            <FormSeparator />
            <FormContainerRow dc={"100%"}>
                <ThemedText fontStyle="h6">{viewModel.updateDetails}</ThemedText>
            </FormContainerRow>
        </React.Fragment>
    );
});
