import styled from "@emotion/styled";
import { AutoGrid, Box, Nullcommand, Show, ThemedButton, ThemedCheckBox, ThemedComboBox, ThemedText, setPageTitle, theme } from "Application";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { InvoiceHistoryViewModel } from "./InvoiceHistoryViewModel";
import { APICommonComponents } from "Application/Helpers/APICommonComponents";
import moment from "moment";
import { InvoiceHistoryMonthCapacityView } from "./InvoiceHistoryCapacity/InvoiceHistoryMonthCapacityView";

/**
 * Contains the contents of the page.
 */
const PageContainer = styled(Box)`
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
`;

const FormContainer = styled(Box)`
    background-color: ${theme.palette.common.white};
    flex: 1;
    /* padding: 15px 30px; */
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 20px;
`;

const FormSubContainer = styled(Box)`
    flex: 1;
    margin-bottom: 55px;
`;

const TableHeader = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 2fr 2fr 1.5fr;

    background-color: ${theme.palette.tableHeader.main};
    margin-bottom: 10px;

    & > * {
        padding: 10px;
    }

    & > *:not(:first-child) {
        border-left: 1px solid ${theme.palette.tableHeader.light};
    }
`;

const TableRow = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 2fr 2fr 1.5fr;

    & > * {
        height: 60px;
        padding: 0px 10px;
    }

    & > *:not(:first-child) {
        border-left: 1px solid ${theme.palette.field.light};
    }

    border-left: 1px solid ${theme.palette.field.light};
    border-right: 1px solid ${theme.palette.field.light};
    border-bottom: 1px solid ${theme.palette.field.light};

    :first-of-type {
        border-top: 1px solid ${theme.palette.field.light};
    }
`;

const TableBody = styled.div``;

const FormHeader = styled.div`
    display: flex;
    align-items: end;
    justify-content: space-between;
`;

interface IProps {}

export const InvoiceHistoryView: React.FC<IProps> = observer((props: IProps) => {
    const [viewModel] = useState(() => new InvoiceHistoryViewModel());

    useEffect(() => {
        setPageTitle("Invoice History");
        viewModel.init();
    }, []);

    const renderMonthCapacities = () => {
        return viewModel.model.capacities.map((c) => (
            <TableRow key={c.KEY}>
                <InvoiceHistoryMonthCapacityView model={c} isEditable={viewModel.getValue<boolean>("isEditable")} />
            </TableRow>
        ));
    };

    const renderTotal = () => (
        <Box marginTop={15}>
            <TableRow key={viewModel.model.KEY}>
                <Box flexBox alignItems={"center"}>
                    <ThemedText>Total</ThemedText>
                </Box>
                <Box flexBox alignItems={"center"}>
                    <ThemedText>{viewModel.formattedTotalHours}</ThemedText>
                </Box>
                <Box flexBox alignItems={"center"}>
                    <ThemedText>{viewModel.formattedTotalValue}</ThemedText>
                </Box>
            </TableRow>
        </Box>
    );

    return (
        <>
            <PageContainer>
                <FormContainer>
                    <FormHeader>
                        <ThemedComboBox
                            command={viewModel.updateFinancialYearCommand}
                            options={viewModel.financialYears}
                            size="larger"
                            value={() => viewModel.getValue("financialYearFilter")}
                            displayName="Financial Year"
                            styles={{ root: { height: "30px" } }}
                        />
                    </FormHeader>
                    <FormSubContainer>
                        <Box>
                            <TableHeader>
                                <Box>
                                    <ThemedText fontStyle="h5" color="white">
                                        Month
                                    </ThemedText>
                                </Box>
                                <Box>
                                    <ThemedText fontStyle="h5" color="white">
                                        Hours
                                    </ThemedText>
                                </Box>

                                <Box>
                                    <ThemedText fontStyle="h5" color="white">
                                        Value
                                    </ThemedText>
                                </Box>
                            </TableHeader>
                            {/* map over months */}
                            <TableBody>
                                {renderMonthCapacities()}
                                {renderTotal()}
                            </TableBody>
                        </Box>
                    </FormSubContainer>
                </FormContainer>
            </PageContainer>
            <AutoGrid
                backgroundColor="#F3F3F3"
                columnGap={"30px"}
                dc={"140px 140px 140px 140px 140px 140px"}
                m={"0"}
                padding={"15px 30px"}
                position="fixed"
                left="0"
                right="0"
                bottom="0"
                zIndex="10000"
            >
                {!viewModel.getValue<boolean>("isEditable") && <ThemedButton command={viewModel.editFinancialYearHistoryCommand} displayName="Edit" paletteColor="primary" />}
                {viewModel.getValue<boolean>("isEditable") && (
                    <>
                        <ThemedButton
                            styles={{ root: { padding: "0 5px" } }}
                            paletteColor={"primary"}
                            displayName={"Save"}
                            command={viewModel.confirmEditFinancialYearHistoryCommand}
                        />
                        <ThemedButton styles={{ root: { padding: "0 5px" } }} displayName="Cancel" command={viewModel.cancelEditFinancialYearHistoryCommand} />
                    </>
                )}
            </AutoGrid>
            <Box>
                {/* Busy Overlay */}
                {APICommonComponents(viewModel.apiClient).renderBusy()}
                {/* Error Overlay */}
                {APICommonComponents(viewModel.apiClient).renderError()}
            </Box>
        </>
    );
});
