import { isEmptyOrWhitespace, isNullOrUndefined, ViewModelBase } from "@shoothill/core";
import { RelayCommand } from "Application";
import { AppUrls } from "AppUrls";
import { makeObservable } from "mobx";
import moment from "moment";

import { EnquiryItemModel } from "./EnquiryItemModel";
import { container } from "tsyringe";
import { LookupStore } from "Stores/Domain";
import { get } from "styled-system";

export class EnquiryItemViewModel extends ViewModelBase<EnquiryItemModel> {
    private lookupStore = container.resolve(LookupStore);
    constructor(model = new EnquiryItemModel()) {
        super(model);
        makeObservable(this, {});
    }

    public get KEY() {
        return this.model.KEY;
    }

    public get enquiryStatusName() {
        return this.model.enquiryStatusName;
    }

    public get enquiryTypeName() {
        return this.model.enquiryTypeName;
    }

    public get reference() {
        return this.model.reference;
    }

    public get title() {
        return this.model.title;
    }

    public get sourceOfEnquiryType() {
        return this.lookupStore.sourceOfEnquiryTypes.find((t) => t.id === this.model.sourceOfEnquiryTypeId);
    }

    public get sourceOfEnquiryTypeName(): string {
        if (this.sourceOfEnquiryType) {
            return this.sourceOfEnquiryType.name;
        } else {
            return "";
        }
    }

    public get sourceOfEnquiryDetail(): string | null {
        return this.model.sourceOfEnquiryDetail;
    }

    public get customerName() {
        return !isEmptyOrWhitespace(this.model.customerName) ? this.model.customerName : `${this.model.customerFirstName} ${this.model.customerLastName}`;
    }

    public get lastActionDate() {
        return this.model.lastActionDate;
    }

    public get fullName() {
        return !isEmptyOrWhitespace(this.model.lastActionFirstName) && !isEmptyOrWhitespace(this.model.lastActionLastName)
            ? `${this.model.lastActionFirstName} ${this.model.lastActionLastName}`
            : "";
    }

    public get personVisitingName() {
        return !isEmptyOrWhitespace(this.model.enquiryPersonVisitingFirstName) && !isEmptyOrWhitespace(this.model.enquiryPersonVisitingLastName)
            ? `${this.model.enquiryPersonVisitingFirstName} ${this.model.enquiryPersonVisitingLastName}`
            : "";
    }

    public get personVisitingDocumentUrl() {
        return this.model.enquiryPersonVisitingDocumentUrl;
    }

    public get contactFullName() {
        if (this.model.customerItemModel) {
            return this.model.customerItemModel.fullName;
        }
        return "";
    }

    public get contactFullAddress() {
        if (this.model.customerItemModel) {
            return this.model.customerItemModel.fullAddress;
        }
        return "";
    }

    public navigateToCustomerDetailCommand = new RelayCommand((id: string) => {
        this.history.push(AppUrls.Client.Customers.Details.replace(":id", id));
    });

    /**
     * Determines if the quote can be displayed, given a set of filters.
     *
     * @param filterKeyword A general filter to cover a number of propoerties of the quote.
     * @param filterFromDate A date from filter.
     * @param filterToDate  A date to filter.
     * @param filterEnquiryStatusId A quote status filter.
     * @param filterVisitingPersonId A visiting person filter.
     *
     * @returns true if the quote can be displayed otherwise false.
     */
    public filterPredicate = (
        filterKeyword: string,
        filterFromDate: Date | undefined,
        filterToDate: Date | undefined,
        filterEnquiryStatusId: string | null,
        filterVisitingPersonId: string | null,
        filterSourceOfEnquiryTypeId: string | null,
    ): boolean => {
        let result = true;

        // Attempt filtering by keyword.
        if (result) {
            const lowerCaseFilterKeyword = filterKeyword.toLowerCase();
            switch (true) {
                case isEmptyOrWhitespace(filterKeyword):
                case this.model.customerName?.toLowerCase().includes(lowerCaseFilterKeyword):
                case this.model.customerFirstName?.toLowerCase().includes(lowerCaseFilterKeyword):
                case this.model.customerLastName?.toLowerCase().includes(lowerCaseFilterKeyword):
                case this.model.reference.toLowerCase().includes(lowerCaseFilterKeyword):
                case this.model.title.toLowerCase().includes(lowerCaseFilterKeyword):
                case this.model.enquiryTypeName.toLowerCase().includes(lowerCaseFilterKeyword):
                case this.sourceOfEnquiryTypeName.toLowerCase().includes(lowerCaseFilterKeyword):
                    result = true;
                    break;

                default:
                    result = false;
                    break;
            }
        }

        if (result) {
            switch (true) {
                case isNullOrUndefined(filterFromDate):
                    result = true;
                    break;

                case isNullOrUndefined(this.model.lastActionDate):
                    result = false;
                    break;

                case moment(this.model.lastActionDate).startOf("day").isSameOrAfter(moment(filterFromDate)):
                    result = true;
                    break;

                default:
                    result = false;
                    break;
            }
        }

        if (result) {
            switch (true) {
                case isNullOrUndefined(filterToDate):
                    result = true;
                    break;

                case isNullOrUndefined(this.model.lastActionDate):
                    result = false;
                    break;

                case moment(this.model.lastActionDate).startOf("day").isSameOrBefore(moment(filterToDate)):
                    result = true;
                    break;

                default:
                    result = false;
                    break;
            }
        }

        if (result) {
            switch (true) {
                case isEmptyOrWhitespace(filterEnquiryStatusId):
                case this.model.enquiryStatusId === filterEnquiryStatusId:
                    result = true;
                    break;

                default:
                    result = false;
                    break;
            }
        }

        if (result) {
            switch (true) {
                case isEmptyOrWhitespace(filterVisitingPersonId):
                case this.model.personVisitingId === filterVisitingPersonId:
                    result = true;
                    break;

                default:
                    result = false;
                    break;
            }
        }

        if (result) {
            switch (true) {
                case isEmptyOrWhitespace(filterSourceOfEnquiryTypeId):
                case this.model.sourceOfEnquiryTypeId === filterSourceOfEnquiryTypeId:
                    result = true;
                    break;

                default:
                    result = false;
                    break;
            }
        }

        return result;
    };
}
