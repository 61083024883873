import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { Service, ServiceGroup, ProjectLite, SignatoryUser } from "Application/Models/Domain";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { CustomerItemModel } from "Views/Shared/Customer/CustomerItemModel";
import { RevisionModel } from "Views/Shared/Revisions/RevisionModel";
import { MODELID_CURRENTQUOTE } from "Views/Shared/Constants";
import { LetterTemplateItemModel } from "Views/Shared/Letter/LetterTemplateItemModel";
import { TermsAndConditionItemModel } from "Views/Shared/TermsAndConditions/TermsAndConditionItemModel";
import { QuoteContainerViewModel } from "../QuoteContainerViewModel";

class CustomerResponse {
    id: string = "";
    contactTypeId: string = "";
    contactTitleId: string | null = null;

    businessName: string | null = null;
    firstName: string | null = null;
    lastName: string | null = null;

    public contactNumber1: string | null = null;
    public contactNumber2: string | null = null;
    public emailAddress: string | null = null;

    public address1: string = "";
    public address2: string | null = null;
    public address3: string | null = null;
    public city: string = "";
    public postcode: string = "";
}

class LetterTemplateResponse {
    id: string = "";
    name: string = "";
    body: string = "";
    isDeleted: boolean = false;
}

class TermsAndConditionResponse {
    id: string = "";
    name: string = "";
    body: string = "";
    isDeleted: boolean = false;
}

class ProjectResponse {
    id: string = "";
    title: string = "";
    projectTypeId: string = "";
}

class ServiceGroupResponse {
    id: string = "";
    name: string = "";
    ordinal: number = 0;
    type: string = "";
    isDeleted: boolean = false;
}

class ServiceResponse {
    id: string = "";
    name: string = "";
    ordinal: number = 0;
    isDeleted: boolean = false;
    serviceGroupId: string = "";
}

class SignatoryUserResponse {
    id: string = "";
    firstName: string = "";
    lastName: string = "";
}

class Response {
    customers: CustomerResponse[] = [];
    letterTemplates: LetterTemplateResponse[] = [];
    termsAndConditions: TermsAndConditionResponse[] = [];
    projects: ProjectResponse[] = [];
    serviceGroups: ServiceGroupResponse[] = [];
    services: ServiceResponse[] = [];
    signatoryUsers: SignatoryUserResponse[] = [];
}

export class GETQuoteRelatedEndpoint extends EndpointWithoutRequest<Response> {
    private readonly viewModel: QuoteContainerViewModel;

    constructor(viewModel: QuoteContainerViewModel) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.Quotes.GetQuoteRelated);
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        // Process the current revision.
        const revisionModels: RevisionModel[] = [];
        const currentRevisionModel = new RevisionModel();

        currentRevisionModel.id = MODELID_CURRENTQUOTE;
        currentRevisionModel.title = "New quote";
        currentRevisionModel.revision = "";
        currentRevisionModel.modifiedDate = new Date();

        revisionModels.push(currentRevisionModel);

        this.viewModel.model.revisions.replace(revisionModels);

        // Process the response customers.
        this.viewModel.quoteFormViewModel.customerViewModel.customers.replace(
            response.customers.map((c) => {
                const model = new CustomerItemModel();

                model.fromResponse(c);

                return model;
            }),
        );

        // Process the response letter templates.
        this.viewModel.quoteFormViewModel.letterViewModel.letterTemplates.replace(
            response.letterTemplates.map((lt) => {
                const model = new LetterTemplateItemModel();

                model.fromResponse(lt);

                return model;
            }),
        );

        // Process the response term and conditions templates.
        this.viewModel.quoteFormViewModel.termsAndConditionsViewModel.termsAndConditionsTemplates.replace(
            response.termsAndConditions.map((tc) => {
                const model = new TermsAndConditionItemModel();

                model.fromResponse(tc);

                return model;
            }),
        );

        // Process the response projects.
        response.projects.forEach((projects) => {
            let model: ProjectLite = {
                id: projects.id,
                title: projects.title,
                projectTypeId: projects.projectTypeId,
            };
            this.viewModel.quoteFormViewModel.quoteViewModel.model.projectsList.push(model);
        });

        // Process the response service groups.
        this.viewModel.quoteFormViewModel.servicesViewModel.model.serviceGroups.replace(
            response.serviceGroups.map((s) => {
                const serviceGroup = new ServiceGroup(s.id);

                serviceGroup.name = s.name;
                serviceGroup.isDeleted = s.isDeleted;
                serviceGroup.ordinal = s.ordinal;
                serviceGroup.type = s.type;

                return serviceGroup;
            }),
        );

        // Process the response services.
        this.viewModel.quoteFormViewModel.servicesViewModel.model.services.replace(
            response.services.map((s) => {
                const service = new Service(s.id);

                service.name = s.name;
                service.isDeleted = s.isDeleted;
                service.ordinal = s.ordinal;
                service.serviceGroupId = s.serviceGroupId;

                return service;
            }),
        );

        // Process the response signatory users.
        this.viewModel.quoteFormViewModel.termsAndConditionsViewModel.model.signatoryUsers.replace(
            response.signatoryUsers.map((u) => {
                const user = new SignatoryUser();

                user.id = u.id;
                user.firstName = u.firstName;
                user.lastName = u.lastName;

                return user;
            }),
        );
    }
}
