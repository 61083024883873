import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { AutoGrid, Box, ThemedText, theme } from "Application";
import styled from "@emotion/styled";
import { CheckCircleIcon } from "Assets/Icons/CheckCircleIcon";
import { CancelCircleIcon } from "Assets/Icons/CancelCircleIcon";
import { PlanningApplicationsViewModel } from "./PlanningApplicationsViewModel";
import { Callout, DirectionalHint } from "@fluentui/react";
import { EventCallout } from "../RunningTotals/PieChartShared.styles";
import { ListItemContainer, ListItemDetails, ListItemLink, ListItemPositionDot, ListItemPositionLabel, ListItemsContainer } from "../Shared/Shared.styles";
import { PlanningApplicationLiteModel } from "./PlanningApplicationLiteModel";
import { nanoid } from "nanoid";

const Container = styled.div`
    display: grid;
    /* row-gap: 5px; */
    position: relative;
    grid-area: planningApplications;
    background: white;
    width: 100%;
    height: 100%;
`;

interface IIconContainerProps {
    fill: string;
}

const IconContainer = styled.div<IIconContainerProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    & > *:not(:last-child) {
        margin-bottom: 10px;
    }
    svg {
        fill: ${(props) => props.fill};
        width: 100px;
        height: 100px;
    }
`;

const StyledButton = styled.button`
    padding: 0;
    border: none;
    background: none;
    font: inherit;
    color: inherit;
    cursor: pointer;
`;

export const PlanningApplicationsView: React.FC = observer(() => {
    const [viewModel] = useState(new PlanningApplicationsViewModel());
    const [callout, setIsCalloutVisible] = useState(false);
    const [buttonId] = useState(() => `callout-${nanoid()}`);
    const renderLiveList = (items: PlanningApplicationLiteModel[]) => {
        const retVal: JSX.Element[] = [];

        items.forEach((item: PlanningApplicationLiteModel, index) => {
            retVal.push(
                <ListItemContainer>
                    <ThemedText fontStyle="h7">{item.projectRef}</ThemedText>
                    <ListItemDetails>
                        <ListItemLink to={"/dashboard/admin/overview/planning-applications/planning-application/"}>
                            <ThemedText fontStyle={"h7"}>{item.planningRef}</ThemedText>
                            <ThemedText fontStyle={"h7"}>- {item.projectTitle}</ThemedText>
                        </ListItemLink>
                    </ListItemDetails>
                </ListItemContainer>,
            );
        });

        return retVal;
    };

    return (
        <Container>
            <Box display="flex" alignItems={"center"} paddingTop={"15px"} paddingLeft={"20px"}>
                <ThemedText fontStyle="titleBold">Planning Applications</ThemedText>
            </Box>
            <AutoGrid dc={"1fr 1fr"} columnGap={"0px"}>
                <IconContainer fill={theme.palette.success.main}>
                    <CheckCircleIcon />
                    <ThemedText fontStyle="titleBoldXL">{viewModel.approved}</ThemedText>
                    <ThemedText fontStyle="pBold">Approved</ThemedText>
                </IconContainer>
                <IconContainer fill={theme.palette.error.main}>
                    <CancelCircleIcon />
                    <ThemedText fontStyle="titleBoldXL">{viewModel.refused}</ThemedText>
                    <ThemedText fontStyle="pBold">Refused</ThemedText>
                </IconContainer>
            </AutoGrid>
            <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                padding={"0px 20px"}
                height={"100px"}
                borderTop={`1px solid ${theme.palette.tableHeader.main}`}
            >
                <ThemedText fontStyle="p">There are currently</ThemedText>
                <ThemedText fontStyle="p">
                    <span style={{ color: theme.palette.secondary.main }}>{viewModel.live} live </span>
                    planning{`${viewModel.live != 0 ? " applications" : " application"}`}
                    {/* <StyledButton
                        id={buttonId}
                        onClick={(e: any) => {
                            e.stopPropagation();
                            setIsCalloutVisible(true);
                        }}
                    >
                        <span style={{ color: theme.palette.secondary.main }}>{viewModel.live} live </span>
                    </StyledButton>
                    planning{`${viewModel.live != 0 ? " applications" : " application"}`} */}
                </ThemedText>
            </Box>
            {callout && (
                <Callout directionalHint={DirectionalHint.bottomAutoEdge} onDismiss={() => setIsCalloutVisible(false)} target={`#${buttonId}`}>
                    <EventCallout maxWidth={"300px"} padding={"15px 20px"}>
                        <ListItemsContainer>{renderLiveList(viewModel.sortedLivePlanningApplicationLites)}</ListItemsContainer>
                    </EventCallout>
                </Callout>
            )}
        </Container>
    );
});
