import { isNullOrUndefined } from "@shoothill/core";
import { runInAction } from "mobx";

import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { TaskViewModel } from "./TaskViewModel";
import { FileModel } from "Views/Shared/Files/FileModel";
import { NotesViewModel } from "./NotesSubView/NotesViewModel";
import { BillingNotesViewModel } from "./BillingSubView/SubViews/BillingNotesViewModel";
import { BillingNotesModel } from "./BillingSubView/SubViews/BillingNotesModel";
import { BillingNoteViewModel } from "./BillingSubView/SubViews/BillingNoteViewModel";
import { BillingNoteModel } from "./BillingSubView/SubViews/BillingNoteModel";
import { BillingViewModel } from "./BillingSubView/BillingViewModel";
import { TA_NOTECONTEXT_ASSIGNMENT, TA_NOTECONTEXT_BILLED, TA_NOTECONTEXT_COMPLETED } from "Views/Shared/Constants";
import { User } from "Application/Models/Domain/User";

class Response {
    billedEffort: number | null = null;
    billableEffort: number | null = null;
    effort: number = 0;
    histories: HistoryResponse[] = [];
    id: string = "";
    isNonBillableEffort = false;
    note: string | null = null;
    reviewedNote: string | null = null;
    plannedEndDate: string = "";
    plannedStartDate: string = "";
    projectTaskAssignmentDocuments: ProjectTaskAssignmentDocumentResponse[] = [];
    projectTaskAssignmentStatusId = "";
    projectTaskBillableEffort: number | null = null;
    projectTaskIsBilledHourly = false;
    remainingProjectTaskBillableEffort: number | null = null;
    maximumProjectTaskBillableEffort: number | null = null;
    summary: SummaryResponse | null = null;
    users: User[] = [];
    user: User | null = null;
}

class ProjectTaskAssignmentDocumentResponse {
    id: string = "";
    fileName: string = "";
    fileSizeBytes: number = 0;
    mimeType: string = "";
    documentUrl: string = "";
}

class SummaryResponse {
    projectTaskAssignmentStatusId = "";
    reference = "";
    title = "";
    projectTaskGroupName = "";
    projectTaskName = "";
    isNonBillableEffort = false;
    isReviewed = false;
}

class ProjectTaskAssignmentUserNoteWithRelatedHistoryResponse {
    id: string = "";

    assignmentUserDate: string | null = null;
    assignmentUserFirstName: string | null = null;
    assignmentUserLastName: string | null = null;
    assignmentUserNote: string | null = null;
    assignmentUserThumbnailUrl: string | null = null;
}

class HistoryResponse {
    id: string = "";

    billedDate: string | null = null;
    billedNote: string | null = null;
    billedUserFirstName: string | null = null;
    billedUserLastName: string | null = null;
    billedUserThumbnailUrl: string | null = null;

    completedDate: string | null = null;
    completedNote: string | null = null;
    completedUserFirstName: string | null = null;
    completedUserLastName: string | null = null;
    completedUserThumbnailUrl: string | null = null;

    projectTaskAssignmentUserNoteWithRelatedHistory: ProjectTaskAssignmentUserNoteWithRelatedHistoryResponse[] = [];
}

export class GETProjectTaskAssignmentWithRelatedEndpoint extends EndpointWithoutRequest<Response> {
    private readonly viewModel: TaskViewModel;

    constructor(projectTaskAssignmentId: string, viewModel: TaskViewModel) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.Resources.GetProjectTaskAssignmentWithRelatedById.replace(":projectTaskAssignmentId", projectTaskAssignmentId));
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            this.viewModel.summaryViewModel.model.fromResponse(response.summary);
            this.viewModel.model.fromResponse(response);

            this.viewModel.model.setValue("initialAssigneeId", response.user ? response.user.id : null);
            this.viewModel.model.users.replace(
                response.users.map((item: any) => {
                    const domainModel = new User();

                    domainModel.id = item.id;
                    domainModel.firstName = item.firstName;
                    domainModel.lastName = item.lastName;
                    domainModel.thumbnailDocumentUrl = item.thumbnailDocumentUrl;
                    domainModel.isAssignable = item.isAssignable;
                    return domainModel;
                }),
            );

            this.viewModel.model.remainingProjectTaskBillableEffort = response.remainingProjectTaskBillableEffort!;
            this.viewModel.model.maximumBillableEffort = response.maximumProjectTaskBillableEffort;

            // Set the billed effort default value, so the user doesn't have to.
            // Only do this if we have not set the billed effort.
            if (isNullOrUndefined(response.billedEffort)) {
                if (response.projectTaskIsBilledHourly) {
                    this.viewModel.setValue("billedEffort", response.effort);
                } else {
                    this.viewModel.setValue("billedEffort", response.billableEffort);
                }
            }

            // Process the histories.
            const billingViewModel = this.viewModel.workspaceViewModels.find((vm) => vm instanceof BillingViewModel) as BillingViewModel;

            billingViewModel.billingNotesViewModels.replace(
                response.histories.map((history) => {
                    const model = new BillingNotesModel();

                    model.fromResponse(history);

                    const billingNotesViewModel = new BillingNotesViewModel(model);
                    //check if user note history is > 0
                    if (history.projectTaskAssignmentUserNoteWithRelatedHistory.length > 0) {
                        history.projectTaskAssignmentUserNoteWithRelatedHistory.forEach((projectTaskHistory) => {
                            const noteModel = new BillingNoteModel();

                            noteModel.context = TA_NOTECONTEXT_ASSIGNMENT;
                            //assignmentUserDate = creation date will always be a date since it is a mandatory entry in the table
                            noteModel.date = new Date(projectTaskHistory.assignmentUserDate!);
                            noteModel.firstName = projectTaskHistory.assignmentUserFirstName;
                            noteModel.lastName = projectTaskHistory.assignmentUserLastName;
                            noteModel.note = projectTaskHistory.assignmentUserNote;
                            noteModel.documentUrl = projectTaskHistory.assignmentUserThumbnailUrl;

                            billingNotesViewModel.assignmentUserNoteViewModels.push(new BillingNoteViewModel(noteModel));
                        });
                    }

                    if (history.completedDate) {
                        const noteModel = new BillingNoteModel();

                        noteModel.context = TA_NOTECONTEXT_COMPLETED;
                        noteModel.date = new Date(history.completedDate);
                        noteModel.firstName = history.completedUserFirstName;
                        noteModel.lastName = history.completedUserLastName;
                        noteModel.note = history.completedNote;
                        noteModel.documentUrl = history.completedUserThumbnailUrl;

                        billingNotesViewModel.completedUserNoteViewModel = new BillingNoteViewModel(noteModel);
                    }

                    if (history.billedDate) {
                        const noteModel = new BillingNoteModel();

                        noteModel.context = TA_NOTECONTEXT_BILLED;
                        noteModel.date = new Date(history.billedDate);
                        noteModel.firstName = history.billedUserFirstName;
                        noteModel.lastName = history.billedUserLastName;
                        noteModel.note = history.billedNote;
                        noteModel.documentUrl = history.billedUserThumbnailUrl;

                        billingNotesViewModel.billedUserNoteViewModel = new BillingNoteViewModel(noteModel);
                    }

                    return billingNotesViewModel;
                }),
            );

            // Process the documents.
            const notesViewModel = this.viewModel.workspaceViewModels.find((vm) => vm instanceof NotesViewModel) as NotesViewModel;

            notesViewModel.filesViewModel.model.files.replace(
                response.projectTaskAssignmentDocuments.map((ptad) => {
                    const model = new FileModel();

                    model.fromResponse(ptad);

                    return model;
                }) ?? [],
            );
        });

        return Promise.resolve(response);
    }
}
