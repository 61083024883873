import { runInAction } from "mobx";

import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "../../../AppUrls";
import { SupplierViewModel } from "./SupplierViewModel";
import { SupplierModel } from "./SupplierModel";
import { SupplierRequest } from "./Shared/SupplierRequest";
import { SupplierResponse } from "./Shared/SupplierResponse";

export class POSTSaveSupplierAndContactsEndpoint extends Endpoint<Request, Response> {
    private readonly viewModel: SupplierViewModel;

    constructor(viewModel: SupplierViewModel) {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Suppliers.SaveSupplierAndContacts);
        this.AllowFileUploads();
        this.viewModel = viewModel;
    }

    public async HandleRequestAsync(model: SupplierModel): Promise<any> {
        const request = new SupplierRequest();

        SupplierRequest.supplierPart(request, model, this.viewModel.filesViewModel.model);

        const formData = new FormData();

        formData.append("data", JSON.stringify(request));

        for (const file of this.viewModel.filesViewModel.model?.files!) {
            formData.append(file.KEY, file.file!);
        }

        return Promise.resolve(formData);
    }

    public async HandleResponseAsync(response: SupplierResponse): Promise<any> {
        runInAction(() => {
            this.viewModel.history.push(AppUrls.Client.Suppliers.Details.replace(":id", response.id));
        });
    }
}
