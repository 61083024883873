import { isNullOrUndefined, ViewModelBase } from "@shoothill/core";
import { formatDate, formatTime } from "Application";
import { makeObservable } from "mobx";

import { WeeklyUpdateHistoryModel } from "./WeeklyUpdateHistoryModel";

export class WeeklyUpdateHistoryViewModel extends ViewModelBase<WeeklyUpdateHistoryModel> {
    constructor(weeklyUpdateHistoryModel: WeeklyUpdateHistoryModel = new WeeklyUpdateHistoryModel()) {
        super(weeklyUpdateHistoryModel);

        makeObservable(this, {
            // Observables
        });
    }

    // #region Properties

    public get KEY() {
        return this.model.KEY;
    }

    public get updateDetails() {
        const date = !isNullOrUndefined(this.model.updatedDate) ? this.model.updatedDate : this.model.createdDate;

        switch (true) {
            case !isNullOrUndefined(date):
                return `Last updated: ${formatDate(date!)} @ ${formatTime(date!)}`;

            default:
                return "";
        }
    }

    // #endregion Properties

    // #region Commands
    // #endregion Commands
}
