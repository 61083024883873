import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { nanoid } from "nanoid";

export class AssignedTaskItemModel extends ModelBase<AssignedTaskItemModel> {
    public KEY: string = nanoid();

    public id: string = "";
    public projectTaskAssignmentStatusId = "";
    public projectId = "";
    public projectTaskId = "";
    public projectLeadId = "";
    public assignedId = "";
    public plannedStartDate: Date | null = null;
    public plannedEndDate: Date | null = null;
    public completedDate: Date | null = null;
    public billableEffort: number | null = null;
    public isNonBillableEffort = false;
    public reference = "";
    public originReference = "";
    public title = "";
    public projectTaskAssignmentStatusName = "";
    public projectTaskAssignmentStatusForegroundColor = "";
    public projectTaskName = "";
    public projectLeadFirstName = "";
    public projectLeadLastName = "";
    public projectLeadDocumentUrl = "";
    public assignedFirstName = "";
    public assignedLastName = "";
    public assignedDocumentUrl = "";

    constructor() {
        super();
        makeObservable(this, {
            id: observable,
            projectTaskAssignmentStatusId: observable,
            projectId: observable,
            projectTaskId: observable,
            projectLeadId: observable,
            assignedId: observable,
            plannedStartDate: observable,
            plannedEndDate: observable,
            completedDate: observable,
            billableEffort: observable,
            isNonBillableEffort: observable,
            reference: observable,
            originReference: observable,
            title: observable,
            projectTaskAssignmentStatusName: observable,
            projectTaskAssignmentStatusForegroundColor: observable,
            projectTaskName: observable,
            projectLeadFirstName: observable,
            projectLeadLastName: observable,
            projectLeadDocumentUrl: observable,
            assignedFirstName: observable,
            assignedLastName: observable,
            assignedDocumentUrl: observable,
        });
    }
}
