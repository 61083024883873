import { runInAction } from "mobx";

import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { QuoteHistoryViewModel } from "./QuoteHistoryViewModel";
import { QuoteHistoryResponse } from "./Shared/QuoteHistoryResponse";

export class GETQuoteHistoryWithRelatedByIdEndpoint extends EndpointWithoutRequest<Response> {
    private readonly viewModel: QuoteHistoryViewModel;

    constructor(quoteHistoryId: string, viewModel: QuoteHistoryViewModel) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.Quotes.GetQuoteHistoryWithRelatedById.replace(":quoteHistoryId", quoteHistoryId));
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: QuoteHistoryResponse): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            QuoteHistoryResponse.quoteResponsePart(response, this.viewModel);
            QuoteHistoryResponse.customerResponse(response, this.viewModel);
            QuoteHistoryResponse.letterTemplateResponse(response, this.viewModel);
            QuoteHistoryResponse.tasksResponsePart(response, this.viewModel);
            QuoteHistoryResponse.termsAndConditionsResponse(response, this.viewModel);
        });
    }
}
