import { runInAction } from "mobx";

import { Logger } from "index";
import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import CustomerDetailViewModel from "./CustomerDetailViewModel";
import { Customer, CustomerContact, Enquiry, Project, Quote } from "./CustomerDetailModel";
import { FileModel } from "Views/Shared/Files/FileModel";

class CustomerDocumentResponse {
    id: string = "";
    fileName: string = "";
    fileSizeBytes: number = 0;
    mimeType: string = "";
    documentUrl: string = "";
}
class Response {
    public customer: Customer = new Customer();
    public customerContacts: CustomerContact[] = [];
    public enquiries: Enquiry[] = [];
    public lostEnquiries: Enquiry[] = [];
    public quotes: Quote[] = [];
    public lostQuotes: Quote[] = [];
    public projects: Project[] = [];
    public completedProjects: Project[] = [];
    public documents: CustomerDocumentResponse[] = [];
}

export class GetCustomerDetailsEndpoint extends EndpointWithoutRequest<Response> {
    private readonly viewModel: CustomerDetailViewModel;

    constructor(id: string, viewModel: CustomerDetailViewModel) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.Customers.GetCustomerDetailsById.replace(":id", id));
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            this.viewModel.getModel.customer.fromResponse(response.customer);

            this.viewModel.model.customerContacts.replace(
                response.customerContacts.map((e) => {
                    const model = new CustomerContact();

                    model.fromResponse(e);

                    return model;
                }),
            );

            this.viewModel.model.enquiries.replace(
                response.enquiries.map((e) => {
                    const model = new Enquiry();

                    model.fromResponse(e);

                    return model;
                }),
            );

            this.viewModel.model.lostEnquiries.replace(
                response.lostEnquiries.map((e) => {
                    const model = new Enquiry();

                    model.fromResponse(e);

                    return model;
                }),
            );

            this.viewModel.model.quotes.replace(
                response.quotes.map((e) => {
                    const model = new Quote();

                    model.fromResponse(e);

                    return model;
                }),
            );

            this.viewModel.model.lostQuotes.replace(
                response.lostQuotes.map((e) => {
                    const model = new Quote();

                    model.fromResponse(e);

                    return model;
                }),
            );

            this.viewModel.model.projects.replace(
                response.projects.map((e) => {
                    const model = new Project();

                    model.fromResponse(e);

                    return model;
                }),
            );

            // Process to customer documents.
            this.viewModel.filesViewModel.model.files.replace(
                response.documents.map((nd) => {
                    const model = new FileModel();

                    model.fromResponse(nd);

                    return model;
                }) ?? [],
            );
        });
    }
}
