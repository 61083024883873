import * as React from "react";
import { SVGProps } from "react";
import { theme } from "../../Styles";
import { Spinner } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import _ from "lodash";
import styled from "@emotion/styled";
import { ICommand } from "Application";
import ClassNames from "classnames";

const Container = styled.button`
    //remove default button styles
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    outline: none;

    position: relative;
    display: inline-block;
    width: min-content;
    height: 39px;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }

    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
`;

interface ICSVIconProps extends SVGProps<SVGSVGElement> {
    isBusy?: boolean;
    command: ICommand;
}

export const ExportCSVButton: React.FC<ICSVIconProps> = observer((props: ICSVIconProps) => {
    const [isBusy, setIsBusy] = React.useState(false);
    const spinnerDurationOfOneRevolutionInMs = 1000; // is the duration of one revolution of the spinner. needs to be the same as the duration of the spinner animation
    React.useEffect(() => {
        if (props.isBusy === undefined) return;

        let timeoutId: NodeJS.Timeout;
        let startTime: number = 0;
        let endTime: number = 0;
        //setTimeout creates a closure that will be executed after the specified delay
        //the timeoutId is used to clear the timeout if the component is unmounted before the timeout is executed
        timeoutId = setTimeout(() => {
            if (props.isBusy === true) {
                setIsBusy(true);
                startTime = performance.now();
            } else {
                endTime = performance.now();
                const duration = endTime - startTime;
                //determine if duration is approximately one revolution or a multiple of revolutions
                const revolutions = Math.floor(duration / spinnerDurationOfOneRevolutionInMs);
                const remainder = duration - revolutions * spinnerDurationOfOneRevolutionInMs;
                setTimeout(() => {
                    setIsBusy(false);
                }, remainder);
            }
        }, 1200);
        //cleanup function so the callback is not invoked unnecessarily
        return () => {
            clearTimeout(timeoutId);
            if (!props.isBusy) {
                setIsBusy(false);
            }
        };
    }, [props.isBusy]);

    const handleClick = () => {
        if (props.command.execute) {
            props.command.execute();
        }
    };

    return (
        <Container typeof="button" onClick={handleClick} className={ClassNames({ disabled: isBusy })} aria-details="Export CSV">
            <svg width="38px" height="38px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M5.106 0c-2.802 0-5.073 2.272-5.073 5.074v53.841c0 2.803 2.271 5.074 5.073 5.074h45.774c2.801 0 5.074-2.271 5.074-5.074v-38.605l-18.903-20.31h-31.945z"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    fill={theme.palette.primary.main}
                />

                {!isBusy && (
                    <path
                        d="M20.306 43.197c.126.144.198.324.198.522 0 .378-.306.72-.703.72-.18 0-.378-.072-.504-.234-.702-.846-1.891-1.387-3.007-1.387-2.629 0-4.627 2.017-4.627 4.88 0 2.845 1.999 4.879 4.627 4.879 1.134 0 2.25-.486 3.007-1.369.125-.144.324-.233.504-.233.415 0 .703.359.703.738 0 .18-.072.36-.198.504-.937.972-2.215 1.693-4.015 1.693-3.457 0-6.176-2.521-6.176-6.212s2.719-6.212 6.176-6.212c1.8.001 3.096.721 4.015 1.711zm6.802 10.714c-1.782 0-3.187-.594-4.213-1.495-.162-.144-.234-.342-.234-.54 0-.361.27-.757.702-.757.144 0 .306.036.432.144.828.739 1.98 1.314 3.367 1.314 2.143 0 2.827-1.152 2.827-2.071 0-3.097-7.112-1.386-7.112-5.672 0-1.98 1.764-3.331 4.123-3.331 1.548 0 2.881.467 3.853 1.278.162.144.252.342.252.54 0 .36-.306.72-.703.72-.144 0-.306-.054-.432-.162-.882-.72-1.98-1.044-3.079-1.044-1.44 0-2.467.774-2.467 1.909 0 2.701 7.112 1.152 7.112 5.636.001 1.748-1.187 3.531-4.428 3.531zm16.994-11.254l-4.159 10.335c-.198.486-.685.81-1.188.81h-.036c-.522 0-1.008-.324-1.207-.81l-4.142-10.335c-.036-.09-.054-.18-.054-.288 0-.36.323-.793.81-.793.306 0 .594.18.72.486l3.889 9.992 3.889-9.992c.108-.288.396-.486.72-.486.468 0 .81.378.81.793.001.09-.017.198-.052.288z"
                        fill="#ffffff"
                    />
                )}

                <g fill-rule="evenodd" clip-rule="evenodd">
                    <path d="M56.001 20.357v1h-12.8s-6.312-1.26-6.128-6.707c0 0 .208 5.707 6.003 5.707h12.925z" fill="#349C42" />

                    <path d="M37.098.006v14.561c0 1.656 1.104 5.791 6.104 5.791h12.8l-18.904-20.352z" opacity=".5" fill="#ffffff" />
                </g>
            </svg>
            {isBusy && (
                <div style={{ position: "absolute", top: "50%", left: "45%", transform: "translate(-50%, -50%)", zIndex: 1000 }}>
                    <Spinner size={1} />
                </div>
            )}
        </Container>
    );
});
