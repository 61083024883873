import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

export class UnassignedTasksModel extends ModelBase<UnassignedTasksModel> {
    public projectId: string | null = null;

    // Filtering

    // Sorting
    public sortKey = "projectTaskOrdinal"; // A property defined in the table column definitions.
    public sortDescending = false;

    constructor(projectId: string | null = null) {
        super();

        this.projectId = projectId;

        makeObservable(this, {
            sortKey: observable,
            sortDescending: observable,
        });
    }
}
