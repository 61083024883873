import { DetailsRow, IColumn } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import { Box, formatDate } from "Application";
import { BilledAssignmentsViewModel } from "./BilledAssignmentsViewModel";
import { BilledAssignmentsItemViewModel } from "./BilledAssignmentsItemViewModel";
import { ThemedDataTableLayer3 } from "Styles/ThemedPrimitives/Style1/ThemedDataTable";
import { isNullOrUndefined } from "@shoothill/core";

interface IProps {
    viewModel: BilledAssignmentsViewModel;
}

export const BilledAssignmentsView = observer((props: IProps) => {
    const viewModel = props.viewModel;

    useEffect(() => {
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter((currCol) => viewModel.getValue("sortKey") === currCol.key)[0];

        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSorted = true;
                currColumn.isSortedDescending = viewModel.getValue("sortDescending");
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = false;
            }
        });

        setColumns(newColumns);
    }, [viewModel.getValue("sortKey"), viewModel.getValue("sortDescending")]);

    const getRowKey = (item: BilledAssignmentsItemViewModel, index?: number): string => {
        return item.KEY;
    };

    const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const currColumn: IColumn = columns.filter((currCol) => column.key === currCol.key)[0];
        const newSortKey = currColumn.key;
        const newSortDescending = currColumn.key === viewModel.getValue("sortKey") ? !currColumn.isSortedDescending : false;

        viewModel.updateSortCommand.execute(newSortKey, newSortDescending);
    };

    /**
     * Handles rendering the row.
     *
     * @param props The row props of which item is the viewmodel.
     * @returns The table row.
     */
    const onRenderRow = (props: any) => {
        const viewModel = props.item;
        const projectTaskAssignmentStatusForegroundColor = `${viewModel.projectTaskAssignmentStatusForegroundColor}11 !important`;

        return <DetailsRow {...props} styles={{ root: { background: projectTaskAssignmentStatusForegroundColor } }} />;
    };

    /**
     * Renders the billed date row data.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The index of the table row.
     * @param column The table column.
     *
     * @returns React billed date element.
     */
    const onRenderBilledDate = (item: BilledAssignmentsItemViewModel, index?: number, column?: IColumn) => {
        return <div>{!isNullOrUndefined(item.billedDate) ? formatDate(item.billedDate!) : ""}</div>;
    };

    const defaultTableColumns: IColumn[] = [
        {
            key: "projectTaskName",
            fieldName: "projectTaskName",
            name: "TASK",
            minWidth: 281,
            maxWidth: 502,
            isResizable: true,
            isSorted: true,
            onColumnClick: onColumnClick,
        },
        {
            key: "assignedUserName",
            fieldName: "assignedUserName",
            name: "ASSIGNED TO",
            minWidth: 100,
            maxWidth: 100,
            isSorted: true,
            onColumnClick: onColumnClick,
        },
        {
            key: "billedByUserName",
            fieldName: "billedByUserName",
            name: "BILLED BY",
            minWidth: 100,
            maxWidth: 100,
            isSorted: true,
            onColumnClick: onColumnClick,
        },
        {
            key: "billedDate",
            fieldName: "billedDate",
            name: "BILLED DATE",
            minWidth: 100,
            maxWidth: 100,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: onRenderBilledDate,
        },
        {
            key: "billedEffort",
            fieldName: "billedEffort",
            name: "BILLED HRS",
            minWidth: 120,
            maxWidth: 120,
            isResizable: true,
            isSorted: true,
            onColumnClick: onColumnClick,
        },
        {
            key: "billedNote",
            fieldName: "billedNote",
            name: "INVOICE NOTE",
            minWidth: 120,
            isResizable: true,
            isSorted: true,
            onColumnClick: onColumnClick,
        },
    ];

    const [columns, setColumns] = useState<IColumn[]>(defaultTableColumns);

    return (
        <Box>
            {/* Table */}
            <ThemedDataTableLayer3
                styles={{ root: { "&&&&": { marginTop: 0 } } }}
                onRenderRow={onRenderRow}
                getKey={getRowKey}
                items={viewModel.filteredAndSortedAssignments}
                columns={columns}
            />
        </Box>
    );
});
