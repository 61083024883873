import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { startOfDay } from "date-fns";

export class StaffDetailsModel extends ModelBase<StaffDetailsModel> {
    public userId: string = "";
    public userFirstName: string = "";
    public userLastName: string = "";
    public documentUrl: string = "";

    public businessHours: number = 0;
    public chargeableBusinessHours: number = 0;
    public chargeableEffortHoursAllocation: number = 0;
    public chargeableEffortPercentageAllocation: number = 0;
    public effortHoursAllocation: number = 0;
    public effortPercentageAllocation: number = 0;

    // Filtering
    public filterStartDate: Date = startOfDay(new Date());
    public filterKeyword: string = "";
    public filterProjectSupportId: string | null = null;
    // Sorting
    public sortKey = "reference"; // A property defined in the table column definitions.
    public sortDescending = false;

    //Render
    public renderCount: number = 0;

    constructor() {
        super();

        makeObservable(this, {
            // Observables
            renderCount: observable,
            userId: observable,
            userFirstName: observable,
            userLastName: observable,
            documentUrl: observable,

            businessHours: observable,
            chargeableBusinessHours: observable,
            chargeableEffortHoursAllocation: observable,
            chargeableEffortPercentageAllocation: observable,
            effortHoursAllocation: observable,
            effortPercentageAllocation: observable,

            filterStartDate: observable,
            filterKeyword: observable,
            filterProjectSupportId: observable,

            sortKey: observable,
            sortDescending: observable,
        });
    }
}
