import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import React from "react";

import { AutoGrid, Box, ThemedButton, ThemedText } from "Application";
import { ThemedLoader } from "Styles/ThemedPrimitives/Style1/ThemedLoader";
import { ThemedError } from "Styles/ThemedPrimitives/Style1/ThemedError";
import { CalendarToggleButton, FormDottedSeparator, PanelTab, PanelTabContainer } from "Views/Shared/SharedComponents";
import { SummaryView } from "./SummaryView/SummaryView";
import { AssignViewModel } from "./AssignSubView/AssignViewModel";
import { AssignView } from "./AssignSubView/AssignView";
import { AssignTaskViewModel } from "./AssignTaskViewModel";
import { CalendarToggleIcon } from "Assets/Icons/CalendarToggleIcon";
import { StaffDetailsViewModel } from "Views/Resource/Staff/Details/StaffDetailsViewModel";

interface IAssignTaskViewProps {
    viewModel: AssignTaskViewModel;
    parentViewModel: StaffDetailsViewModel;
}

const Body = styled(Box)`
    background-color: #fcfcfc;
    display: flex;
    flex: 1;
    flex-direction: column;
`;
const Footer = styled(Box)`
    align-items: center;
    background-color: #fcfcfc;
    display: flex;
    min-height: 70px;
    padding: 0 30px;
`;

export const AssignTaskView: React.FC<IAssignTaskViewProps> = observer((props) => {
    const viewModel = props.viewModel;

    const renderTabs = () => {
        return (
            viewModel && (
                <PanelTabContainer>
                    {viewModel.workspaceViewModels.map((workspace) => {
                        return (
                            <PanelTab isActive={viewModel.isActiveWorkspace(workspace)} key={workspace.KEY} onClick={() => viewModel.navigateToWorkspaceCommand.execute(workspace)}>
                                <ThemedText fontStyle="h5">{workspace.displayName}</ThemedText>
                            </PanelTab>
                        );
                    })}
                </PanelTabContainer>
            )
        );
    };

    const renderActiveWorkspace = () => {
        switch (true) {
            case viewModel.activeWorkspaceViewModel instanceof AssignViewModel:
                return <AssignView viewModel={viewModel.activeWorkspaceViewModel as AssignViewModel} />;
        }
    };

    const renderBusy = () => <ThemedLoader isOpen={viewModel.apiClient.IsBusy} />;

    const renderError = () => {
        const isOpen = viewModel.apiClient.IsSubmitted && !viewModel.apiClient.IsBusy && viewModel.apiClient.HaveValidationMessage;

        return <ThemedError command={viewModel.resetServerErrorCommand} isOpen={isOpen} errorMessage={viewModel.apiClient.ValidationMessage} />;
    };

    return (
        <Box display="flex" flexDirection="column" flex="1" onClick={(e: any) => e.stopPropagation()} zIndex={10000}>
            {viewModel && <SummaryView viewModel={viewModel.summaryViewModel} />}
            <Body>
                <Box display={"flex"} alignItems="center" justifyContent={"space-between"}>
                    {/* Navigation Tabs */}
                    <Box flex={1}>{renderTabs()}</Box>

                    <CalendarToggleButton
                        onClick={() => {
                            props.parentViewModel.toggleMyWorkWeekOverviewCommand.execute("right", "90%", "90%");
                        }}
                    >
                        <CalendarToggleIcon opened={props.parentViewModel.displayHorizontalMyWorkWeekOverview} />
                    </CalendarToggleButton>
                </Box>

                {/* Active Workspace */}
                {viewModel && renderActiveWorkspace()}

                {/* Busy Overlay */}
                {viewModel && renderBusy()}

                {/* Error Overlay */}
                {viewModel && renderError()}
            </Body>
            <FormDottedSeparator styles={{ root: { height: "1px" } }} />
            <Footer>
                {viewModel && (
                    <AutoGrid columnGap={"15px"} dc={"85px 85px"}>
                        <ThemedButton styles={{ root: { padding: "0 5px" } }} paletteColor={"primary"} displayName="Save" command={viewModel.submitCommand} />
                        <ThemedButton styles={{ root: { padding: "0 5px" } }} paletteColor={"default"} displayName="Cancel" command={viewModel.cancelCommand} />
                    </AutoGrid>
                )}
            </Footer>
        </Box>
    );
});
