//libraries
import React from "react";
import { observer } from "mobx-react-lite";
import styled from "@emotion/styled";
//app
import { ICommand, theme } from "Application";
import { WorkCalendarViewModel } from "Views/Shared/WorkCalendar/WorkCalendarViewModel";
import { WorkCalendarView } from "Views/Shared/WorkCalendar/WorkCalendarView";

export type TransitionOrigin = "bottom" | "right" | "left" | "top";

interface IProps {
    show: boolean;
    closeOverviewCommand: ICommand;
    workCalendarViewModel: WorkCalendarViewModel;

    origin: TransitionOrigin;
    height: string;
    width: string;
    showCloseButton: boolean;
    overlayColor: string;
    zIndex?: number;
    styleOverrides?: React.CSSProperties;
}

interface IMyWorkWeekOverviewContainerProps {
    origin: "bottom" | "right" | "left" | "top";
    overlayColor: string;
    zIndex?: number;
}

interface IMyWorkWeekOverviewContainerHelperProps {
    bottomPosition: string;
    leftPosition: string;
    rightPosition: string;
    height: string;
    width: string;
    transform: string;
    justifyContent: string;
}

const myWorkWeekOverviewContainerHelper = (origin: "bottom" | "right" | "left" | "top") => {
    let retValue: IMyWorkWeekOverviewContainerHelperProps = {} as IMyWorkWeekOverviewContainerHelperProps;

    switch (origin) {
        case "bottom":
            retValue.bottomPosition = "-100%";
            retValue.leftPosition = "50%";
            retValue.rightPosition = "auto";
            retValue.height = "0vh";
            retValue.width = "100vw";
            retValue.transform = "translateX(-50%)";
            retValue.justifyContent = "center";

            break;
        case "right":
            retValue.bottomPosition = "50%";
            retValue.leftPosition = "auto";
            retValue.rightPosition = "-100%";
            retValue.height = "100vh";
            retValue.width = "0vw";
            retValue.transform = "translateY(50%)";
            retValue.justifyContent = "flex-start";
            break;
    }

    return retValue;
};

const MyWorkWeekOverviewContainer = styled.div<IMyWorkWeekOverviewContainerProps>`
    position: fixed;

    bottom: ${(props) => myWorkWeekOverviewContainerHelper(props.origin).bottomPosition};
    left: ${(props) => (props.origin == "bottom" ? myWorkWeekOverviewContainerHelper(props.origin).leftPosition : "")};
    right: ${(props) => (props.origin == "right" ? myWorkWeekOverviewContainerHelper(props.origin).rightPosition : "")};

    transform: ${(props) => myWorkWeekOverviewContainerHelper(props.origin).transform};

    height: ${(props) => myWorkWeekOverviewContainerHelper(props.origin).height};
    width: ${(props) => myWorkWeekOverviewContainerHelper(props.origin).width};

    background-color: ${(props) => props.overlayColor};
    transition: all 0.767s cubic-bezier(0.1, 0.9, 0.2, 1);

    display: flex;
    align-items: end;
    justify-content: ${(props) => myWorkWeekOverviewContainerHelper(props.origin).justifyContent};

    z-index: ${(props) => (props.zIndex ? props.zIndex : 1000)};

    padding: 0px 15px;
    height: 100vh;
    width: 100vw;

    &.expanded {
        bottom: ${(props) => (props.origin == "bottom" ? "0" : "")};
        right: ${(props) => (props.origin == "right" ? "0" : "")};
    }

    overflow: hidden;
`;

interface IMyWorkWeekWrapperProps {
    height: string;
    width: string;
}

const MyWorkWeekWrapper = styled.div<IMyWorkWeekWrapperProps>`
    position: relative;

    background-color: white;
    border-radius: 24px 24px 24px 24px;
    box-shadow: 0px 3px 6px #00000029;
    padding: 15px;
    margin-bottom: 15px;

    height: ${(props) => props.height};
    width: ${(props) => props.width};
`;

const CloseButton = styled.div`
    position: absolute;
    cursor: pointer;

    right: 16px;
    top: 20px;
    width: 12px;
    height: 12px;
    opacity: 0.8;
    &:hover {
        opacity: 1;
    }
    &::before,
    &:after {
        content: " ";
        position: absolute;
        height: 12px;
        width: 2px;
        background-color: #171716;
    }

    &:before {
        transform: rotate(45deg);
    }

    &:after {
        transform: rotate(-45deg);
    }
`;

const WorkItemsCalendarContainer = styled.div`
    height: 100%;
    overflow-y: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
`;

export const MyWorkWeekOverview: React.FC<IProps> = observer((props: IProps) => {
    const { show, closeOverviewCommand, origin, height, width, showCloseButton, overlayColor, zIndex, styleOverrides } = props;

    const handleOnClick = (e: any) => {
        e.stopPropagation();
        closeOverviewCommand.execute();
    };

    return (
        <MyWorkWeekOverviewContainer
            overlayColor={overlayColor}
            origin={origin}
            zIndex={zIndex}
            className={`${show ? "expanded" : ""}`}
            onClick={(e) => handleOnClick(e)}
            style={styleOverrides}
        >
            <MyWorkWeekWrapper height={height} width={width}>
                {showCloseButton && <CloseButton onClick={() => closeOverviewCommand.execute()} />}
                <WorkItemsCalendarContainer onClick={(e: any) => e.stopPropagation()}>
                    <WorkCalendarView viewModel={props.workCalendarViewModel} />
                </WorkItemsCalendarContainer>
            </MyWorkWeekWrapper>
        </MyWorkWeekOverviewContainer>
    );
});
