import { css, Global } from "@emotion/react";
import React from "react";
import { theme } from "./AppTheme";

export const GlobalStyles = () => {
    const scrollbar_backgroundcolor = theme.palette.secondary.main;
    const scrollbar_backgroundbordercolor = "#DCDCDC";

    return (
        <Global
            styles={css`
                html,
                body {
                    margin: 0;
                    background-color: #fafafa !important;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    text-rendering: optimizeLegibility;
                    height: 100%;
                    font-family: ${theme.defaultFontStyle.fontFamily};
                    font-size: ${theme.defaultFontStyle.fontSize};
                    line-height: ${theme.defaultFontStyle.lineHeight};

                    //overflow: hidden;
                }
                .overflowContainer {
                    overflow: auto;
                    height: 95vh;
                }
                code {
                    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
                }

                /////////////////////////////////////////
                //Custom scroll bar
                * {
                    scrollbar-width: thin !important;
                    scrollbar-color: ${scrollbar_backgroundcolor} ${scrollbar_backgroundbordercolor} !important;
                }

                // Chrome, Edge, and Safari - more overrides available, including radius, width and thumb borders.
                *::-webkit-scrollbar {
                    height: 9px !important;
                    width: 9px !important;
                }

                *::-webkit-scrollbar-track {
                    background: ${scrollbar_backgroundbordercolor} !important;
                }

                *::-webkit-scrollbar-thumb {
                    background-color: ${scrollbar_backgroundcolor} !important;
                    border-radius: 9px !important;
                    border: 1px solid ${scrollbar_backgroundbordercolor} !important;
                }
                //End of custom scroll bar
                /////////////////////////////////////////

                /////////////////////////////////////////
                // Details Lists

                .ms-DetailsRow-cell.contactCell {
                    border-left: none;
                    padding-left: 8px;
                    padding-right: 8px;
                    justify-content: center;
                }

                .ms-DetailsRow-cell.downloadCell {
                    justify-content: center;
                    svg {
                        padding-right: 5px;
                        width: 25px;
                    }
                }

                // Details Lists
                /////////////////////////////////////////
                //tinymce
                .tox-tiered-menu {
                    .tox-menu {
                        height: 350px;
                    }
                }
            `}
        />
    );
};
