import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

export class SummaryModel extends ModelBase<SummaryModel> {
    public reference = "";
    public title = "";

    constructor() {
        super();

        makeObservable(this, {
            reference: observable,
            title: observable,
        });
    }

    // #region Properties
    // #endregion Properties
}
