/* eslint-disable @typescript-eslint/no-use-before-define */
import React from "react";
import { Modal } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import { LogoutModelView } from "./ModelViews/LogoutModelView";

export interface ILogoutProps {
    isShow: boolean;
    onClose: () => void;
}

const LogoutView: React.FC<ILogoutProps> = observer((props) => {
    const renderDeleteModalContent = () => {
        return (
            <Modal isOpen={props.isShow}>
                <LogoutModelView onClose={props.onClose} />
            </Modal>
        );
    };

    return <>{renderDeleteModalContent()}</>;
});
export default LogoutView;
