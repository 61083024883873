import { observer } from "mobx-react-lite";
import React from "react";

import { ThemedCheckBox, ThemedComboBox, ThemedEditDate, ThemedEditText, ThemedPersonaComboBox } from "Application";
import { AssignViewModel } from "./AssignViewModel";
import { FormContainer, FormContainerRow, FormSubContainer, PanelFiles } from "Views/Shared/SharedComponents";

interface IAssignViewProps {
    viewModel: AssignViewModel;
}

const CheckBoxStyle = {
    root: {
        marginTop: "30px",
    },
};

export const AssignView: React.FC<IAssignViewProps> = observer((props) => {
    const viewModel = props.viewModel;

    return (
        <FormContainer>
            <FormSubContainer>
                <FormContainerRow dc={"100%"}>
                    <ThemedPersonaComboBox
                        command={viewModel.updateUserCommand}
                        displayName="Assigned to*"
                        options={viewModel.users}
                        placeholder="Please select"
                        validationMessage={() => viewModel.getError("userId")}
                        value={() => viewModel.getValue("userId")}
                    />
                </FormContainerRow>
                <FormContainerRow dc={"1fr 1fr 1fr"}>
                    <ThemedEditText
                        command={viewModel.updateEffortCommand}
                        displayName="staff hours*"
                        validationMessage={() => viewModel.getError("effort")}
                        value={() => viewModel.getValue("effort") ?? ""}
                    />
                    {viewModel.updateBillableEffortCommand.canExecute() && (
                        <ThemedEditText
                            command={viewModel.updateBillableEffortCommand}
                            displayName="Chargeable hours*"
                            validationMessage={() => viewModel.getError("billableEffort")}
                            value={() => viewModel.getValue("billableEffort") ?? ""}
                        />
                    )}
                    <ThemedCheckBox
                        command={viewModel.updateIsNonBillableEffortCommand}
                        displayName="NCT"
                        styles={CheckBoxStyle}
                        value={() => viewModel.getValue("isNonBillableEffort")}
                        paletteColor="secondary"
                    />
                </FormContainerRow>
                <FormContainerRow dc={"1fr 1fr"}>
                    <ThemedEditDate
                        command={viewModel.updatePlannedStartDateCommand}
                        displayName="Date start*"
                        size="larger"
                        validationMessage={() => viewModel.getError("plannedStartDate")}
                        value={() => viewModel.getValue("plannedStartDate")}
                    />
                    <ThemedEditDate
                        command={viewModel.updatePlannedEndDateCommand}
                        displayName="Due by*"
                        size="larger"
                        validationMessage={() => viewModel.getError("plannedEndDate")}
                        value={() => viewModel.getValue("plannedEndDate")}
                    />
                </FormContainerRow>
                <FormContainerRow dc={"100%"} mb="5px !important">
                    <ThemedComboBox
                        command={viewModel.updateDescriptionViaTemplateCommand}
                        options={viewModel.templateNotes}
                        placeholder="Template task notes"
                        size="larger"
                        value={() => viewModel.getValue("id")}
                    />
                    <ThemedEditText
                        command={viewModel.updateDescriptionCommand}
                        displayName="Task notes"
                        isMultiline
                        placeholder="Please enter any notes for the task"
                        styles={{ fieldGroup: { minHeight: 120 } }}
                        validationMessage={() => viewModel.getError("note")}
                        value={() => viewModel.getValue("note") ?? ""}
                    />
                </FormContainerRow>
                <PanelFiles viewModel={props.viewModel.filesViewModel} />
            </FormSubContainer>
        </FormContainer>
    );
});
