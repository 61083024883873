import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

import { isTypeOfNumber } from "Application";
import { Validator } from "Application/Validation";
import { LeaveRequestUser } from "Views/Leave/Shared/LeaveRequestUser";

export class ChangeAllowanceModel extends ModelBase<ChangeAllowanceModel> {
    public leaveRequestUser: LeaveRequestUser;
    public numberOfDays: number | string | null = null;

    constructor(leaveRequestUser: LeaveRequestUser) {
        super();

        this.leaveRequestUser = leaveRequestUser;

        makeObservable(this, {
            // Obserables
            numberOfDays: observable,
        });
    }
}

export class ChangeAllowanceModelValidator extends Validator<ChangeAllowanceModel> {
    constructor() {
        super();

        const validateIsWholeOrHalfDay = (value: string): boolean => {
            const regex = /^-?\d+(\.5)?$/;
            return regex.test(value);
        };

        this.ruleFor("numberOfDays")
            .notNull()
            .withMessage("Please provide an amount to adjust the allowance by")
            .must({ predicate: (value, model) => isTypeOfNumber(value), message: (value, model) => "The amount is not a valid number" })
            .must({ predicate: (value, model) => validateIsWholeOrHalfDay(value!.toString()), message: (value, model) => "The amount must be whole or half days" })
            .must({ predicate: (value, model) => +value! !== 0, message: (value, model) => "The amount is must be non-zero" })
            .must({ predicate: (value, model) => +value! <= 50, message: (value, model) => "The amount is greater than 50 days" })
            .must({ predicate: (value, model) => +value! >= -50, message: (value, model) => "The amount is less than -50 days" });
    }
}
