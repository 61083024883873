//libraries
import { makeObservable, observable, action } from "mobx";
//app
import { APIClient, RelayCommand, theme } from "Application";
import { ViewModelBase } from "@shoothill/core";

import { ProgrammingModel } from "./ProgrammingModel";
import { GETAdminProgrammingDetailsEndpoint } from "../../Endpoints/GETAdminProgrammingDetailsEndpoint";
import { OverviewViewModel } from "../OverviewViewModel";

export class ProgrammingViewModel extends ViewModelBase<ProgrammingModel> {
    public apiClient = new APIClient();
    public render: boolean = false;
    private parentViewModel: OverviewViewModel;
    public timeoutDuration: number = 60000;
    public requestHasTimedOut: boolean = false;

    constructor(parentViewModel: OverviewViewModel, model = new ProgrammingModel()) {
        super(model, false);
        makeObservable(this, {
            //observables
            render: observable,
            timeoutDuration: observable,
            requestHasTimedOut: observable,
            //actions
            setRender: action,
            setTimeoutDuration: action,
            setRequestHasTimedOut: action,
            //computed values
        });
        this.parentViewModel = parentViewModel;
        // this.getProgrammingDetails();
    }

    public setRender = (value: boolean) => (this.render = value);
    public setTimeoutDuration = (value: number) => (this.timeoutDuration = value);
    public setRequestHasTimedOut = (value: boolean) => (this.requestHasTimedOut = value);

    //region endpoint calls

    public retryRequestCommand = new RelayCommand(() => {
        this.setTimeoutDuration(this.timeoutDuration * 2);
        this.getProgrammingDetails();
    });

    public getProgrammingDetails = async () => {
        this.setRender(false);
        this.setRequestHasTimedOut(false);
        this.apiClient.setTimeout(this.timeoutDuration);
        const start = performance.now();
        await this.apiClient
            .sendAsync(new GETAdminProgrammingDetailsEndpoint(this, this.parentViewModel.model.startDateFilter, this.parentViewModel.model.endDateFilter))
            .catch((e) => {
                console.log(e);
            })
            .then(() => {
                const end = performance.now();
                const duration = end - start;
                if (!this.apiClient.IsRequestSuccessful && duration >= this.timeoutDuration) {
                    this.setRequestHasTimedOut(true);
                }
            })
            .finally(() => {
                this.setRender(true);
            });
    };

    //endregion endpoint calls

    //region getter values
    public get numberOfLiveProjects() {
        return this.model.numberOfLiveProjects;
    }

    public get numberOfProjectsOnHold() {
        return this.model.numberOfProjectsOnHold;
    }

    public get totalBillableEffort() {
        return this.model.totalBillableEffort;
    }

    public get totalBilledEffort() {
        return this.model.totalBilledEffort;
    }

    public get targetHours() {
        return this.model.targetHours;
    }

    public get programmedHoursPercentage() {
        let retVal = 0;

        if (this.totalBilledEffort > 0 && this.targetHours > 0) {
            retVal = (this.totalBilledEffort / this.targetHours) * 100;
        }
        return parseFloat(retVal.toFixed(2));
    }

    public get overage() {
        return Number(((this.totalBilledEffort ?? 0) - this.targetHours).toFixed(2));
    }
    public get overageHoursStatusColour() {
        return this.overage < 0 ? "crimson" : "inherit";
    }
    public get targetHoursPercentage() {
        let retVal = 100;

        retVal -= this.programmedHoursPercentage;

        return parseFloat(retVal.toFixed(2));
    }

    public get programmedHoursPercentageStringRepresentation() {
        return this.programmedHoursPercentage.toString() + "%";
    }

    public get data(): any[] {
        const data = [
            {
                id: "programmed",
                label: "programmed",
                value: `${this.programmedHoursPercentage}`,
                color: `${theme.palette.common.secondary}`,
            },
            {
                id: "target",
                label: "target",
                value: `${this.targetHoursPercentage}`,
                color: `${theme.palette.common.dropZoneText}`,
            },
        ];
        return data;
    }

    public get isLoading() {
        return this.apiClient.IsBusy;
    }

    //endregion getter values

    //#region commands

    //#endregion commands
}
