import { debounce } from "@mui/material";
import { isEmptyOrWhitespace, isNullOrEmpty, ViewModelBase } from "@shoothill/core";
import { action, computed, makeObservable, observable } from "mobx";
import { container } from "tsyringe";
import { LookupStore } from "Stores/Domain";
import { APIClient } from "Application";
import { RelayCommand } from "Application/Commands";
import { AppUrls } from "AppUrls";
import { SuppliersModel } from "./SuppliersModel";
import { SuppliersItemViewModel } from "./SuppliersItemViewModel";
import { GETSuppliersEndpoint } from "./GETSuppliersEndpoint";

export class SuppliersViewModel extends ViewModelBase<SuppliersModel> {
    public apiClient = new APIClient();
    public suppliersViewModels = observable<SuppliersItemViewModel>([]);
    public timeoutId: NodeJS.Timeout | null = null;

    constructor() {
        super(new SuppliersModel(), false);

        makeObservable(this, {
            timeoutId: observable,
            // Computeds
            canFilterSuppliers: computed,
            filteredSuppliers: computed,
            canSortSuppliers: computed,
            filteredAndSortedSuppliers: computed,

            suppliersViewModels: observable,

            setTimeoutId: action,
        });

        let _ = this.apiClient.sendAsync(new GETSuppliersEndpoint(this));
    }

    public setTimeoutId = (timeoutId: NodeJS.Timeout) => {
        this.timeoutId = timeoutId;
    };

    // #region Sorting

    public updateSortCommand = new RelayCommand((key: string, sortDescending: boolean) => {
        this.model.sortKey = key;
        this.model.sortDescending = sortDescending;
    });

    // #region Filtering

    public updateFilterKeywordCommand = new RelayCommand((keyword: string) => {
        this.debouncedUpdateFilerKeyword(keyword);
    });

    private debouncedUpdateFilerKeyword = (keyword: string) => {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
        this.setTimeoutId(
            setTimeout(() => {
                this.model.setValue("filterKeyword", keyword);
            }, 500),
        );
    };

    public updateFilterSupplierTypeIdCommand = new RelayCommand((supplierTypeId: string) => {
        this.model.setValue("filterSupplierTypeId", supplierTypeId);
    });

    public updateFilterSupplierSubTypeIdCommand = new RelayCommand((supplierSubTypeId: string) => {
        this.model.setValue("filterSupplierSubTypeId", supplierSubTypeId);
    });

    public get canSortSuppliers(): boolean {
        return !isEmptyOrWhitespace(this.model.sortKey);
    }

    public get canFilterSuppliers(): boolean {
        return !isEmptyOrWhitespace(this.model.filterKeyword) || !isEmptyOrWhitespace(this.model.filterSupplierTypeId) || !isEmptyOrWhitespace(this.model.filterSupplierSubTypeId);
    }
    public get filteredSuppliers(): SuppliersItemViewModel[] {
        if (!this.canFilterSuppliers) {
            return this.suppliersViewModels;
        }

        const { filterKeyword, filterSupplierTypeId, filterSupplierSubTypeId } = this.model;
        return this.suppliersViewModels.filter((vm) => vm.filterPredicate(filterKeyword, filterSupplierTypeId, filterSupplierSubTypeId));
    }

    public get filteredAndSortedSuppliers(): SuppliersItemViewModel[] {
        return this.canSortSuppliers
            ? this.filteredSuppliers.slice().sort((lhs, rhs) => {
                  const lhsValue = lhs[this.model.sortKey as keyof SuppliersItemViewModel];
                  const rhsValue = rhs[this.model.sortKey as keyof SuppliersItemViewModel];
                  if (lhsValue < rhsValue) {
                      return this.model.sortDescending ? 1 : -1;
                  }
                  if (lhsValue > rhsValue) {
                      return this.model.sortDescending ? -1 : 1;
                  }
                  return 0;
              })
            : this.filteredSuppliers.slice();
    }

    public get supplierTypeOptions() {
        const options = this.suppliersViewModels.slice().map((t) => {
            return {
                key: t.model.supplierTypeId,
                text: `${t.model.supplierTypeName} `,
            };
        });

        const distinctOptions = options.filter((option, index, arr) => arr.findIndex((t) => t.key === option.key) === index);

        return [{ key: "", text: "All supplier types" }, ...distinctOptions];
    }

    public get supplierSubTypeOptions() {
        const options = this.suppliersViewModels
            .slice()
            .filter((s) => s.model.supplierSubTypeId)
            .map((t) => {
                return {
                    key: t.model.supplierSubTypeId as string,
                    text: `${t.model.supplierSubTypeName} `,
                };
            });

        const distinctOptions = options.filter((option, index, arr) => arr.findIndex((t) => t.key === option.key) === index);

        return [{ key: "", text: "All supplier subtypes" }, ...distinctOptions];
    }

    // #endregion Sorting

    // #region Navigation Commands

    public navigateToNewSuppliersFormCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.Suppliers.New);
    });

    public navigateToDetailCommand = new RelayCommand((id: string) => {
        this.history.push(AppUrls.Client.Suppliers.Details.replace(":id", id));
    });

    // #eendregion Navigation Commands
}
