import { Box, Nullcommand, ThemedButton, ThemedText, setPageTitle } from "Application";

import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { PlanningApplicationViewModel } from "./PlanningApplicationViewModel";
import { Tabbed } from "Styles/ApplicationStyles";
import styled from "@emotion/styled";
import { AppUrls } from "AppUrls";
import { Routes } from "react-router";
import { PlanningApplicationTableView } from "./Table/PlanningApplicationTableView";
import { Modal } from "@fluentui/react";
import { AddEditPlanningApplicationView } from "./AddEdit/AddEditPlanningApplicationView";

const Container = styled(Box)`
    width: 100%;
    margin-top: -1px;
    border-top: 1px solid #d6d6d6;
    background-color: #fff;
    min-height: 70vh;
    padding: 30px;
`;

const ButtonContainer = styled.div`
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(calc(-50% - 15px));
`;

export const PlanningApplicationView: React.FC = observer(() => {
    // #region Code Behind

    const [viewModel] = useState(() => new PlanningApplicationViewModel());

    useEffect(() => {
        setPageTitle("Planning");
        viewModel.setActiveTab(AppUrls.Client.PlanningApplication.Table.Live);
    }, []);

    return (
        <Box overflowY="auto" id="planning-view">
            <Box position={"relative"} margin={"30px 30px 0px 30px"}>
                <Tabbed style={{ margin: "0px" }}>
                    <Box
                        className={viewModel.activeTabClassName(AppUrls.Client.PlanningApplication.Table.Live)}
                        onClick={() => viewModel.setActiveTab(AppUrls.Client.PlanningApplication.Table.Live)}
                    >
                        <ThemedText fontStyle={"h2"}>Live</ThemedText>
                    </Box>
                    <Box
                        className={viewModel.activeTabClassName(AppUrls.Client.PlanningApplication.Table.Approved)}
                        onClick={() => viewModel.setActiveTab(AppUrls.Client.PlanningApplication.Table.Approved)}
                    >
                        <ThemedText fontStyle={"h2"}>Approved</ThemedText>
                    </Box>
                    <Box
                        className={viewModel.activeTabClassName(AppUrls.Client.PlanningApplication.Table.Refused)}
                        onClick={() => viewModel.setActiveTab(AppUrls.Client.PlanningApplication.Table.Refused)}
                    >
                        <ThemedText fontStyle={"h2"}>Refused</ThemedText>
                    </Box>
                    <Box
                        className={viewModel.activeTabClassName(AppUrls.Client.PlanningApplication.Table.Withdrawn)}
                        onClick={() => viewModel.setActiveTab(AppUrls.Client.PlanningApplication.Table.Withdrawn)}
                    >
                        <ThemedText fontStyle={"h2"}>Withdrawn</ThemedText>
                    </Box>
                </Tabbed>
                <ButtonContainer>
                    <ThemedButton paletteColor="primary" command={viewModel.addPlanningApplicationCommand} displayName="new planning app." />
                </ButtonContainer>
            </Box>

            <Container>
                <PlanningApplicationTableView viewModel={viewModel.tableViewModel} />
                {viewModel.showAddEditModal && (
                    <Modal isOpen={viewModel.showAddEditModal} onDismiss={viewModel.dismissAddModal} styles={{ main: { width: "80%", maxWidth: "1000px" } }}>
                        <AddEditPlanningApplicationView
                            onDismissCommand={viewModel.dismissPlanningApplicationModalCommand}
                            onSubmitSuccessCommand={viewModel.onPlanningApplicationModalSubmitSuccessCommand}
                            id={viewModel.planningApplicationIdToAdd!}
                        />
                    </Modal>
                )}
            </Container>
        </Box>
    );
});
