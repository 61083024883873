import styled from "@emotion/styled";
import React from "react";

const CheckMarkContainer = styled.div`
    display: inline-block;
    width: 22px;
    height: 22px;
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
`;

const CheckMarkStem = styled.div`
    position: absolute;
    width: 2px;
    height: 10px;
    background-color: #fcfcfc;
    left: 11px;
    top: 4px;
`;

const CheckMarkKick = styled.div`
    position: absolute;
    width: 7px;
    height: 2px;
    background-color: #fcfcfc;
    left: 6px;
    top: 13px;
`;

export const CheckMark: React.FC = () => {
    return (
        <CheckMarkContainer>
            <CheckMarkStem />
            <CheckMarkKick />
        </CheckMarkContainer>
    );
};
