import { isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { computed, makeObservable, observable } from "mobx";
import moment from "moment";
import { container } from "tsyringe";

import { APIClient, RelayCommand } from "Application";
import { LookupStore } from "Stores/Domain";
import { TaskModel } from "Views/Resource/AssignedTasks/Table/ConfigureSubViews/TaskModel";
import { TaskViewModel } from "Views/Resource/AssignedTasks/Table/ConfigureSubViews/TaskViewModel";
import {
    CURR_CALENDAR_MONTH,
    CURR_CALENDAR_QUARTER,
    CURR_CALENDAR_WEEK,
    CURR_CALENDAR_YEAR,
    CURR_FINANCIAL_YEAR,
    PREV_CALENDAR_MONTH,
    PREV_CALENDAR_QUARTER,
    PREV_CALENDAR_WEEK,
    PREV_CALENDAR_YEAR,
    PREV_FINANCIAL_YEAR,
} from "Views/Shared/Constants";
import { TimesheetModel } from "./TimesheetModel";
import { TimesheetItemViewModel } from "./TimesheetItemViewModel";
import { GETAllBilledProjectTaskAssignmentsEndpoint } from "../Endpoints/GETAllBilledProjectTaskAssignmentsEndpoint";
import { GetFilteredBilledProjectTaskAssignmentsCSVEndpoint } from "../Endpoints/GETFilteredBilledProjectTaskAssignmentsCSVEndpoint";
import { ViewModelBaseExtendedViewModel } from "Application/ViewModels/ViewModelBaseUI";

export class TimesheetViewModel extends ViewModelBaseExtendedViewModel<TimesheetModel> {
    private readonly DEFAULT_PERIODTYPE = "Last Week";

    public apiClient = new APIClient();
    public lookupStore = container.resolve(LookupStore);
    public taskViewModel: TaskViewModel | null = null;
    public tasks = observable<TimesheetItemViewModel>([]);

    constructor() {
        super(new TimesheetModel());

        makeObservable(this, {
            // Observables
            tasks: observable,
            taskViewModel: observable,

            // Computeds
            canDisplayTotalBillableEffort: computed,
            canDisplayTotalBilledEffort: computed,
        });

        this.model.financialPeriodTypeId = this.lookupStore.getFinancialPeriodTypes.find((type) => type.text == this.DEFAULT_PERIODTYPE)!.key;
        this.updateFinancialPeriodTypeCommand.execute(this.model.financialPeriodTypeId);
    }

    // #region Properties

    public get canDisplayTotalBillableEffort() {
        return this.model.totalBillableEffort !== null;
    }

    public get totalBillableEffort() {
        return this.model.totalBillableEffort;
    }

    public get canDisplayTotalBilledEffort() {
        return this.model.totalBilledEffort !== null;
    }

    public get totalBilledEffort() {
        return this.model.totalBilledEffort;
    }

    // #endregion Properties

    // #region Filtering

    public updateFilterKeywordCommand = new RelayCommand((keyword: string) => {
        this.model.filterKeyword = keyword;
    });

    public updateFilterProjectCommand = new RelayCommand((id: string | null) => {
        this.model.filterProjectId = id;
    });

    public updateFilterProjectTaskGroupCommand = new RelayCommand((id: string | null) => {
        this.model.filterProjectTaskGroupName = id;
    });

    public updateFilterProjectLeadCommand = new RelayCommand((id: string | null) => {
        this.model.filterProjectLeadId = id;
    });

    public downloadProjectTaskAssignmentsCSVCommand = new RelayCommand(async () => {
        if (this.apiClient.IsBusy) return;
        this.startedProcessingData();
        const _ = await this.apiClient.sendAsync(new GetFilteredBilledProjectTaskAssignmentsCSVEndpoint(this));
        this.finishedProcessingData();
    });

    public resetFiltersCommand = new RelayCommand(() => {
        this.model.filterKeyword = "";
        this.model.filterProjectId = null;
        this.model.filterProjectTaskGroupName = null;
        this.model.filterProjectLeadId = null;

        this.model.financialPeriodTypeId = this.lookupStore.getFinancialPeriodTypes.find((type) => type.text == this.DEFAULT_PERIODTYPE)!.key;
        this.updateFinancialPeriodTypeCommand.execute(this.model.financialPeriodTypeId);
    });

    public get projects() {
        const options = this.tasks.map((t) => {
            return {
                key: t.model.projectId,
                text: `${t.model.reference} ${t.model.title}`,
            };
        });

        const distinctOptions = options.filter((option, index, arr) => arr.findIndex((t) => t.key === option.key) === index);

        return [{ key: "", text: "All projects" }, ...distinctOptions];
    }

    public get taskGroups() {
        const options = this.tasks.map((t) => {
            return {
                key: t.model.projectTaskGroupName,
                text: t.model.projectTaskGroupName,
            };
        });

        const distinctOptions = options.filter((option, index, arr) => arr.findIndex((t) => t.key === option.key) === index);

        return [{ key: "", text: "All stages" }, ...distinctOptions];
    }

    public get projectLeads() {
        const options = this.tasks
            .map((t) => {
                return {
                    key: t.model.projectLeadId,
                    text: `${t.model.projectLeadFirstName} ${t.model.projectLeadLastName}`,
                };
            })
            .sort((a, b) => (a.text > b.text ? 1 : b.text > a.text ? -1 : 0));

        const distinctOptions = options.filter((option, index, arr) => arr.findIndex((t) => t.key === option.key) === index);

        return [{ key: "", text: "All leads" }, ...distinctOptions];
    }

    public get canFilterTasks(): boolean {
        return (
            !isEmptyOrWhitespace(this.model.filterKeyword) ||
            !isEmptyOrWhitespace(this.model.filterProjectId) ||
            !isEmptyOrWhitespace(this.model.filterProjectTaskGroupName) ||
            !isEmptyOrWhitespace(this.model.filterProjectLeadId)
        );
    }

    public get filteredTasks(): TimesheetItemViewModel[] {
        return this.canFilterTasks
            ? this.tasks.filter((vm) =>
                  vm.filterPredicate(this.model.filterKeyword, this.model.filterProjectId, this.model.filterProjectTaskGroupName, this.model.filterProjectLeadId),
              )
            : this.tasks;
    }

    // #endregion Filtering

    // #region Duration Filtering

    public updateFilterStartDateCommand = new RelayCommand((date: Date | undefined) => {
        this.model.financialPeriodTypeId = "";
        this.model.filterStartDate = date;

        const _ = this.apiClient.sendAsync(new GETAllBilledProjectTaskAssignmentsEndpoint(this));
    });

    public updateFilterEndDateCommand = new RelayCommand((date: Date | undefined) => {
        this.model.financialPeriodTypeId = "";
        this.model.filterEndDate = date;

        const _ = this.apiClient.sendAsync(new GETAllBilledProjectTaskAssignmentsEndpoint(this));
    });

    public updateFinancialPeriodTypeCommand = new RelayCommand((value: string) => {
        this.model.financialPeriodTypeId = value;

        const financialPeriodType = this.lookupStore.financialPeriodTypes.find((type) => type.id === this.model.financialPeriodTypeId)?.type;

        switch (financialPeriodType) {
            case CURR_CALENDAR_WEEK:
                const startOfWeek = moment().startOf("week").add(1, "days");

                this.setValue("filterStartDate", startOfWeek.toDate());
                const endOfWeek = moment().endOf("week").add(-1, "days");
                this.setValue("filterEndDate", endOfWeek);
                break;

            case CURR_CALENDAR_MONTH:
                const startOfMonth = moment().startOf("month");

                this.setValue("filterStartDate", startOfMonth.toDate());
                this.setValue("filterEndDate", new Date());
                break;

            case CURR_CALENDAR_QUARTER:
                const startOfQuarter = moment().startOf("quarter");

                this.setValue("filterStartDate", startOfQuarter.toDate());
                this.setValue("filterEndDate", new Date());
                break;

            case CURR_CALENDAR_YEAR:
                const startOfYear = moment().startOf("year");

                this.setValue("filterStartDate", startOfYear.toDate());
                this.setValue("filterEndDate", new Date());
                break;

            case PREV_CALENDAR_WEEK:
                const startOfPrevWeek = moment().subtract(1, "weeks").startOf("week").add(1, "days");
                const endOfPrevWeek = moment().subtract(1, "weeks").endOf("week").subtract(1, "days");

                this.setValue("filterStartDate", startOfPrevWeek.toDate());
                this.setValue("filterEndDate", endOfPrevWeek.toDate());
                break;

            case PREV_CALENDAR_MONTH:
                const startOfPrevMonth = moment().subtract(1, "months").startOf("month");
                const endOfPrevMonth = moment().subtract(1, "months").endOf("month");

                this.setValue("filterStartDate", startOfPrevMonth.toDate());
                this.setValue("filterEndDate", endOfPrevMonth.toDate());
                break;

            case PREV_CALENDAR_QUARTER:
                const startOfPrevQuarter = moment().subtract(1, "quarters").startOf("quarter");
                const endOfPrevQuarter = moment().subtract(1, "quarters").endOf("quarter");

                this.setValue("filterStartDate", startOfPrevQuarter.toDate());
                this.setValue("filterEndDate", endOfPrevQuarter.toDate());
                break;

            case PREV_CALENDAR_YEAR:
                const startOfPrevYear = moment().subtract(1, "years").startOf("year");
                const endOfPrevYear = moment().subtract(1, "years").endOf("year");

                this.setValue("filterStartDate", startOfPrevYear.toDate());
                this.setValue("filterEndDate", endOfPrevYear.toDate());
                break;

            // start of each financial year is the 1st of April
            // end of each financial year is the 31st of March
            case CURR_FINANCIAL_YEAR:
                let startOfFinancialYear = moment().month(3).startOf("month");

                //compare current date to start of financial year.
                // current date is in current financial year
                if (startOfFinancialYear.toDate().getTime() < new Date().getTime()) {
                    this.setValue("filterStartDate", startOfFinancialYear.toDate());
                    this.setValue("filterEndDate", new Date());
                } else {
                    //current date is previous financial year
                    startOfFinancialYear = moment().subtract(1, "years").month(3).startOf("month");

                    this.setValue("filterStartDate", startOfFinancialYear.toDate());
                    this.setValue("filterEndDate", new Date());
                }
                break;

            case PREV_FINANCIAL_YEAR:
                let startOfPrevFinancialYear = moment().subtract(1, "years").month(3).startOf("month");
                //get the calendar date from last year
                const calendarDate = moment().subtract(1, "years");
                //compare current date to start of financial year.
                // current date is in current financial year
                if (startOfPrevFinancialYear.toDate().getTime() < calendarDate.toDate().getTime()) {
                    this.setValue("filterStartDate", startOfPrevFinancialYear.toDate());
                    this.setValue("filterEndDate", calendarDate.toDate());
                } else {
                    //current date is previous financial year
                    startOfPrevFinancialYear = startOfPrevFinancialYear.subtract(1, "years").month(3).startOf("month");
                    const endOfPrevFinancialYear = calendarDate.month(2).endOf("month");
                    this.setValue("filterStartDate", startOfPrevFinancialYear.toDate());
                    this.setValue("filterEndDate", endOfPrevFinancialYear.toDate());
                }
                break;

            default:
                return;
        }

        const _ = this.apiClient.sendAsync(new GETAllBilledProjectTaskAssignmentsEndpoint(this));
    });

    public get financialPeriodTypes() {
        const options = this.lookupStore.getFinancialPeriodTypes.map((item) => {
            return {
                key: item.key,
                text: item.text,
            };
        });

        return [...options, { key: "", text: "Custom" }];
    }

    // #endregion Duration Filtering

    // #region Sorting

    public updateSortCommand = new RelayCommand((key: string, sortDescending: boolean) => {
        this.model.sortKey = key;
        this.model.sortDescending = sortDescending;
    });

    public get canSortTasks(): boolean {
        return !isEmptyOrWhitespace(this.model.sortKey);
    }

    public get filteredAndSortedTasks(): TimesheetItemViewModel[] {
        return this.canSortTasks
            ? this.filteredTasks.slice().sort((lhs, rhs) => {
                  return (this.model.sortDescending ? lhs[this.model.sortKey] < rhs[this.model.sortKey] : lhs[this.model.sortKey] > rhs[this.model.sortKey]) ? 1 : -1;
              })
            : this.filteredTasks;
    }

    // #endregion Sorting

    // #region Billed Task

    public get canDisplayTask(): boolean {
        return this.taskViewModel !== null;
    }

    /**
     * Displays a task.
     */
    public displayTaskCommand = new RelayCommand((projectTaskId: string) => {
        this.taskViewModel = new TaskViewModel(projectTaskId, new TaskModel(), this.submitTaskCommand, this.cancelTaskCommand);
    });

    /**
     * Handles the result of saving a task.
     */
    public submitTaskCommand = new RelayCommand(async (projectTaskId: string) => {
        await this.apiClient.sendAsync(new GETAllBilledProjectTaskAssignmentsEndpoint(this));

        if (this.apiClient.IsRequestSuccessful) {
            this.taskViewModel = null;
            this.apiClient.reset();
        }
    });

    /**
     * Handles cancelling a task.
     */
    public cancelTaskCommand = new RelayCommand(() => {
        this.taskViewModel = null;
    });

    // #endregion Billed Task
}
