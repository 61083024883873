import { Stack, ActionButton, ComboBox, IComboBox, IComboBoxOption, ISelectableOption, mergeStyleSets, PersonaSize, Label, ILabelStyles } from "@fluentui/react";
import { isEmptyOrWhitespace } from "@shoothill/core";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { PropsWithChildren } from "react";

import { getThemeColorOption, ICommand } from "Application";
import AddIconSVG from "Assets/add.svg";
import { PersonaBase } from "Components/StyleFrameworks/FluentUI/Primitives/Active/PersonaBase";
import { theme } from "../../AppTheme";
import { ValidationLabelError } from "./ValidationLabelError";

export interface IThemedPersonaComboBoxProps {
    /**
     * An optional class name for use with the component.
     */
    className?: string;
    /**
     * A command to execute.
     */
    command: ICommand;
    /**
     * A value to use with the component. Will be passed back by the command.
     */
    value: () => string;
    /**
     * Text content to display in the placeholder.
     */
    placeholder?: string;
    /**
     * Text content to display in the error message.
     */
    validationMessage?: () => string;
    /**
     * A collection of options to display in the component dropdown.
     */
    options: IComboBoxOption[];
    /**
     * Text content to display on the component.
     */
    displayName?: string;
    /**
     * Style overrides for the label.
     */
    labelStyle?: Partial<ILabelStyles>;
}

export const ThemedPersonaComboBox = observer((props: PropsWithChildren<IThemedPersonaComboBoxProps>) => {
    const cbref = React.useRef<any>(null);

    // #region Code Behind

    const labelColor = getThemeColorOption("default");
    const defaultLabelStyle = mergeStyleSets(
        {
            root: {
                color: labelColor,
                fontFamily: theme.fontStyles.label.fontFamily ?? theme.defaultFontStyle.fontFamily,
                fontSize: theme.fontStyles.label.fontSize ?? theme.defaultFontStyle.fontSize,
                fontWeight: theme.fontStyles.label.fontWeight ?? theme.defaultFontStyle.fontWeight,
                letterSpacing: theme.fontStyles.label.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                lineHeight: theme.fontStyles.label.lineHeight ?? theme.defaultFontStyle.lineHeight,
                textTransform: theme.fontStyles.label.textTransform ?? theme.defaultFontStyle.textTransform,
            },
        },
        props.labelStyle,
    );

    const getClasseNames = () => {
        return clsx({
            [props.className!]: !isEmptyOrWhitespace(props.className),
        });
    };

    const getValidationMessage = (): string => {
        return isEmptyOrWhitespace(props.validationMessage?.() as string) ? "" : (props.validationMessage?.() as string);
    };

    const getOptions = (): IComboBoxOption[] => {
        const o = props.options.map((option) => option);
        return o;
    };

    const onChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        props.command.execute(option?.key);
    };

    const onRenderOption = (option?: ISelectableOption<any>): JSX.Element => {
        if (option) {
            return (
                <PersonaBase
                    displayName={option.text}
                    imageUrl={option.data}
                    size={PersonaSize.size24}
                    styles={mergeStyleSets({
                        primaryText: {
                            fontFamily: theme.fontStyles.h5.fontFamily ?? theme.defaultFontStyle.fontFamily,
                            fontSize: theme.fontStyles.h5.fontSize ?? theme.defaultFontStyle.fontSize,
                            fontWeight: theme.fontStyles.h5.fontWeight ?? theme.defaultFontStyle.fontWeight,
                            letterSpacing: theme.fontStyles.h5.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                            lineHeight: theme.fontStyles.h5.lineHeight ?? theme.defaultFontStyle.lineHeight,
                            textTransform: theme.fontStyles.h5.textTransform ?? theme.defaultFontStyle.textTransform,
                        },
                    })}
                />
            );
        }

        return <React.Fragment />;
    };

    const renderLabel = () => {
        if (!isEmptyOrWhitespace(getValidationMessage())) {
            return <ValidationLabelError displayName={props.displayName} validationMessage={props.validationMessage} />;
        }

        return (
            <Stack horizontal>
                <Label styles={defaultLabelStyle}>{props.displayName}</Label>
            </Stack>
        );
    };

    const renderButton = () => {
        if (!isEmptyOrWhitespace(props.value())) {
            const option = props.options.find((option) => option.key === props.value());

            return (
                <ActionButton
                    onClick={() => {
                        if (cbref.current) {
                            cbref.current.focus(true);
                        }
                    }}
                    style={{ padding: 0 }}
                >
                    <PersonaBase
                        displayName={option?.text}
                        imageUrl={option?.data}
                        size={PersonaSize.size32}
                        styles={mergeStyleSets({
                            primaryText: {
                                fontFamily: theme.fontStyles.h5.fontFamily ?? theme.defaultFontStyle.fontFamily,
                                fontSize: theme.fontStyles.h5.fontSize ?? theme.defaultFontStyle.fontSize,
                                fontWeight: theme.fontStyles.h5.fontWeight ?? theme.defaultFontStyle.fontWeight,
                                letterSpacing: theme.fontStyles.h5.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                                lineHeight: theme.fontStyles.h5.lineHeight ?? theme.defaultFontStyle.lineHeight,
                                textTransform: theme.fontStyles.h5.textTransform ?? theme.defaultFontStyle.textTransform,
                            },
                        })}
                    />
                </ActionButton>
            );
        } else {
            return (
                <ActionButton
                    onClick={() => {
                        if (cbref.current) {
                            cbref.current.focus(true);
                        }
                    }}
                    style={{ padding: 0 }}
                >
                    <PersonaBase
                        displayName={props.placeholder}
                        size={PersonaSize.size32}
                        styles={mergeStyleSets({
                            primaryText: {
                                fontFamily: theme.fontStyles.h5.fontFamily ?? theme.defaultFontStyle.fontFamily,
                                fontSize: theme.fontStyles.h5.fontSize ?? theme.defaultFontStyle.fontSize,
                                fontWeight: theme.fontStyles.h5.fontWeight ?? theme.defaultFontStyle.fontWeight,
                                letterSpacing: theme.fontStyles.h5.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                                lineHeight: theme.fontStyles.h5.lineHeight ?? theme.defaultFontStyle.lineHeight,
                                textTransform: theme.fontStyles.h5.textTransform ?? theme.defaultFontStyle.textTransform,
                                color: "#2D91FF",
                                textDecoration: "underline",
                            },
                        })}
                        coinStyles={mergeStyleSets({
                            imageArea: {
                                div: {
                                    backgroundImage: `url(${AddIconSVG})`,
                                    backgroundPosition: "center center",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "10px",
                                    backgroundColor: "#2D91FF",
                                },
                            },
                            initials: {
                                color: "transparent",
                            },
                        })}
                    />
                </ActionButton>
            );
        }
    };

    const renderComboxBox = () => {
        return (
            <ComboBox
                componentRef={cbref}
                className={getClasseNames()}
                onChange={onChange}
                options={getOptions()}
                onRenderOption={onRenderOption}
                comboBoxOptionStyles={mergeStyleSets({
                    root: {
                        minHeight: 40,
                    },
                })}
                styles={mergeStyleSets(
                    {
                        root: {
                            maxHeight: 0,
                            visibility: "hidden",
                        },
                    },
                    {
                        optionsContainer: {
                            fontFamily: theme.fontStyles.field.fontFamily ?? theme.defaultFontStyle.fontFamily,
                            fontSize: theme.fontStyles.field.fontSize ?? theme.defaultFontStyle.fontSize,
                            fontWeight: theme.fontStyles.field.fontWeight ?? theme.defaultFontStyle.fontWeight,
                            letterSpacing: theme.fontStyles.field.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                            lineHeight: theme.fontStyles.field.lineHeight ?? theme.defaultFontStyle.lineHeight,
                            textTransform: theme.fontStyles.field.textTransform ?? theme.defaultFontStyle.textTransform,
                            maxHeight: "336px",
                        },
                    },
                )}
            />
        );
    };

    // #endregion Code Behind

    return (
        <div>
            {renderLabel()}
            {renderButton()}
            {renderComboxBox()}
        </div>
    );
});
