import { observer } from "mobx-react-lite";
import React from "react";

import { AutoGrid, ThemedButton } from "Application";
import { QuoteFormViewModel } from "../QuoteFormViewModel";

export interface IButtonClusterSubViewProps {
    viewModel: QuoteFormViewModel;
}

/**
 * Displays a group of buttons for the quote form.
 */
export const ButtonClusterSubView: React.FC<IButtonClusterSubViewProps> = observer((props) => {
    const viewModel = props.viewModel;

    return (
        <AutoGrid
            backgroundColor="#F3F3F3"
            columnGap={"30px"}
            dc={`140px 140px 140px 140px 140px 140px`}
            m={"0"}
            padding={"15px 30px"}
            position="fixed"
            left="0"
            right="0"
            bottom="0"
            zIndex="10000"
        >
            {viewModel.canDisplaySaveQuoteAsDraftCommand && (
                <ThemedButton styles={{ root: { padding: "0 5px" } }} paletteColor={"primary"} displayName="Save as draft" command={viewModel.saveQuoteAsDraftCommand} />
            )}
            {viewModel.canDisplaySaveQuoteAsPendingApprovalCommand && (
                <ThemedButton
                    styles={{ root: { padding: "0 5px" } }}
                    paletteColor={"primary"}
                    displayName="Save as pending approval"
                    command={viewModel.saveQuoteAsPendingApprovalCommand}
                />
            )}
            {viewModel.canDisplayDisplayGenerateQuoteCommand && (
                <ThemedButton styles={{ root: { padding: "0 5px" } }} paletteColor={"primary"} displayName="Generate quote" command={viewModel.displayGenerateQuoteCommand} />
            )}
            {viewModel.canDisplayDisplayDeleteQuoteCommand && (
                <ThemedButton styles={{ root: { padding: "0 5px" } }} paletteColor={"tertiary"} displayName="Delete draft" command={viewModel.displayDeleteQuoteCommand} />
            )}
            {viewModel.canDisplayApproveQuoteCommand && (
                <ThemedButton styles={{ root: { padding: "0 5px" } }} paletteColor={"quarternary"} displayName="Approve quote" command={viewModel.approveQuoteCommand} />
            )}
            {viewModel.canDisplayDisplayRejectQuoteCommand && (
                <ThemedButton styles={{ root: { padding: "0 5px" } }} paletteColor={"tertiary"} displayName="Reject quote" command={viewModel.displayRejectQuoteCommand} />
            )}
            {viewModel.canDisplayDownloadAndSendQuoteCommand && (
                <ThemedButton styles={{ root: { padding: "0 5px" } }} paletteColor={"primary"} displayName="Download & Send" command={viewModel.downloadAndSendQuoteCommand} />
            )}
            {viewModel.canDisplayDisplayConvertQuoteToProjectCommand && (
                <ThemedButton
                    styles={{ root: { padding: "0 5px" } }}
                    paletteColor={"quarternary"}
                    displayName="Convert to project"
                    command={viewModel.displayConvertQuoteToProjectCommand}
                />
            )}
            {viewModel.canDisplayRevertLostQuoteCommand && (
                <ThemedButton
                    styles={{ root: { padding: "0 5px" } }}
                    paletteColor={"quarternary"}
                    displayName="Revert lost quote"
                    command={viewModel.confirmRevertLostQuoteCommand}
                />
            )}

            {viewModel.canDisplayReviseQuoteCommand && (
                <ThemedButton styles={{ root: { padding: "0 5px" } }} paletteColor={"primary"} displayName="Revise quote" command={viewModel.displayReviseQuoteCommand} />
            )}

            {viewModel.canDisplayDownloadPDFFileCommand && (
                <ThemedButton styles={{ root: { padding: "0 5px" } }} paletteColor={"primary"} displayName="Download pdf" command={viewModel.downloadPDFFileCommand} />
            )}
            {viewModel.canDisplayMarkQuoteAsLostCommand && (
                <ThemedButton styles={{ root: { padding: "0 5px" } }} displayName="Mark as lost" command={viewModel.displayMarkAsLostQuoteCommand} />
            )}
            <ThemedButton styles={{ root: { padding: "0 5px" } }} displayName="Cancel" command={viewModel.navigateToQuotesCommand} />
        </AutoGrid>
    );
});
