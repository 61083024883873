import React, { useState, useEffect, CSSProperties } from "react";
import { useTimeout } from "../Application/Hooks";
import { Text } from "@fluentui/react/lib/Text";
import { Box } from "./StyleFrameworks";
import { ThemedText } from "../Styles";

interface Props {
    //defines the component used to for rendering a loading animation
    component?: JSX.Element;
    //defines the number of milliseconds before the loader shows
    delayBeforeShow?: number;
    //defines the text used display the loading message
    loadingText?: string;
    //container override styles
    containerStyles?: CSSProperties;
    //container override styles
    componentStyles?: CSSProperties;
}

export const Loader = (props: Props) => {
    const [show, setShow] = useState(false);
    const { start, clear } = useTimeout(() => {
        setShow(true);
    }, props.delayBeforeShow);

    useEffect(() => {
        start();
        return () => {
            clear();
        };
    }, []);

    const style = props.containerStyles ?? ({} as CSSProperties);
    const componentStyle = props.componentStyles ?? ({} as CSSProperties);

    if (show) {
        return (
            <Box display={"flex"} flexDirection={"column"} alignItems="center" justifyContent="center" style={{ ...style }}>
                <Box style={{ ...componentStyle }}>{props.component}</Box>
                <ThemedText fontStyle={"h4"}>{props.loadingText}</ThemedText>
            </Box>
        );
    } else return <></>;
};

Loader.defaultProps = {
    delayBeforeShow: 0,
    loadingText: "Loading...",
    /*component: <CircularProgress />,*/
};
