import { ViewModelBase, isEmptyOrWhitespace } from "@shoothill/core";
import { computed, makeObservable, observable } from "mobx";

import { APIClient, RelayCommand } from "Application";
import { AppUrls } from "AppUrls";
import { TermsAndConditionItemViewModel } from "./TermsAndConditionItemViewModel";
import { GetAllTermsAndConditionEndpoint } from "./GetAllTermsAndConditionEndpoint";
import { TermsAndConditionListModel } from "./TermsAndConditionListModel";

export default class TermsAndConditionListViewModel extends ViewModelBase<TermsAndConditionListModel> {
    public apiClient = new APIClient();
    public termsAndConditionItemViewModel = observable<TermsAndConditionItemViewModel>([]);
    constructor() {
        super(new TermsAndConditionListModel());
        makeObservable(this, {
            termsAndConditionItemViewModel: observable,
            canFilterTermsAndCondition: computed,
            filteredTermsAndCondition: computed,
            canSortTermsAndCondition: computed,
            filteredAndSortedTermsAndCondition: computed,
        });
        const __ = this.apiClient.sendAsync(new GetAllTermsAndConditionEndpoint(this));
    }

    public updateFilterKeywordCommand = new RelayCommand((keyword: string) => {
        this.model.filterKeyword = keyword;
    });

    public get canSortTermsAndCondition(): boolean {
        return !isEmptyOrWhitespace(this.model.sortKey);
    }

    public get filteredTermsAndCondition(): TermsAndConditionItemViewModel[] {
        return this.canFilterTermsAndCondition
            ? this.termsAndConditionItemViewModel.filter((vm) => vm.filterPredicate(this.model.filterKeyword))
            : this.termsAndConditionItemViewModel;
    }

    public get canFilterTermsAndCondition(): boolean {
        return !isEmptyOrWhitespace(this.model.filterKeyword);
    }

    public get filteredAndSortedTermsAndCondition(): TermsAndConditionItemViewModel[] {
        return this.canSortTermsAndCondition
            ? this.filteredTermsAndCondition
                  .slice()
                  .sort((lhs, rhs) =>
                      (this.model.sortDescending ? lhs[this.model.sortKey] < rhs[this.model.sortKey] : lhs[this.model.sortKey] > rhs[this.model.sortKey]) ? 1 : -1,
                  )
            : this.filteredTermsAndCondition;
    }

    public navigateToEditForm = new RelayCommand((item: TermsAndConditionItemViewModel) => {
        this.history.push(AppUrls.Client.TermConditionTemplates.Edit.replace(":id", item.model.id));
    });

    public navigateToNewForm = new RelayCommand(() => {
        this.history.push(AppUrls.Client.TermConditionTemplates.New);
    });

    public updateSortCommand = new RelayCommand((key: string, sortDescending: boolean) => {
        this.model.sortKey = key;
        this.model.sortDescending = sortDescending;
    });
}
