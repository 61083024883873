import { isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { computed, makeObservable, observable } from "mobx";

import { RelayCommand } from "Application";
import { InProgressItemViewModel } from "./InProgressItemViewModel";
import { InProgressModel } from "./InProgressModel";

export class InProgressViewModel extends ViewModelBase<InProgressModel> {
    public tasks = observable<InProgressItemViewModel>([]);

    constructor() {
        super(new InProgressModel());

        makeObservable(this, {
            // Observables
            tasks: observable,
            // Computeds
            canFilterTasks: computed,
            filteredTasks: computed,
            canSortTasks: computed,
            filteredAndSortedTasks: computed,
        });
    }

    // #region Filtering

    public get canFilterTasks(): boolean {
        return !isEmptyOrWhitespace(this.model.filterKeyword);
    }

    public get filteredTasks(): InProgressItemViewModel[] {
        return this.canFilterTasks ? this.tasks.filter((vm) => vm.filterPredicate(this.model.filterKeyword)) : this.tasks;
    }

    // #endregion Filtering

    // #region Sorting

    public updateSortCommand = new RelayCommand((key: string, sortDescending: boolean) => {
        this.model.sortKey = key;
        this.model.sortDescending = sortDescending;
    });

    public get canSortTasks(): boolean {
        return !isEmptyOrWhitespace(this.model.sortKey);
    }

    public get filteredAndSortedTasks(): InProgressItemViewModel[] {
        return this.canSortTasks
            ? this.filteredTasks.slice().sort((lhs, rhs) => {
                  return (this.model.sortDescending ? lhs[this.model.sortKey] < rhs[this.model.sortKey] : lhs[this.model.sortKey] > rhs[this.model.sortKey]) ? 1 : -1;
              })
            : this.filteredTasks;
    }

    // #endregion Sorting
}
