import styled from "@emotion/styled";
import { IButtonStyles } from "@fluentui/react";
import { theme } from "Application";

export const Container = styled.div`
    height: 62px;
    min-width: 850px;
    display: grid;

    grid-template-columns: 1fr 250px repeat(2, 150px) 65px;
    padding-left: 70px;
    padding-right: 14px;
    align-items: center;
    width: 100%;

    border-bottom: 1px dashed #ff4b22;
    border-top: 1px dashed #ff4b22;
    border-left: 1px dashed #ff4b22;
    border-right: 1px dashed #ff4b22;
`;

export const Row = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 10px;
    border-right: 1px solid ${theme.palette.common.border};
`;

export const ActionsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const addEditButtonStyleOverride: IButtonStyles = {
    root: {
        backgroundColor: theme.palette.blueAccent.main,
        padding: "0px 0px",
        minWidth: "0px",
        borderWidth: 0,
        height: 20,
        width: 20,
        borderColor: theme.palette.blueAccent.light,
        borderRadius: 0,
    },
    rootFocused: {
        backgroundColor: theme.palette.blueAccent.main,
        borderWidth: 0,
        "::after": {
            outlineColor: theme.palette.blueAccent.light,
        },
    },
    rootHovered: {
        backgroundColor: theme.palette.blueAccent.main,
        opacity: 0.7,
        borderWidth: 0,
    },
    rootPressed: {
        backgroundColor: theme.palette.blueAccent.main,
        borderWidth: 0,
        opacity: 0.7,
    },
    rootDisabled: {
        borderWidth: 0,
        backgroundColor: theme.palette.disabled.main,
    },
};

export const removeCancelButtonStyleOverride: IButtonStyles = {
    root: {
        backgroundColor: "transparent",
        padding: "0px 0px",
        minWidth: "0px",
        borderWidth: 0,
        height: 20,
        width: 20,
        borderColor: theme.palette.blueAccent.light,
        borderRadius: 0,
    },
    rootFocused: {
        backgroundColor: "none",
        borderWidth: 0,
        "::after": {
            outlineColor: "transparent",
        },
    },
    rootHovered: {
        backgroundColor: "none",
        opacity: 0.9,
        borderWidth: 0,
    },
    rootPressed: {
        backgroundColor: "none",
        borderWidth: 0,
        opacity: 0.9,
    },
    rootDisabled: {
        borderWidth: 0,
        backgroundColor: "none",
    },
};

export const editServiceInputStyles = {
    root: {
        width: "100%",
        borderColor: "#E1E1E1",
    },
};
