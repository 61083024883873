import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { ChangeAllowanceModel } from "../ChangeAllowanceModel";

class Request {
    public leaveUserId: string = "";
    public numberOfDays: number | string | null = null;
}

class Response {}

export class POSTChangeLeaveAllowanceEndpoint extends Endpoint<Request, Response> {
    constructor() {
        super();

        this.verb(Http.Post);
        this.path(AppUrls.Server.Admin.Leave.ChangeAllowance);
    }

    public async HandleRequestAsync(model: ChangeAllowanceModel): Promise<any> {
        const request = new Request();

        request.leaveUserId = model.leaveRequestUser.id;
        request.numberOfDays = model.numberOfDays;

        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);
    }
}
