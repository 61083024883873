import _ from "lodash";
import { runInAction } from "mobx";

import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { EnquiryViewModel } from "./EnquiryViewModel";
import { EnquiryModel } from "./EnquiryModel";
import { EnquiryRequest } from "./Shared/EnquiryRequest";
import { EnquiryResponse } from "./Shared/EnquiryResponse";

export class POSTSaveEnquiryEndpoint extends Endpoint<Request, Response> {
    private readonly viewModel: EnquiryViewModel;

    constructor(viewModel: EnquiryViewModel) {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Enquiries.SaveEnquiry);
        this.AllowFileUploads();
        this.viewModel = viewModel;
    }

    public async HandleRequestAsync(model: EnquiryModel): Promise<any> {
        const request = new EnquiryRequest();

        EnquiryRequest.enquiryPart(request, model, this.viewModel.enquirySubViewModel.filesViewModel.model);
        EnquiryRequest.sourceOfEnquiryPart(request, this.viewModel.enquirySubViewModel.sourceOfEnquiryViewModel.model);
        EnquiryRequest.customerRequestPart(request, model);

        // We need to send the data to the server as form data here as we have files.
        const formData = new FormData();

        formData.append("data", JSON.stringify(request));

        for (const file of this.viewModel.enquirySubViewModel.filesViewModel.model?.files!) {
            formData.append(file.KEY, file.file!);
        }

        return Promise.resolve(formData);
    }

    public async HandleResponseAsync(response: EnquiryResponse): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            EnquiryResponse.subEnquiryResponsePart(response, this.viewModel);
            EnquiryResponse.customerResponsePart(response, this.viewModel);
            EnquiryResponse.notesResponsePart(response, this.viewModel);
            EnquiryResponse.remainingResponsePart(response, this.viewModel);

            // This endpoint should be the only one able to create a new enquiry.
            // So, replace the page url with an edit/id route.
            this.viewModel.history.replace(AppUrls.Client.Enquiries.Edit.replace(":id", response.id));
        });
    }
}
