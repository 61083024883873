import { FieldType, isNullOrUndefined, ViewModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { nanoid } from "nanoid";

import { formatDecimalNumber, RelayCommand } from "Application";
import { TaskModel } from "../TaskModel";
import { BillingNotesViewModel } from "./SubViews/BillingNotesViewModel";

export class BillingViewModel extends ViewModelBase<TaskModel> {
    private key = nanoid();

    // Sub viewmodels.
    public billingNotesViewModels = observable<BillingNotesViewModel>([]);

    constructor(model: TaskModel) {
        super(model);

        makeObservable(this, {
            // Observables
        });
    }

    // #region Properties

    public get displayName() {
        return "Billing";
    }

    public get KEY() {
        return this.key;
    }

    public get projectTaskBillableEffort() {
        return this.model.projectTaskBillableEffort ? formatDecimalNumber(this.model.projectTaskBillableEffort) : "";
    }

    public get canDisplayAssignmentNotes() {
        return this.billingNotesViewModels.length > 0;
    }
}
