import { ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";
import { ICommand } from "Application";

export class ConvertToEnquiryViewModel extends ViewModelBase<any> {
    public parentCancelConvertToEnquiryCommand: ICommand;
    public parentConvertToEnquiryCommand: ICommand;

    constructor(parentCancelConvertToEnquiryCommand: ICommand, parentConvertToEnquiryCommand: ICommand) {
        super({});

        this.parentCancelConvertToEnquiryCommand = parentCancelConvertToEnquiryCommand;
        this.parentConvertToEnquiryCommand = parentConvertToEnquiryCommand;

        makeObservable(this, {});
    }

    public get displayMessage() {
        return "Are you sure you want to convert the lost enquiry to an active one?";
    }
}
