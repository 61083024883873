import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import moment from "moment";

export class StaffModel extends ModelBase<StaffModel> {
    // Filtering
    public filterStartDate: Date | null = null;
    public filterEndDate: Date | null = null;
    public financialPeriodTypeId: string | null = null;
    public calendarUserId: string | null = null;

    // Other
    public totalBillableEffort: number | null = null;
    public totalBilledEffort: number | null = null;

    // Work calendar user
    public workCalendarUserId: string | null = null;
    public workCalendarStartDayFilter: Date = moment().startOf("week").add(1, "days").toDate();
    constructor() {
        super();

        makeObservable(this, {
            // Observables
            filterStartDate: observable,
            filterEndDate: observable,
            financialPeriodTypeId: observable,

            totalBillableEffort: observable,
            totalBilledEffort: observable,

            workCalendarUserId: observable,
        });
    }
}
