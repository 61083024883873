import { IButtonStyles, IconButton, IIconProps } from "@fluentui/react";
import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React from "react";

import { ICommand } from "../../../../../Application/Commands";
import { themePaletteOptions, themeShapeOptions, themeSizeOptions } from "../../../../../Styles/IShoothillTheme";

/**
 * Icon button interface.
 */
export interface IIconButtonBaseProps {
    /**
     * An optional class name for use with the button.
     */
    className?: string;
    /**
     * A command to execute.
     */
    command: ICommand;
    /**
     * A value to use with the button. Willbe passed back by the command.
     */
    value?: string | number | boolean;
    /**
     * An icon to display on the button.
     */
    icon?: JSX.Element;
    /**
     * Styling of the button.
     */
    styles?: IButtonStyles;
    /**
     * The size of the button - use this if using generic button.
     */
    size?: themeSizeOptions;
    /**
     * The color of the button - use this if using the generic button.
     */
    paletteColor?: themePaletteOptions;
    /**
     * The shape of the button - use this if using the generic button.
     */
    shape?: themeShapeOptions;
}

export const IconButtonBase: React.FC<IIconButtonBaseProps> = observer((props) => {
    // #region Code Behind

    const getClasseNames = () => {
        return clsx({
            [props.className!]: !isEmptyOrWhitespace(props.className),
        });
    };

    const isDisabled = (): boolean => {
        return isNullOrUndefined(props.command.canExecute) ? false : !props.command.canExecute();
    };

    const onClick = (): void => {
        props.command.execute(props.value);
    };

    const getIcon = (): any | undefined => {
        return props.icon ? props.icon : undefined;
    };

    const getStyles = (): IButtonStyles | undefined => {
        return !isNullOrUndefined(props.styles) ? props.styles : undefined;
    };

    // #endregion Code Behind

    return <IconButton onRenderIcon={() => getIcon()} styles={getStyles()} className={getClasseNames()} onClick={onClick} disabled={isDisabled()} />;
});
