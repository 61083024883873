import { isEmptyOrWhitespace, NullLogger } from "@shoothill/core";
import { QuoteContainerModel } from "../../QuoteContainerModel";
import { SourceOfEnquiryModel } from "Views/Shared/SourceOfEnquiry/SourceOfEnquiryModel";

export class QuoteTaskRequest {
    id: string | null = null;
    serviceGroupId: string = "";
    serviceId: string | null = null;
    taskName: string = "";
    manHours: number | null = null;
    hourlyRate: number | null = null;
    ordinal: number | null = null;
}

class SourceOfEnquiryRequest {
    public id: string | null = null;
    public sourceOfEnquiryTypeId: string = "";
    public detail: string | null = null;
}

export class QuoteRequest {
    // #region Request Data

    id: string | null = null;
    isQuickQuote: boolean | null = null;
    quoteStatusId: string | null = null;
    quoteLeadId: string | null = null;
    projectTypeId: string = "";
    projectId: string | null = null;
    contactTypeId: string = "";
    customerId: string | null = null;
    customerContactId: string | null = null;
    title: string = "";
    leadInWeeks: number | null = null;
    durationWeeks: number | null = null;
    confidence: number | null = null;
    desirability: number | null = null;
    siteAddress1: string | null = null;
    siteAddress2: string | null = null;
    siteAddress3: string | null = null;
    siteCity: string | null = null;
    sitePostcode: string | null = null;
    sameAsBusinessAddress: boolean = false;
    letterTemplateId: string | null = null;
    letterDetails: string | null = null;
    termAndConditionId: string | null = null;
    termsAndConditionsDetails: string | null = null;
    signatoryUserId: string | null = null;
    sourceOfEnquiry: SourceOfEnquiryRequest = new SourceOfEnquiryRequest();
    tasks: QuoteTaskRequest[] = [];
    quoteDate: Date | null = null;

    // boolean flag that determines if any audit trail notes will be generated
    // isAutoSaving == true => no audit trail notes are saved to prevent data pollution
    // isAutoSaving == false => audit trail notes are saved since it was determined by user action
    isAutoSaving: boolean = false;

    // #endregion Request Data

    // #region ViewModel Part Mappings to Request

    public static quoteRequestPart(request: QuoteRequest, model: QuoteContainerModel) {
        request.id = model.quoteForm.id;
        request.isQuickQuote = model.quoteForm.isQuickQuote;
        request.quoteStatusId = model.quoteForm.quoteStatusId;
        request.quoteLeadId = model.quoteForm.quote.quoteLeadId;
        request.projectTypeId = model.quoteForm.quote.projectTypeId;
        request.projectId = isEmptyOrWhitespace(model.quoteForm.quote.projectId) ? null : model.quoteForm.quote.projectId;
        request.title = model.quoteForm.quote.title;
        request.leadInWeeks = model.quoteForm.quote.leadInWeeks;
        request.durationWeeks = model.quoteForm.quote.durationWeeks;
        request.confidence = model.quoteForm.quote.confidence;
        request.desirability = model.quoteForm.quote.desirability;
        request.quoteDate = model.quoteForm.quote.quoteDate;
    }

    public static sourceOfEnquiryPart(request: QuoteRequest, model: SourceOfEnquiryModel) {
        request.sourceOfEnquiry.id = model.id;
        request.sourceOfEnquiry.detail = model.detail;
        request.sourceOfEnquiry.sourceOfEnquiryTypeId = model.sourceOfEnquiryTypeId;
    }

    public static customerRequestPart(request: QuoteRequest, model: QuoteContainerModel) {
        request.contactTypeId = model.quoteForm.customer.contactTypeId;
        request.customerId = model.quoteForm.customer.customerId;
        request.customerContactId = model.quoteForm.customer.customerContactId;
        request.siteAddress1 = model.quoteForm.customer.siteAddress1;
        request.siteAddress2 = model.quoteForm.customer.siteAddress2;
        request.siteAddress3 = model.quoteForm.customer.siteAddress3;
        request.siteCity = model.quoteForm.customer.siteCity;
        request.sitePostcode = model.quoteForm.customer.sitePostcode;
        request.sameAsBusinessAddress = model.quoteForm.customer.sameAsBusinessAddress;
    }

    public static letterRequestPart(request: QuoteRequest, model: QuoteContainerModel) {
        request.letterTemplateId = model.quoteForm.letterModel.letterTemplateId;
        request.letterDetails = model.quoteForm.letterModel.letterDetails;
    }

    public static tasksRequestPart(request: QuoteRequest, model: QuoteContainerModel) {
        for (const taskGroup of model.quoteForm.servicesModel.taskGroups) {
            for (const task of taskGroup.tasks) {
                const taskRequest = new QuoteTaskRequest();

                taskRequest.id = task.id;
                taskRequest.serviceGroupId = taskGroup.id;
                taskRequest.serviceId = task.serviceId;
                taskRequest.taskName = task.taskName;
                taskRequest.manHours = task.manHours;
                taskRequest.hourlyRate = task.hourlyRate;
                taskRequest.ordinal = task.ordinal;

                request.tasks.push(taskRequest);
            }
        }
    }

    public static termsAndConditionsRequestPart(request: QuoteRequest, model: QuoteContainerModel) {
        request.termAndConditionId = model.quoteForm.termsAndConditionsModel.termAndConditionId;
        request.termsAndConditionsDetails = model.quoteForm.termsAndConditionsModel.termsAndConditionsDetails;
        request.signatoryUserId = model.quoteForm.termsAndConditionsModel.signatoryUserId;
    }

    public static autoSaveRequestPart(request: QuoteRequest, isAutoSaving: boolean) {
        request.isAutoSaving = isAutoSaving;
    }

    // #endregion ViewModel Part Mappings to Request
}
