import { FieldType, isEmptyOrWhitespace, KeyValuePair, ViewModelBase } from "@shoothill/core";
import { computed, makeObservable, observable } from "mobx";

import { APIClient, ICommand, IKeyState, RelayCommand } from "Application";
import { AppUrls } from "AppUrls";
import { GETUserWithRelatedByIdEndpoint } from "./GETUserWithRelatedByIdEndpoint";
import { GETUserRelatedEndpoint } from "./GETUserRelatedEndpoint";
import { POSTSaveUserEndpoint } from "./POSTSaveUserEndpoint";
import { DELETEUserEndpoint } from "./DELETEUserEndpoint";
import { RoleLiteViewModel } from "./RoleLiteViewModel";
import { PersonaFileViewModel } from "./SubViews/PersonaUploaderViewModel";
import { UserModel, UserModelValidator } from "./UserModel";

export class UserViewModel extends ViewModelBase<UserModel> {
    public apiClient = new APIClient();

    public rolesLiteViewModel = observable<RoleLiteViewModel>([]);
    public personaFileViewModel = new PersonaFileViewModel();
    public deletePopup: boolean = false;

    constructor(id: string | undefined) {
        super(new UserModel());
        this.setValidator(new UserModelValidator());

        makeObservable(this, {
            //Observable values
            deletePopup: observable,
            //Computed Values
            canDisplayDeleteUserCommand: computed,
            cannotBeAssignedToTasks: computed,
            isUserAdmin: computed,
            isUserSeniorAssociate: computed,
        });

        switch (true) {
            case !id:
            case id === "new":
                this.apiClient.sendAsync(new GETUserRelatedEndpoint(this));
                break;

            default:
                this.apiClient.sendAsync(new GETUserWithRelatedByIdEndpoint(id!, this));
                break;
        }
    }

    // #region Properties

    public getRoles = () => {
        return this.rolesLiteViewModel.map((role) => ({
            key: role.getValue("id"),
            text: role.getValue("name"),
        })) as KeyValuePair[];
    };

    public get currentUserRole() {
        return this.rolesLiteViewModel.find((vm) => vm.model.id == this.model.currentRoleId);
    }

    public get isUserAdmin() {
        return this.currentUserRole?.model.normalizedName === "admin";
    }

    public get isUserSeniorAssociate() {
        return this.currentUserRole?.model.normalizedName === "seniorassociate";
    }
    /**
     * Returns/handles the display names & labels for the view.
     */
    public get displayName() {
        return isEmptyOrWhitespace(this.model.id) ? "New user" : "Edit user";
    }

    public get passwordDisplayName() {
        return isEmptyOrWhitespace(this.model.id) ? "Choose password" : "Set new password";
    }

    public get passwordPlaceHolderName() {
        return isEmptyOrWhitespace(this.model.id) ? "Please enter a password" : "Please enter a new password";
    }

    public get confirmPasswordDisplayName() {
        return isEmptyOrWhitespace(this.model.id) ? "Confirm password" : "Confirm new password";
    }

    public get confirmPasswordPlaceHolderName() {
        return isEmptyOrWhitespace(this.model.id) ? "Please confirm the password" : "Please confirm new password";
    }

    public get canDisplayDelete(): boolean {
        return this.deletePopup;
    }

    public get displayFullName(): string {
        return `${this.model.firstName} ${this.model.lastName}`;
    }

    public get cannotBeAssignedToTasks() {
        return !this.model.isAssignable;
    }

    public get canShowUpdateInvoicingForecastVisibleCommand() {
        return this.isUserAdmin || this.isUserSeniorAssociate;
    }

    // #endregion Properties

    // #region Commands

    public updateFirstNameCommand: ICommand = new RelayCommand((value: string, keyState: IKeyState) => {
        this.updateField("firstName", value, keyState);
    });

    public updateLastNameCommand: ICommand = new RelayCommand((value: string, keyState: IKeyState) => {
        this.updateField("lastName", value, keyState);
    });

    public updateEmailAddressCommand: ICommand = new RelayCommand((value: string) => {
        this.updateField("emailAddress", value);
    });

    public updateSelectRoleCommand: ICommand = new RelayCommand((value: string | null) => {
        this.updateField("currentRoleId", value);
        // side effect: if new privilege is not senior associate or admin reset the IsFinancialForecastVisible

        if (!this.canShowUpdateInvoicingForecastVisibleCommand) {
            this.updateField("isInvoicingForecastVisible", false);
        }
    });

    public updatePasswordCommand: ICommand = new RelayCommand((value: string, keyState: IKeyState) => {
        this.updateField("password", value, keyState);
    });

    public updateConfirmPasswordCommand: ICommand = new RelayCommand((value: string, keyState: IKeyState) => {
        this.updateField("confirmPassword", value, keyState);
    });

    public updateDocumentUrlCommand: ICommand = new RelayCommand((value: string, keyState: IKeyState) => {
        this.updateField("documentUrl", value, keyState);
    });

    public updateIsAssignableCommand: ICommand = new RelayCommand((value: boolean) => {
        this.updateField("isAssignable", !value);
    });

    public updateCanViewInvoicingForecastCommand: ICommand = new RelayCommand(
        (value: boolean) => {
            this.updateField("isInvoicingForecastVisible", value);
        },
        () => this.isUserAdmin || this.isUserSeniorAssociate,
    );

    public updateShowInProjectOverviewDropdownCommand: ICommand = new RelayCommand((value: boolean) => {
        this.updateField("isProjectOverview", value);
    });

    public updateShowInQuoteLeadDropdownCommand: ICommand = new RelayCommand((value: boolean) => {
        this.updateField("isQuoteLead", value);
    });

    public updateCompanyTitleCommand: ICommand = new RelayCommand((value: string) => {
        this.updateField("companyTitle", value);
    });

    public updateAnnualLeaveAllowanceCommand: ICommand = new RelayCommand((value: string) => {
        const parsedValue = parseFloat(value);
        const checkedValue = Number.isNaN(parsedValue) ? null : parsedValue;

        this.updateField("annualLeaveAllowance", checkedValue);
    });

    public updateDailyChargeableHoursCommand: ICommand = new RelayCommand((value: string) => {
        const parsedValue = parseFloat(value);
        const checkedValue = Number.isNaN(parsedValue) ? null : parsedValue;

        this.updateField("dailyChargeableHours", checkedValue);
    });

    public saveUserCommand: ICommand = new RelayCommand(
        () => {
            if (this.isModelValid()) {
                this.apiClient.sendAsync(new POSTSaveUserEndpoint(this), this.model);

                if (this.apiClient.IsRequestSuccessful) {
                    // update the account store to reflect the new value.
                }
            }
        },
        () => {
            return !this.apiClient.IsBusy;
        },
    );

    public cancelSaveUserCommand: ICommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.Users.Table);
    });

    public get canDisplayDeleteUserCommand() {
        return !isEmptyOrWhitespace(this.model.id);
    }

    public deleteUserCommand: ICommand = new RelayCommand(
        () => {
            this.apiClient.sendAsync(new DELETEUserEndpoint(this.model.id, this));
        },
        () => {
            return !this.apiClient.IsBusy && this.canDisplayDeleteUserCommand;
        },
    );

    public navigateToUsersCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.Users.Table);
    });

    public resetServerErrorCommand = new RelayCommand(() => {
        this.apiClient.reset();
    });

    public deleteModalOpenCommand = new RelayCommand(
        () => {
            this.deletePopup = true;
        },
        () => {
            // If the API is busy, we don't want to try and display
            // a modal to carry out a delete operation.
            return !this.apiClient.IsBusy;
        },
    );

    public closeDeleteModalCommand = new RelayCommand(() => {
        this.deletePopup = false;
    });

    public updateSignature = (value: string): void => {
        this.updateField("signature", value);
    };

    // #endregion Commands

    // #region Supporting

    private updateField(fieldName: keyof FieldType<UserModel>, value: any, keyState?: IKeyState) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    // #endregion Supporting
}
