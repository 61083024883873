import { ViewModelBase } from "@shoothill/core";
import { action, computed, makeObservable, observable } from "mobx";
import moment from "moment";

import { APIClient, RelayCommand } from "Application";
import { AppUrls } from "AppUrls";
import { LeaveRequest } from "Views/Leave/Shared/LeaveRequest";
import { LeaveRequestUser } from "Views/Leave/Shared/LeaveRequestUser";

import { CancelLeaveRequestModel } from "Views/Leave/Shared/ModalViews/CancelLeaveRequest/CancelLeaveRequestModel";
import { CancelLeaveRequestViewModel } from "Views/Leave/Shared/ModalViews/CancelLeaveRequest/CancelLeaveRequestViewModel";
import { POSTCancelLeaveForUserEndpoint } from "Views/Leave/Shared/ModalViews/CancelLeaveRequest/Endpoints/POSTCancelLeaveRequestForUserEndpoint";

import { DeleteLeaveRequestModel } from "Views/Leave/Shared/ModalViews/DeleteLeaveRequest/DeleteLeaveRequestModel";
import { DeleteLeaveRequestViewModel } from "Views/Leave/Shared/ModalViews/DeleteLeaveRequest/DeleteLeaveRequestViewModel";
import { POSTDeleteLeaveEndpoint } from "Views/Leave/Shared/ModalViews/DeleteLeaveRequest/Endpoints/POSTDeleteLeaveRequestEndpoint";

import { NewRequestModel } from "Views/Leave/Shared/ModalViews/NewRequest/NewRequestModel";
import { NewRequestViewModel } from "Views/Leave/Shared/ModalViews/NewRequest/NewRequestViewModel";
import { POSTRequestLeaveForUserEndpoint } from "Views/Leave/Shared/ModalViews/NewRequest/Endpoints/POSTRequestLeaveForUser";

import { UserCalendarViewModel } from "./Components/Body/UserCalendar/UserCalendarViewModel";
import { GETAdminCalendarEventsEndpoint } from "./Endpoints/GetAdminCalendarEvents";
import { LeaveCalendarModel } from "./LeaveCalendarModel";

import { ApproveRequestModel } from "../Shared/ModalViews/ApproveRequest/ApproveRequestModel";
import { ApproveRequestViewModel } from "../Shared/ModalViews/ApproveRequest/ApproveRequestViewModel";
import { POSTApproveRequestEndpoint } from "../Shared/ModalViews/ApproveRequest/Endpoints/POSTApproveRequestEndpoint";

import { ChangeAllowanceModel } from "../Shared/ModalViews/ChangeAllowance/ChangeAllowanceModel";
import { ChangeAllowanceViewModel } from "../Shared/ModalViews/ChangeAllowance/ChangeAllowanceViewModel";
import { POSTChangeLeaveAllowanceEndpoint } from "../Shared/ModalViews/ChangeAllowance/Endpoints/POSTChangeLeaveAllowance";

import { DeclineRequestModel } from "../Shared/ModalViews/DeclineRequest/DeclineRequestModel";
import { DeclineRequestViewModel } from "../Shared/ModalViews/DeclineRequest/DeclineRequestViewModel";
import { POSTDeclineRequestEndpoint } from "../Shared/ModalViews/DeclineRequest/Endpoints/POSTDeclineRequestEndpoint";

import { NewRequestModel as NewRequestWithUserSelectModel } from "../Shared/ModalViews/NewRequest/NewRequestModel";
import { NewRequestViewModel as NewRequestWithUserSelectViewModel } from "../Shared/ModalViews/NewRequest/NewRequestViewModel";
import { POSTRequestLeaveForUserEndpoint as POSTRequestLeaveForUserWithuserSelectEndpoint } from "../Shared/ModalViews/NewRequest/Endpoints/POSTRequestLeaveForUser";

import { ResetAllowancesModel } from "../Shared/ModalViews/ResetAllowances/ResetAllowancesModel";
import { ResetAllowancesViewModel } from "../Shared/ModalViews/ResetAllowances/ResetAllowancesViewModel";
import { POSTResetLeaveAllowancesEndpoint } from "../Shared/ModalViews/ResetAllowances/Endpoints/POSTResetLeaveAllowances";

export class LeaveCalendarViewModel extends ViewModelBase<LeaveCalendarModel> {
    public apiClient = new APIClient();

    public relativeMonthIndex: number = 0;
    public userCalendarViewModels = observable<UserCalendarViewModel>([]);

    public newRequestWithUserSelectViewModel: NewRequestWithUserSelectViewModel | null = null;
    public editRequestViewModel: NewRequestViewModel | null = null;
    public resetAllowancesViewModel: ResetAllowancesViewModel | null = null;
    public changeAllowanceViewModel: ChangeAllowanceViewModel | null = null;
    public approveRequestViewModel: ApproveRequestViewModel | null = null;
    public declineRequestViewModel: DeclineRequestViewModel | null = null;
    public cancelLeaveRequestViewModel: CancelLeaveRequestViewModel | null = null;
    public deleteLeaveRequestViewModel: DeleteLeaveRequestViewModel | null = null;

    constructor(model = new LeaveCalendarModel()) {
        super(model, false);

        makeObservable(this, {
            // Observables
            relativeMonthIndex: observable,
            userCalendarViewModels: observable,
            newRequestWithUserSelectViewModel: observable,
            editRequestViewModel: observable,
            resetAllowancesViewModel: observable,
            changeAllowanceViewModel: observable,
            approveRequestViewModel: observable,
            declineRequestViewModel: observable,
            cancelLeaveRequestViewModel: observable,
            deleteLeaveRequestViewModel: observable,

            //Action
            setRelativeMonthIndex: action,

            // Computeds
            startOfMonth: computed,
            endOfMonth: computed,
            canDisplayWithUserSelectNewRequest: computed,
            canDisplayEditRequest: computed,
            canDisplayResetAllowances: computed,
            canDisplayChangeAllowance: computed,
            canDisplayApproveRequest: computed,
            canDisplayDeclineRequest: computed,
            canDisplayCancelRequest: computed,
            canDisplayDeleteRequest: computed,
        });

        const _ = this.apiClient.sendAsync(new GETAdminCalendarEventsEndpoint(this));
    }

    //region actions
    public setRelativeMonthIndex = (relativeMonthIndex: number) => {
        this.relativeMonthIndex = relativeMonthIndex;
    };

    //endregion actions

    // #region Calendar

    public get currentMonthAndYear() {
        return moment().startOf("month").add(this.relativeMonthIndex, "month").format("MMMM YYYY");
    }

    public get startOfMonth() {
        return moment().add(this.relativeMonthIndex, "month").startOf("month");
    }

    public get endOfMonth() {
        return moment().add(this.relativeMonthIndex, "month").endOf("month");
    }

    public previousMonthCommand = new RelayCommand(() => {
        this.setRelativeMonthIndex(this.relativeMonthIndex - 1);
        this.apiClient.sendAsync(new GETAdminCalendarEventsEndpoint(this));
    });

    public nextMonthCommand = new RelayCommand(() => {
        this.setRelativeMonthIndex(this.relativeMonthIndex + 1);
        this.apiClient.sendAsync(new GETAdminCalendarEventsEndpoint(this));
    });

    // #endregion Calendar

    // #region New Request (With user selection) Commands

    public get canDisplayWithUserSelectNewRequest(): boolean {
        return this.newRequestWithUserSelectViewModel !== null;
    }

    public displayNewRequestWithUserSelectCommand = new RelayCommand(() => {
        this.newRequestWithUserSelectViewModel = new NewRequestWithUserSelectViewModel(
            new NewRequestWithUserSelectModel(this.model.users, this.model.leaveTypes, this.model.leaveDayTypes),
            this.submitNewRequestWithUserSelectCommand,
            this.cancelNewRequestWithUserSelectCommand,
        );
    });

    public submitNewRequestWithUserSelectCommand = new RelayCommand(
        async () => {
            await this.apiClient.sendAsync(new POSTRequestLeaveForUserWithuserSelectEndpoint(), this.newRequestWithUserSelectViewModel?.model);

            if (this.apiClient.IsRequestSuccessful) {
                this.newRequestWithUserSelectViewModel = null;
                this.apiClient.reset();
                this.apiClient.sendAsync(new GETAdminCalendarEventsEndpoint(this));
            }
        },
        () => {
            return !this.apiClient.IsBusy;
        },
    );

    public cancelNewRequestWithUserSelectCommand = new RelayCommand(() => {
        this.newRequestWithUserSelectViewModel = null;
    });

    // #endregion New Request (With user selection) Commands

    // #region Edit Request Commands

    public get canDisplayEditRequest(): boolean {
        return this.editRequestViewModel !== null;
    }

    public displayEditRequestCommand = new RelayCommand((leaveRequest: LeaveRequest) => {
        this.editRequestViewModel = new NewRequestViewModel(
            new NewRequestModel(this.model.leaveTypes, this.model.leaveDayTypes, leaveRequest),
            this.submitEditRequestCommand,
            this.cancelEditRequestCommand,
        );
    });

    public submitEditRequestCommand = new RelayCommand(
        async () => {
            await this.apiClient.sendAsync(new POSTRequestLeaveForUserEndpoint(), this.editRequestViewModel?.model);

            if (this.apiClient.IsRequestSuccessful) {
                this.editRequestViewModel = null;
                this.apiClient.reset();
                this.apiClient.sendAsync(new GETAdminCalendarEventsEndpoint(this));
            }
        },
        () => {
            return !this.apiClient.IsBusy;
        },
    );

    public cancelEditRequestCommand = new RelayCommand(() => {
        this.editRequestViewModel = null;
    });

    // #endregion Edit Request Commands

    // #region Reset Allowances Commands

    public get canDisplayResetAllowances(): boolean {
        return this.resetAllowancesViewModel !== null;
    }

    public displayResetAllowancesCommand = new RelayCommand(() => {
        this.resetAllowancesViewModel = new ResetAllowancesViewModel(new ResetAllowancesModel(), this.submitResetAllowancesCommand, this.cancelResetAllowancesCommand);
    });

    public submitResetAllowancesCommand = new RelayCommand(
        async () => {
            await this.apiClient.sendAsync(new POSTResetLeaveAllowancesEndpoint(), this.resetAllowancesViewModel?.model);

            if (this.apiClient.IsRequestSuccessful) {
                this.resetAllowancesViewModel = null;
                this.apiClient.reset();
                this.apiClient.sendAsync(new GETAdminCalendarEventsEndpoint(this));
            }
        },
        () => {
            return !this.apiClient.IsBusy;
        },
    );

    public cancelResetAllowancesCommand = new RelayCommand(() => {
        this.resetAllowancesViewModel = null;
    });

    // #endregion Reset Allowances Commands

    // #region Change Allowance Commands

    public get canDisplayChangeAllowance(): boolean {
        return this.changeAllowanceViewModel !== null;
    }

    public displayChangeAllowanceCommand = new RelayCommand((leaveRequestUser: LeaveRequestUser) => {
        this.changeAllowanceViewModel = new ChangeAllowanceViewModel(
            new ChangeAllowanceModel(leaveRequestUser),
            this.submitChangeAllowanceCommand,
            this.cancelChangeAllowanceCommand,
        );
    });

    public submitChangeAllowanceCommand = new RelayCommand(
        async () => {
            await this.apiClient.sendAsync(new POSTChangeLeaveAllowanceEndpoint(), this.changeAllowanceViewModel?.model);

            if (this.apiClient.IsRequestSuccessful) {
                this.changeAllowanceViewModel = null;
                this.apiClient.reset();
                this.apiClient.sendAsync(new GETAdminCalendarEventsEndpoint(this));
            }
        },
        () => {
            return !this.apiClient.IsBusy;
        },
    );

    public cancelChangeAllowanceCommand = new RelayCommand(() => {
        this.changeAllowanceViewModel = null;
    });

    // #endregion Change Allowance Commands

    // #region Approve Request Commands

    public get canDisplayApproveRequest(): boolean {
        return this.approveRequestViewModel !== null;
    }

    public displayApproveRequestCommand = new RelayCommand((leaveRequest: LeaveRequest) => {
        this.approveRequestViewModel = new ApproveRequestViewModel(new ApproveRequestModel(leaveRequest), this.submitApproveRequestCommand, this.cancelApproveRequestCommand);
    });

    public submitApproveRequestCommand = new RelayCommand(
        async () => {
            await this.apiClient.sendAsync(new POSTApproveRequestEndpoint(), this.approveRequestViewModel?.model);

            if (this.apiClient.IsRequestSuccessful) {
                this.approveRequestViewModel = null;
                this.apiClient.reset();
                this.apiClient.sendAsync(new GETAdminCalendarEventsEndpoint(this));
            }
        },
        () => {
            return !this.apiClient.IsBusy;
        },
    );

    public cancelApproveRequestCommand = new RelayCommand(() => {
        this.approveRequestViewModel = null;
    });

    // #endregion Approve Request Commands

    // #region Decline Request Commands

    public get canDisplayDeclineRequest(): boolean {
        return this.declineRequestViewModel !== null;
    }

    public displayDeclineRequestCommand = new RelayCommand((leaveRequest: LeaveRequest) => {
        this.declineRequestViewModel = new DeclineRequestViewModel(new DeclineRequestModel(leaveRequest), this.submitDeclineRequestCommand, this.cancelDeclineRequestCommand);
    });

    public submitDeclineRequestCommand = new RelayCommand(
        async () => {
            await this.apiClient.sendAsync(new POSTDeclineRequestEndpoint(), this.declineRequestViewModel?.model);

            if (this.apiClient.IsRequestSuccessful) {
                this.declineRequestViewModel = null;
                this.apiClient.reset();
                this.apiClient.sendAsync(new GETAdminCalendarEventsEndpoint(this));
            }
        },
        () => {
            return !this.apiClient.IsBusy;
        },
    );

    public cancelDeclineRequestCommand = new RelayCommand(() => {
        this.declineRequestViewModel = null;
    });

    // #endregion Decline Request Commands

    // #region Cancel Request Commands

    public get canDisplayCancelRequest(): boolean {
        return this.cancelLeaveRequestViewModel !== null;
    }

    public displayCancelRequestCommand = new RelayCommand((leaveRequest: LeaveRequest) => {
        this.cancelLeaveRequestViewModel = new CancelLeaveRequestViewModel(
            new CancelLeaveRequestModel(leaveRequest),
            this.submitCancelRequestCommand,
            this.cancelCancelRequestCommand,
        );
    });

    public submitCancelRequestCommand = new RelayCommand(
        async () => {
            await this.apiClient.sendAsync(new POSTCancelLeaveForUserEndpoint(), this.cancelLeaveRequestViewModel?.model);

            if (this.apiClient.IsRequestSuccessful) {
                this.cancelLeaveRequestViewModel = null;
                this.apiClient.reset();
                this.apiClient.sendAsync(new GETAdminCalendarEventsEndpoint(this));
            }
        },
        () => {
            return !this.apiClient.IsBusy;
        },
    );

    public cancelCancelRequestCommand = new RelayCommand(() => {
        this.cancelLeaveRequestViewModel = null;
    });

    // #endregion Cancel Request Commands

    // #region Delete Rquest Commands

    public get canDisplayDeleteRequest(): boolean {
        return this.deleteLeaveRequestViewModel !== null;
    }

    public displayDeleteRequestCommand = new RelayCommand((leaveRequest: LeaveRequest) => {
        this.deleteLeaveRequestViewModel = new DeleteLeaveRequestViewModel(
            new DeleteLeaveRequestModel(leaveRequest),
            this.submitDeleteRequestCommand,
            this.cancelDeleteRequestCommand,
        );
    });

    public submitDeleteRequestCommand = new RelayCommand(
        async () => {
            await this.apiClient.sendAsync(new POSTDeleteLeaveEndpoint(), this.deleteLeaveRequestViewModel?.model);

            if (this.apiClient.IsRequestSuccessful) {
                this.deleteLeaveRequestViewModel = null;
                this.apiClient.reset();
                this.apiClient.sendAsync(new GETAdminCalendarEventsEndpoint(this));
            }
        },
        () => {
            return !this.apiClient.IsBusy;
        },
    );

    public cancelDeleteRequestCommand = new RelayCommand(() => {
        this.deleteLeaveRequestViewModel = null;
    });

    // #endregion Delete Request Commands

    // #region Commands

    public resetServerErrorCommand = new RelayCommand(() => {
        this.apiClient.reset();
    });

    public navigateToRequestsCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.Leave.LeaveRequests);
    });

    // #endregion Commands
}
