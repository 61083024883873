import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

import { SentQuoteModel } from "./SentQuoteModel";

export class NewProjectModel extends ModelBase<NewProjectModel> {
    public quotes = observable<SentQuoteModel>([]);
    public quoteId: string | null = null;
    public filterKeyword: string = "";

    constructor() {
        super();

        makeObservable(this, {
            // Observables
            quotes: observable,
            quoteId: observable,
            filterKeyword: observable,
        });
    }
}
