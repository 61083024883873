import { observer } from "mobx-react-lite";
import React from "react";

import { Box } from "Application";
import { BillingNotesViewModel } from "./BillingNotesViewModel";
import { BillingNoteView } from "./BillingNoteView";
import { BillingNoteViewModel } from "./BillingNoteViewModel";

interface IAssignViewProps {
    viewModel: BillingNotesViewModel;
}

export const BillingNotesView: React.FC<IAssignViewProps> = observer((props) => {
    // #region Code Behind

    const viewModel = props.viewModel;
    let flipNoteView = true;

    /**
     * Action to flip styling of the note view from ltr to rtl.
     */
    const flipView = (): boolean => {
        flipNoteView = !flipNoteView;

        return flipNoteView;
    };
    const renderAssignmentUserNotes = () => {
        const billingNoteViews: JSX.Element[] = [];
        viewModel.assignmentUserNoteViewModels.forEach((viewModel: BillingNoteViewModel) => {
            billingNoteViews.push(<BillingNoteView viewModel={viewModel} flipView={flipView()} />);
        });
        return billingNoteViews;
    };

    // #endregion Code Behind

    return (
        <Box style={{ display: "flex", flexDirection: "column", flex: 1 }}>
            {renderAssignmentUserNotes()}
            {viewModel.canDisplayCompletedNote && <BillingNoteView viewModel={viewModel.completedUserNoteViewModel!} flipView={flipView()} />}
            {viewModel.canDisplayBilledNote && <BillingNoteView viewModel={viewModel.billedUserNoteViewModel!} flipView={flipView()} />}
        </Box>
    );
});
