import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { nanoid } from "nanoid";
import { RevisionModel } from "Views/Shared/Revisions/RevisionModel";
import { CurrentQuoteModel } from "../Current/CurrentQuoteModel";

export class QuoteListItemModel extends ModelBase<QuoteListItemModel> {
    public KEY: string = nanoid();

    public id: string = "";
    public title: string = "";
    public isMaster: boolean = false;

    public currentQuoteModel = new CurrentQuoteModel();
    public revisions = observable<RevisionModel>([]);

    constructor() {
        super();

        makeObservable(this, {
            // Observables
            id: observable,
            title: observable,
            isMaster: observable,
            revisions: observable,
        });
    }
}
