import { isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { computed, makeObservable, observable, observe } from "mobx";

import { ICommand, RelayCommand } from "Application/Commands";
import { NoteModel } from "./NoteModel";
import { NotesModel } from "./NotesModel";
import { NoteViewModel } from "./NoteViewModel";

export class NotesViewModel extends ViewModelBase<NotesModel> {
    public noteViewModels = observable<NoteViewModel>([]);

    private parentSaveNoteCommand: ICommand;

    private noteContext: string = "";

    constructor(notesModel: NotesModel = new NotesModel(), saveNoteCommand: ICommand, noteContext: string) {
        super(notesModel);

        this.parentSaveNoteCommand = saveNoteCommand;

        this.noteContext = noteContext;

        makeObservable(this, {
            // Observables
            noteViewModels: observable,

            // Computeds
            notes: computed,
        });
    }

    /**
     * Disposes of the notes collection observer.
     */
    public dispose = (): void => {
        this.notesObserverDispose?.();
    };

    // #region Properties

    public get notes() {
        return this.noteViewModels;
    }

    public get getNoteContext() {
        return this.noteContext;
    }

    // #endregion Properties

    // #region Commands

    public addNoteCommand = new RelayCommand(() => {
        const noteModel = new NoteModel();

        noteModel.editMode = true;

        this.model.notes.unshift(noteModel);
    });

    public removeNoteCommand = new RelayCommand((note: NoteModel) => {
        if (isEmptyOrWhitespace(note.id)) {
            const noteModelToRemove = this.model.notes.find((m) => m.KEY === note.KEY);

            if (noteModelToRemove) {
                this.model.notes.remove(noteModelToRemove);
            }
        }
    });

    //#endregion Commands

    /**
     * An observer to listen to changes in the note model collection. Use this to create or remove
     * note viewmodels in response to changes in the note model collection.
     */
    private notesObserverDispose = observe(this.model.notes, (noteChanges: any) => {
        for (const addedNote of noteChanges.added) {
            const noteViewModelToAdd = new NoteViewModel(addedNote, this.removeNoteCommand, this.parentSaveNoteCommand);

            if (noteViewModelToAdd.model.editMode) {
                this.noteViewModels.unshift(noteViewModelToAdd);
            } else {
                this.noteViewModels.push(noteViewModelToAdd);
            }
        }

        for (const removedNote of noteChanges.removed) {
            const noteViewModelToRemove = this.noteViewModels.find((vm) => vm.model.KEY === removedNote.KEY);

            if (noteViewModelToRemove) {
                this.noteViewModels.remove(noteViewModelToRemove);
            }
        }
    });
}
