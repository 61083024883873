import _ from "lodash";

import { User } from "Application/Models/Domain/User";
import { FileModel } from "Views/Shared/Files/FileModel";
import { NoteModel } from "Views/Shared/Note/NoteModel";
import { ProjectDetailsViewModel } from "../../ProjectDetailsViewModel";
import { CustomerContactItemModel } from "../../SubViews/Customer/CustomerContactItemModel";
import { CustomerItemModel } from "../../SubViews/Customer/CustomerItemModel";

export class ProjectDetailsResponse extends Response {
    // #region Response Data

    public id: string = "";
    public projectStatusId: string = "";

    public contactTypeId: string = "";
    public customerId: string = "";
    public customerContactId: string | null = null;

    public sameAsBusinessAddress: boolean = false;
    public siteAddress1: string | null = null;
    public siteAddress2: string | null = null;
    public siteAddress3: string | null = null;
    public siteCity: string | null = null;
    public sitePostcode: string | null = null;

    public projectLeadId: string | null = null;
    public projectSupportId: string | null = null;
    public seniorAssociateId: string | null = null;

    customers: CustomerResponse[] = [];
    customerContacts: CustomerContactResponse[] = [];
    notes: NoteResponse[] = [];
    noteDocuments: NoteDocumentResponse[] = [];
    projectLeads: UserResponse[] = [];
    projectSupports: UserResponse[] = [];
    seniorAssociates: UserResponse[] = [];

    // #endregion Response Data

    // #region Response Part Mappings to ViewModel

    public static customerResponsePart(response: ProjectDetailsResponse, viewModel: ProjectDetailsViewModel) {
        viewModel.customerViewModel.customers.replace(
            response.customers.map((c) => {
                const model = new CustomerItemModel();

                model.fromResponse(c);

                return model;
            }),
        );

        viewModel.customerViewModel.customerContacts.replace(
            response.customerContacts.map((cc) => {
                const model = new CustomerContactItemModel();

                model.fromResponse(cc);

                return model;
            }),
        );

        viewModel.customerViewModel.model.fromResponse(response);
    }

    public static notesResponsePart(response: ProjectDetailsResponse, viewModel: ProjectDetailsViewModel) {
        const groupedNoteDocuments = _.groupBy(response.noteDocuments, (noteDocument) => noteDocument.noteId);

        viewModel.notesViewModel.model.notes.replace(
            response.notes.map((n) => {
                const model = new NoteModel();

                model.fromResponse(n);

                return model;
            }),
        );

        // Process the note documents assign to the appropriate note.
        for (const noteViewModel of viewModel.notesViewModel.noteViewModels) {
            noteViewModel.filesViewModel.model.files.replace(
                Object.entries(groupedNoteDocuments)
                    .find(([noteId, noteDocuments]) => noteId === noteViewModel.model.id)?.[1]
                    .map((nd) => {
                        const model = new FileModel();

                        model.fromResponse(nd);

                        return model;
                    }) ?? [],
            );
        }
    }

    public static remainingResponsePart(response: ProjectDetailsResponse, viewModel: ProjectDetailsViewModel) {
        viewModel.model.projectLeads.replace(
            response.projectLeads.map((item) => {
                const domainModel = new User();

                domainModel.id = item.id;
                domainModel.firstName = item.firstName;
                domainModel.lastName = item.lastName;
                domainModel.thumbnailDocumentUrl = item.thumbnailDocumentUrl;

                return domainModel;
            }),
        );

        viewModel.model.projectSupports.replace(
            response.projectSupports.map((item) => {
                const domainModel = new User();

                domainModel.id = item.id;
                domainModel.firstName = item.firstName;
                domainModel.lastName = item.lastName;
                domainModel.thumbnailDocumentUrl = item.thumbnailDocumentUrl;

                return domainModel;
            }),
        );

        viewModel.model.seniorAssociates.replace(
            response.seniorAssociates.map((item) => {
                const domainModel = new User();

                domainModel.id = item.id;
                domainModel.firstName = item.firstName;
                domainModel.lastName = item.lastName;
                domainModel.thumbnailDocumentUrl = item.thumbnailDocumentUrl;

                return domainModel;
            }),
        );

        viewModel.model.fromResponse(response);
    }

    // #endregion Response Part Mappings to ViewModel
}

class CustomerResponse {
    id: string = "";
    contactTypeId: string = "";

    prefixName: string = "";
    businessName: string | null = null;
    firstName: string | null = null;
    lastName: string | null = null;

    contactNumber1: string = "";
    emailAddress: string = "";
    emailAddress2: string = "";

    address1: string = "";
    address2: string | null = null;
    address3: string | null = null;
    city: string = "";
    postCode: string = "";
}

class CustomerContactResponse {
    id: string = "";
    contactTypeId: string = "";
    customerId: string = "";

    prefixName: string = "";
    firstName: string | null = null;
    lastName: string | null = null;

    contactNumber1: string = "";
    emailAddress: string = "";

    address1: string = "";
    address2: string | null = null;
    address3: string | null = null;
    city: string = "";
    postCode: string = "";
}

class NoteResponse {
    id: string = "";
    noteTypeId: string = "";
    noteDate: Date | null = null;
    noteTime: string = "";
    noteDetail: string = "";
    createdDate: Date | null = null;
    createdByUserId: string = "";
    createdByUserFirstName: string = "";
    createdByUserLastName: string = "";
    lastUpdatedDate: Date | null = null;
    lastUpdatedByUserId: string = "";
    lastUpdatedByUserFirstName: string | null = null;
    lastUpdatedByUserLastName: string | null = null;
}

class NoteDocumentResponse {
    id: string = "";
    noteId: string = "";
    fileName: string = "";
    fileSizeBytes: number = 0;
    mimeType: string = "";
    documentUrl: string = "";
}

class UserResponse {
    id: string = "";
    firstName: string = "";
    lastName: string = "";
    thumbnailDocumentUrl: string = "";
}
