import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

import { User } from "Application/Models/Domain/User";
import { Validator } from "Application/Validation";

export class TaskModel extends ModelBase<TaskModel> {
    public projectTaskBillableEffort: number = 0;

    public users = observable<User>([]);

    constructor() {
        super();
        makeObservable(this, {
            projectTaskBillableEffort: observable,

            users: observable,
        });
    }
}
export class TaskModelValidator extends Validator<TaskModel> {
    constructor() {
        super();
    }
}
