import "../../../index.scss";

import { ICalloutContentStyles, IStyle, ITooltipHostStyles, mergeStyleSets, Stack, TooltipHost } from "@fluentui/react";
import { isEmptyOrWhitespace, isNullOrEmpty } from "@shoothill/core";
import { observer } from "mobx-react-lite";
import { nanoid } from "nanoid";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import { pxToRem, setPageTitle, theme, ThemedButton, ThemedComboBox, ThemedEditText, ThemedText } from "Application/index";
import { ExclamationIconSVG } from "Assets/ExclamationIconSVG";
import { Box } from "Components";
import { AutoGrid } from "Components/Grid";
import { FindAddressView } from "Views/Shared/FindAddress/FindAddressView";
import { ThemedLoader } from "Styles/ThemedPrimitives/Style1/ThemedLoader";
import { ThemedError } from "Styles/ThemedPrimitives/Style1/ThemedError";
import { FormContainer, FormContainerRow, FormSubContainer, NavigationContainer, PageContainer, TitleContainer } from "Views/Shared/SharedComponents";
import { Files } from "Views/Shared/Files/Files";
import { SupplierContactView } from "./SupplierContacts/SupplierContactView";
import { SupplierViewModel } from "./SupplierViewModel";

export interface IContactStyles {
    formRoot: IStyle;
    formRootTitle: IStyle;
    formGroup: IStyle;
    formRootLink: IStyle;
    formRootBlock: IStyle;
    formRootbox: IStyle;
    formButtonGroup: IStyle;
    formfooterBtns: IStyle;
    formRootHeaderLink: IStyle;
    formSimplebox: IStyle;
    formhalfInput: IStyle;
    formDomastic: IStyle;
    dashBorder: IStyle;
    formRootHeading: IStyle;
}

export interface ISupplierViewProps {
    /**
     * An optional class name for use with the header.
     */
    className?: string;
    /**
     * Styling of the view.
     */
    styles?: IContactStyles;
}

export const SupplierView: React.FC<ISupplierViewProps> = observer((props) => {
    // #region Code Behind

    const { id } = useParams();
    const [viewModel] = useState(() => new SupplierViewModel(id));

    useEffect(() => {
        setPageTitle("Supplier");
        return () => viewModel.dispose();
    }, []);

    const styles = mergeStyleSets(props.styles);

    const toolTipHostStyles: Partial<ITooltipHostStyles> = {
        root: {
            "&&": {
                margin: `0 auto auto ${pxToRem(10)}`,
                maxHeight: pxToRem(25),
            },
        },
    };

    const calloutStyles: Partial<ICalloutContentStyles> = {
        beak: { background: theme.palette.common.error },
        beakCurtain: { background: theme.palette.common.error },
        calloutMain: { background: theme.palette.common.error },
    };

    // #endregion Code Behind

    const renderContactsLabel = () => {
        if (!isEmptyOrWhitespace(viewModel.getError("supplierContacts"))) {
            return (
                <Stack horizontal>
                    <ThemedText fontStyle={"h3"}>CONTACTS</ThemedText>
                    <TooltipHost
                        id={nanoid()}
                        content={
                            <ThemedText fontStyle={"h7"} color={"white"}>
                                {viewModel.getError("supplierContacts") as string}
                            </ThemedText>
                        }
                        tooltipProps={{
                            calloutProps: {
                                gapSpace: -5,
                                styles: calloutStyles,
                            },
                        }}
                        styles={toolTipHostStyles}
                    >
                        <ExclamationIconSVG backgroundcolor={theme.palette.common.error} color={theme.palette.common.white} />
                    </TooltipHost>
                </Stack>
            );
        }

        return (
            <Stack horizontal>
                <ThemedText fontStyle={"h3"}>CONTACTS</ThemedText>
            </Stack>
        );
    };

    const renderWorkspace = () => {
        return (
            <FormContainer>
                <FormSubContainer>
                    <FormContainerRow dc={"100%"}>
                        <AutoGrid m={"0"} dc={"1fr 1fr 1fr 1fr"}>
                            <Box style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
                                <ThemedEditText
                                    command={viewModel.updateBusinessNameCommand}
                                    displayName="Business name*"
                                    placeholder="Please add supplier business name"
                                    validationMessage={() => viewModel.getError("businessName")}
                                    value={() => viewModel.getValue("businessName") ?? ""}
                                />
                            </Box>
                            <ThemedComboBox
                                command={viewModel.updateSupplierTypeCommand}
                                displayName="Supplier type*"
                                options={viewModel.supplierTypeOptions}
                                placeholder="Please select"
                                validationMessage={() => viewModel.getError("supplierTypeId")}
                                value={() => viewModel.getValue("supplierTypeId")}
                            />
                            {viewModel.canDisplaySupplierSubTypes && (
                                <ThemedComboBox
                                    command={viewModel.updateSupplierSubTypeCommand}
                                    displayName="Supplier sub-type"
                                    options={viewModel.supplierSubTypeOptions}
                                    placeholder="Please select"
                                    validationMessage={() => viewModel.getError("supplierSubTypeId")}
                                    value={() => viewModel.getValue("supplierSubTypeId")}
                                />
                            )}
                        </AutoGrid>
                    </FormContainerRow>
                    <FormContainerRow dc={"100%"} rowGap={"15px"} mt="32px !important">
                        <ThemedText styles={{ root: { font: "normal normal 600 14px/21px Poppins", letterSpacing: "0.42px", color: "#171716", textTransform: "uppercase" } }}>
                            CONTACT DETAILS
                        </ThemedText>
                        <AutoGrid m={"0"} dc={"1fr 1fr 1fr 1fr"}>
                            <ThemedEditText
                                displayName="Contact number"
                                command={viewModel.updateContactNumberCommand}
                                placeholder="Contact telephone number"
                                value={() => viewModel.getValue("contactNumber1") ?? ""}
                                validationMessage={() => viewModel.getError("contactNumber1")}
                            />
                            <ThemedEditText
                                displayName="Contact number 2 (IF APPLICABLE)"
                                command={viewModel.updateContactNumber2Command}
                                placeholder="Alternative contact telephone number"
                                value={() => viewModel.getValue("contactNumber2") ?? ""}
                                validationMessage={() => viewModel.getError("contactNumber2")}
                            />
                            <ThemedEditText
                                displayName="Primary email"
                                command={viewModel.updateContactPrimaryEmailCommand}
                                placeholder="Contact email address"
                                value={() => viewModel.getValue("emailAddress1") ?? ""}
                                validationMessage={() => viewModel.getError("emailAddress1")}
                            />
                            <ThemedEditText
                                displayName="Secondary email"
                                command={viewModel.updateContactSecondaryEmailCommand}
                                placeholder="Contact email address"
                                value={() => viewModel.getValue("emailAddress2") ?? ""}
                                validationMessage={() => viewModel.getError("emailAddress2")}
                            />
                        </AutoGrid>
                    </FormContainerRow>
                    <FormContainerRow dc={"100%"} rowGap={"15px"} mt="32px !important">
                        <Box
                            display="flex"
                            flexDirection="column"
                            borderTop={`1px dashed${theme.palette.common.secondary}`}
                            borderBottom={`1px dashed${theme.palette.common.secondary}`}
                            padding="20px 0"
                        >
                            <ThemedText
                                mb={"20px"}
                                styles={{ root: { font: "normal normal 600 14px/21px Poppins", letterSpacing: "0.42px", color: "#171716", textTransform: "uppercase" } }}
                            >
                                CORRESPONDENCE ADDRESS
                            </ThemedText>
                            <FormContainerRow>
                                <FindAddressView viewModel={viewModel.findAddressViewModel} />
                            </FormContainerRow>
                            <FormContainerRow alignContent={"flex-start"} dc={"1fr 1fr 1fr 1fr"} rowGap={"20px"}>
                                <ThemedEditText
                                    displayName="Address 1"
                                    command={viewModel.updateAddress1Command}
                                    placeholder="Please enter the first line"
                                    value={() => viewModel.getValue("address1") ?? ""}
                                    validationMessage={() => viewModel.getError("address1")}
                                />
                                <ThemedEditText
                                    displayName="Address 2"
                                    command={viewModel.updateAddress2Command}
                                    placeholder="Please enter the second line"
                                    value={() => viewModel.getValue("address2") ?? ""}
                                    validationMessage={() => viewModel.getError("address2")}
                                />
                                <ThemedEditText
                                    displayName="Address 3"
                                    command={viewModel.updateAddress3Command}
                                    placeholder="Please enter the third line"
                                    value={() => viewModel.getValue("address3") ?? ""}
                                    validationMessage={() => viewModel.getError("address3")}
                                />
                            </FormContainerRow>
                            <FormContainerRow dc={"1fr 1fr 1fr 1fr"}>
                                <ThemedEditText
                                    displayName="Town/city"
                                    command={viewModel.updateCityCommand}
                                    placeholder="Please enter a town or city"
                                    value={() => viewModel.getValue("city") ?? ""}
                                    validationMessage={() => viewModel.getError("city")}
                                />
                                <ThemedEditText
                                    displayName="Postcode"
                                    command={viewModel.updatePostalCodeCommand}
                                    placeholder="Postcode"
                                    value={() => viewModel.getValue("postcode") ?? ""}
                                    validationMessage={() => viewModel.getError("postcode")}
                                    className={styles.formhalfInput}
                                />
                            </FormContainerRow>
                            <Box display={"flex"} mt={3} justifyContent={"space-between"} style={{ borderTop: `1px solid${theme.palette.common.secondary}`, paddingTop: "25px" }}>
                                {renderContactsLabel()}
                                <ThemedButton paletteColor={"primary"} displayName="Add Contact" command={viewModel.createSupplierContactCommand} />
                            </Box>
                        </Box>
                    </FormContainerRow>

                    <AutoGrid dc={"1fr"} mt={0} rowGap={0}>
                        {viewModel.canDisplayNewSupplierContact && <SupplierContactView isNewContact={true} viewModel={viewModel.newSupplierContactViewModel!} />}
                        {viewModel.supplierContactViewModels.map((vm) => {
                            return <SupplierContactView key={vm.model.KEY} viewModel={vm} />;
                        })}
                    </AutoGrid>
                    <FormContainerRow dc={"100%"} mb="15px !important" rowGap={"15px"}>
                        <ThemedText styles={{ root: { font: "normal normal 600 14px/21px Poppins", letterSpacing: "0.42px", color: "#171716", textTransform: "uppercase" } }}>
                            NOTES
                        </ThemedText>
                        <ThemedEditText
                            command={viewModel.updateClientNoteCommand}
                            displayName="Supplier note"
                            isMultiline
                            placeholder="Add your note here"
                            styles={{ fieldGroup: { minHeight: 120, padding: "10px" } }}
                            validationMessage={() => viewModel.getError("clientNote")}
                            value={() => viewModel.getValue("clientNote") ?? ""}
                        />
                    </FormContainerRow>
                    <FormContainerRow dc={"100%"} mb="15px !important">
                        <Files viewModel={viewModel.filesViewModel} />
                    </FormContainerRow>
                </FormSubContainer>
                <AutoGrid
                    backgroundColor="#F3F3F3"
                    columnGap={"30px"}
                    dc={"140px 140px 140px 140px 140px 140px"}
                    m={"0"}
                    padding={"15px 30px"}
                    position="fixed"
                    left="0"
                    right="0"
                    bottom="0"
                    zIndex="10000"
                >
                    <ThemedButton
                        styles={{ root: { padding: "0 5px" } }}
                        paletteColor={"primary"}
                        displayName={isNullOrEmpty(viewModel.getValue("id")) ? "Create Supplier" : "Update Supplier"}
                        command={viewModel.saveSupplierCommand}
                    />
                    <ThemedButton styles={{ root: { padding: "0 5px" } }} displayName="Cancel" command={viewModel.navigateToSupplierCommand} />
                </AutoGrid>
            </FormContainer>
        );
    };

    const renderBusy = () => <ThemedLoader isOpen={viewModel.apiClient.IsBusy} />;

    const renderError = () => {
        const isOpen = viewModel.apiClient.IsSubmitted && !viewModel.apiClient.IsBusy && viewModel.apiClient.HaveValidationMessage;

        return <ThemedError command={viewModel.resetServerErrorCommand} isOpen={isOpen} errorMessage={viewModel.apiClient.ValidationMessage} />;
    };

    return (
        <PageContainer>
            {/* Navigation */}
            <NavigationContainer onClick={() => viewModel.navigateToSupplierCommand.execute()}>
                <ThemedText>{"<"} BACK TO SUPPLIERS</ThemedText>
            </NavigationContainer>
            {/* Title */}
            <TitleContainer style={{ border: "none" }}>
                <ThemedText fontStyle={"h2"}>{viewModel.displayName}</ThemedText>
            </TitleContainer>
            {/* Workspace */}
            {renderWorkspace()}
            {/* Busy Overlay */}
            {renderBusy()}
            {/* Error Overlay */}
            {renderError()}
        </PageContainer>
    );
});

export default SupplierView;
