import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import styled from "@emotion/styled";

import { AutoGrid, Box, ThemedButton, ThemedText, setPageTitle } from "Application";
import { PhoneIcon } from "Assets/Icons/PhoneIcon";
import { AddressIcon } from "Assets/Icons/AddressIcon";
import { EmailIcon } from "Assets/Icons/EmailIcon";
import { EditIcon } from "Assets/Icons/EditIcon";
import { CrossSVG } from "Assets/CrossSVG";
import {
    FormContainer,
    FormContainerRow,
    FormSeparator,
    ItemInformationCell,
    ItemInformationContainer,
    NavigationContainer,
    PageContainer,
    SelectableItemInformationContainer,
    TitleContainer,
} from "Views/Shared/SharedComponents";
import CustomerDetailViewModel from "./CustomerDetailViewModel";
import { ContactCallout, ICalloutState } from "Styles/ThemedPrimitives/Style1/ContactCallout";
import { ThemedLoader } from "Styles/ThemedPrimitives/Style1/ThemedLoader";
import { ThemedError } from "Styles/ThemedPrimitives/Style1/ThemedError";
import { FilesReadOnlyView } from "Views/Shared/Files/FilesReadOnlyView";
import { Modal } from "@fluentui/react";
import { DeleteCustomerView } from "./ModalViews/DeleteCustomerView";

import { isEmptyOrWhitespace, isNullOrEmpty } from "@shoothill/core";
import ReactHtmlParser from "react-html-parser";
export const CustomerDetail: React.FC = observer(() => {
    // #region Code Behind

    const { id } = useParams();
    const [viewModel] = useState(() => new CustomerDetailViewModel(id!));
    const defaultCalloutState: ICalloutState = { isCalloutVisble: false, targetId: "", isEmail: false, data: "" };
    const [callout, setIsCalloutVisible] = useState<ICalloutState>(defaultCalloutState);

    useEffect(() => {
        setPageTitle("Client details");
    }, []);

    const titlePaddingBottom = () => {
        return viewModel.canDisplayCustomerContactNumber1 || viewModel.canDisplayCustomerContactNumber2 || viewModel.canDisplayCustomerAddress ? "15px" : "0";
    };

    const renderDeleteModalContent = () => {
        return (
            <Modal isOpen={viewModel.canDisplayDelete} onDismiss={() => viewModel.closeDeleteModalCommand.execute()}>
                <DeleteCustomerView viewModel={viewModel} />
            </Modal>
        );
    };

    const ButtonGroup = styled.div`
        display: flex;
        align-items: center;
        gap: 30px;
        .redBtn {
            svg {
                margin-right: 8px;
            }
            &:hover {
                color: #fff;
            }
        }
    `;

    const CustomerCrossSVG = styled(CrossSVG)`
        g {
            stroke: #fff;
        }
    `;

    // #endregion Code Behind

    const renderTitle = () => {
        return (
            <TitleContainer>
                <Box display={"flex"} justifyContent={"space-between"} mb={titlePaddingBottom()}>
                    <ThemedText fontStyle={"h2"}>{viewModel.displayName}</ThemedText>
                    <ButtonGroup>
                        <ThemedButton
                            className="editicon"
                            paletteColor={"primary"}
                            displayName="EDIT CLIENT"
                            icon={<EditIcon />}
                            command={viewModel.navigateToEditCustomerCommand}
                        />
                        {viewModel.deleteModalOpenCommand.canExecute() && (
                            <ThemedButton
                                styles={{ root: { color: "#fff" } }}
                                paletteColor={"error"}
                                className="redBtn"
                                displayName="Delete Client"
                                icon={<CustomerCrossSVG />}
                                command={viewModel.deleteModalOpenCommand}
                            />
                        )}
                    </ButtonGroup>
                </Box>
                <Box display="flex" alignItems="center" flexDirection="row">
                    {(viewModel.canDisplayCustomerContactNumber1 || viewModel.canDisplayCustomerContactNumber2) && (
                        <Box display="flex" mr="30px">
                            <Box display="flex" alignItems="center" width="20px" marginBottom="1px" paddingLeft="1px">
                                <PhoneIcon
                                    id="phone"
                                    width="8px"
                                    style={{ cursor: "pointer" }}
                                    onClick={(event: any) => {
                                        setIsCalloutVisible({
                                            isCalloutVisble: true,
                                            isEmail: false,
                                            targetId: `phone`,
                                            data: `${isNullOrEmpty(viewModel.model.customer.contactNumber1) ? " " : viewModel.model.customer.contactNumber1}  ${
                                                isNullOrEmpty(viewModel.model.customer.contactNumber2) ? "" : viewModel.model.customer.contactNumber2
                                            }`,
                                        });
                                    }}
                                />
                            </Box>
                            {viewModel.canDisplayCustomerContactNumber1 && (
                                <Box
                                    style={{ cursor: "pointer" }}
                                    id="customercontactnumber1"
                                    onClick={(event: any) => {
                                        setIsCalloutVisible({
                                            isCalloutVisble: true,
                                            isEmail: false,
                                            targetId: "customercontactnumber1",
                                            data: viewModel.model.customer.contactNumber1,
                                        });
                                    }}
                                >
                                    <ThemedText fontStyle={"h4"}>{viewModel.model.customer.contactNumber1}</ThemedText>
                                </Box>
                            )}
                            {viewModel.canDisplayCustomerContactNumber1 && viewModel.canDisplayCustomerContactNumber2 && (
                                <Box m="0 5px">
                                    <ThemedText color="secondary" fontStyle={"h4"}>
                                        |
                                    </ThemedText>
                                </Box>
                            )}
                            {viewModel.canDisplayCustomerContactNumber2 && (
                                <Box
                                    style={{ cursor: "pointer" }}
                                    id="customercontactnumber2"
                                    onClick={(event: any) => {
                                        setIsCalloutVisible({
                                            isCalloutVisble: true,
                                            isEmail: false,
                                            targetId: "customercontactnumber2",
                                            data: viewModel.model.customer.contactNumber2,
                                        });
                                    }}
                                >
                                    <ThemedText fontStyle={"h4"}>{viewModel.model.customer.contactNumber2}</ThemedText>
                                </Box>
                            )}
                        </Box>
                    )}
                    {(viewModel.canDisplayCustomerEmailAddress || viewModel.canDisplayCustomerEmailAddress2) && (
                        <Box display="flex">
                            <Box display="flex" alignItems="center" width="20px" marginBottom="1px">
                                <EmailIcon
                                    width="15px"
                                    style={{ cursor: "pointer" }}
                                    id="email"
                                    onClick={(event: any) => {
                                        setIsCalloutVisible({
                                            isCalloutVisble: true,
                                            isEmail: true,
                                            targetId: `email`,
                                            data: `${!isEmptyOrWhitespace(viewModel.model.customer.emailAddress) ? viewModel.model.customer.emailAddress : ""} ${
                                                !isEmptyOrWhitespace(viewModel.model.customer.emailAddress2) ? viewModel.model.customer.emailAddress2 : ""
                                            }`,
                                        });
                                    }}
                                />
                            </Box>
                            {viewModel.canDisplayCustomerEmailAddress && (
                                <Box
                                    style={{ cursor: "pointer" }}
                                    id="customeremailaddress"
                                    onClick={(event: any) => {
                                        setIsCalloutVisible({
                                            isCalloutVisble: true,
                                            isEmail: true,
                                            targetId: "customeremailaddress",
                                            data: viewModel.model.customer.emailAddress,
                                        });
                                    }}
                                >
                                    <ThemedText fontStyle={"h4"}>{viewModel.model.customer.emailAddress}</ThemedText>
                                </Box>
                            )}
                            {viewModel.canDisplayCustomerEmailAddress && viewModel.canDisplayCustomerEmailAddress2 && (
                                <Box m="0 5px">
                                    <ThemedText color="secondary" fontStyle={"h4"}>
                                        |
                                    </ThemedText>
                                </Box>
                            )}

                            {viewModel.canDisplayCustomerEmailAddress2 && (
                                <Box
                                    style={{ cursor: "pointer" }}
                                    id="customeremailaddress2"
                                    onClick={(event: any) => {
                                        setIsCalloutVisible({
                                            isCalloutVisble: true,
                                            isEmail: true,
                                            targetId: "customeremailaddress2",
                                            data: viewModel.model.customer.emailAddress2,
                                        });
                                    }}
                                >
                                    <ThemedText fontStyle={"h4"}>{viewModel.model.customer.emailAddress2}</ThemedText>
                                </Box>
                            )}
                        </Box>
                    )}
                </Box>
                {viewModel.canDisplayCustomerAddress && (
                    <Box display="flex">
                        <Box display="flex" alignItems="center" width="20px" marginBottom="2px">
                            <AddressIcon width="10px" />
                        </Box>
                        <ThemedText fontStyle={"h4"}>{viewModel.customerAddress}</ThemedText>
                    </Box>
                )}
            </TitleContainer>
        );
    };

    const renderClientNotes = () => {
        if (viewModel.canDisplayClientNotes) {
            return (
                <React.Fragment>
                    <FormContainerRow dc={"100%"} mt="5px !important" mb="15px !important">
                        <ThemedText fontStyle="h7">{viewModel.clientNote}</ThemedText>
                    </FormContainerRow>
                    <FilesReadOnlyView viewModel={viewModel.filesViewModel} />
                </React.Fragment>
            );
        }

        // This is to ensure any following components are correctly positioned.
        return <Box marginBottom="-15px" />;
    };

    const renderCustomerContacts = () => {
        if (viewModel.canDisplayCustomerContacts) {
            return (
                <React.Fragment>
                    <ThemedText fontStyle={"h3"} marginBottom={"10px"} marginTop={"20px"}>
                        CONTACTS
                    </ThemedText>
                    <AutoGrid dc={"1fr"} mt={0} rowGap={0}>
                        {viewModel.model.customerContacts.map((customerContact, index) => {
                            return (
                                <ItemInformationContainer key={customerContact.KEY} className="clientDetailsList">
                                    <ItemInformationCell flex="20" flexDirection={"column"} style={{ alignItems: "flex-start" }}>
                                        {viewModel.getCustomerContactName(customerContact)}
                                        <ThemedText fontStyle="h7">{viewModel.getBusinessContactTypeName(customerContact)}</ThemedText>
                                    </ItemInformationCell>
                                    {viewModel.canDisplayCustomerContactContactNumber1(customerContact) || viewModel.canDisplayCustomerContactContactNumber2(customerContact) ? (
                                        <ItemInformationCell flex="10" padding="2.5px 15px !important">
                                            <Box display="flex" alignItems="center" width="20px" marginBottom="1px" paddingLeft="1px">
                                                <PhoneIcon width="8px" />
                                            </Box>
                                            <Box flexDirection="column">
                                                <Box
                                                    id={`customercontactnumber1-${index}`}
                                                    onClick={(event: any) => {
                                                        setIsCalloutVisible({
                                                            isCalloutVisble: true,
                                                            isEmail: false,
                                                            targetId: `customercontactnumber1-${index}`,
                                                            data: viewModel.getCustomerContactNumber1(customerContact),
                                                        });
                                                    }}
                                                >
                                                    {viewModel.getCustomerContactNumber1(customerContact)}
                                                </Box>
                                                <Box
                                                    id={`customercontactnumber2-${index}`}
                                                    onClick={(event: any) => {
                                                        setIsCalloutVisible({
                                                            isCalloutVisble: true,
                                                            isEmail: false,
                                                            targetId: `customercontactnumber2-${index}`,
                                                            data: viewModel.getCustomerContactNumber2(customerContact),
                                                        });
                                                    }}
                                                >
                                                    {viewModel.getCustomerContactNumber2(customerContact)}
                                                </Box>
                                            </Box>
                                        </ItemInformationCell>
                                    ) : (
                                        <Box></Box>
                                    )}
                                    {viewModel.canDisplayCustomerContactEmailAddress(customerContact) ? (
                                        <ItemInformationCell flex="30">
                                            <Box display="flex" alignItems="center" width="20px" marginBottom="1px" paddingLeft="1px">
                                                <EmailIcon width="15px" />
                                            </Box>
                                            <Box
                                                id={`customeremailaddress-${index}`}
                                                onClick={(event: any) => {
                                                    setIsCalloutVisible({
                                                        isCalloutVisble: true,
                                                        isEmail: true,
                                                        targetId: `customeremailaddress-${index}`,
                                                        data: viewModel.getCustomerContactEmailAddress(customerContact),
                                                    });
                                                }}
                                            >
                                                {viewModel.getCustomerContactEmailAddress(customerContact)}
                                            </Box>
                                        </ItemInformationCell>
                                    ) : (
                                        <Box></Box>
                                    )}
                                    {viewModel.canDisplayCustomerContactAddress(customerContact) ? (
                                        <ItemInformationCell flex="40">
                                            <Box display="flex" alignItems="center" width="20px" marginBottom="2px">
                                                <AddressIcon width="10px" />
                                            </Box>
                                            <Box>{viewModel.getCustomerContactAddress(customerContact)}</Box>
                                        </ItemInformationCell>
                                    ) : (
                                        <Box></Box>
                                    )}
                                </ItemInformationContainer>
                            );
                        })}
                    </AutoGrid>
                </React.Fragment>
            );
        }

        return <React.Fragment />;
    };

    const renderEnquiries = () => {
        return (
            <React.Fragment>
                <ThemedText fontStyle={"h3"} marginBottom={"10px"} marginTop={"20px"}>
                    ENQUIRIES
                </ThemedText>
                {viewModel.canDisplayEnquiries && (
                    <AutoGrid dc={"1fr"} mt={0} rowGap={0}>
                        {viewModel.model.enquiries.map((enquiry, index) => {
                            return (
                                <SelectableItemInformationContainer key={enquiry.KEY} onClick={() => viewModel.navigateToEnquiryCommand.execute(enquiry.id)}>
                                    <ItemInformationCell flex="10" color={viewModel.getEnquiryStatusColor(enquiry.enquiryStatusId)} style={{ textTransform: "uppercase" }}>
                                        {viewModel.getEnquiryStatusName(enquiry.enquiryStatusId)}
                                    </ItemInformationCell>
                                    <ItemInformationCell flex="6">{enquiry.reference}</ItemInformationCell>
                                    <ItemInformationCell flex="44" mr="60px">
                                        {enquiry.title}
                                    </ItemInformationCell>
                                    <ItemInformationCell flex="20">
                                        <b>CONTACT:&nbsp;</b>
                                        {viewModel.getEnquiryContactName(enquiry)}
                                    </ItemInformationCell>
                                    <ItemInformationCell flex="20" backgroundColor="#F3F3F3">
                                        <b>LAST ACTION:&nbsp;</b>
                                        {viewModel.getEnquiryLastActionUser(enquiry)}
                                        <Box flex="1" textAlign="right">
                                            {viewModel.getEnquiryLastActionDate(enquiry)}
                                        </Box>
                                    </ItemInformationCell>
                                </SelectableItemInformationContainer>
                            );
                        })}
                    </AutoGrid>
                )}
                {viewModel.showNewEnquiriesButton && <ThemedButton paletteColor={"primary"} displayName="NEW ENQUIRY" command={viewModel.navigateToNewEnquiryCommand} />}
            </React.Fragment>
        );
    };

    const renderQuotes = () => {
        return (
            <React.Fragment>
                <ThemedText fontStyle={"h3"} marginBottom={"10px"} marginTop={"20px"}>
                    QUOTES
                </ThemedText>
                {viewModel.canDisplayQuotes && (
                    <AutoGrid dc={"1fr"} mt={0} rowGap={0}>
                        {viewModel.model.quotes.map((quote, index) => {
                            return (
                                <SelectableItemInformationContainer key={quote.KEY} onClick={() => viewModel.navigateToQuoteCommand.execute(quote.id)}>
                                    <ItemInformationCell flex="10" color={viewModel.getQuoteStatusColor(quote.quoteStatusId)} style={{ textTransform: "uppercase" }}>
                                        {viewModel.getQuoteStatusName(quote.quoteStatusId)}
                                    </ItemInformationCell>
                                    <ItemInformationCell flex="6">{quote.reference}</ItemInformationCell>
                                    <ItemInformationCell flex="28">{quote.title}</ItemInformationCell>
                                    <ItemInformationCell flex="8">
                                        <b>HOURS:&nbsp;</b>
                                        {viewModel.getQuoteHours(quote)}
                                    </ItemInformationCell>
                                    <ItemInformationCell flex="8">
                                        <b>VALUE:&nbsp;</b>
                                        {viewModel.getQuoteValue(quote)}
                                    </ItemInformationCell>
                                    <ItemInformationCell flex="20">
                                        <b>CONTACT:&nbsp;</b>
                                        {viewModel.getQuoteContactName(quote)}
                                    </ItemInformationCell>
                                    <ItemInformationCell flex="20" backgroundColor="#F3F3F3">
                                        <b>LAST ACTION:&nbsp;</b>
                                        {viewModel.getQuoteLastActionUser(quote)}
                                        <Box flex="1" textAlign="right">
                                            {viewModel.getQuoteLastActionDate(quote)}
                                        </Box>
                                    </ItemInformationCell>
                                </SelectableItemInformationContainer>
                            );
                        })}
                    </AutoGrid>
                )}
                {viewModel.showNewQuoteButton && (
                    <React.Fragment>
                        <ThemedButton paletteColor={"primary"} mr="15px" displayName="NEW QUOTE" command={viewModel.navigateToNewQuoteCommand} value={false} />
                        <ThemedButton paletteColor={"primary"} displayName="NEW QUICK QUOTE" command={viewModel.navigateToNewQuoteCommand} value={true} />
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    };

    const renderProjects = () => {
        if (viewModel.canDisplayProjects) {
            return (
                <React.Fragment>
                    <ThemedText fontStyle={"h3"} marginBottom={"10px"} marginTop={"20px"}>
                        PROJECTS
                    </ThemedText>
                    <AutoGrid dc={"1fr"} mt={0} rowGap={0}>
                        {viewModel.model.projects.map((project, index) => {
                            return (
                                <SelectableItemInformationContainer key={project.KEY} onClick={() => viewModel.navigateToProjectCommand.execute(project.id)}>
                                    <ItemInformationCell flex="10" color={viewModel.getProjectStatusColor(project.projectStatusId)} style={{ textTransform: "uppercase" }}>
                                        {viewModel.getProjectStatusName(project.projectStatusId)}
                                    </ItemInformationCell>
                                    <ItemInformationCell flex="6">{project.reference}</ItemInformationCell>
                                    <ItemInformationCell flex="28">{project.title}</ItemInformationCell>
                                    <ItemInformationCell flex="8">
                                        <b>HOURS:&nbsp;</b>
                                        {viewModel.getProjectHours(project)}
                                    </ItemInformationCell>
                                    <ItemInformationCell flex="8">
                                        <b>VALUE:&nbsp;</b>
                                        {viewModel.getProjectValue(project)}
                                    </ItemInformationCell>
                                    <ItemInformationCell flex="20">
                                        <b>CONTACT:&nbsp;</b>
                                        {viewModel.getProjectContactName(project)}
                                    </ItemInformationCell>
                                    <ItemInformationCell flex="20" backgroundColor="#F3F3F3">
                                        <b>LAST ACTION:&nbsp;</b>
                                        {viewModel.getProjectLastActionUser(project)}
                                        <Box flex="1" textAlign="right">
                                            {viewModel.getProjectLastActionDate(project)}
                                        </Box>
                                    </ItemInformationCell>
                                </SelectableItemInformationContainer>
                            );
                        })}
                    </AutoGrid>
                </React.Fragment>
            );
        }

        return <React.Fragment />;
    };

    const renderLost = () => {
        if (viewModel.canDisplayLost) {
            return (
                <React.Fragment>
                    <ThemedText fontStyle={"h3"} marginBottom={"10px"} marginTop={"20px"}>
                        LOST
                    </ThemedText>
                    <AutoGrid dc={"1fr"} mt={0} rowGap={0}>
                        {viewModel.model.lostEnquiries.map((enquiry, index) => {
                            return (
                                <SelectableItemInformationContainer key={enquiry.KEY} onClick={() => viewModel.navigateToEnquiryCommand.execute(enquiry.id)}>
                                    <ItemInformationCell flex="10" color="#DC3545" style={{ textTransform: "uppercase" }}>
                                        LOST ENQUIRY
                                    </ItemInformationCell>
                                    <ItemInformationCell flex="6">{enquiry.reference}</ItemInformationCell>
                                    <ItemInformationCell flex="44" mr="60px">
                                        {enquiry.title}
                                    </ItemInformationCell>
                                    <ItemInformationCell flex="20">
                                        <b>CONTACT:&nbsp;</b>
                                        {viewModel.getEnquiryContactName(enquiry)}
                                    </ItemInformationCell>
                                    <ItemInformationCell flex="20" backgroundColor="#F3F3F3">
                                        <b>LAST ACTION:&nbsp;</b>
                                        {viewModel.getEnquiryLastActionUser(enquiry)}
                                        <Box flex="1" textAlign="right">
                                            {viewModel.getEnquiryLastActionDate(enquiry)}
                                        </Box>
                                    </ItemInformationCell>
                                </SelectableItemInformationContainer>
                            );
                        })}
                        {viewModel.model.lostQuotes.map((quote, index) => {
                            return (
                                <SelectableItemInformationContainer key={quote.KEY} onClick={() => viewModel.navigateToQuoteCommand.execute(quote.id)}>
                                    <ItemInformationCell flex="10" color="#DC3545" style={{ textTransform: "uppercase" }}>
                                        LOST QUOTE
                                    </ItemInformationCell>
                                    <ItemInformationCell flex="6">{quote.reference}</ItemInformationCell>
                                    <ItemInformationCell flex="28">{quote.title}</ItemInformationCell>
                                    <ItemInformationCell flex="8">
                                        <b>HOURS:&nbsp;</b>
                                        {viewModel.getQuoteHours(quote)}
                                    </ItemInformationCell>
                                    <ItemInformationCell flex="8">
                                        <b>VALUE:&nbsp;</b>
                                        {viewModel.getQuoteValue(quote)}
                                    </ItemInformationCell>
                                    <ItemInformationCell flex="20">
                                        <b>CONTACT:&nbsp;</b>
                                        {viewModel.getQuoteContactName(quote)}
                                    </ItemInformationCell>
                                    <ItemInformationCell flex="20" backgroundColor="#F3F3F3">
                                        <b>LAST ACTION:&nbsp;</b>
                                        {viewModel.getQuoteLastActionUser(quote)}
                                        <Box flex="1" textAlign="right">
                                            {viewModel.getQuoteLastActionDate(quote)}
                                        </Box>
                                    </ItemInformationCell>
                                </SelectableItemInformationContainer>
                            );
                        })}
                    </AutoGrid>
                </React.Fragment>
            );
        }

        return <React.Fragment />;
    };

    const renderCompleted = () => {
        if (viewModel.canDisplayCompleted) {
            return (
                <React.Fragment>
                    <ThemedText fontStyle={"h3"} marginBottom={"10px"} marginTop={"20px"}>
                        COMPLETED
                    </ThemedText>
                    <AutoGrid dc={"1fr"} mt={0} rowGap={0}>
                        {viewModel.model.completedProjects.map((project, index) => {
                            return (
                                <SelectableItemInformationContainer key={project.KEY} onClick={() => viewModel.navigateToProjectCommand.execute(project.id)}>
                                    <ItemInformationCell flex="10" color="#4ABB0C" style={{ textTransform: "uppercase" }}>
                                        COMPLETED
                                    </ItemInformationCell>
                                    <ItemInformationCell flex="6">{project.reference}</ItemInformationCell>
                                    <ItemInformationCell flex="28">{project.title}</ItemInformationCell>
                                    <ItemInformationCell flex="8">
                                        <b>HOURS:&nbsp;</b>
                                        {viewModel.getProjectHours(project)}
                                    </ItemInformationCell>
                                    <ItemInformationCell flex="8">
                                        <b>VALUE:&nbsp;</b>
                                        {viewModel.getProjectValue(project)}
                                    </ItemInformationCell>
                                    <ItemInformationCell flex="20">
                                        <b>CONTACT:&nbsp;</b>
                                        {viewModel.getProjectContactName(project)}
                                    </ItemInformationCell>
                                    <ItemInformationCell flex="20" backgroundColor="#F3F3F3">
                                        <b>LAST ACTION:&nbsp;</b>
                                        {viewModel.getProjectLastActionUser(project)}
                                        <Box flex="1" textAlign="right">
                                            {viewModel.getProjectLastActionDate(project)}
                                        </Box>
                                    </ItemInformationCell>
                                </SelectableItemInformationContainer>
                            );
                        })}
                    </AutoGrid>
                </React.Fragment>
            );
        }

        return <React.Fragment />;
    };

    const renderWorkspace = () => {
        return (
            <FormContainer>
                {renderClientNotes()}
                {renderCustomerContacts()}
                {renderEnquiries()}
                {renderQuotes()}
                {renderLost()}
                {renderProjects()}
                <FormSeparator />
                {renderCompleted()}
                {/* Delete Modal */}
                {renderDeleteModalContent()}
            </FormContainer>
        );
    };

    const renderBusy = () => <ThemedLoader isOpen={viewModel.apiClient.IsBusy} />;

    const renderError = () => {
        const isOpen = viewModel.apiClient.IsSubmitted && !viewModel.apiClient.IsBusy && viewModel.apiClient.HaveValidationMessage;

        return <ThemedError command={viewModel.resetServerErrorCommand} isOpen={isOpen} errorMessage={viewModel.apiClient.ValidationMessage} />;
    };

    return (
        <PageContainer>
            {/* Navigation */}
            <NavigationContainer onClick={() => viewModel.navigateToCustomersCommand.execute()}>
                <ThemedText>{"<"} BACK TO CLIENTS</ThemedText>
            </NavigationContainer>

            {/* Title */}
            {renderTitle()}

            {/* Workspace */}
            {renderWorkspace()}

            {/* Busy Overlay */}
            {renderBusy()}

            {/* Error Overlay */}
            {renderError()}

            {/* Callout */}
            {callout.isCalloutVisble && (
                <ContactCallout data={callout.data} onDismiss={() => setIsCalloutVisible(defaultCalloutState)} isEmail={callout.isEmail} targetId={callout.targetId} />
            )}
        </PageContainer>
    );
});
