import styled from "@emotion/styled";
import { theme } from "Application";

const Container = styled.div`
    .loadingContainer {
        position: relative;
        padding-top: 30px;
    }
    .loadingContainer div {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background: black;
        float: left;
        margin: 0 3px;
        background: ${theme.palette.primary.main};
    }
    .loadingContainer .ball1 {
        z-index: 1;
        -moz-animation: bounce 3s infinite ease-in-out;
        -webkit-animation: bounce 3s infinite ease-in-out;
    }
    .loadingContainer .ball2 {
        -moz-animation: bounce 3s infinite ease-in-out;
        -webkit-animation: bounce 3s infinite ease-in-out;
        -webkit-animation-delay: 0.5s;
        animation-delay: 0.5s;
    }
    .loadingContainer .ball3 {
        -moz-animation: bounce 3s infinite ease-in-out;
        -webkit-animation: bounce 3s infinite ease-in-out;
        -webkit-animation-delay: 1s;
        animation-delay: 1s;
    }
    .loadingContainer .ball4 {
        -moz-animation: bounce 3s infinite ease-in-out;
        -webkit-animation: bounce 3s infinite ease-in-out;
        -webkit-animation-delay: 1.5s;
        animation-delay: 1.5s;
    }

    @-moz-keyframes bounce {
        0%,
        15% {
            -moz-transform: translate(0, 0);
        }
        50% {
            -moz-transform: translate(0, -30px);
            background: ${theme.palette.tertiary.main};
        }
        85%,
        100% {
            -moz-transform: translate(0, 0);
        }
    }
    @-webkit-keyframes bounce {
        0%,
        20% {
            -webkit-transform: translate(0, 0);
        }
        50% {
            -webkit-transform: translate(0, -30px);
            background: ${theme.palette.secondary.main};
        }
        80%,
        100% {
            -webkit-transform: translate(0, 0);
        }
    }
`;

export const LoadingSpinner = () => {
    return (
        <Container>
            <div className="loadingContainer">
                <div className="ball1"></div>
                <div className="ball2"></div>
                <div className="ball3"></div>
                <div className="ball4"></div>
            </div>
        </Container>
    );
};
