import { isNullOrUndefined } from "@shoothill/core";
import { runInAction } from "mobx";

import { endOfDay, startOfDay } from "Application";
import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { ProjectOverviewViewModel } from "../ProjectOverviewViewModel";
import { ProjectTaskGroupChartModel } from "../SubViews/ProjectTaskGroups/ProjectTaskGroupChartModel";
import { ProjectTaskGroupChartViewModel } from "../SubViews/ProjectTaskGroups/ProjectTaskGroupChartViewModel";

class Response {
    projectTaskGroups: ProjectTaskGroupResponse[] = [];
}

class ProjectTaskGroupResponse {
    public id: string = "";
    public originQuoteId: string | null = null;
    public name: string = "";
    public startDate: string | null = null;
    public endDate: string | null = null;
}

export class GETProjectTaskGroupOverviewByIdEndpoint extends EndpointWithoutRequest<Response> {
    private readonly viewModel: ProjectOverviewViewModel;

    constructor(projectId: string, viewModel: ProjectOverviewViewModel) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.Projects.GetProjectTaskGroupOverviewByProjectId.replace(":id", projectId));
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            const projectTaskGroupViewModels: ProjectTaskGroupChartViewModel[] = [];

            response.projectTaskGroups.forEach((projectTaskGroupResponse) => {
                const model = new ProjectTaskGroupChartModel();

                model.fromResponse(projectTaskGroupResponse);

                if (isNullOrUndefined(model.startDate)) {
                    model.startDate = startOfDay(new Date())!;
                }

                if (isNullOrUndefined(model.endDate)) {
                    model.endDate = endOfDay(new Date())!;
                }

                projectTaskGroupViewModels.push(new ProjectTaskGroupChartViewModel(model));
            });

            this.viewModel.projectTaskGroupChartViewModels.replace(projectTaskGroupViewModels);
        });
    }
}
