import { ModelBase } from "@shoothill/core";
import moment from "moment";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

export class ProgrammingModel extends ModelBase<ProgrammingModel> {
    //includes any project that have the status type set as OnHold, Instructed or InProgress
    public numberOfLiveProjects: number = 0;
    //any project that has the status type set as OnHold
    public numberOfProjectsOnHold: number = 0;
    public targetHours: number = 0;
    public totalBillableEffort: number = 0;
    public totalBilledEffort: number = 0;

    constructor() {
        super();
        makeObservable(this, {
            numberOfLiveProjects: observable,
            numberOfProjectsOnHold: observable,
            targetHours: observable,
            totalBillableEffort: observable,
            totalBilledEffort: observable,
        });
    }
}

export class ProgrammingValidator extends Validator<ProgrammingModel> {
    constructor() {
        super();
    }
}
