import { IStyle, mergeStyleSets } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React from "react";
import { formatCurrency, theme, ThemedText } from "Application";
import { TaskViewModel } from "./TaskViewModel";

interface ITaskReadOnlyViewStyles {
    root: IStyle;
    displayName: IStyle;
    manHours: IStyle;
    hourlyRate: IStyle;
    cost: IStyle;
}

interface ITaskReadOnlyViewProps {
    viewModel: TaskViewModel;
}

const styleDefinitions: ITaskReadOnlyViewStyles = {
    root: {
        display: "flex",
        minHeight: "50px",
    },
    displayName: {
        display: "flex",
        flex: 1,
        "> div": {
            alignItems: "center",
            backgroundColor: "#FCFCFC",
            borderLeft: "15px solid #ff4b22",
            display: "flex",
            flex: "1",
            marginBottom: "15px",
            padding: "4px 15px",
        },
    },
    manHours: {
        borderLeft: "1px solid #E1E1E1",
        display: "flex",
        minWidth: "110px",
        width: "110px",
        "> div": {
            alignItems: "center",
            backgroundColor: "#FCFCFC",
            display: "flex",
            flex: "1",
            justifyContent: "center",
            marginBottom: "15px",
            padding: "4px 15px",
        },
    },
    hourlyRate: {
        borderLeft: "1px solid #E1E1E1",
        display: "flex",
        minWidth: "110px",
        width: "110px",
        "> div": {
            alignItems: "center",
            backgroundColor: "#FCFCFC",
            display: "flex",
            flex: "1",
            justifyContent: "end",
            marginBottom: "15px",
            padding: "4px 30px 4px 15px",
        },
    },
    cost: {
        borderLeft: "1px solid #E1E1E1",
        display: "flex",
        minWidth: "175px",
        "> div": {
            alignItems: "center",
            backgroundColor: "#FCFCFC",
            display: "flex",
            flex: "1",
            justifyContent: "end",
            marginBottom: "15px",
            padding: "0 63px 0 15px",
        },
    },
};

export const TaskReadOnlyView: React.FC<ITaskReadOnlyViewProps> = observer((props) => {
    const viewModel = props.viewModel;
    const styles = mergeStyleSets(styleDefinitions);

    if (viewModel) {
        return (
            <div className={styles.root}>
                <div className={styles.displayName}>
                    <div>
                        <ThemedText fontStyle="h5">{viewModel.displayName}</ThemedText>
                    </div>
                </div>
                <div className={styles.manHours}>
                    <div>
                        <ThemedText fontStyle="h5">{viewModel.model.manHours}</ThemedText>
                    </div>
                </div>
                <div className={styles.hourlyRate}>
                    <div>
                        <ThemedText fontStyle="h5">{viewModel.formattedHourlyRate}</ThemedText>
                    </div>
                </div>
                <div className={styles.cost}>
                    <div>
                        <ThemedText>{viewModel.formattedPredictedCostExclVAT}</ThemedText>
                    </div>
                </div>
            </div>
        );
    }

    return <React.Fragment />;
});
