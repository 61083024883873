import { isEmptyOrWhitespace, isNullOrUndefined, ViewModelBase } from "@shoothill/core";
import { RelayCommand } from "Application";
import { AppUrls } from "AppUrls";
import { makeObservable } from "mobx";
import moment from "moment";

import { ProjectItemModel } from "./ProjectItemModel";

export class ProjectItemViewModel extends ViewModelBase<ProjectItemModel> {
    constructor(project: ProjectItemModel = new ProjectItemModel()) {
        super(project);
        makeObservable(this, {});
    }

    public get KEY() {
        return this.model.KEY;
    }

    public get anticipatedDate() {
        return this.model.anticipatedEndDate;
    }

    public get complete() {
        return this.model.complete ?? 0;
    }

    public get noOfTasks() {
        return this.model.noOfTasks ?? 0;
    }

    public get projectLeadName() {
        return !isEmptyOrWhitespace(this.model.projectLeadFirstName) && !isEmptyOrWhitespace(this.model.projectLeadLastName)
            ? `${this.model.projectLeadFirstName} ${this.model.projectLeadLastName}`
            : "";
    }

    public get seniorAssociateName() {
        return !isEmptyOrWhitespace(this.model.seniorAssociateFirstName) && !isEmptyOrWhitespace(this.model.seniorAssociateLastName)
            ? `${this.model.seniorAssociateFirstName} ${this.model.seniorAssociateLastName}`
            : "";
    }

    public get projectStatusName() {
        return this.model.projectStatusName;
    }

    public get projectStatusForegroundColor() {
        return this.model.projectStatusForegroundColor;
    }

    public get reference() {
        return this.model.reference ?? "";
    }

    public get title() {
        return this.model.title ?? "";
    }

    public get value() {
        return this.model.value ?? 0;
    }

    public get noOfHours() {
        return this.model.noOfHours ?? 0;
    }

    public get contactFullName() {
        if (this.model.customerItemModel) {
            return this.model.customerItemModel.fullName;
        }
        return "";
    }

    public get contactFullAddress() {
        if (this.model.customerItemModel) {
            return this.model.customerItemModel.fullAddress;
        }
        return "";
    }

    public navigateToCustomerDetailCommand = new RelayCommand((id: string) => {
        this.history.push(AppUrls.Client.Customers.Details.replace(":id", id));
    });

    /**
     * Determines if the project can be displayed, given a set of filters.
     *
     * @param filterKeyword A general filter to cover a number of properties of the project.
     * @param filterFromDate A date from filter.
     * @param filterToDate  A date to filter.
     * @param filterProjectStatusId A project status filter.
     * @param filterProjectLeadId A project status filter.
     * @param filterSeniorAssociateId A project status filter.
     *
     * @returns true if the project can be displayed otherwise false.
     */
    public filterPredicate = (
        filterKeyword: string,
        filterFromDate: Date | undefined,
        filterToDate: Date | undefined,
        filterProjectStatusId: string | null,
        filterProjectLeadId: string | null,
        filterSeniorAssociateId: string | null,
    ): boolean => {
        let result = true;

        // Attempt filtering by keyword.
        if (result) {
            const lowerCaseFilterKeyword = filterKeyword.toLowerCase();

            switch (true) {
                case isEmptyOrWhitespace(filterKeyword):
                case this.reference.toLowerCase().includes(lowerCaseFilterKeyword):
                case this.title.toLowerCase().includes(lowerCaseFilterKeyword):
                    result = true;
                    break;

                default:
                    result = false;
                    break;
            }
        }

        // If the quote has passed the keyword filter, attempt filtering by from date.
        if (result) {
            switch (true) {
                case isNullOrUndefined(filterFromDate):
                    result = true;
                    break;

                case isNullOrUndefined(this.model.anticipatedEndDate):
                    result = false;
                    break;

                case moment(this.model.anticipatedEndDate).startOf("day").isSameOrAfter(moment(filterFromDate)):
                    result = true;
                    break;

                default:
                    result = false;
                    break;
            }
        }

        // If the quote has passed the from date filter, attempt filtering by to date.
        if (result) {
            switch (true) {
                case isNullOrUndefined(filterToDate):
                    result = true;
                    break;

                case isNullOrUndefined(this.model.anticipatedEndDate):
                    result = false;
                    break;

                case moment(this.model.anticipatedEndDate).startOf("day").isSameOrBefore(moment(filterToDate)):
                    result = true;
                    break;

                default:
                    result = false;
                    break;
            }
        }

        // If the quote has passed the to date filter, attempt filtering by quote status.
        if (result) {
            switch (true) {
                case isEmptyOrWhitespace(filterProjectStatusId):
                case this.model.projectStatusId === filterProjectStatusId:
                    result = true;
                    break;

                default:
                    result = false;
                    break;
            }
        }

        // If the quote has passed the to date filter, attempt filtering by project lead id.
        if (result) {
            switch (true) {
                case isEmptyOrWhitespace(filterProjectLeadId):
                case this.model.projectLeadId === filterProjectLeadId:
                    result = true;
                    break;

                default:
                    result = false;
                    break;
            }
        }

        // If the quote has passed the to date filter, attempt filtering by senior associate id.
        if (result) {
            switch (true) {
                case isEmptyOrWhitespace(filterSeniorAssociateId):
                case this.model.seniorAssociateId === filterSeniorAssociateId:
                    result = true;
                    break;

                default:
                    result = false;
                    break;
            }
        }

        return result;
    };
}
