import React, { useCallback } from "react";
import { observer } from "mobx-react-lite";
import { ServiceGroupViewModel } from "../ServiceGroupViewModel";
import ClassNames from "classnames";
import { Container } from "./ServicesListView.styles";
import { Reorder } from "framer-motion";
import { ServiceModel } from "../../Service/ServiceModel";
import { ServiceView } from "../../Service/ServiceView";

export const ServicesListView: React.FC<{ viewModel: ServiceGroupViewModel }> = observer(({ viewModel }) => {
    const handleReorder = useCallback(
        (models: ServiceModel[]) => {
            viewModel.model.serviceModels.replace(models);
        },
        [viewModel],
    );

    return (
        <Container className={ClassNames({ expanded: viewModel.model.isServicesListExpanded })}>
            <Reorder.Group values={viewModel.model.serviceModels} onReorder={handleReorder}>
                {viewModel.model.serviceModels.map((model) => (
                    <Reorder.Item
                        style={{ listStyleType: "none" }}
                        key={model.KEY}
                        value={model}
                        whileDrag={{ scale: 0.9, backgroundColor: "lightGray" }}
                        whileHover={{ cursor: "grab" }}
                        whileTap={{ cursor: "grabbing" }}
                        onDragEnd={() => viewModel.onDragEndCommand.execute()}
                    >
                        <ServiceView model={model} parentViewModel={viewModel} />
                    </Reorder.Item>
                ))}
            </Reorder.Group>
        </Container>
    );
});
