import React, { useRef } from "react";
import { observer } from "mobx-react-lite";
import { IStyle, mergeStyleSets } from "@fluentui/react";
import { PersonaFileViewModel } from "./PersonaUploaderViewModel";
import { isEmptyOrWhitespace } from "@shoothill/core";
import { PhotoIcon } from "Assets/Icons/PhotoIcon";

interface IFilesStyles {
    root: IStyle;
    fileButton: IStyle;
    fileImage: IStyle;
    fileInner: IStyle;
}

interface IProps {
    className?: string;
    viewModel: PersonaFileViewModel;
}

export const PersonaUploaderView: React.FC<IProps> = observer((props) => {
    // #region Code Behind

    const filesStyles: IFilesStyles = {
        root: {
            width: "273px",
            height: "273px",
            overflow: "hidden",
            borderRadius: "50%",
            position: "relative",
        },
        fileButton: {
            border: "none",
            zIndex: "10",
            width: "100%",
            height: "100%",
            position: "absolute",
            backgroundColor: "rgb(41, 132, 230)",
            top: "0",
            left: "0",
        },
        fileImage: {
            backgroundImage: `url(${props.viewModel.documentUrl})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            height: "inherit",
            width: "inherit",
        },
        fileInner: {
            fontSize: "12px",
            letterSpacing: "0.3px",
            color: "#FFFFFF",
            textTransform: "uppercase",
        },
    };

    const inputReference = useRef<any>(null);

    const displayName = (): string => {
        return isFileSizeLimitExceed() ? props.viewModel.limitExceededErrorMessage : allowMultipleFiles() ? "Click to attach one or more files" : "UPLOAD PHOTO";
    };

    const displaySub = (): string => {
        return "(500x500 PIXELS, MAX 1MB)";
    };

    const allowMultipleFiles = (): boolean => {
        return props.viewModel.allowMultipleFiles;
    };

    const fileTypes = (): string => {
        return props.viewModel.fileTypes;
    };

    const isDisabled = (): boolean => {
        return false;
    };

    const isFileSizeLimitExceed = (): boolean => {
        if (props.viewModel.documentUrl) {
            return false;
        }
        return !isEmptyOrWhitespace(props.viewModel.limitExceededErrorMessage);
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        props.viewModel.addCommand.execute(event.target.files);

        // Do this to allow the file(s) to be reselected the next time the file explorer is open.
        event.target.value = "";
    };

    const onClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        inputReference?.current?.click();
    };

    /**
     * If an image url is defined, we must display the image without the instruction/error overlay.
     */
    const dynamicStyles: Partial<IFilesStyles> = {
        fileInner: {
            display: props.viewModel.hasDocumentUrl ? "none" : "block",
        },
        fileButton: {
            button: {
                backgroundColor: isFileSizeLimitExceed() ? "#EF9099" : props.viewModel.hasDocumentUrl ? "transparent" : "rgba(41, 132, 230, 0.75)",
                color: isFileSizeLimitExceed() ? "#DC3545" : "#ffffff",
                "&:hover": {
                    backgroundColor: isFileSizeLimitExceed() ? "#EF9099" : "rgba(41, 132, 230, 0.75)",
                    cursor: "pointer",
                    div: {
                        display: "block",
                    },
                },
            },
            svg: {
                ".canFill": {
                    fill: isFileSizeLimitExceed() ? "#DC3545" : "#8D8D8D",
                },
            },
        },
    };

    const styles = mergeStyleSets(filesStyles, dynamicStyles);

    // #endregion Code Behind

    return (
        <div className={styles.root}>
            <div className={styles.fileButton}>
                {/* Placeholder for mugshot. Do not remove.*/}
                <div className={styles.fileImage} />
                <input accept={fileTypes()} multiple={allowMultipleFiles()} ref={inputReference} style={{ display: "none" }} type="file" onChange={onChange} />
                <button className={styles.fileButton} disabled={isDisabled()} onClick={onClick} type="button">
                    <div className={styles.fileInner}>
                        <PhotoIcon />
                        <br />
                        {displayName()}
                        <br />
                        {displaySub()}
                    </div>
                </button>
            </div>
        </div>
    );
});
