import { isNullOrUndefined } from "@shoothill/core";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import { Nullcommand, setPageTitle, theme, ThemedButton, ThemedComboBox } from "Application";
import { ThemedLoader } from "Styles/ThemedPrimitives/Style1/ThemedLoader";
import { ThemedError } from "Styles/ThemedPrimitives/Style1/ThemedError";
import { MODELID_CURRENTPROJECTUPDATE } from "Views/Shared/Constants";
import { FormContainer, FormContainerRow, FormSubContainer } from "Views/Shared/SharedComponents";
import { ProjectWeeklyUpdateContainerViewModel } from "./ProjectWeeklyUpdateContainerViewModel";
import { WeeklyUpdateFormView } from "./Form/WeeklyUpdateFormView";
import { WeeklyUpdateHistoryView } from "./History/WeeklyUpdateHistoryView";
import { ProjectViewModel } from "../ProjectViewModel";

import styled from "@emotion/styled";

interface Props {
    viewModel: ProjectViewModel;
}

export const ProjectWeeklyUpdateContainerView: React.FC<Props> = observer((props: Props) => {
    // #region Code Behind

    const [viewModel] = useState(() => new ProjectWeeklyUpdateContainerViewModel(props.viewModel));

    useEffect(() => {
        setPageTitle("Project weekly update");

        return () => viewModel.dispose();
    }, []);

    // #endregion Code Behind

    const renderActiveWorkspace = () => {
        switch (true) {
            case viewModel.model.weeklyUpdateId === MODELID_CURRENTPROJECTUPDATE:
                return <WeeklyUpdateFormView viewModel={viewModel.weeklyUpdateFormViewModel} />;

            case !isNullOrUndefined(viewModel.weeklyUpdateHistoryViewModel):
                return <WeeklyUpdateHistoryView viewModel={viewModel.weeklyUpdateHistoryViewModel!} />;

            default:
                return <React.Fragment />;
        }
    };

    const renderBusy = () => <ThemedLoader isOpen={viewModel.apiClient.IsBusy} />;

    const renderError = () => {
        const isOpen = viewModel.apiClient.IsSubmitted && !viewModel.apiClient.IsBusy && viewModel.apiClient.HaveValidationMessage;

        return <ThemedError command={viewModel.resetServerErrorCommand} isOpen={isOpen} errorMessage={viewModel.apiClient.ValidationMessage} />;
    };

    return (
        <FormContainer>
            <FormSubContainer>
                {/* Weekly Update Navigation */}
                <FormContainerRow dc={"25%"}>
                    <ThemedComboBox
                        displayName="Week commencing"
                        labelColor="default"
                        options={viewModel.weeklyUpdates}
                        value={() => viewModel.getValue("weeklyUpdateId")}
                        command={viewModel.navigateToWeeklyUpdateCommand}
                        placeholder="Please select"
                        validationMessage={() => viewModel.getError("weeklyUpdateId")}
                    />
                </FormContainerRow>

                {/* Active Workspace */}
                {renderActiveWorkspace()}

                {/* Busy Overlay */}
                {renderBusy()}

                {/* Error Overlay */}
                {renderError()}
            </FormSubContainer>
        </FormContainer>
    );
});
