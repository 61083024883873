import { isEmptyOrWhitespace, ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { nanoid } from "nanoid";

import { Validator } from "Application/Validation";

export class SupplierContactModel extends ModelBase<SupplierContactModel> {
    public KEY: string = nanoid();

    public id: string | null = null;
    public contactTitleId: string | null = null;

    public firstName: string = "";
    public lastName: string = "";

    public supplierContactTypeId: string | null = null;

    public address1: string | null = null;
    public address2: string | null = null;
    public address3: string | null = null;
    public city: string | null = null;
    public postcode: string | null = null;
    public sameAsCustomerAddress: boolean = true;

    public contactNumber1: string | null = null;
    public contactNumber2: string | null = null;
    public emailAddress: string | null = null;

    constructor() {
        super();

        makeObservable(this, {
            id: observable,

            contactTitleId: observable,
            firstName: observable,
            lastName: observable,
            supplierContactTypeId: observable,

            address1: observable,
            address2: observable,
            address3: observable,
            city: observable,
            postcode: observable,
            sameAsCustomerAddress: observable,

            contactNumber1: observable,
            contactNumber2: observable,
            emailAddress: observable,
        });
    }
}

export class SupplierContactModelValidator extends Validator<SupplierContactModel> {
    constructor() {
        super();

        this.ruleFor("firstName")
            .notNull()
            .withMessage("Please enter a name")
            .notEmpty()
            .withMessage("Please enter a name")
            .maxLength(50)
            .withMessage("First name cannot exceed 50 characters");

        this.ruleFor("lastName")
            .notNull()
            .withMessage("Please enter a name")
            .notEmpty()
            .withMessage("Please enter a name")
            .maxLength(50)
            .withMessage("Last name cannot exceed 50 characters");

        this.ruleFor("postcode")
            .notNull()
            .withMessage("Please enter a valid postcode")
            .notEmpty()
            .withMessage("Please enter a valid postcode")
            .maxLength(16)
            .withMessage("Please enter a postcode less than or equal to 16 characters")
            .when((model) => model && !isEmptyOrWhitespace(model.postcode) && !model.sameAsCustomerAddress);

        this.ruleFor("contactNumber1")
            .notNull()
            .withMessage("Please enter a valid contact number or remove contents")
            .notEmpty()
            .withMessage("Please enter a valid contact number or remove contents")
            .maxLength(16)
            .withMessage("Please enter a contact number less than or equal to 16 characters")
            .when((model) => model && !isEmptyOrWhitespace(model.contactNumber1));

        this.ruleFor("contactNumber2")
            .notNull()
            .withMessage("Please enter a valid contact number or remove contents")
            .notEmpty()
            .withMessage("Please enter a valid contact number or remove contents")
            .maxLength(16)
            .withMessage("Please enter a contact number less than or equal to 16 characters")
            .when((model) => model && !isEmptyOrWhitespace(model.contactNumber2));

        this.ruleFor("emailAddress")
            .emailAddress()
            .withMessage("Please enter a valid email address or remove contents")
            .when((model) => model && !isEmptyOrWhitespace(model.emailAddress));
    }
}
