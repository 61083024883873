import { makeObservable, observable, observe, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, IKeyState, RelayCommand } from "Application";
import { TestModel, TestModelValidator } from "./TestModel";
import { Logger } from "index";

export class TestViewModel extends ViewModelBase<TestModel> {
    public apiClient = new APIClient();

    constructor(model = new TestModel()) {
        super(model);
        this.setValidator(new TestModelValidator());
        makeObservable(this, {});
    }

    public dispose = (): void => {};

    // #region Commands
    public updateErikCommand = new RelayCommand((value: string) => {
        this.setValue("erik", value);
    });

    // #endregion Commands
}
