import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import moment from "moment";

import { Validator } from "Application/Validation";
import { LeaveDayType } from "Views/Leave/Shared/LeaveDayType";
import { LeaveRequestUser } from "Views/Leave/Shared/LeaveRequestUser";
import { LeaveType } from "Views/Leave/Shared/LeaveType";

export class NewRequestModel extends ModelBase<NewRequestModel> {
    public id: string | null = null;
    public leaveTypeId: string | null = null;
    public fromDate: Date | null = null;
    public toDate: Date | null = null;
    public fromDateLeaveDayTypeId: string | null = null;
    public toDateLeaveDayTypeId: string | null = null;
    public requestReason: string | null = null;
    public userId: string | null = null;

    // #region Supporting

    public users: LeaveRequestUser[];
    public leaveTypes: LeaveType[];
    public leaveDayTypes: LeaveDayType[];

    // #endregion Supporting

    constructor(users: LeaveRequestUser[], leaveTypes: LeaveType[], leaveDayTypes: LeaveDayType[]) {
        super();

        this.users = users;
        this.leaveTypes = leaveTypes;
        this.leaveDayTypes = leaveDayTypes;

        makeObservable(this, {
            // Observables
            leaveTypeId: observable,
            userId: observable,
            fromDate: observable,
            toDate: observable,
            fromDateLeaveDayTypeId: observable,
            toDateLeaveDayTypeId: observable,
            requestReason: observable,
        });
    }
}

export class NewRequestModelValidator extends Validator<NewRequestModel> {
    constructor() {
        super();

        this.ruleFor("userId").notNull().withMessage("Please select a user").notEmpty().withMessage("Please select a user");
        this.ruleFor("leaveTypeId").notNull().withMessage("Please select the type of leave").notEmpty().withMessage("Please select the type of leave");

        // Date validation.
        this.ruleFor("fromDate").notNull().withMessage("Please provide a date");
        this.ruleFor("toDate")
            .notNull()
            .withMessage("Please provide a date")
            .must({
                predicate: (toDate, model) => moment(toDate).isSameOrAfter(moment(model.fromDate)),
                message: () => "Date must not be earlier than the from date",
            });

        // Day type validation.
        this.ruleFor("fromDateLeaveDayTypeId").notNull().withMessage("Please select the time of day").notEmpty().withMessage("Please select the time of day");
        this.ruleFor("toDateLeaveDayTypeId")
            .notNull()
            .withMessage("Please select the time of day")
            .notEmpty()
            .withMessage("Please select the time of day")
            .must({
                predicate: (toLeaveDayTypeId: string | null, model: NewRequestModel) => {
                    const fromDayType = model.leaveDayTypes.find((i: LeaveDayType) => i.id === model.fromDateLeaveDayTypeId);
                    const toDayType = model.leaveDayTypes.find((i: LeaveDayType) => i.id === toLeaveDayTypeId);

                    // Special case. If the leave is for less than one day, the leave cannot
                    // start at the start of the afternoon and finish at the end of the morning.
                    if (
                        moment(model.toDate).isSame(moment(model.fromDate)) &&
                        fromDayType?.type === LeaveDayType.FROM_AFTERNOON &&
                        toDayType?.type === LeaveDayType.ENDOF_MORNING
                    ) {
                        return false;
                    }

                    return true;
                },
                message: "Please select end of afternoon",
            });
    }
}
