import { ViewModelBase, isEmptyOrWhitespace } from "@shoothill/core";
import { PlanningApplicationModel } from "./PlanningApplicationModel";
import { APIClient, RelayCommand } from "Application";
import { container } from "tsyringe";
import { LookupStore, SettingsStore } from "Stores/Domain";
import { action, computed, makeObservable, observable } from "mobx";
import { useParams } from "react-router";
import { PlanningApplicationTableViewModel } from "./Table/PlanningApplicationTableViewModel";
import { PlanningApplicationTableItemViewModel } from "./Table/TableItem/PlanningApplicationTableItemViewModel";

export class PlanningApplicationViewModel extends ViewModelBase<PlanningApplicationModel> {
    public apiClient = new APIClient();
    public planningApplicationIdToAdd: string | null = null;
    public tableViewModel = new PlanningApplicationTableViewModel();

    constructor() {
        super(new PlanningApplicationModel());

        makeObservable(this, {
            // Observables
            planningApplicationIdToAdd: observable,
            //Actions
            setActiveTab: action,
            setPlanningApplicationToAdd: action,
            //Computed
            showAddEditModal: computed,
        });

        // this.apiClient.sendAsync(new GETAllPlanningApplicationLiteEndpoint(this));
    }
    //region helpers
    public activeTabClassName = (url: string) => {
        const params = useParams();
        const tab = params["*"];
        return url.includes(tab!) ? "activeTab" : "";
    };

    //region actions
    public setActiveTab = (tabName: string) => {
        this.history.push(tabName);
    };

    public setPlanningApplicationToAdd = (id: string | null) => {
        this.planningApplicationIdToAdd = id;
    };

    public dismissAddModal = () => {
        this.setPlanningApplicationToAdd(null);
    };

    //region computed

    public get showAddEditModal() {
        return this.planningApplicationIdToAdd !== null;
    }

    //region commands

    public addPlanningApplicationCommand = new RelayCommand(() => {
        this.setPlanningApplicationToAdd("new-id");
    });

    public dismissPlanningApplicationModalCommand = new RelayCommand(() => {
        this.dismissAddModal();
    });

    public onPlanningApplicationModalSubmitSuccessCommand = new RelayCommand(() => {
        this.tableViewModel.loadItems();
    });
}
