//libraries
import React, { useState } from "react";
import styled from "@emotion/styled";
import { Box, ThemedEditText, ThemedText, theme } from "Application";
import { observer } from "mobx-react-lite";

//app

import { InvoiceForecastMonthCapacityOverrideModel } from "./InvoiceForecastMonthCapacityOverrideModel";
import { InvoiceForecastMonthCapacityOverrideViewModel } from "./InvoiceForecastMonthCapacityOverrideViewModel";

const TableRow = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 2fr 2fr 1.5fr;

    align-items: center;

    & > * {
        padding: 10px;
    }

    & > *:not(:first-child) {
        border-left: 1px solid ${theme.palette.field.light};
    }

    border-left: 1px solid ${theme.palette.field.light};
    border-right: 1px solid ${theme.palette.field.light};
    border-bottom: 1px solid ${theme.palette.field.light};

    :first-of-type {
        border-top: 1px solid ${theme.palette.field.light};
    }
`;

interface IInvoiceForecastMonthCapacityOverrideModelProps {
    model: InvoiceForecastMonthCapacityOverrideModel;
    isEditable: boolean;
}

export const InvoiceForecastMonthCapacityOverrideView: React.FC<IInvoiceForecastMonthCapacityOverrideModelProps> = observer(
    (props: IInvoiceForecastMonthCapacityOverrideModelProps) => {
        const [viewModel] = useState(() => new InvoiceForecastMonthCapacityOverrideViewModel(props.model));

        return (
            <>
                {!props.isEditable && (
                    <>
                        <Box height={"100%"} flexBox alignItems={"center"}>
                            <ThemedText>{viewModel.formattedHours}</ThemedText>
                        </Box>
                        <Box height={"100%"} flexBox alignItems={"center"}>
                            <ThemedText>{viewModel.formattedValue}</ThemedText>
                        </Box>
                    </>
                )}

                {props.isEditable && (
                    <>
                        <Box height={"100%"} padding={"10px"}>
                            <ThemedEditText
                                displayName=""
                                command={viewModel.updateOverrideHoursCommand}
                                placeholder={""}
                                value={() => viewModel.getValue("hours")}
                                validationMessage={() => viewModel.getError("hours")}
                            />
                        </Box>
                        <Box height={"100%"} padding={"10px"}>
                            <ThemedEditText
                                displayName=""
                                command={viewModel.updateOverrideValueCommand}
                                placeholder={""}
                                value={() => viewModel.getValue("value")}
                                validationMessage={() => viewModel.getError("value")}
                            />
                        </Box>
                    </>
                )}
            </>
        );
    },
);
