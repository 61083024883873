import { IModalStyles, Modal } from "@fluentui/react";
import { isEmptyOrWhitespace } from "@shoothill/core";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React from "react";
import { AutoCell, AutoGrid, ICommand, ThemedButton, ThemedText } from "../../../../../Application";

/**
 * Error interface.
 */
export interface IErrorBaseProps {
    /**
     * An optional class name for use with the button.
     */
    className?: string;
    /**
     * A command to execute (to close the error modal).
     */
    command: ICommand;
    /**
     * An optional class name for use with the button.
     */
    isOpen: boolean;
    /**
     * The error message to display
     */
    errorMessage?: string;
    /**
     * Styling of the modal.
     */
    styles?: IModalStyles;
}

export const ErrorBase: React.FC<IErrorBaseProps> = observer((props) => {
    // #region Code Behind

    const getClasseNames = (): string => {
        return clsx({
            [props.className!]: !isEmptyOrWhitespace(props.className),
        });
    };

    const canDisplayErrorMessage = (): boolean => {
        return !isEmptyOrWhitespace(props.errorMessage);
    };

    // #endregion Code Behind

    return (
        <Modal className={getClasseNames()} isOpen={props.isOpen} styles={props.styles}>
            <AutoGrid columnGap={30} rowGap={20} mobileColumns={"1fr"} tabletColumns={"1fr"} desktopColumns={"1fr"}>
                <ThemedText color="white" fontStyle="h1">
                    Oops! something went wrong
                </ThemedText>
                {canDisplayErrorMessage() && (
                    <ThemedText color="white" fontStyle="h2">
                        {props.errorMessage}
                    </ThemedText>
                )}
                <AutoCell alignItems="center">
                    <ThemedButton paletteColor="primary" command={props.command} displayName="Close" />
                </AutoCell>
            </AutoGrid>
        </Modal>
    );
});
