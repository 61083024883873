import { isEmptyOrWhitespace, ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { nanoid } from "nanoid";

import { formatDate, formatTime } from "Application";
import { Validator } from "Application/Validation";
import moment from "moment";

export class NoteModel extends ModelBase<NoteModel> {
    // Note: some observables are predefined in the ModelBase class.
    // If any errors arise, check there to see if the property exists
    // and if so, delete it from here.
    // public createdDate: Date | null = null;
    // public updatedDate: Date | null = null;

    public KEY: string = nanoid();

    public id: string | null = null;

    public enquiryId: string | null = null;
    public quoteId: string | null = null;
    public projectId: string | null = null;

    public noteTypeId: string | null = null;
    public noteSubTypeId: string | null = null;
    public noteDetail: string = "";
    public noteDate: Date | null = new Date();
    public noteTime: string | null = formatTime(new Date());
    public editMode: boolean = false;

    // #region Supporting

    createdByUserId: string = "";
    createdByUserFirstName: string = "";
    createdByUserLastName: string = "";

    lastUpdatedDate: Date | null = null;
    lastUpdatedByUserId: string = "";
    lastUpdatedByUserFirstName: string | null = null;
    lastUpdatedByUserLastName: string | null = null;

    // #endregion Supporting

    public get formattedNoteDate() {
        return formatDate(this.noteDate as Date);
    }

    constructor() {
        super();
        makeObservable(this, {
            id: observable,

            noteTypeId: observable,
            noteSubTypeId: observable,
            noteDetail: observable,
            noteDate: observable,
            noteTime: observable,
            editMode: observable,

            createdByUserId: observable,
            createdByUserFirstName: observable,
            createdByUserLastName: observable,

            lastUpdatedDate: observable,
            lastUpdatedByUserId: observable,
            lastUpdatedByUserFirstName: observable,
            lastUpdatedByUserLastName: observable,
        });
    }

    // #region Helpers

    public copy = (excludeKey: boolean = false): NoteModel => {
        const model = new NoteModel();

        model.KEY = excludeKey ? model.KEY : this.KEY;
        model.id = this.id;

        model.enquiryId = this.enquiryId;
        model.quoteId = this.quoteId;
        model.projectId = this.projectId;

        model.noteTypeId = this.noteTypeId;
        model.noteSubTypeId = this.noteSubTypeId;
        model.noteDetail = this.noteDetail;
        model.noteDate = this.noteDate;
        model.noteTime = this.noteTime;

        model.createdDate = this.createdDate;
        model.createdByUserFirstName = this.createdByUserFirstName;
        model.createdByUserLastName = this.createdByUserLastName;
        model.createdByUserId = this.createdByUserId;

        model.lastUpdatedDate = this.lastUpdatedDate;
        model.lastUpdatedByUserFirstName = this.lastUpdatedByUserFirstName;
        model.lastUpdatedByUserLastName = this.lastUpdatedByUserLastName;
        model.lastUpdatedByUserId = this.lastUpdatedByUserId;

        return model;
    };

    // #endregion Helpers
}

export class NoteModelValidator extends Validator<NoteModel> {
    constructor() {
        super();
        this.ruleFor("noteTypeId").notNull().withMessage("Please select the type of note").notEmpty().withMessage("Please select the type of note");
        this.ruleFor("noteDetail").notEmpty().withMessage("Please add detail about the note");
        this.ruleFor("noteTime")
            .notEmpty()
            .matches(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)
            .withMessage("Please enter a time")
            .when((note) => !isEmptyOrWhitespace(note.noteTime));
    }
}
