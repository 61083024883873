import { runInAction } from "mobx";
import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { OverviewViewModel } from "../Overview/OverviewViewModel";
import { ProjectLiteModel } from "Views/Shared/Project/ProjectLiteModel";
import { QuoteLiteModel } from "Views/Shared/Quote/QuoteLiteModel";

class Response {
    public quoteLites: QuoteLiteResponse[] = [];
    public projectLites: ProjectLiteResponse[] = [];
}

class QuoteLiteResponse {
    public id: string | null = null;
    public title: string | null = null;
    public quoteValue: number | null = null;
    public customerId: string | null = null;
    public customerBusinessName: string | null = null;
}
class ProjectLiteResponse {
    public id: string | null = null;
    public title: string | null = null;
    public projectValue: number | null = null;
    public customerId: string | null = null;
    public customerBusinessName: string | null = null;
}

export class GETTopProjectsAndQuotesEndpoint extends EndpointWithoutRequest<Response> {
    private viewModel: OverviewViewModel;

    constructor(viewModel: OverviewViewModel) {
        super();
        this.viewModel = viewModel;
        this.verb(Http.Get);
        const requestURL = AppUrls.Server.Dashboard.Admin.GetTopProjectsAndQuotes;
        this.path(requestURL);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);
        //to do extend endpoint mapping
        runInAction(() => {
            const projectLiteModels: ProjectLiteModel[] = [];

            response.projectLites.forEach((response) => {
                const model = new ProjectLiteModel();
                model.toModel(response);
                projectLiteModels.push(model);
            });

            this.viewModel.model.topProjectLites.replace(projectLiteModels);

            const quoteLiteModels: QuoteLiteModel[] = [];

            response.quoteLites.forEach((response) => {
                const model = new QuoteLiteModel();
                model.toModel(response);
                quoteLiteModels.push(model);
            });

            this.viewModel.model.topQuoteLites.replace(quoteLiteModels);
        });
    }
}
