//libraries
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import * as React from "react";
import styled from "@emotion/styled";
//app
import { OverviewViewModel } from "./OverviewViewModel";
import { Box, setPageTitle, ThemedButton, ThemedComboBox, ThemedEditDate, ThemedText } from "Application";
import { EnquiriesWidgetView } from "./Enquiries/EnquiriesWidgetView";
import { IDatePickerStyles } from "@fluentui/react";
import { ProjectsListView } from "./ProjectsList/ProjectsListView";
import { RunningTotals } from "./RunningTotals/RunningTotalsView";
import { SalesFunnelView } from "./SalesFunnel/SalesFunnelView";
import { QuotesListView } from "./QuotesList/QuotesListView";
import { AbsenteesView } from "./Absentees/AbsenteesView";
import { ProgrammingView } from "./Programming/ProgrammingView";
import { InvoiceView } from "./Invoice/InvoiceView";
import { PlanningApplicationsView } from "./PlanningApplications/PlanningApplicationsView";

const datePickerBaseStyles: IDatePickerStyles = {
    root: { height: 40 },
    textField: {},
    callout: {},
    icon: {},
};

interface IOverviewWidgetWrapperProps {
    backgroundImage: string;
}
interface IUserPersonaContainerProps {
    backgroundImage: string;
}

const StaffDetails = styled.div`
    background: #f9f9f9;
    z-index: 2;
    grid-area: staffDetails;
    height: calc(100vh - 70px - 91px);
    min-height: 785px;
    overflow: hidden;
`;

const UserDetailsContainer = styled.div`
    background-color: white;
    padding: 20px 0px;
    height: 210px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const UserPersonaContainer = styled.div<IUserPersonaContainerProps>`
    border-radius: 50%;
    width: 96px;
    height: 96px;
    background-image: url(${(props) => props.backgroundImage});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-bottom: 5px;
    filter: grayscale(100%);
`;

const OverviewWidgetWrapper = styled.div<IOverviewWidgetWrapperProps>`
    content: "";
    position: relative;
    min-height: 100%;
    width: 100%;

    background-image: url(${(props) => props.backgroundImage});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    flex: 1;

    display: grid;
    grid-template-areas: "staffDetails widgetContainer";

    grid-template-columns: 250px 1fr;
    grid-template-rows: 1fr;

    .fade-in {
        animation: fadeIn 500ms;
        -webkit-animation: fadeIn 500ms;
        -moz-animation: fadeIn 500ms;
        -o-animation: fadeIn 500ms;
        -ms-animation: fadeIn 500ms;
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @-moz-keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @-webkit-keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @-o-keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @-ms-keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;

const OverviewWidgetContainer = styled.div`
    grid-area: widgetContainer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
`;

const OverviewWidgetInnerContainer = styled.div`
    display: grid;
    width: 100%;
    grid-template-areas:
        "dateFilter dateFilter dateFilter dateFilter dateFilter dateFilter"
        "projectsList runningTotals runningTotals runningTotals runningTotals runningTotals"
        "quotesList enquiries  enquiries enquiries enquiries planningApplications";

    grid-template-columns: repeat(6, minmax(200px, 1fr));
    grid-template-rows: 55px 350px 320px;
    align-items: center;
    gap: 15px;
    z-index: 2;

    & > * {
        border-radius: 3px;
    }
`;

const OverviewWrapper = styled.div`
    height: 100%;
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const BackgroundImageOverlay = styled.div`
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    flex: 1;
    z-index: 1;
`;

const DatePickerContainer = styled(Box)`
    grid-area: dateFilter;
    display: grid;
    grid-template-columns: 80px repeat(3, 175px) 80px;
    column-gap: 30px;
    background: white;
    align-items: center;
    padding: 0 30px;
    height: 55px;
    width: 100%;
`;

const DatePickerItem = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const OverviewView: React.FC = observer(() => {
    const [viewModel] = useState(() => new OverviewViewModel());

    useEffect(() => {
        setPageTitle("Overview");
    }, []);

    const staffDetailsRef = useRef(null);

    return (
        <OverviewWrapper>
            <OverviewWidgetWrapper backgroundImage={viewModel.backgroundImageURL}>
                <StaffDetails ref={staffDetailsRef}>
                    <UserDetailsContainer>
                        <UserPersonaContainer backgroundImage={viewModel.userPersona} />
                        <ThemedText fontStyle="title">
                            <span style={{ fontSize: viewModel.userNameFontSize }}> Hi {viewModel.userFirstName}</span>
                        </ThemedText>
                    </UserDetailsContainer>
                    <AbsenteesView />
                    <ProgrammingView parentViewModel={viewModel} />
                </StaffDetails>
                <OverviewWidgetContainer>
                    <OverviewWidgetInnerContainer className="fade-in">
                        <DatePickerContainer>
                            <ThemedText fontStyle="h3">Showing:</ThemedText>
                            <ThemedComboBox
                                command={viewModel.updateFinancialPeriodTypeCommand}
                                options={viewModel.financialPeriodTypes}
                                size="larger"
                                value={() => viewModel.getValue("financialPeriodTypeId")}
                                showLabel={false}
                            />

                            <DatePickerItem>
                                <ThemedEditDate
                                    command={viewModel.updateStartDateCommand}
                                    placeholder="Start date"
                                    size="larger"
                                    value={() => viewModel.getValue("startDateFilter")}
                                    showLabel={false}
                                    styles={datePickerBaseStyles}
                                    validationMessage={() => viewModel.getError("startDateFilter")}
                                    showErrorMessage={false}
                                    encloseErrorLabel
                                />
                            </DatePickerItem>

                            <DatePickerItem>
                                <ThemedEditDate
                                    command={viewModel.updateEndDateCommand}
                                    placeholder="Start date"
                                    size="larger"
                                    value={() => viewModel.getValue("endDateFilter")}
                                    showLabel={false}
                                    styles={datePickerBaseStyles}
                                    validationMessage={() => viewModel.getError("endDateFilter")}
                                    showErrorMessage={false}
                                    encloseErrorLabel
                                />
                            </DatePickerItem>

                            {viewModel.showApplyDateFilterButton && <ThemedButton displayName="Apply" paletteColor="primary" command={viewModel.generateOverviewCommand} />}
                        </DatePickerContainer>

                        {viewModel.showInvoicingForecast && <InvoiceView />}

                        {viewModel.showProjectsByValue && <ProjectsListView viewModel={viewModel} />}

                        <QuotesListView viewModel={viewModel} />

                        <RunningTotals viewModel={viewModel} />

                        <EnquiriesWidgetView viewModel={viewModel} />

                        {/* <SalesFunnelView viewModel={viewModel} /> */}

                        <PlanningApplicationsView />
                    </OverviewWidgetInnerContainer>
                </OverviewWidgetContainer>

                <BackgroundImageOverlay />
            </OverviewWidgetWrapper>
        </OverviewWrapper>
    );
});
