import { isEmptyOrWhitespace, ModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

export class CustomerItemModel extends ModelBase<CustomerItemModel> {
    // Note: some observables are predefined in the ModelBase class.
    // If any errors arise, check there to see if the property exists
    // and if so, delete it from here.
    // public createdDate: Date | null = null;
    // public updatedDate: Date | null = null;
    // public isDeleted: boolean = false;

    public id: string = "";
    public contactTypeId: string = "";
    public contactTitleId: string | null = null;

    public prefixName: string = "";
    public businessName: string | null = null;
    public firstName: string | null = null;
    public lastName: string | null = null;

    public contactNumber1: string | null = null;
    public contactNumber2: string | null = null;
    public emailAddress: string | null = null;
    public emailAddress2: string | null = null;

    public address1: string | null = null;
    public address2: string | null = null;
    public address3: string | null = null;
    public city: string | null = null;
    public postcode: string | null = null;

    constructor() {
        super();
        makeObservable(this, {});
    }

    // #region Business Logic

    public get fullName(): string {
        return [this.businessName, this.prefixName, this.firstName, this.lastName].filter((item) => !isEmptyOrWhitespace(item)).join(" ");
    }

    public get fullAddress(): string {
        return [this.address1, this.address2, this.address3, this.city, this.postcode?.toUpperCase()].filter((item) => !isEmptyOrWhitespace(item)).join(", ");
    }

    // #endregion Business Logic
}
