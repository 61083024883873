import * as React from "react";
import { SVGProps } from "react";
import { theme } from "../../Styles";

export const AddressIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={11} height={12} viewBox="0 0 11 12" {...props}>
        <g data-name="Group 7250" fill="none">
            <g data-name="Rectangle 1857" transform="translate(1.5 5)" stroke="#ff4b22">
                <rect width={8} height={7} rx={1} stroke="none" />
                <rect x={0.5} y={0.5} width={7} height={6} rx={0.5} />
            </g>
            <g data-name="Path 5666">
                <path d="M5.756.108a.358.358 0 0 0-.513 0L.218 4.746C-.18 5.114 0 6 .475 6h10.05c.475 0 .655-.886.256-1.254Z" />
                <path
                    d="M5.5 1.232 1.418 5h8.164L5.5 1.232M5.5 0c.09 0 .178.036.256.108l5.025 4.638c.399.368.217 1.254-.256 1.254H.475C.002 6-.179 5.114.22 4.746L5.244.108A.378.378 0 0 1 5.5 0Z"
                    fill={theme.palette.common.secondary}
                />
            </g>
        </g>
    </svg>
);
