import { ModelBase } from "@shoothill/core";
import { computed, makeObservable, observable } from "mobx";
import { nanoid } from "nanoid";

import { Validator } from "Application/Validation";

export class TaskModel extends ModelBase<TaskModel> {
    public KEY: string = nanoid();

    public id: string | null = null;
    public serviceId: string | null = null;

    public hourlyRate: number | null = null;
    public manHours: number | null = null;
    public taskName: string = "";
    public ordinal: number = 0;

    constructor() {
        super();
        makeObservable(this, {
            // Observables
            hourlyRate: observable,
            manHours: observable,
            taskName: observable,
            ordinal: observable,
            // Computeds
            predictedCostExclVAT: computed,
        });
    }

    // #region Business Logic

    public get predictedCostExclVAT(): number {
        if (this.manHours && this.hourlyRate) {
            return this.manHours * this.hourlyRate;
        }
        return 0;
    }

    // #endregion Business Logic
}

export class TaskModelValidator extends Validator<TaskModel> {
    constructor() {
        super();

        this.ruleFor("manHours").notNull().withMessage("Please provide a value").greaterThan(0).withMessage("Please provide a value");
        this.ruleFor("hourlyRate").notNull().withMessage("Please provide a value").greaterThan(0).withMessage("Please provide a value");
        this.ruleFor("taskName").notNull().withMessage("Task must have a label");
    }
}
