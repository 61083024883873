import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

export class LetterTemplatesListModel extends ModelBase<LetterTemplatesListModel> {
    // Filtering
    public filterKeyword: string = "";

    // Sorting
    public sortKey = "name"; // A property defined in the table column definitions.
    public sortDescending = false;
    constructor() {
        super();
        makeObservable(this, {
            filterKeyword: observable,
            sortKey: observable,
            sortDescending: observable,
        });
    }
}
