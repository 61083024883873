import { ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

import { RelayCommand } from "Application";
import { AppUrls } from "AppUrls";
import { StaffItemModel } from "./StaffItemModel";

export class StaffItemViewModel extends ViewModelBase<StaffItemModel> {
    constructor(model = new StaffItemModel()) {
        super(model);
        makeObservable(this, {});
    }

    // #region Properties

    public get KEY() {
        return this.model.KEY;
    }

    public get userFullName() {
        return `${this.model.userFirstName} ${this.model.userLastName}`;
    }

    public get userDocumentUrl() {
        return this.model.documentUrl;
    }

    public get effortPercentageAllocation() {
        return this.model.effortPercentageAllocation;
    }

    public get effortHoursAllocation() {
        return this.model.effortHoursAllocation;
    }

    public get chargeableEffortPercentageAllocation() {
        return this.model.chargeableEffortPercentageAllocation;
    }

    public get chargeableEffortHoursAllocation() {
        return this.model.chargeableEffortHoursAllocation;
    }

    public get businessHours() {
        return this.model.businessHours;
    }

    public get chargeableBusinessHours() {
        return this.model.chargeableBusinessHours;
    }

    // #endregion Properties

    // #region Commands

    public navigateToStaffResourceDetailsCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.Staff.Details.replace(":id", this.model.userId));
    });

    // #endregion Commands
}
