import { keyframes, mergeStyleSets } from "@fluentui/react";
import { PropsWithChildren } from "react";

import { ILoaderBaseProps, LoaderBase } from "../../../Components/StyleFrameworks/FluentUI/Primitives/Active/LoaderBase";
import { theme } from "../../AppTheme";
import { observer } from "mobx-react-lite";

/**
 * WARNING - If your styled components needs to change style because of change in the state of the
 * model (via the props), wrap the component in a mobx observer.
 */
export const ThemedLoader = observer((props: PropsWithChildren<ILoaderBaseProps>) => {
    const myEffect = keyframes({
        "0%": {
            backgroundColor: "transparent",
            opacity: "0",
        },
        "100%": {
            backgroundColor: `${theme.palette.common.default}B2`,
            opacity: "1",
        },
    });

    return (
        <LoaderBase
            {...props}
            styles={mergeStyleSets(
                {
                    keyboardMoveIcon: {},
                    keyboardMoveIconContainer: {},
                    layer: {},
                    main: {
                        animationName: myEffect,
                        animationDuration: "5000ms",

                        border: "none",
                        boxShadow: "none",
                        height: "100%",
                        maxHeight: "100%",
                        maxWidth: "100%",
                        width: "100%",

                        backgroundColor: `${theme.palette.common.default}B2`,
                        color: "white",

                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                    },
                    root: {
                        "&&": {
                            backgroundColor: "transparent",
                            transition: "none",
                            "> div.ms-Overlay": {
                                backgroundColor: "transparent",
                            },
                        },
                    },
                    scrollableContent: {
                        flex: "0",
                        overflowY: "none",

                        fontFamily: theme.fontStyles.h1.fontFamily ?? theme.defaultFontStyle.fontFamily,
                        fontSize: theme.fontStyles.h1.fontSize ?? theme.defaultFontStyle.fontSize,
                        fontWeight: theme.fontStyles.h1.fontWeight ?? theme.defaultFontStyle.fontWeight,
                        letterSpacing: theme.fontStyles.h1.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                        lineHeight: theme.fontStyles.h1.lineHeight ?? theme.defaultFontStyle.lineHeight,
                        textTransform: theme.fontStyles.h1.textTransform ?? theme.defaultFontStyle.textTransform,
                    },
                },
                props.styles,
            )}
        />
    );
});
