import { runInAction } from "mobx";

import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { AssignTaskViewModel } from "./AssignTaskViewModel";
import { ProjectTaskAssignmentTemplateNote } from "Views/Shared/ProjectTaskAssignmentTemplateNote/ProjectTaskAssignmentTemplateNoteModel";
import { User } from "Application/Models/Domain/User";

class Response {
    billableEffort: number | null = null;
    effort: number | null = null;
    id: string = "";
    isNonBillableEffort = false;
    plannedEndDate: string = "";
    plannedStartDate: string = "";
    projectTaskIsBilledHourly = false;
    summary: SummaryResponse | null = null;
    projectTaskAssignmentTemplateNotes: ProjectTaskAssignmentTemplateNote[] = [];
    users: User[] = [];
    userId: string | null = null;
}

class SummaryResponse {
    public reference = "";
    public title = "";
    public projectTaskGroupName = "";
    public projectTaskName = "";
}

export class GETProjectTaskAssignmentRelatedEndpoint extends EndpointWithoutRequest<Response> {
    private readonly viewModel: AssignTaskViewModel;

    constructor(projectTaskId: string, viewModel: AssignTaskViewModel) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.Resources.GetProjectTaskAssignmentRelated.replace(":projectTaskId", projectTaskId));
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: any): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            this.viewModel.summaryViewModel.model.fromResponse(response.summary);
            this.viewModel.model.users.replace(
                response.users.map((item: any) => {
                    const domainModel = new User();

                    domainModel.id = item.id;
                    domainModel.firstName = item.firstName;
                    domainModel.lastName = item.lastName;
                    domainModel.thumbnailDocumentUrl = item.thumbnailDocumentUrl;
                    domainModel.isAssignable = item.isAssignable;
                    return domainModel;
                }),
            );
            this.viewModel.model.fromResponse(response);
            this.viewModel.setValue("maximumBillableEffort", response.billableEffort);
            this.viewModel.setValue("plannedStartDate", new Date(response.plannedStartDate));
            this.viewModel.setValue("plannedEndDate", new Date(response.plannedEndDate));
            this.viewModel.model.projectTaskAssignmentTemplateNotes.replace(response.projectTaskAssignmentTemplateNotes);
        });
    }
}
