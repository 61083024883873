import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

import { AutoGrid, Box, Else, Show, theme, ThemedCheckBox, ThemedComboBox, ThemedEditText, ThemedText, ThemedTransparentIconButton } from "Application";
import { AddressIcon } from "Assets/Icons/AddressIcon";
import { PhoneIcon } from "Assets/Icons/PhoneIcon";
import { EmailIcon } from "Assets/Icons/EmailIcon";
import { CrossSVG } from "Assets/CrossSVG";
import { FormContainerRow, FormSeparator, GroupItemInformationCell, GroupItemInformationContainer, GroupItemInformationSubContainer } from "Views/Shared/SharedComponents";
import { CustomerViewModel } from "./CustomerViewModel";
import { FindAddressView } from "../FindAddress/FindAddressView";
import { ContactCallout, ICalloutState } from "Styles/ThemedPrimitives/Style1/ContactCallout";
import { isEmptyOrWhitespace } from "@shoothill/core";
import { ILabelStyles, Label } from "@fluentui/react";
import { NoteEditIcon } from "Components/StyleFrameworks/FluentUI/Primitives/Active/NoteEditIcon";

interface ICustomerViewProps {
    viewModel: CustomerViewModel;
}

const CustomerCrossSVG = styled(CrossSVG)`
    g {
        stroke: ${theme.palette.defaultTr.text};
    }
`;

const CheckBoxGroupLabelStyles = {
    root: {
        fontFamily: theme.fontStyles.label.fontFamily ?? theme.defaultFontStyle.fontFamily,
        fontSize: theme.fontStyles.label.fontSize ?? theme.defaultFontStyle.fontSize,
        fontWeight: theme.fontStyles.label.fontWeight ?? theme.defaultFontStyle.fontWeight,
        letterSpacing: theme.fontStyles.label.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
        lineHeight: theme.fontStyles.label.lineHeight ?? theme.defaultFontStyle.lineHeight,
        textTransform: theme.fontStyles.label.textTransform ?? theme.defaultFontStyle.textTransform,
        padding: "5px 0",
    },
};

export const QuoteThemeCheckBoxStyle = {
    root: {
        marginTop: "5px",
    },
};

export const CustomerView: React.FC<ICustomerViewProps> = observer((props) => {
    // #region Code Behind

    const viewModel = props.viewModel;
    const defaultCalloutState: ICalloutState = { isCalloutVisble: false, targetId: "", isEmail: false, data: "" };
    const [callout, setIsCalloutVisible] = useState<ICalloutState>(defaultCalloutState);

    const labelStyle: Partial<ILabelStyles> = {
        root: {
            fontFamily: theme.fontStyles.label.fontFamily ?? theme.defaultFontStyle.fontFamily,
            fontSize: theme.fontStyles.label.fontSize ?? theme.defaultFontStyle.fontSize,
            fontWeight: theme.fontStyles.label.fontWeight ?? theme.defaultFontStyle.fontWeight,
            letterSpacing: theme.fontStyles.label.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
            lineHeight: theme.fontStyles.label.lineHeight ?? theme.defaultFontStyle.lineHeight,
            textTransform: theme.fontStyles.label.textTransform ?? theme.defaultFontStyle.textTransform,
        },
    };

    // #endregion Code Behind

    const renderCompletedClient = () => {
        return (
            <GroupItemInformationContainer marginBottom="20px !important">
                <GroupItemInformationSubContainer>
                    {viewModel.completedCustomers.map((c, index) => {
                        return (
                            <Box display="flex" key={c.KEY} className="clientDetailsList">
                                <GroupItemInformationCell flex="20">
                                    <b>{viewModel.getCustomerName(c)}</b>
                                </GroupItemInformationCell>
                                {!isEmptyOrWhitespace(c.contactNumber1) || !isEmptyOrWhitespace(c.contactNumber2) ? (
                                    <GroupItemInformationCell flex="10">
                                        <Box display="flex" alignItems="center" width="20px" marginBottom="1px" paddingLeft="1px">
                                            <PhoneIcon width="8px" />
                                        </Box>
                                        <Box flexDirection="column">
                                            <Box
                                                id={`customernumber1-${index}`}
                                                onClick={(event: any) => {
                                                    setIsCalloutVisible({
                                                        isCalloutVisble: true,
                                                        isEmail: false,
                                                        targetId: `customernumber1-${index}`,
                                                        data: viewModel.getCustomerContactNumber1(c)!,
                                                    });
                                                }}
                                            >
                                                {viewModel.getCustomerContactNumber1(c)}
                                            </Box>
                                            <Box
                                                id={`customernumber2-${index}`}
                                                onClick={(event: any) => {
                                                    setIsCalloutVisible({
                                                        isCalloutVisble: true,
                                                        isEmail: false,
                                                        targetId: `customernumber2-${index}`,
                                                        data: viewModel.getCustomerContactNumber2(c)!,
                                                    });
                                                }}
                                            >
                                                {viewModel.getCustomerContactNumber2(c)}
                                            </Box>
                                        </Box>
                                    </GroupItemInformationCell>
                                ) : (
                                    <Box></Box>
                                )}
                                {!isEmptyOrWhitespace(c.emailAddress) || !isEmptyOrWhitespace(c.emailAddress2) ? (
                                    <GroupItemInformationCell flex="30">
                                        <Box display="flex" alignItems="center" width="20px" marginBottom="1px" paddingLeft="1px">
                                            <EmailIcon width="15px" />
                                        </Box>
                                        <Box flexDirection="column">
                                            <Box
                                                id={`customeremailaddress-${index}`}
                                                onClick={(event: any) => {
                                                    setIsCalloutVisible({
                                                        isCalloutVisble: true,
                                                        isEmail: true,
                                                        targetId: `customeremailaddress-${index}`,
                                                        data: viewModel.getCustomerEmailAddress(c)!,
                                                    });
                                                }}
                                            >
                                                {viewModel.getCustomerEmailAddress(c)}
                                            </Box>
                                            <Box
                                                id={`customeremailaddress2-${index}`}
                                                onClick={(event: any) => {
                                                    setIsCalloutVisible({
                                                        isCalloutVisble: true,
                                                        isEmail: true,
                                                        targetId: `customeremailaddress2-${index}`,
                                                        data: viewModel.getCustomerEmailAddress2(c)!,
                                                    });
                                                }}
                                            >
                                                {viewModel.getCustomerEmailAddress2(c)}
                                            </Box>
                                        </Box>
                                    </GroupItemInformationCell>
                                ) : (
                                    <Box></Box>
                                )}
                                {!isEmptyOrWhitespace(c.fullAddress) ? (
                                    <GroupItemInformationCell flex="40">
                                        <Box display="flex" alignItems="center" width="20px" marginBottom="2px">
                                            <AddressIcon width="10px" />
                                        </Box>
                                        <Box>{viewModel.getCustomerAddress(c)}</Box>
                                    </GroupItemInformationCell>
                                ) : (
                                    <Box></Box>
                                )}
                            </Box>
                        );
                    })}
                    {viewModel.completedCustomerContacts.map((c, index) => {
                        return (
                            <Box display="flex" key={c.KEY} className="clientDetailsList">
                                <GroupItemInformationCell flex="20">{viewModel.getCustomerContactName(c)}</GroupItemInformationCell>
                                {!isEmptyOrWhitespace(c.contactNumber1) || !isEmptyOrWhitespace(c.contactNumber2) ? (
                                    <GroupItemInformationCell flex="10">
                                        <Box display="flex" alignItems="center" width="20px" marginBottom="1px" paddingLeft="1px">
                                            <PhoneIcon width="8px" />
                                        </Box>
                                        <Box flexDirection="column">
                                            <Box
                                                id={`customercontactnumber1-${index}`}
                                                onClick={(event: any) => {
                                                    setIsCalloutVisible({
                                                        isCalloutVisble: true,
                                                        isEmail: false,
                                                        targetId: `customercontactnumber1-${index}`,
                                                        data: viewModel.getCustomerContactContactNumber1(c)!,
                                                    });
                                                }}
                                            >
                                                {viewModel.getCustomerContactContactNumber1(c)}
                                            </Box>
                                            <Box
                                                id={`customercontactnumber2-${index}`}
                                                onClick={(event: any) => {
                                                    setIsCalloutVisible({
                                                        isCalloutVisble: true,
                                                        isEmail: false,
                                                        targetId: `customercontactnumber2-${index}`,
                                                        data: viewModel.getCustomerContactContactNumber2(c)!,
                                                    });
                                                }}
                                            >
                                                {viewModel.getCustomerContactContactNumber2(c)}
                                            </Box>
                                        </Box>
                                    </GroupItemInformationCell>
                                ) : (
                                    <Box></Box>
                                )}
                                {!isEmptyOrWhitespace(c.emailAddress) ? (
                                    <GroupItemInformationCell flex="30">
                                        <Box display="flex" alignItems="center" width="20px" marginBottom="1px" paddingLeft="1px">
                                            <EmailIcon width="15px" />
                                        </Box>
                                        <Box
                                            id={`customercontactemailaddress-${index}`}
                                            onClick={(event: any) => {
                                                setIsCalloutVisible({
                                                    isCalloutVisble: true,
                                                    isEmail: true,
                                                    targetId: `customercontactemailaddress-${index}`,
                                                    data: viewModel.getCustomerContactEmailAddress(c)!,
                                                });
                                            }}
                                        >
                                            {viewModel.getCustomerContactEmailAddress(c)}
                                        </Box>
                                    </GroupItemInformationCell>
                                ) : (
                                    <Box></Box>
                                )}
                                {!isEmptyOrWhitespace(c.fullAddress) ? (
                                    <GroupItemInformationCell flex="40">
                                        <Box display="flex" alignItems="center" width="20px" marginBottom="2px">
                                            <AddressIcon width="10px" />
                                        </Box>
                                        <Box>{viewModel.getCustomerContactAddress(c)}</Box>
                                    </GroupItemInformationCell>
                                ) : (
                                    <Box></Box>
                                )}
                            </Box>
                        );
                    })}
                </GroupItemInformationSubContainer>

                <Show if={viewModel.canRemoveCompletedClient}>
                    <Box display="flex" justifyContent="center" alignItems="top" marginTop="2px" marginRight="10px">
                        <ThemedTransparentIconButton icon={<NoteEditIcon />} command={viewModel.removedCompletedClientCommand} />
                    </Box>
                </Show>
            </GroupItemInformationContainer>
        );
    };

    return (
        <React.Fragment>
            <ThemedText fontStyle={"h3"} marginBottom={"15px"} marginTop={"15px"}>
                CLIENT
            </ThemedText>
            <Show if={viewModel.canDisplayCompletedClient}>
                {renderCompletedClient()}
                <Else>
                    <FormContainerRow dc={"75%"}>
                        <AutoGrid m={"0"} columnGap={"30px"} dc={"1fr 1fr 1fr"}>
                            <Box display={"flex"} flexDirection={"column"}>
                                <Label styles={labelStyle}>Client Type</Label>
                                <ThemedText fontStyle="h5">{viewModel.customerContactType?.name}</ThemedText>
                            </Box>

                            <Box display={"flex"} flexDirection={"column"}>
                                <Label styles={labelStyle}>Business Name</Label>
                                <ThemedText fontStyle="h5">{viewModel.customer?.businessName}</ThemedText>
                            </Box>

                            <Show if={viewModel.canDisplayCommercialContactTypeOptions}>
                                <ThemedComboBox
                                    command={viewModel.updateCustomerContactCommand}
                                    displayName="Contact*"
                                    labelColor="default"
                                    options={viewModel.customerContactOptions}
                                    placeholder="Please select"
                                    validationMessage={() => viewModel.getError("customerContactId")}
                                    value={() => viewModel.getValue("customerContactId")}
                                />
                                <Else>
                                    <ThemedComboBox
                                        command={viewModel.updateCustomerCommand}
                                        displayName="Contact*"
                                        labelColor="default"
                                        options={viewModel.customerOptions}
                                        placeholder="Please select"
                                        validationMessage={() => viewModel.getError("customerId")}
                                        value={() => viewModel.getValue("customerId")}
                                    />
                                </Else>
                            </Show>
                        </AutoGrid>
                    </FormContainerRow>
                </Else>
            </Show>

            <FormContainerRow dc={"100%"}>
                <AutoGrid m={"0"} columnGap={"30px"} dc={"auto 1fr"}>
                    <Box>
                        <ThemedText styles={CheckBoxGroupLabelStyles}>SITE ADDRESS</ThemedText>
                        <ThemedCheckBox
                            paletteColor="secondary"
                            command={viewModel.updateSameAsCorrespondenceAddressCommand}
                            displayName="Same as correspondence address"
                            styles={QuoteThemeCheckBoxStyle}
                            value={() => viewModel.getValue("sameAsBusinessAddress")}
                        />
                    </Box>
                    <Show if={viewModel.canDisplaySiteAddress}>
                        <FindAddressView viewModel={viewModel.findAddressViewModel} />
                    </Show>
                </AutoGrid>
            </FormContainerRow>
            <Show if={viewModel.canDisplaySiteAddress}>
                <FormContainerRow mt={"30px !important"} p={"21px 30px 30px 30px"} backgroundColor="#FCFCFC" border={"1px solid #DCDCDC"} dc={"100%"}>
                    <AutoGrid m={"0"} columnGap={"30px"} dc={"1fr 1fr 1fr 1fr"}>
                        <ThemedEditText
                            command={viewModel.updateSiteAddress1Command}
                            displayName="Address 1*"
                            placeholder="Address 1"
                            validationMessage={() => viewModel.getError("siteAddress1")}
                            value={() => viewModel.getValue("siteAddress1") ?? ""}
                        />
                        <ThemedEditText
                            command={viewModel.updateSiteAddress2Command}
                            displayName="Address 2"
                            placeholder="Address 2"
                            validationMessage={() => viewModel.getError("siteAddress2")}
                            value={() => viewModel.getValue("siteAddress2") ?? ""}
                        />
                        <ThemedEditText
                            command={viewModel.updateSiteAddress3Command}
                            displayName="Address 3"
                            placeholder="Address 3"
                            validationMessage={() => viewModel.getError("siteAddress3")}
                            value={() => viewModel.getValue("siteAddress3") ?? ""}
                        />
                    </AutoGrid>
                    <AutoGrid m={"0"} columnGap={"30px"} dc={"1fr 1fr 1fr 1fr"}>
                        <ThemedEditText
                            command={viewModel.updateSiteCityCommand}
                            displayName="Town/City*"
                            placeholder="Town/City"
                            validationMessage={() => viewModel.getError("siteCity")}
                            value={() => viewModel.getValue("siteCity") ?? ""}
                        />
                        <ThemedEditText
                            command={viewModel.updateSitePostCodeCommand}
                            displayName="Postcode*"
                            placeholder="Postcode"
                            validationMessage={() => viewModel.getError("sitePostcode")}
                            value={() => viewModel.getValue("sitePostcode") ?? ""}
                        />
                    </AutoGrid>
                </FormContainerRow>
            </Show>
            <FormSeparator />

            {/* Callout */}
            {callout.isCalloutVisble && (
                <ContactCallout data={callout.data} onDismiss={() => setIsCalloutVisible(defaultCalloutState)} isEmail={callout.isEmail} targetId={callout.targetId} />
            )}
        </React.Fragment>
    );
});
