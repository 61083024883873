import { FieldType, isNullOrEmpty, ViewModelBase } from "@shoothill/core";
import { action, computed, makeObservable, observable } from "mobx";
import moment from "moment";

import { APIClient, RelayCommand, RelayCommandAsync } from "Application";
import { StaffModel } from "./StaffModel";
import { StaffItemViewModel } from "./StaffItemViewModel";
import { GETAllStaffEndpoint } from "./GETAllStaffEndpoint";
import { IMyWorkWeekOverviewProps } from "../Details/StaffDetailsViewModel";
import { WorkCalendarViewModel } from "Views/Shared/WorkCalendar/WorkCalendarViewModel";
import { TransitionOrigin } from "../Details/TableSubViews/MyWorkWeekOverview/MyWorkWeekOverview";
import { GETProjectTaskAssignmentsWithRelatedLiteByGivenDatesAndUserIdEndpoint } from "../Endpoints/GETProjectTaskAssignmentsWithRelatedLiteByGivenDatesAndUserIdEndpoint";
import { container } from "tsyringe";
import { LookupStore } from "Stores/Domain";
import {
    CURR_CALENDAR_MONTH,
    CURR_CALENDAR_QUARTER,
    CURR_CALENDAR_WEEK,
    CURR_CALENDAR_YEAR,
    CURR_FINANCIAL_YEAR,
    PREV_CALENDAR_MONTH,
    PREV_CALENDAR_QUARTER,
    PREV_CALENDAR_WEEK,
    PREV_CALENDAR_YEAR,
    PREV_FINANCIAL_YEAR,
} from "Views/Shared/Constants";
import { TaskViewModel } from "./ConfigureSubViews/TaskViewModel";
import { TaskModel } from "./ConfigureSubViews/TaskModel";

export class StaffViewModel extends ViewModelBase<StaffModel> {
    public apiClient = new APIClient();
    public staffItemViewModels = observable<StaffItemViewModel>([]);
    public lookupStore = container.resolve(LookupStore);
    //task viewModel properties
    public taskViewModel: TaskViewModel | null = null;
    //work week calendar properties
    public workCalendarViewModel: WorkCalendarViewModel | null = null;
    public displayVerticalMyWorkWeekOverview: boolean = false;
    public displayHorizontalMyWorkWeekOverview: boolean = false;
    public myWorkWeekOverviewTransitionProps: IMyWorkWeekOverviewProps = { origin: "bottom", height: "80%", width: "100%" };

    constructor() {
        super(new StaffModel());

        makeObservable(this, {
            // Observables
            staffItemViewModels: observable,
            workCalendarViewModel: observable,
            displayVerticalMyWorkWeekOverview: observable,
            displayHorizontalMyWorkWeekOverview: observable,
            myWorkWeekOverviewTransitionProps: observable,
            taskViewModel: observable,

            // Computeds
            canDisplayStaffNavigation: computed,
            canDisplayWorkCalendar: computed,
            myWorkWeekOverviewHeight: computed,
            myWorkWeekOverviewWidth: computed,
            myWorkWeekOverviewOrigin: computed,

            //Actions
            setDisplayVerticalMyWorkWeekOverview: action,
            setDisplayHorizontalMyWorkWeekOverview: action,
            setMyWorkWeekOverviewTransitionProps: action,
            resetTaskViewModel: action,
        });

        const startOfWeek = moment().startOf("week").add(1, "days");
        this.setValue("filterStartDate", startOfWeek.toDate());
        const endOfWeek = moment().endOf("week").add(-1, "days");
        this.setValue("filterEndDate", endOfWeek);
        this.setValue("financialPeriodTypeId", this.lookupStore.financialPeriodTypes.find((type) => type.type == CURR_CALENDAR_WEEK)?.id);

        this.apiClient.sendAsync(new GETAllStaffEndpoint(this));
    }

    // #region Properties

    public get effortItemProgress() {
        return this.totalStaffEffortHoursAllocation / this.totalBusinessHours;
    }

    public get chargeableEffortItemProgress() {
        const retVal = this.totalStaffChargeableEffortHoursAllocation / this.totalChargeableBusinessHours;
        return retVal;
    }

    public get percentProgress() {
        return (100 * 100) / this.totalStaffMaximumPercentageAllocation;
    }

    public canDisplay100PercentProgress = () => {
        return this.totalStaffMaximumPercentageAllocation > 100;
    };

    public get totalStaffEffortPercentageAllocation() {
        let retVal: number = 0;
        this.staffItemViewModels.forEach((vm) => (retVal += vm.model.effortPercentageAllocation));
        retVal = retVal / this.staffItemViewModels.length;
        return parseFloat(retVal.toFixed(2));
    }

    public get totalStaffEffortHoursAllocation() {
        let retVal: number = 0;
        this.staffItemViewModels.forEach((vm) => (retVal += vm.model.effortHoursAllocation));
        return parseFloat(retVal.toFixed(2));
    }

    public get totalStaffChargeableEffortPercentageAllocation() {
        let retVal: number = 0;
        this.staffItemViewModels.forEach((vm) => (retVal += vm.model.chargeableEffortPercentageAllocation));
        retVal = retVal / this.staffItemViewModels.length;
        return parseFloat(retVal.toFixed(2));
    }

    public get totalStaffChargeableEffortHoursAllocation() {
        let retVal: number = 0;
        this.staffItemViewModels.forEach((vm) => (retVal += vm.model.chargeableEffortHoursAllocation));
        return parseFloat(retVal.toFixed(2));
    }

    public get totalBusinessHours() {
        let retVal: number = 0;
        this.staffItemViewModels.forEach((vm) => (retVal += vm.model.businessHours));
        return parseFloat(retVal.toFixed(2));
    }

    public get totalChargeableBusinessHours() {
        let retVal: number = 0;
        this.staffItemViewModels.forEach((vm) => (retVal += vm.model.chargeableBusinessHours));
        return parseFloat(retVal.toFixed(2));
    }

    /**
     * Returns if staff navigation can be displayed.
     */
    public get canDisplayStaffNavigation() {
        return this.staffItemViewModels.length > 0;
    }

    public get maximumPercentageAllocation() {
        const effortPercentageAllocation = this.staffItemViewModels.map((vm) => vm.model.effortPercentageAllocation);
        const chargeableEffortPercentageAllocation = this.staffItemViewModels.map((vm) => vm.model.chargeableEffortPercentageAllocation);
        const allAllocations = [...effortPercentageAllocation, ...chargeableEffortPercentageAllocation];

        const maximum = Math.max(...allAllocations);

        return maximum < 100 ? 100 : maximum;
    }

    public get totalStaffMaximumPercentageAllocation() {
        const effortPercentageAllocation = this.staffItemViewModels.map((vm) => vm.model.effortPercentageAllocation);
        const chargeableEffortPercentageAllocation = this.staffItemViewModels.map((vm) => vm.model.chargeableEffortPercentageAllocation);
        const maximumEffortPercentageAllocation = effortPercentageAllocation.reduce((partialSum, a) => partialSum + a, 0);
        const maximumChargeableEffortPercentageAllocation = chargeableEffortPercentageAllocation.reduce((partialSum, a) => partialSum + a, 0);

        const maximum = Math.max(maximumEffortPercentageAllocation, maximumChargeableEffortPercentageAllocation);

        return maximum < 100 ? 100 : maximum;
    }

    public get financialPeriodTypes() {
        const options = this.lookupStore.getFinancialPeriodTypes.map((item) => {
            return {
                key: item.key,
                text: item.text,
            };
        });

        return [...options, { key: "", text: "Custom" }];
    }

    public get showApplyDateFilterButton() {
        const retVal = isNullOrEmpty(this.model.financialPeriodTypeId);
        return retVal;
    }

    public get canDisplayTotalBillableEffort() {
        return this.model.totalBillableEffort !== null;
    }

    public get totalBillableEffort() {
        return this.model.totalBillableEffort;
    }

    public get canDisplayTotalBilledEffort() {
        return this.model.totalBilledEffort !== null;
    }

    public get canDisplayOverage() {
        return this.canDisplayTotalBilledEffort;
    }

    public get totalBilledEffort() {
        return this.model.totalBilledEffort;
    }

    public get overage() {
        return (this.totalBilledEffort ?? 0) - this.totalChargeableBusinessHours;
    }
    public get overageHoursStatusColour() {
        return this.overage < 0 ? "crimson" : "inherit";
    }

    // #endregion Properties

    // #region Commands

    public getAllStaff = async () => {
        await this.apiClient.sendAsync(new GETAllStaffEndpoint(this));
    };

    public resetServerErrorCommand = new RelayCommand(() => {
        this.apiClient.reset();
    });

    public getAllStaffCommand = new RelayCommand(() => this.getAllStaff());

    public updateFilterStartDateCommand = new RelayCommand((date: Date) => {
        this.setValue("financialPeriodTypeId", "");
        this.updateField("filterStartDate", date);
        this.isFieldValid("filterEndDate");
    });

    public updateFilterEndDateCommand = new RelayCommand((date: Date) => {
        this.setValue("financialPeriodTypeId", "");
        this.updateField("filterEndDate", date);
        this.isFieldValid("filterStartDate");
    });

    public updateFinancialPeriodTypeCommand = new RelayCommand((value: string | null) => {
        //update filter id
        this.model.setValue("financialPeriodTypeId", value);

        //update date filters
        const financialPeriodType = this.lookupStore.financialPeriodTypes.find((type) => type.id == this.model.financialPeriodTypeId)?.type;

        switch (financialPeriodType) {
            case CURR_CALENDAR_WEEK:
                const startOfWeek = moment().startOf("week").add(1, "days");
                this.setValue("filterStartDate", startOfWeek.toDate());
                const endOfWeek = moment().endOf("week").add(-1, "days");
                this.setValue("filterEndDate", endOfWeek);
                break;

            case CURR_CALENDAR_MONTH:
                const startOfMonth = moment().startOf("month");
                this.setValue("filterStartDate", startOfMonth.toDate());
                this.setValue("filterEndDate", new Date());
                break;

            case CURR_CALENDAR_QUARTER:
                const startOfQuarter = moment().startOf("quarter");
                this.setValue("filterStartDate", startOfQuarter.toDate());
                this.setValue("filterEndDate", new Date());
                break;

            case CURR_CALENDAR_YEAR:
                const startOfYear = moment().startOf("year");
                this.setValue("filterStartDate", startOfYear.toDate());
                this.setValue("filterEndDate", new Date());
                break;

            case PREV_CALENDAR_WEEK:
                const startOfPrevWeek = moment().subtract(1, "weeks").startOf("week").add(1, "days");
                const endOfPrevWeek = moment().subtract(1, "weeks").endOf("week").subtract(1, "days");
                this.setValue("filterStartDate", startOfPrevWeek.toDate());
                this.setValue("filterEndDate", endOfPrevWeek.toDate());
                break;

            case PREV_CALENDAR_MONTH:
                const startOfPrevMonth = moment().subtract(1, "months").startOf("month");
                const endOfPrevMonth = moment().subtract(1, "months").endOf("month");
                this.setValue("filterStartDate", startOfPrevMonth.toDate());
                this.setValue("filterEndDate", endOfPrevMonth.toDate());
                break;

            case PREV_CALENDAR_QUARTER:
                const startOfPrevQuarter = moment().subtract(1, "quarters").startOf("quarter");
                const endOfPrevQuarter = moment().subtract(1, "quarters").endOf("quarter");
                this.setValue("filterStartDate", startOfPrevQuarter.toDate());
                this.setValue("filterEndDate", endOfPrevQuarter.toDate());
                break;

            case PREV_CALENDAR_YEAR:
                const startOfPrevYear = moment().subtract(1, "years").startOf("year");
                const endOfPrevYear = moment().subtract(1, "years").endOf("year");
                this.setValue("filterStartDate", startOfPrevYear.toDate());
                this.setValue("filterEndDate", endOfPrevYear.toDate());
                break;

            // start of each financial year is the 1st of April
            // end of each financial year is the 31st of March
            case CURR_FINANCIAL_YEAR:
                let startOfFinancialYear = moment().month(3).startOf("month");

                //compare current date to start of financial year.
                // current date is in current financial year
                if (startOfFinancialYear.toDate().getTime() < new Date().getTime()) {
                    this.setValue("filterStartDate", startOfFinancialYear.toDate());
                    this.setValue("filterEndDate", new Date());
                } else {
                    //current date is previous financial year
                    startOfFinancialYear = moment().subtract(1, "years").month(3).startOf("month");

                    this.setValue("filterStartDate", startOfFinancialYear.toDate());
                    this.setValue("filterEndDate", new Date());
                }

                break;

            case PREV_FINANCIAL_YEAR:
                let startOfPrevFinancialYear = moment().subtract(1, "years").month(3).startOf("month");
                //get the calendar date from last year
                const calendarDate = moment().subtract(1, "years");
                //compare current date to start of financial year.
                // current date is in current financial year
                if (startOfPrevFinancialYear.toDate().getTime() < calendarDate.toDate().getTime()) {
                    this.setValue("filterStartDate", startOfPrevFinancialYear.toDate());
                    this.setValue("filterEndDate", calendarDate.toDate());
                } else {
                    //current date is previous financial year
                    startOfPrevFinancialYear = startOfPrevFinancialYear.subtract(1, "years").month(3).startOf("month");
                    const endOfPrevFinancialYear = calendarDate.month(2).endOf("month");
                    this.setValue("filterStartDate", startOfPrevFinancialYear.toDate());
                    this.setValue("filterEndDate", endOfPrevFinancialYear.toDate());
                }

                break;
            default:
                return;
        }

        this.getAllStaff();
    });

    // #endregion Commands

    // #region Calendar Properties

    public get myWorkWeekOverviewHeight() {
        return this.myWorkWeekOverviewTransitionProps.height;
    }

    public get myWorkWeekOverviewWidth() {
        return this.myWorkWeekOverviewTransitionProps.width;
    }

    public get myWorkWeekOverviewOrigin() {
        return this.myWorkWeekOverviewTransitionProps.origin;
    }

    public get canDisplayWorkCalendar() {
        return this.workCalendarViewModel !== null;
    }

    public get showCalendar() {
        return this.displayHorizontalMyWorkWeekOverview || this.displayVerticalMyWorkWeekOverview;
    }

    public get showStaffWorkWeekCalendar() {
        const financialPeriodType = this.lookupStore.financialPeriodTypes.find((type) => type.id == this.model.financialPeriodTypeId)?.type;
        return financialPeriodType == CURR_CALENDAR_WEEK || financialPeriodType == PREV_CALENDAR_WEEK;
    }

    // #endregion Calendar Properties

    // #region Calendar Commands

    public setDisplayVerticalMyWorkWeekOverview(value: boolean) {
        this.displayVerticalMyWorkWeekOverview = value;
    }

    public setDisplayHorizontalMyWorkWeekOverview(value: boolean) {
        this.displayHorizontalMyWorkWeekOverview = value;
    }

    public setMyWorkWeekOverviewTransitionProps(origin: TransitionOrigin, height: string, width: string) {
        this.myWorkWeekOverviewTransitionProps = { origin, height, width };
    }

    public openMyWorkWeekOverviewCommand = new RelayCommandAsync(async (origin: TransitionOrigin, height: string, width: string, userId: string) => {
        this.workCalendarViewModel = new WorkCalendarViewModel();
        this.workCalendarViewModel.startOfWeek = moment(new Date()).startOf("isoWeek").toDate();

        this.setMyWorkWeekOverviewTransitionProps(origin, height, width);
        this.setValue("workCalendarUserId", userId);

        if (this.model.workCalendarUserId) {
            await this.apiClient.sendAsync(new GETProjectTaskAssignmentsWithRelatedLiteByGivenDatesAndUserIdEndpoint(this));
            if (this.apiClient.IsRequestSuccessful) {
                if (origin == "bottom") {
                    this.setDisplayVerticalMyWorkWeekOverview(!this.displayVerticalMyWorkWeekOverview);
                } else {
                    this.setDisplayHorizontalMyWorkWeekOverview(!this.displayHorizontalMyWorkWeekOverview);
                }
            }
        }
    });

    public closeMyWorkWeekOverviewCommand = new RelayCommand(() => {
        this.workCalendarViewModel = null;
        this.setDisplayVerticalMyWorkWeekOverview(false);
        this.setDisplayHorizontalMyWorkWeekOverview(false);
        this.resetWorkCalendarUserId();
    });

    public resetWorkCalendarUserId() {
        this.setValue("workCalendarUserId", null);
    }

    // #endregion Calendar Commands

    //region helpers
    private async updateField(fieldName: keyof FieldType<StaffModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    //end region helpers

    //task view model
    public get canDisplayTask(): boolean {
        return this.taskViewModel !== null;
    }

    public resetTaskViewModel() {
        this.taskViewModel = null;
    }

    /**
     * Displays a task.
     */
    public displayTaskCommand = new RelayCommand((projectTaskAssignmentId: string) => {
        this.taskViewModel = new TaskViewModel(projectTaskAssignmentId, new TaskModel(), this.submitTaskCommand, this.cancelTaskCommand);
    });

    /**
     * Handles the result of saving a task.
     */
    public submitTaskCommand = new RelayCommandAsync(async () => {
        if (this.model.workCalendarUserId) {
            await this.apiClient.sendAsync(new GETProjectTaskAssignmentsWithRelatedLiteByGivenDatesAndUserIdEndpoint(this));
            if (this.apiClient.IsRequestSuccessful) {
                this.resetTaskViewModel();
            }
        }
    });

    /**
     * Handles cancelling a task.
     */
    public cancelTaskCommand = new RelayCommand(() => {
        this.resetTaskViewModel();
    });
}
