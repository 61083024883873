import { runInAction } from "mobx";

import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { ProjectDetailsResponse } from "./Shared/ProjectDetailsResponse";
import { ProjectDetailsViewModel } from "../ProjectDetailsViewModel";
import { ProjectDetailsModel } from "../ProjectDetailsModel";

class Request {
    id: string | null = null;
}

export class POSTSaveProjectAsInstructedEndpoint extends Endpoint<Request, Response> {
    private readonly viewModel: ProjectDetailsViewModel;

    constructor(viewModel: ProjectDetailsViewModel) {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Projects.SaveProjectAsInstructed);
        this.viewModel = viewModel;
    }

    public async HandleRequestAsync(model: ProjectDetailsModel): Promise<any> {
        const request = new Request();

        request.id = model.id;

        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: ProjectDetailsResponse): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            ProjectDetailsResponse.customerResponsePart(response, this.viewModel);
            ProjectDetailsResponse.notesResponsePart(response, this.viewModel);
            ProjectDetailsResponse.remainingResponsePart(response, this.viewModel);

            // This is to update the header as it has a reference to the project status.
            this.viewModel.parentViewModel.refeshProjectSummaryCommand.execute();
        });
    }
}
