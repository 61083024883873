import { isNullOrUndefined, ViewModelBase } from "@shoothill/core";
import { IObservableArray, makeObservable, observable } from "mobx";
import { nanoid } from "nanoid";

import { BillingNotesModel } from "./BillingNotesModel";
import { BillingNoteViewModel } from "./BillingNoteViewModel";

export class BillingNotesViewModel extends ViewModelBase<BillingNotesModel> {
    private key = nanoid();

    // Sub viewmodels.
    public assignmentUserNoteViewModels: IObservableArray<BillingNoteViewModel> = observable<BillingNoteViewModel>([]);
    public completedUserNoteViewModel: BillingNoteViewModel | null = null;
    public billedUserNoteViewModel: BillingNoteViewModel | null = null;

    constructor(model: BillingNotesModel) {
        super(model);

        makeObservable(this, {
            // Observables

            completedUserNoteViewModel: observable,
            billedUserNoteViewModel: observable,
        });
    }

    // #region Properties

    public get KEY() {
        return this.key;
    }

    public get canDisplayAssignmentNote() {
        return this.assignmentUserNoteViewModels.length > 0;
    }

    public get canDisplayCompletedNote() {
        return !isNullOrUndefined(this.completedUserNoteViewModel);
    }

    public get canDisplayBilledNote() {
        return !isNullOrUndefined(this.billedUserNoteViewModel);
    }

    // #endregion Properties
}
