import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { ServiceGroupViewModel } from "../ServiceGroup/ServiceGroupViewModel";
import { runInAction } from "mobx";
import { ServiceModel } from "../Service/ServiceModel";

class Request {
    public id: string = "";
}

class ServiceResponse {
    public id: string = "";
    public serviceGroupId: string = "";
    public name: string = "";
    public ordinal: number = 0;
    public manHours: number = 0;
    public hourlyRate: number = 0;
    public updatedDate: Date | null = null;
    public updatedById: string | null = null;
}

class Response {
    public services: ServiceResponse[] = [];
}

export class PostDeleteServiceByIdEndpoint extends Endpoint<Request, Response> {
    private viewModel: ServiceGroupViewModel;

    constructor(viewModel: ServiceGroupViewModel) {
        super();
        this.viewModel = viewModel;
        this.verb(Http.Post);
        this.path(AppUrls.Server.Admin.Services.DeleteServiceById);
    }

    HandleRequestAsync(id: string): Promise<Request> {
        let request: Request = new Request();
        request.id = id;
        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        runInAction(() => {
            //create serviceViewModel array
            const serviceModels: ServiceModel[] = [];
            response.services.forEach((item) => {
                //create serviceGroupModel
                const serviceModel = new ServiceModel();
                //populate model
                serviceModel.toModel(item);
                serviceModels.push(serviceModel);
            });
            //replace services array
            this.viewModel.model.serviceModels.replace(serviceModels);
        });
    }
}
