//libraries
import { ViewModelBase } from "@shoothill/core";
import { computed, makeObservable } from "mobx";
import { APIClient, RelayCommand, formatCurrency, formatFixedDecimalNumber } from "Application";

//app
import { InvoiceHistoryModel } from "./InvoiceHistoryModel";
import { GETAdminInvoicingForecastOverridesByFinancialYearEndpoint } from "../Endpoints/GETAdminInvoicingForecastOverridesByFinancialYearEndpoint";
import { container } from "tsyringe";
import { LookupStore } from "Stores/Domain";
import { InvoiceHistoryCapacityModel } from "./InvoiceHistoryCapacity/InvoiceHistoryCapacityModel";
// import { GetAdminInvoicingHistoryEndpoint } from "./Endpoints/GETAdminInvoicingHistoryEndpoint";

export class InvoiceHistoryViewModel extends ViewModelBase<InvoiceHistoryModel> {
    public apiClient = new APIClient();
    private lookupStore = container.resolve(LookupStore);

    constructor(model = new InvoiceHistoryModel()) {
        super(model, false);

        makeObservable(this, {
            financialYears: computed,
        });
    }

    // only return the financial years from the past
    public get financialYears() {
        //get current year
        const currentYear = new Date().getFullYear();
        const options = this.lookupStore.getFinancialYears.filter((year) => Number(year.key) < currentYear);
        return options;
    }

    public formattedHours(override: InvoiceHistoryCapacityModel) {
        if (override.invoiceForecastCapacityOverride == null) return "N/A";
        if (!override.invoiceForecastCapacityOverride.isActive) return "N/A";
        return override.invoiceForecastCapacityOverride.hours ? formatFixedDecimalNumber(Number(override.invoiceForecastCapacityOverride.hours)) : "N/A";
    }

    public formattedValue(override: InvoiceHistoryCapacityModel) {
        if (override.invoiceForecastCapacityOverride == null) return "N/A";
        if (!override.invoiceForecastCapacityOverride.isActive) return "N/A";
        return override.invoiceForecastCapacityOverride.value ? formatCurrency(Number(override.invoiceForecastCapacityOverride.value)) : "N/A";
    }

    public get formattedTotalHours() {
        const retVal = this.model.capacities.reduce((total, capacity) => {
            if (capacity.invoiceForecastCapacityOverride == null) return total;
            if (!capacity.invoiceForecastCapacityOverride.isActive) return total;
            return total + Number(capacity.invoiceForecastCapacityOverride.hours);
        }, 0);
        return formatFixedDecimalNumber(retVal);
    }

    public get formattedTotalValue() {
        const retVal = this.model.capacities.reduce((total, capacity) => {
            if (capacity.invoiceForecastCapacityOverride == null) return total;
            if (!capacity.invoiceForecastCapacityOverride.isActive) return total;
            return total + Number(capacity.invoiceForecastCapacityOverride.value);
        }, 0);
        return formatCurrency(retVal);
    }

    //endregion properties

    //region endpoint calls

    //endregion endpoint calls

    //region commands

    //endregion commands
}
