import { FieldType, isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

import { APIClient } from "Application";
import { RelayCommand } from "Application/Commands";
import { AppUrls } from "AppUrls";
import { LetterTemplatesModel, LetterTemplatesModelValidator } from "./LetterTemplatesModel";
import { POSTSaveLetterTemplatesEndpoint } from "./POSTSaveLetterTemplatesEndpoint";
import { GETLetterTemplatesByIdEndpoint } from "./GETLetterTemplatesByIdEndpoint";
import { DeleteLetterTemplatesEndpoint } from "./DeleteTemplatesEndpoint";

export class LetterTemplatesViewModel extends ViewModelBase<LetterTemplatesModel> {
    public apiClient = new APIClient();
    public deletePopup: boolean = false;

    constructor(id: string | undefined) {
        super(new LetterTemplatesModel());
        this.setValidator(new LetterTemplatesModelValidator());

        makeObservable(this, { deletePopup: observable });

        if (id) {
            this.apiClient.sendAsync(new GETLetterTemplatesByIdEndpoint(id!, this.model));
        }
    }

    // #region Properties

    public get displayName() {
        return isEmptyOrWhitespace(this.model.id) ? "New template" : "Edit template";
    }

    public get updatedText() {
        return isEmptyOrWhitespace(this.model.id) ? "Create" : "Update";
    }

    public get setDelete(): boolean {
        return isEmptyOrWhitespace(this.model.id);
    }

    public get canDisplayDelete(): boolean {
        return this.deletePopup;
    }

    // #endregion Properties

    // #region Commands

    public deleteModalOpenCommand = new RelayCommand(
        () => {
            this.deletePopup = true;
        },
        () => {
            // If the API is busy, we don't want to try and display
            // a modal to carry out a delete operation.
            return !this.apiClient.IsBusy;
        },
    );

    public closeDeleteModalCommand = new RelayCommand(() => {
        this.deletePopup = false;
    });

    public updateLetterTitleCommand = new RelayCommand((value: string) => {
        this.updateField("name", value);
    });

    public updateLetterText = (value: string): void => {
        this.updateField("body", value);
    };

    public navigateToListCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.LetterTemplates.Table);
    });

    public createLetterTemplatesCommand = new RelayCommand(
        () => {
            if (this.canSubmitForm) {
                this.apiClient.sendAsync(new POSTSaveLetterTemplatesEndpoint(this), this.model);
            }
        },
        () => {
            return !this.apiClient.IsBusy;
        },
    );

    public deleteLetterTemplatesCommand = new RelayCommand(
        () => {
            this.apiClient.sendAsync(new DeleteLetterTemplatesEndpoint(this.model.id!, this));
        },
        () => {
            return !this.apiClient.IsBusy;
        },
    );

    public resetServerErrorCommand = new RelayCommand(() => {
        this.apiClient.reset();
    });

    // #endregion Commands

    // #region Supporting

    private async updateField(fieldName: keyof FieldType<LetterTemplatesModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    public get canSubmitForm(): boolean {
        return this.isModelValid();
    }

    // #endregion Supporting
}
