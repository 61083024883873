import { endOfDay, Endpoint, Http, startOfDay } from "Application";
import { AppUrls } from "AppUrls";
import { PlanningApplicationTableViewModel } from "../PlanningApplicationTableViewModel";
import { PLANNING_APPLICATION_STATUS_APPROVED, PLANNING_APPLICATION_STATUS_LIVE, PLANNING_APPLICATION_STATUS_REJECTED } from "Views/Shared/Constants";

class Request {
    public filterKeyword: string | null = null;
    public filterLocalAuthorityDistrictId: string | null = null;
    public filterPlanningApplicationTypeId: string | null = null;
    public filterProjectLeadId: string | null = null;
    public filterDateFrom: Date | null = null;
    public filterDateTo: Date | null = null;
    public filterStatusType: typeof PLANNING_APPLICATION_STATUS_LIVE | typeof PLANNING_APPLICATION_STATUS_APPROVED | typeof PLANNING_APPLICATION_STATUS_REJECTED =
        PLANNING_APPLICATION_STATUS_LIVE;
}

export class GetFilteredPlanningApplicationsCSVEndpoint extends Endpoint<any, any> {
    private viewModel: PlanningApplicationTableViewModel;

    constructor(viewModel: PlanningApplicationTableViewModel) {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.PlanningApplication.GetFilteredPlanningApplicationsCSV);
        this.responseIsBlob(true);
        this.viewModel = viewModel;
    }

    public async HandleRequestAsync(): Promise<any> {
        const request = new Request();

        request.filterKeyword = this.viewModel.model.filterKeyword.length > 0 ? this.viewModel.model.filterKeyword : null;
        request.filterLocalAuthorityDistrictId = this.viewModel.model.filterLocalAuthorityDistrictId;
        request.filterProjectLeadId = this.viewModel.model.filterProjectLeadId;
        request.filterDateFrom = this.viewModel.model.filterDateFrom ? (startOfDay(this.viewModel.model.filterDateFrom) as Date) : null;
        request.filterDateTo = this.viewModel.model.filterDateTo ? (endOfDay(this.viewModel.model.filterDateTo) as Date) : null;
        request.filterLocalAuthorityDistrictId = this.viewModel.model.filterLocalAuthorityDistrictId;
        request.filterPlanningApplicationTypeId = this.viewModel.model.filterPlanningApplicationTypeId;

        return await Promise.resolve(request);
    }

    public async HandleResponseAsync(response: any): Promise<any> {
        const contentDisposition = response.headers["content-disposition"];
        const filename = contentDisposition.split("filename=")[1];

        // Create a link element for the file and use the filename provided.
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(new Blob([response]));
        link.setAttribute("download", filename);

        document.body.appendChild(link);

        // Download.
        link.click();

        // Having clicked the link, delete the element otherwise it will
        // remain attached to the document.
        document.body.removeChild(link);

        return Promise.resolve();
    }
}
