import styled from "@emotion/styled";
import { ThemedText, theme, formatCurrency } from "Application";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { OverviewViewModel } from "../OverviewViewModel";
import { ListItemContainer, ListItemDetails, ListItemLink, ListItemPositionDot, ListItemPositionLabel, ListItemsContainer } from "../Shared/Shared.styles";

interface IProps {
    viewModel: OverviewViewModel;
}

const Container = styled.div`
    grid-area: quotesList;
    background: white;
    width: 100%;
    height: 100%;

    padding: 10px 25px;
`;

export const QuotesListView: React.FC<IProps> = observer((props: IProps) => {
    const { viewModel } = props;

    const renderTopQuoteItems = () => {
        const retVal: JSX.Element[] = [];
        viewModel.topQuotesByValue.forEach((q, index) => {
            retVal.push(
                <ListItemContainer>
                    <ListItemPositionDot>
                        <ListItemPositionLabel>{index + 1}</ListItemPositionLabel>
                    </ListItemPositionDot>
                    <ListItemDetails>
                        <ListItemLink to={viewModel.customerDetailURL(q)}>
                            <ThemedText fontStyle={"h7"}>{q.customerBusinessName}</ThemedText>
                        </ListItemLink>
                        <ThemedText fontStyle={"h7"}>{q.title}</ThemedText>
                        <ThemedText fontStyle={"h7"}>{formatCurrency(q.quoteValue!)}</ThemedText>
                    </ListItemDetails>
                </ListItemContainer>,
            );
        });
        return retVal;
    };

    return (
        <Container>
            <ThemedText fontStyle="h8" marginBottom={"10px"}>
                Top {viewModel.topQuotesByValue.length} quotes by value
            </ThemedText>
            <ListItemsContainer>{renderTopQuoteItems()}</ListItemsContainer>
        </Container>
    );
});
