import { observer } from "mobx-react-lite";
import { CurrentQuoteViewModel } from "./CurrentQuoteViewModel";
import { Show } from "Application";

import { QuoteReadOnlyView } from "Views/Shared/Quote/QuoteReadOnlyView";
import { CustomerReadOnlyView } from "Views/Shared/Customer/CustomerReadOnlyView";
import { LetterReadOnlyView } from "Views/Shared/Letter/LetterReadOnlyView";
import { ServicesReadOnlyView } from "Views/Shared/Services/ServiceSubViews/ServicesReadOnlyView";
import { TermsAndConditionsViewReadOnlyView } from "Views/Shared/TermsAndConditions/TermsAndConditionsViewReadOnlyView";
import { NotesReadOnlyView } from "Views/Shared/Note/NotesReadOnlyView";
import { FormContainer, FormSubContainer } from "Views/Shared/SharedComponents";

export interface ICurrentQuote {
    viewModel: CurrentQuoteViewModel;
}

export const CurrentQuoteView: React.FC<ICurrentQuote> = observer((props) => {
    const viewModel = props.viewModel;

    return (
        <FormContainer>
            <FormSubContainer>
                <QuoteReadOnlyView viewModel={viewModel.quoteViewModel} />
                <CustomerReadOnlyView viewModel={viewModel.customerViewModel} />
                <LetterReadOnlyView viewModel={viewModel.letterViewModel} />
                <ServicesReadOnlyView viewModel={viewModel.servicesViewModel} />
                <TermsAndConditionsViewReadOnlyView viewModel={viewModel.termsAndConditionsViewModel} />
                <Show if={props.viewModel.canDisplayNotes}>
                    <NotesReadOnlyView viewModel={viewModel.notesViewModel} />
                </Show>
            </FormSubContainer>
        </FormContainer>
    );
});
