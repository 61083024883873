import { ModelBase } from "@shoothill/core";
import { Validator } from "Application/Validation";
import { makeObservable, observable } from "mobx";

export class InvoiceForecastMonthCapacityOverrideModel extends ModelBase<InvoiceForecastMonthCapacityOverrideModel> {
    public id: string | null = null;
    public year: number | null = null;
    public month: number | null = null;
    public hours: string = "";
    public value: string = "";
    public isActive: boolean = false;

    constructor() {
        super();
        makeObservable(this, {
            hours: observable,
            value: observable,
            isActive: observable,
        });
    }
}

export class InvoiceForecastMonthCapacityOverrideModelValidator extends Validator<InvoiceForecastMonthCapacityOverrideModel> {
    constructor() {
        super();
        this.ruleFor("hours").must({
            predicate: (hours, _) => {
                const parsedValue = parseFloat(hours);
                return !Number.isNaN(parsedValue);
            },
            message: () => "Values must be numeric",
        });
        this.ruleFor("value").must({
            predicate: (value, _) => {
                const parsedValue = parseFloat(value);
                return !Number.isNaN(parsedValue);
            },
            message: () => "Values must be numeric",
        });
    }
}
