import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

export class TimesheetModel extends ModelBase<TimesheetModel> {
    // Filtering
    public filterKeyword: string = "";
    public filterStartDate: Date | undefined = undefined;
    public filterEndDate: Date | undefined = undefined;
    public financialPeriodTypeId: string | null = null;
    public filterProjectId: string | null = null;
    public filterProjectTaskGroupName: string | null = null;
    public filterProjectLeadId: string | null = null;

    // Sorting
    public sortKey = "billedDate"; // A property defined in the table column definitions.
    public sortDescending = true;

    // Other
    public totalBillableEffort: number | null = null;
    public totalBilledEffort: number | null = null;

    constructor() {
        super();

        makeObservable(this, {
            // Observables
            filterKeyword: observable,
            filterStartDate: observable,
            filterEndDate: observable,
            financialPeriodTypeId: observable,
            filterProjectId: observable,
            filterProjectTaskGroupName: observable,
            filterProjectLeadId: observable,

            sortKey: observable,
            sortDescending: observable,

            totalBillableEffort: observable,
            totalBilledEffort: observable,
        });
    }
}
