import { KeyValuePair, ModelBase, isEmptyOrWhitespace } from "@shoothill/core";
import { Validator } from "Application/Validation";
import { makeObservable, observable } from "mobx";

export class SubmitWithNoteModel extends ModelBase<SubmitWithNoteModel> {
    public notes: string = "";
    public documents = observable<File>([]);
    public hasNoteSubTypeOptions: boolean = false;
    public noteSubTypeId: string | null = null;
    constructor() {
        super();
        makeObservable(this, {
            // Observables
            notes: observable,
            documents: observable,
            hasNoteSubTypeOptions: observable,

            noteSubTypeId: observable,
        });
    }
}

export class SubmitWithNoteModelValidator extends Validator<SubmitWithNoteModel> {
    constructor() {
        super();
        // APM - I have commented this out as it may be the case that a note
        // may be saved with an actual note, but may have documents.
        //this.ruleFor("notes").notEmpty().withMessage("Please provide a note");

        // this.ruleFor("siteCity").must({
        //     predicate: (siteCity, model) => (model.sameAsBusinessAddress ? true : !isEmptyOrWhitespace(siteCity)),
        //     message: (value, model) => "Please provide a town or city",
        // });

        this.ruleFor("noteSubTypeId").must({
            predicate: (noteSubTypeId, model) => (!model.hasNoteSubTypeOptions ? true : !isEmptyOrWhitespace(noteSubTypeId)),
            message: (value, model) => "Please select a reason",
        });
    }
}
