import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { ServiceGroupModel } from "../ServiceGroup/ServiceGroupModel";
import { ServiceGroupsViewModel } from "../ServiceGroupsViewModel";
import { runInAction } from "mobx";
import { SyncServiceGroupsResponse } from "./SharedResponseModels";

class Request {
    public id: string = "";
}

export class PostDeleteServiceGroupByIdEndpoint extends Endpoint<Request, SyncServiceGroupsResponse> {
    private viewModel: ServiceGroupsViewModel;

    constructor(viewModel: ServiceGroupsViewModel) {
        super();
        this.viewModel = viewModel;
        this.verb(Http.Post);
        this.path(AppUrls.Server.Admin.Services.DeleteServiceGroupById);
    }

    HandleRequestAsync(id: string): Promise<Request> {
        let request: Request = new Request();
        request.id = id;
        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: SyncServiceGroupsResponse): Promise<any> {
        runInAction(() => {
            this.viewModel.syncModels(response);
        });
    }
}
