import React from "react";
import styled from "@emotion/styled";
import { Box, ThemedText, getThemeColorOption, theme, themeColourOptions, themeFontStyleOptions } from "Application";
import { observer } from "mobx-react-lite";

interface IProps {
    /**
     * The text variant.
     */
    fontStyle?: themeFontStyleOptions;
    /**
     * The color of the spinner
     */
    color?: themeColourOptions;
    /**
     * The background color of the spinner
     */
    backgroundColor?: themeColourOptions;
    /**
     * The text to display.
     */
    displayMessage?: string;
    /**
     * The boolean value to determine whether to display the message.
     * Default value is set to true
     */
    showDisplayMessage?: boolean;
    /**
     * The width of the spinner
     */
    width?: string;
    /**
     * The height of the spinner
     */
    height?: string;
    /**
     * The width of the spinner border
     */
    borderWidth?: string;
}

interface ILoadingSpinner {
    /**
     * The color of the spinner
     */
    color: string;
    /**
     * The background color of the spinner
     */
    backgroundColor?: string;
    /**
     * The width of the spinner
     */
    width?: string;
    /**
     * The height of the spinner
     */
    height?: string;
    /**
     * The width of the spinner border
     */
    borderWidth?: string;
}

const LoadingSpinner = styled.div<ILoadingSpinner>`
    border-width: ${(props) => props.borderWidth ?? "12px"};
    border-style: solid;
    border-color: ${(props) => props.backgroundColor ?? theme.palette.common.lighterGray};
    border-top-width: ${(props) => props.borderWidth ?? "12px"};
    border-top-style: solid;
    border-top-color: ${(props) => props.color};
    border-radius: 50%;
    width: ${(props) => props.width ?? "100px"};
    height: ${(props) => props.height ?? "100px"};

    animation: spin 2s linear infinite;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

export const ThemedLoadingSpinner = observer((props: IProps) => {
    const color = getThemeColorOption(props.color);
    const displayMessage = props.displayMessage ?? "Loading ...";
    const showDisplayMessage = props.showDisplayMessage ?? true;
    return (
        <React.Fragment>
            <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                <LoadingSpinner color={color} backgroundColor={props.backgroundColor} width={props.width} height={props.height} borderWidth={props.borderWidth} />
                {showDisplayMessage && (
                    <ThemedText fontStyle={props.fontStyle} marginTop={"15px"}>
                        {displayMessage}
                    </ThemedText>
                )}
            </Box>
        </React.Fragment>
    );
});
