import { ViewModelBase } from "@shoothill/core";
import { computed, makeObservable } from "mobx";

import { APIClient, endOfDay, RelayCommand, startOfDay } from "Application";
import { ProjectTaskGroupChartModel } from "./ProjectTaskGroupChartModel";
import { POSTSaveProjectTaskGroupStartAndEndDateEndpoint } from "../../Endpoints/POSTSaveProjectTaskGroupStartAndEndDates";

export class ProjectTaskGroupChartViewModel extends ViewModelBase<ProjectTaskGroupChartModel> {
    public apiClient = new APIClient();

    constructor(projectTaskGroupModel: ProjectTaskGroupChartModel) {
        super(projectTaskGroupModel);

        makeObservable(this, {
            id: computed,
            originQuoteId: computed,
            name: computed,
            startDate: computed,
            endDate: computed,
        });
    }

    // #region Properties

    public get id() {
        return this.model.id;
    }

    public get originQuoteId() {
        return this.model.originQuoteId;
    }

    public get name() {
        return this.model.name;
    }

    public get startDate() {
        return this.model.startDate;
    }

    public get endDate() {
        return this.model.endDate;
    }

    // #endregion Properties

    // #region Commands

    public dateChangeCommand = new RelayCommand((startDate: Date, endDate: Date) => {
        // We need to establish if this is a drag change. A drag change will see both start
        // and end dates change.
        const isDragChange = startDate.getTime() !== this.model.startDate?.getTime() && endDate.getTime() !== this.model.endDate?.getTime();

        if (isDragChange) {
            // Handling a drag is a bit different. We want to snap the start date
            // and maintain the duration to the end date.
            const milliseconds = this.model.endDate!.getTime() - this.model.startDate!.getTime();

            this.model.startDate = startOfDay(startDate)!;
            this.model.endDate = new Date(this.model.startDate.getTime() + milliseconds);
        } else {
            this.model.startDate = startOfDay(startDate)!;
            this.model.endDate = endOfDay(endDate)!;
        }

        // Send the new start and end times to the API.
        this.apiClient.sendAsync(new POSTSaveProjectTaskGroupStartAndEndDateEndpoint(), this.model);
    });

    // #endregion Commands
}
