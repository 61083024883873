import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import React from "react";

import { Box } from "Application";
import { BankHolidayEventViewModel } from "./BankHolidayEventViewModel";
import { darken } from "@mui/material";

interface IProps {
    viewModel: BankHolidayEventViewModel;
}

const EventCell = styled(Box)`
    background-color: #dff6dd;
    border-left: 5px solid ${darken("#dff6dd", 0.25)};
    flex: 1;
`;

const EventDisplayName = styled(Box)`
    font-size: 10px;
    font-weight: bold;
    padding: 7px 12px 7px 7px;
`;

export const BankHolidayEventView: React.FC<IProps> = observer((props) => {
    const viewModel = props.viewModel;

    return (
        <EventCell>
            <EventDisplayName>{viewModel.displayName}</EventDisplayName>
        </EventCell>
    );
});
