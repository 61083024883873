// Libraries
import React, { useRef, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { EditableCopyViewModel } from "Application/ViewModels/EditableCopyViewModel";
import styled from "@emotion/styled";
import { Box } from "../../../Application";

// Styling & images

export const PrivacyPolicyView: React.FC = () => {
    window.scrollTo(0, 0);
    const { current: viewModel } = useRef(new EditableCopyViewModel());

    const privacy = (
        <>
            <Box center>
                <TermsContainer>
                    <p>
                        <>{ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/privacy-policy", 1))}</>
                    </p>
                    <>{ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/privacy-policy", 2))}</>
                </TermsContainer>
            </Box>
        </>
    );

    return (
        <>
            <div>{privacy}</div>
        </>
    );
};

const TermsContainer = styled(Box)`
    p {
        font-size: 14px;
        margin-bottom: 15px;
        letter-spacing: -0.35px;
        line-height: 24px;
    }

    h1 {
        text-align: left;
        font-size: 28px;
    }

    h2 {
        text-align: left;
        font-size: 24px;
    }
`;
