import { ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";
import { ICommand } from "Application";

export class ConvertToQuoteViewModel extends ViewModelBase<any> {
    public parentCancelConvertToQuoteCommand: ICommand;
    public parentConvertToQuoteCommand: ICommand;

    constructor(parentCancelConvertToQuoteCommand: ICommand, parentConvertToQuoteCommand: ICommand) {
        super({});

        this.parentCancelConvertToQuoteCommand = parentCancelConvertToQuoteCommand;
        this.parentConvertToQuoteCommand = parentConvertToQuoteCommand;

        makeObservable(this, {});
    }

    public get displayMessage() {
        return "Are you sure you want to convert?";
    }
}
