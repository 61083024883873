import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Moment } from "moment";
import { Validator } from "../../../Application/Validation";
import { ServiceGroupModel } from "./ServiceGroup/ServiceGroupModel";

// App
export class ServiceGroupsModel extends ModelBase<ServiceGroupsModel> {
    public id: string = "";
    public serviceGroupModels = observable<ServiceGroupModel>([]);
    public filterKeyword = "";
    constructor() {
        super();
        makeObservable(this, {
            id: observable,
            filterKeyword: observable,
        });
    }
}

export class ServiceGroupsModelValidator extends Validator<ServiceGroupsModel> {
    constructor() {
        super();
        // this.ruleFor("filterKeyword").emailAddress();
    }
}
