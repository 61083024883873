//libraries
import { makeObservable, observable, action, computed } from "mobx";
import moment from "moment";
//app
import { APIClient, RelayCommand } from "Application";
import { isEmptyOrWhitespace, isNullOrEmpty, ViewModelBase } from "@shoothill/core";

import { GETAbsenteesByGivenDatesEndpoint } from "../../Endpoints/GETAbsenteesByGivenDatesEndpoint";
import { container } from "tsyringe";
import { LookupStore } from "Stores/Domain";
import { AbsenteeModel, AbsenteesModel } from "./AbsenteesModel";

export class AbsenteesViewModel extends ViewModelBase<AbsenteesModel> {
    public isAbsenteesListExpanded: boolean = false;
    public lookupStore = container.resolve(LookupStore);
    public apiClient = new APIClient();
    public render: boolean = false;
    public disableExpandButton: boolean = true;
    public absenteeListHeight: number = 0;

    constructor(model = new AbsenteesModel()) {
        super(model, false);
        this.getWeeklyAbsentees();
        makeObservable(this, {
            //observables
            isAbsenteesListExpanded: observable,
            render: observable,
            disableExpandButton: observable,
            absenteeListHeight: observable,

            //actions
            setIsAbsenteesListExpanded: action,
            setRender: action,
            setExpandButtonDisableState: action,
            setAbsenteeListHeight: action,
            //computed values
            absentees: computed,
            isExpandButtonDisabled: computed,
        });
    }

    public setRender = (value: boolean) => (this.render = value);

    public getWeeklyAbsentees = async () => {
        this.setRender(false);
        await this.apiClient.sendAsync(new GETAbsenteesByGivenDatesEndpoint(this)).then(() => {
            this.setRender(true);
            if (this.model.absenteeModels.length < 2) {
                this.setExpandButtonDisableState(true);
            } else {
                this.setExpandButtonDisableState(false);
            }
        });
    };

    public get absentees() {
        return this.model.absenteeModels.slice().sort((a, b) => a.leaveRequestNumberOfDays! - b.leaveRequestNumberOfDays!);
    }

    public setIsAbsenteesListExpanded = (value: boolean) => {
        this.isAbsenteesListExpanded = value;
    };

    public setExpandButtonDisableState = (value: boolean) => {
        this.disableExpandButton = value;
    };

    public setAbsenteeListHeight = (value: number) => (this.absenteeListHeight = value);

    public get expandAbsenteesList() {
        return this.isAbsenteesListExpanded;
    }

    public get absenteesInnerListClassName() {
        return this.expandAbsenteesList ? "expanded" : "collapsed";
    }

    public get absenteeListDynamicHeight() {
        return this.absenteeListHeight;
    }

    public get isExpandButtonDisabled() {
        return this.disableExpandButton;
    }

    public absenteePersona(model: AbsenteeModel) {
        return isNullOrEmpty(model.userDetailsPersonaThumbnailPath) ? "" : model.userDetailsPersonaThumbnailPath!;
    }

    public absenteeFullName(model: AbsenteeModel) {
        const firstName = isNullOrEmpty(model.userFirstName) ? "" : model.userFirstName!;
        const lastName = isNullOrEmpty(model.userLastName) ? "" : model.userLastName!;

        return `${firstName} ${lastName}`;
    }

    public absenteeLeavePeriod(model: AbsenteeModel) {
        return moment(model.leaveRequestFromDate).format("DD/MM/YY").toString() + " - " + moment(model.leaveRequestToDate).format("DD/MM/YY").toString();
    }

    public absenteeLeaveDuration(model: AbsenteeModel) {
        return model.leaveRequestNumberOfDays!.toString() + " " + `day${model.leaveRequestNumberOfDays == 1 ? "" : "s"}`;
    }

    public absenteeLeaveType(model: AbsenteeModel) {
        return model.leaveTypeName ? model.leaveTypeName : "";
    }

    //#region commands

    public toggleExpandListCommand = new RelayCommand(
        () => {
            this.setIsAbsenteesListExpanded(!this.isAbsenteesListExpanded);
        },
        () => !this.isExpandButtonDisabled,
    );

    //#endregion commands
}
