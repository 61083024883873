import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import { Nullcommand, setPageTitle, Show, ThemedText } from "Application";
import { ThemedError } from "Styles/ThemedPrimitives/Style1/ThemedError";
import { ThemedLoader } from "Styles/ThemedPrimitives/Style1/ThemedLoader";
import { MODELID_CURRENTQUOTE } from "Views/Shared/Constants";
import { RevisionView } from "Views/Shared/Revisions/RevisionView";
import { NavigationContainer, PageContainer, QuoteStatus, RevisionNavigationContainer, TitleContainer } from "Views/Shared/SharedComponents";
import { QuoteFormView } from "./Form/QuoteFormView";
import { QuoteHistoryView } from "./History/QuoteHistoryView";
import { QuoteContainerViewModel } from "./QuoteContainerViewModel";
import { QuickQuoteWarningModal } from "./Modals/QuickQuoteWarningModal";

export interface IQuoteViewProps {}

export const QuoteContainerView: React.FC<IQuoteViewProps> = observer((props) => {
    // #region Code Behind

    const { id, customerId, isQuickQuote } = useParams();
    const [viewModel] = useState(() => new QuoteContainerViewModel());

    useEffect(() => {
        setPageTitle("Quote");
        viewModel.initialize(id, customerId, isQuickQuote);
        return () => viewModel.dispose();
    }, []);

    // #endregion Code Behind

    const renderActiveWorkspace = () => {
        switch (true) {
            // If the page does not have an active revision selected or the active revision is the current
            // quote, display the quote form view.
            case !viewModel.activeRevisionViewModel || viewModel.activeRevisionViewModel!.model.id === MODELID_CURRENTQUOTE:
                return <QuoteFormView viewModel={viewModel.quoteFormViewModel} />;

            // If the page has an active revision selected that is not the current quote, it must be an
            // older revision, so display the quote history view.
            default:
                return <QuoteHistoryView key={viewModel.activeRevisionViewModel!.KEY} quoteHistoryId={viewModel.activeRevisionViewModel!.model.id} />;
        }
    };

    const renderQuoteStatus = () => {
        return viewModel.canDisplayQuoteStatus ? (
            <QuoteStatus style={{ backgroundColor: viewModel.quoteStatusBackgroundColor }}>{viewModel.quoteStatusDisplayName}</QuoteStatus>
        ) : null;
    };

    const renderBusy = () => <ThemedLoader isOpen={viewModel.apiClient.IsBusy && !viewModel.isAutoSaving} />;

    const renderError = () => {
        const isOpen = viewModel.apiClient.IsSubmitted && !viewModel.apiClient.IsBusy && viewModel.apiClient.HaveValidationMessage;

        return <ThemedError command={viewModel.resetServerErrorCommand} isOpen={isOpen} errorMessage={viewModel.apiClient.ValidationMessage} />;
    };

    return (
        <PageContainer>
            {/* Navigation and Quote Status */}
            <NavigationContainer onClick={() => viewModel.navigateToQuotesCommand.execute()}>
                <ThemedText>{"<"} BACK TO QUOTES</ThemedText>
                {renderQuoteStatus()}
            </NavigationContainer>

            {/* Title */}
            <TitleContainer>
                <ThemedText fontStyle={"h2"}>{viewModel.displayName}</ThemedText>
            </TitleContainer>

            {/* Revision Navigation */}
            {viewModel.canDisplayRevisionNavigation && (
                <RevisionNavigationContainer>
                    {viewModel.revisionViewModels.map((vm) => {
                        return <RevisionView key={vm.KEY} viewModel={vm} />;
                    })}
                </RevisionNavigationContainer>
            )}

            {/* Active Workspace */}
            {renderActiveWorkspace()}

            {/* Busy Overlay */}
            {renderBusy()}

            {/* Error Overlay */}
            {renderError()}

            {/* Region modals */}
            <QuickQuoteWarningModal
                show={viewModel.canShowQuickQuoteWarningModal}
                onConfirm={viewModel.confirmSaveQuoteAsDraftCommand}
                onCancel={viewModel.dismissSaveQuoteAsDraftCommand}
            />
        </PageContainer>
    );
});
