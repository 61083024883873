import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import React from "react";

import { Box } from "Application";
import { NoEventViewModel } from "./NoEventViewModel";

interface IProps {
    viewModel: NoEventViewModel;
}

const EventCell = styled(Box)`
    background-color: white;
    border-left: 5px solid white;
    flex: 1;
`;

export const NoEventView: React.FC<IProps> = observer((props) => {
    return <EventCell />;
});
