import { override, runInAction } from "mobx";
import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";

import { InvoiceForecastViewModel } from "../InvoiceForecastViewModel";
import { InvoiceForecastMonthCapacityModel } from "../Subviews/InvoiceForecastMonthCapacity/InvoiceForecastMonthCapacityModel";
import { InvoiceForecastMonthCapacityViewModel } from "../Subviews/InvoiceForecastMonthCapacity/InvoiceForecastMonthCapacityViewModel";
import { InvoiceForecastMonthCapacityOverrideModel } from "../../Shared/InvoiceForecastMonthCapacityOverride/InvoiceForecastMonthCapacityOverrideModel";
import { InvoiceForecastMonthCapacityOverrideViewModel } from "../../Shared/InvoiceForecastMonthCapacityOverride/InvoiceForecastMonthCapacityOverrideViewModel";

class Response {
    public capacities: InvoiceForecastMonthCapacityResponse[] = [];
}

class InvoiceForecastMonthCapacityResponse {
    public month: Date = new Date();
    public hours: number = 0;
    public value: number = 0;
    public invoiceForecastMonthCapacityOverride: InvoiceForecastMonthCapacityOverrideResponse = new InvoiceForecastMonthCapacityOverrideResponse();
}

class InvoiceForecastMonthCapacityOverrideResponse {
    public id: string | null = "";
    public year: number = 0;
    public month: number = 0;
    public hours: number = 0;
    public value: number = 0;
    public isActive: boolean = false;
}

export class GetAdminInvoicingForecastEndpoint extends EndpointWithoutRequest<Response> {
    private viewModel: InvoiceForecastViewModel;

    constructor(viewModel: InvoiceForecastViewModel) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.Admin.InvoiceForecast.GetInvoiceForecastAndOverrides);
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            const capacityViewModels: InvoiceForecastMonthCapacityViewModel[] = [];

            response.capacities.forEach((capacity) => {
                const overrideModel = new InvoiceForecastMonthCapacityOverrideModel();
                overrideModel.fromResponse(capacity.invoiceForecastMonthCapacityOverride);
                overrideModel.id = capacity.invoiceForecastMonthCapacityOverride.id;
                overrideModel.hours = capacity.invoiceForecastMonthCapacityOverride.hours.toString();
                overrideModel.value = capacity.invoiceForecastMonthCapacityOverride.value.toString();
                overrideModel.isActive = capacity.invoiceForecastMonthCapacityOverride.isActive;
                const overrideViewModel = new InvoiceForecastMonthCapacityOverrideViewModel(overrideModel);

                const model = new InvoiceForecastMonthCapacityModel();
                model.fromResponse(capacity);
                const vm = new InvoiceForecastMonthCapacityViewModel(overrideViewModel, model);

                capacityViewModels.push(vm);
            });

            this.viewModel.capacities.replace(capacityViewModels);
        });

        return Promise.resolve(response);
    }
}
