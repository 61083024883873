import { FieldType, isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { computed, makeObservable } from "mobx";
import { container } from "tsyringe";

import { formatDate, formatTime, ICommand, RelayCommand } from "Application";
import { NoteModel, NoteModelValidator } from "./NoteModel";
import { LookupStore } from "Stores/Domain";
import { FilesViewModel } from "../Files/FilesViewModel";
import { GENERAL_NOTE } from "../Constants";

export class EditNoteViewModel extends ViewModelBase<NoteModel> {
    private lookupStore = container.resolve(LookupStore);
    private parentUpdateNoteCommand: ICommand;
    private parentCancelNoteCommand: ICommand;

    public filesViewModel = new FilesViewModel();

    constructor(noteModel: NoteModel = new NoteModel(), updateNoteCommand: ICommand, cancelNoteCommand: ICommand) {
        super(noteModel);

        this.setValidator(new NoteModelValidator());

        this.parentUpdateNoteCommand = updateNoteCommand;
        this.parentCancelNoteCommand = cancelNoteCommand;

        makeObservable(this, {
            // Observables

            // Computeds
            noteTypes: computed,
        });

        // Default values
        this.setValue("noteTypeId", this.lookupStore.getNoteTypeIdByType(GENERAL_NOTE));
    }

    // #region Properties

    public get KEY() {
        return this.model.KEY;
    }

    public get noteTypes() {
        return this.lookupStore.getExternalNoteTypes();
    }

    public get userDetails() {
        const userName = isEmptyOrWhitespace(this.model.lastUpdatedByUserId)
            ? `${this.model.createdByUserFirstName} ${this.model.createdByUserLastName}`
            : `${this.model.lastUpdatedByUserFirstName} ${this.model.lastUpdatedByUserLastName}`;

        const date = isEmptyOrWhitespace(this.model.lastUpdatedByUserId) ? formatDate(this.model.createdDate!) : formatDate(this.model.lastUpdatedDate!);
        const time = isEmptyOrWhitespace(this.model.lastUpdatedByUserId) ? formatTime(this.model.createdDate!) : formatTime(this.model.lastUpdatedDate!);

        return `${userName} at ${time} on ${date}`;
    }

    // #endregion Properties

    // #region Commands

    public updateNoteTypeCommand = new RelayCommand((noteTypeId: string | null) => {
        this.updateField("noteTypeId", noteTypeId);
    });

    public updateNoteDateCommand = new RelayCommand((date: Date) => {
        this.updateField("noteDate", date);
    });

    public updateNoteTimeCommand = new RelayCommand((time: string) => {
        this.updateField("noteTime", time);
    });

    public updateNoteDetailCommand = new RelayCommand((note: string) => {
        this.updateField("noteDetail", note);
    });

    public updateNoteCommand = new RelayCommand(() => {
        this.parentUpdateNoteCommand.execute();
    });

    public cancelNoteCommand = new RelayCommand(() => {
        this.parentCancelNoteCommand.execute();
    });

    // #endregion Commands

    // #region Supporting

    private updateField(fieldName: keyof FieldType<NoteModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    // #endregion Supporting
}
