import { DetailsRow, IColumn } from "@fluentui/react";
import { isNullOrUndefined } from "@shoothill/core";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import { Box, formatDate, formatDecimalNumber, ThemedText } from "Application";
import { InProgressViewModel } from "./InProgressViewModel";
import { InProgressItemViewModel } from "./InProgressItemViewModel";
import { ThemedDataTableLayer3 } from "Styles/ThemedPrimitives/Style1/ThemedDataTable";

interface IProps {
    viewModel: InProgressViewModel;
}

export const InProgressView = observer((props: IProps) => {
    const viewModel = props.viewModel;

    // #region Code Behind

    /**
     * Handles updating the tables column sort settings in response to changes in the viewmodel.
     */
    useEffect(() => {
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter((currCol) => viewModel.getValue("sortKey") === currCol.key)[0];

        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSorted = true;
                currColumn.isSortedDescending = viewModel.getValue("sortDescending");
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = false;
            }
        });

        setColumns(newColumns);
    }, [viewModel.getValue("sortKey"), viewModel.getValue("sortDescending")]);

    /**
     * Gets a key for the row. Must be provided if sorting or filtering is enabled.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The row index.
     *
     * @returns A string key to identify the row.
     */
    const getRowKey = (item: InProgressItemViewModel, index?: number): string => {
        return item.KEY;
    };

    /**
     * Handles an onClick event for the table column header.
     *
     * @param ev Mouse event
     * @param column The column that has been clicked.
     */
    const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const currColumn: IColumn = columns.filter((currCol) => column.key === currCol.key)[0];
        const newSortKey = currColumn.key;
        const newSortDescending = currColumn.key === viewModel.getValue("sortKey") ? !currColumn.isSortedDescending : false;
        viewModel.updateSortCommand.execute(newSortKey, newSortDescending);
    };

    /**
     * Handles rendering the row. Normally we would not do this. However if
     * trying to display an overlay (alert, panel or modal), on dismissing
     * the overlay, onActiveItemChanged would be called again and redisplay the
     * overlay!
     *
     * @param props The row props of which item is the viewmodel.
     * @returns The table row.
     */
    const onRenderRow = (props: any) => {
        const viewModel = props.item;
        const projectTaskAssignmentStatusForegroundColor = `${viewModel.projectTaskAssignmentStatusForegroundColor}11 !important`;

        return <DetailsRow {...props} onClick={() => onRowClick(viewModel)} styles={{ root: { background: projectTaskAssignmentStatusForegroundColor } }} />;
    };

    /**
     * Handler an onClick event for a table row.
     *
     * @param item The viewmodel data associated with the table row.
     */
    const onRowClick = (item: InProgressItemViewModel) => {
        // Nothing to do at the moment.
    };

    /**
     * Renders the project task assignment status row data.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The index of the table row.
     * @param column The table column.
     *
     * @returns React element.
     */
    const onRenderProjectTaskAssignmentStatus = (item: InProgressItemViewModel, index?: number, column?: IColumn) => {
        return (
            <div style={{ color: `${item.projectTaskAssignmentStatusForegroundColor}`, textTransform: "uppercase", fontWeight: "500" }}>{item.projectTaskAssignmentStatusName}</div>
        );
    };

    /**
     * Renders a date.
     */
    const onRenderDate = (value?: Date) => {
        return <div>{!isNullOrUndefined(value) ? formatDate(value!) : ""}</div>;
    };

    /**
     * Renders a decimal number.
     */
    const onRenderNumber = (value?: number) => {
        return <div>{!isNullOrUndefined(value) ? formatDecimalNumber(value!) : ""}</div>;
    };

    /**
     * Renders a assignees name.
     */
    const onRenderAssignedFullName = (item: InProgressItemViewModel, index?: number, column?: IColumn) => {
        return <div>{item.assignedUserFullName}</div>;
    };

    const defaultTableColumns: IColumn[] = [
        {
            key: "projectTaskAssignmentStatusName",
            fieldName: "projectTaskAssignmentStatusName",
            name: "STATUS",
            minWidth: 80,
            maxWidth: 80,
            isResizable: true,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: onRenderProjectTaskAssignmentStatus,
        },
        {
            key: "projectTaskName",
            fieldName: "projectTaskName",
            name: "TASK",
            minWidth: 246,
            maxWidth: 578,
            isResizable: true,
            isSorted: true,
            onColumnClick: onColumnClick,
        },
        {
            key: "assignedUserFullName",
            fieldName: "assignedUserFullName",
            name: "ASSIGNED TO",
            minWidth: 246,
            maxWidth: 578,
            isResizable: true,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: onRenderAssignedFullName,
        },
        {
            key: "plannedStartDate",
            fieldName: "plannedStartDate",
            name: "START DATE",
            minWidth: 80,
            maxWidth: 80,
            isResizable: true,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: (item) => onRenderDate(item.plannedStartDate),
        },
        {
            key: "plannedEndDate",
            fieldName: "plannedEndDate",
            name: "END DATE",
            minWidth: 80,
            maxWidth: 80,
            isResizable: true,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: (item) => onRenderDate(item.plannedEndDate),
        },
        {
            key: "effort",
            fieldName: "effort",
            name: "MAN HRS",
            minWidth: 80,
            maxWidth: 80,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: (item) => onRenderNumber(item.effort),
        },
        {
            key: "billableEffort",
            fieldName: "billableEffort",
            name: "BILLABLE HRS",
            minWidth: 80,
            maxWidth: 80,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: (item) => onRenderNumber(item.billableEffort),
        },
    ];

    const [columns, setColumns] = useState<IColumn[]>(defaultTableColumns);

    // #endregion Code Behind

    return (
        <Box>
            {/* Title */}
            <Box display="flex">
                <ThemedText fontStyle="expandable" mb={"8px"}>
                    In progress
                </ThemedText>
            </Box>
            {/* Table */}
            <ThemedDataTableLayer3
                styles={{ root: { "&&&&": { marginTop: 0 } } }}
                onRenderRow={onRenderRow}
                getKey={getRowKey}
                items={viewModel.filteredAndSortedTasks}
                columns={columns}
            />
        </Box>
    );
});
