import { FieldType, isNullOrEmpty, ViewModelBase } from "@shoothill/core";
import { computed, makeObservable } from "mobx";
import { nanoid } from "nanoid";
import { container } from "tsyringe";

import { LookupStore } from "Stores/Domain";
import { endOfDay, formatDecimalNumber, RelayCommand, startOfDay } from "Application";
import { TaskModel, TaskModelValidator } from "../TaskModel";
import { PROJECTTASKASSIGNMENTSTATUSTYPE_ASSIGNEDTYPE, PROJECTTASKASSIGNMENTSTATUSTYPE_UPDATEDTYPE } from "Views/Shared/Constants";

export class AssignViewModel extends ViewModelBase<TaskModel> {
    private lookupStore = container.resolve(LookupStore);
    private key = nanoid();

    constructor(model: TaskModel) {
        super(model);

        this.setValidator(new TaskModelValidator());

        makeObservable(this, {
            // Observables
            // Computeds
            canEditForm: computed,
            canDisplayBillableEffort: computed,
        });
    }

    // #region Properties

    public get displayName() {
        return "Assign";
    }

    public get KEY() {
        return this.key;
    }

    /**
     * Remaining billable effort for the assignment. This value is for use in the view and
     * changes depending on:
     *  1. The current billable effort.
     *  2. If the assignment is non-billable.
     *
     * If the parent task is billed hourly, the value should not be displayed.
     */
    public get remainingProjectTaskBillableEffort() {
        let effort = 0;

        if (!this.model.projectTaskIsBilledHourly) {
            effort = this.model.maximumBillableEffort!;

            // This removes the currently billable amount of effort.
            if (!this.model.isNonBillableEffort) {
                effort -= this.model.billableEffort ?? 0;
            }

            // Don't show a negative remaining effort. Once you are out of effort, display zero.
            if (effort < 0) {
                effort = 0;
            }
        }

        return formatDecimalNumber(effort);
    }

    /**
     * The billable effort on the assignments parent task. This value is for use in the view.
     *
     * If the parent task is billed hourly, the value should not be displayed.
     */
    public get projectTaskBillableEffort() {
        return this.model.projectTaskBillableEffort ? formatDecimalNumber(this.model.projectTaskBillableEffort) : "";
    }

    /**
     * Determines if the billable effort or any other properties related to it can be displayed
     * in the view (see remainingProjectTaskBillableEffort and projectTaskBillableEffort).
     */
    public get canDisplayBillableEffort() {
        return !this.model.projectTaskIsBilledHourly;
    }

    public get canEditForm() {
        const status = this.lookupStore.projectTaskAssignmentStatuses.find((pts) => pts.id === this.model.projectTaskAssignmentStatusId);

        return status?.type === PROJECTTASKASSIGNMENTSTATUSTYPE_ASSIGNEDTYPE || status?.type === PROJECTTASKASSIGNMENTSTATUSTYPE_UPDATEDTYPE;
    }

    public get users() {
        return this.model.users
            .filter((u) => u.isAssignable || (!u.isAssignable && u.id == this.model.initialAssigneeId))
            .map((item) => {
                return {
                    key: item.id,
                    text: `${item.firstName} ${item.lastName}`,
                    data: item.thumbnailDocumentUrl,
                };
            })
            .sort((a, b) => a.text.localeCompare(b.text));
    }

    public get assigneeId() {
        return this.model.user ? this.model.user.id : "";
    }

    public get isTaskAssignmentReassigned(): boolean {
        if (!isNullOrEmpty(this.model.initialAssigneeId)) {
            return this.model.initialAssigneeId !== this.assigneeId;
        } else {
            return false;
        }
    }

    // #endregion Properties

    // #region Commands

    public updateEffortCommand = new RelayCommand(
        (value: string) => {
            const parsedValue = parseFloat(value);
            const checkedValue = Number.isNaN(parsedValue) ? null : parsedValue;

            this.updateField("effort", checkedValue);
        },
        () => this.canEditForm,
    );

    public updateBillableEffortCommand = new RelayCommand(
        (value: string) => {
            const parsedValue = parseFloat(value);
            const checkedValue = Number.isNaN(parsedValue) ? null : parsedValue;

            this.updateField("billableEffort", checkedValue);
        },
        () => this.canEditForm,
    );

    public updateIsNonBillableEffortCommand = new RelayCommand(
        () => {
            this.updateField("isNonBillableEffort", !this.model.isNonBillableEffort);
        },
        () => this.canEditForm,
    );

    public updatePlannedStartDateCommand = new RelayCommand(
        (date: Date | undefined) => {
            // Start date should align with midnight utc (if that is not provided by parameter).
            const startDate = startOfDay(date);
            this.updateField("plannedStartDate", startDate);
            const endDate = endOfDay(date);
            this.updateField("plannedEndDate", endDate);
        },
        () => this.canEditForm,
    );

    public updatePlannedEndDateCommand = new RelayCommand(
        (date: Date | undefined) => {
            // Dates should align with midnight utc (if that is not provided by parameter).
            const endDate = endOfDay(date);

            this.updateField("plannedEndDate", endDate);
        },
        () => this.canEditForm,
    );

    public updateUserCommand = new RelayCommand((value: string) => {
        const newAssignee = this.model.users.find((u) => u.id == value);
        this.updateField("user", newAssignee);
    });

    // #endregion Commands

    // #region Supporting

    private updateField(fieldName: keyof FieldType<TaskModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    // #endregion Supporting
}
