import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { runInAction } from "mobx";
import { UserResponse } from "./Shared/UserResponse";
import { UserViewModel } from "./UserViewModel";
import { RoleLiteViewModel } from "./RoleLiteViewModel";

export class GETUserWithRelatedByIdEndpoint extends EndpointWithoutRequest<UserResponse> {
    private readonly viewModel: UserViewModel;

    constructor(id: string, viewModel: UserViewModel) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.Admin.Users.GetUserWithRelatedById.replace(":id", id));
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: UserResponse): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            this.viewModel.model.fromResponse(response);

            this.viewModel.rolesLiteViewModel.replace(
                response.availableRoles.map((role) => {
                    const item = new RoleLiteViewModel();
                    item.model.id = role.id;
                    item.model.name = role.name;
                    item.model.normalizedName = role.normalizedName;
                    return item;
                }),
            );

            this.viewModel.personaFileViewModel.setValue("documentUrl", this.viewModel.model.documentUrl);
            this.viewModel.model.setValue("signature", this.viewModel.model.signature);
        });
    }
}
