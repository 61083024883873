import { IStyle, mergeStyleSets } from "@fluentui/react";
import { isEmptyOrWhitespace } from "@shoothill/core";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";

import { Box, setPageTitle, theme } from "Application";
import { FilesIcon } from "Assets/Icons/FilesIcon";
import { AutoGrid, Show } from "Components";
import { ThemedError } from "Styles/ThemedPrimitives/Style1/ThemedError";
import { ThemedLoader } from "Styles/ThemedPrimitives/Style1/ThemedLoader";
import { PageContainer } from "Views/Shared/SharedComponents";
import { File } from "./File";
import { ImageSettingsViewModel } from "./ImageSettingsViewModel";

interface IFilesStyles {
    root: IStyle;
    fileButton: IStyle;
    fileContainer: IStyle;
}

const filesStyles: IFilesStyles = {
    root: {},
    fileButton: {
        display: "flex",
        button: {
            alignItems: "center",
            backgroundColor: "#F9F9F9",
            border: "1px dashed #DCDCDC",
            color: "#8D8D8D",
            display: "flex",
            flexDirection: "column",
            flex: 1,
            fontFamily: theme.defaultFontStyle.fontFamily,
            fontSize: "10px",
            height: "80px",
            justifyContent: "center",
            letterSpacing: "0.36",
            textTransform: "uppercase",
            ":hover": {
                cursor: "pointer",
            },
            svg: {
                marginBottom: "4px",
            },
        },
    },
    fileContainer: {
        "&&": {
            marginBottom: "0",
        },
    },
};

export const ImageSettingsView: React.FC = observer(() => {
    const [viewModel] = useState(() => new ImageSettingsViewModel());

    useEffect(() => {
        setPageTitle("Image settings");
    }, []);

    const inputReference = useRef<any>(null);

    const displayName = (): string => {
        return isFileSizeLimitExceed() ? viewModel.limitExceededErrorMessage : allowMultipleFiles() ? "CLICK TO ADD AN IMAGE (.JPG .PNG)" : "Click to attach a file";
    };

    const allowMultipleFiles = (): boolean => {
        return viewModel.allowMultipleFiles;
    };

    const fileTypes = (): string => {
        return viewModel.fileTypes;
    };

    const isDisabled = (): boolean => {
        return !viewModel.saveImageCommand.canExecute;
    };

    const isFileSizeLimitExceed = (): boolean => {
        return !isEmptyOrWhitespace(viewModel.limitExceededErrorMessage);
    };

    const onChange = (event: any): void => {
        if (event?.target?.files) {
            const files: Array<File> = Array.from(event.target.files);
            let totalSize = 0;
            for (let i = 0; i < files.length; i++) {
                totalSize += files[i].size;
            }

            const validFiles = files.filter((x: any) => x.type === "image/png" || x.type === "image/jpg" || x.type === "image/jpeg");
            const validFilesSize = validFiles.filter((x: any) => x.size <= 5242880);
            if (validFiles.length !== files.length) {
                alert("Only jpg, png and jpeg files are allowed!");
            } else if (totalSize > 31457280) {
                alert("You have exceeded the maximum allowed size of 30MB");
            } else if (validFilesSize.length !== files.length) {
                alert("You have exceeded the maximum allowed size of 5MB");
            }

            if (validFilesSize.length > 0) {
                viewModel.saveImageCommand.execute(validFilesSize);
            }
        }

        event.target.value = "";
    };

    const onClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        inputReference?.current?.click();
    };

    const dynamicStyles: Partial<IFilesStyles> = {
        fileButton: {
            button: {
                backgroundColor: isFileSizeLimitExceed() ? "#EF9099" : "#F9F9F9",
                color: isFileSizeLimitExceed() ? "#DC3545" : "#8D8D8D",
            },
            svg: {
                ".canFill": {
                    fill: isFileSizeLimitExceed() ? "#DC3545" : "#8D8D8D",
                },
            },
        },
    };

    const styles = mergeStyleSets(filesStyles, dynamicStyles);

    const renderBusy = () => <ThemedLoader isOpen={viewModel.apiClient.IsBusy} />;

    const renderError = () => {
        const isOpen = viewModel.apiClient.IsSubmitted && !viewModel.apiClient.IsBusy && viewModel.apiClient.HaveValidationMessage;
        return <ThemedError command={viewModel.resetServerErrorCommand} isOpen={isOpen} errorMessage={viewModel.apiClient.ValidationMessage} />;
    };

    return (
        <PageContainer>
            <Box padding="30px 0">
                <p style={{ fontWeight: "600", color: "#000", fontSize: "12px" }}>
                    <span style={{ color: "#ff4b22" }}>{viewModel.imageLength}</span> images added. Please add or remove any images you want to be included on the login and
                    background screens (MAX 5MB)
                </p>
            </Box>
            <div className={styles.root}>
                <div className={styles.fileButton}>
                    <input accept={fileTypes()} multiple={allowMultipleFiles()} ref={inputReference} style={{ display: "none" }} type="file" onChange={onChange} />
                    <button disabled={isDisabled()} onClick={onClick} type="button">
                        <FilesIcon />
                        {displayName()}
                    </button>
                </div>
                <Show if={viewModel.fileViewModels.length > 0}>
                    <AutoGrid className={styles.fileContainer} dc={"1fr 1fr 1fr 1fr"} columnGap="30px" rowGap="30px">
                        {viewModel.fileViewModels.map((fileViewModel) => {
                            return <File viewModel={fileViewModel} parentViewModel={viewModel} key={fileViewModel.KEY} />;
                        })}
                    </AutoGrid>
                </Show>
            </div>

            {/* Busy Overlay */}
            {renderBusy()}

            {/* Error Overlay */}
            {renderError()}
        </PageContainer>
    );
});
