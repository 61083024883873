import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { nanoid } from "nanoid";
import { CustomerItemModel } from "Views/Shared/Customer/CustomerItemModel";

export class LostItemModel extends ModelBase<LostItemModel> {
    public KEY: string = nanoid();

    public isEnquiry: boolean = false;
    public contactTypeId: string = "";
    public customerId: string = "";
    public customerContactId: string = "";
    public reference: string = "";
    public title: string = "";
    public hours: number = 0;
    public value: number = 0;
    public confidence: number = 0;
    public desirability: number = 0;
    public contactNumber1: string = "";
    public contactNumber2: string = "";
    public emailAddress: string = "";
    public emailAddress2: string = "";
    public contactTypeName: string = "";
    public customerName: string = "";
    public customerFirstName: string = "";
    public customerLastName: string = "";
    public createdDate: Date = new Date();
    public lastActionDate: Date = new Date();
    public lastActionByUserId: string = "";
    public lastActionFirstName: string = "";
    public lastActionLastName: string = "";
    public dateIssued: Date = new Date();
    public id: string = "";
    public revision: string = "";
    public lostNoteSubTypeId = "";

    public customerItemModel: CustomerItemModel | null = null;

    constructor() {
        super();
        makeObservable(this, {
            id: observable,
            customerItemModel: observable,
        });
    }
}
