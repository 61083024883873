import { ViewModelBase } from "@shoothill/core";
import { computed, makeObservable } from "mobx";
import { SummaryModel } from "./SummaryModel";

export class SummaryViewModel extends ViewModelBase<SummaryModel> {
    constructor() {
        super(new SummaryModel());

        makeObservable(this, {
            reference: computed,
            title: computed,
            taskGroupName: computed,
            taskName: computed,
        });
    }

    // #region Properties

    public get reference() {
        return this.model.reference;
    }

    public get title() {
        return this.model.title;
    }

    public get taskGroupName() {
        return this.model.projectTaskGroupName;
    }

    public get taskName() {
        return this.model.projectTaskName;
    }

    // #endregion Properties
}
