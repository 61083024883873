import { IStyle, mergeStyleSets } from "@fluentui/react";
import { theme } from "Application";

interface INewRequestViewStyles {
    root: IStyle;
    header: IStyle;
    body: IStyle;
    separator: IStyle;
    footer: IStyle;
    iconButton: IStyle;
}

export const styleDefinitions: INewRequestViewStyles = {
    root: {
        // height: "538px",
    },
    header: {
        width: "100%",
        alignItems: "center",
        backgroundColor: "#F3F3F3",
        display: "flex",
        height: "75px",
        justifyContent: "space-between",
        padding: "20px 30px",
    },
    body: {
        padding: "0px 30px 0 30px",
    },
    separator: {
        "&&": {
            lineHeight: "0px",
            margin: "0px 30px",
        },
    },
    footer: {
        display: "flex",
        height: "75px",
        padding: "20px 30px",
    },
    iconButton: {
        g: {
            stroke: theme.palette.defaultTr.text,
        },
    },
};

export const AddEditPlanningApplicationViewStyles = mergeStyleSets(styleDefinitions);
