import React from "react";
import { observer } from "mobx-react-lite";
import { AutoGrid, Box, RelayCommand, ThemedText, ThemedTransparentIconButton, ThemedButton } from "Application";
import { PlanningApplicationViewModel } from "Views/PlanningApplication/PlanningApplicationViewModel";
import { DeletePlanningApplicationViewStyles as styles } from "./DeletePlanningApplicationView.styles";
import { CrossSVG } from "Assets/CrossSVG";
interface IPlanningApplicationDeleteViewProps {
    onDismissCommand: RelayCommand;
    onSubmitCommand: RelayCommand;
    message: string;
}

export const DeletePlanningApplicationView: React.FC<IPlanningApplicationDeleteViewProps> = observer(({ onDismissCommand, onSubmitCommand, message }) => {
    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <ThemedText fontStyle="h2">Delete Planning Application</ThemedText>
                <ThemedTransparentIconButton command={onDismissCommand} icon={<CrossSVG className={styles.iconButton} />} shape="round" />
            </div>
            <div className={styles.body}>
                <ThemedText fontStyle="p">{message}</ThemedText>
                <ThemedText fontStyle="p">Do you wish to continue?</ThemedText>
            </div>
            <div className={styles.footer}>
                <ThemedButton styles={{ root: { padding: "0 5px" } }} paletteColor={"primary"} displayName={"Yes"} command={onSubmitCommand} />
                <ThemedButton styles={{ root: { padding: "0 5px" } }} displayName="Cancel" command={onDismissCommand} />
            </div>
        </div>
    );
});
