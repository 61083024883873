import "../../../index.scss";

import { IColumn, Link } from "@fluentui/react";
import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import { Box, formatDate, setPageTitle, ThemedComboBox, ThemedEditText } from "Application";
import { PhoneIcon } from "Assets/Icons/PhoneIcon";
import { EmailIcon } from "Assets/Icons/EmailIcon";
import { SearchIcon } from "Assets/SearchIcon";
import { ThemedButton } from "Styles/ThemedPrimitives/Style1/ThemedButton";
import { ThemedDataTable } from "Styles/ThemedPrimitives/Style1/ThemedDataTable";
import { SuppliersViewModel } from "./SuppliersViewModel";
import { SuppliersItemViewModel } from "./SuppliersItemViewModel";
import { ContactCallout, ICalloutState } from "../../../Styles/ThemedPrimitives/Style1/ContactCallout";

const controlStyles = {
    root: {
        width: "700px",
    },
    spacing: {
        wordSpacing: "9999px",
    },
};

const controlTableStyles = {
    root: {
        ".ms-DetailsHeader-cellTitle": {
            justifyContent: "center",
        },
        ".ms-DetailsHeader-cellTitle:first-child": {
            justifyContent: "flex-start",
        },
        ".ms-DetailsHeader-cell:nth-last-child(2) .ms-DetailsHeader-cellTitle": {
            justifyContent: "flex-end",
        },
    },
};

const SuppliersView: React.FC = observer(() => {
    const [viewModel] = useState(() => new SuppliersViewModel());

    useEffect(() => {
        setPageTitle("Suppliers");
    }, []);

    /**
     * Handles updating the tables column sort settings in response to changes in the viewmodel.
     */
    useEffect(() => {
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter((currCol) => viewModel.getValue("sortKey") === currCol.key)[0];

        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSorted = true;
                currColumn.isSortedDescending = viewModel.getValue("sortDescending");
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = false;
            }
        });

        setColumns(newColumns);
    }, [viewModel.getValue("sortKey"), viewModel.getValue("sortDescending")]);

    /**
     * Handles an onClick event for the table column header.
     *
     * @param ev Mouse event
     * @param column The column that has been clicked.
     */

    const getRowKey = (item: SuppliersItemViewModel, index?: number): string => {
        return item.KEY;
    };

    const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const currColumn: IColumn = columns.filter((currCol) => column.key === currCol.key)[0];
        const newSortKey = currColumn.key;
        const newSortDescending = currColumn.key === viewModel.getValue("sortKey") ? !currColumn.isSortedDescending : false;

        viewModel.updateSortCommand.execute(newSortKey, newSortDescending);
    };

    /**
     * Renders the phone icon and provides callout support.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The index of the table row.
     * @param column The table column.
     *
     * @returns React rating element.
     */
    const onRenderPhone = (item: SuppliersItemViewModel, index?: number, column?: IColumn) => {
        if (!isEmptyOrWhitespace(item.model.contactNumber1)) {
            return (
                <Link onFocus={(ev) => ev.stopPropagation()}>
                    <PhoneIcon
                        id={`phoneicon-${index}`}
                        onClick={(event: any) => {
                            setIsCalloutVisible({
                                isCalloutVisble: true,
                                isEmail: false,
                                targetId: `phoneicon-${index}`,
                                data: item.model.contactNumber1,
                            });
                        }}
                    />
                </Link>
            );
        }
        return <React.Fragment />;
    };
    /**
     * Renders the email icon and provides callout support.
     *
     * The icon is wrapped in a Link with a stop propagation call so
     * clicking on the icon prevents the table row click.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The index of the table row.
     * @param column The table column.
     *
     * @returns React rating element.
     */
    const onRenderEmail = (item: SuppliersItemViewModel, index?: number, column?: IColumn) => {
        if (!isEmptyOrWhitespace(item.model.emailAddress1)) {
            return (
                <Link onFocus={(ev) => ev.stopPropagation()}>
                    <EmailIcon
                        id={`emailicon-${index}`}
                        onClick={(event: any) => {
                            setIsCalloutVisible({
                                isCalloutVisble: true,
                                isEmail: true,
                                targetId: `emailicon-${index}`,
                                data: item.model.emailAddress1,
                            });
                        }}
                    />
                </Link>
            );
        }
        return <React.Fragment />;
    };

    const onRenderSuppliersName = (item: SuppliersItemViewModel, index?: number, column?: IColumn) => {
        return (
            <div>
                <div style={{ fontWeight: 600, fontSize: "10px", color: "#171716" }}>{item.fullNameWithPrefix}</div>
                <div style={{ fontSize: "10px", color: "#171716" }}>{item.fullAddress}</div>
            </div>
        );
    };

    const defaultTableColumns: IColumn[] = [
        {
            key: "fullName",
            name: "SUPPLIER",
            fieldName: "fullName",
            minWidth: 0,
            isSorted: true,
            isSortedDescending: false,
            sortAscendingAriaLabel: "Sorted A to Z",
            sortDescendingAriaLabel: "Sorted Z to A",
            onColumnClick: onColumnClick,
            isPadded: true,
            onRender: onRenderSuppliersName,
            styles: {
                cellTitle: {
                    border: "none !important",
                },
            },
        },
        {
            className: "contactCell",
            fieldName: "",
            styles: {
                cellTitle: {
                    border: "none !important",
                },
            },
            key: "phoneicon",
            name: "",
            minWidth: 25,
            onRender: onRenderPhone,
        },
        {
            className: "contactCell",
            fieldName: "",
            key: "emailicon",
            name: "",
            minWidth: 25,
            onRender: onRenderEmail,
        },
        {
            key: "supplierTypeName",
            name: "SUPPLIER TYPE",
            isResizable: true,
            minWidth: 120,
            isSorted: true,
            styles: {
                cellTitle: {
                    justifyContent: "center !important",
                },
            },
            onColumnClick: onColumnClick,
            onRender: (item: SuppliersItemViewModel, index?: number, column?: IColumn) => {
                return <div style={{ width: "100%", textAlign: "center" }}>{item.supplierTypeName}</div>;
            },
        },
        {
            key: "supplierSubTypeName",
            name: "SUPPLIER SUBTYPE",
            isResizable: true,
            minWidth: 120,
            isSorted: true,
            styles: {
                cellTitle: {
                    justifyContent: "center !important",
                },
            },
            onColumnClick: onColumnClick,
            onRender: (item: SuppliersItemViewModel, index?: number, column?: IColumn) => {
                return <div style={{ width: "100%", textAlign: "center" }}>{item.supplierSubTypeName}</div>;
            },
        },
        {
            fieldName: "lastActionDate",
            key: "lastActionDate",
            name: "LAST ACTION",
            minWidth: 180,
            isSorted: true,
            onColumnClick: onColumnClick,
            onRender: (item: SuppliersItemViewModel, index?: number, column?: IColumn) => (
                <Box>
                    <Box fontSize={"10px"}>{!isNullOrUndefined(item.lastActionDate) ? formatDate(item.lastActionDate!) : ""}</Box>
                    <Box fontSize={"10px"}>
                        {item.model.lastActionFirstName} {item.model.lastActionLastName}
                    </Box>
                </Box>
            ),
            styles: {
                cellTitle: {
                    border: "none !important",
                    textAlign: "right !important",
                    justifyContent: "end !important",
                },
            },
        },
    ];

    const defaultCalloutState: ICalloutState = { isCalloutVisble: false, targetId: "", isEmail: false, data: "" };

    const [columns, setColumns] = useState<IColumn[]>(defaultTableColumns);
    const [callout, setIsCalloutVisible] = useState<ICalloutState>(defaultCalloutState);

    return (
        <Box className="contactView-block" overflowY="auto">
            {/* Filter Bar */}
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                <Box display={"flex"}>
                    <Box>
                        <ThemedEditText
                            command={viewModel.updateFilterKeywordCommand}
                            placeholder={"Keyword search"}
                            prefix={<SearchIcon />}
                            styles={controlStyles}
                            value={() => viewModel.getValue("filterKeyword")}
                        />
                    </Box>
                    <Box pl={3}>
                        <ThemedComboBox
                            command={viewModel.updateFilterSupplierTypeIdCommand}
                            options={viewModel.supplierTypeOptions}
                            placeholder="All statuses"
                            showLabel={false}
                            size="larger"
                            value={() => viewModel.getValue("filterSupplierTypeId")}
                        />
                    </Box>
                    <Box pl={3}>
                        <ThemedComboBox
                            command={viewModel.updateFilterSupplierSubTypeIdCommand}
                            options={viewModel.supplierSubTypeOptions}
                            placeholder="All statuses"
                            size="larger"
                            showLabel={false}
                            value={() => viewModel.getValue("filterSupplierSubTypeId")}
                        />
                    </Box>
                </Box>
                <ThemedButton command={viewModel.navigateToNewSuppliersFormCommand} displayName="New supplier" paletteColor={"primary"} />
            </Box>
            <ThemedDataTable
                items={viewModel.filteredAndSortedSuppliers}
                columns={columns}
                getKey={getRowKey}
                onActiveItemChanged={(item: SuppliersItemViewModel) => viewModel.navigateToDetailCommand.execute(item.model.id)}
                styles={controlTableStyles}
            />
            {/* Callout */}
            {callout.isCalloutVisble && (
                <ContactCallout data={callout.data} onDismiss={() => setIsCalloutVisible(defaultCalloutState)} isEmail={callout.isEmail} targetId={callout.targetId} />
            )}
        </Box>
    );
});

export default SuppliersView;
