import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

export class AssignedTasksModel extends ModelBase<AssignedTasksModel> {
    // Filtering
    public filterKeyword: string = "";
    public filterDate: Date | undefined = undefined;
    public filterProjectTaskAssignmentStatusId: string | null = null;
    public filterProjectId: string | null = null;
    public filterProjectLeadId: string | null = null;

    // Sorting
    public sortKey = "projectTaskAssignmentStatusName"; // A property defined in the table column definitions.
    public sortDescending = false;

    constructor() {
        super();
        makeObservable(this, {
            // Observables
            filterKeyword: observable,
            filterDate: observable,
            filterProjectTaskAssignmentStatusId: observable,
            filterProjectId: observable,
            filterProjectLeadId: observable,

            sortKey: observable,
            sortDescending: observable,
        });
    }
}
