import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { ServiceGroupModel } from "../ServiceGroup/ServiceGroupModel";
import { ServiceGroupViewModel } from "../ServiceGroup/ServiceGroupViewModel";
import { runInAction } from "mobx";
import { SyncServiceGroupsResponse } from "./SharedResponseModels";
import { ServiceModel } from "../Service/ServiceModel";

class Request {
    public serviceGroupId: string = "";
    public BulkSwapServiceLites: BulkSwapServiceLite[] = [];
}

class BulkSwapServiceLite {
    public id: string = "";
    public ordinal: number = 0;
}

class ServiceResponse {
    public id: string = "";
    public serviceGroupId: string = "";
    public name: string = "";
    public ordinal: number = 0;
    public manHours: number = 0;
    public hourlyRate: number = 0;
    public updatedDate: Date | null = null;
    public updatedById: string | null = null;
}

class Response {
    public services: ServiceResponse[] = [];
}

export class PostBulkSwapServiceOrdinalsEndpoint extends Endpoint<Request, Response> {
    private viewModel: ServiceGroupViewModel;

    constructor(viewModel: ServiceGroupViewModel) {
        super();
        this.viewModel = viewModel;
        this.verb(Http.Post);
        this.path(AppUrls.Server.Admin.Services.PostBulkSwapServiceOrdinals);
    }

    HandleRequestAsync(requestModels: ServiceModel[]): Promise<Request> {
        const request: Request = new Request();
        request.serviceGroupId = this.viewModel.getValue<string>("id");
        const sortedModels = requestModels.slice().sort((a, b) => a.ordinal - b.ordinal);
        requestModels.forEach((requestModel, index) => {
            const swapRequestModel = new BulkSwapServiceLite();

            swapRequestModel.id = requestModel.id!;
            swapRequestModel.ordinal = sortedModels[index].ordinal;

            request.BulkSwapServiceLites.push(swapRequestModel);
        });

        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        runInAction(() => {
            const serviceModels: ServiceModel[] = [];
            response.services.forEach((serviceResponse) => {
                //create ServiceModel
                const serviceModel = new ServiceModel();
                //populate model
                serviceModel.toModel(serviceResponse);
                //push to model array.
                serviceModels.push(serviceModel);
            });
            //replace services array
            this.viewModel.model.serviceModels.replace(serviceModels);
        });
    }
}
