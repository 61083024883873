import { isEmptyOrWhitespace, isNullOrUndefined, ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";
import moment from "moment";

import { LostItemModel } from "./LostItemModel";
import { container } from "tsyringe";
import { LookupStore } from "Stores/Domain";

export class LostItemViewModel extends ViewModelBase<LostItemModel> {
    private lookupStore = container.resolve(LookupStore);

    constructor(lost: LostItemModel = new LostItemModel()) {
        super(lost);
        makeObservable(this, {});
    }

    public get KEY() {
        return this.model.KEY;
    }

    public get confidence() {
        return this.model.confidence;
    }

    public get contactNumber1() {
        return this.model.contactNumber1;
    }

    public get customerName() {
        return !isEmptyOrWhitespace(this.model.customerName) ? this.model.customerName : `${this.model.customerFirstName} ${this.model.customerLastName}`;
    }

    public get desirability() {
        return this.model.desirability;
    }

    public get emailAddress() {
        return this.model.emailAddress;
    }

    public get hours() {
        return this.model.hours;
    }

    public get issueDate() {
        return this.model.dateIssued;
    }

    public get revision() {
        return this.model.revision ?? "";
    }

    public get lastActionDate() {
        return this.model.lastActionDate;
    }

    public get lastActionUserName() {
        return !isEmptyOrWhitespace(this.model.lastActionFirstName) && !isEmptyOrWhitespace(this.model.lastActionLastName)
            ? `${this.model.lastActionFirstName} ${this.model.lastActionLastName}`
            : "";
    }

    public get reference() {
        return this.model.reference;
    }

    public get title() {
        return this.model.title;
    }

    public get type() {
        return this.model.isEnquiry ? "ENQUIRY" : "QUOTE";
    }

    public get value() {
        return this.model.value;
    }

    public get contactFullName() {
        if (this.model.customerItemModel) {
            return this.model.customerItemModel.fullName;
        }
        return "";
    }

    public get contactFullAddress() {
        if (this.model.customerItemModel) {
            return this.model.customerItemModel.fullAddress;
        }
        return "";
    }

    public get lostReason() {
        const reason = this.lookupStore.noteSubTypes.find((noteSubType) => noteSubType.id == this.model.lostNoteSubTypeId);

        return reason ? reason.name : "";
    }

    /**
     * Determines if the lost item can be displayed, given a set of filters.
     *
     * @param filterKeyword A general filter to cover a number of propoerties of the lost item.
     * @param filterFromDate A date from filter.
     * @param filterToDate  A date to filter.
     * @param filterTypeId A lost Type Filter.
     *
     * @returns true if the lost item can be displayed otherwise false.
     */
    public filterPredicate = (filterKeyword: string, filterFromDate: Date | undefined, filterToDate: Date | undefined, filterTypeId: string | null): boolean => {
        let result = true;

        // Attempt filtering by keyword.
        if (result) {
            const lowerCaseFilterKeyword = filterKeyword.toLowerCase();

            switch (true) {
                case isEmptyOrWhitespace(filterKeyword):
                case this.model.customerName?.toLowerCase().includes(lowerCaseFilterKeyword):
                case this.model.customerFirstName?.toLowerCase().includes(lowerCaseFilterKeyword):
                case this.model.customerLastName?.toLowerCase().includes(lowerCaseFilterKeyword):
                case this.model.reference.toLowerCase().includes(lowerCaseFilterKeyword):
                case this.model.title.toLowerCase().includes(lowerCaseFilterKeyword):
                    result = true;
                    break;

                default:
                    result = false;
                    break;
            }
        }

        // If the lost item has passed the keyword filter, attempt filtering by from date.
        if (result) {
            switch (true) {
                case isNullOrUndefined(filterFromDate):
                    result = true;
                    break;

                case isNullOrUndefined(this.model.lastActionDate):
                    result = false;
                    break;

                case moment(this.model.lastActionDate).startOf("day").isSameOrAfter(moment(filterFromDate)):
                    result = true;
                    break;

                default:
                    result = false;
                    break;
            }
        }

        // If the lost item has passed the from date filter, attempt filtering by to date.
        if (result) {
            switch (true) {
                case isNullOrUndefined(filterToDate):
                    result = true;
                    break;

                case isNullOrUndefined(this.model.lastActionDate):
                    result = false;
                    break;

                case moment(this.model.lastActionDate).startOf("day").isSameOrBefore(moment(filterToDate)):
                    result = true;
                    break;

                default:
                    result = false;
                    break;
            }
        }

        if (result) {
            switch (true) {
                case isEmptyOrWhitespace(filterTypeId):
                case this.model.isEnquiry && filterTypeId === "id_enquiry":
                case !this.model.isEnquiry && filterTypeId === "id_quote":
                    result = true;
                    break;

                default:
                    result = false;
                    break;
            }
        }

        return result;
    };
}
