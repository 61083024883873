import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import React from "react";

import { Box, ThemedText } from "Application";
import { SummaryViewModel } from "./SummaryViewModel";

interface IProps {
    viewModel: SummaryViewModel;
}

export const SummaryView: React.FC<IProps> = observer((props) => {
    const viewModel = props.viewModel;

    const Header = styled(Box)`
        background-color: ${viewModel.backgroundColor};
        color: white;
        min-height: 70px;
        padding: 10px 30px;
    `;

    return (
        <Header>
            <Box display="flex" alignItems="baseline">
                <ThemedText color="white" fontStyle="h5">
                    <b>{viewModel.reference}</b>
                </ThemedText>
                <>&nbsp;-&nbsp;</>
                <ThemedText color="white" fontStyle="h5">
                    {viewModel.title}
                </ThemedText>
            </Box>
            <ThemedText mb="-2px" mt="1px" color="white" fontStyle="h7">
                <b>{viewModel.taskGroupName}</b>
            </ThemedText>
            <ThemedText color="white" fontStyle="h7">
                {viewModel.taskName}
            </ThemedText>
        </Header>
    );
});
