import styled from "@emotion/styled";
import { theme } from "Application";
import { Link } from "react-router-dom";

export const ListItemsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 5px;
    max-height: 300px;
    width: 100%;
    overflow-y: auto;
`;

export const ListItemPositionDot = styled.div`
    border-radius: 50%;
    width: 16px;
    height: 16px;

    background-color: ${theme.palette.secondary.main};
    color: ${theme.palette.secondary.text};

    position: relative;
`;

export const ListItemPositionLabel = styled.span`
    position: absolute;
    top: 50%;
    left: 50%;
    line-height: 0px;
    transform: translate(-50%, -50%);
    color: white;
    font-size: ${theme.fontStyles.workItemStatus.fontSize};
    font-weight: 600;
    height: fit-content;
`;

export const ListItemContainer = styled.div`
    display: grid;
    grid-template-columns: 15px 1fr;
    column-gap: 5px;
`;
export const ListItemDetails = styled.div``;
export const ListItemLink = styled(Link)`
    color: ${theme.palette.field.main};
    &:hover {
        color: ${theme.palette.primary.main};
        & > * {
            color: ${theme.palette.primary.main};
        }
    }
`;
