import { Callout, DirectionalHint, mergeStyleSets } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React from "react";

import { pxToRem, theme } from "Application";
import { DownloadCalloutViewModel } from "./DownloadCalloutViewModel";
import { DownloadIcon } from "Assets/Icons/DownloadIcon";

export interface IDownloadCalloutState {
    isCalloutVisble: boolean;
    targetId: string;
    viewModel: DownloadCalloutViewModel | null;
}

interface IProps {
    onDismiss: () => void;
    targetId: string;
    viewModel: DownloadCalloutViewModel | null;
}

const callOutStyles = {
    root: {},
    divider: {
        borderBottom: "1px solid #E1E1E1",
    },
    row: {
        alignItems: "center",
        display: "flex",
        height: pxToRem(30),
        margin: `${pxToRem(5)} ${pxToRem(10)}`,
    },
    data: {
        cursor: "pointer",
        display: "flex",
        fontFamily: theme.fontStyles.tableHeader.fontFamily ?? theme.defaultFontStyle.fontFamily,
        fontSize: pxToRem(10),
        width: pxToRem(120),
        svg: {
            marginRight: pxToRem(10),
        },
    },
};

export const DownloadCalloutView: React.FC<IProps> = observer((props) => {
    const viewModel = props.viewModel!;
    const styles = mergeStyleSets(callOutStyles);

    const downloadLetterDocumentLink = () => (
        <div
            className={styles.data}
            onClick={() => {
                viewModel.parentDownloadLetterDocumentCommand.execute();
                props?.onDismiss();
            }}
        >
            <DownloadIcon />
            <div>Preview feebid</div>
        </div>
    );

    const downloadTasksAndCostsDocumentLink = () => (
        <div
            className={styles.data}
            onClick={() => {
                viewModel.parentDownloadTasksAndCostsDocumentCommand.execute();
                props?.onDismiss();
            }}
        >
            <DownloadIcon />
            <div>Preview services</div>
        </div>
    );

    const downloadTermsAndConditionsDocumentLink = () => (
        <div
            className={styles.data}
            onClick={() => {
                viewModel.parentDownloadTermsAndConditionsDocumentCommand.execute();
                props?.onDismiss();
            }}
        >
            <DownloadIcon />
            <div>Preview t&c's</div>
        </div>
    );

    return (
        <Callout className={styles.root} directionalHint={DirectionalHint.leftBottomEdge} onDismiss={props.onDismiss} target={`#${props.targetId}`}>
            {viewModel.parentDownloadLetterDocumentCommand.canExecute() && <div className={styles.row}>{downloadLetterDocumentLink()}</div>}
            {viewModel.parentDownloadTasksAndCostsDocumentCommand.canExecute() && <div className={styles.row}>{downloadTasksAndCostsDocumentLink()}</div>}
            {viewModel.parentDownloadTermsAndConditionsDocumentCommand.canExecute() && <div className={styles.row}>{downloadTermsAndConditionsDocumentLink()}</div>}
        </Callout>
    );
});
