import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";

class Response {}

export class DELETEProjectEndpoint extends EndpointWithoutRequest<Response> {
    constructor(id: string) {
        super();
        this.verb(Http.Delete);
        this.path(AppUrls.Server.Projects.DeleteProject.replace(":id", id));
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        return await Promise.resolve(response);
    }
}
