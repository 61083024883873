import styled from "@emotion/styled";
import { Callout, DirectionalHint } from "@fluentui/react";
import { darken } from "@mui/material";
import { observer } from "mobx-react-lite";
import { nanoid } from "nanoid";
import React, { useState } from "react";

import { Box } from "Application";
import InfoIcon from "Assets/InfoIcon.svg";
import { BankHolidayEventViewModel } from "./BankHolidayEventViewModel";

interface IProps {
    viewModel: BankHolidayEventViewModel;
}

const EventCell = styled(Box)`
    background-color: #dff6dd;
    border-left: 5px solid ${darken("#dff6dd", 0.25)};
    flex: 1;
    cursor: pointer;
`;

const EventCallout = styled(Box)`
    background-color: #dff6dd;
    border-left: 5px solid ${darken("#dff6dd", 0.25)};
    flex: 1;
    min-width: 260px;
    min-height: 60px;
`;

const EventDisplayName = styled(Box)`
    font-size: 10px;
    font-weight: bold;
    padding: 7px 12px 7px 7px;
`;

const BankHolidayInfoIcon = styled(Box)`
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url(${InfoIcon});
    height: 18px;
    margin: 4px 4px auto auto;
    opacity: 0.33;
    width: 18px;
`;

export const BankHolidayEventView: React.FC<IProps> = observer((props) => {
    const [callout, setIsCalloutVisible] = useState(false);
    const [buttonId] = useState(() => `callout-${nanoid()}`);
    const viewModel = props.viewModel;

    return (
        <React.Fragment>
            <EventCell id={buttonId} onClick={() => setIsCalloutVisible(true)}>
                <BankHolidayInfoIcon />
            </EventCell>
            {callout && (
                <Callout directionalHint={DirectionalHint.topAutoEdge} onDismiss={() => setIsCalloutVisible(false)} target={`#${buttonId}`}>
                    <EventCallout>
                        <EventDisplayName>{viewModel.displayName}</EventDisplayName>
                    </EventCallout>
                </Callout>
            )}
        </React.Fragment>
    );
});
