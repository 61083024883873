import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { NewRequestModel } from "../NewRequestModel";

class Request {
    public id: string | null = null;
    public leaveTypeId: string = "";
    public userId: string = "";
    public fromDate: Date | null = null;
    public toDate: Date | null = null;
    public fromDateLeaveDayTypeId: string = "";
    public toDateLeaveDayTypeId: string = "";
    public requestReason: string | null = null;
}

class Response {}

export class POSTRequestLeaveForUserEndpoint extends Endpoint<Request, Response> {
    constructor() {
        super();

        this.verb(Http.Post);
        this.path(AppUrls.Server.Admin.Leave.RequestLeaveForUser);
    }

    public async HandleRequestAsync(model: NewRequestModel): Promise<any> {
        const request = new Request();

        model.toRequest(request);

        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);
    }
}
