import _ from "lodash";
import { runInAction } from "mobx";

import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { QuoteResponse } from "./Shared/QuoteResponse";
import { QuoteContainerModel } from "../QuoteContainerModel";
import { QuoteContainerViewModel } from "../QuoteContainerViewModel";

class Request {
    id: string | null = null;
}

export class POSTSaveQuoteAsRejectedEndpoint extends Endpoint<FormData, Response> {
    private readonly viewModel: QuoteContainerViewModel;

    constructor(viewModel: QuoteContainerViewModel) {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Quotes.SaveQuoteAsRejected);
        this.AllowFileUploads();
        this.viewModel = viewModel;
    }

    public async HandleRequestAsync(model: QuoteContainerModel): Promise<any> {
        const request = new Request();

        request.id = model.quoteForm.id;

        // We need to send the data to the server as form data here as we have files.
        const formData = new FormData();

        formData.append("data", JSON.stringify(request));
        formData.append("note", this?.viewModel?.quoteFormViewModel?.submitWithNoteViewModel?.model.notes!);

        for (const file of this?.viewModel?.quoteFormViewModel?.submitWithNoteViewModel?.filesViewModel?.model?.files!) {
            formData.append(file.KEY, file.file!);
        }

        return Promise.resolve(formData);
    }

    public async HandleResponseAsync(response: QuoteResponse): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            QuoteResponse.revisionResponsePart(response, this.viewModel);
            QuoteResponse.quoteResponsePart(response, this.viewModel);
            QuoteResponse.customerResponsePart(response, this.viewModel);
            QuoteResponse.letterTemplateResponsePart(response, this.viewModel);
            QuoteResponse.tasksResponsePart(response, this.viewModel);
            QuoteResponse.termsAndConditionsResponsePart(response, this.viewModel);
            QuoteResponse.notesResponsePart(response, this.viewModel);
            QuoteResponse.remainingResponsePart(response, this.viewModel);
        });
    }
}
