import styled from "@emotion/styled";
import { Modal } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import { Box, setPageTitle, ThemedButton, ThemedText } from "Application";
import { ThemedLoader } from "Styles/ThemedPrimitives/Style1/ThemedLoader";
import { ThemedError } from "Styles/ThemedPrimitives/Style1/ThemedError";
import { CancelLeaveRequestView } from "Views/Leave/Shared/ModalViews/CancelLeaveRequest/CancelLeaveRequestView";
import { DeleteLeaveRequestView } from "Views/Leave/Shared/ModalViews/DeleteLeaveRequest/DeleteLeaveRequestView";
import { NewRequestView } from "Views/Leave/Shared/ModalViews/NewRequest/NewRequestView";
import { FormSeparator } from "Views/Shared/SharedComponents";
import { UserCalendarView } from "./Components/Body/UserCalendar/UserCalendarView";
import { HeaderView } from "./Components/Header/HeaderView";
import { LeaveCalendarViewModel } from "./LeaveCalendarViewModel";
import { NewRequestView as NewRequestWithUserSelectView } from "../Shared/ModalViews/NewRequest/NewRequestView";
import { ResetAllowancesView } from "../Shared/ModalViews/ResetAllowances/ResetAllowancesView";
import { ChangeAllowanceView } from "../Shared/ModalViews/ChangeAllowance/ChangeAllowanceView";
import { ApproveRequestView } from "../Shared/ModalViews/ApproveRequest/ApproveRequestView";
import { DeclineRequestView } from "../Shared/ModalViews/DeclineRequest/DeclineRequestView";

const ThemedButtonStyles = {
    root: {
        height: 30,
        padding: "0 5px",
        width: 147,
        fontSize: 17,
    },
};

const NavigationSeparator = styled(FormSeparator)`
    padding: 0px;
    line-height: 0;
`;

export const LeaveCalendarView: React.FC = observer(() => {
    // #region Code Behind

    const [viewModel] = useState(() => new LeaveCalendarViewModel());

    useEffect(() => {
        setPageTitle("Leave calendar");
    }, []);

    // #endregion Code Behind

    const renderWorkspace = () => {
        return (
            <React.Fragment>
                <Box alignItems={"center"} display={"flex"} justifyContent={"space-between"}>
                    <Box alignItems="baseline" display="flex">
                        <ThemedText fontStyle="h3" mr={3}>
                            Calendar
                        </ThemedText>
                        <Box
                            alignItems={"center"}
                            display={"flex"}
                            justifyContent={"space-between"}
                            onClick={() => viewModel.navigateToRequestsCommand.execute()}
                            style={{ cursor: "pointer" }}
                        >
                            <ThemedText>VIEW REQUESTS {">"}</ThemedText>
                        </Box>
                    </Box>
                    <Box display={"flex"}>
                        <ThemedButton
                            command={viewModel.displayNewRequestWithUserSelectCommand}
                            displayName="New request"
                            marginRight={3}
                            marginTop={3}
                            paletteColor={"primary"}
                            styles={ThemedButtonStyles}
                        />
                        <ThemedButton
                            command={viewModel.displayResetAllowancesCommand}
                            displayName="Reset allowances"
                            marginTop={3}
                            paletteColor={"primary"}
                            styles={ThemedButtonStyles}
                        />
                    </Box>
                </Box>
                <HeaderView viewModel={viewModel} />
                {viewModel.userCalendarViewModels.map((vm) => (
                    <UserCalendarView viewModel={vm} key={vm.KEY} />
                ))}
            </React.Fragment>
        );
    };

    const renderBusy = () => <ThemedLoader isOpen={viewModel.apiClient.IsBusy} />;

    const renderError = () => {
        const isOpen = viewModel.apiClient.IsSubmitted && !viewModel.apiClient.IsBusy && viewModel.apiClient.HaveValidationMessage;
        return <ThemedError command={viewModel.resetServerErrorCommand} isOpen={isOpen} errorMessage={viewModel.apiClient.ValidationMessage} />;
    };

    const renderNewRequestWithUserSelectModal = () => {
        return (
            <Modal isOpen={viewModel.canDisplayWithUserSelectNewRequest} onDismiss={() => viewModel.cancelNewRequestWithUserSelectCommand.execute()}>
                <NewRequestWithUserSelectView viewModel={viewModel.newRequestWithUserSelectViewModel} />
            </Modal>
        );
    };

    const renderEditRequestModal = () => {
        return (
            <Modal isOpen={viewModel.canDisplayEditRequest} onDismiss={() => viewModel.cancelEditRequestCommand.execute()}>
                <NewRequestView viewModel={viewModel.editRequestViewModel} />
            </Modal>
        );
    };

    const renderResetAllowancesModal = () => {
        return (
            <Modal isOpen={viewModel.canDisplayResetAllowances} onDismiss={() => viewModel.cancelResetAllowancesCommand.execute()}>
                <ResetAllowancesView viewModel={viewModel.resetAllowancesViewModel} />
            </Modal>
        );
    };

    const renderChangeAllowanceModal = () => {
        return (
            <Modal isOpen={viewModel.canDisplayChangeAllowance} onDismiss={() => viewModel.cancelChangeAllowanceCommand.execute()}>
                <ChangeAllowanceView viewModel={viewModel.changeAllowanceViewModel} />
            </Modal>
        );
    };

    const renderApproveRequestModal = () => {
        return (
            <Modal isOpen={viewModel.canDisplayApproveRequest} onDismiss={() => viewModel.cancelApproveRequestCommand.execute()}>
                <ApproveRequestView viewModel={viewModel.approveRequestViewModel} />
            </Modal>
        );
    };

    const renderDeclineRequestModal = () => {
        return (
            <Modal isOpen={viewModel.canDisplayDeclineRequest} onDismiss={() => viewModel.cancelDeclineRequestCommand.execute()}>
                <DeclineRequestView viewModel={viewModel.declineRequestViewModel} />
            </Modal>
        );
    };

    const renderCancelLeaveRequestModal = () => {
        return (
            <Modal isOpen={viewModel.canDisplayCancelRequest} onDismiss={() => viewModel.cancelCancelRequestCommand.execute()}>
                <CancelLeaveRequestView viewModel={viewModel.cancelLeaveRequestViewModel} />
            </Modal>
        );
    };

    const renderDeleteLeaveRequestModal = () => {
        return (
            <Modal isOpen={viewModel.canDisplayDeleteRequest} onDismiss={() => viewModel.cancelDeleteRequestCommand.execute()}>
                <DeleteLeaveRequestView viewModel={viewModel.deleteLeaveRequestViewModel} />
            </Modal>
        );
    };

    return (
        <Box display="flex" flexDirection="column" flex="1">
            {/* Workspace */}
            {renderWorkspace()}

            {/* Busy Overlay */}
            {renderBusy()}

            {/* Error Overlay */}
            {renderError()}

            {/* New Request With User Select Modal*/}
            {renderNewRequestWithUserSelectModal()}

            {/* Edit Request Modal*/}
            {renderEditRequestModal()}

            {/* Reset Allowances Modal */}
            {renderResetAllowancesModal()}

            {/* Change Allowance Modal*/}
            {renderChangeAllowanceModal()}

            {/* Approve Request Modal*/}
            {renderApproveRequestModal()}

            {/* Decline Request Modal*/}
            {renderDeclineRequestModal()}

            {/* Cancel Leave Request Modal*/}
            {renderCancelLeaveRequestModal()}

            {/* Delete Leave Request Modal*/}
            {renderDeleteLeaveRequestModal()}
        </Box>
    );
});
