import { ModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

export class InProgressItemModel extends ModelBase<InProgressItemModel> {
    public id: string = "";
    public projectTaskAssignmentStatusForegroundColor: string = "";
    public projectTaskAssignmentStatusId: string = "";
    public projectTaskAssignmentStatusName: string = "";
    public projectTaskName: string = "";
    public projectTaskIsBilledHourly: boolean = false;
    public assignedUserFirstName: string = "";
    public assignedUserLastName: string = "";
    public plannedStartDate: Date | null = null;
    public plannedEndDate: Date | null = null;
    public billableEffort: number | null = null;
    public effort = 0;
    public isNonBillableEffort = false;

    constructor() {
        super();

        makeObservable(this, {});
    }
}
