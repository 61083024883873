import { isEmptyOrWhitespace, isNullOrUndefined, ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { nanoid } from "nanoid";
import { container } from "tsyringe";

import { Validator } from "Application/Validation";
import { SupplierContactModel } from "./SupplierContacts/SupplierContactModel";
import { LookupStore } from "../../../Stores/Domain";

export class SupplierModel extends ModelBase<SupplierModel> {
    public KEY: string = nanoid();

    public id: string = "";
    public businessName: string | null = null;
    public supplierTypeId: string | null = null;
    public supplierSubTypeId: string | null = null;

    public address1: string | null = null;
    public address2: string | null = null;
    public address3: string | null = null;
    public city: string | null = null;
    public postcode: string | null = null;

    public contactNumber1: string | null = null;
    public contactNumber2: string | null = null;
    public emailAddress1: string | null = null;
    public emailAddress2: string | null = null;
    public createdDate: Date | null = null;
    public updatedDate: Date | null = null;

    public supplierContacts = observable<SupplierContactModel>([]);
    public deletedSupplierContacts = observable<SupplierContactModel>([]);

    public clientNote: string | null = null;

    constructor() {
        super();

        makeObservable(this, {
            id: observable,

            businessName: observable,

            supplierTypeId: observable,
            supplierSubTypeId: observable,

            address1: observable,
            address2: observable,
            address3: observable,
            city: observable,
            postcode: observable,

            contactNumber1: observable,
            contactNumber2: observable,
            emailAddress1: observable,
            emailAddress2: observable,

            supplierContacts: observable,
            deletedSupplierContacts: observable,

            clientNote: observable,
        });
    }
}

export class SupplierModelValidator extends Validator<SupplierModel> {
    private lookupStore = container.resolve(LookupStore);

    constructor() {
        super();

        const consultantSupplierTypeId = this.lookupStore.getSupplierTypeIdByType("CONSULTANT");

        this.ruleFor("businessName").notNull().withMessage("Please enter a business name").notEmpty().withMessage("Please enter a business name");

        this.ruleFor("supplierTypeId").notNull().withMessage("Please select a supplier type").notEmpty().withMessage("Please select a supplier type");

        this.ruleFor("supplierSubTypeId")
            .notNull()
            .withMessage("Please select a supplier sub type")
            .notEmpty()
            .withMessage("Please select a supplier sub type")
            .when((model) => model && model.supplierTypeId === consultantSupplierTypeId);

        this.ruleFor("postcode")
            .notNull()
            .withMessage("Please enter a valid postcode")
            .notEmpty()
            .withMessage("Please enter a valid postcode")
            .maxLength(16)
            .withMessage("Please enter a postcode less than or equal to 16 characters")
            .when((model) => model && !isEmptyOrWhitespace(model.postcode));

        this.ruleFor("contactNumber1")
            .notNull()
            .withMessage("Please enter a contact number (or email address)")
            .notEmpty()
            .withMessage("Please enter a contact number (or email address)")
            .maxLength(16)
            .withMessage("Please enter a contact number less than or equal to 16 characters")
            .when((model) => {
                // Ensure we have a model.
                let result = !isNullOrUndefined(model);

                if (result) {
                    result = !isEmptyOrWhitespace(model.contactNumber1) || isEmptyOrWhitespace(model.emailAddress1);
                }

                return result;
            });

        this.ruleFor("contactNumber2")
            .notNull()
            .withMessage("Please enter a valid contact number or remove contents")
            .notEmpty()
            .withMessage("Please enter a valid contact number or remove contents")
            .maxLength(16)
            .withMessage("Please enter a valid contact number less than or equal to 16 characters")
            .when((model) => model && !isEmptyOrWhitespace(model.contactNumber2));

        this.ruleFor("emailAddress1")
            .notNull()
            .withMessage("Please enter an email address (or contact number)")
            .notEmpty()
            .withMessage("Please enter an email address (or contact number)")
            .emailAddress()
            .withMessage("Please enter a valid email address (or contact number)")
            .when((model) => {
                // Ensure we have a model.
                let result = !isNullOrUndefined(model);

                if (result) {
                    result = !isEmptyOrWhitespace(model.emailAddress1) || isEmptyOrWhitespace(model.contactNumber1);
                }

                return result;
            });
    }
}
