import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";

import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { InvoiceForecastViewModel } from "../InvoiceForecastViewModel";

class Request {
    public invoiceForecastMonthOverrides: InvoiceForecastMonthOverrideRequest[] = [];
}

class InvoiceForecastMonthOverrideRequest {
    public id: string | null = null;
    public year: number | null = null;
    public month: number | null = null;
    public hours: string = "";
    public value: string = "";
    public isActive: boolean = false;
}

class Response {}

export class POSTUpsertInvoicingForecastOverridesEndpoint extends Endpoint<Request, Response> {
    private readonly viewModel: InvoiceForecastViewModel;

    constructor(viewModel: InvoiceForecastViewModel) {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Admin.InvoiceForecast.UpsertInvoiceForecastOverrides);
        this.viewModel = viewModel;
    }

    HandleRequestAsync(): Promise<any> {
        const request: Request = new Request();

        this.viewModel.capacities.forEach((c) => {
            const monthOverrideRequest = new InvoiceForecastMonthOverrideRequest();
            c.invoiceForecastMonthCapacityOverride.model.toRequest(monthOverrideRequest);
            request.invoiceForecastMonthOverrides.push(monthOverrideRequest);
        });

        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);
    }
}
