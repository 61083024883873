import { isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

import { SuppliersItemModel } from "./SuppliersItemModel";

export class SuppliersItemViewModel extends ViewModelBase<SuppliersItemModel> {
    constructor(suppliers: SuppliersItemModel = new SuppliersItemModel()) {
        super(suppliers);

        makeObservable(this, {});
    }

    public get KEY() {
        return this.model.KEY;
    }

    public get fullName() {
        return this.model.fullName;
    }

    public get fullNameWithPrefix() {
        return [this.fullName].filter(Boolean).join(" ");
    }

    public get fullAddress() {
        return this.model.fullAddress;
    }

    public get supplierTypeName() {
        return this.model.supplierTypeName;
    }

    public get supplierSubTypeName() {
        return this.model.supplierSubTypeName ?? "";
    }

    public get lastActionDate() {
        return this.model.lastActionDate;
    }

    public get lastActionUserName() {
        return !isEmptyOrWhitespace(this.model.lastActionFirstName) && !isEmptyOrWhitespace(this.model.lastActionLastName)
            ? `${this.model.lastActionFirstName} ${this.model.lastActionLastName}`
            : "";
    }

    /**
     * Determines if the supplier can be displayed, given a set of filters.
     *
     * @param filterKeyword A general filter to cover a number of properties of the suppliers.
     *
     * @param filterSupplierTypeId  A filter to cover the supplier type.
     *
     * @param filterSupplierSubTypeId  A filter to cover the supplier sub type.
     *
     * @returns true if the suppliers can be displayed otherwise false.
     */

    public filterPredicate = (filterKeyword: string, filterSupplierTypeId: string, filterSupplierSubTypeId: string): boolean => {
        let result = true;

        // Attempt filtering by keyword.
        if (result) {
            const lowerCaseFilterKeyword = filterKeyword.toLowerCase();

            switch (true) {
                case isEmptyOrWhitespace(filterKeyword):
                case this.model.fullAddress.toLowerCase().includes(lowerCaseFilterKeyword):
                case this.model.fullName.toLowerCase().includes(lowerCaseFilterKeyword):
                case this.model.suppliersContacts.some((contact) => contact.fullName.toLowerCase().includes(lowerCaseFilterKeyword)):
                    result = true;
                    break;
                case this.model.supplierTypeName.toLowerCase().includes(lowerCaseFilterKeyword):
                    result = true;
                    break;
                case this.model.supplierSubTypeName?.toLowerCase().includes(lowerCaseFilterKeyword):
                    result = true;
                    break;

                default:
                    result = false;
                    break;
            }
        }

        //Attempt filtering by supplier type.
        if (result) {
            switch (true) {
                case isEmptyOrWhitespace(filterSupplierTypeId):
                    result = true;
                    break;
                case this.model.supplierTypeId === filterSupplierTypeId:
                    result = true;
                    break;
                default:
                    result = false;
                    break;
            }
        }

        //Attempt filtering by supplier sub type.
        if (result) {
            switch (true) {
                case isEmptyOrWhitespace(filterSupplierSubTypeId):
                    result = true;
                    break;
                case this.model.supplierSubTypeId === filterSupplierSubTypeId:
                    result = true;
                    break;
                default:
                    result = false;
                    break;
            }
        }

        return result;
    };
}
