import { mergeStyleSets } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import { PropsWithChildren } from "react";

import { PersonaBase, IPersonaBaseProps } from "../../../Components/StyleFrameworks/FluentUI/Primitives/Active/PersonaBase";
import { theme } from "../../AppTheme";

/**
 * A styled persona.
 */
export const ThemedPersona = observer((props: PropsWithChildren<IPersonaBaseProps>) => (
    <PersonaBase
        {...props}
        styles={mergeStyleSets(
            {
                primaryText: {
                    fontFamily: theme.fontStyles.h5.fontFamily ?? theme.defaultFontStyle.fontFamily,
                    fontSize: theme.fontStyles.h5.fontSize ?? theme.defaultFontStyle.fontSize,
                    fontWeight: theme.fontStyles.h5.fontWeight ?? theme.defaultFontStyle.fontWeight,
                    letterSpacing: theme.fontStyles.h5.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                    lineHeight: theme.fontStyles.h5.lineHeight ?? theme.defaultFontStyle.lineHeight,
                    textTransform: theme.fontStyles.h5.textTransform ?? theme.defaultFontStyle.textTransform,
                },
            },
            props.styles,
        )}
    />
));
