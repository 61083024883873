import { ModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

import { InProgressGroupModel } from "../../InProgressGroupModel";

export class DeleteTaskAssignmentGroupModel extends ModelBase<DeleteTaskAssignmentGroupModel> {
    public inProgressGroupModel: InProgressGroupModel;

    constructor(inProgressGroupModel: InProgressGroupModel) {
        super();

        this.inProgressGroupModel = inProgressGroupModel;

        makeObservable(this, {});
    }
}
