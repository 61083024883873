// Libs
import { makeObservable, observable } from "mobx";

export class User {
    public id: string = "";
    public firstName: string = "";
    public lastName: string = "";
    public documentUrl: string | null = null;
    public thumbnailDocumentUrl: string | null = null;
    public isAssignable: boolean | null = null;
    constructor() {
        makeObservable(this, {
            id: observable,
            firstName: observable,
            lastName: observable,
            documentUrl: observable,
            thumbnailDocumentUrl: observable,
            isAssignable: observable,
        });
    }
}
