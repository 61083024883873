// Libs
import { makeObservable, observable } from "mobx";

export class UserData {
    public isInvoicingForecastVisible: boolean = false;

    constructor() {
        makeObservable(this, {
            isInvoicingForecastVisible: observable,
        });
    }
}
