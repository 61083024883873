import { runInAction } from "mobx";
import { KeyValuePair as KeyValuePairSH } from "@shoothill/core";
import { EndpointWithoutRequest } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { AddEditPlanningApplicationViewModel } from "../AddEditPlanningApplicationViewModel";
import { AddEditPlanningApplicationModel } from "../AddEditPlanningApplicationModel";
import { FileModel } from "Views/Shared/Files/FileModel";

class KeyValuePair {
    public key: string = "";
    public value: string = "";
}

class Response {
    public id: string | null = null;
    public projectId: string | null = null;
    public projectRef: string | null = null;
    public localAuthorityDistrictId: string | null = null;
    public planningRef: string | null = null;
    public planningApplicationStatusId: string | null = null;
    public planningApplicationTypeId: string | null = null;
    public note: string | null = null;
    public submittedDate: Date | null = null;
    public determinationDate: Date | null = null;
    public approvedDate: Date | null = null;
    public consultationDate: Date | null = null;
    public options: KeyValuePair[] = [];
    public documents: DocumentResponse[] = [];
}

class DocumentResponse {
    id: string = "";
    fileName: string = "";
    fileSizeBytes: number = 0;
    mimeType: string = "";
    documentUrl: string = "";
}

export class GET_GetPlanningApplicationAndRelatedByIdEndpoint extends EndpointWithoutRequest<Response> {
    private viewModel: AddEditPlanningApplicationViewModel;

    constructor(viewModel: AddEditPlanningApplicationViewModel) {
        super();
        this.viewModel = viewModel;

        this.path(AppUrls.Server.PlanningApplication.GetPlanningApplicationAndRelatedById.replace(":id", this.viewModel.model.id!));
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        runInAction(() => {
            this.viewModel.model.fromResponse(response);

            const options: KeyValuePairSH[] = [];

            response.options.forEach((option: KeyValuePair) => {
                const keyValuePair: KeyValuePairSH = {
                    key: option.key,
                    text: option.value,
                };

                options.push(keyValuePair);
            });

            this.viewModel.model.projectKeyValuePairs.replace(options);

            // Process te enquiry documents.
            this.viewModel.filesViewModel.model.files.replace(
                response.documents.map((nd) => {
                    const model = new FileModel();

                    model.fromResponse(nd);

                    return model;
                }) ?? [],
            );
        });
    }
}
