import { runInAction } from "mobx";

import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { TaskViewModel } from "../Table/ConfigureSubViews/TaskViewModel";
import { SummaryModel } from "../Table/SummaryView/SummaryModel";
import { BillingViewModel } from "../Table/ConfigureSubViews/BillingSubView/BillingViewModel";
import { BillingNotesModel } from "../Table/ConfigureSubViews/BillingSubView/SubViews/BillingNotesModel";
import { BillingNotesViewModel } from "../Table/ConfigureSubViews/BillingSubView/SubViews/BillingNotesViewModel";
import { BillingNoteModel } from "../Table/ConfigureSubViews/BillingSubView/SubViews/BillingNoteModel";
import { BillingNoteViewModel } from "../Table/ConfigureSubViews/BillingSubView/SubViews/BillingNoteViewModel";
import { TA_NOTECONTEXT_ASSIGNMENT, TA_NOTECONTEXT_BILLED, TA_NOTECONTEXT_COMPLETED } from "Views/Shared/Constants";

class Response {
    //begin of ProjectTask Data.
    projectTaskId: string = "";
    projectTaskManHours: number | null = null;
    projectTaskName: string = "";
    //end of ProjectTask Data.
    summary: SummaryResponse | null = null;
    projectTaskAssignmentWithRelatedHistoryResponses: ProjectTaskAssignmentWithRelatedHistoryResponse[] = [];
}

class SummaryResponse {
    projectReference = "";
    projectTitle = "";
    projectTaskGroupName = "";
}

class ProjectTaskAssignmentUserNoteWithRelatedHistoryResponse {
    id: string = "";

    assignmentUserDate: string | null = null;
    assignmentUserFirstName: string | null = null;
    assignmentUserLastName: string | null = null;
    assignmentUserNote: string | null = null;
    assignmentUserThumbnailUrl: string | null = null;
}

class ProjectTaskAssignmentWithRelatedHistoryResponse {
    id: string = "";

    billedDate: string | null = null;
    billedNote: string | null = null;
    billedUserFirstName: string | null = null;
    billedUserLastName: string | null = null;
    billedUserThumbnailUrl: string | null = null;

    completedDate: string | null = null;
    completedNote: string | null = null;
    completedUserFirstName: string | null = null;
    completedUserLastName: string | null = null;
    completedUserThumbnailUrl: string | null = null;

    projectTaskAssignmentUserNoteWithRelatedHistory: ProjectTaskAssignmentUserNoteWithRelatedHistoryResponse[] = [];
}

export class GETAllProjectTaskAssignmentsWithRelatedByProjectTaskIdEndpoint extends EndpointWithoutRequest<Response> {
    private readonly viewModel: TaskViewModel;

    constructor(projectTaskId: string, viewModel: TaskViewModel) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.Resources.GetAllProjectTaskAssignmentsWithRelatedByProjectTaskId.replace(":projectTaskId", projectTaskId));
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            // populate summary model with response data
            const summaryModel = new SummaryModel();
            summaryModel.setValue("reference", response.summary?.projectReference);
            summaryModel.setValue("title", response.summary?.projectTitle);
            summaryModel.setValue("projectTaskGroupName", response.summary?.projectTaskGroupName);
            summaryModel.setValue("projectTaskName", response.projectTaskName);

            this.viewModel.summaryViewModel.model = summaryModel;

            this.viewModel.model.setValue("projectTaskBillableEffort", response.projectTaskManHours);

            // Process the histories.
            const billingViewModel: BillingViewModel = this.viewModel.workspaceViewModels.find((vm) => vm instanceof BillingViewModel) as BillingViewModel;

            billingViewModel.billingNotesViewModels.replace(
                response.projectTaskAssignmentWithRelatedHistoryResponses.map((history) => {
                    const model = new BillingNotesModel();

                    model.fromResponse(history);

                    const billingNotesViewModel = new BillingNotesViewModel(model);
                    //check if user note history is > 0
                    if (history.projectTaskAssignmentUserNoteWithRelatedHistory.length > 0) {
                        history.projectTaskAssignmentUserNoteWithRelatedHistory.forEach((projectTaskHistory) => {
                            const noteModel = new BillingNoteModel();

                            noteModel.context = TA_NOTECONTEXT_ASSIGNMENT;
                            //assignmentUserDate = creation date will always be a date since it is a mandatory entry in the table
                            noteModel.date = new Date(projectTaskHistory.assignmentUserDate!);
                            noteModel.firstName = projectTaskHistory.assignmentUserFirstName;
                            noteModel.lastName = projectTaskHistory.assignmentUserLastName;
                            noteModel.note = projectTaskHistory.assignmentUserNote;
                            noteModel.documentUrl = projectTaskHistory.assignmentUserThumbnailUrl;

                            billingNotesViewModel.assignmentUserNoteViewModels.push(new BillingNoteViewModel(noteModel));
                        });
                    }

                    if (history.completedDate) {
                        const noteModel = new BillingNoteModel();

                        noteModel.context = TA_NOTECONTEXT_COMPLETED;
                        noteModel.date = new Date(history.completedDate);
                        noteModel.firstName = history.completedUserFirstName;
                        noteModel.lastName = history.completedUserLastName;
                        noteModel.note = history.completedNote;
                        noteModel.documentUrl = history.completedUserThumbnailUrl;

                        billingNotesViewModel.completedUserNoteViewModel = new BillingNoteViewModel(noteModel);
                    }

                    if (history.billedDate) {
                        const noteModel = new BillingNoteModel();

                        noteModel.context = TA_NOTECONTEXT_BILLED;
                        noteModel.date = new Date(history.billedDate);
                        noteModel.firstName = history.billedUserFirstName;
                        noteModel.lastName = history.billedUserLastName;
                        noteModel.note = history.billedNote;
                        noteModel.documentUrl = history.billedUserThumbnailUrl;

                        billingNotesViewModel.billedUserNoteViewModel = new BillingNoteViewModel(noteModel);
                    }

                    return billingNotesViewModel;
                }),
            );
        });
    }
}
