import { IStyle, mergeStyleSets } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React from "react";

import { Box, ThemedButton, ThemedText } from "Application";
import warning from "Assets/warning.png";
import { DeleteConfirmationViewModel } from "./DeleteConfirmationViewModel";

interface IDeleteConfirmationViewStyles {
    root: IStyle;
    header: IStyle;
    body: IStyle;
    footer: IStyle;
}

const styleDefinitions: IDeleteConfirmationViewStyles = {
    root: {
        fontFamily: `"Poppins", sans-serif`,
        textAlign: "center",
        maxWidth: "365px",
        padding: "30px 40px 40px",
        width: "350px",
    },
    header: {
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "20px",
    },
    body: {
        textAlign: "center",
        span: {
            fontSize: "18px",
            lineHeight: "27px",
            letterSpacing: "0.54px",
            color: "#191919",
        },
    },
    footer: {
        display: "flex",
        gap: "20px",
        flexDirection: "column",
        marginTop: "25px",
        button: {
            padding: "10px",
            height: "32px",
            width: "168px",
            margin: "0 auto",
        },
    },
};

interface DeleteConfirmationViewProps {
    viewModel: DeleteConfirmationViewModel;
}

export const DeleteConfirmationView: React.FC<DeleteConfirmationViewProps> = observer((props) => {
    const viewModel = props.viewModel;
    const styles = mergeStyleSets(styleDefinitions);

    if (viewModel) {
        return (
            <Box className={styles.root}>
                <Box className={styles.header}>
                    <img src={warning} height={"100"} width={"100"} alt={"warning"} />
                </Box>
                <Box className={styles.body}>
                    <ThemedText fontStyle={"h2"}>{viewModel.displayName}</ThemedText>
                    <ThemedText fontStyle={"h2"}>{viewModel.displayMessage}</ThemedText>
                </Box>
                <Box className={styles.footer}>
                    <ThemedButton paletteColor="tertiary" command={viewModel.parentDeleteConfirmationCommand} displayName="Delete draft" />
                    <ThemedButton command={viewModel.parentCancelDisplayConfirmationCommand} displayName="Cancel" />
                </Box>
            </Box>
        );
    }

    return <React.Fragment />;
});
