import { isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { action, computed, makeObservable, observable } from "mobx";
import { container } from "tsyringe";

import { ICommand, RelayCommand } from "Application";
import { LookupStore } from "Stores/Domain";
import { PROJECTSTATUS_ONHOLD } from "Views/Shared/Constants";
import { AssignTaskViewModel } from "./AssignTaskSubViews/AssignTaskViewModel";
import { AssignTaskModel } from "./AssignTaskSubViews/AssignTaskModel";
import { CreateTaskViewModel } from "./CreateTaskSubViews/CreateTaskViewModel";
import { CreateTaskModel } from "./CreateTaskSubViews/CreateTaskModel";
import { UnassignedTasksItemViewModel } from "./UnassignedTasksItemViewModel";
import { UnassignedTasksModel } from "./UnassignedTasksModel";
import { ProgrammingViewModel } from "../ProgrammingViewModel";

export class UnassignedTasksViewModel extends ViewModelBase<UnassignedTasksModel> {
    private lookupStore = container.resolve(LookupStore);

    public assignTaskViewModel: AssignTaskViewModel | null = null;
    public createTaskViewModel: CreateTaskViewModel | null = null;
    public createNCTTaskViewModel: CreateTaskViewModel | null = null;
    public tasks = observable<UnassignedTasksItemViewModel>([]);

    private parentCreateOrAssignTaskCommand: ICommand;
    private parentCancelTaskCommand: ICommand;
    public parentViewModel: ProgrammingViewModel;

    constructor(parentViewModel: ProgrammingViewModel, userId: string, projectId: string, parentCreateOrAssignTaskCommand: ICommand, parentCancelTaskCommand: ICommand) {
        super(new UnassignedTasksModel(userId, projectId));

        this.parentCreateOrAssignTaskCommand = parentCreateOrAssignTaskCommand;
        this.parentCancelTaskCommand = parentCancelTaskCommand;
        this.parentViewModel = parentViewModel;

        makeObservable(this, {
            // Observables
            tasks: observable,
            assignTaskViewModel: observable,
            createTaskViewModel: observable,

            // Computeds
            canDisplayWorkCalendar: computed,
            canSortTasks: computed,
            filteredAndSortedTasks: computed,
            canDisplayAssignTask: computed,
            canDisplayCreateTask: computed,

            //actions
            setAssignTaskViewModel: action,
        });
    }

    //region actions
    public setAssignTaskViewModel = (item: AssignTaskViewModel) => {
        this.assignTaskViewModel = item;
    };

    //endregion actions

    // #region Filtering
    // #endregion Filtering

    // #region Sorting

    public updateSortCommand = new RelayCommand((key: string, sortDescending: boolean) => {
        this.model.sortKey = key;
        this.model.sortDescending = sortDescending;
    });

    public get canSortTasks(): boolean {
        return !isEmptyOrWhitespace(this.model.sortKey);
    }

    public get filteredAndSortedTasks(): UnassignedTasksItemViewModel[] {
        return this.canSortTasks
            ? this.tasks.slice().sort((lhs, rhs) => {
                  return (this.model.sortDescending ? lhs[this.model.sortKey] < rhs[this.model.sortKey] : lhs[this.model.sortKey] > rhs[this.model.sortKey]) ? 1 : -1;
              })
            : this.tasks.slice();
    }

    public get filteredAndSortedTasksRemainingHours(): number {
        return this.filteredAndSortedTasks
            .filter((item) => {
                return item.remainingProjectTaskBillableEffort > 0;
            })
            .reduce((total, item) => {
                return total + item.remainingProjectTaskBillableEffort;
            }, 0);
    }

    // #endregion Sorting

    // #region Calendar

    public get canDisplayWorkCalendar(): boolean {
        return this.canDisplayAssignTask && this.parentViewModel.parentViewModel.workCalendarViewModel !== null;
    }

    // #endregion Calendar

    // #region Create New Task Assignment

    public get canDisplayAssignTask(): boolean {
        return this.assignTaskViewModel !== null;
    }

    /**
     * Displays a new task assignment.
     */
    public displayAssignTaskCommand = new RelayCommand((projectTaskId: string, originServiceGroupIsNCTPreset: boolean) => {
        const vm = new AssignTaskViewModel(
            projectTaskId,
            this.model.userId,
            new AssignTaskModel(this.model.userId),
            this.submitAssignTaskCommand,
            this.cancelAssignTaskCommand,
            originServiceGroupIsNCTPreset,
        );

        this.setAssignTaskViewModel(vm);
    });

    /**
     * Handles the result of saving a new task assignment.
     *
     * Having assigned a task:
     *  1. Call the parent command to refresh the information.
     *  2. Dismiss the assign task view.
     */
    public submitAssignTaskCommand = new RelayCommand(() => {
        this.parentCreateOrAssignTaskCommand.execute();
        this.assignTaskViewModel = null;
    });

    /**
     * Handles cancelling a new task assignment.
     */
    public cancelAssignTaskCommand = new RelayCommand(() => {
        this.parentCancelTaskCommand.execute();
        this.assignTaskViewModel = null;
    });

    // #endregion Create New Task Assignment

    // #region Create New Task

    public get canDisplayCreateTask(): boolean {
        return this.createTaskViewModel !== null;
    }

    /**
     * Display a new task.
     */
    public displayCreateTaskCommand = new RelayCommand(
        () => {
            this.createTaskViewModel = new CreateTaskViewModel(new CreateTaskModel(this.model.projectId!), this.submitCreateTaskCommand, this.cancelCreateTaskCommand);
        },
        () => {
            const status = this.lookupStore.projectStatuses.find((ps) => ps.id === this.parentViewModel.parentStaffDetailsItemViewModel.model.projectStatusId);

            return status?.type !== PROJECTSTATUS_ONHOLD;
        },
    );

    /**
     * Display a new NCT task.
     */
    /**
     * Behaviour:
     * 1.Prefills the CreateTaskModel with NCT service group Id, NCT service Id, billed hourly true, hourly rate 1
     * 2.Creates the CreateTaskModel
     * NOTE: This is a work around in order to skip the first create AdHoc task and keep the existing business logic intact as much as possible
     */
    public displayCreateNCTTaskCommand = new RelayCommand(
        () => {
            this.createNCTTaskViewModel = new CreateTaskViewModel(new CreateTaskModel(this.model.projectId!), this.submitCreateNCTTaskCommand, this.cancelCreateTaskCommand, false);

            this.createNCTTaskViewModel.initializeProjectTask().then(() => {
                const NCTServiceGroupId: string | undefined = this.createNCTTaskViewModel!.model.serviceGroups.find((g) => g.isNCTPreset)?.id;
                const NCTServiceId: string | undefined = this.createNCTTaskViewModel!.model.services.find((s) => s.serviceGroupId === NCTServiceGroupId)?.id;

                //check if NCT ids exist
                if (NCTServiceGroupId && NCTServiceId) {
                    //set the NCT default values
                    this.createNCTTaskViewModel!.setValue("serviceGroupId", NCTServiceGroupId);
                    this.createNCTTaskViewModel!.setValue("serviceId", NCTServiceId);
                    this.createNCTTaskViewModel!.setValue("isBilledHourly", true);
                    this.createNCTTaskViewModel!.setValue("hourlyRate", 1);
                    this.createNCTTaskViewModel!.saveProjectTask(true);
                }
            });
        },
        () => {
            const status = this.lookupStore.projectStatuses.find((ps) => ps.id === this.parentViewModel.parentStaffDetailsItemViewModel.model.projectStatusId);

            return status?.type !== PROJECTSTATUS_ONHOLD;
        },
    );

    /**
     * Handles the result of saving a new task.
     *
     * Having created the task:
     *  1. Call the parents command to refresh the information.
     *  2. Dismiss the create task view.
     *  3. Display the assign task view if requested by the caller.
     */
    public submitCreateTaskCommand = new RelayCommand((projectTaskId: string, displayAssignView: boolean) => {
        this.parentCreateOrAssignTaskCommand.execute();
        this.createTaskViewModel = null;

        if (displayAssignView) {
            this.displayAssignTaskCommand.execute(projectTaskId);
        }
    });
    /**
     * Handles the result of saving a new task with NCT preset.
     *
     * Having created the task:
     *  1. Call the parents command to refresh the information.
     *  2. Dismiss the create task view.
     *  3. Display the assign task view if requested by the caller.
     */
    public submitCreateNCTTaskCommand = new RelayCommand((projectTaskId: string, displayAssignView: boolean) => {
        this.parentCreateOrAssignTaskCommand.execute();
        this.createTaskViewModel = null;

        if (displayAssignView) {
            this.displayAssignTaskCommand.execute(projectTaskId, true);
        }
    });

    /**
     * Handles cancelling a new task.
     */
    public cancelCreateTaskCommand = new RelayCommand(() => {
        this.createTaskViewModel = null;
    });

    // #endregion Create New Task

    // #region Project Status Commands

    public putProjectOnHoldCommand = new RelayCommand(
        () => this.parentViewModel.putProjectOnHoldCommand.execute(),
        () => this.parentViewModel.putProjectOnHoldCommand.canExecute(),
    );

    public takeProjectOffHoldCommand = new RelayCommand(
        () => this.parentViewModel.takeProjectOffHoldCommand.execute(),
        () => this.parentViewModel.takeProjectOffHoldCommand.canExecute(),
    );

    // #endregion Project Status Commands
}
