import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";
import { PlanningApplicationLiteModel } from "./PlanningApplicationLiteModel";

export class PlanningApplicationsModel extends ModelBase<PlanningApplicationsModel> {
    public live: number = 0;
    public refused: number = 0;
    public approved: number = 0;
    public livePlanningApplicationLites = observable<PlanningApplicationLiteModel>([]);
    constructor() {
        super();
        makeObservable(this, {
            live: observable,
            refused: observable,
            approved: observable,
            livePlanningApplicationLites: observable,
        });
    }
}

export class PlanningApplicationsModelValidator extends Validator<PlanningApplicationsModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
    }
}
