import { ViewModelBase, isEmptyOrWhitespace } from "@shoothill/core";
import { computed, makeObservable, observable } from "mobx";

import { LetterTemplatesListModel } from "./LetterTemplatesListModel";
import { LetterTemplateItemViewModel } from "./LetterTemplateItemViewModel";

import { GetAllLetterTemplatesEndpoint } from "./GetAllLetterTemplatesEndpoint";

import { APIClient, RelayCommand } from "Application";
import { AppUrls } from "AppUrls";

export default class LetterTemplatesListViewModel extends ViewModelBase<LetterTemplatesListModel> {
    public apiClient = new APIClient();
    public letterTemplates = observable<LetterTemplateItemViewModel>([]);

    constructor() {
        super(new LetterTemplatesListModel());
        makeObservable(this, {
            letterTemplates: observable,
            canFilterLetterTemplates: computed,
            filteredLetterTemplates: computed,
            canSortLetterTemplates: computed,
            filteredAndSortedLetterTemplates: computed,
        });
        const __ = this.apiClient.sendAsync(new GetAllLetterTemplatesEndpoint(this));
    }

    public updateFilterKeywordCommand = new RelayCommand((keyword: string) => {
        this.model.filterKeyword = keyword;
    });

    public navigateToNewLetterTemplateCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.LetterTemplates.New);
    });

    public get canSortLetterTemplates(): boolean {
        return !isEmptyOrWhitespace(this.model.sortKey);
    }

    public get filteredLetterTemplates(): LetterTemplateItemViewModel[] {
        return this.canFilterLetterTemplates ? this.letterTemplates.filter((vm) => vm.filterPredicate(this.model.filterKeyword)) : this.letterTemplates;
    }

    public get canFilterLetterTemplates(): boolean {
        return !isEmptyOrWhitespace(this.model.filterKeyword);
    }

    public get filteredAndSortedLetterTemplates(): LetterTemplateItemViewModel[] {
        return this.canSortLetterTemplates
            ? this.filteredLetterTemplates
                  .slice()
                  .sort((lhs, rhs) =>
                      (this.model.sortDescending ? lhs[this.model.sortKey] < rhs[this.model.sortKey] : lhs[this.model.sortKey] > rhs[this.model.sortKey]) ? 1 : -1,
                  )
            : this.filteredLetterTemplates;
    }

    public navigateToEditForm = new RelayCommand((item: LetterTemplateItemViewModel) => {
        this.history.push(AppUrls.Client.LetterTemplates.Edit.replace(":id", item.model.id));
    });

    public navigateToNewForm = new RelayCommand(() => {
        this.history.push(AppUrls.Client.LetterTemplates.New);
    });

    public updateSortCommand = new RelayCommand((key: string, sortDescending: boolean) => {
        this.model.sortKey = key;
        this.model.sortDescending = sortDescending;
    });
}
