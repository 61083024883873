import { observer } from "mobx-react-lite";
import React from "react";
import { MessageBar, MessageBarType } from "@fluentui/react";
export interface MessageBarError {
    errorMessage?: string;
}
export const MessageBarError: React.FC<MessageBarError> = observer((props) => {
    return (
        <>
            <MessageBar messageBarType={MessageBarType.info}>{props.errorMessage}</MessageBar>
        </>
    );
});
