import styled from "@emotion/styled";
import { DateRangeType, DayOfWeek, ICalendarProps } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import { Box, setPageTitle, ThemedButton, ThemedEditDate, ThemedText } from "Application";
import { ThemedError } from "Styles/ThemedPrimitives/Style1/ThemedError";
import { ThemedLoader } from "Styles/ThemedPrimitives/Style1/ThemedLoader";
import { CustomSidePanel } from "Views/Shared/CustomSidePanel/CustomSidePanel";
import { WorkCalendarView } from "Views/Shared/WorkCalendar/WorkCalendarView";
import { MyWorkViewModel } from "./MyWorkViewModel";
import { ProgrammingWeekTaskGroupView } from "./ProgrammingWeekTaskGroup/ProgrammingWeekTaskGroupView";
import { ProgrammingWeekTaskView } from "./ProgrammingWeekTask/ProgrammingWeekTaskView";

const calendarProps: ICalendarProps = {
    dateRangeType: DateRangeType.WorkWeek,
    workWeekDays: [DayOfWeek.Monday],
};

const MyWorkContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
`;

export const MyWorkView: React.FC = observer(() => {
    const [viewModel] = useState(() => new MyWorkViewModel());

    useEffect(() => {
        setPageTitle("My work");
    }, []);

    const renderTaskPanel = () => {
        return (
            <CustomSidePanel
                isOpen={viewModel.canDisplayTask}
                onDismiss={() => {
                    viewModel.cancelTaskCommand.execute();
                }}
            >
                <ProgrammingWeekTaskView viewModel={viewModel.taskViewModel!} />
            </CustomSidePanel>
        );
    };

    const renderTaskGroupPanel = () => {
        return (
            <CustomSidePanel
                isOpen={viewModel.canDisplayTaskGroup}
                onDismiss={() => {
                    viewModel.cancelTaskGroupCommand.execute();
                }}
            >
                <ProgrammingWeekTaskGroupView viewModel={viewModel.taskGroupViewModel!} />
            </CustomSidePanel>
        );
    };

    const renderBusy = () => <ThemedLoader isOpen={viewModel.apiClient.IsBusy} />;

    const renderError = () => {
        const isOpen = viewModel.apiClient.IsSubmitted && !viewModel.apiClient.IsBusy && viewModel.apiClient.HaveValidationMessage;

        return <ThemedError command={viewModel.resetServerErrorCommand} isOpen={isOpen} errorMessage={viewModel.apiClient.ValidationMessage} />;
    };

    return (
        <MyWorkContainer>
            {/* Programming Week Filter */}
            <Box alignItems={"center"} display={"flex"} justifyContent={"space-between"}>
                <Box display="flex" flex={6} justifyContent="start" alignItems="center" mt="-3px" mb="29px">
                    <Box display="flex" alignItems="center" justifyContent="end" mr="10px">
                        <ThemedText fontStyle="h3">Programming week:</ThemedText>
                    </Box>
                    <Box mt="-4px">
                        <ThemedEditDate
                            calendarProps={calendarProps}
                            command={viewModel.updateProgrammingWeekStartDateCommand}
                            placeholder="Start date"
                            size="larger"
                            value={() => viewModel.getValue("programmingWeekStartDate")}
                        />
                    </Box>
                    <ThemedButton displayName="Previous" paletteColor="primary" command={viewModel.updatePreviousProgrammingWeekDateCommand} ml="15px" />
                    <ThemedButton displayName="Next" paletteColor="primary" command={viewModel.updateNextProgrammingWeekDateCommand} ml="15px" />
                </Box>
            </Box>

            {/* Calendar */}
            <WorkCalendarView viewModel={viewModel.workCalendarViewModel} />

            {/* Busy Overlay */}
            {renderBusy()}

            {/* Error Overlay */}
            {renderError()}

            {/* Display work item task panel */}
            {renderTaskPanel()}

            {/* Display work item task group panel */}
            {renderTaskGroupPanel()}
        </MyWorkContainer>
    );
});
