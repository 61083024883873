import styled from "@emotion/styled";
import { PersonaSize } from "@fluentui/react";
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { matchRoutes, Navigate, Route, Routes, useLocation } from "react-router-dom";

import { AutoGrid, Box, ThemedText } from "Application";
import { AppUrls } from "AppUrls";
import { Tabbed } from "Styles/ApplicationStyles";
import { ThemedPersona } from "Styles/ThemedPrimitives/Style1/ThemedPersona";
import { ProjectViewModel } from "./ProjectViewModel";
import { ProjectDetailsView } from "./Details/ProjectDetailsView";
import { ProjectOverviewView } from "./Overview/ProjectOverviewView";
import { ProjectQuoteHistoryView } from "./QuoteHistory/ProjectQuoteHistoryView";
import { ProjectTasksView } from "./Tasks/ProjectTasksView";
import { ProjectWeeklyUpdateContainerView } from "./WeeklyUpdate/ProjectWeeklyUpdateContainerView";
import { NavigationContainer, ProjectStatus, TitleContainer } from "Views/Shared/SharedComponents";

const Container = styled(Box)`
    margin-top: -1px;
    border-top: 1px solid #d6d6d6;
    background-color: #fff;
`;

const ProjectTabbed = styled(Tabbed)`
    margin-top: 15px;
`;

export const ProjectView: React.FC = observer(() => {
    // #region Code Behind

    // Ideally we want the routePath and id to be part of the history listener in the viewmodel.
    // However, react-roter-dom has pattern matching which allows us to get what we need. So for
    // now we will use this as it gets the job done.
    const location = useLocation();
    const matches = matchRoutes(
        [
            { path: AppUrls.Client.Projects.Details },
            { path: AppUrls.Client.Projects.Overview },
            { path: AppUrls.Client.Projects.QuoteHistory },
            { path: AppUrls.Client.Projects.Tasks },
            { path: AppUrls.Client.Projects.WeeklyUpdate },
        ],
        location,
    );

    const routePath = matches?.[0].route.path;
    const id = matches?.[0].params?.id;

    const [viewModel] = useState(() => new ProjectViewModel(id));

    // #endregion Code Behind

    const renderProjectStatus = () => {
        return viewModel.canDisplayProjectStatus ? (
            <ProjectStatus style={{ backgroundColor: viewModel.projectStatusBackgroundColor }}>{viewModel.projectStatusDisplayName}</ProjectStatus>
        ) : null;
    };

    return (
        <Box overflowY="auto">
            {/* Navigation and Project Status */}
            <NavigationContainer onClick={viewModel.navigateToProjectsCommand.execute}>
                <ThemedText>{"<"} BACK TO PROJECTS</ThemedText>
                {renderProjectStatus()}
            </NavigationContainer>

            {/* Title */}
            <TitleContainer paddingBottom="17.5px !important">
                <ThemedText fontStyle={"h2"}>{viewModel.displayName}</ThemedText>
                <AutoGrid mb={0} mt={0} dc={"1fr 1fr 1fr 1fr"}>
                    <AutoGrid mb={0} dc={"auto 1fr"} columnGap={"10px"} alignItems="center">
                        <ThemedText fontStyle="h3">PROJECT LEAD</ThemedText>
                        <ThemedPersona displayName={viewModel.projectLead} size={PersonaSize.size32} imageUrl={viewModel.projectLeadImage} />
                    </AutoGrid>

                    <AutoGrid mb={0} dc={"auto 1fr"} columnGap={"10px"} alignItems="center">
                        <ThemedText fontStyle="h3">TOTAL</ThemedText>
                        <ThemedText fontStyle="h4">{viewModel.total}</ThemedText>
                    </AutoGrid>

                    {viewModel.canDisplayLeadIn && (
                        <AutoGrid mb={0} dc={"auto 1fr"} columnGap={"10px"} alignItems="center">
                            <ThemedText fontStyle="h3">LEAD IN</ThemedText>
                            <ThemedText fontStyle="h4">{viewModel.leadIn}</ThemedText>
                        </AutoGrid>
                    )}
                    {viewModel.canDisplayDuration && (
                        <AutoGrid mb={0} dc={"auto 1fr"} columnGap={"10px"} alignItems="center">
                            <ThemedText fontStyle="h3">DURATION</ThemedText>
                            <ThemedText fontStyle="h4">{viewModel.duration}</ThemedText>
                        </AutoGrid>
                    )}
                </AutoGrid>
                <AutoGrid mb={0} mt={0} dc={"1fr 1fr 1fr 1fr"}>
                    <div></div>

                    <AutoGrid mb={0} dc={"auto 1fr"} columnGap={"10px"} alignItems="center">
                        <ThemedText fontStyle="h3">Remaining Total</ThemedText>
                        <ThemedText fontStyle="h4">{viewModel.remainingTotal}</ThemedText>
                    </AutoGrid>

                    <div></div>

                    <div></div>
                </AutoGrid>
            </TitleContainer>
            <ProjectTabbed>
                <Box className={routePath === AppUrls.Client.Projects.Details ? "activeTab" : ""} onClick={() => viewModel.setDetailsTabCommand.execute(id)}>
                    <ThemedText fontStyle={"h2"}>Details</ThemedText>
                </Box>
                <Box className={routePath === AppUrls.Client.Projects.Overview ? "activeTab" : ""} onClick={() => viewModel.setOverviewTabCommand.execute(id)}>
                    <ThemedText fontStyle={"h2"}>Overview</ThemedText>
                </Box>
                <Box className={routePath === AppUrls.Client.Projects.QuoteHistory ? "activeTab" : ""} onClick={() => viewModel.setQuoteHistoryTabCommand.execute(id)}>
                    <ThemedText fontStyle={"h2"}>Quote history</ThemedText>
                </Box>
                <Box className={routePath === AppUrls.Client.Projects.Tasks ? "activeTab" : ""} onClick={() => viewModel.setTasksTabCommand.execute(id)}>
                    <ThemedText fontStyle={"h2"}>Tasks</ThemedText>
                </Box>
                <Box className={routePath === AppUrls.Client.Projects.WeeklyUpdate ? "activeTab" : ""} onClick={() => viewModel.setWeeklyUpdateTabCommand.execute(id)}>
                    <ThemedText fontStyle={"h2"}>Weekly update </ThemedText>
                </Box>
            </ProjectTabbed>
            <Container>
                <Routes>
                    <Route path="details/:id" element={<ProjectDetailsView viewModel={viewModel} />} />
                    <Route path="overview/:id" element={<ProjectOverviewView viewModel={viewModel} />} />
                    <Route path="quotehistory/:id" element={<ProjectQuoteHistoryView viewModel={viewModel} />} />
                    <Route path="tasks/:id" element={<ProjectTasksView viewModel={viewModel} />} />
                    <Route path="weeklyupdate/:id" element={<ProjectWeeklyUpdateContainerView viewModel={viewModel} />} />
                    <Route path={"*"} element={<Navigate to="projects" replace />} />
                </Routes>
            </Container>
        </Box>
    );
});
