import { ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";
import { ICommand } from "Application";
import { ConvertToProjectModel } from "./ConvertToProjectModel";

export class ConvertToProjectViewModel extends ViewModelBase<ConvertToProjectModel> {
    public parentCancelConvertToProjectCommand: ICommand;
    public parentConvertToProjectCommand: ICommand;

    constructor(parentCancelConvertToProjectCommand: ICommand, parentConvertToProjectCommand: ICommand) {
        super(new ConvertToProjectModel());

        this.parentCancelConvertToProjectCommand = parentCancelConvertToProjectCommand;
        this.parentConvertToProjectCommand = parentConvertToProjectCommand;

        makeObservable(this, {});
    }

    public get displayMessage() {
        return "Are you sure you want to convert?";
    }
}
