import { formatCurrency, isEmptyOrWhitespace, isNullOrUndefined, ViewModelBase } from "@shoothill/core";
import { action, computed, makeObservable, observable } from "mobx";
import { container } from "tsyringe";

import { APIClient, formatFixedDecimalNumber, RelayCommand } from "Application";
import { AppUrls } from "AppUrls";
import { JourneyHistoryItem, UserJourneyHistoryStore, LookupStore, SettingsStore } from "Stores/Domain";
import { GETAllQuotesLiteEndpoint } from "./Endpoints/GETAllQuotesLiteEndpoint";
import { QuoteItemViewModel } from "./QuoteItemViewModel";
import { QuotesModel } from "./QuotesModel";
import { GETQuoteLetterDocumentByIdEndpoint } from "../Endpoints/GETQuoteLetterDocumentByIdEndpoint";
import { GETQuoteTermsAndConditionsDocumentByIdEndpoint } from "../Endpoints/GETQuoteTermsAndConditionsDocumentByIdEndpoint";
import { GETQuoteTasksAndCostsDocumentByIdEndpoint } from "../Endpoints/GETQuoteTasksAndCostsDocumentByIdEndpoint";
import { set } from "date-fns";

export class QuotesViewModel extends ViewModelBase<QuotesModel> {
    public apiClient = new APIClient();
    //resolve dependency injection
    private lookupStore = container.resolve(LookupStore);
    private userJourneyHistoryStore = container.resolve(UserJourneyHistoryStore);

    public quotes = observable<QuoteItemViewModel>([]);

    public render: boolean = false;

    constructor() {
        super(new QuotesModel());

        makeObservable(this, {
            // Observables
            quotes: observable,

            render: observable,
            //actions

            setRender: action,
            // Computeds
            totalSentQuoteHours: computed,
            totalSentQuoteValue: computed,

            canFilterQuotes: computed,
            filteredQuotes: computed,
            canSortQuotes: computed,
            filteredAndSortedQuotes: computed,
            canRender: computed,
        });

        this.loadData();
    }

    public setRender(value: boolean) {
        this.render = value;
    }

    public get canRender() {
        return this.render;
    }

    public loadData = async () => {
        await this.apiClient.sendAsync(new GETAllQuotesLiteEndpoint(this));

        if (this.apiClient.IsRequestSuccessful) {
            this.setRender(true);
        }
    };

    public get settings() {
        return container.resolve(SettingsStore).QuotesTable;
    }

    //region actions

    //endregion actions

    //region computed

    public get totalSentQuoteHours(): string {
        return formatFixedDecimalNumber(this.model.totalSentQuoteHours);
    }

    public get totalSentQuoteValue(): string {
        return formatCurrency(this.model.totalSentQuoteValue);
    }

    //endregion computed

    // #region Filtering

    public updateFilterKeywordCommand = new RelayCommand((keyword: string) => {
        this.model.filterKeyword = keyword;
    });

    public updateFilterQuoteStatusCommand = new RelayCommand((statusId: string | null) => {
        this.model.filterQuoteStatusId = statusId;
    });

    public updateFilterEnquiryPersonVisitingCommand = new RelayCommand((id: string | null) => {
        this.model.filterEnquiryPersonVisitingId = id;
    });

    public updateFilterFromDateComand = new RelayCommand((date: Date | undefined) => {
        this.model.filterFromDate = date;
    });

    public updateFilterToDateCommand = new RelayCommand((date: Date | undefined) => {
        this.model.filterToDate = date;
    });

    public updateQuoteLeadCommand = new RelayCommand((id: string | null) => {
        this.model.filterQuoteLeadId = id;
    });

    public get quoteStatuses() {
        return [{ key: "", text: "All statuses" }, ...this.lookupStore.getQuoteStatuses()];
    }

    public get quoteLeads() {
        const options = this.quotes
            .filter((p) => p.model.quoteLeadId && p.model.quoteLeadFirstName && p.model.quoteLeadLastName)
            .map((t) => {
                return {
                    key: t.model.quoteLeadId!,
                    text: `${t.model.quoteLeadFirstName} ${t.model.quoteLeadLastName}`,
                };
            })
            .sort((a, b) => (a.text > b.text ? 1 : b.text > a.text ? -1 : 0));

        const distinctOptions = options.filter((option, index, arr) => arr.findIndex((t) => t.key === option.key) === index);

        return [{ key: "", text: "All quote leads" }, ...distinctOptions];
    }

    public get enquiryPersonsVisiting() {
        const options = this.quotes
            .filter((p) => p.model.enquiryPersonVisitingId && p.model.enquiryPersonVisitingFirstName && p.model.enquiryPersonVisitingLastName)
            .map((t) => {
                return {
                    key: t.model.enquiryPersonVisitingId!,
                    text: `${t.model.enquiryPersonVisitingFirstName} ${t.model.enquiryPersonVisitingLastName}`,
                };
            })
            .sort((a, b) => (a.text > b.text ? 1 : b.text > a.text ? -1 : 0));
        const distinctOptions = options.filter((option, index, arr) => arr.findIndex((t) => t.key === option.key) === index);
        return [{ key: "", text: "All persons visiting" }, ...distinctOptions];
    }

    public get canFilterQuotes(): boolean {
        return (
            !isEmptyOrWhitespace(this.model.filterKeyword) ||
            !isNullOrUndefined(this.model.filterFromDate) ||
            !isNullOrUndefined(this.model.filterToDate) ||
            !isEmptyOrWhitespace(this.model.filterQuoteStatusId) ||
            !isEmptyOrWhitespace(this.model.filterQuoteLeadId) ||
            !isEmptyOrWhitespace(this.model.filterEnquiryPersonVisitingId)
        );
    }

    public get filteredQuotes(): QuoteItemViewModel[] {
        const hasFilters =
            !isEmptyOrWhitespace(this.model.filterKeyword) ||
            !isNullOrUndefined(this.model.filterFromDate) ||
            !isNullOrUndefined(this.model.filterToDate) ||
            !isEmptyOrWhitespace(this.model.filterQuoteStatusId) ||
            !isEmptyOrWhitespace(this.model.filterQuoteLeadId) ||
            !isEmptyOrWhitespace(this.model.filterEnquiryPersonVisitingId);

        if (!hasFilters) {
            return this.quotes;
        }

        const filterPredicate = (vm: QuoteItemViewModel) =>
            vm.filterPredicate(
                this.model.filterKeyword,
                this.model.filterFromDate,
                this.model.filterToDate,
                this.model.filterQuoteStatusId,
                this.model.filterQuoteLeadId,
                this.model.filterEnquiryPersonVisitingId,
            );

        return this.quotes.slice().filter(filterPredicate);
    }

    // #endregion Filtering

    // #region Sorting

    public updateSortCommand = new RelayCommand((key: string, sortDescending: boolean) => {
        this.settings.sortKey = key;
        this.settings.sortDescending = sortDescending;
    });

    public get canSortQuotes(): boolean {
        return !isEmptyOrWhitespace(this.settings.sortKey);
    }

    public get filteredAndSortedQuotes(): QuoteItemViewModel[] {
        return this.canSortQuotes
            ? this.filteredQuotes
                  .slice()
                  .sort((lhs, rhs) =>
                      (this.settings.sortDescending ? lhs[this.settings.sortKey] < rhs[this.settings.sortKey] : lhs[this.settings.sortKey] > rhs[this.settings.sortKey]) ? 1 : -1,
                  )
            : this.filteredQuotes.slice();
    }

    // #endregion Sorting

    // #region Navigation

    public navigateToQuoteCommand = new RelayCommand((vm: QuoteItemViewModel) => {
        this.history.push(AppUrls.Client.Quotes.Edit.replace(":id", vm.model.id));
        //add
        const journeyHistoryItem = new JourneyHistoryItem();

        journeyHistoryItem.id = vm.model.id;
        journeyHistoryItem.name = vm.model.title;

        this.userJourneyHistoryStore.addQuoteJourneyHistoryItem(journeyHistoryItem);
    });

    public navigateToCustomerDetailCommand = new RelayCommand((id: string) => {
        this.history.push(AppUrls.Client.Customers.Details.replace(":id", id));
    });

    // #endregion Navigation

    // #region Downloads

    public downloadLetterDocumentCommand = new RelayCommand((vm: QuoteItemViewModel) => this.apiClient.sendAsync(new GETQuoteLetterDocumentByIdEndpoint(vm.model.id, true)));

    public downloadTasksAndCostsDocumentCommand = new RelayCommand((vm: QuoteItemViewModel) =>
        this.apiClient.sendAsync(new GETQuoteTasksAndCostsDocumentByIdEndpoint(vm.model.id, true)),
    );

    public downloadTermsAndConditionsDocumentCommand = new RelayCommand((vm: QuoteItemViewModel) =>
        this.apiClient.sendAsync(new GETQuoteTermsAndConditionsDocumentByIdEndpoint(vm.model.id, true)),
    );

    // #endregion Downloads
}
