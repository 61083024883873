import { isEmptyOrWhitespace, isNullOrUndefined, ViewModelBase } from "@shoothill/core";
import { computed, makeObservable, observable } from "mobx";
import { container } from "tsyringe";

import { APIClient, formatDate, formatTime, ICommand, RelayCommand, RelayCommandAsync } from "Application";
import { NoteModel, NoteModelValidator } from "./NoteModel";
import { LookupStore } from "Stores/Domain";
import { FilesViewModel } from "../Files/FilesViewModel";
import { EditNoteViewModel } from "./EditNoteViewModel";

export class NoteViewModel extends ViewModelBase<NoteModel> {
    private lookupStore = container.resolve(LookupStore);
    private parentRemoveNoteCommand: ICommand | null;
    private parentSaveNoteCommand: ICommand | null;

    public apiClient = new APIClient();
    public filesViewModel = new FilesViewModel();
    public editNoteViewModel: EditNoteViewModel | null = null;

    constructor(noteModel: NoteModel = new NoteModel(), removeNoteCommand: ICommand | null = null, saveNoteCommand: ICommand | null = null) {
        super(noteModel);

        this.setValidator(new NoteModelValidator());

        this.parentRemoveNoteCommand = removeNoteCommand;
        this.parentSaveNoteCommand = saveNoteCommand;

        if (noteModel.editMode) {
            this.editNoteViewModel = new EditNoteViewModel(noteModel.copy(), this.updateNoteCommand, this.cancelNoteCommand);
        }

        makeObservable(this, {
            // Observables
            editNoteViewModel: observable,

            // Computeds
            noteTypes: computed,
            canDisplayEditMode: computed,
            noteDetails: computed,
            userDetails: computed,
            isQuoteNote: computed,
            canDisplayNoteReason: computed,
        });
    }

    // #region Properties

    public get KEY() {
        return this.model.KEY;
    }

    public get noteTypes() {
        return this.lookupStore.getExternalNoteTypes();
    }

    public get noteDetails() {
        const noteTypeName = this.lookupStore.getNoteTypeById(this.model.noteTypeId!);
        const noteDate = this.model.noteDate ? formatDate(this.model.noteDate!) : "";
        const noteTime = this.model.noteTime ?? "";

        switch (true) {
            case !isEmptyOrWhitespace(noteTypeName) && !isEmptyOrWhitespace(noteDate) && !isEmptyOrWhitespace(noteTime):
                return `${noteTypeName} at ${noteTime} on ${noteDate}`;

            case !isEmptyOrWhitespace(noteTypeName) && !isEmptyOrWhitespace(noteDate) && isEmptyOrWhitespace(noteTime):
                return `${noteTypeName} on ${noteDate}`;

            case !isEmptyOrWhitespace(noteTypeName) && isEmptyOrWhitespace(noteDate) && !isEmptyOrWhitespace(noteTime):
                return `${noteTypeName} at ${noteTime}`;

            case !isEmptyOrWhitespace(noteTypeName):
                return noteTypeName;

            default:
                return "";
        }
    }

    public get canDisplayNoteReason() {
        return this.model.noteSubTypeId != null;
    }

    public get noteReason() {
        const retVal = this.lookupStore.noteSubTypes.find((noteSubType) => noteSubType.id == this.model.noteSubTypeId);
        return retVal ? `Reason: ${retVal.name}` : "";
    }

    public get userDetails() {
        const userName = isEmptyOrWhitespace(this.model.lastUpdatedByUserId)
            ? `${this.model.createdByUserFirstName} ${this.model.createdByUserLastName}`
            : `${this.model.lastUpdatedByUserFirstName} ${this.model.lastUpdatedByUserLastName}`;

        const date = isEmptyOrWhitespace(this.model.lastUpdatedByUserId) ? formatDate(this.model.createdDate!) : formatDate(this.model.lastUpdatedDate!);
        const time = isEmptyOrWhitespace(this.model.lastUpdatedByUserId) ? formatTime(this.model.createdDate!) : formatTime(this.model.lastUpdatedDate!);

        return `${userName} at ${time} on ${date}`;
    }

    public get canDisplayEditMode() {
        return !isNullOrUndefined(this.editNoteViewModel) && this.model.editMode;
    }

    public get isQuoteNote() {
        if (this.model.quoteId === null) {
            return false;
        }
        return true;
    }

    public get isProjectNote() {
        if (this.model.projectId === null) {
            return false;
        }
        return true;
    }
    // #endregion Properties

    public editNoteCommand = new RelayCommand(
        () => {
            this.editNoteViewModel = new EditNoteViewModel(this.model.copy(), this.updateNoteCommand, this.cancelNoteCommand);
            this.editNoteViewModel.filesViewModel.model.files.replace(this.filesViewModel.model.copy().files);
            this.editNoteViewModel.filesViewModel.model.removedFiles.replace(this.filesViewModel.model.copy().removedFiles);
            this.model.editMode = true;
        },
        () => {
            const noteType = this.lookupStore.noteTypes.find((nt) => nt.id === this.model.noteTypeId);

            if (noteType) {
                return !noteType.isInternal;
            }

            return true;
        },
    );

    public cancelNoteCommand = new RelayCommand(() => {
        this.parentRemoveNoteCommand?.execute(this.model);
        this.editNoteViewModel = null;
        this.model.editMode = false;
    });

    public updateNoteCommand = new RelayCommandAsync(async () => {
        const isValid = this.editNoteViewModel?.isModelValid();

        if (isValid) {
            this.parentSaveNoteCommand?.execute(this);
            this.editNoteViewModel = null;
            this.model.editMode = false;
        }
    });
}
