import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { ImageSettingsViewModel } from "./ImageSettingsViewModel";

class Response {
    public id: string = "";
}

export class DeleteImageSettingsEndpoint extends EndpointWithoutRequest<Response> {
    private viewModel: ImageSettingsViewModel;

    constructor(id: string, viewModel: ImageSettingsViewModel) {
        super();
        this.verb(Http.Delete);
        this.path(AppUrls.Server.Admin.ImageSettings.DeleteImageSetting.replace(":id", id));
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: Response): Promise<void> {
        const fileViewModelToRemove = this.viewModel.fileViewModels.find((x) => x.model.id === response.id);

        if (fileViewModelToRemove) {
            this.viewModel.fileViewModels.remove(fileViewModelToRemove);
        }
    }
}
