import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import React from "react";

import { AutoGrid, Box, ThemedButton, ThemedCheckBox, ThemedComboBox, ThemedEditText } from "Application";
import { ThemedLoader } from "Styles/ThemedPrimitives/Style1/ThemedLoader";
import { ThemedError } from "Styles/ThemedPrimitives/Style1/ThemedError";
import { CreateTaskViewModel } from "./CreateTaskViewModel";
import { FormContainer, FormContainerRow, FormDottedSeparator, FormSubContainer } from "Views/Shared/SharedComponents";
import { SummaryView } from "./SummaryView/SummaryView";

interface ICreateTaskViewProps {
    viewModel: CreateTaskViewModel;
}

const Body = styled(Box)`
    background-color: #fcfcfc;
    display: flex;
    flex: 1;
    flex-direction: column;
`;

const Footer = styled(Box)`
    align-items: center;
    background-color: #fcfcfc;
    display: flex;
    min-height: 70px;
    padding: 0 30px;
`;

export const CreateTaskView: React.FC<ICreateTaskViewProps> = observer((props) => {
    const viewModel = props.viewModel;

    const renderWorkspace = () => {
        return (
            <FormContainer>
                <FormSubContainer>
                    <FormContainerRow dc={"1fr"}>
                        <ThemedComboBox
                            command={viewModel.updateServiceGroupCommand}
                            displayName="Service group*"
                            options={viewModel.serviceGroups}
                            placeholder="Please select"
                            validationMessage={() => viewModel.getError("serviceGroupId")}
                            value={() => viewModel.getValue("serviceGroupId")}
                        />
                    </FormContainerRow>
                    <FormContainerRow dc={"1fr"}>
                        <ThemedComboBox
                            command={viewModel.updateServiceCommand}
                            displayName="Service*"
                            options={viewModel.services}
                            placeholder="Please select"
                            validationMessage={() => viewModel.getError("serviceId")}
                            value={() => viewModel.getValue("serviceId")}
                        />
                    </FormContainerRow>
                    <FormContainerRow dc={"1fr"}>
                        <ThemedCheckBox
                            command={viewModel.updateIsBilledHourlyCommand}
                            displayName="Billed hourly"
                            value={() => viewModel.getValue("isBilledHourly")}
                            paletteColor="secondary"
                        />
                    </FormContainerRow>
                    <FormContainerRow dc={"1fr 1fr 1fr"}>
                        {viewModel.updateManHoursCommand.canExecute() && (
                            <ThemedEditText
                                command={viewModel.updateManHoursCommand}
                                displayName="staff hours*"
                                validationMessage={() => viewModel.getError("manHours")}
                                value={() => viewModel.getValue("manHours") ?? ""}
                            />
                        )}
                        <ThemedEditText
                            command={viewModel.updateHourlyRateCommand}
                            displayName="Hourly rate*"
                            validationMessage={() => viewModel.getError("hourlyRate")}
                            value={() => viewModel.getValue("hourlyRate") ?? ""}
                        />
                    </FormContainerRow>
                </FormSubContainer>
            </FormContainer>
        );
    };

    const renderBusy = () => <ThemedLoader isOpen={viewModel.apiClient.IsBusy} />;

    const renderError = () => {
        const isOpen = viewModel.apiClient.IsSubmitted && !viewModel.apiClient.IsBusy && viewModel.apiClient.HaveValidationMessage;

        return <ThemedError command={viewModel.resetServerErrorCommand} errorMessage={viewModel.apiClient.ValidationMessage} isOpen={isOpen} />;
    };

    return (
        <Box display="flex" flex="1" flexDirection="column" onClick={(e: any) => e.stopPropagation()} zIndex={10000}>
            {viewModel && (
                <React.Fragment>
                    <SummaryView viewModel={viewModel.summaryViewModel} />
                    <Body>
                        {/* Workspace */}
                        {renderWorkspace()}

                        {/* Busy Overlay */}
                        {renderBusy()}

                        {/* Error Overlay */}
                        {renderError()}
                    </Body>
                    <FormDottedSeparator styles={{ root: { height: "1px" } }} />
                    <Footer>
                        <AutoGrid columnGap={"15px"} dc={"85px 115px 85px"}>
                            <ThemedButton command={viewModel.submitCommand} displayName="Create" paletteColor={"primary"} styles={{ root: { padding: "0 5px" } }} value={false} />
                            <ThemedButton
                                command={viewModel.submitCommand}
                                displayName="Create & assign"
                                paletteColor={"primary"}
                                styles={{ root: { padding: "0 5px" } }}
                                value={true}
                            />
                            <ThemedButton command={viewModel.cancelCommand} displayName="Cancel" paletteColor={"default"} styles={{ root: { padding: "0 5px" } }} />
                        </AutoGrid>
                    </Footer>
                </React.Fragment>
            )}
        </Box>
    );
});
