import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import * as React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import styled from "@emotion/styled";
import { HomeViewModel } from "./HomeViewModel";
import { mergeStyleSets, Image, PersonaSize } from "@fluentui/react";
import { AutoCell, AutoGrid, Box, Nullcommand, pxToRem, setPageTitle, ThemedButton, ThemedText } from "Application";
import house from "Assets/housesmall.png";
//app
import logo from "Assets/logo.svg";
import { ThemedPersona } from "Styles/ThemedPrimitives/Style1/ThemedPersona";
import LogoutView from "Views/Logout/LogoutView";

const Wrapper = styled(Box)`
    display: grid;
    grid-template-columns: 228px 1fr;
    height: calc(100vh - 161px);
    & > * {
        height: 100%;
    }
`;

const SidePanel = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 40px 30px 137px 30px;

    & > :first-child {
        margin-bottom: 30px;
    }
    & > :nth-child(2) {
        margin-bottom: 66px;
    }
`;

interface IBackgroundImgHolderProps {
    backgroundImage: string;
}

const BackgroundImgHolder = styled.div<IBackgroundImgHolderProps>`
    content: "";
    height: 100%;
    width: 100%;
    background-image: url(${(props) => props.backgroundImage});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
`;

const ArchitectDetails = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    & > :first-child {
        margin-bottom: 10px;
    }
`;

interface ICustomAvatarProps {
    backgroundImage: string;
}

const CustomAvatar = styled.div<ICustomAvatarProps>`
    content: "";
    height: 96px;
    width: 96px;
    border-radius: 50%;
    background-image: url(${(props) => props.backgroundImage});
    background-image: url(${(props) => props.backgroundImage});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
`;

const ButtonGroup = styled(Box)`
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 10px;
`;

export const HomeView: React.FC = observer(() => {
    const [viewModel] = useState(() => new HomeViewModel());

    useEffect(() => {
        setPageTitle("Hello");
    }, []);

    return (
        <>
            <Wrapper>
                <SidePanel>
                    <Image src={logo} width={168} />
                    <ArchitectDetails>
                        <CustomAvatar backgroundImage={viewModel.userDocumentUrl} />
                        <ThemedText fontStyle="h2">Hi, {viewModel.userDisplayName}</ThemedText>
                    </ArchitectDetails>
                    <ButtonGroup>
                        <ThemedButton displayName="My Work" paletteColor="primary" command={viewModel.setStaffDashboardMyWorkTabCommand} />
                        <ThemedButton displayName="Weekly Update" paletteColor="primary" command={viewModel.setStaffDashboardWeeklyUpdateTabCommand} />
                        <ThemedButton displayName="Book Leave" paletteColor="primary" command={viewModel.navigateToLeaveCommand} />
                        <ThemedButton displayName="Log me out" paletteColor="primary" command={viewModel.navigateToLogoutCommand} />
                    </ButtonGroup>
                </SidePanel>
                <BackgroundImgHolder backgroundImage={viewModel.backgroundImgURL} />
            </Wrapper>
            <LogoutView isShow={viewModel.logout} onClose={() => viewModel.logoutClose.execute()} />
        </>
    );
});
