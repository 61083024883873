import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Routes, Navigate } from "react-router-dom";

import { LeaveRequestsView } from "./Table/LeaveRequestsView";
import { LeaveCalendarView } from "./Calendar/LeaveCalendarView";

export const LeaveView: React.FC = observer(() => {
    return (
        <Routes>
            <Route path={"requests"} element={<LeaveRequestsView />} />
            <Route path={"calendar"} element={<LeaveCalendarView />} />
            <Route path={"*"} element={<Navigate to="requests" replace />} />
        </Routes>
    );
});
