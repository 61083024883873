import { ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

import { APIClient, RelayCommand } from "Application";
import { CustomerViewModel } from "Views/Shared/Customer/CustomerViewModel";
import { LetterViewModel } from "Views/Shared/Letter/LetterViewModel";
import { ServicesViewModel } from "Views/Shared/Services/ServicesViewModel";
import { TermsAndConditionsViewModel } from "Views/Shared/TermsAndConditions/TermsAndConditionsViewModel";
import { QuoteViewModel } from "Views/Shared/Quote/QuoteViewModel";
import { GETQuoteHistoryWithRelatedByIdEndpoint } from "./Endpoints/GETQuoteHistoryWithRelatedByIdEnpoint";
import { QuoteHistoryModel } from "./QuoteHistoryModel";

export class QuoteHistoryViewModel extends ViewModelBase<QuoteHistoryModel> {
    private quoteHistoryId: string = "";

    public apiClient = new APIClient();

    public quoteViewModel: QuoteViewModel;
    public customerViewModel: CustomerViewModel;
    public letterViewModel: LetterViewModel;
    public servicesViewModel: ServicesViewModel;
    public termsAndConditionsViewModel: TermsAndConditionsViewModel;

    constructor(quoteHistoryId: string) {
        super(new QuoteHistoryModel());

        this.quoteHistoryId = quoteHistoryId;
        this.quoteViewModel = new QuoteViewModel(this.model.quote);
        this.customerViewModel = new CustomerViewModel(this.model.customer);
        this.letterViewModel = new LetterViewModel(this.model.letterModel);
        this.servicesViewModel = new ServicesViewModel(this.model.servicesModel);
        this.termsAndConditionsViewModel = new TermsAndConditionsViewModel(this.model.termsAndConditionsModel);

        makeObservable(this, {
            // Observables
        });

        const _ = this.apiClient.sendAsync(new GETQuoteHistoryWithRelatedByIdEndpoint(this.quoteHistoryId, this));
    }

    //region get

    // #region Commands

    public resetServerErrorCommand = new RelayCommand(() => {
        this.apiClient.reset();
    });

    // #endregion Commands
}
