import { ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

import { ICommand, RelayCommand } from "Application/Commands";
import { ServiceTaskModel } from "./ServiceTaskModel";

export class ServiceTaskViewModel extends ViewModelBase<ServiceTaskModel> {
    constructor(serviceTask: ServiceTaskModel = new ServiceTaskModel()) {
        super(serviceTask);

        makeObservable(this, {
            // Observables
        });
    }

    // #region Properties

    public get KEY() {
        return this.model.KEY;
    }

    public get displayName() {
        return this.model.taskName;
    }

    public get showPlanningApplicationInfoIcon() {
        return this.model.isLinkedToPlanningApplication;
    }

    // #endregion Properties

    // #region Commands

    /**
     * Command to toggle selection of this service.
     */
    public toggleEnableServiceTask: ICommand = new RelayCommand(() => {
        this.model.enabled = !this.model.enabled;
    });

    // #endregion Commands
}
