import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

import { ProjectLite } from "Application/Models/Domain";
import { Validator } from "Application/Validation";
import { QUOTESTATUS_DRAFTTYPE } from "../Constants";
import { User } from "Application/Models/Domain/User";

export class QuoteModel extends ModelBase<QuoteModel> {
    // This is not part of the form model, but used for validation.
    public saveQuoteStatusType: string | null = null;
    public enquiryId: string | null = null;
    public isQuickQuote: boolean = false;
    public isLost: boolean = false;
    public projectTypeId = "";
    public title: string = "";
    public leadInWeeks: number | null = null;
    public durationWeeks: number | null = null;
    public confidence: number | null = null;
    public desirability: number | null = null;
    public projectId: string | null = null;
    public projectsList = observable<ProjectLite>([]);
    public quoteLeads = observable<User>([]);
    public quoteDate: Date = new Date();
    public quoteLeadId: string | null = null;
    public lostNoteSubTypeId: string | null = null;
    public lostNoteDetail: string | null = null;
    public approvedDate: Date | null = null;
    public approvedByUser: User | null = null;
    constructor() {
        super();

        makeObservable(this, {
            isQuickQuote: observable,

            // Observables
            projectTypeId: observable,
            title: observable,
            leadInWeeks: observable,
            durationWeeks: observable,
            confidence: observable,
            desirability: observable,
            projectId: observable,
            projectsList: observable,
            lostNoteSubTypeId: observable,
            lostNoteDetail: observable,
            quoteLeadId: observable,
            quoteDate: observable,
            isLost: observable,
            approvedDate: observable,
            approvedByUser: observable,
        });
    }
}

export class QuoteModelValidator extends Validator<QuoteModel> {
    constructor() {
        super();

        this.ruleFor("projectTypeId").notNull().withMessage("Please select a project type").notEmpty().withMessage("Please select a project type");
        this.ruleFor("title").notNull().notEmpty().withMessage("Please provide a job name");
        //this.ruleFor("quoteDate").notNull().withMessage("Please provide a quote date");

        this.ruleFor("leadInWeeks").must({
            predicate: (leadInWeeks, model) => {
                return model.isQuickQuote ? true : model.saveQuoteStatusType === QUOTESTATUS_DRAFTTYPE ? true : (leadInWeeks ?? 0) > 0;
            },
            message: (value, model) => "Please provide a value",
        });
        this.ruleFor("durationWeeks").must({
            predicate: (durationWeeks, model) => {
                return model.isQuickQuote ? true : model.saveQuoteStatusType === QUOTESTATUS_DRAFTTYPE ? true : (durationWeeks ?? 0) > 0;
            },
            message: (value, model) => "Please provide a value",
        });
        this.ruleFor("confidence").must({
            predicate: (confidence, model) => {
                return model.isQuickQuote ? true : model.saveQuoteStatusType === QUOTESTATUS_DRAFTTYPE ? true : (confidence ?? 0) > 0;
            },
            message: (value, model) => "Please provide a value",
        });
        this.ruleFor("desirability").must({
            predicate: (desirability, model) => {
                return model.isQuickQuote ? true : model.saveQuoteStatusType === QUOTESTATUS_DRAFTTYPE ? true : (desirability ?? 0) > 0;
            },
            message: (value, model) => "Please provide a value",
        });
    }
}
