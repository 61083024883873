import React from "react";
import { observer } from "mobx-react-lite";
import { Box, ICommand, ThemedButton } from "Application";
import styled from "@emotion/styled";
import warning from "Assets/warning.png";
import { Modal } from "@fluentui/react";

export interface IQuickQuoteWarningModalViewProps {
    onCancel: ICommand;
    onConfirm: ICommand;
    show: boolean;
}

export const Root = styled.div`
    font-family: "Poppins", sans-serif;
    text-align: center;
    max-width: 365px;
    width: 100%;
    padding-top: 20px;
`;

export const Header = styled.span`
    font-size: 18px;
    text-align: center;
    display: block;
    color: #dc3545;
    font-weight: 600;
    padding: 10px 0;
`;

export const Body = styled.div`
    font-size: 12px;
    line-height: 18px;
    color: #171716;
    margin: 15px 0 22px;
    padding: 0px 10px;
`;

export const Footer = styled.div`
    display: grid;
    grid-template-columns: 140px 140px;
    column-gap: 20px;
    padding: 30px 30px;
    border-top: 1px solid #dcdcdc;
`;

export const QuickQuoteWarningModal: React.FC<IQuickQuoteWarningModalViewProps> = observer(({ onCancel, onConfirm, show }) => {
    const onDismiss = () => {
        if (!onCancel.canExecute()) return;
        onCancel.execute();
    };

    return (
        <>
            {show && (
                <Modal isOpen={show} onDismiss={onDismiss}>
                    <Root>
                        <Box>
                            <img src={warning} height={"100"} width={"100"} alt={"warning"} />
                        </Box>
                        <Header>Warning</Header>
                        <Body>
                            <p>This quick quote is not linked to a project.</p>
                            <p>Do you wish to continue?</p>
                        </Body>
                        <Footer>
                            <ThemedButton paletteColor="primary" command={onConfirm} displayName="Continue" styles={{ root: { padding: "0 20px" } }} />
                            <ThemedButton command={onCancel} displayName="Cancel" styles={{ root: { padding: "0 20px" } }} />
                        </Footer>
                    </Root>
                </Modal>
            )}
        </>
    );
});
