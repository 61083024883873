import { IColumn, Modal, PersonaSize } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { Box, setPageTitle, ThemedButton, ThemedText } from "Application";
import { ThemedDataTable } from "Styles/ThemedPrimitives/Style1/ThemedDataTable";
import { ThemedError } from "Styles/ThemedPrimitives/Style1/ThemedError";
import { ThemedLoader } from "Styles/ThemedPrimitives/Style1/ThemedLoader";
import { ThemedPersona } from "Styles/ThemedPrimitives/Style1/ThemedPersona";
import { DeleteLeaveRequestView } from "Views/Leave/Shared/ModalViews/DeleteLeaveRequest/DeleteLeaveRequestView";
import { NewRequestView } from "Views/Leave/Shared/ModalViews/NewRequest/NewRequestView";
import { LeaveRequestItemViewModel } from "./LeaveRequestItemViewModel";
import { LeaveRequestsViewModel } from "./LeaveRequestsViewModel";
import { ApproveRequestView } from "../Shared/ModalViews/ApproveRequest/ApproveRequestView";
import { DeclineRequestView } from "../Shared/ModalViews/DeclineRequest/DeclineRequestView";
import { NewRequestView as NewRequestWithUserSelectView } from "../Shared/ModalViews/NewRequest/NewRequestView";
import { DashboardViewModel } from "Views/Dashboard/DashboardViewModel";
import { LeaveRequestsModel } from "./LeaveRequestsModel";

const ThemedButtonStyles = {
    root: {
        height: 30,
        width: 147,
        fontSize: 17,
    },
};

interface IProps {
    dashboardViewModel?: DashboardViewModel;
    hideCalendar?: boolean;
}

export const LeaveRequestsView: React.FC<IProps> = observer((props: IProps) => {
    // #region Code Behind

    const [viewModel] = useState(() => new LeaveRequestsViewModel(new LeaveRequestsModel(), props.dashboardViewModel));

    useEffect(() => {
        setPageTitle("Leave requests");
    }, []);

    /**
     * Handles updating the tables column sort settings in response to changes in the viewmodel.
     */
    useEffect(() => {
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter((currCol) => viewModel.getValue("sortKey") === currCol.key)[0];

        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSorted = true;
                currColumn.isSortedDescending = viewModel.getValue("sortDescending");
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = false;
            }
        });

        setColumns(newColumns);
    }, [viewModel.getValue("sortKey"), viewModel.getValue("sortDescending")]);

    /**
     * Gets a key for the row. Must be provided if sorting or filtering is enabled.
     *
     * @param item The viewmodel data associated with the table row.
     * @param index The row index.
     *
     * @returns A string key to identify the row.
     */
    const getRowKey = (item: LeaveRequestItemViewModel, index?: number): string => {
        return item.KEY;
    };

    const onRenderUserName = (item: LeaveRequestItemViewModel, index?: number, column?: IColumn) => {
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <ThemedPersona styles={{ primaryText: { fontSize: "10px" } }} displayName={item.renderNameContentString} size={PersonaSize.size32} imageUrl={item.thumbnailUrl} />
            </div>
        );
    };

    const onRenderLeaveType = (item: LeaveRequestItemViewModel, index?: number, column?: IColumn) => {
        return <Box fontSize={"10px"}>{item.renderLeaveTypeContentString}</Box>;
    };

    const onRenderRequestFromDate = (item: LeaveRequestItemViewModel, index?: number, column?: IColumn) => {
        return <Box fontSize={"10px"}>{item.requestFromContentString}</Box>;
    };

    const onRenderRequestToDate = (item: LeaveRequestItemViewModel, index?: number, column?: IColumn) => {
        return <Box fontSize={"10px"}>{item.requestToContentString}</Box>;
    };

    const onRenderRequestActions = (item: LeaveRequestItemViewModel, index?: number, column?: IColumn) => {
        return (
            <Box display="flex" marginLeft="auto" marginRight="auto">
                {item.canDisplayApprove && (
                    <ThemedButton
                        styles={{ root: { margin: "0 15px", minWidth: "80px", padding: "0 5px" } }}
                        paletteColor={"quarternary"}
                        displayName="Approve"
                        command={viewModel.displayApproveRequestCommand}
                        value={item.model.leaveRequest as any}
                    />
                )}
                {item.canDisplayDecline && (
                    <ThemedButton
                        styles={{ root: { margin: "0 15px", minWidth: "80px", padding: "0 5px" } }}
                        paletteColor={"tertiary"}
                        displayName="Decline"
                        command={viewModel.displayDeclineRequestCommand}
                        value={item.model.leaveRequest as any}
                    />
                )}
                {item.canDisplayEdit && (
                    <ThemedButton
                        styles={{ root: { margin: "0 15px", minWidth: "80px", padding: "0 5px" } }}
                        paletteColor={"primary"}
                        displayName="Edit"
                        command={viewModel.displayEditRequestCommand}
                        value={item.model.leaveRequest as any}
                    />
                )}
                {item.canDisplayDelete && (
                    <ThemedButton
                        styles={{ root: { margin: "0 15px", minWidth: "80px", padding: "0 5px" } }}
                        paletteColor={"primary"}
                        displayName="Delete"
                        command={viewModel.displayDeleteRequestCommand}
                        value={item.model.leaveRequest as any}
                    />
                )}
            </Box>
        );
    };

    /**
     * Handles an onClick event for the table column header.
     *
     * @param ev Mouse event
     * @param column The column that has been clicked.
     */
    const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const currColumn: IColumn = columns.filter((currCol) => column.key === currCol.key)[0];
        const newSortKey = currColumn.key;
        const newSortDescending = currColumn.key === viewModel.getValue("sortKey") ? !currColumn.isSortedDescending : false;

        viewModel.updateSortCommand.execute(newSortKey, newSortDescending);
    };

    const defaultTableColumns: IColumn[] = [
        {
            fieldName: "name",
            key: "name",
            name: "USER",
            minWidth: 300,
            isResizable: true,
            isSorted: true,
            isSortedDescending: false,
            onColumnClick: onColumnClick,
            onRender: onRenderUserName,
        },
        {
            fieldName: "leaveType",
            key: "leaveType",
            name: "LEAVE TYPE",
            maxWidth: 200,
            minWidth: 200,
            isResizable: true,
            isSorted: true,
            isSortedDescending: false,
            onColumnClick: onColumnClick,
            onRender: onRenderLeaveType,
        },
        {
            fieldName: "requestFrom",
            key: "requestFrom",
            name: "REQUEST FROM",
            maxWidth: 200,
            minWidth: 200,
            isResizable: true,
            isSorted: true,
            isSortedDescending: false,
            onColumnClick: onColumnClick,
            onRender: onRenderRequestFromDate,
        },
        {
            fieldName: "requestTo",
            key: "requestTo",
            name: "REQUEST TO",
            maxWidth: 200,
            minWidth: 200,
            isResizable: true,
            isSorted: true,
            isSortedDescending: false,
            onColumnClick: onColumnClick,
            onRender: onRenderRequestToDate,
        },
        {
            fieldName: "numberOfDays",
            key: "numberOfDays",
            name: "NO. OF DAYS",
            maxWidth: 200,
            minWidth: 200,
            isResizable: true,
            isSorted: true,
            isSortedDescending: false,
            onColumnClick: onColumnClick,
        },
        {
            fieldName: "numberOfDaysLeft",
            key: "numberOfDaysLeft",
            name: "REMAINING ALLOWANCE",
            maxWidth: 200,
            minWidth: 200,
            isResizable: true,
            isSorted: true,
            isSortedDescending: false,
            onColumnClick: onColumnClick,
        },
        {
            fieldName: "",
            key: "actions",
            name: "",
            minWidth: 440,
            isResizable: false,
            isSorted: false,
            onRender: onRenderRequestActions,
        },
    ];

    const [columns, setColumns] = useState<IColumn[]>(defaultTableColumns);

    // #endregion Code Behind

    const renderBusy = () => <ThemedLoader isOpen={viewModel.apiClient.IsBusy} />;

    const renderError = () => {
        const isOpen = viewModel.apiClient.IsSubmitted && !viewModel.apiClient.IsBusy && viewModel.apiClient.HaveValidationMessage;
        return <ThemedError command={viewModel.resetServerErrorCommand} isOpen={isOpen} errorMessage={viewModel.apiClient.ValidationMessage} />;
    };

    const renderNewRequestWithUserSelectModal = () => {
        return (
            <Modal isOpen={viewModel.canDisplayWithUserSelectNewRequest} onDismiss={() => viewModel.cancelNewRequestWithUserSelectCommand.execute()}>
                <NewRequestWithUserSelectView viewModel={viewModel.newRequestWithUserSelectViewModel} />
            </Modal>
        );
    };

    const renderEditRequestModal = () => {
        return (
            <Modal isOpen={viewModel.canDisplayEditRequest} onDismiss={() => viewModel.cancelEditRequestCommand.execute()}>
                <NewRequestView viewModel={viewModel.editRequestViewModel} />
            </Modal>
        );
    };

    const renderApproveRequestModal = () => {
        return (
            <Modal isOpen={viewModel.canDisplayApproveRequest} onDismiss={() => viewModel.cancelApproveRequestCommand.execute()}>
                <ApproveRequestView viewModel={viewModel.approveRequestViewModel} />
            </Modal>
        );
    };

    const renderDeclineRequestModal = () => {
        return (
            <Modal isOpen={viewModel.canDisplayDeclineRequest} onDismiss={() => viewModel.cancelDeclineRequestCommand.execute()}>
                <DeclineRequestView viewModel={viewModel.declineRequestViewModel} />
            </Modal>
        );
    };

    const renderDeleteLeaveRequestModal = () => {
        return (
            <Modal isOpen={viewModel.canDisplayDeleteRequest} onDismiss={() => viewModel.cancelDeleteRequestCommand.execute()}>
                <DeleteLeaveRequestView viewModel={viewModel.deleteLeaveRequestViewModel} />
            </Modal>
        );
    };

    const renderWorkspace = () => {
        return (
            <React.Fragment>
                <Box alignItems={"center"} display={"flex"} justifyContent={"space-between"}>
                    <Box alignItems="baseline" display="flex">
                        {!props.hideCalendar && (
                            <>
                                <ThemedText fontStyle="h3" mr={3}>
                                    Requests
                                </ThemedText>
                                <Box
                                    alignItems={"center"}
                                    display={"flex"}
                                    justifyContent={"space-between"}
                                    onClick={() => viewModel.navigateToCalendarCommand.execute()}
                                    style={{ cursor: "pointer" }}
                                >
                                    <ThemedText>VIEW CALENDAR {">"}</ThemedText>
                                </Box>
                            </>
                        )}
                    </Box>

                    <ThemedButton
                        command={viewModel.displayNewRequestWithUserSelectCommand}
                        displayName="New request"
                        marginTop={3}
                        paletteColor={"primary"}
                        styles={ThemedButtonStyles}
                    />
                </Box>
                <ThemedDataTable getKey={getRowKey} items={viewModel.sortedLeaveRequests} columns={columns} />
            </React.Fragment>
        );
    };

    return (
        <Box>
            {/* Workspace */}
            {renderWorkspace()}

            {/* Busy Overlay */}
            {renderBusy()}

            {/* Error Overlay */}
            {renderError()}

            {/* New Request With User Select Modal*/}
            {renderNewRequestWithUserSelectModal()}

            {/* Edit Request Modal*/}
            {renderEditRequestModal()}

            {/* Approve Request Modal*/}
            {renderApproveRequestModal()}

            {/* Decline Request Modal*/}
            {renderDeclineRequestModal()}

            {/* Delete Leave Request Modal*/}
            {renderDeleteLeaveRequestModal()}
        </Box>
    );
});
