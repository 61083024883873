import { formatCurrency, ViewModelBase } from "@shoothill/core";
import { computed, makeObservable } from "mobx";

import { APIClient, formatFixedDecimalNumber, theme } from "Application";
import { InvoiceForecastingCapacityModel, InvoiceForecastingModel } from "./InvoiceForecastingModel";
import { GetAdminInvoicingForecastEndpoint } from "../../../Endpoints/GETAdminInvoicingForecastEndpoint";

export class InvoiceForecastingViewModel extends ViewModelBase<InvoiceForecastingModel> {
    public apiClient = new APIClient();

    constructor(model: InvoiceForecastingModel) {
        super(model);

        makeObservable(this, {
            totalInstructedHours: computed,
            totalInstructedValue: computed,
            totalCapacityHours: computed,
            totalCapacityValue: computed,
            totalSentQuoteHours: computed,
            totalSentQuoteValue: computed,
            capacities: computed,
            canDisplayResults: computed,
        });
    }

    // #region Properties

    public get canDisplayResults(): boolean {
        return !this.apiClient.IsBusy;
    }

    public get isLoading(): boolean {
        return this.apiClient.IsBusy;
    }

    public get totalInstructedHours(): string {
        return formatFixedDecimalNumber(this.model.totalInstructedHours);
    }

    public get totalInstructedValue(): string {
        return formatCurrency(this.model.totalInstructedValue);
    }

    public get totalCapacityHours(): string {
        return formatFixedDecimalNumber(this.model.totalCapacityHours);
    }

    public get totalCapacityValue(): string {
        return formatCurrency(this.model.totalCapacityValue);
    }

    public get totalRemainingHolidayHours(): string {
        return formatFixedDecimalNumber(this.model.totalRemainingHolidayHours);
    }

    public get totalRemainingHolidayValue(): string {
        return formatCurrency(this.model.totalRemainingHolidayValue);
    }

    public get totalForecastedCapacityHours(): string {
        return formatFixedDecimalNumber(this.model.totalForecastedCapacityHours);
    }

    public get totalForecastedCapacityValue(): string {
        return formatCurrency(this.model.totalForecastedCapacityValue);
    }

    public get totalSentQuoteHours(): string {
        return formatFixedDecimalNumber(this.model.totalSentQuoteHours);
    }

    public get totalSentQuoteValue(): string {
        return formatCurrency(this.model.totalSentQuoteValue);
    }

    public get capacities() {
        return this.model.capacities;
    }

    public capacityHours(hours: number): string {
        return formatFixedDecimalNumber(hours);
    }

    public capacityValue(value: number): string {
        return formatCurrency(value);
    }

    public capacityTextColour = (model: InvoiceForecastingCapacityModel) => {
        return model.isActive ? theme.palette.quarternary.main : "inherit";
    };

    public capacityTextFontWeight = (model: InvoiceForecastingCapacityModel) => {
        return model.isActive ? "bold" : "inherit";
    };

    // #endregion Properties
}
