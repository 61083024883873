import { FieldType, ViewModelBase } from "@shoothill/core";
import { makeObservable, observable, action, computed } from "mobx";

import { APIClient, formatDecimalNumber, IKeyState, RelayCommand } from "Application";
import { ServiceModel, ServiceModelValidator } from "./ServiceModel";
import { ServiceGroupViewModel } from "../ServiceGroup/ServiceGroupViewModel";
import { ViewModeType } from "Application/Types/ViewModeType"; // Import ViewModeType

// Define an interface for the properties
interface IServiceViewModelProps {
    apiClient: APIClient;
    parentViewModel: ServiceGroupViewModel;
    viewMode: ViewModeType; // Use ViewModeType
    isDeleteModalOpen: boolean;
    draftServiceNameValue: string;
}

export class ServiceViewModel extends ViewModelBase<ServiceModel> implements IServiceViewModelProps {
    // Observables
    public apiClient = new APIClient();
    public parentViewModel: ServiceGroupViewModel;
    public viewMode: ViewModeType = "Read"; // Initialize with a default mode
    public isDeleteModalOpen = false;
    public draftServiceNameValue: string = this.model.name;

    constructor(parentViewModel: ServiceGroupViewModel, model = new ServiceModel()) {
        super(model, false);

        this.parentViewModel = parentViewModel;
        this.setValidator(new ServiceModelValidator());

        makeObservable(this, {
            viewMode: observable,
            isDeleteModalOpen: observable,

            setViewMode: action,
            setDraftServiceName: action,
            setIsDeleteModalOpen: action,
            draftServiceName: computed,
        });
    }

    //Computed Values

    public get isInAddMode() {
        return this.viewMode === "Add";
    }

    public get isInEditMode() {
        return this.viewMode === "Edit";
    }

    public get isInReadOnlyMode() {
        return this.viewMode === "Read";
    }

    // Actions

    public setViewMode = (mode: ViewModeType) => {
        this.viewMode = mode;
    };

    public toggleViewMode = () => {
        this.viewMode = this.viewMode === "Edit" ? "Read" : "Edit";
    };

    public setIsDeleteModalOpen = (value: boolean) => (this.isDeleteModalOpen = value);

    public setDraftServiceName = (value: string) => (this.draftServiceNameValue = value);

    // Computed Values
    public get draftServiceName() {
        return this.draftServiceNameValue;
    }

    public onRenderNumber(value: number) {
        return formatDecimalNumber(value);
    }

    // Commands
    public beginUpdateServiceCommand = new RelayCommand(() => {
        this.setViewMode("Edit");
    });

    public submitUpdateServiceCommand = new RelayCommand(() => {
        //send api call to update service group name
        this.parentViewModel.upsertService(this.model);
        this.setViewMode("Read");
    });

    public cancelUpdateServiceCommand = new RelayCommand(() => {
        this.setValue("name", this.draftServiceName);
        this.setDirty("name", false);
        this.setViewMode("Read");
    });

    public beginDeleteServiceCommand = new RelayCommand(() => {
        this.setIsDeleteModalOpen(true);
    });

    public confirmDeleteServiceCommand = new RelayCommand(() => {
        this.parentViewModel.deleteServiceCommand.execute(this.model.id);
        this.setIsDeleteModalOpen(false);
    });

    public cancelDeleteServiceCommand = new RelayCommand(() => {
        this.setIsDeleteModalOpen(false);
    });

    public updateNameCommand = new RelayCommand((value: string) => {
        this.updateField("name", value);
    });

    public updateHourlyRateCommand = new RelayCommand((value: string) => {
        const parsedValue = parseFloat(value);
        const checkedValue = Number.isNaN(parsedValue) ? null : parsedValue;

        this.updateField("hourlyRate", checkedValue);
    });

    public updateManHoursCommand = new RelayCommand((value: string) => {
        const parsedValue = parseFloat(value);
        const checkedValue = Number.isNaN(parsedValue) ? null : parsedValue;

        this.updateField("manHours", checkedValue);
    });

    public updateIsLinkedToPlanningApplicationCommand = new RelayCommand((value: boolean) => {
        this.updateField("isLinkedToPlanningApplication", value);
    });

    public incrementServicePositionCommand = new RelayCommand(
        () => {
            const previousServices = this.parentViewModel.model.serviceModels.filter((model) => model.ordinal < this.model.ordinal);

            this.parentViewModel.swapServiceOrdinalCommand.execute(this.model.id, previousServices[previousServices.length - 1].id);
        },
        () => this.isFirstService == false,
    );

    public decrementServicePositionCommand = new RelayCommand(
        () => {
            const nextServices = this.parentViewModel.model.serviceModels.filter((model) => model.ordinal > this.model.ordinal);
            this.parentViewModel.swapServiceOrdinalCommand.execute(this.model.id, nextServices[0].id);
        },
        () => this.isLastService == false,
    );

    public submitAddNewServiceCommand = new RelayCommand(
        () => {
            this.parentViewModel.submitNewServiceCommand.execute();
        },
        () => this.parentViewModel.submitNewServiceCommand.canExecute(),
    );

    public cancelAddNewServiceCommand = new RelayCommand(
        () => {
            this.parentViewModel.cancelAddNewServiceCommand.execute();
        },
        () => this.parentViewModel.cancelAddNewServiceCommand.canExecute(),
    );

    // Helpers
    public get isFirstService() {
        const retVal = this.parentViewModel.model.serviceModels.indexOf(this.model) == 0;
        return retVal;
    }

    public get isLastService() {
        return this.parentViewModel.model.serviceModels.indexOf(this.model) == this.parentViewModel.model.serviceModels.length - 1;
    }

    private updateField(fieldName: keyof FieldType<ServiceModel>, value: any, keyState?: IKeyState) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }
}
