import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { EnquiryViewModel } from "./EnquiryViewModel";
import { EnquiryModel } from "./EnquiryModel";

class Request {
    id: string | null = null;
}

class Response {
    id: string = "";
}

export class POSTSaveEnquiryAsQuoteEndpoint extends Endpoint<Request, Response> {
    private readonly viewModel: EnquiryViewModel;

    constructor(viewModel: EnquiryViewModel) {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Enquiries.SaveEnquiryAsQuote);
        this.viewModel = viewModel;
    }

    public async HandleRequestAsync(model: EnquiryModel): Promise<any> {
        const request = new Request();

        request.id = model.id;

        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: Response): Promise<void> {
        Logger.logInformation("Response", response);

        this.viewModel.history.push(AppUrls.Client.Quotes.Edit.replace(":id", response.id));
    }
}
