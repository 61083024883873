import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import { AutoGrid, Box, setPageTitle, theme, ThemedButton, ThemedComboBox, ThemedText, ThemedTransparentIconButton } from "Application";
import { ThemedLoader } from "Styles/ThemedPrimitives/Style1/ThemedLoader";
import { ThemedError } from "Styles/ThemedPrimitives/Style1/ThemedError";
import { FormContainer, FormContainerRow, FormSeparator, FormSubContainer } from "Views/Shared/SharedComponents";
import { ProjectWeeklyUpdateContainerViewModel } from "./ProjectWeeklyUpdateContainerViewModel";
import { WeeklyUpdateHistoryView } from "./History/WeeklyUpdateHistoryView";
import { WeeklyUpdateViewModel } from "../WeeklyUpdateViewModel";
import { IStyle, mergeStyleSets } from "@fluentui/react";
import { CrossSVG } from "Assets/CrossSVG";
import { Loader } from "Components/Loader";
import { LoadingSpinner } from "Components/LoadingSpinner2";
import { height } from "styled-system";
import styled from "@emotion/styled";

interface IProjectWeeklyUpdateTemplatesViewStyles {
    root: IStyle;
    header: IStyle;
    body: IStyle;
    separator: IStyle;
    footer: IStyle;
    iconButtonContainer: IStyle;
    iconButton: IStyle;
}

const styleDefinitions: IProjectWeeklyUpdateTemplatesViewStyles = {
    root: {
        width: "100%",
        height: "100%",
        backgroundColor: "#F3F3F3",
        position: "relative",
    },
    header: {
        alignItems: "center",
        backgroundColor: theme.palette.primary.main,
        display: "flex",
        height: "70px",
        justifyContent: "space-between",
        padding: "20px 30px",
    },
    body: {
        padding: "30px 30px 0px 30px",
    },
    separator: {
        "&&": {
            lineHeight: "0px",
            margin: "0px 30px",
        },
    },
    footer: {
        position: "absolute",
        bottom: "0px",
        display: "flex",
        height: "75px",
        padding: "20px 30px",
    },
    iconButtonContainer: {
        backgroundColor: "gold",
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    iconButton: {
        g: {
            stroke: theme.palette.common.white,
        },
    },
};

interface Props {
    viewModel: ProjectWeeklyUpdateContainerViewModel;
}

export const ProjectWeeklyUpdateContainerView: React.FC<Props> = observer((props: Props) => {
    // #region Code Behind
    const { viewModel } = props;

    const styles = mergeStyleSets(styleDefinitions);

    useEffect(() => {
        setPageTitle("Project weekly update");

        if (viewModel) {
            return () => viewModel.dispose();
        }
    }, []);

    // #endregion Code Behind

    const renderBusy = () => {
        if (viewModel.isBusy) {
            return (
                <>
                    <Loader
                        component={<LoadingSpinner />}
                        delayBeforeShow={500}
                        loadingText="Fetching"
                        containerStyles={{ height: "100%" }}
                        componentStyles={{ marginBottom: "10px" }}
                    />
                </>
            );
        }
    };

    const renderError = () => {
        if (viewModel.hasError) {
            return (
                <>
                    <ThemedText fontStyle="h5" marginBottom={"10px"}>
                        Oops! something went wrong
                    </ThemedText>
                    <ThemedText fontStyle="h5">{viewModel.apiClient.ValidationMessage}</ThemedText>
                </>
            );
        }
    };

    return (
        <Box display="flex" flexDirection="column" flex="1" height={"100vh"} onClick={(e: any) => e.stopPropagation()}>
            <div className={styles.root}>
                <div className={styles.header}>
                    <ThemedText fontStyle="h2" color={"white"}>
                        Previous Weekly Updates
                    </ThemedText>
                    {viewModel && (
                        <ThemedTransparentIconButton
                            command={viewModel.cancelCommand}
                            icon={<CrossSVG className={styles.iconButton} />}
                            shape="round"
                            styles={{
                                rootHovered: {
                                    opacity: "80%",
                                    backgroundColor: "transparent",
                                },
                                rootPressed: {
                                    opacity: "80%",
                                    backgroundColor: "transparent",
                                },
                            }}
                        />
                    )}
                </div>
                <div className={styles.body}>
                    {/* Weekly Update Navigation */}
                    <FormContainerRow dc={"50%"}>
                        {viewModel && (
                            <ThemedComboBox
                                displayName="Week commencing"
                                labelColor="default"
                                options={viewModel.weeklyUpdates}
                                value={() => viewModel.getValue("weeklyUpdateId")}
                                command={viewModel.navigateToWeeklyUpdateCommand}
                                placeholder="Please select"
                                validationMessage={() => viewModel.getError("weeklyUpdateId")}
                            />
                        )}
                    </FormContainerRow>
                    <Box width="100%" flex={1}>
                        {viewModel && (
                            <>
                                <WeeklyUpdateHistoryView viewModel={viewModel.weeklyUpdateHistoryViewModel} />
                                {renderBusy()}
                                {renderError()}
                            </>
                        )}
                    </Box>
                </div>
                <AutoGrid m={0} className={styles.footer} columnGap={"30px"} dc={"135px 135px"}>
                    {viewModel && <ThemedButton styles={{ root: { padding: "0 5px" } }} displayName="Close" command={viewModel.cancelCommand} />}
                </AutoGrid>
            </div>
        </Box>
    );
});
