export class LeaveType {
    public static readonly HOLIDAY = "HOLIDAY";
    public static readonly TRAINING = "TRAINING";
    public static readonly EDUCATION = "EDUCATION";
    public static readonly MEDICALDENTAL = "MEDICALDENTAL";
    public static readonly COMPASSIONATE = "COMPASSIONATE";
    public static readonly SICK = "SICK";
    public static readonly OTHER = "OTHER";
    public static readonly CANCEL = "CANCEL";

    public id: string = "";
    public name: string = "";
    public type: string = "";
    public ordinal: number = 0;
    public isDeleted: boolean = false;
    public foregroundColor: string = "";
    public backgroundColor: string = "";
    public isTransactionRequest: boolean = false;
    public isTransactionCancelRequest: boolean = false;
}
