import { observer } from "mobx-react-lite";
import { useState } from "react";

import { Box, ThemedText, theme } from "Application";
import styled from "@emotion/styled";
import { ISeparatorProps, Separator, mergeStyleSets } from "@fluentui/react";

import React from "react";

interface IExpandableContainerProps {
    displayName: string;
    children: React.ReactNode;
    // Optional props
    childrenHeight?: number;
    openByDefault?: boolean;
}

interface IContainerProps {
    childrenHeight?: number;
}

const Container = styled.div<IContainerProps>`
    .expandable-content {
        overflow: hidden;
        max-height: 0;
        transition: max-height 0.3s ease-in-out;
    }
    .expanded {
        max-height: ${(props) => (props.childrenHeight ? `${props.childrenHeight}px` : "fit-content")};
    }
`;

const CustomSeparator = (props: ISeparatorProps) => (
    <Separator
        {...props}
        styles={mergeStyleSets(
            {
                root: {
                    fontSize: "0",
                    padding: "0 0 2px 0",
                    lineHeight: "1.25",
                    "::before": {
                        backgroundColor: theme.palette.field.light,
                    },
                },
            },
            props.styles,
        )}
    />
);

const CustomDottedSeparator = (props: ISeparatorProps) => (
    <Separator
        {...props}
        styles={mergeStyleSets(
            {
                root: {
                    fontSize: "0",
                    padding: "0 0 2px 0",
                    lineHeight: "1.25",
                    "::before": {
                        backgroundImage: `linear-gradient(to right, ${theme.palette.field.light}, ${theme.palette.field.light} 50%, rgba(255,255,255,0) 50%)`,
                        backgroundPosition: "bottom",
                        backgroundSize: "6px 2px",
                        backgroundRepeat: "repeat-x",
                    },
                },
            },
            props.styles,
        )}
    />
);

export const ExpandableContainer = observer((props: IExpandableContainerProps) => {
    const { displayName, children, openByDefault, childrenHeight } = props;

    const [showAdditionalInformation, setShowAdditionalInformation] = useState(openByDefault ? openByDefault : false);

    const onTitleClick = () => {
        setShowAdditionalInformation(!showAdditionalInformation);
    };

    const renderDisplayName = () => {
        return showAdditionalInformation ? `- ${displayName}` : `+ ${displayName}`;
    };

    return (
        <Container style={{ marginBottom: "10px" }} childrenHeight={childrenHeight}>
            <Box onClick={onTitleClick} display="flex" flexDirection="column" minHeight="40px" style={{ cursor: "pointer" }}>
                <Box display="flex" flex="1" alignItems="center">
                    <ThemedText fontStyle="expandable">{renderDisplayName()}</ThemedText>
                </Box>
                <CustomDottedSeparator />
            </Box>
            <Box className={showAdditionalInformation ? "expandable-content expanded" : "expandable-content"}>
                <Box display="flex">
                    <Box flex={4} padding="25px 0 15px 0">
                        {children}
                    </Box>
                </Box>
                <CustomSeparator />
            </Box>
        </Container>
    );
});
