import { isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { action, computed, makeObservable, observable } from "mobx";

import { APIClient, RelayCommand, RelayCommandAsync } from "Application";
import { UnassignedTasksItemViewModel } from "./UnassignedTasksItemViewModel";
import { UnassignedTasksModel } from "./UnassignedTasksModel";

import { POSTDeleteProjectTaskEndpoint } from "./Endpoints/POSTDeleteProjectTaskEndpoint";

export class UnassignedTasksViewModel extends ViewModelBase<UnassignedTasksModel> {
    public apiClient = new APIClient();
    public tasks = observable<UnassignedTasksItemViewModel>([]);
    public taskIdToDelete: string | null = null;
    public isDeleteModalOpen: boolean = false;

    constructor(projectId: string) {
        super(new UnassignedTasksModel(projectId));

        makeObservable(this, {
            // Observables
            tasks: observable,
            taskIdToDelete: observable,
            isDeleteModalOpen: observable,

            //actions
            setTaskIdToDelete: action,
            setIsDeleteModalOpen: action,

            // Computed values
            canSortTasks: computed,
            filteredAndSortedTasks: computed,
        });
    }

    //region actions

    public setIsDeleteModalOpen = (value: boolean) => (this.isDeleteModalOpen = value);
    public setTaskIdToDelete = (value: string | null) => (this.taskIdToDelete = value);

    public requestItemRemoval = (taskId: string) => {
        this.setTaskIdToDelete(taskId);
        this.setIsDeleteModalOpen(true);
    };

    public isRemoveButtonDisabled = (item: UnassignedTasksItemViewModel) => {
        return false;
    };

    public cancelItemRemovalRequestCommand = new RelayCommand(() => {
        this.setTaskIdToDelete(null);
        this.setIsDeleteModalOpen(false);
    });

    public confirmItemRemovalRequestCommand = new RelayCommandAsync(async () => {
        if (this.taskIdToDelete) {
            await this.deleteUnassignedTask();
            this.setTaskIdToDelete(null);
            this.setIsDeleteModalOpen(false);
        }
        //get programming and related
    });

    public deleteUnassignedTask = async () => {
        this.apiClient.sendAsync(new POSTDeleteProjectTaskEndpoint(this));
    };

    //endregion actions

    //region computed values

    //endregion computed values

    public updateSortCommand = new RelayCommand((key: string, sortDescending: boolean) => {
        this.model.sortKey = key;
        this.model.sortDescending = sortDescending;
    });

    public get canSortTasks(): boolean {
        return !isEmptyOrWhitespace(this.model.sortKey);
    }

    public get filteredAndSortedTasks(): UnassignedTasksItemViewModel[] {
        return this.canSortTasks
            ? this.tasks.slice().sort((lhs, rhs) => {
                  return (this.model.sortDescending ? lhs[this.model.sortKey] < rhs[this.model.sortKey] : lhs[this.model.sortKey] > rhs[this.model.sortKey]) ? 1 : -1;
              })
            : this.tasks;
    }

    public get filteredAndSortedTasksRemainingHours(): number {
        return this.filteredAndSortedTasks
            .filter((item) => {
                return item.remainingProjectTaskBillableEffort > 0;
            })
            .reduce((total, item) => {
                return total + item.remainingProjectTaskBillableEffort;
            }, 0);
    }
}
