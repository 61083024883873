import { mergeStyleSets, Modal } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import { AutoGrid, setPageTitle, theme, ThemedPersonaComboBox } from "Application";
import { ThemedLoader } from "Styles/ThemedPrimitives/Style1/ThemedLoader";
import { ThemedError } from "Styles/ThemedPrimitives/Style1/ThemedError";
import { NotesView } from "Views/Shared/Note/NotesView";
import { FormContainer, FormSubContainer } from "Views/Shared/SharedComponents";
import { DeleteProjectView } from "./ModalViews/DeleteProject/DeleteProjectView";
import { ProjectAsCompleteView } from "./ModalViews/CompleteProject/ProjectAsCompleteView";
import { ProjectDetailsViewModel } from "./ProjectDetailsViewModel";
import { CustomerView } from "./SubViews/Customer/CustomerView";
import { ButtonClusterSubView } from "./SubViews/ButtonClusterSubView";
import { ProjectViewModel } from "../ProjectViewModel";

interface IProps {
    viewModel: ProjectViewModel;
}

const labelStyle = mergeStyleSets({
    root: {
        fontSize: theme.fontStyles.h3.fontSize ?? theme.defaultFontStyle.fontSize,
        fontWeight: theme.fontStyles.h3.fontWeight ?? theme.defaultFontStyle.fontWeight,
        letterSpacing: theme.fontStyles.h3.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
        lineHeight: theme.fontStyles.h3.lineHeight ?? theme.defaultFontStyle.lineHeight,
        textTransform: theme.fontStyles.h3.textTransform ?? theme.defaultFontStyle.textTransform,
    },
});

export const ProjectDetailsView: React.FC<IProps> = observer((props) => {
    // #region Code Behind

    const [viewModel] = useState(() => new ProjectDetailsViewModel(props.viewModel));

    useEffect(() => {
        setPageTitle("Project details");
    }, []);

    // #endregion Code Behind

    const renderBusy = () => <ThemedLoader isOpen={viewModel.apiClient.IsBusy} />;

    const renderError = () => {
        const isOpen = viewModel.apiClient.IsSubmitted && !viewModel.apiClient.IsBusy && viewModel.apiClient.HaveValidationMessage;

        return <ThemedError command={viewModel.resetServerErrorCommand} isOpen={isOpen} errorMessage={viewModel.apiClient.ValidationMessage} />;
    };

    const renderProjectAsCompleteModalContent = () => {
        return (
            <Modal isOpen={viewModel.canDisplayProjectAsCompleteConfirmation} onDismiss={() => viewModel.cancelProjectAsCompleteConfirmationCommand.execute()}>
                <ProjectAsCompleteView viewModel={viewModel.projectAsCompleteViewModel!} />
            </Modal>
        );
    };

    const renderDeleteProjectModalContent = () => {
        return (
            <Modal isOpen={viewModel.canDisplayDeleteProjectConfirmation} onDismiss={() => viewModel.cancelDeleteProjectConfirmationCommand.execute()}>
                <DeleteProjectView viewModel={viewModel.deleteProjectViewModel!} />
            </Modal>
        );
    };

    return (
        <FormContainer>
            <FormSubContainer>
                <AutoGrid mt="-2px" desktopColumns="1fr 1fr 1fr 1fr">
                    <ThemedPersonaComboBox
                        command={viewModel.updateProjectLeadCommand}
                        displayName="Project lead"
                        labelStyle={labelStyle}
                        options={viewModel.projectLeads}
                        placeholder="Please select"
                        value={() => viewModel.getValue("projectLeadId")}
                    />
                    <ThemedPersonaComboBox
                        command={viewModel.updateProjectSupportCommand}
                        displayName="Project support"
                        labelStyle={labelStyle}
                        options={viewModel.projectSupports}
                        placeholder="Please select"
                        value={() => viewModel.getValue("projectSupportId")}
                    />
                    <ThemedPersonaComboBox
                        command={viewModel.updateSeniorAssociateCommand}
                        displayName="Project Overview"
                        labelStyle={labelStyle}
                        options={viewModel.seniorAssociates}
                        placeholder="Please select"
                        value={() => viewModel.getValue("seniorAssociateId")}
                    />
                </AutoGrid>
                <CustomerView viewModel={viewModel.customerViewModel} />
                <NotesView viewModel={viewModel.notesViewModel} />
                <ButtonClusterSubView viewModel={viewModel} />
            </FormSubContainer>

            {/* Busy Overlay */}
            {renderBusy()}

            {/* Error Overlay */}
            {renderError()}

            {/* Complete Modal */}
            {renderProjectAsCompleteModalContent()}

            {/* Delete Modal */}
            {renderDeleteProjectModalContent()}
        </FormContainer>
    );
});
