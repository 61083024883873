import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import React from "react";

import { AutoGrid, Box, ThemedText, ThemedTransparentIconButton } from "Application";
import { LeaveCalendarViewModel } from "../../LeaveCalendarViewModel";
import { DownIcon } from "Assets/Icons/DownIcon";
import { UpIcon } from "Assets/Icons/UpIcon";

interface IProps {
    viewModel: LeaveCalendarViewModel;
}

const RowContainer = styled(AutoGrid)`
    align-items: center;
    background-color: #f3f3f3;
    border-bottom: 1px solid #edebe9;
    flex: 1;
    font-size: 10px;
    font-weight: bold;
    margin: 32px 0px 4px 0px;
    max-height: 44px;
    min-height: 44px;
`;

const UserPartContainer = styled(AutoGrid)`
    margin: 0;
`;

const DatePartContainer = styled(Box)`
    display: flex;
`;

const DayCell = styled(Box)`
    padding: 4px 12px;
`;

const NavigationContainer = styled(AutoGrid)`
    align-items: center;
    padding: 4px 12px;
    margin: 0px 0px 2px 0px;
`;

const NavigationCurrentMonth = styled(ThemedText)`
    font-size: 10px;
    font-weight: bold;
`;

const NavigationButton = styled(ThemedTransparentIconButton)``;

export const HeaderView: React.FC<IProps> = observer((props) => {
    const viewModel = props.viewModel;

    const renderDaysRow = () => {
        return (
            <RowContainer mc="300px 1fr" tc="300px 1fr" dc="300px 1fr" columnGap={0} rowGap={0}>
                <UserPartContainer mc="175px 3fr 2fr" tc="175px 3fr 2fr" dc="175px 3fr 2fr" columnGap={0} rowGap={0}>
                    <DayCell>USER</DayCell>
                    <DayCell>ALLOW.</DayCell>
                    <DayCell>REM.</DayCell>
                </UserPartContainer>
                <DatePartContainer>
                    <NavigationContainer mc="110px 30px 30px" tc="110px 30px 30px" dc="110px 30px 30px" columnGap="4px" rowGap={0}>
                        <NavigationCurrentMonth>{viewModel.currentMonthAndYear.toLocaleUpperCase()}</NavigationCurrentMonth>
                        <NavigationButton command={props.viewModel.previousMonthCommand} icon={<UpIcon />} />
                        <NavigationButton command={props.viewModel.nextMonthCommand} icon={<DownIcon />} />
                    </NavigationContainer>
                </DatePartContainer>
            </RowContainer>
        );
    };

    return <React.Fragment>{renderDaysRow()}</React.Fragment>;
});
