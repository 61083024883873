import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import React from "react";
import ReactHtmlParser from "react-html-parser";

import { Box, ExpandableContainer, ThemedText } from "Application";
import { LetterViewModel } from "./LetterViewModel";
import { FormSeparator } from "Views/Shared/SharedComponents";
import useResizeObserver from "Application/Hooks/UseResizeObserver";
export interface ILetterSubViewReadOnlyProps {
    viewModel: LetterViewModel;
}

const LetterViewContainer = styled(Box)`
    border: 1px solid #dcdcdc;
    height: 400px;
    margin-bottom: 20px;
    overflow-y: auto;

    background-color: white;
    font-size: 12px;
    line-height: 1.75;
    padding: 30px;

    p {
        padding-bottom: 15px;
    }

    ul {
        padding-left: 15px;
        padding-bottom: 15px;
    }
`;

export const LetterReadOnlyView: React.FC<ILetterSubViewReadOnlyProps> = observer((props) => {
    const viewModel = props.viewModel;
    const { ref, observedHeight } = useResizeObserver(90);

    return (
        <React.Fragment>
            {viewModel.canDisplayForQuickQuote && <></>}
            {!viewModel.canDisplayForQuickQuote && (
                <ExpandableContainer displayName="Quote letter" childrenHeight={observedHeight}>
                    <LetterViewContainer>
                        <div ref={ref}>{ReactHtmlParser(viewModel.model.letterDetails as string)}</div>
                    </LetterViewContainer>
                </ExpandableContainer>
            )}
        </React.Fragment>
    );
});
