import { mergeStyleSets, Image } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import logo from "Assets/logo.svg";
import { AutoCell, AutoGrid, pxToRem, ThemedText } from "../../Application";
import SignInView from "./SignIn/SignInView";
import RegisterView from "./SignUp/RegisterView";
import { AccountStore } from "../../Stores/Domain";
import { container } from "tsyringe";
import { GlobalHistory } from "../../index";
import { LoginHomeViewModel } from "./LoginHomeViewModel";
import { ForgotPasswordView } from "./SignIn/ForgotPassword/ForgotPasswordView";
import { Route, Routes } from "react-router-dom";
import { AppUrls } from "AppUrls";

export const styles = mergeStyleSets({
    root: {
        margin: "auto",
        padding: `${pxToRem(30)}`,
    },
    content: {
        maxWidth: `${pxToRem(270)}`,
        margin: "auto",
    },
    logo: {
        marginBottom: `${pxToRem(35)}`,
    },
});

/**
 * Login page view.
 */

const LoginHomeView: React.FunctionComponent = observer(() => {
    // #region Code Behind
    const accountStore = container.resolve(AccountStore);
    const [viewModel] = useState(() => new LoginHomeViewModel());

    useEffect(() => {
        if (accountStore.IsLoggedIn) {
            GlobalHistory.push(AppUrls.Client.DashboardRoot);
        }
    }, []);

    const doSubmit = (e: any) => {
        e.preventDefault();
    };

    // #endregion Code Behind

    const renderForm = () => (
        <React.Suspense>
            <form className={styles.root} onSubmit={doSubmit}>
                <AutoGrid className={styles.content} columnGap={30} rowGap={"7px"} mc={"1fr"} tc={"1fr"} dc={"1fr"}>
                    <AutoCell className={styles.logo} mb="20px">
                        <Image src={logo} />
                    </AutoCell>
                    <Routes>
                        <Route path="login" element={<SignInView loginHomeViewModel={viewModel} />} />
                        <Route path="register/*" element={<RegisterView loginHomeViewModel={viewModel} />} />
                        <Route path="confirmemail/:token/*" element={<RegisterView loginHomeViewModel={viewModel} />} />
                        <Route path="resetpassword/:token/*" element={<ForgotPasswordView loginHomeViewModel={viewModel} />} />
                        <Route path="forgotpassword/*" element={<ForgotPasswordView loginHomeViewModel={viewModel} />} />
                    </Routes>
                </AutoGrid>
            </form>
        </React.Suspense>
    );

    return <React.Fragment>{renderForm()}</React.Fragment>;
});
export default LoginHomeView;
