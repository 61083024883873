import { FieldType, ViewModelBase } from "@shoothill/core";
import { makeObservable, observable, observe } from "mobx";

import { ICommand, RelayCommand } from "Application";
import { TaskAssignmentGroupModel, TaskAssignmentGroupModelValidator } from "./TaskAssignmentGroupModel";
import { TaskAssignmentViewModel } from "./SubViews/TaskAssignmentViewModel";
import { container } from "tsyringe";
import { LookupStore } from "Stores/Domain";

export class TaskAssignmentGroupViewModel extends ViewModelBase<TaskAssignmentGroupModel> {
    private lookupStore = container.resolve(LookupStore);

    // Callback commands to the parent viewmodel.
    private parentCancelCommand: ICommand;
    private parentSubmitCommand: ICommand;

    public taskAssignmentViewModels = observable<TaskAssignmentViewModel>([]);

    constructor(model: TaskAssignmentGroupModel, submitCommand: ICommand, cancelCommand: ICommand) {
        super(model, false);

        this.setValidator(new TaskAssignmentGroupModelValidator());

        this.parentCancelCommand = cancelCommand;
        this.parentSubmitCommand = submitCommand;

        makeObservable(this, {
            // Observables
            taskAssignmentViewModels: observable,
        });
    }

    /**
     * Disposes of the task assignment collection observer. Call this from your view.
     */
    public dispose = (): void => {
        this.taskAssignmentsObserverDispose?.();
    };

    //#region Properties
    public get templateNotes() {
        const options = this.lookupStore.getProjectTaskAssignmentTemplateNotes.map((item) => {
            return {
                key: item.key,
                text: item.text,
            };
        });

        return [...options];
    }

    //#endregion Properties

    //#region Commands

    public updateNameCommand = new RelayCommand((value: string) => {
        this.updateField("name", value);
    });

    public submitCommand = new RelayCommand(
        () => {
            if (this.isModelValid()) {
                this.parentSubmitCommand.execute();
            }
        },
        () => {
            return this.parentSubmitCommand.canExecute();
        },
    );

    public cancelCommand = new RelayCommand(
        () => this.parentCancelCommand.execute(),
        () => this.parentCancelCommand.canExecute(),
    );

    public updateDescriptionViaTemplateNoteCommand = new RelayCommand((id: string) => {
        const templateNote = this.lookupStore.projectTaskAssignmentTemplateNotes.find((t) => t.id == id)?.note;
        this.updateField("note", templateNote ? templateNote : null);
    });

    public updateDescriptionCommand = new RelayCommand((value: string) => {
        this.updateField("note", value);
    });

    //#endregion Commands

    // #region Supporting

    private async updateField(fieldName: keyof FieldType<TaskAssignmentGroupModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    // #endregion Supporting

    /**
     * An observer to listen to changes in the task assignments model collection. Use this to create or remove
     *  viewmodels in response to changes in the task assignments model collection.
     */
    private taskAssignmentsObserverDispose = observe(this.model.taskAssignments, (taskAssignmentChanges: any) => {
        for (const addedTaskAssignment of taskAssignmentChanges.added) {
            this.taskAssignmentViewModels.push(new TaskAssignmentViewModel(addedTaskAssignment));
        }

        for (const removedTaskAssignment of taskAssignmentChanges.removed) {
            const taskAssignmentViewModelToRemove = this.taskAssignmentViewModels.find((vm) => vm.model.KEY === removedTaskAssignment.KEY);

            if (taskAssignmentViewModelToRemove) {
                this.taskAssignmentViewModels.remove(taskAssignmentViewModelToRemove);
            }
        }
    });
}
