import { ModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

export class ProjectRowModel extends ModelBase<ProjectRowModel> {
    public id: string = "";
    public title: string = "";
    public reference: string = "";

    constructor() {
        super();

        makeObservable(this, {});
    }
}
