import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { nanoid } from "nanoid";

export class WeeklyUpdateItemModel extends ModelBase<WeeklyUpdateItemModel> {
    public KEY: string = nanoid();

    public projectUpdateId: string | null = null;
    public reference: string | null = null;
    public projectTitle: string | null = null;
    public projectId: string | null = null;
    public projectStatusId: string | null = null;
    public sendUpdate: boolean | null = null;
    public updateDate: Date | null = null;

    constructor() {
        super();

        makeObservable(this, {});
    }
}
