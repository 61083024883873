import { ViewModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { container } from "tsyringe";

import { RelayCommand } from "Application/Commands";
import { LookupStore } from "Stores/Domain";
import { HeaderLinkViewModel } from "./HeaderLinkViewModel";
import { AppUrls } from "../../AppUrls";

export class HeaderViewModel extends ViewModelBase<any> {
    public dashBoardViewModel = new HeaderLinkViewModel("Dashboard", "dashboard");
    public suppliersViewModel = new HeaderLinkViewModel("Suppliers", "suppliers");
    public adminViewModel = new HeaderLinkViewModel("Admin", "admin");
    public loginViewModel = new HeaderLinkViewModel("Login", "login");
    public logoutViewModel = new HeaderLinkViewModel("Log out", "logout");
    public customersViewModel = new HeaderLinkViewModel("Clients", "customers");
    public newBusinessViewModel = new HeaderLinkViewModel("New Business", "newbusiness");
    public projectsViewModel = new HeaderLinkViewModel("Projects", "projects");
    public resourceViewModel = new HeaderLinkViewModel("Resource", "resource");
    public planningViewModel = new HeaderLinkViewModel("Planning", "planning");
    public lookupStore = container.resolve(LookupStore);

    private activeHeaderLinkViewModel: HeaderLinkViewModel = this.dashBoardViewModel;

    public logout: boolean = false;

    constructor() {
        super({});

        makeObservable(this, { logout: observable });
    }

    // #region Commands

    public clearActiveCommand = new RelayCommand(() => {
        this.activeHeaderLinkViewModel.clearActiveOption();
    });

    public navigateToHomeCommand = new RelayCommand(() => {
        this.setActiveLink(this.dashBoardViewModel);
        this.dashBoardViewModel.navigateToLinkCommand.execute(AppUrls.Client.DashboardRoot);
    });

    public navigateToLoginCommand = new RelayCommand(
        () => {
            this.setActiveLink(this.loginViewModel);
            this.loginViewModel.navigateToLinkCommand.execute(AppUrls.Client.Account.Login);
        },
        () => !this.isLoggedIn,
    );

    public navigateToLogoutCommand = new RelayCommand(
        () => {
            this.logout = true;
        },
        () => this.isLoggedIn,
    );

    public navigateToDashboardCommand = new RelayCommand(
        () => {
            this.setActiveLink(this.dashBoardViewModel);
            this.dashBoardViewModel.navigateToLinkCommand.execute(AppUrls.Client.DashboardRoot);
        },
        () => this.isLoggedIn,
    );

    public navigateToSuppliersCommand = new RelayCommand(
        () => {
            this.setActiveLink(this.suppliersViewModel);
            this.suppliersViewModel.navigateToLinkCommand.execute(AppUrls.Client.Suppliers.Table);
        },
        () => this.isLoggedIn,
    );

    public navigateToPlanningCommand = new RelayCommand(
        () => {
            this.setActiveLink(this.planningViewModel);
            this.planningViewModel.navigateToLinkCommand.execute(AppUrls.Client.PlanningApplication.Table.Live);
        },
        () => this.isLoggedIn,
    );

    public navigateToAdminCommand = new RelayCommand(
        () => {
            this.setActiveLink(this.adminViewModel);
            this.adminViewModel.navigateToLinkCommand.execute(AppUrls.Client.Admin.AdminHome);
        },
        () => this.isLoggedIn,
    );

    public navigateToCustomersCommand = new RelayCommand(
        () => {
            this.setActiveLink(this.customersViewModel);
            this.customersViewModel.navigateToLinkCommand.execute(AppUrls.Client.Customers.Table);
        },
        () => this.isLoggedIn,
    );

    public navigateToNewBusinessCommand = new RelayCommand(
        () => {
            this.setActiveLink(this.newBusinessViewModel);
            this.newBusinessViewModel.navigateToLinkCommand.execute(AppUrls.Client.NewBusinessRoot);
        },
        () => this.isLoggedIn,
    );

    public navigateToProjectsCommand = new RelayCommand(
        () => {
            this.setActiveLink(this.projectsViewModel);
            this.projectsViewModel.navigateToLinkCommand.execute(AppUrls.Client.Projects.Table);
        },
        () => this.isLoggedIn,
    );

    public navigateToResourceCommand = new RelayCommand(
        () => {
            this.setActiveLink(this.resourceViewModel);
            this.resourceViewModel.navigateToLinkCommand.execute(AppUrls.Client.ResourcesRoot);
        },
        () => this.isLoggedIn,
    );

    public logoutClose = new RelayCommand(
        () => {
            this.logout = false;
        },
        () => this.isLoggedIn,
    );

    // #endregion Commands

    // #region Supporting

    private setActiveLink = (viewModel: HeaderLinkViewModel) => {
        this.clearActiveCommand.execute();
        this.activeHeaderLinkViewModel = viewModel;
    };

    // #endregion Supporting

    // #region User Roles

    public get isAdmin() {
        return this.lookupStore.AccountStore.isInRole("admin");
    }

    public get isArchitect() {
        return this.lookupStore.AccountStore.isInRole("architect");
    }

    public get isBusinessSupport() {
        return this.lookupStore.AccountStore.isInRole("businesssupport");
    }

    public get isSeniorAssociate() {
        return this.lookupStore.AccountStore.isInRole("seniorassociate");
    }

    // #endregion User Roles

    // #region Display Permissions

    public get showDashboard() {
        return this.isAdmin || this.isSeniorAssociate || this.isBusinessSupport || this.isArchitect;
    }

    public get showSuppliers() {
        return this.isAdmin || this.isSeniorAssociate || this.isBusinessSupport || this.isArchitect;
    }

    public get showClients() {
        return this.isAdmin || this.isSeniorAssociate || this.isBusinessSupport || this.isArchitect;
    }

    public get showNewBusiness() {
        return this.isAdmin || this.isSeniorAssociate || this.isBusinessSupport;
    }

    public get showProjects() {
        return this.isAdmin || this.isSeniorAssociate || this.isBusinessSupport || this.isArchitect;
    }

    public get showResource() {
        return this.isAdmin || this.isSeniorAssociate || this.isBusinessSupport;
    }

    public get showPlanning() {
        return this.isAdmin || this.isSeniorAssociate || this.isBusinessSupport || this.isArchitect;
    }

    public get showAdmin() {
        return this.isAdmin || this.isSeniorAssociate || this.isBusinessSupport;
    }

    // #endregion Display Permissions
}
