import { IStyle, mergeStyleSets } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";

import { AutoGrid, Box, theme, ThemedButton, ThemedComboBox, ThemedEditText, ThemedText, ThemedTransparentIconButton } from "Application";
import { CrossSVG } from "Assets/CrossSVG";
import { SubmitWithNoteViewModel } from "./SubmitWithNoteViewModel";
import { FormSeparator } from "Views/Shared/SharedComponents";
import { Files } from "Views/Shared/Files/Files";

interface ISubmitWithNoteViewStyles {
    root: IStyle;
    header: IStyle;
    body: IStyle;
    footer: IStyle;
    iconButton: IStyle;
    editNote: IStyle;
    separator: IStyle;
}

interface ISubmitWithNoteViewProps {
    viewModel: SubmitWithNoteViewModel;
}

const styleDefinitions: ISubmitWithNoteViewStyles = {
    root: {
        width: "700px",
    },
    header: {
        alignItems: "center",
        backgroundColor: "#F3F3F3",
        display: "flex",
        height: "75px",
        justifyContent: "space-between",
        padding: "20px 30px",
    },
    body: {
        padding: "30px 30px 0 30px",
    },
    footer: {
        display: "flex",
        height: "75px",
        padding: "20px 30px",
    },
    iconButton: {
        g: {
            stroke: theme.palette.defaultTr.text,
        },
    },
    editNote: {
        marginTop: "15px",
    },
    separator: {
        marginBottom: "2px",
    },
};

export const SubmitWithNoteView: React.FC<ISubmitWithNoteViewProps> = observer((props) => {
    const viewModel = props.viewModel;
    const styles = mergeStyleSets(styleDefinitions);

    useEffect(() => {
        return () => viewModel.dispose();
    }, []);

    if (viewModel) {
        return (
            <div className={styles.root}>
                <div className={styles.header}>
                    <ThemedText fontStyle="h2">{viewModel.displayName}</ThemedText>
                    <ThemedTransparentIconButton command={viewModel.parentCancelDisplaySubmitWithNotesCommand} icon={<CrossSVG className={styles.iconButton} />} shape="round" />
                </div>
                <div className={styles.body}>
                    <ThemedText fontStyle="h5">{viewModel.displayMessage}</ThemedText>
                    {viewModel.canDisplayReasonItemOptionDropdown && (
                        <Box marginTop={"20px"}>
                            <ThemedComboBox
                                displayName="Reason*"
                                options={viewModel.reasonItemOptions!}
                                value={() => viewModel.getValue("noteSubTypeId")}
                                command={viewModel.updateReasonItemCommand}
                                placeholder="Please select "
                                validationMessage={() => viewModel.getError("noteSubTypeId")}
                            />
                        </Box>
                    )}
                    {viewModel.canDisplayDocuments && (
                        <ThemedEditText
                            className={styles.editNote}
                            command={viewModel.updateNotesCommand}
                            displayName="Note"
                            isMultiline
                            placeholder={"Add your note here"}
                            value={() => viewModel.getValue("notes")}
                        />
                    )}

                    <FormSeparator className={styles.separator} />
                    {viewModel.canDisplayDocuments && <Files viewModel={props.viewModel.filesViewModel} />}
                </div>
                <AutoGrid m={0} className={styles.footer} columnGap={"30px"} dc={"140px 140px"}>
                    <ThemedButton paletteColor="primary" command={viewModel.updateFromSubmitWithNotesCommand} displayName="Continue" styles={{ root: { padding: "0 20px" } }} />
                    <ThemedButton command={viewModel.parentCancelDisplaySubmitWithNotesCommand} displayName="Cancel" styles={{ root: { padding: "0 20px" } }} />
                </AutoGrid>
            </div>
        );
    }

    return <React.Fragment />;
});
