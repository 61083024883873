import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { nanoid } from "nanoid";

import { InProgressItemModel } from "../../../InProgressItemModel";

export class TaskAssignmentModel extends ModelBase<TaskAssignmentModel> {
    public KEY: string = nanoid();
    public enabled: boolean = false;

    public inProgressItemModel: InProgressItemModel;

    constructor(inProgressItemModel: InProgressItemModel, enabled: boolean = false) {
        super();

        this.inProgressItemModel = inProgressItemModel;
        this.enabled = enabled;

        makeObservable(this, {
            // Observables
            enabled: observable,
        });
    }
}
