import { isEmptyOrWhitespace, isNullOrUndefined, ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";
import moment from "moment";

import { ICommand, RelayCommand } from "Application";
import { DownloadCalloutViewModel } from "../../Dashboard/Admin/QuotesAwaitingAction/Components/DownloadCalloutViewModel";
import { QuoteItemModel } from "./QuoteItemModel";

export class QuoteItemViewModel extends ViewModelBase<QuoteItemModel> {
    private parentDownloadLetterDocumentCommand: ICommand;
    private parentDownloadQuoteTasksDocumentCommand: ICommand;
    private parentDownloadTermsAndConditionsDocumentCommand: ICommand;

    constructor(
        quote: QuoteItemModel = new QuoteItemModel(),
        downloadLetterDocumentCommand: ICommand,
        downloadQuoteTasksDocumentCommand: ICommand,
        downloadTermsAndConditionsDocumentCommand: ICommand,
    ) {
        super(quote);

        this.parentDownloadLetterDocumentCommand = downloadLetterDocumentCommand;
        this.parentDownloadQuoteTasksDocumentCommand = downloadQuoteTasksDocumentCommand;
        this.parentDownloadTermsAndConditionsDocumentCommand = downloadTermsAndConditionsDocumentCommand;

        makeObservable(this, {});
    }

    public get KEY() {
        return this.model.KEY;
    }

    public get confidence() {
        return this.model.confidence;
    }

    public get contactNumber() {
        return this.model.contactNumber1;
    }

    public get customerName() {
        return !isEmptyOrWhitespace(this.model.customerName) ? this.model.customerName : `${this.model.customerFirstName} ${this.model.customerLastName}`;
    }

    public get desirability() {
        return this.model.desirability;
    }

    public get emailAddress() {
        return this.model.emailAddress;
    }

    public get hours() {
        return this.model.hours;
    }

    public get issueDate() {
        return this.model.issueDate;
    }

    public get lastActionDate() {
        return this.model.lastActionDate;
    }

    public get quoteLeadFullName() {
        return !isEmptyOrWhitespace(this.model.quoteLeadFirstName) && !isEmptyOrWhitespace(this.model.quoteLeadLastName)
            ? `${this.model.quoteLeadFirstName} ${this.model.quoteLeadLastName}`
            : "";
    }

    public get lastActionUserName() {
        return !isEmptyOrWhitespace(this.model.lastActionFirstName) && !isEmptyOrWhitespace(this.model.lastActionLastName)
            ? `${this.model.lastActionFirstName} ${this.model.lastActionLastName}`
            : "";
    }

    public get quoteStatusName() {
        return this.model.quoteStatusName;
    }

    public get quoteStatusForegroundColor() {
        return this.model.quoteStatusForegroundColor;
    }

    public get reference() {
        return this.model.reference ?? "";
    }

    public get revision() {
        return this.model.revision ?? "";
    }

    public get customerFirstName() {
        return this.model.customerFirstName ?? "";
    }

    public get customerLastName() {
        return this.model.customerLastName ?? "";
    }

    public get title() {
        return this.model.title ?? "";
    }

    public get value() {
        return this.model.value;
    }

    public get contactFullName() {
        if (this.model.customerItemModel) {
            return this.model.customerItemModel.fullName;
        }
        return "";
    }

    public get contactFullAddress() {
        if (this.model.customerItemModel) {
            return this.model.customerItemModel.fullAddress;
        }
        return "";
    }

    public get canDisplayDownloadOptions() {
        return this.canDisplayLetterDownloadOption || this.canDisplayTermsAndConditionsDownloadOption || this.canDisplayQuoteTasksAndCostsDownloadOption;
    }

    public get canDisplayLetterDownloadOption() {
        return !isEmptyOrWhitespace(this.model.letterTemplateId);
    }

    public get canDisplayTermsAndConditionsDownloadOption() {
        return !isEmptyOrWhitespace(this.model.termsAndConditionsId);
    }

    public get canDisplayQuoteTasksAndCostsDownloadOption() {
        return true;
    }

    /**
     * Determines if the quote can be displayed, given a set of filters.
     *
     * @param filterKeyword A general filter to cover a number of propoerties of the quote.
     * @param filterFromDate A date from filter.
     * @param filterToDate  A date to filter.
     * @param filterQuoteStatusId A quote status filter.
     *
     * @returns true if the quote can be displayed otherwise false.
     */
    public filterPredicate = (
        filterKeyword: string,
        filterFromDate: Date | undefined,
        filterToDate: Date | undefined,
        filterQuoteStatusId: string | null,
        filterQuoteLeadId: string | null,
    ): boolean => {
        let result = true;

        // Attempt filtering by keyword.
        if (result) {
            const lowerCaseFilterKeyword = filterKeyword.toLowerCase();

            switch (true) {
                case isEmptyOrWhitespace(filterKeyword):
                case this.customerName.toLowerCase().includes(lowerCaseFilterKeyword):
                case this.customerFirstName.toLowerCase().includes(lowerCaseFilterKeyword):
                case this.customerLastName.toLowerCase().includes(lowerCaseFilterKeyword):
                case this.reference.toLowerCase().includes(lowerCaseFilterKeyword):
                case this.title.toLowerCase().includes(lowerCaseFilterKeyword):
                    result = true;
                    break;

                default:
                    result = false;
                    break;
            }
        }

        // If the quote has passed the keyword filter, attempt filtering by from date.
        if (result) {
            switch (true) {
                case isNullOrUndefined(filterFromDate):
                    result = true;
                    break;

                case isNullOrUndefined(this.model.lastActionDate):
                    result = false;
                    break;

                case moment(this.model.lastActionDate).startOf("day").isSameOrAfter(moment(filterFromDate)):
                    result = true;
                    break;

                default:
                    result = false;
                    break;
            }
        }

        // If the quote has passed the from date filter, attempt filtering by to date.
        if (result) {
            switch (true) {
                case isNullOrUndefined(filterToDate):
                    result = true;
                    break;

                case isNullOrUndefined(this.model.lastActionDate):
                    result = false;
                    break;

                case moment(this.model.lastActionDate).startOf("day").isSameOrBefore(moment(filterToDate)):
                    result = true;
                    break;

                default:
                    result = false;
                    break;
            }
        }

        // If the quote has passed the to date filter, attempt filtering by quote status.
        if (result) {
            switch (true) {
                case isEmptyOrWhitespace(filterQuoteStatusId):
                case this.model.quoteStatusId === filterQuoteStatusId:
                    result = true;
                    break;

                default:
                    result = false;
                    break;
            }
        }

        // If the quote has passed the quote status filter, attempt filtering on quote lead id
        if (result) {
            switch (true) {
                case isEmptyOrWhitespace(filterQuoteLeadId):
                case this.model.quoteLeadId === filterQuoteLeadId:
                    result = true;
                    break;

                default:
                    result = false;
                    break;
            }
        }

        return result;
    };

    // #region Downloads

    public CreateDownloadViewModel = (): DownloadCalloutViewModel => {
        return new DownloadCalloutViewModel(
            this.downloadQuoteLetterDocumentCommand,
            this.downloadQuoteTasksAndCostsDocumentCommand,
            this.downloadQuoteTermsAndConditionsDocumentCommand,
        );
    };

    public downloadQuoteLetterDocumentCommand = new RelayCommand(
        () => this.parentDownloadLetterDocumentCommand.execute(this),
        () => this.canDisplayLetterDownloadOption && this.parentDownloadLetterDocumentCommand.canExecute(),
    );

    public downloadQuoteTermsAndConditionsDocumentCommand = new RelayCommand(
        () => this.parentDownloadTermsAndConditionsDocumentCommand.execute(this),
        () => this.canDisplayTermsAndConditionsDownloadOption && this.parentDownloadTermsAndConditionsDocumentCommand.canExecute(),
    );

    public downloadQuoteTasksAndCostsDocumentCommand = new RelayCommand(
        () => this.parentDownloadQuoteTasksDocumentCommand.execute(this),
        () => this.canDisplayQuoteTasksAndCostsDownloadOption && this.parentDownloadQuoteTasksDocumentCommand.canExecute(),
    );

    // #endregion Downloads
}
