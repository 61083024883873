import { runInAction } from "mobx";

import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { ServiceGroupsViewModel } from "../ServiceGroupsViewModel";
import { SyncServiceGroupsResponse } from "./SharedResponseModels";

export class GETAllServicesGroupsLiteEndpoint extends EndpointWithoutRequest<SyncServiceGroupsResponse> {
    private viewModel: ServiceGroupsViewModel;

    constructor(viewModel: ServiceGroupsViewModel) {
        super();
        this.viewModel = viewModel;
        this.verb(Http.Get);
        this.path(AppUrls.Server.Admin.Services.GetAllServiceGroupsLite);
    }

    public async HandleResponseAsync(response: SyncServiceGroupsResponse): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            this.viewModel.syncModels(response);
        });
    }
}
