import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

import { ServiceGroup, Service } from "Application/Models/Domain";
import { Validator } from "Application/Validation";

export class CreateTaskModel extends ModelBase<CreateTaskModel> {
    public projectId: string | null = null;
    public serviceGroupId: string | null = null;
    public serviceId: string | null = null;
    public hourlyRate: number | null = null;
    public manHours: number | null = null;
    public isBilledHourly: boolean = false;

    public serviceGroups = observable<ServiceGroup>([]);
    public services = observable<Service>([]);

    constructor(projectId: string) {
        super();

        this.projectId = projectId;

        makeObservable(this, {
            // Observables
            serviceGroupId: observable,
            serviceId: observable,
            hourlyRate: observable,
            manHours: observable,
            isBilledHourly: observable,
        });
    }
}

export class CreateTaskModelValidator extends Validator<CreateTaskModel> {
    constructor() {
        super();

        this.ruleFor("serviceGroupId").notNull().withMessage("Please select a service group").notEmpty().withMessage("Please select a service group");
        this.ruleFor("serviceId").notNull().withMessage("Please select a service").notEmpty().withMessage("Please select a service");

        this.ruleFor("hourlyRate").notNull().withMessage("Please provide a value").greaterThan(0).withMessage("Please provide a value");
        this.ruleFor("manHours")
            .notNull()
            .withMessage("Please provide a value")
            .greaterThanOrEqualTo(0)
            .withMessage("Please provide a value")
            .when((model) => !model.isBilledHourly);
    }
}
