import { ViewModelBase } from "@shoothill/core";
import { action, computed, makeObservable, observable } from "mobx";
import { container } from "tsyringe";

import { APIClient, ICommand, RelayCommand } from "Application";
import { LookupStore } from "Stores/Domain";
import { GETProjectTaskAssignmentLiteWithRelatedByIdEndpoint } from "./Endpoints/GETProjectTaskAssignmentLiteWithRelatedByIdEndpoint";
import { ProgrammingWeekTaskModel, ProgrammingWeekTaskModelValidator } from "./ProgrammingWeekTaskModel";
import { ProgrammingWeekTaskDetailsViewModel } from "./ProgrammingWeekTaskDetails/ProgrammingWeekTaskDetailsViewModel";
import { ProgrammingWeekTaskUserNotesViewModel } from "./ProgrammingWeekTaskUserNotes/ProgrammingWeekTaskUserNotesViewModel";
import { ProgrammingWeekTaskSummaryViewModel } from "./ProgrammingWeekTaskSummaryView/ProgrammingWeekTaskSummaryViewModel";

export class ProgrammingWeekTaskViewModel extends ViewModelBase<ProgrammingWeekTaskModel> {
    private lookupStore = container.resolve(LookupStore);
    public apiClient = new APIClient();

    // Callback commands to the parent viewmodel.
    public readonly parentSubmitCommand: ICommand;
    public readonly parentCancelCommand: ICommand;

    // Tab viewmodels.
    public programmingWeekTaskViewModel = new ProgrammingWeekTaskSummaryViewModel();
    public readonly workspaceViewModels = observable<ProgrammingWeekTaskDetailsViewModel | ProgrammingWeekTaskUserNotesViewModel>([]);
    public activeWorkspaceViewModel: ProgrammingWeekTaskDetailsViewModel | ProgrammingWeekTaskUserNotesViewModel;

    constructor(projectTaskAssignmentId: string, model: ProgrammingWeekTaskModel, submitCommand: ICommand, cancelCommand: ICommand) {
        super(model, false);

        this.setValidator(new ProgrammingWeekTaskModelValidator());

        // Commands to be called on the parent viewmodel.
        this.parentSubmitCommand = submitCommand;
        this.parentCancelCommand = cancelCommand;

        // A collection of workspace viewModels.
        // In this case, the workspace is a different view of the same model.
        this.workspaceViewModels.push(new ProgrammingWeekTaskDetailsViewModel(model));
        this.workspaceViewModels.push(new ProgrammingWeekTaskUserNotesViewModel(model));

        // The default active viewModel.
        this.activeWorkspaceViewModel = this.workspaceViewModels[0];

        // Properties on which to observe changes.
        makeObservable(this, {
            workspaceViewModels: observable,
            activeWorkspaceViewModel: observable,
            updateProjectTaskAssignmentStatus: action,
            isProgrammingWeekTaskComplete: computed,
        });

        // Call the API.
        this.apiClient.sendAsync(new GETProjectTaskAssignmentLiteWithRelatedByIdEndpoint(projectTaskAssignmentId, this));
    }

    public navigateToWorkspaceCommand = new RelayCommand((vm: ProgrammingWeekTaskDetailsViewModel | ProgrammingWeekTaskUserNotesViewModel) => {
        this.activeWorkspaceViewModel = vm;
    });

    public cancelCommand = new RelayCommand(() => {
        this.parentCancelCommand.execute();
    });

    public resetServerErrorCommand = new RelayCommand(() => {
        this.apiClient.reset();
    });

    public isActiveWorkspace = (vm: ProgrammingWeekTaskDetailsViewModel | ProgrammingWeekTaskUserNotesViewModel) => {
        return vm.KEY === this.activeWorkspaceViewModel.KEY;
    };

    public updateProjectTaskAssignmentStatus(statusType: "Updated" | "Complete") {
        // a project task assignment status Id can be changed by the admin.
        // only allow the status to change if the status is not billed;

        const status = this.lookupStore.projectTaskAssignmentStatuses.find((pts) => pts.type === statusType);
        if (status?.type != "Billed") {
            this.setValue("projectTaskAssignmentStatusId", status?.id);
        }
    }

    public submitUpdateCommand = new RelayCommand(
        () => {
            if (this.isModelValid()) {
                this.updateProjectTaskAssignmentStatus("Updated");
                this.parentSubmitCommand.execute();
            }
        },
        () => this.isNotesTabActive,
    );

    public submitCompletionCommand = new RelayCommand(
        () => {
            if (this.isModelValid()) {
                this.updateProjectTaskAssignmentStatus("Complete");
                this.parentSubmitCommand.execute();
            }
        },
        () => this.isNotesTabActive,
    );

    public get isProgrammingWeekTaskComplete() {
        const status = this.lookupStore.projectTaskAssignmentStatuses.find((pts) => pts.id === this.model.projectTaskAssignmentStatusId);
        return status?.type == "Complete" || status?.type == "Billed";
    }

    public get isNotesTabActive() {
        return this.activeWorkspaceViewModel instanceof ProgrammingWeekTaskDetailsViewModel;
    }

    public get isHistoryTabActive() {
        return this.activeWorkspaceViewModel instanceof ProgrammingWeekTaskUserNotesViewModel;
    }
}
