import { useState, useEffect, useRef } from "react";

interface UseResizeObserverResult {
    ref: React.RefObject<HTMLDivElement>;
    observedHeight: number | undefined;
}

const useResizeObserver = (additionalHeight: number = 0): UseResizeObserverResult => {
    const contentRef = useRef<HTMLDivElement>(null);
    const [observedHeight, setObservedHeight] = useState<number | undefined>();

    useEffect(() => {
        if (contentRef.current) {
            const resizeObserver = new ResizeObserver((entries) => {
                for (let entry of entries) {
                    setObservedHeight(entry.target.clientHeight + additionalHeight);
                }
            });

            resizeObserver.observe(contentRef.current);

            // Clean up observer on unmount
            return () => {
                resizeObserver.disconnect();
            };
        }
    }, [additionalHeight]);

    return {
        ref: contentRef,
        observedHeight,
    };
};

export default useResizeObserver;
