//libraries
import { observer } from "mobx-react-lite";
import React from "react";
import { ThemedEditText, ThemedText, theme } from "Application";

//app

import { FormContainer, FormContainerRow, FormSubContainer, PanelFiles, PanelFilesReadOnlyView } from "Views/Shared/SharedComponents";
import { ProgrammingWeekTaskDetailsViewModel } from "./ProgrammingWeekTaskDetailsViewModel";
import styled from "@emotion/styled";

interface IProps {
    viewModel: ProgrammingWeekTaskDetailsViewModel;
}

const TaskReviewContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 15px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px dashed ${theme.palette.field.light};
`;

export const ProgrammingWeekTaskDetailsView: React.FC<IProps> = observer((props) => {
    const viewModel = props.viewModel;
    const renderAttachedDocuments = (): JSX.Element => {
        if (props.viewModel.filesViewModel.model.files.length) {
            return <PanelFilesReadOnlyView viewModel={props.viewModel.filesViewModel} />;
        } else {
            return <ThemedText fontStyle="h7">There are no documents attached to this task.</ThemedText>;
        }
    };
    return (
        <>
            <FormContainer>
                <FormSubContainer>
                    {viewModel.isTaskReviewed && (
                        <TaskReviewContainer>
                            <ThemedText fontStyle="label">Review</ThemedText>
                            <ThemedText fontStyle="h7">{viewModel.getValue("projectTaskAssignmentReviewedNote")}</ThemedText>
                        </TaskReviewContainer>
                    )}

                    {viewModel.isTaskReviewed && (
                        <FormContainerRow dc={"100%"} mb="15px !important">
                            <ThemedText fontStyle="label">Original Task</ThemedText>
                        </FormContainerRow>
                    )}
                    <FormContainerRow dc={"100%"} mb="15px !important">
                        <ThemedText fontStyle="h7">{viewModel.getValue("projectTaskAssignmentNote")}</ThemedText>
                    </FormContainerRow>
                    <FormContainerRow dc={"100%"} rowGap={10} mb="15px !important">
                        {/* attached files here */}
                        <ThemedText fontStyle="button">Attached files</ThemedText>
                        {renderAttachedDocuments()}
                    </FormContainerRow>
                    {!viewModel.isProgrammingWeekTaskComplete && (
                        <FormContainerRow dc={"100%"} mb="5px !important">
                            <ThemedEditText
                                command={viewModel.updateUserNoteCommand}
                                displayName="Notes*"
                                isMultiline
                                placeholder="Please enter any notes for the task "
                                styles={{ fieldGroup: { minHeight: 120 } }}
                                validationMessage={() => viewModel.getError("projectTaskAssignmentUserNote")}
                                value={() => viewModel.getValue("projectTaskAssignmentUserNote")}
                            />
                        </FormContainerRow>
                    )}
                </FormSubContainer>
            </FormContainer>
        </>
    );
});
