import { ViewModelBase } from "@shoothill/core";
import { computed, makeObservable, observable } from "mobx";

import { APIClient, ICommand, RelayCommand } from "Application";
import { FileModel } from "./FileModel";

export class FileViewModel extends ViewModelBase<FileModel> {
    public apiClient = new APIClient();

    private parentRemoveFileModelCommand: ICommand;
    public deleteModalOpen = false;

    constructor(fileModel: FileModel = new FileModel(), removeFileModelCommand: ICommand) {
        super(fileModel);

        this.parentRemoveFileModelCommand = removeFileModelCommand;

        makeObservable(this, {
            displayName: computed,
            documentUrl: computed,
            deleteModalOpen: observable,
        });
    }

    public get KEY() {
        return this.model.KEY;
    }

    public get displayName() {
        return this.model.fileName;
    }

    public get documentUrl() {
        return this.model.documentUrl;
    }

    public removeCommand = new RelayCommand(() => {
        this.parentRemoveFileModelCommand.execute(this);
    });

    public deleteModalOpenCommand = new RelayCommand(() => {
        this.deleteModalOpen = true;
    });

    public deleteModalCloseCommand = new RelayCommand(() => {
        this.deleteModalOpen = false;
    });
}
