import { IStyle, mergeStyleSets } from "@fluentui/react";
import { AutoGrid, Show } from "Components";
import { observer } from "mobx-react-lite";
import React from "react";

import { FileReadOnlyView } from "./FileReadOnlyView";
import { FilesViewModel } from "./FilesViewModel";

interface IFilesStyles {
    root: IStyle;
    fileContainer: IStyle;
}

interface IProps {
    className?: string;
    viewModel: FilesViewModel;
    styles?: IFilesStyles;
}

const filesStyles: IFilesStyles = {
    root: {},
    fileContainer: {
        maxHeight: "180px",
        overflowY: "auto",
        "&&": {
            marginBottom: "5px",
            marginTop: "5px",
        },
    },
};

export const FilesReadOnlyView: React.FC<IProps> = observer((props) => {
    // #region Code Behind

    const styles = mergeStyleSets(filesStyles, props.styles);

    // #endregion Code Behind

    return (
        <div className={styles.root}>
            <Show if={props.viewModel.fileViewModels.length > 0}>
                <AutoGrid className={styles.fileContainer} dc={"1fr 1fr 1fr 1fr"} columnGap="15px" rowGap="15px">
                    {props.viewModel.fileViewModels.map((viewModel) => {
                        return <FileReadOnlyView className={props.className} viewModel={viewModel} key={viewModel.KEY} />;
                    })}
                </AutoGrid>
            </Show>
        </div>
    );
});
