import { ViewModelBase, isEmptyOrWhitespace } from "@shoothill/core";
import { computed, makeObservable, observable } from "mobx";
import { container } from "tsyringe";

import { RelayCommand } from "Application";
import { LookupStore } from "Stores/Domain";
import { StaffDetailsItemModel } from "./StaffDetailsItemModel";
import { StaffDetailsViewModel } from "./StaffDetailsViewModel";

export class StaffDetailsItemViewModel extends ViewModelBase<StaffDetailsItemModel> {
    public showAdditionalInformation = false;

    public lookupStore = container.resolve(LookupStore);
    public parentStaffDetailsViewModel: StaffDetailsViewModel;

    constructor(model: StaffDetailsItemModel, staffDetailsViewModel: StaffDetailsViewModel) {
        super(model);

        this.parentStaffDetailsViewModel = staffDetailsViewModel;

        makeObservable(this, {
            // Observables
            showAdditionalInformation: observable,

            // Computeds
            noOfProjectTasks: computed,
            noOfUnassignedOrPartialTasks: computed,
            noOfAssignmentsInProgress: computed,
        });
    }

    public get KEY() {
        return this.model.KEY;
    }

    public get reference() {
        return this.model.reference;
    }

    public get title() {
        return this.model.title;
    }

    public get expandedDisplayName() {
        return this.showAdditionalInformation ? "-" : "+";
    }

    public get hasWeeklyUpdate() {
        return this.model.hasWeeklyUpdate;
    }

    public get noOfProjectTasks() {
        return this.model.noOfProjectTasks;
    }

    public get projectStatusColour() {
        const projectStatus = this.lookupStore.projectStatuses.find((c: any) => c.id === this.model.projectStatusId);

        return projectStatus?.type === "OnHold" ? `${projectStatus.foregroundColor}1A` : "#EAF4FF";
    }

    public get noOfUnassignedOrPartialTasks() {
        return this.model.noOfUnassignedOrPartialTasks;
    }

    public get noOfAssignmentsInProgress() {
        return this.model.noOfAssignmentsInProgress;
    }

    public get customerContactFullName() {
        return this.model.customerContactFullName;
    }

    public get projectSupportFullName(): string {
        return [this.model.projectSupportFirstName, this.model.projectSupportLastName].filter((item) => !isEmptyOrWhitespace(item)).join(" ");
    }

    // #region Commands
    // #region Command

    public toggleAdditionalInformationCommand = new RelayCommand(() => {
        this.showAdditionalInformation = !this.showAdditionalInformation;
    });

    // #endregion Commands

    /**
     * Determines if the project can be displayed, given a set of filters.
     *
     * @param filterKeyword A general filter to cover a number of properties of the project.
     *
     * @returns true if the project can be displayed otherwise false.
     */
    public filterPredicate = (filterKeyword: string, filterProjectSupportId: string | null): boolean => {
        let result = true;

        // Attempt filtering by keyword.
        if (result) {
            const lowerCaseFilterKeyword = filterKeyword.toLowerCase();

            switch (true) {
                case isEmptyOrWhitespace(filterKeyword):
                case this.reference.toLowerCase().includes(lowerCaseFilterKeyword):
                case this.title.toLowerCase().includes(lowerCaseFilterKeyword):
                    result = true;
                    break;

                default:
                    result = false;
                    break;
            }
        }
        // If the quote has passed the keyword filter, attempt filtering by project support id.
        if (result) {
            switch (true) {
                case isEmptyOrWhitespace(filterProjectSupportId):
                case this.model.projectSupportId === filterProjectSupportId:
                    result = true;
                    break;

                default:
                    result = false;
                    break;
            }
        }

        return result;
    };
}
