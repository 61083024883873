import _ from "lodash";
import { runInAction } from "mobx";

import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { QuoteRequest } from "./Shared/QuoteRequest";
import { QuoteResponse } from "./Shared/QuoteResponse";
import { QuoteContainerModel } from "../QuoteContainerModel";
import { QuoteContainerViewModel } from "../QuoteContainerViewModel";

class SaveQuoteTermsAndConditionsRequest {
    id: string = "";
    termAndConditionId: string | null = null;
    termsAndConditionsDetails: string | null = null;
    signatoryUserId: string | null = null;
}

export class POSTSaveQuoteTermsAndConditionsEndpoint extends Endpoint<QuoteRequest, Response> {
    private readonly viewModel: QuoteContainerViewModel;

    constructor(viewModel: QuoteContainerViewModel) {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Quotes.SaveQuoteTermsAndConditions);
        this.viewModel = viewModel;
    }

    public async HandleRequestAsync(model: QuoteContainerModel): Promise<any> {
        const request = new SaveQuoteTermsAndConditionsRequest();

        request.id = model.quoteForm.id!;
        request.termAndConditionId = model.quoteForm.termsAndConditionsModel.termAndConditionId!;
        request.termsAndConditionsDetails = model.quoteForm.termsAndConditionsModel.termsAndConditionsDetails!;
        request.signatoryUserId = model.quoteForm.termsAndConditionsModel.signatoryUserId;

        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: QuoteResponse): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            QuoteResponse.revisionResponsePart(response, this.viewModel);
            QuoteResponse.quoteResponsePart(response, this.viewModel);
            QuoteResponse.customerResponsePart(response, this.viewModel);
            QuoteResponse.letterTemplateResponsePart(response, this.viewModel);
            QuoteResponse.tasksResponsePart(response, this.viewModel);
            QuoteResponse.termsAndConditionsResponsePart(response, this.viewModel);
            QuoteResponse.notesResponsePart(response, this.viewModel);
            QuoteResponse.remainingResponsePart(response, this.viewModel);
        });

        return Promise.resolve(response);
    }
}
