import { ModelBase } from "@shoothill/core";
import { User } from "Application/Models/Domain/User";
import { makeObservable } from "mobx";

import { CustomerModel } from "Views/Shared/Customer/CustomerModel";
import { LetterModel } from "Views/Shared/Letter/LetterModel";
import { QuoteModel } from "Views/Shared/Quote/QuoteModel";
import { ServicesModel } from "Views/Shared/Services/ServicesModel";
import { TermsAndConditionsModel } from "Views/Shared/TermsAndConditions/TermsAndConditionsModel";

export class QuoteHistoryModel extends ModelBase<QuoteHistoryModel> {
    public quote = new QuoteModel();
    public customer = new CustomerModel();
    public letterModel = new LetterModel();
    public servicesModel = new ServicesModel();
    public termsAndConditionsModel = new TermsAndConditionsModel();

    constructor() {
        super();

        makeObservable(this, {
            // Observables
        });
    }
}
