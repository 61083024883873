import { ModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

import { LeaveRequest } from "Views/Leave/Shared/LeaveRequest";

export class DeleteLeaveRequestModel extends ModelBase<DeleteLeaveRequestModel> {
    public leaveRequest: LeaveRequest;

    constructor(leaveRequest: LeaveRequest) {
        super();

        this.leaveRequest = leaveRequest;

        makeObservable(this, {});
    }
}
