// Libs
import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "../../../Application/Validation";

export class RoleModel extends ModelBase<RoleModel> {
    // Note: some observables are predefined in the ModelBase class.
    // If any errors arise, check there to see if the property exists
    // and if so, delete it from here.
    // public createdDate: Date | null = null;
    // public updatedDate: Date | null = null;
    // public isDeleted: boolean = false;

    public id: string = "";
    public name: string = "";

    constructor(id: string) {
        super();
        this.id = id;
        makeObservable(this, {
            id: observable,
            name: observable,
        });
    }
}

export class RoleModelValidator extends Validator<RoleModel> {
    constructor() {
        super();
        this.ruleFor("name").notEmpty();
    }
}
