import { ViewModelBase } from "@shoothill/core";
import { computed, makeObservable, observable } from "mobx";
import { container } from "tsyringe";

import { APIClient, RelayCommand } from "Application";
import { LookupStore } from "Stores/Domain";
import { PROJECTSTATUS_INPROGRESS, PROJECTSTATUS_INSTRUCTED, PROJECTSTATUS_ONHOLD } from "Views/Shared/Constants";
import { GETProgrammingByIdEndpoint } from "./Endpoints/GETProgrammingByIdEndpoint";
import { POSTSaveProjectAsPutOnHoldEndpoint } from "./Endpoints/POSTSaveProjectAsPutOnHoldEndpoint";
import { POSTSaveProjectAsTakenOffHoldEndpoint } from "./Endpoints/POSTSaveProjectAsTakenOffHoldEndpoint";
import { InProgressViewModel } from "./InProgressSuvbView/InProgressViewModel";
import { UnassignedTasksViewModel } from "./UnassignedTasksSubView/UnassignedTasksViewModel";
import { StaffDetailsItemViewModel } from "../../StaffDetailsItemViewModel";
import { StaffDetailsViewModel } from "../../StaffDetailsViewModel";

export class ProgrammingViewModel extends ViewModelBase<any> {
    public apiClient = new APIClient();
    private lookupStore = container.resolve(LookupStore);
    public showAdditionalInformation = true;

    public parentStaffDetailsItemViewModel: StaffDetailsItemViewModel;
    public parentViewModel: StaffDetailsViewModel;
    public unassignedTasksViewModel: UnassignedTasksViewModel;
    public inProgressViewModel: InProgressViewModel;
    public projectId: string;

    constructor(projectId: string, staffDetailsItemViewModel: StaffDetailsItemViewModel, parentViewModel: StaffDetailsViewModel) {
        super({});

        this.parentStaffDetailsItemViewModel = staffDetailsItemViewModel;
        this.parentViewModel = parentViewModel;
        this.unassignedTasksViewModel = new UnassignedTasksViewModel(this, this.parentViewModel.model.userId, projectId, this.onSubmitCommand, this.onCancelCommand);
        this.inProgressViewModel = new InProgressViewModel(this, this.onSubmitCommand);
        this.projectId = projectId;

        makeObservable(this, {
            // Observables
            showAdditionalInformation: observable,

            // Computeds
            displayName: computed,
        });
    }

    public getProgrammingWeek() {
        return this.apiClient.sendAsync(new GETProgrammingByIdEndpoint(this));
    }

    public get KEY() {
        return this.model.KEY;
    }

    public get displayName() {
        return this.showAdditionalInformation ? "- Programming" : "+ Programming";
    }

    // #region Commands

    public toggleAdditionalInformationCommand = new RelayCommand(() => {
        this.showAdditionalInformation = !this.showAdditionalInformation;
    });

    public onSubmitCommand = new RelayCommand(async () => {
        await this.getProgrammingWeek();
        await this.parentStaffDetailsItemViewModel.parentStaffDetailsViewModel.updatePercentageAllocationCommand.execute();
        this.parentViewModel.closeMyWorkWeekOverviewCommand.execute();
    });

    public onCancelCommand = new RelayCommand(async () => {
        this.parentViewModel.closeMyWorkWeekOverviewCommand.execute();
    });

    // #endregion Commands

    // #region Project Status Commands

    public putProjectOnHoldCommand = new RelayCommand(
        async () => {
            await this.apiClient.sendAsync(new POSTSaveProjectAsPutOnHoldEndpoint(this), this.model);

            if (this.apiClient.IsRequestSuccessful) {
                this.apiClient.reset();

                this.getProgrammingWeek();
            }
        },
        () => {
            const status = this.lookupStore.projectStatuses.find((ps) => ps.id === this.parentStaffDetailsItemViewModel.model.projectStatusId);

            return status?.type === PROJECTSTATUS_INPROGRESS || status?.type === PROJECTSTATUS_INSTRUCTED;
        },
    );

    public takeProjectOffHoldCommand = new RelayCommand(
        async () => {
            await this.apiClient.sendAsync(new POSTSaveProjectAsTakenOffHoldEndpoint(this), this.model);

            if (this.apiClient.IsRequestSuccessful) {
                this.apiClient.reset();

                this.getProgrammingWeek();
            }
        },
        () => {
            const status = this.lookupStore.projectStatuses.find((ps) => ps.id === this.parentStaffDetailsItemViewModel.model.projectStatusId);

            return status?.type === PROJECTSTATUS_ONHOLD;
        },
    );

    // #endregion Project Status Commands
}
