import { makeObservable, observable } from "mobx";
import { ViewModelBase } from "@shoothill/core";

import { APIClient, RelayCommand } from "Application";
import { ProjectQuoteHistoryModel } from "./ProjectQuoteHistoryModel";
import { GETQuoteHistoryContainerByProjectId } from "./Container/GETQuoteHistoryContainerByProjectId";
import { ProjectViewModel } from "../ProjectViewModel";
import { QuoteListItemViewModel } from "./Container/Shared/QuoteListItemViewModel";
import { GETQuoteWithRelatedByIdEndpoint } from "./Container/Shared/GETQuoteWithRelatedByIdEndpoint";

export class ProjectQuoteHistoryViewModel extends ViewModelBase<ProjectQuoteHistoryModel> {
    public apiClient = new APIClient();
    public parentViewModel: ProjectViewModel;
    public quoteListViewModels = observable<QuoteListItemViewModel>([]);

    constructor(parentViewModel: ProjectViewModel) {
        super(new ProjectQuoteHistoryModel());
        this.parentViewModel = parentViewModel;

        makeObservable(this, {});

        const projectId = this.parentViewModel.model.id;

        // Server Actions
        switch (true) {
            case projectId !== undefined:
                this.apiClient.sendAsync(new GETQuoteHistoryContainerByProjectId(projectId!, this));
                break;

            default:
                break;
        }
    }

    // #region Properties
    // #endregion Properties

    // #region Commands

    public quoteRowClickCommand = new RelayCommand((vm: QuoteListItemViewModel) => {
        if (vm.isVisible === false) {
            vm.isVisible = true;

            if (vm.revisionViewModels.length === 0) {
                this.apiClient.sendAsync(new GETQuoteWithRelatedByIdEndpoint(vm.model.id, vm));
            }
        } else {
            vm.isVisible = false;
        }
    });

    // #endregion Comands
}
