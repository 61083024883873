import { FieldType, ViewModelBase } from "@shoothill/core";
import { makeObservable, observable, action, computed } from "mobx";

import { APIClient, IKeyState, RelayCommand } from "Application";
import { GETAllServicesLiteByServiceGroupIdEndpoint } from "../Endpoints/GETAllServicesLiteByServiceGroupIdEndpoint";
import { PostBulkSwapServiceOrdinalsEndpoint } from "../Endpoints/POSTBulkSwapServiceOrdinalsEndpoint";
import { PostDeleteServiceByIdEndpoint } from "../Endpoints/POSTDeleteServiceByIdEndpoint";
import { PostSwapServiceOrdinalsEndpoint } from "../Endpoints/POSTSwapServiceOrdinalEndpoint";
import { PostUpsertServiceEndpoint } from "../Endpoints/POSTUpsertServiceEndpoint";
import { ServiceGroupModel, ServiceGroupModelValidator } from "./ServiceGroupModel";
import { ServiceGroupsViewModel } from "../ServiceGroupsViewModel";
import { ServiceModel } from "../Service/ServiceModel";
import { ServiceViewModel } from "../Service/ServiceViewModel";
import { ViewModeType } from "Application/Types/ViewModeType";

export class ServiceGroupViewModel extends ViewModelBase<ServiceGroupModel> {
    public apiClient = new APIClient();

    public parentViewModel: ServiceGroupsViewModel;
    public newServiceViewModel: ServiceViewModel | null = null;

    // Replace isUpdatingName with viewMode
    public viewMode: ViewModeType = "Read";
    public isDeleteModalOpen = false;
    public draftServiceGroupNameValue: string = this.model.name;

    constructor(parentViewModel: ServiceGroupsViewModel, model = new ServiceGroupModel()) {
        super(model, false);

        this.parentViewModel = parentViewModel;
        this.setValidator(new ServiceGroupModelValidator());

        makeObservable(this, {
            viewMode: observable,
            isDeleteModalOpen: observable,
            setViewMode: action,
            setIsDeleteModalOpen: action,
            setDraftServiceGroupName: action,
            draftServiceGroupName: computed,

            // Observables
            newServiceViewModel: observable,

            // Computeds
            canDisplayNewService: computed,
        });
    }

    // Computed Values

    public get isInAddEditMode() {
        return this.viewMode === "Add" || this.viewMode === "Edit";
    }

    public get isInReadOnlyMode() {
        return this.viewMode === "Read";
    }

    // Actions
    public setViewMode = (mode: ViewModeType) => {
        this.viewMode = mode;
    };

    public toggleViewMode = () => {
        this.viewMode = this.viewMode === "Edit" ? "Read" : "Edit";
    };

    public setIsDeleteModalOpen = (value: boolean) => (this.isDeleteModalOpen = value);

    public setDraftServiceGroupName = (value: string) => (this.draftServiceGroupNameValue = value);

    // Computed Values
    public get draftServiceGroupName() {
        return this.draftServiceGroupNameValue;
    }

    // Commands
    public beginUpdateServiceGroupCommand = new RelayCommand(() => {
        this.setViewMode("Edit");
    });

    public confirmUpdateServiceGroupCommand = new RelayCommand(() => {
        //send api call to update service group name
        this.parentViewModel.upsertServiceGroupCommand.execute(this.model);
        this.setViewMode("Read");
    });

    public cancelUpdateServiceGroupCommand = new RelayCommand(() => {
        this.setValue("name", this.draftServiceGroupName);
        this.setDirty("name", false);
        this.setViewMode("Read");
    });

    public beginDeleteServiceGroupCommand = new RelayCommand(() => {
        this.setIsDeleteModalOpen(true);
    });

    public confirmDeleteServiceGroupCommand = new RelayCommand(() => {
        this.parentViewModel.deleteServiceGroupCommand.execute(this.model.id);
        this.setIsDeleteModalOpen(false);
    });

    public cancelDeleteServiceGroupCommand = new RelayCommand(() => {
        this.setIsDeleteModalOpen(false);
    });

    public deleteServiceCommand = new RelayCommand((id: string) => {
        this.apiClient.sendAsync(new PostDeleteServiceByIdEndpoint(this), id);
    });

    public updateNameCommand = new RelayCommand((name: string) => {
        this.updateField("name", name);
    });

    public incrementServiceGroupPositionCommand = new RelayCommand(
        () => {
            const services = this.parentViewModel.model.serviceGroupModels;
            const modelTest = this.model;
            const previousServiceGroups = services.filter((model) => model.ordinal < modelTest.ordinal);

            this.parentViewModel.swapServiceGroupOrdinalCommand.execute(this.model.id, previousServiceGroups[previousServiceGroups.length - 1].id);
        },
        () => this.isFirstServiceGroup == false,
    );

    public decrementServiceGroupPositionCommand = new RelayCommand(
        () => {
            const nextServiceGroups = this.parentViewModel.model.serviceGroupModels.filter((model) => model.ordinal > this.model.ordinal);
            this.parentViewModel.swapServiceGroupOrdinalCommand.execute(this.model.id, nextServiceGroups[0].id);
        },
        () => this.isLastServiceGroup == false,
    );

    public swapServiceOrdinals(idOne: string, idTwo: string) {
        //idOne is the id of the service group that is to be swapped
        //idTwo is the id of the target service group
        const requestModel: any = {
            idOne,
            idTwo,
        };
        this.apiClient.sendAsync(new PostSwapServiceOrdinalsEndpoint(this), requestModel);
    }

    public swapServiceOrdinalCommand = new RelayCommand((idOne: string, idTwo: string) => {
        this.swapServiceOrdinals(idOne, idTwo);
    });

    public onDragEndCommand = new RelayCommand(() => {
        this.apiClient.sendAsync(new PostBulkSwapServiceOrdinalsEndpoint(this), this.model.serviceModels);
    });

    public toggleIsServicesListExpandedCommand = new RelayCommand(() => this.setValue("isServicesListExpanded", !this.getValue<boolean>("isServicesListExpanded")));

    public getAllServicesByServiceGroupId = () => {
        if (this.getValue<boolean>("isServicesListExpanded")) {
            //api call
            this.apiClient.sendAsync(new GETAllServicesLiteByServiceGroupIdEndpoint(this));
        }
    };

    // Properties
    public get isFirstServiceGroup() {
        return this.parentViewModel.model.serviceGroupModels.indexOf(this.model) == 0;
    }

    public get isLastServiceGroup() {
        return this.parentViewModel.model.serviceGroupModels.indexOf(this.model) == this.parentViewModel.model.serviceGroupModels.length - 1;
    }

    // #region Add New Service

    public get canDisplayNewService(): boolean {
        return this.newServiceViewModel !== null;
    }

    public displayNewServiceCommand = new RelayCommand(() => {
        this.newServiceViewModel = new ServiceViewModel(this, new ServiceModel());
        this.newServiceViewModel.setValue("serviceGroupId", this.getValue("id"));
        this.newServiceViewModel.viewMode = "Add";
    });

    public submitNewServiceCommand = new RelayCommand(async () => {
        if (this.newServiceViewModel!.isModelValid()) {
            await this.upsertService(this.newServiceViewModel!.model);

            if (this.apiClient.IsRequestSuccessful) {
                this.newServiceViewModel = null;
                this.apiClient.reset();
            }
        }
    });

    public cancelAddNewServiceCommand = new RelayCommand(() => {
        this.newServiceViewModel = null;
    });

    // #endregion Add New Service

    // #region Helpers

    private updateField(fieldName: keyof FieldType<ServiceGroupModel>, value: any, keyState?: IKeyState) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    public upsertService = async (model: ServiceModel) => {
        return this.apiClient.sendAsync(new PostUpsertServiceEndpoint(this), model);
    };

    // #endregion Helpers
}
