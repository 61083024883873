import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import React from "react";

import { AutoGrid, Box } from "Application";

interface IProps {}

const HeaderGrid = styled(AutoGrid)`
    align-items: center;
    background-color: #f3f3f3;
    border-bottom: 1px solid #edebe9;
    column-gap: 0px !important;
    flex: 1;
    font-size: 10px;
    font-weight: bold;
    margin: 0px 0px;
    max-height: 44px;
    min-height: 44px;
    row-gap: 0px !important;
    @media (max-width: 960px) {
        display: none !important;
    }
`;

const HeaderCell = styled(Box)`
    padding: 4px 12px;
`;

export const HeaderView: React.FC<IProps> = observer((props) => {
    return (
        <HeaderGrid dc="1fr 1fr 1fr 1fr 1fr 1fr 1fr">
            <HeaderCell>MONDAY</HeaderCell>
            <HeaderCell>TUESDAY</HeaderCell>
            <HeaderCell>WEDNESDAY</HeaderCell>
            <HeaderCell>THURSDAY</HeaderCell>
            <HeaderCell>FRIDAY</HeaderCell>
            <HeaderCell>SATURDAY</HeaderCell>
            <HeaderCell>SUNDAY</HeaderCell>
        </HeaderGrid>
    );
});
