import { ModelBase } from "@shoothill/core";
import { User } from "Application/Models/Domain/User";
import { makeObservable, observable } from "mobx";

export class ProjectModel extends ModelBase<ProjectModel> {
    public id: string = "";

    public reference: string = "";
    public title: string = "";
    public leadInWeeks: number = 0;
    public durationWeeks: number = 0;
    public hours: number = 0;
    public value: number = 0;
    public remainingHours: number = 0;
    public remainingValue: number = 0;
    public projectLeadId: string | null = null;
    public projectStatusId: string | null = null;
    public projectLead: User | null = null;

    constructor() {
        super();

        makeObservable(this, {
            id: observable,
            reference: observable,
            title: observable,
            leadInWeeks: observable,
            durationWeeks: observable,
            hours: observable,
            value: observable,
            projectLeadId: observable,
            projectStatusId: observable,
            projectLead: observable,
        });
    }
}
