import { IStyle, mergeStyleSets } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React from "react";
import { ExpandableContainer, ThemedText } from "Application";
import { ServicesViewModel } from "../ServicesViewModel";
import { FormDottedSeparator, FormSeparator } from "Views/Shared/SharedComponents";
import { TaskHeaderView } from "./TaskHeaderView";
import { TaskFooterSubTotalView } from "./TaskFooterSubTotalView";
import { TaskFooterVATTotalView } from "./TaskFooterVATTotalView";
import { TaskFooterTotalView } from "./TaskFooterTotalView";
import { TaskReadOnlyGroupView } from "./TaskReadOnlyGroupView";
import { TaskFooterTotalManHoursView } from "./TaskFooterTotalHoursView";
import useResizeObserver from "Application/Hooks/UseResizeObserver";

interface IServicesReadOnlyViewStyles {
    root: IStyle;
}

interface IServicesReadOnlyViewProps {
    viewModel: ServicesViewModel;
}

const styleDefinitions: IServicesReadOnlyViewStyles = {
    root: {
        marginTop: "20px",
        marginBottom: "20px",
    },
};

export const ServicesReadOnlyView: React.FC<IServicesReadOnlyViewProps> = observer((props) => {
    const viewModel = props.viewModel;
    const styles = mergeStyleSets(styleDefinitions);
    const { ref, observedHeight } = useResizeObserver(90);

    const renderContent = () => {
        return (
            <div ref={ref}>
                <div className={styles.root}>
                    <div>
                        <TaskHeaderView />
                        {viewModel.taskGroupViewModels.map((vm) => {
                            return <TaskReadOnlyGroupView key={vm.KEY} viewModel={vm} />;
                        })}
                        <TaskFooterTotalManHoursView viewModel={viewModel} />
                        <TaskFooterSubTotalView viewModel={viewModel} />
                        <TaskFooterVATTotalView viewModel={viewModel} />
                        <TaskFooterTotalView viewModel={viewModel} />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <React.Fragment>
            <ExpandableContainer displayName="Services" childrenHeight={observedHeight}>
                {renderContent()}
            </ExpandableContainer>
        </React.Fragment>
    );
});
