import { runInAction } from "mobx";

import { Endpoint, Http, startOfDay } from "Application";
import { AppUrls } from "AppUrls";
import { StaffDetailsViewModel } from "./StaffDetailsViewModel";
import { StaffDetailsModel } from "./StaffDetailsModel";

class Request {
    public userId: string = "";
    public filterStartDate: Date | null = null;
}

class Response {
    public businessHours: number = 0;
    public chargeableBusinessHours: number = 0;
    public chargeableEffortHoursAllocation: number = 0;
    public chargeableEffortPercentageAllocation: number = 0;
    public effortHoursAllocation: number = 0;
    public effortPercentageAllocation: number = 0;
}

export class GETStaffProjectDetailsPercentageAllocationByIdEndpoint extends Endpoint<any, any> {
    private viewModel: StaffDetailsViewModel;

    constructor(viewModel: StaffDetailsViewModel) {
        super();
        this.viewModel = viewModel;
        this.verb(Http.Post);
        this.path(AppUrls.Server.Resources.GetStaffProjectDetailsPercentageAllocationByUserId);
    }

    public async HandleRequestAsync(model: StaffDetailsModel): Promise<any> {
        let request = new Request();

        request.userId = this.viewModel.model.userId;
        request.filterStartDate = startOfDay(this.viewModel.model.filterStartDate) ?? null;

        return await Promise.resolve(request);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        runInAction(() => {
            this.viewModel.model.businessHours = response.businessHours;
            this.viewModel.model.chargeableBusinessHours = response.chargeableBusinessHours;
            this.viewModel.model.chargeableEffortHoursAllocation = response.chargeableEffortHoursAllocation;
            this.viewModel.model.chargeableEffortPercentageAllocation = response.chargeableEffortPercentageAllocation;
            this.viewModel.model.effortHoursAllocation = response.effortHoursAllocation;
            this.viewModel.model.effortPercentageAllocation = response.effortPercentageAllocation;
        });
    }
}
