import { observer } from "mobx-react-lite";
import React from "react";

import { ThemedText } from "Application";
import { BillingNoteViewModel } from "./BillingNoteViewModel";
import { PersonaSize } from "@fluentui/react";
import { AssignmentHistoryPersona, AssignmentHistoryNote, AssignmentHistoryNoteContainer, AssignmentHistoryTitle } from "Views/Shared/SharedComponents";

interface IAssignViewProps {
    viewModel: BillingNoteViewModel;
    flipView: boolean;
}

export const BillingNoteView: React.FC<IAssignViewProps> = observer((props) => {
    const viewModel = props.viewModel;

    const personaStyle = props.flipView ? { root: { flexDirection: "row-reverse" }, primaryText: { fontSize: "10px" } } : { primaryText: { fontSize: "10px" } };

    return (
        <AssignmentHistoryNoteContainer>
            <AssignmentHistoryTitle isFlipped={props.flipView}>
                <AssignmentHistoryPersona isFlipped={props.flipView} displayName={viewModel.displayName} size={PersonaSize.size24} imageUrl={viewModel.documentUrl} />
                <ThemedText fontStyle="h7">
                    <b>{viewModel.updateDateContext}: </b>
                    {viewModel.updateDate}
                </ThemedText>
            </AssignmentHistoryTitle>
            <AssignmentHistoryNote isFlipped={props.flipView}>
                <ThemedText fontStyle="h7">{viewModel.note}</ThemedText>
            </AssignmentHistoryNote>
        </AssignmentHistoryNoteContainer>
    );
});
