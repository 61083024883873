import _ from "lodash";
import { runInAction } from "mobx";

import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { LetterTemplatesViewModel } from "./LetterTemplatesViewModel";
import { Logger } from "index";
import { LetterTemplatesModel } from "./LetterTemplatesModel";

class Request {
    public id: string | null = null;
    public name: string = "";
    public body: string | null = "";
}

class Response {}

export class POSTSaveLetterTemplatesEndpoint extends Endpoint<Request, Response> {
    private readonly viewModel: LetterTemplatesViewModel;

    constructor(viewModel: LetterTemplatesViewModel) {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Admin.LetterTemplates.PostSaveLetterTemplate);
        this.viewModel = viewModel;
    }

    public async HandleRequestAsync(model: LetterTemplatesModel): Promise<any> {
        let request = new Request();

        request.id = model.id;
        request.name = model.name;
        request.body = model.body;

        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            // Navigate to the letter templates page.
            this.viewModel.history.push(AppUrls.Client.LetterTemplates.Table);
        });
    }
}
