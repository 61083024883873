import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

import { Validator } from "../../../../Application/Validation";
import { ServiceModel } from "../Service/ServiceModel";

// App
export class ServiceGroupModel extends ModelBase<ServiceGroupModel> {
    public id: string = "";
    public name: string = "";
    public ordinal: number = 0;
    public serviceModels = observable<ServiceModel>([]);
    public serviceModelToAdd: ServiceModel | null = null;
    public isServicesListExpanded: boolean = false;
    constructor() {
        super();

        makeObservable(this, {
            id: observable,
            name: observable,
            ordinal: observable,
            serviceModels: observable,
            serviceModelToAdd: observable,
            isServicesListExpanded: observable,
        });
    }
}

export class ServiceGroupModelValidator extends Validator<ServiceGroupModel> {
    constructor() {
        super();

        this.ruleFor("name").notEmpty().withMessage("Please enter a name.");
    }
}
