import { FieldType, ViewModelBase } from "@shoothill/core";
import { computed, makeObservable, observable } from "mobx";

import { RelayCommand } from "Application";
import { LetterModel, LetterModelValidator } from "./LetterModel";
import { LetterTemplateItemModel } from "./LetterTemplateItemModel";

export class LetterViewModel extends ViewModelBase<LetterModel> {
    public letterTemplates = observable<LetterTemplateItemModel>([]);

    public showAdditionalInformation = false;

    constructor(letterModel: LetterModel = new LetterModel()) {
        super(letterModel);

        this.setValidator(new LetterModelValidator());

        makeObservable(this, {
            // Observables
            letterTemplates: observable,
            showAdditionalInformation: observable,

            // Computeds
            canDisplayForQuickQuote: computed,
        });
    }

    // #region Properties

    public get canDisplayForQuickQuote() {
        return this.model.isQuickQuote;
    }

    public get letterTemplateOptions() {
        return this.letterTemplates.map((item) => {
            return {
                key: item.id,
                text: item.name,
            };
        });
    }

    // #endregion Properties

    // #region Commands

    public updateLetterTemplateIdCommand = new RelayCommand((value: string | null) => {
        this.updateField("letterTemplateId", value);

        // SIDE-EFFECT. When changing the template selection, we need to reset the details
        // to the body of the new template.
        const template = value ? this.letterTemplates.find((item) => item.id === value) : null;

        this.updateField("letterDetails", template?.body);
    });

    public updateLetterText = (value: string): void => {
        this.updateField("letterDetails", value);
    };

    public setSaveQuoteStatusType = (value: string | null): void => {
        this.setValue("saveQuoteStatusType", value);
    };

    public toggleAdditionalInformationCommand = new RelayCommand(() => {
        this.showAdditionalInformation = !this.showAdditionalInformation;
    });

    // #endregion Commands

    // #region Supporting

    private updateField(fieldName: keyof FieldType<LetterModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    // #endregion Supporting
}
