import { AppRoutes } from "./AppRoutes";
import React from "react";
import { GlobalHistory } from "index";
import { CustomThemeProvider, theme } from "./Application";
import { CustomBrowserRouter } from "Components/CustomRouter";
import { Global, css } from "@emotion/react";
import { GlobalStyles } from "./Styles/GlobalStyles";

export const App: React.FC = () => {
    return (
        <>
            <CustomThemeProvider>
                <GlobalStyles />
                <CustomBrowserRouter history={GlobalHistory}>
                    <AppRoutes />
                </CustomBrowserRouter>
            </CustomThemeProvider>
        </>
    );
};
