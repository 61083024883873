import { ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";
import { RoleLiteModel } from "./RoleLiteModel";

export class RoleLiteViewModel extends ViewModelBase<RoleLiteModel> {
    constructor(roleLiteModel: RoleLiteModel = new RoleLiteModel()) {
        super(roleLiteModel);
        makeObservable(this, {});
    }
}
