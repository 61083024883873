import { isEmptyOrWhitespace, isNullOrEmpty } from "@shoothill/core";
import { FilesModel } from "Views/Shared/Files/FilesModel";
import { SupplierModel } from "../SupplierModel";

class supplierDocumentRequest {
    public id: string | null = null;
}

class SupplierContactRequest {
    public id: string | null = null;
    public contactTitleId: string | null = null;
    public supplierContactTypeId: string | null = null;
    public firstName: string = "";
    public lastName: string = "";
    public address1: string | null = null;
    public address2: string | null = null;
    public address3: string | null = null;
    public city: string | null = null;
    public postcode: string | null = null;
    public sameAsCustomerAddress: boolean = true;
    public contactNumber1: string | null = null;
    public contactNumber2: string | null = null;
    public emailAddress: string | null = null;
    public emailAddress2: string | null = null;
}

export class SupplierRequest {
    public id: string | null = null;
    public businessName: string | null = null;
    public supplierTypeId: string | null = null;
    public supplierSubTypeId: string | null = null;
    public address1: string | null = null;
    public address2: string | null = null;
    public address3: string | null = null;
    public city: string | null = null;
    public postcode: string | null = null;
    public contactNumber1: string | null = null;
    public contactNumber2: string | null = null;
    public emailAddress1: string | null = null;
    public emailAddress2: string | null = null;
    public clientNote: string | null = null;
    public supplierContacts: SupplierContactRequest[] = [];
    public deletedSupplierContacts: SupplierContactRequest[] = [];
    public documents: supplierDocumentRequest[] = [];

    public static supplierPart(request: SupplierRequest, model: SupplierModel, filesModel: FilesModel) {
        request.id = model.id;
        request.businessName = model.businessName;
        request.supplierTypeId = model.supplierTypeId;
        request.supplierSubTypeId = model.supplierSubTypeId;
        request.address1 = model.address1;
        request.address2 = model.address2;
        request.address3 = model.address3;
        request.city = model.city;
        request.postcode = model.postcode;
        request.contactNumber1 = model.contactNumber1;
        request.contactNumber2 = model.contactNumber2;
        request.emailAddress1 = model.emailAddress1;
        request.emailAddress2 = model.emailAddress2;
        request.clientNote = model.clientNote;

        if (isNullOrEmpty(request.id)) {
            request.id = null;
        }

        request.supplierContacts = [];
        request.deletedSupplierContacts = [];

        for (const contact of model.supplierContacts) {
            let contactRequest = new SupplierContactRequest();

            contact.toRequest(contactRequest);

            if (isNullOrEmpty(request.id)) {
                contactRequest.id = null;
            }

            request.supplierContacts.push(contactRequest);
        }

        for (const contact of model.deletedSupplierContacts) {
            let contactRequest = new SupplierContactRequest();

            contact.toRequest(contactRequest);

            if (isNullOrEmpty(request.id)) {
                contactRequest.id = null;
            }

            request.deletedSupplierContacts.push(contactRequest);
        }

        request.documents =
            filesModel.files
                .filter((f) => !isEmptyOrWhitespace(f.id))
                .map((f) => {
                    const d = new supplierDocumentRequest();
                    d.id = f.id;
                    return d;
                }) ?? [];
    }
}
