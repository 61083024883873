import { runInAction } from "mobx";

import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { WeeklyUpdatesItemModel } from "../WeeklyUpdatesItemModel";
import { WeeklyUpdatesItemViewModel } from "../WeeklyUpdatesItemViewModel";
import { WeeklyUpdatesListViewModel } from "../WeeklyUpdatesListViewModel";

export class Response {
    public currentProjectUpdateTransaction: ICurrentProjectUpdateTransactionResponse | undefined;
    public projectUpdateTransactions: IProjectUpdateTransactionViewResponse[] = [];
}

export interface IProjectUpdateTransactionViewResponse {
    id: string;
    projectUpdateDate: string;
    projectUpdateCommencementDate: string;
    numberOfUpdatesAvailable: number;
    numberOfUpdatesProcessed: number;
    numberOfUpdatesNotProcessed: number;
    numberOfUpdatesFailed: number;
    projectUpdateTransactionStatus: string;
    createdByUserName: string;
    createdDate: string;
}

export interface ICurrentProjectUpdateTransactionResponse {
    canCreateTransaction: boolean;
    numberOfUpdatesAvailable: number;
    projectUpdateDate: string;
    projectUpdateCommencementDate: string;
}

export class GetProjectUpdateTransactionViewsByProjectUpdateDate extends EndpointWithoutRequest<Response> {
    private viewModel: WeeklyUpdatesListViewModel;

    constructor(viewModel: WeeklyUpdatesListViewModel) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.Admin.ProjectUpdates.GetProjectUpdateTransactionViewsByProjectUpdateDate);
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        runInAction(() => {
            const weeklyUpdatesItemViewModel: WeeklyUpdatesItemViewModel[] = [];

            // Send Updates
            this.viewModel.model.canCreateTransaction = response.currentProjectUpdateTransaction!.canCreateTransaction;
            this.viewModel.model.projectUpdateDate = new Date(response.currentProjectUpdateTransaction!.projectUpdateDate);
            this.viewModel.model.projectUpdateCommencementDate = new Date(response.currentProjectUpdateTransaction!.projectUpdateCommencementDate);
            this.viewModel.model.numberOfUpdatesAvailable = response.currentProjectUpdateTransaction!.numberOfUpdatesAvailable;

            // Table
            response.projectUpdateTransactions.forEach((projectUpdateTransaction, index) => {
                const model = new WeeklyUpdatesItemModel();

                model.projectUpdateTransactionId = projectUpdateTransaction.id;
                model.projectUpdateDate = new Date(projectUpdateTransaction.projectUpdateDate);
                model.projectUpdateCommencementDate = new Date(projectUpdateTransaction.projectUpdateCommencementDate);
                model.numberOfUpdatesAvailable = projectUpdateTransaction.numberOfUpdatesAvailable;
                model.numberOfUpdatesProcessed = projectUpdateTransaction.numberOfUpdatesProcessed;
                model.numberOfUpdatesNotProcessed = projectUpdateTransaction.numberOfUpdatesNotProcessed;
                model.numberOfUpdatesFailed = projectUpdateTransaction.numberOfUpdatesFailed;
                model.projectUpdateTransactionStatus = projectUpdateTransaction.projectUpdateTransactionStatus;
                model.createdByUserName = projectUpdateTransaction.createdByUserName;
                model.createdDate = new Date(projectUpdateTransaction.createdDate);

                weeklyUpdatesItemViewModel.push(new WeeklyUpdatesItemViewModel(model));
            });

            this.viewModel.weeklyUpdates.replace(weeklyUpdatesItemViewModel);
        });
    }
}
