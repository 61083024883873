import { singleton } from "tsyringe";
import { Logger } from "../../index";

@singleton()
export class CacheStore {
    private cachePrefix = "SHCACHE_";
    private size: number = 0;

    constructor() {
        Logger.logDebug("Cache store created");
        (window as any).cacheStore = this;
    }

    public set(key: string, value: any, expiryInSeconds: number) {
        const now = new Date();
        key = this.cachePrefix + key;
        try {
            // `item` is an object which contains the original value
            // as well as the time when it's supposed to expire
            const item = {
                value: value,
                expiry: now.getTime() + expiryInSeconds,
            };
            const json = JSON.stringify(item);
            localStorage.setItem(key, json);
            this.size += json.length;
        } catch {}
    }

    public get<T = any>(key: string): T | null {
        key = this.cachePrefix + key;
        const itemStr = localStorage.getItem(key);
        // if the item doesn't exist, return null
        if (!itemStr) {
            return null;
        }
        const item = JSON.parse(itemStr);
        const now = new Date();
        // compare the expiry time of the item with the current time
        if (now.getTime() > item.expiry) {
            // If the item is expired, delete the item from storage
            // and return null
            localStorage.removeItem(key);
            this.size -= item.length;
            return null;
        }
        return item.value;
    }

    public remove = (key: string): void => {
        key = this.cachePrefix + key;
        const itemStr = localStorage.getItem(key);
        localStorage.remove(key);
        this.size -= itemStr?.length ?? 0;
    };

    public has = (key: string): boolean => {
        key = this.cachePrefix + key;
        return this.allKeys().find((k) => k === key) !== undefined;
    };

    public deleteExpiredEntries = () => {
        this.allKeys().forEach((key, index) => {
            const eachitem = this.get(key);
            if (eachitem.expiry < new Date().getTime()) {
                localStorage.removeItem(key);
            }
        });
    };

    public allKeys = (): string[] => {
        const keys: string[] = [];
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i) as string;
            if (key && key.startsWith(this.cachePrefix)) {
                keys.push(key);
            }
        }
        return keys;
    };

    /*public get = <T>(key: string): T | null => {
        return this.internalCacheStore.get(key);
    };

    public set = (key: string, value: any): void => {
        this.internalCacheStore.set(key, value);
    };

    public remove = (key: string): void => {
        this.internalCacheStore.remove(key);
    };

    public keys = (): string[] => {
        return this.internalCacheStore.keys();
    };

    public size = (): number => {
        return this.internalCacheStore.size();
    };

    public has = (key: string): boolean => {
        return this.internalCacheStore.has(key);
    };*/
}
