import { ComboBox, IChoiceGroupOption, IComboBox, IComboBoxOption, IComboBoxOptionStyles, IComboBoxStyles } from "@fluentui/react";
import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import clsx from "clsx";
import { reaction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";

import { ICommand } from "../../../../../Application/Commands";
import { themeColourOptions, themeShapeOptions, themeSizeOptions } from "../../../../../Styles/IShoothillTheme";

/**
 * Muilti-selection Combo box interface.
 */
export interface IComboBoxMultiSelectBaseProps {
    /**
     * An optional class name for use with the combobox.
     */
    className?: string;
    /**
     * A command to execute.
     */
    command: ICommand;
    /**
     * The values to use with the combobox. Will be passed back by the command.
     */
    values: () => string[];
    /**
     * Text content to display in the placeholder.
     */
    placeholder?: string;
    /**
     * Text content to display in the error message.
     */
    validationMessage?: () => string;
    /**
     * A collection of options to display in the combox dropdown.
     */
    options: IComboBoxOption[];
    /**
     * Text content to display on the combox.
     */
    displayName?: string;
    /**
     * Styling of the combobox options.
     */
    optionStyles?: IComboBoxOptionStyles;
    /**
     * Styling of the combobox.
     */
    styles?: IComboBoxStyles;
    /**
     * The size of the control - use this if using generic control.
     */
    size?: themeSizeOptions;
    /**
     * The shape of the control - use this if using the generic control.
     */
    shape?: themeShapeOptions;

    labelColor?: themeColourOptions;

    onRenderLabel?: (isInError: boolean) => JSX.Element;

    description?: string;
}

export const ComboBoxMultiSelectBase: React.FC<IComboBoxMultiSelectBaseProps> = observer((props) => {
    // #region Code Behind

    const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);

    useEffect(() => {
        reaction(
            () => props.values(),
            (value) => {
                setSelectedKeys(value);
            },
        );

        setSelectedKeys(props.values());
    }, [props.values]);

    const getClasseNames = () => {
        return clsx({
            [props.className!]: !isEmptyOrWhitespace(props.className),
        });
    };

    const getValidationMessage = (): string => {
        return isEmptyOrWhitespace(props.validationMessage?.() as string) ? "" : (props.validationMessage?.() as string);
    };

    const isDisabled = (): boolean => {
        return isNullOrUndefined(props.command.canExecute) ? false : !props.command.canExecute();
    };

    const getStyles = (): IComboBoxStyles | undefined => {
        return !isNullOrUndefined(props.styles) ? props.styles : undefined;
    };

    const getComboBoxOptionStyles = (): IComboBoxOptionStyles | undefined => {
        return !isNullOrUndefined(props.optionStyles) ? props.optionStyles : undefined;
    };

    const onRenderLabel = (isInError: boolean): JSX.Element => {
        return props.onRenderLabel?.(isInError) ?? <>{props.description}</>;
    };

    const getOptions = (): IComboBoxOption[] => {
        return props.options.map((option) => option);
    };

    const onChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        let keys: string[] = [];

        if (option) {
            keys = option.selected ? [...selectedKeys, option.key as string] : selectedKeys.filter((key) => key !== option.key);
            setSelectedKeys(keys);
        }

        props.command.execute(keys);
    };

    // #endregion Code Behind

    return (
        <ComboBox
            className={getClasseNames()}
            disabled={isDisabled()}
            errorMessage={getValidationMessage()}
            onRenderLabel={() => onRenderLabel(getValidationMessage() !== "")}
            label={props.displayName}
            multiSelect={true}
            onChange={onChange}
            options={getOptions()}
            placeholder={props.placeholder}
            selectedKey={props.values()}
            styles={getStyles()}
            comboBoxOptionStyles={getComboBoxOptionStyles()}
            useComboBoxAsMenuWidth={true}
        />
    );
});
