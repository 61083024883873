import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { WeeklyUpdateModel } from "../WeeklyUpdate/WeeklyUpdateModel";
import { WeeklyUpdateViewModel } from "../WeeklyUpdate/WeeklyUpdateViewModel";

class Request {
    id: string | null = null;
    projectId: string = "";
    updateDetail: string = "";
    internalDetail: string = "";
    updateDate: Date | null = null;
    sendUpdate: boolean = false;
}

class Response {}

export class POSTSaveWeeklyUpdateEndpoint extends Endpoint<Request, Response> {
    private readonly viewModel: WeeklyUpdateViewModel;

    constructor(viewModel: WeeklyUpdateViewModel) {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Resources.SaveProjectWeeklyUpdateArchitect);
        this.viewModel = viewModel;
    }

    public async HandleRequestAsync(model: WeeklyUpdateModel): Promise<any> {
        const request = new Request();
        model.toRequest(request);
        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        return Promise.resolve(response);
    }
}
