import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

export class BillingNotesModel extends ModelBase<BillingNotesModel> {
    public id: string = "";

    constructor() {
        super();

        makeObservable(this, {
            id: observable,
        });
    }
}
