import { FieldType, isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { APIClient } from "Application";
import { RelayCommand } from "Application/Commands";
import { AppUrls } from "AppUrls";
import { GETTermsAndConditionByIdEndpoint } from "./GETTermsAndConditionByIdEndpoint";
import { TermsAndConditionModel, TermsAndConditionModelValidator } from "./TermsAndConditionModel";
import { POSTSaveTermsAndConditionEndpoint } from "./POSTSaveTermsAndConditionEndpoint";
import { DeleteTermsAndConditionEndpoint } from "./DeleteTermsAndConditionEndpoint";
import { LookupStore } from "Stores/Domain";
import { container } from "tsyringe";

export class TermsAndConditionViewModel extends ViewModelBase<TermsAndConditionModel> {
    private lookupStore = container.resolve(LookupStore);
    public apiClient = new APIClient();
    public deletePopup: boolean = false;

    constructor(id: string | undefined) {
        super(new TermsAndConditionModel());
        this.setValidator(new TermsAndConditionModelValidator());

        makeObservable(this, { deletePopup: observable });

        if (id) {
            this.apiClient.sendAsync(new GETTermsAndConditionByIdEndpoint(id, this));
        }
    }

    // #region Properties

    public get displayName() {
        return isEmptyOrWhitespace(this.model.id) ? "New template" : "Edit template";
    }

    public get updatedText() {
        return isEmptyOrWhitespace(this.model.id) ? "Create" : "Update";
    }

    public get setDelete(): boolean {
        return isEmptyOrWhitespace(this.model.id);
    }

    public get canDisplayDelete(): boolean {
        return this.deletePopup;
    }

    public get termsAndConditionsTypes() {
        return this.lookupStore.getTermsAndConditionsTypes.map((item) => {
            return {
                key: item.key,
                text: item.text,
            };
        });
    }

    // #endregion Properties

    // #region Commands

    public deleteModalOpenCommand = new RelayCommand(
        () => {
            this.deletePopup = true;
        },
        () => {
            // If the API is busy, we don't want to try and display
            // a modal to carry out a delete operation.
            return !this.apiClient.IsBusy;
        },
    );

    public closeDeleteModalCommand = new RelayCommand(() => {
        this.deletePopup = false;
    });

    public updateTermsAndConditionTitleCommand = new RelayCommand((value: string) => {
        this.updateField("name", value);
    });

    public updateTermsAndConditionText = (value: string): void => {
        this.updateField("body", value);
    };

    public updateTermsAndConditionTypeCommand = new RelayCommand((id: string | null) => {
        this.updateField("termsAndConditionsTypeId", id);
    });

    public navigateToListCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.TermConditionTemplates.Table);
    });

    public createTermsAndConditionCommand = new RelayCommand(
        () => {
            if (this.canSubmitForm) {
                this.apiClient.sendAsync(new POSTSaveTermsAndConditionEndpoint(this), this.model);
            }
        },
        () => {
            return !this.apiClient.IsBusy;
        },
    );

    public deleteTermsAndConditionCommand = new RelayCommand(
        () => {
            this.apiClient.sendAsync(new DeleteTermsAndConditionEndpoint(this.model.id!, this));
        },
        () => {
            return !this.apiClient.IsBusy;
        },
    );

    public resetServerErrorCommand = new RelayCommand(() => {
        this.apiClient.reset();
    });

    // #endregion Commands

    // #region Supporting

    private async updateField(fieldName: keyof FieldType<TermsAndConditionModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    public get canSubmitForm(): boolean {
        return this.isModelValid();
    }

    // #endregion Supporting
}
