import { IChoiceGroupOptionStyles, IChoiceGroupStyles, IStyle, mergeStyleSets } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React from "react";

import { AutoGrid, theme, ThemedButton, ThemedEditText, ThemedRadioButtons, ThemedText, ThemedTransparentIconButton } from "Application";
import { CrossSVG } from "Assets/CrossSVG";
import { NewProjectViewModel } from "./NewProjectViewModel";
import { SearchIcon } from "Assets/SearchIcon";

interface INewProjectViewStyles {
    root: IStyle;
    header: IStyle;
    body: IStyle;
    footer: IStyle;
    iconButton: IStyle;
}

interface INewProjectViewProps {
    viewModel: NewProjectViewModel;
}

const styleDefinitions: INewProjectViewStyles = {
    root: {
        fontFamily: `"Poppins", sans-serif`,
        width: "700px",
    },
    header: {
        alignItems: "center",
        backgroundColor: "#F3F3F3",
        display: "flex",
        height: "75px",
        justifyContent: "space-between",
        padding: "20px 30px",
    },
    body: {
        padding: "30px 30px 0 30px",
    },
    footer: {
        display: "flex",
        height: "75px",
        padding: "20px 30px",
    },
    iconButton: {
        g: {
            stroke: theme.palette.defaultTr.text,
        },
    },
};

const radioButtonGroupStyleDefintions: IChoiceGroupStyles = {
    root: {
        maxHeight: "190px",
        overflowY: "auto",
    },
};

const radioButtonStyleDefinitions: IChoiceGroupOptionStyles = {
    root: {
        alignItems: "center",
        backgroundColor: "#FCFCFC",
        borderLeft: "5px solid #ff4b22",
        display: "flex",
        paddingLeft: "10px",
        marginBottom: "10px",
        justifyContent: "space-between",
        label: {
            ".ms-ChoiceFieldLabel": {
                display: "flex",
                flex: "1",
                paddingLeft: "0",
                paddingRight: "38px",
            },
            "::before": {
                left: "auto",
                right: "0",
            },
            "::after": {
                left: "auto",
                right: "5px",
            },
            ":hover::after": {
                left: "auto",
                right: "5px",
            },
        },
    },
    choiceFieldWrapper: {
        flex: "1",
    },
    field: {
        flex: "1",
    },
};

export const NewProjectView: React.FC<INewProjectViewProps> = observer((props) => {
    const viewModel = props.viewModel;
    const styles = mergeStyleSets(styleDefinitions);

    if (viewModel) {
        return (
            <div className={styles.root}>
                <div className={styles.header}>
                    <ThemedText fontStyle="h2">New project</ThemedText>
                    <ThemedTransparentIconButton command={viewModel.parentCancelCommand} icon={<CrossSVG className={styles.iconButton} />} shape="round" />
                </div>
                <div className={styles.body}>
                    <ThemedText fontStyle="h5">Select the quote you want to convert into a new project</ThemedText>
                    <ThemedEditText
                        command={viewModel.updateFilterKeywordCommand}
                        placeholder={"Keyword search"}
                        prefix={<SearchIcon />}
                        styles={{ root: { fontFamily: theme.defaultFontStyle.fontFamily } }}
                        value={() => props.viewModel.getValue("filterKeyword")}
                    />
                    <ThemedRadioButtons
                        styles={radioButtonGroupStyleDefintions}
                        optionStyles={radioButtonStyleDefinitions}
                        options={props.viewModel.filteredQuotes}
                        value={() => props.viewModel.getValue("quoteId")}
                        command={props.viewModel.updateSelectQuoteCommand}
                    />
                </div>
                <AutoGrid m={0} className={styles.footer} columnGap={"30px"} dc={"140px 140px"}>
                    <ThemedButton paletteColor="primary" command={viewModel.updateCommand} displayName="Continue" styles={{ root: { padding: "0 20px" } }} />
                    <ThemedButton command={viewModel.parentCancelCommand} displayName="Cancel" styles={{ root: { padding: "0 20px" } }} />
                </AutoGrid>
            </div>
        );
    }

    return <React.Fragment />;
});
