//libraries
import React from "react";
import { Box, ThemedText } from "Application";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { InvoiceHistoryMonthCapacityModel } from "./InvoiceHistoryMonthCapacityModel";
import { InvoiceForecastMonthCapacityOverrideView } from "Views/Admin/Shared/InvoiceForecastMonthCapacityOverride/InvoiceForecastMonthCapacityOverrideView";

//app
interface IProps {
    model: InvoiceHistoryMonthCapacityModel;
    isEditable: boolean;
}

export const InvoiceHistoryMonthCapacityView: React.FC<IProps> = observer((props: IProps) => {
    return (
        <>
            <Box flexBox alignItems={"center"}>
                <ThemedText>{moment(props.model.month).format("MMMM")}</ThemedText>
            </Box>
            <InvoiceForecastMonthCapacityOverrideView model={props.model.invoiceForecastCapacityOverride!} isEditable={props.isEditable} />
        </>
    );
});
