import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { CancelLeaveRequestModel } from "../CancelLeaveRequestModel";

class Request {
    userId: string | null = null;
    leaveRequestId: string | null = null;
    responseReason: string | null = null;
}

class Response {}

export class POSTCancelLeaveForUserEndpoint extends Endpoint<Request, Response> {
    constructor() {
        super();

        this.verb(Http.Post);
        this.path(AppUrls.Server.Admin.Leave.RequestCancelLeaveForUser);
    }

    public async HandleRequestAsync(model: CancelLeaveRequestModel): Promise<Request> {
        const request = new Request();

        request.userId = model.leaveRequest.requestUserId;
        request.leaveRequestId = model.leaveRequest.id;
        request.responseReason = model.responseReason;

        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);
    }
}
