import { runInAction } from "mobx";

import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { ICalloutResponse } from "./Shared";
import { CalloutInformationItemModel } from "../CalloutInformationItemModel";
import { CalloutInformationItemViewModel } from "../CalloutInformationItemViewModel";
import { CalloutInformationViewModel } from "../CalloutInformationViewModel";

export class Response {
    items: ICalloutResponse[] = [];
}

export class GetUnprocessedTransactionProjectUpdates extends EndpointWithoutRequest<Response> {
    private viewModel: CalloutInformationViewModel;

    constructor(viewModel: CalloutInformationViewModel) {
        super();

        this.verb(Http.Get);
        this.path(AppUrls.Server.Admin.ProjectUpdates.GetUnprocessedTransactionProjectUpdates.replace(":id", viewModel.model.projectTransactionId));
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        runInAction(() => {
            const viewModels: CalloutInformationItemViewModel[] = [];

            for (const item of response.items) {
                const model = new CalloutInformationItemModel();

                model.customerId = item.customerId;
                model.customerName = item.customerName;
                model.projectId = item.projectId;
                model.projectReference = item.projectReference;

                viewModels.push(new CalloutInformationItemViewModel(model));
            }

            this.viewModel.itemViewModels.replace(viewModels);
        });
    }
}
