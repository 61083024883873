import { ModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

export class ProjectTaskAssignmentEventModel extends ModelBase<ProjectTaskAssignmentEventModel> {
    public id: string = "";
    public taskTitle: string = "";
    public taskGroupTitle: string = "";
    public projectTaskAssignmentStatusId: string = "";
    public projectTaskAssignmentNote: string = "";
    public projectTaskAssignmentIsReviewed: boolean = false;

    constructor() {
        super();

        makeObservable(this, {});
    }
}
