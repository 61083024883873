import { ICalloutContentStyles, ILabelStyles, ITooltipHostStyles, Label, mergeStyleSets, Stack, TextField, TooltipHost } from "@fluentui/react";
import { isEmptyOrWhitespace } from "@shoothill/core";
import { observer } from "mobx-react-lite";
import { nanoid } from "nanoid";
import { NumericFormat } from "react-number-format";

import { ICommand, isTypeOfNumber, pxToRem, theme, ThemedText } from "Application";
import { ExclamationIconSVG } from "Assets/ExclamationIconSVG";

interface IProps {
    command: ICommand;
    displayName: string;
    onBlur: () => void;
    placeholder?: string;
    suffix?: string;
    validationMessage: () => string;
    value: () => string | number;
}

export const AdjustmentInput: React.FC<IProps> = observer((props) => {
    const haveError = !isEmptyOrWhitespace(props.validationMessage());

    const inputStyles = mergeStyleSets(
        // The first style set concerns the border of the text field. Setting the
        // border color on hover and focus states is messy, so I've extracted those
        // style properties here.
        {
            fieldGroup: {
                borderColor: haveError ? theme.palette.error.light : theme.palette.field.light,
                "::after": {
                    borderColor: theme.palette.common.focus,
                },
                ":hover": {
                    borderColor: theme.palette.field.main,
                },
                ":hover::after": {
                    borderColor: haveError ? theme.palette.field.main : theme.palette.common.focus,
                },
            },
        },
        {
            root: {},
            description: {},
            errorMessage: {
                color: theme.palette.error.text,
                fontFamily: theme.fontStyles.error.fontFamily ?? theme.defaultFontStyle.fontFamily,
                fontSize: theme.fontStyles.error.fontSize ?? theme.defaultFontStyle.fontSize,
                fontWeight: theme.fontStyles.error.fontWeight ?? theme.defaultFontStyle.fontWeight,
                letterSpacing: theme.fontStyles.error.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                lineHeight: theme.fontStyles.error.lineHeight ?? theme.defaultFontStyle.lineHeight,
                textTransform: theme.fontStyles.error.textTransform ?? theme.defaultFontStyle.textTransform,
            },
            field: {
                color: theme.palette.field.text,
                fontFamily: theme.fontStyles.field.fontFamily ?? theme.defaultFontStyle.fontFamily,
                fontSize: theme.fontStyles.field.fontSize ?? theme.defaultFontStyle.fontSize,
                fontWeight: theme.fontStyles.field.fontWeight ?? theme.defaultFontStyle.fontWeight,
                letterSpacing: theme.fontStyles.field.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                lineHeight: theme.fontStyles.field.lineHeight ?? theme.defaultFontStyle.lineHeight,
                textTransform: theme.fontStyles.field.textTransform ?? theme.defaultFontStyle.textTransform,
                padding: `0 ${pxToRem(10)}`,
            },
            fieldGroup: {
                height: theme.sizes.larger,
            },
            icon: {},
            prefix: {},
            revealButton: {
                height: "99%",
                ":hover": {
                    cursor: "pointer",
                },
            },
            revealIcon: {},
            revealSpan: {},
            subComponentStyles: {
                label: {
                    root: {
                        color: theme.palette.common.default,
                        fontFamily: theme.fontStyles.label.fontFamily ?? theme.defaultFontStyle.fontFamily,
                        fontSize: theme.fontStyles.label.fontSize ?? theme.defaultFontStyle.fontSize,
                        fontWeight: theme.fontStyles.label.fontWeight ?? theme.defaultFontStyle.fontWeight,
                        letterSpacing: theme.fontStyles.label.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                        lineHeight: theme.fontStyles.label.lineHeight ?? theme.defaultFontStyle.lineHeight,
                        textTransform: theme.fontStyles.label.textTransform ?? theme.defaultFontStyle.textTransform,
                    },
                },
            },
            suffix: {},
            wrapper: {},
        },
    );

    const labelStyles: Partial<ILabelStyles> = {
        root: {
            color: "#171716",
            fontFamily: theme.fontStyles.label.fontFamily ?? theme.defaultFontStyle.fontFamily,
            fontSize: theme.fontStyles.label.fontSize ?? theme.defaultFontStyle.fontSize,
            fontWeight: theme.fontStyles.label.fontWeight ?? theme.defaultFontStyle.fontWeight,
            letterSpacing: theme.fontStyles.label.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
            lineHeight: theme.fontStyles.label.lineHeight ?? theme.defaultFontStyle.lineHeight,
            textTransform: theme.fontStyles.label.textTransform ?? theme.defaultFontStyle.textTransform,
        },
    };

    const toolTipHostStyles: Partial<ITooltipHostStyles> = {
        root: {
            "&&": {
                margin: `auto auto auto ${pxToRem(10)}`,
                paddingTop: pxToRem(4),
            },
        },
    };

    const calloutStyles: Partial<ICalloutContentStyles> = {
        beak: { background: theme.palette.common.error },
        beakCurtain: { background: theme.palette.common.error },
        calloutMain: { background: theme.palette.common.error },
    };

    /**
     * Renders a label for the control with an optional error excalmation.
     */
    const renderAdjustmentInputLabel = (displayName: string, validationMessage: string) => {
        if (!isEmptyOrWhitespace(validationMessage)) {
            return (
                <Stack horizontal>
                    <Label styles={labelStyles}>{displayName}</Label>
                    <TooltipHost
                        id={nanoid()}
                        content={
                            <ThemedText fontStyle={"h7"} color={"white"}>
                                {validationMessage}
                            </ThemedText>
                        }
                        tooltipProps={{
                            calloutProps: {
                                gapSpace: -5,
                                styles: calloutStyles,
                            },
                        }}
                        styles={toolTipHostStyles}
                    >
                        <ExclamationIconSVG backgroundcolor={theme.palette.common.error} color={theme.palette.common.white} />
                    </TooltipHost>
                </Stack>
            );
        }

        return (
            <Stack horizontal>
                <Label styles={labelStyles}>{displayName}</Label>
            </Stack>
        );
    };

    return (
        <Stack>
            {renderAdjustmentInputLabel(props.displayName, props.validationMessage())}
            <NumericFormat
                allowLeadingZeros={true}
                allowNegative={true}
                customInput={TextField}
                decimalScale={1}
                disabled={!props.command.canExecute()}
                onBlur={props.onBlur}
                onValueChange={(values: any) => {
                    // Only return a number if the floatValue is a number and the string value
                    // is not some variant of negative zero. We need the latter as a string so
                    // we can type in negative values less than 1.
                    const returnAsNumber = isTypeOfNumber(values.floatValue) && values.value !== "-0" && values.value !== "-0.";

                    props.command.execute(returnAsNumber ? values.floatValue : values.value);
                }}
                placeholder={props.placeholder}
                styles={inputStyles}
                suffix={props.suffix}
                value={props.value()}
            />
        </Stack>
    );
});
