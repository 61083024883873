import { runInAction } from "mobx";

import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { LeaveDayType } from "Views/Leave/Shared/LeaveDayType";
import { LeaveRequestUser } from "Views/Leave/Shared/LeaveRequestUser";
import { LeaveType } from "Views/Leave/Shared/LeaveType";
import { LeaveRequestsViewModel } from "../LeaveRequestsViewModel";
import { LeaveRequestItemViewModel } from "../LeaveRequestItemViewModel";
import { LeaveRequestItemModel } from "../LeaveRequestItemModel";

class Response {
    public leaveRequests: UserAndLeaveRequestResponse[] = [];
    public users: RequestUserResponse[] = [];
    public leaveTypes: LeaveTypeResponse[] = [];
    public leaveDayTypes: LeaveDayTypeResponse[] = [];
}

class UserAndLeaveRequestResponse {
    public leaveRequest: LeaveRequestResponse | null = null;
    public requestUser: RequestUserResponse | null = null;
}

class LeaveRequestResponse {
    public id: string | null = null;

    // Value Objects
    public leaveType: LeaveTypeResponse | null = null;
    public leaveStatusType: LeaveStatusTypeResponse | null = null;
    public fromDate: RequestDateResponse | null = null;
    public toDate: RequestDateResponse | null = null;

    // User Request
    public requestUserId: string | null = null;
    public requesteDate: string | null = null;
    public requestReason: string | null = null;

    // User Response
    public responseUserId: string | null = null;
    public responseDate: string | null = null;
    public responseReason: string | null = null;

    // Additonal
    public numberOfDays: number | null = null;
    public leaveAllowanceNumberOfDaysLeft: number | null = null;
    public originalLeaveRequestId: string | null = null;
}

class RequestUserResponse {
    public id: string | null = null;
    public firstName: string | null = null;
    public lastName: string | null = null;
    public documentUrl: string | null = null;
}

class LeaveTypeResponse {
    public id: string | null = null;
    public name: string | null = null;
    public type: string | null = null;
    public ordinal: number | null = null;
    public isDeleted: boolean | null = null;
    public foregroundColor: string | null = null;
    public backgroundColor: string | null = null;
    public isTransactionRequest: boolean | null = null;
    public isTransactionCancelRequest: boolean | null = null;
}

class LeaveStatusTypeResponse {
    public id: string | null = null;
    public name: string | null = null;
    public type: string | null = null;
    public ordinal: number | null = null;
    public isDeleted: boolean | null = null;
    public foregroundColor: string | null = null;
    public backgroundColor: string | null = null;
}

class RequestDateResponse {
    public date: string | null = null;
    public leaveDayType: LeaveDayTypeResponse | null = null;
}

class LeaveDayTypeResponse {
    public id: string | null = null;
    public name: string | null = null;
    public type: string | null = null;
    public ordinal: number | null = null;
    public isStartDay: boolean | null = null;
}

export class GETAllPendingLeaveRequestsEndpoint extends EndpointWithoutRequest<Response> {
    private viewModel: LeaveRequestsViewModel;

    constructor(viewModel: LeaveRequestsViewModel) {
        super();

        this.viewModel = viewModel;
        this.verb(Http.Get);
        this.path(AppUrls.Server.Admin.Leave.GetAllPendingLeaveRequests);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            // Users.
            const leaveRequestUsers: LeaveRequestUser[] = [];

            response.users.forEach((item: RequestUserResponse) => {
                const valueObject = new LeaveRequestUser();

                valueObject.id = item.id!;
                valueObject.firstName = item.firstName!;
                valueObject.lastName = item.lastName!;
                valueObject.documentUrl = item.documentUrl;

                leaveRequestUsers.push(valueObject);
            });

            this.viewModel.model.users.replace(leaveRequestUsers);

            // Leave Types.
            const leaveTypes: LeaveType[] = [];

            response.leaveTypes.forEach((item: LeaveTypeResponse) => {
                const valueObject = new LeaveType();

                valueObject.id = item.id!;
                valueObject.name = item.name!;
                valueObject.type = item.type!;
                valueObject.ordinal = item.ordinal!;
                valueObject.isDeleted = item.isDeleted!;
                valueObject.foregroundColor = item.foregroundColor!;
                valueObject.backgroundColor = item.backgroundColor!;
                valueObject.isTransactionRequest = item.isTransactionRequest!;
                valueObject.isTransactionCancelRequest = item.isTransactionCancelRequest!;

                leaveTypes.push(valueObject);
            });

            this.viewModel.model.leaveTypes.replace(leaveTypes);

            // Leave Day Types.
            const leaveDayTypes: LeaveDayType[] = [];

            response.leaveDayTypes.forEach((item: LeaveDayTypeResponse) => {
                const valueObject = new LeaveDayType();

                valueObject.id = item.id!;
                valueObject.name = item.name!;
                valueObject.type = item.type!;
                valueObject.ordinal = item.ordinal!;
                valueObject.isStartDay = item.isStartDay!;

                leaveDayTypes.push(valueObject);
            });

            this.viewModel.model.leaveDayTypes.replace(leaveDayTypes);

            // Leave requests.
            const viewModels: LeaveRequestItemViewModel[] = [];

            response.leaveRequests.forEach((item: UserAndLeaveRequestResponse) => {
                const model = new LeaveRequestItemModel();

                model.fromResponse(item);

                viewModels.push(new LeaveRequestItemViewModel(model));
            });

            this.viewModel.leaveRequests.replace(viewModels);
        });
    }
}
