import styled from "@emotion/styled";
import { IChoiceGroupStyles, IPanelStyles, ISeparatorProps, mergeStyleSets, Separator } from "@fluentui/react";
import { isEmptyOrWhitespace } from "@shoothill/core";

import { AutoGrid, AutoGridProps, Box, IEditTextBaseProps, ITextBaseProps, theme, ThemedButton, ThemedEditText, ThemedText } from "Application";
import { IPersonaBaseProps } from "Components/StyleFrameworks/FluentUI/Primitives/Active/PersonaBase";
import { PropsWithChildren } from "react";
import { SpaceProps } from "styled-system";
import { ThemedPersona } from "Styles/ThemedPrimitives/Style1/ThemedPersona";
import { Files, IFilesProps } from "./Files/Files";
import { FilesReadOnlyView } from "./Files/FilesReadOnlyView";

/**
 * Contains the contents of the page.
 */
export const PageContainer = styled(Box)`
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
`;

/**
 * Contains the pages navigation bar
 */
export const NavigationContainer = styled(Box)`
    align-items: center;
    background-color: #f3f3f3;
    cursor: pointer;
    display: flex;
    min-height: 40px;
    justify-content: space-between;
    padding: 0px 30px;
`;

/**
 * Contains the pages title bar.
 */
export const TitleContainer = styled(Box)`
    background-color: #fcfcfc;
    border-bottom: 1px solid #dcdcdc;
    padding: 23px 30px;
`;

/**
 * Contains the revision navigation bar.
 */
export const RevisionNavigationContainer = styled(Box)`
    align-items: end;
    display: flex;
    height: 30px;
    justify-content: end;
    margin-right: 30px;
    margin-top: -30px;
`;

/**
 * Contains the contents of the form.
 */
export const FormContainer = styled(Box)`
    background-color: ${theme.palette.common.white};
    flex: 1;
    padding: 21px 30px;
`;

/**
 * Contains the sub components of the form.
 */
export const FormSubContainer = styled(Box)`
    flex: 1;
    margin-bottom: 55px;
`;

/**
 * Contains the contents of a row. To be used as an immediate child to a container.
 *
 * @param props auto grid props. If making subtle styling changes, you may need to append !important.
 */
export const FormContainerRow = (props: AutoGridProps) => <AutoGrid m="0 0 21px 0" columnGap="30px" {...props} />;

/**
 * Form separator.
 *
 * @param props Separator props. If making subtle styling changes, you may need to append !important.
 */
export const FormSeparator = (props: ISeparatorProps) => (
    <Separator
        {...props}
        styles={mergeStyleSets(
            {
                root: {
                    padding: "0 0 2px 0",
                    lineHeight: "1.25",
                    "::before": {
                        backgroundColor: "#dcdcdc",
                    },
                },
            },
            props.styles,
        )}
    />
);

export const PrimaryTableSeparator = (props: ISeparatorProps) => (
    <Separator
        {...props}
        styles={mergeStyleSets(
            {
                root: {
                    fontSize: "0",
                    padding: "0 0 2px 0",
                    lineHeight: "1.25",
                    "::before": {
                        backgroundColor: theme.palette.primary.light,
                    },
                },
            },
            props.styles,
        )}
    />
);

export const SecondaryTableSeparator = (props: ISeparatorProps) => (
    <Separator
        {...props}
        styles={mergeStyleSets(
            {
                root: {
                    fontSize: "0",
                    padding: "0 0 2px 0",
                    lineHeight: "1.25",
                    "::before": {
                        backgroundColor: theme.palette.secondary.light,
                    },
                },
            },
            props.styles,
        )}
    />
);

export const BilledTaskTableSeparator = (props: ISeparatorProps) => (
    <Separator
        {...props}
        styles={mergeStyleSets(
            {
                root: {
                    fontSize: "0",
                    padding: "0 0 2px 0",
                    lineHeight: "1.25",
                    "::before": {
                        backgroundColor: "#55DB0B",
                    },
                },
            },
            props.styles,
        )}
    />
);

/**
 * Form separator but with a dotted style.
 *
 * @param props Separator props. If making subtle styling changes, you may need to append !important.
 */
export const FormDottedSeparator = (props: ISeparatorProps) => (
    <Separator
        {...props}
        styles={mergeStyleSets(
            {
                root: {
                    padding: "0 0 2px 0",
                    lineHeight: "1.25",
                    "::before": {
                        backgroundImage: "linear-gradient(to right, #dcdcdc, #dcdcdc 50%, rgba(255,255,255,0) 50%)",
                        backgroundPosition: "bottom",
                        backgroundSize: "6px 2px",
                        backgroundRepeat: "repeat-x",
                    },
                },
            },
            props.styles,
        )}
    />
);

/**
 * Table separator but with a dotted style.
 *
 * @param props Separator props. If making subtle styling changes, you may need to append !important.
 */
export const DefaultTableDottedSeparator = (props: ISeparatorProps) => (
    <Separator
        {...props}
        styles={mergeStyleSets(
            {
                root: {
                    fontSize: "0",
                    padding: "0 0 2px 0",
                    lineHeight: "1.25",
                    "::after": {
                        backgroundImage: `linear-gradient(to ${props.vertical ? "bottom" : "right"},#343A40, #343A40 50%, rgba(255,255,255,0) 50%)`,
                        backgroundPosition: props.vertical ? "left" : "bottom",
                        backgroundSize: props.vertical ? "2px 6px" : "6px 2px",
                        backgroundRepeat: props.vertical ? "repeat-y" : "repeat-x",
                    },
                },
            },
            props.styles,
        )}
    />
);

/**
 * Table separator but with a dotted style.
 *
 * @param props Separator props. If making subtle styling changes, you may need to append !important.
 */
export const PrimaryTableDottedSeparator = (props: ISeparatorProps) => (
    <Separator
        {...props}
        styles={mergeStyleSets(
            {
                root: {
                    fontSize: "0",
                    padding: "0 0 2px 0",
                    lineHeight: "1.25",
                    "::before": {
                        backgroundImage: `linear-gradient(to right, ${theme.palette.primary.light}, ${theme.palette.primary.light} 50%, rgba(255,255,255,0) 50%)`,
                        backgroundPosition: "bottom",
                        backgroundSize: "6px 2px",
                        backgroundRepeat: "repeat-x",
                    },
                },
            },
            props.styles,
        )}
    />
);

/**
 * Table separator but with a dotted style.
 *
 * @param props Separator props. If making subtle styling changes, you may need to append !important.
 */
export const SecondaryTableDottedSeparator = (props: ISeparatorProps) => (
    <Separator
        {...props}
        styles={mergeStyleSets(
            {
                root: {
                    fontSize: "0",
                    padding: "0 0 2px 0",
                    lineHeight: "1.25",
                    "::before": {
                        backgroundImage: `linear-gradient(to right, ${theme.palette.secondary.light}, ${theme.palette.secondary.light} 50%, rgba(255,255,255,0) 50%)`,
                        backgroundPosition: "bottom",
                        backgroundSize: "6px 2px",
                        backgroundRepeat: "repeat-x",
                    },
                },
            },
            props.styles,
        )}
    />
);

/**
 * Table separator but with a dotted style.
 *
 * @param props Separator props. If making subtle styling changes, you may need to append !important.
 */
export const BilledTaskDottedSeparator = (props: ISeparatorProps) => (
    <Separator
        {...props}
        styles={mergeStyleSets(
            {
                root: {
                    fontSize: "0",
                    padding: "0 0 2px 0",
                    lineHeight: "1.25",
                    "::before": {
                        backgroundImage: `linear-gradient(to right, ${"#55DB0B"}, ${"#55DB0B"} 50%, rgba(255,255,255,0) 50%)`,
                        backgroundPosition: "bottom",
                        backgroundSize: "6px 2px",
                        backgroundRepeat: "repeat-x",
                    },
                },
            },
            props.styles,
        )}
    />
);

/**
 * Themed edit text component with no label, but offset as if it had.
 *
 * @param props Edit text base props.
 */
export const ThemedEditTextNoLabel = (props: PropsWithChildren<IEditTextBaseProps>) => (
    <ThemedEditText
        {...props}
        styles={mergeStyleSets(
            {
                root: {
                    marginTop: !isEmptyOrWhitespace(props.validationMessage?.() as string) ? "0px !important" : "15px",
                },
                subComponentStyles: {},
            },
            props.styles,
        )}
    />
);

/**
 * Themed text ratings label.
 *
 * @param props Text base props.
 */
export const ThemedTextRatingLabel = (props: PropsWithChildren<ITextBaseProps & SpaceProps>) => (
    <ThemedText
        {...props}
        styles={mergeStyleSets(
            {
                root: {
                    fontSize: "10px",
                    padding: "5px 0",
                },
            },
            props.styles,
        )}
    />
);

/**
 * Contains the ratings buttons.
 */
export const RatingsContainer = styled(Box)`
    align-items: center;
    display: flex;
    height: 2.5rem;
`;

/**
 * Rating button.
 */
export const RatingButton = styled("button")`
    border: none;
    cursor: pointer;
    height: 22px;
    width: 22px;
`;

/**
 * Item information container.
 */
export const ItemInformationContainer = styled(Box)`
    align-items: stretch;
    background: #fcfcfc 0 0 no-repeat padding-box;
    box-shadow: 0 2px 3px #00000029;
    border-radius: 1px;
    display: flex;
    font-size: 10px;
    margin-bottom: 5px;
`;

/**
 * Item information container (selectable).
 */
export const SelectableItemInformationContainer = styled(ItemInformationContainer)`
    cursor: pointer;
`;

/**
 * Item information cell.
 */
export const ItemInformationCell = styled(Box)`
    align-items: center;
    display: flex;
    padding: 10px 15px;
`;

/**
 * Group Item information container.
 */
export const GroupItemInformationContainer = styled(Box)`
    align-items: stretch;
    background: #fcfcfc 0 0 no-repeat padding-box;
    box-shadow: 0 2px 3px #00000029;
    border-radius: 1px;
    display: flex;
    font-size: 10px;
    margin-bottom: 5px;
`;

/**
 * Group Item information subcontainer.
 */
export const GroupItemInformationSubContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 2px 0;
`;

/**
 * Group Item information cell.
 */
export const GroupItemInformationCell = styled(Box)`
    align-items: center;
    display: flex;
    padding: 0px 15px;
`;

/**
 * Places choice group options in a row.
 */
export const ChoiceGroupRowStyles: IChoiceGroupStyles = {
    label: {
        display: "inline",
    },
    flexContainer: {
        columnGap: "30px",
        display: "inline-flex",
        flexDirection: "row",
        flexWrap: "wrap",
    },
};

/**
 * Enquiry status.
 */
export const EnquiryStatus = styled(Box)`
    align-items: center;
    color: white;
    display: flex;
    font-size: 10px;
    justify-content: center;
    padding: 0 20px;
    min-height: 30px;
    min-width: 130px;
`;

/**
 * Quote status.
 */
export const QuoteStatus = styled(Box)`
    align-items: center;
    color: white;
    display: flex;
    font-size: 10px;
    justify-content: center;
    padding: 0 20px;
    min-height: 30px;
    min-width: 130px;
`;

/**
 * Project status.
 */
export const ProjectStatus = styled(Box)`
    align-items: center;
    color: white;
    display: flex;
    font-size: 10px;
    justify-content: center;
    padding: 0 20px;
    min-height: 30px;
    min-width: 130px;
`;

/**
 * Basics for a Panel.
 */
export const PanelStyles: IPanelStyles = {
    commands: {
        display: "none",
    },
    content: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        padding: 0,
    },
    contentInner: {},
    footer: {},
    footerInner: {},
    header: {},
    headerText: {},
    hiddenPanel: {},
    main: {},
    navigation: {},
    overlay: {},
    root: {},
    scrollableContent: {
        display: "flex",
        flex: 1,
    },
    subComponentStyles: {
        closeButton: {},
    },
};

/**
 * Contains the panel tab navigation bar.
 */
export const PanelTabContainer = styled(Box)`
    align-items: end;
    background-color: #fcfcfc;
    display: flex;
    /* height: 50px; */
    padding-left: 30px;
    padding-top: 15px;
    border-bottom: 1px solid #d6d6d6;
`;

/**
 * The panel tab.
 */
interface ITabProps {
    isActive: boolean;
}

export const PanelTab = styled(Box)<ITabProps>`
    align-items: center;
    border-top: 1px solid #d4d4d4;
    border-left: 1px solid #d4d4d4;
    border-right: 1px solid #d4d4d4;
    display: flex;
    justify-content: center;
    padding: 0 15px;
    background-color: ${(props) => (props.isActive ? "white" : "#fcfcfc")};
    cursor: ${(props) => (props.isActive ? "cursor" : "pointer")};
    height: ${(props) => (props.isActive ? "40px" : "30px")};
    margin-bottom: ${(props) => (props.isActive ? "-1px" : "0")};
`;

/**
 * Panel files.
 *
 * @param props Files props.
 */
export const PanelFiles = (props: IFilesProps) => (
    <Files
        {...props}
        styles={mergeStyleSets(
            {
                root: {},
                fileButton: {
                    button: {
                        height: "50px",
                        flexDirection: "row",
                        justifyContent: "left",
                        padding: "0 9px",
                        textTransform: "normal",
                        svg: {
                            marginBottom: "0",
                            marginRight: "10px",
                        },
                    },
                },
                fileContainer: {
                    gap: "5px 30px !important",
                    gridTemplateColumns: "1fr !important",
                    marginTop: "5px !important",
                    maxHeight: "none",
                },
            },
            props.styles,
        )}
    />
);

/**
 * Panel files.
 *
 * @param props Files props.
 */
export const PanelFilesReadOnlyView = (props: IFilesProps) => (
    <FilesReadOnlyView
        {...props}
        styles={mergeStyleSets(
            {
                root: {},
                fileButton: {
                    button: {
                        height: "50px",
                        flexDirection: "row",
                        justifyContent: "left",
                        padding: "0 9px",
                        textTransform: "normal",
                        svg: {
                            marginBottom: "0",
                            marginRight: "10px",
                        },
                    },
                },
                fileContainer: {
                    gap: "5px 30px !important",
                    gridTemplateColumns: "1fr !important",
                    marginTop: "5px !important",
                    maxHeight: "none",
                },
            },
            props.styles,
        )}
    />
);

/**
 * The task/assignment notes.
 */
interface IAssignmentHistoryNoteProps {
    isFlipped: boolean;
}

export const AssignmentHistoryNoteContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: 15px;
`;

export const AssignmentHistoryTitle = styled(Box)<IAssignmentHistoryNoteProps>`
    display: flex;
    flex: 1;
    flex-direction: ${(props) => (props.isFlipped ? "row-reverse" : "row")};
    align-items: center;
    justify-content: space-between;
    margin-bottom: 13px;
`;

export const AssignmentHistoryNote = styled(Box)<IAssignmentHistoryNoteProps>`
    border-radius: 5px;
    background-color: ${(props) => (props.isFlipped ? "#FDE9CC" : "#f3f3f3")};
    padding: 10px 15px;
    position: relative;
    &:after {
        bottom: 100%;
        left: ${(props) => (props.isFlipped ? "100%" : "0%")};
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border: 1px solid transparent;
        border-color: transparent;
        border-bottom-color: ${(props) => (props.isFlipped ? "#FDE9CC" : "#f3f3f3")};
        border-width: 7px;
        margin-left: ${(props) => (props.isFlipped ? "-20px" : "6px")};
    }
`;

export const AssignmentHistoryPersona = (props: PropsWithChildren<IPersonaBaseProps & IAssignmentHistoryNoteProps>) => (
    <ThemedPersona
        {...props}
        styles={mergeStyleSets(
            {
                root: {
                    flexDirection: props.isFlipped ? "row-reverse" : "reverse",
                },
                subComponentStyles: {},
                primaryText: { fontSize: "10px" },
            },
            props.styles,
        )}
    />
);

export const CalendarToggleButton = styled.button`
    padding: 0px;
    border: none;
    padding-right: 30px;
    padding-top: 10px;
    background-color: transparent;
    border-bottom: 1px solid #d6d6d6;
    height: 100%;
    cursor: pointer;

    :focus {
        background-color: transparent;
        border-bottom: 1px solid #d6d6d6;
    }

    :focus-visible {
        background-color: transparent;
        border-bottom: 1px solid #d6d6d6;
    }

    :hover {
        opacity: 0.8;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #d6d6d6;
    }
`;
