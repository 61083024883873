import { runInAction } from "mobx";

import { Endpoint, EndpointWithoutRequest, Http, startOfDay } from "Application";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { WeeklyUpdateViewModel } from "../WeeklyUpdate/WeeklyUpdateViewModel";

class Request {
    filterStartDate: Date | null = null;
    projectId: string | null = null;
}

class Response {
    public update: WeeklyUpdateResponse | null = null;
    public previousProjectUpdateUpdateDetail: string | null = null;
    public previousProjectUpdateInternalDetail: string | null = null;
    public keyInformation: string = "";
    public hasWeeklyUpdate: boolean = false;
    public hasActiveUpdate: boolean = false;
    public hasUpdate: boolean = false;
}

class WeeklyUpdateResponse {
    id: string | null = null;
    projectId: string = "";
    updateDetail: string = "";
    internalDetail: string = "";
    updateDate: string = "";
    sendUpdate: boolean = false;
    createdDate: string = "";
    createdByUserId: string = "";
    updatedDate: string = "";
    updatedByUserId: string = "";
}

export class GETWeeklyUpdateByIdEndpoint extends Endpoint<Request, Response> {
    private readonly viewModel: WeeklyUpdateViewModel;

    constructor(viewModel: WeeklyUpdateViewModel) {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Resources.GetStaffProjectDetailsWeeklyUpdateByProjectId);
        this.viewModel = viewModel;
    }

    public async HandleRequestAsync(): Promise<any> {
        const request = new Request();
        request.filterStartDate = this.viewModel.filterStartDate;
        request.projectId = this.viewModel.projectId;
        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            this.viewModel.model.fromResponse(response.update);
            this.viewModel.model.hasUpdate = response.hasUpdate;
            this.viewModel.model.hasActiveUpdate = response.hasActiveUpdate;
            this.viewModel.setResponseReceivedValue(true);
            this.viewModel.model.previousProjectUpdateUpdateDetail = response.previousProjectUpdateUpdateDetail;
            this.viewModel.model.previousProjectUpdateInternalDetail = response.previousProjectUpdateInternalDetail;
        });

        return Promise.resolve(response);
    }
}
