import { ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

import { ICommand, RelayCommand } from "Application";
import { ResetAllowancesModel } from "./ResetAllowancesModel";

export class ResetAllowancesViewModel extends ViewModelBase<ResetAllowancesModel> {
    // Callback commands to the parent viewmodel.
    public parentSubmitCommand: ICommand;
    public parentCancelCommand: ICommand;

    constructor(model: ResetAllowancesModel, submitCommand: ICommand, cancelCommand: ICommand) {
        super(model, false);

        // Commands to be called on the parent viewmodel.
        this.parentSubmitCommand = submitCommand;
        this.parentCancelCommand = cancelCommand;

        makeObservable(this, {});
    }

    //#region Commands

    public submitCommand = new RelayCommand(
        () => this.parentSubmitCommand.execute(),
        () => this.parentSubmitCommand.canExecute(),
    );

    public cancelCommand = new RelayCommand(
        () => this.parentCancelCommand.execute(),
        () => this.parentCancelCommand.canExecute(),
    );

    //#endregion Commands
}
