import { isEmptyOrWhitespace, ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

import { Validator } from "Application/Validation";
import { QUOTESTATUS_DRAFTTYPE } from "../Constants";

export class LetterModel extends ModelBase<LetterModel> {
    // This is not part of the form model, but used for validation.
    public saveQuoteStatusType: string | null = null;
    public isQuickQuote: boolean = false;

    public letterTemplateId: string | null = null;
    public letterDetails: string | null = null;

    constructor() {
        super();
        makeObservable(this, {
            isQuickQuote: observable,

            // Observables
            letterTemplateId: observable,
            letterDetails: observable,
        });
    }
}

export class LetterModelValidator extends Validator<LetterModel> {
    constructor() {
        super();

        this.ruleFor("letterTemplateId").must({
            predicate: (letterTemplateId, model) => {
                return model.isQuickQuote ? true : model.saveQuoteStatusType === QUOTESTATUS_DRAFTTYPE ? true : !isEmptyOrWhitespace(letterTemplateId);
            },
            message: (value, model) => "Please select a value",
        });
    }
}
