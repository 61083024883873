import { observer } from "mobx-react-lite";
import React from "react";

import { Box, ThemedText } from "Application";
import { WeeklyUpdateHistoryViewModel } from "./WeeklyUpdateHistoryViewModel";
import { FormContainerRow, FormSeparator } from "Views/Shared/SharedComponents";
import styled from "@emotion/styled";

const WeeklyUpdateContainer = styled.div`
    overflow-y: auto;
    height: calc(100vh - 350px);
    width: 100%;
    content: "";
`;

const Container = styled.div`
    transition: opacity 0.12s ease-in;
    height: 100%;
    opacity: 0%;

    &.render {
        opacity: 100%;
    }
`;

export interface IRevisionViewProps {
    viewModel: WeeklyUpdateHistoryViewModel | null;
}

export const WeeklyUpdateHistoryView: React.FC<IRevisionViewProps> = observer((props) => {
    const viewModel = props.viewModel;
    const weeklyUpdateHistoryContainerClassName = viewModel ? "render" : "";

    const renderWeekLyUpdateHistory = () => {
        if (viewModel) {
            return (
                <Box display={"flex"} flexDirection={"column"} height={"100%"}>
                    <Box>
                        <ThemedText fontStyle="h6" p="5px 0">
                            Weekly update
                        </ThemedText>
                        <WeeklyUpdateContainer>
                            <ThemedText>{viewModel.getValue("updateDetail")}</ThemedText>
                        </WeeklyUpdateContainer>
                    </Box>
                    <FormSeparator />
                    <FormContainerRow dc={"100%"} flex={1}>
                        <ThemedText fontStyle="h6">{viewModel.updateDetails}</ThemedText>
                    </FormContainerRow>
                </Box>
            );
        }
    };

    return <Container className={weeklyUpdateHistoryContainerClassName}>{renderWeekLyUpdateHistory()}</Container>;
});
