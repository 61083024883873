import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Box } from "Application";
import { Link, Routes } from "react-router-dom";
import { Route } from "react-router";
import { TestView } from "./Test/TestView";

export const PreviewView: React.FC = observer(() => {
    return (
        <Box p={3}>
            <Box display={"flex"} style={{ columnGap: "15px" }}>
                <Link to={"/preview/test"}>Test</Link>
            </Box>
            <Box>
                <Routes>
                    <Route path="test" element={<TestView />} />)
                </Routes>
            </Box>
        </Box>
    );
});
