import React from "react";
import { observer } from "mobx-react-lite";
import styled from "@emotion/styled";
import ClassNames from "classnames";
import { ThemedText, theme } from "Application";

const Container = styled.div`
    display: block; /* Initially hidden */
    position: fixed; /* Position for overlay */
    top: 130px; /* Adjust positioning as needed */
    right: 10px;
    transform: translateX(-50%);
    background-color: ${theme.palette.secondary.light};
    color: white;
    padding: 10px 15px;
    border-radius: 5px;
    opacity: 0; /* Start transparent */
    transition: opacity 0.5s ease-in-out; /* Smooth fade */
    z-index: 1000; /* Ensure it stays on top */

    &.show {
        display: block; /* Show the toast */
        opacity: 1; /* Make fully opaque */
    }
`;

interface IToastNotificationProps {
    show: boolean;
    text: string;
}

export const ToastNotification: React.FC<IToastNotificationProps> = observer((props: IToastNotificationProps) => {
    return (
        <Container className={ClassNames({ show: props.show })}>
            <ThemedText color="white" fontStyle="label">
                {props.text}
            </ThemedText>
        </Container>
    );
});
