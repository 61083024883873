import { ModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

export class InProgressItemModel extends ModelBase<InProgressItemModel> {
    public id: string = "";
    public projectTaskAssignmentStatusId: string = "";
    public projectTaskName: string = "";
    public projectTaskIsBilledHourly: boolean = false;
    public projectTaskAssignmentStatusName: string = "";

    public projectTaskAssignmentStatusForegroundColor: string = "";
    public plannedStartDate: Date | null = null;
    public plannedEndDate: Date | null = null;
    public isBilled: boolean = false;
    public hasNote: boolean = false;
    public effort = 0;
    public billableEffort: number | null = null;
    public isNonBillableEffort = false;
    public isReviewed: boolean = false;
    public billedEffort = 0;

    constructor() {
        super();

        makeObservable(this, {});
    }
}
