import { IStyle, mergeStyleSets } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React from "react";

import { theme, ThemedText, ThemedTransparentIconButton } from "Application";
import { ServiceTaskViewModel } from "./ServiceTaskViewModel";
import { CrossSVG } from "Assets/CrossSVG";

interface ICustomServiceTaskViewStyles {
    root: IStyle;
    iconButton: IStyle;
}

interface ICustomServiceTaskViewProps {
    viewModel: ServiceTaskViewModel;
}

const styleDefinitions: ICustomServiceTaskViewStyles = {
    root: {
        alignItems: "center",
        backgroundColor: "#FCFCFC",
        borderLeft: "5px solid #ff4b22",
        display: "flex",
        paddingLeft: "10px",
        marginBottom: "10px",
        justifyContent: "space-between",
    },
    iconButton: {
        g: {
            stroke: theme.palette.defaultTr.text,
        },
    },
};

export const CustomServiceTaskView: React.FC<ICustomServiceTaskViewProps> = observer((props) => {
    const viewModel = props.viewModel;
    const styles = mergeStyleSets(styleDefinitions);

    if (viewModel) {
        return (
            <div className={styles.root}>
                <ThemedText fontStyle="h5">{viewModel.displayName}</ThemedText>
                <ThemedTransparentIconButton icon={<CrossSVG className={styles.iconButton} />} command={viewModel.toggleEnableServiceTask} />
            </div>
        );
    }

    return <React.Fragment />;
});
