import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { ProgrammingViewModel } from "../ProgrammingViewModel";

class Request {
    id: string | null = null;
}

class Response {}

export class POSTSaveProjectAsTakenOffHoldEndpoint extends Endpoint<Request, Response> {
    private readonly viewModel: ProgrammingViewModel;

    constructor(viewModel: ProgrammingViewModel) {
        super();

        this.verb(Http.Post);
        this.path(AppUrls.Server.Resources.SaveProjectAsTakenOffHold);
        this.viewModel = viewModel;
    }

    public async HandleRequestAsync(model: any): Promise<any> {
        const request = new Request();

        request.id = this.viewModel.projectId;

        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        return Promise.resolve(response);
    }
}
