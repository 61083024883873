import { SVGProps } from "react";

export const PhotoIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="67.024" viewBox="0 0 80 67.024" {...props}>
        <g id="uploadCamera" transform="translate(-8515.5 1878)">
            <path
                id="Path_8589"
                data-name="Path 8589"
                d="M153.011,105.358a15,15,0,1,1-15,15A15.042,15.042,0,0,1,153.011,105.358Z"
                transform="translate(8402.488 -1960.108)"
                fill="none"
                stroke="#fff"
                strokeWidth="4"
            />
            <path
                id="Path_8588"
                data-name="Path 8588"
                d="M147.28,89.6l-4.634,9.268H126.89a6.473,6.473,0,0,0-6.488,6.488v40.781a6.473,6.473,0,0,0,6.488,6.488h63.025a6.473,6.473,0,0,0,6.488-6.488V105.358a6.473,6.473,0,0,0-6.488-6.488H174.158L169.524,89.6Z"
                transform="translate(8397.098 -1965.602)"
                fill="none"
                stroke="#fff"
                strokeWidth="4"
            />
        </g>
    </svg>
);
