import { isEmptyOrWhitespace, isNullOrUndefined, ViewModelBase } from "@shoothill/core";
import { computed, makeObservable, observable, observe } from "mobx";

import { ICommand, RelayCommand } from "Application/Commands";
import { ConfigureServicesModel } from "./ConfigureServicesModel";
import { ConfigureServicesViewModel } from "./ConfigureServicesViewModel";
import { ServiceTaskModel } from "./ConfigureServiceSubViews/ServiceTaskModel";
import { ServicesModel } from "./ServicesModel";
import { TaskGroupModel } from "./ServiceSubViews/TaskGroupModel";
import { TaskGroupViewModel } from "./ServiceSubViews/TaskGroupViewModel";
import { TaskModel } from "./ServiceSubViews/TaskModel";

export class ServicesViewModel extends ViewModelBase<ServicesModel> {
    public taskGroupViewModels = observable<TaskGroupViewModel>([]);
    public configureServicesViewModel: ConfigureServicesViewModel | null = null;

    constructor(servicesModel: ServicesModel = new ServicesModel()) {
        super(servicesModel);

        makeObservable(this, {
            // Observables
            taskGroupViewModels: observable,
            configureServicesViewModel: observable,

            // Computeds
            availableServiceGroups: computed,
        });
    }

    /**
     * Disposes of the task group collection observer.
     */
    public dispose = (): void => {
        this.taskGroupsObserverDispose?.();
    };

    // #region Properties

    public get percentageVAT() {
        return this.model.percentageVAT;
    }

    public get canDisplayConfigureServices(): boolean {
        return this.configureServicesViewModel !== null;
    }

    public get availableServiceGroups() {
        const taskGroupIds = this.model.taskGroups.map((tg) => tg.id);

        return this.model.serviceGroups
            .filter((sg) => {
                return !taskGroupIds.includes(sg.id);
            })
            .map((sg) => {
                return {
                    key: sg.id,
                    text: sg.name,
                };
            });
    }

    // #endregion Properties

    // #region Commands

    /**
     * Command to display the configure task group view.
     */
    public displayConfigureTaskGroupCommand: ICommand = new RelayCommand((serviceGroupId: string | null) => {
        // GUARD - If no group id is provided, don't continue processing the command.
        if (isEmptyOrWhitespace(serviceGroupId)) {
            return;
        }

        // Find the service group and its child services.
        const serviceGroup = this.model.serviceGroups.find((sg) => sg.id === serviceGroupId);
        const services = this.model.services.filter((s) => s.serviceGroupId === serviceGroupId);

        // Find the task group and its child task service ids.
        const taskGroupServiceIds = this.model.taskGroups.find((tg) => tg.id === serviceGroupId)?.tasks?.map((t) => t.serviceId) ?? [];

        // Find the custom tasks.
        const customTasks = this.model.taskGroups.find((tg) => tg.id === serviceGroupId)?.tasks?.filter((t) => isEmptyOrWhitespace(t.serviceId)) ?? [];

        // Create the configure services model via the viewmodel.
        const configureServicesViewModel = new ConfigureServicesViewModel(
            new ConfigureServicesModel(),
            this.cancelDisplayConfigureTaskGroupCommand,
            this.updateFromConfigureTaskGroupCommand,
        );

        // The basics concerning the task/service group.
        configureServicesViewModel.model.taskGroupName = serviceGroup ? serviceGroup.name : "";
        configureServicesViewModel.model.serviceGroupId = serviceGroup ? serviceGroup.id : "";

        // Add all services, but state if they are enabled by being used by a task.
        configureServicesViewModel.model.serviceTasks.replace(
            services.map((s) => {
                const serviceTask = new ServiceTaskModel();

                serviceTask.serviceId = s.id;
                serviceTask.taskName = s.name;
                serviceTask.enabled = taskGroupServiceIds?.includes(s.id);
                serviceTask.ordinal = s.ordinal;
                serviceTask.isLinkedToPlanningApplication = s.isLinkedToPlanningApplication;

                return serviceTask;
            }),
        );

        // Add custom tasks. Unlike service-based tasks, they are enabled by existing.
        // Note we use the model key to identify the task as an actual identifier will
        // not exist if it has not been saved to the database.
        configureServicesViewModel.model.customServiceTasks.replace(
            customTasks.map((t) => {
                const serviceTask = new ServiceTaskModel();

                serviceTask.KEY = t.KEY;
                serviceTask.taskName = t.taskName;
                serviceTask.enabled = true;

                return serviceTask;
            }),
        );

        // Assign the viewmodel to display the view.
        this.configureServicesViewModel = configureServicesViewModel;
    });

    /**
     * Command to update a task group based on task group configuration. Also cancels
     * display of the configure task group view.
     */
    public updateFromConfigureTaskGroupCommand: ICommand = new RelayCommand(() => {
        /**
         * Local function to merge the any configured tasks into the tasks of the taskgroup.
         */
        const mergeServiceTasks = (taskGroup: TaskGroupModel) => {
            let startingBaseOrdinal = 10000000;

            // Merge service-based tasks.
            for (const serviceTask of this.configureServicesViewModel!.model.serviceTasks) {
                const task = taskGroup.tasks.find((t) => t.serviceId === serviceTask.serviceId);

                switch (true) {
                    // Adds a task if it is required and not found against the task group.
                    case isNullOrUndefined(task) && serviceTask.enabled: {
                        const newTask = new TaskModel();

                        newTask.serviceId = serviceTask.serviceId;
                        newTask.taskName = serviceTask.taskName;
                        newTask.ordinal = startingBaseOrdinal++;

                        if (this.canApplyDefaultHourlyRate) {
                            newTask.hourlyRate = this.model.defaultHourlyRate;
                        }

                        taskGroup.tasks.push(newTask);
                        break;
                    }

                    // Removes a task if it is not required and found against the task group.
                    case !isNullOrUndefined(task) && !serviceTask.enabled:
                        taskGroup.tasks.remove(task!);
                        break;
                }
            }

            // Merge custom tasks.
            for (const customServiceTask of this.configureServicesViewModel!.model.customServiceTasks) {
                const task = taskGroup.tasks.find((t) => t.KEY === customServiceTask.KEY);

                switch (true) {
                    // Adds a task if it is required and not found against the task group.
                    case isNullOrUndefined(task) && customServiceTask.enabled: {
                        const newTask = new TaskModel();

                        newTask.serviceId = customServiceTask.serviceId;
                        newTask.taskName = customServiceTask.taskName;
                        newTask.ordinal = startingBaseOrdinal++;

                        if (this.canApplyDefaultHourlyRate) {
                            newTask.hourlyRate = this.model.defaultHourlyRate;
                        }

                        taskGroup.tasks.push(newTask);
                        break;
                    }

                    // Removes a task if it is not required and found against the task group.
                    case !isNullOrUndefined(task) && !customServiceTask.enabled:
                        taskGroup.tasks.remove(task!);
                        break;
                }
            }

            // Create a clean set of ordinals for the tasks in the group.
            taskGroup.patchOrdinals();
        };

        // We need to merge task groups and tasks.
        const taskGroup = this.model.taskGroups.find((tg) => tg.id === this.configureServicesViewModel!.model.serviceGroupId);

        switch (true) {
            // If a taskgroup does not exist, but has either service-based or custom tasks, create the taskgroup and then
            // merge the tasks into the taskgroup.
            case isNullOrUndefined(taskGroup) &&
                (this.configureServicesViewModel!.model.serviceTasks.some((st) => st.enabled) ||
                    this.configureServicesViewModel!.model.customServiceTasks.some((st) => st.enabled)): {
                const newTaskGroup = new TaskGroupModel();

                newTaskGroup.id = this.configureServicesViewModel!.model.serviceGroupId;
                newTaskGroup.taskGroupName = this.configureServicesViewModel!.model.taskGroupName;

                this.model.taskGroups.push(newTaskGroup);

                mergeServiceTasks(newTaskGroup);
                break;
            }

            // If a taskgroup exists, but has no service-based or custom tasks, remove the taskgroup.
            case !isNullOrUndefined(taskGroup) &&
                !(
                    this.configureServicesViewModel!.model.serviceTasks.some((st) => st.enabled) ||
                    this.configureServicesViewModel!.model.customServiceTasks.some((st) => st.enabled)
                ): {
                this.model.taskGroups.remove(taskGroup!);
                break;
            }

            // If a taskgroup exists and (implicitly) has either service-based or custom tasks, merge them into the taskgroup.
            case !isNullOrUndefined(taskGroup):
                mergeServiceTasks(taskGroup!);
                break;
        }

        // Close the view.
        // If destroying the viewmodel, check if it has a dispose function and if so, call it.
        this.configureServicesViewModel?.dispose();
        this.configureServicesViewModel = null;
    });

    /**
     * Command to cancel display of the configire task group view
     */
    public cancelDisplayConfigureTaskGroupCommand: ICommand = new RelayCommand(() => {
        // Close the view.
        // If destroying the viewmodel, check if it has a dispose function and if so, call it.
        this.configureServicesViewModel?.dispose();
        this.configureServicesViewModel = null;
    });

    /**
     * Command to remove a task group model from the task model collection.
     */
    public removeTaskGroupModelCommand: ICommand = new RelayCommand((taskGroup: TaskGroupModel) => {
        const taskGroupToRemove = this.model.taskGroups.find((m) => m.KEY === taskGroup.KEY);

        if (taskGroupToRemove) {
            this.model.taskGroups.remove(taskGroupToRemove);
        }
    });

    public updateDefaultHourlyRateCommand: ICommand = new RelayCommand((value: string) => {
        const parsedValue = parseFloat(value);
        const checkedValue = Number.isNaN(parsedValue) ? null : parsedValue;

        this.setValue("defaultHourlyRate", checkedValue);
    });

    public updateApplyDefaultHourlyRateCommand: ICommand = new RelayCommand(() => {
        this.setValue("applyDefaultHourlyRate", !this.model.applyDefaultHourlyRate);
    });

    // #endregion Commands

    // #region Supporting

    /**
     * An observer to listen to changes in the task group model collection. Use this to create or remove
     * task group viewmodels in response to changes in the task group model collection.
     */
    private taskGroupsObserverDispose = observe(this.model.taskGroups, (taskGroupChanges: any) => {
        for (const addedTaskGroup of taskGroupChanges.added) {
            this.taskGroupViewModels.push(new TaskGroupViewModel(addedTaskGroup, this.displayConfigureTaskGroupCommand, this.removeTaskGroupModelCommand));
        }

        for (const removedTaskGroup of taskGroupChanges.removed) {
            const taskGroupViewModelToRemove = this.taskGroupViewModels.find((vm) => vm.model.KEY === removedTaskGroup.KEY);

            if (taskGroupViewModelToRemove) {
                // If destroying the viewmodel, check if it has a dispose function and if so, call it.
                taskGroupViewModelToRemove.dispose();
                this.taskGroupViewModels.remove(taskGroupViewModelToRemove);
            }
        }
    });

    /**
     * Determines if the default hourly rate can be applied to new tasks.
     */
    private get canApplyDefaultHourlyRate(): boolean {
        return this.model.applyDefaultHourlyRate && this.model.defaultHourlyRate !== null;
    }

    public get canSubmitForm(): boolean {
        let isFormValid = true;
        for (const taskGroupViewModel of this.taskGroupViewModels) {
            if (!taskGroupViewModel.canSubmitForm) {
                isFormValid = false;
            }
        }
        return isFormValid;
    }

    // #endregion Supporting
}
