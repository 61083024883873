import { IPersonaCoinStyles, IPersonaStyles, Persona, PersonaSize } from "@fluentui/react";
import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import clsx from "clsx";
import { observer } from "mobx-react-lite";

/**
 * Persona interface.
 */
export interface IPersonaBaseProps {
    /**
     * An optional class name for use with the button.
     */
    className?: string;
    /**
     * Primary text content to display.
     */
    displayName?: string;
    /**
     * Secondary text content to display.
     */
    displaySecondaryName?: string;
    /**
     * Url to an image to display.
     */
    imageUrl?: string;
    /**
     * The size of the persona.
     */
    size?: PersonaSize;
    /**
     * Styling of the persona text.
     */
    styles?: Partial<IPersonaStyles>;
    /**
     * Styling of the persona coin.
     */
    coinStyles?: Partial<IPersonaCoinStyles>;
}

export const PersonaBase: React.FC<IPersonaBaseProps> = observer((props) => {
    // #region Code Behind

    const getClasseNames = () => {
        return clsx({
            [props.className!]: !isEmptyOrWhitespace(props.className),
        });
    };

    // #endregion Code Behind

    return (
        <Persona
            coinProps={{ styles: props.coinStyles }}
            className={getClasseNames()}
            imageUrl={props.imageUrl}
            size={props.size}
            styles={props.styles}
            text={props.displayName}
            secondaryText={props.displaySecondaryName}
        />
    );
});
