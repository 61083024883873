import { observer } from "mobx-react-lite";
import React from "react";
import { ILabelStyles, TooltipHost, Label, ITooltipHostStyles, ICalloutContentStyles, Stack } from "@fluentui/react";
import { ExclamationIconSVG } from "Assets/ExclamationIconSVG";
import { nanoid } from "nanoid";
import { ThemedText } from ".";
import { theme } from "../../AppTheme";
import { getThemeColorOption, pxToRem } from "../../../Application/Helpers";
import { themeColourOptions } from "Styles/IShoothillTheme";
import styled from "@emotion/styled";

export interface ValidationLabelProps {
    displayName?: string;
    //determines if the error message will be shown or hidden
    showErrorMessage?: boolean;
    //determines if the error message shows by default on top of the input or is enclosed inside the input
    encloseErrorLabel?: boolean;
    validationMessage?: () => string;
    labelColor?: themeColourOptions;
}

const EnclosedLabelContainer = styled.div`
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(calc(-50% + 2.5px));
    z-index: 10;
`;

export const ValidationLabelError: React.FC<ValidationLabelProps> = observer((props) => {
    const labelColor = getThemeColorOption(props.labelColor);
    const showErrorMessage = props.showErrorMessage || props.showErrorMessage == undefined ? true : false;

    const labelStyle: Partial<ILabelStyles> = {
        root: {
            color: labelColor,
            fontFamily: theme.fontStyles.label.fontFamily ?? theme.defaultFontStyle.fontFamily,
            fontSize: theme.fontStyles.label.fontSize ?? theme.defaultFontStyle.fontSize,
            fontWeight: theme.fontStyles.label.fontWeight ?? theme.defaultFontStyle.fontWeight,
            letterSpacing: theme.fontStyles.label.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
            lineHeight: theme.fontStyles.label.lineHeight ?? theme.defaultFontStyle.lineHeight,
            textTransform: theme.fontStyles.label.textTransform ?? theme.defaultFontStyle.textTransform,
        },
    };

    const toolTipHostStyle: Partial<ITooltipHostStyles> = {
        root: {
            "&&": {
                margin: `auto auto auto ${pxToRem(10)}`,
                paddingTop: pxToRem(4),
            },
        },
    };

    const calloutStyle: Partial<ICalloutContentStyles> = {
        beak: { background: theme.palette.common.error },
        beakCurtain: { background: theme.palette.common.error },
        calloutMain: { background: theme.palette.common.error },
    };

    const renderInnerLabel = () => {
        return (
            <>
                {showErrorMessage && <Label styles={labelStyle}>{props.displayName ? props.displayName : "Error"}</Label>}
                <TooltipHost
                    id={nanoid()}
                    content={
                        <ThemedText fontStyle={"h7"} color={"white"}>
                            {props.validationMessage?.() as string}
                        </ThemedText>
                    }
                    tooltipProps={{
                        calloutProps: {
                            gapSpace: -5,
                            styles: calloutStyle,
                        },
                    }}
                    styles={toolTipHostStyle}
                >
                    <ExclamationIconSVG backgroundcolor={theme.palette.common.error} color={theme.palette.common.white} />
                </TooltipHost>
            </>
        );
    };

    const renderLabel = () => {
        console.log(props);
        if (props.encloseErrorLabel) {
            return <EnclosedLabelContainer>{renderInnerLabel()}</EnclosedLabelContainer>;
        } else {
            return <Stack horizontal>{renderInnerLabel()}</Stack>;
        }
    };

    return <>{renderLabel()}</>;
});
