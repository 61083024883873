import { KeyValuePair, isEmptyOrWhitespace } from "@shoothill/core";
import { PlanningApplicationTableModel } from "./PlanningApplicationTableModel";
import { APIClient, RelayCommand } from "Application";
import { container } from "tsyringe";
import { LookupStore, SettingsStore } from "Stores/Domain";
import { action, computed, makeObservable, observable } from "mobx";

import moment from "moment";
import {
    CURR_CALENDAR_MONTH,
    CURR_CALENDAR_QUARTER,
    CURR_CALENDAR_WEEK,
    CURR_CALENDAR_YEAR,
    CURR_FINANCIAL_YEAR,
    PREV_CALENDAR_MONTH,
    PREV_CALENDAR_QUARTER,
    PREV_CALENDAR_WEEK,
    PREV_CALENDAR_YEAR,
    PREV_FINANCIAL_YEAR,
} from "Views/Shared/Constants";
import { PlanningApplicationTableItemViewModel } from "./TableItem/PlanningApplicationTableItemViewModel";
import { GETPlanningApplicationTableItemsByStatusTypeEndpoint } from "./Endpoints/GETPlanningApplicationTableItemsByStatusTypeEndpoint";
import { GetFilteredPlanningApplicationsCSVEndpoint } from "./Endpoints/GETFilteredPlanningApplicationsCSVEndpoint";
import { AppUrls } from "AppUrls";
import { ViewModelBaseExtendedViewModel } from "Application/ViewModels/ViewModelBaseUI";
import { IDeletePlanningApplicationRequest, POST_DeletePlanningApplicationEndpoint } from "./Endpoints/POST_DeletePlanningApplicationEndpoint";

export class PlanningApplicationTableViewModel extends ViewModelBaseExtendedViewModel<PlanningApplicationTableModel> {
    private lookupStore = container.resolve(LookupStore);
    public apiClient = new APIClient();
    public items = observable<PlanningApplicationTableItemViewModel>([]);
    public planningApplicationIdToEdit: string | null = null;
    public planningApplicationToDelete: PlanningApplicationTableItemViewModel | null = null;

    constructor() {
        super(new PlanningApplicationTableModel());

        makeObservable(this, {
            // Observables
            planningApplicationIdToEdit: observable,
            planningApplicationToDelete: observable,
            items: observable,
            // Actions
            setPlanningApplicationIdToEdit: action,
            setPlanningApplicationToDelete: action,
            // Computed
            filteredAndSortedItems: computed,
            showEditModal: computed,
            showDeleteModal: computed,
        });
    }

    //region endpoints
    public deletePlanningApplication = async () => {
        if (this.apiClient.IsBusy) return;
        const request: IDeletePlanningApplicationRequest = {
            id: this.planningApplicationToDelete!.model.id,
        };
        const endpoint = new POST_DeletePlanningApplicationEndpoint();
        await this.apiClient.sendAsync(endpoint, request);
        if (this.apiClient.IsRequestSuccessful) {
            //remove the item from the items array
            //returning a new set of data just show that the deletion worked is redundant
            const newItems = this.items.slice().filter((i) => i.model.id != this.planningApplicationToDelete!.model.id);
            this.items.replace(newItems);
        } else {
            // the error wrapped will pick up the errors returned by the ApiClient
        }
        this.dismissDeleteModal();
    };

    //region modal

    /*start of edit*/
    public setPlanningApplicationIdToEdit = (id: string | null) => {
        this.planningApplicationIdToEdit = id;
    };

    public dismissEditModal = () => {
        this.setPlanningApplicationIdToEdit(null);
    };

    public get showEditModal() {
        return this.planningApplicationIdToEdit !== null;
    }
    public dismissEditPlanningApplicationCommand = new RelayCommand(() => {
        this.dismissEditModal();
    });

    public confirmEditPlanningApplicationCommand = new RelayCommand(() => {
        this.loadItems();
    });

    public onEditItem = (item: PlanningApplicationTableItemViewModel) => {
        this.setPlanningApplicationIdToEdit(item.model.id);
    };

    /*end of edit*/

    /*start of delete*/

    public setPlanningApplicationToDelete = (item: PlanningApplicationTableItemViewModel | null) => {
        this.planningApplicationToDelete = item;
    };

    public dismissDeleteModal = () => {
        this.setPlanningApplicationToDelete(null);
    };

    public get showDeleteModal() {
        return this.planningApplicationToDelete !== null;
    }

    public dismissDeletePlanningApplicationCommand = new RelayCommand(() => {
        this.dismissDeleteModal();
    });

    public confirmDeletePlanningApplicationCommand = new RelayCommand(() => {
        this.deletePlanningApplication();
    });

    public onInitiateDeleteItem = (item: PlanningApplicationTableItemViewModel) => {
        this.setPlanningApplicationToDelete(item);
    };

    /*end of delete*/

    //region getters

    public get URLPathname() {
        return this.history.listen;
    }

    public get financialPeriodTypes() {
        const options = this.lookupStore.getFinancialPeriodTypes.map((item) => {
            return {
                key: item.key,
                text: item.text,
            };
        });

        return [...options, { key: "", text: "Custom" }];
    }

    public get projectLeads() {
        const options = this.items.map((p) => {
            return {
                key: p.model.projectLeadId,
                text: `${p.model.projectLeadFirstName} ${p.model.projectLeadLastName}`,
            };
        });
        return this.formatKeyValuePairs(options, { key: "", text: "All project leads" });
    }

    public get localAuthorityDistrictOptions() {
        const options = this.items
            .filter((p) => p.model.localAuthorityDistrictId && p.model.localAuthorityDistrictName)
            .map((p) => {
                return {
                    key: p.model.localAuthorityDistrictId!,
                    text: p.model.localAuthorityDistrictName!,
                };
            });

        return this.formatKeyValuePairs(options, { key: "", text: "All local authorities" });
    }

    public get planningApplicationTypeOptions() {
        const options = this.items
            .filter((p) => p.model.planningApplicationTypeId && p.model.planningApplicationTypeName)
            .map((p) => {
                return {
                    key: p.model.planningApplicationTypeId!,
                    text: p.model.planningApplicationTypeName!,
                };
            });

        return this.formatKeyValuePairs(options, { key: "", text: "All planning application types" });
    }

    //region Commands

    public navigateToCustomerDetailCommand = new RelayCommand((id: string) => {
        this.history.push(AppUrls.Client.Customers.Details.replace(":id", id));
    });

    public navigateToProjectDetailCommand = new RelayCommand((id: string) => {
        this.history.push(AppUrls.Client.Projects.Details.replace(":id", id));
    });

    public updateFilterKeywordCommand = new RelayCommand((keyword: string) => {
        this.setValue("filterKeyword", keyword);
    });
    public updateFilterDateFromCommand = new RelayCommand((date: Date | undefined) => {
        this.setValue("filterDateFrom", date);
    });

    public updateFilterDateToCommand = new RelayCommand((date: Date | undefined) => {
        this.setValue("filterDateTo", date);
    });

    public updateFilterProjectLeadCommand = new RelayCommand((id: string | null) => {
        this.setValue("filterProjectLeadId", id);
    });

    public updateFilterLocalAuthorityDistrictCommand = new RelayCommand((id: string | null) => {
        this.setValue("filterLocalAuthorityDistrictId", id);
    });

    public updateFilterPlanningApplicationTypeCommand = new RelayCommand((id: string | null) => {
        this.setValue("filterPlanningApplicationTypeId", id);
    });

    public updateFinancialPeriodTypeCommand = new RelayCommand((value: string | null) => {
        //update filter id
        this.model.setValue("financialPeriodTypeId", value);

        //update date filters
        const financialPeriodType = this.lookupStore.financialPeriodTypes.find((type) => type.id == this.model.financialPeriodTypeId)?.type;

        switch (financialPeriodType) {
            case CURR_CALENDAR_WEEK:
                const startOfWeek = moment().startOf("week").add(1, "days");
                this.setValue("filterDateFrom", startOfWeek.toDate());
                const endOfWeek = moment().endOf("week").add(-1, "days");
                this.setValue("filterDateTo", endOfWeek);
                break;

            case CURR_CALENDAR_MONTH:
                const startOfMonth = moment().startOf("month");
                this.setValue("filterDateFrom", startOfMonth.toDate());
                this.setValue("filterDateTo", new Date());
                break;

            case CURR_CALENDAR_QUARTER:
                const startOfQuarter = moment().startOf("quarter");
                this.setValue("filterDateFrom", startOfQuarter.toDate());
                this.setValue("filterDateTo", new Date());
                break;

            case CURR_CALENDAR_YEAR:
                const startOfYear = moment().startOf("year");
                this.setValue("filterDateFrom", startOfYear.toDate());
                this.setValue("filterDateTo", new Date());
                break;

            case PREV_CALENDAR_WEEK:
                const startOfPrevWeek = moment().subtract(1, "weeks").startOf("week").add(1, "days");
                const endOfPrevWeek = moment().subtract(1, "weeks").endOf("week").subtract(1, "days");
                this.setValue("filterDateFrom", startOfPrevWeek.toDate());
                this.setValue("filterDateTo", endOfPrevWeek.toDate());
                break;

            case PREV_CALENDAR_MONTH:
                const startOfPrevMonth = moment().subtract(1, "months").startOf("month");
                const endOfPrevMonth = moment().subtract(1, "months").endOf("month");
                this.setValue("filterDateFrom", startOfPrevMonth.toDate());
                this.setValue("filterDateTo", endOfPrevMonth.toDate());
                break;

            case PREV_CALENDAR_QUARTER:
                const startOfPrevQuarter = moment().subtract(1, "quarters").startOf("quarter");
                const endOfPrevQuarter = moment().subtract(1, "quarters").endOf("quarter");
                this.setValue("filterDateFrom", startOfPrevQuarter.toDate());
                this.setValue("filterDateTo", endOfPrevQuarter.toDate());
                break;

            case PREV_CALENDAR_YEAR:
                const startOfPrevYear = moment().subtract(1, "years").startOf("year");
                const endOfPrevYear = moment().subtract(1, "years").endOf("year");
                this.setValue("filterDateFrom", startOfPrevYear.toDate());
                this.setValue("filterDateTo", endOfPrevYear.toDate());
                break;

            // start of each financial year is the 1st of April
            // end of each financial year is the 31st of March
            case CURR_FINANCIAL_YEAR:
                let startOfFinancialYear = moment().month(3).startOf("month");

                //compare current date to start of financial year.
                // current date is in current financial year
                if (startOfFinancialYear.toDate().getTime() < new Date().getTime()) {
                    this.setValue("filterDateFrom", startOfFinancialYear.toDate());
                    this.setValue("filterDateTo", new Date());
                } else {
                    //current date is previous financial year
                    startOfFinancialYear = moment().subtract(1, "years").month(3).startOf("month");

                    this.setValue("filterDateFrom", startOfFinancialYear.toDate());
                    this.setValue("filterDateTo", new Date());
                }

                break;

            case PREV_FINANCIAL_YEAR:
                let startOfPrevFinancialYear = moment().subtract(1, "years").month(3).startOf("month");
                //get the calendar date from last year
                const calendarDate = moment().subtract(1, "years");
                //compare current date to start of financial year.
                // current date is in current financial year
                if (startOfPrevFinancialYear.toDate().getTime() < calendarDate.toDate().getTime()) {
                    this.setValue("filterDateFrom", startOfPrevFinancialYear.toDate());
                    this.setValue("filterDateTo", calendarDate.toDate());
                } else {
                    //current date is previous financial year
                    startOfPrevFinancialYear = startOfPrevFinancialYear.subtract(1, "years").month(3).startOf("month");
                    const endOfPrevFinancialYear = calendarDate.month(2).endOf("month");
                    this.setValue("filterDateFrom", startOfPrevFinancialYear.toDate());
                    this.setValue("filterDateTo", endOfPrevFinancialYear.toDate());
                }

                break;
            default:
                return;
        }
    });

    public downloadFilteredPlanningApplicationCSVCommand = new RelayCommand(() => {
        this.startedProcessingData();
        this.apiClient.sendAsync(new GetFilteredPlanningApplicationsCSVEndpoint(this));
        this.finishedProcessingData();
    });

    public get settings() {
        return container.resolve(SettingsStore).ProjectTable;
    }

    public updateSortCommand = new RelayCommand((key: string, sortDescending: boolean) => {
        this.settings.sortKey = key;
        this.settings.sortDescending = sortDescending;
    });

    public get canSortItems(): boolean {
        return !isEmptyOrWhitespace(this.settings.sortKey);
    }

    public get filteredAndSortedItems(): PlanningApplicationTableItemViewModel[] {
        const retVal = this.canSortItems
            ? this.filteredItems
                  .slice()
                  .sort((lhs, rhs) =>
                      (this.settings.sortDescending ? lhs[this.settings.sortKey] < rhs[this.settings.sortKey] : lhs[this.settings.sortKey] > rhs[this.settings.sortKey]) ? 1 : -1,
                  )
            : this.filteredItems;
        return retVal;
    }

    public get filteredItems(): PlanningApplicationTableItemViewModel[] {
        const retVal = this.items.filter((vm) =>
            vm.filterPredicate(
                this.model.filterKeyword,
                this.model.filterLocalAuthorityDistrictId,
                this.model.filterPlanningApplicationTypeId,
                this.model.filterProjectLeadId,
                this.model.filterDateFrom,
                this.model.filterDateTo,
            ),
        );
        return retVal;
    }

    // region helpers
    private formatKeyValuePairs = (keyValuePairs: KeyValuePair[], defaultKeyValuePair?: KeyValuePair) => {
        const distinctOptions = keyValuePairs
            .slice()
            .sort((a, b) => (a.text > b.text ? 1 : b.text > a.text ? -1 : 0))
            .filter((option, index, arr) => arr.findIndex((t) => t.key === option.key) === index);
        const retVal = [...distinctOptions];

        if (defaultKeyValuePair) {
            retVal.unshift(defaultKeyValuePair);
        }
        return retVal;
    };

    public updateFilterPlanningApplicationStatus = (type: string) => {
        this.setValue("filterStatusType", type.toUpperCase());
    };

    public loadItems = () => {
        this.apiClient.sendAsync(new GETPlanningApplicationTableItemsByStatusTypeEndpoint(this));
    };
}
