import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { WeeklyUpdatesListViewModel } from "../WeeklyUpdatesListViewModel";

export class SendProjectWeeklyUpdates extends EndpointWithoutRequest<Response> {
    private viewModel: WeeklyUpdatesListViewModel;

    constructor(viewModel: WeeklyUpdatesListViewModel) {
        super();

        this.verb(Http.Post);
        this.path(AppUrls.Server.Admin.ProjectUpdates.SendProjectWeeklyUpdates);
        this.timeOut(60000);
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: Response): Promise<any> {}
}
