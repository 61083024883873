import { FieldType, KeyValuePair, ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

import { ICommand, RelayCommand } from "Application";
import { SubmitWithNoteModel, SubmitWithNoteModelValidator } from "./SubmitWithNoteModel";
import { FilesViewModel } from "Views/Shared/Files/FilesViewModel";

export class SubmitWithNoteViewModel extends ViewModelBase<SubmitWithNoteModel> {
    private title: string;
    private message: string;
    public canDisplayDocuments: boolean;
    public reasonItemOptions: KeyValuePair[] | null = null;

    public filesViewModel = new FilesViewModel();
    public parentCancelDisplaySubmitWithNotesCommand: ICommand;
    public parentUpdateFromSubmitWithNotesCommand: ICommand;

    constructor(
        configureServicesModel: SubmitWithNoteModel = new SubmitWithNoteModel(),
        title: string,
        message: string,
        cancelDisplaySubmitWithNotesCommand: ICommand,
        updateFromSubmitWithNotesCommand: ICommand,
        canDisplayDocuments: boolean = true,
        hasNoteSubTypeOptions: boolean = false,
        reasonItemOptions: KeyValuePair[] | null = null,
    ) {
        super(configureServicesModel);

        this.setValidator(new SubmitWithNoteModelValidator());

        this.title = title;
        this.message = message;
        this.canDisplayDocuments = canDisplayDocuments;
        this.reasonItemOptions = reasonItemOptions;
        this.model.setValue("hasNoteSubTypeOptions", hasNoteSubTypeOptions);
        this.parentCancelDisplaySubmitWithNotesCommand = cancelDisplaySubmitWithNotesCommand;
        this.parentUpdateFromSubmitWithNotesCommand = updateFromSubmitWithNotesCommand;

        makeObservable(this, {
            // Observables
        });
    }

    public dispose = (): void => {
        this.filesViewModel.dispose();
    };

    // #region Properties

    public get displayName() {
        return this.title;
    }

    public get displayMessage() {
        return this.message;
    }

    public get canDisplayReasonItemOptionDropdown() {
        return this.reasonItemOptions != null;
    }

    // #endregion Properties

    // #region Commands

    public updateNotesCommand = new RelayCommand((notes: string) => this.updateField("notes", notes));

    public addDocumentCommand = new RelayCommand((document: File) => {
        this.model.documents.push(document);
    });

    public updateReasonItemCommand = new RelayCommand((id: string | null) => {
        this.updateField("noteSubTypeId", id);
    });

    public updateFromSubmitWithNotesCommand = new RelayCommand(() => {
        const isFormValid = this.isModelValid();
        const isFilesValid = this.filesViewModel.isModelValid();

        if (isFormValid && isFilesValid) {
            this.parentUpdateFromSubmitWithNotesCommand.execute();
        }
    });

    // #endregion Commands

    // #region Supporting

    private updateField(fieldName: keyof FieldType<SubmitWithNoteModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    // #endregion Supporting
}
