import { ViewModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import moment from "moment";

import { BankHolidayEventViewModel } from "./Components/BankHolidayEvent/BankHolidayEventViewModel";
import { EffortEventViewModel } from "./Components/EffortEvent/EffortEventViewModel";
import { LeaveRequestEventViewModel } from "./Components/LeaveRequestEvent/LeaveRequestEventViewModel";
import { NoEventViewModel } from "./Components/NoEvent/NoEventViewModel";
import { ProjectTaskAssignmentEventViewModel } from "./Components/ProjectTaskAssignmentEvent/ProjectTaskAssignmentEventViewModel";
import { ProjectTaskAssignmentGroupEventViewModel } from "./Components/ProjectTaskAssignmentGroupEvent/ProjectTaskAssignmentGroupViewModel";
import { DateModel } from "./DateModel";
import { ICommand } from "Application";

export class DateViewModel extends ViewModelBase<DateModel> {
    public eventViewModel:
        | EffortEventViewModel
        | BankHolidayEventViewModel
        | LeaveRequestEventViewModel
        | NoEventViewModel
        | ProjectTaskAssignmentEventViewModel
        | ProjectTaskAssignmentGroupEventViewModel
        | null = null;

    constructor(model: DateModel) {
        super(model, false);

        this.eventViewModel = new NoEventViewModel();

        makeObservable(this, {
            // Obseravbles
            eventViewModel: observable,
        });
    }

    public get date() {
        return this.model.date;
    }

    public get numberOfDays() {
        return this.model.numberOfDays;
    }

    public get formattedDay() {
        return moment(this.date).format("dddd");
    }

    public get formattedDate() {
        return moment(this.date).format("D MMM");
    }

    public get dayOfWeek() {
        return moment(this.date).weekday();
    }
}
