import { isEmptyOrWhitespace, ModelBase } from "@shoothill/core";
import { action, makeObservable, observable } from "mobx";
import { nanoid } from "nanoid";
import { container } from "tsyringe";

import { Validator } from "Application/Validation";
import { LookupStore } from "Stores/Domain";
import { CONTACTTYPE_COMMERCIALTYPE } from "../Constants";

export class CustomerModel extends ModelBase<CustomerModel> {
    public KEY: string = nanoid();
    public backupCopy: CustomerModel | null = null;

    public contactTypeId: string = "";
    public customerId: string | null = null;
    public customerContactId: string | null = null;

    public siteAddress1: string | null = null;
    public siteAddress2: string | null = null;
    public siteAddress3: string | null = null;
    public siteCity: string | null = null;
    public sitePostcode: string | null = null;

    public sameAsBusinessAddress: boolean = true;

    constructor() {
        super();
        makeObservable(this, {
            // Observables
            contactTypeId: observable,
            customerId: observable,
            customerContactId: observable,

            siteAddress1: observable,
            siteAddress2: observable,
            siteAddress3: observable,
            siteCity: observable,
            sitePostcode: observable,

            sameAsBusinessAddress: observable,

            // Actions
            restore: action,
        });
    }

    // #region Helpers

    public backup = (): CustomerModel => {
        const model = new CustomerModel();

        model.KEY = model.KEY;
        model.contactTypeId = this.contactTypeId;
        model.customerId = this.customerId;
        model.customerContactId = this.customerContactId;

        model.siteAddress1 = this.siteAddress1;
        model.siteAddress2 = this.siteAddress2;
        model.siteAddress3 = this.siteAddress3;
        model.siteCity = this.siteCity;
        model.sitePostcode = this.sitePostcode;

        model.sameAsBusinessAddress = this.sameAsBusinessAddress;

        this.backupCopy = model;

        return model;
    };

    public restore = (): CustomerModel => {
        if (this.backupCopy) {
            this.KEY = this.backupCopy.KEY;
            this.contactTypeId = this.backupCopy.contactTypeId;
            this.customerId = this.backupCopy.customerId;
            this.customerContactId = this.backupCopy.customerContactId;

            this.siteAddress1 = this.backupCopy.siteAddress1;
            this.siteAddress2 = this.backupCopy.siteAddress2;
            this.siteAddress3 = this.backupCopy.siteAddress3;
            this.siteCity = this.backupCopy.siteCity;
            this.sitePostcode = this.backupCopy.sitePostcode;

            this.sameAsBusinessAddress = this.backupCopy.sameAsBusinessAddress;
        }

        return this;
    };

    // #endregion Helpers
}

export class CustomerValidator extends Validator<CustomerModel> {
    private lookupStore = container.resolve(LookupStore);

    constructor() {
        super();

        this.ruleFor("customerId").notNull().withMessage("Please select a value").notEmpty().withMessage("Please select a value");

        this.ruleFor("customerContactId").must({
            predicate: (customerContactId, model) => {
                const isContactTypeCommercial = model.contactTypeId === this.lookupStore.getContactTypeIdByType(CONTACTTYPE_COMMERCIALTYPE);

                return isContactTypeCommercial ? !isEmptyOrWhitespace(customerContactId) : true;
            },
            message: (value, model) => "Please select a value",
        });

        this.ruleFor("siteAddress1").must({
            predicate: (siteAddress1, model) => (model.sameAsBusinessAddress ? true : !isEmptyOrWhitespace(siteAddress1)),
            message: (value, model) => "Please provide an address line",
        });

        // No rule required for siteAddress2.

        // No rule required for siteAddress3.

        this.ruleFor("siteCity").must({
            predicate: (siteCity, model) => (model.sameAsBusinessAddress ? true : !isEmptyOrWhitespace(siteCity)),
            message: (value, model) => "Please provide a town or city",
        });

        this.ruleFor("sitePostcode").must({
            predicate: (sitePostcode, model) => (model.sameAsBusinessAddress ? true : !isEmptyOrWhitespace(sitePostcode)),
            message: (value, model) => "Please provide a postcode",
        });
    }
}
