import { runInAction } from "mobx";

import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { CreateTaskViewModel } from "./CreateTaskViewModel";
import { CreateTaskModel } from "./CreateTaskModel";

class Request {
    projectId: string = "";
    serviceGroupId: string = "";
    serviceId: string = "";
    hourlyRate: number = 0;
    manHours: number | null = null;
    isBilledHourly: boolean = false;
}

class Response {
    id: string = "";
}

export class POSTSaveProjectTaskEndpoint extends Endpoint<Request, Response> {
    private readonly viewModel: CreateTaskViewModel;

    constructor(viewModel: CreateTaskViewModel) {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Resources.SaveProjectAdHocTask);
        this.viewModel = viewModel;
    }

    public async HandleRequestAsync(model: CreateTaskModel): Promise<any> {
        const request = new Request();

        request.projectId = model.projectId!;
        request.serviceGroupId = model.serviceGroupId!;
        request.serviceId = model.serviceId!;
        request.hourlyRate = model.hourlyRate!;
        request.manHours = model.manHours;
        request.isBilledHourly = model.isBilledHourly;

        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            this.viewModel.parentSubmitCommand.execute(response.id, this.viewModel.displayAssignView);
        });
    }
}
