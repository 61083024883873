import { debounce } from "@mui/material";
import { FieldType, isEmptyOrWhitespace, isNullOrEmpty, ViewModelBase } from "@shoothill/core";
import { action, computed, makeObservable, observable } from "mobx";
import { container } from "tsyringe";

import { APIClient, RelayCommand } from "Application";
import { LookupStore } from "Stores/Domain";
import { CustomerModel, CustomerValidator } from "./CustomerModel";
import { CustomerContactItemModel } from "./CustomerContactItemModel";
import { CustomerItemModel } from "./CustomerItemModel";
import { CONTACTTYPE_COMMERCIALTYPE, CONTACTTYPE_DOMESTICTYPE, SERVER_DEBOUNCE_PERIOD_MS } from "../Constants";
import { GETCustomerContactsByCustomerIdEndpoint } from "./GETCustomerContactsByCustomerIdEndpoint";
import { FindAddressViewModel } from "../FindAddress/FindAddressViewModel";

export class CustomerViewModel extends ViewModelBase<CustomerModel> {
    public apiClient = new APIClient();
    private lookupStore = container.resolve(LookupStore);

    public customers = observable<CustomerItemModel>([]);
    public customerContacts = observable<CustomerContactItemModel>([]);

    public findAddressViewModel: FindAddressViewModel;

    constructor(revisionModel: CustomerModel = new CustomerModel()) {
        super(revisionModel);

        this.findAddressViewModel = new FindAddressViewModel(this.updateSiteAddressCommand);

        this.setValidator(new CustomerValidator());

        makeObservable(this, {
            // Observables
            customers: observable,
            customerContacts: observable,
            // Computeds
            completedCustomers: computed,
            completedCustomerContacts: computed,
        });

        // Default values
        if (!this.model.contactTypeId) {
            this.setValue("contactTypeId", this.lookupStore.getContactTypeIdByType(CONTACTTYPE_COMMERCIALTYPE));
        }
    }

    // #region Properties

    /**
     * Determines if any features dependent on the contact type being commercial
     * can be displayed.
     */
    public get canDisplayCommercialContactTypeOptions(): boolean {
        return this.model.contactTypeId === this.lookupStore.getContactTypeIdByType(CONTACTTYPE_COMMERCIALTYPE);
    }

    /**
     * Determines if completed client ionformation can be displayed.
     */
    public get canDisplayCompletedClient(): boolean {
        switch (true) {
            case this.model.contactTypeId === this.lookupStore.getContactTypeIdByType(CONTACTTYPE_COMMERCIALTYPE) &&
                !isNullOrEmpty(this.model.customerId) &&
                !isNullOrEmpty(this.model.customerContactId):
            case this.model.contactTypeId === this.lookupStore.getContactTypeIdByType(CONTACTTYPE_DOMESTICTYPE) && !isNullOrEmpty(this.model.customerId):
                return true;
            default:
                return false;
        }
    }

    /**
     * Determines if the site address can be displayed. This is if the site
     * address will differ from the business/correspondence address.
     */
    public get canDisplaySiteAddress(): boolean {
        return !this.model.sameAsBusinessAddress;
    }

    /**
     * Returns a collection of contact types.
     */
    public get contactTypeOptions() {
        return this.lookupStore.getContactTypes;
    }

    public get customerContactType() {
        const retVal = this.lookupStore.contactTypes.find((type) => type.id == this.customer?.contactTypeId);
        return retVal;
    }

    public get fullSiteAddress(): string {
        return [this.model.siteAddress1, this.model.siteAddress2, this.model.siteAddress3, this.model.siteCity, this.model.sitePostcode?.toUpperCase()]
            .filter((item) => !isEmptyOrWhitespace(item))
            .join(", ");
    }

    /**
     * Returns a collection of customers matching the current contact type.
     * However, don't return any deleted customers as they should not be selectable.
     */
    public get customerOptions() {
        return this.customers
            .filter((item: CustomerItemModel) => {
                return item.contactTypeId === this.model.contactTypeId && !item.isDeleted;
            })
            .map((item: CustomerItemModel) => {
                return {
                    key: item.id,
                    text: [item.businessName, item.firstName, item.lastName].filter((item) => !isEmptyOrWhitespace(item)).join(" "),
                };
            });
    }

    /**
     * Returns a collection of customer contacts matching the current customer.
     * However, don't return any deleted customers contacts as they should not be selectable.
     */
    public get customerContactOptions() {
        return this.customerContacts
            .filter((item: CustomerContactItemModel) => {
                return item.customerId === this.model.customerId && !item.isDeleted;
            })
            .map((item: CustomerContactItemModel) => {
                return {
                    key: item.id!,
                    text: `${item.firstName} ${item.lastName}`,
                };
            });
    }

    /**
     * Returns completed customer.
     */
    public get completedCustomers() {
        return this.customers.filter((item: CustomerItemModel) => item.id === this.model.customerId);
    }

    public get customer() {
        const customerId = this.model.customerId;
        const retVal = this.customers.find((item: CustomerItemModel) => item.id === customerId);
        return retVal;
    }

    /**
     * Returns completed customer contacts
     */
    public get completedCustomerContacts() {
        if (this.model.contactTypeId === this.lookupStore.getContactTypeIdByType(CONTACTTYPE_COMMERCIALTYPE)) {
            //For a commercial customer, only return the associated contact.
            return this.customerContacts.filter((item: CustomerContactItemModel) => item.id === this.model.customerContactId);
        } else {
            // For a dometics customer, return all contacts.
            return this.customerContacts.filter((item: CustomerContactItemModel) => item.customerId === this.model.customerId);
        }
    }

    public canDisplayCustomerContactNumber1(customer: CustomerItemModel) {
        return !isEmptyOrWhitespace(customer.contactNumber1);
    }

    public canDisplayCustomerContactNumber2(customer: CustomerItemModel) {
        return !isEmptyOrWhitespace(customer.contactNumber2);
    }

    public canDisplayCustomerEmailAddress(customer: CustomerItemModel) {
        return !isEmptyOrWhitespace(customer.emailAddress);
    }

    public canDisplayCustomerEmailAddress2(customer: CustomerItemModel) {
        return !isEmptyOrWhitespace(customer.emailAddress2);
    }

    public canDisplayCustomerAddress(customer: CustomerItemModel) {
        return !isEmptyOrWhitespace(customer.fullAddress);
    }

    public canDisplayCustomerContactContactNumber1(customerContact: CustomerContactItemModel) {
        return !isEmptyOrWhitespace(customerContact.contactNumber1);
    }

    public canDisplayCustomerContactContactNumber2(customerContact: CustomerContactItemModel) {
        return !isEmptyOrWhitespace(customerContact.contactNumber2);
    }

    public canDisplayCustomerContactEmailAddress(customerContact: CustomerContactItemModel) {
        return !isEmptyOrWhitespace(customerContact.emailAddress);
    }

    public canDisplayCustomerContactAddress(customerContact: CustomerContactItemModel) {
        return !isEmptyOrWhitespace(customerContact.fullAddress);
    }

    // #endregion Properties

    // #region Commands

    public updateContactTypeCommand = new RelayCommand((value: string) => {
        this.updateField("contactTypeId", value);

        // SIDE-EFFECT. When changing the contact type, reset any dependant selected
        // values as the they may no longer be valid.
        this.updateField("customerId", null);
        this.updateField("customerContactId", null);
    });

    public updateCustomerCommand = new RelayCommand((value: string | null) => {
        this.updateField("customerId", value);

        // SIDE-EFFECT. When changing the customer, reset any dependant selected values
        // as the they may no longer be valid. We also need to see if we have the
        // customer contacts available locally and if not load them from the server.
        this.updateField("customerContactId", null);
        this.setCustomerContacts(this.model.customerId);
    });

    public updateCustomerContactCommand = new RelayCommand(
        (value: string | null) => {
            this.updateField("customerContactId", value);
        },
        () => {
            if (this.model.contactTypeId === this.lookupStore.getContactTypeIdByType(CONTACTTYPE_DOMESTICTYPE)) {
                return true;
            }

            return this.model.contactTypeId === this.lookupStore.getContactTypeIdByType(CONTACTTYPE_COMMERCIALTYPE) && !isNullOrEmpty(this.getValue("customerId"));
        },
    );

    public updateSameAsCorrespondenceAddressCommand = new RelayCommand((value: boolean) => {
        this.updateField("sameAsBusinessAddress", value);

        // SIDE-EFFECT. If the site address is the same as the correspondence
        // address, the site address information is no longer valid.
        if (this.model.sameAsBusinessAddress) {
            this.updateField("siteAddress1", null);
            this.updateField("siteAddress2", null);
            this.updateField("siteAddress3", null);
            this.updateField("siteCity", null);
            this.updateField("sitePostcode", null);
        }
    });

    public updateSiteAddressCommand = new RelayCommand((value: any, postcode: string) => {
        // Address information returned depends on the api used.
        // So for the moment, not using an interface on this.
        const siteAddress1 = value?.formatted_address?.[0];
        const siteAddress2 = value?.formatted_address?.[1];
        const siteAddress3 = value?.formatted_address?.[2];
        const siteCity = value?.formatted_address?.[3];
        const postCode = postcode;

        this.updateField("siteAddress1", !isEmptyOrWhitespace(siteAddress1) ? siteAddress1 : null);
        this.updateField("siteAddress2", !isEmptyOrWhitespace(siteAddress2) ? siteAddress2 : null);
        this.updateField("siteAddress3", !isEmptyOrWhitespace(siteAddress3) ? siteAddress3 : null);
        this.updateField("siteCity", !isEmptyOrWhitespace(siteCity) ? siteCity : null);
        this.updateField("sitePostcode", !isEmptyOrWhitespace(postCode) ? postCode : null);
    });

    public updateSiteAddress1Command = new RelayCommand((value: string) => {
        this.updateField("siteAddress1", value);
    });

    public updateSiteAddress2Command = new RelayCommand((value: string) => {
        this.updateField("siteAddress2", value);
    });

    public updateSiteAddress3Command = new RelayCommand((value: string) => {
        this.updateField("siteAddress3", value);
    });

    public updateSiteCityCommand = new RelayCommand((value: string) => {
        this.updateField("siteCity", value);
    });

    public updateSitePostCodeCommand = new RelayCommand((value: string) => {
        this.updateField("sitePostcode", value);
    });

    public removedCompletedClientCommand = new RelayCommand(() => {
        switch (true) {
            case this.model.contactTypeId === this.lookupStore.getContactTypeIdByType(CONTACTTYPE_COMMERCIALTYPE):
                this.updateField("customerContactId", null);
                break;

            case this.model.contactTypeId === this.lookupStore.getContactTypeIdByType(CONTACTTYPE_DOMESTICTYPE):
                this.updateField("customerId", null);
                break;
        }
    });

    public get canRemoveCompletedClient() {
        const retVal = this.model.contactTypeId !== this.lookupStore.getContactTypeIdByType(CONTACTTYPE_DOMESTICTYPE);
        return retVal;
    }

    private setCustomerContacts = debounce(
        action((customerId: string | null) => {
            if (customerId && this.customerContacts.findIndex((cc) => cc.customerId === customerId) === -1) {
                this.apiClient.sendAsync(new GETCustomerContactsByCustomerIdEndpoint(customerId, this));
            }
        }),
        SERVER_DEBOUNCE_PERIOD_MS,
    );

    // #endregion Commands

    // #region Supporting

    // Customers below...
    public getCustomerName = (item: CustomerItemModel) => {
        return item.contactTypeId === this.lookupStore.getContactTypeIdByType(CONTACTTYPE_COMMERCIALTYPE)
            ? item.businessName
            : [item.prefixName, item.firstName, item.lastName].filter((item) => !isEmptyOrWhitespace(item)).join(" ");
    };

    public getCustomerContactNumber1 = (item: CustomerItemModel) => {
        return !isEmptyOrWhitespace(item.contactNumber1) ? item.contactNumber1 : "";
    };

    public getCustomerContactNumber2 = (item: CustomerItemModel) => {
        return !isEmptyOrWhitespace(item.contactNumber2) ? item.contactNumber2 : "";
    };

    public getCustomerEmailAddress = (item: CustomerItemModel) => {
        return !isEmptyOrWhitespace(item.emailAddress) ? item.emailAddress : "";
    };

    public getCustomerEmailAddress2 = (item: CustomerItemModel) => {
        return !isEmptyOrWhitespace(item.emailAddress2) ? item.emailAddress2 : "";
    };

    public getCustomerAddress = (item: CustomerItemModel) => {
        return !isEmptyOrWhitespace(item.fullAddress) ? item.fullAddress : "";
    };

    // Contacts below...
    public getCustomerContactName = (item: CustomerContactItemModel) => {
        return [item.prefixName, item.firstName, item.lastName].filter((item) => !isEmptyOrWhitespace(item)).join(" ");
    };

    public getCustomerContactContactNumber1 = (item: CustomerContactItemModel) => {
        return !isEmptyOrWhitespace(item.contactNumber1) ? item.contactNumber1 : "";
    };

    public getCustomerContactContactNumber2 = (item: CustomerContactItemModel) => {
        return !isEmptyOrWhitespace(item.contactNumber2) ? item.contactNumber2 : "";
    };

    public getCustomerContactEmailAddress = (item: CustomerContactItemModel) => {
        return !isEmptyOrWhitespace(item.emailAddress) ? item.emailAddress : "";
    };

    public getCustomerContactAddress = (item: CustomerContactItemModel) => {
        return !isEmptyOrWhitespace(item.fullAddress) ? item.fullAddress : "";
    };

    // Other...
    private updateField(fieldName: keyof FieldType<CustomerModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    // #endregion Supporting
}
