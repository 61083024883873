import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";

export interface IDeletePlanningApplicationRequest {
    id: string;
}

class Response {}

export class POST_DeletePlanningApplicationEndpoint extends Endpoint<Request, Response> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.PlanningApplication.Delete);
    }

    public async HandleRequestAsync(request: IDeletePlanningApplicationRequest): Promise<any> {
        return request;
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        //Return your model
        return {};
    }
}
