import { runInAction } from "mobx";
import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import SupplierDetailViewModel from "./SupplierDetailViewModel";

export class DeleteSupplierEndpoint extends EndpointWithoutRequest<Response> {
    private readonly viewModel: any;

    constructor(id: string, viewModel: SupplierDetailViewModel) {
        super();
        this.verb(Http.Delete);
        this.path(AppUrls.Server.Suppliers.SoftDeleteSupplierById.replace(":id", id));
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        runInAction(() => {
            this.viewModel.history.replace(AppUrls.Client.Suppliers.Table);
        });
    }
}
