import { IStyle, mergeStyleSets } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React from "react";

import { AutoGrid, Nullcommand, theme, ThemedButton, ThemedEditText, ThemedText, ThemedTransparentIconButton } from "Application";
import { CrossSVG } from "Assets/CrossSVG";
import { ConfigureServicesViewModel } from "./ConfigureServicesViewModel";
import { CustomServiceTaskView } from "./ConfigureServiceSubViews/CustomServiceTaskView";
import { ServiceTaskView } from "./ConfigureServiceSubViews/ServiceTaskView";
import { FormSeparator } from "Views/Shared/SharedComponents";

interface IConfigureServicesViewStyles {
    root: IStyle;
    header: IStyle;
    body: IStyle;
    taskContainer: IStyle;
    footer: IStyle;
    iconButton: IStyle;
    edit: IStyle;
}

interface IConfigureServicesViewProps {
    viewModel: ConfigureServicesViewModel;
}

const styleDefinitions: IConfigureServicesViewStyles = {
    root: {
        width: "1000px",
    },
    header: {
        alignItems: "center",
        backgroundColor: "#F3F3F3",
        display: "flex",
        height: "75px",
        justifyContent: "space-between",
        padding: "20px 30px",
    },
    body: {
        padding: "30px 30px 0 30px",
    },
    taskContainer: {
        maxHeight: "190px",
        overflowY: "auto",
    },
    footer: {
        display: "flex",
        height: "75px",
        padding: "20px 30px",
    },
    iconButton: {
        g: {
            stroke: theme.palette.defaultTr.text,
        },
    },
    edit: {
        ".ms-Stack": {
            display: "none",
        },
    },
};

export const ConfigureServicesView: React.FC<IConfigureServicesViewProps> = observer((props) => {
    const viewModel = props.viewModel;
    const styles = mergeStyleSets(styleDefinitions);

    if (viewModel) {
        return (
            <div className={styles.root}>
                <div className={styles.header}>
                    <ThemedText fontStyle="h2">{viewModel.displayName}</ThemedText>
                    <ThemedTransparentIconButton command={viewModel.cancelDisplayConfigureTaskGroupCommand} icon={<CrossSVG className={styles.iconButton} />} shape="round" />
                </div>
                <div className={styles.body}>
                    <div className={styles.taskContainer}>
                        {viewModel.serviceTaskViewModels.map((vm) => {
                            return <ServiceTaskView key={vm.KEY} viewModel={vm} />;
                        })}
                    </div>
                    <FormSeparator />
                    <AutoGrid dc={"1fr auto"} columnGap={"30px"}>
                        <ThemedEditText
                            className={styles.edit}
                            displayName=""
                            command={viewModel.updateCustomTaskName}
                            placeholder="Type the name of the custom task, then click the button to be included"
                            value={() => viewModel.getValue("customServiceTaskName")}
                            validationMessage={() => ""}
                        />
                        <ThemedButton command={viewModel.addCustomTaskCommand} displayName="Add" paletteColor="primary" size="larger" />
                    </AutoGrid>
                    <div className={styles.taskContainer}>
                        {viewModel.filteredCustomServiceTaskViewModels.map((vm) => {
                            return <CustomServiceTaskView key={vm.KEY} viewModel={vm} />;
                        })}
                    </div>
                </div>
                <AutoGrid m={0} className={styles.footer} columnGap={"30px"} dc={"140px 140px"}>
                    <ThemedButton
                        paletteColor="primary"
                        command={viewModel.updateFromConfigureTaskGroupCommand}
                        displayName="Add to quote"
                        styles={{ root: { padding: "0 20px" } }}
                    />
                    <ThemedButton command={viewModel.cancelDisplayConfigureTaskGroupCommand} displayName="Cancel" styles={{ root: { padding: "0 20px" } }} />
                </AutoGrid>
            </div>
        );
    }

    return <React.Fragment />;
});
