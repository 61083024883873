import { isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { computed, makeObservable, observable } from "mobx";

import { APIClient, ICommand, ICommandAsync, RelayCommand } from "Application";
import { AppUrls } from "AppUrls";
import { LeaveRequest } from "Views/Leave/Shared/LeaveRequest";
import { GETAllPendingLeaveRequestsEndpoint } from "./Endpoints/GETAllPendingLeaveRequests";
import { LeaveRequestsModel } from "./LeaveRequestsModel";
import { LeaveRequestItemViewModel } from "./LeaveRequestItemViewModel";

import { DeleteLeaveRequestModel } from "Views/Leave/Shared/ModalViews/DeleteLeaveRequest/DeleteLeaveRequestModel";
import { DeleteLeaveRequestViewModel } from "Views/Leave/Shared/ModalViews/DeleteLeaveRequest/DeleteLeaveRequestViewModel";
import { POSTDeleteLeaveEndpoint } from "Views/Leave/Shared/ModalViews/DeleteLeaveRequest/Endpoints/POSTDeleteLeaveRequestEndpoint";

import { NewRequestModel } from "Views/Leave/Shared/ModalViews/NewRequest/NewRequestModel";
import { NewRequestViewModel } from "Views/Leave/Shared/ModalViews/NewRequest/NewRequestViewModel";
import { POSTRequestLeaveForUserEndpoint } from "Views/Leave/Shared/ModalViews/NewRequest/Endpoints/POSTRequestLeaveForUser";

import { ApproveRequestModel } from "../Shared/ModalViews/ApproveRequest/ApproveRequestModel";
import { ApproveRequestViewModel } from "../Shared/ModalViews/ApproveRequest/ApproveRequestViewModel";
import { POSTApproveRequestEndpoint } from "../Shared/ModalViews/ApproveRequest/Endpoints/POSTApproveRequestEndpoint";

import { DeclineRequestModel } from "../Shared/ModalViews/DeclineRequest/DeclineRequestModel";
import { DeclineRequestViewModel } from "../Shared/ModalViews/DeclineRequest/DeclineRequestViewModel";
import { POSTDeclineRequestEndpoint } from "../Shared/ModalViews/DeclineRequest/Endpoints/POSTDeclineRequestEndpoint";

import { NewRequestModel as NewRequestWithUserSelectModel } from "../Shared/ModalViews/NewRequest/NewRequestModel";
import { NewRequestViewModel as NewRequestWithUserSelectViewModel } from "../Shared/ModalViews/NewRequest/NewRequestViewModel";
import { POSTRequestLeaveForUserEndpoint as POSTRequestLeaveForUserWithuserSelectEndpoint } from "../Shared/ModalViews/NewRequest/Endpoints/POSTRequestLeaveForUser";
import { DashboardViewModel } from "Views/Dashboard/DashboardViewModel";

export class LeaveRequestsViewModel extends ViewModelBase<LeaveRequestsModel> {
    public apiClient = new APIClient();
    public leaveRequests = observable<LeaveRequestItemViewModel>([]);

    public newRequestWithUserSelectViewModel: NewRequestWithUserSelectViewModel | null = null;
    public editRequestViewModel: NewRequestViewModel | null = null;
    public approveRequestViewModel: ApproveRequestViewModel | null = null;
    public declineRequestViewModel: DeclineRequestViewModel | null = null;
    public deleteLeaveRequestViewModel: DeleteLeaveRequestViewModel | null = null;
    public dashboardSyncCommand: ICommandAsync | null = null;

    constructor(model = new LeaveRequestsModel(), dashboardViewModel?: DashboardViewModel) {
        super(model, false);

        this.dashboardSyncCommand = dashboardViewModel ? dashboardViewModel.syncDashboardCommand : null;

        makeObservable(this, {
            // Observables
            newRequestWithUserSelectViewModel: observable,
            editRequestViewModel: observable,
            approveRequestViewModel: observable,
            declineRequestViewModel: observable,
            deleteLeaveRequestViewModel: observable,

            // Computeds
            canDisplayWithUserSelectNewRequest: computed,
            canDisplayEditRequest: computed,
            canDisplayApproveRequest: computed,
            canDisplayDeclineRequest: computed,
            canDisplayDeleteRequest: computed,
        });

        const _ = this.apiClient.sendAsync(new GETAllPendingLeaveRequestsEndpoint(this));
    }

    // #region Sorting

    public updateSortCommand = new RelayCommand((key: string, sortDescending: boolean) => {
        this.model.sortKey = key;
        this.model.sortDescending = sortDescending;
    });

    public get canSortLeaveRequests(): boolean {
        return !isEmptyOrWhitespace(this.model.sortKey);
    }

    public get sortedLeaveRequests(): LeaveRequestItemViewModel[] {
        return this.canSortLeaveRequests
            ? this.leaveRequests.slice().sort((lhs, rhs) => {
                  return (this.model.sortDescending ? lhs[this.model.sortKey] < rhs[this.model.sortKey] : lhs[this.model.sortKey] > rhs[this.model.sortKey]) ? 1 : -1;
              })
            : this.leaveRequests;
    }

    // #endregion Sorting

    // #region New Request (With user selection) Commands

    public get canDisplayWithUserSelectNewRequest(): boolean {
        return this.newRequestWithUserSelectViewModel !== null;
    }

    public displayNewRequestWithUserSelectCommand = new RelayCommand(() => {
        this.newRequestWithUserSelectViewModel = new NewRequestWithUserSelectViewModel(
            new NewRequestWithUserSelectModel(this.model.users, this.model.leaveTypes, this.model.leaveDayTypes),
            this.submitNewRequestWithUserSelectCommand,
            this.cancelNewRequestWithUserSelectCommand,
        );
    });

    public submitNewRequestWithUserSelectCommand = new RelayCommand(
        async () => {
            await this.apiClient.sendAsync(new POSTRequestLeaveForUserWithuserSelectEndpoint(), this.newRequestWithUserSelectViewModel?.model);

            if (this.apiClient.IsRequestSuccessful) {
                this.newRequestWithUserSelectViewModel = null;
                this.apiClient.reset();
                this.apiClient.sendAsync(new GETAllPendingLeaveRequestsEndpoint(this));
            }
        },
        () => {
            return !this.apiClient.IsBusy;
        },
    );

    public cancelNewRequestWithUserSelectCommand = new RelayCommand(() => {
        this.newRequestWithUserSelectViewModel = null;
    });

    // #endregion New Request (With user selection) Commands

    // #region Approve Request Commands

    public get canDisplayApproveRequest(): boolean {
        return this.approveRequestViewModel !== null;
    }

    public displayApproveRequestCommand = new RelayCommand((leaveRequest: LeaveRequest) => {
        this.approveRequestViewModel = new ApproveRequestViewModel(
            new ApproveRequestModel(leaveRequest),
            this.submitApproveRequestCommand,
            this.cancelApproveRequestCommand,
            this.dashboardSyncCommand,
        );
    });

    public submitApproveRequestCommand = new RelayCommand(
        async () => {
            await this.apiClient.sendAsync(new POSTApproveRequestEndpoint(), this.approveRequestViewModel?.model);

            if (this.apiClient.IsRequestSuccessful) {
                this.approveRequestViewModel = null;
                this.apiClient.reset();
                this.apiClient.sendAsync(new GETAllPendingLeaveRequestsEndpoint(this));
            }
        },
        () => {
            return !this.apiClient.IsBusy;
        },
    );

    public cancelApproveRequestCommand = new RelayCommand(() => {
        this.approveRequestViewModel = null;
    });

    // #endregion Approve Request Commands

    // #region Decline Request Commands

    public get canDisplayDeclineRequest(): boolean {
        return this.declineRequestViewModel !== null;
    }

    public displayDeclineRequestCommand = new RelayCommand((leaveRequest: LeaveRequest) => {
        this.declineRequestViewModel = new DeclineRequestViewModel(new DeclineRequestModel(leaveRequest), this.submitDeclineRequestCommand, this.cancelDeclineRequestCommand);
    });

    public submitDeclineRequestCommand = new RelayCommand(
        async () => {
            await this.apiClient.sendAsync(new POSTDeclineRequestEndpoint(), this.declineRequestViewModel?.model);

            if (this.apiClient.IsRequestSuccessful) {
                this.declineRequestViewModel = null;
                this.apiClient.reset();
                this.apiClient.sendAsync(new GETAllPendingLeaveRequestsEndpoint(this));
            }
        },
        () => {
            return !this.apiClient.IsBusy;
        },
    );

    public cancelDeclineRequestCommand = new RelayCommand(() => {
        this.declineRequestViewModel = null;
    });

    // #endregion Decline Request Commands

    // #region Delete Request Commands

    public get canDisplayDeleteRequest(): boolean {
        return this.deleteLeaveRequestViewModel !== null;
    }

    public displayDeleteRequestCommand = new RelayCommand((leaveRequest: LeaveRequest) => {
        this.deleteLeaveRequestViewModel = new DeleteLeaveRequestViewModel(
            new DeleteLeaveRequestModel(leaveRequest),
            this.submitDeleteRequestCommand,
            this.cancelDeleteRequestCommand,
        );
    });

    public submitDeleteRequestCommand = new RelayCommand(
        async () => {
            await this.apiClient.sendAsync(new POSTDeleteLeaveEndpoint(), this.deleteLeaveRequestViewModel?.model);

            if (this.apiClient.IsRequestSuccessful) {
                this.deleteLeaveRequestViewModel = null;
                this.apiClient.reset();
                this.apiClient.sendAsync(new GETAllPendingLeaveRequestsEndpoint(this));
            }
        },
        () => {
            return !this.apiClient.IsBusy;
        },
    );

    public cancelDeleteRequestCommand = new RelayCommand(() => {
        this.deleteLeaveRequestViewModel = null;
    });

    // #endregion Delete Request Commands

    // #region Edit Request Commands

    public get canDisplayEditRequest(): boolean {
        return this.editRequestViewModel !== null;
    }

    public displayEditRequestCommand = new RelayCommand((leaveRequest: LeaveRequest) => {
        this.editRequestViewModel = new NewRequestViewModel(
            new NewRequestModel(this.model.leaveTypes, this.model.leaveDayTypes, leaveRequest),
            this.submitEditRequestCommand,
            this.cancelEditRequestCommand,
        );
    });

    public submitEditRequestCommand = new RelayCommand(
        async () => {
            await this.apiClient.sendAsync(new POSTRequestLeaveForUserEndpoint(), this.editRequestViewModel?.model);

            if (this.apiClient.IsRequestSuccessful) {
                this.editRequestViewModel = null;
                this.apiClient.reset();
                this.apiClient.sendAsync(new GETAllPendingLeaveRequestsEndpoint(this));
            }
        },
        () => {
            return !this.apiClient.IsBusy;
        },
    );

    public cancelEditRequestCommand = new RelayCommand(() => {
        this.editRequestViewModel = null;
    });

    // #endregion Edit Request Commands

    // #region Commands

    public resetServerErrorCommand = new RelayCommand(() => {
        this.apiClient.reset();
    });

    public navigateToCalendarCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.Leave.LeaveCalendar);
    });

    // #endregion Commands
}
