import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

export class LostModel extends ModelBase<LostModel> {
    // Filtering
    public filterKeyword: string = "";
    public filterFromDate: Date | undefined = undefined;
    public filterToDate: Date | undefined = undefined;
    public filterTypeId: string | null = null;

    constructor() {
        super();
        makeObservable(this, {
            // Observables
            filterKeyword: observable,
            filterFromDate: observable,
            filterToDate: observable,
            filterTypeId: observable,
        });
    }
}
