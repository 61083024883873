import { runInAction } from "mobx";

import { EndpointWithoutRequest, Http } from "Application";
import { AppUrls } from "AppUrls";
import { Logger } from "index";
import { BilledTaskItemModel } from "./BilledTaskItemModel";
import { BilledTasksViewModel } from "./BilledTasksViewModel";
import { BilledTaskItemViewModel } from "./BilledTaskItemViewModel";

class Response {
    public id: string = "";
    public projectTaskGroupId = "";
    public projectTaskId = "";
    public projectLeadId = "";
    public billedEffort = 0;
    public billedDate: string | null = null;
    public reference = "";
    public originReference = "";
    public title = "";
    public projectTaskGroupName = "";
    public projectTaskName = "";
    public projectLeadFirstName = "";
    public projectLeadLastName = "";
    public projectLeadDocumentUrl = "";
}

export class GETProjectTasksEndpoint extends EndpointWithoutRequest<Response> {
    private viewModel: BilledTasksViewModel;

    constructor(viewModel: BilledTasksViewModel) {
        super();
        this.viewModel = viewModel;
        this.verb(Http.Get);
        this.path(AppUrls.Server.Resources.AllBilledProjectTasks);
    }

    public async HandleResponseAsync(response: Response[]): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            const tasks: BilledTaskItemViewModel[] = [];

            response.forEach((element: any) => {
                const model = new BilledTaskItemModel();

                model.fromResponse(element);

                tasks.push(new BilledTaskItemViewModel(model));
            });

            this.viewModel.tasks.replace(tasks);
        });
    }
}
