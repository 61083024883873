import { SVGProps } from "react";

export const DownloadIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="9.977" height="18" viewBox="0 0 9.977 18" {...props}>
        <g id="Group_9252" data-name="Group 9252" transform="translate(-826.023 -506)">
            <g id="Group_9246" data-name="Group 9246" transform="translate(925.523 3942) rotate(180)">
                <path
                    id="sort"
                    d="M5.174,0,9.94,4.381a.134.134,0,0,1,0,.223L5.174,9V6.2h-6A.162.162,0,0,1-1,6.035V2.98a.162.162,0,0,1,.175-.164h6Z"
                    transform="translate(90 3428) rotate(-90)"
                    fill="#ff4b22"
                />
            </g>
            <g id="Group_9247" data-name="Group 9247" transform="translate(836 506) rotate(90)">
                <path
                    id="Path_5673"
                    data-name="Path 5673"
                    d="M4.063,1,1,3.993V8.868a.109.109,0,0,0,.109.109h9.085a.109.109,0,0,0,.108-.109V1.109A.109.109,0,0,0,10.194,1H4.063M3.728,0h6.466A1.108,1.108,0,0,1,11.3,1.109v7.76a1.108,1.108,0,0,1-1.108,1.109H1.109A1.109,1.109,0,0,1,0,8.868V3.5Z"
                    transform="translate(0)"
                    fill="#ff4b22"
                />
                <g id="Group_7137" data-name="Group 7137" transform="translate(0)">
                    <path
                        id="Path_5673-2"
                        data-name="Path 5673"
                        d="M4.693,1V4.447H1V8.868a.109.109,0,0,0,.109.109h9.085a.109.109,0,0,0,.108-.109V1.109A.109.109,0,0,0,10.194,1h-5.5m-1-1h6.5A1.108,1.108,0,0,1,11.3,1.109v7.76a1.108,1.108,0,0,1-1.108,1.109H1.109A1.109,1.109,0,0,1,0,8.868V3.447H3.693Z"
                        fill="#ff4b22"
                    />
                </g>
            </g>
        </g>
    </svg>
);
