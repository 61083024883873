//libraries
import { observer } from "mobx-react-lite";
import * as React from "react";
import styled from "@emotion/styled";

import { Box, formatCurrency, theme, ThemedText } from "Application";

import { OverviewViewModel } from "../OverviewViewModel";
import { BarDatum, BarTooltipProps, ResponsiveBar } from "@nivo/bar";
import moment from "moment";
import { QuoteLiteModel } from "Views/Shared/Quote/QuoteLiteModel";

const Container = styled.div`
    height: 100%;
    width: 100%;
    max-height: 450px;
    background-color: white;
    opacity: 0.97;
    padding: 15px 30px 30px 30px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-area: enquiries;
`;

const Wrapper = styled.div`
    width: 100%;
    height: 100%;

    position: relative;
`;

const CalendarContainer = styled(Box)`
    position: absolute;
    z-index: 100;

    display: flex;
    align-items: center;
    justify-content: center;
`;

const ToolTipContainer = styled.div`
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);

    background-color: ${theme.palette.common.white};
    border: 1px solid ${theme.palette.tableHeader.light};
    border-radius: 3px;

    min-width: 150px;
    min-height: 30px;
    padding: 10px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const ToolTipRow = styled.div`
    min-width: 150px;
    width: 100%;
    display: flex;
    align-items: center;
`;

interface IProps {
    viewModel: OverviewViewModel;
}

export const EnquiriesWidgetView: React.FC<IProps> = observer((props: IProps) => {
    const { viewModel } = props;

    const colorScheme = [theme.palette.common.primary, theme.palette.common.secondary];

    const renderToolTip = (item: BarTooltipProps<BarDatum>) => {
        if (item.id === "Work won") {
            const date: Date = new Date(item.indexValue);
            const quotesWonPerGivenMonth: QuoteLiteModel[] = viewModel.model.numberOfQuotesWonPerMonth(date);
            const valueOfQuotesWonPerGivenMonth: number = quotesWonPerGivenMonth.map((q) => q.quoteValue ?? 0).reduce((accumulator, currentValue) => accumulator + currentValue, 0);

            return (
                <ToolTipContainer>
                    <ToolTipRow>
                        <ThemedText fontStyle="field" marginRight={"5px"}>
                            Number of quotes won:
                        </ThemedText>
                        <ThemedText fontStyle="field">{item.value}</ThemedText>
                    </ToolTipRow>
                    <ToolTipRow>
                        <ThemedText fontStyle="field" marginRight={"5px"}>
                            Value of quotes won:
                        </ThemedText>
                        <ThemedText fontStyle="field">{formatCurrency(valueOfQuotesWonPerGivenMonth)}</ThemedText>
                    </ToolTipRow>
                </ToolTipContainer>
            );
        } else {
            return (
                <ToolTipContainer>
                    <ToolTipRow>
                        <ThemedText fontStyle="field" marginRight={"5px"}>
                            Number of {item.id.toString().toLowerCase()}:
                        </ThemedText>
                        <ThemedText fontStyle="field">{item.value}</ThemedText>
                    </ToolTipRow>
                </ToolTipContainer>
            );
        }
    };

    return (
        <Container>
            <Wrapper>
                <CalendarContainer>
                    <Box display="flex" alignItems="center" justifyContent="end" marginLeft={"20px"}>
                        <ThemedText fontStyle="titleBold">Enquiries V Work won</ThemedText>
                    </Box>
                </CalendarContainer>
                <div style={{ height: "100%", width: "100%" }}>
                    <ResponsiveBar
                        data={viewModel.barChartData}
                        tooltip={(item) => renderToolTip(item)}
                        keys={["Enquiries", "Work won"]}
                        indexBy="month"
                        margin={{ top: 80, right: 15, bottom: 30, left: 30 }}
                        valueScale={{ type: "linear" }}
                        indexScale={{ type: "band", round: true }}
                        colors={colorScheme}
                        groupMode={"grouped"}
                        theme={{
                            background: "transparent",
                            textColor: `${theme.palette.default.main}`,
                            fontSize: 11,
                            axis: {
                                domain: {
                                    line: {
                                        stroke: "#777777",
                                        strokeWidth: 1,
                                    },
                                },
                                legend: {
                                    text: {
                                        fontSize: 12,
                                        fill: `${theme.palette.default.main}`,
                                    },
                                },
                                ticks: {
                                    line: {
                                        stroke: "#777777",
                                        strokeWidth: 1,
                                    },
                                    text: {
                                        fontSize: 11,
                                        fill: `${theme.palette.default.main}`,
                                    },
                                },
                            },
                            grid: {
                                line: {
                                    stroke: "#dddddd",
                                    strokeWidth: 0,
                                },
                            },
                            legends: {
                                title: {
                                    text: {
                                        fontSize: 8,
                                        textTransform: "capitalize",
                                        fill: `${theme.palette.default.main}`,
                                    },
                                },
                                text: {
                                    fontSize: 10,
                                    textTransform: "capitalize",
                                    fontWeight: "bold",
                                    fill: `${theme.palette.default.main}`,
                                    color: `${theme.palette.default.main}`,
                                },
                                ticks: {
                                    line: {},
                                    text: {
                                        fontSize: 10,
                                        fill: `${theme.palette.default.main}`,
                                    },
                                },
                            },
                            annotations: {
                                text: {
                                    fontSize: 13,
                                    fill: "#333333",
                                    outlineWidth: 2,
                                    outlineColor: "#ffffff",
                                    outlineOpacity: 1,
                                },
                                link: {
                                    stroke: "#000000",
                                    strokeWidth: 1,
                                    outlineWidth: 2,
                                    outlineColor: "#ffffff",
                                    outlineOpacity: 1,
                                },
                                outline: {
                                    stroke: "#000000",
                                    strokeWidth: 2,
                                    outlineWidth: 2,
                                    outlineColor: "#ffffff",
                                    outlineOpacity: 1,
                                },
                                symbol: {
                                    fill: "#000000",
                                    outlineWidth: 2,
                                    outlineColor: "#ffffff",
                                    outlineOpacity: 1,
                                },
                            },
                            tooltip: {
                                container: {
                                    background: "#ffffff",
                                    color: `${theme.palette.default.main}`,
                                    fontSize: 11,
                                },
                                basic: {},
                                chip: {},
                                table: {},
                                tableCell: {},
                                tableCellValue: {},
                            },
                        }}
                        borderColor={{
                            from: "color",
                            modifiers: [["darker", 1.6]],
                        }}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: "",
                            legendPosition: "middle",
                            legendOffset: 32,
                            format: (value) => viewModel.formatTargetDate(value),
                        }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            tickValues: "every",

                            legend: "",
                            legendPosition: "middle",
                            legendOffset: -40,
                        }}
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                        labelTextColor={{
                            from: "color",
                            modifiers: [["opacity", 1]],
                        }}
                        legends={[
                            {
                                dataFrom: "keys",
                                anchor: "top-left",
                                direction: "row",
                                justify: false,
                                translateX: 0,
                                translateY: -45,
                                itemsSpacing: 1,
                                itemWidth: 140,
                                itemHeight: 15,
                                itemDirection: "left-to-right",
                                itemOpacity: 0.85,
                                symbolSize: 15,
                                symbolShape: "circle",
                                effects: [
                                    {
                                        on: "hover",
                                        style: {
                                            itemOpacity: 1,
                                        },
                                    },
                                ],
                            },
                        ]}
                        role="application"
                        ariaLabel="Enquiries"
                    />
                </div>
            </Wrapper>
        </Container>
    );
});
